export const VARIANCE_TYPE = {
  NEUTRAL: 'NEUTRAL',
  UNFAVORABLE: 'UNFAVORABLE',
  FAVORABLE: 'FAVORABLE',
};

export const DEFINE_ATTRIBUTE_OPTIONS = {
  VARIANCE_OPTIONS: [
    {
      value: VARIANCE_TYPE.NEUTRAL,
      label: 'Neutral',
    },
    {
      value: VARIANCE_TYPE.UNFAVORABLE,
      label: 'Unfavorable',
    },
    {
      value: VARIANCE_TYPE.FAVORABLE,
      label: 'Favorable',
    },
  ],
  TOTAL_OPTIONS: [
    {
      value: null,
      label: 'None',
    },
    {
      value: 'AVERAGE',
      label: 'A (Average)',
    },
    {
      value: 'FIRST',
      label: 'F (First)',
    },
    {
      value: 'LAST',
      label: 'L (Last)',
    },
    {
      value: 'VARIABLE_WEIGHTED_AVERAGE',
      label: 'V (Variable Weighted Average)',
    },
    {
      value: 'WEIGHTED_AVERAGE',
      label: 'W (Weighted Average)',
    },
  ],
  PERCENTAGE_OPTIONS: [
    {
      value: null,
      label: 'None',
    },
    {
      value: 'PERCENTAGE',
      label: 'P (Percentage)',
    },
    {
      value: 'HEADING',
      label: 'H (Heading)',
    },
  ],
  DECIMAL_OPTIONS: Array.from({ length: 6 }, (v, k) => k).map((item) => {
    return {
      value: item,
      label: item,
    };
  }),
};

export const mapVarianceTypeWithLabel: Map<string, string> = new Map(
  DEFINE_ATTRIBUTE_OPTIONS.VARIANCE_OPTIONS.map((item) => [item.value, item.label])
);
