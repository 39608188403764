// @ts-nocheck
import ButtonLoading from '@mui/lab/LoadingButton';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useMemo, useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import qs from 'query-string';
import { Dayjs } from 'dayjs';
import { Box, Divider, Grid, Stack, Snackbar, Typography } from '@mui/material';
import Alert, { AlertProps } from '@mui/material/Alert';
import Button from '@mui/material/Button';
import {
  useDisplayFormInput,
  useGetDataInputPullAttributeMapping,
  useUpsertDisplayTable,
} from 'services/v1/Tenant/AROKMS/DisplayTableService';
import { Formik, FormikProps } from 'formik';
import { RootState } from 'store';
import { generateYupSchema } from 'utils/SchemaGenerator';
import { generateFormInput } from './components/FormGenerator';
import ActivityIndicator from 'components/ActivityIndicatorComponent';
import { serializeDataInputPayload } from './utils/serialiazePayload';
import { RULES_CONSTANT } from 'constant/RuleConstant';
import { TenantHomeDashboardNavigationBar } from 'components/LayoutComponent/VerticalLayout/HeaderBar';
import { ButtonBackToTop } from 'components/ButtonComponent/ButtonBackToTop';
import { PATH_CONSTANT } from 'constant/PathConstant';
import { getErrorMessage } from 'utils/Error';
import { DISABLED_EDIT_DELETE_SUBJECTS } from '../../../../constant/SubjectConstant';
import { useGetTableDefinitionDescription } from 'services/v1/SystemTenant/AROKMS/TableDefinitionDescriptionService';
import { CustomRuleCondition } from 'types/api/Tenant/AROKMS/DisplayTableTypes';
import { MEMBER_LIST_SUGGESTION_ID_NEED_TO_BE_INSERTED } from './components/DropdownAttributeBaseSubject';
import { useUpsertMemberListData } from 'services/v1/Tenant/AROKMS/MemberListService';
import { useGetSubjectDetails } from 'services/v1/SystemTenant/AROKMS/SubjectService';
import { ModalPreviewAttachment } from './components/ModalAttachmentContent/ModalPreviewAttachment';
import eventEmitter, { EVENT_EMITTER_KEYS } from 'utils/EventEmitter';

export default function InsertDataInput() {
  const authReducer = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();
  const formikRef = useRef<FormikProps<any>>(null);
  const { subjectId } = useParams<{ subjectId: string }>();
  const [searchParams] = useSearchParams();
  const procedureId = searchParams.get('procedureId') || undefined;
  const [displayKey, setDisplayKey] = useState<number>(0);
  const { data: subjectTableDescription, error: errorTableDesc } = useGetTableDefinitionDescription(subjectId);
  const { data: subjectDetails, error: errorSubjectDetails } = useGetSubjectDetails(subjectId);

  const [modalAttachment, setModalAttachment] = useState<{
    visible: boolean;
    url: string | undefined;
    fieldName: string;
    fieldLabel: string;
  }>({
    visible: false,
    url: '',
    fieldName: '',
    fieldLabel: '',
  });
  const { data: pullAttributeDataMapping } = useGetDataInputPullAttributeMapping(subjectId);
  const { mutate: insetData, isLoading: isInserting } = useUpsertDisplayTable({ subjectId, procedureId });
  const { mutateAsync: upserMemberListAsync } = useUpsertMemberListData();
  const {
    data: formDefinitionsData,
    isLoading,
    error: errorFormDefinitions,
  } = useDisplayFormInput({ subjectId, procedureId });
  const [snackbar, setSnackbar] = useState<Pick<AlertProps, 'children' | 'severity'> | null>(null);

  const handleCloseSnackbar = () => setSnackbar(null);

  const error = errorTableDesc || errorSubjectDetails || errorFormDefinitions;

  const handleOnSubmitForm = async (data: { [key: string]: any }, redirectPath: string) => {
    const primaryKeyFieldName = formDefinitionsData?.primaryKeyField?.id;

    // map data with ruleTypeCode from formDefinitionsData
    // then send to API
    const dataWithRuleTypeCode: {
      [key: string]: { ruleTypeCode: string; value: string | number | object | Dayjs };
    } = _.mapValues(data, (value, key) => {
      if (formDefinitionsData) {
        const fieldItem = _.find(formDefinitionsData.fields, { name: key });

        return {
          value,
          ruleTypeCode: fieldItem.ruleTypeCode,
          allowEmpty: fieldItem.allowEmpty,
          dropdownOptions: fieldItem.options,
        };
      }
    });

    const payloadData = serializeDataInputPayload(dataWithRuleTypeCode, authReducer);

    const tableDefinitionFieldId = Object.keys(payloadData).reduce((acc, key) => {
      const fieldItem = _.find(formDefinitionsData.fields, { name: key });
      return { ...acc, [key]: fieldItem.tableDefinitionId };
    }, {});

    const primaryKeyValue = payloadData[primaryKeyFieldName];

    // primaryKeyValue will be dropdownoption object if it is generated from value suggestion
    // so we need to insert the value to member list table first and get the id before insert the record.
    if (primaryKeyValue?.value === MEMBER_LIST_SUGGESTION_ID_NEED_TO_BE_INSERTED) {
      const { data: newMemberListData } = await upserMemberListAsync({
        memberList: payloadData[primaryKeyFieldName]?.label,
        subjectId,
      });

      payloadData[primaryKeyFieldName] = newMemberListData?.id;
    }

    handleInsertData(payloadData, tableDefinitionFieldId);
  };

  const handleInsertData = (payloadData: { [key: string]: any }, tableDefinitionFieldId: { [key: string]: any }) => {
    insetData(
      { data: payloadData, tableDefinitionId: tableDefinitionFieldId },
      {
        onSuccess: () => {
          toast.success('Data submitted successfully!');
          formikRef.current?.resetForm();
          setDisplayKey(displayKey + 1);
          handleOnCancelInput();
        },
        onError: (err) => {
          toast.error(getErrorMessage(err));
        },
      }
    );
  };

  const handleOnCancelInput = () => {
    navigate(
      `${PATH_CONSTANT.TENANT.KMS.DATA_INPUT.replace(':subjectId', subjectId)}?${qs.stringify({
        sid: subjectId,
        procedureId,
      })}`
    );
  };

  const yupSchema = useMemo(() => {
    if (!formDefinitionsData) {
      return {};
    }
    return formDefinitionsData.fields.reduce(generateYupSchema, {});
  }, [formDefinitionsData]);

  const validateSchema = useMemo(() => {
    return yupSchema ? yup.object().shape(yupSchema) : {};
  }, [yupSchema]);

  // Seperate fields into 2 columns
  const formFields = useMemo(() => {
    if (!formDefinitionsData) {
      return {
        input: [],
        multimedia: [],
      };
    }

    const ruleToSeperate = [
      RULES_CONSTANT.GPS.RULE_GPS,
      RULES_CONSTANT.ASSOCIATED_SUBJECT.RULE_ASSOCIATED_SUBJECT_LIST,
      RULES_CONSTANT.ASSOCIATED_SUBJECT.RULE_ASSOCIATED_SUBJECT_LIMITED_DROPDOWN_LIST,
      RULES_CONSTANT.TEXT.RULE_SELECT_FROM_KTREE_TABLE,
      RULES_CONSTANT.BASE_SUBJECT.RULE_ATTRIBUTE_SUBJECT_BASE,
      RULES_CONSTANT.TEXT.RULE_PULL_FROM_SUBJECT_ATTRIBUTE,
      ...Object.values(RULES_CONSTANT.DATE),
    ];
    return {
      input: formDefinitionsData.fields.filter((fieldItem, index) => {
        return ruleToSeperate.includes(fieldItem.ruleTypeCode);
      }),
      multimedia: formDefinitionsData.fields.filter((fieldItem, index) => {
        return !ruleToSeperate.includes(fieldItem.ruleTypeCode);
      }),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formDefinitionsData?.fields]);

  const mapCustomRuleWithField: Map<string, CustomRuleCondition> = useMemo(() => {
    const customRule = new Map();
    if (!formDefinitionsData) {
      return customRule;
    }

    Object.entries(formDefinitionsData.customRule).forEach(([key, value]) => {
      customRule.set(key, value);
    });
    return customRule;
  }, [formDefinitionsData]);

  const tableTitle = useMemo(() => {
    if (!subjectDetails?.data) return '';
    return `Add ${subjectDetails?.data?.displayName}`;
  }, [subjectDetails?.data]);

  const isCoreSubject = useMemo(() => {
    return DISABLED_EDIT_DELETE_SUBJECTS.includes(subjectId);
  }, [subjectId]);

  useEffect(() => {
    const handleOnShowAttachmentClick = (data: { visible: boolean; url: string; fieldName: string }) => {
      setModalAttachment(data);
    };
    eventEmitter.on(EVENT_EMITTER_KEYS.TIRGGER_SHOW_MODAL_ATTACHMENT_INSERT, handleOnShowAttachmentClick);

    return () => {
      eventEmitter.off(EVENT_EMITTER_KEYS.TIRGGER_SHOW_MODAL_ATTACHMENT_INSERT, handleOnShowAttachmentClick);
    };
  }, []);

  const handleOnChangeAttachment = (fieldName: string, value: string) => {
    formikRef.current?.setFieldValue(fieldName, value);
  };

  return (
    <TenantHomeDashboardNavigationBar error={error}>
      <ButtonBackToTop />
      <Box sx={{ height: '100%' }}>
        {isCoreSubject && (
          <Alert sx={{ mb: 2 }} severity='warning'>
            New records cannot be added directly to this subject, as it is a core component tightly integrated with the
            codebase. To add a new record, modifications at the code level are required.
          </Alert>
        )}

        {subjectTableDescription?.data?.description && (
          <Alert sx={{ mb: 2 }} severity='info'>
            {subjectTableDescription?.data?.description}
          </Alert>
        )}

        {isLoading && (
          <Stack height={500}>
            <ActivityIndicator />
          </Stack>
        )}
        {formDefinitionsData && (
          <Formik
            innerRef={formikRef}
            initialValues={formDefinitionsData.initialValues}
            validationSchema={validateSchema}
            onSubmit={handleOnSubmitForm}
          >
            {(formikProps: FormikProps<{ [key: string]: string }>) => {
              return (
                <Box sx={{ backgroundColor: '#fff', pb: 3, px: 3 }} alignItems='center' key={displayKey}>
                  <Stack
                    direction='row'
                    alignItems='center'
                    py={1}
                    justifyContent='space-between'
                    sx={{ backgroundColor: '#fff' }}
                    spacing={2}
                  >
                    <Stack>
                      <Typography variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
                        {tableTitle}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack>
                    <Typography variant='input-label' fontStyle='italic' sx={{ pt: 1, fontSize: 13 }}>
                      (<span style={{ color: 'red' }}>*</span>) indicates required fields
                    </Typography>
                  </Stack>

                  <Grid
                    container
                    marginTop={2}
                    sx={{ backgroundColor: '#fff', py: 1, borderTop: '1px solid #E3EBF6' }}
                    justifyContent='space-between'
                    display='flex'
                    flexWrap='wrap'
                    alignItems='flex-start'
                  >
                    <Grid container item xs={5.5} direction='column'>
                      {formFields?.input?.map((fieldItem) =>
                        generateFormInput(
                          formikProps,
                          fieldItem,
                          false,
                          mapCustomRuleWithField.get(fieldItem.name),
                          pullAttributeDataMapping?.data
                        )
                      )}
                    </Grid>

                    {formFields.multimedia.length > 0 && (
                      <Divider orientation='vertical' variant='middle' flexItem sx={{ px: 0 }} />
                    )}
                    <Grid container item xs={5.5} direction='column'>
                      {formFields?.multimedia?.map((fieldItem) =>
                        generateFormInput(
                          formikProps,
                          fieldItem,
                          false,
                          mapCustomRuleWithField.get(fieldItem.name),
                          pullAttributeDataMapping?.data
                        )
                      )}
                    </Grid>
                  </Grid>
                  <Stack direction='row' spacing={2} justifyContent='flex-end'>
                    <Button disabled={isInserting} onClick={handleOnCancelInput} variant='main-table-panel-border'>
                      Cancel
                    </Button>

                    <ButtonLoading
                      loading={isInserting}
                      variant='main-table-panel'
                      onClick={() => formikProps.handleSubmit()}
                      // @ts-ignore
                      disabled={!validateSchema?.isValidSync(formikProps.values) || isInserting || isCoreSubject}
                    >
                      Save
                    </ButtonLoading>
                  </Stack>
                </Box>
              );
            }}
          </Formik>
        )}
        {!!snackbar && (
          <Snackbar
            open
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            onClose={handleCloseSnackbar}
            autoHideDuration={3000}
          >
            <Alert {...snackbar} onClose={handleCloseSnackbar} />
          </Snackbar>
        )}
      </Box>

      <ModalPreviewAttachment
        visible={modalAttachment.visible}
        subjectId={subjectId}
        viewOnly={false}
        fieldName={modalAttachment.fieldName}
        fieldLabel={modalAttachment.fieldLabel}
        onChange={handleOnChangeAttachment}
        onClose={() => {
          setModalAttachment({ visible: false, url: null });
        }}
      />
    </TenantHomeDashboardNavigationBar>
  );
}
