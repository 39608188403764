export function convertSnackCaseToTitleCase(snackCase: string) {
  return snackCase
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

/**
 *
 * @param {*} fullName
 * @returns {string}
 *
 * Example:
 * getInitialsName('John Doe') => 'JD'
 * getInitialsName('Albus Percival Wulfric Brian dumbledore') => 'AD'
 * getInitialsName('Daniela') => 'D'
 *
 */

export function getInitialsName(fullName: string) {
  if (!fullName) return '';
  const allNames = fullName.trim().split(' ');
  const initials = allNames.reduce((acc, curr, index) => {
    if (index === 0 || index === allNames.length - 1) {
      acc = `${acc}${curr.charAt(0).toUpperCase()}`;
    }
    return acc;
  }, '');
  return initials;
}

export function convertToSnackCase(str: string) {
  if (!str) return '';
  return str
    .split(' ')
    .map((word) => word.toLowerCase())
    .join('_');
}

export function convertRuleTypeParamsToSnakeCase(columns: string): string[] {
  if (!columns) return [];
  return columns.split(',').map((column) => {
    if (column.toLowerCase() === ' ') return ' ';
    return column.trim().toLowerCase().replaceAll(' ', '_');
  });
}

export function convertRuleTypeParamsToSnakeCaseConcatenation(columns: string): string[] {
  if (!columns) return [];
  return columns.split(',').map((column) => {
    if (column.toLowerCase() === ' ') return ' ';
    if (column.startsWith('{') && column.endsWith('}')) {
      // This is a column name, convert to snake case
      return `{${column.slice(1, -1).toLowerCase().replaceAll(' ', '_')}}`;
    } else {
      // This is a static character, return as is
      return column;
    }
  });
}

function extractText(text: string, searchFromText: string, untilText: string, dataNumber: number) {
  let startIndex = 0;
  for (let i = 0; i < dataNumber; i++) {
    startIndex = text.indexOf(searchFromText, startIndex) + searchFromText.length;
  }
  const endIndex = text.indexOf(untilText, startIndex);
  return text.substring(startIndex, endIndex);
}

export function generalSubstringFunction(
  column: string,
  find: string,
  starChar: number | undefined | string,
  numberToAdd: number | undefined,
  endChar: number | undefined | string
) {
  if (starChar === undefined || starChar === null) starChar = 1;
  if (numberToAdd === undefined || numberToAdd === null) numberToAdd = 0;
  if (endChar === undefined || endChar === null) endChar = 0;

  if (starChar && typeof starChar === 'string' && endChar && typeof endChar === 'string') {
    return extractText(column, starChar, endChar, 1);
  }

  // @ts-ignore
  const startIndex = starChar - 1;

  if (!column) return '';

  if (!find) {
    // @ts-ignore
    return column.substring(startIndex, endChar);
  }

  if (!numberToAdd && !endChar) {
    return column.substring(startIndex, column.indexOf(find));
  }

  const index = column.indexOf(find);
  if (index === -1) return '';
  // @ts-ignore
  return column.substring(index + numberToAdd, index + numberToAdd + endChar);
}
export function normalizeParameterNumber(value: string) {
  if (!value) return '';
  if (value === ' ') return value;
  if (/[0-9]/g.test(value)) return Number(value);
  return value;
}

export function normalizeRowWithDropdownValue(row: any, colName: string) {
  const newRow = { ...row };
  Object.keys(row).forEach((key) => {
    if (row?.[key] && row[key]?.[colName]) {
      newRow[key] = row[key][colName];
    }

    if (!row[key]) newRow[key] = null;
  });
  return newRow;
}

export function customFormatRenderValue(value: string) {
  // if string is url or a web address format then return a link
  // check the value with regex
  const regex = /((http|https):\/\/)(www.)?([a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*))/g;
  if (regex.test(value)) {
    return (
      <a href={value} target='_blank' rel='noopener noreferrer'>
        {value}
      </a>
    );
  }

  // if string is a phone number format then return a link
  // check the value with regex
  const regexPhone = /(\+?\d{1,2}[.-\s]?)?(\d{3}[.-]?){2}\d{4}/g;
  if (regexPhone.test(value)) {
    return (
      <a href={`tel:${value}`} target='_blank' rel='noopener noreferrer'>
        {value}
      </a>
    );
  }

  // if string is a email format then return a link
  // check the value with regex
  const regexEmail =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;
  if (regexEmail.test(value)) {
    return (
      <a href={`mailto:${value}`} target='_blank' rel='noopener noreferrer'>
        {value}
      </a>
    );
  }

  // if string is number format then format it with commas
  // check the value with regex
  const regexNumber = /^\d+$/g;
  if (regexNumber.test(value)) {
    return value;
  }

  return value;
}

export function wordsInString(s: string, words: string[]) {
  const regex = new RegExp(`\\b(${words.join('|')})\\b`, 'i');
  return regex.test(s);
}

export function extractYoutubeId(url: string) {
  const regex = /(?:youtube\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  const match = url.match(regex);
  return match ? match[1] : null;
}

export function getYoutubeThumbnailURL(videoId: string) {
  return `https://img.youtube.com/vi/${videoId}/default.jpg`;
}

export function getYoutubeThumbnailFromURL(url: string) {
  const videoId = extractYoutubeId(url);
  return videoId ? getYoutubeThumbnailURL(videoId) : undefined;
}

export const isValidUrl = (url: string) => {
  try {
    new URL(url);
    return true;
  } catch (e) {
    return false;
  }
};
export const isValidYoutubeUrl = (url: string) => {
  const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/;
  return youtubeRegex.test(url);
};
