import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { styled, alpha } from '@mui/material/styles';
import { SvgIcon } from '@mui/material';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

// Custom Icon
import { ReactComponent as CustomIconCSV } from 'assets/icons/icon-csv.svg';
import { ReactComponent as CustomIconXLS } from 'assets/icons/icon-xls.svg';
import { FILE_TYPE_PARAMS } from 'constant/ImportExportConstant';

export const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      color: '#42BB93',
      fontSize: 13,
      '& .MuiSvgIcon-root': {
        fontSize: 16,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },

      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

export enum FileType {
  CSV = 'CSV',
  EXCEL = 'EXCEL',
}

interface ButtonExportProps {
  onClick: (type: FileType) => void;
  disabled?: boolean | undefined;
}

export function ButtonExport(props: ButtonExportProps) {
  const { t } = useTranslation();
  const { onClick, disabled = false } = props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (selectedFileType: string) => {
    onClick(selectedFileType as FileType);
    handleClose();
  };

  return (
    <div>
      <Tooltip title={t('COMMON.BUTTON.EXPORT')}>
        <span data-testid='button-export-label'>
          <IconButton
            disabled={disabled}
            data-testid='button-export'
            aria-controls={open ? 'demo-customized-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined}
            sx={{
              opacity: disabled ? 0.5 : 1,
            }}
            onClick={handleClick}
          >
            <FileDownloadOutlinedIcon sx={{ color: '#42BB93' }} />
          </IconButton>
        </span>
      </Tooltip>

      <StyledMenu data-testid='export-options' anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem
          data-testid='export-menu-button'
          onClick={() => handleMenuItemClick(FILE_TYPE_PARAMS.CSV)}
          disableRipple
        >
          <SvgIcon fontSize='small' sx={{ fill: '#42BB93' }} inheritViewBox component={CustomIconCSV} />
          {t('COMMON.BUTTON.EXPORT_CSV_FILE')}
        </MenuItem>
        <MenuItem
          data-testid='export-menu-button'
          onClick={() => handleMenuItemClick(FILE_TYPE_PARAMS.EXCEL)}
          disableRipple
        >
          <SvgIcon fontSize='small' sx={{ fill: '#42BB93' }} inheritViewBox component={CustomIconXLS} />
          {t('COMMON.BUTTON.EXPORT_EXCEL_FILE')}
        </MenuItem>
      </StyledMenu>
    </div>
  );
}
