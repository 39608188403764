import qs from 'query-string';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router';
import { useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  GridColDef,
  GridRowModesModel,
  GridRowModes,
  GridRowEditStopParams,
  GridRowParams,
  GridSelectionModel,
  GridRowId,
  GridFilterModel,
  getGridStringOperators,
  GridSortModel,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { ModalImportComponent, ModalImportState } from 'components/ModalComponent/ModalImportComponent';
import {
  useMemberList,
  useUpsertMemberListData,
  useDeleteMemberListData,
} from 'services/v1/Tenant/AROKMS/MemberListService';
import { PaginationAndSortingParams } from 'types/api/Common/PaginationTypes';
import { AxiosDefaultErrorEntity } from 'types/api/Common/ErrorTypes';
import { CustomDataTable } from 'components/DatatableComponent';
import { MainLayoutComponent } from 'components/LayoutComponent/SidebarLayout/MainLayoutComponent';
import { MemberListItem } from 'types/api/Tenant/AROKMS/MemberListTypes';
import { SettingPanel } from './components/MemberListPanel';
import ModalDeleteComponent, { ModalDeleteState } from 'components/ModalComponent/ModalDeleteComponent';
import { AutoCompleteItem } from 'types/api/Common/AutoCompleteTypes';
import { getErrorMessage } from 'utils/Error';
import { useExportDataTable, useDownloadDataImportTemplate } from 'services/v1/Misc/UploadService';
import { downloadFileFromURL, getFileName } from 'utils/DownloadFIle';
import { useSubjectDropdownMemberList } from 'services/v1/SystemTenant/AROKMS/SubjectService';
import { RootState } from 'store';
import { checkAccess } from 'routes/PrivateRoute';
import { ROLES } from 'constant/PermissonConstant';
import { DASHBOARD_VIEW_MODE } from 'constant/DashboardTypeConstant';
import ModalInfoComponent, { ModalInfoState } from 'components/ModalComponent/ModalInfoComponent';
import { dayjs } from 'utils/DateUtils';
import { ENV_CONFIG } from 'config/env';

interface MemberListFilter extends PaginationAndSortingParams {
  subjectId?: string | number;
}
interface OptionItem {
  value: string | number;
  label: string;
}

// File Name for export, import, and file import template
const FILE_NAME = {
  EXPORT: 'EXPORT_MEMBER_LIST',
  IMPORT: 'IMPORT_MEMBER_LIST',
  TEMPLATE: 'IMPORT_TEMPLATE_MEMBER_LIST',
};

const panelStyle = {
  transform: 'translate3d(704px, 140.667px, 0px) !important',

  '& .MuiDataGrid-panelFooter': {
    display: 'none',
  },
};

export default function MemberListPage() {
  const { t } = useTranslation();
  const apiRef = useGridApiRef();
  const { threadUserAsRole, dashboardViewMode } = useSelector((state: RootState) => state.auth);
  const location = useLocation();
  const { sid = null } = qs.parse(location.search);
  const [muiDataTableKey, setMuiDataTableKey] = useState<number>(0);
  const [rowData, setRowData] = useState<MemberListItem[]>([]);
  const [modalImportState, setModalImportState] = useState<ModalImportState>({
    open: false,
    fileType: '',
  } as ModalImportState);
  const [modalDeleteState, setModalDeleteState] = useState<ModalDeleteState>({ open: false, message: '' });
  const [modalInfoState, setModalInfoState] = useState<ModalInfoState>({ open: false, descriptionMessage: '' });

  const [selectedDataID, setSelectedDataID] = useState<GridRowId[]>([]);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [filter, setFilter] = useState<MemberListFilter>({
    page: 0,
    size: 10,
    filterValue: '',
    sortType: 'asc',
    filterOperator: 'contains',
  });

  const [filterButtonElement, setFilterButtonElement] = useState<HTMLButtonElement | null>(null);
  const [selectedSubjectId, setSelectedSubjectId] = useState<AutoCompleteItem>({ label: '', value: '' });
  const [initialOption, setInitialOption] = useState<OptionItem | null>(null);
  const [editedData, setEditedData] = useState<MemberListItem[]>([]);

  // Service call
  const {
    data: memberList,
    isLoading,
    refetch: refreshMemberList,
  } = useMemberList({ subjectId: selectedSubjectId.value });
  const { data: subjectDropdownData, isLoading: isDropdownLoading } = useSubjectDropdownMemberList();
  const { mutateAsync: upserMemberListAsync } = useUpsertMemberListData(filter);
  const { mutateAsync: deleteMemberListById } = useDeleteMemberListData(filter);
  const { mutate: exportDataTable } = useExportDataTable();
  const { mutate: downloadDataImportTemplate } = useDownloadDataImportTemplate();

  const isDataEditable = useMemo(() => {
    return checkAccess([ROLES.BUILDER, ROLES.ADMIN, ROLES.TESTER, ROLES.SUPPORT, ROLES.PACKAGER], threadUserAsRole);
  }, [threadUserAsRole]);

  const isViewOnly = dashboardViewMode === DASHBOARD_VIEW_MODE.VIEW_ONLY;

  const columns = useMemo<GridColDef[]>(() => {
    return [
      {
        field: 'memberList',
        headerName: t('SIDEBAR_MENU.MEMBER_LIST'),
        width: 700,
        editable: isDataEditable,
        filterOperators: getGridStringOperators().filter((operator) => ['contains', 'equals'].includes(operator.value)),
      },
      {
        field: 'createdDate',
        headerName: 'Created Date',
        width: 400,
        valueFormatter: (params) => {
          return params?.value ? dayjs(params.value).format(ENV_CONFIG.config.DATE_FORMAT) : '';
        },
        editable: false,
        filterOperators: getGridStringOperators().filter((operator) => ['contains', 'equals'].includes(operator.value)),
      },
    ];
  }, [t, isDataEditable]);

  const dataWithIDSerial = useMemo(() => {
    return (
      rowData.map((item, index) => ({
        ...item,
        idSerial: (filter.page - 1) * filter.size + index + 1,
      })) || []
    );
  }, [filter, rowData]);

  const subjectDropdownOptions = useMemo(() => {
    if (subjectDropdownData?.data) {
      if (sid) {
        const initialSubject = subjectDropdownData.data.find((item) => item.id === +sid);
        if (initialSubject) {
          const initiaOptions = {
            label: initialSubject.subject || '',
            value: initialSubject.id || '',
          };
          setInitialOption(initiaOptions);
          setSelectedSubjectId(initiaOptions);
        } else {
          setSelectedSubjectId({
            label: subjectDropdownData?.data[0]?.subject || '',
            value: subjectDropdownData?.data[0]?.id || '',
          });
        }
      } else {
        setSelectedSubjectId({
          label: subjectDropdownData?.data[0]?.subject || '',
          value: subjectDropdownData?.data[0]?.id || '',
        });
      }
      return subjectDropdownData.data.map((item) => ({
        value: item.id + '',
        label: item.subject,
      }));
    }
    return [];
  }, [subjectDropdownData, sid]);

  const DataMutationSuccessHandlerOption = {
    onSuccess: () => {
      refreshMemberList();
      toast.update('member-list-toast', {
        type: 'success',
        render: 'Your new member list was inserted successfully!',
        isLoading: false,
        autoClose: 4000,
      });
    },
    onError: (error: AxiosDefaultErrorEntity) => {
      refreshMemberList();
      toast.update('member-list-toast', { type: 'error', render: getErrorMessage(error), isLoading: false });
    },
  };

  useEffect(() => {
    if (memberList?.data?.data) {
      setRowData(memberList?.data?.data);
    }
  }, [memberList]);

  // Process row Update will be triggered when
  // user modify or insert new row the table
  const handleProcessRowUpdate = async (data: MemberListItem, oldData: MemberListItem) => {
    try {
      if (!Boolean(data.memberList.trim())) return;
      // If old row data is equal to new row data, then do nothing
      // (no need to update just return the data, because nothing changed)
      if (data.memberList.trim() === oldData.memberList.trim()) {
        // if oldData.isNew equals to true, then it means that this is a new row
        // andl cancle the row when cursor out of focust
        return data;
      }
      // otherwise, update the row data
      else {
        toast('Updating data...', { toastId: 'member-list-toast', isLoading: true, updateId: 'member-list-toast' });
        const isEmpty = data.memberList.trim() === '';

        if (data.isNew) {
          // if the row is new and not empy, then insert the row to database
          if (!isEmpty) {
            upserMemberListAsync(
              { memberList: data.memberList.trim(), subjectId: selectedSubjectId.value },
              DataMutationSuccessHandlerOption
            );
            return { ...data, isNew: false };
          } else {
            handleCancelInsert(data.id);
          }
        }
        // if the row is not new, it means that the row n
        if (!data.isNew) {
          if (isEmpty) {
          } else {
            // if data already exist in state editedData, update it
            // if not, add it to editedData
            const index = editedData.findIndex((item) => item.id === data.id);
            if (index === -1) {
              setEditedData([...editedData, data]);
            } else {
              const newEditedData = [...editedData];
              newEditedData[index] = data;
              setEditedData(newEditedData);
            }
            upserMemberListAsync(
              { memberList: data.memberList.trim(), subjectId: selectedSubjectId.value, id: data.id },
              DataMutationSuccessHandlerOption
            );
            return data;
          }
        }
      }
    } catch (error) {
      toast.update('member-list-toast', { type: 'error', render: getErrorMessage(error as AxiosDefaultErrorEntity) });
    }
  };

  const isAnyRowEdit = useMemo(() => {
    return Object.values(rowModesModel).some((item) => item.mode === GridRowModes.Edit);
  }, [rowModesModel]);

  const handleChangePage = (newPage: number) => {
    setFilter({ ...filter, page: newPage });
  };
  const handleChangePageSize = (newPageSize: number) => {
    setFilter({ ...filter, size: newPageSize });
  };

  const handleOnFilter = (params: GridFilterModel) => {
    let filterObj = {};
    if (params.items.length) {
      filterObj = {
        filterValue: params.items[0].value,
        filterOperator: params.items[0].operatorValue,
      };
    } else {
      filterObj = {
        filterValue: '',
        filterOperator: 'contains',
      };
    }
    setFilter({
      ...filter,
      ...filterObj,
    });
  };

  const handleCancelInsert = (id: number) => {
    const newRows = rowData.filter((item) => item.id !== id);
    setRowData(newRows);
    setRowModesModel((prev) => {
      const newModel = { ...prev };
      delete newModel[id];
      return newModel;
    });
  };

  const handleRowEditStop = (params: GridRowEditStopParams) => {
    setRowModesModel({ ...rowModesModel, [params.id]: { mode: GridRowModes.View } });
  };

  const handleEditRowModes = (params: GridRowParams) => {
    if (isViewOnly || isAnyRowEdit) return;
    if (params.row.counter > 0) {
      setModalInfoState({
        open: true,
        title: 'Cannot Edit',
        descriptionMessage: `Cannot edit this member list '${params.row.memberList}' because it has been used in other data`,
      });
      return;
    }
    setRowModesModel({ ...rowModesModel, [params.id]: { mode: GridRowModes.Edit } });
  };

  const handleOnClickDeleteButton = () => {
    const selectedData = rowData.filter((item) => selectedDataID.includes(item.id));
    setModalDeleteState({
      ...modalDeleteState,
      open: true,
      message: `Member List : ${selectedData.map((item) => item.memberList).join(', ')}`,
    });
  };
  const handleDeleteData = async () => {
    try {
      if (selectedDataID.length === 0) return;
      const multipleDeleteRequest = selectedDataID.map((id) => deleteMemberListById({ id }));
      await Promise.all(multipleDeleteRequest);
      setModalDeleteState({
        ...modalDeleteState,
        open: false,
        message: '',
      });
      toast(`Successfully delete ${selectedDataID.length} member list`, {
        toastId: 'member-list-toast',
        type: 'success',
        updateId: 'member-list-toast',
      });

      refreshMemberList();
      setSelectedDataID([]);
    } catch (err) {
      toast.update('member-list-toast', { type: 'error', render: getErrorMessage(err as AxiosDefaultErrorEntity) });
    }
  };

  const handleOnSortModelChange = (model: GridSortModel) => {
    if (model.length === 0) {
      setFilter({ ...filter, sortType: 'asc' });
      return;
    }
    setFilter({ ...filter, sortType: model[0].sort });
  };

  const handleOnSelectionModelChange = (selectedId: GridSelectionModel) => {
    setSelectedDataID(selectedId);
  };

  const handleOnDropdownSubjectChange = (option: { value: string; label: string }) => {
    setMuiDataTableKey(muiDataTableKey + 1);
    setSelectedSubjectId(option);
    setFilter({ ...filter, filterValue: '' });
  };

  const handleOnExportClick = (selectedFileType: string) => {
    exportDataTable(
      { category: 'member-list', fileType: selectedFileType },
      {
        onSuccess: (response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          downloadFileFromURL(url, getFileName(FILE_NAME.EXPORT, selectedFileType));
        },
      }
    );
  };
  const handleOnImportClick = (selectedFileType: string) => {
    setModalImportState({ ...modalImportState, fileType: selectedFileType, open: true });
  };

  const handleOnTemplateClick = (selectedFileType: string) => {
    downloadDataImportTemplate(
      { category: 'member-list', fileType: selectedFileType },
      {
        onSuccess: (response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          downloadFileFromURL(url, getFileName(FILE_NAME.TEMPLATE, selectedFileType));
        },
      }
    );
  };

  const handleOnImportCompleted = () => {
    refreshMemberList();
  };

  return (
    <MainLayoutComponent
      pageTitle={t('PAGE.MEMBER_LIST.TITLE')}
      breadcrumbs={[t('SIDEBAR_MENU.DASHBOARD'), t('SIDEBAR_MENU.DATA_BUILDER')]}
    >
      <CustomDataTable
        key={muiDataTableKey}
        apiRef={apiRef}
        initialState={{
          pagination: {
            page: 0,
          },
          sorting: {
            sortModel: [],
          },
        }}
        loading={isLoading || isDropdownLoading}
        rows={dataWithIDSerial}
        columns={columns}
        rowCount={memberList?.data?.rowCount}
        page={filter.page || 0}
        pageSize={filter.size}
        checkboxSelection={!isViewOnly}
        getRowId={(row) => row.id}
        rowModesModel={rowModesModel}
        disableSelectionOnClick
        rowsPerPageOptions={[5, 10, 20]}
        getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
        experimentalFeatures={{ newEditingApi: true }}
        onFilterModelChange={handleOnFilter}
        processRowUpdate={handleProcessRowUpdate}
        onSortModelChange={handleOnSortModelChange}
        onPageChange={handleChangePage}
        onPageSizeChange={handleChangePageSize}
        onSelectionModelChange={handleOnSelectionModelChange}
        onRowEditStop={handleRowEditStop}
        onRowDoubleClick={handleEditRowModes}
        autoHeight={rowData.length !== 0 && rowData.length > 5}
        paginationMode='client'
        sortingMode='client'
        filterMode='client'
        components={{
          Toolbar: SettingPanel,
        }}
        componentsProps={{
          panel: {
            sx: panelStyle,
            anchorEl: filterButtonElement,
          },
          toolbar: {
            setRows: setRowData,
            setRowModesModel,
            onDeleteData: handleOnClickDeleteButton,
            setFilterButtonElement: setFilterButtonElement,
            options: subjectDropdownOptions,
            initialOption,
            optionValue: selectedSubjectId,
            disabled: isLoading || rowData.some((item) => item.isNew) || isViewOnly || isAnyRowEdit,
            disabledDeleteButton: selectedDataID.length === 0,
            onOptionChange: handleOnDropdownSubjectChange,
            onExport: handleOnExportClick,
            onImport: handleOnImportClick,
            onButtonTemplateClick: handleOnTemplateClick,
            isInserting: rowData.some((item) => item.isNew),
            config: {
              deleteButtonText: 'Delete',
              fieldFocusOnInsert: 'memberList',
              insertButtonText: t('PAGE.MEMBER_LIST.BUTTON_ADD_NEW'),
              insertInitialObject: {
                memberList: '',
                isNew: true,
              },
            },
          },
        }}
      />

      <ModalImportComponent
        category='member-list'
        fileType={modalImportState.fileType}
        onImportSuccess={handleOnImportCompleted}
        visible={modalImportState.open}
        onClose={() => setModalImportState({ ...modalImportState, open: false })}
        onCancel={() => setModalImportState({ ...modalImportState, open: false })}
      />

      <ModalInfoComponent
        visible={modalInfoState.open}
        title={modalInfoState.title}
        onClose={() => setModalInfoState({ ...modalInfoState, open: false })}
        descriptionMessage={modalInfoState.descriptionMessage}
      />
      <ModalDeleteComponent
        visible={modalDeleteState.open}
        message={modalDeleteState.message}
        onApprove={handleDeleteData}
        onClose={() => setModalDeleteState({ ...modalDeleteState, open: false })}
        onCancel={() => setModalDeleteState({ ...modalDeleteState, open: false })}
      />
    </MainLayoutComponent>
  );
}
