import { AxiosResponse } from 'axios';
import { useMutation, useQueryClient, useQuery } from 'react-query';
import axios from 'libs/Axios';

import { AxiosDefaultErrorEntity } from 'types/api/Common/ErrorTypes';
import {
  TableDefinitionPrimaryKeySuggestionDetails,
  TableDefinitionUpsertPrimaryKeySuggestionRequest,
} from 'types/api/SystemTenant/AROKMS/TableDefinitionTypes';

export const TABLE_DEFINITION_PRIMARY_KEY_SUGGESTION_KEY = {
  TABLE_DEFINITION_PRIMARY_KEY_SUGGESTION: 'TABLE_DEFINITION_PRIMARY_KEY_SUGGESTION',
};

export function useUpsertTableDefinitionPrimaryKeySuggestion() {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse<string>, AxiosDefaultErrorEntity, TableDefinitionUpsertPrimaryKeySuggestionRequest>(
    (bodyRequest) => axios.post(`/api/v1/table-definition/primary-key/upsert/${bodyRequest.subjectId}`, bodyRequest),
    {
      onSettled: () => {
        queryClient.invalidateQueries([
          TABLE_DEFINITION_PRIMARY_KEY_SUGGESTION_KEY.TABLE_DEFINITION_PRIMARY_KEY_SUGGESTION,
        ]);
      },
    }
  );
}

export function useGetTableDefinitionPrimaryKeyDetails(subjectId?: string) {
  return useQuery<AxiosResponse<TableDefinitionPrimaryKeySuggestionDetails>, AxiosDefaultErrorEntity>(
    [TABLE_DEFINITION_PRIMARY_KEY_SUGGESTION_KEY.TABLE_DEFINITION_PRIMARY_KEY_SUGGESTION, subjectId],
    () => axios.get(`/api/v1/table-definition/primary-key/${subjectId}`).then((res) => res),
    {
      enabled: !!subjectId,
    }
  );
}
