import qs from 'query-string';
import { useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import EditIcon from '@mui/icons-material/Edit';
import {
  GridRowModesModel,
  GridFilterModel,
  useGridApiRef,
  GridSortModel,
  GridColumns,
  GridActionsCellItem,
} from '@mui/x-data-grid-pro';
import { useSubjectUsedDropdown } from 'services/v1/SystemTenant/AROKMS/SubjectService';
import { PaginationAndSortingParams } from 'types/api/Common/PaginationTypes';
import { CustomDataTable } from 'components/DatatableComponent';
import { MainLayoutComponent } from 'components/LayoutComponent/SidebarLayout/MainLayoutComponent';
import { SettingPanel } from 'pages/SystemTenant/AROKMS/KTree/components/KTreeTablePanel';
import { AutoCompleteItem } from 'types/api/Common/AutoCompleteTypes';

import { OptionItem } from 'pages/SystemTenant/AROKMS/TableDefinition/components/TabelDefinitionListPanel';

import { DASHBOARD_VIEW_MODE } from 'constant/DashboardTypeConstant';
import { SUBJECT_TYPE } from 'constant/DataInputConstant';
import { useGetAllCubeDefinitionList } from 'services/v1/SystemTenant/AROCube/CubeBuilderService';
import { CubeDefinitionItem } from 'types/api/SystemTenant/AROCube/CubeBuilderTypes';
import { PATH_CONSTANT } from 'constant/PathConstant';

interface MemberListFilter extends PaginationAndSortingParams {
  subjectId?: string | number;
}

const panelStyle = {
  transform: 'translate3d(704px, 140.667px, 0px) !important',

  '& .MuiDataGrid-panelFooter': {
    display: 'none',
  },
};
const COLUMN_WIDTH = 150;

export default function CubeDefinitionListPage() {
  const { t } = useTranslation();
  const { dashboardViewMode } = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();
  const location = useLocation();
  const { sid = null } = qs.parse(location.search);
  const apiRef = useGridApiRef();
  const [muiDataTableKey] = useState<number>(0);
  const [rowData, setRowData] = useState<CubeDefinitionItem[]>([]);
  const [initialOption, setInitialOption] = useState<OptionItem | null>(null);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [filterButtonElement, setFilterButtonElement] = useState<HTMLButtonElement | null>(null);
  const [selectedSubjectId, setSelectedSubjectId] = useState<AutoCompleteItem>({ label: '', value: '' });
  const [filter, setFilter] = useState<MemberListFilter>({
    page: 0,
    size: 10,
    filterValue: '',
    sortType: 'asc',
    filterOperator: 'contains',
  });

  // Service call
  const { data: subjectDropdownData, isLoading: isDropdownLoading } = useSubjectUsedDropdown({
    enabled: true,
    subjectType: SUBJECT_TYPE.TABLE,
  });
  const { isLoading, data: cubeDefinitionListData } = useGetAllCubeDefinitionList(selectedSubjectId?.value);

  const isBuilderViewOnly = dashboardViewMode === DASHBOARD_VIEW_MODE.VIEW_ONLY;

  const columns = useMemo<GridColumns>(() => {
    const cubeCols: GridColumns = [
      {
        field: 'cubeCode',
        headerName: 'Code',
        width: 80,
        editable: true,
        filterable: true,
        sortable: true,
      },
      {
        field: 'cubeName',
        headerName: 'Cube Name',
        width: COLUMN_WIDTH,
        editable: true,
        filterable: true,
        sortable: true,
      },
      {
        field: 'member',
        headerName: 'KTree (M)',
        width: COLUMN_WIDTH,
        editable: true,
        filterable: true,
        sortable: true,
      },
      {
        field: 'subjectX',
        headerName: 'KTree (X)',
        width: COLUMN_WIDTH,
        editable: true,
        filterable: true,
        sortable: true,
      },
      {
        field: 'timePeriodSetCode',
        headerName: 'Time Period Set (T)',
        width: COLUMN_WIDTH,
        editable: true,
        filterable: true,
        sortable: true,
      },
      {
        field: 'timePeriodSetName',
        headerName: 'Period Set Name',
        width: COLUMN_WIDTH,
        editable: true,
        filterable: true,
        sortable: true,
      },
      {
        field: 'layerSetCode',
        headerName: 'Layer Set (T)',
        width: COLUMN_WIDTH,
        editable: true,
        filterable: true,
        sortable: true,
      },
      {
        field: 'layerSetName',
        headerName: 'Layer Set Name',
        width: COLUMN_WIDTH,
        editable: true,
        filterable: true,
        sortable: true,
      },
      {
        field: 'cubeDescription',
        headerName: 'Description',
        width: 450,
        editable: true,
        filterable: true,
        sortable: true,
      },
    ];

    if (isBuilderViewOnly) return cubeCols;
    return [
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Edit',
        width: 30,
        cellClassName: 'actions',

        getActions: ({ id }) => {
          return [
            // @ts-ignore
            <GridActionsCellItem
              icon={<EditIcon />}
              label='Edit'
              onClick={() => {
                if (selectedSubjectId?.value) {
                  navigate(
                    PATH_CONSTANT.SYSTEM.CUBE.CUBE_DEFINITION_EDIT.replace(
                      ':subjectId',
                      selectedSubjectId?.value as string
                    ).replace(':cubeId', id as string)
                  );
                }
              }}
            />,
          ];
        },
      },
      ...cubeCols,
    ];

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSubjectId?.value, isBuilderViewOnly]);

  const subjectDropdownOptions = useMemo(() => {
    if (subjectDropdownData?.data) {
      if (sid) {
        const initialSubject = subjectDropdownData.data.find((item) => item.value === sid);
        if (initialSubject) {
          setInitialOption(initialSubject);
          setSelectedSubjectId(initialSubject);
        } else {
          setSelectedSubjectId(subjectDropdownData?.data[0]);
        }
      } else {
        setSelectedSubjectId(subjectDropdownData?.data[0]);
      }
      return subjectDropdownData.data;
    }
    return [];
  }, [subjectDropdownData, sid]);

  useEffect(() => {
    if (cubeDefinitionListData?.data) {
      setRowData(cubeDefinitionListData.data);
    }
  }, [cubeDefinitionListData?.data]);

  const handleChangePage = (newPage: number) => {
    setFilter({ ...filter, page: newPage });
  };
  const handleChangePageSize = (newPageSize: number) => {
    setFilter({ ...filter, size: newPageSize });
  };

  const handleOnFilter = (params: GridFilterModel) => {
    let filterObj = {};
    if (params.items.length) {
      filterObj = {
        filterValue: params.items[0].value,
        filterOperator: params.items[0].operatorValue,
      };
    } else {
      filterObj = {
        filterValue: '',
        filterOperator: 'contains',
      };
    }
    setFilter({
      ...filter,
      ...filterObj,
    });
  };

  const handleOnDropdownSubjectChange = (option: { value: string; label: string }) => {
    setSelectedSubjectId(option);
    setFilter({ ...filter, filterValue: '' });
    navigate({
      search: `?sid=${option.value}`,
    });
  };

  const handleOnSortModelChange = (model: GridSortModel) => {
    if (model.length === 0) {
      setFilter({ ...filter, sortType: 'asc' });
      return;
    }
    setFilter({ ...filter, sortType: model[0].sort });
  };

  const handleOnAddButtonClick = () => {
    navigate(`/cube/definition/${selectedSubjectId.value}/create`);
  };

  return (
    <MainLayoutComponent pageTitle='Cube Definition' breadcrumbs={[t('SIDEBAR_MENU.DASHBOARD'), 'ARO Cube']}>
      <CustomDataTable
        key={muiDataTableKey}
        apiRef={apiRef}
        initialState={{
          pinnedColumns: { right: ['action'] },
          pagination: {
            page: 0,
          },
        }}
        loading={isLoading || isDropdownLoading}
        rows={rowData}
        columns={columns}
        rowCount={rowData.length}
        page={filter.page}
        pageSize={filter.size}
        getRowId={(row) => row.id}
        rowModesModel={rowModesModel}
        checkboxSelection={false}
        disableSelectionOnClick
        rowsPerPageOptions={[5, 10, 20]}
        getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
        experimentalFeatures={{ newEditingApi: true }}
        onFilterModelChange={handleOnFilter}
        onSortModelChange={handleOnSortModelChange}
        onPageChange={handleChangePage}
        paginationMode='client'
        sortingMode='client'
        filterMode='client'
        onPageSizeChange={handleChangePageSize}
        autoHeight={rowData.length !== 0 && rowData.length > 5}
        components={{
          Toolbar: SettingPanel,
        }}
        componentsProps={{
          panel: {
            sx: panelStyle,
            anchorEl: filterButtonElement,
          },
          toolbar: {
            setRows: setRowData,
            setRowModesModel,
            setFilterButtonElement: setFilterButtonElement,
            options: subjectDropdownOptions,
            initialOption,
            optionValue: selectedSubjectId,
            disabled: isLoading || isBuilderViewOnly,
            onAddButtonClick: handleOnAddButtonClick,
            onOptionChange: handleOnDropdownSubjectChange,
            config: {
              fieldFocusOnInsert: '',
              insertButtonText: 'Add New Cube',
            },
          },
        }}
      />
    </MainLayoutComponent>
  );
}
