import * as yup from 'yup';

const SYSTEM_DATA_SOURCE = {
  SCRATCH: 'SCRATCH',
  COPY_FROM_EXISTING_SYSTEM: 'COPY_FROM_EXISTING_SYSTEM',
};

export const CreateAndDeploySystemValidationSchema = yup.object().shape({
  serverId: yup.object().shape({
    value: yup.string().required('Server Id is required'),
    label: yup.string(),
  }),
  userId: yup.array().of(yup.string()).required('User Id is required').min(1),
  system: yup.object().shape({
    value: yup.string().required('Code status is required'),
    label: yup.string(),
  }),
  codeStatus: yup.object().shape({
    value: yup.string().required('Code status is required'),
    label: yup.string(),
  }),
  demoStatus: yup.object().shape({
    value: yup.string().required('Demo status is required'),
    label: yup.string(),
  }),
  fieldStatus: yup.object().shape({
    value: yup.string().required('Field status is required'),
    label: yup.string(),
  }),
  dataSource: yup
    .mixed()
    .oneOf([SYSTEM_DATA_SOURCE.SCRATCH, SYSTEM_DATA_SOURCE.COPY_FROM_EXISTING_SYSTEM])
    .required('Data source is required'),
  sourceSystemId: yup
    .mixed()
    .when('dataSource', {
      is: SYSTEM_DATA_SOURCE.COPY_FROM_EXISTING_SYSTEM,
      then: yup.mixed().required('Source system is required'),
    })
    .notRequired(),
});

export const UpdateSystemValidationSchema = yup.object().shape({
  serverId: yup.object().shape({
    value: yup.string().required('Server Id is required'),
    label: yup.string(),
  }),
  userId: yup.array().of(yup.string()).required('User Id is required').min(1),
  system: yup.object().shape({
    value: yup.string().required('Code status is required'),
    label: yup.string(),
  }),
  codeStatus: yup.object().shape({
    value: yup.string().required('Code status is required'),
    label: yup.string(),
  }),
  demoStatus: yup.object().shape({
    value: yup.string().required('Demo status is required'),
    label: yup.string(),
  }),
  fieldStatus: yup.object().shape({
    value: yup.string().required('Field status is required'),
    label: yup.string(),
  }),
});
