import { useIsMutating } from 'react-query';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import { ChangeRoleComponent } from './ChangeRoleComponent';

const ModalContentStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 450,
  bgcolor: 'background.paper',
  borderRadius: 1,
  px: 3,
  py: 2,
};

const closeIconStyle = { color: '#98A2AE', cursor: 'pointer' };

export type ModalDeleteState = {
  title?: string;
  message: string;
  open: boolean;
  isError?: boolean;
  key?: string | number;
  errorMessage?: string;
};

export interface ModalDeleteProps {
  visible?: boolean;
  onClose?: () => void;
  onCancel?: () => void;
  onApprove?: () => void;
  title?: string;
}

export function ModalChangeRole(props: ModalDeleteProps) {
  const isMutating = Boolean(useIsMutating());
  const { onClose, visible = false, title = 'Change Role' } = props;

  return (
    <div>
      <Modal open={visible} onClose={onClose}>
        <Stack direction='column' sx={ModalContentStyle}>
          <Stack justifyContent='space-between' direction='row' alignItems='center'>
            <Typography variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
              {title}
            </Typography>
            <IconButton onClick={onClose} component='label' disabled={isMutating}>
              <HighlightOffRoundedIcon sx={closeIconStyle} />
            </IconButton>
          </Stack>
          <Divider sx={{ mb: 1 }} />
          <Stack sx={{ width: '100%' }}>
            <ChangeRoleComponent title={null} onSelectRole={onClose} />
          </Stack>
        </Stack>
      </Modal>
    </div>
  );
}
