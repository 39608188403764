// @ts-nocheck

import { Data, ResponseCubeDataEntry } from 'types/api/Tenant/AROCube/CubeDataEntryTypes';
interface InputData {
  id: string;
  attribute: string;
  Value: number;
  rowid: string;
  path: string[];
}

interface OutputData {
  attributeId: string;
  value: number;
}

export const jsonToGridDataSpecialEntry = (json?: ResponseCubeDataEntry) => {
  if (!json) return [];
  const rows = [];
  json.data.forEach((data) => {
    const attr = json.attributes.find((a) => a.name === data.name);
    if (attr) {
      const rowData = {
        Value: data.value,
        attribute: attr.displayName,
        id: attr.id,
        path: [attr.displayName],
        rowid: `${attr.name}#Value#${data.id}`,
      };
      rows.push(rowData);
    }
  });
  return rows;
};

export const jsonToGridData = (
  json?: {
    attributes: CubeDataEntryAttribute[];
    data: Data;
  },
  withCustomSorting: boolean
) => {
  if (!json) return [];
  // @ts-ignore
  const rows = [];
  const attributeMap = new Map(json.attributes.map((attr) => [attr.id, attr]));

  json.attributes.forEach((attr) => {
    const rowData = {
      id: attr.id,
      attribute: attr.displayName,
    };
    Object.entries(json.data).forEach(([key, value]) => {
      // @ts-ignore
      rowData[key] = value[attr.name];
      // @ts-ignore
      rowData['rowid'] = `${attr.name}#${key}#${value.id}`;
      // @ts-ignore
      rowData['path'] = generateTreeDataPath(attr, attributeMap);
    });
    rows.push(rowData);
  });

  if (!withCustomSorting) return rows;

  // sorting the rows array based on the path
  return rows.sort((a, b) => {
    let i = 0;
    while (true) {
      if (!a.path[i] && !b.path[i]) {
        return 0;
      }
      if (!a.path[i]) {
        return -1;
      }
      if (!b.path[i]) {
        return 1;
      }
      const comparison = a.path[i].localeCompare(b.path[i]);
      if (comparison !== 0) {
        return comparison;
      }
      i++;
    }
  });
};

function generateTreeDataPath(attribute, attributeMap) {
  const path = [attribute.displayName];
  let currentAttribute = attribute;

  while (currentAttribute.parent) {
    const parent = attributeMap.get(currentAttribute.parent);
    if (!parent) break;
    path.unshift(parent.displayName);
    currentAttribute = parent;
  }

  return path;
}

export function transformDataSpecialEntry(inputData: InputData[]): OutputData[] {
  return inputData.map(({ id, Value, rowid }) => {
    let recordId = rowid?.split('#')?.[2] ?? null;
    if (recordId === 'null') recordId = null;
    return {
      attributeId: id,
      id: recordId,
      value: Value,
    };
  });
}

export function transformData(rawData, initialReferenceData) {
  const result = {};

  rawData.forEach((item) => {
    const attributeKey = item.rowid.split('#')[0];
    for (const key in item) {
      const pattern = /^(\d{2}-\d{2}-\d{2}|[A-Z][a-z]{2}-\d{2}|[A-Z][a-z]{2}-[A-Z][a-z]{2} \d{4}|FY-\d{4}\/\d{2})$/;
      if (key.match(pattern)) {
        if (!result[key]) {
          result[key] = {
            id: initialReferenceData[key] ? initialReferenceData[key].id : null,
          };
        }
        if (result[key]) {
          result[key][attributeKey] = item[key];
        }
      }
    }
  });

  console.log({ resultTransform: result, initialReferenceData, rawData });

  return result;
}
function isCellValueChanged(rowId, field, rowData, initialData) {
  const initialRow = initialData.find((row) => row.rowid === rowId);
  if (!initialRow) return false;
  return rowData[field] !== initialRow[field];
}

export function getCellClassName(params, initialData) {
  const { row, field } = params;
  let className = '';

  if (isCellValueChanged(row.rowid, field, row, initialData)) {
    className += ' cube-entry-cell-changed';
  }

  return className;
}
