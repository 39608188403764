import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import InsightsOutlinedIcon from '@mui/icons-material/InsightsOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useGetCubeInsightViewDropdown } from 'services/v1/Tenant/AROCube/CubeInsightViewService';
import { AutoCompleteItem } from 'types/api/Common/AutoCompleteTypes';
import { useSearchParams } from 'react-router-dom';

interface InsightViewOptionsProps {
  onOptionClick: (option: AutoCompleteItem) => void;
  disabled?: boolean;
  cubeId: string;
}

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    fontSize: 14,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      fontSize: 14,
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

export function InsightMenuOptions(props: InsightViewOptionsProps) {
  const { onOptionClick, disabled, cubeId } = props;
  const { data: insighViewDropdown } = useGetCubeInsightViewDropdown(cubeId);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const [searchParams] = useSearchParams();
  const initialInsightId = searchParams.get('insightId');

  const handleOnClickOptions = (option: AutoCompleteItem) => {
    onOptionClick(option);
    handleClose();
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    const initialInsight = insighViewDropdown?.data?.find((item) => item.value === initialInsightId);
    if (initialInsight) {
      onOptionClick(initialInsight);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialInsightId, insighViewDropdown?.data]);

  const dropdownOptions = React.useMemo(() => {
    if (insighViewDropdown?.data) {
      return insighViewDropdown.data;
    }
    return [];
  }, [insighViewDropdown?.data]);

  if (dropdownOptions.length === 0) return null;

  return (
    <div>
      <Button
        variant='secondary-table-panel'
        disableElevation
        disabled={disabled}
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        {disabled ? 'Processing Insight...' : 'Insight'}
      </Button>
      <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {dropdownOptions.map((option) => (
          <MenuItem onClick={() => handleOnClickOptions(option)} disableRipple key={option.value}>
            <InsightsOutlinedIcon />
            {option.label}
          </MenuItem>
        ))}
      </StyledMenu>
    </div>
  );
}
