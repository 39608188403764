import qs from 'query-string';
import _ from 'lodash';
import { useState, useMemo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import {
  GridRowModesModel,
  GridRowModes,
  GridRowParams,
  GridFilterModel,
  useGridApiRef,
  GridSelectionModel,
  GridRowId,
  GridActionsCellItem,
  GridColumns,
  GridEnrichedColDef,
} from '@mui/x-data-grid-pro';
import { useSubjectUsedDropdown } from 'services/v1/SystemTenant/AROKMS/SubjectService';
import { PaginationAndSortingParams } from 'types/api/Common/PaginationTypes';
import { CustomDataTable } from 'components/DatatableComponent';
import { MainLayoutComponent } from 'components/LayoutComponent/SidebarLayout/MainLayoutComponent';
import { SettingPanel } from 'pages/SystemTenant/AROKMS/KTree/components/KTreeTablePanel';
import ModalDeleteComponent, { ModalDeleteState } from 'components/ModalComponent/ModalDeleteComponent';
import { AutoCompleteItem } from 'types/api/Common/AutoCompleteTypes';
import { renderSelectEditInputCellKtreeDefinition } from 'pages/SystemTenant/AROKMS/KTree/components/SelectEditInputCellKTreeDefinition';
import {
  useCheckKTreeValidationEditStatus,
  useCreateKTreeDefinition,
  useDeleteKTreeDefinition,
  useGetKtreeDefinitionList,
  useUpdateKTreeDefinition,
} from 'services/v1/SystemTenant/AROKMS/KTreeBuilderService';
import {
  KTreeDefinitionEditValidationResponse,
  KTreeDefinitionItem,
  KTreeDefinitionRowItem,
} from 'types/api/SystemTenant/KTreeBuilderTypes';
import { OptionItem } from 'pages/SystemTenant/AROKMS/TableDefinition/components/TabelDefinitionListPanel';
import { normalizeRowWithDropdownValue } from 'utils/String';
import { getErrorMessage } from 'utils/Error';
import { DASHBOARD_VIEW_MODE } from 'constant/DashboardTypeConstant';
import { SUBJECT_TYPE } from 'constant/DataInputConstant';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import ModalInfoComponent from 'components/ModalComponent/ModalInfoComponent';
import { DELETE_EDIT_VALIDATION_STATUS } from 'constant/DeleteEditValidationStatus';
function validateKTreeDefinitionRowItem(item: KTreeDefinitionRowItem): boolean {
  return (
    !!item.ktreeName &&
    !!item.topMember &&
    (item.level0 !== undefined ||
      item.level1 !== undefined ||
      item.level2 !== undefined ||
      item.level3 !== undefined ||
      item.level4 !== undefined ||
      item.level5 !== undefined)
  );
}

interface MemberListFilter extends PaginationAndSortingParams {
  subjectId?: string | number;
}

const panelStyle = {
  transform: 'translate3d(704px, 140.667px, 0px) !important',

  '& .MuiDataGrid-panelFooter': {
    display: 'none',
  },
};

interface EditValidationStatusModalState extends KTreeDefinitionEditValidationResponse {
  open: boolean;
}

export default function KTreeListPage() {
  const { t } = useTranslation();
  const { dashboardViewMode } = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();
  const location = useLocation();
  const { sid = null } = qs.parse(location.search);
  const apiRef = useGridApiRef();
  const [muiDataTableKey] = useState<number>(0);
  const [editValidationStatusModal, setEditValidationStatusModal] = useState<EditValidationStatusModalState | null>(
    null
  );
  const [rowData, setRowData] = useState<KTreeDefinitionItem[]>([]);
  const [selectedRowId, setSelectedRowId] = useState<GridRowId[]>([]);
  const [initialOption, setInitialOption] = useState<OptionItem | null>(null);
  const [modalDeleteState, setModalDeleteState] = useState<ModalDeleteState>({ open: false, message: '', key: 0 });
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [filterButtonElement, setFilterButtonElement] = useState<HTMLButtonElement | null>(null);
  const [selectedSubjectId, setSelectedSubjectId] = useState<AutoCompleteItem>({ label: '', value: '' });
  const [filter, setFilter] = useState<MemberListFilter>({
    page: 1,
    size: 10,
    filterValue: '',
    sortType: 'asc',
    filterOperator: 'contains',
  });

  // Service call
  const { data: subjectDropdownData, isLoading: isDropdownLoading } = useSubjectUsedDropdown({
    enabled: true,
    subjectType: SUBJECT_TYPE.TABLE,
  });
  const { isLoading, data: ktreeListData } = useGetKtreeDefinitionList(selectedSubjectId.value);
  const { mutate: createKTreeDefinition } = useCreateKTreeDefinition();
  const { mutate: checkDataStatus } = useCheckKTreeValidationEditStatus();
  const { mutate: updateKTreeDefiniton } = useUpdateKTreeDefinition();
  const {
    mutateAsync: deleteKTreeDefinition,
    isError: isErrorDelete,
    error: errorDelete,
    reset: resetStateDelete,
  } = useDeleteKTreeDefinition();

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleEditClick = (id: GridRowId) => () => {
    checkDataStatus(
      { kTreeDefinitionId: id as string },
      {
        onSuccess: (data) => {
          if (data.data.status === DELETE_EDIT_VALIDATION_STATUS.ELIGIBLE) {
            setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
            return;
          }
          setEditValidationStatusModal({
            ...data.data,
            open: true,
          });
        },
      }
    );
  };

  const isAnyEdit = useMemo(() => {
    return rowModesModel && Object.values(rowModesModel).some((item) => item.mode === GridRowModes.Edit);
  }, [rowModesModel]);

  const isBuilderViewOnly = dashboardViewMode === DASHBOARD_VIEW_MODE.VIEW_ONLY;

  const columns = useMemo<GridColumns>(() => {
    const actionColumn: GridEnrichedColDef = {
      field: 'actions',
      type: 'actions',
      headerName: 'Edit',
      width: 30,
      cellClassName: 'actions',

      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          // @ts-ignore
          return [<GridActionsCellItem icon={<SaveIcon />} label='Save' onClick={handleSaveClick(id)} />];
        }

        return [
          // @ts-ignore
          <GridActionsCellItem disabled={isAnyEdit} icon={<EditIcon />} label='Edit' onClick={handleEditClick(id)} />,
        ];
      },
    };
    const ktreeColumns: GridColumns = [
      {
        field: 'ktreeName',
        headerName: 'KTree Name',
        width: 180,
        editable: true,
        filterable: true,
        sortable: true,
      },
      {
        field: 'topMember',
        headerName: 'Top Member',
        width: 180,
        editable: true,
        filterable: true,
        sortable: true,
      },
      {
        field: 'level0',
        headerName: 'Level 0',
        width: 180,
        editable: true,
        filterable: false,
        sortable: false,
        renderCell: (params) => {
          return <span>{params?.value?.label}</span>;
        },
        valueGetter: (params) => {
          return ktreeListData?.data?.dropdownOptions?.levelsDropdown?.find(
            (item) => item.persistentName === params?.row?.level0
          );
        },
        renderEditCell: (params) =>
          renderSelectEditInputCellKtreeDefinition({
            ...params,
            // @ts-ignore
            optionsSource: ktreeListData?.data.dropdownOptions.levelsDropdown,
          }),
      },
      {
        field: 'level1',
        headerName: 'Level 1',
        width: 180,
        editable: true,
        filterable: false,
        sortable: false,
        renderCell: (params) => {
          return <span>{params?.value?.label}</span>;
        },
        valueGetter: (params) => {
          return ktreeListData?.data?.dropdownOptions?.levelsDropdown?.find(
            (item) => item.persistentName === params?.row?.level1
          );
        },
        renderEditCell: (params) =>
          renderSelectEditInputCellKtreeDefinition({
            ...params,
            // @ts-ignore
            optionsSource: ktreeListData?.data.dropdownOptions.levelsDropdown,
          }),
      },
      {
        field: 'level2',
        headerName: 'Level 2',
        width: 180,
        editable: true,
        filterable: false,
        sortable: false,
        renderCell: (params) => {
          return <span>{params?.value?.label}</span>;
        },
        valueGetter: (params) => {
          console.log('params', params);
          return ktreeListData?.data?.dropdownOptions?.levelsDropdown?.find(
            (item) => item.label === params?.row?.level2
          );
        },
        renderEditCell: (params) =>
          renderSelectEditInputCellKtreeDefinition({
            ...params,
            // @ts-ignore
            optionsSource: ktreeListData?.data.dropdownOptions.levelsDropdown,
          }),
      },
      {
        field: 'level3',
        headerName: 'Level 3',
        width: 180,
        editable: true,
        filterable: false,
        sortable: false,
        renderCell: (params) => {
          return <span>{params?.value?.label}</span>;
        },
        valueGetter: (params) => {
          return ktreeListData?.data?.dropdownOptions?.levelsDropdown?.find(
            (item) => item.label === params?.row?.level3
          );
        },
        renderEditCell: (params) =>
          renderSelectEditInputCellKtreeDefinition({
            ...params,
            // @ts-ignore
            optionsSource: ktreeListData?.data.dropdownOptions.levelsDropdown,
          }),
      },
      {
        field: 'level4',
        headerName: 'Level 4',
        width: 180,
        editable: true,
        filterable: false,
        sortable: false,
        renderCell: (params) => {
          return <span>{params?.value?.label}</span>;
        },
        valueGetter: (params) => {
          return ktreeListData?.data?.dropdownOptions?.levelsDropdown?.find(
            (item) => item.label === params?.row?.level4
          );
        },
        renderEditCell: (params) =>
          renderSelectEditInputCellKtreeDefinition({
            ...params,
            // @ts-ignore
            optionsSource: ktreeListData?.data.dropdownOptions.levelsDropdown,
          }),
      },
      {
        field: 'level5',
        headerName: 'Level 5',
        width: 180,
        editable: true,
        filterable: false,
        sortable: false,
        renderCell: (params) => {
          return <span>{params?.value?.label}</span>;
        },
        valueGetter: (params) => {
          return ktreeListData?.data?.dropdownOptions?.levelsDropdown?.find(
            (item) => item.label === params?.row?.level5
          );
        },
        renderEditCell: (params) =>
          renderSelectEditInputCellKtreeDefinition({
            ...params,
            // @ts-ignore
            optionsSource: ktreeListData?.data.dropdownOptions.levelsDropdown,
          }),
      },
      {
        field: 'level6',
        headerName: 'Level 6',
        width: 180,
        editable: true,
        filterable: false,
        sortable: false,
        renderCell: (params) => {
          return <span>{params?.value?.label}</span>;
        },
        valueGetter: (params) => {
          return ktreeListData?.data?.dropdownOptions?.levelsDropdown?.find(
            (item) => item.label === params?.row?.level5
          );
        },
        renderEditCell: (params) =>
          renderSelectEditInputCellKtreeDefinition({
            ...params,
            // @ts-ignore
            optionsSource: ktreeListData?.data.dropdownOptions.levelsDropdown,
          }),
      },
      {
        field: 'level7',
        headerName: 'Level 7',
        width: 180,
        editable: true,
        filterable: false,
        sortable: false,
        renderCell: (params) => {
          return <span>{params?.value?.label}</span>;
        },
        valueGetter: (params) => {
          return ktreeListData?.data?.dropdownOptions?.levelsDropdown?.find(
            (item) => item.label === params?.row?.level5
          );
        },
        renderEditCell: (params) =>
          renderSelectEditInputCellKtreeDefinition({
            ...params,
            // @ts-ignore
            optionsSource: ktreeListData?.data.dropdownOptions.levelsDropdown,
          }),
      },
      {
        field: 'level8',
        headerName: 'Level 8',
        width: 180,
        editable: true,
        filterable: false,
        sortable: false,
        renderCell: (params) => {
          return <span>{params?.value?.label}</span>;
        },
        valueGetter: (params) => {
          return ktreeListData?.data?.dropdownOptions?.levelsDropdown?.find(
            (item) => item.label === params?.row?.level5
          );
        },
        renderEditCell: (params) =>
          renderSelectEditInputCellKtreeDefinition({
            ...params,
            // @ts-ignore
            optionsSource: ktreeListData?.data.dropdownOptions.levelsDropdown,
          }),
      },
      {
        field: 'level9',
        headerName: 'Level 9',
        width: 180,
        editable: true,
        filterable: false,
        sortable: false,
        renderCell: (params) => {
          return <span>{params?.value?.label}</span>;
        },
        valueGetter: (params) => {
          return ktreeListData?.data?.dropdownOptions?.levelsDropdown?.find(
            (item) => item.label === params?.row?.level5
          );
        },
        renderEditCell: (params) =>
          renderSelectEditInputCellKtreeDefinition({
            ...params,
            // @ts-ignore
            optionsSource: ktreeListData?.data.dropdownOptions.levelsDropdown,
          }),
      },
    ];

    if (isBuilderViewOnly) return ktreeColumns;
    return [actionColumn, ...ktreeColumns];

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ktreeListData?.data, rowModesModel, isAnyEdit, isBuilderViewOnly]);

  const subjectDropdownOptions = useMemo(() => {
    if (subjectDropdownData?.data) {
      if (sid) {
        const initialSubject = subjectDropdownData.data.find((item) => item.value === sid);
        if (initialSubject) {
          setInitialOption(initialSubject);
          setSelectedSubjectId(initialSubject);
        } else {
          setSelectedSubjectId(subjectDropdownData?.data[0]);
        }
      } else {
        setSelectedSubjectId(subjectDropdownData?.data[0]);
      }
      return subjectDropdownData.data;
    }
    return [];
  }, [subjectDropdownData, sid]);

  useEffect(() => {
    if (ktreeListData?.data) {
      setRowData(ktreeListData.data.data);
    }
  }, [ktreeListData?.data]);

  // Process row Update will be triggered when
  // user modify or insert new row the table
  const handleProcessRowUpdate = (data: KTreeDefinitionRowItem, oldData: KTreeDefinitionRowItem) => {
    const isValidData = validateKTreeDefinitionRowItem(data);

    if (!isValidData) return;
    // If old row data is equal to new row data, then do nothing
    if (_.isEqual(normalizeRowWithDropdownValue(data, 'label'), oldData)) return oldData;

    if (data?.isNew) {
      createKTreeDefinition({
        ...normalizeRowWithDropdownValue(data, 'value'),
        subjectId: selectedSubjectId.value,
      });
    } else {
      updateKTreeDefiniton({
        ...normalizeRowWithDropdownValue(data, 'value'),
        subjectId: selectedSubjectId.value,
        id: data.id,
      });
    }

    return data;
  };

  const handleChangePage = (newPage: number) => {
    setFilter({ ...filter, page: newPage + 1 });
  };
  const handleChangePageSize = (newPageSize: number) => {
    setFilter({ ...filter, size: newPageSize });
  };

  const handleOnFilter = (params: GridFilterModel) => {
    let filterObj = {};
    if (params.items.length) {
      filterObj = {
        filterValue: params.items[0].value,
        filterOperator: params.items[0].operatorValue,
      };
    } else {
      filterObj = {
        filterValue: '',
        filterOperator: 'contains',
      };
    }
    setFilter({
      ...filter,
      ...filterObj,
    });
  };

  const handleOnDropdownSubjectChange = (option: { value: string; label: string }) => {
    setSelectedSubjectId(option);
    setFilter({ ...filter, filterValue: '' });
    navigate({
      search: `?sid=${option.value}`,
    });
  };

  const handleSelectionModelChange = (newSelection: GridSelectionModel) => {
    setSelectedRowId(newSelection);
  };

  const handleDeleteKTreeDefinition = async () => {
    try {
      if (selectedRowId.length) {
        for (const row of selectedRowId) {
          await deleteKTreeDefinition(row as string);
        }
      }
      setModalDeleteState((prevState) => ({ ...prevState, open: false }));
    } catch (error: any) {
      setModalDeleteState((prevState) => ({ ...prevState, message: 'Failed to delete records' }));
    }
  };

  const handleOnClickDeleteButton = () => {
    if (selectedRowId.length) {
      setModalDeleteState({
        open: true,
        message: `Are you sure you want to delete ${selectedRowId.length} Ktree(s)?`,
      });
    }
  };

  const handleOnRowDoubleClick = useCallback(
    (params: GridRowParams, event: React.MouseEvent) => {
      if (isBuilderViewOnly || isAnyEdit) return;

      const { id } = params;
      handleEditClick(id as string);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setRowModesModel, isBuilderViewOnly, isAnyEdit]
  );

  const handleCloseModalDelete = () => {
    setModalDeleteState({ ...modalDeleteState, open: false, key: modalDeleteState && +modalDeleteState + 1 });
    resetStateDelete();
  };

  return (
    <MainLayoutComponent pageTitle='Ktree (Knowledge Tree)' breadcrumbs={[t('SIDEBAR_MENU.DASHBOARD'), 'Ktree']}>
      <CustomDataTable
        key={muiDataTableKey}
        apiRef={apiRef}
        initialState={{
          pagination: {
            page: 0,
          },
        }}
        loading={isLoading || isDropdownLoading}
        rows={rowData}
        columns={columns}
        rowCount={0}
        page={0}
        pageSize={filter.size}
        getRowId={(row) => row.id}
        rowModesModel={rowModesModel}
        disableSelectionOnClick
        rowsPerPageOptions={[5, 10, 20]}
        getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
        experimentalFeatures={{ newEditingApi: true }}
        onFilterModelChange={handleOnFilter}
        onSelectionModelChange={handleSelectionModelChange}
        processRowUpdate={handleProcessRowUpdate}
        onRowDoubleClick={handleOnRowDoubleClick}
        onPageChange={handleChangePage}
        onPageSizeChange={handleChangePageSize}
        autoHeight={rowData.length !== 0 && rowData.length > 5}
        components={{
          Toolbar: SettingPanel,
        }}
        componentsProps={{
          panel: {
            sx: panelStyle,
            anchorEl: filterButtonElement,
          },
          toolbar: {
            setRows: setRowData,
            setRowModesModel,
            setFilterButtonElement: setFilterButtonElement,
            onDeleteData: handleOnClickDeleteButton,
            options: subjectDropdownOptions,
            initialOption,
            isEditing: rowData.some((item) => item.isNew),
            optionValue: selectedSubjectId,
            disabled: isLoading || isBuilderViewOnly,
            onOptionChange: handleOnDropdownSubjectChange,
            config: {
              insertButtonText: 'Add New KTree',
              fieldFocusOnInsert: 'ktreeName',
              insertInitialObject: {
                topMember: '',
                level1: '',
                level2: '',
                level3: '',
                level4: '',
                level5: '',
                level6: '',
                level7: '',
                level8: '',
                level9: '',
                kteeName: '',
                isNew: true,
              },
            },
          },
        }}
      />

      <ModalDeleteComponent
        visible={modalDeleteState.open}
        message={modalDeleteState.message}
        key={modalDeleteState.key}
        onApprove={handleDeleteKTreeDefinition}
        isError={isErrorDelete}
        errorMessage={getErrorMessage(errorDelete as any)}
        onClose={handleCloseModalDelete}
        onCancel={handleCloseModalDelete}
      />
      <ModalInfoComponent
        visible={editValidationStatusModal?.open}
        title='KTree Validation Status'
        onClose={() => setEditValidationStatusModal(null)}
        descriptionMessage={editValidationStatusModal?.message}
        data={editValidationStatusModal?.data}
      />
    </MainLayoutComponent>
  );
}
