import { AxiosResponse } from 'axios';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import axios from 'libs/Axios';

import { AxiosDefaultErrorEntity } from 'types/api/Common/ErrorTypes';
import { PaginationAndSortingParams } from 'types/api/Common/PaginationTypes';
import {
  EventDataChannel,
  EventDataSourceMappingDetails,
  LatestSyncronizeEventDataSourceStatus,
  RepsonseListSynchronizeEventDataSourceHistory,
  RequestMappingEventExternalDataSource,
  ResponseEventDataSourceColumnMappingList,
  ResponseEventDataSourcePreview,
} from 'types/api/Tenant/EventDataSourceTypes';
import { AutoCompleteItem } from 'types/api/Common/AutoCompleteTypes';
import { DISPLAY_TABLE_QUERY_KEY } from '../AROKMS/DisplayTableService';

export interface DisplayTableParams extends PaginationAndSortingParams {
  subjectId?: string | number;
}

export const EVENT_DATA_SOURCE_QUERY_KEY = {
  LIST_COLUMNS: 'EVENT_DATA_SOURCE_LIST_COLUMNS',
  DATA_SOURCE_PREVIEW: 'EVENT_DATA_SOURCE_DATA_SOURCE_PREVIEW',
  DATA_SOURCE_TABLE_LIST: 'EVENT_DATA_SOURCE_DATA_SOURCE_TABLE_LIST',
  EVENT_SYNC_HISTORY: 'EVENT_DATA_SOURCE_EVENT_SYNC_HISTORY',
  EVENT_SYNC_HISTORY_LATEST: 'EVENT_DATA_SOURCE_EVENT_SYNC_HISTORY_LATEST',
  SYNC_FAILED_RECORD: 'EVENT_DATA_SOURCE_SYNC_FAILED_RECORD',
  COLUMN_MAPPING_DETAILS: 'EVENT_DATA_SOURCE_COLUMN_MAPPING_DETAILS',
  LIST_DATA_CHANNEL: 'EVENT_DATA_SOURCE_LIST_DATA_CHANNEL',
};

export function useGetEventDataChannelListPairingColumns(eventSubjectId?: string, dataChannelId?: string) {
  return useQuery<AxiosResponse<ResponseEventDataSourceColumnMappingList>, AxiosDefaultErrorEntity>(
    [
      EVENT_DATA_SOURCE_QUERY_KEY.LIST_COLUMNS,
      {
        subjectId: eventSubjectId,
        dataChannelId,
      },
    ],
    () => {
      return axios
        .get(`/api/v1/event-data-source/column-mapping/${eventSubjectId}/${dataChannelId}`)
        .then((res) => res);
    },
    {
      enabled: !!eventSubjectId && !!dataChannelId,
    }
  );
}

export function useGetDataChannelPreview(channelId?: string) {
  return useQuery<AxiosResponse<ResponseEventDataSourcePreview>, AxiosDefaultErrorEntity>(
    [
      EVENT_DATA_SOURCE_QUERY_KEY.LIST_COLUMNS,
      {
        sourceTableName: channelId,
      },
    ],
    () => {
      return axios.get(`/api/v1/event-data-source/preview-data-channel/${channelId}`).then((res) => res);
    },
    {
      enabled: !!channelId,
    }
  );
}

// @DEPRECATED
// TOBE REMOVED
export function useGetEventDataSourceTableList() {
  return useQuery<AxiosResponse<AutoCompleteItem[]>, AxiosDefaultErrorEntity>(
    [EVENT_DATA_SOURCE_QUERY_KEY.DATA_SOURCE_TABLE_LIST],
    () => {
      return axios.get(`/api/v1/event-data-source/source-table/list`).then((res) => res);
    }
  );
}

export function useRetryFailedRecords() {
  const queryClient = useQueryClient();
  return useMutation<
    AxiosResponse,
    AxiosDefaultErrorEntity,
    {
      syncHistoryId: string;
    }
  >((bodyRequest) => axios.get(`/api/v1/event-data-source/retry-sync/${bodyRequest.syncHistoryId}`), {
    onSuccess: () => {
      setTimeout(() => {
        queryClient.resetQueries([EVENT_DATA_SOURCE_QUERY_KEY.EVENT_SYNC_HISTORY_LATEST]);
        queryClient.removeQueries(EVENT_DATA_SOURCE_QUERY_KEY.EVENT_SYNC_HISTORY);
      }, 2500);
    },
  });
}

export function useGetEventDataSourceConfigDetails(eventSubjectId?: string) {
  return useQuery<AxiosResponse<EventDataSourceMappingDetails>, AxiosDefaultErrorEntity>(
    [EVENT_DATA_SOURCE_QUERY_KEY.COLUMN_MAPPING_DETAILS, eventSubjectId],
    () => {
      return axios.get(`/api/v1/event-data-source/column-mapping/${eventSubjectId}`).then((res) => res);
    },
    {
      enabled: !!eventSubjectId,
    }
  );
}

export function useEventDataSourceDisconnect() {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosDefaultErrorEntity, { eventSubjectId: string }>(
    (bodyRequest) => axios.post(`/api/v1/event-data-source/disconnect/${bodyRequest.eventSubjectId}`),
    {
      onSuccess: () => {
        queryClient.resetQueries([EVENT_DATA_SOURCE_QUERY_KEY.EVENT_SYNC_HISTORY_LATEST]);
      },
    }
  );
}

export function useInsertEventDataSourceMapping() {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosDefaultErrorEntity, RequestMappingEventExternalDataSource>(
    (bodyRequest) => axios.post(`/api/v1/event-data-source/column-mapping/${bodyRequest.eventSubjectId}`, bodyRequest),
    {
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(EVENT_DATA_SOURCE_QUERY_KEY.EVENT_SYNC_HISTORY_LATEST);
        queryClient.refetchQueries([
          DISPLAY_TABLE_QUERY_KEY.DATA_INPUT_CURRENT_IMPORT_ACTIVITY,
          variables.eventSubjectId,
        ]);
        queryClient.refetchQueries([DISPLAY_TABLE_QUERY_KEY.DATA_INPUT_IMPORT_HISTORY, variables.eventSubjectId]);
      },
    }
  );
}

export function useGetEventSynchronizationHistory(params: PaginationAndSortingParams, eventSubjectId?: string) {
  return useQuery<AxiosResponse<RepsonseListSynchronizeEventDataSourceHistory>, AxiosDefaultErrorEntity>(
    [EVENT_DATA_SOURCE_QUERY_KEY.EVENT_SYNC_HISTORY, eventSubjectId, params],
    () => {
      return axios.get(`/api/v1/event-data-source/sync-history/${eventSubjectId}`).then((res) => res);
    },
    {
      enabled: !!eventSubjectId,
    }
  );
}

export function useGetLastSyncHistory(eventSubjectId?: string, refetchInterval?: number) {
  return useQuery<AxiosResponse<LatestSyncronizeEventDataSourceStatus>, AxiosDefaultErrorEntity>(
    [EVENT_DATA_SOURCE_QUERY_KEY.EVENT_SYNC_HISTORY_LATEST, eventSubjectId],
    () => {
      return axios.get(`/api/v1/event-data-source/sync-history/latest/${eventSubjectId}`).then((res) => res);
    },
    {
      enabled: !!eventSubjectId,
      refetchInterval,
    }
  );
}

export function useGetFailedSyncRecord(syncHistoryId?: string) {
  return useQuery<
    AxiosResponse<{
      failedRecords: string;
      isRetrySyncAvailable: boolean;
    }>,
    AxiosDefaultErrorEntity
  >(
    [EVENT_DATA_SOURCE_QUERY_KEY.SYNC_FAILED_RECORD, syncHistoryId],
    () => {
      return axios.get(`/api/v1/event-data-source/sync-history/failed-records/${syncHistoryId}`).then((res) => res);
    },
    {
      enabled: !!syncHistoryId,
    }
  );
}

export function useGetListEventDataChannel() {
  return useQuery<AxiosResponse<EventDataChannel[]>, AxiosDefaultErrorEntity>(
    [EVENT_DATA_SOURCE_QUERY_KEY.LIST_DATA_CHANNEL],
    () => {
      return axios.get(`/api/v1/event-data-source/channel/list`).then((res) => res);
    }
  );
}
