//@ts-nocheck
import * as React from 'react';
import { GridRenderCellParams, GridColDef, useGridApiContext } from '@mui/x-data-grid';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { KTreeNode } from 'types/api/Tenant/AROKMS/KTreeDataTypes';
import { flattenTreeData } from 'utils/Object';

const autocompleteStyles = {
  width: '95%',
  '& .MuiOutlinedInput-root': {
    padding: '1px 2px',
    '& .MuiAutocomplete-input': {
      padding: '17px 4px 17px 6px',
    },
  },
  '& .MuiAutocomplete-popper': {
    fontSize: '10px',
  },
};

const textInputStyles = {
  '& .MuiOutlinedInput': {
    padding: '1px 2px',
  },
};

function DataGridSelectInputEditCellComponent(
  props: GridRenderCellParams & {
    optionsSource?: KTreeNode[] | KTreeNode;
    onChange?: (event: React.SyntheticEvent, value: { label: string; value: string }) => void;
    isRequired?: boolean;
  }
) {
  const { id, value, field, optionsSource } = props;

  const errorMessage = !props.value && props.isRequired ? 'This field is required' : null;

  const apiRef = useGridApiContext();

  const handleChange = async (event: React.SyntheticEvent, value: { label: string; value: string }) => {
    if (value) {
      if (props.onChange) {
        props.onChange(event, value);
        return;
      }

      await apiRef.current.setEditCellValue({ id, field, value: value });
      apiRef.current.stopCellEditMode({ id, field });
    }
  };

  const optionsSourceData = Array.isArray(optionsSource)
    ? optionsSource
    : optionsSource.children
    ? [optionsSource]
    : [];
  let options = flattenTreeData(optionsSourceData);

  const lowestLevel = options.reduce((acc, item) => {
    if (item.level > acc) {
      return item.level;
    } else {
      return acc;
    }
  }, 0);

  return (
    <Autocomplete
      options={options}
      getOptionLabel={(option) => option.label}
      onChange={handleChange}
      sx={autocompleteStyles}
      value={value || null}
      renderOption={(props, option, state) => {
        if (option.level === lowestLevel) {
          return (
            <li
              {...props}
              style={{
                marginLeft: `${option.level * 5}px`,
                fontSize: '14px',
              }}
            >
              {option.label}
            </li>
          );
        }
        return (
          <li
            {...props}
            style={{
              fontWeight: 'bold',
              marginLeft: `${option.level * 4}px`,
              borderBottom: '1px solid #e3e3e3',
              fontSize: '14px',
            }}
          >
            {option.label}
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField {...params} sx={textInputStyles} error={!!errorMessage} helperText={errorMessage} />
      )}
    />
  );
}

type RenderSelectEditInputCellParams = GridColDef['renderCell'] & {
  optionsSource?: { label: string; value: string }[];
  onChange?: (event: React.SyntheticEvent, value: { label: string; value: string }) => void;
  isRequired?: boolean;
};

const MemoizedSelectInputCell = React.memo(DataGridSelectInputEditCellComponent);
export const renderSelectTreeComponent: RenderSelectEditInputCellParams = (params) => {
  return <MemoizedSelectInputCell {...params} key={params.id} />;
};
