import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TenantConfiguration } from 'types/api/Tenant/ConfigurationTypes';

export interface SettingState {
  selectedLanguage: string;
  config: TenantConfiguration[];
  setupStatus: string;
}

const initialState: SettingState = {
  selectedLanguage: 'en',
  config: [],
  setupStatus: '',
};

export const settingSlice = createSlice({
  name: '@SETTING',
  initialState,
  reducers: {
    setSelectedLanguage: (state, action: PayloadAction<string>) => {
      state.selectedLanguage = action.payload;
    },
    setConfig: (state, action: PayloadAction<TenantConfiguration[]>) => {
      state.config = action.payload;
    },
    setSetupStatus: (state, action: PayloadAction<string>) => {
      state.setupStatus = action.payload;
    },
  },
});

export const { setSelectedLanguage, setConfig, setSetupStatus } = settingSlice.actions;

export default settingSlice.reducer;
