import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import AutoComplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import { AutoCompleteItem } from 'types/api/Common/AutoCompleteTypes';
import { FormCubeDefinitionMetaDataValues } from './CubeDefinitionSettingPanel';
import { Alert } from '@mui/material';
import { FormikProps } from 'formik';
import { useGetCubeDefinitionByLayerIdDropdown } from 'services/v1/SystemTenant/AROCube/CubeLayerService';
import { CubeDefinitionRowItem } from 'types/api/SystemTenant/AROCube/CubeBuilderTypes';

const autoCompleteStyle = {
  width: 260,
  '& .MuiAutocomplete-popper': {
    backgroundColor: 'red !imoprtant',
    fontSize: '10px',
  },
};

export type FormikPullFromCubeToMultipleLayerParameterItem = {
  id?: string;
  targetPullCube: AutoCompleteItem | null;
  targetPullCubeLayer: AutoCompleteItem | null;
  destinationCubeLayer: AutoCompleteItem | null;
};

type FormikPullFromCubeToMultipleLayerParameter = {
  cubeComponentDefinitions: FormikPullFromCubeToMultipleLayerParameterItem[];
};

type DefineSourceCubeComponentProps = {
  item: AutoCompleteItem;
  cubeMetaData: FormCubeDefinitionMetaDataValues;
  formik: FormikProps<FormikPullFromCubeToMultipleLayerParameter>;
  index: number;
  attributeId?: string;
};

export type PullMultipleLayerParameterPayload = {
  rowItem: CubeDefinitionRowItem;
  cubeComponentDefinitions: FormikPullFromCubeToMultipleLayerParameterItem[];
};

const renderOption = (props: React.HTMLAttributes<HTMLLIElement>, option: AutoCompleteItem) => (
  <Box component='li' sx={{ '& > span': { fontSize: '14px', mr: 1, flexShrink: 0 } }} {...props}>
    <span>{option.label}</span>
  </Box>
);

export function DefineSourceCubeFormComponent(props: Readonly<DefineSourceCubeComponentProps>) {
  const { item, cubeMetaData, formik, index, attributeId } = props;

  const componentFormikValue = formik.values.cubeComponentDefinitions[index];
  const { data: sourceCubeOptions, isLoading: isLoadingSourceCubeOptions } = useGetCubeDefinitionByLayerIdDropdown(
    item.value?.toString(),
    cubeMetaData.timePeriod?.value,
    attributeId,
    cubeMetaData.cubeId
  );

  return (
    <Stack key={item.value}>
      <Typography variant='body2' fontWeight='bold' color='black'>
        {`${cubeMetaData.cubeName} - ${item.label}`}
      </Typography>
      <Stack gap={1} justifyContent='space-between' direction='row'>
        <Stack>
          <Typography variant='input-label-gray'>Source Cube:</Typography>
          {(!cubeMetaData.timePeriod || !cubeMetaData.layerSet || !cubeMetaData.cubeName) && (
            <Stack mb={1}>
              <Alert severity='warning'>
                <Typography variant='caption' mb={1}>
                  Please define the cube name,time period, and layer set for the cube before pulling the data.
                </Typography>
              </Alert>
            </Stack>
          )}
          <AutoComplete
            onChange={(event, value) => {
              formik.setFieldValue(`cubeComponentDefinitions.${index}.targetPullCube`, value);
            }}
            clearIcon={null}
            size='small'
            value={componentFormikValue?.targetPullCube}
            onBlur={() => {
              formik.handleBlur(`cubeComponentDefinitions.${index}.targetPullCube`);
              formik.setFieldTouched(`cubeComponentDefinitions.${index}.targetPullCube`, true);
            }}
            getOptionLabel={(option: AutoCompleteItem) => option.label}
            options={sourceCubeOptions?.data ?? []}
            renderOption={renderOption}
            sx={autoCompleteStyle}
            renderInput={(params) => (
              <Stack direction='row' justifyContent='space-between' display='flex' alignItems='center'>
                <TextField
                  {...params}
                  name='targetPullCube'
                  helperText={
                    formik.touched.cubeComponentDefinitions?.[index]?.targetPullCube &&
                    // @ts-ignore
                    formik.errors.cubeComponentDefinitions?.[index]?.targetPullCube
                  }
                  error={
                    formik.touched.cubeComponentDefinitions?.[index]?.targetPullCube &&
                    // @ts-ignore
                    !!formik.errors.cubeComponentDefinitions?.[index]?.targetPullCube
                  }
                  placeholder={isLoadingSourceCubeOptions ? 'Loading...' : 'Please Select Cube'}
                />
              </Stack>
            )}
          />
        </Stack>
      </Stack>
      <Divider sx={{ my: 1 }} />
    </Stack>
  );
}
