// @ts-nocheck
import { FormikProps } from 'formik';
import { Field } from 'types/api/Tenant/AROKMS/DisplayTableTypes';
import { Stack, TextField, Typography, Box, SxProps, AutocompleteValue } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { useUpsertMemberListData } from 'services/v1/Tenant/AROKMS/MemberListService';
import React, { useMemo, useRef, useEffect, useCallback } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { AxiosDefaultErrorEntity } from 'types';
import { getErrorMessage } from 'utils/Error';
import { useGetNextPrimaryKeySuggestion } from 'services/v1/Tenant/AROKMS/DisplayTableService';
import { convertToSnackCase } from 'utils/String';
import { useSearchParams } from 'react-router-dom';
import { useGetSubjectDetails } from 'services/v1/SystemTenant/AROKMS/SubjectService';
import { PRIMARY_KEY_TYPE } from 'constant/DataInputConstant';

interface IProps<T = { [key: string]: string }> {
  formik: FormikProps<T>;
  field: Field;
  sx?: SxProps;
  ruleTypeCode?: string;
  isEdit?: boolean;
  required?: boolean;
}

const autocompleteStyles = {
  width: '55%',
  '& .MuiOutlinedInput-root': {
    padding: '1px 2px',
  },
  '& .MuiAutocomplete-popper': {
    fontSize: '10px',
  },
};

const textInputAutoCompleteStyles = {
  '& .MuiOutlinedInput': {
    padding: '1px 2px',
  },
};

const optionStyles = { '& > span': { fontSize: '14px', mr: 2, flexShrink: 0 } };

export const MEMBER_LIST_SUGGESTION_ID_NEED_TO_BE_INSERTED = 'MEMBER_LIST_SUGGESTION_ID_NEED_TO_BE_INSERTED';

export function DropdownCreatableMemberListAttributeBaseSubject<T extends {}>(props: IProps<T>) {
  const [searchParams] = useSearchParams();
  const subjectId = searchParams.get('subjectId') || null;
  const { data: subjectDetails } = useGetSubjectDetails(subjectId);
  const { formik, field, sx, required, isEdit = false } = props;
  const formFieldContainerRef = useRef<HTMLDivElement>(null);
  const { mutateAsync: upserMemberListAsync, isLoading: isUpsertingMemberList } = useUpsertMemberListData();
  const { mutate: getNextPrimaryKeySuggestion, isLoading: isFetchingSuggestion } = useGetNextPrimaryKeySuggestion();

  const ruleParameter: {
    triggerPrimaryKeyFetchColumn: string | null;
  } = useMemo(() => {
    if (field.ruleTypeParams) {
      return {
        triggerPrimaryKeyFetchColumn: convertToSnackCase(field.ruleTypeParams.split(',')[3]),
      };
    }
    return {
      triggerPrimaryKeyFetchColumn: null,
    };
  }, [field]);

  const handleFetchNextPrimaryKeySuggestion = (triggerValue, primaryKeyType) => {
    if (!subjectDetails?.data?.metaData?.primaryKeyWithSuggestion) return;
    if (!subjectId) return;
    if (primaryKeyType === PRIMARY_KEY_TYPE.DYNAMIC_REFERENCE && !triggerValue) return;
    getNextPrimaryKeySuggestion(
      {
        comparatorId: triggerValue,
        subjectId,
      },
      {
        onSuccess: async (res) => {
          if (res.data.nextPrimaryKey) {
            const optionItem = getOptionItem(res.data.nextPrimaryKey);
            if (optionItem) {
              formik.setFieldValue(field.name, optionItem);
            } else {
              formik.setFieldValue(field.name, {
                label: res.data.nextPrimaryKey,
                value: MEMBER_LIST_SUGGESTION_ID_NEED_TO_BE_INSERTED,
              });
            }
          }
        },
      }
    );
  };

  const handleOnChange = async (event: React.SyntheticEvent, newValue: AutocompleteValue) => {
    try {
      if (newValue?.isNew) {
        formik.setFieldTouched(field.name, true);
        const regexString = field.customRegex || '';
        const regex = new RegExp(regexString);
        if (!regex.test(newValue.value)) {
          formik.setFieldError(field.name, field.customRegexErrorMessage || 'Invalid format');
          return;
        }

        const { data } = await upserMemberListAsync({
          memberList: newValue.value,
          subjectId: field.colSubjectId || 0,
        });

        formik.setFieldValue(field.name, { label: data.memberList, value: data.id });
      } else {
        formik.setFieldValue(field.name, newValue);
      }
    } catch (error) {
      formik.setFieldError(field.name, getErrorMessage(error as AxiosDefaultErrorEntity));
    }
  };

  const getOptionLabel = useCallback(
    (option) => {
      if (isFetchingSuggestion) return 'Loading...';
      // Value selected with enter, right from the input
      if (typeof option === 'string') {
        return option;
      }

      if (option.isNew) {
        return option.value;
      }
      // Regular option
      return option.label || '';
    },
    [isFetchingSuggestion]
  );

  const getFilterOptions = useCallback((options, params) => {
    const filtered = filter(options, params);
    const { inputValue } = params;
    // Suggest the creation of a new value
    const isExisting = options.some((option) => inputValue.toLowerCase() === option.label.toLowerCase());
    if (inputValue !== '' && !isExisting) {
      filtered.push({
        value: inputValue,
        label: `Add "${inputValue}"`,
        isNew: true,
      });
    }

    return filtered;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!ruleParameter.triggerPrimaryKeyFetchColumn) return;
    if (!formik.values[ruleParameter.triggerPrimaryKeyFetchColumn]) return;
    const triggerColumnValue = formik.values[ruleParameter.triggerPrimaryKeyFetchColumn];

    handleFetchNextPrimaryKeySuggestion(triggerColumnValue?.value, PRIMARY_KEY_TYPE.DYNAMIC_REFERENCE);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ruleParameter.triggerPrimaryKeyFetchColumn, formik.values[ruleParameter.triggerPrimaryKeyFetchColumn]]);

  const getOptionItem = useCallback(
    (labelValue) => {
      if (field.options) {
        return field.options.find((item) => item.label === labelValue);
      }
      return null;
    },
    [field.options]
  );

  useEffect(() => {
    if (subjectDetails?.data?.metaData?.primaryKeyType === PRIMARY_KEY_TYPE.STATIC_REFERENCE) {
      handleFetchNextPrimaryKeySuggestion(null, PRIMARY_KEY_TYPE.STATIC_REFERENCE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subjectDetails?.data]);

  const filter = createFilterOptions<{ value: string; label: string; isNew?: boolean }>();

  return (
    <>
      <Stack direction='row' justifyContent='space-between' alignItems='center' marginTop={2.5} gap={1}>
        <Stack width='40%'>
          <Typography variant='input-label' fontWeight={700}>
            {field.label}
            {required && !field.disabled && <span style={{ color: 'red', marginLeft: 2 }}>*</span>}
          </Typography>
          {field.columnEditable === false && !isEdit && (
            <Typography variant='input-label-gray' fontSize={12}>
              Once you've submitted, this field can no longer be changed
            </Typography>
          )}
        </Stack>
        <Autocomplete
          ref={formFieldContainerRef}
          disabled={field.disabled || isFetchingSuggestion || isUpsertingMemberList}
          // @ts-ignore
          value={formik.values[field.name] === '' ? { value: '', label: '' } : formik.values[field.name]}
          onChange={handleOnChange}
          name={field.name || ''}
          onBlur={formik.handleBlur}
          filterOptions={getFilterOptions}
          getOptionLabel={getOptionLabel}
          clearIcon={null}
          contentEditable={false}
          options={field.options || [{ value: '', label: '' }]}
          renderOption={(props, option) => (
            <Box component='li' sx={optionStyles} {...props}>
              <span>{option.label}</span>
            </Box>
          )}
          sx={{ ...autocompleteStyles, ...sx }}
          renderInput={(params) => (
            <TextField
              {...params}
              name={field.name}
              sx={textInputAutoCompleteStyles}
              placeholder={isFetchingSuggestion ? 'Loading...' : field.placeholder}
              // @ts-ignore
              error={!!formik.touched[field.name] && !!formik.errors[field.name] && !isUpsertingMemberList}
              helperText={
                // @ts-ignore
                formik.touched[field.name] && typeof formik.errors[field.name] === 'string' && !isUpsertingMemberList
                  ? // @ts-ignore
                    formik.errors[field.name]
                  : ''
              }
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {isUpsertingMemberList || isFetchingSuggestion ? (
                      <CircularProgress color='inherit' size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      </Stack>
    </>
  );
}
