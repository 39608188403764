import { useEffect, useState, useCallback } from 'react';

export const ProgressTopBarComponent = ({ visible }: { visible: boolean }) => {
  const [display, setDisplay] = useState(visible);
  const [shouldHide, setShouldHide] = useState(false);

  const handleAnimationIteration = useCallback(() => {
    if (shouldHide) {
      setDisplay(false);
      setShouldHide(false);
    }
  }, [shouldHide]);

  useEffect(() => {
    if (visible) {
      setDisplay(true);
      setShouldHide(false);
    } else {
      setShouldHide(true);
    }
  }, [visible]);

  return (
    <div className='progressBar' style={{ display: display ? 'block' : 'none' }}>
      <div className='progressBarInner' onAnimationIteration={handleAnimationIteration}></div>
    </div>
  );
};
