import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem, { MenuItemProps } from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import AddLinkOutlinedIcon from '@mui/icons-material/AddLinkOutlined';
import ManageSearchOutlinedIcon from '@mui/icons-material/ManageSearchOutlined';
import DatasetLinkedOutlinedIcon from '@mui/icons-material/DatasetLinkedOutlined';
import WidgetsOutlinedIcon from '@mui/icons-material/WidgetsOutlined';
import DifferenceOutlinedIcon from '@mui/icons-material/DifferenceOutlined';
import SyncOutlinedIcon from '@mui/icons-material/SyncOutlined';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';

// Custom Icon
import { GridMoreVertIcon } from '@mui/x-data-grid-pro';

export const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      color: '#000',
      fontSize: 13,
      fontWeight: 700,
      '& .MuiSvgIcon-root': {
        fontSize: 16,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },

      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

export const StyledMenuItem = styled((props: MenuItemProps) => <MenuItem disableRipple {...props} />)(({ theme }) => ({
  '&:hover': {
    color: '#42BB93 !important',
    fill: '#42BB93 !important',
    transition: 'all 0.1s ease-in-out',
    '& .MuiSvgIcon-root': {
      color: '#42BB93 !important',
      fill: '#42BB93 !important',
    },
  },
}));

export enum DataInputTableOptions {
  CUSTOM_RULES = 'CUSTOM_RULES',
  IMPORT_DATA_HISTORY = 'IMPORT_DATA_HISTORY',
  FUNCTION_TRIGGER = 'FUNCTION_TRIGGER',
}

export enum EventTableOptions {
  CONNECT_DATA_SOURCE = 'CONNECT_DATA_SOURCE',
  SYNCRONIZATION_HISTORY = 'SYNCRONIZATION_HISTORY',
  MODIFY_DATA_SOURCE_CONNECTION = 'MODIFY_DATA_SOURCE_CONNECTION',
  MANUAL_SYNC_TO_CUBE = 'MANUAL_SYNC_TO_CUBE',
}

const allOptions = [
  {
    label: 'Modify Data Source Connection',
    value: EventTableOptions.MODIFY_DATA_SOURCE_CONNECTION,
    description: 'Modify the connection to the data source',
    icon: <DatasetLinkedOutlinedIcon sx={{ fontSize: '25px !important' }} />,
    visible: true,
  },
  {
    label: 'Connect Data Source',
    value: EventTableOptions.CONNECT_DATA_SOURCE,
    description: 'Integrate this event with a external data source',
    icon: <AddLinkOutlinedIcon sx={{ fontSize: '25px !important' }} />,
    visible: true,
  },
  {
    label: 'Synchronization History',
    value: EventTableOptions.SYNCRONIZATION_HISTORY,
    description: 'View the history of data synchronization',
    icon: <ManageSearchOutlinedIcon sx={{ fontSize: '25px !important' }} />,
    visible: true,
  },
  {
    label: 'Custom Rules',
    value: DataInputTableOptions.CUSTOM_RULES,
    description: 'Create custom rules to this table',
    icon: <WidgetsOutlinedIcon sx={{ fontSize: '25px !important' }} />,
    visible: true,
  },
  {
    label: 'Import Data History',
    value: DataInputTableOptions.IMPORT_DATA_HISTORY,
    description: 'View the history of data importation',
    icon: <DifferenceOutlinedIcon sx={{ fontSize: '25px !important' }} />,
    visible: true,
  },
  {
    label: 'Associate Model',
    value: DataInputTableOptions.FUNCTION_TRIGGER,
    description: 'Calculate data to the cube',
    icon: <AccountTreeOutlinedIcon sx={{ fontSize: '25px !important' }} />,
    visible: true,
  },
  {
    label: 'Manual Sync to Cube',
    value: EventTableOptions.MANUAL_SYNC_TO_CUBE,
    description: 'Manually synchronize the data to the cube',
    icon: <SyncOutlinedIcon sx={{ fontSize: '25px !important' }} />,
    visible: true,
  },
];

interface ButtonTableOptionsProps {
  onClick?: (type: EventTableOptions) => void;
  disabled?: boolean | undefined;
  menuOptionsId: string[];
}

export function ButtonTableOptions(props: ButtonTableOptionsProps) {
  const { onClick, disabled = false, menuOptionsId } = props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (selectedFileType: string) => {
    onClick && onClick(selectedFileType as EventTableOptions);
    handleClose();
  };

  const options = React.useMemo(() => {
    return allOptions.filter((optionItem) => {
      return menuOptionsId.includes(optionItem.value);
    });
  }, [menuOptionsId]);

  if (menuOptionsId.length === 0) return <></>;

  return (
    <div>
      <Tooltip title='More Options'>
        <span data-testid='button-export-label'>
          <IconButton
            disabled={disabled}
            data-testid='button-export'
            aria-controls={open ? 'demo-customized-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined}
            sx={{
              opacity: disabled ? 0.5 : 1,
            }}
            onClick={handleClick}
          >
            <GridMoreVertIcon sx={{ color: '#42BB93' }} />
          </IconButton>
        </span>
      </Tooltip>

      <StyledMenu data-testid='export-options' anchorEl={anchorEl} open={open} onClose={handleClose}>
        {options.map((optionItem) => {
          if (optionItem.visible) {
            return (
              <StyledMenuItem onClick={() => handleMenuItemClick(optionItem.value)} disableRipple>
                {optionItem.icon}
                <Stack width={220}>
                  {optionItem.label}
                  <Typography
                    sx={{ fontSize: 12, whiteSpace: 'break-spaces' }}
                    component='span'
                    variant='body2'
                    color='text.secondary'
                  >
                    {optionItem.description}
                  </Typography>
                </Stack>
              </StyledMenuItem>
            );
          }
          return <></>;
        })}
      </StyledMenu>
    </div>
  );
}
