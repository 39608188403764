import { AxiosResponse } from 'axios';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import axios from 'libs/Axios';

import { AxiosDefaultErrorEntity } from 'types/api/Common/ErrorTypes';
import { CubeLayerSetItem, RequestCreateCubeLayerSet } from 'types/api/SystemTenant/AROCube/CubeLayerSetTypes';
import { AutoCompleteItem } from 'types/api/Common/AutoCompleteTypes';

export const CUBE_LAYER_SET_QUERY_KEY = {
  CUBE_LAYER_SET_LIST: 'CUBE_LAYER_SET_LIST',
  CUBE_LAYER_SET_DROPDOWNS: 'CUBE_LAYER_SET_DROPDOWNS',
  CUBE_LAYER_SET_LAYERS_DROPDOWN: 'CUBE_LAYER_SET_LAYERS_DROPDOWN',
};

export function useGetCubeLayerSetList() {
  return useQuery<AxiosResponse<CubeLayerSetItem[]>, AxiosDefaultErrorEntity>(
    [CUBE_LAYER_SET_QUERY_KEY.CUBE_LAYER_SET_LIST],
    () => axios.get(`/api/v1/cube-layer/layer-set/list`).then((res) => res)
  );
}

export function useGetCubeLayerSetLayersDropdown(layerSetId?: string) {
  return useQuery<AxiosResponse<AutoCompleteItem[]>, AxiosDefaultErrorEntity>(
    [CUBE_LAYER_SET_QUERY_KEY.CUBE_LAYER_SET_LAYERS_DROPDOWN, layerSetId],
    () => axios.get(`/api/v1/cube-layer/layer-set/${layerSetId}/dropdown`).then((res) => res),
    {
      enabled: !!layerSetId,
    }
  );
}

export function useUpsertCubeLayerSet() {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosDefaultErrorEntity, RequestCreateCubeLayerSet>(
    (data) => axios.post(`/api/v1/cube-layer/layer-set/upsert`, data).then((res) => res),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(CUBE_LAYER_SET_QUERY_KEY.CUBE_LAYER_SET_LIST);
      },
      onError: (error) => {
        queryClient.invalidateQueries(CUBE_LAYER_SET_QUERY_KEY.CUBE_LAYER_SET_LIST);
      },
    }
  );
}
