import { AxiosError } from 'axios';
import { AxiosDefaultErrorEntity } from 'types';

export function getErrorMessage(
  error: AxiosDefaultErrorEntity | AxiosError<{ code: number; message?: string; requestId?: string }>
) {
  if (error?.message === 'Network Error') {
    return 'Failed to connect to server, please check your internet connection or try again later';
  }
  const serverErrorMessage =
    error?.response?.data?.message ?? error?.message ?? 'Something went wrong, please try again later';

  const requestId = error?.response?.data?.requestId;
  return requestId ? `${serverErrorMessage} [${requestId}]` : serverErrorMessage;
}

export function getErrorTitle(error: AxiosDefaultErrorEntity | AxiosError<{ code: number; message?: string }> | null) {
  if (error?.response?.data?.message) return `Requiest Failed - [${error?.response?.status}]`;
  if (error?.message === 'Network Error') return 'Network Error - [Connection Error]';
  if (error?.message) return ` ${error?.message} - [${error?.response?.status}]`;
  return 'Request Failed - [Unknown Error]';
}

export function getDropRejectErrorCode(reject: any) {
  if (reject.errors.length > 0) {
    return reject.errors[0].code;
  }
  return 'File type not allowed';
}

export function getDropFileErrorMessageByCode(code: string) {
  switch (code) {
    case 'too-many-files':
      return 'You can only upload one file at a time';
    case 'file-invalid-type':
      return 'Error: File type not allowed!';
    case 'file-too-large':
      return 'Error: The file you attempted to upload exceeds the allowed size limit. Please ensure that your file is within the maximum size limit specified in the instructions above';
    default:
      return `Error: Something went wrong, please try again later (${code})`;
  }
}
