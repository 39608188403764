import { Button, ButtonProps } from '@mui/material';

import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';

const backButtonStyles = {
  textTransform: 'none',
  mb: 1,
  p: 0,
  '&:hover': {
    textDecoration: 'underline',
  },
};
export function BackToListButton(
  props: ButtonProps & {
    title?: string;
  }
) {
  const { title = 'Return to List', ...rest } = props;
  return (
    <Button variant='text' disableRipple sx={backButtonStyles} {...rest} startIcon={<KeyboardBackspaceOutlinedIcon />}>
      {title}
    </Button>
  );
}
