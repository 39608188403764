import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export default function ActivityIndicator(props: CircularProgressProps) {
  return (
    <Box sx={{ display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
      <CircularProgress {...props} />
    </Box>
  );
}
