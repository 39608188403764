import { useIsMutating } from 'react-query';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import ButtonLoading from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Alert from '@mui/material/Alert';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';

const ModalContentStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  overflow: 'auto',
  maxHeight: '90%',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: 1,
  px: 3,
  py: 2,
};

const buttonActionStyle = { width: '100%', textTransform: 'none' };

const closeIconStyle = { color: '#98A2AE', cursor: 'pointer' };

export type ModalConfirmationState = {
  title?: string;
  message: string;
  open: boolean;
  isError?: boolean;
  key?: string | number;
  errorMessage?: string;
};

export interface ModalConfirmationProps {
  visible?: boolean;
  onClose?: () => void;
  onCancel?: () => void;
  onApprove?: () => void;
  labelButtonApprove?: string;
  labelButtonCancel?: string;
  title?: string;
  message?: string;
  isError?: boolean;
  errorMessage?: string;
  messageType?: 'error' | 'warning' | 'info' | 'success' | undefined;
  dangerouslySetInnerHTML?: { __html: string };
  width?: number;
}

const BUTTON_SUBMIT_COLOR = {
  warning: {
    variant: 'contained' as 'contained',
  },
  info: {
    variant: 'main-table-panel' as 'main-table-panel',
  },
  error: {
    variant: 'contained' as 'contained',
  },
  success: {
    variant: 'main-table-panel-border' as 'main-table-panel-border',
  },
};

const BUTTON_CANCEL_COLOR = {
  warning: {
    variant: 'main-table-panel' as 'main-table-panel',
  },
  info: {
    variant: 'main-table-panel-border' as 'main-table-panel-border',
  },
  error: {
    variant: 'main-table-panel-border' as 'main-table-panel-border',
  },
  success: {
    variant: 'main-table-panel-border' as 'main-table-panel-border',
  },
};

export function ModalConfirmationComponent(props: ModalConfirmationProps) {
  const isMutating = Boolean(useIsMutating());
  const {
    onApprove,
    onCancel,
    onClose,
    visible = false,
    labelButtonCancel = 'Cancel',
    labelButtonApprove = 'Approve',
    errorMessage = 'Error Message',
    isError = false,
    message = 'Description Message ',
    title = 'Action Confirmation',
    messageType = 'warning',
    dangerouslySetInnerHTML,
  } = props;

  return (
    <div>
      <Modal open={visible} onClose={onClose}>
        <Stack
          direction='column'
          sx={{
            ...ModalContentStyle,
            width: props.width ? props.width : ModalContentStyle.width,
          }}
        >
          <Stack justifyContent='space-between' direction='row' spacing={2} textAlign='center'>
            <Typography variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
              {title}
            </Typography>
            <IconButton onClick={onClose} component='label'>
              <HighlightOffRoundedIcon sx={closeIconStyle} />
            </IconButton>
          </Stack>
          <Divider sx={{ mb: 1 }} />
          {!isError && (
            <Alert severity={messageType} sx={{ mt: 2, whiteSpace: 'pre-line' }}>
              {!dangerouslySetInnerHTML ? message : <div dangerouslySetInnerHTML={dangerouslySetInnerHTML} />}
            </Alert>
          )}
          <Stack alignItems='center' sx={{ py: 2 }}>
            {isError && (
              <Alert severity='error' sx={{ width: '90%', mt: 2, backgroundColor: '#FFFBE4', color: '#BC0000' }}>
                {errorMessage}
              </Alert>
            )}
          </Stack>
          <Divider sx={{ mb: 1 }} />
          {isError ? (
            <Button variant='main-table-panel' sx={buttonActionStyle} onClick={onClose}>
              Close
            </Button>
          ) : (
            <Stack direction='row' justifyContent='center' spacing={2} alignItems='center' sx={{ py: 1 }}>
              <Button
                disabled={isMutating}
                variant={BUTTON_CANCEL_COLOR[messageType].variant}
                sx={buttonActionStyle}
                onClick={onCancel}
              >
                {labelButtonCancel}
              </Button>
              <ButtonLoading
                loading={isMutating}
                variant={BUTTON_SUBMIT_COLOR[messageType].variant}
                color='error'
                sx={buttonActionStyle}
                onClick={onApprove}
              >
                {labelButtonApprove}
              </ButtonLoading>
            </Stack>
          )}
        </Stack>
      </Modal>
    </div>
  );
}
