import { useMemo, useState } from 'react';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { dayjs } from 'utils/DateUtils';
import { DataGridPro, GridColDef, GridFilterModel } from '@mui/x-data-grid-pro';
import { ISynchronizeEventDataSourceHistory } from 'types/api/Tenant/EventDataSourceTypes';
import { ENV_CONFIG } from 'config/env';
import { useGetFailedSyncRecord, useRetryFailedRecords } from 'services/v1/Tenant/AROEvent/EventDataSourceService';
import ModalRecordFailedInfo, { ModalRecordFailedInfoProps } from '../ModalRecordFailedInfo';
import { Alert, SvgIcon } from '@mui/material';
import { EVENT_SYNC_DATA_SOURCE_STATUS, EVENT_SYND_DATA_SOURCE_STATUS_INFO } from 'constant/DataInputConstant';
import { EVENT_SYNC_DATA_SOURCE_STATUS_ICON } from './PullDataSourceHistoryTable';
import { PaginationAndSortingParams } from 'types/api/Common/PaginationTypes';
import { isValidJSONString } from 'utils/Object';
const dataGridStyle = {
  '& .MuiCheckbox-root svg': {
    backgroundColor: 'transparent',
    color: '#98A2AE',
    borderRadius: 2,
  },
  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: '#EDF2FF',
    borderTop: '1px solid #E3EBF6',
    borderBottom: '1px solid #E3EBF6',
    textAlign: 'center',
    display: 'flex',
  },
};

type FailedRecord = {
  id: string;
  errorMessage: string;
  primaryKeyColumn: string;
};

function getDetailsMessage(data: ISynchronizeEventDataSourceHistory | null) {
  if (!data) return null;

  if (data?.syncStatus === EVENT_SYNC_DATA_SOURCE_STATUS.COMPLETED && data?.totalFailedCount !== 0) {
    return `Synchronization completed by ${data.lastModifiedBy} with some failed records`;
  }
  if (data?.syncStatus === EVENT_SYNC_DATA_SOURCE_STATUS.COMPLETED) {
    return `Synchronization completed by ${data.lastModifiedBy} with no failed records`;
  }
  if (data.syncStatus === EVENT_SYNC_DATA_SOURCE_STATUS.DISCONNECTED) {
    return `Data source disconnected on ${dayjs(data?.lastPullDate).format(ENV_CONFIG.config.DATE_TIME_FORMAT)} by ${
      data.lastModifiedBy
    }`;
  }
  if (data.syncStatus === EVENT_SYNC_DATA_SOURCE_STATUS.RECONNECTED) {
    return `Data source reconnected on ${dayjs(data?.lastPullDate).format(ENV_CONFIG.config.DATE_TIME_FORMAT)} by ${
      data.lastModifiedBy
    }`;
  }
}

export function PullDataSourceHistoryDetail(props: {
  style?: React.CSSProperties;
  data: ISynchronizeEventDataSourceHistory | null;
  onClose?: () => void;
}) {
  const { style, onClose } = props;

  const { data, isLoading } = useGetFailedSyncRecord(props.data?.id || undefined);
  const [filter, setFilter] = useState<PaginationAndSortingParams>({
    page: 1,
    size: 10,
    filterValue: '',
    sortType: 'desc',
    sortBy: 'activityDate',
    filterColumn: 'fullName',
    filterOperator: 'contains',
  });

  const [modalViewError, setModalViewError] = useState<ModalRecordFailedInfoProps>({
    descriptionMessage: '',
    visible: false,
  });
  const { mutate: retryFailedRecords } = useRetryFailedRecords();

  const failedRecords: FailedRecord[] = useMemo(() => {
    if (data?.data?.failedRecords && isValidJSONString(data?.data?.failedRecords)) {
      return JSON.parse(data?.data?.failedRecords);
    }
    return [];
  }, [data]);

  const generalFailedErrorMessage: string | null = useMemo(() => {
    if (data?.data.failedRecords && !isValidJSONString(data?.data.failedRecords)) {
      return data?.data.failedRecords;
    }
    return null;
  }, [data?.data.failedRecords]);

  const handleOnRetryFailedRecords = () => {
    retryFailedRecords(
      {
        syncHistoryId: props.data?.id || '',
      },
      {
        onSuccess: () => {
          onClose?.();
        },
      }
    );
  };
  const handleChangePage = (newPage: number) => {
    setFilter({ ...filter, page: newPage + 1 });
  };

  const handleOnFilter = (params: GridFilterModel) => {
    let filterObj = {};
    if (params.items.length) {
      filterObj = {
        filterValue: params.items[0].value,
        filterOperator: params.items[0].operatorValue,
        filterColumn: params.items[0].columnField,
      };
    } else {
      filterObj = {
        filterValue: '',
        filterOperator: 'contains',
      };
    }
    setFilter({
      ...filter,
      ...filterObj,
    });
  };

  const columns: GridColDef[] = useMemo(() => {
    if (failedRecords.length > 0) {
      return [
        {
          field: 'id',
          headerName: failedRecords[0].primaryKeyColumn,
          width: 150,
        },
        {
          field: 'errorMessage',
          headerName: 'Description',
          cellClassName: () => 'error-background',
          renderCell: (params) => {
            const fullText = params.value as string;
            const shortenedText = fullText.length > 70 ? fullText.substring(0, 70) + '...' : fullText;

            return (
              <div>
                {shortenedText}
                {fullText.length > 70 && (
                  <button
                    style={{
                      marginLeft: '8px',
                      fontFamily: 'inherit',
                      background: 'none',
                      border: 'none',
                      color: '#325d99',
                      fontWeight: 600,
                      textDecoration: 'underline',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      setModalViewError({
                        descriptionMessage: fullText,
                        visible: true,
                      });
                    }}
                  >
                    See More
                  </button>
                )}
              </div>
            );
          },
          flex: 1,
        },
      ];
    }
    return [];
  }, [failedRecords]);

  const detailsMessage = getDetailsMessage(props.data);

  return (
    <Stack direction='column' px={2} style={style}>
      {detailsMessage && (
        <Alert variant='outlined' color='info' severity='info'>
          <Typography variant='body2' fontSize={13}>
            {detailsMessage}
          </Typography>
        </Alert>
      )}

      <Stack direction='row' gap={2} justifyContent='space-between' my={2}>
        <Stack>
          <Typography variant='body2' fontWeight={800}>
            Sync Date
          </Typography>
          <Typography variant='body2' fontSize={13}>
            {props.data?.lastPullDate && dayjs(props.data?.lastPullDate).format(ENV_CONFIG.config.DATE_TIME_FORMAT)}
          </Typography>
        </Stack>

        <Stack direction='row' gap={2}>
          <Divider orientation='vertical' variant='middle' flexItem />
          {props.data?.syncStatus && (
            <Stack>
              <Typography variant='body2' fontWeight={800}>
                Status
              </Typography>
              <Stack
                direction='row'
                alignItems='center'
                gap={0.5}
                color={EVENT_SYND_DATA_SOURCE_STATUS_INFO.COLOR[props.data?.syncStatus]}
              >
                <SvgIcon
                  component={EVENT_SYNC_DATA_SOURCE_STATUS_ICON[props.data?.syncStatus]}
                  sx={{ fontSize: '1.3rem' }}
                />
                <Typography>{EVENT_SYND_DATA_SOURCE_STATUS_INFO.LABEL[props.data?.syncStatus]}</Typography>
              </Stack>
            </Stack>
          )}
        </Stack>
        <Stack direction='row' gap={2}>
          <Divider orientation='vertical' variant='middle' flexItem />
          <Stack>
            <Typography variant='body2' fontWeight={800}>
              Total Records
            </Typography>
            <Typography variant='body2' fontSize={13}>
              {props.data?.totalRecordCount?.toLocaleString()}
            </Typography>
          </Stack>
        </Stack>
        <Stack direction='row' gap={2}>
          <Divider orientation='vertical' variant='middle' flexItem />
          <Stack>
            <Typography variant='body2' fontWeight={800}>
              Total Failed Records
            </Typography>
            <Typography variant='body2' fontSize={13}>
              {props.data?.totalFailedCount?.toLocaleString()}
            </Typography>
          </Stack>
        </Stack>
      </Stack>

      {generalFailedErrorMessage && (
        <Stack direction='column' justifyContent='space-between'>
          <Typography mb={0.6} fontWeight={800}>
            Syncronization Error:
          </Typography>
          <Alert variant='outlined' color='error' severity='error'>
            <Typography variant='body2' fontSize={13} color='error.main'>
              {generalFailedErrorMessage}
            </Typography>
          </Alert>
        </Stack>
      )}

      <Stack height={450} mt={1}>
        <Stack direction='row' justifyContent='space-between' alignItems='center'>
          <Typography mb={0.6} fontWeight={800}>
            {`Failed Records (${props.data?.totalFailedCount?.toLocaleString()})`}
          </Typography>
          {data?.data?.isRetrySyncAvailable && failedRecords.length > 0 && (
            <Button variant='main-table-panel' color='primary' size='small' onClick={handleOnRetryFailedRecords}>
              Retry Failed Records
            </Button>
          )}
        </Stack>
        <Divider
          sx={{
            my: 1,
          }}
          flexItem
        />
        <DataGridPro
          sx={dataGridStyle}
          autoHeight={false}
          loading={isLoading}
          rows={failedRecords}
          pagination
          disableMultipleColumnsFiltering
          onPageChange={handleChangePage}
          onFilterModelChange={handleOnFilter}
          pageSize={filter.size}
          rowCount={failedRecords.length}
          page={filter.page || 0}
          paginationMode='client'
          density='compact'
          columns={columns}
          filterMode='client'
          checkboxSelection={false}
        />
        <ModalRecordFailedInfo
          visible={modalViewError.visible}
          descriptionMessage={modalViewError.descriptionMessage}
          onClose={() => {
            setModalViewError({
              descriptionMessage: '',
              visible: false,
            });
          }}
        />
      </Stack>
    </Stack>
  );
}
