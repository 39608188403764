import axios from 'libs/Axios';
import qs from 'query-string';
import { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { AxiosDefaultErrorEntity } from 'types/api/Common/ErrorTypes';
import { TableAnalyticData, TableAnalyticOptions } from 'types/api/SystemTenant/AROKMS/TableAnalytic';

export const TABLE_ANALYTIC_KEY = {
  TABLE_ANALYTIC_DATA: 'TABLE_ANALYTIC_DATA',
  TABLE_ANALYTIC_OPTIONS: 'TABLE_ANALYTIC_OPTIONS',
};

export function useGetTableAnalyticData({
  subjectId,
  rowTableDefinitionId,
  columnTableDefinitionId,
  calculateFieldDefinitionId,
  decimalPlace,
  scale,
}: {
  subjectId?: string;
  rowTableDefinitionId?: string;
  columnTableDefinitionId?: string;
  calculateFieldDefinitionId?: string;
  decimalPlace?: number;
  scale?: string;
}) {
  return useQuery<AxiosResponse<TableAnalyticData>, AxiosDefaultErrorEntity>(
    [
      TABLE_ANALYTIC_KEY.TABLE_ANALYTIC_DATA,
      subjectId,
      rowTableDefinitionId,
      columnTableDefinitionId,
      calculateFieldDefinitionId,
      decimalPlace,
      scale,
    ],
    () =>
      axios
        .get(
          `/api/v1/subject-analytic/fetch?${qs.stringify({
            subjectId,
            rowTableDefinitionId,
            columnTableDefinitionId,
            calculateFieldDefinitionId,
            decimalPlace,
            scale,
          })}`
        )
        .then((res) => res),
    {
      enabled: !!subjectId && !!rowTableDefinitionId && !!columnTableDefinitionId,
    }
  );
}

export function useGetTableAnalyticOptions({ subjectId, enable }: { subjectId?: string; enable: boolean }) {
  return useQuery<AxiosResponse<TableAnalyticOptions>, AxiosDefaultErrorEntity>(
    [TABLE_ANALYTIC_KEY.TABLE_ANALYTIC_OPTIONS, subjectId],
    () => axios.get(`/api/v1/subject-analytic/options/${subjectId}`).then((res) => res),
    {
      enabled: !!subjectId && enable,
    }
  );
}
