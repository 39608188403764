import { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { Typography } from '@mui/material';
import { useGetMigrationStatus } from 'services/v1/Tenant/DataMigrationService';
import { useLeavePageConfirm } from 'hooks/usePageLeaveConfirm';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));

interface IProps {
  onFinish: () => void;
}

export function MigrationProgressBar(props: IProps) {
  const { onFinish } = props;
  const { data: result, isLoading } = useGetMigrationStatus({
    enabled: true,
    refetchInterval: 1000,
  });

  useLeavePageConfirm(true, 'Are you sure you want to leave this page?');

  useEffect(() => {
    if (result && ['COMPLETED', 'FAILED'].includes(result.data.status)) {
      setTimeout(() => {
        onFinish();
      }, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result]);

  if (isLoading) return null;

  let progressBarValue;
  if (result?.data?.totalRecords) {
    progressBarValue = (result.data.migratedRecords / result.data.totalRecords) * 100;
  }

  return (
    <>
      <Typography>Migrating Data</Typography>
      <Stack p={4} mt={2} borderRadius={2} border='1px solid #E3EBF6'>
        <Typography variant='input-label' mb={2}>
          Please wait, this might take a little while for the system to migrating data
        </Typography>
        <BorderLinearProgress
          variant='determinate'
          value={progressBarValue}
          sx={{
            '& .MuiLinearProgress-bar': {
              backgroundColor: '#00B3DC',
            },
          }}
        />
        <Typography variant='input-label' mt={2} fontSize={11}>
          {`Data ${result?.data.migratedRecords} of ${result?.data.totalRecords} records migrated`}
        </Typography>
      </Stack>
    </>
  );
}
