const BAHASA_DICTIONARY = {
  translation: {
    PAGE: {
      LOGIN: {
        WELCOME_TITLE: 'Selamat datang di aroTarget',
        WELCOME_DESCRIPTION:
          'Perangkat lunak peramalan bisnis yang disesuaikan secara otomatis untuk Anda menggunakan Ai. Peramalan keuangan, perencanaan sumber daya, dan pengoptimalan rantai pasokan, terintegrasi dengan sistem akuntansi Anda',
        WELCOME_COPYRIGHT: 'ARO Forecasting Pty Ltd All Rights Reserved.',
        FORM_TITLE: 'Masuk',
        FORM_SUB_TITLE: 'Isi kolom di bawah ini untuk mengakses ke akun Anda.',
        FORM_BUTTON_LOGIN: 'MASUK',
        FORM_BUTTON_LOGIN_WITH_MICRSOFOT: 'MICROSOFR 365',
        FORM_LOGIN_OPTIONS: 'Atau Otentikasi Menggunakan',
        FORM_LOGIN_SESSION_CHECKBOX: 'Ingat Saya',
        FORM_LOGIN_FORGOT_PASSWORD: 'Lupa Password?',
        FORM_LOGIN_INPUT_EMAIL_LABEL: 'Alamat Email',
        FORM_LOGIN_INPUT_EMAIL_PLACEHOLDER: 'Ketikan alamat email Anda',
        FORM_LOGIN_INPUT_PASSWORD_LABEL: 'Kata Sandi',
        FORM_LOGIN_INPUT_PASSWORD_PLACEHOLDER: 'Ketikan kata sandi Anda',
      },
      SUBJECT: {
        TITLE: 'Daftar Subjek',
        BUTTON_ADD_NEW: 'Tambah Subjek Baru',
      },
      ATTRIBUTE: {
        TITLE: 'Daftar Atribut',
        BUTTON_ADD_NEW: 'Tambah Atribut Baru',
      },
      MEMBER_LIST: {
        TITLE: 'Daftar Anggota',
        BUTTON_ADD_NEW: 'Tambah Anggota',
      },
      TABLE_DEFINITION: {
        TITLE: 'Definisi Tabel',
        BUTTON_ADD_NEW: 'Tambah Kolom Baru',
        RULE_TYPE: 'Jenis Aturan',
        DATA_TYPE: 'Tipe data',
        DISPLAY_NAME: 'Nama tampilan',
        PARAMETER: 'Parameter',
        FIELD_ATTRIBUTE: 'Atribut Kolom',
        DATA_TYPE_ATTRIBUTE: 'Tipe Data Atribut',
        MODAL_PARAMETER: {
          TITLE: 'Parameter untuk',
          BUTTON_SAVE: 'Simpan Parameter',
        },
      },
      ERROR_NOT_FOUND: {
        DESCRIPTION: 'Halaman yang Anda cari tidak ditemukan',
        BUTTON_ACTION: 'Kembali ke Beranda',
      },
    },
    SIDEBAR_MENU: {
      DASHBOARD: 'Dasbor',
      DATA_BUILDER: 'Pembuat Data',
      DATA_INPUT: 'Masukan Data',
      SET_UP: 'Persiapan',
      UPDATE: 'Update',
      REPORTS: 'Laporan',
      SUBJECT: 'Subjek',
      ATTRIBUTE: 'Atribut',
      MEMBER_LIST: 'Anggota Member',
      TABLE_DEFINITION: 'Definisi Tabel',
      PROJECTS: 'Proyek',
    },
    HEADER_MENU: {
      OPEN_SETTINGS: 'Buka Pengaturan',
      MULTILANGUAGE: 'Bahasa',
      SETTING: 'Pengaturan',
      LOGOUT: 'Keluar',
    },
    COMMON: {
      MODAL_IMPORT: {
        TITLE: 'Impor Data',
        BUTTON_UPLOAD: 'Unggah',
        UPLOAD_AREA_EXCEL: 'atau drop file .xlsx disini',
        UPLOAD_AREA_CSV: 'atau drop file .csv disini',
        INSTRUCTION_EXCEL: 'Unggah file .xlsx',
        INSTRUCTION_CSV: 'Unggah file .csv',
        START_IMPORT: 'Mulai Impor',
      },
      MODAL_DELETE: {
        TITLE: 'Hapus Data',
        DESCRIPTION: 'Apakah Anda yakin ingin menghapus data ini?',
        BUTTON_DELETE: 'Hapus',
      },
      MODAL_SELECT_LANGUAGE: {
        TITLE: 'Pilih Bahasa',
        DESCRIPTION: 'Pilih bahasa yang Anda inginkan',
      },
      DATATABLE: {
        EMPTY_ROW: 'Tidak ada data',
      },
      LABEL: {
        ASSOCIATE_SUBJECT: 'Asosiasi Subjek',
        ASSOCIATE_ATTRIBUTE: 'Asosiasi Atribut',
      },
      BUTTON: {
        EXPORT: 'Ekspor',
        EXPORT_CSV_FILE: 'Ekspor ke CSV',
        EXPORT_EXCEL_FILE: 'Ekspor ke Excel',
        IMPORT: 'Impor',
        IMPORT_CSV_FILE: 'Impor dari CSV',
        IMPORT_EXCEL_FILE: 'Impor dari Excel',
        DOWNLOAD_TEMPLATE: 'Unduh Template',
        DOWNLOAD_TEMPLATE_CSV: 'Unduh Template CSV',
        DOWNLOAD_TEMPLATE_EXCEL: 'Unduh Template Excel',
        CANCEL: 'Batal',
        CREATE: 'Tambah',
        APPLY: 'Terapkan',
      },
    },
    FORM_ERROR: {
      LOGIN: {
        EMAIL_INVALID: 'Email tidak valid',
        EMAIL_REQUIRED: 'Email tidak boleh kosong',
        PASSWORD_REQUIRED: 'Password tidak boleh kosong',
      },
    },
  },
};

export default BAHASA_DICTIONARY;
