// @ts-nocheck
export async function getCurrentLocationBrowser() {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject);
  })
    .then((position: GeolocationPosition) => {
      return {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };
    })
    .catch(() => {
      return {
        lat: 0,
        lng: 0,
      };
    });
}

export const convertGeolocationsToString = (locations) => {
  return locations
    .map((location) => {
      return `${location.lat},${location.lng}`;
    })
    .join(';');
};

export const decodeStringToGeoLocations = (stringLocations) => {
  return stringLocations.split(';').map((location) => {
    const [lat, lng] = location.split(',');
    return {
      lat: parseFloat(lat),
      lng: parseFloat(lng),
    };
  });
};
