import { useSelector } from 'react-redux';
import { RootState } from 'store';

interface RBACProps {
  allowedRoles: string[];
  children: React.ReactNode;
}

export function RoleBasedAccessProvider(props: RBACProps) {
  const { role } = useSelector((state: RootState) => state.auth);
  const { allowedRoles, children } = props;

  if (allowedRoles.includes(role || '')) {
    return <>{children}</>;
  }

  return null;
}
