import { AxiosResponse } from 'axios';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import axios from 'libs/Axios';

import { AutoCompleteItem } from 'types/api/Common/AutoCompleteTypes';
import { AxiosDefaultErrorEntity } from 'types/api/Common/ErrorTypes';
import {
    CreateKTreeDefinitionRequest,
    KTreeDefinitionEditValidationResponse,
    KTreeDefinitionListResponse
} from 'types/api/SystemTenant/KTreeBuilderTypes';

export const KTREE_BUILDER_QUERY_KEY = {
  KTREE_DEFINITION_LIST_DROPDOWN: 'KTREE_DEFINITION_LIST_DROPDOWN',
  KTREE_DEFINITION_LIST: 'KTREE_DEFINITION_LIST',
    KTREE_DEFINITION_EDIT_VALIDATION_STATUS: 'KTREE_DEFINITION_EDIT_VALIDATION_STATUS',
};

export function useGetKTreeDefinitionListDropdown(subjectId?: string | number) {
  return useQuery<AxiosResponse<AutoCompleteItem[]>, AxiosDefaultErrorEntity>(
    [KTREE_BUILDER_QUERY_KEY.KTREE_DEFINITION_LIST_DROPDOWN, subjectId],
    () => axios.get(`/api/v1/customer/ktree/dropdown/ktree-definition/${subjectId}`).then((res) => res),
    {
      enabled: !!subjectId,
    }
  );
}

export function useGetKtreeDefinitionList(subjectId?: string | number) {
  return useQuery<AxiosResponse<KTreeDefinitionListResponse>, AxiosDefaultErrorEntity>(
    [KTREE_BUILDER_QUERY_KEY.KTREE_DEFINITION_LIST, subjectId],
    () => axios.get(`/api/v1/ktree-definition/list/${subjectId}`).then((res) => res),
    {
      enabled: !!subjectId,
    }
  );
}

export function useCheckKTreeValidationEditStatus() {
    return useMutation<AxiosResponse<KTreeDefinitionEditValidationResponse>, AxiosDefaultErrorEntity, {kTreeDefinitionId: string}>(
        (data) => axios.get(`/api/v1/ktree-definition/validation/edit/${data.kTreeDefinitionId}`).then((res) => res)
    );
}


export function useCreateKTreeDefinition() {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosDefaultErrorEntity, CreateKTreeDefinitionRequest>(
    (data) => axios.post('/api/v1/ktree-definition/create', data).then((res) => res),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(KTREE_BUILDER_QUERY_KEY.KTREE_DEFINITION_LIST);
        queryClient.invalidateQueries(KTREE_BUILDER_QUERY_KEY.KTREE_DEFINITION_LIST_DROPDOWN);
      },
      onError: (error) => {
        queryClient.invalidateQueries(KTREE_BUILDER_QUERY_KEY.KTREE_DEFINITION_LIST);
      },
    }
  );
}

export function useUpdateKTreeDefinition() {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosDefaultErrorEntity, CreateKTreeDefinitionRequest>(
    ({ id, ...data }) => axios.put(`/api/v1/ktree-definition/edit/${id}`, data).then((res) => res),
    {
      onSuccess: (data) => {
        queryClient.removeQueries(KTREE_BUILDER_QUERY_KEY.KTREE_DEFINITION_LIST);
        queryClient.removeQueries(KTREE_BUILDER_QUERY_KEY.KTREE_DEFINITION_LIST_DROPDOWN);
      },
      onError: (error) => {
        queryClient.removeQueries(KTREE_BUILDER_QUERY_KEY.KTREE_DEFINITION_LIST);
      },
    }
  );
}

export function useDeleteKTreeDefinition() {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosDefaultErrorEntity, string>(
    (id) => axios.delete(`/api/v1/ktree-definition/delete/${id}`).then((res) => res),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(KTREE_BUILDER_QUERY_KEY.KTREE_DEFINITION_LIST);
        queryClient.invalidateQueries(KTREE_BUILDER_QUERY_KEY.KTREE_DEFINITION_LIST_DROPDOWN);
      },
      onError: (error) => {
        queryClient.invalidateQueries(KTREE_BUILDER_QUERY_KEY.KTREE_DEFINITION_LIST);
      },
    }
  );
}
