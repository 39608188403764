import React, { HTMLAttributes, useEffect, useMemo, useState } from 'react';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import ButtonLoading from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import AutoComplete from '@mui/material/Autocomplete';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import { AutoCompleteItem } from 'types/api/Common/AutoCompleteTypes';
import { StandardViewAttributeItem } from 'types/api/SystemTenant/AROView/StandardViewDefinitionTypes';
import { RULES_CONSTANT, VIEW_RULE_TYPE_DROPDOWN_OPTIONS } from 'constant/RuleConstant';
import { useAttributeDropdownTableDefinition } from 'services/v1/SystemTenant/AROKMS/AttributeService';
import {
  useGetStandardViewCubeAttributesDropdown,
  useGetStandardViewSubjectAttributeDropdown,
} from 'services/v1/SystemTenant/AROView/StandardViewDefinitionService';

const autoCompleteStyle = {
  width: '70%',
  '& .MuiAutocomplete-popper': {
    backgroundColor: 'red !imoprtant',
    fontSize: '10px',
  },
};
const ModalContentStyle = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 550,
  bgcolor: 'background.paper',
  maxHeight: 480,
  overflow: 'auto',
  borderRadius: 1,
  py: 2,
};

const closeIconStyle = { color: '#98A2AE', cursor: 'pointer' };

export interface ModalAddAttributeDefinitionProps {
  visible?: boolean;
  onClose?: () => void;
  onSave?: (attributeItem: StandardViewAttributeItem) => void;
  onDelete?: (attributeItem: StandardViewAttributeItem) => void;
  subjectId?: string;
  cubeId?: string;
  initialData?: StandardViewAttributeItem | null;
  selectedAttributeId?: Set<string>;
}

export function ModalAddAttributeDefinition(props: ModalAddAttributeDefinitionProps) {
  const { onClose, visible = false, onSave, onDelete, subjectId, cubeId, initialData, selectedAttributeId } = props;

  const { data: attributeDropdown, isLoading: isLoadingDropdownAttribute } =
    useAttributeDropdownTableDefinition(subjectId);

  const { data: cubeAttributeDropdown } = useGetStandardViewCubeAttributesDropdown(cubeId);
  const { data: subjectAttributeDropdown } = useGetStandardViewSubjectAttributeDropdown(subjectId);

  const [attirbuteDefinitionItem, setAttributeDefinitionItem] = useState<Partial<StandardViewAttributeItem>>({});

  const sourceAttributeOptionsDropdown = useMemo(() => {
    if (!attirbuteDefinitionItem?.ruleType) return [];
    if (attirbuteDefinitionItem?.ruleType.value === RULES_CONSTANT.VIEW.RULE_STANDARD_VIEW_DISPLAY_FROM_CUBE) {
      return cubeAttributeDropdown?.data ?? [];
    }
    if (attirbuteDefinitionItem?.ruleType.value === RULES_CONSTANT.VIEW.RULE_STANDARD_VIEW_DISPLAY_FROM_SUBJECT) {
      return subjectAttributeDropdown?.data ?? [];
    }
    return [];
  }, [attirbuteDefinitionItem?.ruleType, cubeAttributeDropdown?.data, subjectAttributeDropdown?.data]);

  const handleOnValueChange = (event: React.SyntheticEvent, value: AutoCompleteItem | null, fieldName: string) => {
    if (!value) return;
    setAttributeDefinitionItem((prevState) => ({ ...prevState, [fieldName]: value }));
  };

  const handleOnCancel = () => {
    handleOnCloseModal?.();
  };

  const handleOnCloseModal = () => {
    onClose?.();
    setAttributeDefinitionItem({});
  };

  const handleOnDelete = () => {
    onDelete?.(attirbuteDefinitionItem as StandardViewAttributeItem);
    handleOnCloseModal?.();
  };

  const handleOnSave = () => {
    if (Object.keys(attirbuteDefinitionItem).length === 0) return;
    onSave?.({
      ...attirbuteDefinitionItem,
      isNew: !initialData?.id,
    } as StandardViewAttributeItem);
    handleOnCloseModal?.();
  };

  useEffect(() => {
    if (!initialData) return;
    setAttributeDefinitionItem(initialData);
  }, [initialData]);

  const attributeDropdownOptions = useMemo(() => {
    if (!attributeDropdown?.data) return [];
    return attributeDropdown?.data?.filter((item) => !selectedAttributeId?.has(item.value.toString()));
  }, [attributeDropdown?.data, selectedAttributeId]);

  const renderOption = (props: HTMLAttributes<HTMLElement>, option: AutoCompleteItem) => {
    return (
      <Box component='li' sx={{ '& > span': { fontSize: '14px', mr: 1, flexShrink: 0 } }} {...props}>
        <span>{option.label}</span>
      </Box>
    );
  };

  const isValidForm =
    attirbuteDefinitionItem?.attribute && attirbuteDefinitionItem?.ruleType && attirbuteDefinitionItem?.sourceAttribute;
  return (
    <div>
      <Modal open={visible} onClose={handleOnCloseModal}>
        <Stack direction='column' sx={ModalContentStyle}>
          <Stack>
            <Stack px={2} justifyContent='space-between' direction='row' alignItems='center'>
              <Typography variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
                Attribute Definition
              </Typography>
              <IconButton onClick={handleOnCloseModal} component='label'>
                <HighlightOffRoundedIcon sx={closeIconStyle} />
              </IconButton>
            </Stack>
          </Stack>
          <Divider sx={{ mb: 1 }} />
          <Stack px={2} mb={1.6} width='80%'>
            <Typography variant='body2' fontWeight='bold' color='black'>
              Attribute
            </Typography>
            <Typography variant='input-label-gray' mb={1}>
              Choose the attribute to be used as column for the View.
            </Typography>
            <AutoComplete
              clearIcon={null}
              size='small'
              value={attirbuteDefinitionItem?.attribute}
              onChange={(event, newValue) => handleOnValueChange(event, newValue, 'attribute')}
              getOptionLabel={(option: AutoCompleteItem) => option.label}
              options={attributeDropdownOptions}
              renderOption={renderOption}
              sx={autoCompleteStyle}
              renderInput={(params) => (
                <Stack direction='row' justifyContent='space-between' display='flex' alignItems='center'>
                  <TextField {...params} placeholder={isLoadingDropdownAttribute ? 'Loading...' : 'Select Attribute'} />
                </Stack>
              )}
            />
          </Stack>
          <Stack px={2} mb={1.6} width='80%'>
            <Typography variant='body2' fontWeight='bold' color='black'>
              Rule Type
            </Typography>
            <Typography variant='input-label-gray' mb={1}>
              Choose rule type for the attribute.
            </Typography>
            <AutoComplete
              clearIcon={null}
              size='small'
              onChange={(event, newValue) => {
                handleOnValueChange(event, newValue, 'ruleType');
                setAttributeDefinitionItem((prevState) => ({
                  ...prevState,
                  sourceAttribute: undefined,
                }));
              }}
              value={attirbuteDefinitionItem?.ruleType}
              getOptionLabel={(option: AutoCompleteItem) => option.label}
              options={VIEW_RULE_TYPE_DROPDOWN_OPTIONS}
              renderOption={renderOption}
              sx={autoCompleteStyle}
              renderInput={(params) => (
                <Stack direction='row' justifyContent='space-between' display='flex' alignItems='center'>
                  <TextField {...params} placeholder='Select Rule Type' />
                </Stack>
              )}
            />
          </Stack>
          <Stack px={2} mb={1.6} width='80%'>
            <Typography variant='body2' fontWeight='bold' color='black'>
              Source Attribute
            </Typography>
            <Typography variant='input-label-gray' mb={1}>
              Choose the source attribute to be used to pull the data from.
            </Typography>
            <AutoComplete
              clearIcon={null}
              size='small'
              onChange={(event, newValue) => handleOnValueChange(event, newValue, 'sourceAttribute')}
              disabled={!attirbuteDefinitionItem?.attribute || !attirbuteDefinitionItem?.ruleType}
              value={attirbuteDefinitionItem?.sourceAttribute}
              getOptionLabel={(option: AutoCompleteItem) => option.label}
              options={sourceAttributeOptionsDropdown}
              renderOption={renderOption}
              sx={autoCompleteStyle}
              renderInput={(params) => (
                <Stack direction='row' justifyContent='space-between' display='flex' alignItems='center'>
                  <TextField {...params} placeholder='Select Source Attribute' name='targetPullCube' />
                </Stack>
              )}
            />
          </Stack>
          <Stack height={300} px={2}></Stack>
          <Stack px={2}>
            <Divider sx={{ mb: 1, mt: 2 }} />
            <Stack direction='row' justifyContent='flex-end' spacing={2} alignItems='center' sx={{ py: 1 }}>
              {initialData && (
                <Button
                  onClick={handleOnDelete}
                  variant='outlined'
                  sx={{
                    textTransform: 'none',
                  }}
                  color='error'
                >
                  Delete
                </Button>
              )}
              <Button variant='main-table-panel-border' onClick={handleOnCancel}>
                Cancel
              </Button>
              <ButtonLoading variant='main-table-panel' disabled={!isValidForm} onClick={() => handleOnSave()}>
                Save Definition
              </ButtonLoading>
            </Stack>
          </Stack>
        </Stack>
      </Modal>
    </div>
  );
}
