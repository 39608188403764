import React from 'react';
import { toast } from 'react-toastify';
import { LicenseInfo } from '@mui/x-license-pro';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { AxiosError } from 'axios';
import { QueryClient, QueryClientProvider, DefaultOptions } from 'react-query';
import { I18nextProvider as LanguangeProvider } from 'react-i18next';

import 'react-toastify/dist/ReactToastify.css';
import { store, persistor } from 'store';
import { ENV_CONFIG } from 'config/env';
import i18nConfig from './i18n.config';
import { MainComponent } from 'Main';
import { getErrorMessage } from 'utils/Error';

LicenseInfo.setLicenseKey(ENV_CONFIG.license.MUI_PRO);

const isDev = process.env.NODE_ENV === 'development';

const defaultQueryConfig: DefaultOptions = {
  queries: {
    refetchIntervalInBackground: false,
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    onError: (error: AxiosError | any) => {},
    retry: isDev ? 0 : 3,
  },
  mutations: {
    onError: (error: AxiosError | any) => {
      toast.error(getErrorMessage(error));
    },
  },
};

function App() {
  const [queryClient] = React.useState(
    () =>
      new QueryClient({
        defaultOptions: defaultQueryConfig,
      })
  );

  return (
    <LanguangeProvider i18n={i18nConfig}>
      <QueryClientProvider client={queryClient}>
        <PersistGate loading={null} persistor={persistor}>
          <Provider store={store}>
            <MainComponent />
          </Provider>
        </PersistGate>
      </QueryClientProvider>
    </LanguangeProvider>
  );
}

export default App;
