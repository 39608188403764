import Snackbar from '@mui/material/Snackbar';
import { useState, useMemo, useEffect } from 'react';
import {
  GridRowModesModel,
  GridRowModes,
  GridRowEditStopParams,
  GridSelectionModel,
  GridRowId,
  GridColumns,
  GridFilterModel,
  GridSortModel,
  getGridStringOperators,
} from '@mui/x-data-grid-pro';
import Alert, { AlertProps } from '@mui/material/Alert';
import { CustomDataTable } from 'components/DatatableComponent';
import { PaginationAndSortingParams } from 'types/api/Common/PaginationTypes';
import { ServerItem } from 'types/api/TenantMaster/MasterTable/ServerTableDataTypes';
import { getErrorMessage, getErrorTitle } from 'utils/Error';
import { TenantHomeDashboardNavigationBar } from 'components/LayoutComponent/VerticalLayout/HeaderBar';
import { useNavigate } from 'react-router';
import { PATH_CONSTANT } from '../../../constant/PathConstant';
import { ButtonRecordEdit } from '../../Tenant/AROKMS/DataInput/components/ButtonRecordEdit';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { SettingPanel } from 'pages/Tenant/AROKMS/MemberList/components/MemberListPanel';
import { useGetTenantExternalDataSource } from 'services/v1/TenantMaster/MasterTable/TenantExternalDataSourceService';
import { DataWarehouseDetails } from 'types/api/TenantMaster/MasterTable/TenantExternalDataSourceTypes';
import { Button } from '@mui/material';
const panelStyle = {
  transform: 'translate3d(704px, 140.667px, 0px) !important',

  '& .MuiDataGrid-panelFooter': {
    display: 'none',
  },
};

export default function DataWarehousePage() {
  const [rowData, setRowData] = useState<DataWarehouseDetails[]>([]);
  const navigate = useNavigate();
  const [selectedDataID, setSelectedDataID] = useState<GridRowId[]>([]);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [filter, setFilter] = useState<PaginationAndSortingParams>({
    page: 1,
    size: 10,
    filterValue: '',
    sortType: 'asc',
    sortBy: 'serverName',
    filterColumn: 'serverName',
    filterOperator: 'contains',
  });

  const [filterButtonElement, setFilterButtonElement] = useState<HTMLButtonElement | null>(null);
  const [snackbar, setSnackbar] = useState<Pick<AlertProps, 'children' | 'severity'> | null>(null);

  const { data: serverList, isLoading, isError, error } = useGetTenantExternalDataSource();

  const columns = useMemo<GridColumns>(() => {
    return [
      {
        field: 'action',
        type: 'actions',
        headerName: 'Edit',
        width: 30,
        editable: false,
        filterable: false,
        renderCell: (params: GridRenderCellParams<ServerItem>) => {
          return (
            // @ts-ignore
            <ButtonRecordEdit
              onClick={() => {
                // navigate(PATH_CONSTANT.TENANT_MASTER.DATAWAREHOUSE.EDIT.replace(':id', params.id as string));
              }}
            />
          );
        },
      },

      {
        field: 'organizationName',
        headerName: 'Organization Name',
        width: 240,
        editable: true,
        filterOperators: getGridStringOperators().filter((operator) => ['contains', 'equals'].includes(operator.value)),
      },
      {
        field: 'serverName',
        headerName: 'Server',
        width: 150,
        editable: true,
        filterOperators: getGridStringOperators().filter((operator) => ['contains', 'equals'].includes(operator.value)),
      },
      {
        field: 'databaseName',
        headerName: 'Database Name',
        width: 200,
        editable: true,
        filterOperators: getGridStringOperators().filter((operator) => ['contains', 'equals'].includes(operator.value)),
      },
      {
        field: 'channel',
        headerName: 'Channel',
        width: 200,
        renderCell: (params: GridRenderCellParams<ServerItem>) => {
          return (
            <Button
              variant='secondary-table-panel'
              sx={{
                height: '85%',
                fontSize: '12px',
              }}
              onClick={() => {
                navigate(
                  PATH_CONSTANT.TENANT_MASTER.DATAWAREHOUSE.CHANNEL.replace(':dataSourceId', params.id as string)
                );
              }}
            >
              Channel
            </Button>
          );
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (serverList?.data) {
      setRowData(serverList?.data);
    }
  }, [serverList]);

  const handleChangePage = (newPage: number) => {
    setFilter({ ...filter, page: newPage + 1 });
  };
  const handleChangePageSize = (newPageSize: number) => {
    setFilter({ ...filter, size: newPageSize });
  };

  const handleOnFilter = (params: GridFilterModel) => {
    let filterObj = {};
    if (params.items.length) {
      filterObj = {
        filterValue: params.items[0].value,
        filterOperator: params.items[0].operatorValue,
        filterColumn: params.items[0].columnField,
      };
    } else {
      filterObj = {
        filterValue: '',
        filterOperator: 'contains',
        filterColumn: 'serverName',
      };
    }
    setFilter({
      ...filter,
      ...filterObj,
    });
  };

  const handleOnSortModelChange = (model: GridSortModel) => {
    if (model.length === 0) {
      setFilter({ ...filter, sortType: 'asc', sortBy: 'serverName' });
      return;
    }
    setFilter({ ...filter, sortType: model[0].sort, sortBy: model[0].field });
  };

  const handleRowEditStop = (params: GridRowEditStopParams) => {
    setRowModesModel({ ...rowModesModel, [params.id]: { mode: GridRowModes.View } });
  };

  const handleCloseSnackbar = () => setSnackbar(null);

  const handleOnInsertClick = () => {
    navigate(PATH_CONSTANT.TENANT_MASTER.DATAWAREHOUSE.ADD);
  };

  return (
    <TenantHomeDashboardNavigationBar>
      <CustomDataTable
        initialState={{
          pinnedColumns: {
            left: ['action'],
          },
          pagination: {
            page: 0,
          },
          sorting: {
            sortModel: [
              {
                field: 'serverName',
                sort: 'asc',
              },
            ],
          },
        }}
        isError={isError}
        errorMessage={error && getErrorMessage(error)}
        errorTitle={getErrorTitle(error)}
        loading={isLoading}
        rows={rowData}
        columns={columns}
        pageSize={filter.size}
        getRowId={(row) => row.id}
        rowModesModel={rowModesModel}
        disableSelectionOnClick
        checkboxSelection={false}
        rowsPerPageOptions={[5, 10, 20]}
        getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
        experimentalFeatures={{ newEditingApi: true }}
        onFilterModelChange={handleOnFilter}
        onSortModelChange={handleOnSortModelChange}
        onPageChange={handleChangePage}
        onPageSizeChange={handleChangePageSize}
        onSelectionModelChange={(selectedId: GridSelectionModel) => {
          setSelectedDataID(selectedId);
        }}
        components={{
          Toolbar: SettingPanel,
        }}
        onRowEditStop={handleRowEditStop}
        componentsProps={{
          panel: {
            sx: panelStyle,
            anchorEl: filterButtonElement,
          },
          toolbar: {
            setRows: setRowData,
            setRowModesModel,
            onAddDataClick: handleOnInsertClick,
            setFilterButtonElement: setFilterButtonElement,
            disabled: isLoading,
            disabledDeleteButton: selectedDataID.length === 0,
            title: 'Server List',
            config: {
              withExport: false,
              deleteButtonText: 'Delete',
              fieldFocusOnInsert: '',
              insertButtonText: 'Add Datawarehouse',
              onInsertClick: handleOnInsertClick,
              insertInitialObject: {
                attribute: '',
                isNew: true,
              },
            },
          },
        }}
      />

      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          onClose={handleCloseSnackbar}
          autoHideDuration={3000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
    </TenantHomeDashboardNavigationBar>
  );
}
