import { useState, useEffect, useMemo } from 'react';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import ButtonLoading from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import Typograhpy from '@mui/material/Typography';
import AutoComplete from '@mui/material/Autocomplete';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import { DropdownItem } from 'types/api/SystemTenant/AROKMS/TableDefinitionTypes';
import { AutoCompleteItem } from 'types/api/Common/AutoCompleteTypes';
import {
  useGetCubeDefinitionDetails,
  useGetPullCubeDropdown,
  useGetPullCubeLayerDropdown,
} from 'services/v1/SystemTenant/AROCube/CubeBuilderService';
import { ModalCubeDefinitionParamsProps } from './ModalCubeDefinitionParameter';
import { CubeDefinitionRowItem } from 'types/api/SystemTenant/AROCube/CubeBuilderTypes';
import { FormCubeDefinitionMetaDataValues } from './CubeDefinitionSettingPanel';
import { Alert } from '@mui/material';

const autoCompleteStyle = {
  width: 260,
  '& .MuiAutocomplete-popper': {
    backgroundColor: 'red !imoprtant',
    fontSize: '10px',
  },
};
const ModalContentStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  maxHeight: 550,
  overflow: 'auto',
  borderRadius: 1,
  py: 2,
};

const closeIconStyle = { color: '#98A2AE', cursor: 'pointer' };

export function ModalPullFromCubeParameter(
  props: ModalCubeDefinitionParamsProps & { cubeMetaData: FormCubeDefinitionMetaDataValues }
) {
  const { onClose, visible = false, onSubmitParameter, cubeDefinitionItem, cubeMetaData } = props;

  const [selectedCube, setSelectedCube] = useState<DropdownItem | null>(null);
  const [selectedCubeLayer, setSelectedCubeLayer] = useState<DropdownItem | null>(null);

  const { data: targetPullCube, isLoading: isLoadingTargetPullCube } = useGetCubeDefinitionDetails(selectedCube?.value);
  const { data: pullCubeDropdownOptions, isLoading: isLoadingSubjectEvent } = useGetPullCubeDropdown({
    attributeId: cubeDefinitionItem?.attribute.value,
    layerSetId: cubeMetaData.layerSet?.value,
    timePeriodSetId: cubeMetaData.timePeriod?.value,
  });

  const { data: pullCubeLayerDropdownOptions, isLoading: isLoadingCubeLayerOptions } = useGetPullCubeLayerDropdown(
    selectedCube?.value
  );

  const handleOnCancel = () => {
    handleOnCloseModal();
  };

  const handleOnCloseModal = () => {
    onClose?.();
    setSelectedCube(null);
    setSelectedCubeLayer(null);
  };

  const handleOnSave = () => {
    if (cubeDefinitionItem && selectedCube) {
      const cubePullEventDefinitionRow: CubeDefinitionRowItem = {
        ...cubeDefinitionItem,
        targetPullCubeAttribute: cubeDefinitionItem.attribute,
        targetPullCube: selectedCube,
        ruleTypeParams: selectedCube?.label,
        targetPullCubeLayer: selectedCubeLayer as DropdownItem,
      };
      onSubmitParameter?.(cubePullEventDefinitionRow);
      setSelectedCube(null);
      setSelectedCubeLayer(null);
    }
  };

  useEffect(() => {
    if (cubeDefinitionItem?.targetPullCube) {
      setSelectedCube(cubeDefinitionItem.targetPullCube);
      if (cubeDefinitionItem.targetPullCubeLayer) {
        setSelectedCubeLayer(cubeDefinitionItem.targetPullCubeLayer);
      }
    }
  }, [cubeDefinitionItem]);

  const isTargetCubeHasSameLayerSet = useMemo(() => {
    return targetPullCube?.data.layerSet.value !== cubeMetaData.layerSet?.value;
  }, [targetPullCube?.data, cubeMetaData]);

  const isFormValid = useMemo(() => {
    if (isTargetCubeHasSameLayerSet) return selectedCube && selectedCubeLayer;
    return selectedCube;
  }, [isTargetCubeHasSameLayerSet, selectedCube, selectedCubeLayer]);

  return (
    <div>
      <Modal open={visible} onClose={handleOnCloseModal}>
        <Stack direction='column' sx={ModalContentStyle}>
          <Stack>
            <Stack px={2} justifyContent='space-between' direction='row' alignItems='center'>
              <Typograhpy variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
                Pull From Cube
              </Typograhpy>
              <IconButton onClick={handleOnCloseModal} component='label'>
                <HighlightOffRoundedIcon sx={closeIconStyle} />
              </IconButton>
            </Stack>
          </Stack>

          <Stack
            px={2}
            sx={{
              bgcolor: '#FBFBFB',
              py: 2,
              px: 2,
              borderTop: '1px solid #E3EBF6',
              borderBottom: '1px solid #E3EBF6',
            }}
          >
            <Typograhpy variant='input-label' fontWeight='bold' color='black' mb={1}>
              Select Cube :
            </Typograhpy>
            <Typograhpy variant='input-label-gray' mb={3}>
              Please select the cube from the list below that you would like to pull the data into the{' '}
              {cubeDefinitionItem?.attribute.label}.
            </Typograhpy>
            {(!cubeMetaData.timePeriod || !cubeMetaData.layerSet || !cubeMetaData.cubeName) && (
              <Stack mb={1}>
                <Alert severity='warning'>
                  <Typograhpy variant='body2' mb={1}>
                    Please define the cube name,time period, and layer set for the cube before pulling the data.
                  </Typograhpy>
                </Alert>
              </Stack>
            )}
            <AutoComplete
              // @ts-ignore
              onChange={(event, value) => {
                setSelectedCube(value as DropdownItem);
                setSelectedCubeLayer(null);
              }}
              clearIcon={null}
              size='small'
              value={selectedCube}
              disabled={
                isLoadingSubjectEvent || !cubeMetaData.timePeriod || !cubeMetaData.layerSet || !cubeMetaData.cubeName
              }
              getOptionLabel={(option: AutoCompleteItem) => option.label}
              loading={isLoadingTargetPullCube}
              options={pullCubeDropdownOptions?.data ?? []}
              renderOption={(props, option) => (
                <Box component='li' sx={{ '& > span': { fontSize: '14px', mr: 1, flexShrink: 0 } }} {...props}>
                  <span>{option.label}</span>
                </Box>
              )}
              sx={autoCompleteStyle}
              renderInput={(params) => (
                <Stack direction='row' justifyContent='space-between' display='flex' alignItems='center'>
                  <TextField
                    {...params}
                    name='targetPullCube'
                    placeholder={isLoadingSubjectEvent ? 'Loading...' : 'Please Select Cube'}
                  />
                </Stack>
              )}
            />
          </Stack>

          {isTargetCubeHasSameLayerSet && (
            <Stack px={2} mt={2}>
              <Alert severity='warning'>
                <Typograhpy variant='body2'>
                  The cube you selected has a different layer set than the cube you are currently working on. Please
                  select the layer from the cube you would like to pull the data from.
                </Typograhpy>
              </Alert>
              <Typograhpy variant='input-label' fontWeight='bold' color='black' mb={1} mt={2}>
                Select Cube Layer:
              </Typograhpy>
              <Typograhpy variant='input-label-gray' mb={3}>
                Please select the cube layer from the list below that you would like to pull the data into the{' '}
                {cubeDefinitionItem?.attribute.label}.
              </Typograhpy>

              <AutoComplete
                // @ts-ignore
                onChange={(event, value) => {
                  setSelectedCubeLayer(value as DropdownItem);
                }}
                clearIcon={null}
                size='small'
                value={selectedCubeLayer}
                disabled={
                  isLoadingCubeLayerOptions ||
                  !cubeMetaData.timePeriod ||
                  !cubeMetaData.layerSet ||
                  !cubeMetaData.cubeName
                }
                getOptionLabel={(option: AutoCompleteItem) => option.label}
                options={pullCubeLayerDropdownOptions?.data ?? []}
                renderOption={(props, option) => (
                  <Box component='li' sx={{ '& > span': { fontSize: '14px', mr: 1, flexShrink: 0 } }} {...props}>
                    <span>{option.label}</span>
                  </Box>
                )}
                sx={autoCompleteStyle}
                renderInput={(params) => (
                  <Stack direction='row' justifyContent='space-between' display='flex' alignItems='center'>
                    <TextField
                      {...params}
                      name='targetPullCube'
                      placeholder={isLoadingCubeLayerOptions ? 'Loading...' : 'Please Select Cube'}
                    />
                  </Stack>
                )}
              />
            </Stack>
          )}

          <Stack height={300} px={2}></Stack>
          <Stack px={2}>
            <Divider sx={{ mb: 1, mt: 2 }} />
            <Stack direction='row' justifyContent='flex-end' spacing={2} alignItems='center' sx={{ py: 1 }}>
              <Button variant='main-table-panel-border' onClick={handleOnCancel}>
                Cancel
              </Button>
              <ButtonLoading variant='main-table-panel' disabled={!isFormValid} onClick={() => handleOnSave()}>
                Save Parameter
              </ButtonLoading>
            </Stack>
          </Stack>
        </Stack>
      </Modal>
    </div>
  );
}
