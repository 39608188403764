import * as React from 'react';
import { GridRenderCellParams, GridColDef, useGridApiContext } from '@mui/x-data-grid';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';

import { AutoCompleteItem } from 'types/api/Common/AutoCompleteTypes';

const autocompleteStyles = {
  width: '95%',
  '& .MuiOutlinedInput-root': {
    padding: '1px 2px',
    '& .MuiAutocomplete-input': {
      padding: '17px 4px 17px 6px',
    },
  },
  '& .MuiAutocomplete-popper': {
    fontSize: '10px',
  },
};

const textInputStyles = {
  '& .MuiOutlinedInput': {
    padding: '1px 2px',
  },
};

const optionStyles = { '& > span': { fontSize: '14px', mr: 2, flexShrink: 0 } };

function DataGridSelectInputEditCellComponent(
  props: GridRenderCellParams & {
    optionsSource?: AutoCompleteItem[];
    onChange?: (event: React.SyntheticEvent, value: { label: string; value: string }) => void;
    isRequired?: boolean;
  }
) {
  const { id, value, field, optionsSource } = props;

  const errorMessage = !props.value && props.isRequired ? 'This field is required' : null;

  const apiRef = useGridApiContext();

  const handleChange = async (event: React.SyntheticEvent, value: { label: string; value: string }) => {
    if (value) {
      if (props.onChange) {
        props.onChange(event, value);
        return;
      }

      await apiRef.current.setEditCellValue({ id, field, value: value });
      apiRef.current.stopCellEditMode({ id, field });
    }
  };

  return (
    <Autocomplete
      getOptionLabel={(option) => option?.label || ''}
      clearIcon={null}
      contentEditable={false}
      defaultValue={value || null}
      options={optionsSource || []}
      renderOption={(props, option) => (
        <Box component='li' sx={optionStyles} {...props}>
          <span>{option?.label}</span>
        </Box>
      )}
      sx={autocompleteStyles}
      // @ts-ignore
      onChange={handleChange}
      renderInput={(params) => (
        <TextField {...params} sx={textInputStyles} error={!!errorMessage} helperText={errorMessage} />
      )}
    />
  );
}

type RenderSelectEditInputCellParams = GridColDef['renderCell'] & {
  optionsSource?: { label: string; value: string }[];
  onChange?: (event: React.SyntheticEvent, value: { label: string; value: string }) => void;
  isRequired?: boolean;
};

const MemoizedSelectInputCell = React.memo(DataGridSelectInputEditCellComponent);
export const renderDataGridSelectEditInputCell: RenderSelectEditInputCellParams = (params) => {
  return <MemoizedSelectInputCell {...params} key={params.id} />;
};
