import * as React from 'react';
import { GridRenderCellParams, GridColDef, useGridApiContext } from '@mui/x-data-grid';
import TextField from '@mui/material/TextField';

function TextEditInputCell(
  props: GridRenderCellParams & {
    type?: string;
    maxLength?: number | undefined;
    defaultValue?: string;
    isRequired?: boolean;
    field: string;
  }
) {
  const apiRef = useGridApiContext();
  const { id, value, field, type = 'string', maxLength = undefined, defaultValue, isRequired } = props;

  const errorMessage = !props.value && isRequired ? 'This field is required' : null;

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    apiRef.current.setEditCellValue({ id, field, value: event.target.value.trimStart(), debounceMs: 300 });
    apiRef.current.stopCellEditMode({ id, field });
  };

  React.useEffect(() => {
    if (defaultValue) {
      apiRef.current.setEditCellValue({ id, field, value: defaultValue, debounceMs: 300 });
      apiRef.current.stopCellEditMode({ id, field });
    }
  }, [defaultValue, apiRef, field, id]);

  return (
    <TextField
      onChange={handleChange}
      sx={{ width: '100%', height: '9px' }}
      hiddenLabel
      error={!!errorMessage}
      helperText={errorMessage}
      inputProps={{
        maxLength,
      }}
      id='data-input-cell-custom'
      defaultValue={value}
      variant='outlined'
      type={type}
      size='small'
    />
  );
}

type RenderSelectEditInputCellParams = GridColDef['renderCell'] & {
  type?: string;
  maxLength?: number | undefined;
  defaultValue?: string;
  isRequired?: boolean;
};
const MemoizedTextInputCell = React.memo(TextEditInputCell);

export const renderTextInputEditCell: RenderSelectEditInputCellParams = (params) => {
  return <MemoizedTextInputCell {...params} key={params.id} />;
};
