import Alert, { AlertProps } from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { useTranslation } from 'react-i18next';
import { useState, useMemo, useCallback, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import {
  GridRowModesModel,
  useGridApiRef,
  GridRowModes,
  GridRowId,
  GridSelectionModel,
  GridSortModel,
  getGridStringOperators,
  GridFilterModel,
  GridRowOrderChangeParams,
  GridActionsCellItem,
  GridColumns,
} from '@mui/x-data-grid-pro';
import ModalDeleteComponent, { ModalDeleteState } from 'components/ModalComponent/ModalDeleteComponent';
import { PaginationAndSortingParams } from 'types/api/Common/PaginationTypes';
import { CustomDataTable } from 'components/DatatableComponent';
import { MainLayoutComponent } from 'components/LayoutComponent/SidebarLayout/MainLayoutComponent';
import { DASHBOARD_VIEW_MODE } from 'constant/DashboardTypeConstant';
import { renderSelectEditInputCell } from 'pages/SystemTenant/AROKMS/TableDefinition/components/SelectEditInputCell';
import { CubeDefinitionSettingPanel, FormCubeDefinitionMetaDataValues } from './components/CubeDefinitionSettingPanel';
import { useUpsertCubeDefinition } from 'services/v1/SystemTenant/AROCube/CubeBuilderService';
import {
  CubeAttributesPullFromEvent,
  CubeAttributesPullFromEventsPayload,
  CubeAttributesPullMultipleLayer,
  CubeAttributesPullMultipleLayerPayload,
  CubeDefinitionRowItem,
  RequestCreateCubeDefinition,
} from 'types/api/SystemTenant/AROCube/CubeBuilderTypes';
import { CubeDefinitionAttributeTablePanel } from './components/CubeDefinitionAttributeTablePanel';
import {
  DEFINE_ATTRIBUTE_OPTIONS,
  VARIANCE_TYPE,
  mapVarianceTypeWithLabel,
} from './constant/CubeDefinitionAttributeConstant';
import { generateUniqueLetter, normalizeDataGridRowId } from 'utils/Object';
import {
  ModalCubeDefinitionParameter,
  ModalCubeDefinitionParamsProps,
} from './components/ModalCubeDefinitionParameter';
import { CubeDefinitionParamActionButton } from './components/CubeDefinitionParamsActionButton';
import { ModalCubeEventTable, PullFromEventsParameterPayload } from './components/ModalCubeEventTable';
import { ModalPullFromCubeParameter } from './components/ModalPullFromCube';
import { sortCubeDefinitionByDisplayIndexOrder, updateCubeAttributeRowPosition } from './utils/CubeDefinitionUtils';
import { useAttributeDropdownTableDefinition } from 'services/v1/SystemTenant/AROKMS/AttributeService';
import { ModalPullFromCubeToBaselineValueParameter } from './components/ModalPullFromCubeBaselineValue';
import { ModalPullCubeMultipleLayer, PullMultipleLayerParameterPayload } from './components/ModalPullCubeMultipleLayer';
import { ModalPullWeeklyToTotal } from './components/ModalPullWeeklyToTotal';
import { CreateCubeDefinitionContext, CubeDefinitionProvider } from './components/CubeDefinitionContext';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import { CubeDefinitionActionDisplayNameButton } from './components/CubeDefinitionDsiplayNameActionButton';
import {
  ModalCubeAttributeDisplayName,
  ModalCubeAttributeDisplayNameProps,
} from './components/ModalCubeAttributeDisplayName';

interface MemberListFilter extends PaginationAndSortingParams {
  subjectId?: string | number;
}

const overrideTableStyle = {
  '& .MuiDataGrid-cell--editing': {
    maxHeight: '40px !important',
    minHeight: '40px !important',
  },
  '& .MuiDataGrid-cell.MuiDataGrid-cell--editing:focus-within': {
    outline: 'none',
  },
  '& .MuiDataGrid-row--editing .MuiDataGrid-cell': {
    border: 'none',
  },
  height: 500,
};

const panelStyles = {
  transform: 'translate3d(704px, 140.667px, 0px) !important',
  '& .MuiDataGrid-panelFooter': {
    display: 'none',
  },
};

function CreateCubeDefinitionComponent() {
  const { dashboardViewMode } = useSelector((state: RootState) => state.auth);
  const { t } = useTranslation();
  const apiRef = useGridApiRef();
  const navigate = useNavigate();

  const { isFormValid } = useContext(CreateCubeDefinitionContext);
  const { subjectId } = useParams<{ subjectId: string }>();
  const [filter, setFilter] = useState<MemberListFilter>({
    page: 0,
    size: 100,
    filterValue: '',
    sortType: '',
    filterOperator: '',
  });
  const [selectedDataID, setSelectedDataID] = useState<GridRowId[]>([]);
  const [rowData, setRowData] = useState<CubeDefinitionRowItem[]>([]);
  const [rowMultipleLayerParameter, setRowMultipleLayerParameter] = useState<CubeAttributesPullMultipleLayer>({});
  const [rowPullFromEventParameter, setRowPullFromEventParameter] = useState<CubeAttributesPullFromEvent>({});
  const [optionValue, setOptionValue] = useState<FormCubeDefinitionMetaDataValues>({
    cubeName: '',
    layerSet: null,
    member: null,
    subjectX: null,
    timePeriod: null,
  });
  const [modalParameterState, setModalParameterState] = useState<ModalCubeDefinitionParamsProps>({
    visible: false,
    cubeDefinitionItem: null,
    fieldName: null,
  });

  const [modalDisplayName, setModalDisplayName] = useState<ModalCubeAttributeDisplayNameProps>({
    cubeDefinitionItem: null,
    visible: false,
  });

  const [modalDeleteState, setModalDeleteState] = useState<ModalDeleteState>({ open: false, message: '' });
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [filterButtonElement, setFilterButtonElement] = useState<HTMLButtonElement | null>(null);

  const [snackbar, setSnackbar] = useState<Pick<AlertProps, 'children' | 'severity'> | null>(null);

  // Service call
  const { mutate: upsertCubeDefinition, isLoading: isUpserting } = useUpsertCubeDefinition(subjectId);
  const { data: attributeDropdown } = useAttributeDropdownTableDefinition(subjectId);

  const attributeOptions = useMemo(() => {
    if (!attributeDropdown?.data) return [];
    if (rowData.length === 0) return [];
    const attributeToExclude = rowData.map((item) => item.attribute?.id);
    return attributeDropdown.data.filter((item) => !attributeToExclude.includes(item.value));
  }, [rowData, attributeDropdown]);

  const disableTable = !isFormValid;

  const handleEditRowClick = useCallback(
    (id: GridRowId) => () => {
      setRowModesModel((prevData) => {
        const newData = { ...prevData };
        newData[id] = { mode: GridRowModes.Edit };
        return newData;
      });
    },
    []
  );

  const handleSaveClick = useCallback(
    (id: GridRowId) => () => {
      setRowModesModel((prevData) => {
        const newData = { ...prevData };
        newData[id] = { mode: GridRowModes.View };
        return newData;
      });
    },
    []
  );

  const isAnyEdit = useMemo(() => {
    return rowModesModel && Object.values(rowModesModel).some((item) => item.mode === GridRowModes.Edit);
  }, [rowModesModel]);
  const columnsAttribute = useMemo<GridColumns>(() => {
    return [
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Edit',
        width: 30,
        cellClassName: 'actions',

        getActions: ({ id, row }) => {
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
          if (row.locked) return [];

          if (isInEditMode) {
            return [
              <GridActionsCellItem
                icon={<SaveIcon />}
                nonce={id.toString()}
                onResize={() => {}}
                onResizeCapture={() => {}}
                key={id}
                label='Save'
                onClick={handleSaveClick(id)}
              />,
            ];
          }

          return [
            <GridActionsCellItem
              key={id}
              title='Edit'
              showInMenu={false}
              nonce={id.toString()}
              onResize={() => {}}
              onResizeCapture={() => {}}
              disabled={isAnyEdit || disableTable}
              icon={<EditIcon />}
              label='Edit'
              onClick={handleEditRowClick(id)}
            />,
          ];
        },
      },
      {
        field: 'referenceCode',
        headerName: 'Ref Code',
        width: 60,
        editable: false,
        filterable: true,
        sortable: true,
      },
      {
        field: 'attribute',
        headerName: 'Attribute',
        width: 190,
        editable: true,
        filterable: true,
        sortable: true,
        renderCell: (params) => {
          return <span>{params?.value?.label}</span>;
        },
        valueGetter: (params) => {
          return params.row?.attribute;
        },
        renderEditCell: (params) =>
          // @ts-ignore
          renderSelectEditInputCell({ ...params, selectedSubjectId: subjectId, optionsSource: attributeOptions }),
        filterOperators: getGridStringOperators().filter((operator) => ['contains', 'equals'].includes(operator.value)),
      },
      {
        field: 'displayName',
        headerName: 'Display Name',
        width: 150,
        editable: false,
        filterable: true,
        sortable: true,
        renderCell: (props) => (
          <CubeDefinitionActionDisplayNameButton
            {...props}
            fieldName='displayName'
            disabled={disableTable}
            setModalAttributeDisplayName={setModalDisplayName}
            setRowModesModel={setRowModesModel}
          />
        ),
      },

      {
        field: 'ruleType',
        headerName: t('PAGE.TABLE_DEFINITION.RULE_TYPE'),
        width: 220,
        editable: true,
        filterable: false,
        sortable: false,
        renderCell: (params) => {
          return <span>{params?.value?.label}</span>;
        },
        valueGetter: (params) => {
          return params?.row?.ruleType;
        },
        renderEditCell: (params) =>
          renderSelectEditInputCell({
            ...params,
            // @ts-ignore
            showAllRuleType: true,
            // @ts-ignore
            selectedSubjectType: 'CUBE',
          }),
      },

      {
        field: 'params',
        headerName: t('PAGE.TABLE_DEFINITION.PARAMETER'),
        width: 130,
        editable: false,
        valueGetter: (params) => params.row,
        renderCell: (props) => (
          <CubeDefinitionParamActionButton
            {...props}
            fieldName='ruleTypeParams'
            setModalParameterState={setModalParameterState}
            setRowModesModel={setRowModesModel}
          />
        ),
        sortable: false,
        filterable: false,
      },
      {
        field: 'decimal',
        headerName: 'Decimal',
        width: 80,
        editable: true,
        filterable: true,
        sortable: true,
        renderCell: (params) => {
          return <span>{params?.value?.label}</span>;
        },
        valueGetter: (params) => {
          return params.row?.decimal;
        },
        renderEditCell: (params) =>
          // @ts-ignore
          renderSelectEditInputCell({ ...params, optionsSource: DEFINE_ATTRIBUTE_OPTIONS.DECIMAL_OPTIONS }),
        filterOperators: getGridStringOperators().filter((operator) => ['contains', 'equals'].includes(operator.value)),
      },
      {
        field: 'percentage',
        headerName: 'Percentage',
        width: 125,
        editable: true,
        filterable: true,
        sortable: true,
        renderCell: (params) => {
          return <span>{params?.value?.label}</span>;
        },
        valueGetter: (params) => {
          return params.row?.percentage;
        },
        renderEditCell: (params) =>
          // @ts-ignore
          renderSelectEditInputCell({ ...params, optionsSource: DEFINE_ATTRIBUTE_OPTIONS.PERCENTAGE_OPTIONS }),
        filterOperators: getGridStringOperators().filter((operator) => ['contains', 'equals'].includes(operator.value)),
      },
      {
        field: 'total',
        headerName: 'Total',
        width: 140,
        editable: true,
        filterable: true,
        sortable: true,
        renderCell: (params) => {
          return <span>{params?.value?.label}</span>;
        },
        valueGetter: (params) => {
          return params.row?.total;
        },
        renderEditCell: (params) =>
          // @ts-ignore
          renderSelectEditInputCell({ ...params, optionsSource: DEFINE_ATTRIBUTE_OPTIONS.TOTAL_OPTIONS }),
        filterOperators: getGridStringOperators().filter((operator) => ['contains', 'equals'].includes(operator.value)),
      },
      {
        field: 'consolidateRuleParams',
        headerName: 'Consolidate',
        width: 130,
        editable: false,
        valueGetter: (params) => params.row,
        renderCell: (props) => (
          <CubeDefinitionParamActionButton
            {...props}
            fieldName='consolidateRuleParams'
            setModalParameterState={setModalParameterState}
            setRowModesModel={setRowModesModel}
          />
        ),
        sortable: false,
        filterable: false,
      },

      {
        field: 'variance',
        headerName: 'Variance',
        width: 140,
        editable: true,
        filterable: true,
        sortable: true,
        renderCell: (params) => {
          return <span>{params?.value?.label}</span>;
        },
        valueGetter: (params) => {
          if (params.row.variance === null)
            return {
              label: mapVarianceTypeWithLabel.get(VARIANCE_TYPE.FAVORABLE),
              value: VARIANCE_TYPE.FAVORABLE,
            };
          return params.row?.variance;
        },
        renderEditCell: (params) =>
          // @ts-ignore
          renderSelectEditInputCell({ ...params, optionsSource: DEFINE_ATTRIBUTE_OPTIONS.VARIANCE_OPTIONS }),
        filterOperators: getGridStringOperators().filter((operator) => ['contains', 'equals'].includes(operator.value)),
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attributeOptions]);

  const isBuilderViewOnly = dashboardViewMode === DASHBOARD_VIEW_MODE.VIEW_ONLY;

  const isLoading = isUpserting;

  const handleCancelInsert = useCallback(
    (id: number) => {
      const newRows = rowData.filter((item) => item.id !== id);
      setRowData(newRows);
      setRowModesModel((prevData) => {
        const newData = { ...prevData };
        delete newData[id];
        return newData;
      });
    },
    [rowData]
  );

  const handleOnRowUpdate = useCallback(
    (newData: CubeDefinitionRowItem, oldData: CubeDefinitionRowItem | undefined) => {
      if (!newData.attribute.value && !newData.ruleType.value) {
        handleCancelInsert(newData.id as number);
      } else if (!newData.attribute.value || !newData.ruleType.value) {
        return;
      } else {
        const data = {
          ...newData,
          isNew: false,
          variance: newData.variance ?? {
            label: mapVarianceTypeWithLabel.get(VARIANCE_TYPE.FAVORABLE) ?? '',
            value: VARIANCE_TYPE.FAVORABLE,
            id: VARIANCE_TYPE.FAVORABLE,
            persistentName: VARIANCE_TYPE.FAVORABLE,
          },
          referenceCode: newData.isNew
            ? generateUniqueLetter(
                rowData,
                rowData.map((item) => item.referenceCode)
              )
            : newData.referenceCode,
          displayIndexOrder: newData.isNew ? rowData.length + 1 : newData.displayIndexOrder,
        };
        setRowData((prevData) => {
          const newDataList = [...prevData];
          const index = newDataList.findIndex((item) => item.id === newData.id);
          newDataList[index] = data;
          return sortCubeDefinitionByDisplayIndexOrder(newDataList);
        });
        return data;
      }
      return newData;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rowData]
  );

  const handleOnClickDeleteButton = () => {
    const selectedData = rowData.filter((item) => selectedDataID.includes(item.id));
    setModalDeleteState({
      ...modalDeleteState,
      open: true,
      message: `Cube Attributes : ${selectedData.map((item) => item.attribute.label).join(', ')}`,
    });
  };
  const handleOnDeleteData = async () => {
    const selectedData = rowData.filter((item) => selectedDataID.includes(item.id));
    const deleteData = selectedData.map((item) => item.id);
    const newData = rowData.filter((item) => !deleteData.includes(item.id));
    setRowData(newData);
    setModalDeleteState({ ...modalDeleteState, open: false });
  };
  const handleOnSortModelChange = (model: GridSortModel) => {
    if (model.length === 0) {
      setFilter({ ...filter, sortType: 'asc' });
      return;
    }
    setFilter({ ...filter, sortType: model[0].sort });
  };
  const handleOnFilterModelChange = (params: GridFilterModel) => {
    let filterObj = {};
    if (params.items.length) {
      filterObj = {
        filterValue: params.items[0].value,
        filterOperator: params.items[0].operatorValue,
      };
    } else {
      filterObj = {
        filterValue: '',
        filterOperator: 'contains',
      };
    }
    setFilter({
      ...filter,
      ...filterObj,
    });
  };

  const handleChangePage = (newPage: number) => {
    setFilter({ ...filter, page: newPage });
  };

  const handleChangePageSize = (newPageSize: number) => {
    setFilter({ ...filter, size: newPageSize });
  };

  const handleCloseSnackbar = () => setSnackbar(null);

  const handleOnUpdateParameter = (data: CubeDefinitionRowItem) => {
    const newData = rowData.map((item) => {
      if (item.id === data.id) {
        return data;
      }
      return item;
    });
    setRowData(sortCubeDefinitionByDisplayIndexOrder(newData));
    setModalParameterState({ ...modalParameterState, visible: false, cubeDefinitionItem: null });
  };
  const handleOnCreateCubeDefinition = (data: FormCubeDefinitionMetaDataValues) => {
    const attributesPullMultipleLayers: CubeAttributesPullMultipleLayerPayload = Object.keys(
      rowMultipleLayerParameter
    ).reduce((acc, key) => {
      const value = rowMultipleLayerParameter[key];
      if (value) {
        acc[key] = value.map((item) => ({
          targetPullCubeId: item.targetPullCube?.value?.toString() ?? null,
          targetPullCubeLayerId: item.targetPullCubeLayer?.value?.toString() ?? null,
          destinationCubeLayerId: item.destinationCubeLayer?.value?.toString() ?? null,
        }));
      }
      return acc;
    }, {} as CubeAttributesPullMultipleLayerPayload);

    const attributesPullFromEvents: CubeAttributesPullFromEventsPayload = Object.keys(rowPullFromEventParameter).reduce(
      (acc, key) => {
        const value = rowPullFromEventParameter[key];
        if (value) {
          acc[key] = value.map((item) => ({
            mainAttributeEventId: item.memberAttribute?.value ?? null,
            subjectEventId: item.eventSubject?.value ?? null,
            timeAttributeEventId: item.timeAttribute?.value ?? null,
            xAttributeEventId: item.xMemberAttribute?.value ?? null,
          }));
        }
        return acc;
      },
      {} as CubeAttributesPullFromEventsPayload
    );

    const payload: RequestCreateCubeDefinition = {
      cubeName: data.cubeName,
      cubeLayerSetId: data.layerSet?.value ?? '',
      memberId: data.member?.value ?? '',
      timePeriodSetId: data.timePeriod?.value ?? '',
      subjectXId: data.subjectX?.value ?? null,
      attributesPullMultipleLayers,
      attributesPullFromEvents,
      attributes: rowData.map((item) => ({
        id: normalizeDataGridRowId(item.id),
        attributeId: item.attribute.value,
        allowEmpty: item.allowEmpty,
        displayName: item.displayName,
        ruleTypeId: item.ruleType.value,
        ruleTypeParams: item.ruleTypeParams,
        mainAttributeEventId: item.mainAttributeEvent?.value,
        mainXAttributeEventId: item.mainXAttributeEvent?.value,
        timeAttributeEventId: item.timeAttributeEvent?.value,
        subjectEventId: item.subjectEvent?.value,
        targetPullCubeAttributeId: item.targetPullCubeAttribute?.value,
        targetPullCubeId: item.targetPullCube?.value,
        targetPullCubeLayerId: item.targetPullCubeLayer?.value,
        targetRelativeYearType: item.targetRelativeYearType,
        targetRelativeYear: item.targetRelativeYear,
        targetRelativePeriod: item.targetRelativePeriod,
        targetPullTotalCubeId: item.targetPullTotalCube?.value,
        pullTotalCalculationRule: item.pullTotalCalculationRule,
        displayIndexOrder: item.displayIndexOrder,
        referenceCode: item.referenceCode,
        decimal: item.decimal?.value ?? null,
        percentage: item.percentage?.value ?? null,
        total: item.total?.value ?? null,
        variance: item.variance?.value ?? null,
        consolidateRuleParams: item.consolidateRuleParams,
      })),
    };

    upsertCubeDefinition(payload, {
      onSuccess: () => {
        navigate(`/cube/definition?sid=${subjectId}`);
      },
      onError: () => {},
    });
  };

  const handleOnOptionChange = (data: FormCubeDefinitionMetaDataValues) => {
    setOptionValue(data);
  };

  const handleRowOrderChange = async (params: GridRowOrderChangeParams) => {
    const newRows = updateCubeAttributeRowPosition(params.oldIndex, params.targetIndex, rowData);
    setRowData(newRows);
  };

  const handleOnUpdateParameterPullMultipleLayer = (data: PullMultipleLayerParameterPayload) => {
    handleOnUpdateParameter(data.rowItem);
    setRowMultipleLayerParameter((prevData) => {
      const newData = { ...prevData };
      newData[data.rowItem.attribute.value] = data.cubeComponentDefinitions;
      return newData;
    });
  };

  const handleOnUpdateDisplayName = (data: CubeDefinitionRowItem) => {
    handleOnUpdateParameter(data);
    setModalDisplayName({ ...modalDisplayName, visible: false, cubeDefinitionItem: null });
  };

  const handleOnUpdateParameterPulLFromEvent = (data: PullFromEventsParameterPayload) => {
    handleOnUpdateParameter(data.rowItem);
    setRowPullFromEventParameter((prevData) => {
      const newData = { ...prevData };
      newData[data.rowItem.attribute.value] = data.cubePullEventDefinitons;
      return newData;
    });
  };
  return (
    <MainLayoutComponent pageTitle='Add New Cube' breadcrumbs={[t('SIDEBAR_MENU.DASHBOARD'), 'Cube Definition']}>
      <CubeDefinitionSettingPanel
        onSubmit={handleOnCreateCubeDefinition}
        onChange={handleOnOptionChange}
        disabled={false}
        subjectId={subjectId}
        loading={isLoading}
      />
      <CustomDataTable
        apiRef={apiRef}
        loading={isLoading}
        initialState={{
          pagination: {
            page: 0,
          },
        }}
        rows={rowData}
        columns={columnsAttribute}
        rowCount={rowData?.length || 0}
        page={filter.page}
        pageSize={filter.size}
        getRowId={(row) => row.id || ''}
        rowModesModel={rowModesModel}
        disableSelectionOnClick
        sortingMode='client'
        filterMode='client'
        paginationMode='client'
        isRowSelectable={(row) => {
          return !row.row.locked;
        }}
        isCellEditable={(params) => {
          return !params?.row?.locked;
        }}
        onSortModelChange={handleOnSortModelChange}
        processRowUpdate={handleOnRowUpdate}
        onFilterModelChange={handleOnFilterModelChange}
        onPageChange={handleChangePage}
        onPageSizeChange={handleChangePageSize}
        onSelectionModelChange={(selectedId: GridSelectionModel) => {
          setSelectedDataID(selectedId);
        }}
        rowHeight={45}
        sx={overrideTableStyle}
        onProcessRowUpdateError={(params) => console.log({ params })}
        rowsPerPageOptions={[5, 10, 20, 50, 100]}
        getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
        experimentalFeatures={{ newEditingApi: true }}
        rowReordering
        disableVirtualization
        autoHeight={false}
        onRowOrderChange={handleRowOrderChange}
        components={{
          Toolbar: CubeDefinitionAttributeTablePanel,
        }}
        componentsProps={{
          panel: {
            sx: panelStyles,
            anchorEl: filterButtonElement,
          },
          toolbar: {
            setRows: setRowData,
            isInserting: rowData.some((item) => item.isNew),
            setRowModesModel,
            setFilterButtonElement: setFilterButtonElement,
            onDeleteData: handleOnClickDeleteButton,
            disabledDeleteButton: selectedDataID.length === 0,
            disabled: rowData.some((item) => item.isNew) || isBuilderViewOnly || isLoading || disableTable,
            config: {
              fieldFocusOnInsert: 'attribute',
              insertInitialObject: {
                isNew: true,
              },
            },
          },
        }}
      />

      {!!snackbar && (
        <Snackbar
          open
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={3000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}

      <ModalCubeEventTable
        visible={
          modalParameterState.visible &&
          modalParameterState.cubeDefinitionItem?.ruleType.value === '70' &&
          modalParameterState.fieldName !== 'consolidateRuleParams'
        }
        onClose={() => setModalParameterState({ visible: false, cubeDefinitionItem: null, fieldName: null })}
        cubeDefinitionItem={modalParameterState.cubeDefinitionItem}
        fieldName={modalParameterState.fieldName}
        cubeMetaData={optionValue}
        onSubmitParameter={handleOnUpdateParameterPulLFromEvent}
      />

      <ModalPullFromCubeToBaselineValueParameter
        visible={
          modalParameterState.visible &&
          modalParameterState.cubeDefinitionItem?.ruleType.value === '72' &&
          modalParameterState.fieldName !== 'consolidateRuleParams'
        }
        cubeMetaData={optionValue}
        onClose={() => setModalParameterState({ visible: false, cubeDefinitionItem: null, fieldName: null })}
        cubeDefinitionItem={modalParameterState.cubeDefinitionItem}
        onSubmitParameter={handleOnUpdateParameter}
        fieldName={modalParameterState.fieldName}
      />

      <ModalPullFromCubeParameter
        visible={
          modalParameterState.visible &&
          modalParameterState.cubeDefinitionItem?.ruleType.value === '71' &&
          modalParameterState.fieldName !== 'consolidateRuleParams'
        }
        onClose={() => setModalParameterState({ visible: false, cubeDefinitionItem: null, fieldName: null })}
        cubeDefinitionItem={modalParameterState.cubeDefinitionItem}
        fieldName={modalParameterState.fieldName}
        cubeMetaData={optionValue}
        onSubmitParameter={handleOnUpdateParameter}
      />

      <ModalPullCubeMultipleLayer
        visible={
          modalParameterState.visible &&
          modalParameterState.cubeDefinitionItem?.ruleType.value === '73' &&
          modalParameterState.fieldName !== 'consolidateRuleParams'
        }
        cubeMetaData={optionValue}
        onClose={() => setModalParameterState({ visible: false, cubeDefinitionItem: null, fieldName: null })}
        cubeDefinitionItem={modalParameterState.cubeDefinitionItem}
        onSubmitParameter={handleOnUpdateParameterPullMultipleLayer}
        fieldName={modalParameterState.fieldName}
      />

      <ModalPullWeeklyToTotal
        visible={
          modalParameterState.visible &&
          modalParameterState.cubeDefinitionItem?.ruleType.value === '74' &&
          modalParameterState.fieldName !== 'consolidateRuleParams'
        }
        cubeMetaData={optionValue}
        onClose={() => setModalParameterState({ visible: false, cubeDefinitionItem: null, fieldName: null })}
        cubeDefinitionItem={modalParameterState.cubeDefinitionItem}
        onSubmitParameter={handleOnUpdateParameter}
        fieldName={modalParameterState.fieldName}
      />

      <ModalDeleteComponent
        visible={modalDeleteState.open}
        message={modalDeleteState.message}
        onApprove={handleOnDeleteData}
        onClose={() => setModalDeleteState({ ...modalDeleteState, open: false })}
        onCancel={() => setModalDeleteState({ ...modalDeleteState, open: false })}
      />

      <ModalCubeDefinitionParameter
        visible={
          (modalParameterState.visible &&
            modalParameterState.cubeDefinitionItem?.ruleType.value !== '70' &&
            modalParameterState.cubeDefinitionItem?.ruleType.value !== '71' &&
            modalParameterState.cubeDefinitionItem?.ruleType.value !== '72' &&
            modalParameterState.cubeDefinitionItem?.ruleType.value !== '73' &&
            modalParameterState.cubeDefinitionItem?.ruleType.value !== '74') ||
          modalParameterState.fieldName === 'consolidateRuleParams'
        }
        fieldName={modalParameterState.fieldName}
        referenceCodes={rowData.map((item) => item.referenceCode)}
        cubeDefinitionItem={modalParameterState.cubeDefinitionItem}
        onClose={() => setModalParameterState({ visible: false, cubeDefinitionItem: null, fieldName: null })}
        onSubmitParameter={handleOnUpdateParameter}
      />

      <ModalCubeAttributeDisplayName
        visible={modalDisplayName.visible}
        onSubmitParameter={handleOnUpdateDisplayName}
        onClose={() => setModalDisplayName({ visible: false, cubeDefinitionItem: null })}
        cubeDefinitionItem={modalDisplayName.cubeDefinitionItem}
      />
    </MainLayoutComponent>
  );
}

export default function CreateCubeDefinitionPage() {
  return (
    <CubeDefinitionProvider>
      <CreateCubeDefinitionComponent />
    </CubeDefinitionProvider>
  );
}
