import * as Yup from 'yup';
import React, { useRef, useState, createContext, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import CompareArrowsOutlinedIcon from '@mui/icons-material/CompareArrowsOutlined';
import { MainLayoutComponent } from 'components/LayoutComponent/SidebarLayout/MainLayoutComponent';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import ButtonLoading from '@mui/lab/LoadingButton';
import { Box } from '@mui/material';

import { FUNCTION_CORE, FUNCTION_CORE_OPTIONS } from 'constant/FunctionConstant';
import {
  FunctionDefinitionLoadParameter,
  FunctionDefinitionLoadParameterRef,
} from './components/FunctionDefinitionLoadParameter';
import {
  useCreateFunctionDefinition,
  useGetFunctionDefinitionCubeDropdown,
  useGetFunctionDefinitionCubeLayerDropdown,
  useGetFunctionDefinitionSubjectAttributeDropdown,
  useGetFunctionDefinitionSubjectDropdown,
} from 'services/v1/SystemTenant/AROFunction/FunctionDefinitionService';
import { useFormik } from 'formik';
import { AutoCompleteItem } from 'types/api/Common/AutoCompleteTypes';
import {
  FunctionDefinitionSaveParameter,
  FunctionDefinitionSaveParameterRef,
} from './components/FunctionDefinitionSaveParameter';
import { AutoCompleteWithType } from 'types/api/Tenant/AROKMS/CustomRuleTypes';
import { RequestCreateFunctionDefinition } from 'types/api/SystemTenant/AROFunction/FunctionDefinitionTypes';
import { useNavigate } from 'react-router-dom';
import { PATH_CONSTANT } from 'constant/PathConstant';
import ActivityIndicator from 'components/ActivityIndicatorComponent';
import { FunctionDefinitionDisplayLoadParameter } from './components/FunctionDefinitionDisplayLoadParameter';
const autocompleteStyles = {
  width: '55%',
  '& .MuiOutlinedInput-root': {
    padding: '1px 2px',
  },
  '& .MuiAutocomplete-popper': {
    fontSize: '10px',
  },
};
const textInputStyles = {
  '& .MuiOutlinedInput': {
    padding: '1px 2px',
  },
};

export interface FunctionDefinitionParamFields {
  name: string;
  fieldTitle: string;
  fieldSubtitle: string;
  fieldType: 'text' | 'select' | 'autocomplete';
  placeholder?: string;
  ruleTypes: string[];
  enforceUniqueSelection: boolean;
  fieldOptions?: AutoCompleteWithType[];
  isRequired?: boolean;
}

export interface CreateFunctionDefinitionFormikInitialValues {
  name: string | null;
  subject: AutoCompleteItem | null;
  cube: AutoCompleteItem | null;
  cubeLayer: AutoCompleteItem | null;
  functionCore: AutoCompleteItem | null;
  subjectMemberAttribute: AutoCompleteItem | null;
  subjectXMemberAttribute: AutoCompleteItem | null;
  cubeMember: AutoCompleteItem | null;
}
export const createFunctionDefinitionFormikInitialValues: CreateFunctionDefinitionFormikInitialValues = {
  name: null,
  subject: null,
  cube: null,
  cubeLayer: null,
  functionCore: null,
  subjectMemberAttribute: null,
  subjectXMemberAttribute: null,
  cubeMember: null,
};

export const createFunctionDefinitionValidationSchema = Yup.object().shape({
  name: Yup.string().required('This field is required').nullable(),
  subject: Yup.object().required('This field is required').nullable(),
  cube: Yup.object().required('This field is required').nullable(),
  cubeLayer: Yup.object().required('This field is required').nullable(),
  functionCore: Yup.object().required('This field is required').nullable(),
  subjectMemberAttribute: Yup.object().required('This field is required').nullable(),
  subjectXMemberAttribute: Yup.object().nullable(),
});

interface CreateFunctionDefinitionContextType {
  selectedCubeAttributeOptions: Record<string, string | null>;
  setSelectedCubeAttributeOptions: React.Dispatch<React.SetStateAction<Record<string, string | null>>>;
  showDisplayLoadParameter: boolean;
  setShowDisplayLoadParameter: React.Dispatch<React.SetStateAction<boolean>>;
}

const defaultState: CreateFunctionDefinitionContextType = {
  selectedCubeAttributeOptions: {},
  setSelectedCubeAttributeOptions: () => {},
  showDisplayLoadParameter: false,
  setShowDisplayLoadParameter: () => {},
};

export const CreateFunctionDefinitionContext = createContext<CreateFunctionDefinitionContextType>(defaultState);

interface Props {
  children: React.ReactNode;
}

export const SelectedCubeAttributeOptionsProvider: React.FC<Props> = ({ children }) => {
  const [selectedCubeAttributeOptions, setSelectedCubeAttributeOptions] = useState<Record<string, string | null>>({});
  const [showDisplayLoadParameter, setShowDisplayLoadParameter] = useState(false);

  const providerValue = useMemo(() => {
    return {
      selectedCubeAttributeOptions,
      setSelectedCubeAttributeOptions,
      showDisplayLoadParameter,
      setShowDisplayLoadParameter,
    };
  }, [selectedCubeAttributeOptions, showDisplayLoadParameter]);

  return (
    <CreateFunctionDefinitionContext.Provider value={providerValue}>
      {children}
    </CreateFunctionDefinitionContext.Provider>
  );
};

function CreateFunctionDefinitionComponent() {
  const { t } = useTranslation();

  const loadParameterFieldsRef = useRef<FunctionDefinitionLoadParameterRef | null>(null);
  const displayLoadParameterFieldsRef = useRef<FunctionDefinitionLoadParameterRef | null>(null);
  const saveParameterFieldsRef = useRef<FunctionDefinitionSaveParameterRef | null>(null);

  const { showDisplayLoadParameter } = useContext(CreateFunctionDefinitionContext);

  const navigate = useNavigate();

  const [isFormLoadParameterValid, setIsFormLoadParameterValid] = useState(false);
  const [isFormSaveParameterValid, setIsFormSaveParameterValid] = useState(false);

  const formik = useFormik({
    initialValues: createFunctionDefinitionFormikInitialValues,
    validationSchema: createFunctionDefinitionValidationSchema,
    onSubmit: () => {},
  });
  const { data: subjectDropdownOptions, isLoading: isLoadingSubjectOptions } =
    useGetFunctionDefinitionSubjectDropdown();
  const { data: cubeDropdownOptions, isLoading: isLoadingCubeOptions } = useGetFunctionDefinitionCubeDropdown();

  const { data: cubeLayerDropdownOptions, isLoading: isLoadingCubeLayerOptions } =
    useGetFunctionDefinitionCubeLayerDropdown(
      formik.values.cube?.value?.toString() ?? null,
      formik.values.functionCore?.value?.toString() ?? null
    );

  const { data: attributeDataDropdown, isLoading: isLoadingAttributeDataOptions } =
    useGetFunctionDefinitionSubjectAttributeDropdown(formik.values.subject?.value?.toString() ?? null);

  const { mutate: createFunctionDefinition, isLoading: isSubmitting } = useCreateFunctionDefinition();
  const handleOnSubmit = () => {
    const data = formik.values;

    if (
      !data.name ||
      !data.subject ||
      !data.cube ||
      !data.cubeLayer ||
      !data.functionCore ||
      !data.subjectMemberAttribute
    )
      return;

    const loadParameterStringify = JSON.stringify(loadParameterFieldsRef.current?.getValues());
    const saveParameterStringify = JSON.stringify(saveParameterFieldsRef.current?.getValues());
    const displayLoadParameterStringify = showDisplayLoadParameter
      ? JSON.stringify(displayLoadParameterFieldsRef.current?.getValues())
      : null;

    const payload: RequestCreateFunctionDefinition = {
      cubeId: data.cube?.value?.toString(),
      cubeLayerId: data.cubeLayer?.value?.toString(),
      functionCore: data.functionCore?.value?.toString(),
      name: data.name?.toString(),
      subjectId: data.subject?.value?.toString(),
      subjectMemberAttributeId: data.subjectMemberAttribute?.value?.toString(),
      subjectMemberXAttributeId: data.subjectXMemberAttribute?.value?.toString() ?? null,
      loadParameters: loadParameterStringify,
      saveParameters: saveParameterStringify,
      displayLoadParameters: displayLoadParameterStringify,
    };
    createFunctionDefinition(payload, {
      onSuccess: () => {
        toast.success('Create function definition successfully!');
        navigate(PATH_CONSTANT.SYSTEM.FUNCTION.FUNCTION_DEFINITION);
      },
    });
  };

  const handleOnCancel = () => {
    navigate(PATH_CONSTANT.SYSTEM.FUNCTION.FUNCTION_DEFINITION);
  };

  const isAllFormValid =
    isFormLoadParameterValid &&
    isFormSaveParameterValid &&
    createFunctionDefinitionValidationSchema.isValidSync(formik.values);
  const isLoadingOptionsData = isLoadingSubjectOptions || isLoadingCubeOptions;

  const handleOnFormLoadParameterChange = (values: any, isFormValid: boolean) => {
    setIsFormLoadParameterValid(isFormValid);
  };

  const handleOnFormSaveParameterChange = (values: any, isFormValid: boolean) => {
    setIsFormSaveParameterValid(isFormValid);
  };

  const handleOnFunctionCoreChange = (e: React.SyntheticEvent, value: AutoCompleteItem | null) => {
    formik.setFieldValue('functionCore', value);
    formik.setFieldValue('cubeLayer', null);
  };

  const handleOnCubeChange = (e: React.SyntheticEvent, value: AutoCompleteItem | null) => {
    formik.setFieldValue('cube', value);
    formik.setFieldValue('cubeLayer', null);
  };

  const handleOnSubjectChange = (e: React.SyntheticEvent, value: AutoCompleteItem | null) => {
    formik.setFieldValue('subject', value);
    formik.setFieldValue('subjectMemberAttribute', null);
    formik.setFieldValue('subjectXMemberAttribute', null);
  };

  return (
    <MainLayoutComponent
      pageTitle='Add New Function'
      breadcrumbs={[t('SIDEBAR_MENU.DASHBOARD'), 'Function Definition']}
    >
      <Box p={2} bgcolor='#fff'>
        {isLoadingOptionsData ? (
          <Stack height={450}>
            <ActivityIndicator />
          </Stack>
        ) : (
          <>
            <Typography variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
              Function Definition
            </Typography>
            <Divider sx={{ my: 1 }} />
            <Stack width='100%' direction='row' gap={3} mb={5} flexWrap='wrap' alignItems='center'>
              <Stack width='40%'>
                <Typography variant='input-label' fontWeight={800}>
                  Function Name
                </Typography>
                <Typography variant='input-label-gray' fontSize={13}></Typography>
                <TextField
                  sx={textInputStyles}
                  name='name'
                  hiddenLabel
                  value={formik.values.name}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                  variant='outlined'
                  size='small'
                  placeholder='e.g. Interest Function for Loan'
                />
              </Stack>
              <Stack width='40%'>
                <Typography variant='input-label' fontWeight={800}>
                  Select Subject
                </Typography>
                <Typography variant='input-label-gray' fontSize={13}>
                  Choose the subject (table) from which you want to pull the values.
                </Typography>
                <Autocomplete
                  options={subjectDropdownOptions?.data ?? []}
                  getOptionLabel={(option) => option.label}
                  sx={{ ...autocompleteStyles, width: '100%' }}
                  clearIcon={null}
                  value={formik.values.subject}
                  onBlur={formik.handleBlur}
                  onChange={handleOnSubjectChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name='subject'
                      placeholder='Select Subject...'
                      error={formik.touched.subject && Boolean(formik.errors.subject)}
                      helperText={formik.touched.subject && formik.errors.subject}
                    />
                  )}
                />
              </Stack>
              <Stack width='40%'>
                <Typography variant='input-label' fontWeight={800}>
                  Select Cube
                </Typography>
                <Typography variant='input-label-gray' fontSize={13}>
                  Choose the cube from which you want to calculate the values for.
                </Typography>
                <Autocomplete
                  options={cubeDropdownOptions?.data?.data ?? []}
                  getOptionLabel={(option) => option.label}
                  sx={{ ...autocompleteStyles, width: '100%' }}
                  clearIcon={null}
                  value={formik.values.cube}
                  onBlur={formik.handleBlur}
                  onChange={handleOnCubeChange}
                  renderInput={(params) => <TextField {...params} name='cube' placeholder='Select Cube...' />}
                />
              </Stack>
              <Stack width='40%'>
                <Typography variant='input-label' fontWeight={800}>
                  Function Core
                </Typography>
                <Typography variant='input-label-gray' fontSize={13}>
                  Choose the function core to be used for the calculation.
                </Typography>
                <Autocomplete
                  options={FUNCTION_CORE_OPTIONS}
                  value={formik.values.functionCore}
                  onBlur={formik.handleBlur}
                  onChange={handleOnFunctionCoreChange}
                  sx={{ ...autocompleteStyles, width: '100%' }}
                  clearIcon={null}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name='functionCore'
                      placeholder='Select Function Core...'
                      error={formik.touched.functionCore && Boolean(formik.errors.functionCore)}
                      helperText={formik.touched.functionCore && formik.errors.functionCore}
                    />
                  )}
                />
              </Stack>
              <Stack width='40%'>
                <Typography variant='input-label' fontWeight={800}>
                  Select Cube Layer
                </Typography>
                <Typography variant='input-label-gray' fontSize={13}>
                  Choose the cube layer from which you want to calculate the values for.
                </Typography>
                <Autocomplete
                  disabled={!formik.values.cube || !formik.values.functionCore || isLoadingCubeLayerOptions}
                  options={cubeLayerDropdownOptions?.data ?? []}
                  value={formik.values.cubeLayer}
                  onBlur={formik.handleBlur}
                  onChange={(e, value) => {
                    formik.setFieldValue('cubeLayer', value);
                  }}
                  sx={{ ...autocompleteStyles, width: '100%' }}
                  clearIcon={null}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name='cubeLayer'
                      placeholder={isLoadingCubeLayerOptions ? 'Loading...' : 'Select Cube Layer...'}
                      error={formik.touched.cubeLayer && Boolean(formik.errors.cubeLayer)}
                      helperText={formik.touched.cubeLayer && formik.errors.cubeLayer}
                    />
                  )}
                />
              </Stack>
            </Stack>

            <Typography variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
              Function Lookup Value
            </Typography>
            <Divider sx={{ my: 1 }} />
            <Stack direction='row' width='100%' alignItems='center' gap={3} mb={5}>
              <Stack width='30%'>
                <Typography variant='input-label' fontWeight={800}>
                  Subject Member Attribute
                </Typography>
                <Typography variant='input-label-gray' fontSize={13}>
                  Choose the attribute from the subject to be used as the lookup value for the Cube Member.
                </Typography>
                <Autocomplete
                  options={attributeDataDropdown?.data ?? []}
                  disabled={!formik.values.subject || !formik.values.cubeLayer || isLoadingAttributeDataOptions}
                  value={formik.values.subjectMemberAttribute}
                  onBlur={formik.handleBlur}
                  onChange={(e, value) => {
                    formik.setFieldValue('subjectMemberAttribute', value);
                  }}
                  sx={{ ...autocompleteStyles, width: '100%' }}
                  clearIcon={null}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name='subjectMemberAttribute'
                      placeholder={isLoadingAttributeDataOptions ? 'Loading...' : 'Select Subject Attribute...'}
                      error={formik.touched.subjectMemberAttribute && Boolean(formik.errors.subjectMemberAttribute)}
                      helperText={formik.touched.subjectMemberAttribute && formik.errors.subjectMemberAttribute}
                    />
                  )}
                />
              </Stack>

              <CompareArrowsOutlinedIcon fontSize='large' color='info' />
              <Stack width='40%'>
                <Typography variant='input-label' fontWeight={800} fontSize={16}>
                  {cubeDropdownOptions?.data &&
                    formik.values.cube?.value &&
                    cubeDropdownOptions?.data?.cubeMemberMap?.[formik.values.cube?.value]}
                </Typography>
              </Stack>
            </Stack>
            {cubeDropdownOptions?.data &&
              formik.values.cube?.value &&
              cubeDropdownOptions?.data?.cubeXMemberMap?.[formik.values.cube?.value] && (
                <Stack direction='row' width='100%' alignItems='center' gap={3} mb={5}>
                  <Stack width='30%'>
                    <Typography variant='input-label' fontWeight={800}>
                      Subject Member X Attribute
                    </Typography>
                    <Typography variant='input-label-gray' fontSize={13}>
                      Choose the attribute from the subject to be used as the lookup value for the Cube Member.
                    </Typography>
                    <Autocomplete
                      options={attributeDataDropdown?.data ?? []}
                      disabled={!formik.values.subject || !formik.values.cubeLayer || isLoadingAttributeDataOptions}
                      value={formik.values.subjectXMemberAttribute}
                      onBlur={formik.handleBlur}
                      onChange={(e, value) => {
                        formik.setFieldValue('subjectXMemberAttribute', value);
                      }}
                      sx={{ ...autocompleteStyles, width: '100%' }}
                      clearIcon={null}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name='subjectXMemberAttribute'
                          placeholder={isLoadingAttributeDataOptions ? 'Loading...' : 'Select Subject Attribute...'}
                          error={
                            formik.touched.subjectXMemberAttribute && Boolean(formik.errors.subjectXMemberAttribute)
                          }
                          helperText={formik.touched.subjectXMemberAttribute && formik.errors.subjectXMemberAttribute}
                        />
                      )}
                    />
                  </Stack>
                  <CompareArrowsOutlinedIcon fontSize='large' color='info' />
                  <Stack width='40%'>
                    <Typography variant='input-label' fontWeight={800} fontSize={16}>
                      {cubeDropdownOptions?.data &&
                        formik.values.cube?.value &&
                        cubeDropdownOptions?.data?.cubeXMemberMap?.[formik.values.cube?.value]}
                    </Typography>
                  </Stack>
                </Stack>
              )}

            {formik.values.cubeLayer && formik.values.subjectMemberAttribute && (
              <>
                <FunctionDefinitionLoadParameter
                  ref={loadParameterFieldsRef}
                  onFormikChange={handleOnFormLoadParameterChange}
                  subjectId={formik.values.subject?.value?.toString() ?? null}
                  sectionTitle={`Load Parameter ${formik.values.functionCore?.label}`}
                  functionCore={formik.values.functionCore?.value.toString() ?? ''}
                />
                {showDisplayLoadParameter &&
                  formik.values.functionCore != null &&
                  formik.values.functionCore.value !== FUNCTION_CORE.COUNT_FUNCTION && (
                    <FunctionDefinitionDisplayLoadParameter
                      ref={displayLoadParameterFieldsRef}
                      onFormikChange={handleOnFormLoadParameterChange}
                      cubeId={formik.values.cube?.value?.toString() ?? null}
                      layerId={formik.values.cubeLayer?.value?.toString() ?? null}
                      sectionTitle={`Display Load Parameter ${formik.values.functionCore?.label} in Cube ${formik.values.cube?.label}`}
                      functionCore={formik.values.functionCore?.value.toString() ?? ''}
                    />
                  )}
                <FunctionDefinitionSaveParameter
                  ref={saveParameterFieldsRef}
                  onFormikChange={handleOnFormSaveParameterChange}
                  cubeId={formik.values.cube?.value?.toString() ?? null}
                  layerId={formik.values.cubeLayer?.value?.toString() ?? null}
                  sectionTitle={`Save Parameter ${formik.values.functionCore?.label}`}
                  functionCore={formik.values.functionCore?.value.toString() ?? ''}
                />
              </>
            )}
            <Stack direction='row' px={2} mt={10} justifyContent='space-between' alignItems='center'>
              <Stack />
              <Stack direction='row' gap={2}>
                <ButtonLoading variant='main-table-panel-border' onClick={handleOnCancel}>
                  Cancel
                </ButtonLoading>
                <ButtonLoading
                  variant='main-table-panel'
                  onClick={handleOnSubmit}
                  loading={isSubmitting}
                  disabled={!isAllFormValid}
                >
                  Save
                </ButtonLoading>
              </Stack>
            </Stack>
          </>
        )}
      </Box>
    </MainLayoutComponent>
  );
}

export default function CreateFunctionDefinitionPage() {
  return (
    <SelectedCubeAttributeOptionsProvider>
      <CreateFunctionDefinitionComponent />
    </SelectedCubeAttributeOptionsProvider>
  );
}
