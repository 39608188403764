import { useState, useMemo, forwardRef, useImperativeHandle, useEffect, useCallback } from 'react';
import { CustomDataTable } from 'components/DatatableComponent';
import {
  GridSortModel,
  getGridStringOperators,
  GridFilterModel,
  GridActionsCellItem,
  GridColumns,
} from '@mui/x-data-grid-pro';
import EditIcon from '@mui/icons-material/Edit';
import { PaginationAndSortingParams } from 'types/api/Common/PaginationTypes';
import { StandardViewAttributeItem } from 'types/api/SystemTenant/AROView/StandardViewDefinitionTypes';
import { ModalAddAttributeDefinition } from './ModalAddAttributeDefinition';
import { useParams } from 'react-router-dom';
import { useGetStandardViewDefinitionDetails } from 'services/v1/SystemTenant/AROView/StandardViewDefinitionService';
import { ModalConfigAttributeViewOrder } from './ModalConfigAttributeViewOrder';
import { StandardViewAttributeDefinitionPanel } from './StandardViewAttributeDefinitionPanel';
import { VIEW_ATTRIBUTE_DEFINITION_OPTIONS_MENU } from './AttributeDefinitionOptionsMenu';

const overrideTableStyle = {
  '& .MuiDataGrid-cell--editing': {
    maxHeight: '40px !important',
    minHeight: '40px !important',
  },
  '& .MuiDataGrid-cell.MuiDataGrid-cell--editing:focus-within': {
    outline: 'none',
  },
  '& .MuiDataGrid-row--editing .MuiDataGrid-cell': {
    border: 'none',
  },
};

interface IProps {
  subjectId?: string;
  cubeId?: string;
  disabled?: boolean;
  isEdit?: boolean;
}

export interface IStandardViewAttributeDefinitionRef {
  getRowData: () => StandardViewAttributeItem[];
  setRowData: (data: StandardViewAttributeItem[]) => void;
}

export const StandardViewAttributeDefinition = forwardRef<any, IProps>((props, ref) => {
  const { subjectId, cubeId, disabled, isEdit = false } = props;

  const { viewId } = useParams<{ viewId: string }>();
  const { data: standardViewDetails } = useGetStandardViewDefinitionDetails(isEdit ? viewId : null);

  const [rowData, setRowData] = useState<StandardViewAttributeItem[]>([]);
  const [showModalAddAttributeDefinition, setShowModalAddAttributeDefinition] = useState(false);
  const [showModalConfigAttributeViewOrder, setShowModalConfigAttributeViewOrder] = useState(false);
  const [modalInitialData, setModalInitialData] = useState<StandardViewAttributeItem | null>(null);
  const [filter, setFilter] = useState<PaginationAndSortingParams>({
    page: 0,
    size: 100,
    filterValue: '',
    sortType: '',
    filterOperator: '',
  });

  const handleEditClick = (row: StandardViewAttributeItem) => () => {
    if (!row) return;
    setModalInitialData(row);
    setShowModalAddAttributeDefinition(true);
  };

  const setSelectedAttributeId: Set<string> = useMemo(() => {
    const selectedAttributeId = new Set<string>();
    rowData.forEach((item) => {
      selectedAttributeId.add(item.attribute.value.toString());
    });
    return selectedAttributeId;
  }, [rowData]);

  const columnsAttribute = useMemo<GridColumns>(() => {
    return [
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Edit',
        width: 30,
        cellClassName: 'actions',

        getActions: (params) => {
          return [
            // @ts-ignore
            <GridActionsCellItem
              icon={<EditIcon />}
              key={params.id}
              label='Edit'
              onClick={handleEditClick(params.row)}
            />,
          ];
        },
      },
      {
        field: 'attribute',
        headerName: 'Attribute',
        width: 190,
        editable: true,
        filterable: true,
        sortable: true,
        renderCell: (params) => {
          return <span>{params?.value?.label}</span>;
        },
        valueGetter: (params) => {
          return params.row?.attribute;
        },

        filterOperators: getGridStringOperators().filter((operator) => ['contains', 'equals'].includes(operator.value)),
      },

      {
        field: 'ruleType',
        headerName: 'Rule',
        width: 280,
        editable: true,
        filterable: false,
        sortable: false,
        renderCell: (params) => {
          return <span>{params?.value?.label}</span>;
        },
        valueGetter: (params) => {
          return params?.row?.ruleType;
        },
      },
      {
        field: 'sourceAttribute',
        headerName: 'Source Attribute',
        width: 190,
        editable: true,
        filterable: true,
        sortable: true,
        renderCell: (params) => {
          return <span>{params?.value?.label}</span>;
        },
        valueGetter: (params) => {
          return params.row?.sourceAttribute;
        },

        filterOperators: getGridStringOperators().filter((operator) => ['contains', 'equals'].includes(operator.value)),
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnSortModelChange = (model: GridSortModel) => {
    if (model.length === 0) {
      setFilter({ ...filter, sortType: 'asc' });
      return;
    }
    setFilter({ ...filter, sortType: model[0].sort });
  };

  const handleOnFilterModelChange = (params: GridFilterModel) => {
    let filterObj = {};
    if (params.items.length) {
      filterObj = {
        filterValue: params.items[0].value,
        filterOperator: params.items[0].operatorValue,
      };
    } else {
      filterObj = {
        filterValue: '',
        filterOperator: 'contains',
      };
    }
    setFilter({
      ...filter,
      ...filterObj,
    });
  };

  const handleChangePage = (newPage: number) => {
    setFilter({ ...filter, page: newPage });
  };

  const handleChangePageSize = (newPageSize: number) => {
    setFilter({ ...filter, size: newPageSize });
  };

  const handleOnAddButtonClick = () => {
    setShowModalAddAttributeDefinition(true);
  };

  const handleOnNewAttributeDefinition = (attributeDefinition: StandardViewAttributeItem) => {
    const updatedRowData = rowData.map((item) =>
      item.attribute.value === attributeDefinition.attribute.value ? attributeDefinition : item
    );

    if (!updatedRowData.some((item) => item.attribute.value === attributeDefinition.attribute.value)) {
      updatedRowData.push({
        ...attributeDefinition,
        columnIndexOrder: updatedRowData.length + 1,
      });
    }

    setRowData(updatedRowData);
  };

  const handleOnDeleteAttributeDefinition = (attributeDefinition: StandardViewAttributeItem) => {
    const updatedRowData = rowData.filter((item) => item.attribute.value !== attributeDefinition.attribute.value);
    setRowData(updatedRowData);
  };

  const handleOnModalAddAttributeDefinitionClose = () => {
    setShowModalAddAttributeDefinition(false);
    setModalInitialData(null);
  };

  const handleOnModalConfigClose = () => {
    setShowModalConfigAttributeViewOrder(false);
  };

  const handleOnSaveOrder = (data: StandardViewAttributeItem[]) => {
    setRowData(data);
    handleOnModalConfigClose();
  };

  const handleOnButtonConfigClick = (optionType: string) => {
    if (optionType === VIEW_ATTRIBUTE_DEFINITION_OPTIONS_MENU.OPTION_COLUMN_ORDER) {
      setShowModalConfigAttributeViewOrder(true);
    }
  };

  useImperativeHandle(ref, () => ({
    getRowData: () => rowData,
    setRowData: (data: StandardViewAttributeItem[]) => {
      setRowData(data);
    },
  }));

  useEffect(() => {
    if (standardViewDetails?.data) {
      setRowData(standardViewDetails?.data?.attributeDefinitions);
    }
  }, [standardViewDetails?.data]);

  const getRowId = useCallback((row) => {
    return row.attribute.value;
  }, []);

  return (
    <>
      <StandardViewAttributeDefinitionPanel
        onAddButtonClick={handleOnAddButtonClick}
        onConfigButtonClick={handleOnButtonConfigClick}
        disabled={disabled}
      />
      <CustomDataTable
        rows={rowData}
        checkboxSelection={false}
        columns={columnsAttribute}
        getRowId={getRowId}
        disableSelectionOnClick
        sortingMode='client'
        filterMode='client'
        paginationMode='client'
        isRowSelectable={(row) => {
          return !row.row.locked;
        }}
        isCellEditable={(params) => {
          return !params?.row?.locked;
        }}
        onSortModelChange={handleOnSortModelChange}
        onFilterModelChange={handleOnFilterModelChange}
        onPageChange={handleChangePage}
        onPageSizeChange={handleChangePageSize}
        rowHeight={45}
        autoHeight={rowData.length !== 0 && rowData.length > 5}
        sx={{ ...overrideTableStyle, opacity: disabled ? 0.5 : 1, pointerEvents: disabled ? 'none' : 'auto' }}
        onProcessRowUpdateError={(params) => console.log({ params })}
        getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
        experimentalFeatures={{ newEditingApi: true }}
        components={{
          Toolbar: null,
          Pagination: null,
        }}
      />
      <ModalAddAttributeDefinition
        initialData={modalInitialData}
        selectedAttributeId={setSelectedAttributeId}
        visible={showModalAddAttributeDefinition}
        onClose={handleOnModalAddAttributeDefinitionClose}
        subjectId={subjectId}
        onSave={handleOnNewAttributeDefinition}
        onDelete={handleOnDeleteAttributeDefinition}
        cubeId={cubeId}
      />
      <ModalConfigAttributeViewOrder
        open={showModalConfigAttributeViewOrder}
        onClose={handleOnModalConfigClose}
        attributes={rowData}
        onSave={handleOnSaveOrder}
      />
    </>
  );
});
