import { AxiosResponse } from 'axios';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import qs from 'query-string';
import axios from 'libs/Axios';

import {
  AttributeListResponse,
  AttributeDataUpsertRequest,
  AttributeUpsertResponse,
} from 'types/api/SystemTenant/AROKMS/AttributeTypes';
import { AutoCompleteItem } from 'types/api/Common/AutoCompleteTypes';
import { PaginationAndSortingParams } from 'types/api/Common/PaginationTypes';
import { AxiosDefaultErrorEntity } from 'types/api/Common/ErrorTypes';

import { WithOptimisticUpdate } from 'utils/WithOptimisticUpdate';

export const ATTRIBUTE_QUERY_KEY = {
  ATTRIBUTE_LIST: 'ATTRIBUTE_LIST',
  ATTRIBUTE_DROPDOWN: 'ATTRIBUTE_DROPDOWN',
  ATTRIBUTE_DROPDOWN_TABLE_DEFINITION: 'ATTRIBUTE_DROPDOWN_TABLE_DEFINITION',
};

interface AttributeDropdownParams extends AutoCompleteItem {
  dataType: string | null;
  persistentName: string | null;
  displayName: string | null;
}

export function useAttributeDropdown(params?: {
  tableDefinitionSubjectId?: string | number | undefined;
  dataTypeId?: string | number | undefined;
  ruleTypeId?: string | number | undefined;
}) {
  return useQuery<AxiosResponse<AttributeDropdownParams[]>, AxiosDefaultErrorEntity>(
    [ATTRIBUTE_QUERY_KEY.ATTRIBUTE_DROPDOWN, { ...params }],
    () => axios.get(`/api/v1/attribute/dropdown?${qs.stringify({ ...params })}`).then((res) => res)
  );
}

export function useAttributeDropdownTableDefinition(tableDefinitionSubjectId?: string | number | undefined) {
  return useQuery<AxiosResponse<AutoCompleteItem[]>, AxiosDefaultErrorEntity>(
    [ATTRIBUTE_QUERY_KEY.ATTRIBUTE_DROPDOWN_TABLE_DEFINITION, tableDefinitionSubjectId],
    () => axios.get(`/api/v1/attribute/dropdown/table-definition/${tableDefinitionSubjectId}`).then((res) => res),
    {
      enabled: !!tableDefinitionSubjectId,
    }
  );
}

export function useAttributeList(params?: PaginationAndSortingParams) {
  return useQuery<AxiosResponse<AttributeListResponse>, AxiosDefaultErrorEntity>(
    [ATTRIBUTE_QUERY_KEY.ATTRIBUTE_LIST],
    () => axios.get(`/api/v1/attribute/list`).then((res) => res)
  );
}

export function useUpsertAttributeData(filter?: PaginationAndSortingParams) {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse<AttributeUpsertResponse>, AxiosDefaultErrorEntity, AttributeDataUpsertRequest>(
    (bodyRequest) => axios.post('/api/v1/attribute/upsert', bodyRequest),
    {
      onMutate: async (bodyRequest) =>
        WithOptimisticUpdate<AttributeDataUpsertRequest>(
          bodyRequest,
          [ATTRIBUTE_QUERY_KEY.ATTRIBUTE_LIST],
          queryClient,
          'UPDATE'
        ),
      onError: (err, variables, context) => {
        //@ts-ignore
        if (context?.previousData) {
          //@ts-ignore
          queryClient.setQueryData(context?.query, context.previousData);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries([ATTRIBUTE_QUERY_KEY.ATTRIBUTE_LIST]);
        queryClient.invalidateQueries([ATTRIBUTE_QUERY_KEY.ATTRIBUTE_DROPDOWN]);
      },
    }
  );
}

export function useDeleteAttributeData(filter: PaginationAndSortingParams) {
  const listQuery = [ATTRIBUTE_QUERY_KEY.ATTRIBUTE_LIST];
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosDefaultErrorEntity, { id: number | string }>(
    (bodyRequest) => axios.delete(`/api/v1/attribute/delete?attributeId=${bodyRequest.id}`),
    {
      onMutate: async (bodyRequest) =>
        WithOptimisticUpdate<{ id: string | number }>(bodyRequest, listQuery, queryClient, 'DELETE'),
      onError: (err, variables, context) => {
        //@ts-ignore
        if (context?.previousData) {
          //@ts-ignore
          queryClient.setQueryData(context?.query, context.previousData);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(listQuery);
        queryClient.invalidateQueries([ATTRIBUTE_QUERY_KEY.ATTRIBUTE_DROPDOWN]);
      },
    }
  );
}
