import { GridRowModesModel, GridRenderCellParams } from '@mui/x-data-grid-pro';
import Button from '@mui/material/Button';
import { CubeDefinitionRowItem } from 'types/api/SystemTenant/AROCube/CubeBuilderTypes';

interface SubjectProcedureFieldActionButtonProps extends GridRenderCellParams<CubeDefinitionRowItem> {
  setRowModesModel: (newModel: (oldModel: GridRowModesModel) => GridRowModesModel) => void;
  fieldName: string | null;
  setModaProcedureField: (
    newState: (OldState: { open: boolean; procedureId: string | undefined }) => {
      open: boolean;
      procedureId: string | undefined;
    }
  ) => void;
  disabled?: boolean;
}

export function SubjectProcedureFieldActionButton(props: SubjectProcedureFieldActionButtonProps) {
  const { hasFocus, row, setModaProcedureField: setModalParameterState, fieldName, disabled = false } = props;

  if (!fieldName || row?.isNew) return <span></span>;

  const handleOnClick = () => {
    if (fieldName) {
      setModalParameterState((prevState) => ({ ...prevState, open: true, procedureId: row.id }));
    }
  };

  return (
    <Button
      variant='secondary-table-panel'
      sx={{
        height: '58%',
        fontSize: '12px',
      }}
      onClick={handleOnClick}
      tabIndex={hasFocus ? 0 : -1}
      disabled={disabled}
      onKeyDown={(event: React.KeyboardEvent) => {
        if (event.key === ' ') {
          event.stopPropagation();
        }
      }}
    >
      {Boolean(row[fieldName]) ? 'Edit' : 'Add'}
    </Button>
  );
}
