import { AxiosResponse } from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import axios from 'libs/Axios';
import { AxiosDefaultErrorEntity } from 'types/api/Common/ErrorTypes';
import { AutoCompleteItem } from 'types/api/Common/AutoCompleteTypes';
import {
  SubjectProcedureDataUpsertRequest,
  SubjectProcedureFieldRowItem,
  SubjectProcedureRowItem,
} from 'types/api/SystemTenant/AROKMS/SubjectProcedureTypes';

export interface SubjectDropdownParameter extends AutoCompleteItem {
  persistentName?: string | null;
}

const SUBJECT_PROCEDURE_QUERY = {
  SUBJECT_PROCEDURE_LIST: 'SUBJECT_PROCEDURE_LIST',
  SUBJECT_PROCEDURE_DETAILS: 'SUBJECT_PROCEDURE_DETAILS',
  SUBJECT_PROCEDURE_FIELDS: 'SUBJECT_PROCEDURE_FIELDS',
};

export function useUpsertSubjectProcedureData() {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosDefaultErrorEntity, SubjectProcedureDataUpsertRequest>(
    (bodyRequest) => axios.post('/api/v1/subject-procedure/upsert', bodyRequest),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([SUBJECT_PROCEDURE_QUERY.SUBJECT_PROCEDURE_LIST]);
        queryClient.invalidateQueries([SUBJECT_PROCEDURE_QUERY.SUBJECT_PROCEDURE_FIELDS]);
      },
    }
  );
}

export function useSubjectProcedureList(subjectId?: string) {
  return useQuery<AxiosResponse<SubjectProcedureRowItem[]>, AxiosDefaultErrorEntity>(
    [SUBJECT_PROCEDURE_QUERY.SUBJECT_PROCEDURE_LIST, subjectId],
    () => axios.get(`/api/v1/subject-procedure/list/${subjectId}`).then((res) => res),
    {
      enabled: !!subjectId,
    }
  );
}

export function useSubjectProcedureDetails(procedureId?: string) {
  return useQuery<AxiosResponse<SubjectProcedureRowItem>, AxiosDefaultErrorEntity>(
    [SUBJECT_PROCEDURE_QUERY.SUBJECT_PROCEDURE_DETAILS, procedureId],
    () => axios.get(`/api/v1/subject-procedure/${procedureId}`).then((res) => res),
    {
      enabled: !!procedureId,
    }
  );
}

export function useGetSubjectProcedureFields(procedureId?: string) {
  return useQuery<AxiosResponse<SubjectProcedureFieldRowItem[]>, AxiosDefaultErrorEntity>(
    [SUBJECT_PROCEDURE_QUERY.SUBJECT_PROCEDURE_FIELDS, procedureId],
    () => axios.get(`/api/v1/subject-procedure/fields/${procedureId}`).then((res) => res),
    {
      enabled: !!procedureId,
    }
  );
}

export function useDeleteSubjectProcedure() {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosDefaultErrorEntity, { id: number | string }>(
    (bodyRequest) => axios.delete(`/api/v1/subject-procedure/${bodyRequest.id}`),
    {
      onSettled: () => {
        queryClient.invalidateQueries([SUBJECT_PROCEDURE_QUERY.SUBJECT_PROCEDURE_LIST]);
        queryClient.invalidateQueries([SUBJECT_PROCEDURE_QUERY.SUBJECT_PROCEDURE_FIELDS]);
        queryClient.invalidateQueries([SUBJECT_PROCEDURE_QUERY.SUBJECT_PROCEDURE_DETAILS]);
      },
    }
  );
}
