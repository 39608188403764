import React from 'react';
import Typography from '@mui/material/Typography';
import ActivityIndicator from 'components/ActivityIndicatorComponent';
import CompareArrowsOutlinedIcon from '@mui/icons-material/CompareArrowsOutlined';
import ButtonLoading from '@mui/lab/LoadingButton';
import { Autocomplete, Stack, TextField, Divider, Box, Alert, Avatar } from '@mui/material';
import { toast } from 'react-toastify';
import {
  useEventDataSourceDisconnect,
  useGetEventDataSourceConfigDetails,
  useGetEventDataChannelListPairingColumns,
  useGetLastSyncHistory,
  useInsertEventDataSourceMapping,
} from 'services/v1/Tenant/AROEvent/EventDataSourceService';
import {
  EventDataChannel,
  IColumn,
  RequestMappingEventExternalDataSource,
} from 'types/api/Tenant/EventDataSourceTypes';
import { AutoCompleteItem } from 'types/api/Common/AutoCompleteTypes';
import { SubjectItem } from 'types/api/SystemTenant/AROKMS/SubjectTypes';
import { PairsList } from './../PairListComponent';
import { ListColumn, PairListColumnRef } from './../ListColumnComponent';
import { DataSourcePreviewComponent } from './../DataSourcePreviewComponent';
import { ModalConfirmationComponent } from 'components/ModalComponent/ModalConfirmationComponent';

export type ModalMappingEventDataSourceProps = {
  onClose?: () => void;
  isEdit: boolean;
  eventSubject?: SubjectItem;
  dataChannel: EventDataChannel;
};

export type PairItem = {
  sourceColumn: IColumn;
  targetColumn: IColumn;
};

const autocompleteStyles = {
  width: '55%',
  '& .MuiOutlinedInput-root': {
    padding: '1px 2px',
  },
  '& .MuiAutocomplete-popper': {
    fontSize: '10px',
  },
};
const textInputAutoCompleteStyles = {
  '& .MuiOutlinedInput': {
    padding: '1px 2px',
  },
};

export function PairingColumns(props: ModalMappingEventDataSourceProps) {
  const { onClose, eventSubject, isEdit, dataChannel } = props;

  const [selectedDataSourceTable, setSelectedDataSourceTable] = React.useState<AutoCompleteItem | null>(null);
  const [pairItemList, setPairItemList] = React.useState<PairItem[]>([]);
  const [pairKey, setPairKey] = React.useState<PairItem | null>(null);
  const [currentSelectedSourceColumn, setCurrentSelectedSourceColumn] = React.useState<IColumn | null>(null);
  const [currentSelectedTargetColumn, setCurrentSelectedTargetColumn] = React.useState<IColumn | null>(null);
  const [showDisconnectModal, setShowDisconnectModal] = React.useState<boolean>(false);
  const pairSourceColumnRef = React.useRef<PairListColumnRef>(null);
  const pairTargetColumnRef = React.useRef<PairListColumnRef>(null);
  const { data: eventDataSourceConfigDetails } = useGetEventDataSourceConfigDetails(
    isEdit ? eventSubject?.id?.toString() : undefined
  );
  const {
    data: listEventSourceColumns,
    isLoading: isLoadingEventSourceColumns,
    isSuccess: isEventSourceColumnsLoaded,
  } = useGetEventDataChannelListPairingColumns(eventSubject?.id?.toString(), dataChannel.id);
  const { mutate: disconnectEventDataSource } = useEventDataSourceDisconnect();
  const { remove: resetStatus } = useGetLastSyncHistory();
  const { mutate: insertEventDataSourceMapping, isLoading: isInserting } = useInsertEventDataSourceMapping();

  const sourceColumns = React.useMemo(() => {
    if (!listEventSourceColumns?.data.columnSource) return [];
    return listEventSourceColumns?.data?.columnSource?.filter((item) => {
      return !pairItemList.find((pair) => pair.sourceColumn.id === item.id);
    });
  }, [listEventSourceColumns?.data.columnSource, pairItemList]);

  const targetColumns = React.useMemo(() => {
    if (!listEventSourceColumns?.data.targetColumns) return [];
    return listEventSourceColumns?.data?.targetColumns?.filter((item) => {
      return !pairItemList.find((pair) => pair.targetColumn.id === item.id);
    });
  }, [listEventSourceColumns?.data.targetColumns, pairItemList]);

  const handleOnChangeSourceColumn = (value: IColumn) => {
    if (currentSelectedSourceColumn?.id === value.id) {
      setCurrentSelectedSourceColumn(null);
      return;
    }
    setCurrentSelectedSourceColumn(value);
  };

  const handleOnChangeTargetColumn = async (value: IColumn) => {
    if (currentSelectedTargetColumn?.id === value.id) {
      setCurrentSelectedTargetColumn(null);
      return;
    }
    setCurrentSelectedTargetColumn(value);
  };
  const handleOnMakePair = async () => {
    if (!currentSelectedSourceColumn && !currentSelectedTargetColumn) return;
    const newPairItem = {
      sourceColumn: currentSelectedSourceColumn,
      targetColumn: currentSelectedTargetColumn,
    };
    if (newPairItem.sourceColumn?.id && newPairItem.targetColumn?.id) {
      // @ts-ignore
      setPairItemList((oldPairItemList) => [...oldPairItemList, newPairItem]);
    }
    setCurrentSelectedSourceColumn(null);
    setCurrentSelectedTargetColumn(null);
    pairSourceColumnRef.current?.resetSearch();
    pairTargetColumnRef.current?.resetSearch();
  };

  React.useEffect(() => {
    if (currentSelectedSourceColumn && currentSelectedTargetColumn) {
      handleOnMakePair();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSelectedSourceColumn, currentSelectedTargetColumn]);

  const handleOnUnpair = (value: PairItem) => {
    setPairItemList((oldPairItemList) => oldPairItemList.filter((item) => item !== value));
  };

  const handleOnSubmit = () => {
    if (eventSubject?.id && pairKey) {
      const payload: RequestMappingEventExternalDataSource = {
        pairItems: pairItemList,
        pairColumnKey: pairKey,
        dataChannelId: dataChannel.id,
        eventSubjectId: eventSubject.id.toString(),
      };
      insertEventDataSourceMapping(payload, {
        onSuccess: () => {
          handleOnClose();
        },
      });
    }
  };

  const handleOnDataSourceDisconnectClick = () => {
    if (eventSubject?.id) {
      disconnectEventDataSource(
        {
          eventSubjectId: eventSubject?.id?.toString(),
        },
        {
          onSuccess: () => {
            resetStatus();
            setShowDisconnectModal(false);
            handleOnClose();
            toast.success(`Event ${eventSubject?.displayName} data source disconnected successfully!`);
          },
        }
      );
    }
  };

  const handleOnClose = () => {
    onClose?.();
    setSelectedDataSourceTable(null);
    setPairItemList([]);
  };

  React.useEffect(() => {
    setCurrentSelectedSourceColumn(null);
    setCurrentSelectedTargetColumn(null);
  }, [selectedDataSourceTable]);

  React.useEffect(() => {
    if (eventDataSourceConfigDetails) {
      setPairItemList(eventDataSourceConfigDetails.data?.pairItemList || []);
      setPairKey(eventDataSourceConfigDetails.data?.pairingKey || null);
      setSelectedDataSourceTable(eventDataSourceConfigDetails?.data?.dataSourceTable);
    }
  }, [eventDataSourceConfigDetails]);

  const renderPairingKeyOptionItem = (props: React.HTMLAttributes<HTMLLIElement>, option: PairItem) => (
    <Box component='li' {...props}>
      <Stack>
        <Stack direction='row' alignItems='center' gap={2}>
          <Typography variant='body2' fontWeight={700}>
            {option.sourceColumn.columnName}{' '}
          </Typography>
          <CompareArrowsOutlinedIcon sx={{ color: '#3B4797', fontSize: '20px !important' }} />
          <Typography variant='body2' fontWeight={700}>
            {option.targetColumn.columnName}{' '}
          </Typography>
        </Stack>
        <Typography variant='input-label-gray' fontSize={12}>
          {`Syncronize data source table row with ${eventSubject?.displayName} row by ${option.sourceColumn.columnName} column.`}
        </Typography>
        <Divider />
      </Stack>
    </Box>
  );

  const getSourceColumnsWarningMessage = () => {
    return targetColumns.length !== 0
      ? "All items in this list must be paired with an item in the 'Source Columns' list."
      : undefined;
  };

  return (
    <Stack>
      <Stack mt={2} px={3} py={2} gap={2}>
        {isLoadingEventSourceColumns ? (
          <Stack sx={{ height: 400 }}>
            <ActivityIndicator />
          </Stack>
        ) : (
          <Stack width='100%'>
            <Stack mb={2}>
              <Alert severity='info'>
                <Typography variant='input-label' fontSize={16} fontWeight={800}>
                  {dataChannel.name}
                </Typography>
                <Stack direction='row' alignItems='center' gap={2} mt={1}>
                  {dataChannel.logoUrl && (
                    <Avatar
                      variant='square'
                      alt={dataChannel.name}
                      src={dataChannel.logoUrl}
                      imgProps={{ style: { objectFit: 'inherit' } }}
                      sx={{ width: 200, height: 50, mr: 1, objectFit: 'contain' }}
                    />
                  )}
                  {dataChannel.description}
                </Stack>
              </Alert>
            </Stack>
            {isEventSourceColumnsLoaded && (
              <>
                <Stack>
                  <Stack>
                    <Typography variant='input-label' fontSize={16} fontWeight={800}>
                      Preview Data
                    </Typography>
                    <Typography variant='input-label-gray' fontSize={14}>
                      {`Explore available data with a preview from the ${dataChannel.name}.`}
                    </Typography>
                  </Stack>
                  <DataSourcePreviewComponent dataChannelId={dataChannel.id} />
                </Stack>
                <Divider />
                <Stack mt={3}>
                  <Typography variant='input-label' fontSize={16} fontWeight={800}>
                    Column Pairing
                  </Typography>
                  <Typography variant='input-label-gray' fontSize={14}>
                    {`Connect and integrate by pairing columns between the ${dataChannel.name} and the ${eventSubject?.displayName}.`}
                  </Typography>
                </Stack>
                <Stack mt={2} direction='row' justifyContent='space-between' width='100%'>
                  <ListColumn
                    title='Target Columns'
                    warningMessage={getSourceColumnsWarningMessage()}
                    otherPairValue={currentSelectedSourceColumn}
                    items={targetColumns}
                    subheader={`${targetColumns.length} unpair columns`}
                    value={currentSelectedTargetColumn}
                    ref={pairTargetColumnRef}
                    onChange={handleOnChangeTargetColumn}
                  />
                  <ListColumn
                    title='Source Columns'
                    otherPairValue={currentSelectedTargetColumn}
                    items={sourceColumns}
                    value={currentSelectedSourceColumn}
                    ref={pairSourceColumnRef}
                    subheader={`${sourceColumns.length} unpair columns`}
                    onChange={handleOnChangeSourceColumn}
                  />
                  <PairsList items={pairItemList} title='Pairs' subheader='Paired columns' onUnpair={handleOnUnpair} />
                </Stack>

                <Divider />
                <Stack mt={3} mb={10}>
                  <Typography variant='input-label' fontSize={16} fontWeight={800}>
                    Select Pairing Key
                  </Typography>
                  <Typography variant='input-label-gray' fontSize={14}>
                    {`The pairing key is a designated column that uniquely identifies and matches records from the ${dataChannel.name} 
                                  with the ${eventSubject?.displayName}.`}
                  </Typography>
                  <Autocomplete
                    sx={{
                      ...autocompleteStyles,
                      width: '30%',
                      maxHeight: '50px',
                    }}
                    disabled={pairItemList.length === 0}
                    options={pairItemList || []}
                    clearIcon={null}
                    getOptionLabel={(option) => `${option.sourceColumn.columnName} - ${option.targetColumn.columnName}`}
                    value={pairKey}
                    onChange={(event, value) => {
                      setPairKey(value);
                    }}
                    renderOption={renderPairingKeyOptionItem}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          sx={textInputAutoCompleteStyles}
                          value={`${params}`}
                          placeholder='Select pairing key table...'
                        />
                      );
                    }}
                  />
                </Stack>
              </>
            )}

            <Stack direction='row' mt={10} mb={3} justifyContent='space-between' alignItems='center'>
              <Stack />
              <Stack direction='row' gap={2}>
                {isEdit && (
                  <ButtonLoading
                    sx={{
                      textTransform: 'none',
                    }}
                    variant='outlined'
                    onClick={() => {
                      setShowDisconnectModal(true);
                    }}
                    color='error'
                    disabled={isInserting}
                  >
                    Disconnect
                  </ButtonLoading>
                )}
                <ButtonLoading variant='main-table-panel-border' disabled={isInserting} onClick={handleOnClose}>
                  Cancel
                </ButtonLoading>
                <ButtonLoading
                  variant='main-table-panel'
                  onClick={handleOnSubmit}
                  disabled={!pairKey || !dataChannel.id || !eventSubject?.id}
                  loading={isInserting}
                >
                  {isEdit ? 'Update' : 'Connect Channel'}
                </ButtonLoading>
              </Stack>
            </Stack>
          </Stack>
        )}
      </Stack>
      <ModalConfirmationComponent
        visible={showDisconnectModal}
        title={`Disconnect Data Channel from ${eventSubject?.displayName}`}
        width={600}
        onApprove={handleOnDataSourceDisconnectClick}
        onCancel={() => setShowDisconnectModal(false)}
        onClose={() => setShowDisconnectModal(false)}
        message={`Disconnecting the data channel from ${eventSubject?.displayName} will halt daily data synchronization.
              \n All previously synchronized data will remain intact and accessible within the ${eventSubject?.displayName}. You'll have the option to manually edit or delete the data.
              \n Are you sure you want to proceed with disconnecting the ${dataChannel.name} from the ${eventSubject?.displayName}?`}
        labelButtonApprove='Disconnect'
      />
    </Stack>
  );
}
