import { useRef, useEffect } from 'react';

import * as yup from 'yup';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { Box, Grid, Stack, Typography } from '@mui/material';
import ButtonLoading from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Formik, FormikProps } from 'formik';
import { TenantHomeDashboardNavigationBar } from 'components/LayoutComponent/VerticalLayout/HeaderBar';
import { ButtonBackToTop } from 'components/ButtonComponent/ButtonBackToTop';
import { PATH_CONSTANT } from 'constant/PathConstant';
import {
  useGetDisplayTableDetails,
  useUpdateDisplayTableData,
} from 'services/v1/TenantMaster/MasterTable/DisplayTableDataService';
import ActivityIndicator from 'components/ActivityIndicatorComponent';

const textInputStyles = {
  width: '55%',
  '& .MuiOutlinedInput': {
    padding: '1px 2px',
  },
};

const validationSchema = yup.object().shape({
  level: yup
    .number()
    .min(0, 'Level must be between 0 and 5')
    .max(5, 'Level must be between 0 and 5')
    .required('Level is required'),
});

interface FormValue {
  subject: string;
  level: number | string | null;
}

const formInitialValues: FormValue = {
  subject: '',
  level: null,
};
export default function EditServerTableData() {
  const { id } = useParams();
  const formikRef = useRef<FormikProps<FormValue>>(null);
  const navigate = useNavigate();

  const { data: displayTableDetails, isLoading } = useGetDisplayTableDetails(id);
  const { mutate: updateDisplayTableData, isLoading: isDeploying } = useUpdateDisplayTableData();

  const handleOnSave = (data: FormValue) => {
    updateDisplayTableData(
      {
        adminRightLevel: Number(data.level),
        subjectId: displayTableDetails?.data.id.toString() || '',
      },
      {
        onSuccess: () => {
          toast.success('Data has been updated successfully!');
          handleOnCancelInput();
        },
      }
    );
  };

  const isSemiLoading = isDeploying;

  const handleOnCancelInput = () => {
    navigate(PATH_CONSTANT.TENANT_MASTER.DISPLAY_TABLE);
  };

  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.setValues({
        subject: displayTableDetails?.data.displayName || '',
        level: Number(displayTableDetails?.data?.adminRightLevel) || 0,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayTableDetails, formikRef]);

  return (
    <TenantHomeDashboardNavigationBar>
      <ButtonBackToTop />
      <Box sx={{ height: '100%' }}>
        {isLoading ? (
          <Stack
            direction='column'
            sx={{
              height: 500,
            }}
            justifyContent='center'
          >
            <ActivityIndicator />
          </Stack>
        ) : (
          <Formik
            validationSchema={validationSchema}
            innerRef={formikRef}
            initialValues={formInitialValues}
            onSubmit={handleOnSave}
          >
            {(formikProps: FormikProps<FormValue>) => {
              const { handleSubmit, values, handleChange, errors, touched, handleBlur } = formikProps;
              // @ts-ignore
              return (
                <Box sx={{ backgroundColor: '#fff', pb: 3, px: 3 }} alignItems='center'>
                  <Stack
                    direction='row'
                    alignItems='center'
                    py={1}
                    justifyContent='space-between'
                    sx={{ backgroundColor: '#fff' }}
                    spacing={2}
                  >
                    <Stack>
                      <Typography variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
                        Edit Table Display
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack>
                    <Typography variant='input-label' fontStyle='italic' sx={{ pt: 1, fontSize: 13 }}>
                      (<span style={{ color: 'red' }}>*</span>) indicates required fields
                    </Typography>
                  </Stack>

                  <Grid
                    container
                    marginTop={2}
                    sx={{ backgroundColor: '#fff', py: 1, borderTop: '1px solid #E3EBF6' }}
                    justifyContent='space-between'
                    display='flex'
                    flexWrap='wrap'
                    alignItems='flex-start'
                  >
                    <Grid container item xs={6} direction='column'>
                      <Stack>
                        <Stack
                          direction='row'
                          spacing={2}
                          alignItems='center'
                          justifyContent='space-between'
                          sx={{ mt: 2 }}
                        >
                          <Typography variant='input-label'>Subject</Typography>
                          <TextField
                            sx={textInputStyles}
                            size='small'
                            disabled
                            placeholder='Subject'
                            onChange={handleChange}
                            value={values.subject}
                            onBlur={handleBlur}
                            name='description'
                            error={touched.subject && Boolean(errors.subject)}
                            helperText={touched.subject && errors.subject}
                          />
                        </Stack>

                        <Stack
                          direction='row'
                          spacing={2}
                          alignItems='center'
                          justifyContent='space-between'
                          sx={{ mt: 2 }}
                        >
                          <Typography variant='input-label'>
                            Level
                            <span style={{ color: 'red', marginLeft: 2 }}>*</span>
                          </Typography>
                          <TextField
                            sx={textInputStyles}
                            size='small'
                            disabled={isSemiLoading}
                            type='text'
                            onChange={(e) => {
                              const value = e.target.value;
                              const positiveNumberRegex = /^[0-9]*$/;
                              if (value === '' || positiveNumberRegex.test(value)) {
                                handleChange(e);
                              } else {
                                e.target.value = '';
                              }
                            }}
                            onBlur={handleBlur}
                            placeholder='Minimum admin rights level'
                            value={values?.level}
                            name='level'
                            error={touched.level && Boolean(errors.level)}
                            helperText={touched.level && errors.level}
                          />
                        </Stack>
                      </Stack>
                    </Grid>
                  </Grid>
                  <Stack direction='row' spacing={2} justifyContent='flex-end'>
                    <Button disabled={isDeploying} onClick={handleOnCancelInput} variant='main-table-panel-border'>
                      Cancel
                    </Button>

                    <ButtonLoading
                      disabled={!validationSchema.isValidSync(values) || isDeploying}
                      variant='main-table-panel'
                      loading={isDeploying}
                      onClick={() => handleSubmit()}
                    >
                      Save
                    </ButtonLoading>
                  </Stack>
                </Box>
              );
            }}
          </Formik>
        )}
      </Box>
    </TenantHomeDashboardNavigationBar>
  );
}
