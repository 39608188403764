import { useState, useEffect, useMemo } from 'react';
import { useIsMutating } from 'react-query';
import Stack from '@mui/material/Stack';
import Typograhpy from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import Divider from '@mui/material/Divider';
import { GridRowModesModel, GridRowsProp, GridRowModes, useGridApiContext } from '@mui/x-data-grid-pro';
import { FILE_TYPE_PARAMS } from 'constant/ImportExportConstant';
import { ButtonExport, FileType } from 'components/ButtonComponent/ButtonExport';
import { ButtonDownloadTemplate } from 'components/ButtonComponent/ButtonDownloadTemplate';
import { ButtonImport } from 'components/ButtonComponent/ButtonImport';
import { GridToolbarDensitySelector } from 'components/ButtonComponent/ButtonDensity';
import { GridToolbarFilterButton } from 'components/ButtonComponent/ButtonFilter';
import { RoleBasedAccessProvider } from 'components/RBAC';
import SyncAltOutlinedIcon from '@mui/icons-material/SyncAltOutlined';

// Custom Icon
import { ROLES } from 'constant/PermissonConstant';
import {
  ButtonTableOptions,
  DataInputTableOptions,
  EventTableOptions,
} from 'components/ButtonComponent/ButtonTableOptions';
import { useGetLastSyncHistory } from 'services/v1/Tenant/AROEvent/EventDataSourceService';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import {
  EVENT_SYNC_DATA_SOURCE_CONFIG_STATUS,
  EVENT_SYNC_DATA_SOURCE_STATUS,
  SUBJECT_TYPE,
} from 'constant/DataInputConstant';
import { SystemStatusBasedAccessProvider } from 'components/RBAC/SystemStatusBasedAccessProvider';
import { useGetSubjectDetails } from 'services/v1/SystemTenant/AROKMS/SubjectService';
import { useGetCurrentImportActivity } from 'services/v1/Tenant/AROKMS/DisplayTableService';
import { DataInputImportActivityStatus } from 'types/api/Tenant/AROKMS/DisplayTableTypes';
import { generateColumnNames, handleExport } from 'utils/ExportUtils';
import { ButtonTableAnalytics, FormTableAnalyticParameter } from 'components/ButtonComponent/ButtonTableAnalytic';

interface SettingPanelConfigProps {
  insertButtonText?: string;
  insertInitialObject?: { [key: string]: string | number | boolean };
  fieldFocusOnInsert: string;
  deleteButtonText?: string;
}

interface OptionItem {
  value: string;
  label: string;
}

interface DataInputSettingPanelProps {
  onDeleteData?: () => void;
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (newModel: (oldModel: GridRowModesModel) => GridRowModesModel) => void;
  setFilterButtonElement: () => void;
  config: SettingPanelConfigProps;
  isInserting: boolean;
  title: string;
  options: OptionItem[];
  initialOption?: OptionItem | undefined;
  viewOnly?: boolean;
  withChipLabel?: boolean;
  optionValue: OptionItem;
  disabled: boolean;
  onOptionChange: (value: OptionItem) => void;
  onExport: (selectedFileType: FileType) => void;
  onImport: (selectedFileType: FileType) => void;
  onButtonTemplateClick: (selectedFileType: FileType) => void;
  disabledDeleteButton?: boolean;
  onAddDataClick?: () => void;
  onOptionClick?: (type: EventTableOptions) => void;
  onButtonTableAnalyticClick?: (data: FormTableAnalyticParameter) => void;
}

const buttonActionStyle = {
  padding: 1.4,
};

export function DataInputSettingPanel(props: DataInputSettingPanelProps) {
  const isMutating = Boolean(useIsMutating());
  const {
    setFilterButtonElement,
    setRows,
    setRowModesModel,
    config,
    disabled,
    viewOnly,
    onImport,
    onButtonTemplateClick,
    onAddDataClick = undefined,
    onButtonTableAnalyticClick,
    onOptionClick = () => {},
  } = props;

  const apiRef = useGridApiContext();
  const { navigationState } = useSelector((state: RootState) => state.home);
  const { selectedTenant } = useSelector((state: RootState) => state.auth);
  const { data: subjectDetails } = useGetSubjectDetails(
    (navigationState?.subject?.value && navigationState?.subject?.value.toString()) || ''
  );
  const [lastSyncStatus, setLastSyncStatus] = useState<string | null>(null);
  const [isConnectedToDataSource, setIsConnectedToDataSource] = useState<boolean>(false);

  const { data: importActivity } = useGetCurrentImportActivity(navigationState?.subject?.value.toString());
  const isOnImportActivityProgress = useMemo(() => {
    return (
      importActivity?.data?.status === DataInputImportActivityStatus.ON_PROGRESS ||
      importActivity?.data?.status === DataInputImportActivityStatus.PENDING
    );
  }, [importActivity?.data?.status]);

  const { data: lastSyncEventData } = useGetLastSyncHistory(
    navigationState?.event?.value?.toString(),
    isConnectedToDataSource && lastSyncStatus !== EVENT_SYNC_DATA_SOURCE_STATUS.COMPLETED ? 5000 : 0
  );
  const { fieldFocusOnInsert = '', insertButtonText = 'Add', insertInitialObject = {} } = config;

  useEffect(() => {
    if (lastSyncEventData?.data) {
      setIsConnectedToDataSource(
        Boolean(
          lastSyncEventData?.data?.dataSourceConfig &&
            lastSyncEventData?.data?.dataSourceConfig.status === EVENT_SYNC_DATA_SOURCE_CONFIG_STATUS.CONNECTED
        )
      );

      setLastSyncStatus(lastSyncEventData?.data?.latestPullHistory?.syncStatus);
    }
  }, [lastSyncEventData, isConnectedToDataSource]);

  const handleAddData = () => {
    if (onAddDataClick) {
      onAddDataClick();
    } else {
      const id = new Date().getTime();
      setRows((oldRows) => [{ id, ...insertInitialObject }, ...oldRows]);
      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: fieldFocusOnInsert },
      }));
    }
  };

  const handleExportCLick = (fileType: string) => {
    if (fileType === FILE_TYPE_PARAMS.EXCEL) {
      const exportConfig = {
        columnNames: generateColumnNames(apiRef),
        keys: generateColumnNames(apiRef),
        sheetName: 'Sheet1',
        fileName: `${subjectDetails?.data?.displayName}_${selectedTenant.tenant.tenantName}.xlsx`,
      };
      handleExport(apiRef, exportConfig);
    }
    if (fileType === FILE_TYPE_PARAMS.CSV) {
      apiRef.current.exportDataAsCsv({
        fileName: `${subjectDetails?.data?.displayName}_${selectedTenant.tenant.tenantName}.csv`,
      });
    }
  };

  return (
    <Stack direction='row' justifyContent='space-between' spacing={0} sx={{ backgroundColor: '#fff', py: 1.8 }}>
      <Stack direction='row' alignItems='center'>
        <RoleBasedAccessProvider
          allowedRoles={[ROLES.BUILDER, ROLES.ADMIN, ROLES.TESTER, ROLES.SUPPORT, ROLES.PACKAGER]}
        >
          <SystemStatusBasedAccessProvider>
            {subjectDetails?.data?.type !== SUBJECT_TYPE.VIEW && !isOnImportActivityProgress && !viewOnly && (
              <Typograhpy variant='subtitle1' sx={{ fontWeight: 600 }}>
                <Button
                  disabled={disabled || isMutating}
                  variant='main-table-panel'
                  startIcon={<AddCircleOutlineOutlinedIcon />}
                  onClick={handleAddData}
                >
                  {insertButtonText}
                </Button>
              </Typograhpy>
            )}
          </SystemStatusBasedAccessProvider>
        </RoleBasedAccessProvider>

        {isOnImportActivityProgress && (
          <Stack direction='row' color='GrayText' alignItems='center'>
            <SyncAltOutlinedIcon sx={{ mr: 0.5 }} fontSize='small' />
            <Typograhpy variant='body2'>{`
       Importing ${importActivity?.data.totalCurrentProcessedRecords ?? 0} of ${
              importActivity?.data.totalRecords ?? 0
            } records ...
   `}</Typograhpy>
          </Stack>
        )}
      </Stack>

      <Stack direction='row' spacing={0} alignContent='center' alignItems='center'>
        <GridToolbarFilterButton
          ref={setFilterButtonElement}
          componentsProps={{
            button: {
              disabled: disabled,
              sx: buttonActionStyle,
              variant: 'main-table-panel-borderless',
            },
          }}
        />
        <GridToolbarDensitySelector sx={buttonActionStyle} variant='main-table-panel-borderless' />
        <ButtonTableAnalytics onDisplayData={onButtonTableAnalyticClick} />
        <Divider orientation='vertical' variant='middle' flexItem sx={{ px: 0.5 }} />
        <ButtonExport disabled={disabled} onClick={handleExportCLick} />
        <RoleBasedAccessProvider
          allowedRoles={[ROLES.BUILDER, ROLES.ADMIN, ROLES.TESTER, ROLES.SUPPORT, ROLES.PACKAGER]}
        >
          <SystemStatusBasedAccessProvider>
            {isConnectedToDataSource === false && subjectDetails?.data?.type !== SUBJECT_TYPE.VIEW && (
              <>
                <ButtonImport disabled={disabled || isOnImportActivityProgress} onClick={onImport} />
                <ButtonDownloadTemplate
                  disabled={disabled || isOnImportActivityProgress}
                  onClick={onButtonTemplateClick}
                />
              </>
            )}
            {subjectDetails?.data?.type !== SUBJECT_TYPE.VIEW && (
              <ButtonTableOptions
                onClick={onOptionClick}
                menuOptionsId={[
                  DataInputTableOptions.CUSTOM_RULES,
                  DataInputTableOptions.IMPORT_DATA_HISTORY,
                  DataInputTableOptions.FUNCTION_TRIGGER,
                ]}
              />
            )}
          </SystemStatusBasedAccessProvider>
        </RoleBasedAccessProvider>
      </Stack>
    </Stack>
  );
}
