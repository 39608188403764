import { AxiosResponse } from 'axios';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import qs from 'query-string';
import axios from 'libs/Axios';

import { PaginationAndSortingParams } from 'types/api/Common/PaginationTypes';
import { AxiosDefaultErrorEntity } from 'types/api/Common/ErrorTypes';

import { TenantTableItemsList } from 'types/api/SystemTenant/TenantTableTypes';
import { TenantUserForDeploy } from 'types/api/TenantMaster/MasterTable/TenantTableDataTypes';

export const TENANT_TABLE_QUERY = {
  TENANT_LIST: 'TENANT_LIST',
  USER_TENANT_LIST_DROPDOWN: 'USER_TENANT_LIST_DROPDOWN',
};

export function useTenantTableList(params: PaginationAndSortingParams) {
  return useQuery<AxiosResponse<TenantTableItemsList>, AxiosDefaultErrorEntity>(
    [TENANT_TABLE_QUERY.TENANT_LIST, { ...params }],
    () =>
      axios
        .get(`/api/v1/management/tenant/list?${qs.stringify({ ...params, page: params.page - 1 })}`)
        .then((res) => res)
  );
}
export function useUsersForDeployTenant(roleIds?: string[], licensee?: string) {
  return useQuery<AxiosResponse<TenantUserForDeploy[]>, AxiosDefaultErrorEntity>(
    [TENANT_TABLE_QUERY.USER_TENANT_LIST_DROPDOWN, { roleIds, licensee }],
    () =>
      axios
        .get(
          `/api/v1/deployment/tenant/users?${qs.stringify({
            roleIds,
            licensee,
          })}`
        )
        .then((res) => res),
    {
      enabled: !!roleIds,
    }
  );
}

export function useCreateTenant() {
  const queryClient = useQueryClient();
  return useMutation<
    AxiosResponse,
    AxiosDefaultErrorEntity,
    {
      organization: string;
      organizationAbbn: string;
    }
  >((bodyRequest) => axios.post('/api/v1/management/tenant/create', bodyRequest), {
    onSuccess: () => {
      queryClient.invalidateQueries(TENANT_TABLE_QUERY.TENANT_LIST);
    },
  });
}
