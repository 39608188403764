// @ts-nocheck
import { useEffect, useMemo, useState } from 'react';
import { matchSorter } from 'match-sorter';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import ButtonLoading from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import TextField from '@mui/material/TextField';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import { useGetCubeLayerDropdown } from 'services/v1/SystemTenant/AROCube/CubeLayerService';
import { CubeLayerSetRowItem } from 'types/api/SystemTenant/AROCube/CubeLayerSetTypes';
import { useUpsertCubeLayerSet } from 'services/v1/SystemTenant/AROCube/CubeLayerSetService';

const ModalContentStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  height: 540,
  overflow: 'auto',
  borderRadius: 1,
  py: 2,
};

const textInputStyles = {
  width: '100%',
  '& .MuiOutlinedInput': {
    padding: '1px 2px',
  },
};
const dataGridStyle = {
  '& .MuiCheckbox-root svg': {
    backgroundColor: 'transparent',
    color: '#98A2AE',
    borderRadius: 2,
  },
  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: '#EDF2FF',
    borderTop: '1px solid #E3EBF6',
    borderBottom: '1px solid #E3EBF6',
    textAlign: 'center',
    display: 'flex',
  },
};

const closeIconStyle = { color: '#98A2AE', cursor: 'pointer' };

export interface ModalSelectLayerParamsProps {
  visible?: boolean;
  onClose?: () => void;
  layerSetItem: CubeLayerSetRowItem | null;
}

export function ModalSelectLayer(props: ModalSelectLayerParamsProps) {
  const [searchKeyword, setSearchKeyword] = useState('');
  const { onClose, visible = false, layerSetItem } = props;

  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  const { data: cubeLayerList, isLoading } = useGetCubeLayerDropdown();
  const { mutate: updateCubeLayerSet, isLoading: isUpdating } = useUpsertCubeLayerSet();
  const handleOnCancel = () => {
    handleOnCloseModal?.();
  };

  useEffect(() => {
    if (layerSetItem?.layers) {
      setSelectedItems(layerSetItem?.layers.map((layer) => layer.value));
    }
  }, [layerSetItem]);

  const handleOnCloseModal = () => {
    onClose?.();
  };

  const allLayerOptions = useMemo(() => {
    if (cubeLayerList?.data) {
      return matchSorter(cubeLayerList.data, searchKeyword, { keys: ['label'] }).map((layer) => ({
        label: layer.label,
        value: layer.value,
      }));
    }
    return [];
  }, [cubeLayerList, searchKeyword]);

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'label',
        headerName: 'Label',
        flex: 1,
        renderCell: (params) => {
          const keyword = searchKeyword.toLowerCase();
          const value = params.value.toString().toLowerCase();
          if (keyword && value.includes(keyword)) {
            const parts = value.split(new RegExp(`(${keyword})`, 'gi'));
            return (
              <div>
                {parts.map((part, index) => {
                  const partLoweCase = part.toLowerCase();
                  return partLoweCase === keyword ? (
                    <mark key={index} style={{ backgroundColor: 'yellow' }}>
                      {part}
                    </mark>
                  ) : (
                    <span key={index}>{part}</span>
                  );
                })}
              </div>
            );
          }
          return <div>{params.value}</div>;
        },
      },
    ],
    [searchKeyword]
  );

  const handleOnSave = () => {
    updateCubeLayerSet(
      {
        id: layerSetItem?.id,
        layers: selectedItems,
        description: layerSetItem?.description,
        name: layerSetItem?.name,
      },
      {
        onSuccess: () => {
          handleOnCloseModal();
        },
      }
    );
  };

  return (
    <div>
      <Modal open={visible} onClose={handleOnCloseModal}>
        <Stack direction='column' sx={ModalContentStyle} justifyContent='space-between'>
          <Stack>
            <Stack px={2} justifyContent='space-between' direction='row' alignItems='center'>
              <Typography variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
                {`Select Layer Set : ${layerSetItem?.name}`}
              </Typography>
              <IconButton onClick={handleOnCloseModal} component='label'>
                <HighlightOffRoundedIcon sx={closeIconStyle} />
              </IconButton>
            </Stack>
            <Stack
              sx={{
                bgcolor: '#FBFBFB',
                py: 3,
                px: 2,
                borderTop: '1px solid #E3EBF6',
              }}
            >
              <TextField
                value={searchKeyword}
                onChange={(e) => setSearchKeyword(e.target.value)}
                name='search'
                sx={textInputStyles}
                size='small'
                placeholder='Search...'
                type='text'
              />
            </Stack>
          </Stack>

          <Stack height={300} px={2}>
            <DataGridPro
              sx={dataGridStyle}
              loading={isLoading}
              selectionModel={selectedItems}
              density='compact'
              getRowId={(row) => row.value}
              rows={allLayerOptions}
              columns={columns}
              onSelectionModelChange={(newSelection) => {
                setSelectedItems(newSelection);
              }}
              pagination={false}
              hideFooter
              checkboxSelection
            />
          </Stack>

          <Stack px={2}>
            <Divider sx={{ mb: 1, mt: 2 }} />
            <Stack direction='row' justifyContent='flex-end' spacing={2} alignItems='center' sx={{ py: 1 }}>
              <Button variant='main-table-panel-border' onClick={handleOnCancel}>
                Cancel
              </Button>
              <ButtonLoading variant='main-table-panel' onClick={handleOnSave} loading={isUpdating}>
                Save Parameter
              </ButtonLoading>
            </Stack>
          </Stack>
        </Stack>
      </Modal>
    </div>
  );
}
