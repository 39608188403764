import Snackbar from '@mui/material/Snackbar';
import { useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import {
  GridRowModesModel,
  GridRowModes,
  GridRowParams,
  GridSelectionModel,
  GridRowId,
  GridFilterModel,
  GridSortModel,
  getGridStringOperators,
  GridActionsCellItem,
  GridColumns,
} from '@mui/x-data-grid-pro';
import Alert, { AlertProps } from '@mui/material/Alert';
import { CustomDataTable } from 'components/DatatableComponent';
import { MainLayoutComponent } from 'components/LayoutComponent/SidebarLayout/MainLayoutComponent';
import { PaginationAndSortingParams } from 'types/api/Common/PaginationTypes';
import { AxiosDefaultErrorEntity } from 'types/api/Common/ErrorTypes';
import { getErrorMessage } from 'utils/Error';
import { DASHBOARD_VIEW_MODE } from 'constant/DashboardTypeConstant';
import { useGetCubeLayerList, useUpsertCubeLayer } from 'services/v1/SystemTenant/AROCube/CubeLayerService';
import { CubeLayerItem, CubeLayerRowItem, RequestCreateCubeLayer } from 'types/api/SystemTenant/AROCube/CubeLayerTypes';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import { renderSelectEditInputCell } from 'pages/SystemTenant/AROKMS/TableDefinition/components/SelectEditInputCell';
import { CUBE_LAYER_TYPE } from 'constant/CubeConstant';

const panelStyle = {
  transform: 'translate3d(704px, 140.667px, 0px) !important',

  '& .MuiDataGrid-panelFooter': {
    display: 'none',
  },
};

const CUBE_LAYER_TYPE_OPTIONS = [
  {
    label: 'Actual',
    value: CUBE_LAYER_TYPE.ACTUAL,
  },
  {
    label: 'Baseline',
    value: CUBE_LAYER_TYPE.BASELINE,
  },
  {
    label: 'General',
    value: CUBE_LAYER_TYPE.GENERAL,
  },
];

export default function CubeLayerListPage() {
  const { dashboardViewMode } = useSelector((state: RootState) => state.auth);
  const { t } = useTranslation();
  const [rowData, setRowData] = useState<CubeLayerItem[]>([]);

  const [selectedDataID, setSelectedDataID] = useState<GridRowId[]>([]);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [filter, setFilter] = useState<PaginationAndSortingParams>({
    page: 0,
    size: 20,
    filterValue: '',
    sortType: 'asc',
    filterOperator: 'contains',
  });

  const [filterButtonElement, setFilterButtonElement] = useState<HTMLButtonElement | null>(null);
  const [editedData, setEditedData] = useState<CubeLayerRowItem[]>([]);
  const [snackbar, setSnackbar] = useState<Pick<AlertProps, 'children' | 'severity'> | null>(null);

  const { data: cubeLayerList, isLoading, refetch: refreshCubeLayerData } = useGetCubeLayerList();
  const { mutateAsync: upserCubeLayerAsync } = useUpsertCubeLayer();

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const isAnyEdit = useMemo(() => {
    return rowModesModel && Object.values(rowModesModel).some((item) => item.mode === GridRowModes.Edit);
  }, [rowModesModel]);

  const isBuilderViewOnly = dashboardViewMode === DASHBOARD_VIEW_MODE.VIEW_ONLY;

  const columns = useMemo<GridColumns>(() => {
    const cols: GridColumns = [
      {
        field: 'layerName',
        headerName: 'Layer Name',
        width: 300,
        editable: true,
        filterOperators: getGridStringOperators().filter((operator) => ['contains', 'equals'].includes(operator.value)),
      },
      {
        field: 'layerType',
        headerName: 'Layer Type',
        width: 300,
        editable: true,
        renderCell: (params) => {
          return <span>{params.value?.label}</span>;
        },
        renderEditCell: (params) => {
          return renderSelectEditInputCell({
            ...params,
            // @ts-ignore
            optionsSource: CUBE_LAYER_TYPE_OPTIONS,
          });
        },
        filterOperators: getGridStringOperators().filter((operator) => ['contains', 'equals'].includes(operator.value)),
      },
    ];

    if (isBuilderViewOnly) return cols;
    return [
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Edit',
        width: 30,
        cellClassName: 'actions',

        getActions: ({ id }) => {
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

          if (isInEditMode) {
            // @ts-ignore
            return [<GridActionsCellItem icon={<SaveIcon />} label='Save' onClick={handleSaveClick(id)} />];
          }

          return [
            // @ts-ignore
            <GridActionsCellItem disabled={isAnyEdit} icon={<EditIcon />} label='Edit' onClick={handleEditClick(id)} />,
          ];
        },
      },
      ...cols,
    ];

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowModesModel, isAnyEdit, isBuilderViewOnly]);

  const DataMutationSuccessHandlerOption = {
    onSuccess: () => {
      refreshCubeLayerData();
    },
    onError: (error: AxiosDefaultErrorEntity) => {
      refreshCubeLayerData();
      setSnackbar({ children: getErrorMessage(error), severity: 'error' });
    },
  };

  useEffect(() => {
    if (cubeLayerList?.data) {
      setRowData(cubeLayerList?.data);
      setRowModesModel(
        cubeLayerList?.data?.reduce((acc, item) => {
          return { ...acc, [item.id]: GridRowModes.View };
        }, {})
      );
    }
  }, [cubeLayerList]);

  const handleChangePage = (newPage: number) => {
    setFilter({ ...filter, page: newPage });
  };
  const handleChangePageSize = (newPageSize: number) => {
    setFilter({ ...filter, size: newPageSize });
  };

  const handleOnFilter = (params: GridFilterModel) => {
    let filterObj = {};
    if (params.items.length) {
      filterObj = {
        filterValue: params.items[0].value,
        filterOperator: params.items[0].operatorValue,
      };
    } else {
      filterObj = {
        filterValue: '',
        filterOperator: 'contains',
      };
    }
    setFilter({
      ...filter,
      ...filterObj,
    });
  };

  const handleOnSortModelChange = (model: GridSortModel) => {
    if (model.length === 0) {
      setFilter({ ...filter, sortType: 'asc' });
      return;
    }
    setFilter({ ...filter, sortType: model[0].sort });
  };

  // Process row Update will be triggered when
  // user modify or insert new row the table
  const handleProcessRowUpdate = (data: CubeLayerRowItem, oldData: CubeLayerRowItem) => {
    const isEmpty = !data.layerName;
    const isAnyFieldEmpty = !data.layerName;

    if (isAnyFieldEmpty) return;

    if (isEmpty) handleCancelInsert(data.id);

    if (data.isNew) {
      const insertPayload = {
        layerName: data.layerName,
        layerType: data.layerType?.value,
      };
      // if the row is new and not empy, then insert the row to database
      upserCubeLayerAsync(insertPayload as RequestCreateCubeLayer, DataMutationSuccessHandlerOption);
      return { ...data, isNew: false };
    }
    // if the row is not new, it means that the row n
    if (!data.isNew) {
      // if data already exist in state editedData, update it
      // if not, add it to editedData
      const index = editedData.findIndex((item) => item.id === data.id);
      if (index === -1) {
        setEditedData([...editedData, data]);
      } else {
        const newEditedData = [...editedData];
        newEditedData[index] = data;
        setEditedData(newEditedData);
      }
      const updatePayload = {
        id: data.id,
        layerName: data.layerName,
        layerType: data.layerType?.value,
      };
      upserCubeLayerAsync(updatePayload as RequestCreateCubeLayer, DataMutationSuccessHandlerOption);
      return data;
    }
  };

  const handleCancelInsert = (id: string | number) => {
    const newRows = rowData.filter((item) => item.id !== id);
    setRowData(newRows);
    setRowModesModel((prev) => {
      const newModel = { ...prev };
      delete newModel[id];
      return newModel;
    });
  };

  const handleEditRowModes = (params: GridRowParams) => {
    if (isBuilderViewOnly || isAnyEdit) return;
    setRowModesModel({ ...rowModesModel, [params.id]: { mode: GridRowModes.Edit } });
  };

  const handleCloseSnackbar = () => setSnackbar(null);

  return (
    <MainLayoutComponent
      pageTitle='Cube Layer'
      breadcrumbs={[t('SIDEBAR_MENU.DASHBOARD'), t('SIDEBAR_MENU.DATA_BUILDER')]}
    >
      <CustomDataTable
        initialState={{
          pagination: {
            page: 0,
          },
          sorting: {
            sortModel: [
              {
                field: 'attribute',
                sort: 'asc',
              },
            ],
          },
        }}
        loading={isLoading}
        checkboxSelection={!isBuilderViewOnly}
        rows={rowData}
        columns={columns}
        rowCount={cubeLayerList?.data?.length || 0}
        page={filter.page || 0}
        pageSize={filter.size}
        getRowId={(row) => row.id}
        rowModesModel={rowModesModel}
        disableSelectionOnClick
        rowsPerPageOptions={[5, 10, 20]}
        getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
        experimentalFeatures={{ newEditingApi: true }}
        onFilterModelChange={handleOnFilter}
        onSortModelChange={handleOnSortModelChange}
        onPageChange={handleChangePage}
        onPageSizeChange={handleChangePageSize}
        onSelectionModelChange={(selectedId: GridSelectionModel) => {
          setSelectedDataID(selectedId);
        }}
        processRowUpdate={handleProcessRowUpdate}
        onRowDoubleClick={handleEditRowModes}
        paginationMode='client'
        sortingMode='client'
        filterMode='client'
        componentsProps={{
          panel: {
            sx: panelStyle,
            anchorEl: filterButtonElement,
          },
          toolbar: {
            setRows: setRowData,
            setRowModesModel,
            setFilterButtonElement: setFilterButtonElement,
            disabled: isLoading || isBuilderViewOnly,
            isEditing: rowData.some((item) => item.isNew),
            disabledDeleteButton: selectedDataID.length === 0,
            config: {
              deleteButtonText: 'Delete',
              fieldFocusOnInsert: 'layerName',
              insertButtonText: 'Add New Layer',
              insertInitialObject: {
                layerName: '',
                isNew: true,
              },
            },
          },
        }}
      />

      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          onClose={handleCloseSnackbar}
          autoHideDuration={3000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
    </MainLayoutComponent>
  );
}
