import * as DOMPurify from 'dompurify';

import { Player } from '@lottiefiles/react-lottie-player';
import animationError from 'assets/lottie/initial-setup-error.json';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { TenantInterface } from 'store/reducer/authReducer';
import { Button } from '@mui/material';

const contentContainerStyle = {
  my: 5,
  mx: 4,
  px: 4,
  py: 4,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: '#fff',
  borderRadius: '7px',
  boxShadow: '0px 1px 6px #C8CFE2',
};

const loadingContainerStyle = {
  ...contentContainerStyle,
  boxShadow: 'none',
  backgroundColor: 'transparent',
};

export function ErrorInitialSetup({
  selectedTenant,
  error,
}: {
  selectedTenant: TenantInterface;
  error: string | null;
}) {
  const email = {
    subject: `Failed to setup tenant for ${selectedTenant.tenant.tenantCode}`,
    body: `Hi, I'm having trouble setting up tenant for ${selectedTenant.tenant.tenantCode}.\n\nHere is the error message: ${error} \n\nPlease help me to resolve this issue. \n\nThank you.`,
  };

  const mailToLink = `mailto:admin@admin.com?subject=${encodeURIComponent(
    DOMPurify.sanitize(email.subject)
  )}&body=${encodeURIComponent(DOMPurify.sanitize(email.body))}`;

  return (
    <Box width={500} sx={loadingContainerStyle}>
      <Player
        src={animationError}
        autoplay
        loop
        style={{
          height: 150,
        }}
      />
      <Typography component='h5' variant='h6'>
        We're sorry, but something went wrong while setting up your platform.
      </Typography>
      <Typography component='p' variant='subtitle2' color='#6E7FAA'>
        Please reach out to our support team for assistance.
      </Typography>
      <Button
        variant='main-table-panel'
        onClick={() => {
          window.location.href = mailToLink;
        }}
        sx={{
          mt: 2,
        }}
      >
        Send Error Report
      </Button>
    </Box>
  );
}
