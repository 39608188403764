import { useEffect, useMemo, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import { Formik } from 'formik';
import { SelectRoleValidationSchema } from '../../config/FormValidaton/AuthValidationSchema';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import MenuItem from '@mui/material/MenuItem';
import ButtonLoading from '@mui/lab/LoadingButton';

import { RootState } from 'store';
import { useGetRoleAccessToken } from 'services/v1/Auth';
import { useGetMyPersonalizationLogin, useGetTenantConfigDataMutation } from 'services/v1/Tenant/ConfigurationService';
import {
  RoleInterface,
  RoleType,
  setAccessToken,
  setRole,
  setSelectedTenant,
  setUserBehaviourRole,
} from 'store/reducer/authReducer';
import { resetHomeNavigatinState } from 'store/reducer/homeReducer';
import { setConfig, setSetupStatus } from 'store/reducer/settingReducer';
import { setAuthHeaderToken, setAuthTenantId } from 'libs/Axios';
import { DASHBOARD_TYPE_CONSTANT } from 'constant/DashboardTypeConstant';
import { TENANT_CONFIGURATION_KEY, TENANT_INITIAL_SETUP_STATUS } from 'constant/TenantConfigConstant';
import { PATH_CONSTANT } from 'constant/PathConstant';
import { DEFAUL_LOGIN_REDIRECT_URL } from 'constant/PermissonConstant';
import { getUserRoleBehaviour } from './SelectRolePage';
import { getQuickAccessNavigateURL } from 'components/LayoutComponent/VerticalLayout/QuickAccess';

interface formValues {
  role: RoleType | '';
  tenantId: string;
}

interface IRoleOptions {
  label: RoleType;
  value: RoleType;
}

const dropdownHelperTextStyle = {
  color: '#3B4797',
  fontWeight: '600',
};

const formInitialValues: formValues = {
  role: '',
  tenantId: '',
};

type ChangeRoleComponentProps = {
  setSelectedRoleProps?: (role: RoleType) => void;
  title?: string | null;
  onSelectRole?: (role: RoleType | '') => void;
};

export function ChangeRoleComponent(props: ChangeRoleComponentProps) {
  const queryClient = useQueryClient();
  const { setSelectedRoleProps, title = 'Authentication has been successful', onSelectRole } = props;
  const { roles, tenants, selectedTenant } = useSelector((state: RootState) => state.auth);
  const { mutateAsync: getRoleAccessToken, isLoading: isSubmitting } = useGetRoleAccessToken();
  const { mutateAsync: refetchPersonalization } = useGetMyPersonalizationLogin();
  const { mutateAsync: getTenantConfig, isLoading: isFetchingTenantConfig } = useGetTenantConfigDataMutation();
  const formikRef = useRef<any>(null);
  const [selectedRole, setSelectedRole] = useState<RoleType>('Admin');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const resetQueryCache = () => {
    queryClient.resetQueries();
    queryClient.refetchQueries();
  };

  const handleSubmit = async (data: formValues) => {
    const selectedTenantId = data.tenantId?.split('@')?.[0] || '';
    const selectedRoleName = data.tenantId?.split('@')?.[1] || '';

    const selectedTenant = tenants.find(
      (tenant) => tenant.tenant.id === selectedTenantId && tenant.tenant.roleName === selectedRoleName
    );

    if (selectedTenant) {
      dispatch(resetHomeNavigatinState());
      setAuthTenantId(selectedTenant.tenant.id);
      dispatch(setRole(data.role));

      const res = await getRoleAccessToken({
        roleName: selectedTenant.tenant.roleName,
        tenantId: selectedTenant.tenant.id,
      });
      const roleAccessToken = res.data.data;
      setAuthHeaderToken(roleAccessToken);
      dispatch(setAccessToken(roleAccessToken));

      const roleBehaviour = getUserRoleBehaviour(data.role, selectedTenant) as RoleType;
      dispatch(setUserBehaviourRole(roleBehaviour));

      dispatch(setSelectedTenant(selectedTenant));
      if (selectedTenant.tenant.dashboardType === DASHBOARD_TYPE_CONSTANT.DASHBOARD_TENANT) {
        const tenantConfig = await getTenantConfig();
        const initialSetupStatus = tenantConfig.data?.find(
          (config) => config.id === TENANT_CONFIGURATION_KEY.INITIAL_SETUP_STATUS
        )?.value;

        initialSetupStatus && dispatch(setSetupStatus(initialSetupStatus));
        dispatch(setConfig(tenantConfig.data || []));

        if (initialSetupStatus === TENANT_INITIAL_SETUP_STATUS.INITIAL) {
          navigate(PATH_CONSTANT.AUTH.INITIAL_SETUP);
          return;
        }
      }
      resetQueryCache();
      onSelectRole && onSelectRole(data.role);

      if (selectedTenant.tenant.dashboardType !== DASHBOARD_TYPE_CONSTANT.DASHBOARD_SYSTEM) {
        const userPersonalization = await refetchPersonalization();
        const defaultURL = getQuickAccessNavigateURL(userPersonalization?.data.defaultLoginView);
        if (defaultURL) {
          navigate(defaultURL);
          return;
        }
      }

      navigate(DEFAUL_LOGIN_REDIRECT_URL[roleBehaviour]);
    }
  };

  const roleExistWithTenants = useMemo(() => {
    const uniqueRoles = tenants
      .map((tenant) => tenant.roleType)
      .filter((role, index, roles) => roles.indexOf(role) === index);
    return uniqueRoles;
  }, [tenants]);

  const rolesOption: IRoleOptions[] = useMemo(() => {
    if (roles.length === 0) return [];

    const rolesOptionsDistinct: RoleInterface[] = roles.reduce((acc, role) => {
      if (!acc.find((item: RoleInterface) => item.roleType === role.roleType || item.roleType === 'SUPPORT')) {
        // @ts-ignore
        acc.push(role);
      }
      return acc;
    }, []);
    return rolesOptionsDistinct
      .map((role) => ({
        value: role.roleType as RoleType,
        label: role.roleType as RoleType,
      }))
      .filter((roleOption) => roleExistWithTenants.includes(roleOption.label))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [roles, roleExistWithTenants]);

  const tenantsOption = useMemo(() => {
    if (tenants.length === 0) return [];

    return tenants
      .filter((tenant) => tenant.roleType === selectedRole)
      .map((tenant) => {
        return {
          value: `${tenant.tenant.id}@${tenant.tenant.roleName}`,
          label: tenant.tenant.tenantName || (tenant.tenant.systemName as string),
        };
      })
      .sort((a, b) => a.label?.localeCompare(b.label?.toString()));
  }, [tenants, selectedRole]);

  useEffect(() => {
    if (tenantsOption.length === 0) {
      formikRef.current.setFieldValue('tenantId', '');
    }
  }, [tenantsOption]);

  useEffect(() => {
    if (rolesOption.length > 0) {
      formikRef.current.setFieldValue('role', rolesOption[0].value);
      setSelectedRole(rolesOption[0].value);
      setSelectedRoleProps && setSelectedRoleProps(rolesOption[0].value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rolesOption]);

  useEffect(() => {
    if (selectedTenant.roleType) {
      formikRef.current.setFieldValue('role', selectedTenant.roleType);
      formikRef.current.setFieldValue('tenantId', `${selectedTenant.tenant.id}@${selectedTenant.tenant.roleName}`);
      setSelectedRole(selectedTenant.roleType);
      setSelectedRoleProps && setSelectedRoleProps(selectedTenant.roleType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTenant.roleType, selectedTenant.tenant.id, selectedTenant.tenant.roleName]);

  return (
    <>
      <Typography component='h4' variant='h4' color='#2C2A29' mb={4}>
        {title}
      </Typography>

      <Formik
        innerRef={formikRef}
        initialValues={formInitialValues}
        onSubmit={handleSubmit}
        validationSchema={SelectRoleValidationSchema}
      >
        {({ handleChange, setFieldValue, handleSubmit, touched, errors, values }) => (
          <form
            onSubmit={handleSubmit}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSubmit();
              }
            }}
          >
            <FormHelperText sx={dropdownHelperTextStyle}>Select your role type</FormHelperText>
            <FormControl fullWidth margin='dense'>
              <Select
                value={values.role}
                disabled={isSubmitting || isFetchingTenantConfig}
                onChange={(e) => {
                  handleChange(e);
                  setSelectedRole(e.target.value as RoleType);
                  setSelectedRoleProps && setSelectedRoleProps(e.target.value as RoleType);
                  if (values.tenantId) setFieldValue('tenantId', '');
                }}
                displayEmpty
                name='role'
                inputProps={{ 'aria-label': 'Without label' }}
              >
                {!selectedRole && (
                  <MenuItem value=''>
                    <em>Please select your role type</em>
                  </MenuItem>
                )}
                {rolesOption.map((role) => (
                  <MenuItem value={role.value}>{role.label}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormHelperText sx={dropdownHelperTextStyle}>Select your customer solution</FormHelperText>
            <FormControl fullWidth margin='dense'>
              <Select
                value={values.tenantId}
                disabled={!selectedRole || isSubmitting || isFetchingTenantConfig}
                onChange={handleChange}
                name='tenantId'
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
              >
                {!selectedRole && (
                  <MenuItem value=''>
                    <em>Select Role first</em>
                  </MenuItem>
                )}

                {tenantsOption.length === 0 && selectedRole && (
                  <MenuItem value=''>
                    <em>No Tenant available for this role</em>
                  </MenuItem>
                )}
                {tenantsOption.map((role) => (
                  <MenuItem value={role.value}>{role.label}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <ButtonLoading
              loading={isSubmitting || isFetchingTenantConfig}
              type='submit'
              fullWidth
              loadingPosition='start'
              onClick={() => handleSubmit()}
              variant='main-login'
              sx={{
                mt: 4,
                mb: 2,
              }}
              disabled={!SelectRoleValidationSchema.isValidSync(values)}
            >
              Next
            </ButtonLoading>
          </form>
        )}
      </Formik>
    </>
  );
}
