export const FUNCTION_CORE = {
  COUNT_FUNCTION: 'COUNT_FUNCTION',
  VALUE_FUNCTION: 'VALUE_FUNCTION',
  DEPRECIATION_FUNCTION: 'DEPRECIATION_FUNCTION',
  INTEREST_FUNCTION: 'INTEREST_FUNCTION',
};

export const FUNCTION_CORE_OPTIONS = [
  {
    label: 'Count Function',
    value: FUNCTION_CORE.COUNT_FUNCTION,
  },
  {
    label: 'Depreciation Function',
    value: FUNCTION_CORE.DEPRECIATION_FUNCTION,
  },
  {
    label: 'Interest Function',
    value: FUNCTION_CORE.INTEREST_FUNCTION,
  },
  {
    label: 'Value Function',
    value: FUNCTION_CORE.VALUE_FUNCTION,
  },
];
