import externalDayjs, { Dayjs } from 'dayjs';
import dayJsUtc from 'dayjs/plugin/utc';
import dayjsTimezone from 'dayjs/plugin/timezone';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import { ENV_CONFIG } from 'config/env';
import { InsightViewRelativeTimeCategory, InsightViewRelativeTimeType } from 'constant/ViewConstant';

externalDayjs.extend(dayJsUtc);
externalDayjs.extend(dayjsTimezone);
externalDayjs.extend(weekOfYear);

// set default timezone
externalDayjs.tz.setDefault(ENV_CONFIG.config.TIME_ZONE);

export const dayjs = externalDayjs;

export function dayjsWithUTC(date?: string | number | Date | Dayjs, format?: string): Dayjs {
  return dayjs(date, format).utc();
}

enum DateOptions {
  DAY = 'Day',
  WEEK = 'Week',
  WEEKS = 'Weeks',
  MONTH = 'Month',
  MONTHS = 'Months',
  YEAR = 'Year',
  YEARS = 'Years',
}

export function getJulianDate(date: Dayjs): number {
  const year = date.year();
  const month = date.month() + 1;
  const day = date.date();

  const a = Math.floor((14 - month) / 12);
  const y = year + 4800 - a;
  const m = month + 12 * a - 3;

  const julianDate =
    day +
    Math.floor((153 * m + 2) / 5) +
    365 * y +
    Math.floor(y / 4) -
    Math.floor(y / 100) +
    Math.floor(y / 400) -
    32045;

  return julianDate;
}

export function getJulianDateBasedOnDayType(jDate: number, option: DateOptions | string, decimal: number) {
  let julianDate = jDate;
  switch (option.toLowerCase()) {
    case DateOptions.WEEK.toLowerCase():
    case DateOptions.WEEKS.toLowerCase():
      julianDate = julianDate / 7;
      break;
    case DateOptions.MONTH.toLowerCase():
    case DateOptions.MONTHS.toLowerCase():
      julianDate = (julianDate * 12) / 365;
      break;
    case DateOptions.YEAR.toLowerCase():
    case DateOptions.YEARS.toLowerCase():
      julianDate = julianDate / 365;
      break;
  }
  return Number(julianDate.toFixed(decimal));
}

export function getInsightViewRelativeTimeDescription(number: number, relativeTimeType: string, timeCategory: string) {
  const timeDescriptions = {
    [InsightViewRelativeTimeCategory.YEAR_TIME]: {
      [InsightViewRelativeTimeType.CURRENT_TIME]: 'Current Year',
      [InsightViewRelativeTimeType.PAST_TIME]: number === 1 ? 'Last Year' : `${number} Years Ago`,
      [InsightViewRelativeTimeType.FUTURE_TIME]: number === 1 ? 'Next Year' : `${number} Years Ahead`,
    },
    [InsightViewRelativeTimeCategory.PERIOD_TIME]: {
      [InsightViewRelativeTimeType.CURRENT_TIME]: 'Current Period',
      [InsightViewRelativeTimeType.PAST_TIME]: number === 1 ? 'Last Period' : `${number} Periods Ago`,
      [InsightViewRelativeTimeType.FUTURE_TIME]: number === 1 ? 'Next Period' : `${number} Periods Ahead`,
    },
  };

  return timeDescriptions[timeCategory]?.[relativeTimeType] || '';
}
