import { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import axios from 'libs/Axios';

import { AxiosDefaultErrorEntity } from 'types/api/Common/ErrorTypes';
import {
  BreadcrumbItem,
  HomeDashboardMenuNavigationMember,
  HomeMainMenuNavigation,
  HomeNavigationItem,
} from 'types/api/Tenant/HomeDashboardTypes';
import { NAVIGATION_COMPONENT_TYPE } from 'constant/NavigationConstant';
import { ENV_CONFIG } from 'config/env';
import { AutoCompleteItem } from 'types/api/Common/AutoCompleteTypes';
import { AutoCompleteWithType } from 'types/api/Tenant/AROKMS/CustomRuleTypes';

export const HOME_DASHBOARD_KEY = {
  NAVIGATION_MENU: 'NAVIGATION_MENU',
  MAIN_NAVIGATION_MENU: 'MAIN_NAVIGATION_MENU',
  BREADCRUMBS: 'BREADCRUMBS',
};

export function useGetDropdownMenuMember(subjectId?: string | null) {
  return useQuery<AxiosResponse<HomeDashboardMenuNavigationMember>, AxiosDefaultErrorEntity>(
    [HOME_DASHBOARD_KEY.NAVIGATION_MENU, subjectId],
    () =>
      axios.get(`/api/v1/tenant/dashboard/home/menu/${subjectId}`).then((res) => {
        const transformedData = {
          ...res,
          data: {
            ...res.data,
            dropdownOptions: {
              ...res.data.dropdownOptions,
              processDropdownOptions: res.data.dropdownOptions.processDropdownOptions.map((item: AutoCompleteItem) => ({
                ...item,
                children: res.data.dropdownOptions.procedureDropdownOptions.filter(
                  (procedure: AutoCompleteWithType) => procedure.type === item.value
                ),
              })),
            },
          },
        };
        return transformedData;
      }),
    {
      enabled: Boolean(subjectId),
    }
  );
}

export function useGetMainMenuNavigationDropdown() {
  return useQuery<AxiosResponse<HomeMainMenuNavigation>, AxiosDefaultErrorEntity>(
    [HOME_DASHBOARD_KEY.NAVIGATION_MENU],
    () =>
      axios.get(`/api/v1/tenant/dashboard/home/menu/main-menu`).then((res: AxiosResponse<HomeMainMenuNavigation>) => {
        const transformedData = {
          ...res,
          data: {
            ...res.data,
            processOptions: res.data.processOptions
              .map((item: HomeNavigationItem) => ({
                ...item,
                children: res.data.procedureOptions
                  .filter((procedure: HomeNavigationItem) => procedure?.type?.toString() === item.value.toString())
                  .sort((a: HomeNavigationItem, b: HomeNavigationItem) => {
                    if (a.label && b.label) {
                      return a.label.localeCompare(b.label);
                    }
                    return 0;
                  }),
              }))
              .sort((a: HomeNavigationItem, b: HomeNavigationItem) => {
                if (a.label && b.label) {
                  return a.label.localeCompare(b.label);
                }
                return 0;
              }),
          },
        };
        return transformedData;
      })
  );
}

export function useGetBreadcumbItems(
  componentId?: string,
  componentType?: NAVIGATION_COMPONENT_TYPE,
  subjectId?: string
) {
  return useQuery<AxiosResponse<BreadcrumbItem[]>, AxiosDefaultErrorEntity>(
    [HOME_DASHBOARD_KEY.BREADCRUMBS, componentId, componentType, subjectId],
    () =>
      axios
        .post(`/api/v1/tenant/dashboard/home/breadcrumb`, {
          id: componentId,
          componentType,
          subjectId,
        })
        .then((res) => res),
    {
      enabled: !!componentId && !!componentType,
      cacheTime: ENV_CONFIG.endpoint.MAXIMUM_CACHE_TIME,
      staleTime: ENV_CONFIG.endpoint.MAXIMUM_CACHE_TIME,
    }
  );
}
