import qs from 'query-string';
import { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import axios from 'libs/Axios';

import { AxiosDefaultErrorEntity } from 'types/api/Common/ErrorTypes';
import { KTreeNode } from 'types/api/Tenant/AROKMS/KTreeDataTypes';
import { createTreeData, sortTreeData } from 'utils/Object';
import { ResponseDisplayStandardView } from 'types/api/Tenant/AROView/DisplayStandardViewTypes';
import { ResponseStandardViewDetails } from 'types/api/SystemTenant/AROView/StandardViewDefinitionTypes';

export const DISPLAY_STANDARD_VIEW_QUERY_KEY = {
  DISPLAY_STANDARD_VIEW_MEMBER: 'DISPLAY_STANDARD_VIEW_MEMBER',
  DISPLAY_STANDARD_VIEW_DATA: 'DISPLAY_STANDARD_VIEW_DATA',
  DISPLAY_STANDARD_VIEW_DETAILS: 'DISPLAY_STANDARD_VIEW_DETAILS',
};

export function useGetStandardViewMemberList(standardViewId?: string | null) {
  return useQuery<AxiosResponse<KTreeNode[]>, AxiosDefaultErrorEntity>(
    [DISPLAY_STANDARD_VIEW_QUERY_KEY.DISPLAY_STANDARD_VIEW_MEMBER, standardViewId],
    () =>
      axios.get(`/api/v1/tenant/standard-view/member/${standardViewId}`).then((res) => {
        if (res.data.length > 0) {
          const treeData = createTreeData(null, res.data);
          return sortTreeData(treeData);
        }
        return null;
      }),
    {
      enabled: !!standardViewId,
    }
  );
}

export function useGetStandardViewData(standardViewId?: string | null, memberId?: string | null, date?: string | null) {
  return useQuery<AxiosResponse<ResponseDisplayStandardView>, AxiosDefaultErrorEntity>(
    [DISPLAY_STANDARD_VIEW_QUERY_KEY.DISPLAY_STANDARD_VIEW_DATA, standardViewId, memberId, date],
    () =>
      axios
        .get(
          `/api/v1/tenant/standard-view/display/${standardViewId}/${memberId}?${qs.stringify({
            date,
          })}`
        )
        .then((res) => res),
    {
      enabled: !!standardViewId && !!memberId,
    }
  );
}

export function useGetStandardViewDetails(standardViewId?: string | null) {
  return useQuery<AxiosResponse<ResponseStandardViewDetails>, AxiosDefaultErrorEntity>(
    [DISPLAY_STANDARD_VIEW_QUERY_KEY.DISPLAY_STANDARD_VIEW_DETAILS, standardViewId],
    () => axios.get(`/api/v1/tenant/standard-view/${standardViewId}`).then((res) => res),
    {
      enabled: !!standardViewId,
    }
  );
}
