import * as Yup from 'yup';

export const ModalTableDefinitionValidationSchema = Yup.object()
  .shape({
    validationPattern: Yup.object()
      .shape({
        label: Yup.string(),
        value: Yup.string().nullable(),
      })
      .nullable(),
    minCharacter: Yup.string()
      .when('validationPattern.value', {
        is: 'VALIDATION_PATTERN_CUSTOM',
        then: Yup.string().nullable(),
      })
      .nullable(),
    maxCharacter: Yup.string()
      .when('validationPattern.value', {
        is: 'VALIDATION_PATTERN_CUSTOM',
        then: Yup.string()
          .test('min', 'The value must be greater than 0 or just leave it blank', (val) => {
            return !val || Number(val) > 0;
          })
          .test('max', 'The value must be less than or equal to 255', (val) => {
            return !val || Number(val) <= 255;
          })
          .nullable(),
      })
      .nullable(),
  })
  .test('min-max', 'minCharacter cannot be greater than maxCharacter', function (obj) {
    const { validationPattern, minCharacter, maxCharacter } = obj;

    if (
      validationPattern?.value === 'VALIDATION_PATTERN_CUSTOM' &&
      minCharacter != null &&
      maxCharacter != null &&
      +minCharacter > +maxCharacter
    ) {
      return this.createError({ path: 'minCharacter', message: 'Min Character cannot be greater than Max Character' });
    }
    return true;
  });
