import * as Yup from 'yup';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useMemo, useImperativeHandle, forwardRef, useState, useEffect, useContext } from 'react';
import {
  useGetFunctionDefinitionDetailsById,
  useGetFunctionDefinitionSubjectAttributeDropdown,
} from 'services/v1/SystemTenant/AROFunction/FunctionDefinitionService';
import { FUNCTION_CORE } from 'constant/FunctionConstant';
import {
  RULES_CONSTANT,
  RULE_TYPE_DATE_FROM_SUBJECT,
  RULE_TYPE_NUMBER_FROM_SUBJECT,
  RULE_TYPE_PERCENTAGE_FROM_SUBJECT,
  RULE_TYPE_PRICE_FROM_SUBJECT,
} from 'constant/RuleConstant';
import { CreateFunctionDefinitionContext, FunctionDefinitionParamFields } from '../CreateFunctionDefinition';
import { FormikProps, useFormik } from 'formik';
import { AutoCompleteItem } from 'types/api/Common/AutoCompleteTypes';
import { Alert } from '@mui/material';
import { useParams } from 'react-router-dom';
import Checkbox from '@mui/material/Checkbox';

interface IProps {
  subjectId?: string | null;
  sectionTitle: string;
  functionCore: string;
  onFormikChange?: (
    values: {
      [keyof in AllKeys]?: AutoCompleteItem | null;
    },
    isFormValid: boolean
  ) => void;
}

interface LoadCountFunctionParameterFields {
  attributeStartDate: AutoCompleteItem | null;
  attributeEndDate: AutoCompleteItem | null;
}

interface LoadDepreciationFunctionParameterFields {
  attributePurchasePrice: AutoCompleteItem | null;
  attributePurchaseDate: AutoCompleteItem | null;
  attributeSalePrice: AutoCompleteItem | null;
  attributeSaleDate: AutoCompleteItem | null;
  attributeExpectedLifeYrs: AutoCompleteItem | null;
  attributeDepreciationType: AutoCompleteItem | null;
  attributeDepreciationRate: AutoCompleteItem | null;
}
export interface FunctionDefinitionLoadParameterRef {
  getValues: () => FormikProps<FormikInitialValuesLoadParameter>['values'];
}

type LoadCountKeys = keyof LoadCountFunctionParameterFields;
type LoadDepreciationKeys = keyof LoadDepreciationFunctionParameterFields;
type AllKeys = LoadCountKeys | LoadDepreciationKeys;
type FormikInitialValuesLoadParameter = LoadCountFunctionParameterFields | LoadDepreciationFunctionParameterFields;

const autocompleteStyles = {
  width: '55%',
  '& .MuiOutlinedInput-root': {
    padding: '1px 2px',
  },
  '& .MuiAutocomplete-popper': {
    fontSize: '10px',
  },
};
const textInputStyles = {
  '& .MuiOutlinedInput': {
    padding: '1px 2px',
  },
};
type SchemaFieldsType = { [key: string]: Yup.StringSchema | Yup.NumberSchema | Yup.AnySchema }; // Adjust based on your needs

export const createValidationSchema = (fields: FunctionDefinitionParamFields[]) => {
  let schemaFields: SchemaFieldsType = {};

  fields.forEach((field) => {
    if (field.isRequired) {
      schemaFields[field.name] = Yup.object().required('This field is required').nullable();
    } else {
      schemaFields[field.name] = Yup.object().nullable();
    }
  });

  return Yup.object().shape(schemaFields);
};

export function generateField<T>(
  fieldItem: FunctionDefinitionParamFields,
  formik: FormikProps<T>,
  selectedOptions: Record<string, any>,
  setSelectedOptions: React.Dispatch<React.SetStateAction<Record<string, string | null>>>
) {
  const handleSelectionChange = (fieldName: string, newValue: any) => {
    // Set the formik value
    formik.setFieldValue(fieldName, newValue);
    // Update the selected options state
    setSelectedOptions((prevSelectedOptions) => ({
      ...prevSelectedOptions,
      [fieldName]: newValue?.value || null,
    }));
  };

  switch (fieldItem.fieldType) {
    case 'text':
      return (
        <Stack mb={1} width='40%'>
          <Typography variant='input-label' fontWeight={800}>
            {fieldItem.fieldTitle}
          </Typography>
          <Typography variant='input-label-gray' fontSize={13}>
            {fieldItem.fieldSubtitle}
          </Typography>
          <TextField
            sx={textInputStyles}
            name='ruleName'
            // @ts-ignore
            value={formik.values[fieldItem.name as AllKeys]}
            hiddenLabel
            variant='outlined'
            size='small'
            placeholder='e.g. Hide Column A'
          />
        </Stack>
      );
    case 'autocomplete':
      // Filter the field options based on the ruleTypes.
      const ruleTypesSet = new Set(fieldItem.ruleTypes);
      let fieldOptions = fieldItem.fieldOptions ?? [];
      if (fieldItem.ruleTypes.length > 0) {
        fieldOptions = fieldOptions.filter((item) => ruleTypesSet.has(item.type));
      }

      // Further filter for unique selection if required
      if (fieldItem.enforceUniqueSelection) {
        const selectedValues = Object.values(selectedOptions);
        fieldOptions = fieldOptions.filter((option) => !selectedValues.includes(option.value));
      }
      return (
        <Stack mb={1} width='40%'>
          <Typography variant='input-label' fontWeight={800}>
            {fieldItem.fieldTitle}
          </Typography>
          <Typography variant='input-label-gray' fontSize={13}>
            {fieldItem.fieldSubtitle}
          </Typography>
          <Autocomplete
            options={fieldOptions || []}
            sx={{ ...autocompleteStyles, width: '100%' }}
            onBlur={() => {
              formik.setFieldTouched(fieldItem.name, true);
            }}
            // @ts-ignore
            value={formik.values[fieldItem.name as AllKeys]}
            onChange={(event, newValue) => {
              handleSelectionChange(fieldItem.name, newValue);
            }}
            clearIcon={null}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={fieldItem.placeholder}
                // @ts-ignore
                error={formik.touched[fieldItem.name as AllKeys] && Boolean(formik.errors[fieldItem.name as AllKeys])}
                // @ts-ignore
                helperText={formik.touched[fieldItem.name as AllKeys] && formik.errors[fieldItem.name as AllKeys]}
              />
            )}
          />
        </Stack>
      );
  }
}

export function generateFunctionDefinitionParamFormFields<T>(
  fieldItems: FunctionDefinitionParamFields[],
  formik: FormikProps<T>,
  selectedOptions: Record<string, any>,
  setSelectedOptions: React.Dispatch<React.SetStateAction<Record<string, string | null>>>
) {
  return fieldItems.map((fieldItem) => generateField(fieldItem, formik, selectedOptions, setSelectedOptions));
}

export function getInitialValues<T>(fields: FunctionDefinitionParamFields[]): T {
  const initialValues: {
    [key in AllKeys]?: AutoCompleteItem | null;
  } = {};

  fields.forEach((field) => {
    initialValues[field.name as AllKeys] = null;
  });

  return initialValues as T;
}

export const FunctionDefinitionLoadParameter = forwardRef<FunctionDefinitionLoadParameterRef, IProps>((props, ref) => {
  const { functionId } = useParams<{ functionId: string }>();
  const { data: functionDefinitionDetails } = useGetFunctionDefinitionDetailsById(functionId);
  const { subjectId, sectionTitle, functionCore, onFormikChange } = props;
  const { data: attributeDataDropdown } = useGetFunctionDefinitionSubjectAttributeDropdown(subjectId);
  const [selectedOptions, setSelectedOptions] = useState<Record<string, string | null>>({});
  const { showDisplayLoadParameter, setShowDisplayLoadParameter } = useContext(CreateFunctionDefinitionContext);

  const loadParameterFields: FunctionDefinitionParamFields[] = useMemo(() => {
    switch (functionCore) {
      case FUNCTION_CORE.VALUE_FUNCTION:
        return [
          {
            name: 'attributePurchaseDate',
            fieldTitle: 'Attribute Purchase Date',
            fieldSubtitle: 'Choose the column for the value Purchase Date.',
            fieldType: 'autocomplete',
            placeholder: 'Select Attribute Purchase Date...',
            fieldOptions: attributeDataDropdown?.data ?? [],
            ruleTypes: RULE_TYPE_DATE_FROM_SUBJECT,
            enforceUniqueSelection: true,
            isRequired: true,
          },
          {
            name: 'attributeSaleDate',
            fieldTitle: 'Attribute Sale Date',
            fieldSubtitle: 'Choose the column for the value Sale Date.',
            fieldType: 'autocomplete',
            placeholder: 'Select Attribute Sale Date...',
            fieldOptions: attributeDataDropdown?.data ?? [],
            ruleTypes: RULE_TYPE_DATE_FROM_SUBJECT,
            enforceUniqueSelection: true,
            isRequired: true,
          },
          {
            name: 'attributeRegoPA',
            fieldTitle: 'Attribute Rego PA',
            fieldSubtitle: 'Choose the column for the value Rego PA.',
            fieldType: 'autocomplete',
            placeholder: 'Select Attribute Rego PA...',
            fieldOptions: attributeDataDropdown?.data ?? [],
            ruleTypes: RULE_TYPE_PRICE_FROM_SUBJECT,
            enforceUniqueSelection: true,
            isRequired: true,
          },
        ];
      case FUNCTION_CORE.COUNT_FUNCTION:
        return [
          {
            name: 'attributeStartDate',
            fieldTitle: 'Attribute Start Date',
            fieldSubtitle: 'Choose the column for the value Start Date.',
            fieldType: 'autocomplete',
            placeholder: 'Select Attribute Start Date...',
            fieldOptions: attributeDataDropdown?.data ?? [],
            ruleTypes: RULE_TYPE_DATE_FROM_SUBJECT,
            enforceUniqueSelection: true,
            isRequired: true,
          },
          {
            name: 'attributeEndDate',
            fieldTitle: 'Attribute End Date',
            fieldSubtitle: 'Choose the column for the value End Date.',
            fieldType: 'autocomplete',
            placeholder: 'Select Attribute End Date...',
            fieldOptions: attributeDataDropdown?.data ?? [],
            ruleTypes: RULE_TYPE_DATE_FROM_SUBJECT,
            enforceUniqueSelection: true,
            isRequired: true,
          },
        ];
      case FUNCTION_CORE.DEPRECIATION_FUNCTION:
        return [
          {
            name: 'attributePurchasePrice',
            fieldTitle: 'Attribute Purchase Price',
            fieldSubtitle: 'Choose the column for the value Purchase Price.',
            fieldType: 'autocomplete',
            placeholder: 'Select Attribute Purchase Price...',
            fieldOptions: attributeDataDropdown?.data ?? [],
            ruleTypes: RULE_TYPE_PRICE_FROM_SUBJECT,
            enforceUniqueSelection: true,
            isRequired: true,
          },
          {
            name: 'attributePurchaseDate',
            fieldTitle: 'Attribute Purchase Date',
            fieldSubtitle: 'Choose the column for the value Purchase Date.',
            fieldType: 'autocomplete',
            placeholder: 'Select Attribute Purchase Date...',
            fieldOptions: attributeDataDropdown?.data ?? [],
            ruleTypes: RULE_TYPE_DATE_FROM_SUBJECT,
            enforceUniqueSelection: true,
            isRequired: true,
          },
          {
            name: 'attributeSalePrice',
            fieldTitle: 'Attribute Sale Price',
            fieldSubtitle: 'Choose the column for the value Sale Price.',
            fieldType: 'autocomplete',
            placeholder: 'Select Attribute Sale Price...',
            fieldOptions: attributeDataDropdown?.data ?? [],
            ruleTypes: RULE_TYPE_PRICE_FROM_SUBJECT,
            enforceUniqueSelection: true,
            isRequired: true,
          },
          {
            name: 'attributeSaleDate',
            fieldTitle: 'Attribute Sale Date',
            fieldSubtitle: 'Choose the column for the value Sale Date.',
            fieldType: 'autocomplete',
            placeholder: 'Select Attribute Sale Date...',
            fieldOptions: attributeDataDropdown?.data ?? [],
            ruleTypes: RULE_TYPE_DATE_FROM_SUBJECT,
            enforceUniqueSelection: true,
            isRequired: true,
          },
          {
            name: 'attributeExpectedLifeYrs',
            fieldTitle: 'Attribute Expected Life Yrs',
            fieldSubtitle: 'Choose the column for the value Expected Life Yrs.',
            fieldType: 'autocomplete',
            placeholder: 'Select Attribute Expected Life Yrs...',
            fieldOptions: attributeDataDropdown?.data ?? [],
            ruleTypes: RULE_TYPE_NUMBER_FROM_SUBJECT,
            enforceUniqueSelection: true,
            isRequired: true,
          },
          {
            name: 'attributeDepreciationType',
            fieldTitle: 'Attribute Depreciation Type',
            fieldSubtitle: 'Choose the column for the value Depreciation Type.',
            fieldType: 'autocomplete',
            placeholder: 'Select Attribute Depreciation Type...',
            fieldOptions: attributeDataDropdown?.data ?? [],
            ruleTypes: [RULES_CONSTANT.SYSTEM_OPTIONS.RULE_SYSTEM_OPTIONS_DEPRECIATION_TYPE],
            enforceUniqueSelection: true,
            isRequired: true,
          },
          {
            name: 'attributeDepreciationRate',
            fieldTitle: 'Attribute Depreciation Rate % PA',
            fieldSubtitle: 'Choose the column for the value Depreciation Rate PA.',
            fieldType: 'autocomplete',
            placeholder: 'Select Attribute Depreciation Rate %...',
            fieldOptions: attributeDataDropdown?.data ?? [],
            ruleTypes: RULE_TYPE_PERCENTAGE_FROM_SUBJECT,
            enforceUniqueSelection: true,
            isRequired: true,
          },
        ];

      case FUNCTION_CORE.INTEREST_FUNCTION:
        return [
          {
            name: 'attributeTotalLoanExcludingInterest',
            fieldTitle: 'Attribute Total Loan Excluding Interest',
            fieldSubtitle: 'Choose the column for the value Total Loan Excluding Interest.',
            fieldType: 'autocomplete',
            placeholder: 'Select Attribute Total Loan Excluding Interest...',
            fieldOptions: attributeDataDropdown?.data ?? [],
            ruleTypes: RULE_TYPE_PRICE_FROM_SUBJECT,
            enforceUniqueSelection: true,
            isRequired: true,
          },
          {
            name: 'attributeInterestRate',
            fieldTitle: 'Attribute Interest Rate PA',
            fieldSubtitle: 'Choose the column for the value Interest Rate PA.',
            fieldType: 'autocomplete',
            placeholder: 'Select Attribute Interest Rate PA...',
            fieldOptions: attributeDataDropdown?.data ?? [],
            ruleTypes: RULE_TYPE_PERCENTAGE_FROM_SUBJECT,
            enforceUniqueSelection: true,
            isRequired: true,
          },
          {
            name: 'attributeContractDate',
            fieldTitle: 'Attribute Loan Contract Date',
            fieldSubtitle: 'Choose the column for the value Contract Date.',
            fieldType: 'autocomplete',
            placeholder: 'Select Attribute Contract Date...',
            fieldOptions: attributeDataDropdown?.data ?? [],
            ruleTypes: RULE_TYPE_DATE_FROM_SUBJECT,
            enforceUniqueSelection: true,
            isRequired: true,
          },
          {
            name: 'attributeNumberOfMonthlyPayments',
            fieldTitle: 'Attribute Number Of Monthly Payments',
            fieldSubtitle: 'Choose the column for the value Number Of Monthly Payments.',
            fieldType: 'autocomplete',
            placeholder: 'Select Attribute Number Of Monthly Payments...',
            fieldOptions: attributeDataDropdown?.data ?? [],
            ruleTypes: RULE_TYPE_NUMBER_FROM_SUBJECT,
            enforceUniqueSelection: true,
            isRequired: true,
          },
          {
            name: 'attributeFirstMonthlyPaymentDate',
            fieldTitle: 'Attribute First Monthly Payment Date',
            fieldSubtitle: 'Choose the column for the value First Monthly Payment Date.',
            fieldType: 'autocomplete',
            placeholder: 'Select Attribute First Monthly Payment Date...',
            fieldOptions: attributeDataDropdown?.data ?? [],
            ruleTypes: RULE_TYPE_DATE_FROM_SUBJECT,
            enforceUniqueSelection: true,
            isRequired: true,
          },
          {
            name: 'attributeMonthlyRepaymentAmount',
            fieldTitle: 'Attribute Monthly Repayment Amount',
            fieldSubtitle: 'Choose the column for the value Monthly Repayment Amount.',
            fieldType: 'autocomplete',
            placeholder: 'Select Attribute Monthly Repayment Amount...',
            fieldOptions: attributeDataDropdown?.data ?? [],
            ruleTypes: RULE_TYPE_PRICE_FROM_SUBJECT,
            enforceUniqueSelection: true,
            isRequired: true,
          },
          {
            name: 'attributeFinalMonthlyRepaymentDate',
            fieldTitle: 'Attribute Final Monthly Repayment Date',
            fieldSubtitle: 'Choose the column for the value Monthly Repayment Date.',
            fieldType: 'autocomplete',
            placeholder: 'Select Attribute Monthly Repayment Date...',
            fieldOptions: attributeDataDropdown?.data ?? [],
            ruleTypes: RULE_TYPE_DATE_FROM_SUBJECT,
            enforceUniqueSelection: true,
            isRequired: true,
          },
          {
            name: 'attributeFinalPayoutDate',
            fieldTitle: 'Attribute Final Payout Date',
            fieldSubtitle: 'Choose the column for the value Final Payout Date.',
            fieldType: 'autocomplete',
            placeholder: 'Select Attribute Final Payout Date...',
            fieldOptions: attributeDataDropdown?.data ?? [],
            ruleTypes: RULE_TYPE_DATE_FROM_SUBJECT,
            enforceUniqueSelection: true,
            isRequired: true,
          },
          {
            name: 'attributeFinalPayoutAmount',
            fieldTitle: 'Attribute Final Payout Amount',
            fieldSubtitle: 'Choose the column for the value Final Payout Amount.',
            fieldType: 'autocomplete',
            placeholder: 'Select Attribute Final Payout Amount...',
            fieldOptions: attributeDataDropdown?.data ?? [],
            ruleTypes: RULE_TYPE_PRICE_FROM_SUBJECT,
            enforceUniqueSelection: true,
            isRequired: true,
          },
        ];
      default:
        return [];
    }
  }, [attributeDataDropdown, functionCore]);
  const validationSchema = useMemo(() => createValidationSchema(loadParameterFields), [loadParameterFields]);
  const initialValues = getInitialValues<FormikInitialValuesLoadParameter>(loadParameterFields);
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {},
  });

  const handleOnChangeShowDisplayLoadParameter = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowDisplayLoadParameter(event.target.checked);
  };

  useImperativeHandle(ref, () => ({
    getValues: () => formik.values,
  }));

  useEffect(() => {
    onFormikChange?.(formik.values, validationSchema.isValidSync(formik.values));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values]);

  useEffect(() => {
    formik.resetForm();
    formik.setValues(initialValues);
    setSelectedOptions({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [functionCore]);

  useEffect(() => {
    if (functionDefinitionDetails?.data && functionDefinitionDetails.data.loadParameters) {
      formik.setValues(functionDefinitionDetails.data.loadParameters as unknown as FormikInitialValuesLoadParameter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [functionDefinitionDetails?.data]);

  return (
    <Stack mb={5}>
      <Typography variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
        {sectionTitle}
      </Typography>
      <Alert severity='info' sx={{ my: 1 }}>
        {functionCore === FUNCTION_CORE.VALUE_FUNCTION && (
          <Typography variant='body2'>
            Value Function requires the following parameters to perform the calculation. Please select the appropriate
            attributes from the dropdowns below.
            <br />
            <u>
              <b>All the fields on this section are required.</b>
            </u>
          </Typography>
        )}
        {functionCore === FUNCTION_CORE.COUNT_FUNCTION && (
          <Typography variant='body2'>
            Count Function requires the following parameters to perform the calculation. Please select the appropriate
            attributes from the dropdowns below.
            <br />
            <u>
              <b>All the fields on this section are required.</b>
            </u>
          </Typography>
        )}
        {functionCore === FUNCTION_CORE.DEPRECIATION_FUNCTION && (
          <Typography variant='body2'>
            Depreciation Function requires the following parameters to perform the calculation. Please select the
            appropriate attributes from the dropdowns below.
            <br />
            <u>
              <b>All the fields on this section are required.</b>
            </u>
          </Typography>
        )}
        {functionCore === FUNCTION_CORE.INTEREST_FUNCTION && (
          <Typography variant='body2'>
            Interest Function requires the following parameters to perform the calculation. Please select the
            appropriate attributes from the dropdowns below.
            <br />
            <u>
              <b>All the fields on this section are required.</b>
            </u>
          </Typography>
        )}
      </Alert>
      <Divider sx={{ my: 1 }} />
      <Stack width='100%' direction='row' gap={3} flexWrap='wrap'>
        {generateFunctionDefinitionParamFormFields<FormikInitialValuesLoadParameter>(
          loadParameterFields,
          formik,
          selectedOptions,
          setSelectedOptions
        )}
      </Stack>
      {functionCore !== FUNCTION_CORE.COUNT_FUNCTION && (
        <Stack direction='row' alignItems='center' mt={2}>
          <Checkbox
            checked={showDisplayLoadParameter}
            onChange={handleOnChangeShowDisplayLoadParameter}
            name='active'
            sx={{
              color: '#828C99',
              px: 0,
              paddingRight: 0.5,
            }}
          />
          <Typography variant='input-label' fontWeight={600}>
            I want to display value from load parameters to the cube
          </Typography>
        </Stack>
      )}
    </Stack>
  );
});
