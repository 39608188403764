import { Alert, Box, FormControlLabel, Modal, Stack, TextField, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import ButtonLoading from '@mui/lab/LoadingButton';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import { ENV_CONFIG } from 'config/env';
import { DataInputImportActivity, DataInputImportActivityStatus } from 'types/api/Tenant/AROKMS/DisplayTableTypes';
import { dayjs } from 'utils/DateUtils';
import { useIgnoreImportNotification } from 'services/v1/Tenant/AROKMS/DisplayTableService';
import { DataGridPro, GridColumns, GridValueFormatterParams } from '@mui/x-data-grid-pro';

interface ModalDataImportNotificationProps {
  subjectId?: string;
  subjectDisplayName?: string;
  importActivity?: DataInputImportActivity;
  visible?: boolean;
  onClose?: () => void;
}

const dataGridStyle = {
  '& .MuiCheckbox-root svg': {
    backgroundColor: 'transparent',
    color: '#98A2AE',
    borderRadius: 2,
  },
  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: '#EDF2FF',
    borderTop: '1px solid #E3EBF6',
    borderBottom: '1px solid #E3EBF6',
    textAlign: 'center',
    display: 'flex',
  },
};

const ModalContentStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 650,
  bgcolor: 'background.paper',
  height: 'auto',
  overflow: 'auto',
  borderRadius: 1,
  py: 2,
};

export function ModalDataImportNotification(props: Readonly<ModalDataImportNotificationProps>) {
  const { importActivity, visible = true, onClose, subjectId, subjectDisplayName } = props;

  const { mutate: ignoreImportNotification, isLoading: isSubmitting } = useIgnoreImportNotification();

  const [checkBoxChecked, setCheckBoxChecked] = useState(false);
  const [reason, setReason] = useState('');
  const [isTextTouched, setIsTextTouched] = useState(false);

  const handleOnCloseModal = () => {
    onClose?.();
    setCheckBoxChecked(false);
    setReason('');
    setIsTextTouched(false);
  };

  const gridRows = useMemo(() => {
    if (importActivity) {
      return [
        {
          id: importActivity.id,
          totalRecords: importActivity.totalRecords,
          totalInsertedRecords: importActivity.totalInsertedRecords,
          totalFailedRecords: importActivity.totalFailedRecords,
          createdAt: importActivity.createdAt,
          createdBy: importActivity.createdBy,
          channelName: importActivity.channelName,
        },
      ];
    }
    return [];
  }, [importActivity]);

  const columns: GridColumns = useMemo(
    () => [
      {
        field: 'totalRecords',
        headerName: 'Total Records',
        valueFormatter: (params: GridValueFormatterParams) => {
          return params.value?.toLocaleString();
        },
        width: 120,
      },
      {
        field: 'totalInsertedRecords',
        headerName: 'Total Inserted',
        valueFormatter: (params: GridValueFormatterParams) => {
          return params.value?.toLocaleString();
        },
        width: 120,
      },
      {
        field: 'totalFailedRecords',
        headerName: 'Total Failed',
        valueFormatter: (params: GridValueFormatterParams) => {
          return params.value?.toLocaleString();
        },
        width: 120,
      },

      {
        field: 'channelName',
        headerName: 'Data Channel',
        valueFormatter: (params: GridValueFormatterParams) => {
          return params.value?.toLocaleString();
        },
        width: 120,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const isButtonDisabled = checkBoxChecked && reason === '';

  const displayMessageStatus = useMemo(() => {
    if (importActivity) {
      if (
        importActivity.status === DataInputImportActivityStatus.COMPLETED &&
        importActivity.totalFailedRecords === 0
      ) {
        return (
          <>
            The data import attempt initiated by {importActivity.createdBy} on{' '}
            {dayjs(importActivity.createdAt).format(ENV_CONFIG.config.DATE_TIME_FORMAT)} was fully successful.
          </>
        );
      } else {
        return (
          <>
            The data import attempt initiated by {importActivity?.createdBy} on{' '}
            {dayjs(importActivity?.createdAt).format(ENV_CONFIG.config.DATE_TIME_FORMAT)} was not fully successful. To
            examine the records that failed to import, please{' '}
            <a href={importActivity?.failedRecordsFileUrl}>click here</a> to download the log. The details of the failed
            records can be found in the last column.
          </>
        );
      }
    }
    return null;
  }, [importActivity]);

  const importActivityStatus = useMemo(() => {
    if (importActivity) {
      if (
        importActivity.status === DataInputImportActivityStatus.COMPLETED &&
        importActivity.totalFailedRecords === 0
      ) {
        return 'success';
      } else {
        return 'error';
      }
    }
    return 'info';
  }, [importActivity]);

  const handleOnCloseClick = () => {
    if (importActivity && subjectId) {
      if (checkBoxChecked && reason !== '') {
        ignoreImportNotification(
          { subjectId, importHistoryId: importActivity.id, description: reason },
          {
            onSuccess: () => {
              handleOnCloseModal();
            },
            onError: () => {
              handleOnCloseModal();
            },
          }
        );
        return;
      }
      if (importActivityStatus === 'success') {
        ignoreImportNotification(
          { subjectId, importHistoryId: importActivity.id, description: '' },
          {
            onSuccess: () => {
              handleOnCloseModal();
            },
            onError: () => {
              handleOnCloseModal();
            },
          }
        );
      }
    }
    handleOnCloseModal();
  };

  return (
    <Modal open={visible}>
      <Stack direction='column' sx={ModalContentStyle} px={2}>
        <Stack justifyContent='space-between' direction='row' alignItems='center'>
          <Typography variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
            {subjectDisplayName ? `${subjectDisplayName} - ` : ''} Data Import Notification
          </Typography>
        </Stack>
        <Divider sx={{ mb: 1, mt: 1 }} />
        <Stack direction='column'>
          <Alert severity={importActivityStatus}>
            <Typography variant='body2'>{displayMessageStatus}</Typography>
          </Alert>

          <Stack height={120} py={2}>
            <DataGridPro
              sx={dataGridStyle}
              autoHeight={false}
              loading={false}
              pagination
              disableMultipleColumnsFiltering
              paginationMode='client'
              density='compact'
              rows={gridRows}
              columns={columns}
              filterMode='client'
              hideFooter
              checkboxSelection={false}
            />
          </Stack>
          {importActivityStatus !== 'success' && (
            <Box display='flex' alignItems='center' justifyContent='space-between' mt={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    value='remember'
                    checked={checkBoxChecked}
                    onChange={(e) => {
                      if (!e.target.checked) {
                        setIsTextTouched(false);
                      }
                      setCheckBoxChecked(e.target.checked);
                    }}
                    style={{
                      color: '#3ABB93',
                    }}
                  />
                }
                label={
                  <Typography component='p' variant='body2' color='#98A2AE'>
                    Do not show this message again
                  </Typography>
                }
              />
            </Box>
          )}
          {checkBoxChecked && (
            <TextField
              margin='dense'
              value={reason}
              onChange={(e) => {
                setReason(e.target.value);
              }}
              onBlur={() => {
                setIsTextTouched(true);
              }}
              fullWidth
              error={isTextTouched && reason === ''}
              helperText={isTextTouched && reason === '' ? 'This field is required' : ''}
              multiline
              rows={4}
              placeholder='Enter note for ignoring this alert...'
              name='userName'
              variant='outlined'
            />
          )}
        </Stack>
        <Stack mt={2}>
          <Divider sx={{ mt: 0, mb: 1 }} />
          <ButtonLoading
            type='submit'
            fullWidth
            loading={isSubmitting}
            disabled={isButtonDisabled}
            onClick={handleOnCloseClick}
            loadingPosition='start'
            variant='main-login'
            sx={{
              textTransform: 'none',
            }}
          >
            Close
          </ButtonLoading>
        </Stack>
      </Stack>
    </Modal>
  );
}
