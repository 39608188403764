import * as React from 'react';
import jwtDecode from 'jwt-decode';
import { Skeleton, SvgIcon } from '@mui/material';
import { useGetDropdownMenuMember, useGetMainMenuNavigationDropdown } from 'services/v1/Common/NavBarMenuService';
import Box from '@mui/material/Box';

// Custom Icon
import PollOutlinedIcon from '@mui/icons-material/PollOutlined';
import InputOutlinedIcon from '@mui/icons-material/InputOutlined';
import { ReactComponent as CustomIconSubject } from 'assets/icons/icon-menu-subject.svg';
import { ReactComponent as CustomIconKTree } from 'assets/icons/icon-menu-ktree.svg';
import { ReactComponent as CustomIconEvent } from 'assets/icons/icon-menu-event.svg';
import { ReactComponent as CustomIconCube } from 'assets/icons/icon-menu-cube.svg';
import { NAV_ITEM_TYPE, SelectedMenuItems } from './HeaderBar';
import { useDispatch, useSelector } from 'react-redux';
import { setHomeNavigationState } from 'store/reducer/homeReducer';
import { PATH_CONSTANT } from 'constant/PathConstant';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'store';
import { NavDropdownItem } from './NavDropdownItem';
import { DropdownItemWithType } from 'types/api/Tenant/AROCube/CubeDataDimensionalViewTypes';
import { AutoCompleteItem } from 'types/api/Common/AutoCompleteTypes';
import { isAdminHasAccess } from 'components/LayoutComponent/SidebarLayout/SidebarComponent/MultiLevel';
import { RoleType } from 'store/reducer/authReducer';

export type NavBarOptionItem = DropdownItemWithType & {
  customURL?: string;
  children?: NavBarOptionItem[];
  subjectId?: string | null;
};

type NavItem = {
  title: string;
  icon: React.ReactNode;
  visible: boolean;
  type: keyof SelectedMenuItems;
  withArrow: boolean;
  withSearch: boolean;
  options: NavBarOptionItem[];
};

export const MENU_SUBJECT_TYPE = {
  STATIC_SUBJECT: 'STATIC_SUBJECT',
  DYNAMIC_SUBJECT: 'DYNAMIC_SUBJECT',
};

const STATIC_SUBJECT_MASTER = [
  {
    title: 'Dashboard',
    path: PATH_CONSTANT.TENANT_MASTER.HOME,
    accessAdminRightLevel: 0,
  },
  {
    title: 'Table Display',
    path: PATH_CONSTANT.TENANT_MASTER.DISPLAY_TABLE,
    accessAdminRightLevel: 5,
  },
  {
    title: 'System',
    path: PATH_CONSTANT.TENANT_MASTER.MASTER_TABLE.SYSTEM,
    accessAdminRightLevel: 4,
  },
  {
    title: 'Server',
    path: PATH_CONSTANT.TENANT_MASTER.MASTER_TABLE.SERVER,
    accessAdminRightLevel: 4,
  },
  {
    title: 'Tenant',
    path: PATH_CONSTANT.TENANT_MASTER.MASTER_TABLE.TENANT,
    accessAdminRightLevel: 3,
  },
  {
    title: 'Datawarehouse',
    path: PATH_CONSTANT.TENANT_MASTER.DATAWAREHOUSE.LIST,
    accessAdminRightLevel: 5,
  },
  {
    title: 'Person',
    path: PATH_CONSTANT.TENANT_MASTER.MASTER_TABLE.PERSON,
    accessAdminRightLevel: 1,
  },
];

function getRedirectUrl(type: keyof SelectedMenuItems, id: string | number, subjectId?: string | number | null) {
  if (!id) return;
  switch (type) {
    case NAV_ITEM_TYPE.SUBJECT:
      return PATH_CONSTANT.TENANT.KMS.DATA_INPUT.replace(':subjectId', id.toString());
    case NAV_ITEM_TYPE.SUBJECT_PROCEDURE:
      if (!subjectId) return;
      return `${PATH_CONSTANT.TENANT.KMS.DATA_INPUT.replace(
        ':subjectId',
        subjectId?.toString()
      )}?procedureId=${id.toString()}`;
    case NAV_ITEM_TYPE.CUBE:
      return PATH_CONSTANT.TENANT.CUBE.CUBE.replace(':cubeId', id.toString());
    case NAV_ITEM_TYPE.STANDARD_VIEW:
      return PATH_CONSTANT.TENANT.VIEW.STANDARD_VIEW.replace(':viewId', id.toString());
    case NAV_ITEM_TYPE.KTREE:
      return PATH_CONSTANT.TENANT.KMS.KTREE_DATA.replace(':kTreeId', id.toString());
    case NAV_ITEM_TYPE.EVENT:
      return PATH_CONSTANT.TENANT.EVENT.EVENT.replace(':subjectId', id.toString());
    case NAV_ITEM_TYPE.MASTER_TABLE_DISPLAY:
      return PATH_CONSTANT.TENANT_MASTER.DISPLAY_TABLE;
    default:
      return PATH_CONSTANT.TENANT.HOME;
  }
}
// In Tenant Master, we need to combine the dynamic subject with the static subject
function addStaticSubject(
  subjectDropdownItems: AutoCompleteItem[],
  threadUserAsRole: RoleType | '',
  decodeToken: { adminRightLevel: number } | ''
): NavBarOptionItem[] {
  const selectedMasterSubjectToShow = STATIC_SUBJECT_MASTER.filter((item) => {
    return isAdminHasAccess(item.accessAdminRightLevel, threadUserAsRole, decodeToken);
  });

  const staticSubjectDropdownItems: NavBarOptionItem[] = selectedMasterSubjectToShow.map((item) => ({
    value: item.path,
    label: item.title,
    type: MENU_SUBJECT_TYPE.STATIC_SUBJECT,
    customURL: item.path,
  }));

  const modifedDropdownItems: NavBarOptionItem[] = subjectDropdownItems.map((item) => ({
    ...item,
    value: item.value.toString(),
    label: item.label,
    id: item.value,
    type: MENU_SUBJECT_TYPE.DYNAMIC_SUBJECT,
  }));

  const items = [...staticSubjectDropdownItems, ...modifedDropdownItems];

  return items.sort((a, b) => a.label.localeCompare(b.label));
}

export function Navigation(props: { clickAble?: boolean }) {
  const { clickAble } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const homeReducer = useSelector((state: RootState) => state.home);
  const authReducer = useSelector((state: RootState) => state.auth);

  const { threadUserAsRole, accessToken } = authReducer;
  const decodeToken = accessToken && (jwtDecode(accessToken) as { adminRightLevel: number });

  const { data: menuDropdownData, isLoading: isLoadingMainMenu } = useGetMainMenuNavigationDropdown();

  const { data: dropdownMember, isLoading: isLoadingDropdownMember } = useGetDropdownMenuMember(
    homeReducer?.navigationState?.subject?.type === MENU_SUBJECT_TYPE.STATIC_SUBJECT
      ? null
      : homeReducer.navigationState.subject?.value?.toString()
  );

  const subjectNavItems: NavItem[] = React.useMemo(() => {
    if (menuDropdownData?.data && menuDropdownData.data.subjectOptions.length > 0) {
      const mainMember = [
        {
          title: 'Subject',
          icon: <SvgIcon fontSize='small' color='inherit' inheritViewBox component={CustomIconSubject} />,
          visible: true,
          withArrow: true,
          withSearch: true,
          type: NAV_ITEM_TYPE.SUBJECT as keyof SelectedMenuItems,
          options: addStaticSubject(menuDropdownData.data.subjectOptions, threadUserAsRole, decodeToken) || [],
        },
      ];
      return mainMember;
    }
    return [];
  }, [menuDropdownData?.data, decodeToken, threadUserAsRole]);

  const processNavItems: NavItem[] = React.useMemo(() => {
    if (menuDropdownData?.data && menuDropdownData.data.processOptions.length > 0) {
      const mainMember = [
        {
          title: 'Process',
          icon: <InputOutlinedIcon fontSize='small' color='inherit' />,
          visible: menuDropdownData?.data?.processOptions?.length > 0,
          withArrow: true,
          withSearch: false,
          type: NAV_ITEM_TYPE.SUBJECT_PROCEDURE as keyof SelectedMenuItems,
          options: addStaticSubject(menuDropdownData.data.processOptions, threadUserAsRole, decodeToken) || [],
        },
      ];
      return mainMember;
    }
    return [];
  }, [menuDropdownData?.data, decodeToken, threadUserAsRole]);

  const navItems: NavItem[] = React.useMemo(() => {
    if (dropdownMember?.data) {
      const menuMember = [
        {
          title: 'Member',
          icon: <SvgIcon fontSize='small' sx={{ fill: 'inherit' }} inheritViewBox component={CustomIconKTree} />,
          visible: dropdownMember?.data?.dropdownOptions?.ktreeDropdownOptions?.length > 0,
          withArrow: true,
          withSearch: false,
          type: NAV_ITEM_TYPE.KTREE as keyof SelectedMenuItems,
          options: (dropdownMember?.data?.dropdownOptions?.ktreeDropdownOptions as NavBarOptionItem[]) || [],
        },
        {
          title: 'Event',
          icon: <SvgIcon fontSize='small' sx={{ fill: 'transparent' }} inheritViewBox component={CustomIconEvent} />,
          visible: dropdownMember?.data?.dropdownOptions?.eventDropdownOptions?.length > 0,
          withArrow: true,
          withSearch: false,
          type: NAV_ITEM_TYPE.EVENT as keyof SelectedMenuItems,
          options: (dropdownMember?.data?.dropdownOptions?.eventDropdownOptions as NavBarOptionItem[]) || [],
        },
        {
          title: 'Cube',
          icon: <SvgIcon fontSize='small' sx={{ fill: 'inherit' }} inheritViewBox component={CustomIconCube} />,
          visible: dropdownMember?.data?.dropdownOptions?.cubeDropdownOptions?.length > 0,
          withArrow: true,
          withSearch: false,
          type: NAV_ITEM_TYPE.CUBE as keyof SelectedMenuItems,
          options: (dropdownMember?.data?.dropdownOptions?.cubeDropdownOptions as NavBarOptionItem[]) || [],
        },
        {
          title: 'View',
          icon: <PollOutlinedIcon fontSize='small' color='inherit' />,
          visible: dropdownMember?.data?.dropdownOptions?.viewDropdownOptions?.length > 0,
          withArrow: true,
          withSearch: false,
          type: NAV_ITEM_TYPE.STANDARD_VIEW as keyof SelectedMenuItems,
          options: (dropdownMember?.data?.dropdownOptions?.viewDropdownOptions as NavBarOptionItem[]) || [],
        },
      ];

      return menuMember;
    }
    return [];
  }, [dropdownMember]);

  const handleOnSelectedSubjectChange = (
    selectedSubject: NavBarOptionItem | undefined,
    type: keyof SelectedMenuItems
  ) => {
    if (selectedSubject && selectedSubject.type === MENU_SUBJECT_TYPE.DYNAMIC_SUBJECT) {
      dispatch(
        setHomeNavigationState({
          subject: selectedSubject,
          cube: null,
          event: null,
          ktree: null,
          view: null,
          'subject-procedure': null,
        })
      );
      if (selectedSubject.value) {
        const navigateUrl = getRedirectUrl(type, selectedSubject?.value?.toString(), selectedSubject?.subjectId);
        navigateUrl && navigate(navigateUrl);
      }
    }
    if (selectedSubject && selectedSubject.type === MENU_SUBJECT_TYPE.STATIC_SUBJECT && selectedSubject.customURL) {
      dispatch(
        setHomeNavigationState({
          subject: selectedSubject,
          cube: null,
          event: null,
          ktree: null,
          view: null,
          'subject-procedure': null,
        })
      );
      navigate(selectedSubject.customURL);
    }
  };

  const handleSelectedMenu = (selectedMenu: NavBarOptionItem | undefined, type: keyof SelectedMenuItems) => {
    if (type !== NAV_ITEM_TYPE.CUBE) {
      dispatch(setHomeNavigationState({ [type]: selectedMenu, cube: null }));
    }
    if (type !== NAV_ITEM_TYPE.KTREE) {
      dispatch(setHomeNavigationState({ [type]: selectedMenu, ktree: null }));
    }
    if (type !== NAV_ITEM_TYPE.EVENT) {
      dispatch(setHomeNavigationState({ [type]: selectedMenu, event: null }));
    }
    if (type !== NAV_ITEM_TYPE.STANDARD_VIEW) {
      dispatch(setHomeNavigationState({ [type]: selectedMenu, view: null }));
    }
    if (type !== NAV_ITEM_TYPE.SUBJECT_PROCEDURE) {
      dispatch(setHomeNavigationState({ [type]: selectedMenu, 'subject-procedure': null }));
    }
    if (selectedMenu?.value) {
      const navigateUrl = getRedirectUrl(type, selectedMenu?.value, selectedMenu?.subjectId);
      navigateUrl && navigate(navigateUrl);
    }
  };

  return (
    <Box sx={{ display: 'flex' }}>
      {isLoadingMainMenu ? (
        <Skeleton variant='rounded' width={90} height={30} />
      ) : (
        subjectNavItems.map((item) => (
          <NavDropdownItem
            clickAble={clickAble}
            key={item.title}
            item={item.title}
            type={item.type}
            onChange={handleOnSelectedSubjectChange}
            iconComponent={item.icon}
            visible={item.visible}
            withSearch={item.withSearch}
            withArrow={item.withArrow}
            options={item.options}
          />
        ))
      )}
      {isLoadingMainMenu ? (
        <Skeleton variant='rounded' width={90} sx={{ ml: 1 }} height={30} />
      ) : (
        processNavItems.map((item) => (
          <NavDropdownItem
            clickAble={clickAble}
            key={item.title}
            item={item.title}
            type={item.type}
            onChange={handleSelectedMenu}
            iconComponent={item.icon}
            visible={item.visible}
            withSearch={item.withSearch}
            withArrow={item.withArrow}
            options={item.options}
          />
        ))
      )}

      {isLoadingDropdownMember ? (
        <>
          <Skeleton variant='rounded' sx={{ ml: 1 }} width={90} height={30} />
          <Skeleton variant='rounded' sx={{ ml: 1 }} width={90} height={30} />
        </>
      ) : (
        navItems.map((item) => (
          <NavDropdownItem
            key={item.title}
            clickAble={clickAble}
            item={item.title}
            onChange={handleSelectedMenu}
            type={item.type}
            iconComponent={item.icon}
            visible={item.visible}
            withSearch={item.withSearch}
            withArrow={item.withArrow}
            options={item.options}
          />
        ))
      )}
    </Box>
  );
}
