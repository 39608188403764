export const DropdownSX = {
  autoComplete: {
    width: 260,
    '& .MuiAutocomplete-popper': {
      backgroundColor: 'red !imoprtant',
      fontSize: '10px',
    },
  },
};

export const ToggleButtonGroupSX = {
  buttonGroup: {
    '& .MuiButtonBase-root': {
      borderRadius: 1,
      fontSize: '13px',
      border: '1px solid #bec1c4',
      padding: '6px 12px !important',
      fontWeight: 'bold',

      textTransform: 'none',
      '&:disabled': {
        opacity: 0.5,
        cursor: 'not-allowed',
      },
      '&.Mui-selected': {
        backgroundColor: '#42BB93',
        color: '#fff',
        '&:hover': {
          backgroundColor: '#42BB93',
          color: '#fff',
        },
      },
    },
  },
};
