import { AxiosResponse } from 'axios';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import axios from 'libs/Axios';
import qs from 'query-string';

import { AxiosDefaultErrorEntity } from 'types/api/Common/ErrorTypes';
import {
  CubeDefinitionDetails,
  CubeDefinitionDropdownPanelOptions,
  CubeDefinitionEventAttributeResponse,
  CubeDefinitionItem,
  CubeDefinitionTimePeriodSetResponse,
  RequestCreateCubeDefinition,
} from 'types/api/SystemTenant/AROCube/CubeBuilderTypes';
import { DropdownItem } from 'types/api/SystemTenant/AROKMS/TableDefinitionTypes';
import { ENV_CONFIG } from 'config/env';

export const CUBE_BUILDER_QUERY_KEY = {
  DEFINITION_LIST: 'CUBE_DEFINIITON_LIST',
  DROPDOWNS_PANEL: 'CUBE_DROPDOWNS_PANEL',
  CUBE_DEFINITION_DETAILS: 'CUBE_DEFINITION_DETAILS',
  CUBE_EVENT_ATTRIBUTES_LIST: 'CUBE_EVENT_ATTRIBUTES_LIST',
  PULL_CUBE_DROPDOWN: 'PULL_CUBE_DROPDOWN',
  PULL_CUBE_DROPDOWN_LAYER: 'PULL_CUBE_DROPDOWN_LAYER',
  CUBE_DEFINITION_TIME_PERIOD_SET: 'CUBE_DEFINITION_TIME_PERIOD_SET',
};

export function useGetPullCubeDropdown({
  subjectId,
  attributeId,
  layerSetId,
  timePeriodSetId,
  timePeriodType,
}: {
  subjectId?: string | number;
  attributeId?: string | number;
  layerSetId?: string | number;
  timePeriodSetId?: string | number;
  timePeriodType?: string;
}) {
  return useQuery<AxiosResponse<DropdownItem[]>, AxiosDefaultErrorEntity>(
    [CUBE_BUILDER_QUERY_KEY.PULL_CUBE_DROPDOWN, subjectId, attributeId, layerSetId, timePeriodSetId, timePeriodType],
    () =>
      axios
        .get(
          `/api/v1/cube-definition/pull-cube/dropdown?${qs.stringify({
            subjectId,
            attributeId,
            layerSetId,
            timePeriodSetId,
            timePeriodType,
          })}`
        )
        .then((res) => res)
  );
}

export function useGetPullCubeLayerDropdown(cubeDefinitionId?: string) {
  return useQuery<AxiosResponse<DropdownItem[]>, AxiosDefaultErrorEntity>(
    [CUBE_BUILDER_QUERY_KEY.PULL_CUBE_DROPDOWN_LAYER, cubeDefinitionId],
    () => axios.get(`/api/v1/cube-definition/pull-cube/dropdown/layer/${cubeDefinitionId}`).then((res) => res),
    {
      enabled: !!cubeDefinitionId,
    }
  );
}

export function useGetCubeEventAttributeList(subjectId?: string | number) {
  return useQuery<AxiosResponse<CubeDefinitionEventAttributeResponse>, AxiosDefaultErrorEntity>(
    [CUBE_BUILDER_QUERY_KEY.DEFINITION_LIST, subjectId],
    () => axios.get(`/api/v1/cube-definition/event-attribute/${subjectId}`).then((res) => res),
    {
      enabled: !!subjectId,
      refetchOnMount: true,
    }
  );
}

export function useGetCubeEventAttributeListMutation() {
  return useMutation<
    AxiosResponse<CubeDefinitionEventAttributeResponse>,
    AxiosDefaultErrorEntity,
    { subjectId?: string | number }
  >((payload) => axios.get(`/api/v1/cube-definition/event-attribute/${payload.subjectId}`).then((res) => res));
}

export function useGetCubeDefinitionTimePeriodSet(cubeDefinitionId?: string) {
  return useQuery<AxiosResponse<CubeDefinitionTimePeriodSetResponse>, AxiosDefaultErrorEntity>(
    [CUBE_BUILDER_QUERY_KEY.CUBE_DEFINITION_TIME_PERIOD_SET, cubeDefinitionId],
    () => axios.get(`/api/v1/cube-definition/time-period-set/${cubeDefinitionId}`).then((res) => res),
    {
      enabled: !!cubeDefinitionId,
    }
  );
}

export function useGetCubeDefinitionDetails(cubeId?: string, options?: { refetchInterval: number | false }) {
  return useQuery<AxiosResponse<CubeDefinitionDetails>, AxiosDefaultErrorEntity>(
    [CUBE_BUILDER_QUERY_KEY.CUBE_DEFINITION_DETAILS, cubeId],
    () => axios.get(`/api/v1/cube-definition/${cubeId}`).then((res) => res),
    {
      enabled: !!cubeId,
      refetchInterval: options?.refetchInterval,
      cacheTime: !options?.refetchInterval ? ENV_CONFIG.endpoint.MAXIMUM_CACHE_TIME : undefined,
      staleTime: !options?.refetchInterval ? ENV_CONFIG.endpoint.MAXIMUM_CACHE_TIME : undefined,
    }
  );
}

export function useGetAllCubeDefinitionList(subjectId?: string | number) {
  return useQuery<AxiosResponse<CubeDefinitionItem[]>, AxiosDefaultErrorEntity>(
    [CUBE_BUILDER_QUERY_KEY.DEFINITION_LIST, subjectId],
    () => axios.get(`/api/v1/cube-definition/list/${subjectId}`).then((res) => res),
    {
      enabled: !!subjectId,
    }
  );
}

export function useGetDropdownsCubePanel(subjectId?: string | number) {
  return useQuery<AxiosResponse<CubeDefinitionDropdownPanelOptions>, AxiosDefaultErrorEntity>(
    [CUBE_BUILDER_QUERY_KEY.DROPDOWNS_PANEL, subjectId],
    () => axios.get(`/api/v1/cube-definition/dropdown/${subjectId}`).then((res) => res),
    {
      enabled: !!subjectId,
    }
  );
}

export function useUpsertCubeDefinition(subjectId?: string | number) {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosDefaultErrorEntity, RequestCreateCubeDefinition>(
    (data) => axios.post(`/api/v1/cube-definition/upsert/${subjectId}`, data).then((res) => res),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(CUBE_BUILDER_QUERY_KEY.DEFINITION_LIST);
        queryClient.invalidateQueries(CUBE_BUILDER_QUERY_KEY.CUBE_DEFINITION_DETAILS);
      },
      onError: (error, variables, ctx) => {
        queryClient.invalidateQueries(CUBE_BUILDER_QUERY_KEY.CUBE_DEFINITION_DETAILS);
        queryClient.getDefaultOptions().mutations?.onError?.(error, variables, ctx);
      },
    }
  );
}
