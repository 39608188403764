import { dayjs } from 'utils/DateUtils';
import { NumericFormat } from 'react-number-format';
import { Autocomplete, Box, Stack, TextField, Typography } from '@mui/material';
import { FormikProps } from 'formik';
import { CustomRuleObject, Field, GetPullAttributeMappingResponse } from 'types/api/Tenant/AROKMS/DisplayTableTypes';
import { RULES_CONSTANT } from 'constant/RuleConstant';
import { convertRuleTypeParamsToSnakeCase } from 'utils/String';
import { DatePicker, DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DataInputFileUpload } from './FileUpload';
import { DynamicTextInput } from './DynamicTextInput';
import { PullAttributeTextInput } from './PullAttributeTextInput';
import { DataInputLocationPicker } from './LocationPicker';
import { DropdownCreatableMemberList } from './DropdownCreatableMemberList';
import { DropdownKTree } from './DropdownKTree';
import { ENV_CONFIG } from 'config/env';
import { CUSTOM_RULE_ACTION } from './CustomRuleDetail';
import { customRuleImplementation } from './CustomRuleImplementation';
import { DropdownCreatableMemberListAttributeBaseSubject } from './DropdownAttributeBaseSubject';
import { HighlightedText } from 'components/HighlightedTextComponent';
import { DropdownLimitedMemberList } from './DropdownLimitedMemberList';

const autocompleteStyles = {
  width: '65%',
  '& .MuiOutlinedInput-root': {
    padding: '1px 2px',
  },
  '& .MuiAutocomplete-popper': {
    fontSize: '10px',
  },
};

const textInputAutoCompleteStyles = {
  '& .MuiOutlinedInput': {
    padding: '1px 2px',
  },
};

const optionStyles = { '& > span': { fontSize: '14px', mr: 2, flexShrink: 0 } };

export const WIDTH_LABEL = '40%';

export const INPUT_EDIT_MESSAGE = "Once you've submitted, this field can no longer be changed";

function getGeneratedFieldPlaceholder(ruleTypeCode: string) {
  switch (ruleTypeCode) {
    case RULES_CONSTANT.GENERATED_SYSTEM.RULE_GENERATED_SYSTEM_DATE:
    case RULES_CONSTANT.GENERATED_SYSTEM.RULE_GENERATED_SYSTEM_DATE_TIME:
    case RULES_CONSTANT.GENERATED_SYSTEM.RULE_GENERATED_SYSTEM_MODIFIER:
      return 'Generated by system';

    case RULES_CONSTANT.TEXT.RULE_PULL_ATTRIBUTE:
      return 'Pull from subject attribute';
  }
}

export function generateFormInput(
  formikProps: FormikProps<{ [key: string]: string }>,
  fieldItem: Field,
  isEdit?: boolean,
  customRules?: CustomRuleObject[],
  pullAttributeMapping?: GetPullAttributeMappingResponse
) {
  const { ruleTypeCode, allowEmpty } = fieldItem;
  let prefix, suffix;

  fieldItem.placeholder = fieldItem.placeholder?.replace(fieldItem.label, fieldItem.realLabel);
  fieldItem.label = fieldItem.realLabel || fieldItem.label;
  if (customRules && Array.isArray(customRules)) {
    for (let customRule of customRules) {
      if (
        customRule.action === CUSTOM_RULE_ACTION.HIDE &&
        customRuleImplementation.hideField(customRule.conditions || [], formikProps.values)
      ) {
        return null;
      }

      if (
        customRule.action === CUSTOM_RULE_ACTION.SHOW &&
        !customRuleImplementation.showField(customRule.conditions || [], formikProps.values)
      ) {
        return null;
      }

      if (
        customRule.action === CUSTOM_RULE_ACTION.RENAME_FIELD &&
        customRuleImplementation.renameField(customRule.conditions || [], formikProps.values) &&
        customRule.parameterValue
      ) {
        fieldItem.placeholder = fieldItem.placeholder?.replace(fieldItem.label, customRule.parameterValue);
        fieldItem.label = customRule.parameterValue || fieldItem.label;
      }
    }
  }

  switch (ruleTypeCode) {
    case RULES_CONSTANT.TEXT.RULE_PULL_FROM_SUBJECT_ATTRIBUTE:
    case RULES_CONSTANT.SYSTEM_OPTIONS.RULE_SYSTEM_OPTIONS_DEPRECIATION_TYPE:
      return (
        <Stack direction='row' justifyContent='space-between' alignItems='center' marginTop={2.5} gap={1}>
          <Stack width={WIDTH_LABEL}>
            <Typography variant='input-label' fontWeight={700}>
              {fieldItem.label}
              {!allowEmpty && <span style={{ color: 'red', marginLeft: 2 }}>*</span>}
            </Typography>
            {fieldItem.columnEditable === false && !isEdit && (
              <Typography variant='input-label-gray' fontSize={12}>
                {INPUT_EDIT_MESSAGE}
              </Typography>
            )}
          </Stack>
          <Autocomplete
            // @ts-ignore
            value={
              formikProps.values[fieldItem.name] === '' ? { value: '', label: '' } : formikProps.values[fieldItem.name]
            }
            onChange={(event, newValue) => {
              formikProps.setFieldValue(fieldItem.name, newValue);
            }}
            onBlur={formikProps.handleBlur}
            getOptionLabel={(option) => option.label}
            clearIcon={null}
            contentEditable={false}
            options={fieldItem.options || []}
            renderOption={(props, option, state) => {
              return (
                <Box component='li' sx={optionStyles} {...props}>
                  <span>
                    <HighlightedText text={option.label} highlight={state.inputValue} />
                  </span>
                </Box>
              );
            }}
            sx={autocompleteStyles}
            renderInput={(params) => (
              <TextField
                {...params}
                name={fieldItem.name}
                sx={textInputAutoCompleteStyles}
                placeholder={fieldItem.placeholder}
                error={!!formikProps.touched[fieldItem.name] && !!formikProps.errors[fieldItem.name]}
                helperText={formikProps.touched[fieldItem.name] && formikProps.errors[fieldItem.name]}
              />
            )}
          />
        </Stack>
      );

    case RULES_CONSTANT.ASSOCIATED_SUBJECT.RULE_ASSOCIATED_SUBJECT_LIMITED_DROPDOWN_LIST:
      return (
        <DropdownLimitedMemberList field={fieldItem} formik={formikProps} isEdit={isEdit} required={!allowEmpty} />
      );

    case RULES_CONSTANT.ASSOCIATED_SUBJECT.RULE_ASSOCIATED_SUBJECT_LIST:
      return (
        <DropdownCreatableMemberList
          field={fieldItem}
          freeSolo={false}
          required={!allowEmpty}
          isEdit={isEdit}
          formik={formikProps}
          sx={{ width: '65%' }}
          ruleTypeCode={fieldItem.ruleTypeCode}
        />
      );

    case RULES_CONSTANT.BASE_SUBJECT.RULE_ATTRIBUTE_SUBJECT_BASE: {
      if (isEdit) {
        return (
          <DynamicTextInput
            isEdit={isEdit}
            required={!allowEmpty}
            field={fieldItem}
            formik={formikProps}
            ruleTypeCode={fieldItem.ruleTypeCode}
          />
        );
      }

      return (
        <DropdownCreatableMemberListAttributeBaseSubject
          field={fieldItem}
          required={!allowEmpty}
          isEdit={isEdit}
          formik={formikProps}
          sx={{ width: '65%' }}
          ruleTypeCode={fieldItem.ruleTypeCode}
        />
      );
    }

    case RULES_CONSTANT.TEXT.RULE_SELECT_FROM_KTREE_TABLE:
      return (
        <DropdownKTree
          field={fieldItem}
          formik={formikProps}
          required={!allowEmpty}
          isEdit={isEdit}
          sx={{ width: '65%' }}
          ruleTypeCode={fieldItem.ruleTypeCode}
        />
      );

    case RULES_CONSTANT.INTEGER.RULE_INTEGER:
    case RULES_CONSTANT.NUMBER.RULE_DECIMAL_INPUT:
      return (
        <Stack direction='row' justifyContent='space-between' alignItems='center' marginTop={2.5} gap={1}>
          <Stack width={WIDTH_LABEL}>
            <Typography variant='input-label' fontWeight={700}>
              {fieldItem.label}
              {!allowEmpty && <span style={{ color: 'red', marginLeft: 2 }}>*</span>}
            </Typography>
            {fieldItem.columnEditable === false && !isEdit && (
              <Typography variant='input-label-gray' fontSize={12}>
                {INPUT_EDIT_MESSAGE}
              </Typography>
            )}
          </Stack>
          <NumericFormat
            sx={{ width: '65%' }}
            hiddenLabel
            placeholder={fieldItem.placeholder}
            thousandsGroupStyle='thousand'
            thousandSeparator=','
            disabled={fieldItem.disabled}
            variant='outlined'
            size='small'
            id={fieldItem.name}
            onBlur={formikProps.handleBlur}
            decimalScale={fieldItem.ruleTypeCode === RULES_CONSTANT.INTEGER.RULE_INTEGER ? 0 : 3}
            error={!!formikProps.touched[fieldItem.name] && !!formikProps.errors[fieldItem.name]}
            helperText={formikProps.touched[fieldItem.name] && formikProps.errors[fieldItem.name]}
            value={formikProps.values[fieldItem.name]}
            onValueChange={(values) => {
              const { formattedValue } = values;
              formikProps.setFieldValue(fieldItem.name, formattedValue);
            }}
            customInput={TextField}
          />
        </Stack>
      );

    case RULES_CONSTANT.INTEGER.RULE_INTEGER_RANGE:
      const columnsIntRange = convertRuleTypeParamsToSnakeCase(fieldItem.ruleTypeParams);
      const minIntValue = Number(columnsIntRange?.[0]) || undefined;
      const maxIntValue = Number(columnsIntRange?.[1]) || undefined;
      return (
        <Stack direction='row' justifyContent='space-between' alignItems='center' marginTop={2.5} gap={1}>
          <Stack width={WIDTH_LABEL}>
            <Typography variant='input-label' fontWeight={700}>
              {fieldItem.label}
              {!allowEmpty && <span style={{ color: 'red', marginLeft: 2 }}>*</span>}
            </Typography>
            {fieldItem.columnEditable === false && !isEdit && (
              <Typography variant='input-label-gray' fontSize={12}>
                {INPUT_EDIT_MESSAGE}
              </Typography>
            )}
          </Stack>
          <NumericFormat
            sx={{ width: '65%' }}
            hiddenLabel
            placeholder={fieldItem.placeholder}
            disabled={fieldItem.disabled}
            thousandsGroupStyle='thousand'
            thousandSeparator=','
            isAllowed={(values) => {
              const { floatValue } = values;
              if (!floatValue) return true;
              if (minIntValue && maxIntValue) {
                return floatValue >= minIntValue && floatValue <= maxIntValue;
              }
              if (minIntValue) {
                return floatValue >= minIntValue;
              }
              if (maxIntValue) {
                return floatValue <= maxIntValue;
              }
              return true;
            }}
            variant='outlined'
            size='small'
            id={fieldItem.name}
            onBlur={formikProps.handleBlur}
            decimalScale={0}
            error={!!formikProps.touched[fieldItem.name] && !!formikProps.errors[fieldItem.name]}
            helperText={formikProps.touched[fieldItem.name] && formikProps.errors[fieldItem.name]}
            value={formikProps.values[fieldItem.name]}
            onValueChange={(values) => {
              const { formattedValue } = values;
              formikProps.setFieldValue(fieldItem.name, formattedValue);
            }}
            customInput={TextField}
          />
        </Stack>
      );

    case RULES_CONSTANT.PERCENTAGE.RULE_PERCENTAGE_INPUT:
      return (
        <Stack direction='row' justifyContent='space-between' alignItems='center' marginTop={2.5} gap={1}>
          <Stack width={WIDTH_LABEL}>
            <Typography variant='input-label' fontWeight={700}>
              {fieldItem.label}
              {!allowEmpty && <span style={{ color: 'red', marginLeft: 2 }}>*</span>}
            </Typography>
            {fieldItem.columnEditable === false && !isEdit && (
              <Typography variant='input-label-gray' fontSize={12}>
                {INPUT_EDIT_MESSAGE}
              </Typography>
            )}
          </Stack>
          <NumericFormat
            sx={{ width: '65%' }}
            hiddenLabel
            placeholder={fieldItem.placeholder}
            thousandsGroupStyle='thousand'
            thousandSeparator=','
            disabled={fieldItem.disabled}
            variant='outlined'
            suffix='%'
            size='small'
            id={fieldItem.name}
            onBlur={formikProps.handleBlur}
            decimalScale={2}
            error={!!formikProps.touched[fieldItem.name] && !!formikProps.errors[fieldItem.name]}
            helperText={formikProps.touched[fieldItem.name] && formikProps.errors[fieldItem.name]}
            value={formikProps.values[fieldItem.name]}
            onValueChange={(values) => {
              const { formattedValue } = values;
              formikProps.setFieldValue(fieldItem.name, formattedValue);
            }}
            customInput={TextField}
          />
        </Stack>
      );

    case RULES_CONSTANT.PERCENTAGE.RULE_PERCENTAGE:
      const columnsPercentageRange = convertRuleTypeParamsToSnakeCase(fieldItem.ruleTypeParams);
      const minPercentageValue = Number(columnsPercentageRange?.[0]) || undefined;
      const maxPercentageValue = Number(columnsPercentageRange?.[1]) || undefined;
      return (
        <Stack direction='row' justifyContent='space-between' alignItems='center' marginTop={2.5} gap={1}>
          <Stack width={WIDTH_LABEL}>
            <Typography variant='input-label' fontWeight={700}>
              {fieldItem.label}
              {!allowEmpty && <span style={{ color: 'red', marginLeft: 2 }}>*</span>}
            </Typography>
            {fieldItem.columnEditable === false && !isEdit && (
              <Typography variant='input-label-gray' fontSize={12}>
                {INPUT_EDIT_MESSAGE}
              </Typography>
            )}
          </Stack>
          <NumericFormat
            sx={{ width: '65%' }}
            hiddenLabel
            suffix='%'
            placeholder={fieldItem.placeholder}
            thousandsGroupStyle='thousand'
            thousandSeparator=','
            disabled={fieldItem.disabled}
            isAllowed={(values) => {
              const { floatValue } = values;
              if (!floatValue) return true;
              if (minPercentageValue && maxPercentageValue) {
                return floatValue >= minPercentageValue && floatValue <= maxPercentageValue;
              }
              if (minPercentageValue) {
                return floatValue >= minPercentageValue;
              }
              if (maxPercentageValue) {
                return floatValue <= maxPercentageValue;
              }
              return true;
            }}
            variant='outlined'
            size='small'
            id={fieldItem.name}
            onBlur={formikProps.handleBlur}
            decimalScale={0}
            error={!!formikProps.touched[fieldItem.name] && !!formikProps.errors[fieldItem.name]}
            helperText={formikProps.touched[fieldItem.name] && formikProps.errors[fieldItem.name]}
            value={formikProps.values[fieldItem.name]}
            onValueChange={(values) => {
              const { formattedValue } = values;
              formikProps.setFieldValue(fieldItem.name, formattedValue);
            }}
            customInput={TextField}
          />
        </Stack>
      );

    case RULES_CONSTANT.CURRENCY.RULE_CURRENCY:
      const columns = convertRuleTypeParamsToSnakeCase(fieldItem.ruleTypeParams);
      prefix = columns?.[0] || '';
      suffix = columns?.[1] || '';

      if (typeof suffix === 'string' && suffix.length > 0) {
        suffix = suffix.toLocaleUpperCase();
      }

      return (
        <Stack direction='row' justifyContent='space-between' alignItems='center' marginTop={2.5} gap={1}>
          <Stack width={WIDTH_LABEL}>
            <Typography variant='input-label' fontWeight={700}>
              {fieldItem.label}
              {!allowEmpty && <span style={{ color: 'red', marginLeft: 2 }}>*</span>}
            </Typography>
            {fieldItem.columnEditable === false && !isEdit && (
              <Typography variant='input-label-gray' fontSize={12}>
                {INPUT_EDIT_MESSAGE}
              </Typography>
            )}
          </Stack>
          <NumericFormat
            sx={{ width: '65%' }}
            hiddenLabel
            prefix={prefix + ' '}
            placeholder={fieldItem.placeholder}
            suffix={' ' + suffix}
            thousandsGroupStyle='thousand'
            disabled={fieldItem.disabled}
            thousandSeparator=','
            variant='outlined'
            size='small'
            id={fieldItem.name}
            onBlur={formikProps.handleBlur}
            decimalScale={3}
            error={!!formikProps.touched[fieldItem.name] && !!formikProps.errors[fieldItem.name]}
            helperText={formikProps.touched[fieldItem.name] && formikProps.errors[fieldItem.name]}
            value={formikProps.values[fieldItem.name]}
            onValueChange={(values) => {
              const { formattedValue } = values;
              formikProps.setFieldValue(fieldItem.name, formattedValue);
            }}
            customInput={TextField}
          />
        </Stack>
      );

    case RULES_CONSTANT.CURRENCY.RULE_CURRENCY_RANGE:
      const columnsCurrencyRange = convertRuleTypeParamsToSnakeCase(fieldItem.ruleTypeParams);
      const currencyRangePrefix = columnsCurrencyRange?.[0] || '';
      const currencyRangeSuffix = columnsCurrencyRange?.[1] || '';
      const minRangeValue = Number(columnsCurrencyRange?.[2]) || undefined;
      const maxRangeValue = Number(columnsCurrencyRange?.[3]) || undefined;

      return (
        <Stack direction='row' justifyContent='space-between' alignItems='center' marginTop={2.5} gap={1}>
          <Stack width={WIDTH_LABEL}>
            <Typography variant='input-label' fontWeight={700}>
              {fieldItem.label}
              {!allowEmpty && <span style={{ color: 'red', marginLeft: 2 }}>*</span>}
            </Typography>
            {fieldItem.columnEditable === false && !isEdit && (
              <Typography variant='input-label-gray' fontSize={12}>
                {INPUT_EDIT_MESSAGE}
              </Typography>
            )}
          </Stack>
          <NumericFormat
            sx={{ width: '65%' }}
            hiddenLabel
            disabled={fieldItem.disabled}
            prefix={currencyRangePrefix + ' '}
            placeholder={fieldItem.placeholder}
            suffix={' ' + currencyRangeSuffix}
            isAllowed={(values) => {
              const { floatValue } = values;
              if (!floatValue) return true;
              if (minRangeValue && maxRangeValue) {
                return floatValue >= minRangeValue && floatValue <= maxRangeValue;
              }
              if (minRangeValue) {
                return floatValue >= minRangeValue;
              }
              if (maxRangeValue) {
                return floatValue <= maxRangeValue;
              }
              return true;
            }}
            thousandsGroupStyle='thousand'
            thousandSeparator=','
            variant='outlined'
            size='small'
            id={fieldItem.name}
            onBlur={formikProps.handleBlur}
            decimalScale={3}
            error={!!formikProps.touched[fieldItem.name] && !!formikProps.errors[fieldItem.name]}
            helperText={formikProps.touched[fieldItem.name] && formikProps.errors[fieldItem.name]}
            value={formikProps.values[fieldItem.name]}
            onValueChange={(values) => {
              const { formattedValue } = values;
              formikProps.setFieldValue(fieldItem.name, formattedValue);
            }}
            customInput={TextField}
          />
        </Stack>
      );

    case RULES_CONSTANT.DATE.RULE_DATE_INPUT:
    case RULES_CONSTANT.GENERATED_SYSTEM.RULE_SYSTEM_GENERATED_EVENT_TIMESTAMP:
      return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack direction='row' justifyContent='space-between' alignItems='center' marginTop={2.5} gap={1}>
            <Stack width={WIDTH_LABEL}>
              <Typography variant='input-label' fontWeight={700}>
                {fieldItem.label}
                {!allowEmpty && <span style={{ color: 'red', marginLeft: 2 }}>*</span>}
              </Typography>
              {fieldItem.columnEditable === false && !isEdit && (
                <Typography variant='input-label-gray' fontSize={12}>
                  {INPUT_EDIT_MESSAGE}
                </Typography>
              )}
            </Stack>
            <DatePicker
              inputFormat={ENV_CONFIG.config.DATE_FORMAT}
              disabled={fieldItem.disabled}
              onChange={(value) => {
                formikProps.setFieldValue(fieldItem.name, value);
              }}
              value={
                dayjs.isDayjs(formikProps.values[fieldItem.name])
                  ? formikProps.values[fieldItem.name]
                  : dayjs(formikProps.values[fieldItem.name])
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  name={fieldItem.name}
                  sx={{ width: '65%' }}
                  onBlur={formikProps.handleBlur}
                  placeholder={fieldItem.placeholder}
                  error={!!formikProps.touched[fieldItem.name] && !!formikProps.errors[fieldItem.name]}
                  helperText={formikProps.touched[fieldItem.name] && formikProps.errors[fieldItem.name]}
                />
              )}
            />
          </Stack>
        </LocalizationProvider>
      );

    case RULES_CONSTANT.DATETIME.RULE_DATE_TIME_INPUT:
      return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack direction='row' justifyContent='space-between' alignItems='center' marginTop={2.5} gap={1}>
            <Stack width={WIDTH_LABEL}>
              <Typography variant='input-label' fontWeight={700}>
                {fieldItem.label}
                {!allowEmpty && <span style={{ color: 'red', marginLeft: 2 }}>*</span>}
              </Typography>
              {fieldItem.columnEditable === false && !isEdit && (
                <Typography variant='input-label-gray' fontSize={12}>
                  {INPUT_EDIT_MESSAGE}
                </Typography>
              )}
            </Stack>
            <DateTimePicker
              inputFormat={ENV_CONFIG.config.DATE_TIME_FORMAT}
              disabled={fieldItem.disabled}
              value={
                dayjs.isDayjs(formikProps.values[fieldItem.name])
                  ? formikProps.values[fieldItem.name]
                  : dayjs(formikProps.values[fieldItem.name])
              }
              onChange={(value) => {
                formikProps.setFieldValue(fieldItem.name, value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ width: '65%' }}
                  onBlur={formikProps.handleBlur}
                  name={fieldItem.name}
                  placeholder={fieldItem.placeholder}
                  error={!!formikProps.touched[fieldItem.name] && !!formikProps.errors[fieldItem.name]}
                  helperText={formikProps.touched[fieldItem.name] && formikProps.errors[fieldItem.name]}
                />
              )}
            />
          </Stack>
        </LocalizationProvider>
      );

    case RULES_CONSTANT.MULTIMEDIA.RULE_MULTIMEDIA:
      return (
        <DataInputFileUpload
          required={!allowEmpty}
          name={fieldItem.name}
          label={fieldItem.label}
          field={fieldItem}
          isEdit={isEdit}
          fileUrl={formikProps.values[fieldItem.name]}
          onChange={(url) => {
            formikProps.setFieldValue(fieldItem.name, url);
          }}
        />
      );

    case RULES_CONSTANT.GPS.RULE_GPS:
      return (
        <DataInputLocationPicker
          required={!allowEmpty}
          name={fieldItem.name}
          isEdit={isEdit}
          field={fieldItem}
          label={fieldItem.label}
          initialValue={formikProps.values[fieldItem.name]}
          onChange={(url) => {
            formikProps.setFieldValue(fieldItem.name, url);
          }}
        />
      );

    /**
     * Rule for limiting the number of characters in a text field
     *
     */
    case RULES_CONSTANT.TEXT.RULE_CHAR_LIMIT:
      const charLimitParams = convertRuleTypeParamsToSnakeCase(fieldItem.ruleTypeParams);
      const characterLimit = +charLimitParams?.[0] || undefined;
      return (
        <Stack direction='row' justifyContent='space-between' alignItems='center' marginTop={2.5} gap={1}>
          <Stack width={WIDTH_LABEL}>
            <Typography variant='input-label' fontWeight={700}>
              {fieldItem.label}
              {!allowEmpty && <span style={{ color: 'red', marginLeft: 2 }}>*</span>}
            </Typography>
            {fieldItem.columnEditable === false && !isEdit && (
              <Typography variant='input-label-gray' fontSize={12}>
                {INPUT_EDIT_MESSAGE}
              </Typography>
            )}
          </Stack>
          <TextField
            name={fieldItem.name}
            disabled={fieldItem.disabled}
            value={formikProps.values[fieldItem.name]}
            onChange={formikProps.handleChange}
            onBlur={formikProps.handleBlur}
            error={!!formikProps.touched[fieldItem.name] && !!formikProps.errors[fieldItem.name]}
            helperText={formikProps.touched[fieldItem.name] && formikProps.errors[fieldItem.name]}
            sx={{ width: '65%' }}
            hiddenLabel
            variant='outlined'
            inputProps={{
              maxLength: characterLimit,
            }}
            size='small'
            placeholder={fieldItem.placeholder}
          />
        </Stack>
      );

    case RULES_CONSTANT.GENERATED_SYSTEM.RULE_GENERATED_SYSTEM_MODIFIER:
      // case RULES_CONSTANT.TEXT.RULE_PULL_ATTRIBUTE:
      return (
        <Stack direction='row' justifyContent='space-between' alignItems='center' marginTop={2.5}>
          <Typography variant='input-label' fontWeight={700}>
            {fieldItem.label}
          </Typography>
          {formikProps.values[fieldItem.name] ? (
            <Typography variant='input-label' sx={{ width: '65%' }}>
              {formikProps.values[fieldItem.name]}
            </Typography>
          ) : (
            <TextField
              name={fieldItem.name}
              disabled
              value={getGeneratedFieldPlaceholder(ruleTypeCode)}
              onBlur={formikProps.handleBlur}
              error={!!formikProps.touched[fieldItem.name] && !!formikProps.errors[fieldItem.name]}
              helperText={formikProps.touched[fieldItem.name] && formikProps.errors[fieldItem.name]}
              sx={{ width: '65%', cursor: 'not-allowed' }}
              hiddenLabel
              variant='outlined'
              size='small'
              placeholder={fieldItem.placeholder}
            />
          )}
        </Stack>
      );
    case RULES_CONSTANT.GENERATED_SYSTEM.RULE_GENERATED_SYSTEM_DATE:
    case RULES_CONSTANT.GENERATED_SYSTEM.RULE_GENERATED_SYSTEM_DATE_TIME:
      return (
        <Stack direction='row' justifyContent='space-between' alignItems='center' marginTop={2.5} gap={1}>
          <Stack width={WIDTH_LABEL}>
            <Typography variant='input-label' fontWeight={700}>
              {fieldItem.label}
            </Typography>
          </Stack>

          {formikProps.values[fieldItem.name] ? (
            <Typography variant='input-label' sx={{ width: '65%' }}>
              {dayjs(formikProps.values[fieldItem.name]).format(ENV_CONFIG.config.DATE_FORMAT)}
            </Typography>
          ) : (
            <TextField
              name={fieldItem.name}
              disabled
              value={getGeneratedFieldPlaceholder(ruleTypeCode)}
              onBlur={formikProps.handleBlur}
              error={!!formikProps.touched[fieldItem.name] && !!formikProps.errors[fieldItem.name]}
              helperText={formikProps.touched[fieldItem.name] && formikProps.errors[fieldItem.name]}
              sx={{ width: '65%', cursor: 'not-allowed' }}
              hiddenLabel
              variant='outlined'
              size='small'
              placeholder={fieldItem.placeholder}
            />
          )}
        </Stack>
      );

    case RULES_CONSTANT.TEXT.RULE_PULL_ATTRIBUTE:
      return (
        <PullAttributeTextInput
          isEdit={isEdit}
          required={!allowEmpty}
          field={fieldItem}
          formik={formikProps}
          pullAttributeMapping={pullAttributeMapping}
          ruleTypeCode={fieldItem.ruleTypeCode}
        />
      );
    case RULES_CONSTANT.COLOUR.RULE_COLOUR_HEXA:
      return (
        <Stack direction='row' justifyContent='space-between' alignItems='center' marginTop={2.5} gap={1}>
          <Stack width={WIDTH_LABEL}>
            <Typography variant='input-label' fontWeight={700}>
              {fieldItem.label}
              {!allowEmpty && <span style={{ color: 'red', marginLeft: 2 }}>*</span>}
            </Typography>
            {fieldItem.columnEditable === false && !isEdit && (
              <Typography variant='input-label-gray' fontSize={12}>
                {INPUT_EDIT_MESSAGE}
              </Typography>
            )}
          </Stack>
          <TextField
            name={fieldItem.name}
            value={formikProps.values[fieldItem.name]}
            disabled={fieldItem.disabled}
            onBlur={formikProps.handleBlur}
            onChange={formikProps.handleChange}
            error={!!formikProps.touched[fieldItem.name] && !!formikProps.errors[fieldItem.name]}
            helperText={formikProps.touched[fieldItem.name] && formikProps.errors[fieldItem.name]}
            sx={{ width: '65%' }}
            hiddenLabel
            variant='outlined'
            type='color'
            size='small'
            placeholder={fieldItem.placeholder}
          />
        </Stack>
      );

    // rule for hiding a field
    case RULES_CONSTANT.TEXT.RULE_TEXT_IF_CONDITIONAL:
    case RULES_CONSTANT.FUNCTION.RULE_INTEGER_FUNCTION_DEPRICIATION:
      return null;

    default:
      return (
        <DynamicTextInput
          isEdit={isEdit}
          required={!allowEmpty}
          field={fieldItem}
          formik={formikProps}
          ruleTypeCode={fieldItem.ruleTypeCode}
        />
      );
  }
}
