const ENGLISH_DICTIONARY = {
  translation: {
    PAGE: {
      LOGIN: {
        WELCOME_TITLE: 'Welcome to aroTarget',
        WELCOME_DESCRIPTION:
          'Capturing and sharing business knowledge, enabling better decisions to improve your business future.',
        WELCOME_COPYRIGHT: 'ARO Target Forecasting Pty Ltd All Rights Reserverd',
        FORM_TITLE: 'Sign In',
        FORM_SUB_TITLE: 'Fill in the fields below to sign into your account.',
        FORM_BUTTON_LOGIN: 'LOGIN',
        FORM_BUTTON_LOGIN_WITH_MICRSOFOT: 'MICROSOFT 365',
        FORM_LOGIN_OPTIONS: 'Or Authentication Using',
        FORM_LOGIN_SESSION_CHECKBOX: 'Stay signed in for a month',
        FORM_LOGIN_FORGOT_PASSWORD: 'Forgot Password?',
        FORM_LOGIN_INPUT_EMAIL_LABEL: 'Email Address',
        FORM_LOGIN_INPUT_EMAIL_PLACEHOLDER: 'Enter your email address',
        FORM_LOGIN_INPUT_PASSWORD_LABEL: 'Password',
        FORM_LOGIN_INPUT_PASSWORD_PLACEHOLDER: 'Enter your password',
      },
      SUBJECT: {
        TITLE: 'Subject List',
        BUTTON_ADD_NEW: 'Add New Subject',
      },
      ATTRIBUTE: {
        TITLE: 'Attribute List',
        BUTTON_ADD_NEW: 'Add New Attribute',
      },
      MEMBER_LIST: {
        TITLE: 'Member List',
        BUTTON_ADD_NEW: 'Add Member',
      },
      TABLE_DEFINITION: {
        TITLE: 'Table Definition',
        BUTTON_ADD_NEW: 'Add New Field',
        RULE_TYPE: 'Rule Type',
        DATA_TYPE: 'Data Type',
        DISPLAY_NAME: 'Display Name',
        PARAMETER: 'Parameter',
        FIELD_ATTRIBUTE: 'Field Attribute',
        DATA_TYPE_ATTRIBUTE: 'Data Type Attribute',
        MODAL_PARAMETER: {
          TITLE: 'Parameter for',
          BUTTON_SAVE: 'Save Parameter',
        },
      },
      ERROR_NOT_FOUND: {
        DESCRIPTION: 'The page you are looking for does not exist.',
        BUTTON_ACTION: 'Back to Home',
      },
    },
    SIDEBAR_MENU: {
      DASHBOARD: 'Dashboard',
      DATA_BUILDER: 'Data Builder',
      DATA_INPUT: 'Data Input',
      SET_UP: 'Set Up',
      UPDATE: 'Ktree',
      REPORTS: 'Reports',
      SUBJECT: 'Subject',
      ATTRIBUTE: 'Attribute',
      MEMBER_LIST: 'Member List',
      TABLE_DEFINITION: 'Table Definition',
      PROJECTS: 'PROJECTS',
    },
    HEADER_MENU: {
      OPEN_SETTINGS: 'Personal settings and exist',
      MULTILANGUAGE: 'Multilanguage',
      SETTING: 'Setting',
      LOGOUT: 'Logout',
    },
    COMMON: {
      MODAL_IMPORT: {
        TITLE: 'Import Data',
        BUTTON_UPLOAD: 'Upload',
        UPLOAD_AREA_EXCEL: 'or drop files .xlsx here',
        UPLOAD_AREA_CSV: 'or drop files .csv here',
        INSTRUCTION_EXCEL: 'Please Select the EXCEL file that you want to import',
        INSTRUCTION_CSV: 'Please Select the CSV file that you want to import',
        START_IMPORT: 'Start Import',
      },
      MODAL_DELETE: {
        TITLE: 'Delete Confirmation',
        DESCRIPTION: 'You are about to delete this record. Are you sure?',
        BUTTON_DELETE: 'Delete',
      },
      MODAL_SELECT_LANGUAGE: {
        TITLE: 'Language',
        DESCRIPTION: 'Please select the language that you want to use',
      },
      DATATABLE: {
        EMPTY_ROW: 'No data available in table',
      },
      LABEL: {
        ASSOCIATE_SUBJECT: 'Associate Subject',
        ASSOCIATE_ATTRIBUTE: 'Associate Attribute',
      },
      BUTTON: {
        EXPORT: 'Export data',
        EXPORT_CSV_FILE: 'Export as CSV file',
        EXPORT_EXCEL_FILE: 'Export as Excel file',
        IMPORT: 'Import data',
        IMPORT_CSV_FILE: 'Import from CSV file',
        IMPORT_EXCEL_FILE: 'Import from Excel file',
        DOWNLOAD_TEMPLATE: 'Download Import Template',
        DOWNLOAD_TEMPLATE_CSV: 'Download CSV Template',
        DOWNLOAD_TEMPLATE_EXCEL: 'Download Excel Template',
        CANCEL: 'Cancel',
        CREATE: 'Create',
        APPLY: 'Apply',
      },
    },
    FORM_ERROR: {
      LOGIN: {
        EMAIL_INVALID: 'Email must be valid email',
        EMAIL_REQUIRED: 'Email is required',
        PASSWORD_REQUIRED: 'Password is required',
      },
    },
  },
};

export default ENGLISH_DICTIONARY;
