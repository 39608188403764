import { AxiosResponse } from 'axios';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import axios from 'libs/Axios';

import { AxiosDefaultErrorEntity } from 'types/api/Common/ErrorTypes';
import { PaginationAndSortingParams } from 'types/api/Common/PaginationTypes';
import { AutoCompleteItem } from 'types/api/Common/AutoCompleteTypes';
import {
  RequestCreateTenantExternalDataSource,
  DataWarehouseDetails,
  RequestCreateDataChannel,
  DataChannelDetails,
  DataChannelItem,
} from 'types/api/TenantMaster/MasterTable/TenantExternalDataSourceTypes';
import { DataInputFileUploadResponse } from 'types/api/Tenant/AROKMS/DisplayTableTypes';

export interface DisplayTableParams extends PaginationAndSortingParams {
  subjectId?: string | number | undefined;
}

export const TENANT_EXTERNAL_DATA_SOURCE_QUERY_KEY = {
  ORGANIZATION_DROPDOWN: 'TENANT_EXTERNAL_DATA_SOURCE_ORGANIZATION_DROPDOWN',
  LIST: 'TENANT_EXTERNAL_DATA_SOURCE_LIST',
  LIST_DATA_CHANNEL: 'TENANT_EXTERNAL_DATA_SOURCE_LIST_DATA_CHANNEL',
  DATA_CHANNEL_STATUS_DROPDOWN: 'TENANT_EXTERNAL_DATA_SOURCE_DATA_CHANNEL_STATUS_DROPDOWN',
  DATA_CHANNEL_DETAILS: 'TENANT_EXTERNAL_DATA_SOURCE_DATA_CHANNEL_DETAILS',
  DATA_WAREHOUSE_DETAILS: 'TENANT_EXTERNAL_DATA_SOURCE_DATA_WAREHOUSE_DETAILS',
  DATA_WAREHOUSE_CHANNEL_TABLE: 'TENANT_EXTERNAL_DATA_SOURCE_DATA_WAREHOUSE_CHANNEL_TABLE',
  DETAILS_EDIT: 'TENANT_EXTERNAL_DATA_SOURCE_DETAILS_EDIT',
};

export function useGetOrganizationDropdown() {
  return useQuery<AxiosResponse<AutoCompleteItem[]>, AxiosDefaultErrorEntity>(
    [TENANT_EXTERNAL_DATA_SOURCE_QUERY_KEY.ORGANIZATION_DROPDOWN],
    () => {
      return axios.get(`/api/v1/master/tenant-external-data-source/organization/dropdown`).then((res) => res);
    }
  );
}
export function useGetTenantExternalDataSource() {
  return useQuery<AxiosResponse<DataWarehouseDetails[]>, AxiosDefaultErrorEntity>(
    [TENANT_EXTERNAL_DATA_SOURCE_QUERY_KEY.LIST],
    () => {
      return axios.get(`/api/v1/master/tenant-external-data-source/list`).then((res) => res);
    }
  );
}

export function useCheckDatabaseConnection() {
  return useMutation<AxiosResponse, AxiosDefaultErrorEntity, RequestCreateTenantExternalDataSource>(
    (bodyRequest) => axios.post('/api/v1/master/tenant-external-data-source/test-connection', bodyRequest),
    {}
  );
}

export function useAddTenantExternalDataSource() {
  return useMutation<AxiosResponse, AxiosDefaultErrorEntity, RequestCreateTenantExternalDataSource>(
    (bodyRequest) => axios.post('/api/v1/master/tenant-external-data-source/add', bodyRequest),
    {}
  );
}

export function useGetDataChannelStatusDropdown() {
  return useQuery<AxiosResponse<AutoCompleteItem[]>, AxiosDefaultErrorEntity>(
    [TENANT_EXTERNAL_DATA_SOURCE_QUERY_KEY.DATA_CHANNEL_STATUS_DROPDOWN],
    () => {
      return axios.get(`/api/v1/master/tenant-external-data-source/channel/status/dropdown`).then((res) => res);
    }
  );
}

export function useGetDataChannels(dataWarehouseId?: string) {
  return useQuery<AxiosResponse<DataChannelItem[]>, AxiosDefaultErrorEntity>(
    [TENANT_EXTERNAL_DATA_SOURCE_QUERY_KEY.LIST_DATA_CHANNEL, dataWarehouseId],
    () => {
      return axios.get(`/api/v1/master/tenant-external-data-source/channel/list/${dataWarehouseId}`).then((res) => res);
    },
    {
      enabled: !!dataWarehouseId,
    }
  );
}

export function useGetDataChannelDetails(channelId?: string) {
  return useQuery<AxiosResponse<DataChannelDetails>, AxiosDefaultErrorEntity>(
    [TENANT_EXTERNAL_DATA_SOURCE_QUERY_KEY.DATA_CHANNEL_DETAILS, channelId],
    () => {
      return axios.get(`/api/v1/master/tenant-external-data-source/channel/${channelId}`).then((res) => res);
    },
    {
      enabled: !!channelId,
    }
  );
}

export function useGetDataWarehouseDetails(dataWarehouseId?: string) {
  return useQuery<AxiosResponse<DataWarehouseDetails>, AxiosDefaultErrorEntity>(
    [TENANT_EXTERNAL_DATA_SOURCE_QUERY_KEY.DATA_WAREHOUSE_DETAILS, dataWarehouseId],
    () => {
      return axios.get(`/api/v1/master/tenant-external-data-source/${dataWarehouseId}`).then((res) => res);
    },
    {
      enabled: !!dataWarehouseId,
    }
  );
}

export function useGetDataWarehouseChannelTable(dataWarehouseId?: string) {
  return useQuery<AxiosResponse<AutoCompleteItem[]>, AxiosDefaultErrorEntity>(
    [TENANT_EXTERNAL_DATA_SOURCE_QUERY_KEY.DATA_WAREHOUSE_CHANNEL_TABLE, dataWarehouseId],
    () => {
      return axios
        .get(`/api/v1/master/tenant-external-data-source/${dataWarehouseId}/channel-table/dropdown`)
        .then((res) => res);
    },
    {
      enabled: !!dataWarehouseId,
    }
  );
}
export function useAddDataChannel() {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosDefaultErrorEntity, RequestCreateDataChannel>(
    (bodyRequest) => axios.post('/api/v1/master/tenant-external-data-source/channel/add', bodyRequest),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(TENANT_EXTERNAL_DATA_SOURCE_QUERY_KEY.LIST_DATA_CHANNEL);
      },
    }
  );
}

// const mutation = useMutation<AxiosResponse<DataInputFileUploadResponse>, AxiosDefaultErrorEntity, FormData>(
//     (formData) => {
//       return axios.post(`/api/v1/display-table/upload`, formData, {
//         headers: {
//           'Content-Type': 'multipart/form-data',
//         },
//         onUploadProgress: (progressEvent) => {
//           const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total) - 5;
//           setProgress(percentCompleted);
//         },
//       });
//     }
//   );

export function useUploadDataChannelLogo() {
  return useMutation<AxiosResponse<DataInputFileUploadResponse>, AxiosDefaultErrorEntity, FormData>((formData) =>
    axios.post('/api/v1/master/tenant-external-data-source/channel/upload-logo', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  );
}
