import * as yup from 'yup';

export const CreateAndDeployTenantValidationSchema = yup.object().shape({
  serverId: yup.object().shape({
    value: yup.string().required('Server Id is required'),
    label: yup.string(),
  }),
  userId: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.string().required('User ID is required'),
        roleType: yup.string().required('Role name is required'),
      })
    )
    .required('User Id is required')
    .min(1),
  systemId: yup.object().shape({
    value: yup.string().required('Code status is required'),
    label: yup.string(),
  }),
  organizationId: yup.object().shape({
    value: yup.string().required('Code status is required'),
    label: yup.string(),
  }),
});
