import * as Yup from 'yup';
import { useFormik } from 'formik';
import React, { HTMLAttributes, useEffect, useMemo } from 'react';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import ButtonLoading from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import AutoComplete from '@mui/material/Autocomplete';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';

import { AutoCompleteItem } from 'types/api/Common/AutoCompleteTypes';
import {
  InsightViewAttributeItem,
  InsightViewVarianceItem,
} from 'types/api/SystemTenant/AROView/InsightViewDefinitionTypes';

const autoCompleteStyle = {
  '& .MuiAutocomplete-popper': {
    backgroundColor: 'red !imoprtant',
    fontSize: '10px',
  },
};
const ModalContentStyle = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 550,
  bgcolor: 'background.paper',
  maxHeight: 450,
  overflow: 'auto',
  borderRadius: 1,
  py: 2,
};

const closeIconStyle = { color: '#98A2AE', cursor: 'pointer' };

export interface ModalAddVarianceDefinitionProps {
  visible?: boolean;
  onClose?: () => void;
  onSave?: (attributeItem: InsightViewVarianceItem) => void;
  onDelete?: (attributeItem: InsightViewVarianceItem) => void;
  selectedData?: InsightViewVarianceItem;
  attributeData: InsightViewAttributeItem[];
}

const formikValidationSchema = Yup.object().shape({
  group: Yup.object().required('This field is required').nullable(),
  resultColumn: Yup.object().required('This field is required').nullable(),
  targetColumn: Yup.object().required('This field is required').nullable(),
});

export type FormikVarianceValues = Omit<InsightViewVarianceItem, 'id'>;

export const formikInsightAttributeInitialValues: FormikVarianceValues = {
  group: null,
  resultColumn: null,
  targetColumn: null,
};

export function ModalAddVariance(props: Readonly<ModalAddVarianceDefinitionProps>) {
  const { onClose, visible = false, onSave, onDelete, selectedData, attributeData } = props;

  const formik = useFormik<FormikVarianceValues>({
    initialValues: formikInsightAttributeInitialValues,
    validationSchema: formikValidationSchema,
    onSubmit: () => {},
  });

  const handleOnCloseModal = () => {
    onClose?.();
    formik.resetForm();
  };

  const handleOnCancel = () => {
    handleOnCloseModal();
  };
  const handleOnDelete = () => {
    onDelete?.(selectedData as InsightViewVarianceItem);
    handleOnCloseModal();
  };

  const handleOnChangeGroup = (event: React.ChangeEvent<{}>, newValue: AutoCompleteItem | null) => {
    formik.setFieldValue('group', newValue);
  };

  const handleOnSave = () => {
    if (formik.values.group && formik.values.targetColumn && formik.values.resultColumn) {
      onSave?.({
        id: `NEWVARIANCE-${Date.now()}`,
        ...formik.values,
      });
    }

    if (selectedData) {
      onSave?.({
        ...selectedData,
        ...formik.values,
      });
    }

    handleOnCloseModal();
  };

  const groupDropdownOptions: AutoCompleteItem[] = useMemo(() => {
    if (attributeData) {
      return attributeData
        .filter((item) => item.isGroup)
        .map((item) => ({
          label: item.name,
          value: item.id,
        }));
    }

    return [];
  }, [attributeData]);

  const targetColumnDropdownOptions: AutoCompleteItem[] = useMemo(() => {
    if (attributeData && formik.values.group) {
      return attributeData
        .filter(
          (item) => item.group?.value === formik.values.group?.value && item.id !== formik.values.resultColumn?.value
        )
        .map((item) => ({
          label: item.name,
          value: item.id,
        }));
    }

    return [];
  }, [attributeData, formik.values.group, formik.values.resultColumn]);

  const resultColumnDropdownOptions: AutoCompleteItem[] = useMemo(() => {
    if (attributeData && formik.values.group) {
      return attributeData
        .filter(
          (item) => item.group?.value === formik.values.group?.value && item.id !== formik.values.targetColumn?.value
        )
        .map((item) => ({
          label: item.name,
          value: item.id,
        }));
    }

    return [];
  }, [attributeData, formik.values.group, formik.values.targetColumn]);

  const renderOption = (props: HTMLAttributes<HTMLElement>, option: AutoCompleteItem) => {
    return (
      <Box component='li' sx={{ '& > span': { fontSize: '14px', mr: 1, flexShrink: 0 } }} {...props}>
        <span>{option.label}</span>
      </Box>
    );
  };

  const isValidForm = formikValidationSchema.isValidSync(formik.values);

  useEffect(() => {
    if (selectedData) {
      formik.setValues(selectedData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedData]);

  return (
    <div>
      <Modal open={visible} onClose={handleOnCloseModal}>
        <Stack direction='column' sx={ModalContentStyle} px={2}>
          <Stack>
            <Stack justifyContent='space-between' direction='row' alignItems='center'>
              <Typography variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
                Variance Definition
              </Typography>
              <IconButton onClick={handleOnCloseModal} component='label'>
                <HighlightOffRoundedIcon sx={closeIconStyle} />
              </IconButton>
            </Stack>
          </Stack>
          <Divider sx={{ mb: 1 }} />

          <Stack mb={1.6} width='70%'>
            <Typography variant='body2' fontWeight='bold' color='black'>
              Column Group
            </Typography>
            <Typography variant='input-label-gray' mb={1}>
              Choose the group for this column to be displayed in.
            </Typography>
            <AutoComplete
              clearIcon={null}
              size='small'
              value={formik.values.group}
              onChange={handleOnChangeGroup}
              onBlur={formik.handleBlur}
              getOptionLabel={(option: AutoCompleteItem) => option.label}
              options={groupDropdownOptions}
              renderOption={renderOption}
              sx={autoCompleteStyle}
              renderInput={(params) => (
                <Stack direction='row' justifyContent='space-between' display='flex' alignItems='center'>
                  <TextField {...params} placeholder='Select Group' name='group' />
                </Stack>
              )}
            />
          </Stack>
          {formik.values.group && (
            <>
              <Stack mb={1.6} width='70%'>
                <Typography variant='body2' fontWeight='bold' color='black'>
                  Target Column
                </Typography>
                <Typography variant='input-label-gray' mb={1}>
                  Choose the group for this column to be displayed in.
                </Typography>
                <AutoComplete
                  clearIcon={null}
                  size='small'
                  value={formik.values.targetColumn}
                  onChange={(event, newValue) => {
                    formik.setFieldValue('targetColumn', newValue);
                  }}
                  onBlur={formik.handleBlur}
                  getOptionLabel={(option: AutoCompleteItem) => option.label}
                  options={targetColumnDropdownOptions}
                  renderOption={renderOption}
                  sx={autoCompleteStyle}
                  renderInput={(params) => (
                    <Stack direction='row' justifyContent='space-between' display='flex' alignItems='center'>
                      <TextField {...params} placeholder='Select Target Column' name='targetColumn' />
                    </Stack>
                  )}
                />
              </Stack>
              <Stack mb={1.6} width='70%'>
                <Typography variant='body2' fontWeight='bold' color='black'>
                  Result Column
                </Typography>
                <Typography variant='input-label-gray' mb={1}>
                  Choose the group for this column to be displayed in.
                </Typography>
                <AutoComplete
                  clearIcon={null}
                  size='small'
                  value={formik.values.resultColumn}
                  onChange={(event, newValue) => {
                    formik.setFieldValue('resultColumn', newValue);
                  }}
                  onBlur={formik.handleBlur}
                  getOptionLabel={(option: AutoCompleteItem) => option.label}
                  options={resultColumnDropdownOptions}
                  renderOption={renderOption}
                  sx={autoCompleteStyle}
                  renderInput={(params) => (
                    <Stack direction='row' justifyContent='space-between' display='flex' alignItems='center'>
                      <TextField {...params} placeholder='Select Result Column' name='resultColumn' />
                    </Stack>
                  )}
                />
              </Stack>
            </>
          )}
          <Stack height={300} px={2}></Stack>
          <Stack px={2}>
            <Divider sx={{ mb: 1, mt: 2 }} />
            <Stack direction='row' justifyContent='flex-end' spacing={2} alignItems='center' sx={{ py: 1 }}>
              {selectedData && (
                <Button
                  onClick={handleOnDelete}
                  variant='outlined'
                  sx={{
                    textTransform: 'none',
                  }}
                  color='error'
                >
                  Delete
                </Button>
              )}
              <Button variant='main-table-panel-border' onClick={handleOnCancel}>
                Cancel
              </Button>
              <ButtonLoading variant='main-table-panel' disabled={!isValidForm} onClick={() => handleOnSave()}>
                Save Variance
              </ButtonLoading>
            </Stack>
          </Stack>
        </Stack>
      </Modal>
    </div>
  );
}
