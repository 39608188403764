import {
  GridRenderCellParams,
} from '@mui/x-data-grid-pro';

import { renderDataGridLocation } from 'components/DatatableComponent/RenderLocation';

export function renderLocation() {
  return {
    type: 'text',
    editable: false,
    filterable: false,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => {
      // @ts-ignore
      return renderDataGridLocation({
        ...params,
      });
    },
  };
}
