import { Checkbox, Box, checkboxClasses, Typography, Stack } from '@mui/material';

interface RectangleCheckBoxComponentProps {
  color?: string;
  text: string;
  secondLabel?: string;
  checked: boolean;
  onClick: () => void;
}

export function RectangleCheckBoxComponent(props: Readonly<RectangleCheckBoxComponentProps>) {
  const { color = '#42BB93', text, secondLabel, checked, onClick } = props;

  return (
    <Box
      sx={{
        width: 'auto',
        px: 1,
        height: 40,
        border: `1px solid ${checked ? color : '#bfbfbf'}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 2,
        cursor: 'pointer',
      }}
      onClick={onClick}
    >
      <Checkbox
        sx={{
          [`&, &.${checkboxClasses.root}`]: {
            color: '#bfbfbf',
            px: 0.5,
          },
          [`&, &.${checkboxClasses.checked}`]: {
            color,
          },
        }}
        checked={checked}
      />
      <Stack>
        <Typography
          variant='input-label'
          letterSpacing={0.3}
          sx={{
            color: checked ? color : '#737373',
          }}
          textAlign='left'
        >
          {text}
        </Typography>
        {secondLabel && (
          <Typography
            variant='input-label'
            letterSpacing={0.3}
            sx={{
              fontSize: 11,
              color: checked ? color : '#737373',
            }}
            textAlign='left'
          >
            {secondLabel}
          </Typography>
        )}
      </Stack>
    </Box>
  );
}
