// @ts-nocheck
import { useState, useEffect, useMemo } from 'react';
import { Stack, Typography, SvgIcon, IconButton, TextField } from '@mui/material';

import Tooltip from '@mui/material/Tooltip';
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';
// Custom Icon
import { ReactComponent as CustomIconTrash } from 'assets/icons/icon-trash.svg';
import {
  getCurrentLocationBrowser,
  convertGeolocationsToString,
  decodeStringToGeoLocations,
} from 'utils/DetectLocation';
import { ENV_CONFIG } from 'config/env';
import { Field } from 'types/api/Tenant/AROKMS/DisplayTableTypes';

interface DataInputFileUploadProps {
  name: string;
  onChange: (stringLocations: string) => void;
  initialValue: string;
  label: string;
  required?: boolean;
  field: Field;
  isEdit?: boolean;
}

const INPUT_EDIT_MESSAGE = "Once you've submitted, this field can no longer be changed";
export function DataInputLocationPicker(props: DataInputFileUploadProps) {
  const [initialLocation, setInitialLocation] = useState({ lat: 0, lng: 0 });
  const { onChange = () => {}, initialValue, required, field, isEdit } = props;
  const [selectedLocations, setSelectedLocations] = useState([]);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: ENV_CONFIG.license.GMAPS_API_KEY,
  });
  const center = useMemo(() => {
    if (initialLocation.lat === 0 && initialLocation.lng === 0) {
      return { lat: 0, lng: 0 };
    }
    return { lat: initialLocation.lat, lng: initialLocation.lng };
  }, [initialLocation]);
  const zoom = 14;
  useEffect(() => {
    getCurrentLocationBrowser().then((location) => {
      setInitialLocation(location);
    });
  }, []);

  useEffect(() => {
    if (initialValue) {
      setSelectedLocations(decodeStringToGeoLocations(initialValue));
    }
  }, [initialValue]);

  useEffect(() => {
    onChange(convertGeolocationsToString(selectedLocations));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocations]);

  return (
    <Stack direction='column' display='flex' justifyContent='space-between' marginTop={2.5}>
      <Typography variant='input-label' fontWeight={700}>
        {field.label}
        {required && !field.disabled && <span style={{ color: 'red', marginLeft: 2 }}>*</span>}
      </Typography>
      {field.columnEditable === false && !isEdit && (
        <Typography variant='input-label-gray' fontSize={12} mb={3}>
          {INPUT_EDIT_MESSAGE}
        </Typography>
      )}
      {isLoaded && (
        <div style={{ height: '50vh', width: '100%', marginBottom: 15 }}>
          <GoogleMap
            streetView={false}
            options={{ disableDefaultUI: true, zoomControl: true, draggableCursor: false }}
            zoom={zoom}
            center={center}
            onClick={(e) => {
              if (field.columnEditable === false && isEdit) return;
              setSelectedLocations([...selectedLocations, { lat: e.latLng.lat(), lng: e.latLng.lng() }]);
            }}
            mapContainerClassName='map-container'
          >
            {selectedLocations.map((location, index) => {
              return (
                <Marker
                  key={index}
                  position={{ lat: location.lat, lng: location.lng }}
                  onClick={() => {
                    setSelectedLocations(selectedLocations.filter((_, i) => i !== index));
                  }}
                />
              );
            })}
          </GoogleMap>
        </div>
      )}
      {selectedLocations.length > 0 &&
        selectedLocations.map((location, index) => (
          <Stack direction='row' marginBottom={2}>
            <TextField
              disabled
              value={`${location.lat}, ${location.lng}`}
              sx={{ width: '85%' }}
              hiddenLabel
              variant='outlined'
              size='small'
            />

            {field.columnEditable || !isEdit ? (
              <Tooltip title='Delete'>
                <IconButton
                  onClick={() => {
                    setSelectedLocations(selectedLocations.filter((_, i) => i !== index));
                  }}
                >
                  <SvgIcon fontSize='small' sx={{ fill: '#42BB93' }} inheritViewBox component={CustomIconTrash} />
                </IconButton>
              </Tooltip>
            ) : null}
          </Stack>
        ))}
    </Stack>
  );
}
