import { TenantHomeDashboardNavigationBar } from 'components/LayoutComponent/VerticalLayout/HeaderBar';
import { Box, Paper, Typography, SvgIcon, Stack, Grid } from '@mui/material';

import { ReactComponent as CustomAgreementIcon } from 'assets/icons/icon-dashboard-agreement.svg';
import { ReactComponent as CustomChartIcon } from 'assets/icons/icon-dashboard-chart.svg';
import { ReactComponent as CustomEarthIcon } from 'assets/icons/icon-dashboard-earth.svg';
import { ReactComponent as CustomLicenseIcon } from 'assets/icons/icon-dashboard-license.svg';
import { DashboardChart } from './components/CustomerDashboardChart';

const INFO_TYPES = {
  LICENSE: 'license',
  INDUSTRY_SECTOR: 'industrySector',
  COUNTRY_REGION: 'countryRegion',
  AGREEMENT_TYPE: 'agreementType',
  ORGANIZATION: 'organization',
};

function getInfoBoxIcon(infoType: string) {
  switch (infoType) {
    case INFO_TYPES.LICENSE:
      return CustomLicenseIcon;
    case INFO_TYPES.INDUSTRY_SECTOR:
      return CustomChartIcon;
    case INFO_TYPES.COUNTRY_REGION:
      return CustomEarthIcon;
    case INFO_TYPES.AGREEMENT_TYPE:
      return CustomAgreementIcon;
    case INFO_TYPES.ORGANIZATION:
      return CustomAgreementIcon;
    default:
      return CustomAgreementIcon;
  }
}

function InfoBox(props: { title: string; value: string; type: string }) {
  const { title, value, type } = props;
  return (
    <Paper sx={{ width: '20%' }} variant='outlined'>
      <Stack direction='row' alignItems='center' gap={2} justifyContent='center' py={3}>
        <SvgIcon inheritViewBox fontSize='large' component={getInfoBoxIcon(type)} />
        <Stack>
          <Typography variant='input-label' sx={{ fontSize: 12 }}>
            {title}
          </Typography>
          <Typography variant='body2' fontWeight='bold'>
            {value}
          </Typography>
        </Stack>
      </Stack>
    </Paper>
  );
}

export default function AdminTenantHomePage() {
  return (
    <TenantHomeDashboardNavigationBar
      containerSx={{
        bgcolor: 'transparent',
        boxShadow: 'none',
      }}
    >
      <Stack
        direction='row'
        width='100%'
        py={2}
        gap={1.5}
        sx={{
          bgcolor: 'none',
        }}
      >
        <InfoBox title='License' value='ARO Forecasting' type={INFO_TYPES.LICENSE} />
        <InfoBox title='Industry Sector' value='All Industries' type={INFO_TYPES.INDUSTRY_SECTOR} />
        <InfoBox title='Country Region' value='All Countries' type={INFO_TYPES.COUNTRY_REGION} />
        <InfoBox title='Agreement Type' value='Master' type={INFO_TYPES.AGREEMENT_TYPE} />
        <InfoBox title='Organization' value='MCN Transport' type={INFO_TYPES.AGREEMENT_TYPE} />
      </Stack>
      <Grid container spacing={2}>
        <Grid item xs={9}>
          <Paper variant='outlined'>
            <Box p={2}>
              <Typography variant='body2' fontWeight='bold'>
                Assets And Loans
              </Typography>
              <Typography variant='input-label'>Summary of assets and loans for the selected filters</Typography>
              <DashboardChart />
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper variant='outlined'>
            <Box p={2}>
              <Typography variant='body2' fontWeight='bold'>
                History Event
              </Typography>
              <Typography variant='input-label'>Summary of history events for the selected filters</Typography>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </TenantHomeDashboardNavigationBar>
  );
}
