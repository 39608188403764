import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useMemo, useImperativeHandle, forwardRef, useEffect, useContext } from 'react';
import {
  useGetFunctionDefinitionCubeAttributeDropdown,
  useGetFunctionDefinitionDetailsById,
} from 'services/v1/SystemTenant/AROFunction/FunctionDefinitionService';
import { FUNCTION_CORE } from 'constant/FunctionConstant';
import { FunctionDefinitionParamFields, CreateFunctionDefinitionContext } from '../CreateFunctionDefinition';
import { FormikProps, useFormik } from 'formik';
import { AutoCompleteItem } from 'types/api/Common/AutoCompleteTypes';
import { Alert } from '@mui/material';
import { useParams } from 'react-router-dom';
import {
  createValidationSchema,
  generateFunctionDefinitionParamFormFields,
  getInitialValues,
} from './FunctionDefinitionLoadParameter';

interface IProps {
  cubeId?: string | null;
  layerId?: string | null;
  sectionTitle: string;
  functionCore: string;
  onFormikChange?: (values: any, isFormValid: boolean) => void;
}

interface LoadCountFunctionParameterFields {
  attributeStartDate: AutoCompleteItem | null;
  attributeEndDate: AutoCompleteItem | null;
}

interface LoadDepreciationFunctionParameterFields {
  attributePurchasePrice: AutoCompleteItem | null;
  attributePurchaseDate: AutoCompleteItem | null;
  attributeSalePrice: AutoCompleteItem | null;
  attributeSaleDate: AutoCompleteItem | null;
  attributeExpectedLifeYrs: AutoCompleteItem | null;
  attributeDepreciationType: AutoCompleteItem | null;
  attributeDepreciationRate: AutoCompleteItem | null;
}
export interface FunctionDefinitionLoadParameterRef {
  getValues: () => FormikProps<FormikInitialValuesLoadParameter>['values'];
}

type FormikInitialValuesLoadParameter = LoadCountFunctionParameterFields | LoadDepreciationFunctionParameterFields;

export const FunctionDefinitionDisplayLoadParameter = forwardRef<FunctionDefinitionLoadParameterRef, IProps>(
  (props, ref) => {
    const { functionId } = useParams<{ functionId: string }>();
    const { data: functionDefinitionDetails } = useGetFunctionDefinitionDetailsById(functionId);
    const { sectionTitle, functionCore, onFormikChange, cubeId, layerId } = props;
    const { data: cubeAttributeDropdown } = useGetFunctionDefinitionCubeAttributeDropdown(cubeId, layerId);
    const { selectedCubeAttributeOptions, setSelectedCubeAttributeOptions } = useContext(
      CreateFunctionDefinitionContext
    );

    const displayLoadParamaterFields: FunctionDefinitionParamFields[] = useMemo(() => {
      switch (functionCore) {
        case FUNCTION_CORE.VALUE_FUNCTION:
          return [
            {
              name: 'displayLoadParamAttributeRegoPA',
              fieldTitle: 'Target Cube Attribute to Store Rego PA',
              fieldSubtitle: 'Choose cube attribute to display the loaded data from Rego PA',
              fieldType: 'autocomplete',
              placeholder: 'Select cube attribute...',
              fieldOptions: cubeAttributeDropdown?.data ?? [],
              ruleTypes: [],
              enforceUniqueSelection: true,
              isRequired: false,
            },
          ];

        case FUNCTION_CORE.DEPRECIATION_FUNCTION:
          return [
            {
              name: 'displayLoadParamAttributePurchasePrice',
              fieldTitle: 'Target Cube Attribute to Store Purchase Price',
              fieldSubtitle: 'Choose cube attribute to display the loaded data from Purchase Price',
              fieldType: 'autocomplete',
              placeholder: 'Select cube attribute...',
              fieldOptions: cubeAttributeDropdown?.data ?? [],
              ruleTypes: [],
              enforceUniqueSelection: true,
              isRequired: false,
            },
            {
              name: 'displayLoadParamAttributeSalePrice',
              fieldTitle: 'Target Cube Attribute to Store Sale Price',
              fieldSubtitle: 'Choose cube attribute to display the loaded data from Sale Price',
              fieldType: 'autocomplete',
              placeholder: 'Select cube attribute...',
              fieldOptions: cubeAttributeDropdown?.data ?? [],
              ruleTypes: [],
              enforceUniqueSelection: true,
              isRequired: false,
            },
            {
              name: 'displayLoadParamAttributeSaleDateExpectedLifeYrs',
              fieldTitle: 'Target Cube Attribute to Store Expected Life Years',
              fieldSubtitle: 'Choose cube attribute to display the loaded data from Expected Life Years',
              fieldType: 'autocomplete',
              placeholder: 'Select cube attribute...',
              fieldOptions: cubeAttributeDropdown?.data ?? [],
              ruleTypes: [],
              enforceUniqueSelection: true,
              isRequired: false,
            },

            {
              name: 'displayLoadParamAttributeDepreciationRate',
              fieldTitle: 'Target Cube Attribute to Store Depreciation Rate % PA',
              fieldSubtitle: 'Choose cube attribute to display the loaded data from Depreciation Rate PA',
              fieldType: 'autocomplete',
              placeholder: 'Select cube attribute...',
              fieldOptions: cubeAttributeDropdown?.data ?? [],
              ruleTypes: [],
              enforceUniqueSelection: true,
              isRequired: false,
            },
          ];

        case FUNCTION_CORE.INTEREST_FUNCTION:
          return [
            {
              name: 'displayLoadParamAttributeTotalLoanExcludingInterest',
              fieldTitle: 'Target Cube Attribute to Store Total Loan Excluding Interest',
              fieldSubtitle: 'Choose cube attribute to display the loaded data from Total Loan Excluding Interest',
              fieldType: 'autocomplete',
              placeholder: 'Select cube attribute...',
              fieldOptions: cubeAttributeDropdown?.data ?? [],
              ruleTypes: [],
              enforceUniqueSelection: true,
              isRequired: false,
            },
            {
              name: 'displayLoadParamAttributeInterestRate',
              fieldTitle: 'Target Cube Attribute to Store Interest Rate PA',
              fieldSubtitle: 'Choose cube attribute to display the loaded data from Interest Rate PA',
              fieldType: 'autocomplete',
              placeholder: 'Select cube attribute...',
              fieldOptions: cubeAttributeDropdown?.data ?? [],
              ruleTypes: [],
              enforceUniqueSelection: true,
              isRequired: false,
            },
            {
              name: 'displayLoadParamAttributeNumberOfMonthlyPayments',
              fieldTitle: 'Target Cube Attribute to Store Number of Monthly Payments',
              fieldSubtitle: 'Choose cube attribute to display the loaded data from Number of Monthly Payments',
              fieldType: 'autocomplete',
              placeholder: 'Select cube attribute...',
              fieldOptions: cubeAttributeDropdown?.data ?? [],
              ruleTypes: [],
              enforceUniqueSelection: true,
              isRequired: false,
            },
            {
              name: 'displayLoadParamAttributeMonthlyRepaymentAmount',
              fieldTitle: 'Target Cube Attribute to Store Monthly Repayment Amount',
              fieldSubtitle: 'Choose cube attribute to display the loaded data from Monthly Repayment Amount',
              fieldType: 'autocomplete',
              placeholder: 'Select cube attribute...',
              fieldOptions: cubeAttributeDropdown?.data ?? [],
              ruleTypes: [],
              enforceUniqueSelection: true,
              isRequired: false,
            },
            {
              name: 'displayLoadParamAttributeFinalPayoutAmount',
              fieldTitle: 'Target Cube Attribute to Store Final Payout Amount',
              fieldSubtitle: 'Choose cube attribute to display the loaded data from Final Payout Amount',
              fieldType: 'autocomplete',
              placeholder: 'Select cube attribute...',
              fieldOptions: cubeAttributeDropdown?.data ?? [],
              ruleTypes: [],
              enforceUniqueSelection: true,
              isRequired: false,
            },
          ];
        default:
          return [];
      }
    }, [cubeAttributeDropdown, functionCore]);
    const validationSchema = useMemo(
      () => createValidationSchema(displayLoadParamaterFields),
      [displayLoadParamaterFields]
    );
    const initialValues = getInitialValues<FormikInitialValuesLoadParameter>(displayLoadParamaterFields);
    const formik = useFormik({
      initialValues,
      validationSchema,
      onSubmit: (values) => {},
    });

    useImperativeHandle(ref, () => ({
      getValues: () => formik.values,
    }));

    useEffect(() => {
      onFormikChange?.(formik.values, validationSchema.isValidSync(formik.values));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values]);

    useEffect(() => {
      formik.resetForm();
      formik.setValues(initialValues);
      setSelectedCubeAttributeOptions({});
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [functionCore]);

    useEffect(() => {
      if (functionDefinitionDetails?.data && functionDefinitionDetails.data.displayLoadParameters) {
        formik.setValues(
          functionDefinitionDetails.data.displayLoadParameters as unknown as FormikInitialValuesLoadParameter
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [functionDefinitionDetails?.data]);

    return (
      <Stack mb={5}>
        <Typography variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
          {sectionTitle}
        </Typography>
        <Alert severity='info' sx={{ my: 1 }}>
          <Typography variant='body2'>
            Display all the data loaded from the subject to the cube. All the fields in this section are optional.
          </Typography>
        </Alert>
        <Divider sx={{ my: 1 }} />
        <Stack width='100%' direction='row' gap={3} flexWrap='wrap'>
          {generateFunctionDefinitionParamFormFields<FormikInitialValuesLoadParameter>(
            displayLoadParamaterFields,
            formik,
            selectedCubeAttributeOptions,
            setSelectedCubeAttributeOptions
          )}
        </Stack>
      </Stack>
    );
  }
);
