export const LAYOUT_TABLE_VIEW_TYPE = {
  DATA_GRID_VIEW: 'DATA-GRID-VIEW',
  DEDICATED_PAGE_VIEW: 'DEDICATED-PAGE-VIEW',
};

export const SUBJECT_TYPE = {
  VIEW: 'VIEW',
  TABLE: 'TABLE',
  EVENT: 'EVENT',
};

export const EVENT_SYNC_DATA_SOURCE_CONFIG_STATUS = {
  CONNECTED: 'CONNECTED',
  DISCONNECTED: 'DISCONNECTED',
};
export const EVENT_SYNC_DATA_SOURCE_STATUS = {
  INITIAL: 'INITIAL',
  ONGOING: 'ONGOING',
  COMPLETED: 'COMPLETED',
  FAILED: 'FAILED',
  RECONNECTED: 'RECONNECTED',
  DISCONNECTED: 'DISCONNECTED',
};

export const EVENT_TABLE_IMPORT_OPERATION_TYPE = {
  APPEND_DATA: 'APPEND_DATA',
  OVERWRITE_DATA: 'OVERWRITE_DATA',
};

export const EVENT_SYND_DATA_SOURCE_STATUS_INFO = {
  LABEL: {
    [EVENT_SYNC_DATA_SOURCE_STATUS.INITIAL]: 'Initial',
    [EVENT_SYNC_DATA_SOURCE_STATUS.ONGOING]: 'Ongoing',
    [EVENT_SYNC_DATA_SOURCE_STATUS.COMPLETED]: 'Completed',
    [EVENT_SYNC_DATA_SOURCE_STATUS.FAILED]: 'Failed',
    [EVENT_SYNC_DATA_SOURCE_STATUS.RECONNECTED]: 'Reconnected',
    [EVENT_SYNC_DATA_SOURCE_STATUS.DISCONNECTED]: 'Disconnected',
  },
  COLOR: {
    [EVENT_SYNC_DATA_SOURCE_STATUS.INITIAL]: 'blue',
    [EVENT_SYNC_DATA_SOURCE_STATUS.ONGOING]: 'orange',
    [EVENT_SYNC_DATA_SOURCE_STATUS.COMPLETED]: 'green',
    [EVENT_SYNC_DATA_SOURCE_STATUS.FAILED]: 'red',
    [EVENT_SYNC_DATA_SOURCE_STATUS.RECONNECTED]: 'gray',
    [EVENT_SYNC_DATA_SOURCE_STATUS.DISCONNECTED]: 'orange',
  },
};

export const DATA_INPUT_OBJECT_TYPE = {
  DOCUMENT: 'DOCUMENT',
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO',
  LINK: 'LINK',
};

export const PRIMARY_KEY_TYPE = {
  DYNAMIC_REFERENCE: 'DYNAMIC_REFERENCE',
  STATIC_REFERENCE: 'STATIC_REFERENCE',
};
