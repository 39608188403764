// @ts-nocheck
import React from 'react';
import { FormikProps } from 'formik';
import { Field } from 'types/api/Tenant/AROKMS/DisplayTableTypes';
import { Stack, TextField, Typography, SxProps, Tooltip } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

import { createTreeData, flattenTreeData } from 'utils/Object';
import HelpOutlined from '@mui/icons-material/HelpOutlined';

interface IProps {
  formik: FormikProps<{ [key: string]: string }>;
  field: Field;
  sx?: SxProps;
  ruleTypeCode?: string;
  required?: boolean;
  isEdit?: boolean;
}
const WIDTH_LABEL = '40%';

const INPUT_EDIT_MESSAGE = "Once you've submitted, this field can no longer be changed";
const autocompleteStyles = {
  width: '55%',
  '& .MuiOutlinedInput-root': {
    padding: '1px 2px',
  },
  '& .MuiAutocomplete-popper': {
    fontSize: '10px',
  },
};

const textInputAutoCompleteStyles = {
  '& .MuiOutlinedInput': {
    padding: '1px 2px',
  },
};

export function DropdownKTree(props: IProps) {
  const { formik, field, sx, required, isEdit } = props;
  const [open, setOpen] = React.useState(false);
  const ktreeOptions = field.ktreeOptions ? createTreeData(null, field.ktreeOptions) : [];
  const handleChange = async (event: React.SyntheticEvent, value: { label: string; value: string }) => {
    formik.setFieldValue(field.name, value);
  };

  const optionsSourceData = Array.isArray(ktreeOptions) ? ktreeOptions : ktreeOptions.children ? [ktreeOptions] : [];

  let options = flattenTreeData(optionsSourceData);

  const lowestLevel = options.reduce((acc, item) => {
    if (item.level > acc) {
      return item.level;
    } else {
      return acc;
    }
  }, 0);

  return (
    <Stack direction='row' justifyContent='space-between' alignItems='center' marginTop={2.5} gap={1}>
      <Stack width={WIDTH_LABEL}>
        <Stack direction='row' alignItems='center' gap={0.3}>
          <Typography variant='input-label' fontWeight={700}>
            {field.label}
            {required && !field.disabled && <span style={{ color: 'red', marginLeft: 2 }}>*</span>}
          </Typography>
          {field.description && (
            <Tooltip title={field.description}>
              <HelpOutlined
                sx={{
                  fontSize: '14px',
                }}
              />
            </Tooltip>
          )}
        </Stack>
        {field.columnEditable === false && !isEdit && (
          <Typography variant='input-label-gray' fontSize={12}>
            {INPUT_EDIT_MESSAGE}
          </Typography>
        )}
      </Stack>

      <Autocomplete
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        onChange={handleChange}
        disabled={field.disabled}
        getOptionLabel={(option) => option?.label || ''}
        value={formik.values[field.name] === '' ? { value: '', label: '' } : formik.values[field.name]}
        onInputChange={(event, value) => {
          if (value === '') {
            formik.setFieldValue(field.name, null);
          }
        }}
        options={options || [{ value: '', label: '' }]}
        name={field.name}
        onBlur={formik.handleBlur}
        renderOption={(props, option, state) => {
          if (option.level === lowestLevel) {
            return (
              <li
                {...props}
                style={{
                  marginLeft: `${option.level * 5}px`,
                  fontSize: '14px',
                }}
              >
                {option.label}
              </li>
            );
          }
          return (
            <li
              {...props}
              style={{
                fontWeight: 'bold',
                marginLeft: `${option.level * 4}px`,
                borderBottom: '1px solid #e3e3e3',
                fontSize: '14px',
              }}
            >
              {option.label}
            </li>
          );
        }}
        sx={{ ...autocompleteStyles, ...sx }}
        renderInput={(params) => (
          <TextField
            {...params}
            name={field.name}
            sx={textInputAutoCompleteStyles}
            placeholder={field.placeholder}
            error={!!formik.touched[field.name] && !!formik.errors[field.name]}
            helperText={
              formik.touched[field.name] && formik.errors[field.name] === 'string' ? formik.errors[field.name] : ''
            }
          />
        )}
      />
    </Stack>
  );
}
