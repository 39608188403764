export function formatCubeNumber(num: number, decimalPlaces: number = 0, withPercent: boolean = false) {
  const isNegative = num < 0;
  const absNum = Math.abs(num).toLocaleString(undefined, {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  });

  if (isNegative) {
    if (withPercent) return <span style={{ color: 'red' }}>({absNum}%)</span>;
    return <span style={{ color: 'red' }}>({absNum})</span>;
  } else {
    const value = num.toLocaleString(undefined, {
      minimumFractionDigits: decimalPlaces,
      maximumFractionDigits: decimalPlaces,
    });
    if (withPercent) return <span>{value}%</span>;
    return <span>{value}</span>;
  }
}

export function isPositiveNumber(num: number) {
  return num >= 0;
}
