import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';

import type {} from '@mui/lab/themeAugmentation';

declare module '@mui/material/ButtonGroup' {
  interface ButtonGroupPropsVariantOverrides {
    'main-outlined': true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    'main-login': true;
    'action-warning': true;
    'secondary-login': true;
    'main-table-panel': true;
    'main-table-panel-border': true;
    'secondary-table-panel': true;
    'main-table-panel-borderless': true;
    'navbar-item': true;
  }
}
// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    'input-label': true;
    'input-label-gray': true;
    'label-error': true;
    'modal-title': true;
  }
}

declare module '@mui/material/styles' {
  interface TypographyVariants {
    'input-label': React.CSSProperties;
    'input-label-gray': React.CSSProperties;
    'label-error': React.CSSProperties;
    'modal-title': React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    'input-label'?: React.CSSProperties;
    'input-label-gray'?: React.CSSProperties;
    'label-error'?: React.CSSProperties;
    'modal-title'?: React.CSSProperties;
  }
  interface PaletteOptions {
    mainGreen?: PaletteOptions['primary'];
  }
}
const theme = createTheme({
  palette: {
    primary: {
      light: '#757ce8',
      main: '#3f50b5',
      dark: '#002884',
      contrastText: '#fff',
    },
  },
  typography: {
    fontFamily: '"Nunito", "Helvetica", "Arial", sans-serif',
    subtitle1: {
      fontSize: '14px',
      fontWeight: '300',
    },
    h4: {
      fontSize: '16px',
    },
    'input-label': {
      fontFamily: '"Nunito", "Helvetica", "Arial", sans-serif',
      fontSize: '14px',
      color: '#141414',
    },
    'input-label-gray': {
      fontFamily: '"Nunito", "Helvetica", "Arial", sans-serif',
      fontSize: '14px',
      color: '#828C99',
    },
    'label-error': {
      fontFamily: '"Nunito", "Helvetica", "Arial", sans-serif',
      fontSize: '14px',
      color: '#BB4942',
      marginTop: 1,
    },
    'modal-title': {
      fontFamily: '"Nunito", "Helvetica", "Arial", sans-serif',
      color: '#3B4797',
      fontSize: '16px',
      fontWeight: '600',
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: '#fff',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          color: '#667085',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          color: '#667085',
        },
      },
    },
    MuiLoadingButton: {
      variants: [
        {
          props: { variant: 'action-warning' },
          style: {
            backgroundColor: '#f0d173',
            color: '#fff',
            height: '37px',
            letterSpacing: '1.2px',
            textTransform: 'none',
            fontSize: '13px',
            boxShadow:
              '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
            '&.Mui-disabled': {
              color: '#fff',
              opacity: 0.5,
            },
            '&:hover': {
              backgroundColor: '#FFA000',
            },
          },
        },
        {
          props: { variant: 'main-login' },
          style: {
            textTransform: 'uppercase',
            backgroundColor: '#3ABB93',
            color: '#fff',
            letterSpacing: '1.2px',
            height: '48px',
            boxShadow:
              '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
            '&.Mui-disabled': {
              color: '#fff',
              opacity: 0.5,
            },
            '&:hover': {
              backgroundColor: '#1a5241',
            },
          },
        },
        {
          props: { variant: 'secondary-login' },
          style: {
            backgroundColor: '#00B3DC',
            color: '#fff',
            height: '48px',
            letterSpacing: '1.2px',
            boxShadow:
              '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
            '&.Mui-disabled': {
              color: '#fff',
              opacity: 0.5,
            },
            '&:hover': {
              backgroundColor: '#104a84',
            },
          },
        },
      ],
    },
    MuiButtonGroup: {
      variants: [
        {
          props: { variant: 'main-outlined' },
          style: {
            backgroundColor: '#00B3DC',
            fontSize: '13px',
            textTransform: 'none',
            color: '#fff',
            minWidth: '100px',
            letterSpacing: '1.2px',
            height: '35px',

            '&:hover': {
              backgroundColor: '#104a84',
            },
          },
        },
      ],
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'main-table-panel' },
          style: {
            backgroundColor: '#42BB93',
            fontSize: '13px',
            textTransform: 'none',
            color: '#fff',
            letterSpacing: '1.2px',
            height: '37px',
            boxShadow:
              '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
            '&.Mui-disabled': {
              color: '#fff',
              opacity: 0.5,
            },
            '&:hover': {
              backgroundColor: '#1a5241',
            },
            '&:disabled': {
              cursor: 'not-allowed',
            },
          },
        },
        {
          props: { variant: 'secondary-table-panel' },
          style: {
            backgroundColor: '#00B3DC',
            fontSize: '13px',
            textTransform: 'none',
            color: '#fff',
            letterSpacing: '1.2px',
            height: '37px',
            boxShadow:
              '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
            '&.Mui-disabled': {
              color: '#fff',
              opacity: 0.5,
            },
            '&:hover': {
              backgroundColor: '#104a84',
            },
          },
        },
        {
          props: { variant: 'main-table-panel-borderless' },
          style: {
            color: '#42BB93',
            textTransform: 'none',
            fontSize: '13px',
            backgroundColor: '#fff',
            letterSpacing: '1.2px',
            height: '37px',

            '&.Mui-disabled': {
              opacity: 0.8,
            },
            '&:hover': {
              backgroundColor: '#e8e8e8',
            },
          },
        },
        {
          props: { variant: 'navbar-item' },
          style: {
            color: '#828C99',
            textTransform: 'none',
            fontSize: '14px',
            padding: '12px 8px',
            backgroundColor: '#fff',
            height: '35px',
            border: '1px solid transparent',
            '&.Mui-disabled': {
              opacity: 0.8,
            },
            '&:hover': {
              backgroundColor: '#F3F6F9',
              color: '#00B3DC',
              border: '1px solid #00B3DC',
            },
          },
        },
        {
          props: { variant: 'main-table-panel-border' },
          style: {
            border: '1px solid #42BB93',
            color: '#42BB93',
            textTransform: 'none',
            fontSize: '13px',
            backgroundColor: '#fff',
            letterSpacing: '1.2px',
            height: '37px',
            '&:disabled': {
              color: '#42BB93',
              opacity: 0.5,
            },
            '&:hover': {
              backgroundColor: '#42BB93',
              color: '#fff',
            },
          },
        },
      ],
    },
  },
});

interface ThemeProviderProps {
  children: JSX.Element;
}

export function ThemeProvider({ children }: ThemeProviderProps) {
  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
}
