import { useEffect, useState } from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { InsightViewRelativeTimeType } from 'constant/ViewConstant';
import { ToggleButtonGroupSX } from 'utils/CommonSx';

const options = [
  {
    label: 'Past',
    value: InsightViewRelativeTimeType.PAST_TIME,
  },
  {
    label: 'Current',
    value: InsightViewRelativeTimeType.CURRENT_TIME,
  },
  {
    label: 'Future',
    value: InsightViewRelativeTimeType.FUTURE_TIME,
  },
];

interface RelativeTimeTypeProps {
  value?: string | null;
  onChange?: (value: string) => void;
  disabled?: boolean;
}

export const RelativeTimeType = (props: RelativeTimeTypeProps) => {
  const { value, onChange, disabled } = props;

  const [relativeTime, setRelativeTime] = useState(InsightViewRelativeTimeType.CURRENT_TIME);

  const handleChange = (event: React.MouseEvent<HTMLElement>, selectedRelativeTime: string) => {
    if (selectedRelativeTime === null) return;

    setRelativeTime(selectedRelativeTime);
    onChange?.(selectedRelativeTime);
  };

  useEffect(() => {
    if (value) {
      setRelativeTime(value);
    }
  }, [value]);

  return (
    <ToggleButtonGroup
      color='primary'
      disabled={disabled}
      value={relativeTime}
      exclusive
      onChange={handleChange}
      sx={ToggleButtonGroupSX.buttonGroup}
    >
      {options.map((option) => (
        <ToggleButton key={option.value} value={option.value} size='small'>
          {option.label}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};
