import { GridRowModel } from '@mui/x-data-grid-pro';
import { CubeDefinitionRowItem } from 'types/api/SystemTenant/AROCube/CubeBuilderTypes';
import { FormikPullFromCubeToMultipleLayerParameterItem } from '../components/ModalPullCubeMultipleLayer';

export function sortCubeDefinitionByRefCode(data: CubeDefinitionRowItem[]) {
  const dataCopy = [...data];
  if (dataCopy.length === 0) return dataCopy;

  dataCopy.sort((a, b) => {
    if (a.referenceCode.length !== b.referenceCode.length) {
      return a.referenceCode.length - b.referenceCode.length;
    }
    if (a.referenceCode < b.referenceCode) {
      return -1;
    }
    if (a.referenceCode > b.referenceCode) {
      return 1;
    }
    return 0;
  });
  return dataCopy;
}

export function sortCubeDefinitionByDisplayIndexOrder(data: CubeDefinitionRowItem[]) {
  const dataCopy = [...data];
  if (dataCopy.length === 0) return dataCopy;

  dataCopy.sort((a, b) => {
    if (a.displayIndexOrder < b.displayIndexOrder) {
      return -1;
    }
    if (a.displayIndexOrder > b.displayIndexOrder) {
      return 1;
    }
    return 0;
  });
  return dataCopy;
}

export function updateCubeAttributeRowPosition(
  oldIndex: number,
  newIndex: number,
  rows: Array<GridRowModel<CubeDefinitionRowItem>>
) {
  const rowsClone = [...rows];
  const [row] = rowsClone.splice(oldIndex, 1);
  rowsClone.splice(newIndex, 0, row);

  for (let i = 0; i < rowsClone.length; i++) {
    rowsClone[i].displayIndexOrder = i + 1;
  }

  return rowsClone;
}

export function generateCubePullMultipleLayerDisplayParameter(
  data: FormikPullFromCubeToMultipleLayerParameterItem[]
): string {
  let result = '';
  data.forEach((item) => {
    result += `${item.targetPullCube?.label}(${item.targetPullCubeLayer?.label})`;
    result += ',';
  });

  result = result.slice(0, -1);
  return result;
}
