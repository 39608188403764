import { AxiosResponse } from 'axios';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import axios from 'libs/Axios';

import { AxiosDefaultErrorEntity } from 'types/api/Common/ErrorTypes';
import { DropdownItem } from 'types/api/SystemTenant/AROKMS/TableDefinitionTypes';
import {
  DataMigrationIndexStatus,
  MigrationDetailsItem,
  ResponseMigrationStatus,
} from 'types/api/Tenant/DataMigrationTypes';

export const TENANT_MIGRATION_QUERY_KEY = {
  TENANT_DROPDOWN: 'TENANT_MIGRATION_DATASOURCE_DROPDOWN',
  TENANT_MIGRATION_STATUS: 'TENANT_MIGRATION_STATUS',
  TENANT_MIGRATION_LIST: 'TENANT_MIGRATION_LIST',
  TENANT_ENABLE_BUTTON_MIGRATION: 'TENANT_ENABLE_BUTTON_MIGRATION',
};

export function useGetTenantDataSourceMigrationDropdown() {
  return useQuery<AxiosResponse<DropdownItem[]>, AxiosDefaultErrorEntity>(
    [TENANT_MIGRATION_QUERY_KEY.TENANT_DROPDOWN],
    () => axios.get(`/api/v1/tenant/data-migration/source`).then((res) => res)
  );
}

export function useGenerateMigrationSchema() {
  return useMutation<AxiosResponse<MigrationDetailsItem[]>, AxiosDefaultErrorEntity, { tenantSourceDataId: string }>(
    (data) => axios.post(`/api/v1/tenant/data-migration/generate`, data).then((res) => res)
  );
}

export function useGetTenantMigrationList({ refetchInterval = false }: { refetchInterval: number | false }) {
  return useQuery<AxiosResponse<DataMigrationIndexStatus[]>, AxiosDefaultErrorEntity>(
    [TENANT_MIGRATION_QUERY_KEY.TENANT_MIGRATION_LIST],
    () => axios.get(`/api/v1/tenant/data-migration/list`).then((res) => res),
    {
      refetchInterval,
    }
  );
}

export function useExecuteMigrateData() {
  return useMutation<AxiosResponse, AxiosDefaultErrorEntity, { tenantSourceDataId: string }>((data) =>
    axios.post(`/api/v1/tenant/data-migration/migrate`, data).then((res) => res)
  );
}
export function useDeleteMigration() {
  const queryClient = useQueryClient();

  return useMutation<AxiosResponse, AxiosDefaultErrorEntity, {}>(
    () => axios.delete(`/api/v1/tenant/data-migration/delete`).then((res) => res),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(TENANT_MIGRATION_QUERY_KEY.TENANT_MIGRATION_LIST);
        queryClient.removeQueries(TENANT_MIGRATION_QUERY_KEY.TENANT_MIGRATION_STATUS);
        queryClient.removeQueries(TENANT_MIGRATION_QUERY_KEY.TENANT_DROPDOWN);
      },
    }
  );
}
export function useGetMigrationStatus({
  enabled,
  refetchInterval,
}: {
  enabled: boolean;
  refetchInterval: number | false;
}) {
  return useQuery<AxiosResponse<ResponseMigrationStatus>, AxiosDefaultErrorEntity>(
    [TENANT_MIGRATION_QUERY_KEY.TENANT_MIGRATION_STATUS],
    () => axios.get(`/api/v1/tenant/data-migration/migrate/status`).then((res) => res),
    {
      enabled,
      refetchInterval,
    }
  );
}

export function useGetEnableButtonMigration() {
  return useQuery<
    AxiosResponse<{
      enableButton: boolean;
    }>,
    AxiosDefaultErrorEntity
  >([TENANT_MIGRATION_QUERY_KEY.TENANT_ENABLE_BUTTON_MIGRATION], () =>
    axios.get(`/api/v1/tenant/data-migration/enable-button`).then((res) => res)
  );
}
