import { gridFilteredSortedRowIdsSelector, gridVisibleColumnFieldsSelector, GridApi } from '@mui/x-data-grid-pro';
import * as XLSX from 'xlsx';
export interface ExportConfig {
  columnNames: string[];
  keys: string[];
  sheetName: string;
  fileName: string;
}

export function getExcelData(apiRef: React.MutableRefObject<GridApi>) {
  // Select rows and columns
  const filteredSortedRowIds = gridFilteredSortedRowIdsSelector(apiRef);
  const visibleColumnsField = gridVisibleColumnFieldsSelector(apiRef);

  // Format the data. Here we only keep the value
  const data = filteredSortedRowIds.map((id) => {
    const row: {
      [key: string]: string | number | boolean;
    } = {};
    visibleColumnsField.forEach((field) => {
      row[field] = apiRef.current.getCellParams(id, field).value as string | number | boolean;
    });
    return row;
  });

  return data;
}

export function handleExport(apiRef: React.MutableRefObject<GridApi>, config: ExportConfig) {
  const data = getExcelData(apiRef);

  const rows = data.map((row) => {
    const mRow: {
      [key: string]: string | number | boolean;
    } = {};
    for (const key of config.keys) {
      mRow[key] = row[key];
    }
    return mRow;
  });

  const worksheet = XLSX.utils.json_to_sheet(rows);
  XLSX.utils.sheet_add_aoa(worksheet, [[...config.columnNames]], {
    origin: 'A1',
  });

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, config.sheetName);
  XLSX.writeFile(workbook, config.fileName, { compression: true });
}

export const generateColumnNames = (apiRef: React.MutableRefObject<GridApi>) => {
  const visibleColumnsField = gridVisibleColumnFieldsSelector(apiRef);
  return visibleColumnsField.filter((field) => field !== 'action');
};

export const getDisplayColumnNames = (apiRef: React.MutableRefObject<GridApi>) => {
  const visibleColumnsField = gridVisibleColumnFieldsSelector(apiRef);
  return visibleColumnsField
    .filter((field) => field !== 'action')
    .map((field) => {
      return apiRef.current.getColumn(field);
    });
};
