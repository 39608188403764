import { useContext, useEffect, useImperativeHandle } from 'react';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import Stack from '@mui/material/Stack';
import Typograhpy from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import ButtonLoading from '@mui/lab/LoadingButton';
import { useFormik } from 'formik';

import { CubeDefinitionSettingPanelValidationSchema } from 'config/FormValidaton/AroCube/CubeDefinitionSettingPanelValidationSchema';
import { DropdownItem } from 'types/api/SystemTenant/AROKMS/TableDefinitionTypes';
import { useGetDropdownsCubePanel } from 'services/v1/SystemTenant/AROCube/CubeBuilderService';
import { CubeDefinitionDetails } from 'types/api/SystemTenant/AROCube/CubeBuilderTypes';
import { CreateCubeDefinitionContext } from './CubeDefinitionContext';

export interface OptionItem {
  value: string | number;
  label: string;
}

const autoCompleteStyle = {
  width: 200,
  '& .MuiAutocomplete-popper': {
    backgroundColor: 'red !imoprtant',
    fontSize: '10px',
  },
  '& .MuiInputLabel-outlined': {
    color: 'rgba(0, 0, 0, 0.34)',
  },
};

const textInputStyle = {};

export type FormCubeDefinitionMetaDataValues = {
  cubeId?: string;
  cubeName: string;
  member: DropdownItem | null;
  layerSet: DropdownItem | null;
  timePeriod: DropdownItem | null;
  subjectX: DropdownItem | null;
};

interface SettingPanelProps {
  disabled: boolean;
  loading?: boolean;
  subjectId?: string | number;
  ref?: any;
  onSubmit: (data: FormCubeDefinitionMetaDataValues) => void;
  onChange?: (data: FormCubeDefinitionMetaDataValues) => void;
  initialValues?: CubeDefinitionDetails;
  edit?: boolean;
}

const formikInitialValues = {
  cubeName: '',
  member: null,
  layerSet: null,
  timePeriod: null,
  subjectX: null,
};

export function CubeDefinitionSettingPanel(props: SettingPanelProps) {
  const { disabled: disableProps, subjectId, ref, onSubmit, initialValues, edit = false, loading, onChange } = props;
  const { data: dropdownOptions, isLoading: isLoadingDropdownOptions } = useGetDropdownsCubePanel(subjectId);
  const disabled = disableProps || isLoadingDropdownOptions;

  const formik = useFormik<FormCubeDefinitionMetaDataValues>({
    initialValues: formikInitialValues,
    validationSchema: CubeDefinitionSettingPanelValidationSchema,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  const { setIsFormValid } = useContext(CreateCubeDefinitionContext);

  useImperativeHandle(ref, () => ({
    formData: formik?.values,
  }));

  const handleOnSubmit = () => {
    const data = formik?.values;
    data && onSubmit(data);
  };

  useEffect(() => {
    if (initialValues) {
      formik?.setValues({
        cubeName: initialValues.cubeName,
        member: initialValues.member,
        layerSet: initialValues.layerSet,
        timePeriod: initialValues.timePeriodSet,
        subjectX: initialValues.subjectX,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  useEffect(() => {
    if (formik.values && onChange) {
      onChange(formik.values);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values]);

  const isFormValid = CubeDefinitionSettingPanelValidationSchema.isValidSync(formik?.values);

  useEffect(() => {
    setIsFormValid(isFormValid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFormValid]);

  return (
    <Stack direction='column' sx={{ backgroundColor: '#fff', py: 1.8 }}>
      <Stack direction='row' justifyContent='space-between' spacing={4} alignItems='center' sx={{ mb: 2, px: 2 }}>
        <Stack direction='row' spacing={5} alignItems='center'>
          <Stack width={80}>
            <Typograhpy variant='body2' fontWeight='bold' color='black'>
              Selection
            </Typograhpy>
          </Stack>
          <TextField
            name='cubeName'
            size='small'
            label='Cube Name'
            value={formik.values.cubeName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            disabled={disabled || loading}
            sx={{
              width: 250,
              textAlign: 'left',
              '& .MuiInputLabel-outlined': {
                color: 'rgba(0, 0, 0, 0.34)',
              },
            }}
            error={!!formik.errors.cubeName && formik.touched.cubeName}
            helperText={formik.touched.cubeName && formik.errors.cubeName}
            variant='outlined'
            margin='normal'
            fullWidth
          />
        </Stack>

        <ButtonLoading
          onClick={handleOnSubmit}
          loading={loading}
          disabled={disabled || !isFormValid}
          startIcon={edit ? null : <AddCircleOutlineOutlinedIcon />}
          variant={edit ? 'main-table-panel' : 'secondary-table-panel'}
          sx={{
            width: 170,
          }}
        >
          {edit ? 'Update' : 'Generate'}
        </ButtonLoading>
      </Stack>
      <Stack
        direction='row'
        spacing={4}
        alignItems='center'
        sx={{ mb: 2, bgcolor: '#FBFBFB', py: 2, px: 2, border: '1px solid #E3EBF6' }}
      >
        <Stack direction='row' spacing={5} alignItems='center'>
          <Stack width={80}>
            <Typograhpy variant='body2' fontWeight='bold' color='black'>
              Define
            </Typograhpy>
          </Stack>
          <Autocomplete
            // @ts-ignore
            onChange={(event, value) => {
              formik.setFieldValue('member', value);
            }}
            size='small'
            value={formik.values.member}
            disabled={disabled || loading || edit}
            getOptionLabel={(option: DropdownItem) => option.label}
            options={dropdownOptions?.data.member || []}
            renderOption={(props, option) => (
              <Box component='li' sx={{ '& > span': { fontSize: '14px', mr: 1, flexShrink: 0 } }} {...props}>
                <span>{option.label}</span>
              </Box>
            )}
            sx={autoCompleteStyle}
            renderInput={(params) => (
              <Stack direction='row' justifyContent='space-between' display='flex' alignItems='center'>
                <TextField
                  {...params}
                  sx={textInputStyle}
                  label='Main Dimension'
                  onBlur={formik.handleBlur}
                  name='member'
                  error={!!formik.errors.member && formik.touched.member}
                  helperText={formik.touched.member && formik.errors.member}
                />
              </Stack>
            )}
          />
          <Autocomplete
            // @ts-ignore
            onChange={(event, value) => {
              formik.setFieldValue('layerSet', value);
            }}
            size='small'
            value={formik.values.layerSet}
            disabled={disabled || edit || loading}
            getOptionLabel={(option: DropdownItem) => option.label}
            options={dropdownOptions?.data.layerSet || []}
            renderOption={(props, option) => (
              <Box component='li' sx={{ '& > span': { fontSize: '14px', mr: 1, flexShrink: 0 } }} {...props}>
                <span>{option.label}</span>
              </Box>
            )}
            sx={autoCompleteStyle}
            renderInput={(params) => (
              <Stack direction='row' justifyContent='space-between' display='flex' alignItems='center'>
                <TextField
                  {...params}
                  sx={textInputStyle}
                  label='Layer'
                  onBlur={formik.handleBlur}
                  name='layerSet'
                  error={!!formik.errors.layerSet && formik.touched.layerSet}
                  helperText={formik.touched.layerSet && formik.errors.layerSet}
                />
              </Stack>
            )}
          />{' '}
          <Autocomplete
            // @ts-ignore
            onChange={(event, value) => {
              formik.setFieldValue('timePeriod', value);
            }}
            size='small'
            value={formik.values.timePeriod}
            disabled={disabled || edit || loading}
            getOptionLabel={(option: DropdownItem) => option.label}
            options={dropdownOptions?.data.timePeriod || []}
            renderOption={(props, option) => (
              <Box component='li' sx={{ '& > span': { fontSize: '14px', mr: 1, flexShrink: 0 } }} {...props}>
                <span>{option.label}</span>
              </Box>
            )}
            sx={autoCompleteStyle}
            renderInput={(params) => (
              <Stack direction='row' justifyContent='space-between' display='flex' alignItems='center'>
                <TextField
                  {...params}
                  sx={textInputStyle}
                  label='Time Period'
                  onBlur={formik.handleBlur}
                  name='timePeriod'
                  error={!!formik.errors.timePeriod && formik.touched.timePeriod}
                  helperText={formik.touched.timePeriod && formik.errors.timePeriod}
                />
              </Stack>
            )}
          />
          <Autocomplete
            // @ts-ignore
            onChange={(event, value) => {
              formik.setFieldValue('subjectX', value);
            }}
            size='small'
            value={formik.values.subjectX}
            disabled={disabled || loading || edit}
            getOptionLabel={(option: DropdownItem) => option.label}
            options={dropdownOptions?.data.subjectX || []}
            renderOption={(props, option) => (
              <Box component='li' sx={{ '& > span': { fontSize: '14px', mr: 1, flexShrink: 0 } }} {...props}>
                <span>{option.label}</span>
              </Box>
            )}
            sx={autoCompleteStyle}
            renderInput={(params) => (
              <Stack direction='row' justifyContent='space-between' display='flex' alignItems='center'>
                <TextField
                  {...params}
                  sx={textInputStyle}
                  label='Second Dimension'
                  onBlur={formik.handleBlur}
                  name='subjectX'
                  error={!!formik.errors.subjectX && formik.touched.subjectX}
                  helperText={formik.touched.subjectX && formik.errors.subjectX}
                />
              </Stack>
            )}
          />
        </Stack>
      </Stack>
    </Stack>
  );
}
