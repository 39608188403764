import { useEffect, useState, useRef } from 'react';
import { Formik, FormikProps } from 'formik';
import Divider from '@mui/material/Divider';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import ButtonLoading from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';

import ActivityIndicator from 'components/ActivityIndicatorComponent';
import { SystemDetails, SystemItem } from 'types/api/TenantMaster/MasterTable/SystemTableDataTypes';
import { useSystemDetails, useUpdateSystem } from 'services/v1/TenantMaster/MasterTable/SystemTableDataService';
import { TabSectionSystemDataContent } from '../ModalCreateSystem/SystemDataContent';
import { TabSectionServerUserContent } from '../ModalCreateSystem/TabSectionServerUser';
import { UpdateSystemValidationSchema } from 'config/FormValidaton/TenantMaster/MasterTable/SystemTableValidationScheman';
import { GLOBAL_MODAL_STYLE_VALUE } from 'constant/GloblaStyleConstant';

// Constant for this component
const TAB_PANEL = {
  TAB_PANEL_SYSTEM_DATA: 'TAB_PANEL_SYSTEM_DATA',
  TAB_PANEL_SERVER_USER_DATA: 'TAB_PANEL_SERVER_USER_DATA',
};

// Source of system data when creating a new system
// SCRATCH: Create a new system from scratch
// COPY_FROM_EXISTING_SYSTEM: Copy from an existing system
// If the value is COPY_FROM_EXISTING_SYSTEM, the user will be able to select the system to copy from existing systems
const SYSTEM_DATA_SOURCE = {
  SCRATCH: 'SCRATCH',
  COPY_FROM_EXISTING_SYSTEM: 'COPY_FROM_EXISTING_SYSTEM',
};

const ModalContentStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  minHeight: 610,
  borderRadius: 1,

  py: 2,
};

const TabStyle = {
  textTransform: 'none',
  '&.Mui-selected': {
    color: '#42BB93',
  },
};
const TabIndicatoryStyle = {
  style: {
    backgroundColor: '#42BB93',
  },
};

const closeIconStyle = { color: '#98A2AE', cursor: 'pointer' };

const formInitialValues: SystemDetails = {
  serverId: {
    value: '',
    label: '',
  },
  userId: [],
  system: {
    value: '',
    label: '',
  },
  codeStatus: {
    value: 'Changing',
    label: 'Changing',
  },
  demoStatus: {
    value: 'Changing',
    label: 'Changing',
  },
  fieldStatus: {
    value: 'Unavailable',
    label: 'Unavailable',
  },
  dataSource: SYSTEM_DATA_SOURCE.SCRATCH,
  allowChangeFieldStatus: true,
  sourceSystemId: null,
};
interface ModalParamsProps {
  visible?: boolean;
  data?: SystemItem | null;
  title?: string;
  onClose?: () => void;
}

export function ModalUpdateSystem(props: ModalParamsProps) {
  const formikRef = useRef<FormikProps<SystemDetails>>(null);
  const { onClose, visible = false, title } = props;

  const [selectedTabPanel, setSelectedTabPanel] = useState(TAB_PANEL.TAB_PANEL_SYSTEM_DATA);

  const { mutate: updateSystem, isLoading: isDeploying, error, isError } = useUpdateSystem();
  const { data: tenantDetails, isLoading } = useSystemDetails(props.data?.id);

  const handleOnCancel = () => {
    onClose?.();
  };

  const handleOnSave = (data: SystemDetails) => {
    const payloadData = {
      codeStatus: data.codeStatus?.value || '',
      fieldStatus: data.fieldStatus?.value || '',
      demoStatus: data.demoStatus?.value || '',
      serverId: data.serverId?.value || '',
      system: data.system?.value || '',
      userId: data.userId,
      id: props.data?.id,
    };
    updateSystem(payloadData, {
      onSuccess: () => {
        handleOnCancel();
      },
    });
  };

  useEffect(() => {
    if (tenantDetails) {
      formikRef.current?.setValues(tenantDetails?.data);
    }
  }, [tenantDetails]);

  return (
    <div>
      <Modal open={visible} onClose={onClose}>
        <Stack direction='column' sx={ModalContentStyle} justifyContent='space-between'>
          {isLoading ? (
            <Stack direction='column' sx={ModalContentStyle} justifyContent='center'>
              <ActivityIndicator />
            </Stack>
          ) : (
            <Formik
              initialValues={formInitialValues}
              onSubmit={handleOnSave}
              innerRef={formikRef}
              validationSchema={UpdateSystemValidationSchema}
            >
              {(formikProps) => (
                <>
                  <Stack direction='column' display='flex' flexGrow={3}>
                    <Stack
                      px={GLOBAL_MODAL_STYLE_VALUE.PADDING_X}
                      justifyContent='space-between'
                      direction='row'
                      alignItems='center'
                      spacing={2}
                    >
                      <Typography variant='modal-title' component='h2'>
                        {title}
                      </Typography>
                      <IconButton onClick={onClose} component='label'>
                        <CloseIcon sx={closeIconStyle} />
                      </IconButton>
                    </Stack>

                    <Divider color='#E3EBF6' sx={{ mb: 1, mt: 1 }} />

                    <Box px={GLOBAL_MODAL_STYLE_VALUE.PADDING_X}>
                      <Tabs
                        TabIndicatorProps={TabIndicatoryStyle}
                        onChange={(event, newValue) => {
                          setSelectedTabPanel(newValue);
                        }}
                        value={selectedTabPanel}
                      >
                        <Tab sx={TabStyle} value={TAB_PANEL.TAB_PANEL_SYSTEM_DATA} label='System Data' />
                        <Tab sx={TabStyle} value={TAB_PANEL.TAB_PANEL_SERVER_USER_DATA} label='Server & Users' />
                      </Tabs>
                    </Box>
                    {!tenantDetails?.data?.allowChangeFieldStatus &&
                      selectedTabPanel === TAB_PANEL.TAB_PANEL_SYSTEM_DATA && (
                        <Stack>
                          <Alert severity='info'>
                            This system is currently in use by real tenant data. So you will not be able to change the
                            fields status.
                          </Alert>
                        </Stack>
                      )}

                    {selectedTabPanel === TAB_PANEL.TAB_PANEL_SYSTEM_DATA && (
                      <TabSectionSystemDataContent
                        formikProps={formikProps}
                        disabled
                        enableFieldStatus={tenantDetails?.data.allowChangeFieldStatus}
                      />
                    )}

                    {selectedTabPanel === TAB_PANEL.TAB_PANEL_SERVER_USER_DATA && (
                      <TabSectionServerUserContent formikProps={formikProps} disabled />
                    )}
                  </Stack>

                  {isError && (
                    <Stack>
                      <Alert severity='error'>{error.response?.data.message}</Alert>
                    </Stack>
                  )}

                  <Stack>
                    <Divider sx={{ mb: 1, mt: 2 }} />
                    <Stack
                      px={GLOBAL_MODAL_STYLE_VALUE.PADDING_X}
                      direction='row'
                      justifyContent='flex-end'
                      spacing={2}
                      alignItems='center'
                      sx={{ py: 1 }}
                    >
                      <Button disabled={isDeploying} variant='main-table-panel-border' onClick={handleOnCancel}>
                        Cancel
                      </Button>
                      <ButtonLoading
                        disabled={
                          !UpdateSystemValidationSchema.isValidSync(formikProps.values) ||
                          !tenantDetails?.data?.allowChangeFieldStatus
                        }
                        variant='main-table-panel'
                        loading={isDeploying}
                        onClick={() => formikProps.handleSubmit()}
                      >
                        Update
                      </ButtonLoading>
                    </Stack>
                  </Stack>
                </>
              )}
            </Formik>
          )}
        </Stack>
      </Modal>
    </div>
  );
}
