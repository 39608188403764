import {
  GridPreProcessEditCellProps,
  GridRenderEditCellParams,
  GridValueFormatterParams,
  GridValueGetterParams,
} from '@mui/x-data-grid-pro';
import { dayjs } from 'utils/DateUtils';
import { DataGridCellTextInput } from 'components/DatatableComponent/RenderCustomTextInput';
import { GridEditDateCell } from 'components/DatatableComponent/CustomDateTimeInput';
import { getJulianDateBasedOnDayType, getJulianDate } from 'utils/DateUtils';
import { ENV_CONFIG } from 'config/env';

// Date Rules
export function RuleInputDate(renderFormat: string = ENV_CONFIG.config.DATE_FORMAT, isRequired: boolean = false) {
  return {
    type: 'datetime',
    width: 200,
    // @ts-ignore
    renderEditCell: (params) => {
      return (
        <GridEditDateCell
          {...params}
          isRequired={isRequired}
          colDef={{
            type: 'date',
          }}
        />
      );
    },
    valueGetter: (params: GridValueFormatterParams) => {
      if (!params.value) {
        // return initial value
        return null;
      }
      return dayjs(params.value).toISOString();
    },

    valueFormatter: (params: GridValueFormatterParams) => {
      if (!params.value) return '';
      return `${dayjs(params.value).format(renderFormat)}`;
    },
  };
}

export function RuleWeekNum(column: string) {
  return {
    type: 'number',
    renderEditCell: (params: GridRenderEditCellParams) => <DataGridCellTextInput {...params} />,
    preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
      const value = params?.otherFieldsProps?.[column].value || '';
      const date = dayjs(value);
      return { ...params.props, value: date.week() };
    },
  };
}

export function RuleDateAddition(
  column: string,
  type: string,
  number: number,
  renderFormat: string = ENV_CONFIG.config.DATE_FORMAT
) {
  return {
    type: 'date',
    width: 200,
    editable: false,
    // @ts-ignore
    renderEditCell: (params) => {
      return (
        <GridEditDateCell
          {...params}
          colDef={{
            type: 'date',
          }}
        />
      );
    },
    preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
      const value = params?.otherFieldsProps?.[column].value || '';
      const date = dayjs(value);
      // @ts-ignore
      return { ...params.props, value: date.add(number, type) };
    },

    valueGetter: (params: GridValueGetterParams) => {
      const referenceValue = params?.row?.[column] || null;
      if (referenceValue) {
        const date = dayjs(referenceValue);
        // @ts-ignore
        return date.add(number, type);
      }

      return null;
    },
    valueFormatter: (params: GridValueFormatterParams) => {
      if (!params.value) return '';
      return `${dayjs(params.value).format(renderFormat)}`;
    },
  };
}

export function RuleDatePeriods(paramDate1: string, paramDate2: string, type: string, decimalLimit: number) {
  return {
    type: 'number',
    renderEditCell: (params: GridRenderEditCellParams) => <DataGridCellTextInput {...params} />,
    preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
      const value1 = params?.otherFieldsProps?.[paramDate1].value || '';
      const value2 = params?.otherFieldsProps?.[paramDate2].value || '';
      const finalResult = getJulianDate(dayjs(value2)) - getJulianDate(dayjs(value1));
      const value = getJulianDateBasedOnDayType(finalResult, type, decimalLimit);
      // @ts-ignore
      return { ...params.props, value };
    },
  };
}
