import * as yup from 'yup';

export const AddNewPersonValidationSchema = yup.object().shape({
  person: yup
    .string()
    .matches(/^[A-Za-z]+$/, 'Person can only contain letters and cannot contain spaces')
    .required('Person is required'),
  fullName: yup
    .string()
    .matches(/^[A-Za-z\s]+$/, 'Full name can only contain letters and spaces')
    .required('Full name is required'),
  email: yup.string().email('Invalid email').required('Email is required'),
  mobile: yup.string().min(6, 'Invalid phone number').required('Phone number is required'),
  password: yup
    .string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      'Password must have min length 8 characters, at least a number, at least one uppercase, and at least one special character'
    )
    .required('Password is required'),
  confirmPassword: yup
    .string()
    .required('Confirm password is required')
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
});

export const EditPersonValidationSchema = yup.object().shape({
  person: yup
    .string()
    .matches(/^[A-Za-z\s]+$/, 'Person can only contain letters and spaces')
    .required('Person is required'),
  fullName: yup
    .string()
    .matches(/^[A-Za-z\s]+$/, 'Full name can only contain letters and spaces')
    .required('Full name is required'),
  email: yup.string().email('Invalid email').required('Email is required'),
  mobile: yup.string().min(6, 'Invalid phone number').required('Phone number is required'),
});

export const ChangePasswordValidationSchema = yup.object().shape({
  password: yup
    .string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      'Password must have min length 8 characters, at least a number, at least one uppercase, and at least one special character'
    )
    .required('Password is required'),
  confirmPassword: yup
    .string()
    .required('Confirm password is required')
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
});
