import * as React from 'react';
import { Chip, Paper, Stack, SxProps, Backdrop, IconButton, Tooltip } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined';
import ModalConfigurationPage from 'pages/Tenant/ConfigurationPage';
import { BreadCumbItemType, NavBarStatusBar } from 'components/LayoutComponent/VerticalLayout/NavBarStatusBar';
import { Navigation } from './NavBar';
import { DashboardVerticalNavigationAppBar } from './UserMenuOptions';
import { ModalChangeLanguageComponent } from 'components/ModalComponent/ModalChangeLanguageComponent';
import { AutoCompleteItem } from 'types/api/Common/AutoCompleteTypes';
import { useIsFetching } from 'react-query';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';
import { ProgressTopBarComponent } from '../../ProgressTopBarComponent';
import { ModalChangeRole } from '../../../pages/Auth/ModalChangeRole';
import { RoleType } from 'store/reducer/authReducer';
import { ROLES } from 'constant/PermissonConstant';
import { ModalSessionExpired } from 'pages/Auth/ModalSessionExpired';
import { SESSION_STATUS_CONSTANT } from 'constant/SessionStatusConstant';
import { AxiosDefaultErrorEntity } from 'types';
import { ErrorBoxComponent } from '../../Error/ErrorBoxComponent';
import { BackToListButton } from 'components/ButtonComponent/ButtonBackToList';
import { QuickAccessBox } from './QuickAccess';
import { toggleTenantNavigation } from 'store/reducer/uiReducer';

export type CustomIconType = React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined;
  }
>;

export type SelectedMenuItems = {
  subject: AutoCompleteItem | null;
  ktree: AutoCompleteItem | null;
  event: AutoCompleteItem | null;
  cube: AutoCompleteItem | null;
  'subject-procedure': AutoCompleteItem | null;
};

type TenantHomeDashboardNavigationProps = {
  children: React.ReactNode | JSX.Element;
  containerSx?: SxProps;
  error?: AxiosDefaultErrorEntity | null;
  onBackButtonClick?: () => void;
  backButtonTitle?: string;
};

export const NAV_ITEM_TYPE = {
  SUBJECT: 'subject',
  KTREE: 'ktree',
  EVENT: 'event',
  CUBE: 'cube',
  STANDARD_VIEW: 'view',
  SUBJECT_PROCEDURE: 'subject-procedure',
  INSIGHT_VIEW: 'insight',
  DASHBOARD: 'dashboard',
  ORGANIZATION: 'organization',
  MASTER_TABLE_DISPLAY: 'master-table-display',
  MASTER_TABLE: 'master-table',
};

const chipAdminLabelStyle = {
  fontSize: '0.6rem',
  padding: 0,
  height: 13,
  py: 0.5,
  width: 60,
};

function generateBreadCumbItems(selectedMenus: SelectedMenuItems, organizationName?: string): BreadCumbItemType[] {
  const order = [
    NAV_ITEM_TYPE.ORGANIZATION,
    NAV_ITEM_TYPE.SUBJECT,
    NAV_ITEM_TYPE.EVENT,
    NAV_ITEM_TYPE.KTREE,
    NAV_ITEM_TYPE.CUBE,
    NAV_ITEM_TYPE.INSIGHT_VIEW,
    NAV_ITEM_TYPE.STANDARD_VIEW,
    NAV_ITEM_TYPE.DASHBOARD,
    NAV_ITEM_TYPE.SUBJECT_PROCEDURE,
  ];

  const mainItem = [
    {
      label: organizationName ?? 'Dashboard',
      type: NAV_ITEM_TYPE.ORGANIZATION,
      id: organizationName ?? 'Dashboard',
    },
  ];
  const items = order.reduce((acc: BreadCumbItemType[], key: string) => {
    const item = selectedMenus[key as keyof SelectedMenuItems];
    if (item) {
      acc.push({
        label: item.label,
        type: key,
        id: item.value?.toString(),
      });
    }
    return acc;
  }, []);

  return [...mainItem, ...items];
}

function ImageLogoComponent(props: { roleType: RoleType | ''; tenantVersion?: string }) {
  const { roleType, tenantVersion } = props;
  return (
    <Stack>
      <img
        src='/images/main-logo.svg'
        alt='logo'
        style={{
          width: '115px',
          height: 'auto',
        }}
      />
      <Stack direction='row' gap={1}>
        {roleType === ROLES.ADMIN ? (
          <Chip variant='filled' label='Admin' color='primary' size='small' sx={chipAdminLabelStyle} />
        ) : (
          <Chip variant='filled' label={tenantVersion} color='primary' size='small' sx={chipAdminLabelStyle} />
        )}
      </Stack>
    </Stack>
  );
}

export function TenantHomeDashboardNavigationBar(props: TenantHomeDashboardNavigationProps) {
  const isAnyFetching = useIsFetching();
  const [isQuickAccessOpen, setIsQuickAccessOpen] = React.useState(false);
  const { children, containerSx, error, onBackButtonClick, backButtonTitle } = props;
  const homeReducer = useSelector((state: RootState) => state.home);
  const authReducer = useSelector((state: RootState) => state.auth);
  const [isMenuClickable, setIsMenuClickable] = React.useState(false);
  const { showModalSelectLanguage, modalKey, isTenantNavigationOpen } = useSelector((state: RootState) => state.ui);
  const dispatch = useDispatch();

  const [showModalChangeRole, setShowModalChangeRole] = React.useState(false);
  const [showModalConfigurationPage, setShowModalConfigurationPage] = React.useState(false);

  const breadCrumbItems = React.useMemo(
    () => generateBreadCumbItems(homeReducer.navigationState, authReducer.selectedTenant.tenant.organizationName),
    [homeReducer.navigationState, authReducer.selectedTenant.tenant.organizationName]
  );

  const handleOnCloseModalChangeRole = () => {
    setShowModalChangeRole(false);
  };
  const handleOpenModalChangeRole = () => {
    setShowModalChangeRole(true);
  };

  const handleOpenConfigrationPage = () => {
    setShowModalConfigurationPage(true);
  };

  const handleOnToggleQuickAccess = () => {
    setIsQuickAccessOpen(!isQuickAccessOpen);
  };

  const handleOnToggleShowNavigation = () => {
    setTimeout(() => {
      setIsMenuClickable(!isTenantNavigationOpen);
    }, 600);

    dispatch(toggleTenantNavigation());
  };

  React.useEffect(() => {
    if (isTenantNavigationOpen) {
      setIsMenuClickable(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <ProgressTopBarComponent visible={!!isAnyFetching} />
      <CssBaseline />
      {isQuickAccessOpen && (
        <Backdrop
          open={isQuickAccessOpen}
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, color: '#fff' }}
          onClick={handleOnToggleQuickAccess}
        >
          <AppBar component='nav' sx={{ boxShadow: 'none', zIndex: 10 }} position='fixed'>
            <Toolbar sx={{ justifyContent: 'space-between' }}>
              <Stack direction='row' spacing={2} alignItems='center'>
                <ImageLogoComponent
                  roleType={authReducer.selectedTenant.roleType}
                  tenantVersion={authReducer.selectedTenant.tenant.version}
                />

                {isTenantNavigationOpen && <Navigation clickAble />}
                <Tooltip title={isTenantNavigationOpen ? 'Show less menu' : 'Show more menu'}>
                  <IconButton onClick={handleOnToggleShowNavigation}>
                    <DragIndicatorOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </Stack>
              <DashboardVerticalNavigationAppBar
                onChangeRoleClick={handleOpenModalChangeRole}
                onToggleQuickAccess={handleOnToggleQuickAccess}
                onConfigurationClick={handleOpenConfigrationPage}
              />
            </Toolbar>
            <NavBarStatusBar breadCumbItems={breadCrumbItems} />
            <QuickAccessBox onClose={handleOnToggleQuickAccess} />
          </AppBar>
        </Backdrop>
      )}

      <AppBar
        component='nav'
        sx={{
          boxShadow: 'none',
          zIndex: 10,
        }}
        position='fixed'
      >
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <Stack direction='row' spacing={2} alignItems='center'>
            <ImageLogoComponent
              roleType={authReducer.selectedTenant.roleType}
              tenantVersion={authReducer.selectedTenant.tenant.version}
            />

            {isTenantNavigationOpen && <Navigation clickAble={isMenuClickable} />}

            <Tooltip title={isTenantNavigationOpen ? 'Show less menu' : 'Show more menu'}>
              <IconButton onClick={handleOnToggleShowNavigation}>
                <DragIndicatorOutlinedIcon />
              </IconButton>
            </Tooltip>
          </Stack>
          <DashboardVerticalNavigationAppBar
            onChangeRoleClick={handleOpenModalChangeRole}
            onToggleQuickAccess={handleOnToggleQuickAccess}
            onConfigurationClick={handleOpenConfigrationPage}
          />
        </Toolbar>
        <NavBarStatusBar breadCumbItems={breadCrumbItems} />
      </AppBar>
      <Toolbar />
      <Stack px={3} pt={1} mt={4}>
        {onBackButtonClick && (
          <Stack width={300} alignItems='flex-start'>
            <BackToListButton onClick={onBackButtonClick} title={backButtonTitle} />
          </Stack>
        )}
        <Paper
          sx={{
            width: '100%',
            ...containerSx,
          }}
        >
          {!error && children}
          {error && <ErrorBoxComponent error={error} />}
        </Paper>
      </Stack>
      <ModalChangeRole visible={showModalChangeRole} onClose={handleOnCloseModalChangeRole} />
      <ModalChangeLanguageComponent visible={showModalSelectLanguage} key={modalKey} />
      <ModalSessionExpired visible={authReducer.sessionStatus === SESSION_STATUS_CONSTANT.EXPIRED} />
      <ModalConfigurationPage
        visible={showModalConfigurationPage}
        onClose={() => setShowModalConfigurationPage(false)}
      />
    </Box>
  );
}
