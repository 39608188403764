import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import Divider from '@mui/material/Divider';

import { PDFViewer } from './PDFViewer';
const ModalContentStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  bgcolor: 'background.paper',
  borderRadius: 1,
  px: 3,
  py: 2,
  overflow: 'auto',
};

const closeIconStyle = { color: '#98A2AE', cursor: 'pointer' };

export function ModalPDFViewer(props: { open: boolean; onClose: () => void; title: string; pdfURL: string }) {
  const handleCloseModal = () => {
    props.onClose();
  };
  return (
    <>
      <div>
        <Modal open={props.open} sx={{ zIndex: 1000 }}>
          <Stack>
            <Stack direction='column' sx={ModalContentStyle} spacing={2}>
              <Stack direction='column'>
                <Stack justifyContent='space-between' direction='row' alignItems='center'>
                  <Typography variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
                    {props.title}
                  </Typography>
                  <IconButton onClick={handleCloseModal} component='label'>
                    <HighlightOffRoundedIcon sx={closeIconStyle} />
                  </IconButton>
                </Stack>
                <Divider sx={{ mb: 0 }} />
              </Stack>
              <Stack maxHeight='80vh' overflow='auto'>
                <PDFViewer url={props.pdfURL} />
              </Stack>
            </Stack>
          </Stack>
        </Modal>
      </div>
    </>
  );
}
