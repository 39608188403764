import qs from 'query-string';
import { AxiosResponse } from 'axios';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import axios from 'libs/Axios';

import { AxiosDefaultErrorEntity } from 'types/api/Common/ErrorTypes';
import { AutoCompleteItem } from 'types/api/Common/AutoCompleteTypes';
import {
  FunctionDefinitionDetails,
  FunctionListDefinitionItem,
  RequestCreateFunctionDefinition,
  ResponseFunctionDefinitionCubeDropdown,
} from 'types/api/SystemTenant/AROFunction/FunctionDefinitionTypes';
import { AutoCompleteWithType } from 'types/api/Tenant/AROKMS/CustomRuleTypes';

export const FUNCTION_DEFINITION_QUERY_KEY = {
  FUNCTION_DEFINITION_SUBJECT_DROPDOWN: 'FUNCTION_DEFINITION_SUBJECT_DROPDOWN',
  FUNCTION_DEFINITION_CUBE_DROPDOWN: 'FUNCTION_DEFINITION_CUBE_DROPDOWN',
  FUNCTION_DEFINITION_SUBJECT_ATTRIBUTE_DROPDOWN: 'FUNCTION_DEFINITION_SUBJECT_ATTRIBUTE_DROPDOWN',
  FUNCTION_DEFINITION_CUBE_ATTRIBUTE_DROPDOWN: 'FUNCTION_DEFINITION_CUBE_ATTRIBUTE_DROPDOWN',
  FUNCTION_DEFINITION_CUBE_LAYER_DROPDOWN: 'FUNCTION_DEFINITION_CUBE_LAYER_DROPDOWN',
  FUNCTION_DEFINITION_LIST: 'FUNCTION_DEFINITION_LIST',
  FUNCTION_DEFINITION_DETAILS: 'FUNCTION_DEFINITION_DETAILS',
};

export function useGetFunctionDefinitionSubjectDropdown() {
  return useQuery<AxiosResponse<AutoCompleteItem[]>, AxiosDefaultErrorEntity>(
    [FUNCTION_DEFINITION_QUERY_KEY.FUNCTION_DEFINITION_SUBJECT_DROPDOWN],
    () => axios.get(`/api/v1/function-definition/dropdown/subject`).then((res) => res)
  );
}
export function useGetFunctionDefinitionSubjectAttributeDropdown(subjectId?: string | null) {
  return useQuery<AxiosResponse<AutoCompleteWithType[]>, AxiosDefaultErrorEntity>(
    [FUNCTION_DEFINITION_QUERY_KEY.FUNCTION_DEFINITION_SUBJECT_ATTRIBUTE_DROPDOWN, subjectId],
    () => axios.get(`/api/v1/function-definition/dropdown/subject-attribute/${subjectId}`).then((res) => res),
    {
      enabled: !!subjectId,
    }
  );
}

export function useGetFunctionDefinitionCubeDropdown() {
  return useQuery<AxiosResponse<ResponseFunctionDefinitionCubeDropdown>, AxiosDefaultErrorEntity>(
    [FUNCTION_DEFINITION_QUERY_KEY.FUNCTION_DEFINITION_CUBE_DROPDOWN],
    () => axios.get(`/api/v1/function-definition/dropdown/cube`).then((res) => res)
  );
}
export function useGetFunctionDefinitionCubeAttributeDropdown(
  cubeId?: string | null,
  layerId?: string | null,
  editFunctionId?: string | null
) {
  return useQuery<AxiosResponse<AutoCompleteWithType[]>, AxiosDefaultErrorEntity>(
    [FUNCTION_DEFINITION_QUERY_KEY.FUNCTION_DEFINITION_CUBE_ATTRIBUTE_DROPDOWN, cubeId, layerId, editFunctionId],
    () =>
      axios
        .get(
          `/api/v1/function-definition/dropdown/cube-attribute/${cubeId}?${qs.stringify({
            cubeLayerId: layerId,
            editFunctionId,
          })}`
        )
        .then((res) => res),
    {
      enabled: !!cubeId,
    }
  );
}

export function useGetFunctionDefinitionCubeLayerDropdown(
  cubeId?: string | null,
  functionCore?: string | null,
  showAll: boolean = false
) {
  return useQuery<AxiosResponse<AutoCompleteItem[]>, AxiosDefaultErrorEntity>(
    [FUNCTION_DEFINITION_QUERY_KEY.FUNCTION_DEFINITION_CUBE_LAYER_DROPDOWN, cubeId, functionCore, showAll],
    () =>
      axios
        .get(
          `/api/v1/function-definition/dropdown/cube-layer/${cubeId}?${qs.stringify({
            functionCoreType: functionCore,
            showAll,
          })}`
        )
        .then((res) => res),
    {
      enabled: !!cubeId && !!functionCore,
    }
  );
}

export function useGetFunctionDefinitionList() {
  return useQuery<AxiosResponse<FunctionListDefinitionItem[]>, AxiosDefaultErrorEntity>(
    [FUNCTION_DEFINITION_QUERY_KEY.FUNCTION_DEFINITION_LIST],
    () => axios.get(`/api/v1/function-definition/list`).then((res) => res)
  );
}

export function useGetFunctionDefinitionDetailsById(functionId?: string) {
  return useQuery<AxiosResponse<FunctionDefinitionDetails>, AxiosDefaultErrorEntity>(
    [FUNCTION_DEFINITION_QUERY_KEY.FUNCTION_DEFINITION_DETAILS, functionId],
    () => axios.get(`/api/v1/function-definition/details/${functionId}`).then((res) => res),
    {
      enabled: !!functionId,
    }
  );
}

export function useCreateFunctionDefinition() {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosDefaultErrorEntity, RequestCreateFunctionDefinition>(
    (data) => axios.post(`/api/v1/function-definition/create`, data).then((res) => res),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(FUNCTION_DEFINITION_QUERY_KEY.FUNCTION_DEFINITION_LIST);
      },
      onError: (error, variables, ctx) => {
        queryClient.invalidateQueries(FUNCTION_DEFINITION_QUERY_KEY.FUNCTION_DEFINITION_LIST);
        queryClient.getDefaultOptions().mutations?.onError?.(error, variables, ctx);
      },
    }
  );
}

export function useDeleteFunctionDefinition() {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosDefaultErrorEntity, string>(
    (functionId) => axios.delete(`/api/v1/function-definition/${functionId}`).then((res) => res),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(FUNCTION_DEFINITION_QUERY_KEY.FUNCTION_DEFINITION_LIST);
      },
      onError: (error, variables, ctx) => {
        queryClient.invalidateQueries(FUNCTION_DEFINITION_QUERY_KEY.FUNCTION_DEFINITION_LIST);
        queryClient.getDefaultOptions().mutations?.onError?.(error, variables, ctx);
      },
    }
  );
}

export function useEditFunctionDefinition() {
  const queryClient = useQueryClient();
  return useMutation<
    AxiosResponse,
    AxiosDefaultErrorEntity,
    {
      data: RequestCreateFunctionDefinition;
      functionDefinitionId: string;
    }
  >(
    (payload) =>
      axios.put(`/api/v1/function-definition/edit/${payload.functionDefinitionId}`, payload.data).then((res) => res),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(FUNCTION_DEFINITION_QUERY_KEY.FUNCTION_DEFINITION_LIST);
      },
      onError: (error, variables, ctx) => {
        queryClient.invalidateQueries(FUNCTION_DEFINITION_QUERY_KEY.FUNCTION_DEFINITION_LIST);
        queryClient.getDefaultOptions().mutations?.onError?.(error, variables, ctx);
      },
    }
  );
}
