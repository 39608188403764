import { AxiosResponse } from 'axios';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import qs from 'query-string';
import axios from 'libs/Axios';

import {
  ServerDataUpsertRequest,
  ServerDetailsResponse,
  ServerItem,
  ServerListResponse,
} from 'types/api/TenantMaster/MasterTable/ServerTableDataTypes';
import { PaginationAndSortingParams } from 'types/api/Common/PaginationTypes';
import { AxiosDefaultErrorEntity } from 'types/api/Common/ErrorTypes';

export const SERVER_DATA_QUERY_KEY = {
  SERVER_LIST: 'SERVER_LIST',
  SERVER_DROPDOWN: 'SERVER_DROPDOWN',
  SERVER_DETAILS: 'SERVER_DETAILS',
  SERVER_MEMBER_LIST_DROPDOWN: 'SERVER_MEMBER_LIST_DROPDOWN',
};

export function useServerMemberListDropdown() {
  return useQuery<
    AxiosResponse<{ colSubjectId: string; options: { label: string; value: string }[] }>,
    AxiosDefaultErrorEntity
  >([SERVER_DATA_QUERY_KEY.SERVER_MEMBER_LIST_DROPDOWN], () =>
    axios.get(`/api/v1/master/server/member-list`).then((res) => res)
  );
}

export function useServerList(params: PaginationAndSortingParams) {
  return useQuery<AxiosResponse<ServerListResponse>, AxiosDefaultErrorEntity>(
    [SERVER_DATA_QUERY_KEY.SERVER_LIST, { ...params }],
    () =>
      axios.get(`/api/v1/master/server/list?${qs.stringify({ ...params, page: params.page - 1 })}`).then((res) => res)
  );
}

export function useServerListDropdown() {
  return useQuery<{ label: string; value: string }[], AxiosDefaultErrorEntity>(
    [SERVER_DATA_QUERY_KEY.SERVER_DROPDOWN],
    () =>
      axios
        .get(`/api/v1/master/server/list`)
        .then((res) => res.data?.data?.map((item: ServerItem) => ({ value: item.id, label: item.serverName })) || [])
  );
}

export function useTestServerConnection() {
  return useMutation<AxiosResponse, AxiosDefaultErrorEntity, ServerDataUpsertRequest>(
    (bodyRequest) => axios.post('/api/v1/master/server/test-connection', bodyRequest),
    {}
  );
}

export function useAddServer() {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosDefaultErrorEntity, ServerDataUpsertRequest>(
    (bodyRequest) => axios.post('/api/v1/master/server/upsert', bodyRequest),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(SERVER_DATA_QUERY_KEY.SERVER_LIST);
      },
    }
  );
}

export function useGetServerDetails(serverId?: string | null) {
  return useQuery<AxiosResponse<ServerDetailsResponse>, AxiosDefaultErrorEntity>(
    [SERVER_DATA_QUERY_KEY.SERVER_DETAILS, { serverId }],
    () => axios.get(`/api/v1/master/server/details/${serverId}`).then((res) => res),
    {
      enabled: !!serverId,
    }
  );
}
