import { useMemo } from 'react';
import { flattenTreeData } from 'utils/Object';
import { useGetCubeDataEntry, useGetLayerListDropdownByCube } from '../AROCube/CubeDataEntryService';
import { useGetCubeInsightViewData } from '../AROCube/CubeInsightViewService';
import { useGetCubeDimensionalViewFilterMember } from '../AROCube/CubeDimensionalViewService';
import { DIMENSION_CATEGORY } from 'types/api/Tenant/AROCube/CubeDataDimensionalViewTypes';
import { DashboardMockData } from 'types/api/Tenant/ARODashboard/DashboardDataTypes';
import { RequestCubeInsightlView } from 'types/api/Tenant/AROCube/CubeInisghtViewTypes';

export const usePrefetchDashboardData = (mockData?: DashboardMockData | null, memberId?: string | null) => {
  // Prefetch Insight and Cube Data
  const { data: memberDropdown } = useGetCubeDimensionalViewFilterMember(
    'MAIN_MEMBER',
    mockData?.cubeId,
    'MEASUREMENT_MEMBER',
    'INSIGHT_COLUMNS_MEMBER',
    DIMENSION_CATEGORY.CUBE_INSIGHT_VIEW,
    mockData?.insightId
  );

  const memberOptions = useMemo(() => {
    if (memberDropdown) {
      return flattenTreeData(memberDropdown);
    }
    return [];
  }, [memberDropdown]);
  const insightViewDimensionRequest: RequestCubeInsightlView = {
    insightViewId: mockData?.insightId,
    filterMemberValue: memberId ?? memberOptions?.[0]?.value ?? null,
    selectedXOptionType: 'MEASUREMENT_MEMBER',
    selectedYOptionType: 'INSIGHT_COLUMNS_MEMBER',
    showMemberOnly: false,
    selectedXOptionId: null,
    selectedYOptionId: null,
    cubeId: mockData?.cubeId ?? '',
  };

  const { data: layerDropdown } = useGetLayerListDropdownByCube(mockData?.cubeId);

  useGetCubeInsightViewData(insightViewDimensionRequest);

  const layerId = layerDropdown?.data?.[0]?.value;
  useGetCubeDataEntry(mockData?.cubeId, layerId, memberId ?? memberOptions?.[0]?.value ?? null);
};
