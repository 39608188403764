import { useEffect, useState } from 'react';
import { Fab } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

const fabStyle = {
  position: 'fixed',
  bottom: 10,
  textTransform: 'none',
  backgroundColor: '#3B4797',
  right: 10,
  zIndex: 1000,

  fontWeight: 600,
  fontSize: '0.8rem',
  '&:hover': {
    backgroundColor: '#42BB93',
  },
};
export function ButtonBackToTop() {
  const [showTopBtn, setShowTopBtn] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 200) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);
  return (
    <Fab
      variant='extended'
      size='small'
      color='secondary'
      sx={{ ...fabStyle, display: showTopBtn ? 'flex' : 'none' }}
      onClick={() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }}
    >
      <ArrowUpwardIcon />
      <p style={{ marginLeft: 2 }}>Back to top</p>
    </Fab>
  );
}
