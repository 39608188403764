import * as React from 'react';
import ReactRouterPrompt from 'react-router-prompt';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />;
});

interface ModalLeavePageConfirmationProps {
  when: boolean;
  message?: string;
  title?: string;
}

export function ModalLeavePageConfirmationComponent(props: ModalLeavePageConfirmationProps) {
  const {
    when,
    message = 'You have unsaved data. Are you sure you want to leave this page? Your unsaved data will be lost if you navigate away ',
    title = 'Confirm Navigation',
  } = props;

  return (
    <ReactRouterPrompt when={when}>
      {({ isActive, onConfirm, onCancel }) => (
        <Dialog
          open={isActive}
          TransitionComponent={Transition}
          keepMounted
          onClose={onCancel}
          aria-describedby='alert-dialog-slide-description'
        >
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-slide-description'>{message}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={onCancel}>Cancel</Button>
            <Button onClick={onConfirm}>Continue</Button>
          </DialogActions>
        </Dialog>
      )}
    </ReactRouterPrompt>
  );
}
