import { AxiosResponse } from 'axios';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import axios from 'libs/Axios';

import { AxiosDefaultErrorEntity } from 'types/api/Common/ErrorTypes';
import { AutoCompleteItem } from 'types/api/Common/AutoCompleteTypes';
import { AutoCompleteWithType } from 'types/api/Tenant/AROKMS/CustomRuleTypes';
import {
  RequestCreateStandardViewDefinition,
  RequestUpdateStandardViewDefinition,
  StandardViewDefinitionDetails,
  StandardViewDefinitionItem,
} from 'types/api/SystemTenant/AROView/StandardViewDefinitionTypes';

export const STANDARD_VIEW_DEFINITION_QUERY_KEY = {
  STANDARD_VIEW_DEFINITION_SUBJECT_DROPDOWN: 'STANDARD_VIEW_DEFINITION_SUBJECT_DROPDOWN',
  STANDARD_VIEW_DEFINITION_CUBE_DROPDOWN: 'STANDARD_VIEW_DEFINITION_CUBE_DROPDOWN',
  STANDARD_VIEW_DEFINITION_SUBJECT_ATTRIBUTE_DROPDOWN: 'STANDARD_VIEW_DEFINITION_SUBJECT_ATTRIBUTE_DROPDOWN',
  STANDARD_VIEW_DEFINITION_CUBE_ATTRIBUTE_DROPDOWN: 'STANDARD_VIEW_DEFINITION_CUBE_ATTRIBUTE_DROPDOWN',
  STANDARD_VIEW_DEFINITION_CUBE_LAYER_DROPDOWN: 'STANDARD_VIEW_DEFINITION_CUBE_LAYER_DROPDOWN',
  STANDARD_VIEW_DEFINITION_LIST: 'STANDARD_VIEW_DEFINITION_LIST',
  STANDARD_VIEW_DEFINITION_DETAILS: 'STANDARD_VIEW_DEFINITION_DETAILS',
};

export function useGetStandardViewDefinitionSubjectDropdown() {
  return useQuery<AxiosResponse<AutoCompleteItem[]>, AxiosDefaultErrorEntity>(
    [STANDARD_VIEW_DEFINITION_QUERY_KEY.STANDARD_VIEW_DEFINITION_SUBJECT_DROPDOWN],
    () => axios.get(`/api/v1/standard-view-definition/subject-dropdown`).then((res) => res)
  );
}
export function useGetStandardViewSubjectAttributeDropdown(subjectId?: string | null) {
  return useQuery<AxiosResponse<AutoCompleteWithType[]>, AxiosDefaultErrorEntity>(
    [STANDARD_VIEW_DEFINITION_QUERY_KEY.STANDARD_VIEW_DEFINITION_SUBJECT_ATTRIBUTE_DROPDOWN, subjectId],
    () => axios.get(`/api/v1/standard-view-definition/subject-attributes-dropdown/${subjectId}`).then((res) => res),
    {
      enabled: !!subjectId,
    }
  );
}

export function useGetStandardViewDefinitionCubeDropdown(subjectId: string) {
  return useQuery<AxiosResponse<AutoCompleteItem[]>, AxiosDefaultErrorEntity>(
    [STANDARD_VIEW_DEFINITION_QUERY_KEY.STANDARD_VIEW_DEFINITION_CUBE_DROPDOWN, subjectId],
    () => axios.get(`/api/v1/standard-view-definition/cube-dropdown/${subjectId}`).then((res) => res),
    {
      enabled: !!subjectId,
    }
  );
}

export function useGetListStandardView() {
  return useQuery<AxiosResponse<StandardViewDefinitionItem[]>, AxiosDefaultErrorEntity>(
    [STANDARD_VIEW_DEFINITION_QUERY_KEY.STANDARD_VIEW_DEFINITION_LIST],
    () => axios.get(`/api/v1/standard-view-definition/list`).then((res) => res)
  );
}
export function useGetStandardViewDefinitionCubeLayerDropdown(cubeId?: string | null) {
  return useQuery<AxiosResponse<AutoCompleteItem[]>, AxiosDefaultErrorEntity>(
    [STANDARD_VIEW_DEFINITION_QUERY_KEY.STANDARD_VIEW_DEFINITION_CUBE_LAYER_DROPDOWN, cubeId],
    () =>
      axios
        .get(`/api/v1/standard-view-definition/cube-layers-dropdown/${cubeId}?showAllIfNotFound=true`)
        .then((res) => res),
    {
      enabled: !!cubeId,
    }
  );
}
export function useGetStandardViewCubeAttributesDropdown(cubeId?: string | null) {
  return useQuery<AxiosResponse<AutoCompleteItem[]>, AxiosDefaultErrorEntity>(
    [STANDARD_VIEW_DEFINITION_QUERY_KEY.STANDARD_VIEW_DEFINITION_CUBE_ATTRIBUTE_DROPDOWN, cubeId],
    () => axios.get(`/api/v1/standard-view-definition/cube-attributes-dropdown/${cubeId}`).then((res) => res),
    {
      enabled: !!cubeId,
    }
  );
}

export function useGetStandardViewDefinitionDetails(viewId?: string | null) {
  return useQuery<AxiosResponse<StandardViewDefinitionDetails>, AxiosDefaultErrorEntity>(
    [STANDARD_VIEW_DEFINITION_QUERY_KEY.STANDARD_VIEW_DEFINITION_CUBE_LAYER_DROPDOWN, viewId],
    () => axios.get(`/api/v1/standard-view-definition/detail/${viewId}`).then((res) => res),
    {
      enabled: !!viewId,
    }
  );
}

export function useCreateStandardViewDefinition() {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosDefaultErrorEntity, RequestCreateStandardViewDefinition>(
    (data) => axios.post(`/api/v1/standard-view-definition/create`, data).then((res) => res),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(STANDARD_VIEW_DEFINITION_QUERY_KEY.STANDARD_VIEW_DEFINITION_LIST);
      },
      onError: (error, variables, ctx) => {
        queryClient.invalidateQueries(STANDARD_VIEW_DEFINITION_QUERY_KEY.STANDARD_VIEW_DEFINITION_LIST);
        queryClient.getDefaultOptions().mutations?.onError?.(error, variables, ctx);
      },
    }
  );
}

export function useEditStandardViewDefinition() {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosDefaultErrorEntity, RequestUpdateStandardViewDefinition>(
    (data) => axios.put(`/api/v1/standard-view-definition/edit/${data.id}`, data).then((res) => res),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(STANDARD_VIEW_DEFINITION_QUERY_KEY.STANDARD_VIEW_DEFINITION_LIST);
        queryClient.invalidateQueries(STANDARD_VIEW_DEFINITION_QUERY_KEY.STANDARD_VIEW_DEFINITION_DETAILS);
      },
      onError: (error, variables, ctx) => {
        queryClient.invalidateQueries(STANDARD_VIEW_DEFINITION_QUERY_KEY.STANDARD_VIEW_DEFINITION_LIST);
        queryClient.getDefaultOptions().mutations?.onError?.(error, variables, ctx);
      },
    }
  );
}

export function useDeleteStandardViewDefinition() {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosDefaultErrorEntity, string>(
    (standardViewId) => axios.delete(`/api/v1/standard-view-definition/${standardViewId}`).then((res) => res),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(STANDARD_VIEW_DEFINITION_QUERY_KEY.STANDARD_VIEW_DEFINITION_LIST);
      },
      onError: (error, variables, ctx) => {
        queryClient.invalidateQueries(STANDARD_VIEW_DEFINITION_QUERY_KEY.STANDARD_VIEW_DEFINITION_LIST);
        queryClient.getDefaultOptions().mutations?.onError?.(error, variables, ctx);
      },
    }
  );
}
