import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toggleModalSelectLanguage } from 'store/reducer/uiReducer';
import { setSelectedLanguage } from 'store/reducer/settingReducer';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import ButtonLoading from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { RootState } from 'store';
import CloseIcon from '@mui/icons-material/Close';

const ModalContentStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: 1,
  px: 3,
  py: 2,
};

const buttonActionStyle = { width: '100%' };

const closeIconStyle = { color: '#98A2AE', cursor: 'pointer' };

interface ModalImportProps {
  visible?: boolean;
}

const imageStyle = { width: 40, height: 40 };
const cardImageStyle = {
  width: '17%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  p: 1,
  cursor: 'pointer',
};

export function ModalChangeLanguageComponent(props: ModalImportProps) {
  const { selectedLanguage: selectedLanguageCode } = useSelector((state: RootState) => state.setting);
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const { visible = false } = props;

  const availableLanguages = useMemo(() => {
    return [
      { code: 'en', name: 'English', imagePath: require('../../assets/images/EN.png') },
      { code: 'jp', name: 'Japanase', imagePath: require('../../assets/images/JP.png') },
      { code: 'cn', name: 'Mandarin', imagePath: require('../../assets/images/CN.png') },
      { code: 'id', name: 'Bahasa', imagePath: require('../../assets/images/ID.png') },
    ];
  }, []);

  const [selectedLanguageItem, setSelectedLanguageItem] = useState(
    availableLanguages.find((item) => item.code === selectedLanguageCode) || availableLanguages[0]
  );

  const handleCloseModal = () => {
    dispatch(toggleModalSelectLanguage());
  };

  const handleOnApplyClick = () => {
    i18n.changeLanguage(selectedLanguageItem.code);
    dispatch(setSelectedLanguage(selectedLanguageItem.code));
    handleCloseModal();
  };

  return (
    <div>
      <Modal open={visible} onClose={handleCloseModal}>
        <Stack direction='column' sx={ModalContentStyle}>
          {/* ModalComponent Header (title and button close) */}
          <Stack justifyContent='space-between' direction='row' spacing={2}>
            <Typography variant='body1' component='h2' color='#394798'>
              {t('COMMON.MODAL_SELECT_LANGUAGE.TITLE')}
            </Typography>
            <IconButton onClick={handleCloseModal} component='label'>
              <CloseIcon sx={closeIconStyle} fontSize='small' />
            </IconButton>
          </Stack>

          <Stack alignItems='center' sx={{ py: 2, bgcolor: '#F7F7F7', mt: 2 }}>
            <Typography variant='body1'>{t('COMMON.MODAL_SELECT_LANGUAGE.DESCRIPTION')}</Typography>
          </Stack>

          <Stack alignItems='center' sx={{ py: 2, mt: 2 }}>
            <Typography variant='body1'>{selectedLanguageItem.name}</Typography>
          </Stack>

          <Stack direction='row' spacing={1.4} justifyContent='center' sx={{ mb: 4, mt: 2 }}>
            {availableLanguages.map((language) => (
              <Card
                sx={{
                  ...cardImageStyle,
                  border: language.code !== selectedLanguageItem.code ? '1px solid transparent' : '1px solid #42BB93',
                  boxShadow: language.code !== selectedLanguageItem.code ? 'none' : '0px 1px 2px #DEE8F5',
                  '&:hover': {
                    border: '1px solid #d1cfcf',
                    boxShadow: '0px 1px 2px #DEE8F5',
                  },
                }}
                onClick={() => setSelectedLanguageItem(language)}
                key={language.name}
              >
                <img style={imageStyle} src={language.imagePath} alt={language.name} />
              </Card>
            ))}
          </Stack>

          {/* Footer ButtonComponent Action */}
          <Divider sx={{ mb: 1 }} />
          <Stack direction='row' justifyContent='center' spacing={2} alignItems='center' sx={{ py: 1 }}>
            <Button variant='main-table-panel-border' sx={buttonActionStyle} onClick={handleCloseModal}>
              {t('COMMON.BUTTON.CANCEL')}
            </Button>
            <ButtonLoading variant='main-table-panel' onClick={handleOnApplyClick} sx={buttonActionStyle}>
              {t('COMMON.BUTTON.APPLY')}
            </ButtonLoading>
          </Stack>
        </Stack>
      </Modal>
    </div>
  );
}
