import { useMemo, useRef } from 'react';
import * as Yup from 'yup';
import { Formik, FormikProps } from 'formik';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import ButtonLoading from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import { CubeDefinitionRowItem } from 'types/api/SystemTenant/AROCube/CubeBuilderTypes';

const ModalContentStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  height: 300,
  overflow: 'auto',
  borderRadius: 1,
  py: 2,
};

const textInputStyles = {
  width: '100%',
  '& .MuiOutlinedInput': {
    padding: '1px 2px',
  },
};

const closeIconStyle = { color: '#98A2AE', cursor: 'pointer' };

export interface ModalCubeAttributeDisplayNameProps<T = CubeDefinitionRowItem> {
  visible?: boolean;
  onClose?: () => void;
  cubeDefinitionItem: CubeDefinitionRowItem | null;
  onSubmitParameter?: (parameterValue: T) => void;
}

type FormValues = {
  parameter: string;
};

const validationSchema = Yup.object().shape({
  parameter: Yup.string().nullable(),
});

export function ModalCubeAttributeDisplayName(props: ModalCubeAttributeDisplayNameProps) {
  const formikRef = useRef<FormikProps<FormValues>>(null);
  const { onClose, visible = false, onSubmitParameter, cubeDefinitionItem } = props;

  const handleOnCancel = () => {
    handleOnCloseModal?.();
  };

  const handleOnCloseModal = () => {
    onClose?.();
  };

  const handleOnSave = (data: FormValues) => {
    cubeDefinitionItem &&
      onSubmitParameter &&
      onSubmitParameter({
        ...cubeDefinitionItem,
        displayName: data.parameter,
      });
    handleOnCloseModal();
  };

  const formikInitialValues = useMemo(() => {
    return { parameter: cubeDefinitionItem?.displayName || '' };
  }, [cubeDefinitionItem]);

  return (
    <div>
      <Modal open={visible} onClose={handleOnCloseModal}>
        <Stack direction='column' sx={ModalContentStyle} justifyContent='space-between'>
          <Formik
            innerRef={formikRef}
            initialValues={formikInitialValues}
            validationSchema={validationSchema}
            onSubmit={handleOnSave}
          >
            {({ handleSubmit, handleChange, handleBlur, touched, errors, values }) => {
              return (
                <>
                  <Stack>
                    <Stack px={2} justifyContent='space-between' direction='row' alignItems='center'>
                      <Typography variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
                        {`Display Name for ${cubeDefinitionItem?.attribute?.label}`}
                      </Typography>
                      <IconButton onClick={handleOnCloseModal} component='label'>
                        <HighlightOffRoundedIcon sx={closeIconStyle} />
                      </IconButton>
                    </Stack>

                    <Stack
                      sx={{
                        bgcolor: '#FBFBFB',
                        py: 3,
                        px: 2,
                        borderTop: '1px solid #E3EBF6',
                      }}
                    ></Stack>
                  </Stack>

                  <Stack px={10}>
                    <Typography variant='body2' fontWeight='bold' component='h3' mb={1}>
                      {`Set Display Name for ${cubeDefinitionItem?.attribute?.label}`}
                    </Typography>
                    <TextField
                      value={values.parameter}
                      name='parameter'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      sx={textInputStyles}
                      size='small'
                      placeholder='Input Display Name'
                      type='text'
                      error={touched.parameter && Boolean(errors.parameter)}
                      helperText={touched.parameter && errors.parameter}
                    />
                  </Stack>

                  <Stack px={2}>
                    <Divider sx={{ mb: 1, mt: 2 }} />
                    <Stack direction='row' justifyContent='flex-end' spacing={2} alignItems='center' sx={{ py: 1 }}>
                      <Button variant='main-table-panel-border' onClick={handleOnCancel}>
                        Cancel
                      </Button>
                      <ButtonLoading variant='main-table-panel' onClick={() => handleSubmit()}>
                        Save
                      </ButtonLoading>
                    </Stack>
                  </Stack>
                </>
              );
            }}
          </Formik>
        </Stack>
      </Modal>
    </div>
  );
}
