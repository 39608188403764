import * as yup from 'yup';
import { useEffect } from 'react';
import { useFormik } from 'formik';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import ButtonLoading from '@mui/lab/LoadingButton';
import ActivityIndicator from 'components/ActivityIndicatorComponent';
import IconButton from '@mui/material/IconButton';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import Modal from '@mui/material/Modal';
import Checkbox from '@mui/material/Checkbox';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { useSubjectUsedDropdown } from 'services/v1/SystemTenant/AROKMS/SubjectService';
import { AutoCompleteItem } from 'types/api/Common/AutoCompleteTypes';
import { PRIMARY_KEY_TYPE, SUBJECT_TYPE } from 'constant/DataInputConstant';
import { useAttributeDropdown } from 'services/v1/SystemTenant/AROKMS/AttributeService';
import { TableDefinitionUpsertPrimaryKeySuggestionRequest } from 'types/api/SystemTenant/AROKMS/TableDefinitionTypes';
import {
  useGetTableDefinitionPrimaryKeyDetails,
  useUpsertTableDefinitionPrimaryKeySuggestion,
} from 'services/v1/SystemTenant/AROKMS/TableDefinitionPrimaryKeySuggestionService';

const ModalContentStyle = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 650,
  bgcolor: 'background.paper',
  height: 600,
  overflow: 'auto',
  borderRadius: 1,
  py: 2,
};

const autocompleteStyles = {
  width: '55%',
  '& .MuiOutlinedInput-root': {
    padding: '1px 2px',
  },
  '& .MuiAutocomplete-popper': {
    fontSize: '10px',
  },
};

const closeIconStyle = { color: '#98A2AE', cursor: 'pointer' };

const radioButtonStyle = {
  color: '#98A2AE',
  '& .MuiSvgIcon-root': {
    fontSize: 20,
  },
  '&.Mui-checked': {
    color: '#42BB93',
  },
};

const radioButtonLabelStyle = {
  '& .MuiFormControlLabel-label': {
    fontSize: '14px',
    color: '#98A2AE',
  },
};

const textInputStyles = {
  '& .MuiOutlinedInput': {
    padding: '1px 2px',
  },
};

export interface ModalConfigPrimaryKeySuggestionProps {
  visible?: boolean;
  onClose?: () => void;
  subjectId?: string | number;
}

export const TABLE_ANALYTICS_SCALE_OPTIONS = [
  {
    label: 'Dynamic Reference',
    value: PRIMARY_KEY_TYPE.DYNAMIC_REFERENCE,
  },
  {
    label: 'Static Reference',
    value: PRIMARY_KEY_TYPE.STATIC_REFERENCE,
  },
];

interface FormikInitialValues {
  subjectReference: AutoCompleteItem | null;
  attributeReference: AutoCompleteItem | null;
  attributeComparatorReference: AutoCompleteItem | null;
  currentAttributeComparator: AutoCompleteItem | null;
  active: boolean;
  primaryKeyType: string;
  firstRecordCode: string | null;
}
const formikInitialValues: FormikInitialValues = {
  subjectReference: null,
  attributeReference: null,
  attributeComparatorReference: null,
  currentAttributeComparator: null,
  active: false,
  primaryKeyType: PRIMARY_KEY_TYPE.DYNAMIC_REFERENCE,
  firstRecordCode: null,
};
const validationSchema = yup.object({
  subjectReference: yup
    .object()
    .shape({
      label: yup.string().required('Subject Reference is required'),
      value: yup.string().required('Subject Reference is required'),
    })
    .when('primaryKeyType', {
      is: PRIMARY_KEY_TYPE.DYNAMIC_REFERENCE,
      then: (schema) => schema.required('Subject Reference is required'),
      otherwise: (schema) => schema.nullable(),
    }),
  attributeReference: yup
    .object()
    .shape({
      label: yup.string().required('Attribute Reference is required'),
      value: yup.string().required('Attribute Reference is required'),
    })
    .when('primaryKeyType', {
      is: PRIMARY_KEY_TYPE.DYNAMIC_REFERENCE,
      then: (schema) => schema.required('Attribute Reference is required'),
      otherwise: (schema) => schema.nullable(),
    }),
  attributeComparatorReference: yup
    .object()
    .shape({
      label: yup.string().required('Attribute Comparator Reference is required'),
      value: yup.string().required('Attribute Comparator Reference is required'),
    })
    .when('primaryKeyType', {
      is: PRIMARY_KEY_TYPE.DYNAMIC_REFERENCE,
      then: (schema) => schema.required('Attribute Comparator Reference is required'),
      otherwise: (schema) => schema.nullable(),
    }),
  currentAttributeComparator: yup
    .object()
    .shape({
      label: yup.string().required('Current Attribute Comparator is required'),
      value: yup.string().required('Current Attribute Comparator is required'),
    })
    .when('primaryKeyType', {
      is: PRIMARY_KEY_TYPE.DYNAMIC_REFERENCE,
      then: (schema) => schema.required('Current Attribute Comparator is required'),
      otherwise: (schema) => schema.nullable(),
    }),
  firstRecordCode: yup.string().when('primaryKeyType', {
    is: PRIMARY_KEY_TYPE.STATIC_REFERENCE,
    then: (schema) => schema.required('First Record Code is required'),
    otherwise: (schema) => schema.nullable(),
  }),
});

export function ModalConfigPrimaryKeySuggestion(props: ModalConfigPrimaryKeySuggestionProps) {
  const { visible = false, onClose, subjectId } = props;

  const formik = useFormik({
    initialValues: formikInitialValues,
    validationSchema: validationSchema,
    onSubmit: () => {},
  });

  const { mutate: upsert, isLoading: isUpdating } = useUpsertTableDefinitionPrimaryKeySuggestion();

  const { data: primaryKeyDetails } = useGetTableDefinitionPrimaryKeyDetails(subjectId?.toString());

  const { data: subjectDropdownUsed, isLoading: isFetchingSubjectUsed } = useSubjectUsedDropdown({
    enabled: true,
    subjectType: SUBJECT_TYPE.TABLE,
  });

  const { data: attributeDropdownDependant, isLoading: isFetchingAttributeDependant } = useAttributeDropdown({
    tableDefinitionSubjectId: formik?.values?.subjectReference?.value,
    ruleTypeId: '3',
  });

  const { data: attributeDropdownDependantAll, isLoading: isFetchingAttributeDependentAll } = useAttributeDropdown({
    tableDefinitionSubjectId: formik?.values?.subjectReference?.value,
    ruleTypeId: undefined,
  });

  const { data: currentAttributeDropdown, isLoading: isLoadingCurrentAttributeDropdown } = useAttributeDropdown({
    tableDefinitionSubjectId: subjectId,
    ruleTypeId: undefined,
  });

  const isLoading = false;

  const handleOnSubmit = () => {
    if (!subjectId) return;

    console.log('value', JSON.stringify(formik.values));
    const { active, ...data } = formik.values;
    const payload: TableDefinitionUpsertPrimaryKeySuggestionRequest = {
      subjectId: subjectId,
      ruleTypeParams: Object.values(data)
        .map((item) => {
          return typeof item === 'object' ? item?.label : item;
        })
        .join(),
      ruleTypeParamsTableDefinitionId: Object.values(data)
        .map((item) => {
          return typeof item === 'object' && `${item?.label}=${item?.value}`;
        })
        .filter(Boolean)
        .join(),
      active,
      primaryKeyType: formik.values.primaryKeyType,
      firstRecordCode: formik.values.firstRecordCode,
    };

    upsert(payload, {
      onSuccess: () => {
        handleOnCloseModal();
      },
    });
  };

  const handleOnCloseModal = () => {
    onClose?.();
    formik.resetForm();
  };

  useEffect(() => {
    if (primaryKeyDetails?.data && visible) {
      formik.setValues(primaryKeyDetails?.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [primaryKeyDetails, visible]);

  if (isLoading) {
    return (
      <Stack sx={{ height: 400 }}>
        <ActivityIndicator />
      </Stack>
    );
  }

  console.log({
    formik,
  });
  return (
    <Modal open={visible} onClose={handleOnCloseModal}>
      <Stack direction='column' sx={ModalContentStyle}>
        <Stack px={2} justifyContent='space-between' direction='row' alignItems='center'>
          <Typography variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
            Add Primary Key Suggestion
          </Typography>
          <IconButton onClick={handleOnCloseModal} component='label'>
            <HighlightOffRoundedIcon sx={closeIconStyle} />
          </IconButton>
        </Stack>
        <Divider sx={{ mb: 2 }} />
        <div style={{ position: 'relative', overflow: 'auto', height: '100%', width: '100%' }}>
          <Stack px={2}>
            <Stack>
              <Typography variant='input-label-gray' fontSize={13}>
                Enable this if you wish to apply this primary key suggestion.
              </Typography>
              <Stack direction='row' alignItems='center'>
                <Checkbox
                  checked={formik.values.active}
                  onChange={formik.handleChange}
                  name='active'
                  sx={{
                    color: '#828C99',
                    px: 0,
                    paddingRight: 0.5,
                  }}
                />
                <Typography variant='input-label' fontWeight={800}>
                  Active
                </Typography>
              </Stack>
            </Stack>
            <Stack>
              <Typography variant='input-label-gray' fontSize={13}>
                Select primary key type
              </Typography>
              <RadioGroup
                value={formik.values.primaryKeyType}
                onChange={(event, value) => {
                  formik.setFieldValue('primaryKeyType', value);
                }}
              >
                <Stack direction='row' spacing={1}>
                  {TABLE_ANALYTICS_SCALE_OPTIONS.map((option) => (
                    <FormControlLabel
                      value={option.value}
                      sx={radioButtonLabelStyle}
                      control={<Radio sx={radioButtonStyle} />}
                      label={option.label}
                    />
                  ))}
                </Stack>
              </RadioGroup>
            </Stack>
            <Divider sx={{ my: 1 }} />

            {formik.values.primaryKeyType === PRIMARY_KEY_TYPE.DYNAMIC_REFERENCE && (
              <>
                <Stack width='70%' mb={2}>
                  <Typography variant='input-label' fontWeight={800}>
                    Select Subject Reference
                  </Typography>
                  <Typography variant='input-label-gray' fontSize={13}>
                    Choose the subject (table) from which you want to pull primary key format information.
                  </Typography>
                  <Autocomplete
                    options={subjectDropdownUsed?.data || []}
                    getOptionLabel={(option) => option.label}
                    disabled={isFetchingSubjectUsed}
                    value={formik.values.subjectReference}
                    sx={{ ...autocompleteStyles, width: '100%' }}
                    onChange={(event, newValue) => {
                      formik.setFieldValue('subjectReference', newValue);
                    }}
                    clearIcon={null}
                    renderInput={(params) => (
                      <TextField {...params} placeholder={isFetchingSubjectUsed ? 'Loading...' : 'Select Subject...'} />
                    )}
                  />
                </Stack>
                <Stack width='70%' mb={2}>
                  <Typography variant='input-label' fontWeight={800}>
                    Select Attribute Reference
                  </Typography>
                  <Typography variant='input-label-gray' fontSize={13}>
                    Select the attribute from the chosen subject that defines the primary key format.
                  </Typography>
                  <Autocomplete
                    options={attributeDropdownDependant?.data || []}
                    getOptionLabel={(option) => option.label}
                    value={formik.values.attributeReference}
                    disabled={isFetchingAttributeDependant || !formik.values.subjectReference}
                    sx={{ ...autocompleteStyles, width: '100%' }}
                    onChange={(event, newValue) => {
                      formik.setFieldValue('attributeReference', newValue);
                    }}
                    clearIcon={null}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={isFetchingAttributeDependant ? 'Loading...' : 'Select Attribute...'}
                      />
                    )}
                  />
                </Stack>
                <Stack width='70%' mb={2}>
                  <Typography variant='input-label' fontWeight={800}>
                    Select Attribute Compartor
                  </Typography>
                  <Typography variant='input-label-gray' fontSize={13}>
                    Pick an attribute from the reference subject to be used for value comparison against the current
                    subject's data.
                  </Typography>
                  <Autocomplete
                    options={attributeDropdownDependantAll?.data || []}
                    getOptionLabel={(option) => option.label}
                    value={formik.values.attributeComparatorReference}
                    disabled={isFetchingAttributeDependentAll || !formik.values.attributeReference}
                    sx={{ ...autocompleteStyles, width: '100%' }}
                    onChange={(event, newValue) => {
                      formik.setFieldValue('attributeComparatorReference', newValue);
                    }}
                    clearIcon={null}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={isFetchingAttributeDependentAll ? 'Loading...' : 'Select Attribute...'}
                      />
                    )}
                  />
                </Stack>
                <Stack width='70%' mb={2}>
                  <Typography variant='input-label' fontWeight={800}>
                    Select Current Attribute Compartor
                  </Typography>
                  <Typography variant='input-label-gray' fontSize={13}>
                    Choose the attribute from the current subject that will be compared with the value from the
                    reference subject's comparator.
                  </Typography>
                  <Autocomplete
                    options={currentAttributeDropdown?.data || []}
                    getOptionLabel={(option) => option.label}
                    value={formik.values.currentAttributeComparator}
                    sx={{ ...autocompleteStyles, width: '100%' }}
                    disabled={isLoadingCurrentAttributeDropdown || !formik.values.attributeComparatorReference}
                    onChange={(event, newValue) => {
                      formik.setFieldValue('currentAttributeComparator', newValue);
                    }}
                    clearIcon={null}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={isLoadingCurrentAttributeDropdown ? 'Loading...' : 'Select Attribute...'}
                      />
                    )}
                  />
                </Stack>
              </>
            )}

            {formik.values.primaryKeyType === PRIMARY_KEY_TYPE.STATIC_REFERENCE && (
              <Stack width='70%' mb={2}>
                <Typography variant='input-label' fontWeight={800}>
                  First Primary Key
                </Typography>
                <TextField
                  sx={textInputStyles}
                  name='firstRecordCode'
                  hiddenLabel
                  value={formik.values.firstRecordCode}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  variant='outlined'
                  size='small'
                  placeholder='e.g. A0001'
                />
              </Stack>
            )}
            <Divider sx={{ my: 1 }} />

            <Stack direction='row' px={2} justifyContent='space-between' alignItems='center'>
              <Stack />
              <Stack direction='row' gap={2}>
                <ButtonLoading variant='main-table-panel-border' disabled={isUpdating} onClick={handleOnCloseModal}>
                  Cancel
                </ButtonLoading>
                <ButtonLoading
                  variant='main-table-panel'
                  disabled={!validationSchema.isValidSync(formik.values)}
                  onClick={handleOnSubmit}
                  loading={isUpdating}
                >
                  Save
                </ButtonLoading>
              </Stack>
            </Stack>
          </Stack>
        </div>
      </Stack>
    </Modal>
  );
}
