import 'react-toastify/dist/ReactToastify.css';
import React, { useEffect } from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import { RouterProvider } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { ThemeProvider } from 'components/ThemeProvider';
import { AppRoutesElements } from './routes';
import { SplashScreenPage } from 'pages/Common/SplashScreenPage';
import { useGetMyPersonalization, useGetTenantConfigData } from 'services/v1/Tenant/ConfigurationService';
import { useDispatch } from 'react-redux';
import { setConfig, setSetupStatus } from 'store/reducer/settingReducer';
import { TENANT_CONFIGURATION_KEY } from 'constant/TenantConfigConstant';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { DASHBOARD_TYPE_CONSTANT } from 'constant/DashboardTypeConstant';

export function MainComponent() {
  const dispatch = useDispatch();
  const { selectedTenant } = useSelector((state: RootState) => state.auth);
  const splashScreenRef = React.useRef<Player | null>(null);

  const isCustomerTenant =
    selectedTenant.tenant.dashboardType === DASHBOARD_TYPE_CONSTANT.DASHBOARD_TENANT ||
    selectedTenant.tenant.dashboardType === DASHBOARD_TYPE_CONSTANT.DASHBOARD_MASTER;
  const { data: tenantConfigData } = useGetTenantConfigData({
    refetchInterval: false,
    enabled: isCustomerTenant,
  });

  useGetMyPersonalization({
    enabled: isCustomerTenant,
  });
  const [isSplashScreen, setIsSplashScreen] = React.useState(selectedTenant.roleType ? true : false);

  useEffect(() => {
    if (tenantConfigData?.data) {
      const setupStatus =
        tenantConfigData.data.find((item) => item.id === TENANT_CONFIGURATION_KEY.INITIAL_SETUP_STATUS)?.value || null;
      dispatch(setConfig(tenantConfigData.data));
      setupStatus && dispatch(setSetupStatus(setupStatus));
      splashScreenRef.current?.play();
      setTimeout(() => {
        setIsSplashScreen(false);
      }, 1500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenantConfigData]);

  if (isSplashScreen && isCustomerTenant) return <SplashScreenPage innerRef={splashScreenRef} />;

  return (
    <>
      <ToastContainer />
      <ThemeProvider>
        <RouterProvider router={AppRoutesElements} />
      </ThemeProvider>
    </>
  );
}
