import {
  GridRenderCellParams,
  useGridApiContext,
  useGridSelector,
  gridFilteredDescendantCountLookupSelector,
} from '@mui/x-data-grid-pro';
import { Box } from '@mui/material';

export function CustomGridTreeDataGroupingCell(props: GridRenderCellParams) {
  const { rowNode } = props;
  const apiRef = useGridApiContext();

  const filteredDescendantCountLookup = useGridSelector(apiRef, gridFilteredDescendantCountLookupSelector);
  const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0;

  return (
    <Box sx={{ ml: rowNode.depth * 1.2 }}>
      <div>
        {filteredDescendantCount > 0 ? (
          <span
            style={{
              fontWeight: 'bold',
            }}
          >
            {rowNode.groupingKey}
          </span>
        ) : (
          <span>{rowNode.groupingKey}</span>
        )}
      </div>
    </Box>
  );
}
