import { useMemo, useState } from 'react';

import Stack from '@mui/material/Stack';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import SyncOutlinedIcon from '@mui/icons-material/SyncOutlined';
import CloseFullscreenOutlinedIcon from '@mui/icons-material/CloseFullscreenOutlined';
import MobiledataOffOutlinedIcon from '@mui/icons-material/MobiledataOffOutlined';
import { DataGridPro, GridColDef, GridFilterModel, GridRowParams, getGridDateOperators } from '@mui/x-data-grid-pro';
import { PaginationAndSortingParams } from 'types/api/Common/PaginationTypes';
import { dayjs } from 'utils/DateUtils';
import { ENV_CONFIG } from 'config/env';
import { Button, SvgIcon, Typography } from '@mui/material';
import { useGetEventSynchronizationHistory } from 'services/v1/Tenant/AROEvent/EventDataSourceService';
import { ISynchronizeEventDataSourceHistory } from 'types/api/Tenant/EventDataSourceTypes';
import { EVENT_SYNC_DATA_SOURCE_STATUS, EVENT_SYND_DATA_SOURCE_STATUS_INFO } from 'constant/DataInputConstant';

export const EVENT_SYNC_DATA_SOURCE_STATUS_ICON = {
  [EVENT_SYNC_DATA_SOURCE_STATUS.COMPLETED]: CheckCircleOutlineOutlinedIcon,
  [EVENT_SYNC_DATA_SOURCE_STATUS.FAILED]: HighlightOffOutlinedIcon,
  [EVENT_SYNC_DATA_SOURCE_STATUS.ONGOING]: SyncOutlinedIcon,
  [EVENT_SYNC_DATA_SOURCE_STATUS.RECONNECTED]: CloseFullscreenOutlinedIcon,
  [EVENT_SYNC_DATA_SOURCE_STATUS.DISCONNECTED]: MobiledataOffOutlinedIcon,
};

const dataGridStyle = {
  '& .MuiCheckbox-root svg': {
    backgroundColor: 'transparent',
    color: '#98A2AE',
    borderRadius: 2,
  },
  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: '#EDF2FF',
    borderTop: '1px solid #E3EBF6',
    borderBottom: '1px solid #E3EBF6',
    textAlign: 'center',
    display: 'flex',
  },
};

interface PullDataSourceHistoryTableProps {
  onClickDetails: (item: ISynchronizeEventDataSourceHistory) => void;
  style?: React.CSSProperties;
  visible?: boolean;
  eventSubjectId?: string;
}

export function PullDataSourceHistoryTable(props: PullDataSourceHistoryTableProps) {
  const { onClickDetails, style, visible, eventSubjectId } = props;
  const [filter, setFilter] = useState<PaginationAndSortingParams>({
    page: 1,
    size: 10,
    filterValue: '',
    sortType: 'desc',
    sortBy: 'activityDate',
    filterColumn: 'fullName',
    filterOperator: 'contains',
  });

  const { data: userActivity, isLoading: isLoadingUserActivity } = useGetEventSynchronizationHistory(
    filter,
    visible && eventSubjectId ? eventSubjectId : undefined
  );

  // @ts-ignore
  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'lastPullDate',
        headerName: 'Synchronization Date',
        width: 200,
        valueFormatter: (params) => {
          return dayjs(params.value).format(ENV_CONFIG.config.DATE_TIME_FORMAT);
        },
        filterOperators: getGridDateOperators().filter(
          (operator) => !['onOrAfter', 'onOrBefore', 'isEmpty', 'isNotEmpty'].includes(operator.value)
        ),
      },
      {
        field: 'syncStatus',
        headerName: 'Status',
        width: 150,
        renderCell: (params: GridRowParams) => {
          return (
            <Stack
              direction='row'
              alignItems='center'
              gap={0.5}
              color={EVENT_SYND_DATA_SOURCE_STATUS_INFO.COLOR[params.row.syncStatus]}
            >
              <SvgIcon
                component={EVENT_SYNC_DATA_SOURCE_STATUS_ICON[params.row.syncStatus]}
                sx={{ fontSize: '1.3rem' }}
              />
              <Typography>{EVENT_SYND_DATA_SOURCE_STATUS_INFO.LABEL[params.row.syncStatus]}</Typography>
            </Stack>
          );
        },
      },
      {
        field: 'totalRecordCount',
        headerName: 'Total Records',
        valueFormatter: (params) => {
          return params.value?.toLocaleString();
        },
        width: 120,
      },
      {
        field: 'totalInsertedCount',
        headerName: 'Total Inserted',
        valueFormatter: (params) => {
          return params.value?.toLocaleString();
        },
        width: 120,
      },
      {
        field: 'totalFailedCount',
        headerName: 'Total Failed',
        valueFormatter: (params) => {
          return params.value?.toLocaleString();
        },
        width: 120,
      },
      {
        field: 'action',
        headerName: '',
        width: 120,
        renderCell: (params: GridRowParams) => {
          return (
            <Button
              variant='secondary-table-panel'
              color='primary'
              size='small'
              onClick={() => onClickDetails(params.row)}
              sx={{
                p: 0.5,
                height: 25,
              }}
            >
              View
            </Button>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleChangePage = (newPage: number) => {
    setFilter({ ...filter, page: newPage + 1 });
  };
  const handleChangePageSize = (newPageSize: number) => {
    setFilter({ ...filter, size: newPageSize });
  };

  const handleOnFilter = (params: GridFilterModel) => {
    let filterObj = {};
    if (params.items.length) {
      filterObj = {
        filterValue: params.items[0].value,
        filterOperator: params.items[0].operatorValue,
        filterColumn: params.items[0].columnField,
      };
    } else {
      filterObj = {
        filterValue: '',
        filterOperator: 'contains',
      };
    }
    setFilter({
      ...filter,
      ...filterObj,
    });
  };

  return (
    <Stack height={450} px={2} style={style}>
      <DataGridPro
        sx={dataGridStyle}
        autoHeight={false}
        loading={isLoadingUserActivity}
        pagination
        disableMultipleColumnsFiltering
        paginationMode='server'
        density='compact'
        pageSize={filter.size}
        rowCount={userActivity?.data?.rowCount}
        page={userActivity?.data?.page?.pageNumber || 0}
        rows={userActivity?.data.data || []}
        columns={columns}
        filterMode='server'
        checkboxSelection={false}
        onFilterModelChange={handleOnFilter}
        onPageChange={handleChangePage}
        onPageSizeChange={handleChangePageSize}
      />
    </Stack>
  );
}
