import { AxiosResponse } from 'axios';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import qs from 'query-string';
import axios from 'libs/Axios';

import { PaginationAndSortingParams } from 'types/api/Common/PaginationTypes';
import { AxiosDefaultErrorEntity } from 'types/api/Common/ErrorTypes';
import {
  DeployTenantRequest,
  TenantDetails,
  TenantListResponse,
} from 'types/api/TenantMaster/MasterTable/TenantTableDataTypes';
import { DropdownItem } from 'types/api/SystemTenant/AROKMS/TableDefinitionTypes';

export const TENANT_DATA_QUERY = {
  TENANT_LIST: 'TENANT_LIST',
  TENANT_DROPDOWN: 'TENANT_DROPDOWN',
  TENANT_DETAILS: 'TENANT_DETAILS',
  LIST_ORGANIZATION_DROPDOWN: 'LIST_ORGANIZATION_DROPDOWN',
};

export function useTenantList(params: PaginationAndSortingParams) {
  return useQuery<AxiosResponse<TenantListResponse>, AxiosDefaultErrorEntity>(
    [TENANT_DATA_QUERY.TENANT_LIST, { ...params }],
    () =>
      axios.get(`/api/v1/master/tenant/list?${qs.stringify({ ...params, page: params.page - 1 })}`).then((res) => res)
  );
}

export function useTenantDetails(tenantId?: string) {
  return useQuery<AxiosResponse<TenantDetails>, AxiosDefaultErrorEntity>(
    [TENANT_DATA_QUERY.TENANT_DETAILS, { tenantId }],
    () => axios.get(`/api/v1/master/tenant/details/${tenantId}`).then((res) => res),
    {
      enabled: !!tenantId,
    }
  );
}

export function useGetListOrganizationDropdown(systemId?: string) {
  return useQuery<AxiosResponse<DropdownItem[]>, AxiosDefaultErrorEntity>(
    [TENANT_DATA_QUERY.LIST_ORGANIZATION_DROPDOWN, { systemId }],
    () => axios.get(`/api/v1/master/tenant/dropdown/organization/${systemId}`).then((res) => res),
    {
      enabled: !!systemId,
    }
  );
}

export function useDeployTenant() {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosDefaultErrorEntity, DeployTenantRequest>(
    (bodyRequest) => axios.post('/api/v1/master/tenant/create', bodyRequest),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(TENANT_DATA_QUERY.TENANT_LIST);
        queryClient.invalidateQueries(TENANT_DATA_QUERY.TENANT_DROPDOWN);
      },
    }
  );
}

export function useUpdateTenant() {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosDefaultErrorEntity, DeployTenantRequest>(
    (bodyRequest) => axios.put(`/api/v1/master/tenant/update/${bodyRequest.id}`, bodyRequest),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(TENANT_DATA_QUERY.TENANT_LIST);
        queryClient.invalidateQueries(TENANT_DATA_QUERY.TENANT_DROPDOWN);
      },
    }
  );
}
