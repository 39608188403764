export const ENV_CONFIG = {
  endpoint: {
    API_ENDPOINT: process.env.REACT_APP_ENDPOINT_API as string,
    RUNTIME_ENDPOINT: process.env.REACT_APP_ENDPOINT_RUNTIME as string,
    MAXIMUM_CACHE_TIME: 1000 * 60 * 60 * 24,
  },
  timeout: {
    REQUEST_TIMEOOUT: process.env.REACT_APP_TIMEOUT_REQUEST as string,
  },
  license: {
    MUI_PRO: process.env.REACT_APP_LICENSE_MUI_PRO_KEY as string,
    GMAPS_API_KEY: process.env.REACT_APP_GMAPS_API_KEY as string,
  },
  config: {
    TIME_ZONE: process.env.REACT_APP_CONFIG_TIME_ZONE as string,
    DATE_FORMAT: process.env.REACT_APP_CONFIG_DATE_FORMAT as string,
    DATE_TIME_FORMAT: process.env.REACT_APP_CONFIG_DATE_TIME_FORMAT as string,
    SERVER_DATE_FORMAT: process.env.REACT_APP_CONFIG_SERVER_DATE_FORMAT as string,
    SERVER_DATE_TIME_FORMAT: process.env.REACT_APP_CONFIG_SERVER_DATE_TIME_FORMAT as string,
    REACT_APP_ENCRYPTION_KEY: process.env.REACT_APP_ENCRYPTION_KEY as string,
    SUPPORT_EMAIL: process.env.REACT_APP_SUPPORT_EMAIL as string | 'app.support@arotarget.com',
  },
};
