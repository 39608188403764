export function getColumnDataType(dataTypeCode: string) {
  switch (dataTypeCode) {
    case 'TYPE_TEXT':
      return 'text';

    case 'TYPE_NUMBER' || 'TYPE_PERCENTAGE' || 'TYPE_INTEGER' || 'TYPE_CURRENCY':
      return 'number';

    case 'TYPE_DATE':
      return 'datetime';

    default:
      return 'text';
  }
}
