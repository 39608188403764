import { toast } from 'react-toastify';
import Chip from '@mui/material/Chip';
import { useState, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootState } from 'store';
import {
  GridRowModesModel,
  GridRowModes,
  GridRowParams,
  GridSelectionModel,
  GridRowId,
  GridFilterModel,
  GridSortModel,
  getGridStringOperators,
  GridActionsCellItem,
  GridColumns,
  GridEnrichedColDef,
} from '@mui/x-data-grid-pro';

import { CustomDataTable, SETTING_PANEL_MENU_OPTIONS } from 'components/DatatableComponent';
import { MainLayoutComponent } from 'components/LayoutComponent/SidebarLayout/MainLayoutComponent';
import ModalDeleteComponent, { ModalDeleteState } from 'components/ModalComponent/ModalDeleteComponent';
import {
  useSubjectList,
  useUpsertSubjectData,
  useDeleteSubjectData,
  useExportSubjectData,
  useEditSubject,
} from 'services/v1/SystemTenant/AROKMS/SubjectService';
import { useDownloadDataImportTemplate } from 'services/v1/Misc/UploadService';

import { getErrorMessage } from 'utils/Error';
import { downloadFileFromURL, getFileName } from 'utils/DownloadFIle';
import { ModalImportComponent, ModalImportState } from 'components/ModalComponent/ModalImportComponent';
import { SubjectItem } from 'types/api/SystemTenant/AROKMS/SubjectTypes';
import { PaginationAndSortingParams } from 'types/api/Common/PaginationTypes';
import { AxiosDefaultErrorEntity } from 'types/api/Common/ErrorTypes';
import { DASHBOARD_VIEW_MODE } from 'constant/DashboardTypeConstant';
import ModalInfoComponent from 'components/ModalComponent/ModalInfoComponent';
import { SUBJECT_TYPE } from 'constant/DataInputConstant';
import { getSubjectChipColor } from 'utils/SubjectUtils';

import { renderSelectEditInputCell } from 'pages/SystemTenant/AROKMS/TableDefinition/components/SelectEditInputCell';
import { dayjs } from 'utils/DateUtils';
import { ENV_CONFIG } from 'config/env';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
// File Name for export, import, and file import template
const FILE_NAME = {
  EXPORT: 'EXPORT_SUBJECT_LIST',
  IMPORT: 'IMPORT_SUBJECT_LIST',
  TEMPLATE: 'IMPORT_TEMPLATE_SUBJECT_LIST',
};

const panelStyle = {
  transform: 'translate3d(704px, 140.667px, 0px) !important',

  '& .MuiDataGrid-panelFooter': {
    display: 'none',
  },
};

const SUBJECT_TOAST_ID = 'SUBJECT_TOAST_ID';
const SUBJECT_TYPE_OPTIONS = [
  {
    label: 'Table',
    value: SUBJECT_TYPE.TABLE,
  },
  {
    label: 'Event',
    value: SUBJECT_TYPE.EVENT,
  },
  {
    label: 'View',
    value: SUBJECT_TYPE.VIEW,
  },
];

export default function SubjectDataListPage() {
  const { dashboardViewMode } = useSelector((state: RootState) => state.auth);
  const { t } = useTranslation();
  //  Page State
  const [rowData, setRowData] = useState<SubjectItem[]>([]);
  const [modalDeleteState, setModalDeleteState] = useState<ModalDeleteState>({
    open: false,
    message: '',
    isError: false,
    errorMessage: '',
  });
  const [modalResult, setModalResult] = useState<ModalDeleteState>({ open: false, message: '' });
  const [modalImportState, setModalImportState] = useState<ModalImportState>({
    open: false,
    fileType: '',
  } as ModalImportState);
  const [selectedDataID, setSelectedDataID] = useState<GridRowId[]>([]);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [filter, setFilter] = useState<PaginationAndSortingParams>({
    page: 0,
    size: 20,
    filterValue: '',
    sortType: 'desc',
    filterOperator: 'contains',
  });
  const [filterButtonElement, setFilterButtonElement] = useState<HTMLButtonElement | null>(null);
  const [editedData, setEditedData] = useState<SubjectItem[]>([]);

  // Hook Service for API Call
  const { data: subjectList, isLoading, refetch: refreshSubjectData } = useSubjectList(filter);
  const { mutateAsync: upsertSubjectAsync } = useUpsertSubjectData(filter);
  const { mutateAsync: editSubjectAsync } = useEditSubject();
  const { mutateAsync: deleteSubjectById } = useDeleteSubjectData(filter);
  const { mutate: exportSubjectData } = useExportSubjectData();
  const { mutate: downloadDataImportTemplate } = useDownloadDataImportTemplate();

  const isBuilderViewOnly = dashboardViewMode === DASHBOARD_VIEW_MODE.VIEW_ONLY;

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const isAnyRowEdit = useMemo(() => {
    return Object.values(rowModesModel).some((item) => item.mode === GridRowModes.Edit);
  }, [rowModesModel]);
  // Table Column Definitions
  const columns = useMemo<GridColumns>(() => {
    const actionColumn: GridEnrichedColDef = {
      field: 'actions',
      type: 'actions',
      headerName: 'Edit',
      width: 30,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          // @ts-ignore
          return [<GridActionsCellItem icon={<SaveIcon />} label='Save' onClick={handleSaveClick(id)} />];
        }
        return [
          // @ts-ignore
          <GridActionsCellItem
            disabled={isAnyRowEdit}
            icon={<EditIcon />}
            label='Edit'
            onClick={handleEditClick(id)}
          />,
        ];
      },
    };
    const dataColumns: GridColumns = [
      {
        field: 'subject',
        headerName: t('SIDEBAR_MENU.SUBJECT'),
        width: 400,
        editable: true,
        filterOperators: getGridStringOperators().filter((operator) => ['contains', 'equals'].includes(operator.value)),
      },
      {
        field: 'type',
        headerName: 'Type',
        width: 200,
        editable: true,
        valueGetter: (params) => {
          return SUBJECT_TYPE_OPTIONS.find((type) => type.value === params.value) || '';
        },

        renderEditCell: (params) => {
          if (params?.row?.isNew) {
            return renderSelectEditInputCell({
              ...params,
              // @ts-ignore
              optionsSource: SUBJECT_TYPE_OPTIONS,
            });
          }
          return <span>{params.value?.label}</span>;
        },
        renderCell: (params) => {
          const value = typeof params.value === 'object' ? params.value?.label : params.value || '';

          return (
            <Chip
              label={value}
              color={getSubjectChipColor(params.value?.value)}
              variant='outlined'
              size='small'
              sx={{
                fontSize: '10px',
              }}
            />
          );
        },
      },
      {
        field: 'createdDate',
        headerName: 'Created Date',
        width: 400,
        editable: false,
        valueFormatter: (params) => {
          return params?.value ? dayjs(params.value).format(ENV_CONFIG.config.DATE_TIME_FORMAT) : '';
        },
        filterOperators: getGridStringOperators().filter((operator) => ['contains', 'equals'].includes(operator.value)),
      },
    ];

    if (isBuilderViewOnly) {
      return [...dataColumns];
    }
    return [...dataColumns, actionColumn];

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, rowModesModel, isAnyRowEdit, isBuilderViewOnly]);

  const dataWithIDSerial = useMemo(() => {
    return (
      rowData.map((item, index) => ({
        ...item,
        idSerial: (filter.page - 1) * filter.size + index + 1,
      })) || []
    );
  }, [filter, rowData]);

  // Hanlder when mutation is success
  const DataMutationSuccessHandlerOption = {
    onSuccess: () => {
      refreshSubjectData();
      toast.update(SUBJECT_TOAST_ID, {
        type: 'success',
        render: 'Your new subject was inserted successfully!',
        isLoading: false,
        autoClose: 4000,
      });
    },
    onError: (error: AxiosDefaultErrorEntity) => {
      toast.update(SUBJECT_TOAST_ID, { type: 'error', render: getErrorMessage(error), isLoading: false });
    },
  };

  useEffect(() => {
    if (subjectList?.data?.data) {
      setRowData(subjectList?.data?.data);
    }
  }, [subjectList]);

  const handleChangePage = (newPage: number) => {
    setFilter({ ...filter, page: newPage });
  };
  const handleChangePageSize = (newPageSize: number) => {
    setFilter({ ...filter, size: newPageSize });
  };

  const handleOnFilter = (params: GridFilterModel) => {
    let filterObj = {};
    if (params.items.length) {
      filterObj = {
        filterValue: params.items[0].value,
        filterOperator: params.items[0].operatorValue,
      };
    } else {
      filterObj = {
        filterValue: '',
        filterOperator: 'contains',
      };
    }
    setFilter({
      ...filter,
      ...filterObj,
    });
  };

  const handleOnModelSortChange = (model: GridSortModel) => {
    if (model.length === 0) {
      setFilter({ ...filter, sortType: 'asc' });
      return;
    }
    setFilter({ ...filter, sortType: model[0].sort });
  };

  // Process row Update will be triggered when
  // user modify or insert new row the table
  const handleProcessRowUpdate = (data: SubjectItem, oldData: SubjectItem) => {
    const isEmpty = data.subject.trim() === '' || data?.type?.value.trim() === '';
    if (isEmpty) {
      return;
    }

    // If old row data is equal to new row data, then do nothing
    // (no need to update just return the data, because nothing changed)
    if (data.subject.trim() === oldData.subject.trim()) {
      // if oldData.isNew equals to true, then it means that this is a new row
      // andl cancle the row when cursor out of focust
      if (oldData.isNew) handleCancelInsert(oldData.id);
      return oldData;
    }
    // otherwise, update the row data
    else {
      if (data.isNew) {
        // if the row is new and not empy, then insert the row to database
        toast(`Inserting new subject "${data.subject}"...`, {
          toastId: SUBJECT_TOAST_ID,
          isLoading: true,
          updateId: SUBJECT_TOAST_ID,
        });
        upsertSubjectAsync({ subject: data.subject.trim(), type: data?.type?.value }, DataMutationSuccessHandlerOption);
        return { ...data, isNew: false };
      }
      // if the row is not new, it means that the row n
      if (!data.isNew) {
        // if data already exist in state editedData, update it
        // if not, add it to editedData
        const index = editedData.findIndex((item) => item.id === data.id);
        if (index === -1) {
          setEditedData([...editedData, data]);
        } else {
          const newEditedData = [...editedData];
          newEditedData[index] = data;
          setEditedData(newEditedData);
        }
        editSubjectAsync(
          {
            id: data.id,
            subject: data.subject.trim(),
            type: data?.type?.value,
          },
          DataMutationSuccessHandlerOption
        );
        return data;
      }
    }
  };

  const handleCancelInsert = (id: number) => {
    const newRows = rowData.filter((item) => item.id !== id);

    setRowData(newRows);
  };

  const handleEditRowModes = (params: GridRowParams) => {
    if (isBuilderViewOnly || isAnyRowEdit) return;
    setRowModesModel({ ...rowModesModel, [params.id]: { mode: GridRowModes.Edit } });
  };

  const handleOnClickDeleteButton = () => {
    const selectedData = rowData.filter((item) => selectedDataID.includes(item.id));
    const isMemberListEmpty = selectedData.every((item) => item.totalMemberList === 0);
    const dataWithMemberList = selectedData.filter((item) => item.totalMemberList !== 0);
    const dataWithMemberlistString = dataWithMemberList.map((item) => item.subject).join(', ');
    setModalDeleteState({
      ...modalDeleteState,
      open: true,
      message: `Subject : ${selectedData.map((item) => item.subject).join(', ')}`,
      isError: !isMemberListEmpty,
      errorMessage: isMemberListEmpty
        ? ''
        : `The selected subject(s) (${dataWithMemberlistString}) cannot be deleted as it is being referenced by other data in the system`,
    });
  };

  const handleDeleteData = async () => {
    try {
      if (selectedDataID.length === 0) return;
      const multipleDeleteRequest = selectedDataID.map((id) => deleteSubjectById({ id }));
      const selectedData = rowData.filter((item) => selectedDataID.includes(item.id));
      await Promise.all(multipleDeleteRequest);
      setModalDeleteState({
        ...modalDeleteState,
        open: false,
        message: '',
      });
      setModalResult({
        open: true,
        message: `Successfully deleted subjects : ${selectedData.map((item) => item.subject).join(', ')}!`,
        title: 'Delete Subject',
      });
    } catch (err: any) {
      toast(getErrorMessage(err), { toastId: SUBJECT_TOAST_ID, type: 'error' });
    }
  };

  const handleOnExport = (selectedFileType: string) => {
    exportSubjectData(
      { fileType: selectedFileType },
      {
        onSuccess: (response) => {
          const blob = new Blob([response.data]);
          downloadFileFromURL(window.URL.createObjectURL(blob), getFileName(`${FILE_NAME.EXPORT}}`, selectedFileType));
        },
      }
    );
  };

  const handleOnImportButtonClick = (selectedFileType: string) => {
    setModalImportState({ ...modalImportState, fileType: selectedFileType, open: true });
  };

  const handleOnImportCompleted = () => {
    refreshSubjectData();
  };

  const handleOnTemplateClick = (selectedFileType: string) => {
    downloadDataImportTemplate(
      { category: 'subject', fileType: selectedFileType },
      {
        onSuccess: (response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          downloadFileFromURL(url, getFileName(`${FILE_NAME.TEMPLATE}`, selectedFileType));
        },
      }
    );
  };

  const menuOptionsTable = [SETTING_PANEL_MENU_OPTIONS.DENSITY, SETTING_PANEL_MENU_OPTIONS.FILTER];

  return (
    <MainLayoutComponent
      pageTitle={t('PAGE.SUBJECT.TITLE')}
      breadcrumbs={[t('SIDEBAR_MENU.DASHBOARD'), t('SIDEBAR_MENU.DATA_BUILDER')]}
    >
      <CustomDataTable
        viewOnly={isBuilderViewOnly}
        initialState={{
          pinnedColumns: {
            left: ['actions'],
          },
          pagination: {
            page: 0,
          },
          sorting: {
            sortModel: [],
          },
        }}
        loading={isLoading}
        rows={dataWithIDSerial}
        columns={columns}
        rowCount={subjectList?.data?.rowCount}
        page={filter.page || 0}
        pageSize={filter.size}
        getRowId={(row) => row.id}
        checkboxSelection={false}
        rowModesModel={rowModesModel}
        disableSelectionOnClick
        rowsPerPageOptions={[5, 10, 20]}
        getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
        experimentalFeatures={{ newEditingApi: true }}
        onFilterModelChange={handleOnFilter}
        onSortModelChange={handleOnModelSortChange}
        onPageChange={handleChangePage}
        onPageSizeChange={handleChangePageSize}
        onSelectionModelChange={(selectedId: GridSelectionModel) => {
          setSelectedDataID(selectedId);
        }}
        processRowUpdate={handleProcessRowUpdate}
        onRowDoubleClick={handleEditRowModes}
        paginationMode='client'
        sortingMode='client'
        sx={{
          '& .MuiOutlinedInput-input': {
            padding: '0px 14px',
          },
        }}
        componentsProps={{
          panel: {
            sx: panelStyle,
            anchorEl: filterButtonElement,
          },
          toolbar: {
            setRows: setRowData,
            setRowModesModel,
            onDeleteData: handleOnClickDeleteButton,
            onExport: handleOnExport,
            onButtonTemplateClick: handleOnTemplateClick,
            onImport: handleOnImportButtonClick,
            setFilterButtonElement: setFilterButtonElement,
            menuOptions: menuOptionsTable,
            isEditing: rowData.some((item) => item.isNew),
            disabled: isLoading || isBuilderViewOnly,
            disabledDeleteButton: selectedDataID.length === 0,
            config: {
              infoText:
                'Please kindly note that once subject has been inputted, it becomes permanently locked and cannot be edited or deleted.',
              deleteButtonText: 'Delete',
              fieldFocusOnInsert: 'subject',
              insertButtonText: t('PAGE.SUBJECT.BUTTON_ADD_NEW'),
              insertInitialObject: {
                subject: '',
                type: '',
                isNew: true,
              },
            },
          },
        }}
      />

      <ModalImportComponent
        key={modalImportState.fileType}
        category='subject'
        fileType={modalImportState.fileType}
        onImportSuccess={handleOnImportCompleted}
        visible={modalImportState.open}
        onClose={() => setModalImportState({ ...modalImportState, open: false })}
        onCancel={() => setModalImportState({ ...modalImportState, open: false })}
      />
      <ModalDeleteComponent
        visible={modalDeleteState.open}
        message={modalDeleteState.message}
        isError={modalDeleteState.isError}
        errorMessage={modalDeleteState.errorMessage}
        onApprove={handleDeleteData}
        onClose={() => setModalDeleteState({ ...modalDeleteState, open: false })}
        onCancel={() => setModalDeleteState({ ...modalDeleteState, open: false })}
      />
      <ModalInfoComponent
        visible={modalResult.open}
        title={modalResult.title}
        onClose={() => setModalResult({ ...modalResult, open: false })}
        descriptionMessage={modalResult.message}
      />
    </MainLayoutComponent>
  );
}
