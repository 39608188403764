import { dayjs } from 'utils/DateUtils';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';
import { getInitialsName } from 'utils/String';
import { RoleType, TenantInterface } from 'store/reducer/authReducer';
import { ROLES } from 'constant/PermissonConstant';

import { RootState } from 'store';
import { DASHBOARD_TYPE_CONSTANT } from 'constant/DashboardTypeConstant';
import { ChangeRoleComponent } from './ChangeRoleComponent';
import { Link } from '@mui/material';
import { FOOTER_MENU_OPTIONS } from 'constant/NavigationConstant';

const gridBannerStyle = {
  backgroundImage: `url('/images/auth-banner.jpg')`,
  backgroundRepeat: 'no-repeat',
  backgroundBlendMode: 'color',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  height: '100%',
};

const greetingBoldStyle = {
  marginBottom: 1,
  fontWeight: '500',
};

const gridContentContainerStyle = {
  backgroundColor: '#F8FAFF',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
};

const contentContainerStyle = {
  my: 5,
  mx: 4,
  px: 4,
  py: 4,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: '#fff',
  borderRadius: '7px',
  boxShadow: '0px 1px 6px #C8CFE2',
};

export function getRoleAccessDescription(roleType: RoleType) {
  switch (roleType) {
    case ROLES.ADMIN:
      return 'As an admin, you have the ability to view and make changes to the master database, giving you a high level of control and access to important information about your organization.';

    case ROLES.BUILDER:
      return 'As a Builder, you have access to the DEV and SYS Tenants. While the SYS Tenant can only be modified when the System Code Status is set to Changing, you have the ability to make data changes to the DEV Tenants at any time, even when the Code Status is locked.';

    case ROLES.TESTER:
      return 'As a Tester, you have the ability to access DEV Tenants and make data changes at any time. This role enables you to test and validate changes before they go live.';

    case ROLES.PACKAGER:
      return 'As a Packager, you have access to the DEM Tenant. While you are able to make data changes when the System Demo Status is set to Changing, access is limited to view-only when the status is locked.';

    case ROLES.SUPPORT:
      return "As a Support, you have the ability to make data changes to your organizatio's Tenant";

    case ROLES.MANAGER:
      return "As a Manager, you have view-only access to your organizatio's Tenant, allowing you to monitor and review the data.";
  }
}

export function getUserRoleBehaviour(selectedRole: RoleType | '', selectedTenant: TenantInterface) {
  // If selected role is "Builder" and selected tenant's dashboardType is "DASHBOARD_TENANT"
  // then we set user behaviour role as "Tester"
  // Because Builder role is allowed to access "DASHBOARD_TENANT" dashboard

  if (
    selectedRole === ROLES.BUILDER &&
    selectedTenant.tenant.dashboardType === DASHBOARD_TYPE_CONSTANT.DASHBOARD_TENANT
  ) {
    return ROLES.TESTER;
  }
  return selectedRole;
}

export function SelectRolePage(): JSX.Element {
  const { profile } = useSelector((state: RootState) => state.auth);

  const [selectedRole, setSelectedRole] = useState<RoleType>('Admin');

  return (
    <Grid container component='main' sx={{ height: '100vh' }}>
      <CssBaseline />

      <Grid
        item
        display='flex'
        flexDirection='column'
        paddingBottom={3}
        justifyContent='space-between'
        xs={5}
        sx={gridBannerStyle}
      >
        <Box width='100%' justifyContent='center' display='flex' sx={{ marginTop: 15 }}>
          <Stack direction='column' width='75%'>
            <Stack display='flex' justifyContent='center' flexDirection='row' mb={2}>
              <Avatar sx={{ bgcolor: '#00B3DC', width: 89, height: 89, fontSize: 37 }}>
                {getInitialsName(profile.name)}
              </Avatar>
            </Stack>
            <Typography component='h2' variant='h5' color='#fff' sx={greetingBoldStyle} textAlign='center'>
              {`Welcome ${profile.name}`}
            </Typography>
            <Typography color='#fff' sx={greetingBoldStyle} textAlign='center'>
              {`You are currently logged in as ${selectedRole}`}
            </Typography>

            <Typography component='p' variant='subtitle1' color='#fff'>
              {getRoleAccessDescription(selectedRole)}
            </Typography>
          </Stack>
        </Box>
        <Box width='100%' justifyContent='center' display='flex'>
          <Stack
            direction='row'
            width='75%'
            alignItems='center'
            justifyContent='flex-start'
            gap={3}
            height={80}
            divider={<Divider orientation='vertical' flexItem color='#fff' />}
          >
            <Stack direction='row' gap={1} mt={2}>
              <div>
                <img
                  src='/images/jazanz_logo.jpg'
                  alt='Jas ANZ Logo'
                  style={{
                    width: 'auto',
                    height: 60,
                  }}
                />
              </div>
              <div>
                <img
                  src='/images/iso_9001_certified.svg'
                  alt='ISO 9001 Certified'
                  style={{
                    background: 'white',
                    width: 'auto',
                    height: 60,
                  }}
                />
              </div>
            </Stack>
            <Stack>
              <Stack direction='row' spacing={2}>
                {FOOTER_MENU_OPTIONS.map((menu) => (
                  <Link
                    href={menu.link}
                    underline='hover'
                    target='_blank'
                    sx={{
                      color: '#fff',
                      fontSize: 12,
                      fontWeight: 'bold',
                      '&:hover': {
                        color: '#fff',
                      },
                    }}
                  >
                    {menu.label}
                  </Link>
                ))}
              </Stack>
              <Typography component='p' variant='subtitle1' color='#fff' sx={{ fontSize: 10, marginTop: 1 }}>
                {`©️ ${dayjs().year()} ARO Forecasting Pty Ltd All Rights Reserved.`}
              </Typography>
            </Stack>
          </Stack>
        </Box>
      </Grid>

      <Grid item xs={7} sx={gridContentContainerStyle}>
        <Box width={500} sx={contentContainerStyle}>
          <Box width={400} display='flex' justifyContent='space-between'>
            <Typography
              component='h5'
              variant='h5'
              color='#394798'
              sx={{
                marginBottom: 3,
              }}
            >
              Select Role
            </Typography>

            <div>
              <img
                src='/images/main-logo.svg'
                alt='logo'
                style={{
                  width: '100px',
                  height: 'auto',
                }}
              />
            </div>
          </Box>
          <Box width={400}>
            <ChangeRoleComponent setSelectedRoleProps={setSelectedRole} />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
