// @ts-nocheck
import Snackbar from '@mui/material/Snackbar';
import { useState, useMemo, useEffect } from 'react';
import {
  GridColDef,
  GridRowModesModel,
  GridRowModes,
  GridSelectionModel,
  GridRowId,
  GridFilterModel,
  GridSortModel,
  getGridStringOperators,
  getGridDateOperators,
} from '@mui/x-data-grid-pro';
import Alert, { AlertProps } from '@mui/material/Alert';
import qs from 'query-string';
import { CustomDataTable } from 'components/DatatableComponent';
import { PaginationAndSortingParams } from 'types/api/Common/PaginationTypes';
import { getErrorMessage, getErrorTitle } from 'utils/Error';
import { SystemItem } from 'types/api/TenantMaster/MasterTable/SystemTableDataTypes';
import { usePersonList } from 'services/v1/TenantMaster/MasterTable/PersonTableDataService';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { TenantHomeDashboardNavigationBar } from 'components/LayoutComponent/VerticalLayout/HeaderBar';
import { ModalAddPersonComponent } from '../components/ModalTablePersonData/ModalAddPersonComponent';
import { ModalEditPersonComponent } from '../components/ModalTablePersonData/ModalEditPersonComponent';
import { ButtonRecordEdit } from 'pages/Tenant/AROKMS/DataInput/components/ButtonRecordEdit';
import { useNavigate } from 'react-router';
import { PATH_CONSTANT } from 'constant/PathConstant';
import { dayjs } from 'utils/DateUtils';
import { ENV_CONFIG } from 'config/env';
import { useSearchParams } from 'react-router-dom';
import { SettingPanel } from 'pages/Tenant/AROKMS/MemberList/components/MemberListPanel';

const panelStyle = {
  transform: 'translate3d(704px, 140.667px, 0px) !important',

  '& .MuiDataGrid-panelFooter': {
    display: 'none',
  },
};

export default function PersonTableDataPage() {
  const [rowData, setRowData] = useState<SystemItem[]>([]);
  const [searchParams] = useSearchParams();
  const [showModalAddPerson, setShowModalAddPerson] = useState<boolean>(false);
  const [showModalEditPerson, setShowModalEditPerson] = useState<{
    visible: boolean;
    userId: string | null;
  }>({
    visible: false,
    userId: null,
  });
  const { profile } = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();
  const [selectedDataID, setSelectedDataID] = useState<GridRowId[]>([]);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [filter, setFilter] = useState<PaginationAndSortingParams>({
    page: searchParams.get('page') ? Number(searchParams.get('page')) : 1,
    size: searchParams.get('size') ? Number(searchParams.get('size')) : 20,
    filterValue: '',
    sortType: 'asc',
    sortBy: 'fullName',
    filterOperator: 'contains',
    filterColumn: 'fullName',
  });

  const [filterButtonElement, setFilterButtonElement] = useState<HTMLButtonElement | null>(null);
  const [snackbar, setSnackbar] = useState<Pick<AlertProps, 'children' | 'severity'> | null>(null);
  const [modalDeleteState, setModalDeleteState] = useState<ModalDeleteState>({ open: false, message: '' });

  const { data: personList, isLoading, isError, error } = usePersonList(filter);

  const columns = useMemo<GridColDef[]>(() => {
    return [
      {
        field: 'action',
        type: 'actions',
        headerName: 'Edit',
        width: 30,
        editable: false,
        filterable: false,
        renderCell: (params: GridRenderCellParams<MasterTableTenantItem>) => {
          return (
            <ButtonRecordEdit
              text='Detail'
              onClick={() => {
                navigate(
                  `${PATH_CONSTANT.TENANT_MASTER.MASTER_TABLE.EDIT_PERSON.replace(
                    ':id',
                    params.id
                  )}?refBackParam=${encodeURIComponent(window.location.pathname + window.location.search)}`
                );
              }}
            />
          );
        },
      },
      {
        field: 'person',
        headerName: 'Person',
        filterable: false,
        width: 160,
        filterOperators: getGridStringOperators().filter((operator) => ['contains', 'equals'].includes(operator.value)),
      },

      {
        field: 'fullName',
        headerName: 'Full Name',
        editable: true,
        width: 130,
        filterOperators: getGridStringOperators().filter((operator) => ['contains', 'equals'].includes(operator.value)),
      },
      {
        field: 'mobile',
        headerName: 'Mobile',
        width: 140,
        editable: true,
        filterOperators: getGridStringOperators().filter((operator) => ['contains', 'equals'].includes(operator.value)),
      },
      {
        field: 'userName',
        headerName: 'Email',
        editable: true,
        width: 170,
        filterOperators: getGridStringOperators().filter((operator) => ['contains', 'equals'].includes(operator.value)),
      },

      {
        field: 'updatedBy',
        headerName: 'Modifier',
        editable: false,
        width: 170,
        filterOperators: getGridStringOperators().filter((operator) => ['contains', 'equals'].includes(operator.value)),
      },
      {
        field: 'updatedDate',
        headerName: 'Modified Date',
        type: 'date',
        valueFormatter: (params) => {
          if (!params.value) return '';
          return `${dayjs(params.value).format(ENV_CONFIG.config.DATE_FORMAT)}`;
        },
        editable: false,
        width: 170,
        filterOperators: getGridDateOperators().filter(
          (operator) => !['onOrAfter', 'onOrBefore', 'isEmpty', 'isNotEmpty'].includes(operator.value)
        ),
      },
      {
        field: 'createdBy',
        headerName: 'Created By',
        editable: false,
        width: 170,
        filterOperators: getGridStringOperators().filter((operator) => ['contains', 'equals'].includes(operator.value)),
      },
      {
        field: 'createdDate',
        headerName: 'Created Date',
        type: 'date',
        valueFormatter: (params) => {
          if (!params.value) return '';
          return `${dayjs(params.value).format(ENV_CONFIG.config.DATE_FORMAT)}`;
        },
        editable: false,
        width: 170,
        filterOperators: getGridDateOperators().filter(
          (operator) => !['onOrAfter', 'onOrBefore', 'isEmpty', 'isNotEmpty'].includes(operator.value)
        ),
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddPerson = () => {
    navigate(PATH_CONSTANT.TENANT_MASTER.MASTER_TABLE.ADD_PERSON);
  };

  const handleOnModalAddPersonClose = () => {
    setShowModalAddPerson(false);
  };

  const handleOnModalEditPersonClose = () => {
    setShowModalEditPerson({ visible: false, userId: null });
  };

  useEffect(() => {
    if (personList?.data?.data) {
      setRowData(personList?.data?.data);
      setRowModesModel(
        personList?.data?.data?.reduce((acc, item) => {
          return { ...acc, [item.id]: GridRowModes.View };
        }, {})
      );
    }
  }, [personList]);

  const handleChangePage = (newPage: number) => {
    setFilter({ ...filter, page: newPage + 1 });
    navigate({
      search: qs.stringify({
        ...filter,
        page: newPage + 1,
      }),
    });
  };
  const handleChangePageSize = (newPageSize: number) => {
    setFilter({ ...filter, size: newPageSize });
    navigate({
      search: qs.stringify({
        ...filter,
        size: newPageSize,
        page: 1,
      }),
    });
  };

  const handleOnFilter = (params: GridFilterModel) => {
    let filterObj = {};
    if (params.items.length) {
      filterObj = {
        filterValue: params.items[0].value,
        filterOperator: params.items[0].operatorValue,
        filterColumn: params.items[0].columnField,
      };
    } else {
      filterObj = {
        filterValue: '',
        filterOperator: 'contains',
      };
    }
    setFilter({
      ...filter,
      ...filterObj,
    });
  };

  const handleOnSortModelChange = (model: GridSortModel) => {
    if (model.length === 0) {
      setFilter({ ...filter, sortType: 'asc', sortBy: 'fullName' });
      return;
    }

    setFilter({ ...filter, sortType: model[0].sort, sortBy: model[0].field });
  };

  const handleCloseSnackbar = () => setSnackbar(null);

  const handleOnClickDeleteButton = () => {
    const selectedData = rowData.filter((item) => selectedDataID.includes(item.id));
    setModalDeleteState({
      ...modalDeleteState,
      open: true,
      message: `Users : ${selectedData
        .map((item) => {
          return item.fullName;
        })
        .join(', ')}`,
    });
  };

  return (
    <TenantHomeDashboardNavigationBar>
      <CustomDataTable
        initialState={{
          pinnedColumns: {
            left: ['action'],
          },
          pagination: {
            page: 0,
          },
          sorting: {
            sortModel: [
              {
                field: 'fullName',
                sort: 'asc',
              },
            ],
          },
        }}
        isError={isError}
        errorMessage={error && getErrorMessage(error)}
        errorTitle={getErrorTitle(error)}
        loading={isLoading}
        rows={rowData}
        columns={columns}
        rowCount={personList?.data?.rowCount}
        page={personList?.data?.page?.pageNumber || 0}
        pageSize={filter.size}
        getRowId={(row) => row?.id || ''}
        rowModesModel={rowModesModel}
        checkboxSelection={false}
        isRowSelectable={(row) => row.id !== profile?.id}
        rowsPerPageOptions={[5, 10, 20]}
        getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
        experimentalFeatures={{ newEditingApi: true }}
        onFilterModelChange={handleOnFilter}
        onSortModelChange={handleOnSortModelChange}
        onPageChange={handleChangePage}
        onPageSizeChange={handleChangePageSize}
        onSelectionModelChange={(selectedId: GridSelectionModel) => {
          setSelectedDataID(selectedId);
        }}
        components={{
          Toolbar: SettingPanel,
        }}
        componentsProps={{
          panel: {
            sx: panelStyle,
            anchorEl: filterButtonElement,
          },
          toolbar: {
            setRows: setRowData,
            setRowModesModel,
            title: 'Person List',
            setFilterButtonElement: setFilterButtonElement,
            onDeleteData: handleOnClickDeleteButton,
            disabled: isLoading || rowData.some((item) => item.isNew),
            disabledDeleteButton: selectedDataID.length === 0,
            onAddDataClick: handleAddPerson,
            config: {
              withExport: false,
              deleteButtonText: 'Delete',
              onInsertClick: handleAddPerson,
              fieldFocusOnInsert: 'fullName',
              insertButtonText: 'Add New Person',
              insertInitialObject: {
                fullName: null,
                userName: null,
                person: null,
                mobile: null,
                password: null,
                isNew: true,
              },
            },
          },
        }}
      />

      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          onClose={handleCloseSnackbar}
          autoHideDuration={3000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}

      <ModalAddPersonComponent visible={showModalAddPerson} onClose={handleOnModalAddPersonClose} />
      <ModalEditPersonComponent
        visible={showModalEditPerson.visible}
        editId={showModalEditPerson.userId}
        onClose={handleOnModalEditPersonClose}
      />
    </TenantHomeDashboardNavigationBar>
  );
}
