// @ts-nocheck
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { useState, useMemo, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import Alert, { AlertProps } from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import {
  GridColDef,
  GridRowModesModel,
  GridRowEditStopParams,
  GridRowModes,
  GridRowId,
  GridRowParams,
  GridFilterModel,
  GridSelectionModel,
} from '@mui/x-data-grid-pro';
import { AutoCompleteItem } from 'types/api/Common/AutoCompleteTypes';
import { CustomDataTable } from 'components/DatatableComponent';
import { enUS } from '@mui/x-data-grid';
import {
  DisplayTableParams,
  useDataInputDownloadImportTemplate,
  useGetCurrentImportActivity,
  useGetDataInputImportHistory,
} from 'services/v1/Tenant/AROKMS/DisplayTableService';
import {
  DataInputImportActivityStatus,
  DynamicDataItem,
  EventSyncCubeActivityStatus,
} from 'types/api/Tenant/AROKMS/DisplayTableTypes';
import { AxiosDefaultErrorEntity } from 'types';
import { RulesImplementation, getFilterOperatorByRuleTypeCode } from 'config/RulesImplementation/rulesMapping';
import { convertRuleTypeParamsToSnakeCase } from 'utils/String';
import { renderTextInputEditCell } from 'components/DatatableComponent/CustomTextInput';
import { getColumnDataType } from 'config/RulesImplementation/dataTypeMapping';
import { useGetSubjectDetails } from 'services/v1/SystemTenant/AROKMS/SubjectService';
import { convertObjectToValue } from 'utils/Object';
import ModalDeleteComponent, { ModalDeleteState } from 'components/ModalComponent/ModalDeleteComponent';
import ModalLoadingComponent from 'components/ModalComponent/ModalLoadingComponent';
import { getErrorMessage, getErrorTitle } from 'utils/Error';
import { EVENT_SYNC_DATA_SOURCE_CONFIG_STATUS, LAYOUT_TABLE_VIEW_TYPE, SUBJECT_TYPE } from 'constant/DataInputConstant';
import { serializeDataInputPayload } from '../../AROKMS/DataInput/utils/serialiazePayload';
import { ButtonRecordEdit } from '../../AROKMS/DataInput/components/ButtonRecordEdit';

import { checkAccess } from 'routes/PrivateRoute';
import { ROLES } from 'constant/PermissonConstant';
import { DASHBOARD_VIEW_MODE } from 'constant/DashboardTypeConstant';
import {
  useDeleteEventData,
  useDisplayEventTable,
  useGetCurrentCubeSyncEventActivity,
  useSyncCubeEventData,
  useUpdateEventData,
  useUpsertEventTable,
} from 'services/v1/Tenant/AROEvent/EventTableService';
import { CUBE_EVENT_SYNC_STATUS } from 'constant/CubeConstant';
import { TenantHomeDashboardNavigationBar } from 'components/LayoutComponent/VerticalLayout/HeaderBar';
import { PATH_CONSTANT } from 'constant/PathConstant';
import { DataInputTableOptions, EventTableOptions } from 'components/ButtonComponent/ButtonTableOptions';
import { ModalPullEventDataSourceHistroy } from './components/ModalPullEventDataSourceHistory';
import { EventSettingPanel } from './components/EventSettingPanel';
import { useGetLastSyncHistory } from 'services/v1/Tenant/AROEvent/EventDataSourceService';
import { SystemStatusBasedAccessProvider } from 'components/RBAC/SystemStatusBasedAccessProvider';
import { setHomeNavigationState } from 'store/reducer/homeReducer';
import { useDispatch } from 'react-redux';
import {
  ModalDataInputState,
  ModalImportDataInput,
} from 'pages/Tenant/AROKMS/DataInput/components/ModalImportDataInput';
import { ModalDataImportNotification } from 'pages/Tenant/AROKMS/DataInput/components/ModalDataImportNotification';
import { isImportingData } from 'pages/Tenant/AROKMS/DataInput/utils/fileImport';
import { ModalImportDataHistory } from 'pages/Tenant/AROKMS/DataInput/components/ModalImportDataHistory';
import { downloadFileFromURL, getFileName } from 'utils/DownloadFIle';
import { ModalConnectDataChannel } from './components/DataChannel/ModalConnectDataChannel';
import { generateBreadCrumbItems } from 'utils/NavigationUtil';
import { NAVIGATION_COMPONENT_TYPE } from 'constant/NavigationConstant';
import { useGetBreadcumbItems } from 'services/v1/Common/NavBarMenuService';
import { RULE_DATA_TYPE_DATE_TIME } from 'constant/RuleConstant';
import { useSearchParams } from 'react-router-dom';

interface OptionItem {
  value: string | number;
  label: string;
}

export type ModalMappingColumnState = {
  open: boolean;
  isEdit: boolean;
  eventDataSourceConfigId: string | null;
};

const myCustomLocale = {
  ...enUS,
  dataGrid: {
    ...enUS.dataGrid,
    filterOperatorDropdownFilter: 'contains',
  },
};

const panelStyle = {
  transform: 'translate3d(704px, 140.667px, 0px) !important',

  '& .MuiDataGrid-panelFooter': {
    display: 'none',
  },
};

const modalDeleteStateInitial: ModalDeleteState = {
  message: '',
  isError: false,
  errorMessage: null,
  open: false,
};

export default function DataInputPage() {
  const navigate = useNavigate();
  const authReducer = useSelector((state: RootState) => state.auth);
  const { navigationState } = useSelector((state: RootState) => state.home);

  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const refSubjectId = searchParams.get('refSubjectId');

  const { subjectId } = useParams<{ subjectId: string }>();
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [rowData, setRowData] = useState<DynamicDataItem[]>([]);
  const [selectedSubjectOption, setSelectedSubjectOption] = useState<AutoCompleteItem>({ label: '', value: '' });
  const [initialOption] = useState<OptionItem | null>(null);
  const [modalDeleteState, setModalDeleteState] = useState<ModalDeleteState>(modalDeleteStateInitial);
  const [modalShowSyncHistory, setModalShowSyncHistory] = useState<boolean>(false);
  const [modalMappingEventDataSource, setModalMappingEventDataSource] = useState<ModalMappingColumnState>(false);
  const [filter, setFilter] = useState<DisplayTableParams>({
    page: 1,
    size: 20,
    filterValue: '',
    sortBy: undefined,
    sortType: 'asc',
    filterOperator: 'contains',
    filterColumn: undefined,
    subjectId: undefined,
  });
  const [snackbar, setSnackbar] = useState<Pick<AlertProps, 'children' | 'severity'> | null>(null);
  const [muiDataTableKey, setMuiDataTableKey] = useState<number>(0);
  const [selectedDataID, setSelectedDataID] = useState<GridRowId[]>([]);
  const [showModalImportHistory, setShowModalImportHistory] = useState<boolean>(false);
  const [importActivityStatus, setImportActivityStatus] = useState<DataInputImportActivityStatus | null>(null);
  const [showModalImportNotification, setShowModalImportNotification] = useState<boolean>(false);
  const [eventSyncCubeActivityStatus, setEventSyncCubeActivityStatus] = useState<EventSyncCubeActivityStatus | null>(
    null
  );
  const [modalImportData, setModalImportData] = useState<ModalDataInputState | null>(null);
  const {
    data: dataRecords,
    isLoading: isLoadingTable,
    isFetching: isFetchingTable,
    isError,
    refetch: refetchDataRecords,
    error,
  } = useDisplayEventTable({ subjectId });
  const { data: importActivity } = useGetCurrentImportActivity(subjectId, {
    refetchInterval: isImportingData(importActivityStatus) ? 5000 : false,
  });

  const { data: syncCubeActivityStatus, refetch: refetchCubeSyncActivity } = useGetCurrentCubeSyncEventActivity(
    subjectId,
    {
      refetchInterval: eventSyncCubeActivityStatus ? 5000 : false,
    }
  );

  const { refetch: refetchImportHistory } = useGetDataInputImportHistory(subjectId);

  const { mutate: downloadImportTemplate } = useDataInputDownloadImportTemplate();
  const [isConnectedToDataSource, setIsConnectedToDataSource] = useState<boolean>(false);
  const { data: lastSyncEventData } = useGetLastSyncHistory(subjectId, false);

  const { mutate: syncEventData, isLoading: isSyncEventData } = useSyncCubeEventData();
  const { mutate: upsertData } = useUpsertEventTable({ subjectId });
  const { mutate: deleteData } = useDeleteEventData({ subjectId });
  const { mutate: editData } = useUpdateEventData({ subjectId });
  const { data: breadCrumbItems } = useGetBreadcumbItems(
    subjectId,
    NAVIGATION_COMPONENT_TYPE.EVENT,
    refSubjectId ?? navigationState.subject?.value
  );

  const { data: subjectDetails } = useGetSubjectDetails(subjectId);

  const isTableLocked = useMemo(() => {
    if (subjectDetails?.data) {
      return subjectDetails?.data.tableDefinitionLocked;
    }
    return true;
  }, [subjectDetails]);

  const isDataEditable = useMemo(() => {
    return checkAccess(
      [ROLES.BUILDER, ROLES.ADMIN, ROLES.TESTER, ROLES.SUPPORT, ROLES.PACKAGER],
      authReducer.threadUserAsRole
    );
  }, [authReducer.threadUserAsRole]);

  const isViewOnly =
    authReducer.dashboardViewMode === DASHBOARD_VIEW_MODE.VIEW_ONLY ||
    selectedSubjectOption?.type === SUBJECT_TYPE.VIEW ||
    isTableLocked;

  const columns = useMemo<GridColDef[]>(() => {
    if (dataRecords?.data?.columns) {
      let dynamicColumns = dataRecords?.data?.columns?.map((column) => {
        const dataTypeCode = RULE_DATA_TYPE_DATE_TIME.has(column.ruleTypeCode) ? 'TYPE_DATE' : column.dataTypeCode;
        return {
          editable: true,
          field: column.field,
          type: getColumnDataType(dataTypeCode),
          headerName: column.headerName,
          width: 150,
          align: 'left',
          headerAlign: 'left',

          renderEditCell: (params) =>
            renderTextInputEditCell({ ...params, type: getColumnDataType(column.dataTypeCode) }),

          ...RulesImplementation(column, convertRuleTypeParamsToSnakeCase(column.ruleTypeParams)),

          filterOperators: getFilterOperatorByRuleTypeCode(column.ruleTypeCode),
        };
      });

      if (!isDataEditable) {
        dynamicColumns = dynamicColumns.map((column) => {
          return {
            ...column,
            editable: false,
          };
        });
      }

      if (
        dataRecords.data.layoutView === LAYOUT_TABLE_VIEW_TYPE.DEDICATED_PAGE_VIEW &&
        !isConnectedToDataSource &&
        isDataEditable
      ) {
        return [
          ...dynamicColumns,
          {
            field: 'action',
            headerName: 'Edit',
            editable: false,
            width: 30,
            renderCell: (params: GridRowParams) => (
              <SystemStatusBasedAccessProvider>
                <ButtonRecordEdit
                  {...params}
                  onClick={() => {
                    navigate(
                      PATH_CONSTANT.TENANT.EVENT.EDIT_EVENT.replace(':recordId', params.id.toString()).replace(
                        ':subjectId',
                        subjectId
                      )
                    );
                  }}
                />
              </SystemStatusBasedAccessProvider>
            ),
          },
        ];
      }

      return dynamicColumns;
    }

    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataRecords, isDataEditable, subjectId, isConnectedToDataSource]);

  const isDedicatedPageLayout = useMemo(() => {
    if (dataRecords?.data?.layoutView === LAYOUT_TABLE_VIEW_TYPE.DEDICATED_PAGE_VIEW) {
      return true;
    }
    return false;
  }, [dataRecords]);

  const isAnyRowEdit = useMemo(() => {
    return Object.values(rowModesModel).some((item) => item.mode === GridRowModes.Edit);
  }, [rowModesModel]);
  const tableTitle = useMemo(() => {
    if (!subjectDetails?.data) return '';
    return `Event: ${subjectDetails?.data?.displayName}`;
  }, [subjectDetails?.data]);

  const DataMutationSuccessHandlerOption = {
    onSuccess: () => {
      refetchDataRecords();
      setRowModesModel({});
    },
    onError: (error: AxiosDefaultErrorEntity) => {
      setSnackbar({ children: getErrorMessage(error), severity: 'error' });
      setRowModesModel({});
      refetchDataRecords();
    },
  };

  const handleOnSyncEventData = () => {
    syncEventData({
      subjectEventId: subjectId,
    });
  };

  const handleOnFilter = (params: GridFilterModel) => {
    let filterObj = {};
    if (params.items.length) {
      filterObj = {
        filterValue: params.items[0].value,
        filterOperator: params.items[0].operatorValue,
        filterColumn: params.items[0].columnField,
      };
    } else {
      filterObj = {
        filterValue: '',
        filterOperator: 'contains',
      };
    }
    setFilter({
      ...filter,
      ...filterObj,
    });
  };

  const handleCloseSnackbar = () => setSnackbar(null);

  const handleRowEditStop = (params: GridRowEditStopParams) => {
    setRowModesModel({ ...rowModesModel, [params.id]: { mode: GridRowModes.View } });
  };

  const handleEditRowModes = (params: GridRowParams) => {
    return;

    // eslint-disable-next-line no-unreachable
    if (isViewOnly || isAnyRowEdit) return;

    setRowModesModel({ ...rowModesModel, [params.id]: { mode: GridRowModes.Edit } });
  };

  const handleOnClickDeleteButton = () => {
    setModalDeleteState({
      ...modalDeleteState,
      open: true,
      message: `Are you sure you want to delete ${selectedDataID.length} data?`,
    });
  };

  const handleOnSelectionModelChange = (selectedId: GridSelectionModel) => {
    setSelectedDataID(selectedId);
  };

  const handleOnDeleteData = () => {
    if (selectedDataID.length === 0) return;
    deleteData(
      { subjectId, ids: selectedDataID },
      {
        onSuccess: () => {
          setMuiDataTableKey(muiDataTableKey + 1);
          setSelectedDataID([]);
          setModalDeleteState({ open: false, message: '', isError: false, errorMessage: null });
        },

        onError: (error: AxiosDefaultErrorEntity) => {
          setModalDeleteState({
            open: true,
            message: '',
            isError: true,
            errorMessage: getErrorMessage(error),
          });
        },
      }
    );
  };

  const handleCancelInsert = (id: number) => {
    const newRows = rowData.filter((item) => item.id !== id);
    setRowData(newRows);
    setRowModesModel((prev) => {
      const newModel = _.cloneDeep(prev);
      delete newModel[id];
      return newModel;
    });
  };

  const handleOnOptionClick = (type: EventTableOptions) => {
    if (type === EventTableOptions.CONNECT_DATA_SOURCE) {
      setModalMappingEventDataSource({
        open: true,
        isEdit: false,
        eventDataSourceConfigId: null,
      });
    }
    if (type === EventTableOptions.SYNCRONIZATION_HISTORY) {
      setModalShowSyncHistory(true);
    }
    if (type === EventTableOptions.MODIFY_DATA_SOURCE_CONNECTION) {
      setModalMappingEventDataSource({
        open: true,
        isEdit: true,
        eventDataSourceConfigId: lastSyncEventData?.data?.dataSourceConfig?.id || null,
      });
    }
    if (type === DataInputTableOptions.IMPORT_DATA_HISTORY) {
      setShowModalImportHistory(true);
    }
    if (type === EventTableOptions.MANUAL_SYNC_TO_CUBE) {
      handleOnSyncEventData();
    }
  };

  const handleProcessRowUpdate = (data: DynamicDataItem, oldData: DynamicDataItem) => {
    // If old row data is equal to new row data, then do nothing
    // (no need to update just return the data, because nothing changed)
    const { isNew, ...payloadData } = data;

    if (_.isEqual(convertObjectToValue(payloadData, 'label'), oldData)) return oldData;
    // otherwise, update the row data
    else {
      // check is empty from data and check to column whether is nullable
      const isDataEmpty = Object.keys(_.omit(data, ['id', 'isNew'])).every((key) => {
        return !data[key];
      });

      const isAnyDataEmpty = Object.keys(_.omit(data, ['id', 'isNew'])).some((key) => {
        const column = dataRecords?.data.columns.find((item) => item.field === key);
        if (column) {
          const dataEmpty = data[key] === undefined || data[key] === null || data[key] === '';
          if ((column.allowEmpty === true || column.readOnlyColumn === true) && dataEmpty) return false;
          if (column.nullable === false && dataEmpty) return true;
        }
        return false;
      });

      const dataWithRuleTypeCode = _.mapValues(payloadData, (value, key) => {
        const column = dataRecords?.data.columns.find((item) => item.field === key);
        if (column) {
          return {
            value,
            ruleTypeCode: column.ruleTypeCode,
            dropdownOptions: column.dropdownOptions,
            readOnlyColumn: column.readOnlyColumn,
          };
        }
        return { value };
      });

      const tableDefinitionIdFromColumn = _.mapValues(_.omit(payloadData, ['id', 'isNew']), (value, key) => {
        const column = dataRecords?.data.columns.find((item) => item.field === key);
        if (column) {
          return column.tableDefinitionId;
        }
      });

      if (isDataEmpty) return handleCancelInsert(data.id);

      if (!isDataEmpty && isAnyDataEmpty) return;

      if (isNew) {
        // if the row is new and not empy, then insert the row to database
        if (!isDataEmpty) {
          const insertPayload = _.omit(serializeDataInputPayload(dataWithRuleTypeCode, authReducer), ['id']);

          upsertData(
            { data: insertPayload, tableDefinitionId: tableDefinitionIdFromColumn },
            DataMutationSuccessHandlerOption
          );

          return { ...data, isNew: false };
        }
      } else {
        const updatePayload = serializeDataInputPayload(dataWithRuleTypeCode, authReducer);
        editData(
          { data: updatePayload, tableDefinitionId: tableDefinitionIdFromColumn },
          DataMutationSuccessHandlerOption
        );
        return data;
      }
    }
  };

  const handleChangePage = (newPage: number) => {
    setFilter({ ...filter, page: newPage + 1 });
  };

  const handleChangePageSize = (newPageSize: number) => {
    setFilter({ ...filter, size: newPageSize });
  };

  const handleOnTemplateClick = (selectedFileType: string) => {
    downloadImportTemplate(
      { subjectId, fileType: selectedFileType },
      {
        onSuccess: (response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));

          downloadFileFromURL(
            url,
            `ImportTemplate_${getFileName(subjectDetails?.data?.displayName, selectedFileType)}`
          );
        },
      }
    );
  };

  const handleOnImportOptionClick = (selectedFileType: string) => {
    setModalImportData({ fileType: selectedFileType, open: true });
  };
  const handleOnImportModalClose = () => {
    setModalImportData(null);
  };

  useEffect(() => {
    if (dataRecords?.data?.data) {
      setRowData(dataRecords?.data?.data);
    }
  }, [dataRecords?.data]);
  useEffect(() => {
    if (isError) setRowData([]);
  }, [isError]);

  useEffect(() => {
    if (subjectDetails?.data) {
      const subjectOption = {
        label: subjectDetails?.data?.displayName,
        value: subjectDetails?.data?.id,
      };

      setSelectedSubjectOption(subjectOption);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subjectDetails?.data]);

  useEffect(() => {
    if (breadCrumbItems?.data) {
      dispatch(setHomeNavigationState(generateBreadCrumbItems(breadCrumbItems?.data)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [breadCrumbItems?.data]);

  useEffect(() => {
    const isImporting = isImportingData(importActivity?.data?.status);
    if (!isImporting && importActivityStatus === DataInputImportActivityStatus.ON_PROGRESS) {
      refetchCubeSyncActivity();
      refetchImportHistory();
      setImportActivityStatus(null);
      refetchDataRecords();
      return;
    }

    if (isImporting) {
      setImportActivityStatus(DataInputImportActivityStatus.ON_PROGRESS);
    } else {
      refetchCubeSyncActivity();
      refetchImportHistory();
      setImportActivityStatus(null);
      refetchDataRecords();
    }
  }, [
    importActivity?.data?.status,
    importActivityStatus,
    refetchCubeSyncActivity,
    refetchImportHistory,
    refetchDataRecords,
  ]);

  useEffect(() => {
    if (syncCubeActivityStatus?.data?.status) {
      setEventSyncCubeActivityStatus(syncCubeActivityStatus?.data?.status);
    } else {
      setEventSyncCubeActivityStatus(null);
      refetchDataRecords();
    }
  }, [syncCubeActivityStatus?.data?.status, refetchDataRecords]);

  useEffect(() => {
    if (lastSyncEventData?.data) {
      setIsConnectedToDataSource(
        Boolean(
          lastSyncEventData?.data?.dataSourceConfig &&
            lastSyncEventData?.data?.dataSourceConfig.status === EVENT_SYNC_DATA_SOURCE_CONFIG_STATUS.CONNECTED
        )
      );
    } else {
      setIsConnectedToDataSource(false);
    }
  }, [lastSyncEventData?.data, isConnectedToDataSource]);

  const insertButtonText = useMemo(() => {
    if (!subjectDetails?.data) return 'Add New';
    return `Add New ${subjectDetails.data.displayName}`;
  }, [subjectDetails?.data]);

  const handleOnAddData = () => {
    navigate(PATH_CONSTANT.TENANT.EVENT.ADD_EVENT.replace(':subjectId', subjectId));
  };

  useEffect(() => {
    if (
      isDataEditable &&
      !eventSyncCubeActivityStatus &&
      (importActivity?.data?.status === DataInputImportActivityStatus.FAILED ||
        importActivity?.data?.status === DataInputImportActivityStatus.COMPLETED)
    ) {
      setShowModalImportNotification(true);
    }
  }, [importActivity?.data, isDataEditable, eventSyncCubeActivityStatus]);

  return (
    <TenantHomeDashboardNavigationBar>
      <ModalDataImportNotification
        importActivity={importActivity?.data}
        onClose={() => setShowModalImportNotification(false)}
        visible={showModalImportNotification}
        subjectDisplayName={subjectDetails?.data?.displayName}
        subjectId={subjectId}
      />
      <CustomDataTable
        key={`custom-data-table-${muiDataTableKey}-${subjectId}`}
        localeText={myCustomLocale.dataGrid}
        initialState={{
          pinnedColumns: { left: ['action'] },
          pagination: {
            page: 0,
          },
        }}
        isError={isError}
        errorMessage={error && getErrorMessage(error)}
        errorTitle={getErrorTitle(error)}
        errorData={error}
        loading={
          isLoadingTable ||
          isFetchingTable ||
          importActivityStatus === DataInputImportActivityStatus.ON_PROGRESS ||
          eventSyncCubeActivityStatus !== null
        }
        rows={rowData}
        columns={columns}
        autoHeight={true}
        page={filter.page - 1}
        pageSize={filter.size}
        getRowId={(row) => row?.id || 0}
        onPageChange={handleChangePage}
        checkboxSelection={false}
        onPageSizeChange={handleChangePageSize}
        rowModesModel={rowModesModel}
        disableSelectionOnClick
        rowsPerPageOptions={[5, 10, 20]}
        onSelectionModelChange={handleOnSelectionModelChange}
        processRowUpdate={handleProcessRowUpdate}
        onRowEditStop={handleRowEditStop}
        onRowDoubleClick={handleEditRowModes}
        getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
        experimentalFeatures={{ newEditingApi: true }}
        onFilterModelChange={handleOnFilter}
        paginationMode='client'
        editMode='row'
        sortingMode='client'
        filterMode='client'
        components={{
          Toolbar: EventSettingPanel,
        }}
        componentsProps={{
          panel: {
            sx: panelStyle,
          },
          toolbar: {
            // @ts-ignore
            viewOnly: isViewOnly,
            withChipLabel: true,
            onOptionClick: handleOnOptionClick,
            disabled: isLoadingTable || rowData.some((item) => item.isNew) || isAnyRowEdit,
            isInserting: rowData.some((item) => item.isNew),
            setRows: setRowData,
            setRowModesModel,
            title: tableTitle,
            onDeleteData: handleOnClickDeleteButton,
            options: [],
            showSyncButton: dataRecords?.data.cubeSyncStatus === CUBE_EVENT_SYNC_STATUS.REQUIRED,
            onSync: handleOnSyncEventData,
            totalDependentCubes: dataRecords?.data?.totalDependentCube,
            initialOption,
            optionValue: selectedSubjectOption,
            onAddDataClick: isDedicatedPageLayout ? () => handleOnAddData() : undefined,
            onButtonTemplateClick: handleOnTemplateClick,
            onImport: handleOnImportOptionClick,
            config: {
              deleteButtonText: 'Delete',
              fieldFocusOnInsert: '',
              insertButtonText,
              insertInitialObject: {
                ...dataRecords?.data.columnInitialValues,
                isNew: true,
              },
            },
          },
        }}
      />
      {!!snackbar && (
        <Snackbar
          open
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={3000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
      <ModalImportDataInput
        visible={modalImportData?.open}
        subject={subjectDetails?.data}
        fileType={modalImportData?.fileType}
        onClose={handleOnImportModalClose}
        onCancel={handleOnImportModalClose}
        subjectType='EVENT'
        withSyncCubeCheckbox
        onImportSuccess={handleOnImportModalClose}
      />
      <ModalImportDataHistory
        visible={showModalImportHistory}
        onClose={() => setShowModalImportHistory(false)}
        subjectId={subjectId}
      />
      <ModalDeleteComponent
        visible={modalDeleteState.open}
        message={modalDeleteState.message}
        isError={modalDeleteState.isError}
        errorMessage={modalDeleteState.errorMessage}
        onApprove={handleOnDeleteData}
        onClose={() => setModalDeleteState(modalDeleteStateInitial)}
        onCancel={() => setModalDeleteState(modalDeleteStateInitial)}
      />
      <ModalLoadingComponent
        visible={isSyncEventData}
        title='Syncing Event Data'
        descriptionMessage='Please wait while we are syncing the event data...'
      />
      <ModalConnectDataChannel
        open={modalMappingEventDataSource.open}
        isEdit={modalMappingEventDataSource.isEdit}
        eventDataSourceConfigId={modalMappingEventDataSource.eventDataSourceConfigId}
        eventSubject={subjectDetails?.data}
        onClose={() =>
          setModalMappingEventDataSource({
            eventDataSourceConfigId: null,
            isEdit: false,
            open: false,
          })
        }
      />

      <ModalPullEventDataSourceHistroy
        visible={modalShowSyncHistory}
        onClose={() => setModalShowSyncHistory(false)}
        eventSubjectId={selectedSubjectOption.value}
      />
    </TenantHomeDashboardNavigationBar>
  );
}
