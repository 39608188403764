import * as React from 'react';
import packageJson from '../../../../package.json';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { styled, alpha } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import { SvgIcon } from '@mui/material';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu, { MenuProps } from '@mui/material/Menu';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import { setTenantNavigation, toggleModalSelectLanguage } from 'store/reducer/uiReducer';
import { logout } from 'store/reducer/authReducer';
import { ReactComponent as CustomIconLogout } from 'assets/icons/icon-user-logout.svg';
import { ReactComponent as CustomIconSetting } from 'assets/icons/icon-user-setting.svg';
import { ReactComponent as CustomIconLanguage } from 'assets/icons/icon-user-language.svg';
import { RootState } from 'store';
import { Stack } from '@mui/system';
import { convertSnackCaseToTitleCase, getInitialsName } from 'utils/String';
import { useGetRoleAccess, useLogout, useSessionCheck } from 'services/v1/Auth';
import { resetHomeNavigatinState } from 'store/reducer/homeReducer';
import { useGetMyPersonalization } from 'services/v1/Tenant/ConfigurationService';

const avatarStyle = { bgcolor: '#00B3DC', width: 37, height: 37, fontSize: 15 };

export const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 280,
    p: 5,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '10px 0',
    },
    '& .MuiMenuItem-root': {
      color: '#98A2AE',
      fontSize: 13,
      '& .MuiSvgIcon-root': {
        fontSize: 16,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

interface DashboardVerticalNavigationAppBarProps {
  onChangeRoleClick?: () => void;
  onConfigurationClick?: () => void;
  onToggleQuickAccess?: () => void;
}

export function DashboardVerticalNavigationAppBar(props: DashboardVerticalNavigationAppBarProps) {
  const { onChangeRoleClick, onToggleQuickAccess, onConfigurationClick } = props;
  useSessionCheck();
  useGetRoleAccess();
  const { data: allMyViewsData } = useGetMyPersonalization({ enabled: true });
  const { t } = useTranslation();
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const dispatch = useDispatch();
  const { profile, selectedTenant, tenants } = useSelector((state: RootState) => state.auth);
  const { selectedLanguage } = useSelector((state: RootState) => state.setting);
  const { mutate: logoutUser } = useLogout();

  const handleLogoutRedirect = () => {
    dispatch(resetHomeNavigatinState());
    setTimeout(() => {
      dispatch(setTenantNavigation(false));
      dispatch(logout());
      window.location.href = '/auth/login';
    }, 200);
  };

  const handleLogout = () => {
    logoutUser(undefined, {
      onSuccess: handleLogoutRedirect,
      onError: handleLogoutRedirect,
    });
  };
  const settingMenuItems = React.useMemo(() => {
    return [
      {
        label: 'divider-2',
        isDivider: true,
        visible: true,
        icon: CustomIconLanguage,
        onClick: () => {},
      },
      {
        label: 'Change Role',
        isDivider: false,
        visible: tenants.length > 1,
        icon: CustomIconLanguage,
        onClick: () => {
          onChangeRoleClick && onChangeRoleClick();
        },
      },
      {
        label: 'divider-1',
        isDivider: true,
        visible: tenants.length > 1,
        icon: CustomIconLanguage,
        onClick: () => {},
      },
      {
        label: `${t('HEADER_MENU.MULTILANGUAGE')} - ${selectedLanguage.toUpperCase()}`,
        isDivider: false,
        visible: true,
        icon: CustomIconLanguage,
        onClick: () => {
          dispatch(toggleModalSelectLanguage());
        },
      },
      {
        label: t('HEADER_MENU.SETTING'),
        isDivider: false,
        visible: true,
        icon: CustomIconSetting,
        onClick: () => {
          onConfigurationClick && onConfigurationClick();
        },
      },
      {
        label: 'divider-0',
        isDivider: true,
        visible: true,
        icon: CustomIconLanguage,
        onClick: () => {},
      },
      {
        label: t('HEADER_MENU.LOGOUT'),
        isDivider: false,
        visible: true,
        icon: CustomIconLogout,
        onClick: handleLogout,
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, t, selectedLanguage]);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOnToggleQuickAccess = () => {
    onToggleQuickAccess && onToggleQuickAccess();
  };

  return (
    <Toolbar>
      <Box sx={{ flexGrow: 0 }}>
        <Stack direction='row' alignItems='center' gap={3}>
          {allMyViewsData?.data && allMyViewsData.data.myViewItems?.length > 0 && (
            <Tooltip title='Personal dashboards'>
              <Button
                variant='main-table-panel-border'
                onClick={handleOnToggleQuickAccess}
                startIcon={<StarBorderOutlinedIcon />}
              >
                myView
              </Button>
            </Tooltip>
          )}

          <Tooltip title='Personal settings and exit'>
            <Stack direction='row'>
              <Stack
                direction='column'
                sx={{
                  mr: 2,
                }}
              >
                <Typography variant='body2' noWrap component='div' color='#384663' sx={{ fontWeight: 600 }}>
                  {profile.name}
                </Typography>
                <Typography
                  variant='body2'
                  sx={{ display: 'flex', alignItems: 'center', fontSize: 12 }}
                  color='#667085DE'
                >
                  {convertSnackCaseToTitleCase(selectedTenant.tenant.roleName)}
                </Typography>
              </Stack>
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar sizes='' sx={avatarStyle}>
                  {getInitialsName(profile.name)}
                </Avatar>
              </IconButton>
            </Stack>
          </Tooltip>
        </Stack>
        <StyledMenu
          sx={{ mt: '45px' }}
          id='menu-appbar'
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          <Stack direction='row' px={1} mb={2}>
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar sx={{ bgcolor: '#00B3DC' }}>{getInitialsName(profile.name)}</Avatar>
            </IconButton>
            <Stack
              direction='column'
              sx={{
                ml: 2,
              }}
            >
              <Typography variant='body2' noWrap component='div' color='#384663' sx={{ fontWeight: 600 }}>
                {profile.name}
              </Typography>
              <Typography
                variant='body2'
                sx={{ display: 'flex', alignItems: 'center', fontSize: 12 }}
                color='#667085DE'
              >
                {convertSnackCaseToTitleCase(selectedTenant.tenant.roleName)}
              </Typography>
            </Stack>
          </Stack>
          {settingMenuItems.map((setting) => {
            if (!setting.visible) return <></>;
            if (setting.isDivider) {
              return (
                <Divider
                  key={setting.label}
                  sx={{
                    mb: 1,
                  }}
                />
              );
            }
            return (
              <MenuItem
                key={setting.label}
                onClick={() => {
                  handleCloseUserMenu();
                  setting?.onClick();
                }}
              >
                <SvgIcon fontSize='small' sx={{ fill: '#fff' }} inheritViewBox component={setting.icon} />
                {setting.label}
              </MenuItem>
            );
          })}
          <Divider
            sx={{
              mb: 1,
            }}
          />
          <MenuItem>
            <Typography
              variant='body2'
              textAlign='center'
              sx={{ display: 'flex', alignItems: 'center', fontSize: 12 }}
              color='#667085DE'
            >
              {`Platform Version: ${packageJson.version}`}
            </Typography>
          </MenuItem>
        </StyledMenu>
      </Box>
    </Toolbar>
  );
}
