/* eslint-disable no-useless-escape */
// @ts-nocheck
import * as Yup from 'yup';

/**
 * Form validation schema for /auth/select-role
 */

export const CubeDefinitionSettingPanelValidationSchema = Yup.object().shape({
  cubeName: Yup.string().required('Cube Name is required'),
  member: Yup.object().required('Member is required').nullable(),
  layerSet: Yup.object().required('Layer Set is required').nullable(),
  timePeriod: Yup.object().required('Time Period is required').nullable(),
});

const isValidExpression = (value: string, referenceCode: string[]) => {
  try {
    console.log({
      validExpression: value,
    });

    // eslint-disable-next-line no-new-func
    new Function(...referenceCode, `return ${value}`);
    return true;
  } catch (error) {
    return false;
  }
};

export const createCalculationRuleParametersValidationSchema = (referenceCodes, isConsolidate) => {
  return Yup.object().shape({
    parameter: Yup.string()

      .test('valid-syntax', 'Invalid calculation rule', (value) => {
        if (isConsolidate || value === 'Y' || !value) return true;

        return value && isValidExpression(value, referenceCodes);
      })

      .test('valid-reference', (value, { createError, path }) => {
        if (isConsolidate || value === 'Y' || !value) return true;
        if (!value) return false;
        const invalidLetter = value.match(/[A-Za-z]+/g).find((letter) => !referenceCodes.includes(letter));

        return invalidLetter
          ? createError({
              path,
              message: `The "${invalidLetter}" field doesn't exist`,
            })
          : true;
      })
      .test('valid-math-operation', 'Invalid math calculation', (value) => {
        if (isConsolidate || value === 'Y' || !value) return true;
        if (!value) return false;

        if (referenceCodes.includes(value)) return true;

        const regex = /[\+\-\*/\(\)]+/g;
        const operators = value.match(regex);

        if (operators) {
          const invalidParenthesesPattern = /\)[^\+\-\*/]/;
          return !invalidParenthesesPattern.test(value);
        }

        return false;
      })
      .when('_', {
        is: () => isConsolidate,
        then: Yup.string().notRequired(),
        otherwise: Yup.string().required('Required'),
      }),
  });
};

function validateFunctionInput(input, allowedFields) {
  const functions = ['A', 'B', 'S', 'P'];
  const allowedFieldsMessage = `Allowed fields are ${allowedFields.join(', ')}.`;
  const number = /^-?[0-9]+$|^[0-9]*\.[0-9]+$/;
  const mathExpression = /^([A-Za-z0-9]+[\+\-\*\/])*[A-Za-z0-9]+$/;
  const ytd = /^YTD$/;
  const tl = /^TL[0-9]{1,2}$/;
  const al = /^AL[0-9]{1,2}$/;
  const an = /^AN[0-9]{1,2}$/;
  const tn = /^TN[0-9]{1,2}$/;

  let func = input.charAt(0);
  if (!functions.includes(func)) {
    return "Invalid function. Allowed functions are 'A' = Accumulate , 'B' = Balance, 'S' = Shift, 'P' = Percent.";
  }
  let params;

  if (func === 'B' && input.includes(')-')) {
    params = input.substring(2, input.length).split(')-');
    if (params.length > 1 && !allowedFields.includes(params[1])) {
      return `Invalid field in function 'B'. Allowed fields are 'F', 'G', 'K'.`;
    }
  } else {
    params = input.substring(2, input.length - 1).split(',');
  }
  const checkFields = (param) => {
    let fields = param.split(/[\+\-\*\/]/);
    for (let field of fields) {
      if (!allowedFields.includes(field)) {
        return false;
      }
    }
    return true;
  };

  switch (func) {
    case 'A':
      if (params.length === 1) {
        if (!mathExpression.test(params[0])) {
          return "Invalid math expression in function 'A'.";
        } else if (!checkFields(params[0])) {
          return "Invalid field in function 'A'." + allowedFieldsMessage;
        }
      } else if (params.length === 2) {
        if (!allowedFields.includes(params[0])) {
          return "Invalid field in function 'A'." + allowedFieldsMessage;
        } else if (
          !(ytd.test(params[1]) || tl.test(params[1]) || tn.test(params[1]) || al.test(params[1]) || an.test(params[1]))
        ) {
          return "Invalid second parameter in function 'A'. It must be 'YTD', 'TLnn', 'TNnn','ALnn' or 'ANnn'.";
        }
      } else {
        return "Invalid number of parameters in function 'A'. It should have 1 or 2 parameters.";
      }
      break;
    case 'B':
      if (!mathExpression.test(params[0]) || !checkFields(params[0])) {
        return "Invalid math expression in function 'B'.";
      }
      break;

    case 'S':
      if (params.length === 1 || params.length === 2) {
        if (!allowedFields.includes(params[0])) {
          return "Invalid field in function 'S'." + allowedFieldsMessage;
        } else if (params.length === 2 && !(allowedFields.includes(params[1]) || number.test(params[1]))) {
          return `Invalid second parameter in function 'S'. It must be a field (${allowedFields.join(
            ', '
          )}) or a number. Negative fractional numbers are not allowed.`;
        }
      } else {
        return "Invalid number of parameters in function 'S'. It should have 1 or 2 parameters.";
      }
      break;
    case 'P':
      if (params.length === 2) {
        if (!allowedFields.includes(params[0])) {
          return "Invalid field in function 'P'. " + allowedFieldsMessage;
        } else if (!number.test(params[1])) {
          return "Invalid second parameter in function 'P'. It must be a number.";
        }
      } else {
        return "Invalid number of parameters in function 'P'. It should have 2 parameters.";
      }
      break;
    default:
      return "Invalid function. Allowed functions are 'A', 'B', 'S', 'P'.";
  }
  return true;
}
export const createCubeFunctionRuleParametersValidationSchema = (allowedFields) => {
  return Yup.object().shape({
    parameter: Yup.string()
      // .test('valid-syntax', 'Invalid function rule', (value) => value && validateFunctionInput(value) === true)
      .test('invalid-syntax', (value, { createError, path }) => {
        console.log({ validationResult: validateFunctionInput(value, allowedFields) });
        if (!value) return true;
        let validationResult = validateFunctionInput(value, allowedFields);
        if (validationResult === true) {
          return true;
        } else {
          return createError({
            path,
            message: validationResult,
          });
        }
      })
      .required('Required'),
  });
};
