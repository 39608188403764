import { dayjs } from 'utils/DateUtils';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import ActivityIndicator from 'components/ActivityIndicatorComponent';
import { TenantConfiguration } from 'types/api/Tenant/ConfigurationTypes';
import { useGetTenantConfigData, useSaveTenantConfigData } from 'services/v1/Tenant/ConfigurationService';
import { getErrorMessage } from 'utils/Error';
import { MONTH_OPTIONS } from 'constant/OptionsConstant';
import { TENANT_CONFIGURATION_KEY } from 'constant/TenantConfigConstant';
import { AutoCompleteItem } from 'types/api/Common/AutoCompleteTypes';

export const DateConfigurationPage = () => {
  const [value, setValue] = useState<TenantConfiguration[]>([]);
  const { mutate } = useSaveTenantConfigData();
  const { data, isLoading } = useGetTenantConfigData({ refetchInterval: false });
  const handleOnSubmit = () => {
    mutate(value, {
      onError: (error) => {
        toast.dismiss();
        toast.error(getErrorMessage(error));
      },
    });
  };

  const handleOnChangeInitFinancialYear = (e: React.SyntheticEvent, newValue: AutoCompleteItem | null) => {
    setValue((prev) => {
      return prev.map((item) => {
        if (item.id === TENANT_CONFIGURATION_KEY.CONFIG_INITIAL_FINANCIAL_YEAR) {
          const selectedMonth = newValue?.value ? +newValue?.value : 6;
          return {
            ...item,
            value: dayjs(item?.value)
              .set('month', selectedMonth - 1)
              .format('YYYY-MM-DD'),
          };
        }
        return item;
      });
    });
  };

  useEffect(() => {
    if (data?.data) {
      setValue(data.data);
    }
  }, [data]);

  useEffect(() => {
    if (value.length > 0) {
      handleOnSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);
  return (
    <Box sx={{ bgcolor: '#fff', px: 1, py: 2, width: '100%' }}>
      <Stack mb={2} width='100%'>
        <Typography variant='h6' color='text.primary' fontWeight='bold'>
          Date Configuration
        </Typography>
        <Divider sx={{ mt: 1 }} />
      </Stack>
      {isLoading ? (
        <Stack direction='column' justifyContent='center'>
          <ActivityIndicator />
        </Stack>
      ) : (
        <>
          <Stack direction='column' display='flex'>
            <Typography variant='h4' color='text.primary' fontWeight='bold'>
              Initial Financial Year
            </Typography>
            <Typography variant='body2' color='text.secondary'>
              Initial Financial Year is the first financial year of the company. This will be used on functions such as
              calculation Depreciation Value and Loan
            </Typography>
            <Stack mt={1}>
              <Autocomplete
                disabled
                clearIcon={false}
                value={MONTH_OPTIONS.find(
                  (item) =>
                    item.value ===
                    dayjs(
                      value.find((item) => item.id === TENANT_CONFIGURATION_KEY.CONFIG_INITIAL_FINANCIAL_YEAR)?.value
                    ).format('MM')
                )}
                onChange={handleOnChangeInitFinancialYear}
                disablePortal
                options={MONTH_OPTIONS}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} />}
              />
            </Stack>
          </Stack>
        </>
      )}
    </Box>
  );
};
