import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import ButtonLoading from '@mui/lab/LoadingButton';
import { SvgIcon } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import CloseIcon from '@mui/icons-material/Close';

import { getDropRejectErrorCode, getDropFileErrorMessageByCode } from 'utils/Error';
import { useUploadScanFileImport, useUploadFileImport } from 'services/v1/Misc/UploadService';

// Custom Icon
import { ReactComponent as CustomIconTrash } from 'assets/icons/icon-trash.svg';
import { getFileName, downloadFileFromURL } from 'utils/DownloadFIle';

const ModalContentStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: 1,
  px: 3,
  py: 2,
};

const buttonActionStyle = { width: '100%' };

const closeIconStyle = { color: '#98A2AE', cursor: 'pointer' };

interface ModalImportProps {
  visible?: boolean;
  onClose: () => void;
  onCancel?: () => void;
  onImportSuccess: () => void;
  title?: string;
  message?: string;
  isError?: boolean;
  parameter?: { [key: string]: string | number | boolean };
  errorMessage?: string;
  category: string;
  fileType?: string;
}

export type ModalImportState = {
  title?: string;
  message: string;
  fileType: string;
  open: boolean;
  isError?: boolean;
  errorMessage?: string;
};
export function ModalImportComponent(props: ModalImportProps) {
  const { t } = useTranslation();
  const { onImportSuccess, onCancel, onClose, visible = false, parameter, fileType = 'EXCEL' } = props;

  const {
    reset: resetScanning,
    mutate: scanFile,
    isLoading: isScanning,
    isSuccess: isScanningSuccess,
    data,
    isError: isScaningError,
    error: scaningError,
  } = useUploadScanFileImport({ ...parameter, fileType });

  const {
    reset: resetImporting,
    mutate: importData,
    isLoading: isImporting,
    error: errorImporting,
    isError: isErrorImporting,
  } = useUploadFileImport({ ...parameter, fileType });

  const [fileBuffer, setFileBuffer] = useState<File | null>(null);
  const [fileError, setFileError] = useState<string | null>(null);

  const onDrop = useCallback((acceptedFiles) => {
    setFileError(null);
    if (acceptedFiles.length > 0) {
      setFileBuffer(acceptedFiles[0]);
      const formData = new FormData();
      formData.append('file', acceptedFiles[0]);
      scanFile({ formData, category: props.category });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDropRejected = useCallback((rejectedFiles) => {
    setFileBuffer(null);
    setFileError(getDropFileErrorMessageByCode(getDropRejectErrorCode(rejectedFiles[0])));
  }, []);

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    onDrop,
    onDropRejected,
    multiple: false,
    maxFiles: 1,

    accept:
      fileType === 'EXCEL'
        ? {
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
            'application/vnd.ms-excel': ['.xls'],
          }
        : {
            'text/csv': ['.csv'],
          },
  });

  const resetAllState = () => {
    setFileBuffer(null);
    resetImporting();
    resetScanning();
    acceptedFiles.length = 0;
  };

  const handleCloseModal = () => {
    resetAllState();
    onClose();
  };

  const handleOnStartImport = () => {
    const formData = new FormData();
    formData.append('file', fileBuffer as Blob);
    importData(
      { formData, category: props.category },
      {
        onSuccess: () => {
          handleCloseModal();
          onImportSuccess();
        },
      }
    );
  };

  const handleOnDeleteFile = () => {
    resetAllState();
  };

  return (
    <div>
      <Modal open={visible} onClose={handleCloseModal}>
        <Stack direction='column' sx={ModalContentStyle}>
          {/* ModalComponent Header (title and button close) */}
          <Stack justifyContent='space-between' direction='row' spacing={2}>
            <Typography variant='body1' component='h2' color='#394798'>
              {t('COMMON.MODAL_IMPORT.TITLE')}
            </Typography>
            <IconButton onClick={handleCloseModal} component='label'>
              <CloseIcon sx={closeIconStyle} fontSize='small' />
            </IconButton>
          </Stack>
          {/* If server return error with file during the import, let user download the file */}
          {['application/octet-stream', 'text/csv'].includes(
            errorImporting?.response?.headers['content-type'] || ''
          ) && (
            <Stack direction='column' spacing={2}>
              <Alert severity='error'>
                <Typography variant='body2' component='p'>
                  There are some errors in the file. Please download the file and fix the errors before importing again.
                </Typography>
              </Alert>
              <Button
                variant='main-table-panel'
                endIcon={<FileDownloadOutlinedIcon />}
                onClick={() => {
                  if (errorImporting?.response?.data) {
                    // @ts-ignore
                    const url = window.URL.createObjectURL(new Blob([errorImporting?.response?.data as Blob]));
                    downloadFileFromURL(
                      url,
                      getFileName(`IMPORT_${props.category.toLocaleUpperCase()}_FAILED`, fileType)
                    );
                  }
                }}
              >
                Download Error File
              </Button>
            </Stack>
          )}
          {!fileBuffer && (
            <Stack alignItems='center' sx={{ py: 2, bgcolor: '#F7F7F7', my: 1 }}>
              <Typography variant='body2'>
                {fileType === 'CSV'
                  ? t('COMMON.MODAL_IMPORT.INSTRUCTION_CSV')
                  : t('COMMON.MODAL_IMPORT.INSTRUCTION_EXCEL')}
              </Typography>
            </Stack>
          )}
          {/* Scanning Result Message */}
          {!isErrorImporting && isScanningSuccess && (
            <Stack alignItems='center' sx={{ py: 2, bgcolor: '#F7F7F7', my: 1 }}>
              <Typography
                variant='body1'
                sx={{ color: '#42BB93' }}
              >{`We found ${data.data.totalRow} records from the file`}</Typography>
              <Typography variant='body2'>Please continue to start import</Typography>
            </Stack>
          )}
          {!isErrorImporting && isScaningError && (
            <Stack alignItems='center' sx={{ py: 2, bgcolor: '#F7F7F7', my: 1 }}>
              <Typography variant='body1' sx={{ color: '#BB4942' }}>
                Import Failed
              </Typography>
              <Typography textAlign='center' variant='body2'>
                {scaningError?.response?.data.message}
              </Typography>
            </Stack>
          )}
          {/* Upload file Area */}
          {!isErrorImporting && (
            <Stack alignItems='center' sx={{ py: 1 }}>
              {!isErrorImporting && isScanning && (
                <div className='dropzone' style={{ justifyContent: 'center' }}>
                  <CircularProgress size={24} />
                </div>
              )}
              {!fileBuffer && (
                <>
                  <div {...getRootProps({ className: 'dropzone' })}>
                    <Button
                      endIcon={<AddCircleOutlineOutlinedIcon />}
                      variant='main-table-panel'
                      sx={{ width: '35%', mr: 1 }}
                    >
                      {t('COMMON.MODAL_IMPORT.BUTTON_UPLOAD')}
                    </Button>

                    <p>
                      {fileType === 'CSV'
                        ? t('COMMON.MODAL_IMPORT.UPLOAD_AREA_CSV')
                        : t('COMMON.MODAL_IMPORT.UPLOAD_AREA_EXCEL')}
                    </p>
                    <input {...getInputProps()} />
                  </div>
                  <Typography variant='body2' sx={{ color: '#BB4942', mt: 1 }}>
                    {fileError}
                  </Typography>
                </>
              )}
              {!isScanning && fileBuffer && (
                <>
                  <div {...getRootProps({ className: 'dropzone' })}>
                    <img
                      src='/images/excel-icon.png'
                      alt='Excel File'
                      style={{
                        width: '25px',
                        height: 'auto',
                      }}
                    />
                    <p className='file-name'>{fileBuffer?.name}</p>

                    <IconButton size='small'>
                      <SvgIcon
                        fontSize='small'
                        sx={{ fill: '#42BB93' }}
                        inheritViewBox
                        component={CustomIconTrash}
                        onClick={handleOnDeleteFile}
                      />
                    </IconButton>
                  </div>
                </>
              )}
            </Stack>
          )}

          {/* Footer ButtonComponent Action */}
          <Divider sx={{ mb: 1 }} />
          <Stack direction='row' justifyContent='center' spacing={2} alignItems='center' sx={{ py: 1 }}>
            <Button
              disabled={isScanning || isImporting}
              variant='main-table-panel-border'
              sx={buttonActionStyle}
              onClick={onCancel}
            >
              {t('COMMON.BUTTON.CANCEL')}
            </Button>
            <ButtonLoading
              loading={isImporting}
              disabled={isScanning || !isScanningSuccess || isErrorImporting}
              variant='main-table-panel'
              onClick={handleOnStartImport}
              sx={buttonActionStyle}
            >
              {t('COMMON.MODAL_IMPORT.START_IMPORT')}
            </ButtonLoading>
          </Stack>
        </Stack>
      </Modal>
    </div>
  );
}
