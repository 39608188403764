import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './i18n/en';
import jp from './i18n/jp';
import id from './i18n/id';
import cn from './i18n/cn';

const isDev = process.env.NODE_ENV === 'development';

const config = {
  fallbackLng: 'en',
  debug: isDev,
  resources: {
    en,
    jp,
    id,
    cn,
  },
};

i18next.use(LanguageDetector).init(config);

export default i18next;
