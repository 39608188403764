export const CUBE_LAYER_TYPE = {
  BASELINE: 'BASELINE',
  ACTUAL: 'ACTUAL',
  GENERAL: 'GENERAL',
};
export const CUBE_PERCENTAGE_TYPE = {
  PERCENTAGE: 'PERCENTAGE',
  HEADING: 'HEADING',
};

export const CUBE_SYNC_STATUS = {
  REQUIRED: 'REQUIRED',
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETED: 'COMPLETED',
  FAILED: 'FAILED',
};

export const CUBE_EVENT_SYNC_STATUS = {
  REQUIRED: 'REQUIRED',
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETED: 'COMPLETED',
  FAILED: 'FAILED',
};

export const CUBE_ACTIVITY_STATUS = {
  READY: 'READY',
  CALCULATING: 'CALCULATING',
  RECALCULATING: 'RECALCULATING',
  CALCULATION_FAILED: 'CALCULATION_FAILED',
};

export const CUBE_TIME_PERIOD_TYPE = {
  YEAR: 'YEAR',
  QUARTER: 'QUARTER',
  MONTH: 'MONTH',
  WEEK: 'WEEK',
};
export const CUBE_PULL_TOTALING_RULE = {
  LAST_WEEK_IN_MONTH: 'LAST_WEEK_IN_MONTH',
  ALL_WEEKS_IN_MONTH: 'ALL_WEEKS_IN_MONTH',
  ALL_WEEKS_IN_QUARTER: 'ALL_WEEKS_IN_QUARTER',
  ALL_WEEKS_IN_YEAR: 'ALL_WEEKS_IN_YEAR',
};
