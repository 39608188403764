import { useState, useRef, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import { Box, Grid, Stack, Typography } from '@mui/material';
import ButtonLoading from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Formik, FormikProps } from 'formik';
import { TenantHomeDashboardNavigationBar } from 'components/LayoutComponent/VerticalLayout/HeaderBar';
import { ButtonBackToTop } from 'components/ButtonComponent/ButtonBackToTop';
import { EditPersonValidationSchema } from 'config/FormValidaton/TenantMaster/MasterTable/PersonTableValidationSchema';
import { PersonUsertRequest } from 'types/api/TenantMaster/MasterTable/PersonTableDataTypes';
import {
  useDeletePerson,
  usePersonDetails,
  useUpdatePerson,
} from 'services/v1/TenantMaster/MasterTable/PersonTableDataService';
import { PATH_CONSTANT } from 'constant/PathConstant';
import LockResetOutlinedIcon from '@mui/icons-material/LockResetOutlined';
import { useParams, useSearchParams } from 'react-router-dom';
import { ModalResetPassword } from '../../components/ModalTablePersonData/ModalResetPasswordPersonComponent';
import ModalDeleteComponent, { ModalDeleteState } from '../../../../components/ModalComponent/ModalDeleteComponent';
import { getErrorMessage } from 'utils/Error';
import ActivityIndicator from 'components/ActivityIndicatorComponent';

const textInputStyles = {
  width: '55%',
  '& .MuiOutlinedInput': {
    padding: '1px 2px',
  },
};

interface FormValue {
  person: string;
  fullName: string;
  mobile: string;
  email: string;
}

const formInitialValues: FormValue = {
  person: '',
  fullName: '',
  mobile: '',
  email: '',
};

export default function EditPersonTableData() {
  const { id } = useParams();
  const formikRef = useRef<FormikProps<FormValue>>(null);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { mutate: updatePerson, isLoading: isUpdating } = useUpdatePerson();
  const [showModalResetPassword, setShowModalResetPassword] = useState(false);
  const [modalDeleteState, setModalDeleteState] = useState<ModalDeleteState>({
    message: 'Are you sure you want to delete this data?',
    open: false,
  });
  const { data: personDetailsData, isLoading } = usePersonDetails(id || undefined);
  const { mutate: deletePerson } = useDeletePerson();

  const handleOnOpenModalResetPassword = () => {
    setShowModalResetPassword(true);
  };
  const handleOnSave = (data: FormValue) => {
    const payload: PersonUsertRequest = {
      id: id || '',
      fullName: data.fullName,
      mobile: data.mobile,
      userName: data.email,
      person: data.person,
    };

    updatePerson(payload, {
      onSuccess: () => {
        toast.success('Data submitted successfully!');
        formikRef.current?.resetForm();
        handleOnCancelInput();
      },
    });
  };

  const isSemiLoading = isUpdating;

  const handleOnCancelInput = () => {
    const url =
      searchParams.get('refBackParam') != null
        ? searchParams.get('refBackParam')
        : PATH_CONSTANT.TENANT_MASTER.MASTER_TABLE.PERSON;
    const redirectPath = decodeURIComponent(url as string);
    navigate(redirectPath);
  };

  const handleOnCloseModalResetPassword = () => {
    setShowModalResetPassword(false);
  };

  const handleOnDeleteData = () => {
    if (id) {
      deletePerson(
        { userId: id },
        {
          onSuccess: () => {
            toast.success('Data deleted successfully!');
            navigate(PATH_CONSTANT.TENANT_MASTER.MASTER_TABLE.PERSON);
          },
          onError: (error) => {
            setModalDeleteState({
              open: true,
              message: '',
              isError: true,
              errorMessage: getErrorMessage(error),
            });
          },
        }
      );
    }
  };

  const handleOnShowDeleteConfirmation = () => {
    const message = `Are you sure you want to delete <strong>Person ${personDetailsData?.data?.person}</strong>?`;
    setModalDeleteState({ ...modalDeleteState, open: true, message });
  };

  useEffect(() => {
    if (personDetailsData) {
      formikRef.current?.setValues({
        person: personDetailsData.data.person,
        fullName: personDetailsData.data.fullName,
        mobile: personDetailsData.data.mobile,
        email: personDetailsData.data.userName,
      });
    }
  }, [personDetailsData]);

  return (
    <TenantHomeDashboardNavigationBar>
      <ButtonBackToTop />
      <Box sx={{ height: '100%' }}>
        {isLoading ? (
          <Stack direction='column' sx={{ height: 500 }} justifyContent='center'>
            <ActivityIndicator />
          </Stack>
        ) : (
          <Formik
            innerRef={formikRef}
            initialValues={formInitialValues}
            validationSchema={EditPersonValidationSchema}
            onSubmit={handleOnSave}
          >
            {(formikProps: FormikProps<{ [key: string]: string }>) => {
              const { values, handleChange, errors, touched, handleBlur } = formikProps;
              return (
                <Box sx={{ backgroundColor: '#fff', pb: 3, px: 3 }} alignItems='center'>
                  <Stack
                    direction='row'
                    alignItems='center'
                    py={1}
                    justifyContent='space-between'
                    sx={{ backgroundColor: '#fff' }}
                    spacing={2}
                  >
                    <Stack>
                      <Typography variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
                        Edit Person
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack>
                    <Typography variant='input-label' fontStyle='italic' sx={{ pt: 1, fontSize: 13 }}>
                      (<span style={{ color: 'red' }}>*</span>) indicates required fields
                    </Typography>
                  </Stack>

                  <Grid
                    container
                    marginTop={2}
                    sx={{ backgroundColor: '#fff', py: 1, borderTop: '1px solid #E3EBF6' }}
                    justifyContent='space-between'
                    display='flex'
                    flexWrap='wrap'
                    alignItems='flex-start'
                  >
                    <Grid container item xs={6} direction='column'>
                      <Stack>
                        <Stack
                          direction='row'
                          spacing={2}
                          alignItems='center'
                          justifyContent='space-between'
                          sx={{ mt: 2 }}
                        >
                          <Typography variant='input-label'>
                            Person
                            <span style={{ color: 'red', marginLeft: 2 }}>*</span>
                          </Typography>
                          <TextField
                            sx={textInputStyles}
                            size='small'
                            disabled
                            placeholder='e.g. John'
                            onChange={handleChange}
                            value={values?.person}
                            onBlur={handleBlur}
                            name='person'
                            error={touched.person && Boolean(errors.person)}
                            helperText={touched.person && errors.person}
                          />
                        </Stack>

                        <Stack
                          direction='row'
                          spacing={2}
                          alignItems='center'
                          justifyContent='space-between'
                          sx={{ mt: 2 }}
                        >
                          <Typography variant='input-label'>
                            Full Name
                            <span style={{ color: 'red', marginLeft: 2 }}>*</span>
                          </Typography>
                          <TextField
                            sx={textInputStyles}
                            size='small'
                            disabled={isSemiLoading}
                            placeholder='e.g. John Doe'
                            onChange={handleChange}
                            value={values?.fullName}
                            onBlur={handleBlur}
                            name='fullName'
                            error={touched.fullName && Boolean(errors.fullName)}
                            helperText={touched.fullName && errors.fullName}
                          />
                        </Stack>

                        <Stack
                          direction='row'
                          spacing={2}
                          alignItems='center'
                          justifyContent='space-between'
                          sx={{ mt: 2 }}
                        >
                          <Typography variant='input-label'>
                            Email
                            <span style={{ color: 'red', marginLeft: 2 }}>*</span>
                          </Typography>
                          <TextField
                            sx={textInputStyles}
                            size='small'
                            disabled={isSemiLoading}
                            placeholder='e.g. johndoe@email.com'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.email}
                            name='email'
                            error={touched.email && Boolean(errors.email)}
                            helperText={touched.email && errors.email}
                          />
                        </Stack>
                        <Stack
                          direction='row'
                          spacing={2}
                          alignItems='center'
                          justifyContent='space-between'
                          sx={{ mt: 2 }}
                        >
                          <Typography variant='input-label'>
                            Mobile Phone
                            <span style={{ color: 'red', marginLeft: 2 }}>*</span>
                          </Typography>
                          <TextField
                            sx={textInputStyles}
                            size='small'
                            disabled={isSemiLoading}
                            placeholder='e.g. 6123456789'
                            onChange={(e) => {
                              const value = e.target.value;
                              if (value.match(/^[0-9]*$/)) {
                                handleChange(e);
                              }
                            }}
                            onBlur={handleBlur}
                            value={values?.mobile}
                            name='mobile'
                            error={touched.mobile && Boolean(errors.mobile)}
                            helperText={touched.mobile && errors.mobile}
                          />
                        </Stack>
                        <Stack
                          direction='row'
                          spacing={2}
                          alignItems='center'
                          justifyContent='space-between'
                          sx={{ mt: 2 }}
                        >
                          <Typography variant='input-label'>Reset Password</Typography>
                          <Button
                            disabled={isSemiLoading}
                            variant='main-table-panel-border'
                            onClick={handleOnOpenModalResetPassword}
                            startIcon={<LockResetOutlinedIcon />}
                          >
                            Reset Password
                          </Button>
                        </Stack>
                      </Stack>
                    </Grid>
                  </Grid>
                  <Stack direction='row' spacing={2} justifyContent='flex-end'>
                    <Button
                      disabled={isUpdating}
                      onClick={handleOnShowDeleteConfirmation}
                      variant='outlined'
                      sx={{
                        textTransform: 'none',
                      }}
                      color='error'
                    >
                      Delete
                    </Button>
                    <Button disabled={isUpdating} onClick={handleOnCancelInput} variant='main-table-panel-border'>
                      Cancel
                    </Button>

                    <ButtonLoading
                      loading={isUpdating}
                      variant='main-table-panel'
                      onClick={() => formikProps.handleSubmit()}
                      // @ts-ignore
                      disabled={!EditPersonValidationSchema?.isValidSync(formikProps.values) || isUpdating}
                    >
                      Save
                    </ButtonLoading>
                  </Stack>
                </Box>
              );
            }}
          </Formik>
        )}

        <ModalResetPassword
          visible={showModalResetPassword}
          userId={id || ''}
          onClose={handleOnCloseModalResetPassword}
        />
      </Box>
      <ModalDeleteComponent
        errorMessage={modalDeleteState.errorMessage}
        isError={modalDeleteState.isError}
        visible={modalDeleteState.open}
        title={null}
        message={modalDeleteState.message}
        onApprove={handleOnDeleteData}
        onClose={() => setModalDeleteState({ ...modalDeleteState, open: false, isError: false, errorMessage: '' })}
        onCancel={() => setModalDeleteState({ ...modalDeleteState, open: false, isError: false, errorMessage: '' })}
      />
    </TenantHomeDashboardNavigationBar>
  );
}
