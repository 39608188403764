import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { DEFAUL_LOGIN_REDIRECT_URL } from 'constant/PermissonConstant';
import { RootState } from 'store';

const buttonTextGoBackStyle = {
  color: '#42BB93',
  fontWeight: 'bold',
  textTransform: 'none',
  fontSize: 14,
  cursor: 'pointer',
};

export function PageNotFound() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { accessToken, role } = useSelector((state: RootState) => state.auth);

  const handleGoBackClick = () => {
    if (!!accessToken) {
      navigate(DEFAUL_LOGIN_REDIRECT_URL[role]);
    } else {
      navigate('/auth/login');
    }
  };
  return (
    <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
      <img src='/images/404.svg' alt='404' style={{ marginTop: 30 }} />
      <img src='/images/main-logo.svg' alt='404' />
      <Typography variant='body2' sx={{ mt: 3, color: '#98A2AE' }}>
        {t('PAGE.ERROR_NOT_FOUND.DESCRIPTION')}{' '}
        <span>
          <Typography onClick={handleGoBackClick} variant='overline' sx={buttonTextGoBackStyle}>
            {t('PAGE.ERROR_NOT_FOUND.BUTTON_ACTION')}
          </Typography>
        </span>
      </Typography>
    </Box>
  );
}
