import { useNavigate } from 'react-router';

import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { IconButton } from '@mui/material';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
interface ButtonRecordEditProps extends GridRenderCellParams<any> {
  subjectId?: string | number;
  onClick?: () => void;
}

export function ButtonRecordEdit(props: ButtonRecordEditProps) {
  const { id, subjectId, onClick } = props;

  const navigate = useNavigate();

  const handleOnClick = () => {
    if (onClick) {
      onClick();
      return;
    }
    navigate(`/data-model/data-input/edit/${subjectId}/${id}`);
  };

  return (
    <IconButton aria-label='delete' size='small' onClick={handleOnClick}>
      <ModeEditOutlineOutlinedIcon
        sx={{
          color: '#a3a2a2',
        }}
      />
    </IconButton>
  );
}
