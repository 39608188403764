import { useState } from 'react';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';

import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import { CustomRuleList } from './CustomRuleList';
import { CustomRuleDetails } from './CustomRuleDetail';
import { CustomRuleListItem } from 'types/api/Tenant/AROKMS/CustomRuleTypes';

const ModalContentStyle = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 950,
  bgcolor: 'background.paper',
  height: 600,
  overflow: 'auto',
  borderRadius: 1,
  py: 2,
};
const backButtonStyles = {
  textTransform: 'none',
  mb: 1,
  p: 0,
  width: 100,
};

const MODAL_VIEW_STATE_CUSTOM_RULE = {
  CUSTOM_RULE_LIST: 'CUSTOM_RULE_LIST',
  CUSTOM_RULE_DETAIL: 'CUSTOM_RULE_DETAIL',
};

const closeIconStyle = { color: '#98A2AE', cursor: 'pointer' };

const transitionStyles = {
  entering: { transform: 'translateX(-100%)', opacity: 0, position: 'absolute' } as React.CSSProperties,
  entered: {
    transform: 'translateX(0)',
    opacity: 1,
    transition: 'transform 0.3s, opacity 0.3s',
  } as React.CSSProperties,
  exiting: {
    transform: 'translateX(100%)',
    opacity: 0,
    position: 'absolute',
  } as React.CSSProperties,
  exited: { transform: 'translateX(0)', opacity: 1, transition: 'transform 0.3s, opacity 0.3s' } as React.CSSProperties,
};

export interface ModalDataInputCustomRuleProps {
  visible?: boolean;
  onClose?: () => void;
  subjectId?: string | number;
}

export function ModalDataInputCustomRule(props: ModalDataInputCustomRuleProps) {
  const { onClose, visible = false, subjectId } = props;
  const [modalView, setModalView] = useState(MODAL_VIEW_STATE_CUSTOM_RULE.CUSTOM_RULE_LIST);
  const [customRuleEditItem, setCustomRuleEditItem] = useState<CustomRuleListItem | null>(null);

  const handleOnCloseModal = () => {
    onClose?.();
  };

  const handleOnCreateCustomRule = () => {
    setModalView(MODAL_VIEW_STATE_CUSTOM_RULE.CUSTOM_RULE_DETAIL);
  };

  const handleOnEditCustomRule = (data: CustomRuleListItem) => {
    setModalView(MODAL_VIEW_STATE_CUSTOM_RULE.CUSTOM_RULE_DETAIL);
    setCustomRuleEditItem(data);
  };
  const handleOnClickBackToList = () => {
    setModalView(MODAL_VIEW_STATE_CUSTOM_RULE.CUSTOM_RULE_LIST);
    if (customRuleEditItem) {
      setCustomRuleEditItem(null);
    }
  };

  const isShowList = modalView === MODAL_VIEW_STATE_CUSTOM_RULE.CUSTOM_RULE_LIST;

  return (
    <Modal open={visible} onClose={handleOnCloseModal}>
      <Stack direction='column' sx={ModalContentStyle}>
        <Stack px={2} justifyContent='space-between' direction='row' alignItems='center'>
          {modalView === MODAL_VIEW_STATE_CUSTOM_RULE.CUSTOM_RULE_DETAIL && (
            <Button
              variant='text'
              disableRipple
              onClick={handleOnClickBackToList}
              sx={backButtonStyles}
              startIcon={<KeyboardBackspaceOutlinedIcon />}
            >
              Back to list
            </Button>
          )}
          {modalView === MODAL_VIEW_STATE_CUSTOM_RULE.CUSTOM_RULE_LIST && (
            <>
              <Typography variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
                Custom Rule
              </Typography>
              <IconButton onClick={handleOnCloseModal} component='label'>
                <HighlightOffRoundedIcon sx={closeIconStyle} />
              </IconButton>
            </>
          )}
        </Stack>
        <Divider sx={{ mb: 2 }} />
        <div style={{ position: 'relative', overflow: 'auto', height: '100%', width: '100%' }}>
          <CustomRuleList
            onCreateCustomRule={handleOnCreateCustomRule}
            onEditCustomRule={handleOnEditCustomRule}
            subjectId={subjectId}
            style={isShowList ? transitionStyles.entered : transitionStyles.exiting}
          />
          <CustomRuleDetails
            onBackToList={handleOnClickBackToList}
            editRow={customRuleEditItem}
            style={isShowList ? transitionStyles.entering : transitionStyles.entered}
            subjectId={subjectId}
          />
        </div>
      </Stack>
    </Modal>
  );
}
