import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { useAdmiDashboardAcitivityChart } from 'services/v1/TenantMaster/MasterTable/AdminDashboardService';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import ActivityIndicator from 'components/ActivityIndicatorComponent';
import { Stack } from '@mui/material';

type AdminDashboardUserActivityChart = {
  name: string;
  'Total Login': number;
};

export function DashboardChart() {
  const { selectedTenant } = useSelector((state: RootState) => state.auth);
  const { data: rawCharData, isLoading: isLoadingChart } = useAdmiDashboardAcitivityChart(selectedTenant?.tenant?.id);

  const chartData: AdminDashboardUserActivityChart[] =
    rawCharData?.data?.map((item) => ({
      name: item.label,
      'Total Login': item.value,
    })) || [];
  return (
    <div style={{ width: '100%' }}>
      {isLoadingChart ? (
        <Stack height={280}>
          <ActivityIndicator />
        </Stack>
      ) : (
        <BarChart
          width={900}
          height={300}
          data={chartData}
          style={{
            fontSize: 12,
          }}
          maxBarSize={40}
          margin={{
            top: 20,
            right: 0,
            bottom: 0,
            left: -35,
          }}
        >
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='name' stroke='#656666' />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey='Total Login' fill='#394798' label={{ position: 'top' }} />
        </BarChart>
      )}
    </div>
  );
}
