import { useIsMutating } from 'react-query';
import Stack from '@mui/material/Stack';
import Typograhpy from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import Tooltip from '@mui/material/Tooltip';
import Divider from '@mui/material/Divider';
import { GridRowModesModel, GridRowsProp, GridRowModes } from '@mui/x-data-grid-pro';
import { ButtonExport, FileType } from 'components/ButtonComponent/ButtonExport';
import { ButtonDownloadTemplate } from 'components/ButtonComponent/ButtonDownloadTemplate';
import { ButtonImport } from 'components/ButtonComponent/ButtonImport';
import { GridToolbarDensitySelector } from 'components/ButtonComponent/ButtonDensity';
import { GridToolbarFilterButton } from 'components/ButtonComponent/ButtonFilter';
import { RoleBasedAccessProvider } from 'components/RBAC';
import ButtonLoading from '@mui/lab/LoadingButton';
import SyncAltOutlinedIcon from '@mui/icons-material/SyncAltOutlined';
// Custom Icon
import { ROLES } from 'constant/PermissonConstant';
import { ButtonTableOptions, EventTableOptions } from 'components/ButtonComponent/ButtonTableOptions';
interface SettingPanelConfigProps {
  insertButtonText?: string;
  insertInitialObject?: { [key: string]: string | number | boolean };
  fieldFocusOnInsert: string;
  deleteButtonText?: string;
}

interface OptionItem {
  value: string;
  label: string;
}

interface SettingPanelProps {
  onDeleteData?: () => void;
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (newModel: (oldModel: GridRowModesModel) => GridRowModesModel) => void;
  setFilterButtonElement: () => void;
  config: SettingPanelConfigProps;
  isInserting: boolean;
  title: string;
  options: OptionItem[];
  initialOption?: OptionItem | undefined;
  viewOnly?: boolean;
  withChipLabel?: boolean;
  optionValue: OptionItem;
  disabled: boolean;
  showSyncButton?: boolean;
  onSync?: () => void;
  totalDependentCubes?: number;
  onOptionChange: (value: OptionItem) => void;
  onExport: (selectedFileType: FileType) => void;
  onImport: (selectedFileType: FileType) => void;
  onButtonTemplateClick: (selectedFileType: FileType) => void;
  disabledDeleteButton?: boolean;
  onAddDataClick?: () => void;
  onOptionClick?: (type: EventTableOptions) => void;
}

const buttonActionStyle = {
  padding: 1.4,
};

export function SettingPanel(props: SettingPanelProps) {
  const isMutating = Boolean(useIsMutating());
  const {
    setFilterButtonElement,
    setRows,
    setRowModesModel,
    config,
    disabled,
    viewOnly,
    onExport,
    onImport,
    onButtonTemplateClick,
    onAddDataClick = undefined,
    showSyncButton,
    totalDependentCubes,
    onSync,
    onOptionClick = () => {},
  } = props;

  const { fieldFocusOnInsert = '', insertButtonText = 'Add', insertInitialObject = {} } = config;

  const handleAddData = () => {
    if (onAddDataClick) {
      onAddDataClick();
    } else {
      const id = new Date().getTime();
      setRows((oldRows) => [{ id, ...insertInitialObject }, ...oldRows]);
      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: fieldFocusOnInsert },
      }));
    }
  };

  return (
    <Stack direction='row' justifyContent='space-between' spacing={0} sx={{ backgroundColor: '#fff', py: 1.8 }}>
      <Stack direction='row' alignItems='center'>
        <Typograhpy variant='subtitle1' sx={{ fontWeight: 600 }}>
          {insertButtonText && (
            <Button
              disabled={disabled || isMutating}
              variant='main-table-panel'
              startIcon={<AddCircleOutlineOutlinedIcon />}
              onClick={handleAddData}
            >
              {insertButtonText}
            </Button>
          )}
        </Typograhpy>
      </Stack>

      <Stack direction='row' spacing={0} alignContent='center' alignItems='center'>
        <GridToolbarFilterButton
          ref={setFilterButtonElement}
          componentsProps={{
            button: {
              disabled: disabled,
              sx: buttonActionStyle,
              variant: 'main-table-panel-borderless',
            },
          }}
        />
        <GridToolbarDensitySelector sx={buttonActionStyle} variant='main-table-panel-borderless' />
        <Divider orientation='vertical' variant='middle' flexItem sx={{ px: 0.5 }} />
        <ButtonExport disabled={disabled} onClick={onExport} />
        <RoleBasedAccessProvider allowedRoles={[ROLES.BUILDER, ROLES.ADMIN, ROLES.TESTER, ROLES.SUPPORT]}>
          <ButtonImport disabled={disabled} onClick={onImport} />
          <ButtonDownloadTemplate disabled={disabled} onClick={onButtonTemplateClick} />
        </RoleBasedAccessProvider>
        {!viewOnly && (
          <RoleBasedAccessProvider
            allowedRoles={[ROLES.BUILDER, ROLES.ADMIN, ROLES.TESTER, ROLES.SUPPORT, ROLES.PACKAGER]}
          >
            {showSyncButton && (
              <Stack direction='row' spacing={2} ml={4}>
                <Tooltip
                  title={`Ensure synchronization among ${totalDependentCubes} cubes linked to this data. Click the button to sync.`}
                  arrow
                >
                  <ButtonLoading onClick={onSync} startIcon={<SyncAltOutlinedIcon />} variant='main-table-panel'>
                    Syncronize
                  </ButtonLoading>
                </Tooltip>
              </Stack>
            )}
          </RoleBasedAccessProvider>
        )}
        <ButtonTableOptions menuOptionsId={[]} onClick={onOptionClick} />
      </Stack>
    </Stack>
  );
}
