import { GridRowModesModel, GridRenderCellParams } from '@mui/x-data-grid-pro';
import Button from '@mui/material/Button';
import { CubeDefinitionRowItem } from 'types/api/SystemTenant/AROCube/CubeBuilderTypes';
import { ModalCubeDefinitionParamsProps } from './ModalCubeDefinitionParameter';
import IconButton from '@mui/material/IconButton';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { Stack } from '@mui/material';

interface CubeDefinitionParamActionButtonProps extends GridRenderCellParams<CubeDefinitionRowItem> {
  setRowModesModel: (newModel: (oldModel: GridRowModesModel) => GridRowModesModel) => void;
  fieldName: 'ruleTypeParams' | 'consolidateRuleParams' | null;
  setModalParameterState: (
    newState: (OldState: ModalCubeDefinitionParamsProps) => ModalCubeDefinitionParamsProps
  ) => void;
  disabled?: boolean;
}

export function CubeDefinitionParamActionButton(props: CubeDefinitionParamActionButtonProps) {
  const { hasFocus, row, setModalParameterState, fieldName, disabled = false } = props;
  const isConsolidate = fieldName === 'consolidateRuleParams';

  if (!fieldName || row?.isNew) return <span></span>;

  const handleOnClick = () => {
    if (fieldName) {
      setModalParameterState((prevState) => ({ ...prevState, visible: true, cubeDefinitionItem: row, fieldName }));
    }
  };

  if (!isConsolidate) {
    if (!row.ruleType?.isRequiredParams || row.ruleType?.isRequiredParams === 'N') return <span></span>;
  }

  if (row[fieldName]) {
    return (
      <Stack direction='row' alignItems='center'>
        <IconButton onClick={handleOnClick} aria-label='delete' size='small' disabled={disabled}>
          <ModeEditOutlineOutlinedIcon fontSize='inherit' />
        </IconButton>
        <div>{row[fieldName]}</div>
      </Stack>
    );
  }

  return (
    <Button
      variant='secondary-table-panel'
      sx={{
        height: '58%',
        fontSize: '12px',
      }}
      onClick={handleOnClick}
      tabIndex={hasFocus ? 0 : -1}
      disabled={disabled}
      onKeyDown={(event: React.KeyboardEvent) => {
        if (event.key === ' ') {
          // Prevent key navigation when focus is on button
          event.stopPropagation();
        }
      }}
    >
      {row[fieldName] ? 'View' : isConsolidate ? 'Add' : 'Create'}
    </Button>
  );
}
