import * as React from 'react';
import { GridRenderCellParams, useGridApiContext } from '@mui/x-data-grid';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { toast } from 'react-toastify';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { useUpsertMemberListData } from 'services/v1/Tenant/AROKMS/MemberListService';
import { getErrorMessage } from 'utils/Error';
import { AxiosDefaultErrorEntity } from 'types';

type IProps = GridRenderCellParams & {
  optionsSource?: { label: string; value: string }[];
  onChange?: (event: React.SyntheticEvent, value: { label: string; value: string }) => void;
  subjectId: string;
  error?: string;
  isRequired?: boolean;
};

const autocompleteStyles = {
  width: '95%',
  '& .MuiOutlinedInput-root': {
    padding: '1px 2px',

    '& .MuiAutocomplete-input': {
      padding: '17px 4px 17px 6px',
    },
  },
  '& .MuiAutocomplete-popper': {
    fontSize: '10px',
  },
};

const textInputStyles = {
  '& .MuiOutlinedInput': {
    padding: '1px 2px',
  },
};

const optionStyles = { '& > span': { fontSize: '14px', mr: 2, flexShrink: 0 } };

function CreatableDropdown(props: IProps) {
  const { id, value, field, optionsSource, subjectId, error, isRequired } = props;
  const { mutateAsync: upserMemberListAsync } = useUpsertMemberListData();

  const errorMessage = !props.value && isRequired ? 'This field is required' : error;

  const apiRef = useGridApiContext();

  const filter = createFilterOptions<{ value: string; label: string; isNew?: boolean }>();

  const handleChange = async (
    event: React.SyntheticEvent,
    value: { label: string; value: string; isNew?: boolean }
  ) => {
    try {
      if (value.isNew) {
        const { data } = await upserMemberListAsync({ memberList: value.value, subjectId });
        await apiRef.current.setEditCellValue({ id, field, value: { label: data.memberList, value: data.id } });
        apiRef.current.stopCellEditMode({ id, field });
      } else {
        if (value) {
          await apiRef.current.setEditCellValue({ id, field, value: value });
          apiRef.current.stopCellEditMode({ id, field });
        }
      }
    } catch (error) {
      toast.error(getErrorMessage(error as AxiosDefaultErrorEntity));
    }
  };

  return (
    <Autocomplete
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }

        if (option.isNew) {
          return option.value;
        }
        // Regular option
        return option.label || '';
      }}
      clearIcon={null}
      selectOnFocus
      freeSolo
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some((option) => inputValue.toLowerCase() === option.label.toLowerCase());

        if (inputValue !== '' && !isExisting) {
          filtered.push({
            value: inputValue,
            label: `Add "${inputValue}"`,
            isNew: true,
          });
        }

        return filtered;
      }}
      contentEditable={false}
      value={value}
      onInputChange={async (event, value) => {
        if (value === '') {
          await apiRef.current.setEditCellValue({ id, field, value: null });
          apiRef.current.stopCellEditMode({ id, field });
        }
      }}
      defaultValue={{ label: value?.label || '', value: value?.value || '' }}
      options={optionsSource || []}
      renderOption={(props, option) => (
        <Box component='li' sx={optionStyles} {...props}>
          <span>{option?.label}</span>
        </Box>
      )}
      sx={autocompleteStyles}
      // @ts-ignore
      onChange={handleChange}
      renderInput={(params) => (
        <TextField {...params} sx={textInputStyles} error={!!errorMessage} helperText={errorMessage} />
      )}
    />
  );
}

type RenderSelectEditInputCellParams = GridRenderCellParams & {
  optionsSource?: { label: string; value: string }[];
  onChange?: (event: React.SyntheticEvent, value: { label: string; value: string }) => void;
  subjectId: string;
  error?: string;
  isRequired?: boolean;
};

const MemoizedCreatableDropdownInput = React.memo(CreatableDropdown);
export const renderCreatableDropdownInputMemberList = (params: RenderSelectEditInputCellParams) => {
  return <MemoizedCreatableDropdownInput {...params} subjectId={params.subjectId} key={params.field} />;
};
