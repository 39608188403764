import axios from 'axios';
import { dayjs } from 'utils/DateUtils';
import { ENV_CONFIG } from 'config/env';
import { AxiosInstance } from 'axios';

import { setAccessToken, setSessionStatus } from 'store/reducer/authReducer';
import { store, RootState } from 'store';
import { SESSION_STATUS_CONSTANT } from 'constant/SessionStatusConstant';
import { wordsInString } from 'utils/String';

let instance: AxiosInstance;

function makeAxiosIntance(): AxiosInstance {
  if (instance) return instance;

  instance = axios.create({
    baseURL: ENV_CONFIG.endpoint.API_ENDPOINT,
    timeout: +ENV_CONFIG.timeout.REQUEST_TIMEOOUT || 60000,
  });

  return instance;
}

const axiosInstance = makeAxiosIntance();

axiosInstance.interceptors.request.use(
  (config) => {
    const appState: RootState = store.getState();
    if (appState.auth.accessToken && isTokenExpired(appState)) {
      store.dispatch(setSessionStatus(SESSION_STATUS_CONSTANT.EXPIRED));
    }
    if (appState.auth.accessToken) {
      // @ts-ignore
      config.headers.Authorization = `${appState.auth.accessToken}`;
      // @ts-ignore
      config.headers['X-TENANT-ID'] = `${appState.auth.selectedTenant?.tenant?.id}`;
    }

    if (config.url === '/login') {
      delete config.headers?.Authorization;
      delete config.headers?.['X-TENANT-ID'];
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const sessionExpiredErrorMessageWords = ['session', 'expired'];

axiosInstance.interceptors.response.use(
  (response) => {
    if (response.config.url === '/login' && response.status === 200) {
      store.dispatch(setAccessToken(response.headers.authorization));
    }
    return response;
  },
  (error) => {
    if (
      error?.response?.status === 401 &&
      wordsInString(error?.response?.data?.message?.toLowerCase(), sessionExpiredErrorMessageWords)
    ) {
      localStorage.clear();
      window.location.href = '/auth/login';
    }

    return Promise.reject(error);
  }
);

export const setAuthHeaderToken = (token: string): void => {
  axiosInstance.defaults.headers.common['Authorization'] = token;
};

export const setAuthTenantId = (tenantId: string): void => {
  axiosInstance.defaults.headers.common['X-TENANT-ID'] = tenantId;
};

export const removeAuthHeaderToken = (): void => {
  delete axiosInstance.defaults.headers.common['Authorization'];
};

export function isTokenExpired(appState: RootState): boolean {
  const { auth } = appState;
  const { accessToken, expiredDate } = auth;

  if (accessToken === '') return true;

  let expired = true;

  if (dayjs(expiredDate).isValid()) {
    expired = dayjs() > dayjs(expiredDate);
  }

  return !!accessToken && expired;
}

export default axiosInstance;
