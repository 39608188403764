import qs from 'query-string';
import { toast } from 'react-toastify';
import { useState, useMemo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import {
  GridRowModesModel,
  GridRowModes,
  GridRowParams,
  GridFilterModel,
  useGridApiRef,
  GridSelectionModel,
  GridRowId,
  GridActionsCellItem,
  GridColumns,
  GridEnrichedColDef,
} from '@mui/x-data-grid-pro';
import { useSubjectUsedDropdown } from 'services/v1/SystemTenant/AROKMS/SubjectService';
import { PaginationAndSortingParams } from 'types/api/Common/PaginationTypes';
import { CustomDataTable } from 'components/DatatableComponent';
import { MainLayoutComponent } from 'components/LayoutComponent/SidebarLayout/MainLayoutComponent';
import { SettingPanel } from 'pages/SystemTenant/AROKMS/KTree/components/KTreeTablePanel';
import ModalDeleteComponent, { ModalDeleteState } from 'components/ModalComponent/ModalDeleteComponent';
import { AutoCompleteItem } from 'types/api/Common/AutoCompleteTypes';
import { renderSelectEditInputCellKtreeDefinition } from 'pages/SystemTenant/AROKMS/KTree/components/SelectEditInputCellKTreeDefinition';
import { KTreeDefinitionEditValidationResponse } from 'types/api/SystemTenant/KTreeBuilderTypes';
import { OptionItem } from 'pages/SystemTenant/AROKMS/TableDefinition/components/TabelDefinitionListPanel';
import { DASHBOARD_VIEW_MODE } from 'constant/DashboardTypeConstant';
import { SUBJECT_TYPE } from 'constant/DataInputConstant';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import ModalInfoComponent from 'components/ModalComponent/ModalInfoComponent';
import { useGetSubjectProcessDropdown } from 'services/v1/SystemTenant/AROKMS/SubjectProcessService';
import {
  useDeleteSubjectProcedure,
  useSubjectProcedureList,
  useUpsertSubjectProcedureData,
} from 'services/v1/SystemTenant/AROKMS/SubjectProcedureService';
import { SubjectProcedureRowItem } from 'types/api/SystemTenant/AROKMS/SubjectProcedureTypes';
import { ModalSubjectProcedureFieldDefinition } from './ModalProcedureFieldDefinition';
import { SubjectProcedureFieldActionButton } from './SubjectProcedureFieldActionButton';
import { getErrorMessage } from 'utils/Error';
import {
  getSubjectProcedureAllowActionsLabel,
  SUBJECT_PROCEDURE_ALLOW_ACTIONS_DROPDOWN,
} from 'constant/SubjectConstant';

interface MemberListFilter extends PaginationAndSortingParams {
  subjectId?: string | number;
}

const panelStyle = {
  transform: 'translate3d(704px, 140.667px, 0px) !important',

  '& .MuiDataGrid-panelFooter': {
    display: 'none',
  },
};

interface EditValidationStatusModalState extends KTreeDefinitionEditValidationResponse {
  open: boolean;
}

export default function SubjecProcedurePage() {
  const { t } = useTranslation();
  const { dashboardViewMode } = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();
  const location = useLocation();
  const { sid = null } = qs.parse(location.search);
  const apiRef = useGridApiRef();
  const [muiDataTableKey] = useState<number>(0);
  const [editValidationStatusModal, setEditValidationStatusModal] = useState<EditValidationStatusModalState | null>(
    null
  );
  const [rowData, setRowData] = useState<SubjectProcedureRowItem[]>([]);
  const [selectedRowId, setSelectedRowId] = useState<GridRowId[]>([]);
  const [initialOption, setInitialOption] = useState<OptionItem | null>(null);
  const [modalDeleteState, setModalDeleteState] = useState<ModalDeleteState>({ open: false, message: '', key: 0 });
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [filterButtonElement, setFilterButtonElement] = useState<HTMLButtonElement | null>(null);
  const [selectedSubjectId, setSelectedSubjectId] = useState<AutoCompleteItem>({ label: '', value: '' });
  const [filter, setFilter] = useState<MemberListFilter>({
    page: 1,
    size: 10,
    filterValue: '',
    sortType: 'asc',
    filterOperator: 'contains',
  });
  const [modalShowProcedureField, setModalShowProcedureField] = useState<{
    open: boolean;
    procedureId: string | undefined;
  }>({
    open: false,
    procedureId: undefined,
  });

  // Service call
  const { data: subjectDropdownData, isLoading: isDropdownLoading } = useSubjectUsedDropdown({
    enabled: true,
    subjectType: SUBJECT_TYPE.TABLE,
  });
  const { isLoading, data: subjectProcedureListData } = useSubjectProcedureList(selectedSubjectId.value?.toString());

  const { data: subjectProcessDropdownOptions } = useGetSubjectProcessDropdown();
  const { mutate: upsertSubjectProcedure } = useUpsertSubjectProcedureData();

  const {
    mutateAsync: deleteSubjectProcedureById,
    isError: isErrorDeleteProcedure,
    error: errorDeleteProcedure,
    reset: resetDeleteAttribute,
  } = useDeleteSubjectProcedure();

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const isAnyEdit = useMemo(() => {
    return rowModesModel && Object.values(rowModesModel).some((item) => item.mode === GridRowModes.Edit);
  }, [rowModesModel]);

  const isBuilderViewOnly = dashboardViewMode === DASHBOARD_VIEW_MODE.VIEW_ONLY;

  const columns = useMemo<GridColumns>(() => {
    const actionColumn: GridEnrichedColDef = {
      field: 'actions',
      type: 'actions',
      headerName: 'Edit',
      width: 30,
      cellClassName: 'actions',

      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          // @ts-ignore
          return [<GridActionsCellItem icon={<SaveIcon />} label='Save' onClick={handleSaveClick(id)} />];
        }

        return [
          // @ts-ignore
          <GridActionsCellItem disabled={isAnyEdit} icon={<EditIcon />} label='Edit' onClick={handleEditClick(id)} />,
        ];
      },
    };
    const ktreeColumns: GridColumns = [
      {
        field: 'procedureName',
        headerName: 'Procedure Name',
        width: 230,
        editable: true,
        filterable: true,
        sortable: true,
      },
      {
        field: 'allowAction',
        headerName: 'Allow Action',
        width: 250,
        editable: true,
        filterable: false,
        sortable: false,
        renderCell: (params) => {
          return <span>{getSubjectProcedureAllowActionsLabel(params?.value)}</span>;
        },
        renderEditCell: (params) =>
          renderSelectEditInputCellKtreeDefinition({
            ...params,
            // @ts-ignore
            optionsSource: SUBJECT_PROCEDURE_ALLOW_ACTIONS_DROPDOWN,
          }),
      },
      {
        field: 'process',
        headerName: 'Process',
        width: 250,
        editable: true,
        filterable: false,
        sortable: false,
        renderCell: (params) => {
          return <span>{params?.value?.label}</span>;
        },
        renderEditCell: (params) =>
          renderSelectEditInputCellKtreeDefinition({
            ...params,
            // @ts-ignore
            optionsSource: subjectProcessDropdownOptions,
          }),
      },
      {
        field: 'totalField',
        headerName: 'Fields',
        width: 150,
        editable: false,
        filterable: true,
        sortable: true,
        renderCell: (props) => (
          <SubjectProcedureFieldActionButton
            {...props}
            fieldName='totalField'
            disabled={false}
            setModaProcedureField={setModalShowProcedureField}
            setRowModesModel={setRowModesModel}
          />
        ),
      },
    ];

    if (isBuilderViewOnly) return ktreeColumns;
    return [actionColumn, ...ktreeColumns];

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subjectProcedureListData?.data, rowModesModel, isAnyEdit, isBuilderViewOnly]);

  const subjectDropdownOptions = useMemo(() => {
    if (subjectDropdownData?.data) {
      if (sid) {
        const initialSubject = subjectDropdownData.data.find((item) => item.value === sid);
        if (initialSubject) {
          setInitialOption(initialSubject);
          setSelectedSubjectId(initialSubject);
        } else {
          setSelectedSubjectId(subjectDropdownData?.data[0]);
        }
      } else {
        setSelectedSubjectId(subjectDropdownData?.data[0]);
      }
      return subjectDropdownData.data;
    }
    return [];
  }, [subjectDropdownData, sid]);

  useEffect(() => {
    if (subjectProcedureListData?.data) {
      setRowData(subjectProcedureListData.data);
    }
  }, [subjectProcedureListData?.data]);

  // Process row Update will be triggered when
  // user modify or insert new row the table
  const handleProcessRowUpdate = (data: SubjectProcedureRowItem) => {
    if (data?.isNew) {
      upsertSubjectProcedure({
        procedureName: data.procedureName,
        processId: data.process?.value?.toString(),
        subjectId: selectedSubjectId.value.toString(),
        allowAction: data.allowAction.value.toString(),
      });
    } else {
      upsertSubjectProcedure({
        id: data.id,
        procedureName: data.procedureName,
        processId: data.process?.value?.toString(),
        subjectId: selectedSubjectId.value.toString(),
        allowAction: data.allowAction.value.toString(),
      });
    }
    return data;
  };

  const handleChangePage = (newPage: number) => {
    setFilter({ ...filter, page: newPage + 1 });
  };
  const handleChangePageSize = (newPageSize: number) => {
    setFilter({ ...filter, size: newPageSize });
  };

  const handleOnFilter = (params: GridFilterModel) => {
    let filterObj = {};
    if (params.items.length) {
      filterObj = {
        filterValue: params.items[0].value,
        filterOperator: params.items[0].operatorValue,
      };
    } else {
      filterObj = {
        filterValue: '',
        filterOperator: 'contains',
      };
    }
    setFilter({
      ...filter,
      ...filterObj,
    });
  };

  const handleOnDropdownSubjectChange = (option: { value: string; label: string }) => {
    setSelectedSubjectId(option);
    setFilter({ ...filter, filterValue: '' });
    navigate({
      search: `?sid=${option.value}`,
    });
  };

  const handleSelectionModelChange = (newSelection: GridSelectionModel) => {
    setSelectedRowId(newSelection);
  };

  const handleOnClickDeleteButton = () => {
    if (selectedRowId.length) {
      setModalDeleteState({
        open: true,
        message: `Are you sure you want to delete ${selectedRowId.length} Procedure(s)?`,
      });
    }
  };

  const handleOnRowDoubleClick = useCallback(
    (params: GridRowParams, event: React.MouseEvent) => {
      if (isBuilderViewOnly || isAnyEdit) return;

      const { id } = params;
      handleEditClick(id as string);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setRowModesModel, isBuilderViewOnly, isAnyEdit]
  );

  const handleDeleteData = async () => {
    try {
      if (selectedRowId.length === 0) return;
      const multipleDeleteRequest = selectedRowId.map((id) => deleteSubjectProcedureById({ id }));
      await Promise.allSettled(multipleDeleteRequest);
      setModalDeleteState({
        ...modalDeleteState,
        open: false,
        message: '',
      });
      toast(`Successfully delete ${selectedRowId.length} procedure(s)`, {
        toastId: 'delete-procedure',
        type: 'success',
      });
    } catch (err: any) {
      toast.update('delete-procedure', { type: 'error', render: getErrorMessage(err), isLoading: false });
    }
  };

  return (
    <MainLayoutComponent pageTitle='Subject Procedure' breadcrumbs={[t('SIDEBAR_MENU.DASHBOARD'), 'Subject']}>
      <CustomDataTable
        key={muiDataTableKey}
        apiRef={apiRef}
        initialState={{
          pagination: {
            page: 0,
          },
        }}
        loading={isLoading || isDropdownLoading}
        rows={rowData}
        columns={columns}
        rowCount={0}
        page={0}
        pageSize={filter.size}
        getRowId={(row) => row.id}
        rowModesModel={rowModesModel}
        disableSelectionOnClick
        rowsPerPageOptions={[5, 10, 20]}
        getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
        experimentalFeatures={{ newEditingApi: true }}
        onFilterModelChange={handleOnFilter}
        onSelectionModelChange={handleSelectionModelChange}
        processRowUpdate={handleProcessRowUpdate}
        onRowDoubleClick={handleOnRowDoubleClick}
        onPageChange={handleChangePage}
        onPageSizeChange={handleChangePageSize}
        autoHeight={rowData.length !== 0 && rowData.length > 5}
        components={{
          Toolbar: SettingPanel,
        }}
        componentsProps={{
          panel: {
            sx: panelStyle,
            anchorEl: filterButtonElement,
          },
          toolbar: {
            setRows: setRowData,
            setRowModesModel,
            setFilterButtonElement: setFilterButtonElement,
            onDeleteData: handleOnClickDeleteButton,
            options: subjectDropdownOptions,
            initialOption,
            isEditing: rowData.some((item) => item.isNew),
            optionValue: selectedSubjectId,
            disabled: isLoading || isBuilderViewOnly,
            onOptionChange: handleOnDropdownSubjectChange,
            config: {
              insertButtonText: 'Add New Procedure',
              fieldFocusOnInsert: 'procedureName',
              insertInitialObject: {
                procedureName: '',
                process: null,
                isNew: true,
              },
            },
          },
        }}
      />

      <ModalInfoComponent
        visible={editValidationStatusModal?.open}
        title='KTree Validation Status'
        onClose={() => setEditValidationStatusModal(null)}
        descriptionMessage={editValidationStatusModal?.message}
        data={editValidationStatusModal?.data}
      />
      <ModalSubjectProcedureFieldDefinition
        open={modalShowProcedureField.open}
        onClose={() => {
          setModalShowProcedureField({ open: false, procedureId: undefined });
        }}
        procedureId={modalShowProcedureField.procedureId}
        subjectId={selectedSubjectId?.value?.toString()}
      />

      <ModalDeleteComponent
        visible={modalDeleteState.open}
        isError={isErrorDeleteProcedure}
        errorMessage={errorDeleteProcedure && getErrorMessage(errorDeleteProcedure)}
        message={modalDeleteState.message}
        onApprove={handleDeleteData}
        onClose={() => {
          setModalDeleteState({ ...modalDeleteState, open: false });
          resetDeleteAttribute();
        }}
        onCancel={() => setModalDeleteState({ ...modalDeleteState, open: false })}
      />
    </MainLayoutComponent>
  );
}
