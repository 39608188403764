import { FormikProps } from 'formik';
import { Field } from 'types/api/Tenant/AROKMS/DisplayTableTypes';
import { Stack, TextField, Typography, Box, SxProps } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { useUpsertMemberListData } from 'services/v1/Tenant/AROKMS/MemberListService';
import { memo } from 'react';
import { AxiosDefaultErrorEntity } from 'types';
import { getErrorMessage } from 'utils/Error';
import { HighlightedText } from 'components/HighlightedTextComponent';

interface IProps<T = { [key: string]: string }> {
  formik: FormikProps<T>;
  field: Field;
  sx?: SxProps;
  freeSolo?: boolean;
  ruleTypeCode?: string;
  isEdit?: boolean;
  required?: boolean;
}

const autocompleteStyles = {
  width: '55%',
  '& .MuiOutlinedInput-root': {
    padding: '1px 2px',
  },
  '& .MuiAutocomplete-popper': {
    fontSize: '10px',
  },
};

const textInputAutoCompleteStyles = {
  '& .MuiOutlinedInput': {
    padding: '1px 2px',
  },
};

const optionStyles = { '& > span': { fontSize: '14px', mr: 2, flexShrink: 0 } };

export function DropdownCreatableMemberList<T extends {}>(props: IProps<T>) {
  const { formik, field, sx, required, isEdit = false, freeSolo = true } = props;

  const { mutateAsync: upserMemberListAsync } = useUpsertMemberListData();

  const filter = createFilterOptions<{ value: string; label: string; isNew?: boolean }>();

  return (
    <Stack direction='row' justifyContent='space-between' alignItems='center' marginTop={2.5} gap={1}>
      <Stack
        sx={{
          width: '40%',
        }}
      >
        <Typography variant='input-label' fontWeight={700}>
          {field.label}
          {required && !field.disabled && <span style={{ color: 'red', marginLeft: 2 }}>*</span>}
        </Typography>
        {field.columnEditable === false && !isEdit && (
          <Typography variant='input-label-gray' fontSize={12}>
            Once you've submitted, this field can no longer be changed
          </Typography>
        )}
      </Stack>
      <Autocomplete
        disabled={field.disabled}
        // @ts-ignore
        value={formik.values[field.name] === '' ? { value: '', label: '' } : formik.values[field.name]}
        onChange={async (event, newValue) => {
          try {
            if (newValue?.isNew) {
              formik.setFieldTouched(field.name, true);
              const regexString = field.customRegex || '';
              const regex = new RegExp(regexString);
              if (!regex.test(newValue.value)) {
                formik.setFieldError(field.name, field.customRegexErrorMessage || 'Invalid format');
                return;
              }

              const { data } = await upserMemberListAsync({
                memberList: newValue.value,
                subjectId: field.colSubjectId || 0,
              });

              formik.setFieldValue(field.name, { label: data.memberList, value: data.id });
            } else {
              formik.setFieldValue(field.name, newValue);
            }
          } catch (error) {
            formik.setFieldError(field.name, getErrorMessage(error as AxiosDefaultErrorEntity));
          }
        }}
        // @ts-ignore
        name={field.name || ''}
        onBlur={formik.handleBlur}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          const { inputValue } = params;
          // Suggest the creation of a new value
          const isExisting = options.some((option) => inputValue.toLowerCase() === option.label.toLowerCase());

          if (inputValue !== '' && !isExisting && freeSolo) {
            filtered.push({
              value: inputValue,
              label: `Add "${inputValue}"`,
              isNew: true,
            });
          }

          return filtered;
        }}
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }

          if (option.isNew) {
            return option.value;
          }
          // Regular option
          return option.label || '';
        }}
        clearIcon={null}
        contentEditable={false}
        options={field.options || [{ value: '', label: '' }]}
        renderOption={(props, option, state) => {
          return (
            <Box component='li' sx={optionStyles} {...props}>
              <span>
                <HighlightedText text={option.label} highlight={state.inputValue} />
              </span>
            </Box>
          );
        }}
        sx={{ ...autocompleteStyles, ...sx }}
        renderInput={(params) => (
          <TextField
            {...params}
            name={field.name}
            sx={textInputAutoCompleteStyles}
            placeholder={field.placeholder}
            // @ts-ignore
            error={!!formik.touched[field.name] && !!formik.errors[field.name]}
            helperText={
              // @ts-ignore
              formik.touched[field.name] && typeof formik.errors[field.name] === 'string'
                ? // @ts-ignore
                  formik.errors[field.name]
                : ''
            }
          />
        )}
      />
    </Stack>
  );
}

export const DynamicTextInput = memo(DropdownCreatableMemberList);
