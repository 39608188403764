import { AxiosResponse } from 'axios';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import axios from 'libs/Axios';

import { MemberListResponse, MemberListDataUpsertRequest } from 'types/api/Tenant/AROKMS/MemberListTypes';
import { PaginationAndSortingParams } from 'types/api/Common/PaginationTypes';
import { AxiosDefaultErrorEntity } from 'types/api/Common/ErrorTypes';
import { SYSTEM_DATA_QUERY_KEY } from 'services/v1/TenantMaster/MasterTable/SystemTableDataService';
import { SERVER_DATA_QUERY_KEY } from 'services/v1/TenantMaster/MasterTable/ServerTableDataService';
import { DISPLAY_TABLE_QUERY_KEY } from './DisplayTableService';

async function OptimisticUpdate<T>(bodyRequest: T, listQuery: any, queryClient: any) {
  const previousData = queryClient.getQueriesData(listQuery);
  await queryClient.cancelQueries(listQuery);

  return { previousData };
}

export function useMemberList(params: { subjectId: string | number }) {
  return useQuery<AxiosResponse<MemberListResponse>, AxiosDefaultErrorEntity>(
    ['MEMBER_LIST', { ...params }],
    () => axios.get(`/api/v1/member-list/list/${params.subjectId}`).then((res) => res),
    {
      enabled: !!params.subjectId,
    }
  );
}

export function useUpsertMemberListData(filter?: PaginationAndSortingParams) {
  const queryClient = useQueryClient();
  const listQuery = ['MEMBER_LIST', { ...filter }];
  return useMutation<AxiosResponse, AxiosDefaultErrorEntity, MemberListDataUpsertRequest>(
    (bodyRequest) => axios.post('/api/v1/member-list/upsert', bodyRequest),
    {
      onMutate: async (bodyRequest) =>
        OptimisticUpdate<MemberListDataUpsertRequest>(bodyRequest, listQuery, queryClient),
      onError: (err, variables, context) => {
        //@ts-ignore
        if (context?.previousData) {
          //@ts-ignore
          queryClient.setQueriesData(listQuery, context.previousData);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(listQuery);
        queryClient.refetchQueries([SYSTEM_DATA_QUERY_KEY.SYSTEM_MEMBER_LIST_DROPDOWN]);
        queryClient.refetchQueries([SERVER_DATA_QUERY_KEY.SERVER_MEMBER_LIST_DROPDOWN]);
        queryClient.refetchQueries([DISPLAY_TABLE_QUERY_KEY.COLUMNS]);
      },
    }
  );
}

export function useDeleteMemberListData(filter: PaginationAndSortingParams) {
  const queryClient = useQueryClient();
  const listQuery = ['MEMBER_LIST', { ...filter }];
  return useMutation<AxiosResponse, AxiosDefaultErrorEntity, { id: number | string }>(
    (bodyRequest) => axios.delete(`/api/v1/member-list/delete?memberListId=${bodyRequest.id}`),
    {
      onMutate: async (bodyRequest) => OptimisticUpdate<{ id: string | number }>(bodyRequest, listQuery, queryClient),
      onError: (err, variables, context) => {
        //@ts-ignore
        if (context?.previousData) {
          //@ts-ignore
          queryClient.setQueriesData(listQuery, context.previousData);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(listQuery);
      },
    }
  );
}
