import _ from 'lodash';
import { useState, useMemo, forwardRef, useImperativeHandle, useCallback, useEffect } from 'react';
import { CustomDataTable } from 'components/DatatableComponent';
import {
  GridSortModel,
  getGridStringOperators,
  GridFilterModel,
  GridActionsCellItem,
  GridColumns,
} from '@mui/x-data-grid-pro';
import EditIcon from '@mui/icons-material/Edit';
import { PaginationAndSortingParams } from 'types/api/Common/PaginationTypes';
import { InsightViewAttributeDefinitionPanel } from './InsightViewAttributeDefinitionPanel';
import { InsightViewAttributeItem } from 'types/api/SystemTenant/AROView/InsightViewDefinitionTypes';
import { ModalAddGroupAttribute } from './ModalAddGroupAttribute';
import { ModalAddColumnAttribute } from './ModalAddColumnAttribute';
import { getInsightViewRelativeTimeDescription } from 'utils/DateUtils';
import { InsightViewRelativeTimeCategory, MAP_INSIGHT_VIEW_RULE_WITH_LABEL } from 'constant/ViewConstant';
import { INSIGHT_COLUM_GROUP_TOP_HEADER_TYPE } from './SelectGroupType';

interface IProps {
  cubeId?: string;
  disabled?: boolean;
  initialData?: InsightViewAttributeItem[];
  onTableChange?: (data: InsightViewAttributeItem[]) => void;
}

export interface IInsightViewAttributeDefinitionRef {
  getRowData: () => InsightViewAttributeItem[];
  setRowData: (data: InsightViewAttributeItem[]) => void;
}

function organizeAttributeColumn(data: InsightViewAttributeItem[]): InsightViewAttributeItem[] {
  const groups = data.filter((item) => item.isGroup);

  groups.sort((a, b) => a.name.localeCompare(b.name));

  const organizedData = groups.flatMap((group) => {
    const columns = data.filter((item) => item.group?.value === group.id);
    columns.sort((a, b) => a.name.localeCompare(b.name));

    return [group, ...columns];
  });
  return organizedData;
}

export const InsightViewAttributeDefinitionTable = forwardRef<any, IProps>((props, ref) => {
  const { cubeId, disabled, initialData, onTableChange } = props;

  const [rowData, setRowData] = useState<InsightViewAttributeItem[]>([] as InsightViewAttributeItem[]);
  const [showModalGroupAttribute, setShowModalGroupAttribute] = useState<{
    visible: boolean;
    data?: InsightViewAttributeItem;
  }>({
    visible: false,
  });
  const [showModalAddAttributeDefinition, setShowModalAddAttributeDefinition] = useState<{
    visible: boolean;
    data?: InsightViewAttributeItem;
  }>({
    visible: false,
  });
  const [filter, setFilter] = useState<PaginationAndSortingParams>({
    page: 0,
    size: 100,
    filterValue: '',
    sortType: '',
    filterOperator: '',
  });

  const handleEditClick = (row: InsightViewAttributeItem) => () => {
    if (!row) return;
    if (row.isGroup) {
      setShowModalGroupAttribute({
        visible: true,
        data: row,
      });

      return;
    }
    setShowModalAddAttributeDefinition({
      visible: true,
      data: row,
    });
  };

  const columnsAttribute = useMemo<GridColumns>(() => {
    return [
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Edit',
        width: 30,
        cellClassName: 'actions',

        getActions: (params) => {
          return [
            // @ts-ignore
            <GridActionsCellItem
              icon={<EditIcon />}
              key={params.id}
              label='Edit'
              onClick={handleEditClick(params.row)}
            />,
          ];
        },
      },
      {
        field: 'name',
        headerName: 'Column/Group Name',
        width: 240,
        editable: false,
        filterable: true,
        sortable: true,
        renderCell: (params) => {
          if (params?.row?.isGroup) return <span style={{ fontWeight: 'bold' }}>{params?.value}</span>;
          return <span>{params?.value}</span>;
        },
        filterOperators: getGridStringOperators().filter((operator) => ['contains', 'equals'].includes(operator.value)),
      },

      {
        field: 'relativePeriod',
        headerName: 'Relative Period',
        width: 150,
        editable: false,
        filterable: false,
        sortable: false,
        renderCell: (params) => {
          return getInsightViewRelativeTimeDescription(
            params.row.relativePeriod,
            params.row.relativePeriodType,
            InsightViewRelativeTimeCategory.PERIOD_TIME
          );
        },
      },
      {
        field: 'relativeYear',
        headerName: 'Relative Year',
        width: 150,
        editable: false,
        filterable: false,
        sortable: false,
        renderCell: (params) => {
          return getInsightViewRelativeTimeDescription(
            params.row.relativeYear,
            params.row.relativeYearType,
            InsightViewRelativeTimeCategory.YEAR_TIME
          );
        },
      },
      {
        field: 'cubeLayer',
        headerName: 'Layer',
        width: 160,
        editable: false,
        filterable: true,
        sortable: true,
        renderCell: (params) => {
          return <span>{params?.value?.label}</span>;
        },

        filterOperators: getGridStringOperators().filter((operator) => ['contains', 'equals'].includes(operator.value)),
      },
      {
        field: 'totalRule',
        headerName: 'Total Rule',
        width: 250,
        editable: false,
        filterable: false,
        sortable: false,
        renderCell: (params) => {
          if (params?.row?.isGroup) return <span></span>;
          return <span>{MAP_INSIGHT_VIEW_RULE_WITH_LABEL.get(params?.value?.value) || 'None'}</span>;
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnSortModelChange = (model: GridSortModel) => {
    if (model.length === 0) {
      setFilter({ ...filter, sortType: 'asc' });
      return;
    }
    setFilter({ ...filter, sortType: model[0].sort });
  };

  const handleOnFilterModelChange = (params: GridFilterModel) => {
    let filterObj = {};
    if (params.items.length) {
      filterObj = {
        filterValue: params.items[0].value,
        filterOperator: params.items[0].operatorValue,
      };
    } else {
      filterObj = {
        filterValue: '',
        filterOperator: 'contains',
      };
    }
    setFilter({
      ...filter,
      ...filterObj,
    });
  };

  const handleChangePage = (newPage: number) => {
    setFilter({ ...filter, page: newPage });
  };

  const handleChangePageSize = (newPageSize: number) => {
    setFilter({ ...filter, size: newPageSize });
  };

  const handleOnAddAttributeButtonClick = () => {
    setShowModalAddAttributeDefinition({
      visible: true,
      data: undefined,
    });
  };
  const handleOnAddGroupButtonClick = () => {
    setShowModalGroupAttribute({
      visible: true,
      data: undefined,
    });
  };

  const handleOnModalAddAttributeDefinitionClose = () => {
    setShowModalAddAttributeDefinition({
      visible: false,
      data: undefined,
    });
  };

  const handleOnAddGroup = (groupItem: InsightViewAttributeItem) => {
    const memberGroupAttribute = rowData.filter((item) => item.group?.value === groupItem.id);
    const newMemberGroupAttribute: InsightViewAttributeItem[] = updateMemberGroupAttribute(
      groupItem,
      memberGroupAttribute
    );

    console.log({
      newMemberGroupAttribute,
      groupItem,
    });

    setRowData((prevState) => {
      let newState = _.cloneDeep(prevState);

      // Update the objects in rowData with those from newMemberGroupAttribute
      if (newMemberGroupAttribute.length > 0) {
        newMemberGroupAttribute.forEach((newItem) => {
          const itemIndex = _.findIndex(newState, { id: newItem.id });
          if (itemIndex !== -1) {
            newState[itemIndex] = newItem;
          }
        });
      }

      // Update or add groupItem in newState
      const groupItemIndex = _.findIndex(newState, { id: groupItem.id });
      if (groupItemIndex !== -1) {
        newState[groupItemIndex] = groupItem;
      } else {
        newState.push(groupItem);
      }

      // Reorganize the attribute column
      return organizeAttributeColumn(newState);
    });
  };

  const updateMemberGroupAttribute = (
    groupItem: InsightViewAttributeItem,
    memberGroupAttributes: InsightViewAttributeItem[]
  ) => {
    switch (groupItem.groupTopHeaderType) {
      case INSIGHT_COLUM_GROUP_TOP_HEADER_TYPE.TIME_PERIOD: {
        return memberGroupAttributes.map((item) => {
          return {
            ...item,
            relativePeriod: groupItem.relativePeriod,
            relativePeriodType: groupItem.relativePeriodType,
          };
        });
      }
      case INSIGHT_COLUM_GROUP_TOP_HEADER_TYPE.RELATIVE_YEAR_AND_LAYER: {
        return memberGroupAttributes.map((item) => {
          return {
            ...item,
            relativeYear: groupItem.relativeYear,
            relativeYearType: groupItem.relativeYearType,
            cubeLayer: groupItem.cubeLayer,
          };
        });
      }

      case INSIGHT_COLUM_GROUP_TOP_HEADER_TYPE.TOTAL_RULE: {
        return memberGroupAttributes.map((item) => {
          return {
            ...item,
            totalRule: groupItem.totalRule,
          };
        });
      }

      default: {
        return memberGroupAttributes;
      }
    }
  };

  const handleOnAddNewColumn = (attributeItem: InsightViewAttributeItem) => {
    const itemIndex = _.findIndex(rowData, { id: attributeItem.id });
    if (itemIndex !== -1) {
      const newState = _.cloneDeep(rowData);
      newState[itemIndex] = attributeItem;
      setRowData(organizeAttributeColumn(newState));
    } else {
      const newData = [...rowData, attributeItem];
      setRowData(organizeAttributeColumn(newData));
    }
  };

  const handleOnDeleteColumn = (attributeItem: InsightViewAttributeItem) => {
    const itemIndex = _.findIndex(rowData, { id: attributeItem.id });
    if (itemIndex !== -1) {
      const newState = _.cloneDeep(rowData);
      newState.splice(itemIndex, 1);
      setRowData(newState);
    }
  };

  const handleOnDeleteGroup = (groupItem: InsightViewAttributeItem) => {
    const newState = _.reject(rowData, (item) => item.id === groupItem.id || item.group?.value === groupItem.id);
    setRowData(newState);
  };

  const handleOnModalAddGroupAttributeClose = () => {
    setShowModalGroupAttribute({
      visible: false,
      data: undefined,
    });
  };

  useImperativeHandle(ref, () => ({
    getRowData: () => rowData,
    setRowData: (data: InsightViewAttributeItem[]) => {
      setRowData(data);
    },
  }));

  useEffect(() => {
    onTableChange?.(rowData);
  }, [onTableChange, rowData]);

  const groupData = useMemo(() => {
    return rowData.filter((item) => item.isGroup);
  }, [rowData]);

  const mapGroupIdWithGroupData: Map<string, InsightViewAttributeItem> = useMemo(() => {
    const map = new Map<string, InsightViewAttributeItem>();
    groupData.forEach((item) => {
      map.set(item.id, item);
    });
    return map;
  }, [groupData]);

  const getRowId = useCallback((row) => {
    return row.id;
  }, []);

  useEffect(() => {
    if (initialData) {
      setRowData(organizeAttributeColumn(initialData));
    }
  }, [initialData]);

  return (
    <>
      <InsightViewAttributeDefinitionPanel
        onAddColumnAttributeButtonClick={handleOnAddAttributeButtonClick}
        onAddGroupAttributeButtonClick={handleOnAddGroupButtonClick}
        disabled={disabled}
      />
      <CustomDataTable
        rows={rowData}
        checkboxSelection={false}
        columns={columnsAttribute}
        getRowId={getRowId}
        disableSelectionOnClick
        sortingMode='client'
        filterMode='client'
        paginationMode='client'
        isRowSelectable={(row) => {
          return !row.row.locked;
        }}
        isCellEditable={(params) => {
          return !params?.row?.locked;
        }}
        onSortModelChange={handleOnSortModelChange}
        onFilterModelChange={handleOnFilterModelChange}
        onPageChange={handleChangePage}
        onPageSizeChange={handleChangePageSize}
        rowHeight={45}
        autoHeight={rowData.length !== 0 && rowData.length > 5}
        sx={{ opacity: disabled ? 0.5 : 1, pointerEvents: disabled ? 'none' : 'auto' }}
        onProcessRowUpdateError={(params) => console.log({ params })}
        getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
        experimentalFeatures={{ newEditingApi: true }}
        components={{
          Toolbar: null,
          Pagination: null,
        }}
      />

      <ModalAddGroupAttribute
        visible={showModalGroupAttribute.visible}
        selectedData={showModalGroupAttribute.data}
        onDelete={handleOnDeleteGroup}
        onClose={handleOnModalAddGroupAttributeClose}
        onSave={handleOnAddGroup}
        cubeId={cubeId}
      />
      <ModalAddColumnAttribute
        visible={showModalAddAttributeDefinition.visible}
        onClose={handleOnModalAddAttributeDefinitionClose}
        selectedData={showModalAddAttributeDefinition.data}
        onSave={handleOnAddNewColumn}
        onDelete={handleOnDeleteColumn}
        groupData={groupData}
        mapGroupIdWithGroupData={mapGroupIdWithGroupData}
        cubeId={cubeId}
      />
    </>
  );
});
