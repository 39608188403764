import { useCallback, useEffect, useMemo, useState } from 'react';
import { DropzoneInputProps, DropzoneRootProps, useDropzone } from 'react-dropzone';
import ReactPlayer from 'react-player/lazy';
import ButtonLoading from '@mui/lab/LoadingButton';
import {
  Stack,
  Typography,
  SvgIcon,
  Button,
  IconButton,
  TextField,
  Grid,
  Box,
  LinearProgress,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import AddLinkOutlinedIcon from '@mui/icons-material/AddLinkOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { useDataInputFileUpload } from 'services/v1/Tenant/AROKMS/DisplayTableService';
import { getDropRejectErrorCode, getDropFileErrorMessageByCode } from 'utils/Error';
import { ReactComponent as CustomIconTrash } from 'assets/icons/icon-trash.svg';
import { FormikProps, useFormik } from 'formik';
import { DATA_INPUT_OBJECT_TYPE } from 'constant/DataInputConstant';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';
import { AxiosDefaultErrorEntity } from 'types';
import { isValidUrl, isValidYoutubeUrl } from 'utils/String';

interface DataInputObjectUploadProps {
  name: string;
  onUploadComplete?: (objectIds: string[]) => void;
  subjectId?: string;
  label: string;
  objectType: string | null;
}

const textInputStyles = {
  '& .MuiOutlinedInput': {
    padding: '1px 2px',
  },
};

const formikInitialValues = {
  objects: [],
};
interface FileObject {
  objectName: string;
  objectDescription: string;
  file: File | null;
  filePreviewURL?: string;
  youtubeLink?: string;
  externalLink?: string;
  objectId?: string;
  uploadStatus?: 'uploading' | 'success' | 'error';
}

interface FormikFormValues {
  objects: FileObject[];
}

const styles = {
  textInput: {
    '& .MuiOutlinedInput': {
      padding: '1px 2px',
    },
  },
  radioButtonLabel: {
    '& .MuiFormControlLabel-label': {
      fontSize: '14px',
      color: '#98A2AE',
    },
  },
  radioButton: {
    color: '#98A2AE',
    '& .MuiSvgIcon-root': {
      fontSize: 20,
    },
    '&.Mui-checked': {
      color: '#42BB93',
    },
  },
};
function getAcceptedFileByObject(objectType: string | null) {
  switch (objectType) {
    case DATA_INPUT_OBJECT_TYPE.IMAGE:
      return {
        'image/*': ['.jpg', '.jpeg', '.png'],
      };
    case DATA_INPUT_OBJECT_TYPE.VIDEO:
      return {
        'video/*': ['.mp4'],
      };
    case DATA_INPUT_OBJECT_TYPE.DOCUMENT:
      return {
        'application/pdf': ['.pdf'],
      };
    default:
      return {
        'image/*': ['.jpg', '.jpeg', '.png'],
      };
  }
}

function getFileExtensionDescriptionByObject(objectType: string | null) {
  switch (objectType) {
    case DATA_INPUT_OBJECT_TYPE.IMAGE:
      return 'JPG, JPEG, or PNG';
    case DATA_INPUT_OBJECT_TYPE.VIDEO:
      return 'MP4';
    case DATA_INPUT_OBJECT_TYPE.DOCUMENT:
      return 'PDF';
    default:
      return 'JPG, JPEG, or PNG';
  }
}

function getTitleUploadByObjectType(objectType: string | null) {
  switch (objectType) {
    case DATA_INPUT_OBJECT_TYPE.IMAGE:
      return {
        title: 'Upload Images',
        icon: (
          <ImageOutlinedIcon
            sx={{
              fill: '#42BB93',
            }}
          />
        ),
      };
    case DATA_INPUT_OBJECT_TYPE.VIDEO:
      return {
        title: 'Upload Video',
        icon: (
          <VideocamOutlinedIcon
            sx={{
              fill: '#42BB93',
            }}
          />
        ),
      };
    case DATA_INPUT_OBJECT_TYPE.DOCUMENT:
      return {
        title: 'Upload Document',
        icon: (
          <ArticleOutlinedIcon
            sx={{
              fill: '#42BB93',
            }}
          />
        ),
      };
    case DATA_INPUT_OBJECT_TYPE.LINK:
      return {
        title: 'Upload External Link',
        icon: (
          <AddLinkOutlinedIcon
            sx={{
              fill: '#42BB93',
            }}
          />
        ),
      };
    default:
      return {
        title: 'Upload Images',
        icon: (
          <ImageOutlinedIcon
            sx={{
              fill: '#42BB93',
            }}
          />
        ),
      };
  }
}

const VIDEO_UPLOAD_TYPE = {
  UPLOAD_VIDEO: 'UPLOAD_VIDEO',
  YOUTUBE_VIDEO: 'YOUTUBE_VIDEO',
};

const VIDEO_UPLOAD_OPTIONS = [
  {
    label: 'Upload From your Computer',
    value: VIDEO_UPLOAD_TYPE.UPLOAD_VIDEO,
  },
  {
    label: 'Insert Youtube Video Link',
    value: VIDEO_UPLOAD_TYPE.YOUTUBE_VIDEO,
  },
];

// max file size is 40MB
const MAX_FILE_SIZE = 40 * 1024 * 1024;
export function DataInputObjectUpload(props: DataInputObjectUploadProps) {
  const { name = 'input-upload', onUploadComplete = () => {}, subjectId, label, objectType } = props;
  const [fileError, setFileError] = useState<string | null>(null);
  const [uploadingObjets, setUploadingObjects] = useState<FileObject[]>([]);
  const [selectedVideoUploadType, setSelectedVideoUploadType] = useState(VIDEO_UPLOAD_TYPE.UPLOAD_VIDEO);
  const [inputYoutubeLink, setInputYoutubeLink] = useState<string>('');
  const [inputExternalLink, setInputExternalLink] = useState<string>('');
  const { mutateAsync: uploadObjectFile, isError, error } = useDataInputFileUpload();

  const formik = useFormik<FormikFormValues>({
    initialValues: formikInitialValues,
    onSubmit: () => {},
  });

  const onDropAccepted = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles.length > 0) {
        setFileError(null);
        const existingPreviews = formik.values.objects.map((media) => media?.file?.name);
        const newPreviews = acceptedFiles.filter((preview) => !existingPreviews.includes(preview.name));

        formik.setValues({
          ...formik.values,
          objects: [
            ...formik.values.objects,
            ...newPreviews.map((file) => {
              const fileNameWithoutExtension = file.name.substring(0, file.name.lastIndexOf('.')) || file.name;

              return {
                objectName: fileNameWithoutExtension,
                objectDescription: '',
                file,
                filePreviewURL: URL.createObjectURL(file),
              };
            }),
          ],
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formik.values.objects]
  );

  const onDropRejected = useCallback((rejectedFiles) => {
    setFileError(getDropFileErrorMessageByCode(getDropRejectErrorCode(rejectedFiles[0])));
  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    onDropAccepted,
    onDropRejected,
    maxSize: MAX_FILE_SIZE,
    multiple: true,
    // @ts-ignore
    accept: getAcceptedFileByObject(objectType),
  });

  const handleOnUploadFiles = async () => {
    try {
      if (formik.values.objects.length === 0 || !subjectId || !objectType) {
        return;
      }

      const objectsToUpload = [...formik.values.objects];

      setUploadingObjects(objectsToUpload);

      const uploadPromises = objectsToUpload.map(async (object) => {
        const formData = new FormData();
        formData.append('subjectId', subjectId);
        formData.append('url', '');
        formData.append('objectType', objectType);
        formData.append('objectName', object.objectName);
        formData.append('description', object.objectDescription);
        formData.append('objectId', '');
        formData.append('file', '');
        if (!!object.file) {
          formData.append('file', object.file);
          setUploadingObjects((prev) =>
            prev.map((prevObject) => {
              if (prevObject.file && object.file) {
                return prevObject.file.name === object.file.name
                  ? { ...prevObject, uploadStatus: 'uploading' }
                  : prevObject;
              }
              return prevObject;
            })
          );
        }

        if (object.youtubeLink) {
          formData.delete('url');
          formData.append('url', object.youtubeLink);
        }
        if (object.externalLink) {
          formData.delete('url');
          formData.append('url', object.externalLink);
        }

        const result = await uploadObjectFile(formData);

        if (object.file) {
          setUploadingObjects((prev) =>
            prev.map((prevObject) => {
              if (prevObject.file && object.file) {
                return prevObject.file.name === object.file.name
                  ? { ...prevObject, uploadStatus: result ? 'success' : 'error' }
                  : prevObject;
              }
              return prevObject;
            })
          );
        }

        return result;
      });

      const allResultPromised = await Promise.allSettled(uploadPromises);
      const allResult = allResultPromised.map((result) => result.status === 'fulfilled' && result.value);
      // @ts-ignore
      const objectIds = allResult.filter((result) => result).map((result) => result?.data.objectId);

      setUploadingObjects([]);
      onUploadComplete(objectIds);
      formik.setValues(formikInitialValues);
    } catch (error) {
      console.error(error);
    }
  };

  const handleOnInsertLinkYoutube = () => {
    if (!inputYoutubeLink) {
      return;
    }
    formik.setValues({
      ...formik.values,
      objects: [
        ...formik.values.objects,
        {
          objectName: '',
          objectDescription: '',
          file: null,
          youtubeLink: inputYoutubeLink,
        },
      ],
    });
    setInputYoutubeLink('');
  };

  const handleOnInsertExternalLink = () => {
    if (!inputExternalLink) {
      return;
    }
    formik.setValues({
      ...formik.values,
      objects: [
        ...formik.values.objects,
        {
          objectName: '',
          objectDescription: '',
          file: null,
          externalLink: inputExternalLink,
        },
      ],
    });
    setInputExternalLink('');
  };

  const totalUploading = useMemo(() => {
    return {
      totalUploading: uploadingObjets.filter((object) => object.uploadStatus === 'uploading').length,
      totalSuccess: uploadingObjets.filter((object) => object.uploadStatus === 'success').length,
      totalError: uploadingObjets.filter((object) => object.uploadStatus === 'error').length,
      totalFiles: uploadingObjets.length,
    };
  }, [uploadingObjets]);

  const isUploading = totalUploading.totalUploading > 0;

  useEffect(() => {
    return () => {
      setSelectedVideoUploadType(VIDEO_UPLOAD_TYPE.UPLOAD_VIDEO);
    };
  }, []);

  return (
    <>
      <Stack direction='row' gap={1} alignItems='center' mt={1}>
        {getTitleUploadByObjectType(objectType).icon}
        <Typography variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
          {getTitleUploadByObjectType(objectType).title}
        </Typography>
      </Stack>
      {objectType === DATA_INPUT_OBJECT_TYPE.VIDEO && (
        <VideoUploadTypeSelector selectedType={selectedVideoUploadType} onTypeChange={setSelectedVideoUploadType} />
      )}

      {objectType === DATA_INPUT_OBJECT_TYPE.LINK && (
        <ExternalLinkInput
          inputExternalLink={inputExternalLink}
          setInputExternalLink={setInputExternalLink}
          handleOnInsertExternalLink={handleOnInsertExternalLink}
        />
      )}
      <Stack direction='column' display='flex' justifyContent='space-between' marginTop={2.5} mb={2}>
        {selectedVideoUploadType === VIDEO_UPLOAD_TYPE.YOUTUBE_VIDEO && (
          <YoutubeVideoInput
            inputYoutubeLink={inputYoutubeLink}
            setInputYoutubeLink={setInputYoutubeLink}
            handleOnInsertLinkYoutube={handleOnInsertLinkYoutube}
          />
        )}
        {selectedVideoUploadType === VIDEO_UPLOAD_TYPE.UPLOAD_VIDEO && objectType !== DATA_INPUT_OBJECT_TYPE.LINK && (
          <FileUploadSection
            label={label}
            uploadingObjects={uploadingObjets}
            totalUploading={totalUploading}
            getRootProps={getRootProps}
            getInputProps={getInputProps}
            name={name}
            objectType={objectType}
            isError={isError}
            error={error}
            fileError={fileError}
          />
        )}

        {formik.values.objects.length > 0 && (
          <PreviewSection
            formik={formik}
            isUploading={isUploading}
            objectType={objectType}
            handleOnUploadFiles={handleOnUploadFiles}
          />
        )}
      </Stack>
    </>
  );
}

function PreviewObjectItem(props: {
  object: FileObject;
  formik: FormikProps<any>;
  index: number;
  disabled?: boolean;
  objectType: string | null;
  onDelete: () => void;
}) {
  const { formik, index, object, disabled = false, objectType, onDelete } = props;
  return (
    <Stack
      display='flex'
      justifyContent='space-between'
      alignItems='center'
      direction='row'
      width='100%'
      gap={2}
      padding={1}
      sx={{ opacity: disabled ? 0.5 : 1 }}
      borderBottom='1px solid #E0E0E0'
    >
      <Box position='relative' flexBasis='10%' flexGrow={1} width='40%' style={{ cursor: 'pointer' }}>
        {objectType === DATA_INPUT_OBJECT_TYPE.IMAGE && (
          <img
            src={object.filePreviewURL}
            alt={object.objectName}
            style={{
              objectFit: 'cover',
              width: '100%',
              height: 140,
              borderRadius: 4,
            }}
          />
        )}
        {objectType === DATA_INPUT_OBJECT_TYPE.DOCUMENT && (
          <Stack
            alignItems='center'
            sx={{
              border: '1px solid #E0E0E0',
              borderRadius: 4,
              height: 150,
            }}
            p={2}
          >
            <PictureAsPdfOutlinedIcon style={{ fontSize: 60 }} />
            <Typography variant='input-label-gray' component='p' fontSize={10} textAlign='center'>
              {object?.file?.name}
            </Typography>
          </Stack>
        )}
        {objectType === DATA_INPUT_OBJECT_TYPE.LINK && (
          <Stack
            alignItems='center'
            justifyContent='center'
            sx={{
              border: '1px solid #E0E0E0',
              borderRadius: 4,
              height: 150,
              overflow: 'auto',
            }}
            p={2}
            gap={1}
          >
            <LanguageOutlinedIcon style={{ fontSize: 40 }} />
            <Stack flexDirection='row' width='100%' alignItems='center'>
              <Typography
                variant='input-label-gray'
                component='p'
                fontSize={10}
                textAlign='center'
                sx={{
                  whiteSpace: 'nowrap', // Prevent text from wrapping
                  overflow: 'hidden', // Hide the overflowing text
                  textOverflow: 'ellipsis', // Show ellipsis when the text overflows
                  width: '100%', // Ensure it applies within the parent width
                }}
              >
                {object?.externalLink}
              </Typography>
              <IconButton
                onClick={() => {
                  window.open(object.externalLink, '_blank');
                }}
              >
                <LaunchOutlinedIcon style={{ fontSize: 17, fill: '#42BB93' }} />
              </IconButton>
            </Stack>
          </Stack>
        )}
        {objectType === DATA_INPUT_OBJECT_TYPE.VIDEO &&
          (object.youtubeLink ? (
            <ReactPlayer url={object.youtubeLink} width='100%' height='100%' controls volume={0.6} />
          ) : (
            <video
              src={`${object.filePreviewURL}#t=0.1`}
              style={{
                objectFit: 'cover',
                width: '100%',
                height: 140,
                borderRadius: 4,
              }}
              controls
              preload='metadata'
            />
          ))}

        <IconButton
          size='small'
          disabled={disabled}
          sx={{
            position: 'absolute',
            top: 4,
            right: 4,
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
          }}
        >
          <SvgIcon
            fontSize='small'
            sx={{ fill: '#42BB93' }}
            inheritViewBox
            component={CustomIconTrash}
            onClick={onDelete}
          />
        </IconButton>
      </Box>
      <Stack width='60%' gap={1}>
        {object.file && (
          <Typography variant='input-label-gray' component='p' fontSize={12}>
            {`Size (MB) :  ${(object.file.size / 1024 / 1024).toFixed(2)}`}
          </Typography>
        )}
        <TextField
          sx={textInputStyles}
          name={`objects[${props.index}].objectName`}
          label='Name'
          variant='outlined'
          disabled={disabled}
          onChange={formik.handleChange}
          value={formik.values.objects[index]?.objectName}
          size='small'
          placeholder='e.g. 3'
        />
        <TextField
          sx={textInputStyles}
          name={`objects[${props.index}].objectDescription`}
          label='Description (Optional)'
          variant='outlined'
          value={formik.values.objects[index]?.objectDescription}
          onChange={formik.handleChange}
          multiline
          disabled={disabled}
          size='small'
          minRows={3}
          placeholder='e.g. 3'
        />
      </Stack>
    </Stack>
  );
}

const VideoUploadTypeSelector = ({
  selectedType,
  onTypeChange,
}: {
  selectedType: string;
  onTypeChange: (val: string) => void;
}) => (
  <Stack width='85%' mt={2}>
    <RadioGroup onChange={(event, value) => onTypeChange(value)} value={selectedType}>
      <Stack direction='row' spacing={1}>
        {VIDEO_UPLOAD_OPTIONS.map((option) => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            sx={styles.radioButtonLabel}
            control={<Radio sx={styles.radioButton} />}
            label={option.label}
          />
        ))}
      </Stack>
    </RadioGroup>
  </Stack>
);

const ExternalLinkInput = ({
  inputExternalLink,
  setInputExternalLink,
  handleOnInsertExternalLink,
}: {
  inputExternalLink: string;
  setInputExternalLink: (val: string) => void;
  handleOnInsertExternalLink: () => void;
}) => {
  const [error, setError] = useState<string | null>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInputExternalLink(value);
    if (value && !isValidUrl(value)) {
      setError('Please enter a valid URL');
    } else {
      setError(null);
    }
  };

  const handleInsertLink = () => {
    if (isValidUrl(inputExternalLink)) {
      handleOnInsertExternalLink();
      setError(null);
    } else {
      setError('Please enter a valid URL');
    }
  };
  return (
    <Stack width='100%' mt={2} gap={1}>
      <Stack width='30%'>
        <Typography variant='input-label' fontWeight={800}>
          Insert Link
        </Typography>
      </Stack>
      <Stack width='70%' flexDirection='row' gap={2}>
        <Stack width='70%'>
          <TextField
            sx={styles.textInput}
            name='externalLink'
            hiddenLabel
            value={inputExternalLink}
            onChange={handleChange}
            variant='outlined'
            size='small'
            placeholder='e.g. https://example.com'
            error={!!error}
            helperText={error}
          />
        </Stack>
        <ButtonLoading variant='main-table-panel' onClick={handleInsertLink} disabled={!!error || !inputExternalLink}>
          Add External Link
        </ButtonLoading>
      </Stack>
    </Stack>
  );
};

const YoutubeVideoInput = ({
  inputYoutubeLink,
  setInputYoutubeLink,
  handleOnInsertLinkYoutube,
}: {
  inputYoutubeLink: string;
  setInputYoutubeLink: (val: string) => void;
  handleOnInsertLinkYoutube: () => void;
}) => {
  const [error, setError] = useState<string | null>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInputYoutubeLink(value);
    if (value && !isValidYoutubeUrl(value)) {
      setError('Please enter a valid YouTube URL');
    } else {
      setError(null);
    }
  };

  const handleInsertLink = () => {
    if (isValidYoutubeUrl(inputYoutubeLink)) {
      handleOnInsertLinkYoutube();
      setError(null);
    } else {
      setError('Please enter a valid YouTube URL');
    }
  };

  return (
    <Stack width='100%' mb={2} gap={2}>
      <Stack width='30%'>
        <Typography variant='input-label' fontWeight={800}>
          Youtube Video URL
        </Typography>
      </Stack>
      <Stack width='70%' flexDirection='row' gap={2}>
        <Stack width='70%'>
          <TextField
            sx={styles.textInput}
            name='youtubeLink'
            hiddenLabel
            value={inputYoutubeLink}
            onChange={handleChange}
            variant='outlined'
            size='small'
            placeholder='e.g. https://www.youtube.com/watch?v=123456'
            error={!!error}
            helperText={error}
          />
        </Stack>
        <ButtonLoading variant='main-table-panel' onClick={handleInsertLink} disabled={!inputYoutubeLink || !!error}>
          Insert
        </ButtonLoading>
      </Stack>
    </Stack>
  );
};
const FileUploadSection = ({
  label,
  uploadingObjects,
  totalUploading,
  getRootProps,
  getInputProps,
  name,
  objectType,
  isError,
  error,
  fileError,
}: {
  label: string;
  uploadingObjects: FileObject[];
  totalUploading: {
    totalUploading: number;
    totalSuccess: number;
    totalError: number;
    totalFiles: number;
  };
  name: string;
  objectType: string | null;
  isError: boolean;
  getRootProps: <T extends DropzoneRootProps>(props?: T) => T;
  getInputProps: <T extends DropzoneInputProps>(props?: T) => T;
  error: AxiosDefaultErrorEntity | null;
  fileError: string | null;
}) => (
  <>
    <Typography variant='input-label' fontWeight={700}>
      {label}
    </Typography>

    {uploadingObjects.length > 0 ? (
      <Stack alignItems='center'>
        <Typography variant='input-label' mt={3} mb={1}>
          Uploading {totalUploading.totalSuccess} of {totalUploading.totalFiles} files..
        </Typography>
        <Box sx={{ width: '50%' }}>
          <LinearProgress />
        </Box>
      </Stack>
    ) : (
      <Stack>
        <div {...getRootProps({ className: 'dropzone-data-input' })}>
          <Stack width='100%' textAlign='center' justifyContent='center' alignItems='center'>
            <Button endIcon={<AddCircleOutlineOutlinedIcon />} variant='secondary-table-panel' sx={{ width: '30%' }}>
              Upload File
            </Button>
            <p>or drop file here</p>
            <span>{`${getFileExtensionDescriptionByObject(objectType)} (MAX 40MB)`}</span>
          </Stack>
          <input {...getInputProps()} name={name} />
        </div>
        {isError && <Typography variant='label-error'>{error?.response?.data?.message}</Typography>}
        {fileError && <Typography variant='label-error'>{fileError}</Typography>}
      </Stack>
    )}
  </>
);

const PreviewSection = ({
  formik,
  isUploading,
  objectType,
  handleOnUploadFiles,
}: {
  isUploading: boolean;
  objectType: string | null;
  handleOnUploadFiles: () => void;
  formik: FormikProps<FormikFormValues>;
}) => {
  const handleOnDeletePreviewObject = (index: number) => {
    const currentObjets = [...formik.values.objects];
    currentObjets.splice(index, 1);
    formik.setValues({
      objects: currentObjets,
    });
  };
  return (
    <>
      <Typography variant='input-label' fontWeight={700} my={3}>
        {`Preview (${formik.values.objects.length})`}
      </Typography>
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} height={400} overflow='auto'>
        {formik.values.objects.map((object, index) => (
          <Grid item xs={2} sm={6} md={6} key={index}>
            <PreviewObjectItem
              object={object}
              formik={formik}
              onDelete={() => handleOnDeletePreviewObject(index)}
              index={index}
              disabled={isUploading}
              objectType={objectType}
            />
          </Grid>
        ))}
      </Grid>
      <Stack alignItems='flex-end'>
        <ButtonLoading
          sx={{ width: 200 }}
          variant='main-table-panel'
          onClick={handleOnUploadFiles}
          loading={isUploading}
        >
          Upload
        </ButtonLoading>
      </Stack>
    </>
  );
};
