import jwtDecode from 'jwt-decode';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';

import { RootState } from 'store';
import { checkAccess, checkAccessByAdminRightLevel } from 'routes/PrivateRoute';
import { SidebarItemsProps } from './SidebarItems';
import { ROLES } from 'constant/PermissonConstant';
import { RoleType } from 'store/reducer/authReducer';

export function isAdminHasAccess(
  requiredAdminAccessLevel: number | undefined,
  currentUserRole: RoleType | '',
  decodeToken: any
) {
  return (
    currentUserRole === ROLES.ADMIN &&
    // @ts-ignore
    checkAccessByAdminRightLevel(requiredAdminAccessLevel, decodeToken?.adminRightLevel || 0)
  );
}

export const MultiLevelMenu = ({ item, nested }: { item: SidebarItemsProps; nested: boolean }) => {
  const { threadUserAsRole, accessToken } = useSelector((state: RootState) => state.auth);
  const { pathname } = useLocation();
  const decodeToken = accessToken && (jwtDecode(accessToken) as { adminRightLevel: number });
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { child } = item;
  const [open, setOpen] = useState(false);

  if (!checkAccess(item.allowedRoles, threadUserAsRole)) return null;

  const showParentMenu = child?.some((childItem) => {
    if (childItem.accessAdminRightLevel) {
      return isAdminHasAccess(childItem.accessAdminRightLevel, threadUserAsRole, decodeToken);
    }
    return true;
  });

  if (!showParentMenu) return null;

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  return (
    <>
      <ListItem
        button
        onClick={handleClick}
        sx={{
          backgroundColor: pathname.includes(item.parentPath || '') && !nested ? '#00B3DC' : 'transparent',
          '&:hover': {
            backgroundColor: pathname.includes(item.parentPath || '') && !nested ? '#00B3DC' : '#171A31',
          },
        }}
      >
        <ListItemIcon>{item.icon}</ListItemIcon>
        <ListItemText
          primary={t(item.title)}
          color='#fff'
          primaryTypographyProps={{
            fontSize: 12,
            color: '#fff',
            letterSpacing: 0.3,
            marginLeft: nested ? 1 : 0,
          }}
        />
        {pathname.includes(item.parentPath || '') || open ? (
          <ExpandLessOutlinedIcon sx={{ color: '#fff' }} />
        ) : (
          <ExpandMoreOutlinedIcon sx={{ color: '#fff' }} />
        )}
      </ListItem>
      <Collapse in={pathname.includes(item.parentPath || '') || open} timeout='auto' unmountOnExit>
        <List component='div' sx={{ backgroundColor: '#1C203C' }}>
          {child?.map((childItem, key) => {
            if (childItem.child?.length > 0) {
              // @ts-ignore
              return <MultiLevelMenu item={childItem} nested />;
            }

            if (
              childItem.accessAdminRightLevel === undefined ||
              isAdminHasAccess(childItem.accessAdminRightLevel, threadUserAsRole, decodeToken)
            ) {
              return (
                <ListItem
                  button
                  key={key}
                  sx={{ pl: nested ? 12 : 10, borderRight: pathname === childItem.path ? '3px solid #00B3DC' : 'none' }}
                  onClick={() => {
                    if (childItem.path) {
                      navigate(childItem.path);
                    }
                  }}
                >
                  <ListItemText
                    primary={t(childItem.title)}
                    color='#fff'
                    primaryTypographyProps={{
                      fontSize: 12,
                      color: pathname === childItem.path ? '#00B3DC' : '#fff',
                      letterSpacing: 0.3,
                    }}
                  />
                </ListItem>
              );
            }
            return null;
          })}
        </List>
      </Collapse>
    </>
  );
};
