import * as React from 'react';
import * as Yup from 'yup';
import { styled } from '@mui/material/styles';
import { Backdrop, Popper } from '@mui/material';
import { Paper, Stack } from '@mui/material';
import { useFormik } from 'formik';
import { AutoCompleteItem } from 'types/api/Common/AutoCompleteTypes';
import { useGetCubeDataDetailOptions } from 'services/v1/Tenant/AROCube/CubeDataEntryService';
import { getListBoxPropsAutoScrolItem } from 'utils/Object';
import Autocomplete from '@mui/material/Autocomplete';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import ButtonLoading from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';

const StyledPopper = styled(Popper)(({ theme }) => ({
  zIndex: 1000,
  '&[x-placement*="bottom"] $arrow': {
    top: 0,
    left: 0,
    marginTop: '-0.71em',
    marginLeft: 4,
    marginRight: 4,
    '&::before': {
      transformOrigin: '0 100%',
    },
  },
  '&[x-placement*="top"] $arrow': {
    bottom: 0,
    left: 0,
    marginBottom: '-0.71em',
    marginLeft: 4,
    marginRight: 4,
    '&::before': {
      transformOrigin: '100% 0',
    },
  },
  '&[x-placement*="right"] $arrow': {
    left: 0,
    marginLeft: '-0.71em',
    height: '1em',
    width: '0.71em',
    marginTop: 4,
    marginBottom: 4,
    '&::before': {
      transformOrigin: '100% 100%',
    },
  },
  '&[x-placement*="left"] $arrow': {
    right: 0,
    marginRight: '-0.71em',
    height: '1em',
    width: '0.71em',
    marginTop: 4,
    marginBottom: 4,
    '&::before': {
      transformOrigin: '0 0',
    },
  },
}));

export interface FormCubeDrillThruParameter {
  member: AutoCompleteItem | null;
  timePeriod: AutoCompleteItem | null;
  eventSubject: AutoCompleteItem | null;
}

const tableAnalyticParameterValidatonSchema = Yup.object().shape({
  member: Yup.object().required('Required'),
  timePeriod: Yup.object().required('Required'),
  eventSubject: Yup.object().required('Required'),
});

const formikInitialValues: FormCubeDrillThruParameter = {
  member: null,
  timePeriod: null,
  eventSubject: null,
};

interface ButtonCubeDrillThruProps {
  onDisplayData?: (data: FormCubeDrillThruParameter) => void;
  disabled?: boolean | undefined;
  cubeId?: string;
  initialValue?: {
    member: AutoCompleteItem | null;
    timePeriod: AutoCompleteItem | null;
  };
}

const autocompleteStyles = {
  width: '55%',
  '& .MuiOutlinedInput-root': {
    padding: '1px 2px',
  },
  '& .MuiAutocomplete-popper': {
    fontSize: '10px',
  },
};

export function ButtonCubeDrillThru(props: ButtonCubeDrillThruProps) {
  const { onDisplayData, disabled, cubeId, initialValue } = props;

  const { data: cubeDetailDataOptions } = useGetCubeDataDetailOptions(cubeId);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleOnCancelInput = () => {
    handlePopoverClose();
  };

  const open = Boolean(anchorEl);

  const formik = useFormik<FormCubeDrillThruParameter>({
    initialValues: formikInitialValues,
    validationSchema: tableAnalyticParameterValidatonSchema,
    onSubmit: (values, formikHelper) => {
      handlePopoverClose();
      onDisplayData && onDisplayData(values);
      formikHelper.resetForm();
    },
  });

  React.useEffect(() => {
    if (initialValue && open) {
      formik.setFieldValue('member', initialValue.member);
      formik.setFieldValue('timePeriod', initialValue.timePeriod);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue, open]);

  return (
    <div>
      <Backdrop open={open} sx={{ zIndex: 999 }} />
      <Tooltip title='Data Details'>
        <span data-testid='button-export-label'>
          <IconButton data-testid='button-export' onClick={handlePopoverOpen}>
            <TableChartOutlinedIcon sx={{ color: '#42BB93' }} />
          </IconButton>
        </span>
      </Tooltip>

      <StyledPopper
        open={open}
        anchorEl={anchorEl}
        placement='bottom'
        modifiers={[
          {
            name: 'flip',
            enabled: true,
            options: {
              altBoundary: true,
              rootBoundary: 'document',
              padding: 8,
            },
          },
          {
            name: 'preventOverflow',
            enabled: true,
            options: {
              altAxis: true,
              altBoundary: true,
              tether: true,
              rootBoundary: 'document',
              padding: 8,
            },
          },
        ]}
      >
        <Paper sx={{ py: 2, px: 3, display: 'flex', flexDirection: 'column' }}>
          <Stack width={430} mb={3}>
            <Typography fontWeight='bold' sx={{ fontSize: '18px', mb: 1 }}>
              Data Details
            </Typography>
          </Stack>
          <Stack width='100%' mb={2} flexDirection='row' gap={2} justifyContent='center' alignItems='center'>
            <Stack width='30%'>
              <Typography variant='input-label' fontWeight={800}>
                Member
              </Typography>
            </Stack>
            <Stack width='70%'>
              <Autocomplete
                options={cubeDetailDataOptions?.data?.memberOptions || []}
                value={formik.values.member}
                sx={{ ...autocompleteStyles, width: '100%' }}
                onChange={(event, newValue) => {
                  formik.setFieldValue('member', newValue);
                }}
                ListboxProps={{
                  ...getListBoxPropsAutoScrolItem(
                    cubeDetailDataOptions?.data?.memberOptions || [],
                    formik.values.member
                  ),
                }}
                clearIcon={null}
                renderInput={(params) => <TextField {...params} placeholder='Select Attribute...' />}
              />
            </Stack>
          </Stack>
          <Stack width='100%' mb={2} flexDirection='row' gap={2} justifyContent='center' alignItems='center'>
            <Stack width='30%'>
              <Typography variant='input-label' fontWeight={800}>
                Week
              </Typography>
            </Stack>
            <Stack width='70%'>
              <Autocomplete
                options={cubeDetailDataOptions?.data?.timePeriodOptions || []}
                value={formik.values.timePeriod}
                defaultValue={initialValue?.timePeriod}
                sx={{ ...autocompleteStyles, width: '100%' }}
                onChange={(event, newValue) => {
                  formik.setFieldValue('timePeriod', newValue);
                }}
                slot=''
                ListboxProps={{
                  ...getListBoxPropsAutoScrolItem(
                    cubeDetailDataOptions?.data?.timePeriodOptions || [],
                    formik.values.timePeriod
                  ),
                }}
                clearIcon={null}
                renderInput={(params) => <TextField {...params} placeholder='Select Attribute...' />}
              />
            </Stack>
          </Stack>
          <Stack width='100%' mb={2} flexDirection='row' gap={2} justifyContent='center' alignItems='center'>
            <Stack width='30%'>
              <Typography variant='input-label' fontWeight={800}>
                Data
              </Typography>
            </Stack>
            <Stack width='70%'>
              <Autocomplete
                options={cubeDetailDataOptions?.data?.attributeOptions || []}
                value={formik.values.eventSubject}
                sx={{ ...autocompleteStyles, width: '100%' }}
                onChange={(event, newValue) => {
                  formik.setFieldValue('eventSubject', newValue);
                }}
                clearIcon={null}
                renderInput={(params) => <TextField {...params} placeholder='Select Attribute...' />}
              />
            </Stack>
          </Stack>

          <Stack direction='row' spacing={2} justifyContent='flex-end'>
            <Button onClick={handleOnCancelInput} variant='main-table-panel-border'>
              Cancel
            </Button>

            <ButtonLoading
              variant='main-table-panel'
              onClick={() => formik.handleSubmit()}
              disabled={!tableAnalyticParameterValidatonSchema.isValidSync(formik.values) || disabled}
            >
              Display
            </ButtonLoading>
          </Stack>
        </Paper>
      </StyledPopper>
    </div>
  );
}
