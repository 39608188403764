import { ChartCSSProvider } from 'components/ChartComponent/ChartCSSProvider';
import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

const data = [
  {
    name: 'Asset Count',
    Predictions: 4000,
    'Current Data': 2400,
    amt: 2400,
  },
  {
    name: 'Market Value',
    Predictions: 3000,
    'Current Data': 1398,
    amt: 2210,
  },
  {
    name: 'Debt',
    Predictions: 2000,
    'Current Data': 9800,
    amt: 2290,
  },
  {
    name: 'Equity',
    Predictions: 2780,
    'Current Data': 3908,
    amt: 2000,
  },
];

export function DashboardChart() {
  return (
    <ChartCSSProvider>
      <BarChart
        width={700}
        height={300}
        data={data}
        maxBarSize={40}
        margin={{
          top: 20,
          right: 30,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey='name' stroke='#656666' tick={{ fontSize: 13, color: '#fff' }} />
        <YAxis tick={{ fontSize: 13 }} />
        <Tooltip />
        <Legend />
        <Bar dataKey='Current Data' fill='#AACEF2' />
        <Bar dataKey='Predictions' fill='#AAF2D8' />
      </BarChart>
    </ChartCSSProvider>
  );
}
