import { Box, List, ListItem, ListItemText, Typography } from '@mui/material';
import { NAV_ITEM_TYPE } from './HeaderBar';
import { PATH_CONSTANT } from 'constant/PathConstant';
import { useNavigate } from 'react-router-dom';

export type BreadCumbItemType = {
  label: string;
  type: string;
  id: string;
};

const breadCumbItemTextStyle = {
  fontSize: '14px',
  lineHeight: '20px',
  fontWeight: 500,
  color: '#fff',
};

const breadCumbListContainerStyle = {
  display: 'flex',
  alignItems: 'center',
  gap: '1px',
  color: '#fff',
  flexDirection: 'row',
  p: 0,
};

const breadCumbContainerStyle = {
  backgroundColor: '#3B4797',
  height: '33px',
  display: 'flex',
  zIndex: -1,
  px: 3,
};

export function NavBarStatusBar(props: {
  breadCumbItems?: BreadCumbItemType[];
  selectedBreadCumbItem?: BreadCumbItemType;
  onBreadCumbItemClick?: (item: BreadCumbItemType) => void;
}) {
  const { breadCumbItems = [] } = props;
  const navigate = useNavigate();

  const handleOnSubjectClick = (item: BreadCumbItemType) => {
    if (item.type === NAV_ITEM_TYPE.SUBJECT) {
      const navigateUrl = PATH_CONSTANT.TENANT.KMS.DATA_INPUT.replace(':subjectId', item.id);
      navigateUrl && navigate(navigateUrl);
    }
  };

  return (
    <Box sx={breadCumbContainerStyle}>
      <List sx={breadCumbListContainerStyle}>
        {breadCumbItems.map((item, index) => {
          const isFirstItem = index === 0;
          const isLastItem = breadCumbItems.length - 1 === index;
          const isSubject = item.type === NAV_ITEM_TYPE.SUBJECT;
          const isHiglightSubjectAsMenu = isSubject && !isLastItem;
          return (
            <ListItem
              key={item.label}
              sx={{
                height: '100%',
                width: 'auto',
                px: 1.2,
                bgcolor: isFirstItem ? '#4D5AAE' : 'transparent',
                cursor: isHiglightSubjectAsMenu ? 'pointer' : 'default',
                textDecoration: isHiglightSubjectAsMenu ? 'underline' : 'none',
              }}
              onClick={() => handleOnSubjectClick(item)}
            >
              <ListItemText primary={<Typography sx={breadCumbItemTextStyle}>{item.label}</Typography>} />
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
}
