import Snackbar from '@mui/material/Snackbar';
import { useState, useMemo, useEffect } from 'react';
import {
  GridColDef,
  GridRowModesModel,
  GridRowModes,
  GridRowEditStopParams,
  GridSelectionModel,
  GridRowId,
  GridFilterModel,
  GridSortModel,
  getGridStringOperators,
  getGridDateOperators,
} from '@mui/x-data-grid-pro';
import Alert, { AlertProps } from '@mui/material/Alert';
import { dayjs } from 'utils/DateUtils';
import { ENV_CONFIG } from 'config/env';
import { CustomDataTable } from 'components/DatatableComponent';
import { useDeleteAttributeData } from 'services/v1/SystemTenant/AROKMS/AttributeService';
import { PaginationAndSortingParams } from 'types/api/Common/PaginationTypes';
import ModalDeleteComponent, { ModalDeleteState } from 'components/ModalComponent/ModalDeleteComponent';
import { useServerList } from 'services/v1/TenantMaster/MasterTable/ServerTableDataService';
import { ServerItem } from 'types/api/TenantMaster/MasterTable/ServerTableDataTypes';
import { ModalAddServerComponent } from '../components/ModalAddServerComponent';
import { getErrorMessage, getErrorTitle } from 'utils/Error';
import { TenantHomeDashboardNavigationBar } from 'components/LayoutComponent/VerticalLayout/HeaderBar';
import { useNavigate } from 'react-router';
import { PATH_CONSTANT } from '../../../constant/PathConstant';
import { ButtonRecordEdit } from '../../Tenant/AROKMS/DataInput/components/ButtonRecordEdit';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { SettingPanel } from 'pages/Tenant/AROKMS/MemberList/components/MemberListPanel';
const panelStyle = {
  transform: 'translate3d(704px, 140.667px, 0px) !important',

  '& .MuiDataGrid-panelFooter': {
    display: 'none',
  },
};

export default function ServerTableDataPage() {
  const [modalAddServer, setModalAddServer] = useState<{
    visible: boolean;
    key: number;
    type: string;
    editId: string | null;
  }>({
    visible: false,
    key: 0,
    type: 'ADD',
    editId: null,
  });
  const [rowData, setRowData] = useState<ServerItem[]>([]);
  const navigate = useNavigate();
  const [modalDeleteState, setModalDeleteState] = useState<ModalDeleteState>({ open: false, message: '' });
  const [selectedDataID, setSelectedDataID] = useState<GridRowId[]>([]);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [filter, setFilter] = useState<PaginationAndSortingParams>({
    page: 1,
    size: 10,
    filterValue: '',
    sortType: 'asc',
    sortBy: 'serverName',
    filterColumn: 'serverName',
    filterOperator: 'contains',
  });

  const [filterButtonElement, setFilterButtonElement] = useState<HTMLButtonElement | null>(null);
  const [snackbar, setSnackbar] = useState<Pick<AlertProps, 'children' | 'severity'> | null>(null);

  const { data: serverList, isLoading, isError, error } = useServerList(filter);
  const { mutateAsync: deletAttributeById } = useDeleteAttributeData(filter);

  const columns = useMemo<GridColDef[]>(() => {
    return [
      {
        field: 'action',
        type: 'actions',
        headerName: 'Edit',
        width: 30,
        editable: false,
        filterable: false,
        renderCell: (params: GridRenderCellParams<ServerItem>) => {
          return (
            // @ts-ignore
            <ButtonRecordEdit
              onClick={() => {
                navigate(PATH_CONSTANT.TENANT_MASTER.MASTER_TABLE.EDIT_SERVER.replace(':id', params.id as string));
              }}
            />
          );
        },
      },
      {
        field: 'serverName',
        headerName: 'Server',
        width: 150,
        editable: true,
        filterOperators: getGridStringOperators().filter((operator) => ['contains', 'equals'].includes(operator.value)),
      },
      {
        field: 'description',
        headerName: 'Description',
        width: 240,
        editable: true,
        filterOperators: getGridStringOperators().filter((operator) => ['contains', 'equals'].includes(operator.value)),
      },
      {
        field: 'url',
        headerName: 'URL',
        width: 200,
        editable: true,
        filterOperators: getGridStringOperators().filter((operator) => ['contains', 'equals'].includes(operator.value)),
      },
      {
        field: 'serverStatus',
        headerName: 'Status',
        width: 200,
        editable: true,
        filterOperators: getGridStringOperators().filter((operator) => ['contains', 'equals'].includes(operator.value)),
      },
      {
        field: 'createdBy',
        headerName: 'Created By',
        width: 200,
        editable: true,
        filterOperators: getGridStringOperators().filter((operator) => ['contains', 'equals'].includes(operator.value)),
      },
      {
        field: 'createdDate',
        headerName: 'Created Date',
        width: 200,
        editable: true,
        valueFormatter: (params) => {
          if (!params.value) return '';
          return `${dayjs(params.value).format(ENV_CONFIG.config.DATE_FORMAT)}`;
        },
        type: 'date',
        filterOperators: getGridDateOperators().filter(
          (operator) => !['onOrAfter', 'onOrBefore', 'isEmpty', 'isNotEmpty'].includes(operator.value)
        ),
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (serverList?.data?.data) {
      setRowData(serverList?.data?.data);
      setRowModesModel(
        serverList?.data?.data?.reduce((acc, item) => {
          return { ...acc, [item.id]: GridRowModes.View };
        }, {})
      );
    }
  }, [serverList]);

  const handleChangePage = (newPage: number) => {
    setFilter({ ...filter, page: newPage + 1 });
  };
  const handleChangePageSize = (newPageSize: number) => {
    setFilter({ ...filter, size: newPageSize });
  };

  const handleOnFilter = (params: GridFilterModel) => {
    let filterObj = {};
    if (params.items.length) {
      filterObj = {
        filterValue: params.items[0].value,
        filterOperator: params.items[0].operatorValue,
        filterColumn: params.items[0].columnField,
      };
    } else {
      filterObj = {
        filterValue: '',
        filterOperator: 'contains',
        filterColumn: 'serverName',
      };
    }
    setFilter({
      ...filter,
      ...filterObj,
    });
  };

  const handleOnSortModelChange = (model: GridSortModel) => {
    if (model.length === 0) {
      setFilter({ ...filter, sortType: 'asc', sortBy: 'serverName' });
      return;
    }
    setFilter({ ...filter, sortType: model[0].sort, sortBy: model[0].field });
  };

  const handleRowEditStop = (params: GridRowEditStopParams) => {
    setRowModesModel({ ...rowModesModel, [params.id]: { mode: GridRowModes.View } });
  };

  const handleCloseSnackbar = () => setSnackbar(null);

  const handleOnInsertClick = () => {
    navigate(PATH_CONSTANT.TENANT_MASTER.MASTER_TABLE.ADD_SERVER);
  };

  const handleDeleteData = async () => {
    try {
      if (selectedDataID.length === 0) return;
      const multipleDeleteRequest = selectedDataID.map((id) => deletAttributeById({ id }));
      await Promise.all(multipleDeleteRequest);
      setModalDeleteState({
        ...modalDeleteState,
        open: false,
        message: '',
      });
    } catch (err) {
      setSnackbar({ children: 'Something went wrong when deleting data!', severity: 'error' });
    }
  };

  const handleOnClickDeleteButton = () => {
    const selectedData = rowData.filter((item) => selectedDataID.includes(item.id));
    setModalDeleteState({
      ...modalDeleteState,
      open: true,
      message: `Server : ${selectedData.map((item) => item.serverName).join(', ')}`,
    });
  };

  return (
    <TenantHomeDashboardNavigationBar>
      <CustomDataTable
        initialState={{
          pinnedColumns: {
            left: ['action'],
          },
          pagination: {
            page: 0,
          },
          sorting: {
            sortModel: [
              {
                field: 'serverName',
                sort: 'asc',
              },
            ],
          },
        }}
        isError={isError}
        errorMessage={error && getErrorMessage(error)}
        errorTitle={getErrorTitle(error)}
        loading={isLoading}
        rows={rowData}
        columns={columns}
        rowCount={serverList?.data?.rowCount}
        page={serverList?.data?.page?.pageNumber || 0}
        pageSize={filter.size}
        getRowId={(row) => row.id}
        rowModesModel={rowModesModel}
        disableSelectionOnClick
        checkboxSelection={false}
        rowsPerPageOptions={[5, 10, 20]}
        getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
        experimentalFeatures={{ newEditingApi: true }}
        onFilterModelChange={handleOnFilter}
        onSortModelChange={handleOnSortModelChange}
        onPageChange={handleChangePage}
        onPageSizeChange={handleChangePageSize}
        onSelectionModelChange={(selectedId: GridSelectionModel) => {
          setSelectedDataID(selectedId);
        }}
        components={{
          Toolbar: SettingPanel,
        }}
        onRowDoubleClick={(params) => {
          setModalAddServer((prevState) => ({
            ...prevState,
            visible: true,
            key: prevState.key + 1,
            type: 'EDIT',
            editId: params.id as string,
          }));
        }}
        onRowEditStop={handleRowEditStop}
        componentsProps={{
          panel: {
            sx: panelStyle,
            anchorEl: filterButtonElement,
          },
          toolbar: {
            setRows: setRowData,
            setRowModesModel,
            onDeleteData: handleOnClickDeleteButton,
            onAddDataClick: handleOnInsertClick,
            setFilterButtonElement: setFilterButtonElement,
            disabled: isLoading,
            disabledDeleteButton: selectedDataID.length === 0,
            title: 'Server List',
            config: {
              withExport: false,
              deleteButtonText: 'Delete',
              fieldFocusOnInsert: '',
              insertButtonText: 'Add New Server',
              onInsertClick: handleOnInsertClick,
              insertInitialObject: {
                attribute: '',
                isNew: true,
              },
            },
          },
        }}
      />

      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          onClose={handleCloseSnackbar}
          autoHideDuration={3000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
      <ModalAddServerComponent
        key={`modal-add-server-${modalAddServer.key}`}
        visible={modalAddServer.visible}
        editId={modalAddServer.editId}
        type={modalAddServer.type}
        onClose={() => setModalAddServer((prevState) => ({ ...prevState, visible: false, key: prevState.key + 1 }))}
      />

      <ModalDeleteComponent
        visible={modalDeleteState.open}
        message={modalDeleteState.message}
        onApprove={handleDeleteData}
        onClose={() => setModalDeleteState({ ...modalDeleteState, open: false })}
        onCancel={() => setModalDeleteState({ ...modalDeleteState, open: false })}
      />
    </TenantHomeDashboardNavigationBar>
  );
}
