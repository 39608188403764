import { useState, useCallback, useRef, useEffect, useMemo, CSSProperties } from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import Divider from '@mui/material/Divider';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import LightGallery from 'lightgallery/react';
import ActivityIndicator from 'components/ActivityIndicatorComponent';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';
import DescriptionIcon from '@mui/icons-material/Description';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import lgVideo from 'lightgallery/plugins/video';
import { DataInputObjectUpload } from './UploadObject';
import { useDeleteDataInputObject, useGetDisplayObjects } from 'services/v1/Tenant/AROKMS/DisplayTableService';
import { DataInputObjectFile } from 'types/api/Tenant/AROKMS/DisplayTableTypes';
import { DATA_INPUT_OBJECT_TYPE } from 'constant/DataInputConstant';
import { GalleryItem } from 'lightgallery/lg-utils';
import { ModalPDFViewer } from './ModalPDFViewer';
import { ModalVideoViewer } from './ModalVideoViewer';
import { getYoutubeThumbnailFromURL } from 'utils/String';
import { Box, Tooltip } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import ModalDeleteDataInputObjectConfirmation from './ModalDeleteConfirmation';

import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';

const Styles = {
  modalStyle: {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    bgcolor: 'background.paper',
    borderRadius: 1,
    px: 3,
    py: 2,
  },
  modalContentStyle: {
    height: '75vh',
    overflow: 'auto',
  },
  itemOverlay: {
    flexBasis: '10%',
    flexGrow: 1,
    height: 'auto',
    aspectRatio: '1 / 1',
    maxWidth: '10%',
    cursor: 'pointer',
    '&:hover .overlay': {
      opacity: 1,
    },
  },
  boxOverlay: {
    opacity: 0,
    transition: 'opacity 0.3s',
  },
  imgStyle: {
    objectFit: 'cover',
    width: '100%',
    height: '100%',
  },
  videoStyle: {
    objectFit: 'cover',
    flexBasis: '10%',
    flexGrow: 1,
    height: '100%',
    maxWidth: '100%',
    cursor: 'pointer',
  },
  addButtonBox: {
    flexBasis: '10%',
    flexGrow: 1,
    height: 'auto',
    maxWidth: '10%',
    border: '2px dashed #ccc',
    display: 'flex',
    justifyContent: 'center',
    textTransform: 'none',
    alignItems: 'center',
    aspectRatio: 1,
  },
  contentBoxItem: {
    border: '1px solid #E0E0E0',
    height: '100%',
    width: '100%',
    cursor: 'pointer',
  },
  transitionStyles: {
    entering: { transform: 'translateX(-100%)', opacity: 0, position: 'absolute' } as React.CSSProperties,
    entered: {
      transform: 'translateX(0)',
      opacity: 1,
      transition: 'transform 0.3s, opacity 0.3s',
    } as React.CSSProperties,
    exiting: {
      transform: 'translateX(100%)',
      opacity: 0,
      position: 'absolute',
    } as React.CSSProperties,
    exited: {
      transform: 'translateX(0)',
      opacity: 1,
      transition: 'transform 0.3s, opacity 0.3s',
    } as React.CSSProperties,
  },
};

const closeIconStyle = { color: '#98A2AE', cursor: 'pointer' };

interface ModalPreviewAttachmentProps {
  visible?: boolean;
  onClose: () => void;
  onChange: (fieldName: string, value: string | null) => void;
  fileUrl: string;
  subjectId: string;
  fieldName: string;
  fieldLabel: string;
  initialObjectIds?: string[];
  viewOnly?: boolean;
}

interface PreviewAttachmentData {
  [key: string]: DataInputObjectFile[];
}

const MODAL_CONTENT = {
  ALL_CONTENT: 'ALL_CONTENT',
  UPLOAD_CONTENT: 'UPLOAD_CONTENT',
};

const MAP_DOC_TYPE_WITH_ATTACHMENT_TYPE = new Map<string, string>([
  ['IMAGE', 'IMAGES_ATTACHMENT'],
  ['DOCUMENT', 'DOCUMENTS_ATTACHMENT'],
  ['VIDEO', 'VIDEO_CONTENT'],
  ['LINK', 'EXTERNAL_LINKS'],
]);

export function ModalPreviewAttachment(props: ModalPreviewAttachmentProps) {
  const {
    onClose,
    visible = false,
    subjectId,
    fieldName,
    onChange,
    initialObjectIds,
    fieldLabel,
    viewOnly = true,
  } = props;
  const lightGallery = useRef<any>(null);

  const [selectedItemToDelete, setSelectedItemToDelete] = useState<{
    data: DataInputObjectFile;
    sectionIndex: number;
  } | null>(null);

  const [selectedContent, setSelectedContent] = useState<{
    page: string;
    objectType: string | null;
  }>({
    page: MODAL_CONTENT.ALL_CONTENT,
    objectType: null,
  });

  const [selectedPDFViewer, setSelectedPDFViewer] = useState<{
    open: boolean;
    title: string;
    pdfURL: string;
  }>({
    open: false,
    title: '',
    pdfURL: '',
  });

  const [selectedVideoViewer, setSelectedVideoViewer] = useState<{
    open: boolean;
    title: string;
    videoURL: string;
  }>({
    open: false,
    title: '',
    videoURL: '',
  });

  const [objectIds, setObjectIds] = useState<string[]>([]);

  const [previewData, setPreviewData] = useState<PreviewAttachmentData>({
    IMAGES_ATTACHMENT: [],
    DOCUMENTS_ATTACHMENT: [],
    VIDEO_CONTENT: [],
    EXTERNAL_LINKS: [],
  });

  const [objectIdsToDelete, setObjectIdsToDelete] = useState<string[]>([]);
  const { data: objectsData, isLoading: isLoadingObjectData } = useGetDisplayObjects(objectIds, subjectId);

  const { mutateAsync: deleteDataInputObject, isLoading: isDeleting } = useDeleteDataInputObject();

  const onInit = useCallback((detail) => {
    if (detail) {
      lightGallery.current = detail.instance;
    }
  }, []);

  const handleImageClick = useCallback(
    (index: number) => {
      lightGallery?.current?.openGallery(index);
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [lightGallery?.current]
  );
  const handleOnBackToList = () => {
    setSelectedContent({
      page: MODAL_CONTENT.ALL_CONTENT,
      objectType: null,
    });
  };
  const handleCloseModal = () => {
    onClose();
    setObjectIds([]);
    setPreviewData({
      IMAGES_ATTACHMENT: [],
      DOCUMENTS_ATTACHMENT: [],
      VIDEO_CONTENT: [],
      EXTERNAL_LINKS: [],
    });
  };

  const handleOnSaveClick = async () => {
    try {
      if (objectIdsToDelete.length > 0 && initialObjectIds) {
        await deleteDataInputObject({ ids: objectIdsToDelete });
        const newObjectIds = initialObjectIds?.filter((objId) => !objectIdsToDelete.includes(objId));
        onChange(fieldName, newObjectIds.length > 0 ? newObjectIds?.join(',') : null);
      }

      handleCloseModal();
    } catch {}
  };

  const handleOnClickAddMoreObject = (objectType: string) => {
    setSelectedContent({
      page: MODAL_CONTENT.UPLOAD_CONTENT,
      objectType,
    });
  };

  const handleOnUploadCompleted = (newObjectIds: string[]) => {
    const updateObjectIds = [...objectIds, ...newObjectIds];
    setObjectIds(updateObjectIds);
    handleOnBackToList();
    onChange(fieldName, updateObjectIds.join(','));
  };

  const galleryDynamicElement: GalleryItem[] = useMemo(() => {
    return (
      objectsData?.data
        ?.filter((item) => item.objectType === DATA_INPUT_OBJECT_TYPE.IMAGE)
        .map((item) => {
          return {
            src: item.objectType === DATA_INPUT_OBJECT_TYPE.IMAGE ? item.link : undefined,
            video: {
              source: [{ src: item.link, type: 'video/mp4' }],
              attributes: { preload: 'metadata', autoplay: true, controls: true, muted: true },
            },
            thumb: item.link,
            title: item.objectName,
            subHtml: `<h4>${item.objectName}</h4><p>${item.description}</p>`,
          } as GalleryItem;
        }) || []
    );
  }, [objectsData?.data]);

  useEffect(() => {
    if (initialObjectIds) {
      setObjectIds(initialObjectIds);
    }
  }, [initialObjectIds]);

  useEffect(() => {
    if (objectsData?.data) {
      setPreviewData({
        IMAGES_ATTACHMENT: objectsData?.data?.filter((item) => item.objectType === 'IMAGE'),
        DOCUMENTS_ATTACHMENT: objectsData?.data?.filter((item) => item.objectType === 'DOCUMENT'),
        VIDEO_CONTENT: objectsData?.data?.filter((item) => item.objectType === 'VIDEO'),
        EXTERNAL_LINKS: objectsData?.data?.filter((item) => item.objectType === 'LINK'),
      });
    }
  }, [objectsData?.data]);

  const isShowList = selectedContent.page === MODAL_CONTENT.ALL_CONTENT;

  const handleOnDeleteObjectItem = (itemObj: DataInputObjectFile, sectionIndex: number) => {
    const docType = MAP_DOC_TYPE_WITH_ATTACHMENT_TYPE.get(itemObj.objectType);
    if (docType) {
      if (Boolean(itemObj.id)) {
        setObjectIdsToDelete((prevData) => [...prevData, itemObj.id]);
      }
      const currentData = { ...previewData };

      const currentSectionData = currentData[docType];
      currentSectionData.splice(sectionIndex, 1);
      setPreviewData((oldData) => ({
        ...oldData,
        [docType]: currentSectionData,
      }));
    }
    handleOnModalDeleteClose();
  };

  const handleOnModalDeleteClose = () => {
    setSelectedItemToDelete(null);
  };

  return (
    <>
      <div>
        <Modal open={visible} sx={{ zIndex: 1000 }}>
          <Stack direction='column' sx={Styles.modalStyle} justifyContent='space-between'>
            {isLoadingObjectData && (
              <Stack height={450}>
                <ActivityIndicator />
              </Stack>
            )}
            {!isLoadingObjectData && (
              <>
                <Stack>
                  <Stack direction='column'>
                    <Stack justifyContent='space-between' direction='row' alignItems='center'>
                      {selectedContent.page === MODAL_CONTENT.UPLOAD_CONTENT && (
                        <Button
                          variant='text'
                          disableRipple
                          onClick={handleOnBackToList}
                          startIcon={<KeyboardBackspaceOutlinedIcon />}
                        >
                          Back to list
                        </Button>
                      )}
                      {selectedContent.page === MODAL_CONTENT.ALL_CONTENT && (
                        <>
                          <Typography variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
                            {fieldLabel}
                          </Typography>
                          <IconButton onClick={handleCloseModal} component='label'>
                            <HighlightOffRoundedIcon sx={closeIconStyle} />
                          </IconButton>
                        </>
                      )}
                    </Stack>
                    <Divider sx={{ mb: 0 }} />
                  </Stack>
                  <Stack mt={1} sx={Styles.modalContentStyle}>
                    <Stack style={isShowList ? Styles.transitionStyles.entered : Styles.transitionStyles.exiting}>
                      <Stack mb={2}>
                        <Typography variant='input-label-gray' component='h2' fontWeight='bold'>
                          Images Content ({previewData.IMAGES_ATTACHMENT.length})
                        </Typography>
                        <Stack direction='row' gap={1} mt={1}>
                          {previewData.IMAGES_ATTACHMENT?.map((item, index) => (
                            <Box key={item.objectName} position='relative' sx={Styles.itemOverlay}>
                              <img src={item.link} alt={item.objectName} style={Styles.imgStyle as CSSProperties} />
                              <ContentOverlayAction
                                viewOnly={viewOnly}
                                objectName={item.objectName}
                                onDeleteClick={() => {
                                  setSelectedItemToDelete({
                                    data: item,
                                    sectionIndex: index,
                                  });
                                }}
                                onViewClick={() => handleImageClick(index)}
                              />
                            </Box>
                          ))}
                          {!viewOnly && (
                            <AddMoreContentItemButton
                              onClick={() => handleOnClickAddMoreObject(DATA_INPUT_OBJECT_TYPE.IMAGE)}
                              title='Add Images'
                            />
                          )}
                        </Stack>
                      </Stack>

                      <Stack mb={2}>
                        <Typography variant='input-label-gray' component='h2' fontWeight='bold'>
                          Document Content ({previewData.DOCUMENTS_ATTACHMENT.length})
                        </Typography>
                        <Stack direction='row' gap={1} mt={1}>
                          {previewData.DOCUMENTS_ATTACHMENT?.map((item, index) => (
                            <Box key={item.objectName} position='relative' sx={Styles.itemOverlay}>
                              <Stack alignItems='center' sx={Styles.contentBoxItem} justifyContent='center' p={2}>
                                <DescriptionIcon style={{ fontSize: 30 }} />
                                <Typography
                                  variant='input-label-gray'
                                  component='p'
                                  fontSize='70%'
                                  textAlign='center'
                                  textOverflow='ellipsis'
                                  overflow='hidden'
                                  whiteSpace='nowrap'
                                  width='100%'
                                >
                                  {item.objectName}
                                </Typography>
                              </Stack>
                              <ContentOverlayAction
                                viewOnly={viewOnly}
                                objectName={item.objectName}
                                onDeleteClick={() => {
                                  setSelectedItemToDelete({
                                    data: item,
                                    sectionIndex: index,
                                  });
                                }}
                                onViewClick={() =>
                                  setSelectedPDFViewer({
                                    open: true,
                                    title: item.objectName,
                                    pdfURL: item.link,
                                  })
                                }
                              />
                            </Box>
                          ))}
                          {!viewOnly && (
                            <AddMoreContentItemButton
                              onClick={() => handleOnClickAddMoreObject(DATA_INPUT_OBJECT_TYPE.DOCUMENT)}
                              title='Add Documents'
                            />
                          )}
                        </Stack>
                      </Stack>
                      <Stack mb={2}>
                        <Typography variant='input-label-gray' component='h2' fontWeight='bold'>
                          Video Content ({previewData.VIDEO_CONTENT.length})
                        </Typography>
                        <Stack direction='row' gap={1} mt={1}>
                          {previewData.VIDEO_CONTENT?.map((item, index) => (
                            <Box key={item.objectName} position='relative' sx={Styles.itemOverlay}>
                              <video
                                key={item.objectName}
                                src={`${item.link}#t=0.1`}
                                poster={getYoutubeThumbnailFromURL(item.link)}
                                preload='metadata'
                                style={Styles.videoStyle as CSSProperties}
                                onClick={() =>
                                  setSelectedVideoViewer({ open: true, title: item.objectName, videoURL: item.link })
                                }
                              />
                              <ContentOverlayAction
                                viewOnly={viewOnly}
                                objectName={item.objectName}
                                onDeleteClick={() => {
                                  setSelectedItemToDelete({
                                    data: item,
                                    sectionIndex: index,
                                  });
                                }}
                                onViewClick={() =>
                                  setSelectedVideoViewer({
                                    open: true,
                                    title: item.objectName,
                                    videoURL: item.link,
                                  })
                                }
                              />
                            </Box>
                          ))}
                          {!viewOnly && (
                            <AddMoreContentItemButton
                              onClick={() => handleOnClickAddMoreObject(DATA_INPUT_OBJECT_TYPE.VIDEO)}
                              title='Add Videos'
                            />
                          )}
                        </Stack>
                      </Stack>
                      <Stack mb={2}>
                        <Typography variant='input-label-gray' component='h2' fontWeight='bold'>
                          External Link Content ({previewData.EXTERNAL_LINKS.length})
                        </Typography>
                        <Stack direction='row' gap={1} mt={1}>
                          {previewData.EXTERNAL_LINKS?.map((item, index) => (
                            <Box key={item.objectName} position='relative' sx={Styles.itemOverlay}>
                              <Stack alignItems='center' sx={Styles.contentBoxItem} justifyContent='center' p={2}>
                                <LaunchOutlinedIcon style={{ fontSize: 30 }} />
                                <Typography
                                  variant='input-label-gray'
                                  component='p'
                                  fontSize='70%'
                                  textAlign='center'
                                  textOverflow='ellipsis'
                                  overflow='hidden'
                                  whiteSpace='nowrap'
                                  width='100%'
                                >
                                  {item.objectName}
                                </Typography>
                              </Stack>

                              <ContentOverlayAction
                                viewOnly={viewOnly}
                                objectName={item.objectName}
                                onDeleteClick={() => {
                                  setSelectedItemToDelete({
                                    data: item,
                                    sectionIndex: index,
                                  });
                                }}
                                onViewClick={() => window.open(item.link, '_blank', 'noopener,noreferrer')}
                              />
                            </Box>
                          ))}
                          {!viewOnly && (
                            <AddMoreContentItemButton
                              onClick={() => handleOnClickAddMoreObject(DATA_INPUT_OBJECT_TYPE.LINK)}
                              title='Add Links'
                            />
                          )}
                        </Stack>
                      </Stack>
                    </Stack>

                    <Stack style={isShowList ? Styles.transitionStyles.entering : Styles.transitionStyles.entered}>
                      <DataInputObjectUpload
                        name={fieldName}
                        objectType={selectedContent.objectType}
                        onUploadComplete={handleOnUploadCompleted}
                        subjectId={subjectId}
                        label='Attachment'
                      />
                    </Stack>
                  </Stack>

                  {selectedContent.page === MODAL_CONTENT.ALL_CONTENT && !viewOnly && (
                    <>
                      <Divider sx={{ mb: 1 }} />
                      <Stack>
                        <Stack alignItems='flex-end'>
                          <LoadingButton
                            loading={isDeleting}
                            sx={{ width: 150 }}
                            variant='main-table-panel'
                            onClick={handleOnSaveClick}
                          >
                            Save
                          </LoadingButton>
                        </Stack>
                      </Stack>
                    </>
                  )}
                </Stack>
              </>
            )}
          </Stack>
        </Modal>
      </div>
      <LightGallery
        onInit={onInit}
        speed={500}
        plugins={[lgThumbnail, lgZoom, lgVideo]}
        dynamic
        dynamicEl={galleryDynamicElement}
        closable={true}
        elementClassNames='hidden-gallery'
      />
      <ModalPDFViewer
        open={selectedPDFViewer.open}
        pdfURL={selectedPDFViewer.pdfURL}
        title={selectedPDFViewer.title}
        onClose={() => setSelectedPDFViewer({ open: false, title: '', pdfURL: '' })}
      />
      <ModalVideoViewer
        open={selectedVideoViewer.open}
        videoURL={selectedVideoViewer.videoURL}
        title={selectedVideoViewer.title}
        onClose={() => setSelectedVideoViewer({ open: false, title: '', videoURL: '' })}
      />
      <ModalDeleteDataInputObjectConfirmation
        visible={Boolean(selectedItemToDelete)}
        item={selectedItemToDelete || undefined}
        onCancel={handleOnModalDeleteClose}
        onClose={handleOnModalDeleteClose}
        onApprove={handleOnDeleteObjectItem}
      />
    </>
  );
}
function ContentOverlayAction({
  onViewClick,
  onDeleteClick,
  viewOnly,
  objectName,
}: {
  onViewClick: () => void;
  onDeleteClick: () => void;
  viewOnly: boolean;
  objectName?: string;
}) {
  return (
    <Box
      className='overlay'
      position='absolute'
      top={0}
      left={0}
      width='100%'
      height='100%'
      display='flex'
      justifyContent='center'
      alignItems='center'
      bgcolor='rgba(66, 187, 147, 0.7)'
      sx={Styles.boxOverlay}
    >
      <Stack direction='row'>
        {!viewOnly && (
          <Tooltip title={`Delete ${objectName}`}>
            <IconButton
              onClick={onDeleteClick}
              size='small'
              color='info'
              sx={{
                color: 'white',
              }}
            >
              <DeleteOutlineOutlinedIcon />
            </IconButton>
          </Tooltip>
        )}

        <Tooltip title={`View ${objectName}`}>
          <IconButton
            size='small'
            color='primary'
            onClick={onViewClick}
            sx={{
              color: 'white',
            }}
          >
            <VisibilityOutlinedIcon />
          </IconButton>
        </Tooltip>
      </Stack>
    </Box>
  );
}

function AddMoreContentItemButton({ onClick, title }: { onClick: () => void; title: string }) {
  return (
    <Button
      variant='outlined'
      onClick={onClick}
      sx={{
        flexBasis: '10%',
        flexGrow: 1,
        height: 'auto',
        maxWidth: '10%',
        border: '2px dashed #ccc',
        display: 'flex',
        justifyContent: 'center',
        textTransform: 'none',
        alignItems: 'center',
        aspectRatio: 1, // Ensures the button is a square
      }}
    >
      <Stack justifyContent='center' alignItems='center' gap={1}>
        <AddIcon sx={{ fontSize: '24px' }} />
        <Typography variant='input-label-gray' component='h2' fontWeight='bold' sx={{ fontSize: 11 }}>
          {title}
        </Typography>
      </Stack>
    </Button>
  );
}
