import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

const StyledGridOverlay = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  '& .ant-empty-img-1': {
    fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
  },
  '& .ant-empty-img-2': {
    fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
  },
  '& .ant-empty-img-3': {
    fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
  },
  '& .ant-empty-img-4': {
    fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
  },
  '& .ant-empty-img-5': {
    fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
    fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
  },
}));

interface DataTableErrorProps {
  title: string;
  description: string | null;
}

export function RenderDataTableError(props: DataTableErrorProps) {
  const { title, description } = props;

  return (
    <StyledGridOverlay>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        className='icon icon-tabler icon-tabler-file-alert'
        width='44'
        height='44'
        viewBox='0 0 24 24'
        stroke-width='2'
        stroke='#707070'
        fill='none'
        stroke-linecap='round'
        stroke-linejoin='round'
      >
        <path stroke='none' d='M0 0h24v24H0z' fill='none'></path>
        <path d='M14 3v4a1 1 0 0 0 1 1h4'></path>
        <path d='M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z'></path>
        <line x1='12' y1='17' x2='12.01' y2='17'></line>
        <line x1='12' y1='11' x2='12' y2='14'></line>
      </svg>
      <Box sx={{ mt: 1 }}>
        <Typography variant='label-error'>{title}</Typography>
      </Box>
      <Box sx={{ mt: 1 }}>
        <Typography variant='input-label'>{description}</Typography>
      </Box>
    </StyledGridOverlay>
  );
}
