import { memo } from 'react';
import Button from '@mui/material/Button';
import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid';
import eventEmitter, { EVENT_EMITTER_KEYS } from 'utils/EventEmitter';

const ButtonStyles = {
  height: '78%',
  fontSize: '12px',
  color: '#00B3DC',
  letterSpacing: '0.15px',
  textDecorationLine: 'underline',
  textTransform: 'none',

  '&:hover': {
    backgroundColor: 'transparent',
  },
};

export function RenderAttachment(props: GridRenderCellParams<string>) {
  const { value } = props;

  const handleOnClick = () => {
    const subjectTableName = props.row.id?.split('@')?.[0];
    const primaryKeyValue = props.row[subjectTableName];
    eventEmitter.emit(EVENT_EMITTER_KEYS.TRIGGER_SHOW_MODAL_ATTACHMENT, {
      visible: true,
      objectIds: value?.split(','),
      fieldLabel: `${primaryKeyValue} - ${props.colDef.headerName}`,
    });
  };

  if (!value) return null;

  return (
    <>
      <Button variant='text' sx={ButtonStyles} onClick={handleOnClick}>
        View Content
      </Button>
    </>
  );
}

const MemoRenderAttachment = memo(RenderAttachment);

type RenderAttachmentProps = GridColDef['renderCell'];
export const renderDataGridAttachment: RenderAttachmentProps = (params) => {
  return <MemoRenderAttachment {...params} key={params.id} />;
};
