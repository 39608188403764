import { toast } from 'react-toastify';
import { useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import {
  GridRowModesModel,
  GridRowModes,
  GridRowEditStopParams,
  GridRowParams,
  GridSelectionModel,
  GridRowId,
  GridFilterModel,
  GridSortModel,
  getGridStringOperators,
  GridActionsCellItem,
  GridColumns,
  GridEnrichedColDef,
} from '@mui/x-data-grid-pro';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { CustomDataTable } from 'components/DatatableComponent';
import { MainLayoutComponent } from 'components/LayoutComponent/SidebarLayout/MainLayoutComponent';
import { ModalImportComponent, ModalImportState } from 'components/ModalComponent/ModalImportComponent';
import { useExportDataTable, useDownloadDataImportTemplate } from 'services/v1/Misc/UploadService';
import {
  useAttributeList,
  useDeleteAttributeData,
  useUpsertAttributeData,
} from 'services/v1/SystemTenant/AROKMS/AttributeService';
import { AtributeItem } from 'types/api/SystemTenant/AROKMS/AttributeTypes';
import { PaginationAndSortingParams } from 'types/api/Common/PaginationTypes';
import { AxiosDefaultErrorEntity } from 'types/api/Common/ErrorTypes';

import ModalDeleteComponent, { ModalDeleteState } from 'components/ModalComponent/ModalDeleteComponent';
import { getErrorMessage } from 'utils/Error';
import { downloadFileFromURL, getFileName } from 'utils/DownloadFIle';
import { DASHBOARD_VIEW_MODE } from 'constant/DashboardTypeConstant';
import ModalInfoComponent from 'components/ModalComponent/ModalInfoComponent';
import { dayjs } from 'utils/DateUtils';
import { ENV_CONFIG } from 'config/env';
const ATTRIBUTE_TOAST_ID = 'ATTRIBUTE_TOAST_ID';

const panelStyle = {
  transform: 'translate3d(704px, 140.667px, 0px) !important',

  '& .MuiDataGrid-panelFooter': {
    display: 'none',
  },
};

// File Name for export, import, and file import template
const FILE_NAME = {
  EXPORT: 'EXPORT_ATTRIBUTE_LIST',
  IMPORT: 'IMPORT_ATTRIBUTE_LIST',
  TEMPLATE: 'IMPORT_TEMPLATE_ATTRIBUTE_LIST',
};

export default function AttributeDataListPage() {
  const { dashboardViewMode } = useSelector((state: RootState) => state.auth);
  const { t } = useTranslation();
  const [rowData, setRowData] = useState<AtributeItem[]>([]);
  const [modalImportState, setModalImportState] = useState<ModalImportState>({
    open: false,
    fileType: '',
  } as ModalImportState);
  const [modalDeleteState, setModalDeleteState] = useState<ModalDeleteState>({ open: false, message: '' });
  const [modalResult, setModalResult] = useState<ModalDeleteState>({ open: false, message: '' });
  const [selectedDataID, setSelectedDataID] = useState<GridRowId[]>([]);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [filter, setFilter] = useState<PaginationAndSortingParams>({
    page: 0,
    size: 20,
    filterValue: '',
    sortType: 'asc',
    filterOperator: 'contains',
  });

  const [filterButtonElement, setFilterButtonElement] = useState<HTMLButtonElement | null>(null);
  const [editedData, setEditedData] = useState<AtributeItem[]>([]);

  const { data: attributeList, isLoading, refetch: refreshAttributeData } = useAttributeList(filter);
  const { mutateAsync: upsertAttributeAsync } = useUpsertAttributeData(filter);
  const {
    mutateAsync: deletAttributeById,
    isError: isErrorAttribute,
    error: errorAttribute,
    reset: resetDeleteAttribute,
  } = useDeleteAttributeData(filter);
  const { mutate: exportDataTable } = useExportDataTable();
  const { mutate: downloadDataImportTemplate } = useDownloadDataImportTemplate();

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const isAnyEdit = useMemo(() => {
    return rowModesModel && Object.values(rowModesModel).some((item) => item.mode === GridRowModes.Edit);
  }, [rowModesModel]);
  const isBuilderViewOnly = dashboardViewMode === DASHBOARD_VIEW_MODE.VIEW_ONLY;

  const columns = useMemo<GridColumns>(() => {
    const actionColumn: GridEnrichedColDef = {
      field: 'actions',
      type: 'actions',
      headerName: 'Edit',
      width: 30,
      cellClassName: 'actions',

      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          // @ts-ignore
          return [<GridActionsCellItem icon={<SaveIcon />} label='Save' onClick={handleSaveClick(id)} />];
        }
        // @ts-ignore
        return [<GridActionsCellItem icon={<EditIcon />} label='Edit' onClick={handleEditClick(id)} />];
      },
    };

    const attributeColumns: GridColumns = [
      {
        field: 'attribute',
        headerName: t('SIDEBAR_MENU.ATTRIBUTE'),
        width: 700,
        editable: true,
        valueGetter: (params) => {
          return params?.row?.displayName;
        },
        filterOperators: getGridStringOperators().filter((operator) => ['contains', 'equals'].includes(operator.value)),
      },
      {
        field: 'createdDate',
        headerName: 'Created Date',
        width: 400,
        editable: false,
        valueFormatter: (params) => {
          return params?.value ? dayjs(params.value).format(ENV_CONFIG.config.DATE_TIME_FORMAT) : '';
        },
        filterOperators: getGridStringOperators().filter((operator) => ['contains', 'equals'].includes(operator.value)),
      },
    ];

    if (isBuilderViewOnly) return attributeColumns;
    return [actionColumn, ...attributeColumns];

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, rowModesModel, isBuilderViewOnly]);

  const DataMutationSuccessHandlerOption = {
    onSuccess: () => {
      refreshAttributeData();
      toast.update(ATTRIBUTE_TOAST_ID, {
        type: 'success',
        render: 'Your new attribute was inserted successfully!',
        isLoading: false,
        autoClose: 4000,
      });
    },
    onError: (error: AxiosDefaultErrorEntity) => {
      toast.update(ATTRIBUTE_TOAST_ID, { type: 'error', render: getErrorMessage(error), isLoading: false });
    },
  };

  useEffect(() => {
    if (attributeList?.data?.data) {
      setRowData(attributeList?.data?.data);
      setRowModesModel(
        attributeList?.data?.data?.reduce((acc, item) => {
          return { ...acc, [item.id]: GridRowModes.View };
        }, {})
      );
    }
  }, [attributeList]);

  const handleChangePage = (newPage: number) => {
    setFilter({ ...filter, page: newPage });
  };
  const handleChangePageSize = (newPageSize: number) => {
    setFilter({ ...filter, size: newPageSize });
  };

  const handleOnFilter = (params: GridFilterModel) => {
    let filterObj = {};
    if (params.items.length) {
      filterObj = {
        filterValue: params.items[0].value,
        filterOperator: params.items[0].operatorValue,
      };
    } else {
      filterObj = {
        filterValue: '',
        filterOperator: 'contains',
      };
    }
    setFilter({
      ...filter,
      ...filterObj,
    });
  };

  const handleOnSortModelChange = (model: GridSortModel) => {
    if (model.length === 0) {
      setFilter({ ...filter, sortType: 'asc' });
      return;
    }
    setFilter({ ...filter, sortType: model[0].sort });
  };

  // Process row Update will be triggered when
  // user modify or insert new row the table
  const handleProcessRowUpdate = (data: AtributeItem, oldData: AtributeItem) => {
    const isEmpty = data.attribute.trim() === '';
    if (isEmpty) {
      return;
    }

    // If old row data is equal to new row data, then do nothing
    // (no need to update just return the data, because nothing changed)
    if (data.attribute.trim() === oldData.displayName?.trim()) {
      // if oldData.isNew equals to true, then it means that this is a new row
      // andl cancle the row when cursor out of focust
      if (oldData.isNew) handleCancelInsert(oldData.id);
      return oldData;
    }

    if (data.isNew) {
      toast(`Inserting new attribute "${data.attribute}"...`, {
        toastId: ATTRIBUTE_TOAST_ID,
        isLoading: true,
        updateId: ATTRIBUTE_TOAST_ID,
      });
      // if the row is new and not empy, then insert the row to database
      upsertAttributeAsync({ attribute: data.attribute.trim() }, DataMutationSuccessHandlerOption);
      return { ...data, isNew: false };
    }
    // if the row is not new, it means that the row n
    if (!data.isNew) {
      toast(`Updating attribute "${data.attribute}"...`, {
        toastId: ATTRIBUTE_TOAST_ID,
        isLoading: true,
        updateId: ATTRIBUTE_TOAST_ID,
      });
      // if data already exist in state editedData, update it
      // if not, add it to editedData
      const index = editedData.findIndex((item) => item.id === data.id);
      if (index === -1) {
        setEditedData([...editedData, data]);
      } else {
        const newEditedData = [...editedData];
        newEditedData[index] = data;
        setEditedData(newEditedData);
      }
      upsertAttributeAsync(
        {
          id: data.id,
          attribute: data.attribute.trim(),
        },
        DataMutationSuccessHandlerOption
      );
      return data;
    }
  };

  const handleCancelInsert = (id: number) => {
    const newRows = rowData.filter((item) => item.id !== id);
    setRowData(newRows);
    setRowModesModel((prev) => {
      const newModel = { ...prev };
      delete newModel[id];
      return newModel;
    });
  };

  const handleRowEditStop = (params: GridRowEditStopParams) => {};

  const handleEditRowModes = (params: GridRowParams) => {
    if (isBuilderViewOnly || isAnyEdit) return;
    setRowModesModel({ ...rowModesModel, [params.id]: { mode: GridRowModes.Edit } });
  };

  const handleDeleteData = async () => {
    try {
      if (selectedDataID.length === 0) return;
      const multipleDeleteRequest = selectedDataID.map((id) => deletAttributeById({ id }));
      await Promise.all(multipleDeleteRequest);
      setModalDeleteState({
        ...modalDeleteState,
        open: false,
        message: '',
      });
      toast(`Successfully delete ${selectedDataID.length} attribute(s)`, {
        toastId: ATTRIBUTE_TOAST_ID,
        type: 'success',
      });
    } catch (err: any) {
      toast.update(ATTRIBUTE_TOAST_ID, { type: 'error', render: getErrorMessage(err), isLoading: false });
    }
  };

  const handleOnClickDeleteButton = () => {
    const selectedData = rowData.filter((item) => selectedDataID.includes(item.id));
    setModalDeleteState({
      ...modalDeleteState,
      open: true,
      message: `Attribute : ${selectedData.map((item) => item.attribute).join(', ')}`,
    });
  };

  const handleOnExport = (selectedFileType: string) => {
    exportDataTable(
      { category: 'attribute', fileType: selectedFileType },
      {
        onSuccess: (response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          downloadFileFromURL(url, getFileName(FILE_NAME.EXPORT, selectedFileType));
        },
      }
    );
  };

  const handleOnTemplateClick = (selectedFileType: string) => {
    downloadDataImportTemplate(
      { category: 'attribute', fileType: selectedFileType },
      {
        onSuccess: (response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          downloadFileFromURL(url, getFileName(FILE_NAME.TEMPLATE, selectedFileType));
        },
      }
    );
  };

  const handleOnImportCompleted = () => {
    refreshAttributeData();
  };

  return (
    <MainLayoutComponent
      pageTitle={t('PAGE.ATTRIBUTE.TITLE')}
      breadcrumbs={[t('SIDEBAR_MENU.DASHBOARD'), t('SIDEBAR_MENU.DATA_BUILDER')]}
    >
      <CustomDataTable
        initialState={{
          pagination: {
            page: 0,
          },
          sorting: {
            sortModel: [],
          },
        }}
        loading={isLoading}
        checkboxSelection={!isBuilderViewOnly}
        rows={rowData}
        columns={columns}
        rowCount={attributeList?.data?.rowCount}
        page={filter.page || 0}
        pageSize={filter.size}
        getRowId={(row) => row.id}
        rowModesModel={rowModesModel}
        disableSelectionOnClick
        rowsPerPageOptions={[5, 10, 20]}
        getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
        experimentalFeatures={{ newEditingApi: true }}
        onFilterModelChange={handleOnFilter}
        onSortModelChange={handleOnSortModelChange}
        onPageChange={handleChangePage}
        onPageSizeChange={handleChangePageSize}
        onSelectionModelChange={(selectedId: GridSelectionModel) => {
          setSelectedDataID(selectedId);
        }}
        processRowUpdate={handleProcessRowUpdate}
        onRowEditStop={handleRowEditStop}
        onRowDoubleClick={handleEditRowModes}
        paginationMode='client'
        sortingMode='client'
        onProcessRowUpdateError={(params) => {
          console.log(params);
        }}
        filterMode='client'
        componentsProps={{
          panel: {
            sx: panelStyle,
            anchorEl: filterButtonElement,
          },
          toolbar: {
            setRows: setRowData,
            setRowModesModel,
            onDeleteData: handleOnClickDeleteButton,
            onButtonTemplateClick: handleOnTemplateClick,
            onExport: handleOnExport,

            onImport: (selectedFileType: string) =>
              setModalImportState({ ...modalImportState, fileType: selectedFileType, open: true }),
            setFilterButtonElement: setFilterButtonElement,
            disabled: isLoading || isBuilderViewOnly,
            disabledDeleteButton: selectedDataID.length === 0,
            isEditing: rowData.some((item) => item.isNew),
            config: {
              deleteButtonText: 'Delete',
              fieldFocusOnInsert: 'attribute',
              infoText:
                'Please kindly note that once data attribute has been inputted, it becomes permanently locked and cannot be edited.',
              insertButtonText: t('PAGE.ATTRIBUTE.BUTTON_ADD_NEW'),
              insertInitialObject: {
                attribute: '',
                isNew: true,
              },
            },
          },
        }}
      />

      <ModalImportComponent
        key={modalImportState.fileType}
        category='attribute'
        fileType={modalImportState.fileType}
        onImportSuccess={handleOnImportCompleted}
        visible={modalImportState.open}
        onClose={() => setModalImportState({ ...modalImportState, open: false })}
        onCancel={() => setModalImportState({ ...modalImportState, open: false })}
      />

      <ModalDeleteComponent
        visible={modalDeleteState.open}
        isError={isErrorAttribute}
        errorMessage={errorAttribute && getErrorMessage(errorAttribute)}
        message={modalDeleteState.message}
        onApprove={handleDeleteData}
        onClose={() => {
          setModalDeleteState({ ...modalDeleteState, open: false });
          resetDeleteAttribute();
        }}
        onCancel={() => setModalDeleteState({ ...modalDeleteState, open: false })}
      />

      <ModalInfoComponent
        visible={modalResult.open}
        title={modalResult.title}
        onClose={() => setModalResult({ ...modalResult, open: false })}
        descriptionMessage={modalResult.message}
      />
    </MainLayoutComponent>
  );
}
