import { DASHBOARD_TYPE_CONSTANT } from 'constant/DashboardTypeConstant';
import { ROLES } from 'constant/PermissonConstant';
import { MEMBER_LIST_STATIC_RECORD } from 'data/MemberListRecord';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

interface SSBAProps {
  children: React.ReactNode;
}

// This provider will hide component if the system status is locker
// and the user role are either BUILDER or PACKAGER
export function SystemStatusBasedAccessProvider(props: SSBAProps) {
  const { selectedTenant, role } = useSelector((state: RootState) => state.auth);
  const { children } = props;

  if (
    selectedTenant.tenant.systemCodeStatus === MEMBER_LIST_STATIC_RECORD.CODE_STATUS.LOCKED &&
    selectedTenant.tenant.dashboardType === DASHBOARD_TYPE_CONSTANT.DASHBOARD_SYSTEM &&
    role === ROLES.BUILDER
  ) {
    return null;
  }

  if (
    selectedTenant.tenant.systemDemoStatus === MEMBER_LIST_STATIC_RECORD.DEMO_STATUS.LOCKED &&
    role === ROLES.PACKAGER
  ) {
    return null;
  }

  return <>{children}</>;
}
