import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { styled, alpha } from '@mui/material/styles';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { SvgIcon } from '@mui/material';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import { IconBreadcrumbs } from 'components/BreadcrumbsComponent';
import { setSidebarOpen, setTenantNavigation, toggleModalSelectLanguage } from 'store/reducer/uiReducer';
import { logout } from 'store/reducer/authReducer';
import { ReactComponent as CustomIconLogout } from 'assets/icons/icon-user-logout.svg';
import { ReactComponent as CustomIconSetting } from 'assets/icons/icon-user-setting.svg';
import { ReactComponent as CustomIconLanguage } from 'assets/icons/icon-user-language.svg';
import { RootState } from 'store';
import { Stack } from '@mui/system';
import { convertSnackCaseToTitleCase, getInitialsName } from 'utils/String';
import { useGetRoleAccess, useLogout, useSessionCheck } from 'services/v1/Auth';
import packageJson from '../../../package.json';

const drawerWidth = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 280,
    p: 5,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '10px 0',
    },
    '& .MuiMenuItem-root': {
      color: '#98A2AE',
      fontSize: 13,
      '& .MuiSvgIcon-root': {
        fontSize: 16,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

interface MainAppBarProps {
  pageTitle: string;
  breadcrumbs: string[];
  onChangeRoleClick?: () => void;
}

export function MainAppBar(props: MainAppBarProps) {
  const { onChangeRoleClick } = props;
  useSessionCheck();
  useGetRoleAccess();
  const { t } = useTranslation();
  const { pageTitle, breadcrumbs = [] } = props;
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const dispatch = useDispatch();
  const { isSidebarOpen } = useSelector((state: RootState) => state.ui);
  const { profile, selectedTenant, tenants } = useSelector((state: RootState) => state.auth);
  const { selectedLanguage } = useSelector((state: RootState) => state.setting);

  const { mutate: logoutUser } = useLogout();

  const handleLogoutRedirect = () => {
    dispatch(setTenantNavigation(false));
    dispatch(logout());
    window.location.href = '/auth/login';
  };

  const handleLogout = () => {
    logoutUser(undefined, {
      onSuccess: handleLogoutRedirect,
      onError: handleLogoutRedirect,
    });
  };

  const settingMenuItems = React.useMemo(() => {
    return [
      {
        label: 'divider-2',
        isDivider: true,
        icon: CustomIconLanguage,
        visible: true,
        onClick: () => {},
      },
      {
        label: 'Change Role',
        isDivider: false,
        icon: CustomIconLanguage,
        visible: tenants.length > 1,
        onClick: () => {
          onChangeRoleClick && onChangeRoleClick();
        },
      },
      {
        label: 'divider-1',
        isDivider: true,
        icon: CustomIconLanguage,
        visible: tenants.length > 1,
        onClick: () => {},
      },
      {
        label: `${t('HEADER_MENU.MULTILANGUAGE')} - ${selectedLanguage.toUpperCase()}`,
        isDivider: false,
        icon: CustomIconLanguage,
        visible: true,
        onClick: () => {
          dispatch(toggleModalSelectLanguage());
        },
      },
      {
        label: t('HEADER_MENU.SETTING'),
        isDivider: false,
        icon: CustomIconSetting,
        visible: true,
        onClick: () => {},
      },
      {
        label: 'divider-0',
        isDivider: true,
        icon: CustomIconLanguage,
        visible: true,
        onClick: () => {},
      },
      {
        label: t('HEADER_MENU.LOGOUT'),
        isDivider: false,
        icon: CustomIconLogout,
        visible: true,
        onClick: handleLogout,
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, t, selectedLanguage]);

  const handleToogleDrawer = () => {
    dispatch(setSidebarOpen(!isSidebarOpen));
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position='fixed' open={isSidebarOpen} sx={{ boxShadow: 'none' }}>
      <Toolbar>
        <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            onClick={handleToogleDrawer}
            edge='start'
            sx={{ mr: 2, color: '#384663' }}
          >
            {isSidebarOpen ? <ChevronLeftIcon /> : <MenuIcon />}
          </IconButton>
          <Box>
            <Typography
              data-testid='main-appbar-title'
              variant='body1'
              noWrap
              component='div'
              color='#384663'
              sx={{ fontWeight: 600 }}
            >
              {pageTitle}
            </Typography>
            <IconBreadcrumbs item={breadcrumbs} />
          </Box>
        </Box>
        <Box sx={{ flexGrow: 0 }}>
          <Tooltip title='Personal settings and exit'>
            <Stack direction='row'>
              <Stack
                direction='column'
                sx={{
                  mr: 2,
                }}
              >
                <Typography variant='body2' noWrap component='div' color='#384663' sx={{ fontWeight: 600 }}>
                  {profile.name}
                </Typography>
                <Typography
                  variant='body2'
                  sx={{ display: 'flex', alignItems: 'center', fontSize: 12 }}
                  color='#667085DE'
                >
                  {convertSnackCaseToTitleCase(selectedTenant.tenant.roleName)}
                </Typography>
              </Stack>
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar sx={{ bgcolor: '#00B3DC' }}>{getInitialsName(profile.name)}</Avatar>
              </IconButton>
            </Stack>
          </Tooltip>
          <StyledMenu
            sx={{ mt: '45px' }}
            id='menu-appbar'
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            <Stack direction='row' px={1} mb={2}>
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar sx={{ bgcolor: '#00B3DC' }}>{getInitialsName(profile.name)}</Avatar>
              </IconButton>
              <Stack
                direction='column'
                sx={{
                  ml: 2,
                }}
              >
                <Typography variant='body2' noWrap component='div' color='#384663' sx={{ fontWeight: 600 }}>
                  {profile.name}
                </Typography>
                <Typography
                  variant='body2'
                  sx={{ display: 'flex', alignItems: 'center', fontSize: 12 }}
                  color='#667085DE'
                >
                  {convertSnackCaseToTitleCase(selectedTenant.tenant.roleName)}
                </Typography>
              </Stack>
            </Stack>
            {settingMenuItems.map((setting) => {
              if (!setting.visible) return <></>;
              if (setting.isDivider) {
                return (
                  <Divider
                    key={setting.label}
                    sx={{
                      mb: 1,
                    }}
                  />
                );
              }
              return (
                <MenuItem
                  key={setting.label}
                  onClick={() => {
                    handleCloseUserMenu();
                    setting?.onClick();
                  }}
                >
                  <SvgIcon fontSize='small' sx={{ fill: '#fff' }} inheritViewBox component={setting.icon} />
                  {setting.label}
                </MenuItem>
              );
            })}
            <Divider
              sx={{
                mb: 1,
              }}
            />
            <MenuItem>
              <Typography
                variant='body2'
                textAlign='center'
                sx={{ display: 'flex', alignItems: 'center', fontSize: 12 }}
                color='#667085DE'
              >
                {`Platform Version: ${packageJson.version}`}
              </Typography>
            </MenuItem>
          </StyledMenu>
        </Box>
      </Toolbar>
    </AppBar>
  );
}
