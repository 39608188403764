import { ENV_CONFIG } from 'config/env';
import CryptoJS from 'crypto-js';

export function valueSafeEcryptor(value: string): string | null {
  if (!value) return null;
  const key = CryptoJS.enc.Hex.parse(ENV_CONFIG.config.REACT_APP_ENCRYPTION_KEY);
  const ciphertext = CryptoJS.AES.encrypt(value, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });
  return ciphertext.toString();
}
export function valueSafeDecryptor(encrypted: string): string | null {
  if (!encrypted) return null;
  const key = CryptoJS.enc.Hex.parse(ENV_CONFIG.config.REACT_APP_ENCRYPTION_KEY);
  const bytes = CryptoJS.AES.decrypt(encrypted, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });
  const plaintext = bytes.toString(CryptoJS.enc.Utf8);
  return plaintext;
}
