// @ts-nocheck
import { useQueryClient } from 'react-query';
import { useSelector, useDispatch } from 'react-redux';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import ListItem from '@mui/material/ListItem';
import { Typography } from '@mui/material';

import { setAuthTenantId } from 'libs/Axios';
import { setSelectedTenant, setUserBehaviourRole } from 'store/reducer/authReducer';
import { RootState } from 'store';
import { DEFAUL_LOGIN_REDIRECT_URL, ROLES } from 'constant/PermissonConstant';
import { getUserRoleBehaviour } from 'pages/Auth/SelectRolePage';
import { useNavigate } from 'react-router-dom';
import { useMemo } from 'react';

const menuItemOptionStyle = {
  fontSize: 12,
};
const roleLabelStyle = { color: '#fff', fontSize: 12, mt: 2 };
const formControlStyle = {
  backgroundColor: '#fff',
  borderRadius: 2,
  mt: -1,
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#fff',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: '#fff',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#fff',
  },
};

const selectStyle = {
  boxShadow: 'none',

  maxHeight: 43,
  '& .MuiOutlinedInput-notchedOutline': { border: 0 },
  '& .MuiSelect-select': {
    fontSize: 12,
  },
};

export function SelectRole() {
  const queryClient = useQueryClient();
  const { auth } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { tenants, selectedTenant } = useSelector((state: RootState) => state.auth);
  const handleOnChangeTenant = (event: SelectChangeEvent) => {
    const selectedTenantId = event.target.value.split('@')[0];
    const selectedRoleName = event.target.value.split('@')[1];

    const selected = tenants.find(
      (tenant) => tenant.tenant.id === selectedTenantId && tenant.tenant.roleName === selectedRoleName
    );
    if (selected) {
      setAuthTenantId(selected.id);
      dispatch(setSelectedTenant(selected));
      const roleBehaviour = getUserRoleBehaviour(selected.roleType, selected);
      dispatch(setUserBehaviourRole(roleBehaviour));
      queryClient.resetQueries();
      queryClient.refetchQueries();
      navigate(DEFAUL_LOGIN_REDIRECT_URL[roleBehaviour]);
    }
  };

  const tenantsOptions = useMemo(() => {
    const tenantsBasedOnRole = tenants.filter((tenant) => tenant.roleType === auth.role);
    if (tenantsBasedOnRole.length > 0) {
      return tenantsBasedOnRole.map((tenant) => ({
        id: `${tenant.tenant.id}@${tenant.tenant.roleName}`,
        label: tenant.tenant.tenantCode || tenant.tenant.systemCode,
      }));
    }
    return [];
  }, [tenants, auth]);

  return (
    <>
      <ListItem>
        <Typography variant='body2' sx={roleLabelStyle}>
          {auth.role === ROLES.BUILDER ? 'SYSTEM' : 'TENANT'}
        </Typography>
      </ListItem>
      <ListItem button>
        <FormControl fullWidth sx={formControlStyle}>
          <Select
            value={`${selectedTenant.tenant.id}@${selectedTenant.tenant.roleName}`}
            onChange={handleOnChangeTenant}
            sx={selectStyle}
          >
            {tenantsOptions.map((itemTenant) => (
              <MenuItem sx={menuItemOptionStyle} key={itemTenant.id} value={itemTenant.id}>
                {itemTenant.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </ListItem>
    </>
  );
}
