import Typography from '@mui/material/Typography';
import TableViewOutlinedIcon from '@mui/icons-material/TableViewOutlined';
import InputOutlinedIcon from '@mui/icons-material/InputOutlined';
import Stack from '@mui/material/Stack';

export interface ModalCustomColumnSelectConfigProps {
  onMenuOptionClick: (option: string) => void;
  value?: string;
}

const selectedMenuOptionSx = {
  backgroundColor: '#F5F5F5',
  border: '1px solid #42BB93',
  transition: 'ease-in 0.1s',
  '& svg': {
    color: '#42BB93',
  },
};

const menuOptionSx = {
  border: '1px solid #E0E0E0',
  p: 2,
  height: '75px',
  borderRadius: '4px',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#F5F5F5',
    border: '1px solid #42BB93',
    transition: 'ease-in 0.1s',
    '& svg': {
      color: '#42BB93',
    },
  },
};

const iconSx = {
  color: '#828C99',
  fontSize: 40,
};

export const INSIGHT_COLUM_GROUP_TOP_HEADER_TYPE = {
  TIME_PERIOD: 'TIME_PERIOD',
  RELATIVE_YEAR_AND_LAYER: 'RELATIVE_YEAR_AND_LAYER',
  TOTAL_RULE: 'TOTAL_RULE',
};
export function SelectGroupConfigType(props: ModalCustomColumnSelectConfigProps) {
  const { onMenuOptionClick, value } = props;

  return (
    <Stack gap={1}>
      <Stack direction='row' px={1} gap={2}>
        <Stack
          width='45%'
          gap={2}
          alignItems='center'
          direction='row'
          onClick={() => onMenuOptionClick(INSIGHT_COLUM_GROUP_TOP_HEADER_TYPE.RELATIVE_YEAR_AND_LAYER)}
          sx={{
            ...menuOptionSx,
            ...(value === INSIGHT_COLUM_GROUP_TOP_HEADER_TYPE.RELATIVE_YEAR_AND_LAYER ? selectedMenuOptionSx : {}),
          }}
        >
          <TableViewOutlinedIcon sx={iconSx} />
          <Stack>
            <Typography variant='body2' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
              Relative Year and Layer
            </Typography>
            <Typography variant='input-label-gray' fontWeight={400} component='h2' fontSize={12}>
              Set top heading as a relative year and layer. This will force all columns to be grouped with the same
              relative year and layer.
            </Typography>
          </Stack>
        </Stack>
        <Stack
          width='45%'
          gap={2}
          alignItems='center'
          direction='row'
          onClick={() => onMenuOptionClick(INSIGHT_COLUM_GROUP_TOP_HEADER_TYPE.TIME_PERIOD)}
          sx={{
            ...menuOptionSx,
            ...(value === INSIGHT_COLUM_GROUP_TOP_HEADER_TYPE.TIME_PERIOD ? selectedMenuOptionSx : {}),
          }}
        >
          <InputOutlinedIcon sx={iconSx} />
          <Stack>
            <Typography variant='body2' component='h3' fontWeight='bold' sx={{ color: '#3B4797' }}>
              Time Period
            </Typography>
            <Typography variant='input-label-gray' fontWeight={400} fontSize={12}>
              Set top heading as a time period. This will force all columns to be grouped with the same time period.
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Stack direction='row' px={1} gap={2}>
        <Stack
          width='45%'
          gap={2}
          alignItems='center'
          direction='row'
          onClick={() => onMenuOptionClick(INSIGHT_COLUM_GROUP_TOP_HEADER_TYPE.TOTAL_RULE)}
          sx={{
            ...menuOptionSx,
            ...(value === INSIGHT_COLUM_GROUP_TOP_HEADER_TYPE.TOTAL_RULE ? selectedMenuOptionSx : {}),
          }}
        >
          <TableViewOutlinedIcon sx={iconSx} />
          <Stack>
            <Typography variant='body2' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
              Total Rule
            </Typography>
            <Typography variant='input-label-gray' fontWeight={400} component='h2' fontSize={12}>
              Set top heading as a total rule. This will force all columns to be grouped with the same total rule.
            </Typography>
          </Stack>
        </Stack>
        <Stack
          width='45%'
          gap={2}
          alignItems='center'
          direction='row'
          sx={{
            ...menuOptionSx,
            border: 'none',
            pointerEvents: 'none',
          }}
        ></Stack>
      </Stack>
    </Stack>
  );
}
