import { useMemo, useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import ActivityIndicator from 'components/ActivityIndicatorComponent';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import InfoIcon from '@mui/icons-material/Info';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined';
import {
  useGetMyViewConfigUser,
  useGetTenantConfigData,
  useUpdateUserDefaultLoginView,
} from 'services/v1/Tenant/ConfigurationService';
import { DataGridPro, GridColDef, GridFilterModel, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid-pro';
import { PaginationAndSortingParams } from 'types/api/Common/PaginationTypes';
import { Button, IconButton, Tooltip } from '@mui/material';
import { ModalPeopleAddComponent } from './ModalPeopleAddComponent';
import { MyViewWorkFlowItem, PeopleMyViewItemConfig } from 'types/api/Tenant/ConfigurationTypes';
import { BackToListButton } from 'components/ButtonComponent/ButtonBackToList';
import { ModalMyViewAdditionalConfig } from './ModalMyViewAdditionalConfig';
import { NAVIGATION_COMPONENT_TYPE } from 'constant/NavigationConstant';

interface PeopleConfigDetailComponentProps {
  selectedUser: { id: string; fullName: string } | null;
  onClose?: () => void;
}

const initialModalMyViewConfigValue = {
  show: false,
  defaultXDimension: null,
  defaultYDimension: null,
  defaultDimensionFilter: null,
};

export const PeopleConfigDetailComponent = (props: PeopleConfigDetailComponentProps) => {
  const { selectedUser, onClose } = props;
  const { isLoading } = useGetTenantConfigData({ refetchInterval: false });
  const [showModalComponentList, setShowModalComponentList] = useState(false);
  const [modalMyViewConfig, setModalMyViewConfig] = useState(initialModalMyViewConfigValue);
  const { data: userViewConfig, isLoading: isLoadingUserViewConfig } = useGetMyViewConfigUser(selectedUser?.id);
  const [selectedUserViewConfig, setSelectedUserViewConfig] = useState<PeopleMyViewItemConfig | null>(null);

  const { mutate: updateDefaultLoginView, isLoading: isUpdatingViewConfig } = useUpdateUserDefaultLoginView();

  const [filter, setFilter] = useState<PaginationAndSortingParams>({
    page: 0,
    size: 10,
    filterValue: '',
    sortType: 'desc',
    sortBy: 'activityDate',
    filterColumn: 'fullName',
    filterOperator: 'contains',
  });

  const handleChangePage = (newPage: number) => {
    setFilter({ ...filter, page: newPage + 1 });
  };
  const handleChangePageSize = (newPageSize: number) => {
    setFilter({ ...filter, size: newPageSize });
  };

  const handleOnFilter = (params: GridFilterModel) => {
    let filterObj = {};
    if (params.items.length) {
      filterObj = {
        filterValue: params.items[0].value,
        filterOperator: params.items[0].operatorValue,
        filterColumn: params.items[0].columnField,
      };
    } else {
      filterObj = {
        filterValue: '',
        filterOperator: 'contains',
      };
    }
    setFilter({
      ...filter,
      ...filterObj,
    });
  };

  const handleOnSubmitDefaultLoginView = (myViewId: string) => {
    if (selectedUser?.id) {
      updateDefaultLoginView({ userId: selectedUser.id, myViewId });
    }
  };

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'componentName',
        headerName: 'Component Name',
        renderCell: (params: GridRenderCellParams) => {
          if (params.row.defaultLoginView) {
            return <div>{params.row.componentName} (Login View)</div>;
          }
          return <div>{params.row.componentName}</div>;
        },

        width: 250,
      },
      {
        field: 'componentMember',
        headerName: 'Member Filter',

        renderCell: (params: GridRenderCellParams) => {
          if (!params.row.componentMember) return null;
          return (
            <Stack direction='row' alignItems='center'>
              <div>{params.row.componentMember}</div>
            </Stack>
          );
        },
        width: 200,
      },
      {
        field: 'defaultDimensionFilter',
        headerName: 'Dimension Filter',

        renderCell: (params: GridRenderCellParams) => {
          if (params.row.componentType !== NAVIGATION_COMPONENT_TYPE.INSIGHT_VIEW.toString()) return 'N/A';

          return (
            <Stack direction='row' alignItems='center'>
              <div>{params.row.defaultYDimension ? 'Configured' : 'Default'}</div>
            </Stack>
          );
        },
        width: 150,
      },
      {
        field: 'additionalWorkflows',
        headerName: 'Drill Down Flow',

        renderCell: (params: GridRenderCellParams) => {
          if (params.row.componentType !== NAVIGATION_COMPONENT_TYPE.INSIGHT_VIEW.toString()) return 'N/A';
          const additionalWorkFlows = params.row.additionalWorkFlows;
          const values = `${additionalWorkFlows?.length} flow(s)`;
          const tooltipValues = additionalWorkFlows?.map((item: MyViewWorkFlowItem) => (
            <div key={item.componentName}>{item.componentName}</div>
          ));
          return (
            <Stack direction='row' alignItems='center'>
              <div>{values}</div>
              <Tooltip title={tooltipValues}>
                <IconButton size='small'>
                  <InfoIcon fontSize='inherit' color='primary' />
                </IconButton>
              </Tooltip>
            </Stack>
          );
        },
        width: 150,
      },
      {
        field: 'actions',
        type: 'actions',
        headerName: '',
        width: 200,
        getActions: (params: GridRowParams) => {
          const defaultLoginViewButton = (
            <Tooltip title='Set as Default Login View'>
              <IconButton
                onClick={() => {
                  handleOnSubmitDefaultLoginView(params.row.id);
                }}
                size='medium'
              >
                <VpnKeyOutlinedIcon fontSize='inherit' />
              </IconButton>
            </Tooltip>
          );
          const additionalConfigButton = (
            <Tooltip title='Additional Configuration'>
              <IconButton
                onClick={() => {
                  setModalMyViewConfig({
                    show: true,
                    defaultXDimension: params.row.defaultXDimension,
                    defaultYDimension: params.row.defaultYDimension,
                    defaultDimensionFilter: params.row.defaultDimensionFilter,
                  });
                  setSelectedUserViewConfig(params.row as PeopleMyViewItemConfig);
                }}
                size='medium'
              >
                <SettingsOutlinedIcon fontSize='inherit' />
              </IconButton>
            </Tooltip>
          );
          if (params.row.defaultLoginView) {
            return [additionalConfigButton];
          }
          return [additionalConfigButton, defaultLoginViewButton];
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const selectedItemIds: Set<string> = useMemo(() => {
    const selectedItemIds =
      userViewConfig?.data?.map((item) => {
        if (item.subjectId) {
          return `${item.componentId}#${item.subjectId}`;
        }
        return item.componentId;
      }) || [];
    return new Set(selectedItemIds);
  }, [userViewConfig?.data]);

  const handleAddComponent = () => {
    setShowModalComponentList(true);
  };

  const isLoadingData = isLoading || isLoadingUserViewConfig || isUpdatingViewConfig;
  return (
    <Box sx={{ bgcolor: '#fff', px: 1, py: 2, width: '100%' }}>
      <Stack mb={2} width='100%'>
        <Stack direction='row' alignItems='center' gap={1}>
          <Typography variant='h6' color='text.primary' fontWeight='bold'>
            People Configuration
          </Typography>
          <ChevronRightIcon />
          <Typography variant='h6' color='text.primary' fontWeight='bold'>
            {selectedUser?.fullName}
          </Typography>
        </Stack>
        <Divider sx={{ mt: 1 }} />
        <Stack width={150} alignItems='flex-start'>
          <BackToListButton title='Return to people' onClick={onClose} />
        </Stack>
      </Stack>
      {isLoading ? (
        <Stack direction='column' justifyContent='center'>
          <ActivityIndicator />
        </Stack>
      ) : (
        <Stack direction='column' display='flex'>
          <Stack mb={1}>
            <Typography variant='h4' color='text.primary' fontWeight='bold'>
              Assign myView items
            </Typography>
            <Typography variant='body2' color='text.secondary'>
              myView items are the items that your user can see on their myView page. This allows your user to navigate
              to the page that they need to access quickly. You can assign multiple items to a user.
            </Typography>
          </Stack>
          <Stack width={180} my={1}>
            <Button
              variant='secondary-table-panel'
              startIcon={<AddCircleOutlineOutlinedIcon />}
              onClick={handleAddComponent}
              disabled={isLoadingData}
            >
              Add or Remove
            </Button>
          </Stack>
          <Stack mt={1} height={300} width='100%'>
            <DataGridPro
              autoHeight={false}
              loading={isLoadingData}
              pagination
              disableMultipleColumnsFiltering
              paginationMode='client'
              density='compact'
              pageSize={filter.size}
              rowCount={userViewConfig?.data?.length ?? 0}
              rows={userViewConfig?.data || []}
              columns={columns}
              filterMode='client'
              checkboxSelection={false}
              onFilterModelChange={handleOnFilter}
              onPageChange={handleChangePage}
              onPageSizeChange={handleChangePageSize}
            />
          </Stack>
        </Stack>
      )}
      <ModalPeopleAddComponent
        visible={showModalComponentList}
        onClose={() => setShowModalComponentList(false)}
        selectedUser={selectedUser}
        selectedItemIds={selectedItemIds}
      />

      <ModalMyViewAdditionalConfig
        visible={modalMyViewConfig.show}
        defaultXDimensionTypeId={modalMyViewConfig.defaultXDimension}
        defaultYDimensionTypeId={modalMyViewConfig.defaultYDimension}
        defaultFilterMemberId={modalMyViewConfig.defaultDimensionFilter}
        onClose={() => setModalMyViewConfig(initialModalMyViewConfigValue)}
        selectedView={selectedUserViewConfig}
      />
    </Box>
  );
};
