import { useEffect } from 'react';
import Stack from '@mui/material/Stack';
import { SvgIcon, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import {
  DataGridPro,
  gridClasses,
  DataGridProProps,
  GridRowModesModel,
  GridRowsProp,
  GridRowModes,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { ButtonExport, FileType } from 'components/ButtonComponent/ButtonExport';
import { ButtonDownloadTemplate } from 'components/ButtonComponent/ButtonDownloadTemplate';
import { ButtonImport } from 'components/ButtonComponent/ButtonImport';

import { styled, alpha, Theme } from '@mui/material/styles';

// Custom Icon
import { ReactComponent as CustomIconTrash } from 'assets/icons/icon-trash.svg';

import { CustomNoRowsOverlay } from './components/GridOverlay';
import { GridToolbarFilterButton } from '../ButtonComponent/ButtonFilter';
import { GridToolbarDensitySelector } from '../ButtonComponent/ButtonDensity';
import { RenderDataTableError } from './components/DataTableError';
import { normalizeDataGridRowId } from 'utils/Object';
import { AxiosDefaultErrorEntity } from 'types';
import { ErrorBoxComponent } from 'components/Error/ErrorBoxComponent';

const ODD_OPACITY = 0.2;

function customCheckbox(theme: Theme) {
  return {
    '& .MuiCheckbox-root svg': {
      backgroundColor: 'transparent',
      color: '#98A2AE',
      borderRadius: 2,
    },
  };
}

const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
  minHeight: 500,
  // Strip cell
  [`& .${gridClasses.row}.odd`]: {
    backgroundColor: '#F8F8F8',
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-selected': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY + theme.palette.action.selectedOpacity),
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity
        ),
      },
    },
  },

  [`& .${gridClasses['pinnedColumnHeaders--left']}`]: {
    backgroundColor: '#EDF2FF',
    boxShadow: 'none',
  },
  [`& .${gridClasses['pinnedColumns--left']}`]: {
    boxShadow: '0px 0px 0px 1px #E3EBF6',
  },

  // for editable background shoud be #FFFBE5
  [`& .${gridClasses.row}.editable`]: {
    backgroundColor: '#FFFBE5',
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha('#e5c200', ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },

      '&.Mui-selected': {
        backgroundColor: alpha('#e5c200', ODD_OPACITY + theme.palette.action.selectedOpacity),
        '&:hover, &.Mui-hovered': {
          backgroundColor: alpha(
            '#e5c200',
            ODD_OPACITY + theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity
          ),
        },
      },
    },
  },
  [`& .${gridClasses.cell}.editable`]: {
    backgroundColor: '#FFFBE5 ',
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha('#e5c200', ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },

      '&.Mui-selected': {
        backgroundColor: alpha('#e5c200', ODD_OPACITY + theme.palette.action.selectedOpacity),
        '&:hover, &.Mui-hovered': {
          backgroundColor: alpha(
            '#e5c200',
            ODD_OPACITY + theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity
          ),
        },
      },
    },
  },
  [`& .${gridClasses.row}.cube-odd`]: {
    backgroundColor: '#fff3ca',
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha('#e5af00', ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-selected': {
      backgroundColor: alpha('#e5af00', ODD_OPACITY + theme.palette.action.selectedOpacity),
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(
          '#e5af00',
          ODD_OPACITY + theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity
        ),
      },
    },
  },

  // NON Editable row for cube
  [`& .${gridClasses.row}.non-editable`]: {
    backgroundColor: '#FFFFFF',
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-selected': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY + theme.palette.action.selectedOpacity),
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity
        ),
      },
    },
  },

  // Calculated row
  [`& .${gridClasses.cell}.calculated`]: {
    backgroundColor: '#FFFFFF',
    fontWeight: 'bold',
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-selected': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY + theme.palette.action.selectedOpacity),
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity
        ),
      },
    },
  },
  border: 0,
  color: '#000000',
  fontSize: 12,
  paddingRight: 10,
  paddingLeft: 10,
  WebkitFontSmoothing: 'auto',
  letterSpacing: 'normal',

  '& .MuiDataGrid-virtualScrollerContent': {
    marginBottom: 8,
  },

  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: '#EDF2FF',
    borderTop: '1px solid #E3EBF6',
    borderBottom: '1px solid #E3EBF6',
    textAlign: 'center',
    display: 'flex',
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    fontWeight: 600,
  },
  '& .MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel, .MuiTablePagination-root': {
    fontSize: 12,
  },

  '& .MuiDataGrid-cell': {
    borderBottom: '1px solid #E3EBF6',
  },

  '& .MuiDataGrid-cell.MuiDataGrid-cell--editing:focus-within': {
    outline: 'solid #DCE0E4 1px ',
  },
  '& .MuiDataGrid-cell--editing .MuiInputBase-input': {
    border: '1px solid #DCE0E4',
    maxHeight: '25px !important',
    minHeight: '25px !important',
    borderRadius: '3px',
    fontSize: 13,
  },
  '& .MuiDataGrid-cell--editing  #data-input-cell-custom': {
    border: '1px solid #DCE0E4',
    maxHeight: '13px !important',
    minHeight: '13px !important',
    borderRadius: '3px',
    fontSize: 13,
  },
  '& .MuiDataGrid-cell--editing .MuiAutocomplete-inputRoot': {
    maxHeight: '27px !important',
    minHeight: '27px !important',
    borderRadius: '3px',
    fontSize: 13,
  },

  '& .MuiDataGrid-cell--editing .MuiAutocomplete-inputRoot input': {
    border: 'none',
    maxHeight: '0px !important',
    minHeight: '0px !important',
    borderRadius: '3px',
    fontSize: 13,
  },

  '& .MuiDataGrid-cell--editing .MuiInputBase-input:focus-within': {
    outline: 'none',
  },

  '& .MuiDataGrid-cell--editing:focus-within': {
    outline: 'none !important',
  },

  '& .MuiDataGrid-cell--editing:focus': {
    border: '1px solid #DCE0E4',
  },
  '& .MuiDataGrid-row--editing': {
    boxShadow: 'none',
    backgroundColor: 'transparent',
    marginTop: 6,
    height: '70px',
  },
  '& .MuiDataGrid-row--editing:hover': {
    backgroundColor: 'transparent',
  },
  '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer': {
    display: 'none',
  },

  ...customCheckbox(theme),
}));

interface SettingPanelConfigProps {
  insertButtonText?: string | null;
  infoText?: string | null;
  insertInitialObject?: { [key: string]: string | number | boolean };
  fieldFocusOnInsert: string;
  deleteButtonText?: string;
  onInsertClick?: (initialObject?: { [key: string]: string | number | boolean }) => void;
  withExport?: boolean;
}

interface SettingPanelProps {
  onDeleteData?: () => void;
  onExport: (selectedFileType: FileType) => void;
  onImport: (selectedFileType: FileType) => void;
  onButtonTemplateClick: (selectedFileType: FileType) => void;
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (newModel: (oldModel: GridRowModesModel) => GridRowModesModel) => void;
  setFilterButtonElement: () => void;
  menuOptions?: string[];
  buttonComponent?: React.ReactNode;
  config: SettingPanelConfigProps;
  disabled?: boolean;
  isEditing?: boolean;
  disabledDeleteButton?: boolean;
}

export const SETTING_PANEL_MENU_OPTIONS = {
  EXPORT: 'Export',
  IMPORT: 'Import',
  DELETE: 'Delete',
  TEMPLATE: 'Template',
  FILTER: 'Filter',
  DENSITY: 'Density',
};

type NoRowsOverlayComponentProps = React.JSXElementConstructor<any> & {
  errorData: AxiosDefaultErrorEntity;
  isError: boolean;
  errorTitle: string;
  errorMessage: string;
};

function SettingPanel(props: Readonly<SettingPanelProps>) {
  const {
    setFilterButtonElement,
    onExport,
    onImport,
    onDeleteData,
    setRows,
    setRowModesModel,
    config,
    disabled,
    disabledDeleteButton,
    isEditing,
    buttonComponent,
    onButtonTemplateClick,
    menuOptions = Object.values(SETTING_PANEL_MENU_OPTIONS),
  } = props;

  const { fieldFocusOnInsert = '', insertButtonText = 'Add', insertInitialObject = {}, withExport = true } = config;

  const handleAddData = () => {
    if (config.onInsertClick) {
      config?.onInsertClick();
    } else {
      const id = new Date().getTime();
      setRows((oldRows) => [{ id, ...insertInitialObject }, ...oldRows]);
      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: fieldFocusOnInsert },
      }));
    }
  };

  const handleCancelInsert = () => {
    setRows((oldRows) => oldRows.filter((row) => normalizeDataGridRowId(row.id) !== null));
    // get id of the row has edit mode
    setRowModesModel((oldModel) => {
      const id = Object.keys(oldModel).find((key) => oldModel[key].mode === GridRowModes.Edit);
      if (id) {
        const newModel = { ...oldModel };
        delete newModel[id];
        return newModel;
      }
      return oldModel;
    });
  };

  return (
    <Stack>
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        spacing={1}
        sx={{ backgroundColor: '#fff', py: 1.8 }}
      >
        <Stack direction='row' width='100%' justifyContent='space-between'>
          <Stack direction='row'>
            {!isEditing && buttonComponent && buttonComponent}
            {!isEditing && insertButtonText && !buttonComponent && (
              <Button
                variant='main-table-panel'
                disabled={disabled}
                startIcon={<AddCircleOutlineOutlinedIcon />}
                onClick={handleAddData}
              >
                {insertButtonText}
              </Button>
            )}

            {isEditing && (
              <Button
                sx={{
                  width: 170,
                }}
                variant='secondary-table-panel'
                disabled={disabled}
                onClick={handleCancelInsert}
              >
                Cancel
              </Button>
            )}
          </Stack>
          <Stack direction='row' spacing={0.6}>
            {menuOptions.includes(SETTING_PANEL_MENU_OPTIONS.FILTER) && (
              <GridToolbarFilterButton
                ref={setFilterButtonElement}
                hidden
                sx={{
                  '& .MuiButton-label': {
                    display: 'none',
                  },
                }}
                componentsProps={{
                  button: {
                    disabled,
                    variant: 'main-table-panel-borderless',
                  },
                }}
              />
            )}
            {menuOptions.includes(SETTING_PANEL_MENU_OPTIONS.DENSITY) && (
              <GridToolbarDensitySelector variant='main-table-panel-borderless' />
            )}

            {menuOptions.includes(SETTING_PANEL_MENU_OPTIONS.DELETE) && (
              <Tooltip title='Delete'>
                <IconButton onClick={onDeleteData} disabled={disabled || disabledDeleteButton}>
                  <SvgIcon
                    fontSize='small'
                    sx={{ fill: disabled || disabledDeleteButton ? '#00000042' : '#42BB93' }}
                    inheritViewBox
                    component={CustomIconTrash}
                  />
                </IconButton>
              </Tooltip>
            )}

            {withExport && (
              <>
                <Divider orientation='vertical' variant='middle' flexItem sx={{ px: 0.5 }} />
                <ButtonExport disabled={disabled} onClick={onExport} />
                <ButtonImport disabled={disabled} onClick={onImport} />
                <ButtonDownloadTemplate disabled={disabled} onClick={onButtonTemplateClick} />
              </>
            )}
          </Stack>
        </Stack>
      </Stack>
      {config?.infoText && (
        <>
          <Divider orientation='horizontal' variant='middle' flexItem sx={{ px: 0.5 }} />
          <Stack spacing={1} sx={{ backgroundColor: '#fff', py: 1.8, px: 1.8 }}>
            <Typography variant='body2' color='gray'>
              <span>*</span>
              {config.infoText}
            </Typography>
          </Stack>
        </>
      )}
    </Stack>
  );
}

function NoRowsOverlayComponent(props: NoRowsOverlayComponentProps) {
  const { errorData, isError, errorTitle, errorMessage, ...restProps } = props;

  if (isError && errorData) return <ErrorBoxComponent error={errorData} />;
  if (isError) return <RenderDataTableError title={errorTitle} description={errorMessage} />;

  return <CustomNoRowsOverlay {...restProps} />;
}

interface CustomDataTableProps extends DataGridProProps {
  isError?: boolean;
  errorMessage?: string | null;
  errorTitle?: string;
  viewOnly?: boolean;
  errorData?: AxiosDefaultErrorEntity;
  initialScrollColumnIndex?: number;
}

export function CustomDataTable(props: Readonly<CustomDataTableProps>) {
  const {
    isError,
    errorMessage = 'An error occurred while loading data. Please try again later.',
    errorTitle = 'Failed to display data - [FE-500]',
    errorData,
    initialScrollColumnIndex = 0,
    ...defaultProps
  } = props;

  const gridApiRef = useGridApiRef();
  useEffect(() => {
    if (initialScrollColumnIndex === 0) return;
    if (gridApiRef?.current) {
      setTimeout(() => {
        gridApiRef.current.scrollToIndexes({
          colIndex: initialScrollColumnIndex,
          rowIndex: 0,
        });
      }, 200);
    }
  }, [gridApiRef, initialScrollColumnIndex]);

  return (
    <StyledDataGrid
      pagination
      autoHeight
      apiRef={gridApiRef}
      initialState={{
        pagination: {
          page: 0,
        },
      }}
      sx={{ backgroundColor: '#fff' }}
      rowHeight={42}
      paginationMode='server'
      editMode='row'
      density='compact'
      checkboxSelection
      sortingMode='server'
      filterMode='server'
      disableSelectionOnClick
      {...defaultProps}
      components={{
        Toolbar: SettingPanel,
        NoRowsOverlay: (props) => (
          <NoRowsOverlayComponent
            {...props}
            isError={isError}
            errorData={errorData}
            errorTitle={errorTitle}
            errorMessage={errorMessage}
          />
        ),
        ...defaultProps.components,
      }}
    />
  );
}
