import { Player } from '@lottiefiles/react-lottie-player';
import animationData from 'assets/lottie/initial-setup-loading.json';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const contentContainerStyle = {
  my: 5,
  mx: 4,
  px: 4,
  py: 4,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: '#fff',
  borderRadius: '7px',
  boxShadow: '0px 1px 6px #C8CFE2',
};

const loadingContainerStyle = {
  ...contentContainerStyle,
  boxShadow: 'none',
  backgroundColor: 'transparent',
};

export function LoadingSetupComponent() {
  return (
    <Box width={500} sx={loadingContainerStyle}>
      <Player
        src={animationData}
        autoplay
        loop
        style={{
          height: 150,
        }}
      />
      <Typography component='h5' variant='h6'>
        We're setting things up for you...
      </Typography>
      <Typography component='p' variant='subtitle2' color='#6E7FAA'>
        This will take a few minutes. Please do not close this page.
      </Typography>
    </Box>
  );
}
