import { Formik, FormikProps } from 'formik';
import { useEffect, useRef, useState } from 'react';
import Divider from '@mui/material/Divider';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import ButtonLoading from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import LockResetOutlinedIcon from '@mui/icons-material/LockResetOutlined';

import { EditPersonValidationSchema } from 'config/FormValidaton/TenantMaster/MasterTable/PersonTableValidationSchema';
import { usePersonDetails, useUpdatePerson } from 'services/v1/TenantMaster/MasterTable/PersonTableDataService';
import { PersonUsertRequest } from 'types/api/TenantMaster/MasterTable/PersonTableDataTypes';
import { getErrorMessage } from 'utils/Error';
import { AxiosDefaultErrorEntity } from 'types';
import ActivityIndicator from 'components/ActivityIndicatorComponent';
import { ModalResetPassword } from './ModalResetPasswordPersonComponent';

const ModalContentStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  minHeight: 400,
  borderRadius: 1,
  px: 3,
  py: 2,
};

const textInputStyles = {
  width: '55%',
  '& .MuiOutlinedInput': {
    padding: '1px 2px',
  },
};

const closeIconStyle = { color: '#98A2AE', cursor: 'pointer' };

interface FormValue {
  person: string;
  fullName: string;
  mobile: string;
  email: string;
}

const formInitialValues: FormValue = {
  person: '',
  fullName: '',
  mobile: '',
  email: '',
};

interface ModalEditPersonProps {
  visible?: boolean;
  onClose?: () => void;
  editId?: string | null;
}

export function ModalEditPersonComponent(props: ModalEditPersonProps) {
  const formikRef = useRef<FormikProps<FormValue>>(null);
  const { onClose, visible = false } = props;

  const [showModalResetPassword, setShowModalResetPassword] = useState(false);

  const {
    mutate: updatePerson,
    isLoading: isUpdating,
    isError: isUpdatingError,
    error: errorUpdating,
    reset: resetUpdatePerson,
  } = useUpdatePerson();

  const {
    data: personDetailsData,
    isLoading,
    isError: isErrorPersonDetails,
    error: errorPersonDetails,
    isSuccess,
  } = usePersonDetails(props.editId || undefined);

  const handleOnClose = () => {
    onClose?.();
    resetUpdatePerson();
  };

  const isSemiLoading = isUpdating;

  const handleOnOpenModalResetPassword = () => {
    setShowModalResetPassword(true);
  };

  const handleOnCloseModalResetPassword = () => {
    setShowModalResetPassword(false);
  };

  const handleOnSave = (data: FormValue) => {
    const payload: PersonUsertRequest = {
      id: props.editId || '',
      fullName: data.fullName,
      mobile: data.mobile,
      userName: data.email,
      person: data.person,
    };

    updatePerson(payload, {
      onSuccess: () => {
        onClose?.();
      },
    });
  };

  useEffect(() => {
    if (personDetailsData) {
      formikRef.current?.setValues({
        person: personDetailsData.data.person,
        fullName: personDetailsData.data.fullName,
        mobile: personDetailsData.data.mobile,
        email: personDetailsData.data.userName,
      });
    }
  }, [personDetailsData]);

  return (
    <div>
      <Formik
        innerRef={formikRef}
        initialValues={formInitialValues}
        validationSchema={EditPersonValidationSchema}
        onSubmit={handleOnSave}
      >
        {(formikProps) => {
          const { handleSubmit, values, handleChange, errors, touched, handleBlur } = formikProps;
          return (
            <Modal open={visible} onClose={handleOnClose}>
              <Stack direction='column' sx={ModalContentStyle} justifyContent='space-between'>
                <Stack direction='column'>
                  <Stack justifyContent='space-between' direction='row' alignItems='center' spacing={2}>
                    <Typography variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
                      Edit Person
                    </Typography>
                    <IconButton onClick={handleOnClose} component='label'>
                      <HighlightOffRoundedIcon sx={closeIconStyle} />
                    </IconButton>
                  </Stack>
                  <Divider sx={{ mb: 1 }} />
                </Stack>
                {isLoading && (
                  <Stack sx={{ height: 300 }}>
                    <ActivityIndicator />
                  </Stack>
                )}
                {isErrorPersonDetails && (
                  <Alert severity='error'>{getErrorMessage(errorPersonDetails as AxiosDefaultErrorEntity)}</Alert>
                )}

                {isSuccess && (
                  <Stack>
                    <Stack>
                      <Stack
                        direction='row'
                        spacing={2}
                        alignItems='center'
                        justifyContent='space-between'
                        sx={{ mt: 2 }}
                      >
                        <Typography variant='input-label'>Person</Typography>
                        <TextField
                          sx={textInputStyles}
                          size='small'
                          disabled
                          placeholder='e.g. John'
                          onChange={handleChange}
                          value={values?.person}
                          onBlur={handleBlur}
                          name='person'
                          error={touched.person && Boolean(errors.person)}
                          helperText={touched.person && errors.person}
                        />
                      </Stack>

                      <Stack
                        direction='row'
                        spacing={2}
                        alignItems='center'
                        justifyContent='space-between'
                        sx={{ mt: 2 }}
                      >
                        <Typography variant='input-label'>Full Name</Typography>
                        <TextField
                          sx={textInputStyles}
                          size='small'
                          disabled={isSemiLoading}
                          placeholder='e.g. John Doe'
                          onChange={handleChange}
                          value={values?.fullName}
                          onBlur={handleBlur}
                          name='fullName'
                          error={touched.fullName && Boolean(errors.fullName)}
                          helperText={touched.fullName && errors.fullName}
                        />
                      </Stack>

                      <Stack
                        direction='row'
                        spacing={2}
                        alignItems='center'
                        justifyContent='space-between'
                        sx={{ mt: 2 }}
                      >
                        <Typography variant='input-label'>Email</Typography>
                        <TextField
                          sx={textInputStyles}
                          size='small'
                          disabled={isSemiLoading}
                          placeholder='e.g. johndoe@email.com'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.email}
                          name='email'
                          error={touched.email && Boolean(errors.email)}
                          helperText={touched.email && errors.email}
                        />
                      </Stack>
                      <Stack
                        direction='row'
                        spacing={2}
                        alignItems='center'
                        justifyContent='space-between'
                        sx={{ mt: 2 }}
                      >
                        <Typography variant='input-label'>Mobile Phone</Typography>
                        <TextField
                          sx={textInputStyles}
                          size='small'
                          disabled={isSemiLoading}
                          placeholder='e.g. 6123456789'
                          onChange={(e) => {
                            const value = e.target.value;
                            if (value.match(/^[0-9]*$/)) {
                              handleChange(e);
                            }
                          }}
                          onBlur={handleBlur}
                          value={values?.mobile}
                          name='mobile'
                          error={touched.mobile && Boolean(errors.mobile)}
                          helperText={touched.mobile && errors.mobile}
                        />
                      </Stack>
                      <Stack
                        direction='row'
                        spacing={2}
                        alignItems='center'
                        justifyContent='space-between'
                        sx={{ mt: 2 }}
                      >
                        <Typography variant='input-label'>Reset Password</Typography>
                        <Button
                          disabled={isSemiLoading}
                          variant='main-table-panel-border'
                          onClick={handleOnOpenModalResetPassword}
                          startIcon={<LockResetOutlinedIcon />}
                        >
                          Reset Password
                        </Button>
                      </Stack>
                    </Stack>

                    <Stack py={2}>
                      {isUpdatingError && (
                        <Alert severity='error'>{getErrorMessage(errorUpdating as AxiosDefaultErrorEntity)}</Alert>
                      )}
                    </Stack>

                    <Divider sx={{ mb: 1, mt: 2 }} />
                    <Stack direction='row' justifyContent='space-between' alignItems='' alignContent='flex-end'>
                      <Stack />
                      <Stack direction='row' justifyContent='flex-end' spacing={2} alignItems='center' sx={{ py: 1 }}>
                        <Button disabled={isSemiLoading} variant='main-table-panel-border' onClick={handleOnClose}>
                          Cancel
                        </Button>
                        <ButtonLoading
                          disabled={!EditPersonValidationSchema.isValidSync(values)}
                          variant='main-table-panel'
                          loading={isSemiLoading}
                          onClick={() => handleSubmit()}
                        >
                          Update
                        </ButtonLoading>
                      </Stack>
                    </Stack>
                  </Stack>
                )}
              </Stack>
            </Modal>
          );
        }}
      </Formik>
      <ModalResetPassword
        visible={showModalResetPassword}
        userId={props.editId || ''}
        onClose={handleOnCloseModalResetPassword}
      />
    </div>
  );
}
