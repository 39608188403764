import * as Yup from 'yup';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { MainLayoutComponent } from 'components/LayoutComponent/SidebarLayout/MainLayoutComponent';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import ButtonLoading from '@mui/lab/LoadingButton';
import { Box } from '@mui/material';
import { useFormik } from 'formik';
import { AutoCompleteItem } from 'types/api/Common/AutoCompleteTypes';
import { useNavigate } from 'react-router-dom';
import { PATH_CONSTANT } from 'constant/PathConstant';
import {
  IStandardViewAttributeDefinitionRef,
  StandardViewAttributeDefinition,
} from './components/StandardViewAttributeDefinition';
import ActivityIndicator from 'components/ActivityIndicatorComponent';
import Alert from '@mui/material/Alert';
import {
  useCreateStandardViewDefinition,
  useGetStandardViewDefinitionCubeDropdown,
  useGetStandardViewDefinitionCubeLayerDropdown,
  useGetStandardViewDefinitionSubjectDropdown,
} from 'services/v1/SystemTenant/AROView/StandardViewDefinitionService';
import {
  RequestCreateStandardViewDefinition,
  RequestStandardViewAttributeDefinition,
} from 'types/api/SystemTenant/AROView/StandardViewDefinitionTypes';
const autocompleteStyles = {
  width: '55%',
  '& .MuiOutlinedInput-root': {
    padding: '1px 2px',
  },
  '& .MuiAutocomplete-popper': {
    fontSize: '10px',
  },
};
const textInputStyles = {
  '& .MuiOutlinedInput': {
    padding: '1px 2px',
  },
};

export interface CreateStandardViewDefinitionFormikInitialValues {
  name: string | null;
  subject: AutoCompleteItem | null;
  cube: AutoCompleteItem | null;
  cubeLayer: AutoCompleteItem | null;
}
export const createStandardViewDefinitionFormikInitialValues: CreateStandardViewDefinitionFormikInitialValues = {
  name: null,
  subject: null,
  cube: null,
  cubeLayer: null,
};

export const createFunctionDefinitionValidationSchema = Yup.object().shape({
  name: Yup.string().required('This field is required').nullable(),
  subject: Yup.object().required('This field is required').nullable(),
  cube: Yup.object().required('This field is required').nullable(),
  cubeLayer: Yup.object().required('This field is required').nullable(),
});

export default function CreateStandardViewDefinition() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const attributeDefiintionRef = React.useRef<IStandardViewAttributeDefinitionRef>(null);

  const formik = useFormik({
    initialValues: createStandardViewDefinitionFormikInitialValues,
    validationSchema: createFunctionDefinitionValidationSchema,
    onSubmit: () => {},
  });
  const { data: subjectDropdownOptions, isLoading: isLoadingSubjectOptions } =
    useGetStandardViewDefinitionSubjectDropdown();
  const { data: cubeDropdownOptions, isLoading: isLoadingCubeOptions } = useGetStandardViewDefinitionCubeDropdown(
    formik.values.subject?.value?.toString() ?? ''
  );
  const { data: cubeLayerDropdownOptions, isLoading: isLoadingCubeLayerOptions } =
    useGetStandardViewDefinitionCubeLayerDropdown(formik.values.cube?.value?.toString() ?? null);

  const { mutate: createStandardViewDefinition, isLoading: isSubmitting } = useCreateStandardViewDefinition();
  const handleOnSubmit = () => {
    const data = formik.values;

    const attributeDefinitions: RequestStandardViewAttributeDefinition[] =
      attributeDefiintionRef.current?.getRowData().map(
        (item, index) =>
          ({
            id: item.isNew ? undefined : item.id,
            attributeId: item.attribute?.value?.toString(),
            ruleType: item.ruleType?.value,
            sourceAttributeId: item.sourceAttribute?.value?.toString(),
            columnIndexOrder: index + 1,
          } as RequestStandardViewAttributeDefinition)
      ) ?? [];

    if (data.cubeLayer?.value && data.cube?.value && data.subject?.value && data.name) {
      const payload: RequestCreateStandardViewDefinition = {
        cubeId: data.cube?.value?.toString(),
        cubeLayerId: data.cubeLayer?.value?.toString(),
        subjectId: data.subject?.value?.toString(),
        name: data.name,
        attributeDefinitions,
      };
      createStandardViewDefinition(payload, {
        onSuccess: () => {
          handleOnCancel();
        },
      });
    }
  };

  const handleOnCancel = () => {
    navigate(PATH_CONSTANT.SYSTEM.VIEW.STANDARD_VIEW_DEFINITION);
  };

  const isAllFormValid = createFunctionDefinitionValidationSchema.isValidSync(formik.values);
  const isLoadingOptionsData = isLoadingSubjectOptions;

  const handleOnCubeChange = (e: React.SyntheticEvent, value: AutoCompleteItem | null) => {
    formik.setFieldValue('cube', value);
    formik.setFieldValue('cubeLayer', null);
  };

  const handleOnSubjectChange = (e: React.SyntheticEvent, value: AutoCompleteItem | null) => {
    formik.setFieldValue('subject', value);
    handleOnCubeChange({} as React.SyntheticEvent, null);
  };

  return (
    <MainLayoutComponent
      pageTitle='Add New Standard View'
      breadcrumbs={[t('SIDEBAR_MENU.DASHBOARD'), 'Standard View Definition']}
    >
      <Box p={2} bgcolor='#fff'>
        {isLoadingOptionsData ? (
          <Stack height={450}>
            <ActivityIndicator />
          </Stack>
        ) : (
          <>
            <Typography variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
              Standard View Definition
            </Typography>
            <Divider sx={{ my: 1 }} />
            <Stack width='100%' direction='row' gap={3} mb={5} flexWrap='wrap' alignItems='center'>
              <Stack width='40%'>
                <Typography variant='input-label' fontWeight={800}>
                  View Name
                </Typography>
                <Typography variant='input-label-gray' fontSize={13}></Typography>
                <TextField
                  sx={textInputStyles}
                  name='name'
                  hiddenLabel
                  value={formik.values.name}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                  variant='outlined'
                  size='small'
                  placeholder='e.g. Interest Function for Loan'
                />
              </Stack>
              <Stack width='40%'>
                <Typography variant='input-label' fontWeight={800}>
                  Select Subject
                </Typography>
                <Typography variant='input-label-gray' fontSize={13}>
                  Choose the subject (table) from which you want to pull the values.
                </Typography>
                <Autocomplete
                  options={subjectDropdownOptions?.data ?? []}
                  getOptionLabel={(option) => option.label}
                  sx={{ ...autocompleteStyles, width: '100%' }}
                  clearIcon={null}
                  value={formik.values.subject}
                  onBlur={formik.handleBlur}
                  onChange={handleOnSubjectChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name='subject'
                      placeholder='Select Subject...'
                      error={formik.touched.subject && Boolean(formik.errors.subject)}
                      helperText={formik.touched.subject && formik.errors.subject}
                    />
                  )}
                />
              </Stack>
              <Stack width='40%'>
                <Typography variant='input-label' fontWeight={800}>
                  Select Cube
                </Typography>
                <Typography variant='input-label-gray' fontSize={13}>
                  Choose the cube from which you want to calculate the values for.
                </Typography>
                <Autocomplete
                  options={cubeDropdownOptions?.data ?? []}
                  disabled={!formik.values.subject || isLoadingCubeOptions}
                  getOptionLabel={(option) => option.label}
                  sx={{ ...autocompleteStyles, width: '100%' }}
                  clearIcon={null}
                  value={formik.values.cube}
                  onBlur={formik.handleBlur}
                  onChange={handleOnCubeChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name='cube'
                      placeholder='Select Cube...'
                      error={formik.touched.cube && Boolean(formik.errors.cube)}
                      helperText={formik.touched.cube && formik.errors.cube}
                    />
                  )}
                />
              </Stack>

              <Stack width='40%'>
                <Typography variant='input-label' fontWeight={800}>
                  Select Cube Layer
                </Typography>
                <Typography variant='input-label-gray' fontSize={13}>
                  Choose the cube layer from which you want to calculate the values for.
                </Typography>
                <Autocomplete
                  disabled={!formik.values.cube || isLoadingCubeLayerOptions}
                  options={cubeLayerDropdownOptions?.data ?? []}
                  value={formik.values.cubeLayer}
                  onBlur={formik.handleBlur}
                  onChange={(e, value) => {
                    formik.setFieldValue('cubeLayer', value);
                  }}
                  sx={{ ...autocompleteStyles, width: '100%' }}
                  clearIcon={null}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name='cubeLayer'
                      placeholder={isLoadingCubeLayerOptions ? 'Loading...' : 'Select Cube Layer...'}
                      error={formik.touched.cubeLayer && Boolean(formik.errors.cubeLayer)}
                      helperText={formik.touched.cubeLayer && formik.errors.cubeLayer}
                    />
                  )}
                />
              </Stack>
            </Stack>
            <Typography variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
              Attribute Definition
            </Typography>
            <Divider sx={{ my: 1 }} />
            {formik.values.cubeLayer && formik.values.cube && formik.values.subject && formik.values.name && (
              <Alert severity='info' sx={{ my: 1 }}>
                When viewing your dashboard, any attributes set with the 'Display From Cube' rule will be modified to
                include a 'Current' prefix. For example, an attribute named 'Fuel Cost' will be displayed as 'Current
                Fuel Cost'.
              </Alert>
            )}
            <StandardViewAttributeDefinition
              disabled={
                !formik.values.cubeLayer || !formik.values.cube || !formik.values.subject || !formik.values.name
              }
              subjectId={formik.values.subject?.value?.toString()}
              cubeId={formik.values.cube?.value?.toString()}
              ref={attributeDefiintionRef}
            />

            <Stack direction='row' px={2} justifyContent='space-between' alignItems='center'>
              <Stack />
              <Stack direction='row' gap={2}>
                <ButtonLoading variant='main-table-panel-border' onClick={handleOnCancel}>
                  Cancel
                </ButtonLoading>
                <ButtonLoading
                  variant='main-table-panel'
                  onClick={handleOnSubmit}
                  loading={isSubmitting}
                  disabled={!isAllFormValid}
                >
                  Save
                </ButtonLoading>
              </Stack>
            </Stack>
          </>
        )}
      </Box>
    </MainLayoutComponent>
  );
}
