import qs from 'query-string';
import { GridRowModesModel, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { useNavigate } from 'react-router-dom';
import { TableDefinitionItem } from 'types/api/SystemTenant/AROKMS/TableDefinitionTypes';
import Button from '@mui/material/Button';
import { ModalParamState } from './ModalParameter';
import { RULES_CONSTANT } from 'constant/RuleConstant';
import { VALIDATION_PATTERN_TYPE } from '../ModalValidationOptions';

interface RenderButtonActionProps extends GridRenderCellParams<TableDefinitionItem> {
  setRowModesModel: (newModel: (oldModel: GridRowModesModel) => GridRowModesModel) => void;
  setModalParameterState: (newState: (OldState: ModalParamState) => ModalParamState) => void;
}
const TEXT_INPUT_RULES = [
  RULES_CONSTANT.TEXT.RULE_INPUT,
  RULES_CONSTANT.BASE_SUBJECT.RULE_ATTRIBUTE_SUBJECT_BASE,
  RULES_CONSTANT.TEXT.RULE_CHAR_LIMIT,
];

export function RenderButtonActionValidation(props: RenderButtonActionProps) {
  const navigate = useNavigate();
  const { hasFocus, row, setModalParameterState } = props;

  const handleOnClick = () => {
    navigate({
      pathname: window.location.pathname,
      search: `?${qs.stringify({
        tddtid: row?.dataType?.id,
        dependenciesAttribute: row?.allowDependenciesDataTypes,
        recordAssociateSubjectId: row?.associatedSubject?.label || undefined,
        recordAssociateAttributeId: row?.attribute?.label || undefined,
      })}`,
    });
    setModalParameterState((prevState) => ({ ...prevState, open: true, tableDefinitionId: row.id }));
  };

  if (!TEXT_INPUT_RULES.includes(row.ruleType?.code)) return <span></span>;

  const validationMetaData = row.validationPattern ? JSON.parse(row.validationPattern) : null;

  const isValidationExist = validationMetaData && validationMetaData.validationPattern !== VALIDATION_PATTERN_TYPE.NONE;

  return (
    <Button
      variant='secondary-table-panel'
      sx={{
        height: '58%',
        fontSize: '12px',
      }}
      onClick={handleOnClick}
      tabIndex={hasFocus ? 0 : -1}
      onKeyDown={(event: React.KeyboardEvent) => {
        if (event.key === ' ') {
          // Prevent key navigation when focus is on button
          event.stopPropagation();
        }
      }}
    >
      {isValidationExist ? 'Edit' : 'Add'}
    </Button>
  );
}
