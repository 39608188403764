export const TENANT_CONFIGURATION_KEY = {
  CONFIG_INITIAL_FINANCIAL_YEAR: 'CONFIG_INITIAL_FINANCIAL_YEAR',
  CONFIG_END_DAY_OF_WEEK: 'CONFIG_END_DAY_OF_WEEK',
  INITIAL_SETUP_STATUS: 'INITIAL_SETUP_STATUS',
  INITIAL_SETUP_ERROR: 'INITIAL_SETUP_ERROR',
  TENANT_STATUS: 'TENANT_STATUS',
  ENABLE_DUPLICATION_VALIDATION_EVENT_DATA_IMPORT: 'ENABLE_DUPLICATION_VALIDATION_EVENT_DATA_IMPORT',
  ENABLE_OVERRIDE_VALIDATION_EVENT_DATA_IMPORT: 'ENABLE_OVERRIDE_VALIDATION_EVENT_DATA_IMPORT',
};

export const TENANT_INITIAL_SETUP_STATUS = {
  ON_PROGRESS: 'ON_PROGRESS',
  COMPLETED: 'COMPLETED',
  ERROR: 'ERROR',
  INITIAL: 'INITIAL',
};

export const CONFIG_FLAG_VALUE = {
  ENABLE: 'ENABLE',
  DISABLE: 'DISABLE',
};
