import { memo } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';
import { ENV_CONFIG } from 'config/env';
const ModalContentStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  borderRadius: 1,
  px: 3,
  py: 2,
};

const closeIconStyle = { color: '#98A2AE', cursor: 'pointer' };

interface ModalPreviewLocationProps {
  visible?: boolean;
  onClose: () => void;
  locations: { lat: number; lng: number }[];
}

function ModalPreviewLocationComponent(props: ModalPreviewLocationProps) {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: ENV_CONFIG.license.GMAPS_API_KEY,
  });
  const { onClose, visible = false, locations } = props;

  const handleCloseModal = () => {
    onClose();
  };

  return (
    <div>
      <Modal open={visible} onClose={handleCloseModal}>
        <Stack direction='column' sx={ModalContentStyle} spacing={2}>
          <Stack justifyContent='space-between' direction='row' spacing={2}>
            <Typography variant='body1' component='h2' color='#394798'>
              Maps Preview
            </Typography>
            <IconButton onClick={handleCloseModal} component='label'>
              <CloseIcon sx={closeIconStyle} fontSize='small' />
            </IconButton>
          </Stack>
          {isLoaded && (
            <div style={{ height: '60vh', width: '100%', marginBottom: 15 }}>
              <GoogleMap
                center={locations[0]}
                zoom={14}
                options={{ disableDefaultUI: true, zoomControl: true }}
                mapContainerClassName='map-container'
              >
                {locations.map((location, index) => {
                  return <Marker key={index} position={{ lat: location.lat, lng: location.lng }} />;
                })}
              </GoogleMap>
            </div>
          )}
        </Stack>
      </Modal>
    </div>
  );
}

export const ModalPreviewLocation = memo(ModalPreviewLocationComponent);
