import React from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import animationMainLogo from 'assets/lottie/main-logo-animation.json';
import { Box } from '@mui/material';

const animationBoxContainerStyle = {
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
};

const animationPlayerStyle = {
  height: 80,
};

export function SplashScreenPage({
  innerRef = { current: null },
}: {
  innerRef?: React.MutableRefObject<Player | null>;
}) {
  return (
    <Box sx={animationBoxContainerStyle}>
      <Player ref={innerRef} loop={false} src={animationMainLogo} style={animationPlayerStyle} />
    </Box>
  );
}
