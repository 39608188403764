import { AxiosResponse } from 'axios';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import axios from 'libs/Axios';

import { AxiosDefaultErrorEntity } from 'types/api/Common/ErrorTypes';
import {
  TableDefinitionDescriptionDetails,
  TableDefinitionDescriptionRequest,
} from 'types/api/SystemTenant/AROKMS/TableDefinitionDescriptionTypes';

export const TABLE_DEFINITION_DESCRIPTION_KEY = {
  TABLE_DEFINITION_DESCRIPTION: 'TABLE_DEFINITION_DESCRIPTION',
};

export function useGetTableDefinitionDescription(subjectId?: string) {
  return useQuery<AxiosResponse<TableDefinitionDescriptionDetails>, AxiosDefaultErrorEntity>(
    [TABLE_DEFINITION_DESCRIPTION_KEY.TABLE_DEFINITION_DESCRIPTION, subjectId],
    () => axios.get(`/api/v1/table-definition/description/${subjectId}`).then((res) => res),
    {
      enabled: !!subjectId,
    }
  );
}

export function useUpsertTableDefinitionDescription() {
  const queryClient = useQueryClient();
  return useMutation<
    AxiosResponse<TableDefinitionDescriptionDetails>,
    AxiosDefaultErrorEntity,
    TableDefinitionDescriptionRequest
  >((bodyRequest) => axios.post('/api/v1/table-definition/description', bodyRequest), {
    onSettled: () => {
      queryClient.invalidateQueries([TABLE_DEFINITION_DESCRIPTION_KEY.TABLE_DEFINITION_DESCRIPTION]);
    },
  });
}
