import { useEffect, useMemo } from 'react';
import * as Yup from 'yup';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import ButtonLoading from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';

import { AutoCompleteItem } from 'types/api/Common/AutoCompleteTypes';
import { useGetPullCubeDropdown } from 'services/v1/SystemTenant/AROCube/CubeBuilderService';
import { ModalCubeDefinitionParamsProps } from './ModalCubeDefinitionParameter';
import { FormCubeDefinitionMetaDataValues } from './CubeDefinitionSettingPanel';
import { useFormik } from 'formik';
import { useGetCubeLayerSetLayersDropdown } from 'services/v1/SystemTenant/AROCube/CubeLayerSetService';
import ActivityIndicator from 'components/ActivityIndicatorComponent';
import { CubeDefinitionRowItem } from 'types/api/SystemTenant/AROCube/CubeBuilderTypes';
import AutoComplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { Box, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { DropdownSX, ToggleButtonGroupSX } from 'utils/CommonSx';
import { CUBE_PULL_TOTALING_RULE, CUBE_TIME_PERIOD_TYPE } from 'constant/CubeConstant';
import { DropdownItem } from 'types/api/SystemTenant/AROKMS/TableDefinitionTypes';
import { useGetTimePeriodSetDetail } from 'services/v1/SystemTenant/AROCube/CubeTimePeriodSetService';

const ModalContentStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  maxHeight: 500,
  overflow: 'auto',
  borderRadius: 1,
  py: 2,
};

const closeIconStyle = { color: '#98A2AE', cursor: 'pointer' };

type FormikPullCubeWeeklyToTotalParameter = {
  totalCalculationRule: string;
  targetPullCube: AutoCompleteItem | null;
};

function getTimePeriodsTotalLabel() {
  return [
    {
      value: CUBE_PULL_TOTALING_RULE.LAST_WEEK_IN_MONTH,
      label: 'Only Last Week in Month',
    },
    {
      value: CUBE_PULL_TOTALING_RULE.ALL_WEEKS_IN_MONTH,
      label: '4-Weeks Rolling',
    },
    {
      value: CUBE_PULL_TOTALING_RULE.ALL_WEEKS_IN_QUARTER,
      label: '13-Weeks Rolling',
    },
    {
      value: CUBE_PULL_TOTALING_RULE.ALL_WEEKS_IN_YEAR,
      label: '52-Weeks Rolling',
    },
  ];
}

const validationSchema = Yup.object({
  totalCalculationRule: Yup.string().required('Source Cube is required'),
  targetPullCube: Yup.object().nullable().required('Source Cube  is required'),
});

const renderOption = (props: React.HTMLAttributes<HTMLLIElement>, option: AutoCompleteItem) => (
  <Box component='li' sx={{ '& > span': { fontSize: '14px', mr: 1, flexShrink: 0 } }} {...props}>
    <span>{option.label}</span>
  </Box>
);

const initialValues = {
  totalCalculationRule: CUBE_PULL_TOTALING_RULE.LAST_WEEK_IN_MONTH,
  targetPullCube: null,
};
export function ModalPullWeeklyToTotal(
  props: ModalCubeDefinitionParamsProps & {
    cubeMetaData: FormCubeDefinitionMetaDataValues;
    cubeId?: string;
  }
) {
  const { onClose, visible = false, onSubmitParameter, cubeDefinitionItem, cubeMetaData } = props;

  const { data: currentCubeTimePeriodSet, isLoading: isLoadingCurrentCubeTimePeriodSet } = useGetTimePeriodSetDetail(
    cubeMetaData.timePeriod?.value
  );
  const { data: pullCubeDropdownOptions, isLoading: isLoadingCubeOptions } = useGetPullCubeDropdown({
    attributeId: cubeDefinitionItem?.attribute.value,
    layerSetId: cubeMetaData.layerSet?.value,
    timePeriodType: CUBE_TIME_PERIOD_TYPE.WEEK,
  });

  console.log({
    currentCubeTimePeriodSet,
    timePeriodId: cubeMetaData.timePeriod?.value,
    cubeMetaData,
  });
  const { data: currentCubeLayers, isLoading: isLoadingCurrentCubeLayers } = useGetCubeLayerSetLayersDropdown(
    cubeMetaData.layerSet?.value
  );

  const timePeriodType = useMemo(() => {
    return currentCubeTimePeriodSet?.data.timePeriod;
  }, [currentCubeTimePeriodSet]);

  const TOTAL_CALCULATION_OPTIONS = useMemo(() => {
    if (timePeriodType) {
      return getTimePeriodsTotalLabel();
    }
    return [];
  }, [timePeriodType]);

  const formik = useFormik<FormikPullCubeWeeklyToTotalParameter>({
    initialValues,
    validationSchema,
    onSubmit: (values) => {},
  });

  const handleOnCancel = () => {
    handleOnCloseModal();
  };

  const handleOnCloseModal = () => {
    onClose?.();
    formik.resetForm();
  };

  const handleOnSave = () => {
    if (cubeDefinitionItem) {
      const cubePullEventDefinitionRow: CubeDefinitionRowItem = {
        ...cubeDefinitionItem,
        targetPullTotalCube: formik.values.targetPullCube as DropdownItem,
        pullTotalCalculationRule: formik.values.totalCalculationRule,
        ruleTypeParams: formik.values.targetPullCube?.label as string,
      };
      onSubmitParameter?.(cubePullEventDefinitionRow);
    }
  };

  useEffect(() => {
    formik.setValues({
      targetPullCube: cubeDefinitionItem?.targetPullTotalCube ?? null,
      totalCalculationRule: cubeDefinitionItem?.pullTotalCalculationRule ?? CUBE_PULL_TOTALING_RULE.LAST_WEEK_IN_MONTH,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cubeDefinitionItem]);

  const isLoadingData = isLoadingCurrentCubeTimePeriodSet || isLoadingCubeOptions || isLoadingCurrentCubeLayers;

  return (
    <div>
      <Modal open={visible} onClose={handleOnCloseModal}>
        <Stack direction='column' sx={ModalContentStyle}>
          <Stack px={2} justifyContent='space-between' direction='row' alignItems='center'>
            <Typography variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
              Pull From Cube Weekly to Total
            </Typography>
            <IconButton onClick={handleOnCloseModal} component='label'>
              <HighlightOffRoundedIcon sx={closeIconStyle} />
            </IconButton>
          </Stack>
          <Divider sx={{ mb: 1 }} />

          <Stack px={2} gap={2}>
            {isLoadingData && !currentCubeLayers ? (
              <Stack height={450}>
                <ActivityIndicator />
              </Stack>
            ) : (
              <>
                <Stack>
                  <Typography variant='body2' fontWeight='bold' color='black' mb={1}>
                    Total calculation rule:
                  </Typography>
                  <ToggleButtonGroup
                    color='primary'
                    value={formik.values.totalCalculationRule}
                    exclusive
                    onChange={(e, value) => {
                      if (!value) return;
                      formik.setFieldValue('totalCalculationRule', value);
                    }}
                    sx={ToggleButtonGroupSX.buttonGroup}
                  >
                    {TOTAL_CALCULATION_OPTIONS.map((option) => (
                      <ToggleButton key={option.value} value={option.value} size='small'>
                        {option.label}
                      </ToggleButton>
                    ))}
                  </ToggleButtonGroup>
                </Stack>
                <Stack>
                  <Typography variant='body2' fontWeight='bold' color='black'></Typography>
                  <Stack gap={1} justifyContent='space-between' direction='row'>
                    <Stack>
                      <Typography variant='body2' fontWeight='bold' color='black' mb={1}>
                        Source Cube:
                      </Typography>
                      <AutoComplete
                        onChange={(event, value) => {
                          formik.setFieldValue('targetPullCube', value);
                        }}
                        clearIcon={null}
                        size='small'
                        value={formik.values.targetPullCube}
                        onBlur={() => {
                          formik.handleBlur('targetPullCube');
                          formik.setFieldTouched(`targetPullCube`, true);
                        }}
                        getOptionLabel={(option: AutoCompleteItem) => option.label}
                        options={pullCubeDropdownOptions?.data || []}
                        renderOption={renderOption}
                        sx={DropdownSX.autoComplete}
                        renderInput={(params) => (
                          <Stack direction='row' justifyContent='space-between' display='flex' alignItems='center'>
                            <TextField
                              {...params}
                              name='targetPullCube'
                              placeholder={isLoadingCubeOptions ? 'Loading...' : 'Please Select Cube'}
                            />
                          </Stack>
                        )}
                      />
                    </Stack>
                  </Stack>
                  <Divider sx={{ my: 1 }} />
                </Stack>
              </>
            )}
          </Stack>

          <Stack height={300} px={2}></Stack>
          <Stack px={2}>
            <Divider sx={{ mb: 1, mt: 2 }} />
            <Stack direction='row' justifyContent='flex-end' spacing={2} alignItems='center' sx={{ py: 1 }}>
              <Button variant='main-table-panel-border' onClick={handleOnCancel}>
                Cancel
              </Button>
              <ButtonLoading
                variant='main-table-panel'
                onClick={() => handleOnSave()}
                disabled={!validationSchema.isValidSync(formik.values)}
              >
                Save Parameter
              </ButtonLoading>
            </Stack>
          </Stack>
        </Stack>
      </Modal>
    </div>
  );
}
