import { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import qs from 'query-string';
import axios from 'libs/Axios';

import { AxiosDefaultErrorEntity } from 'types/api/Common/ErrorTypes';
import {
  DashboardAdminTotalUser,
  ListDashboardAdminUserActivity,
} from 'types/api/TenantMaster/MasterTable/AdminDashboardTypes';
import { PaginationAndSortingParams } from 'types/api/Common/PaginationTypes';
import { GeneralChartData } from 'types/api/Common/ChartDataTypes';

export const DASHBOARD_ADMIN_KEY = {
  TOTAL_USER: 'ADMIN_TOTAL_USER',
  ACTIVITY_USER: 'ADMIN_ACTIVITY_USER',
  CHART_ACTIVITY_USER: 'ADMIN_CHART_ACTIVITY_USER',
};

export function useAdminDashboardTotalUser(tenantId?: string) {
  return useQuery<AxiosResponse<DashboardAdminTotalUser>, AxiosDefaultErrorEntity>(
    [DASHBOARD_ADMIN_KEY.TOTAL_USER, tenantId],
    () => axios.get(`/api/v1/master/dashboard/total-user`).then((res) => res),
    {
      enabled: !!tenantId,
    }
  );
}

export function useAdminDashboardUserAcivity(params: PaginationAndSortingParams, enabled?: boolean) {
  return useQuery<AxiosResponse<ListDashboardAdminUserActivity>, AxiosDefaultErrorEntity>(
    [DASHBOARD_ADMIN_KEY.ACTIVITY_USER, { ...params }],
    () =>
      axios
        .get(`/api/v1/master/dashboard/user-activity?${qs.stringify({ ...params, page: params.page - 1 })}`)
        .then((res) => res),
    {
      enabled,
    }
  );
}

export function useAdmiDashboardAcitivityChart(tenantId?: string) {
  return useQuery<AxiosResponse<GeneralChartData[]>, AxiosDefaultErrorEntity>(
    [DASHBOARD_ADMIN_KEY.CHART_ACTIVITY_USER, tenantId],
    () => axios.get(`/api/v1/master/dashboard/user-activity/chart`).then((res) => res),
    {
      enabled: !!tenantId,
    }
  );
}
