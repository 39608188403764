import { AutoCompleteItem } from 'types/api/Common/AutoCompleteTypes';

export const SUBJECT_TENANT_MASTER_ID = {
  AGREEMENT_TYPE: '4028819184b7b43a0184b7b53eda0015',
  ROLE_TYPE: '4028819184b7b43a0184b7b57c0d001b',
  FIELD_STATUS: '4028819c84e0d4a30184e0d4ccb70006',
  DEMO_STATUS: '4028819c84e0d4a30184e0d4b77d0003',
  CODE_STATUS: '4028819c84e0d4a30184e0d4a3490000',
};

export enum SUBJECT_PROCEDURE_ALLOW_ACTIONS {
  INSERT_AND_UPDATE = 'INSERT_AND_UPDATE',
  INSERT_ONLY = 'INSERT_ONLY',
  UPDATE_ONLY = 'UPDATE_ONLY',
}

export const SUBJECT_PROCEDURE_ALLOW_ACTIONS_DROPDOWN: AutoCompleteItem[] = [
  {
    label: 'Insert and Update',
    value: SUBJECT_PROCEDURE_ALLOW_ACTIONS.INSERT_AND_UPDATE,
  },
  {
    label: 'Insert Only',
    value: SUBJECT_PROCEDURE_ALLOW_ACTIONS.INSERT_ONLY,
  },
  {
    label: 'Update Only',
    value: SUBJECT_PROCEDURE_ALLOW_ACTIONS.UPDATE_ONLY,
  },
];

export const getSubjectProcedureAllowActionsLabel = (value: SUBJECT_PROCEDURE_ALLOW_ACTIONS) => {
  switch (value) {
    case SUBJECT_PROCEDURE_ALLOW_ACTIONS.INSERT_AND_UPDATE:
      return 'Insert and Update';
    case SUBJECT_PROCEDURE_ALLOW_ACTIONS.INSERT_ONLY:
      return 'Insert Only';
    case SUBJECT_PROCEDURE_ALLOW_ACTIONS.UPDATE_ONLY:
      return 'Update Only';
    default:
      return '';
  }
};

export const DISABLED_EDIT_DELETE_SUBJECTS = Object.values(SUBJECT_TENANT_MASTER_ID);
