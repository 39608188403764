import EventEmitter from 'eventemitter3';

const eventEmitter = new EventEmitter();

export default eventEmitter;

export const EVENT_EMITTER_KEYS = {
  TRIGGER_SHOW_MODAL_ATTACHMENT: 'TRIGGER_SHOW_MODAL_ATTACHMENT',
  TIRGGER_SHOW_MODAL_ATTACHMENT_INSERT: 'TIRGGER_SHOW_MODAL_ATTACHMENT_INSERT',
};
