import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DashboardType } from './authReducer';

export interface UIState {
  isSidebarOpen: boolean;
  showModalSelectLanguage: boolean;
  modalKey: string;
  selectedDashboardView: DashboardType | '';
  isTenantNavigationOpen: boolean;
}

const initialState: UIState = {
  isSidebarOpen: true,
  showModalSelectLanguage: false,
  modalKey: new Date().getTime().toString(),
  selectedDashboardView: '',
  isTenantNavigationOpen: false,
};

export const uiSlice = createSlice({
  name: '@UI',
  initialState,
  reducers: {
    setSidebarOpen: (state, action: PayloadAction<boolean>) => {
      state.isSidebarOpen = action.payload;
    },
    toggleModalSelectLanguage: (state) => {
      state.showModalSelectLanguage = !state.showModalSelectLanguage;
      state.modalKey = new Date().getTime().toString();
    },
    setSelectedDashboardView: (state, action: PayloadAction<DashboardType>) => {
      state.selectedDashboardView = action.payload;
    },
    toggleTenantNavigation: (state) => {
      state.isTenantNavigationOpen = !state.isTenantNavigationOpen;
    },
    setTenantNavigation: (state, action: PayloadAction<boolean>) => {
      state.isTenantNavigationOpen = action.payload;
    },
  },
});

export const {
  setSidebarOpen,
  toggleModalSelectLanguage,
  setSelectedDashboardView,
  toggleTenantNavigation,
  setTenantNavigation,
} = uiSlice.actions;

export default uiSlice.reducer;
