import React from 'react';

import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';
import Typograhpy from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useIsMutating } from 'react-query';
import { useTranslation } from 'react-i18next';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { GridRowModesModel, GridRowsProp, GridRowModes } from '@mui/x-data-grid-pro';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Divider from '@mui/material/Divider';
import { ButtonExport, FileType } from 'components/ButtonComponent/ButtonExport';
import { ButtonDownloadTemplate } from 'components/ButtonComponent/ButtonDownloadTemplate';
import { ButtonImport } from 'components/ButtonComponent/ButtonImport';
import { GridToolbarDensitySelector } from 'components/ButtonComponent/ButtonDensity';
import { GridToolbarFilterButton } from 'components/ButtonComponent/ButtonFilter';

// Custom Icon
import { ReactComponent as CustomIconTrash } from 'assets/icons/icon-trash.svg';
import { getSubjectChipColor } from 'utils/SubjectUtils';
import { TABLE_DEFINITION_TYPE } from '..';
import { TableDefinitionOptionsMenu } from './TableDefinitionOptionsMenu';

interface SettingPanelConfigProps {
  insertButtonText?: string;
  insertInitialObject?: { [key: string]: string | number | boolean };
  fieldFocusOnInsert: string;
  deleteButtonText?: string;
  isInserting: boolean;
}

export interface OptionItem {
  value: string | number;
  label: string;
}

const TabStyle = {
  textTransform: 'none',
  '&.Mui-selected': {
    color: '#42BB93',
  },
};
const TabIndicatoryStyle = {
  style: {
    backgroundColor: '#42BB93',
  },
};

const chipStyle = {
  fontSize: '9px',
  px: 0,
};

const autoCompleteStyle = {
  width: 280,
  '& .MuiOutlinedInput-root': {
    padding: '1px 2px',
  },
  '& .MuiAutocomplete-popper': {
    backgroundColor: 'red !imoprtant',
    fontSize: '10px',
  },
};

export const EVENT_TABLE_DEFINITION_LOCK_STATUS: OptionItem[] = [
  { value: '0', label: 'Unlocked' },
  { value: '1', label: 'Locked' },
];

interface SettingPanelProps {
  onDeleteData?: () => void;
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (newModel: (oldModel: GridRowModesModel) => GridRowModesModel) => void;
  setFilterButtonElement: () => void;
  config: SettingPanelConfigProps;
  isInserting: boolean;
  options: OptionItem[];
  initialOption?: OptionItem | undefined;
  optionValue: OptionItem;
  optionLockStatusValue: OptionItem;
  layoutTableOptionValue: OptionItem;
  onTabPanelChange: (event: React.SyntheticEvent, newValue: string) => void;
  selectedTabPanel: string;
  isSubjectView: boolean;
  disabled: boolean;
  disabledDeleteButton: boolean;
  onLockStatusChange: (value: OptionItem) => void;
  onOptionChange: (value: OptionItem) => void;
  onOptionMenuClick: (optionId: string) => void;
  onExportClick: (selectedFileType: FileType) => void;
  onImportClick: (selectedFileType: FileType) => void;
  onDownloadTemplateClick: () => void;
  type: TABLE_DEFINITION_TYPE;
}

export function SettingPanel(props: SettingPanelProps) {
  const isAnyMutating = useIsMutating();
  const { t } = useTranslation();
  const {
    setFilterButtonElement,
    onDeleteData,
    setRows,
    setRowModesModel,
    config,
    onOptionChange,
    isSubjectView,
    options,
    disabled,
    selectedTabPanel,
    optionValue,
    disabledDeleteButton,
    isInserting,
    onTabPanelChange = () => {},
    onDownloadTemplateClick,
    onExportClick,
    onImportClick,
    onOptionMenuClick,
    onLockStatusChange,
    optionLockStatusValue,
    type,
  } = props;

  const {
    fieldFocusOnInsert = '',
    insertButtonText = t('PAGE.TABLE_DEFINITION.BUTTON_ADD_NEW'),
    insertInitialObject = {},
  } = config;

  const handleCancelInsert = () => {
    setRowModesModel((oldModel) => {
      const newModel = { ...oldModel };

      Object.keys(newModel).forEach((key) => {
        if (newModel[key].mode === GridRowModes.Edit) {
          delete newModel[key];
        }
      });
      return newModel;
    });
    setRows((oldRows) => {
      return oldRows.filter((row) => !row.isNew);
    });
  };
  const handleAddData = () => {
    const id = new Date().getTime();
    setRows((oldRows) => [{ id, ...insertInitialObject }, ...oldRows]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: fieldFocusOnInsert },
    }));
  };

  const handleDropdownChange = (event: React.SyntheticEvent<Element, Event>, newValue: OptionItem) => {
    if (newValue) {
      onOptionChange(newValue);
    }
  };

  const handleOnEventLockStatusChange = (event: React.SyntheticEvent<Element, Event>, newValue: OptionItem) => {
    if (newValue) {
      onLockStatusChange(newValue);
    }
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    onTabPanelChange(event, newValue);
  };

  return (
    <Stack direction='column' sx={{ backgroundColor: '#fff', py: 1.8, px: 2 }}>
      <Stack direction='row' spacing={4} alignItems='center' sx={{ mb: 2 }} justifyContent='space-between'>
        <Stack direction='row' spacing={1} alignItems='center'>
          <Typograhpy variant='body2' color='textSecondary' sx={{ marginRight: 2 }}>
            {t('SIDEBAR_MENU.SUBJECT')}
          </Typograhpy>
          <Autocomplete
            // @ts-ignore
            onChange={handleDropdownChange}
            clearIcon={null}
            value={optionValue}
            disabled={!!isAnyMutating}
            getOptionLabel={(option) => option.label}
            options={options}
            renderOption={(props, option) => (
              <Box component='li' sx={{ '& > span': { fontSize: '14px', mr: 1, flexShrink: 0 } }} {...props}>
                <span>{option.label}</span>
                {/*   @ts-ignore */}
                {option?.type && (
                  <Chip
                    // @ts-ignore
                    label={option.type}
                    // @ts-ignore
                    color={getSubjectChipColor(option?.type)}
                    variant='outlined'
                    size='small'
                    sx={chipStyle}
                  />
                )}
              </Box>
            )}
            sx={autoCompleteStyle}
            renderInput={(params) => (
              <Stack direction='row' justifyContent='space-between' display='flex' alignItems='center'>
                <TextField
                  {...params}
                  sx={{
                    '& .MuiOutlinedInput': {
                      padding: '1px 2px',
                    },
                  }}
                />
                {/* @ts-ignore */}
                {optionValue?.type && (
                  <Chip
                    // @ts-ignore
                    color={getSubjectChipColor(optionValue?.type)}
                    variant='outlined'
                    size='small'
                    // @ts-ignore
                    label={optionValue?.type}
                    sx={{
                      ...chipStyle,
                      marginLeft: 2,
                    }}
                  />
                )}
              </Stack>
            )}
          />
        </Stack>
        {type === TABLE_DEFINITION_TYPE.EVENT_DEFINITION && (
          <Stack direction='row' spacing={1} alignItems='center'>
            <Typograhpy variant='body2' color='textSecondary' sx={{ marginRight: 2 }}>
              Lock Status
            </Typograhpy>
            <Autocomplete
              // @ts-ignore
              onChange={handleOnEventLockStatusChange}
              clearIcon={null}
              value={optionLockStatusValue}
              disabled={disabled || !!isAnyMutating || options.length === 0}
              getOptionLabel={(option) => option.label}
              options={EVENT_TABLE_DEFINITION_LOCK_STATUS}
              sx={{
                ...autoCompleteStyle,
                width: 180,
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </Stack>
        )}
        <TableDefinitionOptionsMenu disabled={disabled || options.length === 0} onOptionClick={onOptionMenuClick} />
      </Stack>

      <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={1}>
        <Stack direction='row' spacing={1} alignItems='center'>
          <Box>
            <Tabs TabIndicatorProps={TabIndicatoryStyle} value={selectedTabPanel} onChange={handleChange}>
              {!isSubjectView && (
                <Tab
                  disabled={disabled}
                  sx={TabStyle}
                  value='ASSOCIATED_SUBJECT'
                  label={t('COMMON.LABEL.ASSOCIATE_SUBJECT')}
                />
              )}

              <Tab
                disabled={disabled}
                sx={TabStyle}
                value='ASSOCIATED_ATTRIBUTE'
                label={!isSubjectView ? t('COMMON.LABEL.ASSOCIATE_ATTRIBUTE') : 'View Attribute'}
              />
            </Tabs>
          </Box>
        </Stack>

        <Stack direction='row' spacing={1}>
          {!isInserting ? (
            <Button
              disabled={disabled || options.length === 0 || !!isAnyMutating}
              variant='main-table-panel'
              startIcon={<AddCircleOutlineOutlinedIcon />}
              onClick={handleAddData}
              sx={{ mr: 4 }}
            >
              {insertButtonText}
            </Button>
          ) : (
            <Button
              sx={{
                width: 160,
                mr: 4,
              }}
              variant='secondary-table-panel'
              onClick={handleCancelInsert}
            >
              Cancel
            </Button>
          )}

          <GridToolbarFilterButton
            ref={setFilterButtonElement}
            componentsProps={{
              button: {
                disabled: disabled || options.length === 0,
                variant: 'main-table-panel-borderless',
              },
            }}
          />
          <GridToolbarDensitySelector variant='main-table-panel-borderless' disabled={options.length === 0} />
          <Tooltip title='Delete'>
            <IconButton disabled={disabled || disabledDeleteButton} onClick={onDeleteData}>
              <SvgIcon
                fontSize='small'
                sx={{ fill: disabled || disabledDeleteButton ? '#00000042' : '#42BB93' }}
                inheritViewBox
                component={CustomIconTrash}
              />
            </IconButton>
          </Tooltip>
          <Divider orientation='vertical' variant='middle' flexItem sx={{ px: 0.3 }} />
          <ButtonExport disabled={disabled} onClick={onExportClick} />
          <ButtonImport disabled={disabled} onClick={onImportClick} />
          <ButtonDownloadTemplate disabled={disabled} onClick={onDownloadTemplateClick} />
        </Stack>
      </Stack>
    </Stack>
  );
}
