import React from 'react';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';
import Typograhpy from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { GridRowModesModel, GridRowsProp, GridRowModes } from '@mui/x-data-grid-pro';
import { GridToolbarDensitySelector } from 'components/ButtonComponent/ButtonDensity';
import { GridToolbarFilterButton } from 'components/ButtonComponent/ButtonFilter';
import { normalizeDataGridRowId } from 'utils/Object';

// Custom Icon
import { ReactComponent as CustomIconTrash } from 'assets/icons/icon-trash.svg';

interface SettingPanelConfigProps {
  insertButtonText?: string;
  insertInitialObject?: { [key: string]: string | number | boolean };
  fieldFocusOnInsert: string;
  deleteButtonText?: string;
}

interface OptionItem {
  value: string;
  label: string;
}

interface SettingPanelProps {
  onDeleteData?: () => void;
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (newModel: (oldModel: GridRowModesModel) => GridRowModesModel) => void;
  setFilterButtonElement: () => void;
  config: SettingPanelConfigProps;
  options: OptionItem[];
  initialOption?: OptionItem | undefined;
  optionValue: OptionItem;
  disabled: boolean;
  buttonComponent?: React.ReactNode;
  isEditing?: boolean;
  onOptionChange: (value: OptionItem) => void;
  disabledDeleteButton?: boolean;
  onAddButtonClick?: () => void;
}

const buttonActionStyle = {
  padding: 1.4,
};

export function SettingPanel(props: SettingPanelProps) {
  const {
    setFilterButtonElement,
    onDeleteData,
    setRows,
    setRowModesModel,
    config,
    onOptionChange,
    options,
    optionValue,
    disabled,
    disabledDeleteButton,
    buttonComponent,
    isEditing,
    onAddButtonClick = null,
  } = props;

  const { fieldFocusOnInsert = '', insertInitialObject = {}, insertButtonText } = config;

  const handleAddData = () => {
    if (onAddButtonClick) {
      onAddButtonClick();
      return;
    }
    const id = new Date().getTime();
    setRows((oldRows) => [{ id, ...insertInitialObject }, ...oldRows]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: fieldFocusOnInsert },
    }));
  };

  const handleDropdownChange = (event: React.SyntheticEvent<Element, Event>, newValue: OptionItem) => {
    if (newValue) onOptionChange(newValue);
  };

  const handleCancelInsert = () => {
    setRows((oldRows) => oldRows.filter((row) => normalizeDataGridRowId(row.id) !== null));
    // get id of the row has edit mode
    setRowModesModel((oldModel) => {
      const id = Object.keys(oldModel).find((key) => oldModel[key].mode === GridRowModes.Edit);
      if (id) {
        const newModel = { ...oldModel };
        delete newModel[id];
        return newModel;
      }
      return oldModel;
    });
  };

  return (
    <Stack direction='column' spacing={0} sx={{ backgroundColor: '#fff', py: 1.8, px: 1 }}>
      <Stack direction='row' spacing={1} alignItems='center'>
        <Typograhpy variant='body2' color='textSecondary' sx={{ marginRight: 2 }}>
          Subject
        </Typograhpy>
        <Autocomplete
          // @ts-ignore
          onChange={handleDropdownChange}
          disabled={disabled}
          value={optionValue}
          getOptionLabel={(option) => option.label}
          options={options}
          renderOption={(props, option) => (
            <Box component='li' sx={{ '& > span': { fontSize: '14px', mr: 2, flexShrink: 0 } }} {...props}>
              <span>{option.label}</span>
            </Box>
          )}
          sx={{
            width: 200,
            '& .MuiOutlinedInput-root': {
              padding: '1px 2px',
            },
            '& .MuiAutocomplete-popper': {
              backgroundColor: 'red !imoprtant',
              fontSize: '10px',
            },
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{
                '& .MuiOutlinedInput': {
                  padding: '1px 2px',
                },
              }}
            />
          )}
        />
      </Stack>

      <Stack direction='row' sx={{ mt: 2 }} justifyContent='space-between' spacing={0}>
        <Stack>
          {buttonComponent}

          {!isEditing && !buttonComponent && (
            <Button
              disabled={disabled || options.length === 0}
              variant='main-table-panel'
              startIcon={<AddCircleOutlineOutlinedIcon />}
              onClick={handleAddData}
            >
              {insertButtonText || 'Add'}
            </Button>
          )}

          {isEditing && (
            <Button
              sx={{
                width: 170,
              }}
              variant='secondary-table-panel'
              disabled={disabled}
              onClick={handleCancelInsert}
            >
              Cancel
            </Button>
          )}
        </Stack>

        <Stack direction='row'>
          <GridToolbarFilterButton
            ref={setFilterButtonElement}
            componentsProps={{
              button: {
                disabled: disabled || options.length === 0,
                sx: buttonActionStyle,
                variant: 'main-table-panel-borderless',
              },
            }}
          />
          <GridToolbarDensitySelector
            disabled={options.length === 0}
            sx={buttonActionStyle}
            variant='main-table-panel-borderless'
          />
          <Tooltip title='Delete'>
            <IconButton disabled={disabled || disabledDeleteButton} sx={buttonActionStyle} onClick={onDeleteData}>
              <SvgIcon
                fontSize='small'
                sx={{ fill: disabled || disabledDeleteButton ? '#00000042' : '#42BB93' }}
                inheritViewBox
                component={CustomIconTrash}
              />
            </IconButton>
          </Tooltip>
        </Stack>
      </Stack>
    </Stack>
  );
}
