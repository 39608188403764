import { CustomRuleCondition } from 'types/api/Tenant/AROKMS/DisplayTableTypes';
import { CUSTOM_RULE_OPERATOR_VALUE } from './CustomRuleDetail';
const _evaluateCustomRuleCondition = Symbol('_evaluateCustomRuleCondition');

export class CustomRuleImplementation {
  [_evaluateCustomRuleCondition](rules: CustomRuleCondition[], formValues: { [key: string]: any }) {
    let generatedExpression = '';
    let stack: string[] = [];
    let tempGroup: string[] = [];

    rules.forEach((rule, index) => {
      const { field, operator, value } = rule;
      const fieldValue = formValues[field];
      const comparisonValue = typeof fieldValue === 'object' ? fieldValue?.value : fieldValue;

      let comparison = '';
      switch (operator) {
        case CUSTOM_RULE_OPERATOR_VALUE.EQUALS:
          comparison = `${JSON.stringify(comparisonValue)} === ${JSON.stringify(value)}`;
          break;
        case CUSTOM_RULE_OPERATOR_VALUE.NOT_EQUALS:
          comparison = `${JSON.stringify(comparisonValue)} !== ${JSON.stringify(value)}`;
          break;
        case CUSTOM_RULE_OPERATOR_VALUE.NOT_EMPTY:
          comparison = `${JSON.stringify(comparisonValue)} !== null && ${JSON.stringify(
            comparisonValue
          )} !== undefined && ${JSON.stringify(comparisonValue)} !== ""`;
          break;
        default:
          console.log(`Unsupported operator: ${operator}`);
      }

      tempGroup.push(comparison);

      if (index < rules.length - 1) {
        const nextOperatorType = rules[index + 1].operatorType;

        if (nextOperatorType === 'OR') {
          if (tempGroup.length > 1) {
            stack.push(`(${tempGroup.join(' && ')})`);
          } else {
            stack.push(tempGroup[0]);
          }
          tempGroup = [];
        }
      } else {
        if (tempGroup.length > 1) {
          stack.push(`(${tempGroup.join(' && ')})`);
        } else {
          stack.push(tempGroup[0]);
        }
      }
    });

    generatedExpression = stack.join(' || ');

    // eslint-disable-next-line no-eval
    return eval(generatedExpression);
  }

  hideField(rules: CustomRuleCondition[], formValues: { [key: string]: any }) {
    return rules.length > 0 && this[_evaluateCustomRuleCondition](rules, formValues);
  }
  showField(rules: CustomRuleCondition[], formValues: { [key: string]: any }) {
    return rules.length > 0 && this[_evaluateCustomRuleCondition](rules, formValues);
  }
  renameField(rules: CustomRuleCondition[], formValues: { [key: string]: any }) {
    return rules.length > 0 && this[_evaluateCustomRuleCondition](rules, formValues);
  }
}

export const customRuleImplementation = new CustomRuleImplementation();
