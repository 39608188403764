const MANDARIN_DICTIONARY = {
  translation: {
    PAGE: {
      LOGIN: {
        WELCOME_TITLE: '欢迎来到 aroTarget',
        WELCOME_DESCRIPTION:
          '使用 Ai 自动为您量身定制的业务预测软件。与您的会计系统集成的财务预测、资源规划和供应链优化',
        WELCOME_COPYRIGHT: 'ARO 目标 版权所有。',
        FORM_TITLE: '登入',
        FORM_SUB_TITLE: '填写以下字段以登录您的帐户。',
        FORM_BUTTON_LOGIN: '登录',
        FORM_BUTTON_LOGIN_WITH_MICRSOFOT: 'MICROSOFT 365',
        FORM_LOGIN_OPTIONS: '或使用身份验证',
        FORM_LOGIN_SESSION_CHECKBOX: '记得我',
        FORM_LOGIN_FORGOT_PASSWORD: '忘记密码?',
        FORM_LOGIN_INPUT_EMAIL_LABEL: '电子邮件地址',
        FORM_LOGIN_INPUT_EMAIL_PLACEHOLDER: '输入你的电子邮箱地址',
        FORM_LOGIN_INPUT_PASSWORD_LABEL: '密码',
        FORM_LOGIN_INPUT_PASSWORD_PLACEHOLDER: '输入您的密码',
      },
      SUBJECT: {
        TITLE: '主题列表',
        BUTTON_ADD_NEW: '添加新主题',
      },
      ATTRIBUTE: {
        TITLE: '属性列表',
        BUTTON_ADD_NEW: '添加新属性',
      },
      MEMBER_LIST: {
        TITLE: '会员名单',
        BUTTON_ADD_NEW: '添加会员',
      },
      TABLE_DEFINITION: {
        TITLE: '表定义',
        BUTTON_ADD_NEW: '添加新字段',
        RULE_TYPE: '规则类型',
        DATA_TYPE: '数据类型',
        DISPLAY_NAME: '显示名称',
        PARAMETER: '范围',
        FIELD_ATTRIBUTE: '字段属性',
        DATA_TYPE_ATTRIBUTE: '数据类型属性',
        MODAL_PARAMETER: {
          TITLE: '参数为',
          BUTTON_SAVE: '保存参数',
        },
      },
      ERROR_NOT_FOUND: {
        DESCRIPTION: '404 页面未找到',
        BUTTON_ACTION: '返回主页',
      },
    },
    SIDEBAR_MENU: {
      DASHBOARD: '仪表板',
      DATA_BUILDER: '数据生成器',
      DATA_INPUT: '数据输入',
      SET_UP: '设置',
      UPDATE: '更新',
      REPORTS: '报告',
      SUBJECT: '主题',
      ATTRIBUTE: '属性',
      MEMBER_LIST: '会员名单',
      TABLE_DEFINITION: '表定义',
      PROJECTS: '项目',
    },
    HEADER_MENU: {
      OPEN_SETTINGS: '打开设置',
      MULTILANGUAGE: '多语言',
      SETTING: '环境',
      LOGOUT: '登出',
    },
    COMMON: {
      MODAL_IMPORT: {
        TITLE: '导入数据',
        BUTTON_UPLOAD: '上传',
        UPLOAD_AREA_EXCEL: '或将文件 .xlsx 放在这里',
        UPLOAD_AREA_CSV: '或将文件 .csv 放在这里',
        INSTRUCTION_EXCEL: '请选择您要导入的EXCEL文件',
        INSTRUCTION_CSV: '请选择您要导入的CSV文件',
        START_IMPORT: '开始导入',
      },
      MODAL_DELETE: {
        TITLE: '删除确认',
        DESCRIPTION: '您即将删除此记录。你确定吗？',
        BUTTON_DELETE: '删除',
      },
      MODAL_SELECT_LANGUAGE: {
        TITLE: '语',
        DESCRIPTION: '请选择您要使用的语言',
      },
      DATATABLE: {
        EMPTY_ROW: '没有数据',
      },
      LABEL: {
        ASSOCIATE_SUBJECT: '关联主题',
        ASSOCIATE_ATTRIBUTE: '关联属性',
      },
      BUTTON: {
        EXPORT: '导出数据',
        EXPORT_CSV_FILE: '导出为 CSV 文件',
        EXPORT_EXCEL_FILE: '导出为 Excel 文件',
        IMPORT: '导入数据',
        IMPORT_CSV_FILE: '从 CSV 文件导入',
        IMPORT_EXCEL_FILE: '从 Excel 文件导入e',
        DOWNLOAD_TEMPLATE: '下载导入模板',
        DOWNLOAD_TEMPLATE_CSV: '下载 CSV 模板',
        DOWNLOAD_TEMPLATE_EXCEL: '下载 Excel 模板',
        CANCEL: '取消',
        CREATE: '创造',
        APPLY: '申请',
      },
    },
    FORM_ERROR: {
      LOGIN: {
        EMAIL_INVALID: '电子邮件必须是有效的电子邮件',
        EMAIL_REQUIRED: '电子邮件是必需的',
        PASSWORD_REQUIRED: '密码是必需的',
      },
    },
  },
};

export default MANDARIN_DICTIONARY;
