import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';
import Button from '@mui/material/Button';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { GridRowModesModel, GridRowsProp, GridRowModes } from '@mui/x-data-grid-pro';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import { GridToolbarDensitySelector } from 'components/ButtonComponent/ButtonDensity';
import { GridToolbarFilterButton } from 'components/ButtonComponent/ButtonFilter';

// Custom Icon
import { ReactComponent as CustomIconTrash } from 'assets/icons/icon-trash.svg';

interface CubeDefinitionAttributeTablePanelConfigProps {
  insertButtonText?: string;
  insertInitialObject?: { [key: string]: string | number | boolean };
  fieldFocusOnInsert: string;
  deleteButtonText?: string;
  isInserting: boolean;
}

export interface OptionItem {
  value: string | number;
  label: string;
}

interface CubeDefinitionAttributeTablePanelProps {
  setFilterButtonElement: () => void;
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (newModel: (oldModel: GridRowModesModel) => GridRowModesModel) => void;
  config: CubeDefinitionAttributeTablePanelConfigProps;
  disabled: boolean;
  disabledDeleteButton: boolean;
  isInserting: boolean;
  onDeleteData?: () => void;
}

export function CubeDefinitionAttributeTablePanel(props: CubeDefinitionAttributeTablePanelProps) {
  const {
    setFilterButtonElement,
    setRows,
    setRowModesModel,
    config,
    disabled,
    disabledDeleteButton,
    isInserting,
    onDeleteData,
  } = props;

  const { fieldFocusOnInsert = '', insertButtonText = 'Add New Field', insertInitialObject = {} } = config;

  const handleCancelInsert = () => {
    setRowModesModel((oldModel) => {
      const newModel = { ...oldModel };

      Object.keys(newModel).forEach((key) => {
        if (newModel[key].mode === GridRowModes.Edit) {
          delete newModel[key];
        }
      });
      return newModel;
    });
    setRows((oldRows) => {
      return oldRows.filter((row) => !row.isNew);
    });
  };
  const handleAddData = () => {
    const id = new Date().getTime();
    setRows((oldRows) => [{ id, ...insertInitialObject }, ...oldRows]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: fieldFocusOnInsert },
    }));
  };

  return (
    <Stack direction='column' sx={{ backgroundColor: '#fff', py: 1.8, px: 2 }}>
      <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={1}>
        {!isInserting ? (
          <Button
            disabled={disabled}
            variant='main-table-panel'
            startIcon={<AddCircleOutlineOutlinedIcon />}
            onClick={handleAddData}
            sx={{ mr: 4 }}
          >
            {insertButtonText}
          </Button>
        ) : (
          <Button
            sx={{
              width: 160,
              mr: 4,
            }}
            variant='secondary-table-panel'
            onClick={handleCancelInsert}
          >
            Cancel
          </Button>
        )}

        <Stack direction='row'>
          <GridToolbarFilterButton
            ref={setFilterButtonElement}
            componentsProps={{
              button: {
                disabled: disabled,
                variant: 'main-table-panel-borderless',
              },
            }}
          />
          <GridToolbarDensitySelector variant='main-table-panel-borderless' disabled={disabled} />
          <Tooltip title='Delete'>
            <IconButton disabled={disabled || disabledDeleteButton} onClick={onDeleteData}>
              <SvgIcon
                fontSize='small'
                sx={{ fill: disabled || disabledDeleteButton ? '#00000042' : '#42BB93' }}
                inheritViewBox
                component={CustomIconTrash}
              />
            </IconButton>
          </Tooltip>
        </Stack>
      </Stack>
    </Stack>
  );
}
