import * as React from 'react';
import {
  DataGridPro,
  GridColDef,
  GridRenderCellParams,
  GridRowModel,
  GridRowOrderChangeParams,
} from '@mui/x-data-grid-pro';
import _ from 'lodash';
import Typography from '@mui/material/Typography';
import { useMemo } from 'react';
import { Divider, Modal } from '@mui/material';
import Stack from '@mui/material/Stack';
import ButtonLoading from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import { StandardViewAttributeItem } from 'types/api/SystemTenant/AROView/StandardViewDefinitionTypes';
import IconButton from '@mui/material/IconButton';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
const ModalContentStyle = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'background.paper',
  minHeight: 350,
  borderRadius: 1,
  px: 3,
  py: 2,
};

const closeIconStyle = { color: '#98A2AE', cursor: 'pointer' };
export interface ModalConfigAttributeViewOrderProps {
  open?: boolean;
  attributes: StandardViewAttributeItem[];
  onClose?: () => void;
  onSave?: (data: StandardViewAttributeItem[]) => void;
}

function updateRowPosition(oldIndex: number, newIndex: number, rows: Array<GridRowModel<StandardViewAttributeItem>>) {
  const rowsClone = [...rows];
  const [row] = rowsClone.splice(oldIndex, 1);
  rowsClone.splice(newIndex, 0, row);

  for (let i = 0; i < rowsClone.length; i++) {
    rowsClone[i].columnIndexOrder = i + 1;
  }

  return rowsClone;
}

export function ModalConfigAttributeViewOrder(props: ModalConfigAttributeViewOrderProps) {
  const { open = false, onClose, attributes, onSave } = props;

  const [rows, setRows] = React.useState<StandardViewAttributeItem[] | []>([]);
  const [initialData, setInitialData] = React.useState<StandardViewAttributeItem[] | []>([]);
  const [tableKey, setTableKey] = React.useState<number>(0);

  const handleCheckboxChange = React.useCallback(
    (checked: boolean, id: any) => {
      const newRows = rows.map((row) => {
        if (row.id === id) {
          return { ...row, columnEditable: checked };
        }
        return row;
      });

      setRows(newRows);
    },
    [rows]
  );

  const columns = useMemo<GridColDef[]>(() => {
    return [
      {
        field: 'columnIndexOrder',
        headerName: 'Order',
        width: 80,
        editable: true,
      },
      {
        field: 'attribute',
        headerName: 'Attribute',
        width: 200,
        editable: false,
        filterable: false,
        valueGetter: (params) => {
          return params.row?.attribute?.label;
        },
        renderCell: (params: GridRenderCellParams) => {
          return (
            <Stack direction='row' gap={1}>
              <Typography variant='body2' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
                {params.value}
              </Typography>
            </Stack>
          );
        },
      },
      {
        field: 'ruleType',
        headerName: 'Rule',
        width: 200,
        editable: false,
        filterable: false,
        valueGetter: (params) => {
          return params.row?.ruleType?.label;
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleCheckboxChange]);

  const handleRowOrderChange = async (params: GridRowOrderChangeParams) => {
    const newRows = updateRowPosition(params.oldIndex, params.targetIndex, rows);
    setRows(newRows);
    setTableKey(tableKey + 1);
  };

  const handleOnResetOrder = () => {
    setRows(initialData);
    setTableKey(tableKey + 1);
  };
  const handleOnClose = () => {
    setTableKey(tableKey + 1);
    onClose?.();
  };

  const handleSaveData = () => {
    onSave?.(rows);
  };

  React.useEffect(() => {
    setRows(attributes);
    setInitialData(attributes);
  }, [attributes]);

  const getRowId = React.useCallback((row) => {
    return row.attribute.value;
  }, []);

  const isButtonResetEnable = React.useMemo(() => {
    return !_.isEqual(rows, initialData);
  }, [rows, initialData]);

  return (
    <Modal open={open} onClose={handleOnClose}>
      <>
        <Stack direction='column' sx={ModalContentStyle} justifyContent='space-between'>
          <Stack direction='column'>
            <Stack justifyContent='space-between' direction='row' alignItems='center' spacing={2}>
              <Typography variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
                View Attribute Order
              </Typography>
              <IconButton onClick={handleOnClose} component='label'>
                <HighlightOffRoundedIcon sx={closeIconStyle} />
              </IconButton>
            </Stack>
            <Divider sx={{ mb: 1 }} />
          </Stack>
          <Stack height={500}>
            <DataGridPro
              columns={columns}
              sx={{
                height: 500,
              }}
              rows={rows}
              getRowId={getRowId}
              disableSelectionOnClick
              rowReordering
              onRowOrderChange={handleRowOrderChange}
            />

            <Stack direction='row' justifyContent='space-between' alignItems='center'>
              <Stack />
              <Stack direction='row' justifyContent='flex-end' spacing={2} alignItems='center' sx={{ py: 1 }}>
                <Button variant='main-table-panel-border' onClick={handleOnResetOrder} disabled={!isButtonResetEnable}>
                  Reset Order
                </Button>
                <ButtonLoading variant='main-table-panel' disabled={rows.length === 0} onClick={handleSaveData}>
                  Save Order
                </ButtonLoading>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </>
    </Modal>
  );
}
