// @ts-nocheck
import { dayjs } from 'utils/DateUtils';
import { RULES_CONSTANT } from 'constant/RuleConstant';
import { AuthState } from 'store/reducer/authReducer';
import { DropdownItem } from 'types/api/SystemTenant/AROKMS/TableDefinitionTypes';
import { findObjectByLabelOrValue } from 'utils/Object';
import { ENV_CONFIG } from 'config/env';
import { MEMBER_LIST_SUGGESTION_ID_NEED_TO_BE_INSERTED } from './../components/DropdownAttributeBaseSubject';
type SerializedValue = string | number | { value: string | number } | DropdownItem;

export function serializeDataInputPayload(
  payload: Record<
    string,
    {
      ruleTypeCode: string;
      value: SerializedValue;
      dropdownOptions: SerializedValue[];
      readOnlyColumn: boolan;
      allowEmpty: boolan;
    }
  >,
  authReducer: AuthState
) {
  const result: Record<string, string | number> = {};

  for (const [key, { ruleTypeCode, value, dropdownOptions, readOnlyColumn, allowEmpty }] of Object.entries(payload)) {
    let serializedValue: string | number | undefined;

    if (readOnlyColumn || (!value && allowEmpty)) {
      // skip if column is read only, do not execute the switch case
      continue;
    }

    switch (ruleTypeCode) {
      case RULES_CONSTANT.DATE.RULE_DATE_INPUT:
      case RULES_CONSTANT.GENERATED_SYSTEM.RULE_SYSTEM_GENERATED_EVENT_TIMESTAMP:
        serializedValue =
          value && dayjs.isDayjs(value) ? value.format(ENV_CONFIG.config.SERVER_DATE_FORMAT) : value ? value : null;
        break;

      case RULES_CONSTANT.DATETIME.RULE_DATE_TIME_INPUT:
        serializedValue =
          value && dayjs.isDayjs(value)
            ? value.format(ENV_CONFIG.config.SERVER_DATE_TIME_FORMAT)
            : value
            ? value
            : null;
        break;

      case RULES_CONSTANT.DATETIME.RULE_DATE_TIME:
        // serializedValue = value ? dayjs(value, 'MM/DD/YYYY hh:mm A').format('YYYY-MM-DDTHH:mm:ss[Z]') : null;
        serializedValue =
          value && dayjs.isDayjs(value)
            ? value.format(ENV_CONFIG.config.SERVER_DATE_TIME_FORMAT)
            : value
            ? value
            : null;
        break;

      case RULES_CONSTANT.DATE.RULE_DATE_INPUT_NUM_TYPE:
        serializedValue =
          value && dayjs.isDayjs(value) ? value.format(ENV_CONFIG.config.SERVER_DATE_FORMAT) : value ? value : null;
        break;

      case RULES_CONSTANT.INTEGER.RULE_INTEGER:
      case RULES_CONSTANT.INTEGER.RULE_INTEGER_MULTIPLICATION:
      case RULES_CONSTANT.INTEGER.RULE_INTEGER_DIVISION:
      case RULES_CONSTANT.INTEGER.RULE_INTEGER_ADDITION:
      case RULES_CONSTANT.INTEGER.RULE_INTEGER_SUBSTRACTION:
      case RULES_CONSTANT.INTEGER.RULE_INTEGER_RANGE:
      case RULES_CONSTANT.NUMBER.RULE_DECIMAL_INPUT:
      case RULES_CONSTANT.NUMBER.RULE_DECIMAL_ADDITION:
      case RULES_CONSTANT.NUMBER.RULE_DECIMAL_DIVISION:
      case RULES_CONSTANT.NUMBER.RULE_DECIMAL_MULTIPLICATION:
      case RULES_CONSTANT.NUMBER.RULE_DECIMAL_SUBTRACTION:
      case RULES_CONSTANT.CURRENCY.RULE_CURRENCY_RANGE:
      case RULES_CONSTANT.CURRENCY.RULE_CURRENCY_ADDITION:
      case RULES_CONSTANT.CURRENCY.RULE_CURRENCY_DIVISION:
      case RULES_CONSTANT.CURRENCY.RULE_CURRENCY_MULTIPLICATION:
      case RULES_CONSTANT.CURRENCY.RULE_CURRENCY_SUBSTRACTION:
      case RULES_CONSTANT.CURRENCY.RULE_CURRENCY:
      case RULES_CONSTANT.PERCENTAGE.RULE_PERCENTAGE:
      case RULES_CONSTANT.PERCENTAGE.RULE_PERCENTAGE_INPUT:
      case RULES_CONSTANT.PERCENTAGE.RULE_PERCENTAGE_ADDITION:
      case RULES_CONSTANT.PERCENTAGE.RULE_PERCENTAGE_DIVISION:
      case RULES_CONSTANT.PERCENTAGE.RULE_PERCENTAGE_MULTIPLICATION:
      case RULES_CONSTANT.PERCENTAGE.RULE_PERCENTAGE_SUBTRACTION:
      case RULES_CONSTANT.PERCENTAGE.RULE_CALCULATE_PERCENTAGE:
        serializedValue = typeof value === 'string' ? value.replace(/[^0-9.-]+/g, '') : value;
        break;

      case RULES_CONSTANT.TEXT.RULE_PULL_FROM_SUBJECT_ATTRIBUTE:
      case RULES_CONSTANT.BASE_SUBJECT.RULE_ATTRIBUTE_SUBJECT_BASE:
      case RULES_CONSTANT.ASSOCIATED_SUBJECT.RULE_ASSOCIATED_SUBJECT_LIST:
      case RULES_CONSTANT.TEXT.RULE_SELECT_FROM_KTREE_TABLE:
      case RULES_CONSTANT.ASSOCIATED_SUBJECT.RULE_ASSOCIATED_SUBJECT_LIMITED_DROPDOWN_LIST:
      case RULES_CONSTANT.TEXT.RULE_PULL_FROM_STATIC_TABLE:
      case RULES_CONSTANT.SYSTEM_OPTIONS.RULE_SYSTEM_OPTIONS_DEPRECIATION_TYPE:
        if (typeof value === 'object') {
          if (value?.value === MEMBER_LIST_SUGGESTION_ID_NEED_TO_BE_INSERTED) {
            serializedValue = value;
            break;
          }

          serializedValue = value?.value;
        } else if (typeof value === 'string') {
          const selectedOption = findObjectByLabelOrValue(value, dropdownOptions);
          serializedValue = selectedOption?.value;
        }
        break;

      case RULES_CONSTANT.GENERATED_SYSTEM.RULE_GENERATED_SYSTEM_MODIFIER:
        serializedValue = authReducer.profile.id;
        break;
      case RULES_CONSTANT.GENERATED_SYSTEM.RULE_GENERATED_SYSTEM_DATE_TIME:
      case RULES_CONSTANT.GENERATED_SYSTEM.RULE_GENERATED_SYSTEM_DATE:
        serializedValue = dayjs().toISOString();

        break;

      default:
        serializedValue = value;
        break;
    }

    if (serializedValue !== undefined && !readOnlyColumn) {
      result[key] = serializedValue;
    }
  }

  return result;
}
