import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import * as yup from 'yup';
import Chip from '@mui/material/Chip';
import Alert from '@mui/material/Alert';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import ButtonLoading from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import Autocomplete from '@mui/material/Autocomplete';
import { Formik, FormikErrors, FormikProps } from 'formik';
import {
  useGetLimitedDropdownAssociteSubject,
  useTableDefinitioDetails,
  useUpsertTableDefinition,
} from 'services/v1/SystemTenant/AROKMS/TableDefinitionService';
import {
  dateTypeParameterOptions,
  dateTimeTypeParameterOptions,
  staticColumnParameterDropdownOption,
  staticTableParameterDropdownOption,
  depreciationResultOptions,
  ruleConditionOptions,
  concatenateConditionOptions,
} from 'data/Dropdown';
import ActivityIndicator from 'components/ActivityIndicatorComponent';
import { useAttributeDropdown } from 'services/v1/SystemTenant/AROKMS/AttributeService';
import { useSubjectDropdown, useSubjectUsedDropdown } from 'services/v1/SystemTenant/AROKMS/SubjectService';
import {
  TableDefinitionGetParams,
  TableDefinitionUpsertRequest,
} from 'types/api/SystemTenant/AROKMS/TableDefinitionTypes';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import RemoveCircleOutlinedIcon from '@mui/icons-material/RemoveCircleOutlined';
import { useGetKTreeDefinitionListDropdown } from 'services/v1/SystemTenant/AROKMS/KTreeBuilderService';
import { RULES_CONSTANT } from 'constant/RuleConstant';
import { SUBJECT_TYPE } from 'constant/DataInputConstant';
import { getErrorMessage } from 'utils/Error';

import { generateYupSchema } from 'utils/SchemaGenerator';
import { isValidJSONString } from 'utils/Object';

const ModalContentStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'background.paper',
  height: 540,
  overflow: 'auto',
  borderRadius: 1,
  px: 3,
  py: 2,
};

const autocompleteStyles = {
  width: '55%',
  '& .MuiOutlinedInput-root': {
    padding: '1px 2px',
  },
  '& .MuiAutocomplete-popper': {
    fontSize: '10px',
  },
};

const textInputStyles = {
  width: '55%',
  '& .MuiOutlinedInput': {
    padding: '1px 2px',
  },
};

const textInputAutoCompleteStyles = {
  '& .MuiOutlinedInput': {
    padding: '1px 2px',
  },
};

const closeIconStyle = { color: '#98A2AE', cursor: 'pointer' };

const optionStyles = { '& > span': { fontSize: '14px', mr: 2, flexShrink: 0 } };

export type ModalParamState = {
  tableDefinitionId: string | number | null;
  message: string;
  open: boolean;
};

interface ModalParamsProps {
  visible?: boolean;
  tableDefinitionId: string | number | null;
  onClose?: () => void;
  selectedTab: string;
  listFilter: TableDefinitionGetParams;
}

interface ParameterItem {
  label: string;
  id: string;
  type: string;
  optionsSource: string;
  required: boolean;
  removeTrim: boolean;
  defaultValue?: string | undefined | null | object;
  inline: boolean;
  placeholder?: string;
  validations?: [];
}

export interface AttributeParamDropdownFilter {
  tableDefinitionSubjectId: string | number | undefined;
  dataTypeId: string | number | undefined;
  ruleTypeId?: string | number | undefined;
  dependenciesAttributeId?: string | number | undefined;
}

const DEFAULT_EMPTY_OPTIONS = [{ label: '', value: '', dataType: null, persistentName: '' }];
const chipStyle = {
  fontSize: '10px',
};

type FormValues = {};
export function ModalParameter(props: ModalParamsProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const formikRef = useRef<FormikProps<FormValues>>(null);
  const [dependantDropdown, setDependantDropdown] = useState<string>('');
  const { onClose, visible = false, tableDefinitionId, selectedTab, listFilter } = props;
  const [formFields, setFormFields] = useState<ParameterItem[]>([]);

  const paramsForTableDefinitionAssociateSubject = searchParams.get('recordAssociateSubjectId');
  const paramsForTableDefinitionAssociateAttribute = searchParams.get('recordAssociateAttributeId');

  const [attributeDropdownFilter, setAttributeDropdownFilter] = useState<AttributeParamDropdownFilter>({
    tableDefinitionSubjectId: undefined,
    dependenciesAttributeId: undefined,
    dataTypeId: undefined,
    ruleTypeId: undefined,
  });
  const [attributeDropdownFilterDependant, setAttributeDropdownFilterDependant] =
    useState<AttributeParamDropdownFilter>({
      tableDefinitionSubjectId: undefined,
      dataTypeId: undefined,
    });

  const { data: subjectDropdownUsed, isLoading: isFetchingSubjectUsed } = useSubjectUsedDropdown({
    enabled: visible,
    subjectType: SUBJECT_TYPE.TABLE,
  });
  const { data: limitedDropdownSubject } = useGetLimitedDropdownAssociteSubject(listFilter.subjectId);
  const { data: subjectDropdown, isLoading: isFetchingSubject } = useSubjectDropdown(undefined, SUBJECT_TYPE.TABLE);
  const { data: attributeDropdown, isLoading: isFetchingAttribute } = useAttributeDropdown(attributeDropdownFilter);
  const { data: attributeDropdownDependant, isLoading: isFetchingAttributeDependant } = useAttributeDropdown(
    attributeDropdownFilterDependant
  );
  const { data: ktreeDefinitionOptions, isLoading: isLoadingKtreeOptions } = useGetKTreeDefinitionListDropdown(
    attributeDropdownFilterDependant.tableDefinitionSubjectId
  );
  const {
    data: tableDefinitionDetails,
    isLoading,
    isFetching: isFetchingTableDefinitions,
  } = useTableDefinitioDetails({
    tableDefinitionId: tableDefinitionId || undefined,
    enabled: visible,
  });
  const {
    mutate: upsertTableDefinition,
    isLoading: isSubmitting,
    isError: isErrorUpsert,
    error: erroMessageUpsert,
  } = useUpsertTableDefinition(listFilter, selectedTab);

  const isAnyDataLoading =
    isFetchingSubject ||
    isFetchingAttribute ||
    isFetchingSubjectUsed ||
    isLoading ||
    isFetchingAttributeDependant ||
    isFetchingTableDefinitions ||
    isLoadingKtreeOptions;

  // tddtid => table definition data type id
  // use for filtering attribute dropdown to show only attribute with same data type
  useEffect(() => {
    if (searchParams.get('tddtid')) {
      setAttributeDropdownFilter((prev) => ({
        ...prev,
        dataTypeId: searchParams.get('tddtid') || undefined,
        ruleTypeId: searchParams.get('ruleTypeId') || undefined,
        dependenciesAttributeId: searchParams.get('dependenciesAttribute') || undefined,
        tableDefinitionSubjectId: tableDefinitionDetails?.data?.subject?.id || undefined,
      }));
    }
  }, [searchParams, tableDefinitionDetails]);

  useEffect(() => {
    setAttributeDropdownFilterDependant(attributeDropdownFilter);
  }, [attributeDropdownFilter]);

  const getDisabledDropdown = (fieldName: string, value: { [key: string]: string }) => {
    if (fieldName === 'ATTRIBUTE') {
      return value['SUBJECT'] === '';
    }
    return false;
  };

  const getDisabledDynamicDropdown = (
    formFields: ParameterItem[],
    fieldName: string,
    value: { [key: string]: string }
  ) => {
    // disable dropdown if the value of the previous field is empty
    const index = formFields.findIndex((item) => item.id === fieldName);

    if (index > 0) {
      const previousField = formFields[index - 1];
      return value[previousField.id] === '' || value[previousField.id] === undefined;
    }
    return false;
  };

  const getFieldTypes = (
    field: string,
    formikOptions: {
      loading: boolean;
      errors: FormikErrors<{ [key: string]: string | number }>;
      values: { [key: string]: any };
      removeTrim?: boolean;
      setAttributeDropdownFilterDependant?: (params: AttributeParamDropdownFilter) => void;
      options: { value: string | number; label: string; dataType?: string | null }[];
      placeholder?: string;
      fieldName: string;
      setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
    },
    index: number,
    formFields: ParameterItem[]
  ) => {
    switch (field) {
      case 'textinput':
        return (
          <TextField
            value={formikOptions.values[formikOptions.fieldName]}
            sx={textInputStyles}
            size='small'
            placeholder={formikOptions.placeholder || ''}
            error={formikOptions.errors[formikOptions.fieldName] ? true : false}
            helperText={formikOptions.errors[formikOptions.fieldName]}
            onChange={(e) => {
              if (!formikOptions.removeTrim) {
                formikOptions.setFieldValue(formikOptions.fieldName, e.target.value);
                return;
              }
              formikOptions.setFieldValue(formikOptions.fieldName, e.target.value.trim());
            }}
          />
        );
      case 'numberinput':
        return (
          <TextField
            value={formikOptions.values[formikOptions.fieldName]}
            sx={textInputStyles}
            size='small'
            placeholder={formikOptions.placeholder || ''}
            type='number'
            error={formikOptions.errors[formikOptions.fieldName] ? true : false}
            helperText={formikOptions.errors[formikOptions.fieldName]}
            onChange={(e) => {
              if (formikOptions.removeTrim) {
                formikOptions.setFieldValue(formikOptions.fieldName, e.target.value);
                return;
              }
              formikOptions.setFieldValue(formikOptions.fieldName, e.target.value.trim());
            }}
          />
        );
      case 'switch-toggle':
        return (
          <>
            <Switch
              checked={formikOptions.values[formikOptions.fieldName] === 'true'}
              inputProps={{ 'aria-label': 'controlled' }}
              onChange={(e) => {
                formikOptions.setFieldValue(formikOptions.fieldName, `${e.target.checked}`);
              }}
            />
          </>
        );
      case 'dropdown':
        return (
          <Autocomplete
            key={formikOptions.fieldName}
            value={{
              label:
                typeof formikOptions.values[formikOptions.fieldName] === 'object'
                  ? formikOptions.values[formikOptions.fieldName]?.label || ''
                  : formikOptions.values[formikOptions.fieldName] || '',
              value:
                typeof formikOptions.values[formikOptions.fieldName] === 'object'
                  ? formikOptions.values[formikOptions.fieldName]?.value || ''
                  : formikOptions.values[formikOptions.fieldName] || '',
              dataType: '',
            }}
            getOptionLabel={(option) => option.label}
            clearIcon={null}
            disabled={getDisabledDynamicDropdown(formFields, formikOptions.fieldName, formikOptions.values)}
            contentEditable={false}
            options={formikOptions.options}
            loading={formikOptions.loading}
            onChange={(event, value) => {
              if (formikOptions.fieldName === 'SUBJECT' && value?.value) {
                setAttributeDropdownFilterDependant({ tableDefinitionSubjectId: value.value, dataTypeId: undefined });
                formikOptions.setFieldValue('ATTRIBUTE', '');
                formikOptions.setFieldValue('WHEREATTRIBUTE', '');
                formikOptions.setFieldValue('TARGETATTRIBUTE', '');
              }
              if (formikOptions.fieldName === 'STABLE' && value?.value) {
                setDependantDropdown(value?.value as string);
                formikOptions.setFieldValue('SCOLUMN', '');
              }
              formikOptions.setFieldValue(formikOptions.fieldName, value);
            }}
            renderOption={(props, option) => (
              <Box component='li' sx={optionStyles} {...props}>
                <span>{option?.label}</span>
                {option?.dataType && (
                  <Chip label={option.dataType} color='info' variant='outlined' size='small' sx={chipStyle} />
                )}
              </Box>
            )}
            sx={autocompleteStyles}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={textInputAutoCompleteStyles}
                error={formikOptions.errors[formikOptions.fieldName] ? true : false}
                helperText={formikOptions.errors[formikOptions.fieldName]}
              />
            )}
          />
        );
      case 'dropdown-dynamic':
        return (
          <Stack direction='row' display='flex' flex={1} alignItems='center' justifyContent='flex-end'>
            <Autocomplete
              key={formikOptions.fieldName}
              value={{
                label:
                  typeof formikOptions.values[formikOptions.fieldName] === 'object'
                    ? formikOptions.values[formikOptions.fieldName]?.label || ''
                    : formikOptions.values[formikOptions.fieldName] || '',
                value:
                  typeof formikOptions.values[formikOptions.fieldName] === 'object'
                    ? formikOptions.values[formikOptions.fieldName]?.value || ''
                    : formikOptions.values[formikOptions.fieldName] || '',
                dataType: '',
              }}
              getOptionLabel={(option) => option.label}
              clearIcon={null}
              disabled={getDisabledDropdown(formikOptions.fieldName, formikOptions.values)}
              contentEditable={false}
              options={formikOptions.options}
              loading={formikOptions.loading}
              onChange={(event, value) => {
                if (formikOptions.fieldName === 'SUBJECT' && value?.value) {
                  setAttributeDropdownFilterDependant({ tableDefinitionSubjectId: value.value, dataTypeId: undefined });
                  formikOptions.setFieldValue('ATTRIBUTE', '');
                }
                if (formikOptions.fieldName === 'STABLE' && value?.value) {
                  setDependantDropdown(value?.value as string);
                  formikOptions.setFieldValue('SCOLUMN', '');
                }
                formikOptions.setFieldValue(formikOptions.fieldName, value);
              }}
              renderOption={(props, option) => (
                <Box component='li' sx={optionStyles} {...props}>
                  <span>{option?.label}</span>
                  {option.dataType && (
                    <Chip label={option.dataType} color='info' variant='outlined' size='small' sx={chipStyle} />
                  )}
                </Box>
              )}
              sx={{ ...autocompleteStyles }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={textInputAutoCompleteStyles}
                  error={formikOptions.errors[formikOptions.fieldName] ? true : false}
                  helperText={formikOptions.errors[formikOptions.fieldName]}
                />
              )}
            />
            <Tooltip title='Add column'>
              <IconButton
                onClick={() => {
                  setFormFields((prev) => [
                    ...prev,
                    {
                      label: `Attribute ${formFields.length + 1}`,
                      id: `ATTRIBUTE${formFields.length + 1}`,
                      type: 'dropdown-dynamic',
                      optionsSource: prev[0].optionsSource,
                      required: false,
                      removeTrim: false,
                      placeholder: '',
                      inline: false,
                      defaultValue: { label: '', value: '' },
                    },
                  ]);
                }}
              >
                <AddCircleOutlinedIcon fontSize='medium' sx={{ fill: '#00B3DC' }} />
              </IconButton>
            </Tooltip>

            <Tooltip title='Delete' disableHoverListener={index === 0} disableInteractive={index === 0}>
              <IconButton
                disabled={index === 0}
                onClick={() => {
                  setFormFields((prev) => prev.filter((_, i) => i !== index));
                }}
              >
                <RemoveCircleOutlinedIcon fontSize='medium' sx={{ fill: '#00B3DC', opacity: index === 0 ? 0 : 1 }} />
              </IconButton>
            </Tooltip>
          </Stack>
        );
    }
  };

  const getDropdownOptions = (
    field: string
  ): { label: string; value: string | number; dataType?: string | null; persistentName?: string | null }[] => {
    switch (field) {
      case 'ATTRIBUTE_DROPDOWN':
        return (
          attributeDropdown?.data
            .filter((item) => item?.label !== paramsForTableDefinitionAssociateAttribute)
            .map((item) => ({
              label: item?.label,
              value: item?.value,
              dataType: item?.dataType,
              persistentName: item?.persistentName,
            })) || DEFAULT_EMPTY_OPTIONS
        );
      case 'ATTRIBUTE_DROPDOWN_DEPENDANT':
        const options =
          attributeDropdownDependant?.data.map((item) => ({
            label: item?.label,
            value: item?.value,
            dataType: item?.dataType || '',
            persistentName: item?.persistentName,
          })) || DEFAULT_EMPTY_OPTIONS;
        return options;
      case 'SUBJECT_DROPDOWN':
        return subjectDropdownUsed?.data || DEFAULT_EMPTY_OPTIONS;
      case 'KTREE_DROPDOWN':
        return (
          ktreeDefinitionOptions?.data.map((item) => ({ ...item, persistentName: item.label })) || DEFAULT_EMPTY_OPTIONS
        );
      case 'SUBJECT_DROPDOWN_ALL': {
        return (
          subjectDropdown?.data.map((item) => ({
            id: item.id,
            value: item.id,
            label: item.subject,
            persistentName: item.subject,
          })) || DEFAULT_EMPTY_OPTIONS
        );
      }
      case 'LIMITED_SUBJECT_LIST_DROPDOWN':
        return (
          limitedDropdownSubject?.data.filter((item) => item?.label !== paramsForTableDefinitionAssociateSubject) ||
          DEFAULT_EMPTY_OPTIONS
        );

      case 'RULE_CONDITION_TYPES_OPTIONS':
        return ruleConditionOptions;

      case 'DATE_TYPE_DROPDOWN':
        return dateTypeParameterOptions?.map((item) => ({ ...item, persistentName: item.label }));

      case 'CONCATE_RULES_OPTIONS':
        return concatenateConditionOptions;
      case 'DATETIME_TYPE_DROPDOWN':
        return dateTimeTypeParameterOptions;
      case 'STATIC_TABLE_DROPDONW':
        return staticTableParameterDropdownOption;
      case 'F_DEPRECIATION_RESULT':
        return depreciationResultOptions;
      case 'STATIC_COLUMN_DROPDOWN':
        // @ts-ignore
        return staticColumnParameterDropdownOption[dependantDropdown];
      default:
        return DEFAULT_EMPTY_OPTIONS;
    }
  };
  const getDropdownLoading = (field: string): boolean => {
    switch (field) {
      case 'ATTRIBUTE_DROPDOWN':
        return isFetchingAttribute;
      case 'SUBJECT_DROPDOWN':
        return isFetchingSubject;

      case 'SUBJECT_DROPDOWN_ALL': {
        return isFetchingSubjectUsed;
      }
      default:
        return false;
    }
  };

  const handleOnCancel = () => {
    handleOnCloseModal?.();
  };

  const handleOnCloseModal = () => {
    //  Clear query params
    searchParams.delete('tddtid');
    searchParams.delete('recordAssociateSubjectId');
    searchParams.delete('recordAssociateAttributeId');
    searchParams.delete('ruleTypeId');
    searchParams.delete('dependenciesAttribute');
    setSearchParams(searchParams);
    onClose?.();
  };

  const handleOnSave = (data: { [key: string]: string | number }) => {
    if (tableDefinitionDetails?.data) {
      const payloadRequest: TableDefinitionUpsertRequest = {
        ruleTypeId: tableDefinitionDetails.data.ruleType.id,
        subjectId: tableDefinitionDetails.data.subject.id,
        allowEmpty: tableDefinitionDetails.data.allowEmpty,
        id: tableDefinitionId,
        ruleTypeParams: Object.values(data)
          // @ts-ignore
          .map((item) => {
            // @ts-ignore
            return typeof item === 'object' ? item?.persistentName : item;
          })
          .join(),
        ruleTypeParamsId: Object.values(data)
          .map((item) => {
            // @ts-ignore
            return typeof item === 'object' && `${item?.persistentName}=${item?.value}`;
          })
          .filter(Boolean)
          .join(),
      };

      // if ruleType id is "52" which means this is rule for "SELECT FROM KTREE TABLE"
      // we need send id, instead of name
      //@ts-ignore
      if (tableDefinitionDetails.data.ruleType.code === RULES_CONSTANT.TEXT.RULE_SELECT_FROM_KTREE_TABLE) {
        // @ts-ignore
        payloadRequest.ruleTypeParams = `${data.SUBJECT.persistentName},${data.KTREE.value}`;
      }

      if (
        tableDefinitionDetails.data.ruleType.code === RULES_CONSTANT.TEXT.RULE_CONCATENATE_ATTRIBUTES &&
        payloadRequest.ruleTypeParams
      ) {
        // const ruleTypeParamsId =
        const columnNames = payloadRequest.ruleTypeParams
          .split(',')
          .filter((string) => string.startsWith('{') && string.endsWith('}'))
          .map((string) => string.slice(1, -1));

        const columnNamesSet = new Set(columnNames);

        const dropdownOptions = getDropdownOptions('ATTRIBUTE_DROPDOWN');
        const persistentColumnNamesWithValue = dropdownOptions.filter((dropdownItem) => {
          return columnNamesSet.has(dropdownItem.label);
        });

        const ruleTypeParamsId = persistentColumnNamesWithValue
          .map((item) => `${item?.persistentName}=${item?.value}`)
          .filter(Boolean)
          .join();
        console.log({
          ruleTypeParamsId,
          columnNames,
        });
        payloadRequest.ruleTypeParamsId = ruleTypeParamsId;
        payloadRequest.ruleTypeParams = JSON.stringify(data);
      }

      if (selectedTab === 'ASSOCIATED_ATTRIBUTE') {
        payloadRequest.attributeId = tableDefinitionDetails.data.attribute.id;
      }
      if (selectedTab === 'ASSOCIATED_SUBJECT') {
        payloadRequest.displayName = tableDefinitionDetails.data.associatedSubjectDefinition.displayName;
        payloadRequest.associatedSubjectId =
          tableDefinitionDetails.data.associatedSubjectDefinition.associatedSubject.id;
      }

      upsertTableDefinition(payloadRequest, {
        onSuccess: () => {
          handleOnCloseModal?.();
        },
      });
    }
  };

  const getModalTitle = useCallback(() => {
    if (!tableDefinitionDetails?.data) return '';

    if (selectedTab === 'ASSOCIATED_ATTRIBUTE' && tableDefinitionDetails?.data) {
      return `Parameter for ${tableDefinitionDetails?.data.attribute.attribute}`;
    }
    return `Parameter for ${tableDefinitionDetails?.data.associatedSubjectDefinition.associatedSubject.subject}`;
  }, [selectedTab, tableDefinitionDetails]);

  const parameterFields: ParameterItem[] = useMemo(() => {
    if (tableDefinitionDetails?.data?.ruleType?.paramsValue) {
      return JSON.parse(tableDefinitionDetails?.data.ruleType.paramsValue);
    }
    return [];
  }, [tableDefinitionDetails]);

  const yupSchema = useMemo(() => {
    if (parameterFields.length > 0) {
      // @ts-ignore
      return parameterFields.reduce(generateYupSchema, {});
    }
    return false;
  }, [parameterFields]);

  const validationSchema: {
    isValidSync?: (data: { [key: string]: string | number }) => boolean;
  } = useMemo(() => {
    // @ts-ignore
    return yupSchema ? yup.object().shape(yupSchema) : {};
  }, [yupSchema]);

  const formikInitialValues = useMemo(() => {
    if (formFields.length > 0) {
      if (tableDefinitionDetails?.data.ruleTypeParams) {
        const params = tableDefinitionDetails?.data.ruleTypeParams.split(',');

        return formFields.reduce((acc, item, index) => {
          if (item.id === 'ATTRIBUTE_CONCATENATION') {
            let obcAttrbConcate = tableDefinitionDetails?.data.ruleTypeParams;
            let ruleParamsOBJ;
            if (isValidJSONString(tableDefinitionDetails?.data.ruleTypeParams)) {
              ruleParamsOBJ = JSON.parse(tableDefinitionDetails?.data.ruleTypeParams);
              obcAttrbConcate = ruleParamsOBJ['ATTRIBUTE_CONCATENATION'];
            }

            return { ...acc, [item.id]: obcAttrbConcate };
          }
          if (item.id === 'ATTRIBUTE_CONCATENATION_RULES') {
            let obcAttrbConcate = tableDefinitionDetails?.data.ruleTypeParams;
            let ruleParamsOBJ;
            if (isValidJSONString(tableDefinitionDetails?.data.ruleTypeParams)) {
              ruleParamsOBJ = JSON.parse(tableDefinitionDetails?.data.ruleTypeParams);
              obcAttrbConcate = ruleParamsOBJ['ATTRIBUTE_CONCATENATION_RULES'];
              return { ...acc, [item.id]: obcAttrbConcate };
            }
          }
          if (item.type === 'dropdown-dynamic' || item.type === 'dropdown') {
            const options = getDropdownOptions(item.optionsSource);
            // @ts-ignore
            const option = options.find((option) => option.persistentName === params[index]);

            if (option) {
              return { ...acc, [item.id]: option };
            }
          }
          return { ...acc, [item.id]: params[index] };
        }, {});
      }
      const parameters = formFields.map((item) => {
        return {
          [item.id]: '',
        };
      });

      return Object.assign({}, ...parameters);
    }
    return {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formFields,
    tableDefinitionDetails?.data.ruleTypeParams,
    attributeDropdown,
    subjectDropdown,
    subjectDropdownUsed,
  ]);

  const isButtonSubmitDisabled = (values: { [key: string]: string | number }) => {
    //  Check if with required fields form parameterFields is filled
    const isRequiredFieldsEmpty = parameterFields.some((item) => {
      if (item.required) {
        return values[item.id] === '' || values[item.id] === undefined || values[item.id] === null;
      }
      return false;
    });

    return isRequiredFieldsEmpty;
  };

  useEffect(() => {
    if (
      parameterFields.length > 0 &&
      tableDefinitionDetails?.data.ruleTypeParams &&
      parameterFields.some((item) => item.type === 'dropdown-dynamic')
    ) {
      const params = tableDefinitionDetails?.data.ruleTypeParams.split(',');

      // by default the fields will be 2 fields
      if (params.length) {
        // remove the first 2 fields
        const initialFieldsBasedOnParams = params.slice(2);
        const initialFields = initialFieldsBasedOnParams.map((item, index) => {
          return {
            label: `Attribute ${index + 3}`,
            id: `ATTRIBUTE${index + 3}`,
            type: 'dropdown-dynamic',
            optionsSource: parameterFields[0].optionsSource,
            required: false,
            removeTrim: false,
            placeholder: '',
            inline: false,
          };
        });
        setFormFields([...parameterFields.slice(0, 2), ...initialFields]);
      }
    } else {
      setFormFields(parameterFields);
    }
  }, [parameterFields, tableDefinitionDetails?.data.ruleTypeParams]);

  useEffect(() => {
    if (Object.keys(formikInitialValues).length > 0) {
      if (formikInitialValues?.KTREE) {
        const KtreeLabel =
          tableDefinitionDetails?.data?.ruleTypeParamsTableDefinitionId?.split(',')?.[1].split('=')?.[0] ||
          'Unknown Ktree';

        const ktreeInitialValue = {
          label: KtreeLabel,
          value: formikInitialValues?.KTREE,
        };

        formikRef.current?.setValues({
          ...formikRef.current?.values,
          ...formikInitialValues,
          KTREE: ktreeInitialValue,
        });
      } else {
        formikRef.current?.setValues({
          ...formikRef.current?.values,
          ...formikInitialValues,
        });
      }
    }
    if (formikInitialValues?.SUBJECT && formikInitialValues?.SUBJECT?.value) {
      setAttributeDropdownFilterDependant({
        tableDefinitionSubjectId: formikInitialValues.SUBJECT.value || '',
        dataTypeId: attributeDropdownFilter.dataTypeId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formikInitialValues]);

  return (
    <div>
      <Modal open={visible} onClose={handleOnCloseModal}>
        {isAnyDataLoading ? (
          <Stack direction='column' sx={ModalContentStyle} justifyContent='center'>
            <Stack justifyContent='space-between' direction='row' alignItems='center' spacing={2}>
              <Typography variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
                {getModalTitle()}
              </Typography>
              <IconButton onClick={handleOnCloseModal} component='label'>
                <HighlightOffRoundedIcon sx={closeIconStyle} />
              </IconButton>
            </Stack>
            <ActivityIndicator />
          </Stack>
        ) : (
          <Stack direction='column' sx={ModalContentStyle} justifyContent='space-between'>
            <Formik
              innerRef={formikRef}
              initialValues={formikInitialValues}
              onSubmit={handleOnSave}
              validationSchema={validationSchema}
            >
              {({ handleSubmit, values, setFieldValue, errors, touched }) => {
                return (
                  <>
                    <Stack>
                      <Stack justifyContent='space-between' direction='row' alignItems='center' spacing={2}>
                        <Typography variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
                          {getModalTitle()}
                        </Typography>
                        <IconButton onClick={handleOnCloseModal} component='label'>
                          <HighlightOffRoundedIcon sx={closeIconStyle} />
                        </IconButton>
                      </Stack>
                      <Divider sx={{ mb: 1 }} />
                    </Stack>
                    <Stack sx={{ mt: -10 }}>
                      {formFields.some((item) => item.inline) ? (
                        <Stack
                          direction='row'
                          spacing={2}
                          alignItems='center'
                          justifyContent='space-between'
                          sx={{ mt: 2 }}
                        >
                          {formFields.map((parameterField, index) => {
                            return (
                              <>
                                {index === 0 && <Typography variant='input-label'>{parameterField.label}</Typography>}
                                {getFieldTypes(
                                  parameterField.type,
                                  {
                                    errors,
                                    setFieldValue,
                                    values,
                                    options: getDropdownOptions(parameterField.optionsSource),
                                    fieldName: parameterField.id,
                                    placeholder: parameterField.placeholder,
                                    removeTrim: parameterField.removeTrim,
                                    setAttributeDropdownFilterDependant,
                                    loading: getDropdownLoading(parameterField.optionsSource),
                                  },
                                  index,
                                  formFields
                                )}
                              </>
                            );
                          })}
                        </Stack>
                      ) : (
                        <>
                          {formFields.map((parameterField, index) => {
                            return (
                              <Stack
                                direction='row'
                                spacing={2}
                                alignItems='center'
                                justifyContent='space-between'
                                sx={{ mt: 2 }}
                              >
                                <Typography variant='input-label'>{parameterField.label}</Typography>
                                {getFieldTypes(
                                  parameterField.type,
                                  {
                                    errors,
                                    setFieldValue,
                                    values,
                                    options: getDropdownOptions(parameterField.optionsSource),
                                    fieldName: parameterField.id,
                                    removeTrim: parameterField.removeTrim,
                                    setAttributeDropdownFilterDependant,
                                    loading: getDropdownLoading(parameterField.optionsSource),
                                  },
                                  index,
                                  formFields
                                )}
                              </Stack>
                            );
                          })}
                        </>
                      )}
                    </Stack>

                    <Stack>
                      {isErrorUpsert && <Alert severity='error'>{getErrorMessage(erroMessageUpsert)}</Alert>}
                      <Divider sx={{ mb: 1, mt: 1 }} />
                      <Stack direction='row' justifyContent='flex-end' spacing={2} alignItems='center' sx={{ py: 1 }}>
                        <Button disabled={isSubmitting} variant='main-table-panel-border' onClick={handleOnCancel}>
                          Cancel
                        </Button>
                        <ButtonLoading
                          loading={isSubmitting}
                          disabled={isButtonSubmitDisabled(values) || !validationSchema?.isValidSync?.(values)}
                          variant='main-table-panel'
                          onClick={() => handleSubmit()}
                        >
                          Save Parameter
                        </ButtonLoading>
                      </Stack>
                    </Stack>
                  </>
                );
              }}
            </Formik>
          </Stack>
        )}
      </Modal>
    </div>
  );
}
