import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { authSlice, AuthState, setSessionStatus } from './reducer/authReducer';
import { uiSlice, UIState } from './reducer/uiReducer';
import { homeSlice, HomeState } from './reducer/homeReducer';
import { settingSlice, SettingState, setSelectedLanguage } from './reducer/settingReducer';
import { isTokenExpired, setAuthHeaderToken, removeAuthHeaderToken, setAuthTenantId } from 'libs/Axios';
import { SESSION_STATUS_CONSTANT } from 'constant/SessionStatusConstant';
export interface RootState {
  setting: SettingState;
  auth: AuthState;
  ui: UIState;
  home: HomeState;
}

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'setting', 'ui', 'home'],
};

const rootReducer = combineReducers({
  setting: settingSlice.reducer,
  auth: authSlice.reducer,
  ui: uiSlice.reducer,
  home: homeSlice.reducer,
});
// @ts-ignore
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore(
  {
    middleware(getDefaultMiddleware) {
      return getDefaultMiddleware({
        serializableCheck: false,
      });
    },
    reducer: persistedReducer,
  },
  // @ts-ignore
  typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

const persistor = persistStore(store, {}, () => {
  const appState: RootState = store.getState();
  localStorage.getItem('i18nextLng') && store.dispatch(setSelectedLanguage(localStorage.getItem('i18nextLng') ?? 'en'));
  if (isTokenExpired(appState)) {
    removeAuthHeaderToken();
    store.dispatch(setSessionStatus(SESSION_STATUS_CONSTANT.EXPIRED));
  } else {
    setAuthTenantId(appState.auth.selectedTenant.tenant.id);
    setAuthHeaderToken(appState.auth.accessToken);
  }
});

export { store, persistor };
