import _ from 'lodash';
import { useState, useMemo, forwardRef, useImperativeHandle, useCallback, useEffect } from 'react';
import { CustomDataTable } from 'components/DatatableComponent';
import {
  GridSortModel,
  getGridStringOperators,
  GridFilterModel,
  GridActionsCellItem,
  GridColumns,
} from '@mui/x-data-grid-pro';
import EditIcon from '@mui/icons-material/Edit';
import { PaginationAndSortingParams } from 'types/api/Common/PaginationTypes';
import { InsightViewAttributeDefinitionPanel } from './InsightViewAttributeDefinitionPanel';
import {
  InsightViewAttributeItem,
  InsightViewVarianceItem,
} from 'types/api/SystemTenant/AROView/InsightViewDefinitionTypes';
import { ModalAddVariance } from './ModalAddVariance';

interface IProps {
  disabled?: boolean;
  initialData?: InsightViewVarianceItem[];
  attributeDefinitionData: InsightViewAttributeItem[];
}

export interface IInsightViewVarianceDefinitionRef {
  getRowData: () => InsightViewVarianceItem[];
  setRowData: (data: InsightViewVarianceItem[]) => void;
}
export const InsightViewVarianceDefinitionTable = forwardRef<any, IProps>((props, ref) => {
  const { disabled: disabledProps, initialData, attributeDefinitionData } = props;
  const disabled = disabledProps ?? attributeDefinitionData.length === 0;

  const [rowData, setRowData] = useState<InsightViewVarianceItem[]>([] as InsightViewVarianceItem[]);
  const [showModalAddVariance, setShowModalAddVariance] = useState<{
    visible: boolean;
    data?: InsightViewVarianceItem;
  }>({
    visible: false,
  });

  const [filter, setFilter] = useState<PaginationAndSortingParams>({
    page: 0,
    size: 100,
    filterValue: '',
    sortType: '',
    filterOperator: '',
  });

  const handleEditClick = (row: InsightViewVarianceItem) => () => {
    if (!row) return;
    setShowModalAddVariance({ visible: true, data: row });
  };

  const columnsAttribute = useMemo<GridColumns>(() => {
    return [
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Edit',
        width: 30,
        cellClassName: 'actions',

        getActions: (params) => {
          return [
            // @ts-ignore
            <GridActionsCellItem
              icon={<EditIcon />}
              key={params.id}
              label='Edit'
              onClick={handleEditClick(params.row)}
            />,
          ];
        },
      },
      {
        field: 'group',
        headerName: 'Group',
        width: 240,
        editable: false,
        filterable: true,
        sortable: true,
        valueGetter: (params) => {
          return params?.row?.group.label;
        },
        filterOperators: getGridStringOperators().filter((operator) => ['contains', 'equals'].includes(operator.value)),
      },
      {
        field: 'targetColumn',
        headerName: 'Target Column',
        width: 240,
        editable: false,
        filterable: true,
        sortable: true,
        valueGetter: (params) => {
          return params?.row?.targetColumn.label;
        },
        filterOperators: getGridStringOperators().filter((operator) => ['contains', 'equals'].includes(operator.value)),
      },
      {
        field: 'resultColumn',
        headerName: 'Result Column',
        width: 240,
        editable: false,
        filterable: true,
        sortable: true,
        valueGetter: (params) => {
          return params?.row?.resultColumn.label;
        },
        filterOperators: getGridStringOperators().filter((operator) => ['contains', 'equals'].includes(operator.value)),
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnSortModelChange = (model: GridSortModel) => {
    if (model.length === 0) {
      setFilter({ ...filter, sortType: 'asc' });
      return;
    }
    setFilter({ ...filter, sortType: model[0].sort });
  };

  const handleOnFilterModelChange = (params: GridFilterModel) => {
    let filterObj = {};
    if (params.items.length) {
      filterObj = {
        filterValue: params.items[0].value,
        filterOperator: params.items[0].operatorValue,
      };
    } else {
      filterObj = {
        filterValue: '',
        filterOperator: 'contains',
      };
    }
    setFilter({
      ...filter,
      ...filterObj,
    });
  };

  console.log({
    rowData,
  });

  const handleChangePage = (newPage: number) => {
    setFilter({ ...filter, page: newPage });
  };

  const handleChangePageSize = (newPageSize: number) => {
    setFilter({ ...filter, size: newPageSize });
  };

  const handleOnAddVarianceButtonClick = () => {
    setShowModalAddVariance({ visible: true });
  };

  const handleOnCloseModalAddVariance = () => {
    setShowModalAddVariance({ visible: false });
  };

  const handleOnSaveVarianceItem = (data: InsightViewVarianceItem) => {
    const itemIndex = _.findIndex(rowData, { id: data.id });
    if (itemIndex !== -1) {
      setRowData((prevData) => {
        const newData = [...prevData];
        newData[itemIndex] = data;
        return newData;
      });
    } else {
      setRowData([...rowData, data]);
    }
  };

  const handleOnDeleteVariance = (data: InsightViewVarianceItem) => {
    setRowData((prevData) => {
      return prevData.filter((item) => item.id !== data.id);
    });
  };

  useImperativeHandle(ref, () => ({
    getRowData: () => rowData,
    setRowData: (data: InsightViewVarianceItem[]) => {
      setRowData(data);
    },
  }));

  const getRowId = useCallback((row) => {
    return row.id;
  }, []);

  useEffect(() => {
    if (initialData) {
      setRowData(initialData);
    }
  }, [initialData]);

  console.log({
    rowData,
  });

  return (
    <>
      <InsightViewAttributeDefinitionPanel
        buttonAddColumnLabel='Add Variance'
        onAddColumnAttributeButtonClick={handleOnAddVarianceButtonClick}
        disabled={disabled}
      />
      <CustomDataTable
        rows={rowData}
        checkboxSelection={false}
        columns={columnsAttribute}
        getRowId={getRowId}
        disableSelectionOnClick
        sortingMode='client'
        filterMode='client'
        paginationMode='client'
        isRowSelectable={(row) => {
          return !row.row.locked;
        }}
        isCellEditable={(params) => {
          return !params?.row?.locked;
        }}
        onSortModelChange={handleOnSortModelChange}
        onFilterModelChange={handleOnFilterModelChange}
        onPageChange={handleChangePage}
        onPageSizeChange={handleChangePageSize}
        rowHeight={45}
        autoHeight={rowData.length !== 0 && rowData.length > 5}
        sx={{ opacity: disabled ? 0.5 : 1, pointerEvents: disabled ? 'none' : 'auto', height: '300px' }}
        onProcessRowUpdateError={(params) => console.log({ params })}
        getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
        experimentalFeatures={{ newEditingApi: true }}
        components={{
          Toolbar: null,
          Pagination: null,
        }}
      />

      <ModalAddVariance
        visible={showModalAddVariance.visible}
        onClose={handleOnCloseModalAddVariance}
        onDelete={handleOnDeleteVariance}
        onSave={handleOnSaveVarianceItem}
        attributeData={attributeDefinitionData}
        selectedData={showModalAddVariance.data}
      />
    </>
  );
});
