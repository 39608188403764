import 'react-datepicker/dist/react-datepicker.css';
import { dayjs } from 'utils/DateUtils';
import { useParams, useSearchParams } from 'react-router-dom';
import { useEffect, useImperativeHandle, useMemo, useState, forwardRef, useCallback } from 'react';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekday from 'dayjs/plugin/weekday';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import DatePicker from 'react-datepicker';
import IconButton from '@mui/material/IconButton';
import LoopOutlinedIcon from '@mui/icons-material/LoopOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { AutoCompleteItem } from 'types/api/Common/AutoCompleteTypes';
import { flattenTreeData, renderOptionsKtree, getListBoxPropsAutoScrolItem } from 'utils/Object';
import {
  useGetStandardViewData,
  useGetStandardViewDetails,
  useGetStandardViewMemberList,
} from 'services/v1/Tenant/AROView/DisplayStandardViewService';
import { Tooltip } from '@mui/material';
import { CUBE_TIME_PERIOD_TYPE } from 'constant/CubeConstant';
import { DropdownItem } from 'types/api/SystemTenant/AROKMS/TableDefinitionTypes';

dayjs.extend(weekOfYear);
dayjs.extend(weekday);

const textInputStyle = {
  width: '100%',
  '& .MuiOutlinedInput': {
    padding: '1px 2px',
  },
};

export type StandardViewPanelOptionValues = {
  date: string | null;
  member: AutoCompleteItem | null;
};

interface StandardViewSettingPanelProps {
  disabled?: boolean;
  ref?: any;
  onOptionChange?: (data: StandardViewPanelOptionValues) => void;
}

const getEndOfWeek = (date: Date | null, endDayOfWeek: number) => {
  if (!date) return new Date();
  let endOfWeek = dayjs(date).day(endDayOfWeek);
  if (endOfWeek.isBefore(date)) {
    endOfWeek = endOfWeek.add(7, 'day');
  }
  return endOfWeek.toDate();
};

const CustomInput = forwardRef((props, ref) => (
  // @ts-ignore
  <TextField
    sx={textInputStyle}
    size='small'
    label='Date'
    // @ts-ignore
    value={props.value}
    // @ts-ignore
    onClick={props.onClick}
    // @ts-ignore
    ref={ref}
    // @ts-ignore
    disabled={props.disabled}
    InputProps={{
      endAdornment: (
        <Stack
          direction='row'
          justifyContent='flex-end'
          alignItems='center'
          sx={{
            cursor: 'pointer',
          }}
        >
          <CalendarMonthOutlinedIcon fontSize='small' color='inherit' />
        </Stack>
      ),
    }}
  />
));

export const StandardViewSettingPanel = forwardRef((props: StandardViewSettingPanelProps, ref) => {
  const { onOptionChange, disabled } = props;
  const { viewId } = useParams<{ viewId: string }>();
  const [searchParams] = useSearchParams();
  const memberId = searchParams.get('memberId');
  const [optionValue, setOptionValue] = useState<{
    date: Date | null;
    member: AutoCompleteItem | null;
  }>({
    date: new Date(),
    member: null,
  });

  const { data: standardViewMember, isLoading: isFetchingMember } = useGetStandardViewMemberList(viewId);
  const { data: standardViewDetails, isLoading: isLoadingStandardViewDetails } = useGetStandardViewDetails(viewId);

  const {
    isRefetching: isRefreshing,
    isLoading: isLoadingViewData,
    refetch: refetchStandardViewData,
  } = useGetStandardViewData(
    viewId,
    optionValue?.member?.value?.toString(),
    dayjs(optionValue.date).format('DD/MM/YYYY')
  );

  const memberOptions = useMemo(() => {
    if (standardViewMember) return flattenTreeData(standardViewMember);
    return [];
  }, [standardViewMember]);

  const todayDate = useMemo(() => {
    return dayjs().format('dddd, DD MMM YYYY');
  }, []);

  const endDayOfWeek = useMemo(() => {
    if (standardViewDetails?.data) return +standardViewDetails?.data?.endOfWeek;
    return 0;
  }, [standardViewDetails?.data]);

  const lowestMemberLevel = useMemo(() => {
    // @ts-ignore
    return memberOptions.reduce((acc, item) => {
      if (item.level > acc) {
        return item.level;
      } else {
        return acc;
      }
    }, 0);
  }, [memberOptions]);

  useEffect(() => {
    const selectedOptionValue: StandardViewPanelOptionValues = {
      date: optionValue.date ? dayjs(optionValue.date).format('DD/MM/YYYY') : null,
      member: optionValue.member,
    };
    onOptionChange?.(selectedOptionValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionValue]);

  useEffect(() => {
    if (memberOptions.length > 0) {
      const member = memberOptions[0];
      const selectedMember: AutoCompleteItem = {
        value: member.value?.toString() || '',
        label: member.label,
      };
      setOptionValue((prev) => ({ ...prev, member: selectedMember }));
    }
  }, [memberOptions]);

  useEffect(() => {
    if (standardViewDetails?.data.cubeTimePeriodType === CUBE_TIME_PERIOD_TYPE.WEEK) {
      setOptionValue((prev) => ({ ...prev, date: getEndOfWeek(new Date(), +standardViewDetails?.data?.endOfWeek) }));
    }
  }, [standardViewDetails?.data]);

  const handleOnRefresh = () => {
    refetchStandardViewData();
  };

  const handleWeekSelect = (date: Date) => {
    const startOfWeek = getEndOfWeek(date, endDayOfWeek);
    setOptionValue({
      ...optionValue,
      date: startOfWeek,
    });
  };

  const handleOnMemberChange = (event: React.SyntheticEvent, value: AutoCompleteItem | null) => {
    setOptionValue((prev) => ({ ...prev, member: value }));
  };

  const getWeekDays = useCallback(
    (date: Date | null) => {
      if (!date) return;
      let endOfWeek = dayjs(date).day(endDayOfWeek); // Set the end of the week
      let startOfWeek = endOfWeek.subtract(6, 'day'); // Subtract 6 days to get the start of the week

      let days = [];
      for (let day = startOfWeek; day.isBefore(endOfWeek) || day.isSame(endOfWeek); day = day.add(1, 'day')) {
        days.push(day.toDate());
      }
      return days;
    },
    [endDayOfWeek]
  );

  const highlightDates = useMemo(() => {
    return getWeekDays(optionValue.date);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionValue.date]);

  const isMonthlyView = standardViewDetails?.data.cubeTimePeriodType === CUBE_TIME_PERIOD_TYPE.MONTH;
  const dateFormat = useMemo(() => {
    if (isMonthlyView) {
      return 'MMM/yyyy';
    }
    return 'dd/MM/yyyy';
  }, [isMonthlyView]);

  useImperativeHandle(ref, () => ({
    isLoadingOptionsData: isFetchingMember,
  }));

  // auto select member
  useEffect(() => {
    if (memberOptions && memberOptions?.length > 0) {
      const member = memberOptions?.find((item: DropdownItem) => item.value === memberId) ?? memberOptions?.[0];
      const selectedMember: DropdownItem = {
        id: member.value,
        value: member?.value?.toString() || '',
        label: member.label,
      };
      setOptionValue((prev) => ({ ...prev, member: selectedMember }));
    }
  }, [memberOptions, memberId]);

  return (
    <Stack direction='column' sx={{ backgroundColor: '#fff', py: 1.8 }}>
      <Stack direction='row' justifyContent='space-between' spacing={0} sx={{ backgroundColor: '#fff', px: 2 }}>
        <Stack direction='row' spacing={1} alignItems='center'>
          <Typography variant='subtitle1' sx={{ fontWeight: 600 }}>
            Today's Date: {todayDate}
          </Typography>
        </Stack>
      </Stack>
      <Stack
        direction='row'
        alignItems='center'
        sx={{ bgcolor: '#FBFBFB', py: 1.5, px: 2, border: '1px solid #E3EBF6' }}
      >
        <Stack direction='row' justifyContent='space-between' width='100%'>
          <Stack direction='row' spacing={5} alignItems='center'>
            <Autocomplete
              onChange={handleOnMemberChange}
              clearIcon={null}
              sx={{
                width: 300,
              }}
              size='small'
              value={optionValue.member ?? null}
              disabled={isFetchingMember || isLoadingViewData || isLoadingStandardViewDetails}
              getOptionLabel={(option: AutoCompleteItem) => option.label}
              options={memberOptions || []}
              renderOption={(props, option, state) => renderOptionsKtree(props, option, state, lowestMemberLevel)}
              ListboxProps={{
                ...getListBoxPropsAutoScrolItem(memberOptions, optionValue.member),
              }}
              renderInput={(params) => (
                <Stack direction='row' justifyContent='space-between' display='flex' alignItems='center'>
                  <TextField
                    {...params}
                    sx={textInputStyle}
                    label={isFetchingMember ? 'Loading...' : 'Member'}
                    name='member'
                  />
                </Stack>
              )}
            />
            <Stack direction='row' justifyContent='space-between' display='flex' alignItems='center'>
              <DatePicker
                selected={optionValue.date}
                disabled={isFetchingMember || isLoadingViewData || isLoadingStandardViewDetails}
                onChange={(date: Date) => {
                  handleWeekSelect(date);
                }}
                dateFormat={dateFormat}
                showMonthYearPicker={isMonthlyView}
                calendarStartDay={endDayOfWeek + 1}
                showFullMonthYearPicker={isMonthlyView}
                highlightDates={highlightDates}
                showYearDropdown={!isMonthlyView}
                customInput={<CustomInput />}
              />
            </Stack>
          </Stack>
          <Tooltip title='Refresh' arrow onClick={handleOnRefresh}>
            <IconButton aria-label='delete' size='small' disabled={disabled}>
              {isRefreshing && (
                <CircularProgress
                  size={18}
                  sx={{
                    color: '#42BB93',
                  }}
                />
              )}
              {!isRefreshing && (
                <LoopOutlinedIcon
                  sx={{
                    color: '#42BB93',
                  }}
                />
              )}
            </IconButton>
          </Tooltip>
        </Stack>
      </Stack>
    </Stack>
  );
});
