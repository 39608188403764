import { AutoCompleteItem } from 'types/api/Common/AutoCompleteTypes';

export const DAY_OPTIONS = [
  {
    label: 'Sunday',
    value: '0',
  },
  {
    label: 'Monday',
    value: '1',
  },
  {
    label: 'Tuesday',
    value: '2',
  },
  {
    label: 'Wednesday',
    value: '3',
  },
  {
    label: 'Thursday',
    value: '4',
  },
  {
    label: 'Friday',
    value: '5',
  },
  {
    label: 'Saturday',
    value: '6',
  },
];

export const MONTH_OPTIONS: AutoCompleteItem[] = [
  {
    label: 'January',
    value: '01',
  },
  {
    label: 'February',
    value: '02',
  },
  {
    label: 'March',
    value: '03',
  },
  {
    label: 'April',
    value: '04',
  },
  {
    label: 'May',
    value: '05',
  },
  {
    label: 'June',
    value: '06',
  },
  {
    label: 'July',
    value: '07',
  },
  {
    label: 'August',
    value: '08',
  },
  {
    label: 'September',
    value: '09',
  },
  {
    label: 'October',
    value: '10',
  },
  {
    label: 'November',
    value: '11',
  },
  {
    label: 'December',
    value: '12',
  },
];
