import { useState } from 'react';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import { PullDataSourceHistoryTable } from './PullDataSourceHistoryTable';
import { PullDataSourceHistoryDetail } from './PullDataSourceHistoryDetail';
import { ISynchronizeEventDataSourceHistory } from 'types/api/Tenant/EventDataSourceTypes';

const ModalContentStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  bgcolor: 'background.paper',
  height: 'auto',
  overflow: 'auto',
  borderRadius: 1,
  py: 2,
};

const transitionStyles = {
  entering: { transform: 'translateX(-100%)', opacity: 0, position: 'absolute' } as React.CSSProperties,
  entered: {
    transform: 'translateX(0)',
    opacity: 1,
    transition: 'transform 0.3s, opacity 0.3s',
  } as React.CSSProperties,
  exiting: { transform: 'translateX(100%)', opacity: 0, position: 'absolute' } as React.CSSProperties,
  exited: { transform: 'translateX(0)', opacity: 1, transition: 'transform 0.3s, opacity 0.3s' } as React.CSSProperties,
};

const backButtonStyles = {
  textTransform: 'none',
  mb: 1,
  p: 0,
  width: 100,
};

const closeIconStyle = { color: '#98A2AE', cursor: 'pointer' };

const MODAL_VIEW_STATE = {
  PULL_HISTORY_LIST: 'PULL_HISTORY_LIST',
  PULL_HISTORY_DETAIL: 'PULL_HISTORY_DETAIL',
};

export interface ModalPullEventDataSourceHistroyProps {
  visible?: boolean;
  eventSubjectId?: string;
  onClose?: () => void;
}

export function ModalPullEventDataSourceHistroy(props: ModalPullEventDataSourceHistroyProps) {
  const { onClose, visible = false, eventSubjectId } = props;
  const [modalView, setModalView] = useState(MODAL_VIEW_STATE.PULL_HISTORY_LIST);
  const [selectedRow, setSelectedRow] = useState<ISynchronizeEventDataSourceHistory | null>(null);

  const handleOnCloseModal = () => {
    onClose?.();
    setSelectedRow(null);
    setModalView(MODAL_VIEW_STATE.PULL_HISTORY_LIST);
  };

  const handleOnClickHistoryDetails = (rowItem: ISynchronizeEventDataSourceHistory) => {
    setSelectedRow(rowItem);
    setModalView(MODAL_VIEW_STATE.PULL_HISTORY_DETAIL);
  };
  const handleOnClickBackToList = () => {
    setModalView(MODAL_VIEW_STATE.PULL_HISTORY_LIST);
  };

  const isPullHistoryList = modalView === MODAL_VIEW_STATE.PULL_HISTORY_LIST;

  return (
    <div>
      <Modal open={visible} onClose={handleOnCloseModal}>
        <Stack direction='column' sx={ModalContentStyle}>
          <Stack px={2} justifyContent='space-between' direction='row' alignItems='center'>
            {modalView === MODAL_VIEW_STATE.PULL_HISTORY_DETAIL && (
              <Button
                variant='text'
                disableRipple
                onClick={handleOnClickBackToList}
                sx={backButtonStyles}
                startIcon={<KeyboardBackspaceOutlinedIcon />}
              >
                Back to list
              </Button>
            )}
            {modalView === MODAL_VIEW_STATE.PULL_HISTORY_LIST && (
              <>
                <Typography variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
                  Synchronization History
                </Typography>
                <IconButton onClick={handleOnCloseModal} component='label'>
                  <HighlightOffRoundedIcon sx={closeIconStyle} />
                </IconButton>
              </>
            )}
          </Stack>
          <Divider sx={{ mb: 1, mt: 0 }} />
          <div style={{ position: 'relative', overflow: 'auto', maxHeight: 'calc(100vh - 200px)' }}>
            <PullDataSourceHistoryTable
              onClickDetails={handleOnClickHistoryDetails}
              visible={visible}
              eventSubjectId={eventSubjectId}
              style={isPullHistoryList ? transitionStyles.entered : transitionStyles.exiting}
            />
            <PullDataSourceHistoryDetail
              data={selectedRow}
              onClose={handleOnCloseModal}
              style={isPullHistoryList ? transitionStyles.entering : transitionStyles.entered}
            />
          </div>
        </Stack>
      </Modal>
    </div>
  );
}
