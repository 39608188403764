import * as React from 'react';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { MainAppBar } from 'components/AppBarComponent';
import { AppSidebar } from 'components/LayoutComponent/SidebarLayout/SidebarComponent';
import { ModalChangeLanguageComponent } from 'components/ModalComponent/ModalChangeLanguageComponent';
import { RootState } from 'store';
import { MEMBER_LIST_STATIC_RECORD } from 'data/MemberListRecord';
import { ROLES } from 'constant/PermissonConstant';
import { DASHBOARD_TYPE_CONSTANT } from 'constant/DashboardTypeConstant';
import { ModalChangeRole } from 'pages/Auth/ModalChangeRole';

const drawerWidth = 240;

const alertStyle = {
  container: {
    mb: 2,
    mt: -2,
    fontSize: 13,
  },
  title: {
    fontSize: 14,
  },
};

const mainLayoutStyle = {
  boxContainer: { display: 'flex', backgroundColor: '#F8FAFF', height: 'auto' },
  contentBox: { display: 'flex', padding: '5px 0px 0px 0px' },
  contentInnerBox: { flexGrow: 1, border: '1px solid #E3EBF6', borderRadius: '5px' },
};

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

interface MainLayoutProps {
  children: React.ReactNode | JSX.Element;
  pageTitle: string;
  breadcrumbs: string[];
}
interface IPropsAlertMessage {
  title: string;
  message: string;
}

const AlertMessage = ({ message, title }: IPropsAlertMessage) => {
  return (
    <Alert severity='info' sx={alertStyle.container}>
      <AlertTitle sx={alertStyle.title}>{title}</AlertTitle>
      {message}
    </Alert>
  );
};

export function MainLayoutComponent(props: MainLayoutProps): JSX.Element {
  const { children, pageTitle, breadcrumbs = [] } = props;

  const [showModalChangeRole, setShowModalChangeRole] = React.useState(false);

  const { isSidebarOpen, showModalSelectLanguage, modalKey } = useSelector((state: RootState) => state.ui);
  const { selectedTenant, role } = useSelector((state: RootState) => state.auth);

  const handleOnCloseModalChangeRole = () => {
    setShowModalChangeRole(false);
  };
  const handleOpenModalChangeRole = () => {
    setShowModalChangeRole(true);
  };

  return (
    <Box sx={mainLayoutStyle.boxContainer}>
      <ModalChangeLanguageComponent visible={showModalSelectLanguage} key={modalKey} />
      <MainAppBar pageTitle={pageTitle} breadcrumbs={breadcrumbs} onChangeRoleClick={handleOpenModalChangeRole} />

      <AppSidebar />

      <Main open={isSidebarOpen}>
        <DrawerHeader />
        {selectedTenant.tenant.systemCodeStatus === MEMBER_LIST_STATIC_RECORD.CODE_STATUS.LOCKED &&
          selectedTenant.tenant.dashboardType === DASHBOARD_TYPE_CONSTANT.DASHBOARD_SYSTEM &&
          role === ROLES.BUILDER && (
            <AlertMessage
              title={'Code Status Locked'}
              message="This system's code status is set to Locked, allowing you to only view the data. If you require any modifications, please reach out to your system administrator."
            />
          )}

        {selectedTenant.tenant.systemDemoStatus === MEMBER_LIST_STATIC_RECORD.DEMO_STATUS.LOCKED &&
          role === ROLES.PACKAGER && (
            <AlertMessage
              title={'Demo Status Locked'}
              message="This system's demo status is set to Locked, allowing you to only view the data. If you require any modifications, please reach out to your system administrator."
            />
          )}

        <div style={mainLayoutStyle.contentBox}>
          <div style={mainLayoutStyle.contentInnerBox}>{children}</div>
        </div>
      </Main>
      <ModalChangeRole visible={showModalChangeRole} onClose={handleOnCloseModalChangeRole} />
    </Box>
  );
}
