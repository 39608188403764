import React from 'react';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import { Stack } from '@mui/material';
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';

import { useGetListEventDataChannel } from 'services/v1/Tenant/AROEvent/EventDataSourceService';
import { EventDataChannel } from 'types/api/Tenant/EventDataSourceTypes';
import { SubjectItem } from 'types/api/SystemTenant/AROKMS/SubjectTypes';
import { BUTTON_ACTION_CHANNEL_ITEM_KEY, ChannelCard } from './ChannelCard';
import ModalChannelPreviewData, { ModalChannelPreviewDataState } from './ModalChannelPreviewData';

export type ChannelListProps = {
  eventSubject?: SubjectItem;
  onSelectedChannel?: (selectedChannel: EventDataChannel) => void;
};

export function ChannelList(props: ChannelListProps) {
  const { eventSubject, onSelectedChannel } = props;

  const [dataChannelPreviewModal, setDataChannelPreviewModal] = React.useState<ModalChannelPreviewDataState>({
    open: false,
  });
  const { data: eventChannelList } = useGetListEventDataChannel();

  const handleOnSelectChannel = (selectedChannel: EventDataChannel, optionKey: string) => {
    if (optionKey === BUTTON_ACTION_CHANNEL_ITEM_KEY.PREVIEW_DATA) {
      setDataChannelPreviewModal({ open: true, selectedChannel });
    }
    if (optionKey === BUTTON_ACTION_CHANNEL_ITEM_KEY.CONNECT_CHANNEL) {
      handleOnSelectedChannel(selectedChannel);
    }
  };

  const handleOnSelectedChannel = (selectedChannel: EventDataChannel) => {
    onSelectedChannel?.(selectedChannel);
  };

  return (
    <Stack>
      <Stack mt={2} px={3} py={2} gap={2}>
        <Stack direction='row' width='100%' gap={4} alignItems='center'>
          <Stack direction='column' spacing={0.5} alignItems='flex-start' width={500}>
            <Typography variant='input-label' fontSize={16} fontWeight={800}>
              Data Channel Integration
            </Typography>
            <Typography variant='input-label-gray' fontSize={14}>
              {`Select a Data Channel to synchronize with your ${eventSubject?.displayName}. 
             Connecting a channel enables automatic data import, facilitating a seamless data flow into your ${eventSubject?.displayName} system.`}
            </Typography>
          </Stack>
          <KeyboardDoubleArrowRightOutlinedIcon sx={{ fontSize: '30px', color: '#3B4797' }} />
          <Stack direction='column' spacing={0.3} alignItems='flex-start'>
            <Typography variant='input-label' fontSize={16} fontWeight={800} sx={{ color: '#3B4797' }}>
              {eventSubject?.displayName}
            </Typography>
          </Stack>
        </Stack>
        <Stack>
          <Alert variant='outlined' severity='info'>
            Note: The list below contains the data channels available within your organization. If the channel you
            require is not listed, please reach out to aroTarget administrator for access or further assistance.
          </Alert>
        </Stack>
        <Stack direction='row' gap={2}>
          {eventChannelList?.data?.map((channelItem) => (
            <ChannelCard key={channelItem.id} channelItem={channelItem} onSelectChannel={handleOnSelectChannel} />
          ))}
        </Stack>
      </Stack>
      <ModalChannelPreviewData
        visible={dataChannelPreviewModal.open}
        selectedChannel={dataChannelPreviewModal.selectedChannel}
        onConnectChannel={handleOnSelectedChannel}
        onClose={() => setDataChannelPreviewModal({ open: false, selectedChannel: undefined })}
      />
    </Stack>
  );
}
