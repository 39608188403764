import * as React from 'react';
import { RadioGroup, FormControlLabel, Radio, Stack } from '@mui/material';
import { AutoCompleteItem } from 'types/api/Common/AutoCompleteTypes';
import { ENV_CONFIG } from 'config/env';
import { ButtonSortChart, CHART_SORT_OPTIONS } from './ButtonSortChart';

const radioButtonLabelStyle = {
  '& .MuiFormControlLabel-label': {
    fontSize: '14px',
    color: '#98A2AE',
  },
};

const radioButtonStyle = {
  color: '#98A2AE',
  '& .MuiSvgIcon-root': {
    fontSize: 20,
  },
  '&.Mui-checked': {
    color: '#42BB93',
  },
};

interface BarChartProps {
  data?: {
    [rowId: string]: {
      [colId: string]: string | number;
    };
  };
  rowDimension?: AutoCompleteItem;
  columnDimension?: AutoCompleteItem;
  dataDimension?: AutoCompleteItem;
  scaleLabel?: string;
  totalData?: number;
}

export interface TableAnalyticBarChartRef {
  downloadChart: () => void;
}

const CHART_GROUPING_VALUE = {
  COLUMN: 'column',
  ROW: 'row',
};

// Helper function for this component
function convertDataRowToChartData(
  data: { [colId: string]: string | number },
  legendLabel?: string,
  scaleLabel?: string,
  totalData?: number,
  sortType?: string
) {
  let legendLabelChart = legendLabel || 'Total';
  if (legendLabelChart !== 'Total' && scaleLabel) {
    legendLabelChart = `${legendLabelChart} (${scaleLabel})`;
  }
  if (totalData) {
    legendLabelChart = `${legendLabelChart} : ${totalData?.toLocaleString()}`;
  }

  let result = [['category', legendLabelChart]];

  for (let key in data) {
    if (key !== 'Total' && key !== 'Total %') {
      result.push([key, data[key]] as string[]);
    }
  }

  if (CHART_SORT_OPTIONS.ASC === sortType) {
    // @ts-ignore
    result.sort((a, b) => a[1] - b[1]);
  }
  if (CHART_SORT_OPTIONS.DESC === sortType) {
    // @ts-ignore
    result.sort((a, b) => b[1] - a[1]);
  }
  return result;
}

function convertDataColumnToChartData(
  rawData: {
    [rowId: string]: {
      [colId: string]: string | number;
    };
  },
  legendLabel?: string,
  scaleLabel?: string,
  totalData?: number,
  sortType?: string
) {
  let legendLabelChart = legendLabel || 'Total';
  if (legendLabelChart !== 'Total' && scaleLabel) {
    legendLabelChart = `${legendLabelChart} (${scaleLabel})`;
  }
  if (totalData) {
    legendLabelChart = `${legendLabelChart} : ${totalData?.toLocaleString()}`;
  }
  let result = [['category', legendLabelChart]];

  for (let key in rawData) {
    if (
      rawData[key] &&
      typeof rawData[key] === 'object' &&
      'Total' in rawData[key] &&
      key !== 'Total' &&
      key !== 'Total %'
    ) {
      result.push([key, rawData[key]['Total'] as number] as string[]);
    }
  }

  if (CHART_SORT_OPTIONS.ASC === sortType) {
    // @ts-ignore
    result.sort((a, b) => a[1] - b[1]);
  }
  if (CHART_SORT_OPTIONS.DESC === sortType) {
    // @ts-ignore
    result.sort((a, b) => b[1] - a[1]);
  }

  return result;
}

export const ChartTableAnalytic = React.forwardRef<TableAnalyticBarChartRef, BarChartProps>(
  (props: BarChartProps, ref) => {
    const { data, dataDimension, scaleLabel, totalData } = props;
    const chartRef = React.useRef(null);
    const [selectedChartGrouping, setSelectedChartGrouping] = React.useState(CHART_GROUPING_VALUE.COLUMN);
    const [chartInstance, setChartInstance] = React.useState<any>(null);
    const [selectedSortChart, setSelectedSortChart] = React.useState(CHART_SORT_OPTIONS.NONE);

    const chartData = React.useMemo(() => {
      if (!data) return [];
      if (selectedChartGrouping === CHART_GROUPING_VALUE.COLUMN) {
        return convertDataColumnToChartData(data, dataDimension?.label, scaleLabel, totalData, selectedSortChart);
      }
      return convertDataRowToChartData(data?.['Total'], dataDimension?.label, scaleLabel, totalData, selectedSortChart);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, selectedChartGrouping, selectedSortChart]);

    React.useEffect(() => {
      const loadECharts = () => {
        return new Promise((resolve, reject) => {
          const script = document.createElement('script');
          script.src = `${ENV_CONFIG.endpoint.RUNTIME_ENDPOINT}images/echarts.min.js`;
          script.onload = resolve;
          script.onerror = reject;
          document.body.appendChild(script);
        });
      };

      const initChart = () => {
        if (chartData.length === 0) {
          return;
        }
        if (!chartRef.current) {
          console.error('Chart container not found');
          return;
        }
        //@ts-ignore
        if (typeof window === 'undefined' || !window.echarts) {
          console.error('ECharts is not available');
          return;
        }

        //@ts-ignore
        const instance = window.echarts.init(chartRef.current);
        setChartInstance(instance);

        const option = {
          legend: {},
          tooltip: {},
          dataset: {
            source: chartData,
          },
          xAxis: {
            type: 'category',
            axisLabel: {
              interval: 0,
              rotate: 30,
              formatter: function (value: any) {
                const maxLength = 15;
                if (value.length > maxLength) {
                  return value.substring(0, maxLength) + '...';
                }
                return value;
              },
            },
          },
          yAxis: {},
          series: [
            {
              type: 'bar',
              label: {
                normal: {
                  show: true,
                  position: 'top',
                  fontWeight: 'bold',
                  formatter: function (params: any) {
                    return params.value[1].toLocaleString();
                  },
                },
              },
            },
          ],
        };

        instance.setOption(option);
      };

      loadECharts()
        .then(initChart)
        .catch((error) => console.error('Failed to load ECharts:', error));

      return () => {
        if (chartInstance) {
          chartInstance.dispose();
        }
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chartData]);

    const handleDownload = () => {
      if (chartInstance) {
        const dataURL = chartInstance.getDataURL({
          type: 'png',
          pixelRatio: 2,
          backgroundColor: '#fff',
        });

        const link = document.createElement('a');
        link.href = dataURL;
        link.download = 'chart-image.png';
        link.click();
      }
    };

    React.useImperativeHandle(ref, () => ({
      downloadChart: handleDownload,
    }));
    return (
      <>
        <Stack mt={1} flexDirection='row' justifyContent='space-between'>
          <RadioGroup
            value={selectedChartGrouping}
            onChange={(event, value) => {
              setSelectedChartGrouping(value as string);
            }}
          >
            <Stack direction='row' spacing={1}>
              <FormControlLabel
                value={CHART_GROUPING_VALUE.COLUMN}
                sx={radioButtonLabelStyle}
                control={<Radio sx={radioButtonStyle} />}
                label={props.columnDimension?.label}
              />
              <FormControlLabel
                value={CHART_GROUPING_VALUE.ROW}
                sx={radioButtonLabelStyle}
                control={<Radio sx={radioButtonStyle} />}
                label={props.rowDimension?.label}
              />
            </Stack>
          </RadioGroup>
          <ButtonSortChart
            onChange={(value) => {
              setSelectedSortChart(value);
            }}
          />
        </Stack>
        <div ref={chartRef} style={{ width: '100%', height: 350, paddingBottom: 6 }} />
      </>
    );
  }
);
