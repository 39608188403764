import _ from 'lodash';
import { useRef, useEffect, useCallback } from 'react';
import { Autocomplete, Box, Checkbox, FormControlLabel, FormGroup, Modal, TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import Divider from '@mui/material/Divider';
import ButtonLoading from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import AbcIcon from '@mui/icons-material/Abc';
import HttpOutlinedIcon from '@mui/icons-material/HttpOutlined';
import AlternateEmailOutlinedIcon from '@mui/icons-material/AlternateEmailOutlined';
import DisplaySettingsOutlinedIcon from '@mui/icons-material/DisplaySettingsOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import ActivityIndicator from 'components/ActivityIndicatorComponent';
import { Formik, FormikProps } from 'formik';
import { AutoCompleteItem } from 'types/api/Common/AutoCompleteTypes';
import {
  useGetTableDefinitionValidationOption,
  useTableDefinitioDetails,
  useUpsertTableDefinitionValidationOption,
} from 'services/v1/SystemTenant/AROKMS/TableDefinitionService';
import { ModalTableDefinitionValidationSchema } from 'config/FormValidaton/SystemTenant/TableDefinitionModalValidationSchema';

const ModalContentStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  minHeight: 400,
  maxHeight: 600,
  borderRadius: 1,
  overflowY: 'auto',
  px: 3,
  py: 2,
};

const autocompleteStyles = {
  width: '55%',
  '& .MuiOutlinedInput-root': {
    padding: '1px 2px',
  },
  '& .MuiAutocomplete-popper': {
    fontSize: '10px',
  },
};
const textInputAutoCompleteStyles = {
  '& .MuiOutlinedInput': {
    padding: '1px 2px',
  },
};

const closeIconStyle = { color: '#98A2AE', cursor: 'pointer' };

export interface ModalCustomColumnOrderProps {
  open: boolean;
  onClose: () => void;
  tableDefinitionId: string | null;
}

const textInputStyles = {
  width: '55%',
  '& .MuiOutlinedInput': {
    padding: '1px 2px',
  },
};

export const VALIDATION_PATTERN_TYPE = {
  NONE: null,
  EMAIL: 'VALIDATION_PATTERN_EMAIL',
  URL: 'VALIDATION_PATTERN_URL',
  CUSTOM: 'VALIDATION_PATTERN_CUSTOM',
  TEXT_DESCRIPTION: 'VALIDATION_PATTERN_TEXT_DESCRIPTION',
};

const VALIDATION_PATTERN_OPTIONS = [
  {
    label: 'None',
    value: VALIDATION_PATTERN_TYPE.NONE,
    description: 'No validation pattern is applied to this field.',
    iconComponent: <AbcIcon />,
  },
  {
    label: 'Email',
    value: VALIDATION_PATTERN_TYPE.EMAIL,
    description: 'Validates that the value of the field is a valid email address.',
    iconComponent: <AlternateEmailOutlinedIcon />,
  },
  {
    label: 'Website URL',
    value: VALIDATION_PATTERN_TYPE.URL,
    description: 'Validates that the value of the field is a valid website URL.',
    iconComponent: <HttpOutlinedIcon />,
  },
  {
    label: 'Text Description',
    value: VALIDATION_PATTERN_TYPE.TEXT_DESCRIPTION,
    description: 'Validation rule for text description',
    iconComponent: <DescriptionOutlinedIcon />,
  },
  {
    label: 'Custom Pattern',
    value: VALIDATION_PATTERN_TYPE.CUSTOM,
    description: 'Customize your own validation pattern.',
    iconComponent: <DisplaySettingsOutlinedIcon />,
  },
];

interface AutoCompleteItemField extends AutoCompleteItem {
  description: string;
  iconComponent?: React.ReactNode;
}
interface FieldValidationValues {
  validationPattern: AutoCompleteItemField | null;
  minCharacter: number | null;
  maxCharacter: number | null;
  noSpace: boolean;
  noSpecialCharacter: boolean;
}

const initialValue: FieldValidationValues = {
  validationPattern: VALIDATION_PATTERN_OPTIONS[0] as AutoCompleteItemField,
  minCharacter: null,
  maxCharacter: 255,
  noSpace: false,
  noSpecialCharacter: false,
};

export function ModalFieldValidationOptions(props: ModalCustomColumnOrderProps) {
  const { onClose, open, tableDefinitionId } = props;

  const formikRef = useRef<FormikProps<FieldValidationValues>>(null);
  const { data: tableDefinitionValidation, isLoading: isLoadingValidation } =
    useGetTableDefinitionValidationOption(tableDefinitionId);

  const { mutate: upsert, isLoading: isSubmitting } = useUpsertTableDefinitionValidationOption(tableDefinitionId);

  const { data: tableDefinitionDetails, isLoading: isLoadingTableDefinitionDetails } = useTableDefinitioDetails({
    tableDefinitionId: tableDefinitionId || undefined,
    enabled: open,
  });

  const isLoading = isLoadingValidation || isLoadingTableDefinitionDetails;
  const handleClose = () => {
    onClose();
  };

  const handleOnSave = (values: FieldValidationValues) => {
    const validationMetaData = {
      validationPattern: values.validationPattern?.value,
      minCharacter: values.minCharacter ?? 0,
      maxCharacter: values.maxCharacter ?? 255,
      noSpace: values.noSpace,
      noSpecialCharacter: values.noSpecialCharacter,
    };

    const validationPattern = JSON.stringify(validationMetaData);
    upsert(
      {
        validationPattern,
      },
      {
        onSuccess: handleClose,
      }
    );
  };

  useEffect(() => {
    if (tableDefinitionValidation) {
      const validationMetaDataString = tableDefinitionValidation.data?.validationPattern;

      const validationMetaData = validationMetaDataString ? JSON.parse(validationMetaDataString) : null;

      const validationPattern = _.find(VALIDATION_PATTERN_OPTIONS, {
        value: validationMetaData?.validationPattern ?? null,
      });

      const values: FieldValidationValues = {
        validationPattern: (validationPattern as AutoCompleteItemField) ?? VALIDATION_PATTERN_OPTIONS[0],
        minCharacter: validationMetaData?.minCharacter ?? null,
        maxCharacter: validationMetaData?.maxCharacter,
        noSpace: validationMetaData?.noSpace ?? false,
        noSpecialCharacter: validationMetaData?.noSpecialCharacter ?? false,
      };

      formikRef.current?.setValues(values);
    }
  }, [tableDefinitionValidation, tableDefinitionId]);

  const getModalTitle = useCallback(() => {
    if (tableDefinitionDetails) {
      return `Validation Options for ${tableDefinitionDetails.data?.attribute.displayName}`;
    }

    return 'Validation Options';
  }, [tableDefinitionDetails]);

  return (
    <Modal open={open} onClose={handleClose}>
      <>
        <Stack direction='column' sx={ModalContentStyle} justifyContent='space-between'>
          <Formik
            validationSchema={ModalTableDefinitionValidationSchema}
            innerRef={formikRef}
            initialValues={initialValue}
            onSubmit={handleOnSave}
          >
            {({ values, handleChange, handleBlur, handleSubmit, setFieldValue, touched, errors }) => {
              console.log('values', {
                values,
                errors,
              });
              return (
                <>
                  {isLoading ? (
                    <Stack sx={{ height: 400 }}>
                      <ActivityIndicator />
                    </Stack>
                  ) : (
                    <>
                      <Stack direction='column'>
                        <Stack justifyContent='space-between' direction='row' alignItems='center' spacing={2}>
                          <Typography variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
                            {getModalTitle()}
                          </Typography>
                          <IconButton onClick={handleClose} component='label'>
                            <HighlightOffRoundedIcon sx={closeIconStyle} />
                          </IconButton>
                        </Stack>
                        <Divider sx={{ mb: 1 }} />

                        <Stack mt={2}>
                          <Stack direction='column' spacing={1} alignItems='flex-start'>
                            <Typography variant='input-label' fontWeight={800}>
                              Validation Pattern
                            </Typography>
                            <Typography variant='input-label-gray' fontSize={13}>
                              Choose a validation pattern for this field to ensure user input meets specified criteria.
                            </Typography>
                            <Autocomplete
                              id='highlights-demo'
                              // @ts-ignore
                              value={values.validationPattern}
                              sx={autocompleteStyles}
                              options={VALIDATION_PATTERN_OPTIONS}
                              clearIcon={null}
                              onChange={(event, newValue) => {
                                setFieldValue('validationPattern', newValue);
                              }}
                              renderOption={(props, option) => (
                                <Box component='li' sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                  <Stack direction='row' alignItems='center'>
                                    {option.iconComponent}
                                    <Stack ml={2}>
                                      <Typography variant='body2' fontWeight={700}>
                                        {option.label}
                                      </Typography>
                                      <Typography variant='input-label-gray' fontSize={13}>
                                        {option.description}
                                      </Typography>
                                    </Stack>
                                  </Stack>
                                </Box>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  sx={textInputAutoCompleteStyles}
                                  placeholder='Select validation pattern...'
                                />
                              )}
                            />
                          </Stack>
                          {values.validationPattern?.value === VALIDATION_PATTERN_TYPE.CUSTOM && (
                            <>
                              <Stack direction='column' spacing={1} alignItems='flex-start' mt={2}>
                                <Typography variant='input-label' fontWeight={800}>
                                  Min Character
                                </Typography>
                                <Typography variant='input-label-gray' fontSize={13}>
                                  Specify the minimum character count for this field; leave blank for no limit.
                                </Typography>
                                <TextField
                                  sx={textInputStyles}
                                  error={touched.minCharacter && !!errors.minCharacter}
                                  helperText={touched.minCharacter && errors.minCharacter}
                                  placeholder='Enter min character...'
                                  size='small'
                                  onChange={(e) => {
                                    const numberOnlyRegex = /^[0-9\b]+$/;
                                    if (e.target.value === '' || numberOnlyRegex.test(e.target.value)) {
                                      handleChange(e);
                                    }
                                  }}
                                  onBlur={handleBlur}
                                  value={values.minCharacter || ''}
                                  name='minCharacter'
                                />
                              </Stack>
                              <Stack direction='column' spacing={1} alignItems='flex-start' mt={2}>
                                <Typography variant='input-label' fontWeight={800}>
                                  Max Character
                                </Typography>
                                <Typography variant='input-label-gray' fontSize={13}>
                                  Specify the maximum character count for this field (max 255); leave blank for default
                                  limit (255).
                                </Typography>
                                <TextField
                                  sx={textInputStyles}
                                  error={touched.maxCharacter && !!errors.maxCharacter}
                                  helperText={touched.maxCharacter && errors.maxCharacter}
                                  placeholder='Enter max character...'
                                  onChange={(e) => {
                                    const numberOnlyRegex = /^[0-9\b]+$/;
                                    if (e.target.value === '' || numberOnlyRegex.test(e.target.value)) {
                                      handleChange(e);
                                    }
                                  }}
                                  onBlur={handleBlur}
                                  value={values.maxCharacter || ''}
                                  size='small'
                                  name='maxCharacter'
                                />
                              </Stack>
                              <Stack direction='column' spacing={1} alignItems='flex-start' mt={2}>
                                <Typography variant='input-label' fontWeight={800}>
                                  Other Validation
                                </Typography>
                                <Typography variant='input-label-gray' fontSize={13}>
                                  Select other validation options to apply to this field.
                                </Typography>
                                <FormGroup aria-label='position' sx={{}}>
                                  <FormControlLabel
                                    checked={values.noSpace}
                                    onChange={handleChange}
                                    name='noSpace'
                                    control={
                                      <Checkbox
                                        sx={{
                                          color: '#828C99',
                                        }}
                                      />
                                    }
                                    label={<Typography variant='input-label'>No Spaces Allowed</Typography>}
                                    labelPlacement='end'
                                  />
                                  <FormControlLabel
                                    checked={values.noSpecialCharacter}
                                    onChange={handleChange}
                                    name='noSpecialCharacter'
                                    control={
                                      <Checkbox
                                        sx={{
                                          color: '#828C99',
                                        }}
                                      />
                                    }
                                    label={<Typography variant='input-label'>No Special Characters Allowed</Typography>}
                                    labelPlacement='end'
                                  />
                                </FormGroup>
                              </Stack>
                            </>
                          )}
                        </Stack>
                      </Stack>
                      <Stack direction='row' justifyContent='space-between' alignItems='center'>
                        <Stack></Stack>
                        <Stack direction='row' justifyContent='flex-end' spacing={2} alignItems='center' sx={{ py: 1 }}>
                          <Button
                            variant='main-table-panel-border'
                            disabled={isLoading || isSubmitting}
                            onClick={handleClose}
                          >
                            Cancel
                          </Button>
                          <ButtonLoading
                            variant='main-table-panel'
                            disabled={isSubmitting || !ModalTableDefinitionValidationSchema.isValidSync(values)}
                            loading={isSubmitting}
                            onClick={() => handleSubmit()}
                          >
                            Save Validation
                          </ButtonLoading>
                        </Stack>
                      </Stack>
                    </>
                  )}
                </>
              );
            }}
          </Formik>
        </Stack>
      </>
    </Modal>
  );
}
