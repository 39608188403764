import React from 'react';

interface HighlightedTextProps {
  text: string;
  highlight: string;
}

export const HighlightedText = React.memo(({ text, highlight }: HighlightedTextProps) => {
  if (!highlight) return <>{text}</>;

  const keyword = highlight.toLowerCase();
  const value = text.toLowerCase();

  if (keyword && value.includes(keyword)) {
    const parts = text.split(new RegExp(`(${keyword})`, 'gi'));
    return (
      <>
        {parts.map((part, index) =>
          part.toLowerCase() === keyword ? (
            <span key={index} style={{ backgroundColor: 'yellow' }}>
              {part}
            </span>
          ) : (
            <span key={index}>{part}</span>
          )
        )}
      </>
    );
  }

  return <>{text}</>;
});
