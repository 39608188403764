import { Column } from 'types/api/Tenant/AROKMS/DisplayTableTypes';
import { RULES_CONSTANT } from 'constant/RuleConstant';
import {
  RuleNumberAddition,
  RuleNumberSubstraction,
  RuleNumberMultiplication,
  RuleNumberDivision,
} from './MUIColumnRuleImplementation/RuleDecimalImplementation';
import {
  RulePercentAddition,
  RulePercentInput,
  RulePercentSubstraction,
  RulePercentDivision,
  RulePercentMultiplication,
  RulePercentageRange,
} from './MUIColumnRuleImplementation/RulePercentageImplementation';
import {
  renderAssociateSubjectLimitedDropdown,
  renderBaseSubjectDropdown,
  renderCreatableDropdowns,
  renderDropdowns,
  renderKtreeSelectDropdown,
  renderReadOnlyGrid,
  renderPullAttribute,
} from './MUIColumnRuleImplementation/CommonImplementation';
import {
  extractLeftCharacter,
  extractMidCharachter,
  concatenanteCharacterWithSpace,
  RuleTextInputLimitLength,
  generalSubstringRule,
} from './MUIColumnRuleImplementation/RuleTextImplementation';
import {
  RuleInputDate,
  RuleWeekNum,
  RuleDateAddition,
  RuleDatePeriods,
} from './MUIColumnRuleImplementation/RuleDateImplementation';
import {
  RuleCurrencyInput,
  RuleCurrencyInputRange,
  RuleCurrencyAddition,
  RuleCurrencyDivision,
  RuleCurrencyMultipication,
  RuleCurrencySubstractuion,
} from './MUIColumnRuleImplementation/RuleCurrencyImplementation';
import {
  RuleIntegerAddition,
  RuleIntegerDivision,
  RuleIntegerInput,
  RuleIntegerMultipication,
  RuleIntegerSubstractuion,
  RuleIntegerInputRange,
} from './MUIColumnRuleImplementation/RuleIntegerImplementation';
import { RuleHexaCode } from './MUIColumnRuleImplementation/RuleColourImplementation';
import { RulesInputDateTime, RuleDateTimeAddition } from './MUIColumnRuleImplementation/RuleDateTimeImplementation';
import { renderAttachment } from './MUIColumnRuleImplementation/RuleMultimediaImplementation';
import { renderLocation } from './MUIColumnRuleImplementation/RuleGPSImplementation';
import { normalizeParameterNumber } from 'utils/String';
import { ENV_CONFIG } from 'config/env';
import { getGridDateOperators, getGridStringOperators } from '@mui/x-data-grid-pro';
import { customDropdownFilterOperator } from './customFilterFunction';

const RULE_FOR_FILTER_DATE = [
  RULES_CONSTANT.DATE.RULE_DATE_INPUT,
  RULES_CONSTANT.DATETIME.RULE_DATE_TIME_INPUT,
  RULES_CONSTANT.GENERATED_SYSTEM.RULE_GENERATED_SYSTEM_DATE,
  RULES_CONSTANT.GENERATED_SYSTEM.RULE_GENERATED_SYSTEM_DATE_TIME,
  RULES_CONSTANT.GENERATED_SYSTEM.RULE_SYSTEM_GENERATED_EVENT_TIMESTAMP,
];

const RULE_FOR_DROPDOWN_FILTER = [
  RULES_CONSTANT.ASSOCIATED_SUBJECT.RULE_ASSOCIATED_SUBJECT_LIMITED_DROPDOWN_LIST,
  RULES_CONSTANT.ASSOCIATED_SUBJECT.RULE_ASSOCIATED_SUBJECT_LIST,
  RULES_CONSTANT.BASE_SUBJECT.RULE_ATTRIBUTE_SUBJECT_BASE,
  RULES_CONSTANT.TEXT.RULE_SELECT_FROM_KTREE_TABLE,
  RULES_CONSTANT.TEXT.RULE_PULL_FROM_SUBJECT_ATTRIBUTE,
];

export function getFilterOperatorByRuleTypeCode(ruleTypeCode: string) {
  if (RULE_FOR_DROPDOWN_FILTER.includes(ruleTypeCode)) return [customDropdownFilterOperator];

  if (RULE_FOR_FILTER_DATE.includes(ruleTypeCode))
    return getGridDateOperators().filter(
      (operator) => !['onOrAfter', 'onOrBefore', 'isEmpty', 'isNotEmpty'].includes(operator.value)
    );
  return getGridStringOperators().filter((operator) => ['contains', 'equals'].includes(operator.value));
}

export function RulesImplementation(columnItem: Column, columns: string[]) {
  if (columnItem.readOnlyColumn) {
    return renderReadOnlyGrid(columnItem.ruleTypeCode);
  }

  switch (columnItem.ruleTypeCode) {
    // RULE FOR DATA Decimal
    case RULES_CONSTANT.NUMBER.RULE_DECIMAL_ADDITION:
      return RuleNumberAddition(columns);
    case RULES_CONSTANT.NUMBER.RULE_DECIMAL_SUBTRACTION:
      return RuleNumberSubstraction(columns);

    case RULES_CONSTANT.NUMBER.RULE_DECIMAL_DIVISION:
      return RuleNumberDivision(columns);

    case RULES_CONSTANT.NUMBER.RULE_DECIMAL_MULTIPLICATION:
      return RuleNumberMultiplication(columns);

    // RULE FOR DATA TYPE TEXT

    case RULES_CONSTANT.TEXT.RULE_ATTRIBUTE_LEFT:
      const column = columns[0];
      const totalChar = Number(columns[1]);
      return extractLeftCharacter(column, totalChar);

    case RULES_CONSTANT.TEXT.RULE_ATTRIBUTE_MID:
      const column1 = columns[0];
      const startChar = Number(columns[1]);
      const endChar = Number(columns[2]);
      return extractMidCharachter(column1, startChar, endChar);
    case RULES_CONSTANT.TEXT.RULE_TEXT_GENERAL_SUBSTRING:
    case RULES_CONSTANT.TEXT.RULE_TEXT_GENERAL_SUBSTRING_STATIC_TABLE:
      const generalSubstringSourceColumn = columns[0];
      const generalSubstringCharToFind = columns[1] || '';

      const generalSubstringStarChar = normalizeParameterNumber(columns?.[2]);
      const generalSubstringNumberToAddFind = columns?.[3] !== '' ? Number(columns[3]) : undefined;
      const generalSubstringEndChar = normalizeParameterNumber(columns?.[4]);

      return generalSubstringRule(
        generalSubstringSourceColumn,
        generalSubstringCharToFind,
        generalSubstringStarChar,
        generalSubstringNumberToAddFind,
        generalSubstringEndChar
      );

    case RULES_CONSTANT.TEXT.RULE_CONCATENATE_ATTRIBUTES:
      return concatenanteCharacterWithSpace(columnItem, columns);

    case RULES_CONSTANT.TEXT.RULE_CHAR_LIMIT:
      const maxLength = Number(columns?.[0]) || undefined;

      return RuleTextInputLimitLength(maxLength, !columnItem.allowEmpty);

    // RULE FOR DATA TYPE DATE

    case RULES_CONSTANT.DATE.RULE_DATE_INPUT:
    case RULES_CONSTANT.GENERATED_SYSTEM.RULE_SYSTEM_GENERATED_EVENT_TIMESTAMP:
      return RuleInputDate(ENV_CONFIG.config.DATE_FORMAT, !columnItem.allowEmpty);

    case RULES_CONSTANT.DATE.RULE_DATE_WEEKNUM:
      const col = columns[0];
      return RuleWeekNum(col);

    case RULES_CONSTANT.DATE.RULE_DATE_INPUT_NUM_TYPE:
      const dateInputCol = columns[0];
      const dateInputType = columns[1];
      const dateInputNum = Number(columns[2]);
      return RuleDateAddition(dateInputCol, dateInputType, dateInputNum);

    case RULES_CONSTANT.DATE.RULE_DATE_PERIODS:
      const datePeriodsCol1 = columns[0];
      const datePeriodsCol2 = columns[1];
      const datePeriodsType = columns[2];
      const ddecimalLimit = Number(columns[3]) || 0;
      return RuleDatePeriods(datePeriodsCol1, datePeriodsCol2, datePeriodsType, ddecimalLimit);

    // RULE FOR DATA TYPE PERCENTAGE
    case RULES_CONSTANT.PERCENTAGE.RULE_PERCENTAGE_INPUT:
      return RulePercentInput(!columnItem.allowEmpty);

    case RULES_CONSTANT.PERCENTAGE.RULE_PERCENTAGE_MULTIPLICATION:
      return RulePercentMultiplication(columns);

    case RULES_CONSTANT.PERCENTAGE.RULE_PERCENTAGE_ADDITION:
      return RulePercentAddition(columns);

    case RULES_CONSTANT.PERCENTAGE.RULE_PERCENTAGE:
      const minPercentageValue = Number(columns?.[0]) || undefined;
      const maxPercentageValue = Number(columns?.[1]) || undefined;
      return RulePercentageRange(minPercentageValue, maxPercentageValue);

    case RULES_CONSTANT.PERCENTAGE.RULE_PERCENTAGE_SUBTRACTION:
      return RulePercentSubstraction(columns);

    case RULES_CONSTANT.PERCENTAGE.RULE_PERCENTAGE_DIVISION:
      return RulePercentDivision(columns);
    // RULE FOR DATA TYPE CURRENCY
    case RULES_CONSTANT.CURRENCY.RULE_CURRENCY:
      const prefix = columns[0];
      const suffix = columns[1];
      return RuleCurrencyInput(prefix, suffix, !columnItem.allowEmpty);

    case RULES_CONSTANT.CURRENCY.RULE_CURRENCY_RANGE:
      const prefixRange = columns[0];
      const suffixRange = columns[1];
      const minCurrencyValue = Number(columns?.[2]);
      const maxCurrencyValue = Number(columns?.[3]) || undefined;

      return RuleCurrencyInputRange(
        prefixRange,
        suffixRange,
        minCurrencyValue,
        maxCurrencyValue,
        !columnItem.allowEmpty
      );

    case RULES_CONSTANT.CURRENCY.RULE_CURRENCY_ADDITION:
      const currencyAdditionCol1 = columns[0];
      const currencyAdditionCol2 = columns[1];
      const currencyAdditionPrefix = columns?.[2] || '';
      const currencyAdditionSuffix = columns?.[3] || '';
      return RuleCurrencyAddition(
        [currencyAdditionCol1, currencyAdditionCol2],
        currencyAdditionPrefix,
        currencyAdditionSuffix
      );

    case RULES_CONSTANT.CURRENCY.RULE_CURRENCY_SUBSTRACTION:
      const currencySubstractionCol1 = columns[0];
      const currencySubstractionCol2 = columns[1];
      const currencySubstractionPrefix = columns?.[2] || '';
      const currencySubstractionSuffix = columns?.[3] || '';
      return RuleCurrencySubstractuion(
        [currencySubstractionCol1, currencySubstractionCol2],
        currencySubstractionPrefix,
        currencySubstractionSuffix
      );

    case RULES_CONSTANT.CURRENCY.RULE_CURRENCY_MULTIPLICATION:
      const currencyMultiplicationCol1 = columns[0];
      const currencyMultiplicationCol2 = columns[1];
      const currencyMultiplicationPrefix = columns?.[2] || '';
      const currencyMultiplicationSuffix = columns?.[3] || '';
      return RuleCurrencyMultipication(
        [currencyMultiplicationCol1, currencyMultiplicationCol2],
        currencyMultiplicationPrefix,
        currencyMultiplicationSuffix
      );

    case RULES_CONSTANT.CURRENCY.RULE_CURRENCY_DIVISION:
      const currencyDivisionCol1 = columns[0];
      const currencyDivisionCol2 = columns[1];
      const currencyDivisionPrefix = columns?.[2] || '';
      const currencyDivisionSuffix = columns?.[3] || '';
      return RuleCurrencyDivision(
        [currencyDivisionCol1, currencyDivisionCol2],
        currencyDivisionPrefix,
        currencyDivisionSuffix
      );

    case RULES_CONSTANT.INTEGER.RULE_INTEGER:
      return RuleIntegerInput(!columnItem.allowEmpty);

    case RULES_CONSTANT.INTEGER.RULE_INTEGER_ADDITION:
      return RuleIntegerAddition(columns);

    case RULES_CONSTANT.INTEGER.RULE_INTEGER_SUBSTRACTION:
      return RuleIntegerSubstractuion(columns);

    case RULES_CONSTANT.INTEGER.RULE_INTEGER_MULTIPLICATION:
      return RuleIntegerMultipication(columns);

    case RULES_CONSTANT.INTEGER.RULE_INTEGER_DIVISION:
      return RuleIntegerDivision(columns);

    case RULES_CONSTANT.INTEGER.RULE_INTEGER_RANGE:
      const minIntValue = Number(columns?.[0]) || undefined;
      const maxIntValue = Number(columns?.[1]) || undefined;
      return RuleIntegerInputRange(minIntValue, maxIntValue, !columnItem.allowEmpty);

    // Hexa Code
    case RULES_CONSTANT.COLOUR.RULE_COLOUR_HEXA:
      return RuleHexaCode(!columnItem.allowEmpty);

    // Date Time
    case RULES_CONSTANT.DATETIME.RULE_DATE_TIME_INPUT:
      return RulesInputDateTime();
    case RULES_CONSTANT.DATETIME.RULE_DATE_TIME:
      const dateTimeInputCol = columns[0];
      const dateTimeInputType = columns[1];
      const dateTimeInputNum = Number(columns[2]);
      return RuleDateTimeAddition(dateTimeInputCol, dateTimeInputType, dateTimeInputNum);

    // Rule for Associate Subject
    case RULES_CONSTANT.TEXT.RULE_PULL_FROM_SUBJECT_ATTRIBUTE:
    case RULES_CONSTANT.SYSTEM_OPTIONS.RULE_SYSTEM_OPTIONS_DEPRECIATION_TYPE:
    case RULES_CONSTANT.TEXT.RULE_PULL_FROM_STATIC_TABLE:
      return renderDropdowns(columnItem.dropdownOptions, !columnItem.allowEmpty);

    case RULES_CONSTANT.ASSOCIATED_SUBJECT.RULE_ASSOCIATED_SUBJECT_LIMITED_DROPDOWN_LIST:
      const referenceAssosicateSubject = columns[0];
      return renderAssociateSubjectLimitedDropdown(
        columnItem.dropdownOptions,
        referenceAssosicateSubject,
        !columnItem.allowEmpty
      );

    case RULES_CONSTANT.ASSOCIATED_SUBJECT.RULE_ASSOCIATED_SUBJECT_LIST:
      return renderCreatableDropdowns(columnItem.dropdownOptions, columnItem.colSubjectId, !columnItem.allowEmpty);

    case RULES_CONSTANT.BASE_SUBJECT.RULE_ATTRIBUTE_SUBJECT_BASE:
      return renderBaseSubjectDropdown(
        columnItem.dropdownOptions,
        columnItem.colSubjectId,
        columnItem.allDropdownOptions,
        true
      );

    case RULES_CONSTANT.TEXT.RULE_SELECT_FROM_KTREE_TABLE:
      return renderKtreeSelectDropdown(columnItem.ktreeOptions, columnItem.colSubjectId, !columnItem.allowEmpty);

    case RULES_CONSTANT.MULTIMEDIA.RULE_MULTIMEDIA:
      return renderAttachment();

    case RULES_CONSTANT.GPS.RULE_GPS:
      return renderLocation();

    case RULES_CONSTANT.TEXT.RULE_PULL_ATTRIBUTE: {
      if (columnItem.renderAsRuleTypeCode === RULES_CONSTANT.GPS.RULE_GPS) return renderLocation();
      if (columnItem.renderAsRuleTypeCode === RULES_CONSTANT.MULTIMEDIA.RULE_MULTIMEDIA) return renderAttachment();
      if (columnItem.renderAsRuleTypeCode === RULES_CONSTANT.COLOUR.RULE_COLOUR_HEXA) return RuleHexaCode();
      return renderPullAttribute(columnItem.renderAsRuleTypeCode, columnItem.renderAsRuleTypeParams);
    }

    case RULES_CONSTANT.GENERATED_SYSTEM.RULE_GENERATED_SYSTEM_DATE:
    case RULES_CONSTANT.GENERATED_SYSTEM.RULE_GENERATED_SYSTEM_DATE_TIME:
    case RULES_CONSTANT.GENERATED_SYSTEM.RULE_GENERATED_SYSTEM_MODIFIER:
    case RULES_CONSTANT.INTEGER.RULE_INTEGER_PULL_NUMERIC:
    case RULES_CONSTANT.TEXT.RULE_PULL_ATTRIBUTE_STATIC_TABLE:
    case RULES_CONSTANT.PERCENTAGE.RULE_CALCULATE_PERCENTAGE:
    case RULES_CONSTANT.INTEGER.RULE_INTEGER_COUNT_ROW:
    case RULES_CONSTANT.INTEGER.RULE_INTEGER_MULTIPLICATION_WITH_PERCENTAGE:
    case RULES_CONSTANT.TEXT.RULE_TEXT_IF_CONDITIONAL:
    case RULES_CONSTANT.TEXT.RULE_INPUT:
    case RULES_CONSTANT.FUNCTION.RULE_INTEGER_FUNCTION_DEPRICIATION:
      return renderReadOnlyGrid(columnItem.ruleTypeCode);

    default:
      return {};
  }
}
