import { NAVIGATION_COMPONENT_TYPE } from 'constant/NavigationConstant';
import { PATH_CONSTANT } from 'constant/PathConstant';
import { MyViewWorkFlowItem, QuickAccessItem } from 'types/api/Tenant/ConfigurationTypes';
import qs from 'query-string';

export enum WorkFlowDrillNavigationDirection {
  UP = 'UP',
  DOWN = 'DOWN',
}

export const getWorkFlowDrillView = (
  currentMyViewConfig: QuickAccessItem | null,
  currentWorkFlowId: string | null,
  direction: WorkFlowDrillNavigationDirection
): MyViewWorkFlowItem | null => {
  console.log({
    currentMyViewConfig,
    currentWorkFlowId,
  });
  if (currentMyViewConfig && !currentWorkFlowId) {
    return currentMyViewConfig.drillDownWorkflows[0];
  }

  if (currentMyViewConfig && currentWorkFlowId) {
    if (direction === WorkFlowDrillNavigationDirection.DOWN) {
      const currentIndex = currentMyViewConfig.drillDownWorkflows.findIndex((item) => item.id === currentWorkFlowId);

      if (currentIndex === -1) return null;
      return currentMyViewConfig.drillDownWorkflows[currentIndex + 1];
    }
    if (direction === WorkFlowDrillNavigationDirection.UP) {
      const currentIndex = currentMyViewConfig.drillDownWorkflows.findIndex((item) => item.id === currentWorkFlowId);
      if (currentIndex === -1) return null;
      return currentMyViewConfig.drillDownWorkflows[currentIndex - 1];
    }
  }
  return null;
};

export const getNextDrillDownURL = (
  currentMyViewConfig: QuickAccessItem | null,
  nextDrillDownWorkFlow: MyViewWorkFlowItem | null,
  additionalParams: { [key: string]: string | number | boolean | null | undefined } | undefined = {}
): string | null => {
  if (!currentMyViewConfig || !nextDrillDownWorkFlow) return null;

  switch (nextDrillDownWorkFlow?.componentType) {
    case NAVIGATION_COMPONENT_TYPE.CUBE:
      const cubeURL = `${PATH_CONSTANT.TENANT.CUBE.CUBE_DIMENSIONAL_VIEW.replace(
        ':cubeId',
        nextDrillDownWorkFlow.componentId
      )}?${qs.stringify({
        myViewId: currentMyViewConfig.viewId,
        workflowId: nextDrillDownWorkFlow.id,
        ...additionalParams,
      })}`;
      return cubeURL;
    case NAVIGATION_COMPONENT_TYPE.INSIGHT_VIEW:
      const insightViewConfig = currentMyViewConfig;
      const insightViewURL = `${PATH_CONSTANT.TENANT.CUBE.INSIGHT.replace(':cubeId', insightViewConfig.cubeId).replace(
        ':insightId',
        nextDrillDownWorkFlow.componentId
      )}?${qs.stringify({
        memberId: insightViewConfig.memberId,
        defaultXDimension: insightViewConfig.defaultDimensionFilter?.defaultXDimension,
        defaultYDimension: insightViewConfig.defaultDimensionFilter?.defaultYDimension,
        defaultFilterMember: insightViewConfig.defaultDimensionFilter?.defaultFilterMember,
        myViewId: insightViewConfig.viewId,
        workFlowId: insightViewConfig.drillDownWorkflows[0]?.id,
        ...additionalParams,
      })}`;
      return insightViewURL;
    default:
      return null;
  }
};
