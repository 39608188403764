import { useEffect, useImperativeHandle, useMemo, useRef, useState, forwardRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import qs from 'query-string';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Formik, FormikProps } from 'formik';
import { DropdownItem } from 'types/api/SystemTenant/AROKMS/TableDefinitionTypes';
import { ReactComponent as CubeDown } from 'assets/icons/icon-cube-down.svg';
import { flattenTreeData, renderOptionsKtree, getListBoxPropsAutoScrolItem } from 'utils/Object';
import { InsightViewDimensionalOptionValue } from 'types/api/Tenant/AROCube/CubeInisghtViewTypes';
import { AutoCompleteItem } from 'types/api/Common/AutoCompleteTypes';
import { ControllerNavigation, ControllerNavigationDirection } from './ControllerNavigation';
import { useGetCubeMemberDropdownByCube } from 'services/v1/Tenant/AROCube/CubeDataEntryService';
import { getDashboardListData, getDashboardMockData } from '.';
import { PATH_CONSTANT } from 'constant/PathConstant';
import { PAGE_REF_NAVIGATION } from 'utils/Animation';

export interface OptionItem {
  value: string | number;
  label: string;
}

const autoCompleteStyle = {
  width: 230,
  '& .MuiAutocomplete-popper': {
    backgroundColor: 'red !imoprtant',
    fontSize: '10px',
  },
};

const textInputStyle = {};

interface DashboardPanelProps {
  disabled?: boolean;
  cubeDefinitionId?: string;
  dashboardId?: string;
  ref?: any;
  onOptionChange?: (option: InsightViewDimensionalOptionValue) => void;
  loading?: boolean;
  initialValues: InsightViewDimensionalOptionValue;

  setIsLoadingOptionsData?: (isLoading: boolean) => void;
}

const formikInitialValues: InsightViewDimensionalOptionValue = {
  member: null,
  xDimensionType: null,
  yDimensionType: null,
};

export const DashboardPanel = forwardRef((props: DashboardPanelProps, ref) => {
  const { disabled: disableProps, initialValues, onOptionChange, setIsLoadingOptionsData, dashboardId } = props;

  const mockData = getDashboardMockData(dashboardId);

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const memberId = searchParams.get('memberId');

  const [optionValue, setOptionValue] = useState<InsightViewDimensionalOptionValue>({
    xDimensionType: null,
    yDimensionType: null,
    member: null,
    showMemberOnly: false,
  });
  const formikRef = useRef<FormikProps<InsightViewDimensionalOptionValue>>(null);

  const { data: memberDropdown, isLoading: isFetchingMember } = useGetCubeMemberDropdownByCube(
    '0E8DDF3700FE4565BE569BC76B9E2F64'
  );

  const nextDashboard = useMemo(() => {
    const data = getDashboardListData();
    const currentIndex = data.findIndex((item) => item.name === dashboardId);
    if (currentIndex === data.length - 1) {
      return data[0];
    }
    if (currentIndex === -1) return null;
    return data[currentIndex + 1];
  }, [dashboardId]);

  const previouseDashboard = useMemo(() => {
    const data = getDashboardListData();
    const currentIndex = data.findIndex((item) => item.name === dashboardId);
    if (currentIndex === 0) {
      return data[data.length - 1];
    }
    if (currentIndex === -1) return null;
    return data[currentIndex - 1];
  }, [dashboardId]);

  const navigationControllers = useMemo(() => {
    const navigations = [ControllerNavigationDirection.DOWN];
    if (previouseDashboard) {
      navigations.push(ControllerNavigationDirection.LEFT);
    }
    if (nextDashboard) {
      navigations.push(ControllerNavigationDirection.RIGHT);
    }

    return navigations;
  }, [previouseDashboard, nextDashboard]);

  const navigationControllerTooltips = useMemo(() => {
    return {
      [ControllerNavigationDirection.UP]: 'Show Insight',
      [ControllerNavigationDirection.DOWN]: `Show ${mockData?.insightName}`,
      [ControllerNavigationDirection.LEFT]: `Show ${previouseDashboard?.name}`,
      [ControllerNavigationDirection.RIGHT]: `Show ${nextDashboard?.name}`,
    };
  }, [previouseDashboard, nextDashboard, mockData?.insightName]);

  const disabled = disableProps;

  const handleOnNavigate = (direction: ControllerNavigationDirection) => {
    if (!mockData || !nextDashboard || !previouseDashboard) return;
    switch (direction) {
      case ControllerNavigationDirection.DOWN: {
        const insightURL = `${PATH_CONSTANT.TENANT.CUBE.INSIGHT.replace(':cubeId', mockData.cubeId).replace(
          ':insightId',
          mockData?.insightId
        )}?${qs.stringify({
          memberId,
          fromDashboardId: dashboardId,
          fromDashboardName: mockData.name,
          ref: PAGE_REF_NAVIGATION.BOTTOM,
        })}`;
        navigate(insightURL);
        break;
      }
      case ControllerNavigationDirection.RIGHT: {
        const dashboardURL = `${PATH_CONSTANT.TENANT.DASHBOARD.DASHBOARD.replace(
          ':dashboardId',
          nextDashboard.name
        )}?${qs.stringify({
          ref: PAGE_REF_NAVIGATION.RIGHT,
        })}`;
        navigate(dashboardURL);
        break;
      }

      case ControllerNavigationDirection.LEFT: {
        const dashboardURL = `${PATH_CONSTANT.TENANT.DASHBOARD.DASHBOARD.replace(
          ':dashboardId',
          previouseDashboard.name
        )}?${qs.stringify({
          ref: PAGE_REF_NAVIGATION.LEFT,
        })}`;
        navigate(dashboardURL);
        break;
      }

      default:
        break;
    }
  };

  const memberOptions = useMemo(() => {
    if (memberDropdown) {
      return flattenTreeData(memberDropdown);
    }
    return [];
  }, [memberDropdown]);

  const lowestMemberLevel = useMemo(() => {
    // @ts-ignore
    return memberOptions.reduce((acc, item) => {
      if (item.level > acc) {
        return item.level;
      } else {
        return acc;
      }
    }, 0);
  }, [memberOptions]);

  useEffect(() => {
    onOptionChange && onOptionChange(optionValue);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionValue]);

  useEffect(() => {
    if (initialValues) {
      setOptionValue((prevState) => ({
        ...prevState,
        xDimensionType: initialValues.xDimensionType,
        yDimensionType: initialValues.yDimensionType,
      }));
    }
  }, [initialValues]);

  // auto select member
  useEffect(() => {
    if (memberOptions && memberOptions?.length > 0) {
      const member = memberOptions?.find((item: DropdownItem) => item.value === memberId) ?? memberOptions?.[0];
      const selectedMember: DropdownItem = {
        id: member.value,
        value: member?.value?.toString() || '',
        label: member.label,
      };
      setOptionValue((prev) => ({ ...prev, member: selectedMember }));
    }
  }, [memberOptions, memberId]);

  useImperativeHandle(ref, () => ({
    isLoadingOptionsData: isFetchingMember,
  }));

  useEffect(() => {
    setIsLoadingOptionsData && setIsLoadingOptionsData(isFetchingMember);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchingMember]);

  return (
    <Stack direction='column' sx={{ backgroundColor: '#fff', py: 1 }}>
      <Formik onSubmit={() => {}} innerRef={formikRef} initialValues={formikInitialValues}>
        {({ errors, touched, handleBlur, handleChange, values, setFieldValue, handleSubmit }) => {
          return (
            <>
              <Stack
                direction='row'
                alignItems='center'
                sx={{ bgcolor: '#FBFBFB', py: 1, px: 2, border: '1px solid #E3EBF6' }}
              >
                <Stack direction='row' justifyContent='space-between' width='100%'>
                  <Stack direction='row' spacing={5} alignItems='center'>
                    <Autocomplete
                      // @ts-ignore
                      onChange={(event, value) => {
                        setOptionValue((prev) => ({ ...prev, member: value }));
                      }}
                      clearIcon={null}
                      sx={{
                        width: 300,
                      }}
                      size='small'
                      value={optionValue.member}
                      disabled={disabled || isFetchingMember}
                      getOptionLabel={(option: AutoCompleteItem) => option.label}
                      options={memberOptions || []}
                      renderOption={(props, option, state) =>
                        renderOptionsKtree(props, option, state, lowestMemberLevel)
                      }
                      ListboxProps={{
                        ...getListBoxPropsAutoScrolItem(memberOptions, optionValue.member),
                      }}
                      renderInput={(params) => (
                        <Stack direction='row' justifyContent='space-between' display='flex' alignItems='center'>
                          <TextField
                            {...params}
                            sx={textInputStyle}
                            label={isFetchingMember ? 'Loading...' : 'Member'}
                            onBlur={handleBlur}
                            name='member'
                          />
                        </Stack>
                      )}
                    />
                    <Autocomplete
                      // @ts-ignore
                      onChange={(event, value) => {
                        setOptionValue((prev) => ({
                          ...prev,
                          xDimensionType: value,
                        }));
                      }}
                      clearIcon={null}
                      size='small'
                      value={optionValue.xDimensionType}
                      disabled={disabled}
                      getOptionLabel={(option: DropdownItem) => option.label}
                      options={[
                        {
                          label: '17-03-2024',
                          type: 'xxs',
                          value: '17-03-2024',
                        },
                      ]}
                      renderOption={(props, option) => (
                        <Box component='li' sx={{ '& > span': { fontSize: '14px', mr: 1, flexShrink: 0 } }} {...props}>
                          <span>{option.label}</span>
                        </Box>
                      )}
                      sx={autoCompleteStyle}
                      renderInput={(params) => (
                        <Stack direction='row' justifyContent='space-between' display='flex' alignItems='center'>
                          <TextField
                            {...params}
                            sx={textInputStyle}
                            onBlur={handleBlur}
                            label='Week Ending Sunday'
                            InputProps={{
                              ...params.InputProps,
                              startAdornment: (
                                <InputAdornment position='start'>
                                  <CubeDown />
                                </InputAdornment>
                              ),
                            }}
                            name='xDimensionType'
                          />
                        </Stack>
                      )}
                    />
                  </Stack>
                </Stack>
                <ControllerNavigation
                  enableNavigations={navigationControllers}
                  onNavigate={handleOnNavigate}
                  tooltips={navigationControllerTooltips}
                />
              </Stack>
              <Stack direction='row' alignItems='center' sx={{ pt: 1, px: 2 }} width='100%'>
                <Stack direction='row' gap={3} width='100%' alignItems='center'></Stack>
              </Stack>
            </>
          );
        }}
      </Formik>
    </Stack>
  );
});
