import { PATH_CONSTANT } from './PathConstant';

export const ROLES = {
  ADMIN: 'Admin',
  BUILDER: 'Builder',
  MANAGER: 'Manager',
  PACKAGER: 'Packager',
  TESTER: 'Tester',
  SUPPORT: 'Support',
  TRAINER: 'Trainer',
};

export const DEFAULT_TENANT_LOGIN_REDIRECT_URL = PATH_CONSTANT.TENANT.HOME;

export const DEFAUL_LOGIN_REDIRECT_URL = {
  [ROLES.ADMIN]: PATH_CONSTANT.TENANT_MASTER.HOME,
  [ROLES.BUILDER]: PATH_CONSTANT.SYSTEM.KMS.DATA_BUILDER.SUBJECT,
  [ROLES.MANAGER]: DEFAULT_TENANT_LOGIN_REDIRECT_URL,
  [ROLES.TESTER]: DEFAULT_TENANT_LOGIN_REDIRECT_URL,
  [ROLES.PACKAGER]: DEFAULT_TENANT_LOGIN_REDIRECT_URL,
  [ROLES.SUPPORT]: DEFAULT_TENANT_LOGIN_REDIRECT_URL,
  [ROLES.TRAINER]: DEFAULT_TENANT_LOGIN_REDIRECT_URL,
};

export const ACCESS_SCOPES = {
  CREATE: 'create',
  VIEW: 'view',
  UPDATE: 'update',
  DELETE: 'delete',
};

export const PERMISSIONS = {
  [ROLES.ADMIN]: [ACCESS_SCOPES.CREATE, ACCESS_SCOPES.VIEW, ACCESS_SCOPES.UPDATE, ACCESS_SCOPES.DELETE],
  [ROLES.BUILDER]: [ACCESS_SCOPES.VIEW],
  [ROLES.MANAGER]: [ACCESS_SCOPES.CREATE],
  [ROLES.TESTER]: [ACCESS_SCOPES.CREATE],
};
