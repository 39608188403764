import { useGridApiContext, GridRenderEditCellParams } from '@mui/x-data-grid';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';

import { dayjs } from 'utils/DateUtils';
import { ENV_CONFIG } from 'config/env';
import { TextField } from '@mui/material';
/**
 * `date` column
 */
/**
 * `dateTime` column
 */

const textInputStyles = {
  px: 1,
  '& .MuiOutlinedInput': {
    padding: '1px 2px',
  },
  '& .MuiOutlinedInput-input': {
    padding: '2px 5px',
  },
};

function getDatePickerInputFormat(type?: string) {
  if (type === 'dateTime') {
    return ENV_CONFIG.config.DATE_TIME_FORMAT;
  }
  return ENV_CONFIG.config.DATE_FORMAT;
}

interface CustomGridEditDateCellParams extends GridRenderEditCellParams<Date | string | null> {
  isRequired?: boolean;
}

export function GridEditDateCell({ id, field, value, colDef, isRequired }: CustomGridEditDateCellParams) {
  const apiRef = useGridApiContext();

  const errorMessage = isRequired && !value ? 'This field is required' : '';

  const Component = colDef.type === 'dateTime' ? DateTimePicker : DatePicker;

  const inputFormat = getDatePickerInputFormat(colDef.type);

  const handleChange = (newValue: unknown) => {
    apiRef.current.setEditCellValue({ id, field, value: newValue });
  };

  return (
    <LocalizationProvider dateFormats={{}} dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
      <Component
        value={value}
        inputFormat={inputFormat}
        renderInput={(params) => (
          <TextField {...params} sx={textInputStyles} error={!!errorMessage} helperText={errorMessage} />
        )}
        onChange={handleChange}
      />
    </LocalizationProvider>
  );
}
