// @ts-nocheck
import ButtonLoading from '@mui/lab/LoadingButton';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import { useMemo, useState, useRef } from 'react';
import { useSelector } from 'react-redux';

import { Dayjs } from 'dayjs';
import { Box, Divider, Grid, Stack, Snackbar, Typography } from '@mui/material';
import Alert, { AlertProps } from '@mui/material/Alert';
import Button from '@mui/material/Button';
import {
  useDisplayFormInput,
  useGetDataInputPullAttributeMapping,
} from 'services/v1/Tenant/AROKMS/DisplayTableService';
import { Formik, FormikProps } from 'formik';
import { RootState } from 'store';
import { generateYupSchema } from 'utils/SchemaGenerator';
import { generateFormInput } from 'pages/Tenant/AROKMS/DataInput/components/FormGenerator';
import ActivityIndicator from 'components/ActivityIndicatorComponent';
import { serializeDataInputPayload } from 'pages/Tenant/AROKMS/DataInput/utils/serialiazePayload';
import { RULES_CONSTANT } from 'constant/RuleConstant';
import { columnOrderForDedicatedView } from 'pages/Tenant/AROKMS/DataInput/utils/columnOrder';
import { TenantHomeDashboardNavigationBar } from 'components/LayoutComponent/VerticalLayout/HeaderBar';
import { ButtonBackToTop } from 'components/ButtonComponent/ButtonBackToTop';
import { PATH_CONSTANT } from 'constant/PathConstant';
import { getErrorMessage } from 'utils/Error';
import { useUpsertEventTable } from 'services/v1/Tenant/AROEvent/EventTableService';
import { useGetSubjectDetails } from 'services/v1/SystemTenant/AROKMS/SubjectService';

export default function InsertEventData() {
  const authReducer = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();
  const formikRef = useRef<FormikProps<any>>(null);
  const { subjectId } = useParams<{ subjectId: string }>();
  const [displayKey, setDisplayKey] = useState<number>(0);
  const { data: eventDetails } = useGetSubjectDetails(subjectId);
  const { mutate: insertDataEvent, isLoading: isInserting } = useUpsertEventTable({ subjectId });
  const { data: pullAttributeDataMapping } = useGetDataInputPullAttributeMapping(subjectId);
  const { data: formDefinitionsData, isLoading } = useDisplayFormInput({ subjectId });
  const [snackbar, setSnackbar] = useState<Pick<AlertProps, 'children' | 'severity'> | null>(null);

  const handleCloseSnackbar = () => setSnackbar(null);

  const handleOnSubmitForm = (data: { [key: string]: any }, redirectPath: string) => {
    // map data with ruleTypeCode from formDefinitionsData
    // then send to API
    const dataWithRuleTypeCode: {
      [key: string]: { ruleTypeCode: string; value: string | number | object | Dayjs };
    } = _.mapValues(data, (value, key) => {
      if (formDefinitionsData) {
        const fieldItem = _.find(formDefinitionsData.fields, { name: key });

        return {
          value,
          ruleTypeCode: fieldItem.ruleTypeCode,
          allowEmpty: fieldItem.allowEmpty,
          dropdownOptions: fieldItem.options,
        };
      }
    });

    const payloadData = serializeDataInputPayload(dataWithRuleTypeCode, authReducer);

    const tableDefinitionFieldId = Object.keys(payloadData).reduce((acc, key) => {
      const fieldItem = _.find(formDefinitionsData.fields, { name: key });
      return { ...acc, [key]: fieldItem.tableDefinitionId };
    }, {});

    insertDataEvent(
      { data: payloadData, tableDefinitionId: tableDefinitionFieldId },
      {
        onSuccess: () => {
          toast.success('Data submitted successfully!');
          formikRef.current?.resetForm();
          setDisplayKey(displayKey + 1);
          handleOnCancelInput();
        },
        onError: (err) => {
          toast.error(getErrorMessage(err));
        },
      }
    );
  };

  const handleOnCancelInput = () => {
    navigate(PATH_CONSTANT.TENANT.EVENT.EVENT.replace(':subjectId', subjectId));
  };

  const yupSchema = useMemo(() => {
    if (!formDefinitionsData) {
      return {};
    }
    return formDefinitionsData.fields.reduce(generateYupSchema, {});
  }, [formDefinitionsData]);

  const validateSchema = useMemo(() => {
    return yupSchema ? yup.object().shape(yupSchema) : {};
  }, [yupSchema]);

  // Seperate fields into 2 columns
  const formFields = useMemo(() => {
    if (!formDefinitionsData) {
      return {
        input: [],
        multimedia: [],
      };
    }

    const ruleToSeperate = [
      RULES_CONSTANT.MULTIMEDIA.RULE_MULTIMEDIA,
      RULES_CONSTANT.GPS.RULE_GPS,
      RULES_CONSTANT.ASSOCIATED_SUBJECT.RULE_ASSOCIATED_SUBJECT_LIST,
      RULES_CONSTANT.ASSOCIATED_SUBJECT.RULE_ASSOCIATED_SUBJECT_LIMITED_DROPDOWN_LIST,
      RULES_CONSTANT.TEXT.RULE_SELECT_FROM_KTREE_TABLE,
      RULES_CONSTANT.BASE_SUBJECT.RULE_ATTRIBUTE_SUBJECT_BASE,
      ...Object.values(RULES_CONSTANT.DATE),
    ];
    return {
      input: columnOrderForDedicatedView(
        formDefinitionsData.fields.filter((fieldItem, index) => {
          return ruleToSeperate.includes(fieldItem.ruleTypeCode);
        })
      ),
      multimedia: columnOrderForDedicatedView(
        formDefinitionsData.fields.filter((fieldItem, index) => {
          return !ruleToSeperate.includes(fieldItem.ruleTypeCode);
        })
      ),
    };
  }, [formDefinitionsData]);

  const tableTitle = useMemo(() => {
    if (!eventDetails?.data) return '';
    return `Add ${eventDetails?.data.displayName}`;
  }, [eventDetails?.data]);

  return (
    <TenantHomeDashboardNavigationBar>
      <ButtonBackToTop />
      <Box sx={{ height: '100%' }}>
        {isLoading && (
          <Stack height={500}>
            <ActivityIndicator />
          </Stack>
        )}
        {formDefinitionsData && (
          <Formik
            innerRef={formikRef}
            initialValues={formDefinitionsData.initialValues}
            validationSchema={validateSchema}
            onSubmit={handleOnSubmitForm}
          >
            {(formikProps: FormikProps<{ [key: string]: string }>) => {
              return (
                <Box sx={{ backgroundColor: '#fff', pb: 3, px: 3 }} alignItems='center' key={displayKey}>
                  <Stack
                    direction='row'
                    alignItems='center'
                    py={1}
                    justifyContent='space-between'
                    sx={{ backgroundColor: '#fff' }}
                    spacing={2}
                  >
                    <Stack>
                      <Typography variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
                        {tableTitle}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack>
                    <Typography variant='input-label' fontStyle='italic' sx={{ pt: 1, fontSize: 13 }}>
                      (<span style={{ color: 'red' }}>*</span>) indicates required fields
                    </Typography>
                  </Stack>

                  <Grid
                    container
                    marginTop={2}
                    sx={{ backgroundColor: '#fff', py: 1, borderTop: '1px solid #E3EBF6' }}
                    justifyContent='space-between'
                    display='flex'
                    flexWrap='wrap'
                    alignItems='flex-start'
                  >
                    <Grid container item xs={6} direction='column'>
                      {formFields?.input?.map((fieldItem) =>
                        generateFormInput(formikProps, fieldItem, false, undefined, pullAttributeDataMapping?.data)
                      )}
                    </Grid>

                    {formFields.multimedia.length > 0 && (
                      <Divider orientation='vertical' variant='middle' flexItem sx={{ px: 0.5 }} />
                    )}
                    <Grid container item xs={5} direction='column'>
                      {formFields?.multimedia?.map((fieldItem) =>
                        generateFormInput(formikProps, fieldItem, false, undefined, pullAttributeDataMapping?.data)
                      )}
                    </Grid>
                  </Grid>
                  <Stack direction='row' spacing={2} justifyContent='flex-end'>
                    <Button disabled={isInserting} onClick={handleOnCancelInput} variant='main-table-panel-border'>
                      Cancel
                    </Button>

                    <ButtonLoading
                      loading={isInserting}
                      variant='main-table-panel'
                      onClick={() => formikProps.handleSubmit()}
                      // @ts-ignore
                      disabled={!validateSchema?.isValidSync(formikProps.values) || isInserting}
                    >
                      Save
                    </ButtonLoading>
                  </Stack>
                </Box>
              );
            }}
          </Formik>
        )}
        {!!snackbar && (
          <Snackbar
            open
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            onClose={handleCloseSnackbar}
            autoHideDuration={3000}
          >
            <Alert {...snackbar} onClose={handleCloseSnackbar} />
          </Snackbar>
        )}
      </Box>
    </TenantHomeDashboardNavigationBar>
  );
}
