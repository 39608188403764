import { useMemo, useState } from 'react';

import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { DataGridPro, GridColDef, GridFilterModel, getGridDateOperators } from '@mui/x-data-grid-pro';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import { useAdminDashboardUserAcivity } from 'services/v1/TenantMaster/MasterTable/AdminDashboardService';
import { PaginationAndSortingParams } from 'types/api/Common/PaginationTypes';
import { dayjs } from 'utils/DateUtils';
import { ENV_CONFIG } from 'config/env';
const ModalContentStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 850,
  bgcolor: 'background.paper',
  height: 'auto',
  overflow: 'auto',
  borderRadius: 1,
  py: 2,
};

const dataGridStyle = {
  '& .MuiCheckbox-root svg': {
    backgroundColor: 'transparent',
    color: '#98A2AE',
    borderRadius: 2,
  },
  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: '#EDF2FF',
    borderTop: '1px solid #E3EBF6',
    borderBottom: '1px solid #E3EBF6',
    textAlign: 'center',
    display: 'flex',
  },
};

const closeIconStyle = { color: '#98A2AE', cursor: 'pointer' };

export interface ModalListAcitivityUserProps {
  visible?: boolean;
  onClose?: () => void;
}

export function ModalListAcitivityUser(props: ModalListAcitivityUserProps) {
  const { onClose, visible = false } = props;

  const [filter, setFilter] = useState<PaginationAndSortingParams>({
    page: 1,
    size: 10,
    filterValue: '',
    sortType: 'desc',
    sortBy: 'activityDate',
    filterColumn: 'fullName',
    filterOperator: 'contains',
  });

  const { data: userActivity, isLoading: isLoadingUserActivity } = useAdminDashboardUserAcivity(filter, visible);

  const handleOnCloseModal = () => {
    onClose?.();
  };

  const handleChangePage = (newPage: number) => {
    setFilter({ ...filter, page: newPage + 1 });
  };
  const handleChangePageSize = (newPageSize: number) => {
    setFilter({ ...filter, size: newPageSize });
  };

  const handleOnFilter = (params: GridFilterModel) => {
    let filterObj = {};
    if (params.items.length) {
      filterObj = {
        filterValue: params.items[0].value,
        filterOperator: params.items[0].operatorValue,
        filterColumn: params.items[0].columnField,
      };
    } else {
      filterObj = {
        filterValue: '',
        filterOperator: 'contains',
      };
    }
    setFilter({
      ...filter,
      ...filterObj,
    });
  };

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'fullName',
        headerName: 'Full Name',
        width: 200,
      },
      {
        field: 'description',
        headerName: 'Description',
        width: 400,
      },
      {
        field: 'activityDate',
        headerName: 'Timestamp',
        width: 200,
        valueFormatter: (params) => {
          return dayjs(params.value).format(ENV_CONFIG.config.DATE_TIME_FORMAT);
        },
        filterOperators: getGridDateOperators().filter(
          (operator) => !['onOrAfter', 'onOrBefore', 'isEmpty', 'isNotEmpty'].includes(operator.value)
        ),
      },
    ],
    []
  );

  return (
    <div>
      <Modal open={visible} onClose={handleOnCloseModal}>
        <Stack direction='column' sx={ModalContentStyle}>
          <Stack px={2} justifyContent='space-between' direction='row' alignItems='center'>
            <Typography variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
              User Activity
            </Typography>
            <IconButton onClick={handleOnCloseModal} component='label'>
              <HighlightOffRoundedIcon sx={closeIconStyle} />
            </IconButton>
          </Stack>
          <Divider sx={{ mb: 2, mt: 1 }} />

          <Stack height={450} px={2}>
            <DataGridPro
              sx={dataGridStyle}
              autoHeight
              loading={isLoadingUserActivity}
              pagination
              disableMultipleColumnsFiltering
              paginationMode='server'
              density='compact'
              pageSize={filter.size}
              rowCount={userActivity?.data?.rowCount}
              page={userActivity?.data?.page?.pageNumber || 0}
              rows={userActivity?.data.data || []}
              columns={columns}
              filterMode='server'
              checkboxSelection={false}
              onFilterModelChange={handleOnFilter}
              onPageChange={handleChangePage}
              onPageSizeChange={handleChangePageSize}
            />
          </Stack>
        </Stack>
      </Modal>
    </div>
  );
}
