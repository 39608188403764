// @ts-nocheck
import { dayjs } from 'utils/DateUtils';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { KTreeTableItem } from 'types/api/Tenant/AROKMS/KTreeDataTypes';
dayjs.extend(customParseFormat);

// Formats to try
const formats = ['YYYY/YYYY', 'MMM-YYYY', 'MMM-YY', 'DD-MM-YY'];

// Get date from label
function getDateFromLabelTree(label) {
  for (const format of formats) {
    const d = dayjs(label, format);
    if (d.isValid()) {
      return d;
    }
  }
  return label;
}

// Recursive function to sort nested children
function sortChildren(node) {
  if (node.children && node.children.length > 0) {
    node.children = node.children.sort(compareNodes);
    node.children.forEach(sortChildren);
  }
}

// Compare function for sorting
function compareNodes(a, b) {
  const dateA = getDateFromLabelTree(a.label);
  const dateB = getDateFromLabelTree(b.label);

  if (dayjs.isDayjs(dateA) && dayjs.isDayjs(dateB)) {
    return dateA.isAfter(dateB) ? 1 : -1;
  } else if (typeof dateA === 'string' && typeof dateB === 'string') {
    return dateA > dateB ? 1 : -1;
  }

  return 0;
}

// Sort function
export function sortTreeData(data) {
  if (Array.isArray(data)) {
    data.sort(compareNodes);
    data.forEach(sortChildren);
    return data;
  } else {
    return data.split('').sort().join('');
  }
}

export function convertObjectToValue(data, keyPair = 'value') {
  return Object.keys(data).reduce((acc, key) => {
    const value = data[key];
    if (typeof value === 'object' && value?.value && value?.label) {
      acc[key] = value[keyPair];
    } else {
      acc[key] = value;
    }
    return acc;
  }, {});
}

export function objectValidateWithSchema(object, schema) {
  return Object.keys(schema)
    .filter((key) => !schema[key](object[key]))
    .map((key) => new Error(`${key} is invalid.`));
}

export function isObjectEmptyValue(object) {
  return Object.keys(object).every((key) => !object[key]);
}

export function isObjectHasEmptyValue(object) {
  return Object.keys(object).some((key) => !object[key]);
}

export function createTreeData(parentName, data) {
  if (!parentName) {
    const highestLevel = data.reduce((acc, item) => {
      if (item.level > acc) {
        return item.level;
      } else {
        return acc;
      }
    }, 0);

    data = data.map((item) => {
      if (highestLevel !== 999 && item.level === highestLevel) {
        return {
          ...item,
          parent: null,
        };
      }
      return item;
    });
  }

  const children = data.filter((item) => item.parent === parentName);
  return children.map((child) => {
    if (child.parent === child.member) {
      return {
        label: child.member,
        level: child.level,
        value: child.id,
      };
    }
    return {
      label: child.member,
      value: child.id,
      level: child.level,
      children: createTreeData(child.member, data),
    };
  });
}

export function flattenTreeData(data, parent = null, level = 0) {
  let result = [];

  for (let item of data) {
    result.push({
      label: item.label.trim(),
      value: item.value,
      level: level,
      parent: parent?.trim(),
    });

    if (item.children.length) {
      result = result.concat(flattenTreeData(item.children, item.label, level + 1));
    }
  }

  return result;
}

export function renderOptionsKtree(props, option, state, lowestLevel) {
  if (option.level === lowestLevel) {
    return (
      <li
        {...props}
        style={{
          marginLeft: `${option.level * 3}px`,
          fontSize: '14px',
        }}
      >
        {option.label}
      </li>
    );
  }
  return (
    <li
      {...props}
      style={{
        fontWeight: 'bold',
        marginLeft: `${option.level * 2}px`,
        borderBottom: '1px solid #e3e3e3',
        fontSize: '14px',
      }}
    >
      {option.label}
    </li>
  );
}

export function getListBoxPropsAutoScrolItem(
  memberOptions: AutoCompleteItem[],
  selectedOption: AutoCompleteItem | null
) {
  return {
    ref: (listboxNode: HTMLElement | null) => {
      if (listboxNode && listboxNode instanceof HTMLElement) {
        const index = memberOptions.findIndex((option: AutoCompleteItem) => option.value === selectedOption?.value);
        if (index !== -1) {
          const itemNode = listboxNode.querySelector(`[data-option-index="${index}"]`);
          if (itemNode && itemNode instanceof HTMLElement) {
            listboxNode.scrollTop = itemNode.offsetTop;
          }
        }
      }
    },
  };
}

export function findObjectByLabelOrValue(text: string, options: DropdownItem[]) {
  const option = options.find((option) => option.label === text || option.value === text);
  return option;
}

export function generateUniqueLetter(records: any[], existingLetters: string[]) {
  const length = records.length - 1;

  function numToChars(num) {
    let chars = '';

    while (num >= 0) {
      chars = String.fromCharCode((num % 26) + 65) + chars;
      num = Math.floor(num / 26) - 1;
    }

    return chars;
  }

  function findUniqueChar(chars) {
    while (existingLetters.includes(chars)) {
      const index = (chars.charCodeAt(chars.length - 1) - 65 + 1) % 26;
      if (index === 0) {
        chars = chars.slice(0, -1) + String.fromCharCode(65) + (chars.length > 1 ? String.fromCharCode(65) : '');
      } else {
        chars = chars.slice(0, -1) + String.fromCharCode(index + 65);
      }
    }

    return chars;
  }

  const candidateChar = numToChars(length);
  return findUniqueChar(candidateChar);
}

export function normalizeDataGridRowId(rowId) {
  if (isNaN(rowId)) {
    return rowId;
  }
  return null;
}

export function sortAttributeColumnByOrderKtree(arr, map) {
  let sorted = [];

  function helper(item) {
    if (!arr.includes(item)) return;
    sorted.push(item);
    let children = [];
    for (let [key, value] of map) {
      if (value === item) children.push(key);
    }
    children.sort();
    for (let child of children) {
      helper(child);
    }
  }

  // Start from root element
  let root;
  for (let [key, value] of map) {
    if (value === null) {
      root = key;
      break;
    }
  }
  helper(root);

  return sorted;
}

export function getSortedKTreeId(arr, map, mapKTreeMemberWithId) {
  let sorted = [];

  function helper(item) {
    if (!arr.includes(item)) return;
    const id = mapKTreeMemberWithId.get(item);
    if (id) {
      sorted.push(id);
    }
    let children = [];
    for (let [key, value] of map) {
      if (value === item) children.push(key);
    }
    children.sort();
    for (let child of children) {
      helper(child);
    }
  }

  // Start from root element
  let root;
  for (let [key, value] of map) {
    if (value === null) {
      root = key;
      break;
    }
  }
  helper(root);
  return sorted;
}

export function mapKTreeMemberWithParent(ktreeData: KTreeTableItem[]) {
  const mapAttributeWithParent = new Map<string, string>();
  ktreeData.forEach((col) => {
    mapAttributeWithParent.set(col?.member, col?.parent);
  });
  return mapAttributeWithParent;
}

export function mapKTreeMemberWithId(ktreeData: KTreeTableItem[]) {
  const mapAttributeWithParent = new Map<string, string>();
  ktreeData.forEach((col) => {
    mapAttributeWithParent.set(col?.member, col?.id);
  });
  return mapAttributeWithParent;
}

export function isValidJSONString(str: string) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}
