import { FormikProps } from 'formik';
import { useEffect, useMemo } from 'react';
import Stack from '@mui/material/Stack';

import Typography from '@mui/material/Typography';

import TextField from '@mui/material/TextField';

import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import {
  useSystemMemberListDropdown,
  useListAllSystemStatusDropdown,
} from 'services/v1/TenantMaster/MasterTable/SystemTableDataService';
import { GLOBAL_MODAL_STYLE_VALUE } from 'constant/GloblaStyleConstant';
import { DropdownCreatableMemberList } from 'pages/Tenant/AROKMS/DataInput/components/DropdownCreatableMemberList';
import { Field, Option } from 'types/api/Tenant/AROKMS/DisplayTableTypes';
import { MEMBER_LIST_STATIC_RECORD } from 'data/MemberListRecord';
const autocompleteStyles = {
  width: '55%',
  '& .MuiOutlinedInput-root': {
    padding: '1px 2px',
  },
  '& .MuiAutocomplete-popper': {
    fontSize: '10px',
  },
};

const textInputAutoCompleteStyles = {
  '& .MuiOutlinedInput': {
    padding: '1px 2px',
  },
};

const optionStyles = { '& > span': { fontSize: '14px', mr: 2, flexShrink: 0 } };

interface ModalParamsProps {
  formikProps: FormikProps<any>;
  disabled?: boolean;
  enableFieldStatus?: boolean;
}

export function TabSectionSystemDataContent(props: ModalParamsProps) {
  const { formikProps, disabled, enableFieldStatus = true } = props;
  const { values, setFieldValue } = formikProps;

  const { data: systemMemberList } = useSystemMemberListDropdown();
  const { data: optionsStatus } = useListAllSystemStatusDropdown();

  const disableDropodownStatus = useMemo(() => {
    // Here is the criterea to disable the dropdown status
    // If codeStatus equal "Locked", then enable the demoStatus dropdown
    // if codeStatus equal "Locked" and demoStatus equal "Locked", then enable the fieldStatus dropdown
    // return should be true or false
    return {
      codeStatus: values.fieldStatus?.value !== MEMBER_LIST_STATIC_RECORD.FIELD_STATUS.RELEASED,
      demoStatus:
        values.codeStatus?.value === MEMBER_LIST_STATIC_RECORD.CODE_STATUS.LOCKED &&
        values.fieldStatus?.value !== MEMBER_LIST_STATIC_RECORD.FIELD_STATUS.RELEASED,
      fieldStatus:
        values.codeStatus?.value === MEMBER_LIST_STATIC_RECORD.CODE_STATUS.LOCKED &&
        values.demoStatus?.value === MEMBER_LIST_STATIC_RECORD.DEMO_STATUS.LOCKED,
    };
  }, [values]);

  useEffect(() => {
    if (
      values.codeStatus?.value === MEMBER_LIST_STATIC_RECORD.CODE_STATUS.CHANGING &&
      values.demoStatus?.value === MEMBER_LIST_STATIC_RECORD.DEMO_STATUS.LOCKED
    ) {
      setFieldValue(
        'demoStatus',
        optionsStatus?.data.demoStatus.find((item) => item.value === MEMBER_LIST_STATIC_RECORD.DEMO_STATUS.CHANGING)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, optionsStatus?.data.demoStatus]);

  const dropdownCreateableMemberListField = {
    name: 'system',
    options: (systemMemberList?.data as Option[]) || [],
    label: 'System Member List',
    placeholder: 'Please Select',
    colSubjectId: '4028819c84e5373a0184e5373a3d0000',
    disabled,
  } as Field;
  return (
    <Stack px={GLOBAL_MODAL_STYLE_VALUE.PADDING_X} bgcolor='#FAFAFA' display='flex' flexGrow={2}>
      <Stack>
        <DropdownCreatableMemberList formik={formikProps} field={dropdownCreateableMemberListField} />
      </Stack>
      <Stack>
        <Stack direction='row' spacing={2} alignItems='center' justifyContent='space-between' sx={{ mt: 2 }}>
          <Typography variant='input-label'>Code Status</Typography>
          <Autocomplete
            disabled={!disableDropodownStatus.codeStatus}
            options={optionsStatus?.data.codeStatus || []}
            // @ts-ignore
            value={values.codeStatus}
            getOptionLabel={(option) => option.label}
            clearIcon={null}
            contentEditable={false}
            onChange={(event, value) => {
              setFieldValue('codeStatus', value);
            }}
            renderOption={(props, option) => (
              <Box component='li' sx={optionStyles} {...props}>
                <span>{option.label}</span>
              </Box>
            )}
            sx={autocompleteStyles}
            renderInput={(params) => <TextField {...params} sx={textInputAutoCompleteStyles} />}
          />
        </Stack>
      </Stack>
      <Stack>
        <Stack direction='row' spacing={2} alignItems='center' justifyContent='space-between' sx={{ mt: 2 }}>
          <Typography variant='input-label'>Demo Status</Typography>
          <Autocomplete
            options={optionsStatus?.data.demoStatus || []}
            // @ts-ignore
            value={values.demoStatus}
            disabled={!disableDropodownStatus.demoStatus}
            getOptionLabel={(option) => option.label}
            clearIcon={null}
            contentEditable={false}
            onChange={(event, value) => {
              setFieldValue('demoStatus', value);
            }}
            renderOption={(props, option) => (
              <Box component='li' sx={optionStyles} {...props}>
                <span>{option.label}</span>
              </Box>
            )}
            sx={autocompleteStyles}
            renderInput={(params) => <TextField {...params} sx={textInputAutoCompleteStyles} />}
          />
        </Stack>
      </Stack>
      <Stack>
        <Stack direction='row' spacing={2} alignItems='center' justifyContent='space-between' sx={{ mt: 2 }}>
          <Typography variant='input-label'>Field Status</Typography>
          <Autocomplete
            options={optionsStatus?.data.fieldStatus || []}
            disabled={!disableDropodownStatus.fieldStatus || !enableFieldStatus}
            // @ts-ignore
            value={values.fieldStatus}
            getOptionLabel={(option) => option.label}
            clearIcon={null}
            placeholder='Please select'
            contentEditable={false}
            onChange={(event, value) => {
              setFieldValue('fieldStatus', value);
            }}
            renderOption={(props, option) => (
              <Box component='li' sx={optionStyles} {...props}>
                <span>{option.label}</span>
              </Box>
            )}
            sx={autocompleteStyles}
            renderInput={(params) => <TextField {...params} sx={textInputAutoCompleteStyles} />}
          />
        </Stack>
      </Stack>
    </Stack>
  );
}
