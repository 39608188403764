import React, { useRef, useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import CompareArrowsOutlinedIcon from '@mui/icons-material/CompareArrowsOutlined';
import { MainLayoutComponent } from 'components/LayoutComponent/SidebarLayout/MainLayoutComponent';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import ButtonLoading from '@mui/lab/LoadingButton';
import { Box } from '@mui/material';

import { FUNCTION_CORE, FUNCTION_CORE_OPTIONS } from 'constant/FunctionConstant';
import {
  FunctionDefinitionLoadParameter,
  FunctionDefinitionLoadParameterRef,
} from './components/FunctionDefinitionLoadParameter';
import {
  useDeleteFunctionDefinition,
  useEditFunctionDefinition,
  useGetFunctionDefinitionCubeDropdown,
  useGetFunctionDefinitionCubeLayerDropdown,
  useGetFunctionDefinitionDetailsById,
  useGetFunctionDefinitionSubjectAttributeDropdown,
  useGetFunctionDefinitionSubjectDropdown,
} from 'services/v1/SystemTenant/AROFunction/FunctionDefinitionService';
import { useFormik } from 'formik';
import { AutoCompleteItem } from 'types/api/Common/AutoCompleteTypes';
import {
  FunctionDefinitionSaveParameter,
  FunctionDefinitionSaveParameterRef,
} from './components/FunctionDefinitionSaveParameter';
import { RequestCreateFunctionDefinition } from 'types/api/SystemTenant/AROFunction/FunctionDefinitionTypes';
import { useNavigate, useParams } from 'react-router-dom';
import { PATH_CONSTANT } from 'constant/PathConstant';
import ActivityIndicator from 'components/ActivityIndicatorComponent';
import { FunctionDefinitionDisplayLoadParameter } from './components/FunctionDefinitionDisplayLoadParameter';
import {
  CreateFunctionDefinitionContext,
  SelectedCubeAttributeOptionsProvider,
  createFunctionDefinitionFormikInitialValues,
  createFunctionDefinitionValidationSchema,
} from './CreateFunctionDefinition';
import ModalDeleteComponent from 'components/ModalComponent/ModalDeleteComponent';
import { ModalDeleteState } from 'pages/Auth/ModalChangeRole';
const autocompleteStyles = {
  width: '55%',
  '& .MuiOutlinedInput-root': {
    padding: '1px 2px',
  },
  '& .MuiAutocomplete-popper': {
    fontSize: '10px',
  },
};
const textInputStyles = {
  '& .MuiOutlinedInput': {
    padding: '1px 2px',
  },
};

function EditFunctionDefinitionComponent() {
  const { t } = useTranslation();

  const loadParameterFieldsRef = useRef<FunctionDefinitionLoadParameterRef | null>(null);
  const displayLoadParameterFieldsRef = useRef<FunctionDefinitionLoadParameterRef | null>(null);
  const saveParameterFieldsRef = useRef<FunctionDefinitionSaveParameterRef | null>(null);

  const { functionId } = useParams<{ functionId: string }>();
  const { showDisplayLoadParameter, setShowDisplayLoadParameter } = useContext(CreateFunctionDefinitionContext);

  const navigate = useNavigate();

  const [isFormLoadParameterValid, setIsFormLoadParameterValid] = useState(false);
  const [isFormSaveParameterValid, setIsFormSaveParameterValid] = useState(false);
  const [modalDeleteState, setModalDeleteState] = useState<ModalDeleteState>({
    message: 'Are you sure you want to delete this data?',
    open: false,
  });

  const formik = useFormik({
    initialValues: createFunctionDefinitionFormikInitialValues,
    validationSchema: createFunctionDefinitionValidationSchema,
    onSubmit: () => {},
  });

  const { data: functionDefinitionDetails, isLoading: isLoadingDetails } =
    useGetFunctionDefinitionDetailsById(functionId);
  const { data: subjectDropdownOptions, isLoading: isLoadingSubjectOptions } =
    useGetFunctionDefinitionSubjectDropdown();
  const { data: cubeDropdownOptions, isLoading: isLoadingCubeOptions } = useGetFunctionDefinitionCubeDropdown();
  const { data: cubeLayerDropdownOptions, isLoading: isLoadingCubeLayerOptions } =
    useGetFunctionDefinitionCubeLayerDropdown(
      formik.values.cube?.value?.toString() ?? null,
      formik.values.functionCore?.value?.toString() ?? null,
      true
    );

  const { data: attributeDataDropdown, isLoading: isLoadingAttributeDataOptions } =
    useGetFunctionDefinitionSubjectAttributeDropdown(formik.values.subject?.value?.toString() ?? null);

  const { mutate: editFunctionDefinition, isLoading: isSubmitting } = useEditFunctionDefinition();
  const { mutate: deleteFunctionDefinition } = useDeleteFunctionDefinition();

  const handleOnSubmit = () => {
    const data = formik.values;

    if (
      !data.name ||
      !data.subject ||
      !data.cube ||
      !data.cubeLayer ||
      !data.functionCore ||
      !data.subjectMemberAttribute
    )
      return;

    const loadParameterStringify = JSON.stringify(loadParameterFieldsRef.current?.getValues());
    const saveParameterStringify = JSON.stringify(saveParameterFieldsRef.current?.getValues());
    const displayLoadParameterStringify = showDisplayLoadParameter
      ? JSON.stringify(displayLoadParameterFieldsRef.current?.getValues())
      : null;

    const payload: RequestCreateFunctionDefinition = {
      cubeId: data.cube?.value?.toString(),
      cubeLayerId: data.cubeLayer?.value?.toString(),
      functionCore: data.functionCore?.value?.toString(),
      name: data.name?.toString(),
      subjectId: data.subject?.value?.toString(),
      subjectMemberAttributeId: data.subjectMemberAttribute?.value?.toString(),
      subjectMemberXAttributeId: data.subjectXMemberAttribute?.value?.toString() ?? null,
      loadParameters: loadParameterStringify,
      saveParameters: saveParameterStringify,
      displayLoadParameters: displayLoadParameterStringify,
    };
    if (functionId) {
      editFunctionDefinition(
        {
          data: payload,
          functionDefinitionId: functionId,
        },
        {
          onSuccess: () => {
            toast.success('Update function definition successfully!');
            navigate(PATH_CONSTANT.SYSTEM.FUNCTION.FUNCTION_DEFINITION);
          },
        }
      );
    }
  };

  const handleOnCancel = () => {
    navigate(PATH_CONSTANT.SYSTEM.FUNCTION.FUNCTION_DEFINITION);
  };

  const isAllFormValid =
    isFormLoadParameterValid &&
    isFormSaveParameterValid &&
    createFunctionDefinitionValidationSchema.isValidSync(formik.values);

  const isLoadingOptionsData = isLoadingSubjectOptions || isLoadingCubeOptions;

  const handleOnFormLoadParameterChange = (values: any, isFormValid: boolean) => {
    setIsFormLoadParameterValid(isFormValid);
  };

  const handleOnFormSaveParameterChange = (values: any, isFormValid: boolean) => {
    setIsFormSaveParameterValid(isFormValid);
  };

  const handleOnFunctionCoreChange = (e: React.SyntheticEvent, value: AutoCompleteItem | null) => {
    formik.setFieldValue('functionCore', value);
    formik.setFieldValue('cubeLayer', null);
  };

  const handleOnCubeChange = (e: React.SyntheticEvent, value: AutoCompleteItem | null) => {
    formik.setFieldValue('cube', value);
    formik.setFieldValue('cubeLayer', null);
  };

  const handleOnSubjectChange = (e: React.SyntheticEvent, value: AutoCompleteItem | null) => {
    formik.setFieldValue('subject', value);
    formik.setFieldValue('subjectMemberAttribute', null);
  };

  const handleOnDeleteButtonClick = () => {
    if (functionDefinitionDetails?.data.name) {
      const message = `Are you sure you want to delete function <strong>${functionDefinitionDetails?.data.name}</strong>?
      <br />
      <br />
      This action will remove the function but retain the existing data in the <strong>${functionDefinitionDetails?.data.cube.label}</strong> cube.
      <br />
      Please note that the retained data will not be updated with any subsequent changes.
      `;

      setModalDeleteState({ ...modalDeleteState, open: true, message });
    }
  };

  const handleOnDeleteData = () => {
    if (functionDefinitionDetails) {
      deleteFunctionDefinition(functionDefinitionDetails?.data.id, {
        onSuccess: () => {
          toast.success('Delete function definition successfully!');
          navigate(PATH_CONSTANT.SYSTEM.FUNCTION.FUNCTION_DEFINITION);
        },
        onError: (error) => {
          setModalDeleteState({
            ...modalDeleteState,
            isError: true,
            errorMessage: error?.response?.data?.message,
          });
        },
      });
    }
  };

  useEffect(() => {
    if (functionDefinitionDetails?.data) {
      formik.setFieldValue('name', functionDefinitionDetails?.data?.name);
      formik.setFieldValue('subject', functionDefinitionDetails?.data?.subject);
      formik.setFieldValue('cube', functionDefinitionDetails?.data?.cube);
      formik.setFieldValue('cubeLayer', functionDefinitionDetails?.data?.cubeLayer);
      formik.setFieldValue('functionCore', functionDefinitionDetails?.data?.functionCore);
      formik.setFieldValue('subjectMemberAttribute', functionDefinitionDetails?.data?.subjectMemberAttribute);
      formik.setFieldValue('subjectXMemberAttribute', functionDefinitionDetails?.data?.subjectXMemberAttribute);
      if (functionDefinitionDetails?.data?.displayLoadParameters) {
        setShowDisplayLoadParameter(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [functionDefinitionDetails?.data]);

  return (
    <MainLayoutComponent pageTitle='Edit Function' breadcrumbs={[t('SIDEBAR_MENU.DASHBOARD'), 'Function Definition']}>
      <Box p={2} bgcolor='#fff'>
        {isLoadingOptionsData || isLoadingDetails ? (
          <Stack height={450}>
            <ActivityIndicator />
          </Stack>
        ) : (
          <>
            <Typography variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
              Function Definition
            </Typography>
            <Divider sx={{ my: 1 }} />
            <Stack width='100%' direction='row' gap={3} mb={5} flexWrap='wrap' alignItems='center'>
              <Stack width='40%'>
                <Typography variant='input-label' fontWeight={800}>
                  Function Name
                </Typography>
                <Typography variant='input-label-gray' fontSize={13}></Typography>
                <TextField
                  sx={textInputStyles}
                  name='name'
                  hiddenLabel
                  value={formik.values.name}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                  variant='outlined'
                  size='small'
                  placeholder='e.g. Interest Function for Loan'
                />
              </Stack>
              <Stack width='40%'>
                <Typography variant='input-label' fontWeight={800}>
                  Select Subject
                </Typography>
                <Typography variant='input-label-gray' fontSize={13}>
                  Choose the subject (table) from which you want to pull the values.
                </Typography>
                <Autocomplete
                  options={subjectDropdownOptions?.data ?? []}
                  getOptionLabel={(option) => option.label}
                  sx={{ ...autocompleteStyles, width: '100%' }}
                  clearIcon={null}
                  value={formik.values.subject}
                  onBlur={formik.handleBlur}
                  onChange={handleOnSubjectChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name='subject'
                      placeholder='Select Subject...'
                      error={formik.touched.subject && Boolean(formik.errors.subject)}
                      helperText={formik.touched.subject && formik.errors.subject}
                    />
                  )}
                />
              </Stack>
              <Stack width='40%'>
                <Typography variant='input-label' fontWeight={800}>
                  Select Cube
                </Typography>
                <Typography variant='input-label-gray' fontSize={13}>
                  Choose the cube from which you want to calculate the values for.
                </Typography>
                <Autocomplete
                  options={cubeDropdownOptions?.data?.data ?? []}
                  getOptionLabel={(option) => option.label}
                  sx={{ ...autocompleteStyles, width: '100%' }}
                  clearIcon={null}
                  value={formik.values.cube}
                  onBlur={formik.handleBlur}
                  onChange={handleOnCubeChange}
                  renderInput={(params) => <TextField {...params} name='cube' placeholder='Select Cube...' />}
                />
              </Stack>
              <Stack width='40%'>
                <Typography variant='input-label' fontWeight={800}>
                  Function Core
                </Typography>
                <Typography variant='input-label-gray' fontSize={13}>
                  Choose the function core to be used for the calculation.
                </Typography>
                <Autocomplete
                  options={FUNCTION_CORE_OPTIONS}
                  value={formik.values.functionCore}
                  onBlur={formik.handleBlur}
                  onChange={handleOnFunctionCoreChange}
                  sx={{ ...autocompleteStyles, width: '100%' }}
                  clearIcon={null}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name='functionCore'
                      placeholder='Select Function Core...'
                      error={formik.touched.functionCore && Boolean(formik.errors.functionCore)}
                      helperText={formik.touched.functionCore && formik.errors.functionCore}
                    />
                  )}
                />
              </Stack>
              <Stack width='40%'>
                <Typography variant='input-label' fontWeight={800}>
                  Select Cube Layer
                </Typography>
                <Typography variant='input-label-gray' fontSize={13}>
                  Choose the cube layer from which you want to calculate the values for.
                </Typography>
                <Autocomplete
                  disabled={!formik.values.cube || !formik.values.functionCore || isLoadingCubeLayerOptions}
                  options={cubeLayerDropdownOptions?.data ?? []}
                  value={formik.values.cubeLayer}
                  onBlur={formik.handleBlur}
                  onChange={(e, value) => {
                    formik.setFieldValue('cubeLayer', value);
                  }}
                  sx={{ ...autocompleteStyles, width: '100%' }}
                  clearIcon={null}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name='cubeLayer'
                      placeholder={isLoadingCubeLayerOptions ? 'Loading...' : 'Select Cube Layer...'}
                      error={formik.touched.cubeLayer && Boolean(formik.errors.cubeLayer)}
                      helperText={formik.touched.cubeLayer && formik.errors.cubeLayer}
                    />
                  )}
                />
              </Stack>
            </Stack>

            <Typography variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
              Function Lookup Value
            </Typography>
            <Divider sx={{ my: 1 }} />
            <Stack direction='row' width='100%' alignItems='center' gap={3} mb={5}>
              <Stack width='30%'>
                <Typography variant='input-label' fontWeight={800}>
                  Subject Member Attribute
                </Typography>
                <Typography variant='input-label-gray' fontSize={13}>
                  Choose the attribute from the subject to be used as the lookup value for the Cube Member.
                </Typography>
                <Autocomplete
                  options={attributeDataDropdown?.data ?? []}
                  disabled={!formik.values.subject || !formik.values.cubeLayer || isLoadingAttributeDataOptions}
                  value={formik.values.subjectMemberAttribute}
                  onBlur={formik.handleBlur}
                  onChange={(e, value) => {
                    formik.setFieldValue('subjectMemberAttribute', value);
                  }}
                  sx={{ ...autocompleteStyles, width: '100%' }}
                  clearIcon={null}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name='subjectMemberAttribute'
                      placeholder={isLoadingAttributeDataOptions ? 'Loading...' : 'Select Subject Attribute...'}
                      error={formik.touched.subjectMemberAttribute && Boolean(formik.errors.subjectMemberAttribute)}
                      helperText={formik.touched.subjectMemberAttribute && formik.errors.subjectMemberAttribute}
                    />
                  )}
                />
              </Stack>
              <CompareArrowsOutlinedIcon fontSize='large' color='info' />
              <Stack width='40%'>
                <Typography variant='input-label' fontWeight={800} fontSize={16}>
                  {cubeDropdownOptions?.data &&
                    formik.values.cube?.value &&
                    cubeDropdownOptions?.data?.cubeMemberMap?.[formik.values.cube?.value]}
                </Typography>
              </Stack>
            </Stack>
            {cubeDropdownOptions?.data &&
              formik.values.cube?.value &&
              cubeDropdownOptions?.data?.cubeXMemberMap?.[formik.values.cube?.value] && (
                <Stack direction='row' width='100%' alignItems='center' gap={3} mb={5}>
                  <Stack width='30%'>
                    <Typography variant='input-label' fontWeight={800}>
                      Subject Member X Attribute
                    </Typography>
                    <Typography variant='input-label-gray' fontSize={13}>
                      Choose the attribute from the subject to be used as the lookup value for the Cube Member.
                    </Typography>
                    <Autocomplete
                      options={attributeDataDropdown?.data ?? []}
                      disabled={!formik.values.subject || !formik.values.cubeLayer || isLoadingAttributeDataOptions}
                      value={formik.values.subjectXMemberAttribute}
                      onBlur={formik.handleBlur}
                      onChange={(e, value) => {
                        formik.setFieldValue('subjectXMemberAttribute', value);
                      }}
                      sx={{ ...autocompleteStyles, width: '100%' }}
                      clearIcon={null}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name='subjectXMemberAttribute'
                          placeholder={isLoadingAttributeDataOptions ? 'Loading...' : 'Select Subject Attribute...'}
                          error={
                            formik.touched.subjectXMemberAttribute && Boolean(formik.errors.subjectXMemberAttribute)
                          }
                          helperText={formik.touched.subjectXMemberAttribute && formik.errors.subjectXMemberAttribute}
                        />
                      )}
                    />
                  </Stack>
                  <CompareArrowsOutlinedIcon fontSize='large' color='info' />
                  <Stack width='40%'>
                    <Typography variant='input-label' fontWeight={800} fontSize={16}>
                      {cubeDropdownOptions?.data &&
                        formik.values.cube?.value &&
                        cubeDropdownOptions?.data?.cubeXMemberMap?.[formik.values.cube?.value]}
                    </Typography>
                  </Stack>
                </Stack>
              )}
            {formik.values.cubeLayer && formik.values.subjectMemberAttribute && (
              <>
                <FunctionDefinitionLoadParameter
                  ref={loadParameterFieldsRef}
                  onFormikChange={handleOnFormLoadParameterChange}
                  subjectId={formik.values.subject?.value?.toString() ?? null}
                  sectionTitle={`Load Parameter ${formik.values.functionCore?.label}`}
                  functionCore={formik.values.functionCore?.value.toString() ?? ''}
                />
                {showDisplayLoadParameter &&
                  formik.values.functionCore != null &&
                  formik.values.functionCore.value !== FUNCTION_CORE.COUNT_FUNCTION && (
                    <FunctionDefinitionDisplayLoadParameter
                      ref={displayLoadParameterFieldsRef}
                      onFormikChange={handleOnFormLoadParameterChange}
                      cubeId={formik.values.cube?.value?.toString() ?? null}
                      layerId={formik.values.cubeLayer?.value?.toString() ?? null}
                      sectionTitle={`Display Load Parameter ${formik.values.functionCore?.label} in Cube ${formik.values.cube?.label}`}
                      functionCore={formik.values.functionCore?.value.toString() ?? ''}
                    />
                  )}
                <FunctionDefinitionSaveParameter
                  ref={saveParameterFieldsRef}
                  onFormikChange={handleOnFormSaveParameterChange}
                  cubeId={formik.values.cube?.value?.toString() ?? null}
                  layerId={formik.values.cubeLayer?.value?.toString() ?? null}
                  sectionTitle={`Save Parameter ${formik.values.functionCore?.label}`}
                  functionCore={formik.values.functionCore?.value.toString() ?? ''}
                />
              </>
            )}
            <Stack direction='row' px={2} mt={10} justifyContent='space-between' alignItems='center'>
              <Stack />
              <Stack direction='row' gap={2}>
                <ButtonLoading
                  variant='outlined'
                  sx={{
                    textTransform: 'none',
                    '&:disabled': {
                      cursor: 'not-allowed',
                    },
                  }}
                  color='error'
                  onClick={handleOnDeleteButtonClick}
                  loading={isSubmitting}
                  disabled={isSubmitting}
                >
                  Delete
                </ButtonLoading>
                <ButtonLoading variant='main-table-panel-border' onClick={handleOnCancel}>
                  Cancel
                </ButtonLoading>

                <ButtonLoading
                  variant='main-table-panel'
                  onClick={handleOnSubmit}
                  loading={isSubmitting}
                  disabled={!isAllFormValid}
                >
                  Update
                </ButtonLoading>
              </Stack>
            </Stack>
          </>
        )}
      </Box>
      <ModalDeleteComponent
        errorMessage={modalDeleteState.errorMessage}
        isError={modalDeleteState.isError}
        visible={modalDeleteState.open}
        title={null}
        message={modalDeleteState.message}
        onApprove={handleOnDeleteData}
        onClose={() =>
          setModalDeleteState({ ...modalDeleteState, open: false, isError: false, errorMessage: undefined })
        }
        onCancel={() =>
          setModalDeleteState({ ...modalDeleteState, open: false, isError: false, errorMessage: undefined })
        }
      />
    </MainLayoutComponent>
  );
}

export default function EditFunctionDefinitionPage() {
  return (
    <SelectedCubeAttributeOptionsProvider>
      <EditFunctionDefinitionComponent />
    </SelectedCubeAttributeOptionsProvider>
  );
}
