import { SubjectDropdownParameter } from 'services/v1/SystemTenant/AROKMS/SubjectService';
import { AutoCompleteItem } from 'types/api/Common/AutoCompleteTypes';

export const staticTableParameterDropdownOption: SubjectDropdownParameter[] = [
  {
    label: 'mst_db_server',
    value: 'mst_db_server',
    persistentName: 'mst_db_server',
  },
  {
    label: 'mst_system',
    value: 'mst_system',
    persistentName: 'mst_system',
  },
  {
    label: 'mst_tenant',
    value: 'mst_tenant',
    persistentName: 'mst_tenant',
  },
  {
    label: 'mst_user',
    value: 'mst_user',
    persistentName: 'mst_user',
  },
];

export const depreciationResultOptions: SubjectDropdownParameter[] = [
  {
    value: 'ASSET_COUNT',
    label: 'Asset Count',
    persistentName: 'Asset Count',
  },
  {
    value: 'DEPRECIATION',
    label: 'Depreciation',
    persistentName: 'Depreciation',
  },
  {
    value: 'BOOK_VALUE',
    label: 'Book Value',
    persistentName: 'Book Value',
  },
  {
    value: 'PROFIT_LOSS_ON_SALE',
    label: 'Profit(Loss) on Sale',
    persistentName: 'Profit(Loss) on Sale',
  },
];

export const ruleConditionOptions: SubjectDropdownParameter[] = [
  {
    value: 'NUMERIC_EQUAL',
    label: 'Numeric - Equal',
    persistentName: 'Numeric - Equal',
  },
  {
    value: 'NUMERIC_NOT_EQUAL',
    label: 'Numeric - Not Equal',
    persistentName: 'Numeric - Not Equal',
  },
  {
    value: 'NUMERIC_LESS_THAN',
    label: 'Numeric - Less Than ( < )',
    persistentName: 'Numeric - Less Than ( < )',
  },
  {
    value: 'NUMERIC_LESS_THAN_OR_EQUAL',
    label: 'Numeric - Less Than or Equal ( <= )',
    persistentName: 'Numeric - Less Than or Equal ( <= )',
  },
  {
    value: 'NUMERIC_GREATER_THAN',
    label: 'Numeric - Greater Than ( > )',
    persistentName: 'Numeric - Greater Than ( > )',
  },
  {
    value: 'NUMERIC_GREATER_THAN_OR_EQUAL',
    label: 'Numeric - Greater Than or Equal ( >= )',
    persistentName: 'Numeric - Greater Than or Equal ( >= )',
  },

  {
    value: 'TEXT_EQUAL',
    label: 'Text - Equal',
    persistentName: 'Text - Equal',
  },
  {
    value: 'TEXT_NOT_EQUAL',
    label: 'Text - Not Equal',
    persistentName: 'Text - Not Equal',
  },
  {
    value: 'TEXT_CONTAINS',
    label: 'Text - Contains',
    persistentName: 'Text - Contains',
  },
];

export const staticColumnParameterDropdownOption = {
  '': [],
  mst_db_server: [
    {
      label: 'server_name Name',
      value: 'server_name',
    },
    {
      label: 'server_description',
      value: 'server_description',
    },
    {
      label: 'server_status',
      value: 'server_status',
    },
  ],
  mst_system: [
    {
      label: 'system_name',
      value: 'system_name',
    },
    {
      label: 'system_description',
      value: 'system_description',
    },
    {
      label: 'status',
      value: 'status',
    },
    {
      label: 'system_code',
      value: 'system_code',
    },
  ],
  mst_tenant: [
    {
      label: 'tenant_name',
      value: 'tenant_name',
    },
    {
      label: 'tenant_description',
      value: 'tenant_description',
    },
    {
      label: 'tenant_description',
      value: 'status',
    },
    {
      label: 'tenant_code',
      value: 'tenant_code',
    },
  ],
  mst_user: [
    {
      label: 'full_name',
      value: 'full_name',
    },
    {
      label: 'person',
      value: 'person',
    },
    {
      label: 'user_name',
      value: 'user_name',
    },
  ],
};

export const dateTypeParameterOptions: AutoCompleteItem[] = [
  {
    label: 'Years',
    value: 'Years',
  },
  {
    label: 'Months',
    value: 'Months',
  },
  {
    label: 'Weeks',
    value: 'Weeks',
  },
  {
    label: 'Days',
    value: 'Days',
  },
];
export const dateTimeTypeParameterOptions: AutoCompleteItem[] = [
  {
    label: 'Years',
    value: 'Years',
  },
  {
    label: 'Months',
    value: 'Months',
  },
  {
    label: 'Weeks',
    value: 'Weeks',
  },
  {
    label: 'Days',
    value: 'Days',
  },
  {
    label: 'Hours',
    value: 'Hours',
  },
  {
    label: 'Minutes',
    value: 'Minutes',
  },
  {
    label: 'Seconds',
    value: 'Seconds',
  },
];

export const depreciationTypeOptions: AutoCompleteItem[] = [
  {
    label: '1 - DV (Diminishing Value)',
    value: '1',
  },
  {
    label: '2 - SL (Straight Line)',
    value: '2',
  },
  {
    label: '3 - DV200',
    value: '3',
  },
  {
    label: '4 - IMO (Immediate Write Off)',
    value: '4',
  },
];

export const concatenateConditionOptions: AutoCompleteItem[] = [
  {
    label: 'Concat if column empty',
    value: 'CONCATE_COLUMN_EMPTY',
  },

  {
    label: 'No concat if column empty',
    value: 'NO_CONCATE_IF_COLUMN_EMPTY',
  },
];
