import { SUBJECT_TYPE } from 'constant/DataInputConstant';

export function getSubjectChipColor(subjectType: string) {
  switch (subjectType) {
    case SUBJECT_TYPE.TABLE:
      return 'success';
    case SUBJECT_TYPE.VIEW:
      return 'info';
    case SUBJECT_TYPE.EVENT:
      return 'warning';
    default:
      return 'primary';
  }
}
