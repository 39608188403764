export const KTREE_DEFINITIN_STATUS = {
  NO_DATA: 'NO_DATA',
  CREATED: 'CREATED',
  DATA_GENERATED: 'DATA_GENERATED',
  DATA_AVAILABLE: 'DATA_AVAILABLE',
  EDITED: 'EDITED',
};

export const KTREE_DEFINITION_STATUS_LABEL = {
  [KTREE_DEFINITIN_STATUS.NO_DATA]: 'No Data',
  [KTREE_DEFINITIN_STATUS.CREATED]: 'Created',
  [KTREE_DEFINITIN_STATUS.DATA_GENERATED]: 'Data Generated',
  [KTREE_DEFINITIN_STATUS.DATA_AVAILABLE]: 'Data Available',
  [KTREE_DEFINITIN_STATUS.EDITED]: 'Rebuild required',
};
