import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Modal, Tooltip } from '@mui/material';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { GridColumns, GridRenderEditCellParams, useGridApiRef } from '@mui/x-data-grid-pro';
import { CustomDataTable } from 'components/DatatableComponent';
import { useGetSubjectDetails } from 'services/v1/SystemTenant/AROKMS/SubjectService';
import { useState, useMemo, useEffect } from 'react';
import { GridRowModesModel, GridCellParams, GridValueGetterParams } from '@mui/x-data-grid-pro';
import { DisplayTableParams } from 'services/v1/Tenant/AROKMS/DisplayTableService';
import { DynamicDataItem } from 'types/api/Tenant/AROKMS/DisplayTableTypes';
import { AxiosDefaultErrorEntity } from 'types';
import { RulesImplementation, getFilterOperatorByRuleTypeCode } from 'config/RulesImplementation/rulesMapping';
import { convertRuleTypeParamsToSnakeCase } from 'utils/String';
import { renderTextInputEditCell } from 'components/DatatableComponent/CustomTextInput';
import { getColumnDataType } from 'config/RulesImplementation/dataTypeMapping';
import { getErrorMessage, getErrorTitle } from 'utils/Error';
import { useDisplayEventTable } from 'services/v1/Tenant/AROEvent/EventTableService';
import { CUBE_EVENT_SYNC_STATUS } from 'constant/CubeConstant';
import { RULE_DATA_TYPE_DATE_TIME } from 'constant/RuleConstant';
import { getClosestNumericFieldIndex } from 'pages/Tenant/AROKMS/DataInput/utils/columnOrder';
import { generateColumnNames, handleExport } from 'utils/ExportUtils';

const ModalContentStyle = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  bgcolor: 'background.paper',
  height: 'auto',
  maxHeight: '95%',
  borderRadius: 1,
  overflow: 'auto',
  px: 3,
  py: 2,
};

const panelStyle = {
  transform: 'translate3d(704px, 140.667px, 0px) !important',

  '& .MuiDataGrid-panelFooter': {
    display: 'none',
  },
};

const closeIconStyle = { color: '#98A2AE', cursor: 'pointer' };

export interface ModalCubeEventDataDetailsProps {
  open: boolean;
  onClose: () => void;
  subjectId?: string;
  cubeId?: string;
  timePeriod?: string;
  cubeMember?: string;
  cubeMemberName?: string;
  title?: string;
  subtitle?: string;
}

export const TABS = {
  ANALYTIC_DATA: 0,
  ANALYTIC_CHART: 1,
};

interface OptionItem {
  value: string | number;
  label: string;
}

export function ModalCubeEventDataDetails(props: ModalCubeEventDataDetailsProps) {
  const {
    onClose,
    open,
    subjectId,
    cubeId,
    timePeriod,
    cubeMember,
    cubeMemberName,
    title = 'Data Detail',
    subtitle,
  } = props;
  const apiRef = useGridApiRef();
  const { data: subjectDetails } = useGetSubjectDetails(subjectId);

  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [rowData, setRowData] = useState<DynamicDataItem[]>([]);
  const [initialOption] = useState<OptionItem | null>(null);
  const [filter] = useState<DisplayTableParams>({
    page: 1,
    size: 20,
    filterValue: '',
    sortBy: undefined,
    sortType: 'asc',
    filterOperator: 'contains',
    filterColumn: undefined,
    subjectId: undefined,
  });

  const {
    data: dataRecords,
    isLoading: isLoadingTable,
    isFetching: isFetchingTable,
    isError,
    error,
  } = useDisplayEventTable({
    subjectId: subjectId,
    page: 0,
    size: 0,
    ktreeMemberName: cubeMember,
    timePeriod,
    cubeId,
  });

  const closestIndexField = useMemo(() => {
    if (dataRecords?.data.columns) {
      return getClosestNumericFieldIndex(dataRecords?.data.columns);
    }
    return -1;
  }, [dataRecords?.data?.columns]);

  const columns = useMemo<GridColumns>(() => {
    if (dataRecords?.data?.columns) {
      let dynamicColumns = dataRecords?.data?.columns?.map((column, index) => {
        const dataTypeCode = RULE_DATA_TYPE_DATE_TIME.has(column.ruleTypeCode) ? 'TYPE_DATE' : column.dataTypeCode;
        const ruleImplementation = RulesImplementation(column, convertRuleTypeParamsToSnakeCase(column.ruleTypeParams));
        const colDef = {
          editable: false,
          field: column.field,
          type: getColumnDataType(dataTypeCode),
          headerName: column.headerName,
          width: 150,
          align: 'left',
          headerAlign: 'left',

          renderEditCell: (params: GridRenderEditCellParams) =>
            // @ts-ignore
            renderTextInputEditCell({ ...params, type: getColumnDataType(column.dataTypeCode) }),

          ...ruleImplementation,

          filterOperators: getFilterOperatorByRuleTypeCode(column.ruleTypeCode),
        };
        if (index === 0) {
          // @ts-ignore
          colDef.valueGetter = (params: GridValueGetterParams) => {
            const { row } = params;
            if (row.id === 'SUBTOTAL') {
              return row.label;
            }
            // @ts-ignore
            if (ruleImplementation?.valueGetter) {
              // @ts-ignore
              return ruleImplementation?.valueGetter(params);
            }
          };
          // @ts-ignore
          colDef.colSpan = ({ row }: GridCellParams) => {
            if (row.id === 'SUBTOTAL') {
              return closestIndexField;
            }

            return undefined;
          };
        }

        return colDef;
      });

      return dynamicColumns as GridColumns;
    }

    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataRecords?.data.columns, subjectId, closestIndexField]);

  const checkGetCellClassName = React.useCallback((params) => {
    const { id } = params;

    let className = params.indexRelativeToCurrentPage % 2 === 0 ? '' : 'cube-odd';
    if (id === 'SUBTOTAL') {
      className += ' custom-cube-tree-data-grouping-cell font-bold';
    }

    return className;
  }, []);

  const handleOnClose = React.useCallback(() => {
    onClose();
    setRowData([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const tableTitle = useMemo(() => {
    if (!subjectDetails?.data) return '';
    return `Event: ${subjectDetails?.data?.displayName}`;
  }, [subjectDetails?.data]);

  useEffect(() => {
    // @ts-ignore
    if (dataRecords?.data?.data && dataRecords.data.data.length !== 0) {
      setRowData([
        ...dataRecords?.data?.data,
        {
          // @ts-ignore
          id: 'SUBTOTAL',
          label: 'Total',
          ...dataRecords.data.dataTotal,
        },
      ]);
    }
  }, [dataRecords?.data]);
  useEffect(() => {
    if (isError) setRowData([]);
  }, [isError]);

  const handleChangePage = () => {};

  const handleOnFilter = () => {};

  const handleChangePageSize = () => {};

  const handleOnExportClick = () => {
    const exportConfig = {
      columnNames: generateColumnNames(apiRef),
      keys: generateColumnNames(apiRef),
      sheetName: `${timePeriod}`,
      fileName: `${subjectDetails?.data?.displayName}_${cubeMemberName ? cubeMemberName : timePeriod}.xlsx`,
    };
    handleExport(apiRef, exportConfig);
  };

  return (
    <Modal open={open} onClose={handleOnClose}>
      <>
        <Stack direction='column' sx={ModalContentStyle} gap={1}>
          <Stack direction='column'>
            <Stack justifyContent='space-between' direction='row' alignItems='center' spacing={2}>
              <Stack direction='row' gap={1} justifyContent='center' alignItems='center'>
                <TableChartOutlinedIcon sx={{ color: '#42BB93' }} />
                <Stack>
                  <Typography variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
                    {title}
                  </Typography>
                  {subtitle && (
                    <Typography variant='caption' component='h5' fontWeight='bold' sx={{ color: '#999999' }}>
                      {subtitle}
                    </Typography>
                  )}
                </Stack>
              </Stack>

              <IconButton onClick={handleOnClose} component='label'>
                <HighlightOffRoundedIcon sx={closeIconStyle} />
              </IconButton>
            </Stack>
            <Divider sx={{ mb: 0 }} />
          </Stack>

          <Stack mt={1}></Stack>

          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Stack direction='row' justifyContent='space-between' alignSelf='flex-end' width='100%' mb={2}>
              <div />
              <Tooltip title='Export Data'>
                <IconButton
                  disabled={isLoadingTable}
                  data-testid='button-export'
                  aria-controls={open ? 'demo-customized-menu' : undefined}
                  aria-haspopup='true'
                  aria-expanded={open ? 'true' : undefined}
                  sx={{
                    opacity: isLoadingTable ? 0.5 : 1,
                  }}
                  onClick={handleOnExportClick}
                >
                  <FileDownloadOutlinedIcon sx={{ color: '#42BB93' }} />
                </IconButton>
              </Tooltip>
            </Stack>
            <Stack direction='row' justifyContent='space-between' alignItems='center'>
              <CustomDataTable
                apiRef={apiRef}
                isError={isError}
                errorMessage={error && getErrorMessage(error)}
                errorTitle={getErrorTitle(error)}
                errorData={error as AxiosDefaultErrorEntity}
                loading={isLoadingTable || isFetchingTable}
                rows={rowData}
                columns={columns}
                autoHeight={false}
                rowCount={dataRecords?.data.rowCount || 0}
                page={filter.page !== undefined ? filter.page - 1 : 0}
                getRowId={(row) => row?.id || 0}
                onPageChange={handleChangePage}
                checkboxSelection={false}
                onPageSizeChange={handleChangePageSize}
                getCellClassName={checkGetCellClassName}
                rowModesModel={rowModesModel}
                disableSelectionOnClick
                getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
                onFilterModelChange={handleOnFilter}
                sortingMode='client'
                filterMode='client'
                components={{
                  Toolbar: null,
                  Pagination: null,
                }}
                componentsProps={{
                  panel: {
                    sx: panelStyle,
                  },
                  toolbar: {
                    // @ts-ignore
                    viewOnly: true,
                    withChipLabel: true,

                    disabled: false,
                    isInserting: false,
                    setRows: setRowData,
                    setRowModesModel,
                    title: tableTitle,

                    options: [],
                    showSyncButton: dataRecords?.data.cubeSyncStatus === CUBE_EVENT_SYNC_STATUS.REQUIRED,

                    totalDependentCubes: dataRecords?.data?.totalDependentCube,
                    initialOption,
                    optionValue: null,
                    config: {
                      deleteButtonText: 'Delete',
                      insertButtonText: null,
                      fieldFocusOnInsert: '',
                      insertInitialObject: {
                        ...dataRecords?.data.columnInitialValues,
                        isNew: true,
                      },
                    },
                  },
                }}
              />
            </Stack>
          </Box>
        </Stack>
      </>
    </Modal>
  );
}
