import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import ButtonLoading from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Alert } from '@mui/material';
import { useLogout } from 'services/v1/Auth';
import { useDispatch } from 'react-redux';
import { logout, setSessionStatus } from 'store/reducer/authReducer';
import { removeAuthHeaderToken } from 'libs/Axios';
import { setTenantNavigation } from 'store/reducer/uiReducer';
const ModalContentStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 450,
  height: 270,
  bgcolor: 'background.paper',
  borderRadius: 1,
  px: 3,
  py: 2,
};

export interface ModalSessionExpiredProps {
  visible: boolean;
}

export function ModalSessionExpired(props: ModalSessionExpiredProps) {
  const { visible = false } = props;
  const dispatch = useDispatch();
  const { mutate: logoutUser, isLoading } = useLogout();

  const handleLogoutRedirect = () => {
    dispatch(setTenantNavigation(false));
    dispatch(logout());
    dispatch(setSessionStatus(null));
    removeAuthHeaderToken();
    // window.location.href = '/auth/login';
  };

  const handleLogout = () => {
    logoutUser(undefined, {
      onSuccess: handleLogoutRedirect,
      onError: handleLogoutRedirect,
    });
  };
  return (
    <div>
      <Modal open={visible}>
        <Stack direction='column' sx={ModalContentStyle}>
          <Stack justifyContent='space-between' direction='row' alignItems='center'>
            <Typography variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
              Session Expired
            </Typography>
          </Stack>
          <Divider sx={{ mb: 1 }} />
          <Stack sx={{ width: '100%', mt: 3 }}>
            <Alert severity='info'>
              It seems your session has expired. To ensure the security of your account and continue your progress,
              please log in again.
            </Alert>
          </Stack>
          <ButtonLoading
            type='submit'
            fullWidth
            onClick={handleLogout}
            loading={isLoading}
            loadingPosition='start'
            variant='main-login'
            sx={{
              mt: 4,
              mb: 2,
              textTransform: 'none',
            }}
          >
            Re-Login
          </ButtonLoading>
        </Stack>
      </Modal>
    </div>
  );
}
