import Snackbar from '@mui/material/Snackbar';
import Alert, { AlertProps } from '@mui/material/Alert';
import { useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  GridColDef,
  GridRowModesModel,
  GridRowModes,
  GridRowEditStopParams,
  GridRowParams,
  GridCellParams,
  getGridStringOperators,
  GridRenderCellParams,
} from '@mui/x-data-grid-pro';

import { CustomDataTable } from 'components/DatatableComponent';
import {
  useGetDisplayTableData,
  useUpdateDisplayTableData,
} from 'services/v1/TenantMaster/MasterTable/DisplayTableDataService';
import { DisplayTableDataType } from 'types/api/TenantMaster/MasterTable/DisplayTableDataTypes';
import { TenantHomeDashboardNavigationBar } from 'components/LayoutComponent/VerticalLayout/HeaderBar';
import { ButtonRecordEdit } from 'pages/Tenant/AROKMS/DataInput/components/ButtonRecordEdit';
import { useNavigate } from 'react-router-dom';
import { PATH_CONSTANT } from 'constant/PathConstant';
import { SettingPanel } from 'pages/Tenant/AROKMS/MemberList/components/MemberListPanel';

const panelStyle = {
  transform: 'translate3d(704px, 140.667px, 0px) !important',

  '& .MuiDataGrid-panelFooter': {
    display: 'none',
  },
};

export default function DisplayTableListPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [rowData, setRowData] = useState<DisplayTableDataType[]>([]);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

  const [filterButtonElement, setFilterButtonElement] = useState<HTMLButtonElement | null>(null);
  const [snackbar, setSnackbar] = useState<Pick<AlertProps, 'children' | 'severity'> | null>(null);

  // Hook Service for API Call
  const { data: subjectList, isLoading } = useGetDisplayTableData();
  const { mutateAsync: updateDisplayTableData } = useUpdateDisplayTableData();

  // Table Column Definitions
  const columns = useMemo<GridColDef[]>(() => {
    return [
      {
        field: 'action',
        type: 'actions',
        headerName: 'Edit',
        width: 30,
        editable: false,
        filterable: false,
        renderCell: (params: GridRenderCellParams<DisplayTableDataType>) => {
          return (
            // @ts-ignore
            <ButtonRecordEdit
              onClick={() => {
                navigate(PATH_CONSTANT.TENANT_MASTER.EDIT_DISPLAY_TABLE.replace(':id', params.id as string));
              }}
            />
          );
        },
      },
      {
        field: 'displayName',
        headerName: t('SIDEBAR_MENU.SUBJECT'),
        width: 200,
        editable: false,
        filterOperators: getGridStringOperators().filter((operator) => ['contains', 'equals'].includes(operator.value)),
      },
      {
        field: 'adminRightLevel',
        headerName: 'Admin Level',
        width: 100,
        editable: true,
        filterOperators: getGridStringOperators().filter((operator) => ['contains', 'equals'].includes(operator.value)),
      },
      {
        field: 'static',
        headerName: 'Static',
        width: 140,
        editable: false,
        filterable: false,
        renderCell: (params) => {
          return params.value ? 'Yes' : '';
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);

  useEffect(() => {
    if (subjectList?.data) {
      setRowData(subjectList?.data);
    }
  }, [subjectList]);

  // Process row Update will be triggered when
  // user modify or insert new row the table
  const handleProcessRowUpdate = (data: DisplayTableDataType, oldData: DisplayTableDataType) => {
    // If old row data is equal to new row data, then do nothing
    // (no need to update just return the data, because nothing changed)
    if (data.adminRightLevel === oldData.adminRightLevel) {
      // if oldData.isNew equals to true, then it means that this is a new row
      // andl cancle the row when cursor out of focust

      return oldData;
    }
    // otherwise, update the row data
    else {
      // if the row is not new, it means that the row n
      if (!data.isNew) {
        updateDisplayTableData({
          subjectId: data.id,
          adminRightLevel: data.adminRightLevel,
        });

        return data;
      }
    }
  };

  const handleRowEditStop = (params: GridRowEditStopParams) => {
    setRowModesModel({ ...rowModesModel, [params.id]: { mode: GridRowModes.View } });
  };

  const handleEditRowModes = (params: GridRowParams) => {
    setRowModesModel({ ...rowModesModel, [params.id]: { mode: GridRowModes.Edit } });
  };

  const handleCloseSnackbar = () => setSnackbar(null);

  return (
    <TenantHomeDashboardNavigationBar>
      <CustomDataTable
        sortingMode='client'
        filterMode='client'
        paginationMode='client'
        initialState={{
          pinnedColumns: {
            left: ['action'],
          },
          pagination: {
            page: 0,
          },
          sorting: {
            sortModel: [
              {
                field: 'subject',
                sort: 'asc',
              },
            ],
          },
        }}
        loading={isLoading}
        checkboxSelection={false}
        rows={rowData}
        columns={columns}
        getRowId={(row) => row.id}
        rowModesModel={rowModesModel}
        disableSelectionOnClick
        rowsPerPageOptions={[5, 10, 20]}
        getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
        experimentalFeatures={{ newEditingApi: true }}
        isCellEditable={(params: GridCellParams<DisplayTableDataType>) => {
          if (params.row.static) return false;
          return true;
        }}
        processRowUpdate={handleProcessRowUpdate}
        onRowEditStop={handleRowEditStop}
        onRowDoubleClick={handleEditRowModes}
        components={{
          Toolbar: SettingPanel,
        }}
        componentsProps={{
          panel: {
            sx: panelStyle,
            anchorEl: filterButtonElement,
          },
          toolbar: {
            setRows: setRowData,
            setRowModesModel,
            setFilterButtonElement: setFilterButtonElement,
            disabled: isLoading,
            config: {
              insertButtonText: null,
              withExport: false,
              deleteButtonText: 'Delete',
              fieldFocusOnInsert: '',
              insertInitialObject: {
                isNew: true,
              },
            },
          },
        }}
      />
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          onClose={handleCloseSnackbar}
          autoHideDuration={3000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
    </TenantHomeDashboardNavigationBar>
  );
}
