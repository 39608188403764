import { useMemo, useCallback } from 'react';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { Stack } from '@mui/material';
import { useGetDataChannelPreview } from 'services/v1/Tenant/AROEvent/EventDataSourceService';

const dataGridStyle = {
  '& .MuiCheckbox-root svg': {
    backgroundColor: 'transparent',
    color: '#98A2AE',
    borderRadius: 2,
  },
  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: '#EDF2FF',
    borderTop: '1px solid #E3EBF6',
    borderBottom: '1px solid #E3EBF6',
    textAlign: 'center',
    display: 'flex',
  },
};

export function DataSourcePreviewComponent(props: { dataChannelId?: string }) {
  const { dataChannelId } = props;
  const { data: dataSourcePreview, isLoading } = useGetDataChannelPreview(dataChannelId);
  const columns: GridColDef[] | any[] = useMemo(() => {
    if (dataSourcePreview?.data && dataSourcePreview?.data?.[0]) {
      const columns = Object.keys(dataSourcePreview.data[0]).map((key) => ({
        field: key,
        headerName: key,
        flex: 1,
        minWidth: 150,
        sortable: false,
        filterable: false,
      }));
      return columns;
    }

    return [];
  }, [dataSourcePreview]);

  const getRowId = useCallback(
    (row) => {
      if (dataSourcePreview?.data) {
        return dataSourcePreview.data.indexOf(row);
      }
      return 0;
    },
    [dataSourcePreview]
  );
  return (
    <Stack height={380} py={2}>
      <DataGridPro
        sx={{
          ...dataGridStyle,
          height: 360,
        }}
        autoHeight={false}
        loading={isLoading}
        pagination
        disableMultipleColumnsFiltering
        paginationMode='server'
        getRowId={getRowId}
        density='compact'
        rows={dataSourcePreview?.data || []}
        columns={columns}
        filterMode='server'
        hideFooter
        checkboxSelection={false}
      />
    </Stack>
  );
}
