import jwtDecode from 'jwt-decode';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import { RootState } from 'store';
import { checkAccess, checkAccessByAdminRightLevel } from 'routes/PrivateRoute';
import { SidebarItemsProps } from './SidebarItems';
import { ROLES } from 'constant/PermissonConstant';

export const SingleLevelMenu = ({ item }: { item: SidebarItemsProps }) => {
  const { pathname } = useLocation();
  const { threadUserAsRole, accessToken } = useSelector((state: RootState) => state.auth);

  const decodeToken = accessToken && (jwtDecode(accessToken) as { adminRightLevel: number });

  const { t } = useTranslation();

  const navigate = useNavigate();

  if (!checkAccess(item.allowedRoles, threadUserAsRole)) return null;

  if (
    threadUserAsRole === ROLES.ADMIN &&
    // @ts-ignore
    !checkAccessByAdminRightLevel(item.accessAdminRightLevel, decodeToken?.adminRightLevel || 0)
  )
    return null;

  return (
    <ListItem
      button
      sx={{
        backgroundColor: pathname === item.path ? '#00B3DC' : 'transparent',
        '&:hover': {
          backgroundColor: pathname === item.path ? '#00B3DC' : '#171A31',
        },
      }}
      onClick={() => {
        if (item.path) {
          navigate(item.path);
        }
      }}
    >
      <ListItemIcon>{item.icon}</ListItemIcon>
      <ListItemText
        primary={t(item.title)}
        color='#fff'
        primaryTypographyProps={{
          fontSize: 12,
          color: '#fff',
          letterSpacing: 0.3,
        }}
      />
    </ListItem>
  );
};
