import * as Yup from 'yup';
import { HTMLAttributes, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import ButtonLoading from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import AutoComplete from '@mui/material/Autocomplete';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { AutoCompleteItem } from 'types/api/Common/AutoCompleteTypes';
import { InsightViewAttributeItem } from 'types/api/SystemTenant/AROView/InsightViewDefinitionTypes';
import { useFormik } from 'formik';
import {
  useAddDataChannel,
  useGetDataChannelDetails,
  useGetDataChannelStatusDropdown,
  useGetDataWarehouseChannelTable,
  useGetDataWarehouseDetails,
  useUploadDataChannelLogo,
} from 'services/v1/TenantMaster/MasterTable/TenantExternalDataSourceService';
import { useParams } from 'react-router-dom';
import { RequestCreateDataChannel } from 'types/api/TenantMaster/MasterTable/TenantExternalDataSourceTypes';
import { Alert } from '@mui/material';
import { ModalConfirmationComponent } from 'components/ModalComponent/ModalConfirmationComponent';
const autoCompleteStyle = {
  '& .MuiAutocomplete-popper': {
    backgroundColor: 'red !imoprtant',
    fontSize: '10px',
  },
};
const ModalContentStyle = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 650,
  bgcolor: 'background.paper',
  maxHeight: 670,
  overflow: 'auto',
  borderRadius: 1,
  py: 2,
};

const closeIconStyle = { color: '#98A2AE', cursor: 'pointer' };

export interface ModalTenantDataChannelProps {
  visible?: boolean;
  onClose?: () => void;
  channelId?: string;
  selectedData?: InsightViewAttributeItem | null;
}

export type ModalTenantDataChannelState = Omit<ModalTenantDataChannelProps, 'onClose' | 'selectedData'>;

const formikValidationSchema = Yup.object().shape({
  name: Yup.string().required('This field is required').max(50, 'Maximum 50 characters allowed').nullable(),
  channelTable: Yup.object().required('This field is required').nullable(),
  description: Yup.string().required('This field is required').max(200, 'Maximum 200 characters allowed').nullable(),
  status: Yup.object().required('This field is required').nullable(),
});

export type FormikAddDataChannelValues = {
  name: string;
  channelTable: AutoCompleteItem | null;
  description: string;
  status: AutoCompleteItem | null;
};

export const formikInsightAttributeInitialValues: FormikAddDataChannelValues = {
  name: '',
  channelTable: null,
  description: '',
  status: null,
};

export function ModalAddTenantDataChannel(props: Readonly<ModalTenantDataChannelProps>) {
  const { onClose, visible = false, channelId, selectedData } = props;
  const { dataSourceId } = useParams<{ dataSourceId: string }>();

  const { data: channelStatusDropdownOptions, isLoading } = useGetDataChannelStatusDropdown();
  const { data: dataWarehouseDetails, isLoading: isLoadingWarehouseDetails } = useGetDataWarehouseDetails(dataSourceId);
  const { data: dataChannelDetails } = useGetDataChannelDetails(channelId);
  const { data: channelTableDropdownOptions, isLoading: isLoadingChannelTable } =
    useGetDataWarehouseChannelTable(dataSourceId);

  const [showModalConfirmation, setShowModalConfirmation] = useState(false);
  const [selectedTargetStatus, setSelectedTargetStatus] = useState<AutoCompleteItem | null>(null);
  const [selectedFileBuffer, setSelectedFileBuffer] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState<string | ArrayBuffer | null>(null);

  const { mutate: addDataChannel, isLoading: isCreating } = useAddDataChannel();
  const { mutateAsync: uploadDataChannelLogo, isLoading: isUploading } = useUploadDataChannelLogo();

  const formik = useFormik<FormikAddDataChannelValues>({
    initialValues: formikInsightAttributeInitialValues,
    validationSchema: formikValidationSchema,
    onSubmit: () => {},
  });

  const handleOnCloseModal = () => {
    onClose?.();
    selectedFileBuffer && setSelectedFileBuffer(null);
    setImagePreviewUrl(null);
    formik.resetForm();
  };

  const handleOnCancel = () => {
    handleOnCloseModal();
  };
  const handleOnDelete = () => {
    handleOnCloseModal();
  };

  const handleOnSave = async () => {
    let logoPictureURL = null;
    if (selectedFileBuffer) {
      const formData = new FormData();
      formData.append('file', selectedFileBuffer);
      const response = await uploadDataChannelLogo(formData);
      logoPictureURL = response.data?.fileURL;
    }
    if (
      formik.values.channelTable &&
      formik.values.status &&
      formik.values.name &&
      formik.values.description &&
      formik.values.status &&
      dataSourceId
    ) {
      const payload: RequestCreateDataChannel = {
        channelTableId: formik.values.channelTable?.value.toString(),
        dataWarehouseId: dataSourceId,
        description: formik.values.description,
        logoUrl: selectedFileBuffer ? logoPictureURL : dataChannelDetails?.data?.logoUrl ?? null,
        name: formik.values.name,
        status: formik.values.status?.value.toString(),
      };
      if (channelId) payload.id = channelId;

      addDataChannel(payload, {
        onSuccess: (res) => {
          toast.success(channelId ? 'Data Channel updated successfully' : 'Data Channel added successfully');
          handleOnCloseModal();
        },
      });
    }
  };

  // @ts-ignore
  const handleOnUploadFileChange = (e) => {
    //chedck file size with maximum 5mb and type, only accept image
    if (e.target.files[0].size > 5000000) {
      toast('File size is too large. Maximum 5mb is allowed');
      return;
    }
    if (!e.target.files[0].type.includes('image')) {
      toast('Only image file is allowed');
      return;
    }
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      setSelectedFileBuffer(file);
      reader.result && setImagePreviewUrl(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleOnChannelStatusChange = (event: React.SyntheticEvent, value: AutoCompleteItem | null) => {
    if (channelId && dataChannelDetails?.data?.connectedWithTenant && formik.values.status?.value === 'ACTIVE') {
      setSelectedTargetStatus(value);
      setShowModalConfirmation(true);
    } else {
      formik.setFieldValue('status', value);
    }
  };

  const handleOnApproveInactiveDataChannel = () => {
    if (selectedTargetStatus) {
      formik.setFieldValue('status', selectedTargetStatus);
      setShowModalConfirmation(false);
    }
  };

  const renderOption = (props: HTMLAttributes<HTMLElement>, option: AutoCompleteItem) => {
    return (
      <Box component='li' sx={{ '& > span': { fontSize: '14px', mr: 1, flexShrink: 0 } }} {...props}>
        <span>{option.label}</span>
      </Box>
    );
  };
  const isLoadingData = isLoading || isLoadingWarehouseDetails || isLoadingChannelTable;

  const isDisabledChannelTableEdit = useMemo(() => {
    if (dataChannelDetails?.data) {
      return dataChannelDetails.data?.connectedWithTenant;
    }
  }, [dataChannelDetails?.data]);

  const isDisableForm = isCreating || isLoadingData || isUploading;
  const isValidForm = formikValidationSchema.isValidSync(formik.values);

  useEffect(() => {
    if (channelId && dataChannelDetails?.data) {
      formik.setValues({
        channelTable: dataChannelDetails.data?.channelTable,
        description: dataChannelDetails.data?.description,
        name: dataChannelDetails.data?.name,
        status: dataChannelDetails.data?.status,
      });
      dataChannelDetails?.data?.logoUrl && setImagePreviewUrl(dataChannelDetails?.data?.logoUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataChannelDetails, channelId]);

  return (
    <div>
      <Modal open={visible} onClose={handleOnCloseModal}>
        <Stack direction='column' sx={ModalContentStyle} px={2}>
          <Stack>
            <Stack justifyContent='space-between' direction='row' alignItems='center'>
              <Typography variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
                {channelId ? 'Edit' : 'Add'} Data Channel
              </Typography>
              <IconButton onClick={handleOnCloseModal} component='label'>
                <HighlightOffRoundedIcon sx={closeIconStyle} />
              </IconButton>
            </Stack>
          </Stack>
          <Divider sx={{ mb: 1 }} />

          <Stack width='85%'>
            <Stack mb={1.6} width='100%'>
              <Typography variant='body2' fontWeight='bold' color='black'>
                Channel Logo
              </Typography>
              <Typography variant='input-label-gray' mb={1}>
                Upload the logo for the channel. Make user aware of the channel. This will be displayed in the User
                Interface.
              </Typography>

              <label htmlFor='contained-button-file'>
                {imagePreviewUrl && (
                  <img
                    src={imagePreviewUrl ? imagePreviewUrl.toString() : '/assets/images/placeholder-image.png'}
                    alt='Channel Logo'
                    style={{
                      margin: '10px',
                      width: '400px',
                      height: '80px',
                      overflow: 'hidden',
                      objectFit: 'contain',
                      objectPosition: 'center',
                    }}
                  />
                )}
              </label>
              <Stack
                sx={{
                  width: '30%',
                }}
              >
                <Button
                  component='label'
                  role={undefined}
                  variant='main-table-panel'
                  tabIndex={-1}
                  startIcon={<CloudUploadIcon />}
                >
                  {imagePreviewUrl ? 'Change Logo' : 'Upload Logo'}
                  <input
                    accept='image/*'
                    id='contained-button-file'
                    hidden
                    type='file'
                    onChange={handleOnUploadFileChange}
                  />
                </Button>
              </Stack>
            </Stack>
            <Stack mb={1.6} width='100%'>
              <Typography variant='body2' fontWeight='bold' color='black'>
                Channel Name
              </Typography>
              <Typography variant='input-label-gray' mb={1}>
                Input the name of the channel. This will be displayed in the User Interface.
              </Typography>
              <TextField
                size='small'
                placeholder='e.g. McNaughts Bustle Channel'
                value={formik.values.name}
                onBlur={formik.handleBlur}
                disabled={isDisableForm}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                name='name'
              />
            </Stack>
            <Stack mb={1.6} width='100%'>
              <Typography variant='body2' fontWeight='bold' color='black'>
                Channel Table
              </Typography>
              {dataWarehouseDetails?.data?.name && (
                <Typography variant='input-label-gray' mb={1}>
                  {`Select the table from ${dataWarehouseDetails?.data?.name} that you want to use as the source for this channel.`}
                </Typography>
              )}
              {isDisabledChannelTableEdit && (
                <Stack mb={1}>
                  <Alert severity='info'>
                    <Typography variant='input-label-gray' mb={1}>
                      {`This channel is connected with ${dataChannelDetails?.data?.totalConnectedTenant} tenant(s). You can not change the channel table until the channel has no connected tenant.`}
                    </Typography>
                  </Alert>
                </Stack>
              )}

              <AutoComplete
                clearIcon={null}
                size='small'
                value={formik.values.channelTable}
                disabled={isDisableForm || isDisabledChannelTableEdit}
                onChange={(e, value) => {
                  formik.setFieldValue('channelTable', value);
                }}
                onBlur={formik.handleBlur}
                getOptionLabel={(option: AutoCompleteItem) => option.label}
                options={channelTableDropdownOptions?.data ?? []}
                renderOption={renderOption}
                sx={autoCompleteStyle}
                renderInput={(params) => (
                  <Stack direction='row' justifyContent='space-between' display='flex' alignItems='center'>
                    <TextField
                      {...params}
                      placeholder='Select Table'
                      name='channelTable'
                      error={formik.touched.channelTable && Boolean(formik.errors.channelTable)}
                      helperText={formik.touched.channelTable && formik.errors.channelTable}
                    />
                  </Stack>
                )}
              />
            </Stack>
            <Stack mb={1.6} width='100%'>
              <Typography variant='body2' fontWeight='bold' color='black'>
                Channel Status
              </Typography>
              <Typography variant='input-label-gray' mb={1}>
                Select the table from MCN Datawarehouse that you want to use as the source for this channel.
              </Typography>
              <AutoComplete
                clearIcon={null}
                size='small'
                value={formik.values.status}
                disabled={isDisableForm}
                onChange={handleOnChannelStatusChange}
                onBlur={formik.handleBlur}
                getOptionLabel={(option: AutoCompleteItem) => option.label}
                options={channelStatusDropdownOptions?.data ?? []}
                renderOption={renderOption}
                sx={autoCompleteStyle}
                renderInput={(params) => (
                  <Stack direction='row' justifyContent='space-between' display='flex' alignItems='center'>
                    <TextField
                      {...params}
                      placeholder='Select Status'
                      name='status'
                      error={formik.touched.status && Boolean(formik.errors.status)}
                      helperText={formik.touched.status && formik.errors.status}
                    />
                  </Stack>
                )}
              />
            </Stack>
            <Stack mb={1.6} width='100%'>
              <Typography variant='body2' fontWeight='bold' color='black'>
                Channel Description
              </Typography>
              <Typography variant='input-label-gray' mb={1}>
                Input the name of the channel. This will be displayed in the User Interface.
              </Typography>
              <TextField
                size='small'
                placeholder='e.g. McNaughts Bustle Channel'
                disabled={isDisableForm}
                value={formik.values.description}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={formik.touched.description && Boolean(formik.errors.description)}
                helperText={formik.touched.description && formik.errors.description}
                multiline
                rows={5}
                name='description'
              />
            </Stack>
          </Stack>

          <Stack height={300} px={2}></Stack>
          <Stack px={2}>
            <Divider sx={{ mb: 1, mt: 2 }} />
            <Stack direction='row' justifyContent='flex-end' spacing={2} alignItems='center' sx={{ py: 1 }}>
              {selectedData && (
                <Button
                  onClick={handleOnDelete}
                  disabled={isDisableForm}
                  variant='outlined'
                  sx={{
                    textTransform: 'none',
                  }}
                  color='error'
                >
                  Delete
                </Button>
              )}
              <Button variant='main-table-panel-border' onClick={handleOnCancel} disabled={isDisableForm}>
                Cancel
              </Button>
              <ButtonLoading
                variant='main-table-panel'
                disabled={!isValidForm || isDisableForm}
                onClick={() => handleOnSave()}
              >
                Save Channel
              </ButtonLoading>
            </Stack>
          </Stack>
        </Stack>
      </Modal>
      <ModalConfirmationComponent
        visible={showModalConfirmation}
        title='Inactive Data Channel'
        width={600}
        onApprove={handleOnApproveInactiveDataChannel}
        onCancel={() => setShowModalConfirmation(false)}
        onClose={() => setShowModalConfirmation(false)}
        message={`Attention: The following tenant is connected to this channel: 
                ${dataChannelDetails?.data?.connectedTenants?.join(', ')}

        If you change the status to "Inactive," this channel will be disabled. As a result, all scheduled data synchronizations will be paused until you reactivate the channel.
        
        Are you certain you want to proceed with changing the status to "Inactive"?`}
        labelButtonApprove='Yes, Set to Inactive '
      />
    </div>
  );
}
