import * as React from 'react';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { MigrationDetailsItem } from 'types/api/Tenant/DataMigrationTypes';
import { Button, Chip } from '@mui/material';
import { ModalListTableDefinition } from './ModalListTableDefinition';

interface IProps {
  data: MigrationDetailsItem[];
  tenantDataSource: string;
  tenantTarget: string;
}

const chipStyle = {
  fontSize: '13px',
};

const tableContainerStyle = {
  border: 'none',
  boxShadow: 'none',
};

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#EDF2FF',
    color: theme.palette.common.black,
  },
}));

function getChipLabelMigrationStatus(status: string) {
  switch (status) {
    case 'FULLY_MIGRATED':
      return <Chip label='Fully Migrate' color='info' variant='outlined' size='small' sx={chipStyle} />;
    case 'PARTIALLY_MIGRATED':
      return <Chip label='Partialy Migrate' color='warning' variant='outlined' size='small' sx={chipStyle} />;
    default:
      return <Chip label='Not Available' color='error' variant='outlined' size='small' sx={chipStyle} />;
  }
}

function Row(props: { row: MigrationDetailsItem; onDetailClick: (data: MigrationDetailsItem) => void }) {
  const { row, onDetailClick } = props;

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell component='th' scope='row'>
          {row.sourceSubjectId}
        </TableCell>
        <TableCell>{row.targetSubjectId}</TableCell>
        <TableCell>{getChipLabelMigrationStatus(row.migrationStatus)}</TableCell>
        <TableCell>
          <Button variant='secondary-table-panel' onClick={() => onDetailClick(row)}>
            Detail
          </Button>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export function TableListSchema(props: IProps) {
  const { data, tenantDataSource, tenantTarget } = props;
  const [showModalTableDefinitionList, setShowModalTableDefinitionList] = React.useState<{
    visible: boolean;
    data: MigrationDetailsItem | null;
  }>({
    visible: false,
    data: null,
  });

  const handleOnDetailClick = (data: MigrationDetailsItem) => {
    setShowModalTableDefinitionList({
      visible: true,
      data,
    });
  };

  return (
    <Stack height={400} borderRadius={2} border='1px solid #E3EBF6'>
      <Typography variant='body1' mb={2} pl={2} pt={2}>
        Table Mapping
      </Typography>
      <TableContainer component={Paper} sx={tableContainerStyle}>
        <Table stickyHeader aria-label='collapsible table' size='small'>
          <TableHead>
            <TableRow>
              <StyledTableCell>{tenantDataSource}</StyledTableCell>
              <StyledTableCell>{tenantTarget}</StyledTableCell>
              <StyledTableCell>Migration Status</StyledTableCell>
              <StyledTableCell>View</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <Row key={row.id} row={row} onDetailClick={handleOnDetailClick} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <ModalListTableDefinition
        visible={showModalTableDefinitionList.visible}
        data={showModalTableDefinitionList.data}
        onClose={() => setShowModalTableDefinitionList({ visible: false, data: null })}
      />
    </Stack>
  );
}
