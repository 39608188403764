import { useState } from 'react';
import { PeopleConfigListComponent } from './PeopleConfigListComponent';
import { PeopleConfigDetailComponent } from './PeopleConfigDetailComponent';

enum PageContentID {
  PEOPLE_CONFIG_LIST = 'PEOPLE_CONFIG_LIST',
  PEOPLE_CONFIG_DETAILS = 'PEOPLE_CONFIG_DETAILS',
}

export const PeopleConfigurationPage = () => {
  const [pageContent, setPageContent] = useState<PageContentID>(PageContentID.PEOPLE_CONFIG_LIST);
  const [selectedUser, setSelectedUser] = useState<{ id: string; fullName: string } | null>(null);

  const handleOnUserClick = (user: { id: string; fullName: string }) => {
    setSelectedUser(user);
    setPageContent(PageContentID.PEOPLE_CONFIG_DETAILS);
  };

  switch (pageContent) {
    case PageContentID.PEOPLE_CONFIG_LIST:
      return <PeopleConfigListComponent onUserClick={handleOnUserClick} />;

    case PageContentID.PEOPLE_CONFIG_DETAILS:
      return (
        <PeopleConfigDetailComponent
          selectedUser={selectedUser}
          onClose={() => {
            setPageContent(PageContentID.PEOPLE_CONFIG_LIST);
            setSelectedUser(null);
          }}
        />
      );
    default:
      return null;
  }
};
