import { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import { Avatar, Box, Button, Grid, Stack, Typography } from '@mui/material';
import { TenantHomeDashboardNavigationBar } from 'components/LayoutComponent/VerticalLayout/HeaderBar';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { CustomDataTable } from 'components/DatatableComponent';
import { GridActionsCellItem, GridColumns, GridRenderCellParams, getGridStringOperators } from '@mui/x-data-grid-pro';
import { getInitialsName } from 'utils/String';
import { ModalAddTenantDataChannel, ModalTenantDataChannelState } from './ModalAddDataChannel';
import {
  useGetDataChannels,
  useGetDataWarehouseDetails,
} from 'services/v1/TenantMaster/MasterTable/TenantExternalDataSourceService';
import { PATH_CONSTANT } from 'constant/PathConstant';

export const autocompleteStyles = {
  width: '55%',
  '& .MuiOutlinedInput-root': {
    padding: '1px 2px',
  },
  '& .MuiAutocomplete-popper': {
    fontSize: '10px',
  },
};

export const textInputAutoCompleteStyles = {
  '& .MuiOutlinedInput': {
    padding: '1px 2px',
  },
};

export default function AddTenantDataChannelPage() {
  const { dataSourceId } = useParams<{ dataSourceId: string }>();
  const navigate = useNavigate();
  const { data: dataWarehouseDetails, isLoading: isLoadingWarehouseDetails } = useGetDataWarehouseDetails(dataSourceId);
  const { data: dataChannelList } = useGetDataChannels(dataSourceId);

  const [modalAddChannel, setModalAddChannel] = useState<ModalTenantDataChannelState>({
    visible: false,
    channelId: undefined,
  });

  const handleEditRowClick = (id: string) => () => {
    setModalAddChannel({
      visible: true,
      channelId: id,
    });
  };
  const columns = useMemo<GridColumns>(() => {
    return [
      {
        field: 'action',
        type: 'actions',
        headerName: 'Edit',
        width: 30,
        editable: false,
        filterable: false,
        getActions: ({ id, row }) => {
          return [
            <GridActionsCellItem
              key={id}
              title='Edit'
              showInMenu={false}
              nonce={id.toString()}
              onResize={() => {}}
              onResizeCapture={() => {}}
              icon={<EditIcon />}
              label='Edit'
              onClick={handleEditRowClick(id.toString())}
            />,
          ];
        },
      },
      {
        field: 'logo',
        headerName: 'Logo',
        width: 110,
        editable: false,
        renderCell: (params: GridRenderCellParams) => {
          if (!params.row.logoUrl)
            return (
              <Avatar variant='square' sx={{ bgcolor: '#9e9d9d' }}>
                {getInitialsName(params.row.name)}
              </Avatar>
            );

          return (
            <Avatar
              src={params.row.logoUrl}
              sx={{
                width: 100,
                height: 40,
              }}
              imgProps={{ style: { objectFit: 'contain' } }}
              title={params.row.name}
              variant='square'
            />
          );
        },
      },
      {
        field: 'name',
        headerName: 'Channel Name',
        width: 240,

        editable: false,
        filterOperators: getGridStringOperators().filter((operator) => ['contains', 'equals'].includes(operator.value)),
      },

      {
        field: 'channelTable',
        headerName: 'Channel Table',
        width: 200,
        editable: false,
        valueGetter(params) {
          return params.row.channelTable?.label;
        },
        filterOperators: getGridStringOperators().filter((operator) => ['contains', 'equals'].includes(operator.value)),
      },
      {
        field: 'status',
        headerName: 'Status',
        width: 150,
        editable: false,
        valueGetter(params) {
          return params.row.status?.label;
        },
        filterOperators: getGridStringOperators().filter((operator) => ['contains', 'equals'].includes(operator.value)),
      },
      {
        field: 'description',
        headerName: 'Description',
        width: 300,
        editable: false,
        filterable: false,
      },
      {
        field: 'totalConnectedTenant',
        headerName: 'Connected Tenant',
        width: 120,
        editable: false,
        filterOperators: getGridStringOperators().filter((operator) => ['contains', 'equals'].includes(operator.value)),
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddData = () => {
    setModalAddChannel({
      visible: true,
      channelId: undefined,
    });
  };

  const handleOnBackButtonClick = () => {
    navigate(PATH_CONSTANT.TENANT_MASTER.DATAWAREHOUSE.LIST);
  };
  return (
    <TenantHomeDashboardNavigationBar onBackButtonClick={handleOnBackButtonClick}>
      <Box sx={{ height: '100%' }}>
        <Box sx={{ backgroundColor: '#fff', pb: 3, px: 3 }} alignItems='center'>
          <Stack
            direction='row'
            alignItems='center'
            py={1}
            justifyContent='space-between'
            sx={{ backgroundColor: '#fff' }}
            spacing={2}
          >
            <Stack>
              <Typography variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
                Data Channel
              </Typography>
              {dataWarehouseDetails?.data?.name && (
                <Typography variant='input-label' fontStyle='italic' sx={{ fontSize: 13 }}>
                  {dataWarehouseDetails.data?.name}
                </Typography>
              )}
            </Stack>
          </Stack>

          <Grid
            container
            marginTop={2}
            sx={{ backgroundColor: '#fff', py: 1, borderTop: '1px solid #E3EBF6' }}
            justifyContent='space-between'
            display='flex'
            flexWrap='wrap'
            alignItems='flex-start'
          >
            <Stack width='100%' direction='column' gap={1}>
              <Stack width={200}>
                <Button
                  disabled={isLoadingWarehouseDetails}
                  variant='main-table-panel'
                  startIcon={<AddCircleOutlineOutlinedIcon />}
                  onClick={handleAddData}
                >
                  Add Data Channel
                </Button>
              </Stack>
              <CustomDataTable
                initialState={{
                  pinnedColumns: {
                    left: ['action'],
                  },
                  pagination: {
                    page: 0,
                  },
                }}
                density='comfortable'
                rows={dataChannelList?.data ?? []}
                columns={columns}
                getRowId={(row) => row.id}
                sx={{
                  paddingLeft: 0,
                  paddingRight: 0,
                }}
                loading={isLoadingWarehouseDetails}
                disableSelectionOnClick
                checkboxSelection={false}
                rowsPerPageOptions={[5, 10, 20]}
                getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
                components={{
                  Toolbar: null,
                  Pagination: null,
                }}
              />
            </Stack>
          </Grid>
        </Box>
      </Box>
      <ModalAddTenantDataChannel
        onClose={() => {
          setModalAddChannel({
            visible: false,
            channelId: undefined,
          });
        }}
        channelId={modalAddChannel.channelId}
        visible={modalAddChannel.visible}
      />
    </TenantHomeDashboardNavigationBar>
  );
}
