import { GridPreProcessEditCellProps, GridRenderEditCellParams } from '@mui/x-data-grid-pro';

import { DataGridCellTextInput } from 'components/DatatableComponent/RenderCustomTextInput';
import { renderTextInputEditCell } from 'components/DatatableComponent/CustomTextInput';
import { generalSubstringFunction } from 'utils/String';
import { Column } from 'types/api/Tenant/AROKMS/DisplayTableTypes';
import { isValidJSONString } from 'utils/Object';

// Text Rule

// #RULE_CHAR_LIMIT
export function RuleTextInputLimitLength(limit: number | undefined, isRequired: boolean = false) {
  return {
    renderEditCell: (params: GridRenderEditCellParams) => {
      // @ts-ignore
      return renderTextInputEditCell({ ...params, maxLength: limit, isRequired });
    },
  };
}

// #RULE_ATTRIBUTE_LEFT
export function extractLeftCharacter(column: string, character: number) {
  return {
    type: 'text',
    renderEditCell: (params: GridRenderEditCellParams) => <DataGridCellTextInput {...params} />,
    preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
      let value = params?.otherFieldsProps?.[column].value || '';
      if (typeof value === 'object') {
        value = value.label;
      }
      return { ...params.props, value: value.substring(0, character) };
    },
  };
}

// #RULE_ATTRIBUTE_MID
export function extractMidCharachter(column: string, start: number, end: number) {
  return {
    type: 'text',
    renderEditCell: (params: GridRenderEditCellParams) => <DataGridCellTextInput {...params} />,
    preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
      let value = params?.otherFieldsProps?.[column].value || '';
      if (typeof value === 'object') {
        value = value.label;
      }

      return { ...params.props, value: value.substring(start - 1, end) };
    },
  };
}

// #RULE_CONCATENATE_ATTRIBUTES
export function concatenanteCharacterWithSpace(columnItem: Column, columns: string[]) {
  const ruleParamObj = isValidJSONString(columnItem.ruleTypeParams) ? JSON.parse(columnItem.ruleTypeParams) : null;
  const concateColumns: string[] = ruleParamObj ? ruleParamObj?.['ATTRIBUTE_CONCATENATION'] : columnItem.ruleTypeParams;
  const concateRule = ruleParamObj ? ruleParamObj?.['ATTRIBUTE_CONCATENATION_RULES']?.value : null;
  return {
    type: 'text',
    renderEditCell: (params: GridRenderEditCellParams) => <DataGridCellTextInput {...params} />,
    preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
      const values = concateColumns.map((field) => {
        const fieldValue: string | { label: string; value: string } = params?.otherFieldsProps?.[field]?.value || '';
        if (typeof fieldValue === 'object') return fieldValue.label;
        return fieldValue;
      });

      let finalValue = null;

      if (concateRule === 'NO_CONCATE_IF_COLUMN_EMPTY') {
        const isEveryValueFilled = values.every((val) => val);
        if (isEveryValueFilled) {
          finalValue = values.join(' ');
        }
      } else {
        finalValue = values.join(' ');
      }

      return { ...params.props, value: finalValue };
    },
  };
}

// #RULE_MID_FIND_ATTRIBUTES
export function extractMidCharacterWithFind(column: string, find: string, start: number, end: number) {
  return {
    type: 'text',
    renderEditCell: (params: GridRenderEditCellParams) => <DataGridCellTextInput {...params} />,
    preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
      const value = params?.otherFieldsProps?.[column].value || '';
      const index = value.indexOf(find);
      return { ...params.props, value: value.substring(index + start, index + end) };
    },
  };
}

// #RULE_LEFT_FIND_ATTRIBUTES
export function extractLeftCharacterWithFind(column: string, find: string, character: number) {
  return {
    type: 'text',
    renderEditCell: (params: GridRenderEditCellParams) => <DataGridCellTextInput {...params} />,
    preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
      const value = params?.otherFieldsProps?.[column].value || '';
      const index = value.indexOf(find);
      return { ...params.props, value: value.substring(0, index + character) };
    },
  };
}

// #RULE_GENERAL_SUB_STRING
export function generalSubstringRule(
  column: string,
  find: string,
  startChar: number | undefined | string,
  numberToAdd: number | undefined,
  endChar: number | undefined | string
) {
  return {
    type: 'text',
    renderEditCell: (params: GridRenderEditCellParams) => <DataGridCellTextInput {...params} />,
    preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
      let value = params?.otherFieldsProps?.[column]?.value || '';

      if (typeof value === 'object') value = value.label;

      const resultValue = generalSubstringFunction(value, find, startChar, numberToAdd, endChar);

      return { ...params.props, value: resultValue };
    },
  };
}
