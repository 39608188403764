import * as React from 'react';
import { TenantHomeDashboardNavigationBar } from 'components/LayoutComponent/VerticalLayout/HeaderBar';
import { Box, Paper, Typography, SvgIcon, Stack, Button, Alert } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import { ReactComponent as CustomLicenseIcon } from 'assets/icons/icon-dashboard-license.svg';
import { ReactComponent as CustomIconUser } from 'assets/icons/icon-dashboard-user.svg';
import { ReactComponent as CustomIconManager } from 'assets/icons/icon-dashboard-manager.svg';
import { ReactComponent as CustomIconSupport } from 'assets/icons/icon-dashboard-support.svg';
import { DashboardChart } from 'pages/TenantMaster/AdminDashboard/components/DashboardChart';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import {
  useAdminDashboardTotalUser,
  useAdminDashboardUserAcivity,
} from 'services/v1/TenantMaster/MasterTable/AdminDashboardService';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { ModalListAcitivityUser } from './components/ModalListUserActivity';
import ActivityIndicator from 'components/ActivityIndicatorComponent';
import { getErrorMessage } from 'utils/Error';

const INFO_TYPES = {
  LICENSE: 'license',
  REGISTERED_USER: 'registered_user',
  MANAGERS: 'managers',
  SUPPORT: 'support',
};

function getInfoBoxIcon(infoType: string) {
  switch (infoType) {
    case INFO_TYPES.LICENSE:
      return CustomLicenseIcon;
    case INFO_TYPES.REGISTERED_USER:
      return CustomIconUser;
    case INFO_TYPES.MANAGERS:
      return CustomIconManager;
    case INFO_TYPES.SUPPORT:
      return CustomIconSupport;
    default:
      return CustomLicenseIcon;
  }
}

function ListLastUserLogin(props: { onClickShowAll?: () => void }) {
  const { onClickShowAll } = props;
  const {
    data: userActivity,
    isLoading: isLoadingUserActivity,
    isError,
    error,
  } = useAdminDashboardUserAcivity(
    {
      page: 1,
      size: 5,
    },
    true
  );
  return (
    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
      {isError && <Alert severity='error'>{getErrorMessage(error)}</Alert>}
      {userActivity?.data.data.length === 0 && (
        <Typography variant='input-label' fontStyle='italic'>
          There is no user activity
        </Typography>
      )}

      {isLoadingUserActivity && (
        <Stack height={200}>
          <ActivityIndicator />
        </Stack>
      )}
      {!isLoadingUserActivity &&
        userActivity?.data.data.map((item, index) => {
          const isLastItem = index === userActivity?.data.data.length - 1;
          return (
            <>
              <ListItem
                key={item.id}
                sx={{
                  px: 0,
                  py: 0.3,
                }}
              >
                <ListItemAvatar>
                  <Avatar
                    sx={{ bgcolor: '#00B3DC', width: 40, height: 40 }}
                    alt={item.fullName}
                    src='/static/images/avatar/1.jpg'
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <>
                      <Typography variant='body2'>{item.fullName}</Typography>
                    </>
                  }
                  secondary={
                    <React.Fragment>
                      <Typography variant='input-label-gray' sx={{ fontSize: 12 }}>
                        {item.description}
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>
              {!isLastItem && <Divider />}
            </>
          );
        })}

      {!isLoadingUserActivity && userActivity?.data?.data.length === 5 && (
        <Button
          onClick={onClickShowAll}
          sx={{
            textTransform: 'none',
            color: '#00B3DC',
            fontSize: 13,
          }}
          endIcon={<ArrowForwardOutlinedIcon />}
        >
          View All
        </Button>
      )}
    </List>
  );
}

function InfoBox(props: { title: string; value?: string | number; type: string; loading?: boolean }) {
  const { title, value, type, loading } = props;
  return (
    <Paper sx={{ width: '100%' }} variant='outlined'>
      <Stack direction='row' alignItems='center' gap={2} justifyContent='center' py={3}>
        {loading ? (
          <ActivityIndicator size={25} />
        ) : (
          <>
            <SvgIcon inheritViewBox fontSize='large' component={getInfoBoxIcon(type)} />
            <Stack>
              <Typography variant='input-label-gray' sx={{ fontSize: 12 }}>
                {title}
              </Typography>
              {value && (
                <Typography variant='body2' fontWeight='bold'>
                  {value}
                </Typography>
              )}
            </Stack>
          </>
        )}
      </Stack>
    </Paper>
  );
}

export default function AdminDashboard() {
  const { selectedTenant } = useSelector((state: RootState) => state.auth);
  const { data: totalUsers, isLoading: isLoadingTotalUsers } = useAdminDashboardTotalUser(selectedTenant.tenant?.id);

  const [showModalListActivityUser, setShowModalListActivityUser] = React.useState(false);

  const handleCloseModalListActivityUser = () => {
    setShowModalListActivityUser(false);
  };

  const handleOpenModalListActivityUser = () => {
    setShowModalListActivityUser(true);
  };
  return (
    <TenantHomeDashboardNavigationBar
      containerSx={{
        bgcolor: 'transparent',
        boxShadow: 'none',
      }}
    >
      <Stack direction='row' spacing={2}>
        <Stack direction='column' width='77%'>
          <Stack
            direction='row'
            width='100%'
            gap={1.5}
            pb={2}
            sx={{
              bgcolor: 'none',
            }}
          >
            <InfoBox
              title='License'
              value={totalUsers?.data.licenseName}
              type={INFO_TYPES.LICENSE}
              loading={isLoadingTotalUsers}
            />
            <InfoBox
              title='User'
              value={totalUsers?.data?.totalUser}
              type={INFO_TYPES.REGISTERED_USER}
              loading={isLoadingTotalUsers}
            />
            <InfoBox
              title='Admin'
              value={totalUsers?.data?.totalAdmin}
              type={INFO_TYPES.MANAGERS}
              loading={isLoadingTotalUsers}
            />
            <InfoBox
              title='Manager'
              value={totalUsers?.data?.totalManager}
              type={INFO_TYPES.MANAGERS}
              loading={isLoadingTotalUsers}
            />
            <InfoBox
              title='Support'
              value={totalUsers?.data?.totalSupport}
              type={INFO_TYPES.SUPPORT}
              loading={isLoadingTotalUsers}
            />
          </Stack>
          <Stack>
            <Paper variant='outlined'>
              <Box p={2}>
                <Typography variant='body2' fontWeight='bold'>
                  Recent Activity
                </Typography>
                <Typography variant='input-label'>Summary of user logins during the past week​</Typography>
                <DashboardChart />
              </Box>
            </Paper>
          </Stack>
        </Stack>
        <Stack width='23%'>
          <Paper variant='outlined'>
            <Box p={2}>
              <Typography variant='body2' fontWeight='bold'>
                Last User Activity
              </Typography>
              <ListLastUserLogin onClickShowAll={handleOpenModalListActivityUser} />
            </Box>
          </Paper>
        </Stack>
      </Stack>
      <ModalListAcitivityUser visible={showModalListActivityUser} onClose={handleCloseModalListActivityUser} />
    </TenantHomeDashboardNavigationBar>
  );
}
