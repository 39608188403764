import { ManipulateType } from 'dayjs';
import { dayjs } from 'utils/DateUtils';
import { FormikProps } from 'formik';
import { Field } from 'types/api/Tenant/AROKMS/DisplayTableTypes';
import { Stack, TextField, Tooltip, Typography } from '@mui/material';
import HelpOutlinedIcon from '@mui/icons-material/HelpOutlined';
import { RULES_CONSTANT } from 'constant/RuleConstant';
import {
  convertRuleTypeParamsToSnakeCase,
  convertRuleTypeParamsToSnakeCaseConcatenation,
  convertToSnackCase,
  generalSubstringFunction,
  normalizeParameterNumber,
} from 'utils/String';
import { useEffect, memo } from 'react';
import { getJulianDate, getJulianDateBasedOnDayType } from 'utils/DateUtils';
import { ENV_CONFIG } from 'config/env';
import { isValidJSONString } from 'utils/Object';

interface IProps {
  formik: FormikProps<{ [key: string]: string }>;
  field: Field;
  ruleTypeCode: string;
  required?: boolean;
  isEdit?: boolean;
}

const getMathOperation = (ruleTypeCode: string, value1: number, value2: number) => {
  if (ruleTypeCode.includes('ADD')) {
    return value1 + value2;
  }
  if (ruleTypeCode.includes('SUB')) {
    return value1 - value2;
  }
  if (ruleTypeCode.includes('MULTIPL')) {
    return value1 * value2;
  }
  if (ruleTypeCode.includes('DIVI')) {
    return value1 / value2;
  }
  return 0;
};

export function DynamicTextInputComponent(props: IProps) {
  const { formik, field, required, isEdit = false } = props;

  useEffect(() => {
    if (
      field.ruleTypeCode === RULES_CONSTANT.INTEGER.RULE_INTEGER_COUNT_ROW ||
      field.ruleTypeCode === RULES_CONSTANT.PERCENTAGE.RULE_CALCULATE_PERCENTAGE
    ) {
      let value = formik.values[field.name];
      formik.setFieldValue(field.name, value);
      return;
    }
    if (Object.values(RULES_CONSTANT.CURRENCY).includes(props.ruleTypeCode)) {
      let columns = convertRuleTypeParamsToSnakeCase(field.ruleTypeParams);
      let column1 = convertToSnackCase(columns?.[0]);
      let column2 = convertToSnackCase(columns?.[1]);
      let prefix = columns?.[2] || '';
      let suffix = columns?.[3] || '';
      let operationResult = getMathOperation(
        field.ruleTypeCode,
        Number(formik.values[column1]?.replace(/[^0-9.-]+/g, '')),
        Number(formik.values[column2]?.replace(/[^0-9.-]+/g, ''))
      );

      if (operationResult !== undefined && operationResult !== null && !isNaN(operationResult)) {
        formik.setFieldValue(field.name, `${prefix} ${operationResult.toLocaleString()} ${suffix}`);
      } else {
        formik.setFieldValue(field.name, 'To be calculated');
      }
    }
    if (
      Object.values({
        ...RULES_CONSTANT.INTEGER,
        ...RULES_CONSTANT.NUMBER,
        ...RULES_CONSTANT.PERCENTAGE,
      }).includes(props.ruleTypeCode)
    ) {
      let columns = convertRuleTypeParamsToSnakeCase(field.ruleTypeParams);
      let column1 = convertToSnackCase(columns?.[0]);
      let column2 = convertToSnackCase(columns?.[1]);

      let operationResult = getMathOperation(
        field.ruleTypeCode,
        Number(formik.values[column1]?.replace(/[^0-9.-]+/g, '')),
        Number(formik.values[column2]?.replace(/[^0-9.-]+/g, ''))
      );

      if (operationResult !== undefined && operationResult !== null && !isNaN(operationResult)) {
        let fieldValue = operationResult.toLocaleString();

        if (field.ruleTypeCode.includes('PERCENTAGE')) {
          fieldValue = `${fieldValue}%`;
        }
        formik.setFieldValue(field.name, fieldValue);
      }
      return;
    }
    if (field.ruleTypeCode === RULES_CONSTANT.TEXT.RULE_ATTRIBUTE_LEFT) {
      const textLeftParams = convertRuleTypeParamsToSnakeCase(field.ruleTypeParams);
      const textLeftColName = convertToSnackCase(textLeftParams?.[0]);
      const textLeftTotalChar = Number(textLeftParams[1]);
      let targetValue = formik.values?.[textLeftColName];
      if (typeof targetValue === 'object') {
        // @ts-ignore
        targetValue = targetValue?.label;
      }
      const textLeftValue = targetValue?.substring(0, textLeftTotalChar);
      textLeftValue && formik.setFieldValue(field.name, textLeftValue?.trim());
    }
    if (field.ruleTypeCode === RULES_CONSTANT.TEXT.RULE_ATTRIBUTE_MID) {
      const textMidparams = convertRuleTypeParamsToSnakeCase(field.ruleTypeParams);
      const column1 = convertToSnackCase(textMidparams[0]);
      const startChar = Number(textMidparams[1]) !== 0 ? Number(textMidparams[1]) - 1 : 0;
      const endChar = Number(textMidparams[2]);
      let targetValue = formik.values[column1];
      if (typeof targetValue === 'object') {
        // @ts-ignore
        targetValue = targetValue?.label;
      }
      const textMidValue = targetValue?.substring(startChar, endChar);
      textMidValue && formik.setFieldValue(field.name, textMidValue?.trim());
    }
    if (
      field.ruleTypeCode === RULES_CONSTANT.TEXT.RULE_TEXT_GENERAL_SUBSTRING ||
      field.ruleTypeCode === RULES_CONSTANT.TEXT.RULE_TEXT_GENERAL_SUBSTRING_STATIC_TABLE
    ) {
      const textMidparams = convertRuleTypeParamsToSnakeCase(field.ruleTypeParams);
      const generalSubstringSourceColumn = textMidparams[0];
      const generalSubstringCharToFind = textMidparams[1] || '';
      const generalSubstringStarChar = normalizeParameterNumber(textMidparams?.[2]);
      const generalSubstringNumberToAddFind = textMidparams?.[3] !== '' ? Number(textMidparams[3]) : undefined;
      const generalSubstringEndChar = normalizeParameterNumber(textMidparams?.[4]);

      let sourceColumnValue = formik.values[generalSubstringSourceColumn];

      // @ts-ignore
      if (typeof sourceColumnValue === 'object') sourceColumnValue = sourceColumnValue?.label || '';
      const finalValue = generalSubstringFunction(
        sourceColumnValue,
        generalSubstringCharToFind,
        generalSubstringStarChar,
        generalSubstringNumberToAddFind,
        generalSubstringEndChar
      );
      finalValue && formik.setFieldValue(field.name, finalValue);
    }
    if (field.ruleTypeCode === RULES_CONSTANT.TEXT.RULE_CONCATENATE_ATTRIBUTES) {
      const ruleParamObj = isValidJSONString(field.ruleTypeParams) ? JSON.parse(field.ruleTypeParams) : null;
      const concateColumns: string[] = ruleParamObj
        ? convertRuleTypeParamsToSnakeCaseConcatenation(ruleParamObj?.['ATTRIBUTE_CONCATENATION'])
        : convertRuleTypeParamsToSnakeCaseConcatenation(field.ruleTypeParams);
      const concateRule = ruleParamObj ? ruleParamObj?.['ATTRIBUTE_CONCATENATION_RULES']?.value : null;
      let textConcatenateValue = null;

      const values = concateColumns.map((param) => {
        if (param === ' ') return ' ';
        if (param.startsWith('{') && param.endsWith('}')) {
          // This is a column name or conditional expression
          const colExpression = param.slice(1, -1);

          // Handle conditional concatenation, split by '|'
          const colNames = colExpression.split('|').map((col) => convertToSnackCase(col.trim()));

          // Find the first non-empty value in the condition chain
          for (const colName of colNames) {
            const fieldValue = formik.values[colName];
            if (typeof fieldValue === 'object') {
              // @ts-ignore
              if (fieldValue?.label) return fieldValue.label;
            } else if (fieldValue) {
              return fieldValue;
            }
          }

          // If none of the conditions return a value, return an empty string
          return '';
        } else {
          // This is a static character
          return param;
        }
      });

      if (concateRule === 'NO_CONCATE_IF_COLUMN_EMPTY') {
        const isEveryValueFilled = values.every((val) => val);
        if (isEveryValueFilled) {
          textConcatenateValue = values.join('');
        }
      } else {
        textConcatenateValue = values.join('');
      }

      if (textConcatenateValue) {
        formik.setFieldValue(field.name, textConcatenateValue?.trim());
      }
    }

    if (field.ruleTypeCode === RULES_CONSTANT.DATE.RULE_DATE_WEEKNUM) {
      const dateWeekNumParams = convertRuleTypeParamsToSnakeCase(field.ruleTypeParams);
      const dateWeekNumColName = convertToSnackCase(dateWeekNumParams[0]);
      const dateWeekNumValue = dayjs(formik.values[dateWeekNumColName]).isValid()
        ? dayjs(formik.values[dateWeekNumColName]).week()
        : undefined;

      dateWeekNumValue && formik.setFieldValue(field.name, dateWeekNumValue);
    }

    if (field.ruleTypeCode === RULES_CONSTANT.DATE.RULE_DATE_PERIODS) {
      const datePeriodsParameter = convertRuleTypeParamsToSnakeCase(field.ruleTypeParams);
      const datePeriodsCol1 = datePeriodsParameter[0];
      const datePeriodsCol2 = datePeriodsParameter[1];
      const datePeriodsType = datePeriodsParameter[2];
      const decimalLimit = Number(datePeriodsParameter[3]) || 0;
      const value1 = formik.values[datePeriodsCol1];
      const value2 = formik.values[datePeriodsCol2];

      const finalResult = getJulianDate(dayjs(value2)) - getJulianDate(dayjs(value1));
      const calculatedResult = getJulianDateBasedOnDayType(finalResult, datePeriodsType, decimalLimit);

      calculatedResult && formik.setFieldValue(field.name, calculatedResult);
    }
    if (field.ruleTypeCode === RULES_CONSTANT.DATE.RULE_DATE_INPUT_NUM_TYPE) {
      const dateInputNumTypeParams = convertRuleTypeParamsToSnakeCase(field.ruleTypeParams);
      const dateColumnReference = convertToSnackCase(dateInputNumTypeParams[0]);
      const additionDateType = dateInputNumTypeParams[1] as ManipulateType;
      const additionDateValue = Number(dateInputNumTypeParams[2]);

      const dateValue = dayjs(formik.values[dateColumnReference]).isValid()
        ? dayjs(formik.values[dateColumnReference]).add(additionDateValue, additionDateType)
        : undefined;
      dateValue && formik.setFieldValue(field.name, dateValue.format(ENV_CONFIG.config.DATE_FORMAT));
    }
    if (field.ruleTypeCode === RULES_CONSTANT.DATETIME.RULE_DATE_TIME) {
      const dateTimeInputNumTypeParams = convertRuleTypeParamsToSnakeCase(field.ruleTypeParams);
      const dateTimeColumnReference = convertToSnackCase(dateTimeInputNumTypeParams[0]);
      const additionDateTimeType = dateTimeInputNumTypeParams[1] as ManipulateType;
      const additionDateTimeValue = Number(dateTimeInputNumTypeParams[2]);

      const dateValue = formik.values[dateTimeColumnReference]
        ? dayjs(formik.values[dateTimeColumnReference]).add(additionDateTimeValue, additionDateTimeType)
        : undefined;

      dateValue && formik.setFieldValue(field.name, dateValue.format(ENV_CONFIG.config.DATE_TIME_FORMAT));
    }

    if (field.ruleTypeCode === RULES_CONSTANT.BASE_SUBJECT.RULE_ATTRIBUTE_SUBJECT_BASE) {
      let value = formik.values[field.name];
      formik.setFieldValue(field.name, value);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values, field]);

  return (
    <Stack direction='row' justifyContent='space-between' alignItems='center' gap={1} marginTop={2.5}>
      <Stack
        sx={{
          width: '40%',
        }}
      >
        <Stack direction='row' alignItems='center' gap={0.3}>
          <Typography variant='input-label' fontWeight={700}>
            {field.label}
            {required && !field.disabled && <span style={{ color: 'red', marginLeft: 2 }}>*</span>}
          </Typography>
          {field.description && (
            <Tooltip title={field.description}>
              <HelpOutlinedIcon
                sx={{
                  fontSize: '14px',
                }}
              />
            </Tooltip>
          )}
        </Stack>
        {field.columnEditable === false && !isEdit && (
          <Typography variant='input-label-gray' fontSize={12}>
            Once you've submitted, this field can no longer be changed
          </Typography>
        )}
      </Stack>

      <TextField
        name={field.name}
        disabled={field.disabled}
        value={
          // @ts-ignore
          typeof formik.values[field.name] === 'object' ? formik.values[field.name]?.label : formik.values[field.name]
        }
        onChange={(e) => {
          if (field.ruleTypeCode === RULES_CONSTANT.BASE_SUBJECT.RULE_ATTRIBUTE_SUBJECT_BASE) {
            const objectValue = {
              label: e.target.value,
              // @ts-ignore
              value: formik.values[field.name]?.value,
            };
            formik.setFieldValue(field.name, objectValue);
            return;
          }
          formik.handleChange(e);
        }}
        onBlur={formik.handleBlur}
        error={!!formik.touched[field.name] && !!formik.errors[field.name]}
        helperText={formik.touched[field.name] && formik.errors[field.name]}
        sx={{
          width: '65%',
          '& .Mui-disabled': {
            cursor: 'not-allowed !important',
          },
        }}
        minRows={field.componentType === 'TEXT_AREA' ? 4 : undefined}
        multiline={field.componentType === 'TEXT_AREA'}
        hiddenLabel
        variant='outlined'
        size='small'
        placeholder={field.placeholder}
      />
    </Stack>
  );
}

export const DynamicTextInput = memo(DynamicTextInputComponent);
