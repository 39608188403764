import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { SvgIcon } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { StyledMenu, FileType } from './ButtonExport';

// Custom Icon
import { ReactComponent as CustomIconCSV } from 'assets/icons/icon-csv.svg';
import { ReactComponent as CustomIconXLS } from 'assets/icons/icon-xls.svg';
import { FILE_TYPE_PARAMS } from 'constant/ImportExportConstant';

interface ButtonExportProps {
  onClick: (type: FileType) => void;
  disabled?: boolean | undefined;
}

export function ButtonDownloadTemplate(props: ButtonExportProps) {
  const { t } = useTranslation();
  const { onClick, disabled = false } = props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (selectedFileType: string) => {
    onClick(selectedFileType as FileType);
    handleClose();
  };

  return (
    <div>
      <Tooltip title={t('COMMON.BUTTON.DOWNLOAD_TEMPLATE')}>
        <IconButton
          disabled={disabled}
          aria-controls={open ? 'demo-customized-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          sx={{
            opacity: disabled ? 0.5 : 1,
            cursor: disabled ? 'not-allowed' : 'pointer',
          }}
        >
          <InsertDriveFileOutlinedIcon sx={{ color: '#42BB93' }} />
        </IconButton>
      </Tooltip>

      <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={() => handleMenuItemClick(FILE_TYPE_PARAMS.CSV)} disableRipple>
          <SvgIcon fontSize='small' sx={{ fill: '#42BB93' }} inheritViewBox component={CustomIconCSV} />
          {t('COMMON.BUTTON.DOWNLOAD_TEMPLATE_CSV')}
        </MenuItem>
        <MenuItem onClick={() => handleMenuItemClick(FILE_TYPE_PARAMS.EXCEL)} disableRipple>
          <SvgIcon fontSize='small' sx={{ fill: '#42BB93' }} inheritViewBox component={CustomIconXLS} />
          {t('COMMON.BUTTON.DOWNLOAD_TEMPLATE_EXCEL')}
        </MenuItem>
      </StyledMenu>
    </div>
  );
}
