import * as React from 'react';
import Stack from '@mui/material/Stack';
import CheckCircleSharpIcon from '@mui/icons-material/CheckCircleSharp';
import CancelSharpIcon from '@mui/icons-material/CancelSharp';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { DataMigrationStatusItem, MigrationDetailsItem } from 'types/api/Tenant/DataMigrationTypes';
import { useGetMigrationStatus } from 'services/v1/Tenant/DataMigrationService';

const tabelConstainerStyle = {
  border: 'none',
  boxShadow: 'none',
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#EDF2FF',
    color: theme.palette.common.black,
  },
}));

interface IProps {
  data: MigrationDetailsItem[];
  tenantDataSource: string;
  tenantTarget: string;
}

export function getMigrationStatusLabel(status: string | null) {
  switch (status) {
    case 'COMPLETED':
      return (
        <Stack display='flex' flexDirection='row' alignItems='center'>
          <CheckCircleSharpIcon sx={{ color: '#00B3DC' }} />
          <Typography variant='body1' sx={{ color: '#00B3DC' }} ml={1} fontSize={13}>
            Migration Successful
          </Typography>
        </Stack>
      );

    case 'DELETING':
      return (
        <Stack display='flex' flexDirection='row' alignItems='center'>
          <Typography variant='body1' sx={{ color: '#DF2E38' }} ml={1} fontSize={13}>
            Deleting...
          </Typography>
        </Stack>
      );

    case null:
      return '-';

    default:
      return (
        <Stack display='flex' flexDirection='row' alignItems='center'>
          <CancelSharpIcon sx={{ color: '#DF2E38' }} />
          <Typography variant='body1' sx={{ color: '#DF2E38' }} ml={1} fontSize={13}>
            Migration Fail
          </Typography>
        </Stack>
      );
  }
}

function Row(props: { row: DataMigrationStatusItem }) {
  const { row } = props;

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell component='th' scope='row'>
          {row.sourceSubjectId}
        </TableCell>
        <TableCell>{row.targetSubjectId}</TableCell>
        <TableCell>{getMigrationStatusLabel(row.availabilityStatus)}</TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export function MigrationResult(props: IProps) {
  const { tenantDataSource, tenantTarget } = props;

  const { data: result } = useGetMigrationStatus({
    enabled: true,
    refetchInterval: false,
  });

  return (
    <Stack height={400} borderRadius={2} border='1px solid #E3EBF6'>
      <Stack pl={2} mb={2}>
        <Typography variant='body1' mb={1} pt={2}>
          Migrate Complete
        </Typography>
        <Typography variant='input-label'>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer molestie tellus sit amet nulla convallis
          fermentum.
        </Typography>
      </Stack>
      <TableContainer component={Paper} sx={tabelConstainerStyle}>
        <Table stickyHeader size='small'>
          <TableHead>
            <TableRow>
              <StyledTableCell>{tenantDataSource}</StyledTableCell>
              <StyledTableCell>{tenantTarget}</StyledTableCell>
              <StyledTableCell>
                {result?.data && result?.data?.data?.length > 0 ? `Status (${result?.data?.data?.length})` : 'Status'}
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {result?.data?.data?.map((row) => (
              <Row key={row.id} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
}
