import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import Alert from '@mui/material/Alert';
import EastOutlinedIcon from '@mui/icons-material/EastOutlined';
import { DataSourcePreviewComponent } from '../DataSourcePreviewComponent';
import { EventDataChannel } from 'types/api/Tenant/EventDataSourceTypes';
import { Avatar } from '@mui/material';
const ModalContentStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  height: 600,
  bgcolor: 'background.paper',
  borderRadius: 1,
  px: 2,
  py: 2,
};

const closeIconStyle = { color: '#98A2AE', cursor: 'pointer' };

export type ModalChannelPreviewDataState = {
  open: boolean;
  key?: string | number;
  selectedChannel?: EventDataChannel;
};

export interface ModalChannelPreviewDataProps {
  visible?: boolean;
  onClose?: () => void;
  selectedChannel?: EventDataChannel;
  onConnectChannel?: (selectedChannel: EventDataChannel) => void;
}

export default function ModalChannelPreviewData(props: ModalChannelPreviewDataProps) {
  const { onClose, visible = false, selectedChannel, onConnectChannel } = props;

  const handleOnClickConnect = () => {
    if (!selectedChannel) return;
    onConnectChannel?.(selectedChannel);
  };
  if (!selectedChannel) return null;

  return (
    <div>
      <Modal open={visible} onClose={onClose}>
        <Stack direction='column' sx={ModalContentStyle}>
          <Stack justifyContent='space-between' direction='row' spacing={2} textAlign='center'>
            <Typography variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
              Preview Data - {selectedChannel.name}
            </Typography>
            <IconButton onClick={onClose} component='label'>
              <HighlightOffRoundedIcon sx={closeIconStyle} />
            </IconButton>
          </Stack>
          <Divider />

          <Stack flexDirection='column' flex={1} justifyContent='space-between' mt={1}>
            <Stack>
              <Alert severity='info'>
                <Stack direction='row' alignItems='center' gap={2}>
                  {selectedChannel.logoUrl && (
                    <Avatar
                      variant='square'
                      alt={selectedChannel.name}
                      src={selectedChannel.logoUrl}
                      imgProps={{ style: { objectFit: 'inherit' } }}
                      sx={{ width: 180, height: 30, mr: 1, objectFit: 'contain' }}
                    />
                  )}

                  {`Below is a sample of the data extracted from the ${selectedChannel.name}. Please review the data to ensure
                accuracy and relevance for synchronization with the event dataset. If the data meets your requirements,
                click the "Connect Channel" button to proceed with the data mapping process.`}
                </Stack>
              </Alert>
              <Typography variant='body2' color='text.secondary'></Typography>
              <DataSourcePreviewComponent dataChannelId={selectedChannel.id} />
            </Stack>

            <Stack>
              <Divider sx={{ mb: 1 }} />
              <Stack direction='row' gap={2} justifyContent='flex-end'>
                <Button variant='main-table-panel-border' sx={{}} onClick={onClose}>
                  Close
                </Button>
                <Button
                  variant='main-table-panel'
                  sx={{}}
                  onClick={handleOnClickConnect}
                  endIcon={<EastOutlinedIcon />}
                >
                  Connect Channel
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Modal>
    </div>
  );
}
