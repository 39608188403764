import React, { useState, useRef, useMemo, useEffect } from 'react';
import { Formik, FormikProps } from 'formik';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import ButtonLoading from '@mui/lab/LoadingButton';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Divider from '@mui/material/Divider';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { TenantHomeDashboardNavigationBar } from 'components/LayoutComponent/VerticalLayout/HeaderBar';
import { PATH_CONSTANT } from 'constant/PathConstant';
import {
  useDeploySystem,
  useListAllSystemStatusDropdown,
  useListCurrentSystemDropdown,
  useSystemMemberListDropdown,
  useUsersForDeploySystem,
} from 'services/v1/TenantMaster/MasterTable/SystemTableDataService';
import { MEMBER_LIST_STATIC_RECORD } from 'data/MemberListRecord';
import { useServerListDropdown } from 'services/v1/TenantMaster/MasterTable/ServerTableDataService';
import { DropdownItem } from 'types/api/SystemTenant/AROKMS/TableDefinitionTypes';
import { DropdownCreatableMemberList } from 'pages/Tenant/AROKMS/DataInput/components/DropdownCreatableMemberList';
import { Field, Option } from 'types/api/Tenant/AROKMS/DisplayTableTypes';
import { CreateAndDeploySystemValidationSchema } from 'config/FormValidaton/TenantMaster/MasterTable/SystemTableValidationScheman';

export const dataGridStyle = {
  '& .MuiCheckbox-root svg': {
    backgroundColor: 'transparent',
    color: '#98A2AE',
    borderRadius: 2,
  },
  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: '#EDF2FF',
    borderTop: '1px solid #E3EBF6',
    borderBottom: '1px solid #E3EBF6',
    textAlign: 'center',
    display: 'flex',
  },
};

export const autocompleteStyles = {
  width: '55%',
  '& .MuiOutlinedInput-root': {
    padding: '1px 2px',
  },
  '& .MuiAutocomplete-popper': {
    fontSize: '10px',
  },
};

export const textInputAutoCompleteStyles = {
  '& .MuiOutlinedInput': {
    padding: '1px 2px',
  },
};

const SYSTEM_DATA_SOURCE = {
  SCRATCH: 'SCRATCH',
  COPY_FROM_EXISTING_SYSTEM: 'COPY_FROM_EXISTING_SYSTEM',
};

const optionStyles = { '& > span': { fontSize: '14px', mr: 2, flexShrink: 0 } };

const radioButtonStyle = {
  color: '#98A2AE',
  '& .MuiSvgIcon-root': {
    fontSize: 20,
  },
  '&.Mui-checked': {
    color: '#42BB93',
  },
};
const radioButtonLabelStyle = {
  '& .MuiFormControlLabel-label': {
    fontSize: '14px',
    color: '#98A2AE',
  },
};

interface FormValues {
  sourceSystemId: {
    value: string;
    label: string;
  } | null;
  serverId: {
    value: string;
    label: string;
  };
  userId: string[];
  system: {
    value: string;
    label: string;
  };
  codeStatus: {
    value: string;
    label: string;
  };
  demoStatus: {
    value: string;
    label: string;
  };
  fieldStatus: {
    value: string;
    label: string;
  };
  dataSource: string;
}

const formInitialValues: FormValues = {
  serverId: {
    value: '',
    label: '',
  },
  userId: [],
  system: {
    value: '',
    label: '',
  },
  codeStatus: {
    value: '',
    label: '',
  },
  demoStatus: {
    value: '',
    label: '',
  },
  fieldStatus: {
    value: '',
    label: '',
  },
  dataSource: SYSTEM_DATA_SOURCE.SCRATCH,
  sourceSystemId: null,
};

function optionRenderInput(props: React.HTMLAttributes<HTMLLIElement>, option: DropdownItem) {
  return (
    <Box component='li' sx={optionStyles} {...props}>
      <span>{option.label}</span>
    </Box>
  );
}

export default function AddSystemPage() {
  const formikRef = useRef<FormikProps<FormValues>>(null);
  const navigate = useNavigate();
  const [selectedFormValue, setSelectedFormValue] = useState<FormValues>(formInitialValues);
  const [selectedSourceData, setSelectedSourceData] = useState(SYSTEM_DATA_SOURCE.SCRATCH);
  const { mutate: deploySystem, isLoading: isDeploying, error, isError } = useDeploySystem();
  const { data: usersData, isLoading: isLoadingUsers } = useUsersForDeploySystem();
  const { data: listCurrentSystemDropdown } = useListCurrentSystemDropdown();
  const { data: serverOptions } = useServerListDropdown();
  const { data: systemMemberList, isLoading: isFetchingSystem } = useSystemMemberListDropdown();
  const { data: optionsStatus, isLoading: isFetchingOptionStatus } = useListAllSystemStatusDropdown();

  const disableDropdownStatus = useMemo(() => {
    // Here is the criteria to disable the dropdown status
    // If codeStatus equal "Locked", then enable the demoStatus dropdown
    // if codeStatus equal "Locked" and demoStatus equal "Locked", then enable the fieldStatus dropdown
    // return should be true or false
    return {
      codeStatus: selectedFormValue.fieldStatus?.value !== MEMBER_LIST_STATIC_RECORD.FIELD_STATUS.RELEASED,
      demoStatus:
        selectedFormValue.codeStatus?.value === MEMBER_LIST_STATIC_RECORD.CODE_STATUS.LOCKED &&
        selectedFormValue.fieldStatus?.value !== MEMBER_LIST_STATIC_RECORD.FIELD_STATUS.RELEASED,
      fieldStatus:
        selectedFormValue.codeStatus?.value === MEMBER_LIST_STATIC_RECORD.CODE_STATUS.LOCKED &&
        selectedFormValue.demoStatus?.value === MEMBER_LIST_STATIC_RECORD.DEMO_STATUS.LOCKED,
    };
  }, [selectedFormValue]);

  const dropdownCreateableMemberListField = {
    name: 'system',
    options: (systemMemberList?.data as Option[]) || [],
    label: 'System',
    placeholder: 'Please Select',
    colSubjectId: '4028819c84e5373a0184e5373a3d0000',
    disabled: false,
  } as Field;

  const isAnyLoading = isFetchingSystem || isFetchingOptionStatus;

  const columns: GridColDef[] = [
    { field: 'fullName', headerName: 'Username', width: 180 },
    {
      field: 'userName',
      headerName: 'Email',
      width: 240,
    },
  ];

  const selectedIds = useMemo(() => {
    if (selectedFormValue.userId) {
      return selectedFormValue.userId;
    }
    return [];
  }, [selectedFormValue.userId]);

  const handleOnSave = (data: FormValues) => {
    const payloadData = {
      codeStatus: data.codeStatus.value,
      fieldStatus: data.fieldStatus.value,
      demoStatus: data.demoStatus.value,
      dataSource: data.dataSource,
      serverId: data.serverId.value,
      sourceSystemId: data.sourceSystemId?.value || null,
      system: data.system.value,
      userId: data.userId,
    };
    deploySystem(payloadData, {
      onSuccess: () => {
        toast.success('System has been created successfully!');
        handleOnCancelInput();
      },
    });
  };

  const handleOnCancelInput = () => {
    navigate(PATH_CONSTANT.TENANT_MASTER.MASTER_TABLE.SYSTEM);
  };

  useEffect(() => {
    if (
      selectedFormValue.codeStatus?.value === MEMBER_LIST_STATIC_RECORD.CODE_STATUS.CHANGING &&
      selectedFormValue.demoStatus?.value === MEMBER_LIST_STATIC_RECORD.DEMO_STATUS.LOCKED &&
      formikRef.current
    ) {
      formikRef.current.setFieldValue(
        'demoStatus',
        optionsStatus?.data.demoStatus.find((item) => item.value === MEMBER_LIST_STATIC_RECORD.DEMO_STATUS.CHANGING)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFormValue, optionsStatus?.data.demoStatus]);

  useEffect(() => {
    if (optionsStatus) {
      formikRef.current?.setFieldValue(
        'codeStatus',
        optionsStatus.data.codeStatus.find((item) => item.label.toLocaleLowerCase() === 'changing')
      );
      formikRef.current?.setFieldValue(
        'demoStatus',
        optionsStatus.data.demoStatus.find((item) => item.label.toLocaleLowerCase() === 'changing')
      );
      formikRef.current?.setFieldValue(
        'fieldStatus',
        optionsStatus.data.fieldStatus.find((item) => item.label.toLocaleLowerCase() === 'not available')
      );
    }
  }, [optionsStatus]);

  useEffect(() => {
    if (selectedSourceData === SYSTEM_DATA_SOURCE.SCRATCH) {
      formikRef.current?.setFieldValue('sourceSystemId', null);
    }
  }, [selectedSourceData]);

  return (
    <TenantHomeDashboardNavigationBar>
      <Box sx={{ height: '100%' }}>
        <Formik
          innerRef={formikRef}
          initialValues={formInitialValues}
          validationSchema={CreateAndDeploySystemValidationSchema}
          onSubmit={handleOnSave}
        >
          {(formikProps: FormikProps<FormValues>) => {
            const { values, setFieldValue } = formikProps;
            return (
              <Box sx={{ backgroundColor: '#fff', pb: 3, px: 3 }} alignItems='center'>
                <Stack
                  direction='row'
                  alignItems='center'
                  py={1}
                  justifyContent='space-between'
                  sx={{ backgroundColor: '#fff' }}
                  spacing={2}
                >
                  <Stack>
                    <Typography variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
                      Add System
                    </Typography>
                  </Stack>
                </Stack>
                <Stack>
                  <Typography variant='input-label' fontStyle='italic' sx={{ pt: 1, fontSize: 13 }}>
                    (<span style={{ color: 'red' }}>*</span>) indicates required fields
                  </Typography>
                </Stack>

                <Grid
                  container
                  marginTop={2}
                  sx={{ backgroundColor: '#fff', py: 1, borderTop: '1px solid #E3EBF6' }}
                  justifyContent='space-between'
                  display='flex'
                  flexWrap='wrap'
                  alignItems='flex-start'
                >
                  <Grid container item xs={6} direction='column'>
                    <Stack direction='row' spacing={2} alignItems='center' justifyContent='space-between'>
                      <Typography variant='input-label'>Create System</Typography>
                      <RadioGroup
                        aria-labelledby='demo-radio-buttons-group-label'
                        defaultValue={SYSTEM_DATA_SOURCE.SCRATCH}
                        sx={{ width: '57%' }}
                        value={formikProps.values.dataSource}
                        onChange={(event, value) => {
                          formikProps.setFieldValue('dataSource', value);
                          setSelectedSourceData(value);
                        }}
                        name='radio-buttons-group'
                      >
                        <Stack direction='row' spacing={1}>
                          <FormControlLabel
                            value={SYSTEM_DATA_SOURCE.SCRATCH}
                            sx={radioButtonLabelStyle}
                            control={<Radio sx={radioButtonStyle} />}
                            label='From Scratch'
                          />
                          <FormControlLabel
                            value={SYSTEM_DATA_SOURCE.COPY_FROM_EXISTING_SYSTEM}
                            sx={radioButtonLabelStyle}
                            control={<Radio sx={radioButtonStyle} />}
                            label='Copying from existing'
                          />
                        </Stack>
                      </RadioGroup>
                    </Stack>
                    {formikProps.values.dataSource === SYSTEM_DATA_SOURCE.COPY_FROM_EXISTING_SYSTEM && (
                      <Stack
                        direction='row'
                        spacing={2}
                        alignItems='center'
                        justifyContent='space-between'
                        sx={{ mt: 2 }}
                      >
                        <Typography variant='input-label'>
                          Source System
                          <span style={{ color: 'red', marginLeft: 2 }}>*</span>
                        </Typography>
                        <Autocomplete
                          options={listCurrentSystemDropdown?.data || []}
                          // @ts-ignore
                          value={formikProps.values.sourceSystemId}
                          getOptionLabel={(option) => option.label}
                          clearIcon={null}
                          contentEditable={false}
                          onChange={(event, value) => {
                            formikProps.setFieldValue('sourceSystemId', value);
                          }}
                          renderOption={(props, option) => (
                            <Box component='li' sx={optionStyles} {...props}>
                              <span>{option.label}</span>
                            </Box>
                          )}
                          sx={autocompleteStyles}
                          renderInput={(params) => (
                            <TextField {...params} sx={textInputAutoCompleteStyles} placeholder='Please select' />
                          )}
                        />
                      </Stack>
                    )}
                    <Divider color='#E3EBF6' sx={{ mb: 1, mt: 1 }} />
                    <Stack>
                      <DropdownCreatableMemberList<FormValues>
                        required
                        formik={formikProps}
                        field={dropdownCreateableMemberListField}
                      />
                    </Stack>

                    <Stack
                      direction='row'
                      spacing={2}
                      alignItems='center'
                      justifyContent='space-between'
                      sx={{ mt: 2 }}
                    >
                      <Typography variant='input-label'>
                        Code Status
                        <span style={{ color: 'red', marginLeft: 2 }}>*</span>
                      </Typography>
                      <Autocomplete
                        disabled={!disableDropdownStatus.codeStatus}
                        options={optionsStatus?.data.codeStatus || []}
                        value={values.codeStatus}
                        getOptionLabel={(option: DropdownItem) => option.label}
                        clearIcon={null}
                        contentEditable={false}
                        onChange={(event, value) => {
                          setFieldValue('codeStatus', value);
                          if (value) {
                            setSelectedFormValue((prevState) => ({
                              ...prevState,
                              codeStatus: value,
                            }));
                          }
                        }}
                        renderOption={optionRenderInput}
                        sx={autocompleteStyles}
                        renderInput={(params) => <TextField {...params} sx={textInputAutoCompleteStyles} />}
                      />
                    </Stack>
                    <Stack
                      direction='row'
                      spacing={2}
                      alignItems='center'
                      justifyContent='space-between'
                      sx={{ mt: 2 }}
                    >
                      <Typography variant='input-label'>
                        Demo Status
                        <span style={{ color: 'red', marginLeft: 2 }}>*</span>
                      </Typography>
                      <Autocomplete
                        options={optionsStatus?.data.demoStatus || []}
                        // @ts-ignore
                        value={values.demoStatus}
                        disabled={!disableDropdownStatus.demoStatus}
                        getOptionLabel={(option) => option.label}
                        clearIcon={null}
                        contentEditable={false}
                        onChange={(event, value) => {
                          setFieldValue('demoStatus', value);
                          if (value) {
                            setSelectedFormValue((prevState) => ({
                              ...prevState,
                              demoStatus: value,
                            }));
                          }
                        }}
                        renderOption={optionRenderInput}
                        sx={autocompleteStyles}
                        renderInput={(params) => <TextField {...params} sx={textInputAutoCompleteStyles} />}
                      />
                    </Stack>
                    <Stack
                      direction='row'
                      spacing={2}
                      alignItems='center'
                      justifyContent='space-between'
                      sx={{ mt: 2 }}
                    >
                      <Typography variant='input-label'>
                        Field Status
                        <span style={{ color: 'red', marginLeft: 2 }}>*</span>
                      </Typography>
                      <Autocomplete
                        options={optionsStatus?.data.fieldStatus || []}
                        // @ts-ignore
                        value={values.fieldStatus}
                        disabled={!disableDropdownStatus.fieldStatus}
                        getOptionLabel={(option) => option.label}
                        clearIcon={null}
                        contentEditable={false}
                        onChange={(event, value) => {
                          setFieldValue('fieldStatus', value);
                          if (value) {
                            setSelectedFormValue((prevState) => ({
                              ...prevState,
                              fieldStatus: value,
                            }));
                          }
                        }}
                        renderOption={optionRenderInput}
                        sx={autocompleteStyles}
                        renderInput={(params) => <TextField {...params} sx={textInputAutoCompleteStyles} />}
                      />
                    </Stack>

                    <Stack
                      direction='row'
                      spacing={2}
                      alignItems='center'
                      justifyContent='space-between'
                      sx={{ mt: 2 }}
                    >
                      <Typography variant='input-label'>
                        Server
                        <span style={{ color: 'red', marginLeft: 2 }}>*</span>
                      </Typography>
                      <Autocomplete
                        options={serverOptions || []}
                        // @ts-ignore
                        value={values.serverId}
                        disabled={isAnyLoading || !selectedFormValue.system}
                        getOptionLabel={(option) => option.label}
                        clearIcon={null}
                        contentEditable={false}
                        onChange={(event, value) => {
                          setFieldValue('serverId', value);
                        }}
                        renderOption={optionRenderInput}
                        sx={autocompleteStyles}
                        renderInput={(params) => (
                          <TextField {...params} sx={textInputAutoCompleteStyles} placeholder='Select Server' />
                        )}
                      />
                    </Stack>

                    <Stack direction='column' spacing={2} justifyContent='space-between' sx={{ mt: 2 }}>
                      <Typography variant='input-label' mb={2}>
                        Assign Builder
                        <span style={{ color: 'red', marginLeft: 2 }}>*</span>
                      </Typography>
                      <div style={{ height: 250, width: '100%' }}>
                        {!isLoadingUsers && (
                          <DataGridPro
                            loading={isLoadingUsers}
                            selectionModel={selectedIds}
                            sx={dataGridStyle}
                            density='compact'
                            rows={usersData?.data || []}
                            columns={columns}
                            onSelectionModelChange={(newSelection) => {
                              if (usersData?.data) {
                                // @ts-ignore
                                setSelectedFormValue((prevState) => ({
                                  ...prevState,
                                  userId: newSelection,
                                }));
                                setFieldValue('userId', newSelection);
                              }
                            }}
                            pagination={false}
                            hideFooter
                            rowsPerPageOptions={[5]}
                            checkboxSelection={
                              !isAnyLoading ||
                              (!!selectedFormValue.system && !!selectedFormValue.userId && !!selectedFormValue.serverId)
                            }
                          />
                        )}
                      </div>
                    </Stack>
                    {isError && (
                      <Stack mt={2}>
                        <Alert severity='error'>{error.response?.data.message}</Alert>
                      </Stack>
                    )}
                  </Grid>
                </Grid>
                <Stack direction='row' spacing={2} justifyContent='flex-end'>
                  <Button disabled={isDeploying} onClick={handleOnCancelInput} variant='main-table-panel-border'>
                    Cancel
                  </Button>
                  <ButtonLoading
                    loading={isDeploying}
                    variant='main-table-panel'
                    onClick={() => formikProps.handleSubmit()}
                    disabled={!CreateAndDeploySystemValidationSchema?.isValidSync(formikProps.values) || isDeploying}
                  >
                    Save
                  </ButtonLoading>
                </Stack>
              </Box>
            );
          }}
        </Formik>
      </Box>
    </TenantHomeDashboardNavigationBar>
  );
}
