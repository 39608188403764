// @ts-nocheck
import { useState } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import { MigrationDetailsItem, TableDefinition } from 'types/api/Tenant/DataMigrationTypes';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { StyledTableCell } from 'pages/Tenant/MigrationPage/components/TableListSchema';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const ModalContentStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'background.paper',
  minHeight: 500,
  borderRadius: 1,
  px: 3,
  py: 2,
};

const closeIconStyle = { color: '#98A2AE', cursor: 'pointer' };

const tableContainerStyle = {
  border: 'none',
  boxShadow: 'none',
};

interface ModalParamsProps {
  visible?: boolean;
  data?: MigrationDetailsItem | null;
  onClose?: () => void;
}

function getCompatibleElement(isCompatible: boolean) {
  if (isCompatible) {
    return (
      <Typography fontWeight={400} fontSize={13} sx={{ color: '#00B74A' }}>
        Compatible
      </Typography>
    );
  }
  return (
    <Typography fontWeight={400} fontSize={13} sx={{ color: '#FF0000' }}>
      Not Compatible
    </Typography>
  );
}

function Row(props: { row: TableDefinition }) {
  const { row } = props;
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell width='15%'>
          <IconButton aria-label='expand row' size='small' onClick={() => setOpen(!open)}>
            {open ? (
              <KeyboardArrowUpIcon sx={{ color: '#42BB93' }} />
            ) : (
              <KeyboardArrowDownIcon sx={{ color: '#42BB93' }} />
            )}
          </IconButton>
        </TableCell>
        <TableCell width='33%'>
          <Typography fontWeight={400} fontSize={13}>
            {row.sourceTableDefinition.columnName}
          </Typography>
        </TableCell>
        <TableCell width='33%'>
          <Typography fontWeight={400} fontSize={13}>
            {row.targetTableDefinition.columnName}
          </Typography>
        </TableCell>
        <TableCell width='19%'>{getCompatibleElement(row.compatible)}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box sx={{ margin: 0 }}>
              <Table size='small' aria-label='purchases'>
                <TableBody>
                  <TableRow>
                    <TableCell width='15%'>
                      <Typography color='#98A2AE' fontSize={12}>
                        Rule Type
                      </Typography>
                    </TableCell>
                    <TableCell width='35%' align='left'>
                      <Typography fontSize={12}>{row.sourceTableDefinition.ruleTypeLabel}</Typography>
                    </TableCell>
                    <TableCell width='35%' align='left'>
                      <Typography fontSize={12}>{row.targetTableDefinition.ruleTypeLabel}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width='15%'>
                      <Typography color='#98A2AE' fontSize={12}>
                        Data Type
                      </Typography>
                    </TableCell>
                    <TableCell width='35%' align='left'>
                      <Typography fontSize={12}>{row.sourceTableDefinition.dataType}</Typography>
                    </TableCell>
                    <TableCell width='35%' align='left'>
                      <Typography fontSize={12}>{row.targetTableDefinition.dataType}</Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export function ModalListTableDefinition(props: ModalParamsProps) {
  const { onClose, visible = false, data } = props;

  return (
    <Modal open={visible} onClose={onClose}>
      <Stack direction='column' sx={ModalContentStyle} justifyContent='space-between'>
        <Stack direction='column'>
          <Stack justifyContent='space-between' direction='row' spacing={2}>
            <Typography variant='body1' component='h2'>
              History Migrate
            </Typography>
            <IconButton onClick={onClose} component='label'>
              <CloseIcon sx={closeIconStyle} />
            </IconButton>
          </Stack>
          <Stack>
            <Stack height={400} borderRadius border='1px solid #E3EBF6'>
              <TableContainer component={Paper} sx={tableContainerStyle}>
                <Table stickyHeader aria-label='collapsible table' size='small'>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell></StyledTableCell>
                      <StyledTableCell>Source Attribute</StyledTableCell>
                      <StyledTableCell>Target Attribute</StyledTableCell>
                      <StyledTableCell>Field Status</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.tableDefinitions?.map((row) => (
                      <Row key={row.id} row={row} />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Modal>
  );
}
