import { AxiosResponse } from 'axios';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import qs from 'query-string';
import axios from 'libs/Axios';

import { PaginationAndSortingParams } from 'types/api/Common/PaginationTypes';
import { AxiosDefaultErrorEntity } from 'types/api/Common/ErrorTypes';
import {
  PersonItem,
  PersonListResponse,
  PersonResetPasswordRequest,
  PersonUsertRequest,
} from 'types/api/TenantMaster/MasterTable/PersonTableDataTypes';

export const PERSON_DATA_QUERY_KEY = {
  PERSON_LIST: 'PERSON_LIST',
  PERSON_DROPDOWN: 'PERSON_DROPDOWN',
  PERSON_MEMBER_LIST_DROPDOWN: 'PERSON_MEMBER_LIST_DROPDOWN',
  PERSON_DETAILS: 'PERSON_DETAILS',
};

export function usePersonList(params: PaginationAndSortingParams) {
  return useQuery<AxiosResponse<PersonListResponse>, AxiosDefaultErrorEntity>(
    [PERSON_DATA_QUERY_KEY.PERSON_LIST, { ...params }],
    () => axios.get(`/api/v1/master/user/list?${qs.stringify({ ...params, page: params.page - 1 })}`).then((res) => res)
  );
}

export function usePersonMemberListDropdown() {
  return useQuery<
    AxiosResponse<{ colSubjectId: string; options: { label: string; value: string } }>,
    AxiosDefaultErrorEntity
  >([PERSON_DATA_QUERY_KEY.PERSON_MEMBER_LIST_DROPDOWN], () =>
    axios.get(`/api/v1/master/user/member-list`).then((res) => res)
  );
}

export function usePersonDetails(userId?: string) {
  return useQuery<AxiosResponse<PersonItem>, AxiosDefaultErrorEntity>(
    [PERSON_DATA_QUERY_KEY.PERSON_DETAILS, userId],
    () => axios.get(`/api/v1/master/user/detail/${userId}`).then((res) => res),
    {
      enabled: !!userId,
    }
  );
}
export function useResetPasswordPerson() {
  return useMutation<AxiosResponse, AxiosDefaultErrorEntity, PersonResetPasswordRequest>((bodyRequest) =>
    axios.put('/api/v1/master/user/reset-password', bodyRequest)
  );
}

export function useCreatePerson() {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosDefaultErrorEntity, PersonUsertRequest>(
    (bodyRequest) => axios.post('/api/v1/master/user/create', bodyRequest),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(PERSON_DATA_QUERY_KEY.PERSON_LIST);
      },
    }
  );
}

export function useUpdatePerson() {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosDefaultErrorEntity, PersonUsertRequest>(
    (bodyRequest) => axios.put('/api/v1/master/user/update', bodyRequest),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(PERSON_DATA_QUERY_KEY.PERSON_LIST);
      },
    }
  );
}

export function useDeletePerson() {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosDefaultErrorEntity, { userId: string }>(
    ({ userId }) => axios.delete(`/api/v1/master/user/delete/${userId}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(PERSON_DATA_QUERY_KEY.PERSON_LIST);
      },
    }
  );
}
