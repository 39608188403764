import React, { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { MainLayoutComponent } from 'components/LayoutComponent/SidebarLayout/MainLayoutComponent';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import ButtonLoading from '@mui/lab/LoadingButton';
import Checkbox from '@mui/material/Checkbox';
import { Box, Button } from '@mui/material';
import { useFormik } from 'formik';
import { AutoCompleteItem } from 'types/api/Common/AutoCompleteTypes';
import { useNavigate, useParams } from 'react-router-dom';
import { PATH_CONSTANT } from 'constant/PathConstant';
import { INSIGHT_VIEW_CUBE_ADDITIONAL_DROPDOWN_OPTIONS } from 'constant/ViewConstant';
import {
  IInsightViewAttributeDefinitionRef,
  InsightViewAttributeDefinitionTable,
} from './components/InsightViewAttributeDefinition';
import ActivityIndicator from 'components/ActivityIndicatorComponent';
import {
  useGetStandardViewDefinitionCubeDropdown,
  useGetStandardViewDefinitionSubjectDropdown,
} from 'services/v1/SystemTenant/AROView/StandardViewDefinitionService';
import {
  InsightViewAttributeItem,
  RequestCreateInsightViewDefinition,
  RequestInsightViewAttributeItem,
  RequestInsightViewCellColorItem,
  RequestInsightViewVarianceItem,
} from 'types/api/SystemTenant/AROView/InsightViewDefinitionTypes';
import {
  useDeleteInsightdViewDefinition,
  useEditInsightViewDefinition,
  useGetInsightViewDefinitionDetails,
} from 'services/v1/SystemTenant/AROView/InsightViewDefinitionService';
import {
  createInsightViewDefinitionFormikInitialValues,
  createInsightViewDefinitionValidationSchema,
} from './CreateInsightViewDefinition';
import ModalDeleteComponent, { ModalDeleteState } from 'components/ModalComponent/ModalDeleteComponent';
import {
  IInsightViewVarianceDefinitionRef,
  InsightViewVarianceDefinitionTable,
} from './components/InsightViewVarianceDefinition';
import {
  IInsightViewCellColorDefinitionRef,
  InsightViewCellColorDefinitionTable,
} from './components/InsightViewCellColorDefinition';

const autocompleteStyles = {
  width: '55%',
  '& .MuiOutlinedInput-root': {
    padding: '1px 2px',
  },
  '& .MuiAutocomplete-popper': {
    fontSize: '10px',
  },
};
const textInputStyles = {
  '& .MuiOutlinedInput': {
    padding: '1px 2px',
  },
};

export default function EditInsightViewDefinitionPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const attributeDefiintionRef = React.useRef<IInsightViewAttributeDefinitionRef>(null);
  const varianceDefinitionRef = React.useRef<IInsightViewVarianceDefinitionRef>(null);
  const cellColorDefinitionRef = React.useRef<IInsightViewCellColorDefinitionRef>(null);
  const [showVarianceDefinition, setShowVarianceDefinition] = React.useState<boolean>(false);
  const [showCellColorDefinition, setShowColorDefinition] = React.useState<boolean>(false);
  const { insightViewId } = useParams<{ insightViewId: string }>();

  const [insightAttributeDefinition, setInsightAttributeDefinition] = React.useState<InsightViewAttributeItem[]>([]);

  const formik = useFormik({
    initialValues: createInsightViewDefinitionFormikInitialValues,
    validationSchema: createInsightViewDefinitionValidationSchema,
    onSubmit: () => {},
  });

  const { data: subjectDropdownOptions, isLoading: isLoadingSubjectOptions } =
    useGetStandardViewDefinitionSubjectDropdown();
  const { data: cubeDropdownOptions, isLoading: isLoadingCubeOptions } = useGetStandardViewDefinitionCubeDropdown(
    formik.values.subject?.value?.toString() ?? ''
  );

  const { data: insighViewDefinitionDetails, isLoading: isLoadingDetails } =
    useGetInsightViewDefinitionDetails(insightViewId);

  const { mutate: editInsightView, isLoading: isSubmitting } = useEditInsightViewDefinition();

  const { mutate: deleteInsightViewDefinition } = useDeleteInsightdViewDefinition();

  const [modalDeleteState, setModalDeleteState] = useState<ModalDeleteState>({
    message: 'Are you sure you want to delete this data?',
    open: false,
  });

  const handleOnSubmit = () => {
    if (formik.values.cube && formik.values.name) {
      const attributeDefinitionData = attributeDefiintionRef.current?.getRowData();
      const varianceDefinitionData = varianceDefinitionRef?.current?.getRowData();
      const cellColorDefinitionData = cellColorDefinitionRef?.current?.getRowData();
      const attributeDefinitions: RequestInsightViewAttributeItem[] = (attributeDefinitionData ?? []).map((item) => {
        return {
          id: item.id,
          isGroup: item.isGroup,
          isNew: item.isNew,
          relativePeriodType: item.relativePeriodType,
          relativePeriod: item.relativePeriod,
          relativeYear: item.relativeYear,
          relativeYearType: item.relativeYearType,
          totalRule: item.totalRule?.value.toString() ?? null,
          name: item.name,
          groupId: item.group?.value.toString() ?? null,
          cubeLayerId: item.cubeLayer?.value.toString() ?? null,
          groupTopHeaderType: item.groupTopHeaderType,
        };
      });

      const varianceDefinitions: RequestInsightViewVarianceItem[] =
        varianceDefinitionData?.map((item) => {
          return {
            groupId: item.group?.value.toString() ?? null,
            resultColumnId: item.resultColumn?.value.toString() ?? null,
            targetColumnId: item.targetColumn?.value.toString() ?? null,
            id: item.id,
          };
        }) ?? [];

      // @ts-ignore
      const cellColorDefinitions: RequestInsightViewCellColorItem[] =
        cellColorDefinitionData
          ?.map((item) => {
            if (item.cubeAttribute?.value) {
              return {
                id: item.id,
                cubeAttributeId: item.cubeAttribute?.value.toString(),
                lessThanColor: item.lessThanColor?.value ?? null,
                lessThanValue: item.lessThanValue,
                greaterThanColor: item.greaterThanColor?.value ?? null,
                greaterThanValue: item.greaterThanValue,
              };
            }
            return null;
          })
          .filter((item) => item != null) ?? [];

      const payload: RequestCreateInsightViewDefinition = {
        cubeId: formik.values.cube?.value?.toString(),
        name: formik.values.name,
        attributeDefinitions,
        varianceDefinitions,
        cellColorDefinitions,
      };
      if (insightViewId) {
        editInsightView(
          {
            ...payload,
            id: insightViewId,
          },
          {
            onSuccess: () => {
              toast.success('Edit Insight View Definition successfully!');
              navigate(PATH_CONSTANT.SYSTEM.VIEW.INSIGHT_VIEW_DEFINITION);
            },
          }
        );
      }
    }
  };

  const handleOnCancel = () => {
    navigate(PATH_CONSTANT.SYSTEM.VIEW.INSIGHT_VIEW_DEFINITION);
  };

  const handleOnCubeChange = (e: React.SyntheticEvent, value: AutoCompleteItem | null) => {
    formik.setFieldValue('cube', value);
  };

  const handleOnSubjectChange = (e: React.SyntheticEvent, value: AutoCompleteItem | null) => {
    formik.setFieldValue('subject', value);
  };

  const handleOnDeleteButtonClick = () => {
    const message = `Are you sure you want to delete Insight View <strong>${insighViewDefinitionDetails?.data.name}</strong>?`;
    setModalDeleteState({ ...modalDeleteState, open: true, message });
  };

  const handleOnDeleteData = () => {
    if (insightViewId) {
      deleteInsightViewDefinition(insightViewId, {
        onSuccess: () => {
          toast.success('Delete insight view definition successfully!');
          navigate(PATH_CONSTANT.SYSTEM.VIEW.INSIGHT_VIEW_DEFINITION);
        },
        onError: (error) => {
          setModalDeleteState({
            ...modalDeleteState,
            isError: true,
            errorMessage: error?.response?.data?.message,
          });
        },
      });
    }
  };
  const handleOnChangeShowVarianceDefinition = (e: React.ChangeEvent<HTMLInputElement>) => {
    setShowVarianceDefinition(e.target.checked);
  };

  const handleOnChangeShowCellColorDefinition = (e: React.ChangeEvent<HTMLInputElement>) => {
    setShowColorDefinition(e.target.checked);
  };

  const isAllFormValid = createInsightViewDefinitionValidationSchema.isValidSync(formik.values);
  const isLoadingOptionsData = isLoadingSubjectOptions || isLoadingCubeOptions || isLoadingDetails;
  const cubeDropdownOptionsCustom = useMemo(() => {
    if (cubeDropdownOptions?.data && cubeDropdownOptions?.data.length > 0) {
      return [...cubeDropdownOptions.data, ...INSIGHT_VIEW_CUBE_ADDITIONAL_DROPDOWN_OPTIONS];
    }
    return INSIGHT_VIEW_CUBE_ADDITIONAL_DROPDOWN_OPTIONS;
  }, [cubeDropdownOptions?.data]);

  useEffect(() => {
    if (insighViewDefinitionDetails?.data) {
      formik.setValues({
        cube: insighViewDefinitionDetails?.data?.cube,
        name: insighViewDefinitionDetails?.data?.name,
        subject: insighViewDefinitionDetails?.data?.subject,
      });

      if (insighViewDefinitionDetails?.data?.varianceDefinitions?.length > 0) {
        setShowVarianceDefinition(true);
      }

      if (insighViewDefinitionDetails?.data.cellColorDefinitions.length > 0) {
        setShowColorDefinition(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insighViewDefinitionDetails?.data]);

  return (
    <MainLayoutComponent
      pageTitle='Edit Insight View'
      breadcrumbs={[t('SIDEBAR_MENU.DASHBOARD'), 'Insight View Definition']}
    >
      <Box p={2} bgcolor='#fff'>
        {isLoadingOptionsData ? (
          <Stack height={450}>
            <ActivityIndicator />
          </Stack>
        ) : (
          <>
            <Typography variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
              Insight View Definition
            </Typography>
            <Divider sx={{ my: 1 }} />
            <Stack width='100%' direction='row' gap={3} mb={2} flexWrap='wrap' alignItems='center'>
              <Stack width='40%'>
                <Typography variant='input-label' fontWeight={800}>
                  Insight Name
                </Typography>
                <Typography variant='input-label-gray' fontSize={13}></Typography>
                <TextField
                  sx={textInputStyles}
                  name='name'
                  hiddenLabel
                  value={formik.values.name}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                  variant='outlined'
                  size='small'
                  placeholder='e.g. Interest Function for Loan'
                />
              </Stack>
              <Stack width='40%'>
                <Typography variant='input-label' fontWeight={800}>
                  Select Subject
                </Typography>
                <Typography variant='input-label-gray' fontSize={13}>
                  Choose the subject (table) from which you want to pull the values.
                </Typography>
                <Autocomplete
                  options={subjectDropdownOptions?.data ?? []}
                  getOptionLabel={(option) => option.label}
                  sx={{ ...autocompleteStyles, width: '100%' }}
                  clearIcon={null}
                  value={formik.values.subject}
                  onBlur={formik.handleBlur}
                  onChange={handleOnSubjectChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name='subject'
                      placeholder='Select Subject...'
                      error={formik.touched.subject && Boolean(formik.errors.subject)}
                      helperText={formik.touched.subject && formik.errors.subject}
                    />
                  )}
                />
              </Stack>
              <Stack width='40%'>
                <Typography variant='input-label' fontWeight={800}>
                  Select Cube
                </Typography>
                <Typography variant='input-label-gray' fontSize={13}>
                  Choose the cube from which you want to calculate the values for.
                </Typography>
                <Autocomplete
                  options={cubeDropdownOptionsCustom}
                  getOptionLabel={(option) => option.label}
                  sx={{ ...autocompleteStyles, width: '100%' }}
                  clearIcon={null}
                  value={formik.values.cube}
                  onBlur={formik.handleBlur}
                  onChange={handleOnCubeChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name='cube'
                      placeholder='Select Cube...'
                      error={formik.touched.cube && Boolean(formik.errors.cube)}
                      helperText={formik.touched.cube && formik.errors.cube}
                    />
                  )}
                />
              </Stack>
            </Stack>
            <Stack direction='row' alignItems='center'>
              <Checkbox
                checked={showVarianceDefinition}
                onChange={handleOnChangeShowVarianceDefinition}
                name='active'
                sx={{
                  color: '#828C99',
                  px: 0,
                  paddingRight: 0.5,
                }}
              />
              <Typography variant='input-label-gray' fontWeight={600}>
                Add Variance Definition to this Insight View
              </Typography>
            </Stack>
            <Stack direction='row' alignItems='center' mb={2}>
              <Checkbox
                checked={showCellColorDefinition}
                onChange={handleOnChangeShowCellColorDefinition}
                name='active'
                sx={{
                  color: '#828C99',
                  px: 0,
                  paddingRight: 0.5,
                }}
              />
              <Typography variant='input-label-gray' fontWeight={600}>
                Add Cell Colour Definition
              </Typography>
            </Stack>
            <Typography variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
              Column Definition
            </Typography>
            <Divider sx={{ my: 1 }} />

            <InsightViewAttributeDefinitionTable
              disabled={!formik.values.cube || !formik.values.name}
              cubeId={formik.values.cube?.value?.toString()}
              initialData={insighViewDefinitionDetails?.data?.attributeDefinitions ?? []}
              onTableChange={(data) => {
                setInsightAttributeDefinition(data);
              }}
              ref={attributeDefiintionRef}
            />

            {showVarianceDefinition && (
              <>
                <Typography variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
                  Variance Definition
                </Typography>
                <Divider sx={{ my: 1 }} />
                <InsightViewVarianceDefinitionTable
                  attributeDefinitionData={insightAttributeDefinition}
                  initialData={insighViewDefinitionDetails?.data?.varianceDefinitions ?? []}
                  ref={varianceDefinitionRef}
                />
              </>
            )}

            {showCellColorDefinition && (
              <>
                <Typography variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
                  Cell Colour Definition
                </Typography>
                <Divider sx={{ my: 1 }} />
                <InsightViewCellColorDefinitionTable
                  attributeDefinitionData={insightAttributeDefinition}
                  initialData={insighViewDefinitionDetails?.data.cellColorDefinitions ?? []}
                  ref={cellColorDefinitionRef}
                  cubeId={formik.values.cube?.value?.toString()}
                />
              </>
            )}

            <Stack direction='row' px={2} justifyContent='space-between' alignItems='center'>
              <Stack />
              <Stack direction='row' gap={2}>
                <Button
                  onClick={handleOnDeleteButtonClick}
                  variant='outlined'
                  sx={{
                    textTransform: 'none',
                  }}
                  color='error'
                >
                  Delete
                </Button>
                <ButtonLoading disabled={isSubmitting} variant='main-table-panel-border' onClick={handleOnCancel}>
                  Cancel
                </ButtonLoading>
                <ButtonLoading
                  variant='main-table-panel'
                  loading={isSubmitting}
                  onClick={handleOnSubmit}
                  disabled={!isAllFormValid}
                >
                  Save
                </ButtonLoading>
              </Stack>
            </Stack>
          </>
        )}
      </Box>
      <ModalDeleteComponent
        errorMessage={modalDeleteState.errorMessage}
        isError={modalDeleteState.isError}
        visible={modalDeleteState.open}
        title={null}
        message={modalDeleteState.message}
        onApprove={handleOnDeleteData}
        onClose={() =>
          setModalDeleteState({ ...modalDeleteState, open: false, isError: false, errorMessage: undefined })
        }
        onCancel={() =>
          setModalDeleteState({ ...modalDeleteState, open: false, isError: false, errorMessage: undefined })
        }
      />
    </MainLayoutComponent>
  );
}
