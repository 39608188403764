import { useMemo, useState } from 'react';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import ActivityIndicator from 'components/ActivityIndicatorComponent';
import { useGetPeopleConfigList } from 'services/v1/Tenant/ConfigurationService';
import {
  DataGridPro,
  GridColumns,
  GridFilterModel,
  GridRowParams,
  GridValueFormatterParams,
} from '@mui/x-data-grid-pro';
import { PaginationAndSortingParams } from 'types/api/Common/PaginationTypes';
import { Button } from '@mui/material';

interface PeopleConfigDetailComponentProps {
  onUserClick: (data: { id: string; fullName: string }) => void;
}

export const PeopleConfigListComponent = (props: PeopleConfigDetailComponentProps) => {
  const { onUserClick } = props;
  const { isLoading, data: peopleList } = useGetPeopleConfigList();
  const [filter, setFilter] = useState<PaginationAndSortingParams>({
    page: 0,
    size: 10,
    filterValue: '',
    sortType: 'desc',
    sortBy: 'activityDate',
    filterColumn: 'fullName',
    filterOperator: 'contains',
  });

  const handleChangePage = (newPage: number) => {
    setFilter({ ...filter, page: newPage + 1 });
  };
  const handleChangePageSize = (newPageSize: number) => {
    setFilter({ ...filter, size: newPageSize });
  };

  const handleOnFilter = (params: GridFilterModel) => {
    let filterObj = {};
    if (params.items.length) {
      filterObj = {
        filterValue: params.items[0].value,
        filterOperator: params.items[0].operatorValue,
        filterColumn: params.items[0].columnField,
      };
    } else {
      filterObj = {
        filterValue: '',
        filterOperator: 'contains',
      };
    }
    setFilter({
      ...filter,
      ...filterObj,
    });
  };

  const columns: GridColumns = useMemo(
    () => [
      {
        field: 'fullName',
        headerName: 'Full Name',
        valueFormatter: (params: GridValueFormatterParams) => {
          return params.value?.toLocaleString();
        },
        width: 250,
      },
      {
        field: 'role',
        headerName: 'Role',
        valueFormatter: (params: GridValueFormatterParams) => {
          return params.value?.toLocaleString();
        },
        width: 200,
      },

      {
        field: 'ax',
        type: 'actions',
        headerName: 'Action',
        width: 200,
        getActions: (params: GridRowParams) => {
          return [
            <Button
              variant='main-table-panel-border'
              key={params.id + 'view'}
              size='small'
              onClick={() => {
                onUserClick({ id: params.id as string, fullName: params.row.fullName as string });
              }}
              sx={{
                p: 1.5,
                height: 29,
              }}
            >
              Details
            </Button>,
          ];
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  return (
    <Box sx={{ bgcolor: '#fff', px: 1, py: 2, width: '100%' }}>
      <Stack mb={2} width='100%'>
        <Typography variant='h6' color='text.primary' fontWeight='bold'>
          People Configuration
        </Typography>
        <Divider sx={{ mt: 1 }} />
      </Stack>
      {isLoading ? (
        <Stack direction='column' justifyContent='center'>
          <ActivityIndicator />
        </Stack>
      ) : (
        <Stack direction='column' display='flex'>
          <Typography variant='body2' color='text.secondary'>
            Initial Financial Year is the first financial year of the company. This will be used on functions such as
            calculation Depreciation Value and Loan
          </Typography>
          <Stack mt={1} height={300} width={800}>
            <DataGridPro
              autoHeight={false}
              loading={false}
              pagination
              disableMultipleColumnsFiltering
              paginationMode='client'
              density='compact'
              pageSize={filter.size}
              rowCount={0}
              page={filter.page || 0}
              rows={peopleList?.data || []}
              columns={columns}
              filterMode='client'
              checkboxSelection={false}
              onFilterModelChange={handleOnFilter}
              onPageChange={handleChangePage}
              onPageSizeChange={handleChangePageSize}
            />
          </Stack>
        </Stack>
      )}
    </Box>
  );
};
