import { NAVIGATION_COMPONENT_TYPE } from 'constant/NavigationConstant';
import { NavigationStateValue } from 'store/reducer/homeReducer';
import { BreadcrumbItem } from 'types/api/Tenant/HomeDashboardTypes';

export function generateBreadCrumbItems(items: BreadcrumbItem[]): NavigationStateValue {
  const navigationState: NavigationStateValue = {
    cube: null,
    event: null,
    insight: null,
    ktree: null,
    subject: null,
    view: null,
    dashboard: null,
    'subject-procedure': null,
  };
  items.forEach((item) => {
    switch (item.componentType) {
      case NAVIGATION_COMPONENT_TYPE.SUBJECT:
        navigationState.subject = {
          value: item.id,
          label: item.name,
        };
        break;
      case NAVIGATION_COMPONENT_TYPE.K_TREE:
        navigationState.ktree = {
          value: item.id,
          label: item.name,
        };
        break;
      case NAVIGATION_COMPONENT_TYPE.EVENT:
        navigationState.event = {
          value: item.id,
          label: item.name,
        };
        break;
      case NAVIGATION_COMPONENT_TYPE.CUBE:
        navigationState.cube = {
          value: item.id,
          label: item.name,
        };
        break;
      case NAVIGATION_COMPONENT_TYPE.STANDARD_VIEW:
        navigationState.view = {
          value: item.id,
          label: item.name,
        };
        break;
      case NAVIGATION_COMPONENT_TYPE.INSIGHT_VIEW:
        navigationState.insight = {
          value: item.id,
          label: item.name,
        };
        break;
      case NAVIGATION_COMPONENT_TYPE.DASHBOARD:
        navigationState.dashboard = {
          value: item.id,
          label: item.name,
        };
        break;
      case NAVIGATION_COMPONENT_TYPE.SUBJECT_PROCEDURE:
        navigationState['subject-procedure'] = {
          value: item.id,
          label: item.name,
        };
        break;
      default:
        break;
    }
  });
  return navigationState;
}
