import axios from 'libs/Axios';
import { useMutation, useQuery } from 'react-query';
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedTenant, setTenants, setDashboardViewMode } from 'store/reducer/authReducer';
import { RootState } from 'store';
import { AxiosError, AxiosResponse } from 'axios';
import { TenantInterface, RoleInterface } from 'store/reducer/authReducer';
import { AxiosDefaultErrorEntity } from 'types';
import { MEMBER_LIST_STATIC_RECORD } from 'data/MemberListRecord';
import { ROLES } from 'constant/PermissonConstant';
import { DASHBOARD_TYPE_CONSTANT, DASHBOARD_VIEW_MODE } from 'constant/DashboardTypeConstant';

export interface LoginResponse {
  userName: string;
  roleName: string;
  tenants: TenantInterface[];
  roles: RoleInterface[];
}

export interface Tenant {
  id: string;
  tenantName: string;
  tenantDesc: string;
  tenantStatus: string;
}

interface LoginRequestBody {
  userName: string;
  password: string;
}

export function useLogin() {
  return useMutation<AxiosResponse<LoginResponse>, AxiosError<{ code: number; message?: string }>, LoginRequestBody>(
    (values) => axios.post('/login', values).then((res) => res)
  );
}

export function useLogout() {
  return useMutation<AxiosResponse<string>, AxiosError<{ code: number; message?: string }>>(() =>
    axios.post('/api/v1/auth/logout').then((res) => res)
  );
}

export function useGetRoleAccessToken() {
  return useMutation<
    AxiosResponse<{
      data: string;
    }>,
    AxiosError<{ code: number; message?: string }>,
    {
      roleName: string;
      tenantId: string;
    }
  >((values) => axios.post('/api/v1/auth/role-session', values).then((res) => res));
}

export function useGetRoleAccess() {
  const dispatch = useDispatch();
  const { selectedTenant, role } = useSelector((state: RootState) => state.auth);
  return useQuery<AxiosResponse<TenantInterface[]>, AxiosDefaultErrorEntity>(
    'rolesAccess',
    () => axios.get('/api/v1/auth/roles-access').then((res) => res),
    {
      onSuccess: (data) => {
        dispatch(setTenants(data.data));

        const newSelectedTenant = data.data.find(
          (tenant) =>
            tenant.tenant.id === selectedTenant.tenant.id && tenant.tenant.roleName === selectedTenant.tenant.roleName
        );
        if (newSelectedTenant) {
          dispatch(setSelectedTenant(newSelectedTenant));
          dispatch(setDashboardViewMode(DASHBOARD_VIEW_MODE.DATA_CHANGE));

          // Every time we fetch the roles access, we need to check if the current role is still valid
          // By Default the dashboard view mode is "DATA_CHANGE"

          // for user with Builder role
          // if the codeStatus is "LOCKED", then we set the app view mode to "VIEW_ONLY"
          if (
            selectedTenant.tenant.systemCodeStatus === MEMBER_LIST_STATIC_RECORD.CODE_STATUS.LOCKED &&
            selectedTenant.tenant.dashboardType === DASHBOARD_TYPE_CONSTANT.DASHBOARD_SYSTEM &&
            role === ROLES.BUILDER
          ) {
            dispatch(setDashboardViewMode(DASHBOARD_VIEW_MODE.VIEW_ONLY));
            return;
          }

          // for user with Packager role
          // if the demoStatus is "LOCKED", then we set the app view mode to "VIEW_ONLY"
          if (
            selectedTenant.tenant.systemDemoStatus === MEMBER_LIST_STATIC_RECORD.DEMO_STATUS.LOCKED &&
            selectedTenant.tenant.dashboardType === DASHBOARD_TYPE_CONSTANT.DASHBOARD_TENANT &&
            role === ROLES.PACKAGER
          ) {
            dispatch(setDashboardViewMode(DASHBOARD_VIEW_MODE.VIEW_ONLY));
            return;
          }
        }
      },
      refetchOnMount: true,
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
    }
  );
}

export function useSessionCheck() {
  return useQuery('sessionCheck', () => axios.get('/api/v1/auth/session-check').then((res) => res), {
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
  });
}
