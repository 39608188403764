import { AxiosResponse } from 'axios';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import qs from 'query-string';
import axios from 'libs/Axios';

import { PaginationAndSortingParams } from 'types/api/Common/PaginationTypes';
import { AxiosDefaultErrorEntity } from 'types/api/Common/ErrorTypes';
import {
  SystemDataUpsertRequest,
  SystemDeployRequest,
  SystemDetails,
  SystemListResponse,
  SystemStatusDropdown,
  UserForDeployTenant,
} from 'types/api/TenantMaster/MasterTable/SystemTableDataTypes';
import { DropdownItem } from 'types/api/SystemTenant/AROKMS/TableDefinitionTypes';

export const SYSTEM_DATA_QUERY_KEY = {
  SYSTEM_LIST: 'SYSTEM_LIST',
  SYSTEM_DROPDOWN: 'SYSTEM_DROPDOWN',
  USER_SYSTEM_LIST_DROPDOWN: 'USER_SYSTEM_LIST_DROPDOWN',
  SYSTEM_MEMBER_LIST_DROPDOWN: 'SYSTEM_MEMBER_LIST_DROPDOWN',
  LIST_CURRENT_SYSTEM_DROPDOWN: 'LIST_CURRENT_SYSTEM_DROPDOWN',
  LIST_ALL_SYSTEM_STATUS_DROPDOWN: 'LIST_ALL_SYSTEM_STATUS_DROPDOWN',
  SYSTEM_DETAILS: 'SYSTEM_DETAILS',
};

export function useUsersForDeploySystem() {
  return useQuery<AxiosResponse<UserForDeployTenant[]>, AxiosDefaultErrorEntity>(
    [SYSTEM_DATA_QUERY_KEY.USER_SYSTEM_LIST_DROPDOWN],
    () => axios.get('/api/v1/deployment/system-tenant/users').then((res) => res)
  );
}

export function useSystemMemberListDropdown() {
  return useQuery<AxiosResponse<DropdownItem[]>, AxiosDefaultErrorEntity>(
    [SYSTEM_DATA_QUERY_KEY.SYSTEM_MEMBER_LIST_DROPDOWN],
    () => axios.get('/api/v1/master/system/dropdown/member-list').then((res) => res)
  );
}

export function useListCurrentSystemDropdown() {
  return useQuery<AxiosResponse<DropdownItem[]>, AxiosDefaultErrorEntity>(
    [SYSTEM_DATA_QUERY_KEY.LIST_CURRENT_SYSTEM_DROPDOWN],
    () => axios.get('/api/v1/master/system/dropdown/current-system').then((res) => res)
  );
}
export function useListAllSystemStatusDropdown() {
  return useQuery<AxiosResponse<SystemStatusDropdown>, AxiosDefaultErrorEntity>(
    [SYSTEM_DATA_QUERY_KEY.LIST_ALL_SYSTEM_STATUS_DROPDOWN],
    () => axios.get('/api/v1/master/system/dropdown/status').then((res) => res)
  );
}

export function useSystemList(params: PaginationAndSortingParams) {
  return useQuery<AxiosResponse<SystemListResponse>, AxiosDefaultErrorEntity>(
    [SYSTEM_DATA_QUERY_KEY.SYSTEM_LIST, { ...params }],
    () =>
      axios.get(`/api/v1/master/system/list?${qs.stringify({ ...params, page: params.page - 1 })}`).then((res) => res)
  );
}

export function useSystemDetails(systemId?: string) {
  return useQuery<AxiosResponse<SystemDetails>, AxiosDefaultErrorEntity>(
    [SYSTEM_DATA_QUERY_KEY.SYSTEM_DETAILS, { systemId }],
    () => axios.get(`/api/v1/master/system/details/${systemId}`).then((res) => res),
    {
      enabled: !!systemId,
    }
  );
}

export function useDeploySystem() {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosDefaultErrorEntity, SystemDeployRequest>(
    (bodyRequest) => axios.post('/api/v1/master/system/create', bodyRequest),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(SYSTEM_DATA_QUERY_KEY.USER_SYSTEM_LIST_DROPDOWN);
        queryClient.invalidateQueries(SYSTEM_DATA_QUERY_KEY.SYSTEM_LIST);
      },
    }
  );
}
export function useUpdateSystem() {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosDefaultErrorEntity, SystemDeployRequest>(
    (bodyRequest) => axios.put(`/api/v1/master/system/update/${bodyRequest?.id}`, bodyRequest),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(SYSTEM_DATA_QUERY_KEY.USER_SYSTEM_LIST_DROPDOWN);
        queryClient.invalidateQueries(SYSTEM_DATA_QUERY_KEY.SYSTEM_LIST);
      },
    }
  );
}

export function useCreateSystem() {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosDefaultErrorEntity, SystemDataUpsertRequest>(
    (bodyRequest) => axios.post('/api/v1/master/system/create', bodyRequest),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(SYSTEM_DATA_QUERY_KEY.SYSTEM_LIST);
      },
    }
  );
}
