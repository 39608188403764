import React, { useState, useRef, useMemo, useEffect } from 'react';
import { Formik, FormikProps } from 'formik';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import ButtonLoading from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { TenantHomeDashboardNavigationBar } from 'components/LayoutComponent/VerticalLayout/HeaderBar';
import { PATH_CONSTANT } from 'constant/PathConstant';
import {
  useListAllSystemStatusDropdown,
  useSystemDetails,
  useSystemMemberListDropdown,
  useUpdateSystem,
  useUsersForDeploySystem,
} from 'services/v1/TenantMaster/MasterTable/SystemTableDataService';
import { MEMBER_LIST_STATIC_RECORD } from 'data/MemberListRecord';
import { useServerListDropdown } from 'services/v1/TenantMaster/MasterTable/ServerTableDataService';
import { DropdownItem } from 'types/api/SystemTenant/AROKMS/TableDefinitionTypes';
import { DropdownCreatableMemberList } from 'pages/Tenant/AROKMS/DataInput/components/DropdownCreatableMemberList';
import { Field, Option } from 'types/api/Tenant/AROKMS/DisplayTableTypes';
import { UpdateSystemValidationSchema } from 'config/FormValidaton/TenantMaster/MasterTable/SystemTableValidationScheman';
import {useParams, useSearchParams} from 'react-router-dom';
import { SystemDetails } from '../../../../types/api/TenantMaster/MasterTable/SystemTableDataTypes';
import ActivityIndicator from '../../../../components/ActivityIndicatorComponent';

export const dataGridStyle = {
  '& .MuiCheckbox-root svg': {
    backgroundColor: 'transparent',
    color: '#98A2AE',
    borderRadius: 2,
  },
  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: '#EDF2FF',
    borderTop: '1px solid #E3EBF6',
    borderBottom: '1px solid #E3EBF6',
    textAlign: 'center',
    display: 'flex',
  },
};

export const autocompleteStyles = {
  width: '55%',
  '& .MuiOutlinedInput-root': {
    padding: '1px 2px',
  },
  '& .MuiAutocomplete-popper': {
    fontSize: '10px',
  },
};

export const textInputAutoCompleteStyles = {
  '& .MuiOutlinedInput': {
    padding: '1px 2px',
  },
};

const SYSTEM_DATA_SOURCE = {
  SCRATCH: 'SCRATCH',
  COPY_FROM_EXISTING_SYSTEM: 'COPY_FROM_EXISTING_SYSTEM',
};

const optionStyles = { '& > span': { fontSize: '14px', mr: 2, flexShrink: 0 } };

const formInitialValues: SystemDetails = {
  serverId: {
    value: '',
    label: '',
  },
  userId: [],
  system: {
    value: '',
    label: '',
  },
  codeStatus: {
    value: '',
    label: '',
  },
  demoStatus: {
    value: '',
    label: '',
  },
  fieldStatus: {
    value: '',
    label: '',
  },
  dataSource: SYSTEM_DATA_SOURCE.SCRATCH,
  allowChangeFieldStatus: true,
  sourceSystemId: null,
};

function optionRenderInput(props: React.HTMLAttributes<HTMLLIElement>, option: DropdownItem) {
  return (
    <Box component='li' sx={optionStyles} {...props}>
      <span>{option.label}</span>
    </Box>
  );
}

export default function EditSystemPage() {
  const formikRef = useRef<FormikProps<SystemDetails>>(null);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { id } = useParams();
  const [selectedFormValue, setSelectedFormValue] = useState<SystemDetails>(formInitialValues);

  const { mutate: updateSystem, isLoading: isDeploying, error, isError } = useUpdateSystem();
  const { data: tenantDetails, isLoading } = useSystemDetails(id);
  const { data: usersData, isLoading: isLoadingUsers } = useUsersForDeploySystem();
  const { data: serverOptions } = useServerListDropdown();
  const { data: systemMemberList, isLoading: isFetchingSystem } = useSystemMemberListDropdown();
  const { data: optionsStatus, isLoading: isFetchingOptionStatus } = useListAllSystemStatusDropdown();

  const disableDropdownStatus = useMemo(() => {
    // Here is the criteria to disable the dropdown status
    // If codeStatus equal "Locked", then enable the demoStatus dropdown
    // if codeStatus equal "Locked" and demoStatus equal "Locked", then enable the fieldStatus dropdown
    // return should be true or false
    return {
      codeStatus: selectedFormValue.fieldStatus?.value !== MEMBER_LIST_STATIC_RECORD.FIELD_STATUS.RELEASED,
      demoStatus:
        selectedFormValue.codeStatus?.value === MEMBER_LIST_STATIC_RECORD.CODE_STATUS.LOCKED &&
        selectedFormValue.fieldStatus?.value !== MEMBER_LIST_STATIC_RECORD.FIELD_STATUS.RELEASED,
      fieldStatus:
        selectedFormValue.codeStatus?.value === MEMBER_LIST_STATIC_RECORD.CODE_STATUS.LOCKED &&
        selectedFormValue.demoStatus?.value === MEMBER_LIST_STATIC_RECORD.DEMO_STATUS.LOCKED,
    };
  }, [selectedFormValue]);

  const dropdownCreateableMemberListField = {
    name: 'system',
    options: (systemMemberList?.data as Option[]) || [],
    label: 'System',
    placeholder: 'Please Select',
    colSubjectId: '4028819c84e5373a0184e5373a3d0000',
    disabled: true,
  } as Field;

  const isAnyLoading = isFetchingSystem || isFetchingOptionStatus;

  const columns: GridColDef[] = [
    { field: 'fullName', headerName: 'Username', width: 180 },
    {
      field: 'userName',
      headerName: 'Email',
      width: 240,
    },
  ];

  const selectedIds = useMemo(() => {
    if (selectedFormValue.userId) {
      return selectedFormValue.userId;
    }
    return [];
  }, [selectedFormValue.userId]);

  const handleOnSave = (data: SystemDetails) => {
    const payloadData = {
      codeStatus: data.codeStatus?.value || '',
      fieldStatus: data.fieldStatus?.value || '',
      demoStatus: data.demoStatus?.value || '',
      serverId: data.serverId?.value || '',
      system: data.system?.value || '',
      userId: data.userId,
      id,
    };
    updateSystem(payloadData, {
      onSuccess: () => {
        handleOnCancelInput();
        toast.success('System has been updated successfully!');
      },
    });
  };

  const handleOnCancelInput = () => {
    const url =
        searchParams.get('refBackParam') != null
            ? searchParams.get('refBackParam')
            : PATH_CONSTANT.TENANT_MASTER.MASTER_TABLE.SYSTEM;
    const redirectPath = decodeURIComponent(url as string);
    navigate(redirectPath);
  };


  useEffect(() => {
    if (tenantDetails) {
      formikRef.current?.setValues(tenantDetails?.data);
      setSelectedFormValue(tenantDetails?.data);
    }
  }, [tenantDetails]);

  useEffect(() => {
    if (
      selectedFormValue.codeStatus?.value === MEMBER_LIST_STATIC_RECORD.CODE_STATUS.CHANGING &&
      selectedFormValue.demoStatus?.value === MEMBER_LIST_STATIC_RECORD.DEMO_STATUS.LOCKED &&
      formikRef.current
    ) {
      formikRef.current.setFieldValue(
        'demoStatus',
        optionsStatus?.data.demoStatus.find((item) => item.value === MEMBER_LIST_STATIC_RECORD.DEMO_STATUS.CHANGING)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFormValue, optionsStatus?.data.demoStatus]);

  return (
    <TenantHomeDashboardNavigationBar>
      <Box sx={{ height: '100%' }}>
        {isLoading ? (
          <Stack
            direction='column'
            sx={{
              height: 500,
            }}
            justifyContent='center'
          >
            <ActivityIndicator />
          </Stack>
        ) : (
          <Formik
            innerRef={formikRef}
            initialValues={formInitialValues}
            validationSchema={UpdateSystemValidationSchema}
            onSubmit={handleOnSave}
          >
            {(formikProps: FormikProps<SystemDetails>) => {
              const { values, setFieldValue } = formikProps;
              return (
                <Box sx={{ backgroundColor: '#fff', pb: 3, px: 3 }} alignItems='center'>
                  <Stack
                    direction='row'
                    alignItems='center'
                    py={1}
                    justifyContent='space-between'
                    sx={{ backgroundColor: '#fff' }}
                    spacing={2}
                  >
                    <Stack>
                      <Typography variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
                        Edit System
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack>
                    <Typography variant='input-label' fontStyle='italic' sx={{ pt: 1, fontSize: 13 }}>
                      (<span style={{ color: 'red' }}>*</span>) indicates required fields
                    </Typography>
                  </Stack>

                  <Grid
                    container
                    marginTop={2}
                    sx={{ backgroundColor: '#fff', py: 1, borderTop: '1px solid #E3EBF6' }}
                    justifyContent='space-between'
                    display='flex'
                    flexWrap='wrap'
                    alignItems='flex-start'
                  >
                    <Grid container item xs={6} direction='column'>
                      <Stack>
                        <DropdownCreatableMemberList<SystemDetails>
                          required
                          formik={formikProps}
                          field={dropdownCreateableMemberListField}
                        />
                      </Stack>

                      <Stack
                        direction='row'
                        spacing={2}
                        alignItems='center'
                        justifyContent='space-between'
                        sx={{ mt: 2 }}
                      >
                        <Typography variant='input-label'>
                          Code Status
                          <span style={{ color: 'red', marginLeft: 2 }}>*</span>
                        </Typography>
                        <Autocomplete
                          disabled={!disableDropdownStatus.codeStatus}
                          options={optionsStatus?.data.codeStatus || []}
                          value={values.codeStatus}
                          getOptionLabel={(option: DropdownItem) => option.label}
                          clearIcon={null}
                          contentEditable={false}
                          onChange={(event, value) => {
                            setFieldValue('codeStatus', value);
                            if (value) {
                              setSelectedFormValue((prevState) => ({
                                ...prevState,
                                codeStatus: value,
                              }));
                            }
                          }}
                          renderOption={optionRenderInput}
                          sx={autocompleteStyles}
                          renderInput={(params) => <TextField {...params} sx={textInputAutoCompleteStyles} />}
                        />
                      </Stack>
                      <Stack
                        direction='row'
                        spacing={2}
                        alignItems='center'
                        justifyContent='space-between'
                        sx={{ mt: 2 }}
                      >
                        <Typography variant='input-label'>
                          Demo Status
                          <span style={{ color: 'red', marginLeft: 2 }}>*</span>
                        </Typography>
                        <Autocomplete
                          options={optionsStatus?.data.demoStatus || []}
                          // @ts-ignore
                          value={values.demoStatus}
                          disabled={!disableDropdownStatus.demoStatus}
                          getOptionLabel={(option) => option.label}
                          clearIcon={null}
                          contentEditable={false}
                          onChange={(event, value) => {
                            setFieldValue('demoStatus', value);
                            if (value) {
                              setSelectedFormValue((prevState) => ({
                                ...prevState,
                                demoStatus: value,
                              }));
                            }
                          }}
                          renderOption={optionRenderInput}
                          sx={autocompleteStyles}
                          renderInput={(params) => <TextField {...params} sx={textInputAutoCompleteStyles} />}
                        />
                      </Stack>
                      <Stack
                        direction='row'
                        spacing={2}
                        alignItems='center'
                        justifyContent='space-between'
                        sx={{ mt: 2 }}
                      >
                        <Typography variant='input-label'>
                          Field Status
                          <span style={{ color: 'red', marginLeft: 2 }}>*</span>
                        </Typography>
                        <Autocomplete
                          options={optionsStatus?.data.fieldStatus || []}
                          // @ts-ignore
                          value={values.fieldStatus}
                          disabled={!disableDropdownStatus.fieldStatus}
                          getOptionLabel={(option) => option.label}
                          clearIcon={null}
                          contentEditable={false}
                          onChange={(event, value) => {
                            setFieldValue('fieldStatus', value);
                            if (value) {
                              setSelectedFormValue((prevState) => ({
                                ...prevState,
                                fieldStatus: value,
                              }));
                            }
                          }}
                          renderOption={optionRenderInput}
                          sx={autocompleteStyles}
                          renderInput={(params) => <TextField {...params} sx={textInputAutoCompleteStyles} />}
                        />
                      </Stack>

                      <Stack
                        direction='row'
                        spacing={2}
                        alignItems='center'
                        justifyContent='space-between'
                        sx={{ mt: 2 }}
                      >
                        <Typography variant='input-label'>
                          Server
                          <span style={{ color: 'red', marginLeft: 2 }}>*</span>
                        </Typography>
                        <Autocomplete
                          options={serverOptions || []}
                          // @ts-ignore
                          value={values.serverId}
                          disabled
                          getOptionLabel={(option) => option.label}
                          clearIcon={null}
                          contentEditable={false}
                          onChange={(event, value) => {
                            setFieldValue('serverId', value);
                          }}
                          renderOption={optionRenderInput}
                          sx={autocompleteStyles}
                          renderInput={(params) => (
                            <TextField {...params} sx={textInputAutoCompleteStyles} placeholder='Select Server' />
                          )}
                        />
                      </Stack>

                      <Stack direction='column' spacing={2} justifyContent='space-between' sx={{ mt: 2 }}>
                        <Typography variant='input-label' mb={2}>
                          Assign Builder
                          <span style={{ color: 'red', marginLeft: 2 }}>*</span>
                        </Typography>
                        <div style={{ height: 250, width: '100%' }}>
                          {!isLoadingUsers && (
                            <DataGridPro
                              loading={isLoadingUsers}
                              selectionModel={selectedIds}
                              sx={dataGridStyle}
                              density='compact'
                              rows={usersData?.data || []}
                              columns={columns}
                              onSelectionModelChange={(newSelection) => {
                                if (usersData?.data) {
                                  // @ts-ignore
                                  setSelectedFormValue((prevState) => ({
                                    ...prevState,
                                    userId: newSelection,
                                  }));
                                  setFieldValue('userId', newSelection);
                                }
                              }}
                              pagination={false}
                              hideFooter
                              rowsPerPageOptions={[5]}
                              checkboxSelection={
                                !isAnyLoading ||
                                (!!selectedFormValue.system &&
                                  !!selectedFormValue.userId &&
                                  !!selectedFormValue.serverId)
                              }
                            />
                          )}
                        </div>
                      </Stack>
                      {isError && (
                        <Stack mt={2}>
                          <Alert severity='error'>{error.response?.data.message}</Alert>
                        </Stack>
                      )}
                    </Grid>
                  </Grid>
                  <Stack direction='row' spacing={2} justifyContent='flex-end'>
                    <Button disabled={isDeploying} onClick={handleOnCancelInput} variant='main-table-panel-border'>
                      Cancel
                    </Button>
                    <ButtonLoading
                      loading={isDeploying}
                      variant='main-table-panel'
                      onClick={() => formikProps.handleSubmit()}
                      disabled={!UpdateSystemValidationSchema?.isValidSync(formikProps.values) || isDeploying}
                    >
                      Save
                    </ButtonLoading>
                  </Stack>
                </Box>
              );
            }}
          </Formik>
        )}
      </Box>
    </TenantHomeDashboardNavigationBar>
  );
}
