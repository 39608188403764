import { AxiosResponse } from 'axios';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import axios from 'libs/Axios';

import { AxiosDefaultErrorEntity } from 'types/api/Common/ErrorTypes';

import {
  CustomRuleDetailsItem,
  CustomRuleDropdownForm,
  CustomRuleListItem,
  RequestUpsertCustomRule,
} from 'types/api/Tenant/AROKMS/CustomRuleTypes';
import { AutoCompleteItem } from 'types/api/Common/AutoCompleteTypes';
import { DISPLAY_TABLE_QUERY_KEY } from './DisplayTableService';

export const CUSTOM_RULE_DATA_QUERY_ = {
  DROPDOWN_FORM: 'CUSTOM_RULE_DROPDOWN_FORM',
  ATTRIBUTE_COLOR_RULE_DROPDOWN: 'ATTRIBUTE_COLOR_RULE_DROPDOWN',
  LIST: 'CUSTOM_RULE_LIST',
  DETAILS: 'CUSTOM_RULE_DETAILS',
};

export function useUpsertCustomRule(subjectId?: string | number) {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosDefaultErrorEntity, RequestUpsertCustomRule>(
    (data) => axios.post(`/api/v1/display-table/custom-rule/upsert/${subjectId}`, data).then((res) => res),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(CUSTOM_RULE_DATA_QUERY_.DROPDOWN_FORM);
        queryClient.invalidateQueries(CUSTOM_RULE_DATA_QUERY_.LIST);
        queryClient.invalidateQueries(DISPLAY_TABLE_QUERY_KEY.DATA_LIST);
      },
    }
  );
}

export function useGetCustomRuleDropdownFormData(subjectId?: string | number) {
  return useQuery<AxiosResponse<CustomRuleDropdownForm>, AxiosDefaultErrorEntity>(
    [CUSTOM_RULE_DATA_QUERY_.DROPDOWN_FORM, subjectId],
    () => axios.get(`/api/v1/display-table/custom-rule/dropdown-form/${subjectId}`).then((res) => res),
    {
      enabled: !!subjectId,
    }
  );
}

export function useGetAttributeRuleColorDropdown(subjectId?: string | number) {
  return useQuery<AxiosResponse<AutoCompleteItem[]>, AxiosDefaultErrorEntity>(
    [CUSTOM_RULE_DATA_QUERY_.ATTRIBUTE_COLOR_RULE_DROPDOWN, subjectId],
    () => axios.get(`/api/v1/display-table/custom-rule/dropdown-form/attribute-color/${subjectId}`).then((res) => res),
    {
      enabled: !!subjectId,
    }
  );
}

export function useGetCustomRuleDetails(customRuleId?: string) {
  return useQuery<AxiosResponse<CustomRuleDetailsItem>, AxiosDefaultErrorEntity>(
    [CUSTOM_RULE_DATA_QUERY_.DETAILS, customRuleId],
    () => axios.get(`/api/v1/display-table/custom-rule/${customRuleId}`).then((res) => res),
    {
      enabled: !!customRuleId,
    }
  );
}

export function useGetCustomRuleList(subjectId?: string | number) {
  return useQuery<AxiosResponse<CustomRuleListItem[]>, AxiosDefaultErrorEntity>(
    [CUSTOM_RULE_DATA_QUERY_.LIST, subjectId],
    () => axios.get(`/api/v1/display-table/custom-rule/list/${subjectId}`).then((res) => res),
    {
      enabled: !!subjectId,
    }
  );
}

export function useUpdateCustomRuleStatus() {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosDefaultErrorEntity, { customRuleId: string; enabled: boolean }>(
    ({ customRuleId, enabled }) =>
      axios.put(`/api/v1/display-table/custom-rule/${customRuleId}/status`, { enabled }).then((res) => res),
    {
      onError: (err) => {
        queryClient.invalidateQueries(CUSTOM_RULE_DATA_QUERY_.LIST);
      },
      onSuccess: () => {
        queryClient.invalidateQueries(DISPLAY_TABLE_QUERY_KEY.DATA_LIST);
        queryClient.invalidateQueries(CUSTOM_RULE_DATA_QUERY_.LIST);
      },
    }
  );
}

export function useDeleteCustomRule() {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosDefaultErrorEntity, string>(
    (customRuleId) => axios.delete(`/api/v1/display-table/custom-rule/${customRuleId}`).then((res) => res),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(CUSTOM_RULE_DATA_QUERY_.LIST);
        queryClient.invalidateQueries(DISPLAY_TABLE_QUERY_KEY.DATA_LIST);
      },
    }
  );
}
