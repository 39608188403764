import { Route, createBrowserRouter, createRoutesFromElements, createHashRouter } from 'react-router-dom';

import { PrivateRouteWrapper } from './PrivateRoute';
import { PageNotFound } from 'pages/Error/PageNotFound';
import { APP_ROUTES } from './DeclarationRoutes';

export const AppRoutesElements = createBrowserRouter(
  createRoutesFromElements(
    <>
      {APP_ROUTES.map((route) => (
        <Route
          key={route.id}
          path={route.path}
          element={
            <PrivateRouteWrapper
              allowedRoles={route.allowedRoles}
              isPrivate={route.private}
              accessAdminRightLevel={route.accressAdminRightLevel}
            >
              {route.component}
            </PrivateRouteWrapper>
          }
        />
      ))}
      <Route path='*' element={<PageNotFound />} />
    </>
  )
);

export const TenantRoutesElements = createHashRouter(
  createRoutesFromElements(
    <>
      {APP_ROUTES.map((route) => (
        <Route
          key={route.id}
          path={route.path}
          element={
            <PrivateRouteWrapper
              allowedRoles={route.allowedRoles}
              isPrivate={route.private}
              accessAdminRightLevel={route.accressAdminRightLevel}
            >
              {route.component}
            </PrivateRouteWrapper>
          }
        />
      ))}
      <Route path='*' element={<PageNotFound />} />
    </>
  )
);
