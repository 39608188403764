import { GridPreProcessEditCellProps, GridRenderEditCellParams, GridValueFormatterParams } from '@mui/x-data-grid-pro';
import { DataGridCellTextInput } from 'components/DatatableComponent/RenderCustomTextInput';

//  Rule Type: For Decimal
// #RULE_DECIMAL_OPERATION
export function RuleNumberOperation(operation: (values: number[]) => number, columns: string[]) {
  return {
    renderEditCell: (params: GridRenderEditCellParams) => <DataGridCellTextInput {...params} />,
    preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
      const values = columns.map((field) => {
        const fieldValue = params?.otherFieldsProps?.[field].value || 0;
        return fieldValue ? Number(fieldValue) : 0;
      });

      const value = operation(values);

      return { ...params.props, value };
    },
    valueFormatter: (params: GridValueFormatterParams) => params.value?.toLocaleString(),
  };
}

// Examples of using RuleNumberOperation function
export const RuleNumberAddition = (columns: string[]) =>
  RuleNumberOperation((values) => values.reduce((a, b) => a + b, 0), columns);

export const RuleNumberSubstraction = (columns: string[]) =>
  RuleNumberOperation((values) => values.reduce((a, b) => a - b), columns);

export const RuleNumberMultiplication = (columns: string[]) =>
  RuleNumberOperation((values) => values.reduce((a, b) => a * b, 1), columns);

export const RuleNumberDivision = (columns: string[]) =>
  RuleNumberOperation((values) => values.reduce((a, b) => a / b), columns);
