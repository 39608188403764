import React, { forwardRef, useImperativeHandle } from 'react';
import Typography from '@mui/material/Typography';
import {
  Alert,
  Card,
  CardHeader,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemText,
  Radio,
  Stack,
  TextField,
} from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import { IColumn } from 'types/api/Tenant/EventDataSourceTypes';
import { matchSorter } from 'match-sorter';
import CancelIcon from '@mui/icons-material/Cancel';
import { HighlightedText } from 'components/HighlightedTextComponent';
const textInputStyles = {
  width: '100%',
  '& .MuiOutlinedInput': {
    padding: '1px 2px',
  },
};
interface IListColumnProps {
  items: IColumn[];
  title: string;
  subheader: string;
  value?: IColumn | null;
  otherPairValue?: IColumn | null;
  onChange: (value: IColumn) => void;
  warningMessage?: string;
}
const chipStaticSubjectStyle = { fontSize: 10, padding: 0, height: 'auto' };

export interface PairListColumnRef {
  resetSearch: () => void;
}

export const ListColumn = forwardRef<PairListColumnRef, IListColumnProps>((props, ref) => {
  const {
    items: listOptions,
    title,
    subheader,
    value: selectedValue,
    onChange = () => {},
    otherPairValue,
    warningMessage,
  } = props;

  const [searchKeyword, setSearchKeyword] = React.useState('');

  const items = React.useMemo(() => {
    if (listOptions.length > 0) {
      return matchSorter(listOptions, searchKeyword, { keys: ['columnName', 'dataType'] });
    }
    return [];
  }, [listOptions, searchKeyword]);

  useImperativeHandle(ref, () => ({
    resetSearch: () => setSearchKeyword(''),
  }));

  return (
    <Card
      elevation={0}
      sx={{
        width: '30%',
        border: '1px solid #aac5e6',
      }}
    >
      <CardHeader
        sx={{ px: 2, py: 1 }}
        action={
          <TextField
            value={searchKeyword}
            onChange={(e) => setSearchKeyword(e.target.value)}
            name='search'
            sx={textInputStyles}
            size='small'
            placeholder='Search...'
            type='text'
            InputProps={{
              endAdornment: (
                <CancelIcon
                  sx={{
                    display: searchKeyword ? 'block' : 'none',
                    fontSize: 14,
                    color: '#98A2AE',
                    cursor: 'pointer',
                  }}
                  onClick={() => setSearchKeyword('')}
                />
              ),
            }}
          />
        }
        title={
          <Stack>
            <Typography variant='input-label' fontSize={14} fontWeight={800}>
              {title}
            </Typography>
            <Typography variant='input-label-gray' fontSize={13}>
              {subheader}
            </Typography>
          </Stack>
        }
      ></CardHeader>
      {warningMessage && <Alert severity='warning'>{warningMessage}</Alert>}
      <Divider />
      <List
        sx={{
          height: 330,
          bgcolor: 'background.paper',
          overflow: 'auto',
        }}
        dense
        component='div'
        role='list'
      >
        {items.map((column) => {
          const labelId = `transfer-list-all-item-${column.columnName}-label`;

          return (
            <>
              <ListItem
                key={column.columnName}
                role='listitem'
                sx={{
                  opacity: otherPairValue && otherPairValue?.dataType !== column.dataType ? 0.3 : 1,
                  cursor: otherPairValue && otherPairValue?.dataType !== column.dataType ? 'not-allowed' : 'default',
                  p: 0,
                  m: 0,
                }}
              >
                <ListItemIcon>
                  <Radio
                    checked={selectedValue?.id === column.id}
                    onClick={() => onChange(column)}
                    disabled={(otherPairValue && otherPairValue?.dataType !== column.dataType) || false}
                    value={column.columnName}
                    sx={{
                      p: 0.5,
                      m: 0.4,
                    }}
                    name={column.id?.toString()}
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemIcon>
                <ListItemText
                  id={labelId}
                  primary={
                    <Typography variant='body2'>
                      <HighlightedText text={column.columnName} highlight={searchKeyword} key={labelId} />
                    </Typography>
                  }
                  secondary={
                    <Chip
                      label={<HighlightedText text={column.dataType} highlight={searchKeyword} key={labelId} />}
                      color='info'
                      variant='outlined'
                      sx={chipStaticSubjectStyle}
                      size='small'
                    />
                  }
                />
              </ListItem>
              <Divider />
            </>
          );
        })}
      </List>
    </Card>
  );
});
