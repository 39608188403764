import { RULES_CONSTANT } from 'constant/RuleConstant';
import { Column, Field } from 'types/api/Tenant/AROKMS/DisplayTableTypes';

// Define the sorting order
const ruleOrder = {
  [RULES_CONSTANT.BASE_SUBJECT.RULE_ATTRIBUTE_SUBJECT_BASE]: 1,
  [RULES_CONSTANT.ASSOCIATED_SUBJECT.RULE_ASSOCIATED_SUBJECT_LIST]: 2,
  [RULES_CONSTANT.ASSOCIATED_SUBJECT.RULE_ASSOCIATED_SUBJECT_LIMITED_DROPDOWN_LIST]: 3,
  [RULES_CONSTANT.TEXT.RULE_SELECT_FROM_KTREE_TABLE]: 4,
  [RULES_CONSTANT.TEXT.RULE_PULL_FROM_SUBJECT_ATTRIBUTE]: 4,
  [RULES_CONSTANT.DATE.RULE_DATE_INPUT]: 5,
  [RULES_CONSTANT.TEXT.RULE_INPUT]: 5,
  [RULES_CONSTANT.INTEGER.RULE_INTEGER]: 6,
  [RULES_CONSTANT.NUMBER.RULE_DECIMAL_INPUT]: 7,
  [RULES_CONSTANT.CURRENCY.RULE_CURRENCY]: 8,
  [RULES_CONSTANT.PERCENTAGE.RULE_PERCENTAGE_INPUT]: 9,
  [RULES_CONSTANT.TEXT.RULE_CONCATENATE_ATTRIBUTES]: 10,
  [RULES_CONSTANT.MULTIMEDIA.RULE_MULTIMEDIA]: 997,
  [RULES_CONSTANT.GPS.RULE_GPS]: 998,
};

const defaultOrder = 500;

export function columnOrderForDedicatedView(data: Field[]) {
  const newData = [...data];
  return newData.sort((a, b) => {
    // If ruleTypeCode is in ruleOrder, use it, otherwise use the default order
    const orderA = ruleOrder[a.ruleTypeCode] !== undefined ? ruleOrder[a.ruleTypeCode] : defaultOrder;
    const orderB = ruleOrder[b.ruleTypeCode] !== undefined ? ruleOrder[b.ruleTypeCode] : defaultOrder;

    return orderA - orderB;
  });
}

export function getClosestNumericFieldIndex(fields: Column[]): number {
  for (let i = 0; i < fields.length; i++) {
    if (fields[i].numeric) {
      return i;
    }
  }
  return -1;
}
