import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useLeavePageConfirm = (active: boolean, message: string) => {
  const location = useLocation();
  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (active) {
        e.preventDefault();
        e.returnValue = message;
      }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [message, active, location]);
};
