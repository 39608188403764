import _ from 'lodash';
import { useState, useMemo, forwardRef, useImperativeHandle, useCallback, useEffect } from 'react';
import { CustomDataTable } from 'components/DatatableComponent';
import {
  GridSortModel,
  getGridStringOperators,
  GridFilterModel,
  GridActionsCellItem,
  GridColumns,
} from '@mui/x-data-grid-pro';
import EditIcon from '@mui/icons-material/Edit';
import { PaginationAndSortingParams } from 'types/api/Common/PaginationTypes';
import { InsightViewAttributeDefinitionPanel } from './InsightViewAttributeDefinitionPanel';
import {
  InsightViewAttributeItem,
  InsightViewCellColorItem,
} from 'types/api/SystemTenant/AROView/InsightViewDefinitionTypes';
import { ModalAddCellColor } from './ModalAddCellColor';
import { MAP_COLOR_CODE_WITH_COLOR_HEXA } from 'constant/ViewConstant';
import { Stack } from '@mui/material';

interface IProps {
  disabled?: boolean;
  initialData?: InsightViewCellColorItem[];
  attributeDefinitionData: InsightViewAttributeItem[];
  cubeId?: string;
}

export interface IInsightViewCellColorDefinitionRef {
  getRowData: () => InsightViewCellColorItem[];
  setRowData: (data: InsightViewCellColorItem[]) => void;
}

function ColorOptionIndicator(props: { value: string | null }) {
  const { value } = props;
  if (!value) return null;
  return (
    <span
      style={{
        width: 25,
        height: 20,
        display: 'inline-block',
        backgroundColor: MAP_COLOR_CODE_WITH_COLOR_HEXA.get(value),
      }}
    />
  );
}
export const InsightViewCellColorDefinitionTable = forwardRef<any, IProps>((props, ref) => {
  const { disabled: disabledProps, initialData, attributeDefinitionData, cubeId } = props;
  const disabled = disabledProps ?? attributeDefinitionData.length === 0;

  const [rowData, setRowData] = useState<InsightViewCellColorItem[]>([] as InsightViewCellColorItem[]);
  const [showModalAddCellColor, setShowModalAddCellColor] = useState<{
    visible: boolean;
    data?: InsightViewCellColorItem;
  }>({
    visible: false,
  });

  const [filter, setFilter] = useState<PaginationAndSortingParams>({
    page: 0,
    size: 100,
    filterValue: '',
    sortType: '',
    filterOperator: '',
  });

  const handleEditClick = (row: InsightViewCellColorItem) => () => {
    if (!row) return;
    setShowModalAddCellColor({ visible: true, data: row });
  };

  const columnsAttribute = useMemo<GridColumns>(() => {
    return [
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Edit',
        width: 30,
        cellClassName: 'actions',

        getActions: (params) => {
          return [
            // @ts-ignore
            <GridActionsCellItem
              icon={<EditIcon />}
              key={params.id}
              label='Edit'
              onClick={handleEditClick(params.row)}
            />,
          ];
        },
      },
      {
        field: 'cubeAttribute',
        headerName: 'Cube Attribute',
        width: 240,
        editable: false,
        filterable: true,
        sortable: true,
        valueGetter: (params) => {
          return params?.row?.cubeAttribute.label;
        },
        filterOperators: getGridStringOperators().filter((operator) => ['contains', 'equals'].includes(operator.value)),
      },
      {
        field: 'lessThanValue',
        headerName: 'Value Less Than',
        width: 240,
        editable: false,
        filterable: true,
        sortable: true,

        filterOperators: getGridStringOperators().filter((operator) => ['contains', 'equals'].includes(operator.value)),
      },
      {
        field: 'lessThanColor',
        headerName: 'Cell Color',
        width: 240,
        editable: false,
        filterable: true,
        sortable: true,

        renderCell: (params) => {
          if (!params.value) return <></>;
          return (
            <Stack direction='row' alignItems='center' gap={1}>
              <ColorOptionIndicator value={params.value.value} />
              <span>{params.value.label}</span>
            </Stack>
          );
        },
        filterOperators: getGridStringOperators().filter((operator) => ['contains', 'equals'].includes(operator.value)),
      },
      {
        field: 'greaterThanValue',
        headerName: 'Value Greater Than',
        width: 240,
        editable: false,
        filterable: true,
        sortable: true,

        filterOperators: getGridStringOperators().filter((operator) => ['contains', 'equals'].includes(operator.value)),
      },
      {
        field: 'greaterThanColor',
        headerName: 'Cell Color',
        width: 240,
        editable: false,
        filterable: true,
        sortable: true,
        renderCell: (params) => {
          if (!params.value) return <></>;
          return (
            <Stack direction='row' alignItems='center' gap={1}>
              <ColorOptionIndicator value={params.value.value} />
              <span>{params.value.label}</span>
            </Stack>
          );
        },
        filterOperators: getGridStringOperators().filter((operator) => ['contains', 'equals'].includes(operator.value)),
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnSortModelChange = (model: GridSortModel) => {
    if (model.length === 0) {
      setFilter({ ...filter, sortType: 'asc' });
      return;
    }
    setFilter({ ...filter, sortType: model[0].sort });
  };

  const usedCubeAttributeIds: Set<string> = useMemo(() => {
    const usedIds = new Set<string>();
    rowData.forEach((item) => {
      item.cubeAttribute && usedIds.add(item.cubeAttribute?.value.toString());
    });
    return usedIds;
  }, [rowData]);

  const handleOnFilterModelChange = (params: GridFilterModel) => {
    let filterObj = {};
    if (params.items.length) {
      filterObj = {
        filterValue: params.items[0].value,
        filterOperator: params.items[0].operatorValue,
      };
    } else {
      filterObj = {
        filterValue: '',
        filterOperator: 'contains',
      };
    }
    setFilter({
      ...filter,
      ...filterObj,
    });
  };

  const handleChangePage = (newPage: number) => {
    setFilter({ ...filter, page: newPage });
  };

  const handleChangePageSize = (newPageSize: number) => {
    setFilter({ ...filter, size: newPageSize });
  };

  const handleOnAddVarianceButtonClick = () => {
    setShowModalAddCellColor({ visible: true });
  };

  const handleOnCloseModalAddVariance = () => {
    setShowModalAddCellColor({ visible: false });
  };

  const handleOnSaveVarianceItem = (data: InsightViewCellColorItem) => {
    const itemIndex = _.findIndex(rowData, { id: data.id });
    if (itemIndex !== -1) {
      setRowData((prevData) => {
        const newData = [...prevData];
        newData[itemIndex] = data;
        return newData;
      });
    } else {
      setRowData([...rowData, data]);
    }
  };

  const handleOnDeleteVariance = (data: InsightViewCellColorItem) => {
    setRowData((prevData) => {
      return prevData.filter((item) => item.id !== data.id);
    });
  };

  useImperativeHandle(ref, () => ({
    getRowData: () => rowData,
    setRowData: (data: InsightViewCellColorItem[]) => {
      setRowData(data);
    },
  }));

  const getRowId = useCallback((row) => {
    return row.id;
  }, []);

  useEffect(() => {
    if (initialData) {
      setRowData(initialData);
    }
  }, [initialData]);

  console.log({
    rowData,
  });

  return (
    <>
      <InsightViewAttributeDefinitionPanel
        buttonAddColumnLabel='Add Cell Colour'
        onAddColumnAttributeButtonClick={handleOnAddVarianceButtonClick}
        disabled={disabled}
      />
      <CustomDataTable
        rows={rowData}
        checkboxSelection={false}
        columns={columnsAttribute}
        getRowId={getRowId}
        disableSelectionOnClick
        sortingMode='client'
        filterMode='client'
        paginationMode='client'
        isRowSelectable={(row) => {
          return !row.row.locked;
        }}
        isCellEditable={(params) => {
          return !params?.row?.locked;
        }}
        onSortModelChange={handleOnSortModelChange}
        onFilterModelChange={handleOnFilterModelChange}
        onPageChange={handleChangePage}
        onPageSizeChange={handleChangePageSize}
        rowHeight={45}
        autoHeight={rowData.length !== 0 && rowData.length > 5}
        sx={{ opacity: disabled ? 0.5 : 1, pointerEvents: disabled ? 'none' : 'auto', height: '300px' }}
        onProcessRowUpdateError={(params) => console.log({ params })}
        getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
        experimentalFeatures={{ newEditingApi: true }}
        components={{
          Toolbar: null,
          Pagination: null,
        }}
      />

      <ModalAddCellColor
        usedCubeAttributeIds={usedCubeAttributeIds}
        visible={showModalAddCellColor.visible}
        onClose={handleOnCloseModalAddVariance}
        selectedData={showModalAddCellColor.data}
        onDelete={handleOnDeleteVariance}
        onSave={handleOnSaveVarianceItem}
        cubeId={cubeId}
      />
    </>
  );
});
