import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import qs from 'query-string';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import InsightsOutlined from '@mui/icons-material/InsightsOutlined';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import { Paper, Typography, SvgIcon, Stack, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as CustomIconSubject } from 'assets/icons/icon-menu-subject.svg';
import { ReactComponent as CustomIconKTree } from 'assets/icons/icon-menu-ktree.svg';
import { ReactComponent as CustomIconEvent } from 'assets/icons/icon-menu-event.svg';
import { ReactComponent as CustomIconCube } from 'assets/icons/icon-menu-cube.svg';
import { PATH_CONSTANT } from 'constant/PathConstant';
import { NAVIGATION_COMPONENT_TYPE } from 'constant/NavigationConstant';
import { QuickAccessItem } from 'types/api/Tenant/ConfigurationTypes';
import { useGetMyPersonalization } from 'services/v1/Tenant/ConfigurationService';
import PollOutlined from '@mui/icons-material/PollOutlined';

const getIcon = (type: NAVIGATION_COMPONENT_TYPE) => {
  switch (type) {
    case NAVIGATION_COMPONENT_TYPE.K_TREE:
      return (
        <SvgIcon
          fontSize='medium'
          sx={{ color: '#42BB93', fill: 'transparent' }}
          inheritViewBox
          component={CustomIconKTree}
        />
      );
    case NAVIGATION_COMPONENT_TYPE.SUBJECT:
      return (
        <SvgIcon
          fontSize='medium'
          sx={{ color: '#42BB93', fill: 'transparent' }}
          inheritViewBox
          component={CustomIconSubject}
        />
      );
    case NAVIGATION_COMPONENT_TYPE.EVENT:
      return (
        <SvgIcon
          fontSize='medium'
          sx={{ color: '#42BB93', fill: 'transparent' }}
          inheritViewBox
          component={CustomIconEvent}
        />
      );
    case NAVIGATION_COMPONENT_TYPE.CUBE:
      return <SvgIcon fontSize='medium' sx={{ color: '#42BB93' }} inheritViewBox component={CustomIconCube} />;
    case NAVIGATION_COMPONENT_TYPE.INSIGHT_VIEW:
      return (
        <InsightsOutlined
          fontSize='medium'
          sx={{
            color: '#42BB93',
          }}
        />
      );
    case NAVIGATION_COMPONENT_TYPE.STANDARD_VIEW:
      return (
        <PollOutlined
          fontSize='medium'
          sx={{
            color: '#42BB93',
          }}
        />
      );
    case NAVIGATION_COMPONENT_TYPE.DASHBOARD:
      return (
        <DashboardOutlinedIcon
          fontSize='medium'
          sx={{
            color: '#42BB93',
          }}
        />
      );
    default:
      return null;
  }
};

export const getQuickAccessNavigateURL = (
  item?: QuickAccessItem | null,
  withProxyMemberParam?: boolean
): string | null => {
  let URL = null;
  if (!item) return URL;
  switch (item.componentType) {
    case NAVIGATION_COMPONENT_TYPE.INSIGHT_VIEW:
      URL = `${PATH_CONSTANT.TENANT.CUBE.INSIGHT.replace(':cubeId', item.cubeId).replace(
        ':insightId',
        item.value
      )}?${qs.stringify({
        memberId: item.memberId,
        defaultXDimension: item.defaultDimensionFilter?.defaultXDimension,
        defaultYDimension: item.defaultDimensionFilter?.defaultYDimension,
        defaultFilterMember: item.defaultDimensionFilter?.defaultFilterMember,
        myViewId: item.viewId,
        workFlowId: item.drillDownWorkflows[0]?.id,
        proxyMainMemberId: withProxyMemberParam ? item.memberId : undefined,
      })}`;
      break;
    case NAVIGATION_COMPONENT_TYPE.STANDARD_VIEW:
      URL = `${PATH_CONSTANT.TENANT.VIEW.STANDARD_VIEW.replace(':viewId', item.value)}?${qs.stringify({
        memberId: item.memberId,
        myViewId: item.viewId,
      })}`;
      break;

    case NAVIGATION_COMPONENT_TYPE.CUBE:
      URL = `${PATH_CONSTANT.TENANT.CUBE.CUBE_DIMENSIONAL_VIEW.replace(':cubeId', item.cubeId)}?${qs.stringify({
        memberId: item.memberId,
        myViewId: item.viewId,
      })}`;
      break;

    case NAVIGATION_COMPONENT_TYPE.DASHBOARD:
      URL = `${PATH_CONSTANT.TENANT.DASHBOARD.DASHBOARD.replace(':dashboardId', item.title)}?${qs.stringify({
        memberId: item.memberId,
        myViewId: item.viewId,
      })}`;
      break;
    case NAVIGATION_COMPONENT_TYPE.EVENT:
      URL = `${PATH_CONSTANT.TENANT.EVENT.EVENT.replace(':subjectId', item.value)}?${qs.stringify({
        refSubjectId: item.subjectId,
        myViewId: item.viewId,
      })}`;
      break;

    default:
      break;
  }
  return URL;
};

export function QuickAccessBox(props: { onClose?: () => void }) {
  const { onClose } = props;
  const navigate = useNavigate();

  const { data: allMyViewsData } = useGetMyPersonalization({ enabled: true });

  const handleOnNavigate = (item: QuickAccessItem) => {
    const navigateURL = getQuickAccessNavigateURL(item);
    if (navigateURL) {
      navigate(navigateURL);
    }
    onClose?.();
  };
  return (
    <Paper sx={{ width: '100%', p: 2 }} variant='outlined'>
      {allMyViewsData?.data && allMyViewsData?.data?.myViewItems.length > 0 && (
        <Stack direction='row' gap={1} mb={1}>
          <StarBorderOutlinedIcon
            fontSize='medium'
            sx={{
              color: '#42BB93',
            }}
          />
          <Typography variant='body1' fontWeight='bold' color='text.primary'>
            myView
          </Typography>
        </Stack>
      )}

      <Divider sx={{ mb: 1 }} />
      <Stack width='100%'>
        <List
          sx={{
            width: '100%',
            bgcolor: 'background.paper',
            display: 'flex',
            flexDirection: 'row',
            gap: 1,
            flexWrap: 'wrap',
          }}
        >
          {allMyViewsData?.data?.myViewItems?.map((item, index) => (
            <Button
              key={item.value}
              sx={{
                width: 'auto',
                border: '1px solid #E0E0E0',
                alignItems: 'center',
                display: 'flex',
                textTransform: 'none',
                flexDirection: 'row',
                paddingX: 2,
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                  border: '1px solid #42BB93',
                },
              }}
              onClick={() => {
                handleOnNavigate(item);
              }}
            >
              <Stack width={40}>{getIcon(item.componentType)}</Stack>

              <Stack width='auto' direction='column' alignItems='flex-start'>
                <Typography variant='body2' fontWeight='bold' textAlign='left'>
                  {item.title}
                </Typography>
                <Typography variant='body2' color='text.secondary' textAlign='left'>
                  {item.secondaryTitle}
                </Typography>
                <Typography variant='body2' color='text.secondary' fontStyle='italic'>
                  {item.memberName}
                </Typography>
              </Stack>
            </Button>
          ))}
        </List>
      </Stack>
    </Paper>
  );
}
