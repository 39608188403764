// @ts-nocheck
import { Formik, FormikProps } from 'formik';
import { useRef, useEffect, useMemo } from 'react';
import Divider from '@mui/material/Divider';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import ButtonLoading from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import ContactlessOutlinedIcon from '@mui/icons-material/ContactlessOutlined';
import { Field } from 'types/api/Tenant/AROKMS/DisplayTableTypes';
import {
  useAddServer,
  useGetServerDetails,
  useServerMemberListDropdown,
  useTestServerConnection,
} from 'services/v1/TenantMaster/MasterTable/ServerTableDataService';
import { SystemItem } from 'types/api/TenantMaster/MasterTable/SystemTableDataTypes';
import { RULES_CONSTANT } from 'constant/RuleConstant';
import { valueSafeDecryptor, valueSafeEcryptor } from 'utils/Encryptor';
import { Chip } from '@mui/material';
import { DropdownCreatableMemberList } from 'pages/Tenant/AROKMS/DataInput/components/DropdownCreatableMemberList';

const ModalContentStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  minHeight: 400,
  borderRadius: 1,
  px: 3,
  py: 2,
};

const textInputStyles = {
  width: '55%',
  '& .MuiOutlinedInput': {
    padding: '1px 2px',
  },
};

const closeIconStyle = { color: '#98A2AE', cursor: 'pointer' };

interface formValues {
  serverName: string;
  description: string;
  host: string;
  username: string;
  password: string;
  port: string;
}

const formInitialValues: formValues = {
  serverName: '',
  description: '',
  host: '',
  username: '',
  password: '',
  port: '',
};

interface ModalAddServerProps {
  visible?: boolean;
  data?: SystemItem | null;
  onClose?: () => void;
  editId?: string | null;
  type: string;
}

export function ModalAddServerComponent(props: ModalAddServerProps) {
  const formikRef = useRef<FormikProps<formValues>>(null);
  const { onClose, visible = false, type = 'ADD' } = props;

  const {
    mutate: testServerConnection,
    isLoading: isTesting,
    isSuccess: isServerConnected,
    isError: isServerConnectionError,
    error: serverConnectionError,
    data: serverConnectionData,
    reset,
  } = useTestServerConnection();
  const { mutate: createNewServer, isLoading: isDeploying, error, isError } = useAddServer();
  const { data: serverDetailsData, isLoading, remove } = useGetServerDetails(props.editId);
  const { data: serverMemberListOptions } = useServerMemberListDropdown();

  const handleOnCancel = () => {
    onClose?.();
    reset();
    remove();
  };

  const handleOnSave = (data: formValues) => {
    createNewServer(
      {
        ...data,
        serverName: data?.serverName?.value,
        password: valueSafeEcryptor(data?.password),
        username: valueSafeEcryptor(data?.username),
      },
      {
        onSuccess: () => {
          onClose?.();
        },
      }
    );
  };
  const handleOnTestConnection = () => {
    const payloadData = formikRef?.current?.values;
    payloadData &&
      testServerConnection({
        ...payloadData,
        serverName: payloadData?.serverName?.value,
        password: valueSafeEcryptor(payloadData?.password),
        username: valueSafeEcryptor(payloadData?.username),
      });
  };

  const fieldItem: Field = useMemo(() => {
    if (serverMemberListOptions) {
      return {
        name: 'serverName',
        options: serverMemberListOptions.data.options,
        label: 'Server Name',
        placeholder: 'Server Name',
        colSubjectId: serverMemberListOptions.data.colSubjectId,
      };
    }
    return [];
  }, [serverMemberListOptions]);

  useEffect(() => {
    if (formikRef.current) {
      if (type === 'EDIT' && serverDetailsData && serverMemberListOptions) {
        const { serverName, serverDescription, host, username, port, password } = serverDetailsData.data;
        const serverNameValue = serverMemberListOptions?.data.options.find((item) => item.value === serverName);
        formikRef?.current?.setValues({
          serverName: serverNameValue,
          description: serverDescription,
          host,
          username,
          password: valueSafeDecryptor(password),
          port,
        });
      }
    }
  }, [visible, serverDetailsData, type, formikRef, serverMemberListOptions]);

  if (isLoading) return null;

  return (
    <div>
      <Formik innerRef={formikRef} initialValues={formInitialValues} onSubmit={handleOnSave}>
        {(formikProps) => {
          const { handleSubmit, values, handleChange } = formikProps;
          return (
            <Modal open={visible} onClose={onClose}>
              <Stack direction='column' sx={ModalContentStyle} justifyContent='space-between'>
                <Stack direction='column'>
                  <Stack justifyContent='space-between' direction='row' alignItems='center' spacing={2}>
                    <Typography variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
                      {type === 'ADD' ? 'Add New Server' : 'Edit Server'}
                    </Typography>
                    <IconButton onClick={onClose} component='label'>
                      <HighlightOffRoundedIcon sx={closeIconStyle} />
                    </IconButton>
                  </Stack>
                  <Divider sx={{ mb: 1 }} />
                  <Stack>
                    <DropdownCreatableMemberList
                      field={fieldItem}
                      formik={formikProps}
                      ruleTypeCode={RULES_CONSTANT.BASE_SUBJECT.RULE_ATTRIBUTE_SUBJECT_BASE}
                    />

                    <Stack
                      direction='row'
                      spacing={2}
                      alignItems='center'
                      justifyContent='space-between'
                      sx={{ mt: 2 }}
                    >
                      <Typography variant='input-label'>Server Description</Typography>
                      <TextField
                        sx={textInputStyles}
                        multiline
                        placeholder='Your server description...'
                        rows={2}
                        size='small'
                        onChange={handleChange}
                        value={values?.description}
                        name='description'
                      />
                    </Stack>
                    <Stack
                      direction='row'
                      spacing={2}
                      alignItems='center'
                      justifyContent='space-between'
                      sx={{ mt: 2 }}
                    >
                      <Typography variant='input-label'>Host</Typography>
                      <TextField
                        sx={textInputStyles}
                        size='small'
                        placeholder='Example: server.yourdomain.com'
                        onChange={handleChange}
                        value={values?.host}
                        name='host'
                      />
                    </Stack>

                    <Stack
                      direction='row'
                      spacing={2}
                      alignItems='center'
                      justifyContent='space-between'
                      sx={{ mt: 2 }}
                    >
                      <Typography variant='input-label'>Port</Typography>
                      <Stack sx={{ width: '55%' }}>
                        <TextField
                          sx={{ ...textInputStyles, width: '45%' }}
                          size='small'
                          placeholder='Example: 5432'
                          onChange={handleChange}
                          value={values?.port}
                          name='port'
                        />
                      </Stack>
                    </Stack>
                    <Stack
                      direction='row'
                      spacing={2}
                      alignItems='center'
                      justifyContent='space-between'
                      sx={{ mt: 2 }}
                    >
                      <Typography variant='input-label'>Username</Typography>
                      <TextField
                        sx={textInputStyles}
                        size='small'
                        placeholder='Your database username'
                        onChange={handleChange}
                        value={values?.username}
                        name='username'
                      />
                    </Stack>
                    <Stack
                      direction='row'
                      spacing={2}
                      alignItems='center'
                      justifyContent='space-between'
                      sx={{ mt: 2 }}
                    >
                      <Typography variant='input-label'>Password</Typography>
                      <TextField
                        sx={textInputStyles}
                        size='small'
                        type='password'
                        onChange={handleChange}
                        placeholder='Your database password'
                        value={values?.password}
                        name='password'
                      />
                    </Stack>
                    <Stack
                      direction='row'
                      spacing={2}
                      alignItems='center'
                      justifyContent='space-between'
                      sx={{ mt: 2 }}
                    >
                      <Typography variant='input-label'>Status</Typography>
                      <Stack sx={{ width: '55%' }}>
                        {serverConnectionData === undefined && !isServerConnectionError && (
                          <Typography variant='body2'>-</Typography>
                        )}
                        {serverConnectionData !== undefined && isServerConnected && (
                          <Chip label='Connected!' color='success' sx={{ width: '30%', bgcolor: '#42BB93' }} />
                        )}

                        {serverConnectionData === undefined && isServerConnectionError && (
                          // @ts-ignore
                          <Typography variant='body2' sx={{ color: '#BB4942' }}>
                            {serverConnectionError?.response?.data?.message}
                          </Typography>
                        )}
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>

                <Stack>{isError && <Alert severity='error'>{error.response?.data.message}</Alert>}</Stack>

                <Divider sx={{ mb: 1, mt: 2 }} />
                <Stack direction='row' justifyContent='space-between' alignItems='center'>
                  <ButtonLoading
                    disabled={Object.values(values).some((value) => !value)}
                    variant='main-table-panel'
                    loading={isTesting}
                    startIcon={<ContactlessOutlinedIcon />}
                    onClick={handleOnTestConnection}
                  >
                    Test Connection
                  </ButtonLoading>
                  <Stack direction='row' justifyContent='flex-end' spacing={2} alignItems='center' sx={{ py: 1 }}>
                    <Button disabled={isDeploying} variant='main-table-panel-border' onClick={handleOnCancel}>
                      Cancel
                    </Button>
                    <ButtonLoading
                      disabled={Object.values(values).some((value) => !value) || !isServerConnected}
                      variant='main-table-panel'
                      loading={isDeploying}
                      onClick={() => handleSubmit()}
                    >
                      Save
                    </ButtonLoading>
                  </Stack>
                </Stack>
              </Stack>
            </Modal>
          );
        }}
      </Formik>
    </div>
  );
}
