import { useIsMutating } from 'react-query';
import { useTranslation } from 'react-i18next';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import ButtonLoading from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Alert from '@mui/material/Alert';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';

const ModalContentStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: 1,
  px: 3,
  py: 2,
};

const buttonActionStyle = { width: '100%', textTransform: 'none' };

const closeIconStyle = { color: '#98A2AE', cursor: 'pointer' };

export type ModalDeleteState = {
  title?: string;
  message: string;
  open: boolean;
  isError?: boolean;
  key?: string | number;
  errorMessage?: string;
};

export interface ModalDeleteProps {
  visible?: boolean;
  onClose?: () => void;
  onCancel?: () => void;
  onApprove?: () => void;
  title?: string | null;
  message?: string;
  isError?: boolean;
  errorMessage?: string | null;
}

export default function ModalDeleteComponent(props: ModalDeleteProps) {
  const isMutating = Boolean(useIsMutating());
  const { t } = useTranslation();
  const {
    onApprove,
    onCancel,
    onClose,
    visible = false,
    errorMessage = 'One of the subject selected cannot be deleted as it is being referenced by other data in the system',
    isError = false,
    message = 'Subject : Car ',
    title = t('COMMON.MODAL_DELETE.DESCRIPTION'),
  } = props;

  return (
    <div>
      <Modal open={visible} onClose={onClose}>
        <Stack direction='column' sx={ModalContentStyle}>
          <Stack justifyContent='space-between' direction='row' alignItems='center'>
            <Typography variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
              {t('COMMON.MODAL_DELETE.TITLE')}
            </Typography>
            <IconButton onClick={onClose} component='label' disabled={isMutating}>
              <HighlightOffRoundedIcon sx={closeIconStyle} />
            </IconButton>
          </Stack>
          <Divider sx={{ mb: 1 }} />
          {!isError && title && (
            <Stack alignItems='center' sx={{ py: 2, bgcolor: '#F7F7F7', mt: 2 }}>
              <Typography variant='body1'>{title} </Typography>
            </Stack>
          )}
          <Stack alignItems='center' sx={{ py: 3 }}>
            {!isError && (
              <Alert severity='warning'>
                <span
                  dangerouslySetInnerHTML={{
                    __html: message,
                  }}
                ></span>
              </Alert>
            )}
            {isError && (
              <Alert severity='error' sx={{ width: '90%', mt: 2, backgroundColor: '#FFFBE4', color: '#BC0000' }}>
                {errorMessage}
              </Alert>
            )}
          </Stack>
          <Divider sx={{ mb: 1 }} />
          {isError ? (
            <Button variant='main-table-panel' sx={buttonActionStyle} onClick={onClose}>
              Close
            </Button>
          ) : (
            <Stack direction='row' justifyContent='center' spacing={2} alignItems='center' sx={{ py: 1 }}>
              <Button disabled={isMutating} variant='main-table-panel' sx={buttonActionStyle} onClick={onCancel}>
                {t('COMMON.BUTTON.CANCEL')}
              </Button>
              <ButtonLoading
                loading={isMutating}
                variant='contained'
                color='error'
                sx={buttonActionStyle}
                onClick={onApprove}
              >
                {t('COMMON.MODAL_DELETE.BUTTON_DELETE')}
              </ButtonLoading>
            </Stack>
          )}
        </Stack>
      </Modal>
    </div>
  );
}
