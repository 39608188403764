import { AxiosResponse } from 'axios';
import { useQuery, useMutation } from 'react-query';
import axios from 'libs/Axios';
import { AxiosDefaultErrorEntity } from 'types/api/Common/ErrorTypes';
import { AssociateSubjectFunctionItem } from 'types/api/Tenant/AROKMS/AssociateFunctionTypes';

export const ASSOCIATE_SUBJECT_FUNCTION_QUERY = {
  LIST: 'ASSOCIATE_SUBJECT_FUNCTION_QUERY_LIST',
};

export function useTriggerFunctionHardCalculation() {
  return useMutation<
    AxiosResponse,
    AxiosDefaultErrorEntity,
    {
      functionIds: string[];
    }
  >((data) => axios.post('/api/v1/function-calculation/hard-calculation', data).then((res) => res), {});
}

export function useGetAsscoiatedSubjectFunction(subjectId?: string | number) {
  return useQuery<AxiosResponse<AssociateSubjectFunctionItem[]>, AxiosDefaultErrorEntity>(
    [ASSOCIATE_SUBJECT_FUNCTION_QUERY.LIST, subjectId],
    () => axios.get(`/api/v1/function-calculation/${subjectId}`).then((res) => res),
    {
      enabled: !!subjectId,
    }
  );
}
