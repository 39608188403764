import {
  GridRenderEditCellParams,
  GridValueFormatterParams,
  GridPreProcessEditCellProps,
  GridValueGetterParams,
} from '@mui/x-data-grid-pro';
import { dayjs } from 'utils/DateUtils';

import { GridEditDateCell } from 'components/DatatableComponent/CustomDateTimeInput';
import { ENV_CONFIG } from 'config/env';

// Date Time

export function RulesInputDateTime(renderFormat: string = ENV_CONFIG.config.DATE_TIME_FORMAT) {
  return {
    type: 'dateTime',
    width: 230,

    renderEditCell: (params: GridRenderEditCellParams) => {
      return (
        <GridEditDateCell
          {...params}
          // @ts-ignore
          colDef={{
            type: 'dateTime',
          }}
        />
      );
    },

    valueFormatter: (params: GridValueFormatterParams) => {
      if (typeof params.value === 'string') {
        return dayjs(params.value).format(renderFormat);
      }
      return '';
    },
  };
}

export function RuleDateTimeAddition(
  column: string,
  type: string,
  number: number,
  renderFormat: string = ENV_CONFIG.config.DATE_TIME_FORMAT
) {
  return {
    type: 'dateTime',
    width: 230,
    editable: false,
    renderEditCell: (params: GridRenderEditCellParams) => {
      return (
        <GridEditDateCell
          {...params}
          // @ts-ignore
          colDef={{
            type: 'dateTime',
          }}
        />
      );
    },
    valueGetter: (params: GridValueGetterParams) => {
      const referenceValue = params?.row?.[column] || null;
      if (referenceValue) {
        const date = dayjs(referenceValue);
        // @ts-ignore
        return date.add(number, type);
      }

      return null;
    },
    preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
      const value = params?.otherFieldsProps?.[column].value || '';
      const date = dayjs(value);
      // @ts-ignore
      return { ...params.props, value: date.add(number, type) };
    },
    valueFormatter: (params: GridValueFormatterParams) => {
      if (!params.value) return '';
      return dayjs(params.value).format(renderFormat);
    },
  };
}
