import * as React from 'react';
import { NumericFormat } from 'react-number-format';
import { GridRenderCellParams, GridColDef, useGridApiContext } from '@mui/x-data-grid';
import TextField from '@mui/material/TextField';

function TextNumberInputCell(
  props: GridRenderCellParams & {
    type?: string;
    prefix?: string;
    suffix?: string;
    decimalScale?: number;
    max?: number;
    min?: number;
    isRequired?: boolean;
  }
) {
  const apiRef = useGridApiContext();
  const { id, value, field, suffix = '', prefix = '', decimalScale = 3, max = undefined, min = undefined } = props;

  const errorMessage = !props.value && props.isRequired ? 'This field is required' : null;

  return (
    <NumericFormat
      sx={{ width: '100%' }}
      hiddenLabel
      thousandsGroupStyle='thousand'
      thousandSeparator=','
      prefix={prefix}
      isAllowed={(values) => {
        if (max && min && values?.floatValue) {
          return values?.floatValue <= max && values?.floatValue >= min;
        }
        return true;
      }}
      suffix={suffix?.toUpperCase() || ''}
      variant='outlined'
      size='small'
      error={!!errorMessage}
      helperText={errorMessage}
      id='data-input-cell-custom'
      decimalScale={decimalScale}
      value={value}
      onValueChange={(values) => {
        const { value } = values;
        apiRef.current.setEditCellValue({ id, field, value, debounceMs: 300 });
        apiRef.current.stopCellEditMode({ id, field });
      }}
      customInput={TextField}
    />
  );
}

type RenderSelectEditInputCellParams = GridColDef['renderCell'] & {
  type?: string;
  prefix?: string;
  suffix?: string;
  decimalScale?: number;
  max?: number;
  min?: number;
  isRequired?: boolean;
};
const MemoizedNumberInputCell = React.memo(TextNumberInputCell);

export const renderNumberInputEditCell: RenderSelectEditInputCellParams = (params) => {
  return <MemoizedNumberInputCell {...params} key={params.id} />;
};
