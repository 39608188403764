import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { SvgIcon } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { StyledMenu, FileType } from './ButtonExport';

// Custom Icon
import { ReactComponent as CustomIconCSV } from 'assets/icons/icon-csv.svg';
import { ReactComponent as CustomIconXLS } from 'assets/icons/icon-xls.svg';
import { FILE_TYPE_PARAMS } from 'constant/ImportExportConstant';

export const BUTTON_IMPORT_CATEGORY = {
  NORMAL_DATA_IMPORT: 'NORMAL_DATA_IMPORT',
  DATA_CHANNEL_IMPORT: 'DATA_CHANNEL_IMPORT',
};

interface ButtonExportProps {
  onClick: (type: FileType) => void;
  disabled?: boolean | undefined;
  tooltip?: string;
  category?: string;
}

export function ButtonImport(props: ButtonExportProps) {
  const { t } = useTranslation();
  const {
    onClick,
    disabled = false,
    tooltip = t('COMMON.BUTTON.IMPORT'),
    category = BUTTON_IMPORT_CATEGORY.NORMAL_DATA_IMPORT,
  } = props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (selectedFileType: string) => {
    onClick(selectedFileType as FileType);
    handleClose();
  };

  return (
    <div>
      <Tooltip title={tooltip}>
        <IconButton
          disabled={disabled}
          aria-controls={open ? 'demo-customized-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          sx={{
            opacity: disabled ? 0.5 : 1,
            cursor: disabled ? 'not-allowed' : 'pointer',
          }}
        >
          <CloudUploadOutlinedIcon sx={{ color: '#42BB93' }} />
        </IconButton>
      </Tooltip>

      <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {category === BUTTON_IMPORT_CATEGORY.NORMAL_DATA_IMPORT && (
          <>
            <MenuItem onClick={() => handleMenuItemClick(FILE_TYPE_PARAMS.CSV)} disableRipple>
              <SvgIcon fontSize='small' sx={{ fill: '#42BB93' }} inheritViewBox component={CustomIconCSV} />
              {t('COMMON.BUTTON.IMPORT_CSV_FILE')}
            </MenuItem>
            <MenuItem onClick={() => handleMenuItemClick(FILE_TYPE_PARAMS.EXCEL)} disableRipple>
              <SvgIcon fontSize='small' sx={{ fill: '#42BB93' }} inheritViewBox component={CustomIconXLS} />
              {t('COMMON.BUTTON.IMPORT_EXCEL_FILE')}
            </MenuItem>
          </>
        )}
        {category === BUTTON_IMPORT_CATEGORY.DATA_CHANNEL_IMPORT && (
          <MenuItem
            onClick={() => handleMenuItemClick(FILE_TYPE_PARAMS.EXCEL)}
            disableRipple
            sx={{
              maxWidth: 250,
              height: 'auto',
              whiteSpace: 'normal',
              overflow: 'hidden',
            }}
          >
            <SvgIcon fontSize='small' sx={{ fill: '#42BB93' }} inheritViewBox component={CustomIconXLS} />
            Import from Data Channel Failed Excel File
          </MenuItem>
        )}
      </StyledMenu>
    </div>
  );
}
