import { useRef } from 'react';
import { useNavigate } from 'react-router';
import { Formik, FormikProps } from 'formik';

import { Autocomplete, Box, Chip, Grid, Stack, Typography } from '@mui/material';
import ButtonLoading from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import { TenantHomeDashboardNavigationBar } from 'components/LayoutComponent/VerticalLayout/HeaderBar';
import { ButtonBackToTop } from 'components/ButtonComponent/ButtonBackToTop';
import { PATH_CONSTANT } from 'constant/PathConstant';
import { useServerListDropdown } from 'services/v1/TenantMaster/MasterTable/ServerTableDataService';

import { AutoCompleteItem } from 'types/api/Common/AutoCompleteTypes';
import {
  useAddTenantExternalDataSource,
  useCheckDatabaseConnection,
  useGetOrganizationDropdown,
} from 'services/v1/TenantMaster/MasterTable/TenantExternalDataSourceService';
import ContactlessOutlined from '@mui/icons-material/ContactlessOutlined';

const textInputStyles = {
  width: '55%',
  '& .MuiOutlinedInput': {
    padding: '1px 2px',
  },
};

export const autocompleteStyles = {
  width: '55%',
  '& .MuiOutlinedInput-root': {
    padding: '1px 2px',
  },
  '& .MuiAutocomplete-popper': {
    fontSize: '10px',
  },
};

export const textInputAutoCompleteStyles = {
  '& .MuiOutlinedInput': {
    padding: '1px 2px',
  },
};

const optionStyles = { '& > span': { fontSize: '14px', mr: 2, flexShrink: 0 } };

interface FormValue {
  server: AutoCompleteItem | null;
  organization: AutoCompleteItem | null;
  databaseName: string;
}

const formInitialValues: FormValue = {
  server: null,
  organization: null,
  databaseName: '',
};
export default function AddDataWarehousePage() {
  const formikRef = useRef<FormikProps<FormValue>>(null);
  const navigate = useNavigate();

  const { mutate: createExternalDataSource, isLoading: isDeploying } = useAddTenantExternalDataSource();
  const { data: serverOptions, isLoading: isLoadingServer } = useServerListDropdown();

  const {
    mutate: checkDataBaseConnection,
    data: serverConnectionData,
    isSuccess: isServerConnected,
    isError: isServerConnectionError,
    isLoading: isTesting,
    error: serverConnectionError,
  } = useCheckDatabaseConnection();

  const { data: organizationOptions, isLoading: isLoadingOrganization } = useGetOrganizationDropdown();

  const handleOnSave = (data: FormValue) => {
    console.log({
      data,
    });
    const payload = {
      serverId: data?.server?.value?.toString() || '',
      organizationId: data?.organization?.value?.toString() || '',
      databaseName: data?.databaseName,
    };
    createExternalDataSource(payload, {
      onSuccess: () => {
        navigate(PATH_CONSTANT.TENANT_MASTER.DATAWAREHOUSE.LIST);
      },
    });
  };

  const handleOnCheckConnection = () => {
    const { values } = formikRef.current || {};
    if (values?.server?.value && values?.databaseName) {
      checkDataBaseConnection({
        serverId: values?.server?.value.toString(),
        databaseName: values?.databaseName,
        organizationId: values?.organization?.value?.toString() || '',
      });
    }
  };

  const isSemiLoading = isDeploying;

  const handleOnCancelInput = () => {
    navigate(PATH_CONSTANT.TENANT_MASTER.DATAWAREHOUSE.LIST);
  };

  return (
    <TenantHomeDashboardNavigationBar>
      <ButtonBackToTop />
      <Box sx={{ height: '100%' }}>
        <Formik innerRef={formikRef} initialValues={formInitialValues} onSubmit={handleOnSave}>
          {(formikProps: FormikProps<FormValue>) => {
            const { handleSubmit, values, handleChange, errors, touched, handleBlur, setFieldValue } = formikProps;
            // @ts-ignore
            return (
              <Box sx={{ backgroundColor: '#fff', pb: 3, px: 3 }} alignItems='center'>
                <Stack
                  direction='row'
                  alignItems='center'
                  py={1}
                  justifyContent='space-between'
                  sx={{ backgroundColor: '#fff' }}
                  spacing={2}
                >
                  <Stack>
                    <Typography variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
                      Edit Datawarehoue
                    </Typography>
                  </Stack>
                </Stack>
                <Stack>
                  <Typography variant='input-label' fontStyle='italic' sx={{ pt: 1, fontSize: 13 }}>
                    (<span style={{ color: 'red' }}>*</span>) indicates required fields
                  </Typography>
                </Stack>

                <Grid
                  container
                  marginTop={2}
                  sx={{ backgroundColor: '#fff', py: 1, borderTop: '1px solid #E3EBF6' }}
                  justifyContent='space-between'
                  display='flex'
                  flexWrap='wrap'
                  alignItems='flex-start'
                >
                  <Grid container item xs={6} direction='column'>
                    <Stack>
                      <Stack
                        direction='row'
                        spacing={2}
                        alignItems='center'
                        justifyContent='space-between'
                        sx={{ mt: 2 }}
                      >
                        <Typography variant='input-label'>
                          Server
                          <span style={{ color: 'red', marginLeft: 2 }}>*</span>
                        </Typography>
                        <Autocomplete
                          options={serverOptions || []}
                          value={values.server}
                          getOptionLabel={(option) => option.label}
                          disabled={isLoadingServer}
                          clearIcon={null}
                          contentEditable={false}
                          onChange={(event, value) => {
                            setFieldValue('server', value);
                          }}
                          renderOption={(props, option) => (
                            <Box component='li' sx={optionStyles} {...props}>
                              <span>{option.label}</span>
                            </Box>
                          )}
                          sx={autocompleteStyles}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              sx={textInputAutoCompleteStyles}
                              placeholder={isLoadingServer ? 'Loading...' : 'Select Server'}
                            />
                          )}
                        />
                      </Stack>
                      <Stack
                        direction='row'
                        spacing={2}
                        alignItems='center'
                        justifyContent='space-between'
                        sx={{ mt: 2 }}
                      >
                        <Typography variant='input-label'>
                          Organization
                          <span style={{ color: 'red', marginLeft: 2 }}>*</span>
                        </Typography>
                        <Autocomplete
                          options={organizationOptions?.data || []}
                          value={values.organization}
                          disabled={isLoadingOrganization}
                          getOptionLabel={(option) => option.label}
                          clearIcon={null}
                          contentEditable={false}
                          onChange={(event, value) => {
                            setFieldValue('organization', value);
                          }}
                          renderOption={(props, option) => (
                            <Box component='li' sx={optionStyles} {...props}>
                              <span>{option.label}</span>
                            </Box>
                          )}
                          sx={autocompleteStyles}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              sx={textInputAutoCompleteStyles}
                              placeholder={isLoadingOrganization ? 'Loading...' : 'Select Organization'}
                            />
                          )}
                        />
                      </Stack>
                      <Stack
                        direction='row'
                        spacing={2}
                        alignItems='center'
                        justifyContent='space-between'
                        sx={{ mt: 2 }}
                      >
                        <Typography variant='input-label'>
                          Database Name
                          <span style={{ color: 'red', marginLeft: 2 }}>*</span>
                        </Typography>
                        <TextField
                          sx={textInputStyles}
                          size='small'
                          disabled={isSemiLoading}
                          placeholder='e.g. Database Name'
                          onChange={handleChange}
                          value={values?.databaseName}
                          onBlur={handleBlur}
                          name='databaseName'
                          error={touched.databaseName && Boolean(errors.databaseName)}
                          helperText={touched.databaseName && errors.databaseName}
                        />
                      </Stack>
                      <Stack
                        direction='row'
                        spacing={2}
                        alignItems='center'
                        justifyContent='space-between'
                        sx={{ mt: 2 }}
                      >
                        <Typography variant='input-label'>Status</Typography>
                        <Stack sx={{ width: '55%' }}>
                          <Stack
                            direction='row'
                            alignItems='center'
                            justifyContent='space-between'
                            sx={{ width: '100%' }}
                          >
                            {serverConnectionData === undefined && !isServerConnectionError && (
                              <Typography variant='body2'>-</Typography>
                            )}
                            {isServerConnected && (
                              <Chip label='Connected!' color='success' sx={{ width: '30%', bgcolor: '#42BB93' }} />
                            )}

                            {isServerConnectionError && (
                              // @ts-ignore
                              <Typography variant='body2' sx={{ color: '#BB4942' }}>
                                {serverConnectionError?.response?.data?.message}
                              </Typography>
                            )}
                          </Stack>
                          <Stack sx={{ width: '50%' }} mt={2}>
                            <ButtonLoading
                              disabled={Object.values(values).some((value) => !value)}
                              variant='main-table-panel'
                              loading={isTesting}
                              startIcon={<ContactlessOutlined />}
                              onClick={handleOnCheckConnection}
                            >
                              Test Connection
                            </ButtonLoading>
                          </Stack>
                        </Stack>
                      </Stack>
                    </Stack>
                  </Grid>
                </Grid>
                <Stack direction='row' spacing={2} justifyContent='flex-end'>
                  <Button disabled={isDeploying} onClick={handleOnCancelInput} variant='main-table-panel-border'>
                    Cancel
                  </Button>

                  <ButtonLoading
                    variant='main-table-panel'
                    loading={isDeploying}
                    disabled={!isServerConnected}
                    onClick={() => handleSubmit()}
                  >
                    Save
                  </ButtonLoading>
                </Stack>
              </Box>
            );
          }}
        </Formik>
      </Box>
    </TenantHomeDashboardNavigationBar>
  );
}
