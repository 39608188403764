import { useNavigate } from 'react-router-dom';
import { Player } from '@lottiefiles/react-lottie-player';
import animationSuccess from 'assets/lottie/initial-setup-success.json';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { TenantInterface } from 'store/reducer/authReducer';
import { Button } from '@mui/material';
import { DEFAULT_TENANT_LOGIN_REDIRECT_URL } from 'constant/PermissonConstant';

const contentContainerStyle = {
  my: 5,
  mx: 4,
  px: 4,
  py: 4,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: '#fff',
  borderRadius: '7px',
  boxShadow: '0px 1px 6px #C8CFE2',
};

const loadingContainerStyle = {
  ...contentContainerStyle,
  boxShadow: 'none',
  backgroundColor: 'transparent',
};

export function CompletedInitialSetup({ selectedTenant }: { selectedTenant: TenantInterface }) {
  const navigate = useNavigate();
  return (
    <Box width={500} sx={loadingContainerStyle}>
      <Player
        src={animationSuccess}
        autoplay
        loop
        style={{
          height: 150,
        }}
      />
      <Typography component='h5' variant='h6'>
        Setup Complete!
      </Typography>
      <Typography component='p' variant='subtitle2' color='#6E7FAA'>
        Your platform is ready to use. You can now start exploring. Thank you for your patience.
      </Typography>
      <Button
        variant='main-table-panel'
        onClick={() => {
          navigate(DEFAULT_TENANT_LOGIN_REDIRECT_URL);
        }}
        sx={{
          mt: 2,
        }}
      >
        {`OPEN ${selectedTenant.tenant.tenantCode}`}
      </Button>
    </Box>
  );
}
