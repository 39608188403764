import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { EventDataChannel } from 'types/api/Tenant/EventDataSourceTypes';
import { Chip, Divider, Stack } from '@mui/material';
import { ButtonGroupSplit } from 'components/ButtonComponent/ButtonGroupSplit';

interface ChannelCardProps {
  channelItem: EventDataChannel;
  onSelectChannel: (selectedChannel: EventDataChannel, actionKey: string) => void;
}

export const BUTTON_ACTION_CHANNEL_ITEM_KEY = {
  CONNECT_CHANNEL: 'CONNECT_CHANNEL',
  PREVIEW_DATA: 'PREVIEW_DATA',
};

const BUTTON_ACTION_OPTIONS = [
  {
    label: 'Connect Channel',
    value: BUTTON_ACTION_CHANNEL_ITEM_KEY.CONNECT_CHANNEL,
  },
  {
    label: 'Preview Data',
    value: BUTTON_ACTION_CHANNEL_ITEM_KEY.PREVIEW_DATA,
  },
];

export function ChannelCard(props: ChannelCardProps) {
  const { channelItem, onSelectChannel } = props;

  const handleOnSelectOption = (optionKey: string) => {
    onSelectChannel(channelItem, optionKey);
  };

  const isInactive = channelItem.status === 'INACTIVE';
  return (
    <Card sx={{ width: 270 }} variant='outlined'>
      <CardContent>
        <Stack direction='row' gap={2} justifyContent='space-between'>
          <CardMedia
            sx={{ height: 50, width: '50%', objectFit: 'contain' }}
            component='img'
            image={channelItem.logoUrl ? channelItem.logoUrl : '/images/main-logo.svg'}
            style={{
              filter: isInactive ? 'grayscale(100%)' : 'none',
            }}
            title={channelItem.name}
          />
          {isInactive && <Chip label='Inactive' />}
        </Stack>
        <Stack height={65} mt={1}>
          <Typography gutterBottom variant='h6' fontSize={16} fontWeight='bold'>
            {channelItem.name}
          </Typography>
        </Stack>
        <Stack height={120}>
          <Typography variant='body2' color='text.secondary'>
            {channelItem.description}
          </Typography>
        </Stack>
      </CardContent>
      <Divider />
      <CardActions>
        <ButtonGroupSplit
          disabled={isInactive}
          text='Select Channel'
          options={BUTTON_ACTION_OPTIONS}
          onSelectOption={handleOnSelectOption}
        />
      </CardActions>
    </Card>
  );
}
