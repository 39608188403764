export enum NAVIGATION_COMPONENT_TYPE {
  K_TREE = 'K_TREE',
  SUBJECT = 'SUBJECT',
  EVENT = 'EVENT',
  CUBE = 'CUBE',
  INSIGHT_VIEW = 'INSIGHT_VIEW',
  STANDARD_VIEW = 'STANDARD_VIEW',
  DASHBOARD = 'DASHBOARD',
  SUBJECT_PROCEDURE = 'SUBJECT_PROCEDURE',
}

export const FOOTER_MENU_LINK = {
  PRIVACY_POLICY: 'https://arotarget.com/privacy/',
  TERMS_OF_USE: 'https://arotarget.com/terms/',
};

export const FOOTER_MENU_OPTIONS = [
  {
    label: 'Privacy Policy',
    link: FOOTER_MENU_LINK.PRIVACY_POLICY,
  },
  {
    label: 'Terms of Use',
    link: FOOTER_MENU_LINK.TERMS_OF_USE,
  },
];
