import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Modal, Tooltip } from '@mui/material';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import Divider from '@mui/material/Divider';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { GridColumns, GridValueFormatterParams, useGridApiRef } from '@mui/x-data-grid-pro';
import { CustomDataTable } from 'components/DatatableComponent';
import { useGetTableAnalyticData } from 'services/v1/SystemTenant/AROKMS/TableAnalyticService';
import { TableAnalyticData } from 'types/api/SystemTenant/AROKMS/TableAnalytic';
import { useGetSubjectDetails } from 'services/v1/SystemTenant/AROKMS/SubjectService';
import ActivityIndicator from 'components/ActivityIndicatorComponent';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { generateColumnNames, handleExport } from 'utils/ExportUtils';
import {
  FormTableAnalyticParameter,
  TABLE_ANALYTIC_SCALE_ABBREVIATION,
} from 'components/ButtonComponent/ButtonTableAnalytic';
import { ChartTableAnalytic, TableAnalyticBarChartRef } from './ChartTableAnalytic';
import { ModalParameterController } from './ModalParameterController';

const ModalContentStyle = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  bgcolor: 'background.paper',
  height: 'auto',
  maxHeight: '95%',
  borderRadius: 1,
  overflow: 'auto',
  px: 3,
  py: 2,
};

const closeIconStyle = { color: '#98A2AE', cursor: 'pointer' };

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export interface ModalTableAnalyticProps {
  open: boolean;
  onClose: () => void;
  subjectId?: string;
  parameterValue: FormTableAnalyticParameter;
}

function jsonToGridDataTableAnalytic(tableAnalyticData?: TableAnalyticData) {
  if (!tableAnalyticData) return [];
  // @ts-ignore
  const rows = [];

  tableAnalyticData.rowAttributes.forEach((attr) => {
    const rowData = {
      id: attr.value,
      attribute: attr.label,
    };
    Object.entries(tableAnalyticData.data).forEach(([key, value]) => {
      // @ts-ignore
      rowData[key] = value[attr.label];
      // @ts-ignore
      rowData['rowid'] = `${attr.label}#${key}#${value.label}`;
    });
    rows.push(rowData);
  });
  // @ts-ignore
  return rows || [];
}

export const TABS = {
  ANALYTIC_DATA: 0,
  ANALYTIC_CHART: 1,
};

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

export function ModalTableAnalytic(props: ModalTableAnalyticProps) {
  const {
    onClose,
    open,
    subjectId,

    parameterValue,
  } = props;
  const [selectedTab, setSelectedTab] = React.useState(TABS.ANALYTIC_DATA);
  const barChartRef = React.useRef<TableAnalyticBarChartRef>(null);
  const apiRef = useGridApiRef();

  const [selectedParameter, setSelectedParameter] = React.useState<FormTableAnalyticParameter | null>(null);

  const { data: tableAnalyticData, isLoading: isLoadingData } = useGetTableAnalyticData({
    subjectId,
    rowTableDefinitionId: selectedParameter?.rowColumn?.value?.toString(),
    columnTableDefinitionId: selectedParameter?.columnColumn?.value?.toString(),
    calculateFieldDefinitionId: selectedParameter?.dataColumn?.value?.toString(),
    decimalPlace: selectedParameter?.dataDecimals ? parseInt(selectedParameter?.dataDecimals) : 0,
    scale: !!selectedParameter?.scale ? selectedParameter?.scale : undefined,
  });

  const { data: subjectDetails } = useGetSubjectDetails(subjectId);

  const handleOnClose = React.useCallback(() => {
    onClose();
    setSelectedTab(TABS.ANALYTIC_DATA);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkGetCellClassName = React.useCallback((params) => {
    let className = params.indexRelativeToCurrentPage % 2 === 0 ? '' : 'cube-odd';
    const { field, id } = params;
    const rowField = id.split('#')[0];
    if (field === 'Total' || field === 'Total %' || rowField === 'Total' || rowField === 'Total %') {
      className += ' font-bold custom-cube-tree-data-grouping-cell';
    }
    return className;
  }, []);

  const columns: GridColumns = React.useMemo<GridColumns>(() => {
    if (tableAnalyticData?.data?.data) {
      const attributeField = {
        field: 'attribute',
        headerName: `${tableAnalyticData?.data?.rowDimension?.label}/${tableAnalyticData?.data?.columnDimension?.label}`,
        type: 'string',
        width: 200,
        pinned: 'left',
        cellClassName: 'custom-cube-tree-data-grouping-cell',
      };
      return [
        attributeField,
        ...Object.keys(tableAnalyticData?.data.data).map((timePeriod) => ({
          field: timePeriod,
          headerName: timePeriod,
          type: 'number',
          editable: true,
          minWidth: 120,
          headerClassName: 'custom-cube-tree-data-header',
          valueFormatter: (params: GridValueFormatterParams) => {
            if (!params.value) return '';
            const rowColumn = params.id?.toString().split('#')[0];
            if (rowColumn === 'Total %' || params.field === 'Total %') {
              return `${params.value}%`;
            }
            return params.value?.toLocaleString();
          },
          sortComparator: (v1: any, v2: any, cellParams1: any, cellParams2: any) => {
            const isTotalRow1 = cellParams1?.id?.split('#')[0] === 'Total';
            const isTotalRow2 = cellParams2?.id?.split('#')[0] === 'Total';
            const isTotalPercent1 = cellParams1?.id?.split('#')[0] === 'Total %';
            const isTotalPercent2 = cellParams2?.id?.split('#')[0] === 'Total %';

            if (isTotalRow1 || isTotalRow2 || isTotalPercent1 || isTotalPercent2) {
              return 0;
            }
            return v1 - v2;
          },
        })),
      ];
    }
    return [];
  }, [tableAnalyticData?.data]);

  const rowsData = React.useMemo(() => {
    return jsonToGridDataTableAnalytic(tableAnalyticData?.data);
  }, [tableAnalyticData?.data]);

  const handleOnChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const handleOnExportTable = () => {
    if (selectedTab === TABS.ANALYTIC_CHART) {
      barChartRef.current?.downloadChart();
      return;
    }

    const exportConfig = {
      columnNames: generateColumnNames(apiRef),
      keys: generateColumnNames(apiRef),
      sheetName: 'Sheet1',
      fileName: `${subjectDetails?.data?.displayName}_Analytic_Data.xlsx`,
    };
    handleExport(apiRef, exportConfig);
  };

  const totalDataAnalyticSummary = React.useMemo(() => {
    if (!tableAnalyticData?.data?.data) return null;

    const totalData = tableAnalyticData?.data?.data['Total']?.['Total'];
    const dataLabel =
      tableAnalyticData?.data?.dataDimension?.value === 'COUNT'
        ? 'Number of'
        : tableAnalyticData?.data?.dataDimension?.label;

    const subjectName = subjectDetails?.data?.displayName;

    if (tableAnalyticData?.data?.scale) {
      const scaleLabel = TABLE_ANALYTIC_SCALE_ABBREVIATION.get(tableAnalyticData?.data?.scale);
      return `${dataLabel} ${subjectName}s (${scaleLabel}) : ${totalData?.toLocaleString()}`;
    }

    return `${dataLabel} ${subjectName}s : ${totalData?.toLocaleString()}`;
  }, [tableAnalyticData?.data, subjectDetails?.data]);

  const handleOnParameterChange = (data: FormTableAnalyticParameter) => {
    setSelectedParameter(data);
  };

  React.useEffect(() => {
    if (parameterValue) {
      setSelectedParameter(parameterValue);
    }
  }, [parameterValue]);

  return (
    <Modal open={open} onClose={handleOnClose}>
      <>
        <Stack direction='column' sx={ModalContentStyle} gap={1}>
          <Stack direction='column'>
            <Stack justifyContent='space-between' direction='row' alignItems='center' spacing={2}>
              <Stack direction='row' gap={1}>
                <TroubleshootIcon
                  sx={{
                    color: '#42BB93',
                  }}
                />
                <Typography variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
                  {subjectDetails?.data?.displayName && `${subjectDetails?.data?.displayName} Analytics`}
                </Typography>
              </Stack>

              <IconButton onClick={handleOnClose} component='label'>
                <HighlightOffRoundedIcon sx={closeIconStyle} />
              </IconButton>
            </Stack>
            <Divider sx={{ mb: 0 }} />
          </Stack>

          <Stack mt={1}>
            <ModalParameterController
              initialValues={
                selectedParameter
                  ? {
                      ...selectedParameter,
                      scale: selectedParameter.scale || 'NONE',
                    }
                  : undefined
              }
              onChange={handleOnParameterChange}
              loading={isLoadingData}
            />
          </Stack>

          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Stack direction='row' justifyContent='space-between' alignItems='center'>
              <Tabs value={selectedTab} onChange={handleOnChangeTab} aria-label='basic tabs example'>
                <Tab label='Analytic Data' value={TABS.ANALYTIC_DATA} />
                <Tab label='Analytic Chart' value={TABS.ANALYTIC_CHART} />
              </Tabs>
              <Tooltip title='Export'>
                <span data-testid='button-export-label'>
                  <IconButton data-testid='button-export' onClick={handleOnExportTable}>
                    <FileDownloadOutlinedIcon sx={{ color: '#42BB93' }} />
                  </IconButton>
                </span>
              </Tooltip>
            </Stack>
          </Box>
          <CustomTabPanel value={selectedTab} index={0}>
            {isLoadingData && (
              <Stack height={450}>
                <ActivityIndicator />
              </Stack>
            )}
            {!isLoadingData && (
              <>
                <Stack justifyContent='space-between' direction='row' mt={2} px={2}>
                  <div />
                  <Typography variant='subtitle2' sx={{ color: '#3B4797' }}>
                    {totalDataAnalyticSummary}
                  </Typography>
                </Stack>
                <CustomDataTable
                  apiRef={apiRef}
                  initialState={{
                    pinnedColumns: { left: ['attribute'] },
                    pagination: {
                      page: 0,
                    },
                  }}
                  columns={columns}
                  rows={rowsData || []}
                  getRowId={(row) => row?.rowid || 0}
                  getTreeDataPath={(row) => row.path}
                  disableSelectionOnClick
                  checkboxSelection={false}
                  rowsPerPageOptions={[5, 10, 20]}
                  paginationMode='client'
                  getCellClassName={checkGetCellClassName}
                  editMode='cell'
                  sortingMode='client'
                  columnBuffer={2}
                  columnThreshold={2}
                  filterMode='client'
                  sx={{ minHeight: 390, height: '100%', width: '100%', mt: 1 }}
                  autoHeight={false}
                  hideFooter
                  components={{
                    Toolbar: null,
                    Pagination: null,
                  }}
                />
              </>
            )}
          </CustomTabPanel>
          <CustomTabPanel value={selectedTab} index={1}>
            <ChartTableAnalytic
              ref={barChartRef}
              data={tableAnalyticData?.data?.data}
              columnDimension={tableAnalyticData?.data?.columnDimension}
              rowDimension={tableAnalyticData?.data?.rowDimension}
              dataDimension={tableAnalyticData?.data?.dataDimension}
              totalData={tableAnalyticData?.data?.data['Total']?.['Total'] as number}
              scaleLabel={TABLE_ANALYTIC_SCALE_ABBREVIATION.get(tableAnalyticData?.data?.scale as string)}
            />
          </CustomTabPanel>
        </Stack>
      </>
    </Modal>
  );
}
