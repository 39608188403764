import { Stack, Typography, Button } from '@mui/material';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { Field } from 'types/api/Tenant/AROKMS/DisplayTableTypes';
import eventEmitter, { EVENT_EMITTER_KEYS } from 'utils/EventEmitter';

interface DataInputFileUploadProps {
  name: string;
  onChange: (fileUrl: string) => void;
  fileUrl: string;
  label: string;
  required?: boolean;
  isEdit?: boolean;
  field: Field;
}

const INPUT_EDIT_MESSAGE = "Once you've submitted, this field can no longer be changed";
export function DataInputFileUpload(props: DataInputFileUploadProps) {
  const { fileUrl, required, isEdit, field } = props;

  const handleOnShowModalAttachment = () => {
    eventEmitter.emit(EVENT_EMITTER_KEYS.TIRGGER_SHOW_MODAL_ATTACHMENT_INSERT, {
      visible: true,
      url: Boolean(fileUrl) ? fileUrl : undefined,
      fieldName: field.name,
      fieldLabel: field.label,
    });
  };

  const totalAttachment = fileUrl?.split(',')?.length || 0;

  return (
    <Stack direction='column' display='flex' justifyContent='space-between' marginTop={2.5} mb={2}>
      <Typography variant='input-label' fontWeight={700}>
        {field.label}
        {required && !field.disabled && <span style={{ color: 'red', marginLeft: 2 }}>*</span>}
      </Typography>
      {field.columnEditable === false && !isEdit && (
        <Typography variant='input-label-gray' fontSize={12} mb={3}>
          {INPUT_EDIT_MESSAGE}
        </Typography>
      )}

      <div className='dropzone-data-input'>
        <Button
          endIcon={<AddCircleOutlineOutlinedIcon />}
          variant='secondary-table-panel'
          onClick={handleOnShowModalAttachment}
          sx={{ width: '85%', mr: 1 }}
        >
          {totalAttachment > 0 ? `See Attachment(s) - (${totalAttachment})` : 'Add Attachment'}
        </Button>
      </div>
    </Stack>
  );
}
