import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import RectangleIcon from '@mui/icons-material/Rectangle';
import { IconButton, Grid, Stack, Tooltip } from '@mui/material';

const iconButtonStyle = {
  padding: '0px',
};

export enum ControllerNavigationDirection {
  UP = 'up',
  DOWN = 'down',
  LEFT = 'left',
  RIGHT = 'right',
  CENTER = 'center',
}

interface ControllerNavigationProps {
  onNavigate: (direction: ControllerNavigationDirection) => void;
  enableNavigations?: ControllerNavigationDirection[];
  tooltips?: {
    [key in ControllerNavigationDirection]?: string;
  };
}

export function ControllerNavigation(props: ControllerNavigationProps) {
  const {
    onNavigate,
    tooltips = {},
    enableNavigations = [
      ControllerNavigationDirection.UP,
      ControllerNavigationDirection.DOWN,
      ControllerNavigationDirection.LEFT,
      ControllerNavigationDirection.RIGHT,
    ],
  } = props;
  const handleArrowClick = (direction: ControllerNavigationDirection) => {
    onNavigate(direction);
  };
  return (
    <Grid container justifyContent='center' alignItems='center' style={{ width: 'fit-content', position: 'relative' }}>
      <Grid item xs={12} container justifyContent='center'>
        {enableNavigations.includes(ControllerNavigationDirection.UP) ? (
          <Tooltip title={tooltips[ControllerNavigationDirection.UP] ?? ''}>
            <IconButton style={iconButtonStyle} onClick={() => handleArrowClick(ControllerNavigationDirection.UP)}>
              <KeyboardArrowUpOutlinedIcon fontSize='medium' />
            </IconButton>
          </Tooltip>
        ) : (
          <Stack height={20} />
        )}
      </Grid>
      <Grid item xs={12} container justifyContent='space-between' alignItems='center' width={70}>
        {enableNavigations.includes(ControllerNavigationDirection.LEFT) ? (
          <Tooltip title={tooltips[ControllerNavigationDirection.LEFT] ?? ''}>
            <IconButton style={iconButtonStyle} onClick={() => handleArrowClick(ControllerNavigationDirection.LEFT)}>
              <KeyboardArrowLeftOutlinedIcon fontSize='medium' />
            </IconButton>
          </Tooltip>
        ) : (
          <Stack height={20} />
        )}
        <IconButton
          style={{
            ...iconButtonStyle,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <RectangleIcon fontSize='small' />
        </IconButton>
        {enableNavigations.includes(ControllerNavigationDirection.RIGHT) ? (
          <Tooltip title={tooltips[ControllerNavigationDirection.RIGHT] ?? ''}>
            <IconButton style={iconButtonStyle} onClick={() => handleArrowClick(ControllerNavigationDirection.RIGHT)}>
              <KeyboardArrowRightOutlinedIcon fontSize='medium' />
            </IconButton>
          </Tooltip>
        ) : (
          <Stack height={20} />
        )}
      </Grid>
      <Grid item xs={12} container justifyContent='center'>
        {enableNavigations.includes(ControllerNavigationDirection.DOWN) ? (
          <Stack width={25}>
            <Tooltip title={tooltips[ControllerNavigationDirection.DOWN] ?? ''}>
              <IconButton style={iconButtonStyle} onClick={() => handleArrowClick(ControllerNavigationDirection.DOWN)}>
                <KeyboardArrowDownOutlinedIcon fontSize='medium' />
              </IconButton>
            </Tooltip>
          </Stack>
        ) : (
          <Stack height={20} />
        )}
      </Grid>
    </Grid>
  );
}
