// @ts-nocheck
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import ButtonLoading from '@mui/lab/LoadingButton';
import { MainLayoutComponent } from 'components/LayoutComponent/SidebarLayout/MainLayoutComponent';
import Divider from '@mui/material/Divider';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import {
  useExecuteMigrateData,
  useGenerateMigrationSchema,
  useGetMigrationStatus,
} from 'services/v1/Tenant/DataMigrationService';
import { MigrationDetailsItem } from 'types/api/Tenant/DataMigrationTypes';
import { DropdownItem } from 'types/api/SystemTenant/AROKMS/TableDefinitionTypes';
import { StepSelectTenant } from './components/StepSelectTenantSource';
import { TableListSchema } from './components/TableListSchema';
import { MigrationProgressBar } from './components/MigrationProgress';
import { MigrationResult } from './components/MigrationResult';

const steps = [
  {
    label: 'Select migration source',
    description: `Select tenant to migrate data from.`,
  },
  {
    label: 'Confirm Table Mapping',
    description: `Preview the table mapping and confirm.`,
  },
  {
    label: 'Processing',
    description: `Migrating your data...`,
  },
  {
    label: "You're done!",
    description: `Your data has been migrated successfully.`,
  },
];

const stepperStyle = {
  '& .MuiStepIcon-root': {
    fontSize: '30px',
  },
  '& .MuiStepIcon-root.Mui-active': {
    color: '#00B3DC',
  },
  '& .MuiStepIcon-root.Mui-completed': {
    color: '#00B3DC',
  },

  '& .MuiStepConnector-root': {
    marginLeft: '14px',
  },
  '& .MuiStepContent-root': {
    marginLeft: '14px',
    paddingLeft: '24px',
  },
};

const stepLabelStyle = {
  paddingBottom: '5px',
  '&.Mui-active .MuiTypography-body1 ': {
    color: '#00B3DC',
  },
};

export default function TenantMigrationData() {
  const navigate = useNavigate();
  const { selectedTenant } = useSelector((state: RootState) => state.auth);
  const [activeStep, setActiveStep] = useState(0);
  const [tenantSource, setTenantSource] = useState<DropdownItem | null>(null);
  const [schemaList, setSchemaList] = useState<MigrationDetailsItem[]>([]);

  const { mutate: migrateData, isLoading: isMigrating } = useExecuteMigrateData();
  const { mutate: generateSchema, isLoading: isGenerating } = useGenerateMigrationSchema();
  const { data: resultStatus } = useGetMigrationStatus({
    enabled: true,
    refetchInterval: false,
  });

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleChangeSelectTenant = (value: DropdownItem) => {
    setTenantSource(value);
  };

  const handleOnGenerateSchema = () => {
    generateSchema(
      {
        tenantSourceDataId: tenantSource?.value,
      },
      {
        onSuccess: (res) => {
          setSchemaList(res.data);
          handleNext();
        },
      }
    );
  };

  const handleOnStartMigrateData = () => {
    migrateData(
      {
        tenantSourceDataId: tenantSource?.value,
      },
      {
        onSuccess: (res) => {
          handleNext();
        },
      }
    );
  };

  const getButtonNextStepMetaData = () => {
    if (activeStep === 0) {
      return {
        label: 'Next',
        onClick: handleOnGenerateSchema,
        disabled: !tenantSource,
      };
    }
    if (activeStep === 1) {
      return {
        label: 'Migrate Data',
        onClick: handleOnStartMigrateData,
        disabled: !tenantSource,
      };
    }
    if (activeStep === 2) {
      return {
        label: 'MigrationPage on progress...',
        onClick: () => {
          handleNext();
        },
        disabled: true,
      };
    }
    if (activeStep === 3) {
      return {
        label: 'Close',
        onClick: () => {
          navigate('/configuration');
        },
        disabled: false,
      };
    }
  };

  useEffect(() => {
    if (resultStatus) {
      if (resultStatus.data.status === 'COMPLETED' && activeStep === 0) setActiveStep(3);
      if (resultStatus.data.status === 'FAILED' && activeStep === 0) setActiveStep(3);
      if (resultStatus.data.status === 'IN_PROGRESS' && activeStep === 0) setActiveStep(2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultStatus]);

  return (
    <MainLayoutComponent pageTitle='Migrate Data' breadcrumbs={['Dashboard', 'Migrate Data']}>
      <Box bgcolor='#fff' p={4}>
        <Stack direction='row' spacing={2} justifyContent='space-between' mt={5}>
          <Stack width='29%'>
            <Stepper sx={stepperStyle} activeStep={activeStep} orientation='vertical'>
              {steps.map((step, index) => (
                <Step key={step.label} expanded>
                  <StepLabel sx={stepLabelStyle}>
                    <Typography
                      variant='body1'
                      sx={{
                        fontWeight: 600,
                        color: activeStep === index ? '#00B3DC' : '#0f0f0f',
                      }}
                    >
                      {step.label}
                    </Typography>
                  </StepLabel>
                  <StepContent>
                    <Typography fontSize='14px' variant='input-label' sx={{ color: '#98A2AE' }}>
                      {step.description}
                    </Typography>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
          </Stack>
          <Divider orientation='vertical' flexItem />
          <Stack width='70%'>
            {activeStep === 0 && <StepSelectTenant onChange={handleChangeSelectTenant} />}
            {activeStep === 1 && (
              <TableListSchema
                tenantDataSource={tenantSource?.label}
                tenantTarget={selectedTenant?.tenant.tenantCode}
                data={schemaList || []}
              />
            )}
            {activeStep === 2 && (
              <MigrationProgressBar
                onFinish={() => {
                  setActiveStep(3);
                }}
              />
            )}
            {activeStep === 3 && (
              <MigrationResult
                tenantDataSource={tenantSource?.label}
                tenantTarget={selectedTenant?.tenant.tenantCode}
                data={schemaList || []}
              />
            )}
          </Stack>
        </Stack>
        <Stack>
          <Divider sx={{ mb: 1, mt: 2 }} />
          <Stack direction='row' justifyContent='flex-end' spacing={2} alignItems='center' py={1}>
            {activeStep !== 3 && (
              <Button variant='main-table-panel-border' disabled={activeStep === 2}>
                Cancel
              </Button>
            )}
            {![0, 3].includes(activeStep) && (
              <Button variant='main-table-panel-border' disabled={activeStep === 2} onClick={handleBack}>
                Previous
              </Button>
            )}
            <ButtonLoading
              disabled={getButtonNextStepMetaData()?.disabled}
              variant='main-table-panel'
              loading={isGenerating || isMigrating}
              onClick={getButtonNextStepMetaData()?.onClick}
            >
              {getButtonNextStepMetaData()?.label}
            </ButtonLoading>
          </Stack>
        </Stack>
      </Box>
    </MainLayoutComponent>
  );
}
