import { createContext, useMemo, useState } from 'react';

interface CreateCubeDefinitionContextType {
  isFormValid: boolean;
  setIsFormValid: React.Dispatch<React.SetStateAction<boolean>>;
}

const defaultState: CreateCubeDefinitionContextType = {
  isFormValid: false,
  setIsFormValid: () => {},
};

export const CreateCubeDefinitionContext = createContext<CreateCubeDefinitionContextType>(defaultState);

export const CubeDefinitionProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [isFormValid, setIsFormValid] = useState(false);

  const providerValue = useMemo(() => {
    return {
      isFormValid,
      setIsFormValid,
    };
  }, [isFormValid, setIsFormValid]);

  return <CreateCubeDefinitionContext.Provider value={providerValue}>{children}</CreateCubeDefinitionContext.Provider>;
};
