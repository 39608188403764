import { useEffect, useMemo } from 'react';
import * as Yup from 'yup';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import ButtonLoading from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';

import { AutoCompleteItem } from 'types/api/Common/AutoCompleteTypes';
import { useGetCubeDefinitionDetails } from 'services/v1/SystemTenant/AROCube/CubeBuilderService';
import { ModalCubeDefinitionParamsProps } from './ModalCubeDefinitionParameter';
import { FormCubeDefinitionMetaDataValues } from './CubeDefinitionSettingPanel';
import { useFormik } from 'formik';
import { useGetCubeLayerSetLayersDropdown } from 'services/v1/SystemTenant/AROCube/CubeLayerSetService';
import ActivityIndicator from 'components/ActivityIndicatorComponent';
import { CubeDefinitionRowItem } from 'types/api/SystemTenant/AROCube/CubeBuilderTypes';
import { generateCubePullMultipleLayerDisplayParameter } from '../utils/CubeDefinitionUtils';
import { DefineSourceCubeFormComponent } from './DefineSourceCubeComponentForm';

const ModalContentStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  maxHeight: 500,
  overflow: 'auto',
  borderRadius: 1,
  py: 2,
};

const closeIconStyle = { color: '#98A2AE', cursor: 'pointer' };

export type FormikPullFromCubeToMultipleLayerParameterItem = {
  id?: string;
  targetPullCube: AutoCompleteItem | null;
  targetPullCubeLayer: AutoCompleteItem | null;
  destinationCubeLayer: AutoCompleteItem | null;
};

type FormikPullFromCubeToMultipleLayerParameter = {
  cubeComponentDefinitions: FormikPullFromCubeToMultipleLayerParameterItem[];
};

export type PullMultipleLayerParameterPayload = {
  rowItem: CubeDefinitionRowItem;
  cubeComponentDefinitions: FormikPullFromCubeToMultipleLayerParameterItem[];
};

const cubeComponentValidationSchema = Yup.object({
  targetPullCube: Yup.object().nullable().required('Source Cube is required'),
  targetPullCubeLayer: Yup.object().nullable().required('Source Cube Layer is required'),
});

const validationSchema = Yup.object({
  cubeComponentDefinitions: Yup.array().of(cubeComponentValidationSchema),
});

export function ModalPullCubeMultipleLayer(
  props: ModalCubeDefinitionParamsProps<PullMultipleLayerParameterPayload> & {
    cubeMetaData: FormCubeDefinitionMetaDataValues;
    cubeId?: string;
  }
) {
  const { onClose, visible = false, onSubmitParameter, cubeDefinitionItem, cubeMetaData, cubeId } = props;
  const { data: cubeDefinitionDetails } = useGetCubeDefinitionDetails(cubeId);

  const { data: currentCubeLayers, isLoading: isLoadingCurrentCubeLayers } = useGetCubeLayerSetLayersDropdown(
    cubeMetaData.layerSet?.value
  );

  const initialValues: FormikPullFromCubeToMultipleLayerParameter = useMemo(() => {
    const defaultValue = {
      cubeComponentDefinitions:
        currentCubeLayers?.data.map((item) => ({
          id: undefined,
          targetPullCube: null,
          targetPullCubeLayer: item,
          destinationCubeLayer: item,
        })) ?? [],
    };
    if (cubeDefinitionDetails?.data) {
      const selectedAttributeId = cubeDefinitionItem?.attribute.value;
      const currentAttributesParameter = selectedAttributeId
        ? cubeDefinitionDetails.data.attributesPullMultipleLayers[selectedAttributeId]
        : null;

      if (currentAttributesParameter) {
        const mapAttributesParameterWithDestinationCubeLayerId: Map<
          string,
          FormikPullFromCubeToMultipleLayerParameterItem
        > = new Map();
        currentAttributesParameter.forEach((item) => {
          item.destinationCubeLayer?.value &&
            mapAttributesParameterWithDestinationCubeLayerId.set(item.destinationCubeLayer?.value.toString(), item);
        });

        return {
          cubeComponentDefinitions:
            currentCubeLayers?.data.map((item) => {
              const currentCubeLayerParameter = mapAttributesParameterWithDestinationCubeLayerId.get(
                item.value.toString()
              );

              if (currentCubeLayerParameter) {
                return {
                  id: currentCubeLayerParameter.id,
                  targetPullCube: currentCubeLayerParameter.targetPullCube,
                  targetPullCubeLayer: currentCubeLayerParameter.targetPullCubeLayer,
                  destinationCubeLayer: item,
                };
              }
              return {
                id: undefined,
                targetPullCube: null,
                targetPullCubeLayer: item,
                destinationCubeLayer: item,
              };
            }) ?? [],
        };
      }
      return defaultValue;
    }

    return defaultValue;
  }, [currentCubeLayers, cubeDefinitionDetails?.data, cubeDefinitionItem?.attribute.value]);

  const formik = useFormik<FormikPullFromCubeToMultipleLayerParameter>({
    initialValues,
    validationSchema,
    onSubmit: (values) => {},
  });

  const handleOnCancel = () => {
    handleOnCloseModal();
  };

  const handleOnCloseModal = () => {
    onClose?.();
    formik.resetForm();
  };

  const handleOnSave = () => {
    onSubmitParameter &&
      cubeDefinitionItem &&
      onSubmitParameter({
        rowItem: {
          ...cubeDefinitionItem,
          ruleTypeParams: generateCubePullMultipleLayerDisplayParameter(formik.values.cubeComponentDefinitions),
        },
        cubeComponentDefinitions: formik.values.cubeComponentDefinitions,
      });

    handleOnCloseModal();
  };

  useEffect(() => {
    formik.setValues(initialValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  return (
    <div>
      <Modal open={visible} onClose={handleOnCloseModal}>
        <Stack direction='column' sx={ModalContentStyle}>
          <Stack px={2} justifyContent='space-between' direction='row' alignItems='center'>
            <Typography variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
              Pull From Cube to Multiple Layers
            </Typography>
            <IconButton onClick={handleOnCloseModal} component='label'>
              <HighlightOffRoundedIcon sx={closeIconStyle} />
            </IconButton>
          </Stack>
          <Divider sx={{ mb: 1 }} />

          <Stack px={2}>
            {isLoadingCurrentCubeLayers && !currentCubeLayers ? (
              <Stack height={450}>
                <ActivityIndicator />
              </Stack>
            ) : (
              <>
                <Typography variant='body2' mb={1} fontWeight='bold'>
                  Attribute: {cubeDefinitionItem?.attribute.label}
                </Typography>
                {Boolean(initialValues.cubeComponentDefinitions.length) &&
                  currentCubeLayers?.data.map((item, index) => (
                    <DefineSourceCubeFormComponent
                      key={item.value}
                      item={item}
                      cubeMetaData={cubeMetaData}
                      formik={formik}
                      index={index}
                      attributeId={cubeDefinitionItem?.attribute.value}
                    />
                  ))}
              </>
            )}
          </Stack>

          <Stack height={300} px={2}></Stack>
          <Stack px={2}>
            <Divider sx={{ mb: 1, mt: 2 }} />
            <Stack direction='row' justifyContent='flex-end' spacing={2} alignItems='center' sx={{ py: 1 }}>
              <Button variant='main-table-panel-border' onClick={handleOnCancel}>
                Cancel
              </Button>
              <ButtonLoading
                variant='main-table-panel'
                onClick={() => handleOnSave()}
                disabled={!validationSchema.isValidSync(formik.values)}
              >
                Save Parameter
              </ButtonLoading>
            </Stack>
          </Stack>
        </Stack>
      </Modal>
    </div>
  );
}
