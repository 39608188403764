import qs from 'query-string';
import { GridRowModesModel, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { useNavigate } from 'react-router-dom';
import { TableDefinitionItem } from 'types/api/SystemTenant/AROKMS/TableDefinitionTypes';
import Button from '@mui/material/Button';
import { ModalParamState } from './ModalParameter';

interface RenderButtonActionProps extends GridRenderCellParams<TableDefinitionItem> {
  setRowModesModel: (newModel: (oldModel: GridRowModesModel) => GridRowModesModel) => void;
  setModalParameterState: (newState: (OldState: ModalParamState) => ModalParamState) => void;
}

export function RenderButtonAction(props: RenderButtonActionProps) {
  const navigate = useNavigate();
  const { hasFocus, value, row, setModalParameterState } = props;

  const handleOnClick = () => {
    navigate({
      pathname: window.location.pathname,
      search: `?${qs.stringify({
        tddtid: row?.dataType?.id,
        dependenciesAttribute: row?.allowDependenciesDataTypes,
        recordAssociateSubjectId: row?.associatedSubject?.label || undefined,
        recordAssociateAttributeId: row?.attribute?.label || undefined,
      })}`,
    });
    setModalParameterState((prevState) => ({ ...prevState, open: true, tableDefinitionId: row.id }));
  };

  if (value?.isNew || row.isRequiredParams === 'N') return <span></span>;

  return (
    <Button
      variant='secondary-table-panel'
      sx={{
        height: '58%',
        fontSize: '12px',
      }}
      onClick={handleOnClick}
      tabIndex={hasFocus ? 0 : -1}
      onKeyDown={(event: React.KeyboardEvent) => {
        if (event.key === ' ') {
          // Prevent key navigation when focus is on button
          event.stopPropagation();
        }
      }}
    >
      {row.ruleTypeParams ? 'View' : 'Create'}
    </Button>
  );
}
