import * as React from 'react';
import { matchSorter } from 'match-sorter';
import { Typography, Chip, TextField } from '@mui/material';
import { debounce } from 'lodash';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Button from '@mui/material/Button';
import Popper from '@mui/base/Popper';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { NAV_ITEM_TYPE, SelectedMenuItems } from './HeaderBar';
import { MENU_SUBJECT_TYPE, NavBarOptionItem } from './NavBar';

const listContainerStyle = {
  boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.08)',
  borderRadius: '4px',
  border: '1px solid #E5E7EB',
  backgroundColor: '#fff',
  minWidth: 150,
  maxHeight: 295,
  overflowY: 'auto',
  my: 1,
  px: 2,
};

const menuItemStyle = {
  py: 0,
  px: 0.5,
  borderRadius: '4px',
  color: '#797979',
  '&:hover': {
    outline: '1px solid #aeafb0',
  },
};

const chipStaticSubjectStyle = { fontSize: 10, padding: 0, height: 'auto', ml: 1 };

const menuItemTextStyle = { fontSize: 15, py: 0.5 };

function NavigationDropdownItem(props: {
  item: string;
  onChange?: (selectedItem: NavBarOptionItem | undefined, type: keyof SelectedMenuItems) => void;
  iconComponent: React.ReactNode;
  visible: boolean;
  withSearch: boolean;
  withArrow: boolean;
  type: keyof SelectedMenuItems;
  options: NavBarOptionItem[];
  clickAble?: boolean;
}) {
  const { item, iconComponent, visible, withArrow, options, withSearch, type, onChange, clickAble } = props;

  const [filteredOptions, setFilteredOptions] = React.useState<NavBarOptionItem[]>(options);
  const [anchorEl, setAnchorEl] = React.useState<HTMLAnchorElement | null>(null);
  const [subMenuAnchorEl, setSubMenuAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const [activeItem, setActiveItem] = React.useState<NavBarOptionItem | null>(null);

  const open = Boolean(anchorEl);
  const id = open ? item : undefined;
  const handleClose = () => {
    setAnchorEl(null);
    if (Boolean(subMenuAnchorEl)) {
      setSubMenuAnchorEl(null);
    }

    setFilteredOptions(options);
  };

  const handleOnMenuItemClick = (selectedItem: NavBarOptionItem, event: React.MouseEvent<HTMLDivElement>) => {
    if (selectedItem.children && selectedItem.children.length > 0) {
      setActiveItem(selectedItem);
      setSubMenuAnchorEl(event.currentTarget);
      return;
    }

    if (onChange) onChange(selectedItem, type);
    handleClose();
  };

  const handleOnMenuButtonClick = () => {
    if (options.length === 0) {
      if (onChange) onChange(undefined, type);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearch = React.useCallback(
    debounce((event: React.ChangeEvent<HTMLInputElement>) => {
      const searchValue = event.target.value;
      const filtered = matchSorter(options, searchValue, { keys: ['label'] });
      setFilteredOptions(filtered);
    }, 300),
    [options]
  );

  const handleSubMenuLeave = () => {
    setActiveItem(null);
    setSubMenuAnchorEl(null);
  };

  const handleSubMenuEnter = () => {
    // clearTimeout();
  };

  const renderMenuItem = (option: NavBarOptionItem, depth: number = 0) => (
    <ListItem disablePadding key={option.value}>
      <ListItemButton
        onClick={(event) => handleOnMenuItemClick(option, event)}
        onMouseEnter={(event) => {
          type === NAV_ITEM_TYPE.SUBJECT_PROCEDURE && depth === 0 && handleOnMenuItemClick(option, event);
        }}
        sx={{
          ...menuItemStyle,
          paddingLeft: `${5 + depth * 10}px`,
        }}
      >
        <Typography variant='input-label' sx={menuItemTextStyle}>
          {option.label}
        </Typography>
        {option.type === MENU_SUBJECT_TYPE.STATIC_SUBJECT && (
          <Chip label='Static' color='info' variant='outlined' sx={chipStaticSubjectStyle} size='small' />
        )}
        {option.children && option.children.length > 0 && <KeyboardArrowRight sx={{ marginLeft: 'auto' }} />}
      </ListItemButton>
      {option.children && option.children.length > 0 && (
        <Popper
          open={Boolean(subMenuAnchorEl) && activeItem === option}
          anchorEl={subMenuAnchorEl}
          placement='right-start'
          style={{ zIndex: 1301, marginLeft: 4 }}
          onMouseEnter={handleSubMenuEnter}
          onMouseLeave={handleSubMenuLeave}
          modifiers={[
            {
              name: 'offset',
              options: {
                offset: [-10, 0], // Adjust these values as needed
              },
            },
            {
              name: 'preventOverflow',
              options: {
                boundary: 'viewport',
              },
            },
          ]}
        >
          <List sx={listContainerStyle}>{option.children.map((child) => renderMenuItem(child, depth + 1))}</List>
        </Popper>
      )}
    </ListItem>
  );

  React.useEffect(() => {
    return () => {
      handleSearch.cancel();
    };
  }, [handleSearch]);

  React.useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  if (!visible) return <></>;

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Box onMouseLeave={handleClose}>
        <Button
          key={item}
          variant='navbar-item'
          startIcon={iconComponent}
          onMouseEnter={(event) => {
            // @ts-ignore
            clickAble && setAnchorEl(event.currentTarget);
          }}
          onClick={handleOnMenuButtonClick}
        >
          {item}
          {withArrow && <KeyboardArrowDown />}
        </Button>
        {options.length > 0 && (
          <Popper id={id} open={open} anchorEl={anchorEl} disablePortal placement='bottom-start'>
            <List sx={listContainerStyle}>
              {withSearch && (
                <TextField
                  variant='standard'
                  size='small'
                  placeholder='Search...'
                  onChange={handleSearch}
                  sx={{ width: '100%', marginBottom: 1 }}
                />
              )}

              {filteredOptions.map((option) => renderMenuItem(option))}

              {filteredOptions.length === 0 && (
                <ListItem disablePadding>
                  <ListItemButton sx={menuItemStyle}>
                    <Typography variant='input-label' sx={menuItemTextStyle}>
                      No data found
                    </Typography>
                  </ListItemButton>
                </ListItem>
              )}
            </List>
          </Popper>
        )}
      </Box>
    </ClickAwayListener>
  );
}

export const NavDropdownItem = React.memo(NavigationDropdownItem);
