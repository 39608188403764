import qs from 'query-string';
import { cloneDeep } from 'lodash';
import { AxiosResponse } from 'axios';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import axios from 'libs/Axios';

import { AxiosDefaultErrorEntity } from 'types/api/Common/ErrorTypes';
import {
  RequestCubeIngestionRetry,
  RequestSycnCubeData,
  ResponseCubeDataEntry,
  ResponseCubeIngestionStatus,
  RequestUpsertCubeDataEntry,
  ResponseCubeBaselineConnection,
  RequestPullCubeBaselineData,
} from 'types/api/Tenant/AROCube/CubeDataEntryTypes';
import { DropdownItem } from 'types/api/SystemTenant/AROKMS/TableDefinitionTypes';
import { createTreeData, sortTreeData } from 'utils/Object';
import { KTreeNode } from 'types/api/Tenant/AROKMS/KTreeDataTypes';
import { CUBE_DATA_DIMENSIONAL_VIEW_KEY } from './CubeDimensionalViewService';
import { CUBE_BUILDER_QUERY_KEY } from 'services/v1/SystemTenant/AROCube/CubeBuilderService';
import { ENV_CONFIG } from 'config/env';
import { AutoCompleteItem } from 'types/api/Common/AutoCompleteTypes';

export const CUBE_DATA_ENTRY_QUERY_KEY = {
  CUBE_DATA_ENTRY: 'CUBE_DATA_ENTRY',
  CUBE_DATA_ENTRY_INITIAL_VALUE: 'CUBE_DATA_ENTRY_INITIAL_VALUE',
  CUBE_DATA_ENTRY_BASE_VALUE: 'CUBE_DATA_ENTRY_BASE_VALUE',
  CUBE_DATA_ENTRY_CUBE_LIST_DROPDOWN: 'CUBE_DATA_ENTRY_CUBE_LIST_DROPDOWN',
  CUBE_DATA_ERNTY_LAYER_LIST_DROPDOWN: 'CUBE_DATA_ERNTY_LAYER_LIST_DROPDOWN',
  CUBE_DATA_ENTRY_MEMBER_LIST_DROPDOWN: 'CUBE_DATA_ENTRY_MEMBER_LIST_DROPDOWN',
  CUBE_DATA_ENTRY_MEMBER_X_LIST_DROPDOWN: 'CUBE_DATA_ENTRY_MEMBER_X_LIST_DROPDOWN',
  CUBE_DATA_ENTRY_SUBJECT_DROPDOWN: 'CUBE_DATA_ENTRY_SUBJECT_DROPDOWN',
  CUBE_DATA_ENTRY_INGESTION_STATUS: 'CUBE_DATA_ENTRY_INGESTION_STATUS',
  CUBE_DATA_ENTRY_BASELINE_CONNECTION_OPTIONS: 'CUBE_DATA_ENTRY_BASELINE_CONNECTION_OPTIONS',
  CUBE_DATA_ENTRY_CURREN_TIME_PERIOD_DATE: 'CUBE_DATA_ENTRY_CURREN_TIME_PERIOD_DATE',
  CUBE_DATA_ENTRY_DATA_DETAIL_OPTIONS: 'CUBE_DATA_ENTRY_DATA_DETAIL_OPTIONS',
};

// @ts-ignore
function orderSpecialAttributesData(res) {
  const modifiedRes = cloneDeep(res);

  // order based on the referenceCode of the attribute
  // the referenceCode is alpahebet value such as A, B, C, D,AB,AC, etc.
  // order based on alphabet value
  // @ts-ignore
  modifiedRes.data?.data?.sort((a, b) => {
    if (a.referenceCode > b.referenceCode) {
      return 1;
    }
    if (a.referenceCode < b.referenceCode) {
      return -1;
    }
    return 0;
  });
  return modifiedRes;
}

export function useGetCubeDataEntry(cubeId?: string, layerId?: string, memberId?: string, xMemberId?: string) {
  return useQuery<AxiosResponse<ResponseCubeDataEntry>, AxiosDefaultErrorEntity>(
    [CUBE_DATA_ENTRY_QUERY_KEY.CUBE_DATA_ENTRY, cubeId, layerId, memberId, xMemberId],
    () =>
      axios
        .get(
          `/api/v1/tenant/cube/data-entry/${cubeId}/${memberId}?${qs.stringify({
            layerId,
            xDimensionMemberId: xMemberId,
          })}`
        )
        .then((res) => res),
    {
      enabled: !!cubeId && !!layerId && !!memberId,
    }
  );
}

export function useGetCubeDataEntryBaseValue(cubeId?: string, memberId?: string, xMemberId?: string) {
  return useQuery<AxiosResponse<ResponseCubeDataEntry>, AxiosDefaultErrorEntity>(
    [CUBE_DATA_ENTRY_QUERY_KEY.CUBE_DATA_ENTRY_BASE_VALUE, cubeId, memberId, xMemberId],
    () =>
      axios
        .get(
          `/api/v1/tenant/cube/data-entry/base/${cubeId}/${memberId}?${qs.stringify({
            xMemberId,
          })}`
        )
        .then(orderSpecialAttributesData),
    {
      enabled: !!cubeId && !!memberId,
    }
  );
}
export function useGetCubeDataEntryInitialValue(cubeId?: string, memberId?: string, xMemberId?: string) {
  return useQuery<AxiosResponse<ResponseCubeDataEntry>, AxiosDefaultErrorEntity>(
    [CUBE_DATA_ENTRY_QUERY_KEY.CUBE_DATA_ENTRY_INITIAL_VALUE, cubeId, memberId, xMemberId],
    () =>
      axios
        .get(
          `/api/v1/tenant/cube/data-entry/initial/${cubeId}/${memberId}?${qs.stringify({
            xMemberId,
          })}`
        )
        .then(orderSpecialAttributesData),
    {
      enabled: !!cubeId && !!memberId,
    }
  );
}

export function useGetCubeListDropdownBySubject(subjectId?: string | number) {
  return useQuery<AxiosResponse<DropdownItem[]>, AxiosDefaultErrorEntity>(
    [CUBE_DATA_ENTRY_QUERY_KEY.CUBE_DATA_ENTRY_CUBE_LIST_DROPDOWN, subjectId],
    () => axios.get(`/api/v1/tenant/cube/dropdown/cubes/${subjectId}`).then((res) => res),
    {
      enabled: !!subjectId,
    }
  );
}

export function useGetCubeSubjectList() {
  return useQuery<AxiosResponse<DropdownItem[]>, AxiosDefaultErrorEntity>(
    [CUBE_DATA_ENTRY_QUERY_KEY.CUBE_DATA_ENTRY_SUBJECT_DROPDOWN],
    () => axios.get('/api/v1/tenant/cube/dropdown/subject').then((res) => res)
  );
}

export function useGetLayerListDropdownByCube(cubeId?: string | number) {
  return useQuery<AxiosResponse<DropdownItem[]>, AxiosDefaultErrorEntity>(
    [CUBE_DATA_ENTRY_QUERY_KEY.CUBE_DATA_ERNTY_LAYER_LIST_DROPDOWN, cubeId],
    () => axios.get(`/api/v1/tenant/cube/dropdown/layers/${cubeId}`).then((res) => res),
    {
      cacheTime: ENV_CONFIG.endpoint.MAXIMUM_CACHE_TIME,
      staleTime: ENV_CONFIG.endpoint.MAXIMUM_CACHE_TIME,
      enabled: !!cubeId,
    }
  );
}
export function useGetCubeMemberDropdownByCube(cubeId?: string | number, type?: string) {
  return useQuery<KTreeNode[], AxiosDefaultErrorEntity>(
    [CUBE_DATA_ENTRY_QUERY_KEY.CUBE_DATA_ENTRY_MEMBER_LIST_DROPDOWN, cubeId],
    () =>
      axios.get(`/api/v1/tenant/cube/dropdown/member/${cubeId}?${qs.stringify({ type })}`).then((res) => {
        if (res.data.length > 0) {
          const treeData = createTreeData(null, res.data);
          return sortTreeData(treeData);
        }
        return null;
      }),
    {
      enabled: !!cubeId,
    }
  );
}
export function useGetCubeXMemberDropdownByCube(cubeId?: string | number) {
  return useQuery<KTreeNode[], AxiosDefaultErrorEntity>(
    [CUBE_DATA_ENTRY_QUERY_KEY.CUBE_DATA_ENTRY_MEMBER_X_LIST_DROPDOWN, cubeId],
    () =>
      axios.get(`/api/v1/tenant/cube/dropdown/member/x-dimension/${cubeId}`).then((res) => {
        if (res.data.length > 0) {
          const treeData = createTreeData(null, res.data);
          return sortTreeData(treeData);
        }
        return null;
      }),
    {
      enabled: !!cubeId,
    }
  );
}

export function useGetCubeIngestionStatus(
  cubeId: string | undefined,
  layerId: string | undefined,
  options?: { refetchInterval: number | false }
) {
  return useQuery<AxiosResponse<ResponseCubeIngestionStatus>, AxiosDefaultErrorEntity>(
    [CUBE_DATA_ENTRY_QUERY_KEY.CUBE_DATA_ENTRY_INGESTION_STATUS, cubeId, layerId],
    () => axios.get(`/api/v1/tenant/cube/ingestion-status/${cubeId}/${layerId}`).then((res) => res),
    {
      enabled: !!cubeId && !!layerId,
      refetchInterval: options?.refetchInterval,
    }
  );
}

export function useGetCubeCurrentTimePeriodDate(cubeId: string | undefined) {
  return useQuery<
    AxiosResponse<{
      currentPeriodDate: string;
      currentPeriod: string;
      currentFYPeriod: string;
    }>,
    AxiosDefaultErrorEntity
  >(
    [CUBE_DATA_ENTRY_QUERY_KEY.CUBE_DATA_ENTRY_CURREN_TIME_PERIOD_DATE, cubeId],
    () => axios.get(`/api/v1/tenant/cube/data-entry/current-period/${cubeId}`).then((res) => res),
    {
      enabled: !!cubeId,
    }
  );
}

export function useGetCubeDataDetailOptions(cubeId: string | undefined) {
  return useQuery<
    AxiosResponse<{
      memberOptions: AutoCompleteItem[];
      timePeriodOptions: AutoCompleteItem[];
      attributeOptions: AutoCompleteItem[];
    }>,
    AxiosDefaultErrorEntity
  >(
    [CUBE_DATA_ENTRY_QUERY_KEY.CUBE_DATA_ENTRY_DATA_DETAIL_OPTIONS, cubeId],
    () => axios.get(`/api/v1/tenant/cube/data-entry/detail-data/options/${cubeId}`).then((res) => res),
    {
      enabled: !!cubeId,
    }
  );
}

export function useGetCubeBaselineConnectionOptions(
  cubeId: string | undefined,
  layerId: string | undefined,
  cubeMemberId: string | undefined
) {
  return useQuery<AxiosResponse<ResponseCubeBaselineConnection>, AxiosDefaultErrorEntity>(
    [CUBE_DATA_ENTRY_QUERY_KEY.CUBE_DATA_ENTRY_BASELINE_CONNECTION_OPTIONS, cubeId, layerId, cubeMemberId],
    () =>
      axios
        .get(`/api/v1/tenant/cube/data-entry/baseline-connection/${cubeId}/${layerId}/${cubeMemberId}`)
        .then((res) => res),
    {
      enabled: !!cubeId && !!layerId && !!cubeMemberId,
    }
  );
}

export function usePullBaselineDataFromCubeConnection() {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosDefaultErrorEntity, RequestPullCubeBaselineData>(
    (data) =>
      axios
        .post(
          `/api/v1/tenant/cube/data-entry/baseline-connection/pull/${data.cubeId}/${data.targetCubePullId}/${data.layerId}/${data.memberId}`,
          data
        )
        .then((res) => res),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(CUBE_DATA_ENTRY_QUERY_KEY.CUBE_DATA_ENTRY);
        queryClient.invalidateQueries(CUBE_DATA_ENTRY_QUERY_KEY.CUBE_DATA_ENTRY_INITIAL_VALUE);
        queryClient.invalidateQueries(CUBE_DATA_ENTRY_QUERY_KEY.CUBE_DATA_ENTRY_INGESTION_STATUS);
      },
      onError: (error, variables, ctx) => {
        queryClient.invalidateQueries(CUBE_DATA_ENTRY_QUERY_KEY.CUBE_DATA_ENTRY);
        queryClient.invalidateQueries(CUBE_DATA_ENTRY_QUERY_KEY.CUBE_DATA_ENTRY_INITIAL_VALUE);
        queryClient.invalidateQueries([CUBE_BUILDER_QUERY_KEY.CUBE_DEFINITION_DETAILS]);
        queryClient.getDefaultOptions().mutations?.onError?.(error, variables, ctx);
      },
    }
  );
}

export function useUpsertCubeDataentry() {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosDefaultErrorEntity, RequestUpsertCubeDataEntry>(
    (data) => axios.post(`/api/v1/tenant/cube/data-entry/upsert/${data.cubeId}`, data).then((res) => res),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(CUBE_DATA_ENTRY_QUERY_KEY.CUBE_DATA_ENTRY);
        queryClient.invalidateQueries(CUBE_DATA_ENTRY_QUERY_KEY.CUBE_DATA_ENTRY_INGESTION_STATUS);
        queryClient.invalidateQueries(CUBE_DATA_DIMENSIONAL_VIEW_KEY.CUBE_DIMENSIONAL_VIEW);
      },
      onError: (error, variables, ctx) => {
        queryClient.invalidateQueries(CUBE_DATA_ENTRY_QUERY_KEY.CUBE_DATA_ENTRY);
        queryClient.invalidateQueries([CUBE_BUILDER_QUERY_KEY.CUBE_DEFINITION_DETAILS]);
        queryClient.getDefaultOptions().mutations?.onError?.(error, variables, ctx);
      },
    }
  );
}

export function useUpsertCubeDataEntryBase() {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosDefaultErrorEntity, RequestUpsertCubeDataEntry>(
    (data) => axios.post(`/api/v1/tenant/cube/data-entry/base/upsert/${data.cubeId}`, data).then((res) => res),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(CUBE_DATA_ENTRY_QUERY_KEY.CUBE_DATA_ENTRY);
        queryClient.invalidateQueries(CUBE_DATA_ENTRY_QUERY_KEY.CUBE_DATA_ENTRY_BASE_VALUE);
        queryClient.invalidateQueries(CUBE_DATA_ENTRY_QUERY_KEY.CUBE_DATA_ENTRY_INGESTION_STATUS);
      },
      onError: (error, variables, ctx) => {
        queryClient.invalidateQueries(CUBE_DATA_ENTRY_QUERY_KEY.CUBE_DATA_ENTRY);
        queryClient.invalidateQueries(CUBE_DATA_ENTRY_QUERY_KEY.CUBE_DATA_ENTRY_BASE_VALUE);
        queryClient.invalidateQueries([CUBE_BUILDER_QUERY_KEY.CUBE_DEFINITION_DETAILS]);
        queryClient.getDefaultOptions().mutations?.onError?.(error, variables, ctx);
      },
    }
  );
}
export function useUpsertCubeDataEntryInitial() {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosDefaultErrorEntity, RequestUpsertCubeDataEntry>(
    (data) => axios.post(`/api/v1/tenant/cube/data-entry/initial/upsert/${data.cubeId}`, data).then((res) => res),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(CUBE_DATA_ENTRY_QUERY_KEY.CUBE_DATA_ENTRY);
        queryClient.invalidateQueries(CUBE_DATA_ENTRY_QUERY_KEY.CUBE_DATA_ENTRY_INITIAL_VALUE);
        queryClient.invalidateQueries(CUBE_DATA_ENTRY_QUERY_KEY.CUBE_DATA_ENTRY_INGESTION_STATUS);
      },
      onError: (error, variables, ctx) => {
        queryClient.invalidateQueries(CUBE_DATA_ENTRY_QUERY_KEY.CUBE_DATA_ENTRY);
        queryClient.invalidateQueries(CUBE_DATA_ENTRY_QUERY_KEY.CUBE_DATA_ENTRY_INITIAL_VALUE);
        queryClient.invalidateQueries([CUBE_BUILDER_QUERY_KEY.CUBE_DEFINITION_DETAILS]);
        queryClient.getDefaultOptions().mutations?.onError?.(error, variables, ctx);
      },
    }
  );
}

export function useRetryCubeIngestion() {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosDefaultErrorEntity, RequestCubeIngestionRetry>(
    (data) => axios.post(`/api/v1/tenant/cube/ingestion-status/retry`, data).then((res) => res),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(CUBE_DATA_ENTRY_QUERY_KEY.CUBE_DATA_ENTRY_INGESTION_STATUS);
      },
      onError: (error) => {
        queryClient.invalidateQueries(CUBE_DATA_ENTRY_QUERY_KEY.CUBE_DATA_ENTRY_INGESTION_STATUS);
      },
    }
  );
}

export function useSyncCubeData() {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosDefaultErrorEntity, RequestSycnCubeData>(
    (data) => axios.post(`/api/v1/tenant/cube/sync`, data).then((res) => res),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(CUBE_DATA_ENTRY_QUERY_KEY.CUBE_DATA_ENTRY);
      },
    }
  );
}
