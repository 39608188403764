import * as React from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import SVGIcon from '@mui/material/SvgIcon';

import { ReactComponent as CustomIconHome } from 'assets/icons/icon-home.svg';

export function IconBreadcrumbs({ item }: { item: string[] }) {
  function handleClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    event.preventDefault();
    console.info('You clicked a breadcrumb.');
  }
  return (
    <div role='presentation' onClick={handleClick}>
      <Breadcrumbs
        aria-label='breadcrumb'
        sx={{
          '& .MuiBreadcrumbs-separator': {
            color: '#667085DE',
          },
        }}
      >
        <Link underline='hover' sx={{ display: 'flex', alignItems: 'center' }} color='inherit' href='/'>
          <SVGIcon sx={{ fill: 'none', fontSize: 13 }} inheritViewBox component={CustomIconHome} />
        </Link>
        {item.map((breadCrumbsTitle) => (
          <Typography
            data-testid='main-appbar-breadcrumb'
            variant='body2'
            key={breadCrumbsTitle}
            sx={{ display: 'flex', alignItems: 'center', fontSize: 12 }}
            color='#667085DE'
          >
            {breadCrumbsTitle}
          </Typography>
        ))}
      </Breadcrumbs>
    </div>
  );
}
