import { AxiosResponse } from 'axios';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import axios from 'libs/Axios';

import { AxiosDefaultErrorEntity } from 'types/api/Common/ErrorTypes';
import {
  CubeTimePeriodSetDetails,
  RequestCreateTimePeriodSet,
  ResponseTimePeriodSetItems,
} from 'types/api/SystemTenant/AROCube/CubeTimePeriodSetTypes';

export const CUBE_TIME_PERIOD_QUERY_KEY = {
  TIME_PERIOD_LIST: 'TIME_PERIOD_LIST',
  TIME_PERIOD_SET_DETAIL: 'TIME_PERIOD_SET_DETAIL',
};

export function useGetTimePeriodSetList() {
  return useQuery<AxiosResponse<ResponseTimePeriodSetItems>, AxiosDefaultErrorEntity>(
    [CUBE_TIME_PERIOD_QUERY_KEY.TIME_PERIOD_LIST],
    () => axios.get(`/api/v1/cube-time-period-set/list`).then((res) => res)
  );
}

export function useGetTimePeriodSetDetail(id?: string) {
  return useQuery<AxiosResponse<CubeTimePeriodSetDetails>, AxiosDefaultErrorEntity>(
    [CUBE_TIME_PERIOD_QUERY_KEY.TIME_PERIOD_SET_DETAIL, id],
    () => axios.get(`/api/v1/cube-time-period-set/${id}`).then((res) => res),
    {
      enabled: !!id,
    }
  );
}

export function useUpsertTimePeriod() {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosDefaultErrorEntity, RequestCreateTimePeriodSet>(
    (data) => axios.post(`/api/v1/cube-time-period-set/upsert`, data).then((res) => res),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(CUBE_TIME_PERIOD_QUERY_KEY.TIME_PERIOD_LIST);
      },
      onError: (error) => {
        queryClient.invalidateQueries(CUBE_TIME_PERIOD_QUERY_KEY.TIME_PERIOD_LIST);
      },
    }
  );
}
