import ButtonLoading from '@mui/lab/LoadingButton';
import { toast } from 'react-toastify';
import { useGenerateKTreeData } from 'services/v1/Tenant/AROKMS/KTreeDataService';
import { KTREE_DEFINITIN_STATUS } from 'constant/KTreeDefinitionStatusConstant';
import { KTreeDefinitionItem } from 'types/api/SystemTenant/KTreeBuilderTypes';
import { getErrorMessage } from 'utils/Error';
import { Stack } from '@mui/material';

const buttonStyle = {
  height: '78%',
  fontSize: '12px',
};
type ButtonActionProps = KTreeDefinitionItem & {
  onClickAddFilter: () => void;
};

export function ButtonAction(props: ButtonActionProps) {
  const { id, status, onClickAddFilter } = props;

  const { mutate: generateKtreeData, isLoading: isGenerating } = useGenerateKTreeData();
  const handleOnGenerateJKtreeData = () => {
    const toastId = toast.loading('Building KTree data...');
    generateKtreeData(
      { id },
      {
        onSuccess: () => {
          toast.update(toastId, { render: 'Build data success!', type: 'success', isLoading: false, autoClose: 3000 });
        },
        onError: (error: any) => {
          toast.update(toastId, { render: getErrorMessage(error), type: 'error', isLoading: false, autoClose: 3000 });
        },
      }
    );
  };

  const isDisabledButtonBuild = KTREE_DEFINITIN_STATUS.NO_DATA === status;

  return (
    <Stack direction='row' gap={2} height={40}>
      <ButtonLoading
        loading={isGenerating}
        variant='main-table-panel'
        disabled={isDisabledButtonBuild}
        sx={buttonStyle}
        onClick={handleOnGenerateJKtreeData}
      >
        {status === KTREE_DEFINITIN_STATUS.NO_DATA ? 'Build' : 'Refresh Data'}
      </ButtonLoading>
      <ButtonLoading
        loading={isGenerating}
        variant='secondary-table-panel'
        disabled={isDisabledButtonBuild}
        sx={buttonStyle}
        onClick={onClickAddFilter}
      >
        Filter
      </ButtonLoading>
    </Stack>
  );
}
