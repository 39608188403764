import { AxiosResponse } from 'axios';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import {
  TableDefinitionResponse,
  TableDefinitionGetParams,
  TableDefinitionUpsertRequest,
  DropdownItem,
  TableDefinitionColumnOrderItem,
} from 'types/api/SystemTenant/AROKMS/TableDefinitionTypes';
import qs from 'query-string';
import axios from 'libs/Axios';
import { WithOptimisticUpdate } from 'utils/WithOptimisticUpdate';
import {
  DataTypeItem,
  RuleTypeItem,
  TableDefinitionDDetails,
} from 'types/api/SystemTenant/AROKMS/TableDefinitionTypes';
import { AxiosDefaultErrorEntity } from 'types/api/Common/ErrorTypes';
import { ATTRIBUTE_QUERY_KEY } from './AttributeService';

export function useDataTypesDropdown({ subjectType }: { subjectType?: string }) {
  return useQuery<AxiosResponse<DataTypeItem[]>, AxiosDefaultErrorEntity>(
    ['DATA_TYPES_DROPDOWN', { subjectType }],
    () => axios.get(`/api/v1/table-definition/data-types?${qs.stringify({ subjectType })}`).then((res) => res)
  );
}

export function useGetTableDefinitionValidationOption(tableDefinitionId?: string | null) {
  return useQuery<
    AxiosResponse<{
      validationPattern: string;
    }>,
    AxiosDefaultErrorEntity
  >(['VALIDATION_OPTION_PATTERN', { tableDefinitionId }], () =>
    axios.get(`/api/v1/table-definition/validation-pattern/${tableDefinitionId}`).then((res) => res)
  );
}

export function useUpsertTableDefinitionValidationOption(tableDefinitionId?: string | null) {
  const queryClient = useQueryClient();
  const keyQuery = ['VALIDATION_OPTION_PATTERN', { tableDefinitionId }];

  return useMutation<AxiosResponse, AxiosDefaultErrorEntity, { validationPattern: string }>(
    (bodyRequest) => axios.post(`/api/v1/table-definition/validation-pattern/${tableDefinitionId}`, bodyRequest),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(keyQuery);
        queryClient.invalidateQueries(['TABLE_DEFINITION_LIST']);
      },
    }
  );
}

export function useTableDefinitionColOrderList(subjectId?: string | number) {
  return useQuery<AxiosResponse<TableDefinitionColumnOrderItem[]>, AxiosDefaultErrorEntity>(
    ['TABLE_DEFINTION_COL_ORDER', { subjectId }],
    () => axios.get(`/api/v1/table-definition/columns-order/input/${subjectId}`).then((res) => res),
    {
      enabled: !!subjectId,
    }
  );
}

export function useTableDefinitionColDisplayOrderList(subjectId?: string | number) {
  return useQuery<AxiosResponse<TableDefinitionColumnOrderItem[]>, AxiosDefaultErrorEntity>(
    ['TABLE_DEFINTION_COL_DISPLAY_ORDER', { subjectId }],
    () => axios.get(`/api/v1/table-definition/columns-order/display/${subjectId}`).then((res) => res),
    {
      enabled: !!subjectId,
    }
  );
}

export function useTableDefinitionColDisplayOrderUpsert(subjectId?: string | number) {
  const queryClient = useQueryClient();
  const listQuery = ['TABLE_DEFINTION_COL_DISPLAY_ORDER', { subjectId }];

  return useMutation<AxiosResponse, AxiosDefaultErrorEntity, TableDefinitionColumnOrderItem[]>(
    (bodyRequest) => axios.post(`/api/v1/table-definition/columns-order/display/${subjectId}`, bodyRequest),
    {
      onError: (err, variables, context) => {
        queryClient.invalidateQueries(listQuery);
      },
      onSuccess: () => {
        queryClient.invalidateQueries(listQuery);
      },
    }
  );
}
export function useTableDefinitionColOrderUpsert(subjectId?: string | number) {
  const queryClient = useQueryClient();
  const listQuery = ['TABLE_DEFINTION_COL_ORDER', { subjectId }];

  return useMutation<AxiosResponse, AxiosDefaultErrorEntity, TableDefinitionColumnOrderItem[]>(
    (bodyRequest) => axios.post(`/api/v1/table-definition/columns-order/input/${subjectId}`, bodyRequest),
    {
      onError: (err, variables, context) => {
        queryClient.invalidateQueries(listQuery);
      },
      onSuccess: () => {
        queryClient.invalidateQueries(listQuery);
      },
    }
  );
}

export function useRuleTypesDropdown({ subjectType }: { subjectType?: string }) {
  return useQuery<AxiosResponse<RuleTypeItem[]>, AxiosDefaultErrorEntity>(
    ['RULE_TYPES_DROPDOWN', { subjectType }],
    () => axios.get(`/api/v1/table-definition/rule-types?${qs.stringify({ subjectType })}`).then((res) => res),
    {
      enabled: !!subjectType,
    }
  );
}

export function useGetLimitedDropdownAssociteSubject(subjectId: string | number) {
  return useQuery<AxiosResponse<DropdownItem[]>, AxiosDefaultErrorEntity>(
    ['LIMITED_DROPDOWN_LIST', subjectId],
    () => axios.get(`/api/v1/table-definition/limited-dropdown/associated-subject/${subjectId}`).then((res) => res),
    {
      enabled: !!subjectId,
    }
  );
}

export function useTableDefinitionList(params: TableDefinitionGetParams, tabPanel: string) {
  return useQuery<AxiosResponse<TableDefinitionResponse>, AxiosDefaultErrorEntity>(
    ['TABLE_DEFINITION_LIST', params, tabPanel],
    () => {
      if (tabPanel === 'ASSOCIATED_SUBJECT') {
        return axios
          .get(`/api/v1/table-definition/list/associated-subject/${params.subjectId}?${qs.stringify(params)}`)
          .then((res) => res);
      } else {
        return axios
          .get(`/api/v1/table-definition/list/${params.subjectId}?${qs.stringify(params)}`)
          .then((res) => res);
      }
    },
    {
      enabled: !!params.subjectId,
    }
  );
}

export function useTableDefinitioDetails({
  tableDefinitionId,
  enabled,
}: {
  tableDefinitionId: number | string | null | undefined;
  enabled: boolean;
}) {
  return useQuery<AxiosResponse<TableDefinitionDDetails>, AxiosDefaultErrorEntity>(
    ['TABLE_DEFINITION_DETAILS', tableDefinitionId],
    () => axios.get(`/api/v1/table-definition/${tableDefinitionId}`).then((res) => res),
    {
      enabled: enabled && Boolean(tableDefinitionId),
    }
  );
}

export function useUpsertTableDefinition(filter?: TableDefinitionGetParams, tabPanel?: string) {
  const queryClient = useQueryClient();
  const listQuery = ['TABLE_DEFINITION_LIST', { ...filter }, tabPanel];

  return useMutation<AxiosResponse, AxiosDefaultErrorEntity, TableDefinitionUpsertRequest>(
    (bodyRequest) => {
      if (tabPanel === 'ASSOCIATED_SUBJECT') {
        return axios.post(`/api/v1/table-definition/upsert-associated-subject`, bodyRequest);
      }
      return axios.post('/api/v1/table-definition/add', bodyRequest);
    },
    {
      onMutate: async (bodyRequest) =>
        WithOptimisticUpdate<TableDefinitionUpsertRequest>(bodyRequest, listQuery, queryClient, 'UPDATE'),
      onError: (err, variables, context) => {
        //@ts-ignore
        if (context?.previousData) {
          //@ts-ignore
          queryClient.setQueryData(listQuery, context.previousData);
        }
      },
      onSuccess: () => {
        queryClient.resetQueries(listQuery);
        queryClient.invalidateQueries(listQuery);
        queryClient.invalidateQueries([ATTRIBUTE_QUERY_KEY.ATTRIBUTE_DROPDOWN]);
        queryClient.invalidateQueries(['LIMITED_DROPDOWN_LIST']);
        queryClient.invalidateQueries(['TABLE_DEFINTION_COL_ORDER']);
      },
    }
  );
}

export function useDeleteTableDefinitionItem() {
  const listQuery = ['TABLE_DEFINITION_LIST'];
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosDefaultErrorEntity, { id: number | string }>(
    (bodyRequest) => axios.delete(`/api/v1/table-definition/${bodyRequest.id}`),
    {
      onMutate: async (bodyRequest) =>
        WithOptimisticUpdate<{ id: string | number }>(bodyRequest, listQuery, queryClient, 'DELETE'),
      onError: (err, variables, context) => {
        //@ts-ignore
        if (context?.previousData) {
          //@ts-ignore
          queryClient.setQueryData(context.query, context.previousData);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(listQuery);
        queryClient.resetQueries(listQuery);
        queryClient.invalidateQueries(['TABLE_DEFINTION_COL_ORDER']);
      },
    }
  );
}
