import { useMemo, useEffect, memo } from 'react';
import { FormikProps } from 'formik';
import { Field } from 'types/api/Tenant/AROKMS/DisplayTableTypes';
import { Stack, TextField, Typography, Box, SxProps } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { HighlightedText } from 'components/HighlightedTextComponent';
import { convertRuleTypeParamsToSnakeCase } from 'utils/String';
import { INPUT_EDIT_MESSAGE, WIDTH_LABEL } from './FormGenerator';

interface IProps {
  formik: FormikProps<{ [key: string]: any }>;
  field: Field;
  sx?: SxProps;
  freeSolo?: boolean;
  ruleTypeCode?: string;
  isEdit?: boolean;
  required?: boolean;
}

const autocompleteStyles = {
  width: '65%',
  '& .MuiOutlinedInput-root': {
    padding: '1px 2px',
  },
  '& .MuiAutocomplete-popper': {
    fontSize: '10px',
  },
};

const textInputAutoCompleteStyles = {
  '& .MuiOutlinedInput': {
    padding: '1px 2px',
  },
};

const optionStyles = { '& > span': { fontSize: '14px', mr: 2, flexShrink: 0 } };

function DropdownLimitedMemberListComponent(props: IProps) {
  const { formik: formikProps, field: fieldItem, required, isEdit = false } = props;

  const params = convertRuleTypeParamsToSnakeCase(fieldItem.ruleTypeParams);
  const dependDropdownOnColumn = params[0];

  const refColumnValue = formikProps.values[dependDropdownOnColumn];
  const referenceValue = useMemo(() => {
    return refColumnValue?.value || refColumnValue;
  }, [refColumnValue]);

  const dropdownOptions = useMemo(() => {
    const options = fieldItem.options?.filter(
      // @ts-ignore
      (option) => option.parent === referenceValue
    );

    const optionsMap = new Map();
    options?.forEach((option) => optionsMap.set(option.value, option));
    return optionsMap;
  }, [referenceValue, fieldItem]);

  useEffect(() => {
    const currentVal = formikProps.values[fieldItem.name]?.value;

    const optionItem = dropdownOptions?.get(currentVal);

    if (formikProps.values[fieldItem.name] && !optionItem) {
      formikProps.setFieldValue(fieldItem.name, null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referenceValue, fieldItem.name]);

  return (
    <Stack direction='row' justifyContent='space-between' alignItems='center' marginTop={2.5} gap={1}>
      <Stack width={WIDTH_LABEL}>
        <Typography variant='input-label' fontWeight={700}>
          {fieldItem.label}
          {required && <span style={{ color: 'red', marginLeft: 2 }}>*</span>}
        </Typography>
        {fieldItem.columnEditable === false && !isEdit && (
          <Typography variant='input-label-gray' fontSize={12}>
            {INPUT_EDIT_MESSAGE}
          </Typography>
        )}
      </Stack>

      <Autocomplete
        value={
          formikProps.values[fieldItem.name] === '' ? { value: '', label: '' } : formikProps.values[fieldItem.name]
        }
        onChange={(event, newValue) => {
          formikProps.setFieldValue(fieldItem.name, newValue);
        }}
        onBlur={formikProps.handleBlur}
        getOptionLabel={(option) => option.label}
        clearIcon={null}
        disabled={fieldItem.disabled}
        contentEditable={false}
        options={Array.from(dropdownOptions.values()) || []}
        renderOption={(props, option, state) => {
          return (
            <Box component='li' sx={optionStyles} {...props}>
              <span>
                <HighlightedText text={option.label} highlight={state.inputValue} />
              </span>
            </Box>
          );
        }}
        sx={autocompleteStyles}
        renderInput={(params) => (
          <TextField
            {...params}
            name={fieldItem.name}
            sx={textInputAutoCompleteStyles}
            placeholder={fieldItem.placeholder}
            error={!!formikProps.touched[fieldItem.name] && !!formikProps.errors[fieldItem.name]}
            helperText={formikProps.touched[fieldItem.name] && formikProps.errors[fieldItem.name]}
          />
        )}
      />
    </Stack>
  );
}

export const DropdownLimitedMemberList = memo(DropdownLimitedMemberListComponent);
