import { AxiosResponse } from 'axios';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import axios from 'libs/Axios';

import { AxiosDefaultErrorEntity } from 'types/api/Common/ErrorTypes';
import {
  InsighViewDefinitionDetails,
  InsightViewAttributeItem,
  RequestCreateInsightViewDefinition,
  RequestEditInsightViewDefinition,
} from 'types/api/SystemTenant/AROView/InsightViewDefinitionTypes';

export const INSIGHT_VIEW_DEFINITION_QUERY_KEY = {
  INSIGHT_VIEW_DEFINITION_LIST: 'INSIGHT_VIEW_DEFINITION_LIST',
  INSIGHT_VIEW_DEFINITION_DETAILS: 'INSIGHT_VIEW_DEFINITION_DETAILS',
};

export function useGetListInsightView() {
  return useQuery<AxiosResponse<InsightViewAttributeItem[]>, AxiosDefaultErrorEntity>(
    [INSIGHT_VIEW_DEFINITION_QUERY_KEY.INSIGHT_VIEW_DEFINITION_LIST],
    () => axios.get(`/api/v1/insight-view-definition/list`).then((res) => res)
  );
}

export function useGetInsightViewDefinitionDetails(insightViewId?: string | null) {
  return useQuery<AxiosResponse<InsighViewDefinitionDetails>, AxiosDefaultErrorEntity>(
    [INSIGHT_VIEW_DEFINITION_QUERY_KEY.INSIGHT_VIEW_DEFINITION_DETAILS, insightViewId],
    () => axios.get(`/api/v1/insight-view-definition/detail/${insightViewId}`).then((res) => res),
    {
      enabled: !!insightViewId,
    }
  );
}

export function useCreateInsightViewDefinition() {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosDefaultErrorEntity, RequestCreateInsightViewDefinition>(
    (data) => axios.post(`/api/v1/insight-view-definition/create`, data).then((res) => res),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(INSIGHT_VIEW_DEFINITION_QUERY_KEY.INSIGHT_VIEW_DEFINITION_LIST);
      },
      onError: (error, variables, ctx) => {
        queryClient.invalidateQueries(INSIGHT_VIEW_DEFINITION_QUERY_KEY.INSIGHT_VIEW_DEFINITION_LIST);
        queryClient.getDefaultOptions().mutations?.onError?.(error, variables, ctx);
      },
    }
  );
}

export function useEditInsightViewDefinition() {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosDefaultErrorEntity, RequestEditInsightViewDefinition>(
    (data) => axios.put(`/api/v1/insight-view-definition/edit/${data.id}`, data).then((res) => res),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(INSIGHT_VIEW_DEFINITION_QUERY_KEY.INSIGHT_VIEW_DEFINITION_LIST);
        queryClient.invalidateQueries(INSIGHT_VIEW_DEFINITION_QUERY_KEY.INSIGHT_VIEW_DEFINITION_DETAILS);
      },
      onError: (error, variables, ctx) => {
        queryClient.invalidateQueries(INSIGHT_VIEW_DEFINITION_QUERY_KEY.INSIGHT_VIEW_DEFINITION_LIST);
        queryClient.getDefaultOptions().mutations?.onError?.(error, variables, ctx);
      },
    }
  );
}

export function useDeleteInsightdViewDefinition() {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosDefaultErrorEntity, string>(
    (insightViewId) => axios.delete(`/api/v1/insight-view-definition/${insightViewId}`).then((res) => res),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(INSIGHT_VIEW_DEFINITION_QUERY_KEY.INSIGHT_VIEW_DEFINITION_LIST);
      },
      onError: (error, variables, ctx) => {
        queryClient.invalidateQueries(INSIGHT_VIEW_DEFINITION_QUERY_KEY.INSIGHT_VIEW_DEFINITION_LIST);
        queryClient.getDefaultOptions().mutations?.onError?.(error, variables, ctx);
      },
    }
  );
}
