import { Formik, FormikProps } from 'formik';
import { useRef, useState } from 'react';
import Divider from '@mui/material/Divider';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import ButtonLoading from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import Visibility from '@mui/icons-material/VisibilityOutlined';
import VisibilityOff from '@mui/icons-material/VisibilityOffOutlined';

import { valueSafeEcryptor } from 'utils/Encryptor';
import { AddNewPersonValidationSchema } from 'config/FormValidaton/TenantMaster/MasterTable/PersonTableValidationSchema';
import { useCreatePerson } from 'services/v1/TenantMaster/MasterTable/PersonTableDataService';
import { PersonUsertRequest } from 'types/api/TenantMaster/MasterTable/PersonTableDataTypes';
import { getErrorMessage } from 'utils/Error';
import { AxiosDefaultErrorEntity } from 'types';

const ModalContentStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  minHeight: 400,
  borderRadius: 1,
  px: 3,
  py: 2,
};

const textInputStyles = {
  width: '55%',
  '& .MuiOutlinedInput': {
    padding: '1px 2px',
  },
};

const closeIconStyle = { color: '#98A2AE', cursor: 'pointer' };

interface FormValue {
  person: string;
  fullName: string;
  mobile: string;
  email: string;
  password: string;
  confirmPassword: string;
}

const formInitialValues: FormValue = {
  person: '',
  fullName: '',
  mobile: '',
  confirmPassword: '',
  password: '',
  email: '',
};

interface ModalAddPersonProps {
  visible?: boolean;
  onClose?: () => void;
}

export function ModalAddPersonComponent(props: ModalAddPersonProps) {
  const formikRef = useRef<FormikProps<FormValue>>(null);
  const { onClose, visible = false } = props;
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handlePasswordToggle = () => {
    setShowPassword(!showPassword);
  };

  const handleConfirmPasswordToggle = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const {
    mutate: addPerson,
    isLoading: isCreating,
    isError: isCreatingError,
    error: errorCreating,
    reset: resetCreatePerson,
  } = useCreatePerson();

  const handleOnClose = () => {
    onClose?.();
    formikRef.current?.resetForm();
    resetCreatePerson();
    setShowConfirmPassword(false);
    setShowPassword(false);
  };

  const isSemiLoading = isCreating;

  const handleOnSave = (data: FormValue) => {
    const payload: PersonUsertRequest = {
      fullName: data.fullName,
      mobile: data.mobile,
      userName: data.email,
      password: valueSafeEcryptor(data.confirmPassword) || '',
      person: data.person,
    };

    addPerson(payload, {
      onSuccess: () => {
        handleOnClose();
      },
    });
  };

  return (
    <div>
      <Formik
        innerRef={formikRef}
        initialValues={formInitialValues}
        validationSchema={AddNewPersonValidationSchema}
        onSubmit={handleOnSave}
      >
        {(formikProps) => {
          const { handleSubmit, values, handleChange, errors, touched, handleBlur } = formikProps;
          return (
            <Modal open={visible} onClose={handleOnClose}>
              <Stack direction='column' sx={ModalContentStyle} justifyContent='space-between'>
                <Stack direction='column'>
                  <Stack justifyContent='space-between' direction='row' alignItems='center' spacing={2}>
                    <Typography variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
                      Add New Person
                    </Typography>
                    <IconButton onClick={handleOnClose} component='label'>
                      <HighlightOffRoundedIcon sx={closeIconStyle} />
                    </IconButton>
                  </Stack>
                  <Divider sx={{ mb: 1 }} />
                  <Stack>
                    <Stack
                      direction='row'
                      spacing={2}
                      alignItems='center'
                      justifyContent='space-between'
                      sx={{ mt: 2 }}
                    >
                      <Typography variant='input-label'>Person</Typography>
                      <TextField
                        sx={textInputStyles}
                        size='small'
                        disabled={isSemiLoading}
                        placeholder='e.g. John'
                        onChange={handleChange}
                        value={values?.person}
                        onBlur={handleBlur}
                        name='person'
                        error={touched.person && Boolean(errors.person)}
                        helperText={touched.person && errors.person}
                      />
                    </Stack>

                    <Stack
                      direction='row'
                      spacing={2}
                      alignItems='center'
                      justifyContent='space-between'
                      sx={{ mt: 2 }}
                    >
                      <Typography variant='input-label'>Full Name</Typography>
                      <TextField
                        sx={textInputStyles}
                        size='small'
                        disabled={isSemiLoading}
                        placeholder='e.g. John Doe'
                        onChange={handleChange}
                        value={values?.fullName}
                        onBlur={handleBlur}
                        name='fullName'
                        error={touched.fullName && Boolean(errors.fullName)}
                        helperText={touched.fullName && errors.fullName}
                      />
                    </Stack>

                    <Stack
                      direction='row'
                      spacing={2}
                      alignItems='center'
                      justifyContent='space-between'
                      sx={{ mt: 2 }}
                    >
                      <Typography variant='input-label'>Email</Typography>
                      <TextField
                        sx={textInputStyles}
                        size='small'
                        disabled={isSemiLoading}
                        placeholder='e.g. johndoe@email.com'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values?.email}
                        name='email'
                        error={touched.email && Boolean(errors.email)}
                        helperText={touched.email && errors.email}
                      />
                    </Stack>
                    <Stack
                      direction='row'
                      spacing={2}
                      alignItems='center'
                      justifyContent='space-between'
                      sx={{ mt: 2 }}
                    >
                      <Typography variant='input-label'>Mobile Phone</Typography>
                      <TextField
                        sx={textInputStyles}
                        size='small'
                        disabled={isSemiLoading}
                        placeholder='e.g. 6123456789'
                        onChange={(e) => {
                          const value = e.target.value;
                          if (value.match(/^[0-9]*$/)) {
                            handleChange(e);
                          }
                        }}
                        onBlur={handleBlur}
                        value={values?.mobile}
                        name='mobile'
                        error={touched.mobile && Boolean(errors.mobile)}
                        helperText={touched.mobile && errors.mobile}
                      />
                    </Stack>

                    <Stack
                      direction='row'
                      spacing={2}
                      alignItems='center'
                      justifyContent='space-between'
                      sx={{ mt: 2 }}
                    >
                      <Typography variant='input-label'>Password</Typography>
                      <TextField
                        sx={textInputStyles}
                        size='small'
                        disabled={isSemiLoading}
                        type={showPassword ? 'text' : 'password'}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder={`User's password`}
                        value={values?.password}
                        name='password'
                        error={touched.password && Boolean(errors.password)}
                        helperText={touched.password && errors.password}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='end'>
                              <IconButton onClick={handlePasswordToggle} size='small'>
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Stack>
                    <Stack
                      direction='row'
                      spacing={2}
                      alignItems='center'
                      justifyContent='space-between'
                      sx={{ mt: 2 }}
                    >
                      <Typography variant='input-label'>Confirm Password</Typography>
                      <TextField
                        sx={textInputStyles}
                        size='small'
                        type={showConfirmPassword ? 'text' : 'password'}
                        disabled={isSemiLoading}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Please re-enter user's password"
                        value={values?.confirmPassword}
                        name='confirmPassword'
                        error={touched.confirmPassword && Boolean(errors.confirmPassword)}
                        helperText={touched.confirmPassword && errors.confirmPassword}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='end'>
                              <IconButton onClick={handleConfirmPasswordToggle} size='small'>
                                {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Stack>
                  </Stack>
                </Stack>

                <Stack py={2}>
                  {isCreatingError && (
                    <Alert severity='error'>{getErrorMessage(errorCreating as AxiosDefaultErrorEntity)}</Alert>
                  )}
                </Stack>

                <Divider sx={{ mb: 1, mt: 2 }} />
                <Stack direction='row' justifyContent='space-between' alignItems='' alignContent='flex-end'>
                  <Stack />
                  <Stack direction='row' justifyContent='flex-end' spacing={2} alignItems='center' sx={{ py: 1 }}>
                    <Button disabled={isSemiLoading} variant='main-table-panel-border' onClick={handleOnClose}>
                      Cancel
                    </Button>
                    <ButtonLoading
                      disabled={!AddNewPersonValidationSchema.isValidSync(values)}
                      variant='main-table-panel'
                      loading={isSemiLoading}
                      onClick={() => handleSubmit()}
                    >
                      Save
                    </ButtonLoading>
                  </Stack>
                </Stack>
              </Stack>
            </Modal>
          );
        }}
      </Formik>
    </div>
  );
}
