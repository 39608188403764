import { AxiosResponse } from 'axios';
import { useQuery, useMutation, useQueryClient } from 'react-query';

import axios from 'libs/Axios';

import { AxiosDefaultErrorEntity } from 'types/api/Common/ErrorTypes';
import { DisplayTableDataType } from 'types/api/TenantMaster/MasterTable/DisplayTableDataTypes';

export const DISPLAY_TABLE_DATA_QUERY = {
  DISPLAY_TABLE_LIST: 'DISPLAY_TABLE_LIST',
  DISPLAY_DETAILS: 'DISPLAY_DETAILS',
};

export function useGetDisplayTableData() {
  return useQuery<AxiosResponse<DisplayTableDataType[]>, AxiosDefaultErrorEntity>(
    [DISPLAY_TABLE_DATA_QUERY.DISPLAY_TABLE_LIST],
    () => axios.get(`/api/v1/subject/subject-master/list`).then((res) => res)
  );
}
export function useGetDisplayTableDetails(subjectId?: string | number) {
  return useQuery<AxiosResponse<DisplayTableDataType>, AxiosDefaultErrorEntity>(
    [DISPLAY_TABLE_DATA_QUERY.DISPLAY_DETAILS],
    () => axios.get(`/api/v1/subject/subject-master/${subjectId}`).then((res) => res),
    {
      enabled: !!subjectId,
    }
  );
}

export function useUpdateDisplayTableData() {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosDefaultErrorEntity, { subjectId: string; adminRightLevel: number | string }>(
    ({ subjectId, adminRightLevel }) =>
      axios.put(`/api/v1/subject/subject-master/edit/${subjectId}`, { adminRightLevel }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(DISPLAY_TABLE_DATA_QUERY.DISPLAY_TABLE_LIST);
        queryClient.invalidateQueries(DISPLAY_TABLE_DATA_QUERY.DISPLAY_DETAILS);
      },
    }
  );
}
