import { RULES_CONSTANT } from 'constant/RuleConstant';
import { Field } from 'types/api/Tenant/AROKMS/DisplayTableTypes';

const getDefaultFormValidationType = (field: Field) => {
  const { label, type } = field;
  return {
    placeholder: `Please enter ${label}`,
    validationType: type === 'text' ? 'string' : 'object',
    validations: [
      {
        type: 'required',
        params: [`${label} is required`],
      },
      {
        type: 'nullable',
        params: [],
      },
    ],
  };
};

export function disabledFieldForNonEditColumn(field: Field, isEdit: boolean): Field {
  return {
    ...field,
    disabled: isEdit ? !field.columnEditable : field.disabled,
  };
}

export function getAdditionalConfigFormByRule(field: Field, isEdit: boolean) {
  const { ruleTypeCode } = field;
  switch (ruleTypeCode) {
    case RULES_CONSTANT.INTEGER.RULE_INTEGER_ADDITION:
    case RULES_CONSTANT.INTEGER.RULE_INTEGER_DIVISION:
    case RULES_CONSTANT.INTEGER.RULE_INTEGER_MULTIPLICATION:
    case RULES_CONSTANT.INTEGER.RULE_INTEGER_SUBSTRACTION:
    case RULES_CONSTANT.TEXT.RULE_ATTRIBUTE_MID:
    case RULES_CONSTANT.TEXT.RULE_CONCATENATE_ATTRIBUTES:
    case RULES_CONSTANT.TEXT.RULE_ATTRIBUTE_LEFT:
    case RULES_CONSTANT.TEXT.RULE_TEXT_GENERAL_SUBSTRING:
    case RULES_CONSTANT.TEXT.RULE_TEXT_GENERAL_SUBSTRING_STATIC_TABLE:
    case RULES_CONSTANT.CURRENCY.RULE_CURRENCY_ADDITION:
    case RULES_CONSTANT.CURRENCY.RULE_CURRENCY_DIVISION:
    case RULES_CONSTANT.CURRENCY.RULE_CURRENCY_MULTIPLICATION:
    case RULES_CONSTANT.CURRENCY.RULE_CURRENCY_SUBSTRACTION:
    case RULES_CONSTANT.PERCENTAGE.RULE_PERCENTAGE_ADDITION:
    case RULES_CONSTANT.PERCENTAGE.RULE_PERCENTAGE_DIVISION:
    case RULES_CONSTANT.PERCENTAGE.RULE_PERCENTAGE_MULTIPLICATION:
    case RULES_CONSTANT.PERCENTAGE.RULE_PERCENTAGE_SUBTRACTION:
    case RULES_CONSTANT.PERCENTAGE.RULE_CALCULATE_PERCENTAGE:
    case RULES_CONSTANT.INTEGER.RULE_INTEGER_COUNT_ROW:
    case RULES_CONSTANT.NUMBER.RULE_DECIMAL_ADDITION:
    case RULES_CONSTANT.NUMBER.RULE_DECIMAL_DIVISION:
    case RULES_CONSTANT.NUMBER.RULE_DECIMAL_MULTIPLICATION:
    case RULES_CONSTANT.NUMBER.RULE_DECIMAL_SUBTRACTION:
    case RULES_CONSTANT.DATE.RULE_DATE_WEEKNUM:
    case RULES_CONSTANT.DATE.RULE_DATE_INPUT_NUM_TYPE:
    case RULES_CONSTANT.DATETIME.RULE_DATE_TIME:
    case RULES_CONSTANT.TEXT.RULE_PULL_ATTRIBUTE:
    case RULES_CONSTANT.TEXT.RULE_PULL_ATTRIBUTE_STATIC_TABLE:
    case RULES_CONSTANT.TEXT.RULE_PULL_FROM_STATIC_TABLE:
    case RULES_CONSTANT.INTEGER.RULE_INTEGER_PULL_NUMERIC:
    case RULES_CONSTANT.DATE.RULE_DATE_PERIODS:
    case RULES_CONSTANT.INTEGER.RULE_INTEGER_MULTIPLICATION_WITH_PERCENTAGE:
      return {
        ...getDefaultFormValidationType(field),
        placeholder: '',
        disabled: true,
      };

    case RULES_CONSTANT.TEXT.RULE_INPUT:
      return getDefaultFormValidationType(field);

    case RULES_CONSTANT.TEXT.RULE_TEXT_IF_CONDITIONAL:
      return {
        ...getDefaultFormValidationType(field),
        disabled: true,
      };

    case RULES_CONSTANT.BASE_SUBJECT.RULE_ATTRIBUTE_SUBJECT_BASE:
      return {
        ...getDefaultFormValidationType(field),
      };

    default:
      return getDefaultFormValidationType(field);
  }
}
