import * as React from 'react';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { Typography } from '@mui/material';

interface BasicAlertProps {
  type: 'success' | 'error' | 'info' | 'warning';
  title?: string;
}

export function BasicAlert(props: BasicAlertProps) {
  const { type, title = 'Something went wrong, please try again' } = props;
  return (
    <Stack sx={{ width: '100%' }} spacing={1}>
      <Alert severity={type}>
        <Typography component='p' variant='body2' color='#0F2875'>
          {title}
        </Typography>
      </Alert>
    </Stack>
  );
}
