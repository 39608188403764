import { FormikProps } from 'formik';

import Stack from '@mui/material/Stack';

import Typography from '@mui/material/Typography';

import TextField from '@mui/material/TextField';

import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';

import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { useUsersForDeploySystem } from 'services/v1/TenantMaster/MasterTable/SystemTableDataService';
import { useServerListDropdown } from 'services/v1/TenantMaster/MasterTable/ServerTableDataService';
import { GLOBAL_MODAL_STYLE_VALUE } from 'constant/GloblaStyleConstant';

const columns: GridColDef[] = [
  { field: 'fullName', headerName: 'Username', width: 220 },
  {
    field: 'userName',
    headerName: 'Email',
    width: 260,
  },
];

const autocompleteStyles = {
  width: '55%',
  '& .MuiOutlinedInput-root': {
    padding: '1px 2px',
  },
  '& .MuiAutocomplete-popper': {
    fontSize: '10px',
  },
};

const textInputAutoCompleteStyles = {
  '& .MuiOutlinedInput': {
    padding: '1px 2px',
  },
};

const optionStyles = { '& > span': { fontSize: '14px', mr: 2, flexShrink: 0 } };

interface ModalParamsProps {
  formikProps: FormikProps<any>;
  disabled?: boolean;
}

export function TabSectionServerUserContent(props: ModalParamsProps) {
  const { formikProps, disabled } = props;
  const { values, setFieldValue } = formikProps;

  const { data: usersData, isLoading: isLoadingUsers } = useUsersForDeploySystem();
  const { data: serverOptions } = useServerListDropdown();

  return (
    <Stack px={GLOBAL_MODAL_STYLE_VALUE.PADDING_X} bgcolor='#FAFAFA'>
      <Stack>
        <Stack direction='row' spacing={2} alignItems='center' justifyContent='space-between' sx={{ mt: 2 }}>
          <Typography variant='input-label'>Select Server</Typography>
          <Autocomplete
            options={serverOptions || []}
            // @ts-ignore
            value={values.serverId}
            getOptionLabel={(option) => option.label}
            clearIcon={null}
            disabled={disabled}
            contentEditable={false}
            onChange={(event, value) => {
              setFieldValue('serverId', value);
            }}
            renderOption={(props, option) => (
              <Box component='li' sx={optionStyles} {...props}>
                <span>{option.label}</span>
              </Box>
            )}
            sx={autocompleteStyles}
            renderInput={(params) => (
              <TextField {...params} sx={textInputAutoCompleteStyles} placeholder='Please select' />
            )}
          />
        </Stack>
      </Stack>
      <Stack mt={3} mb={2}>
        <Typography variant='input-label' mb={2}>
          Assign Users
        </Typography>
        <div style={{ height: 250, width: '100%' }}>
          {!isLoadingUsers && (
            <DataGridPro
              sx={{
                // customise header background color
                '& .MuiCheckbox-root svg': {
                  backgroundColor: 'transparent',
                  color: '#98A2AE',
                  borderRadius: 2,
                },
                '& .MuiDataGrid-columnHeaders': {
                  backgroundColor: '#EDF2FF',
                  borderTop: '1px solid #E3EBF6',
                  borderBottom: '1px solid #E3EBF6',
                  textAlign: 'center',
                  display: 'flex',
                },
              }}
              density='compact'
              rows={usersData?.data || []}
              selectionModel={values.userId}
              columns={columns}
              onSelectionModelChange={(newSelection) => {
                setFieldValue('userId', newSelection);
              }}
              pagination={false}
              hideFooter
              rowsPerPageOptions={[5]}
              checkboxSelection
            />
          )}
        </div>
      </Stack>
    </Stack>
  );
}
