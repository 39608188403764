import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Modal } from '@mui/material';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import Divider from '@mui/material/Divider';
import { MODAL_TABLE_DEFINITION_CONFIG_OPTIONS, SelectConfigOption } from './SelectConfigOption';
import { ListConfigInput } from './ListConfigInput';
import { ListConfigDisplayTable } from './ListConfigDisplayTable';
import Button from '@mui/material/Button';
import KeyboardBackspaceOutlined from '@mui/icons-material/KeyboardBackspaceOutlined';

const ModalContentStyle = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  minHeight: 600,
  borderRadius: 1,
  px: 3,
  py: 2,
};

const backButtonStyles = {
  textTransform: 'none',
  mb: 1,
  p: 0,
  width: 100,
};
const transitionStyles = {
  entering: { transform: 'translateX(-100%)', position: 'absolute' } as React.CSSProperties,
  entered: {
    transform: 'translateX(0)',
    transition: 'transform 0.3s',
    position: 'relative',
  } as React.CSSProperties,
  exiting: {
    transform: 'translateX(100%)',
    position: 'absolute',
  } as React.CSSProperties,
  exited: { transform: 'translateX(-100%)', position: 'absolute' } as React.CSSProperties,
};

const closeIconStyle = { color: '#98A2AE', cursor: 'pointer' };

const MODAL_TITLE = {
  [MODAL_TABLE_DEFINITION_CONFIG_OPTIONS.DISPLAY_CONFIG]: 'Customize Table Display',
  [MODAL_TABLE_DEFINITION_CONFIG_OPTIONS.INPUT_CONFIG]: 'Customize Input Columns',
};

export interface ModalCustomColumnOrderProps {
  open: boolean;
  onClose: () => void;
  subjectId: string;
}

export function ModalCustomColumnOrder(props: ModalCustomColumnOrderProps) {
  const { onClose, open, subjectId } = props;

  const [selectedDisplay, setSelectedDisplay] = React.useState<string | null>(null);

  const handleOnSelectedConfigOption = React.useCallback((option: string) => {
    setSelectedDisplay(option);
  }, []);

  const handleOnClickBackToList = React.useCallback(() => {
    setSelectedDisplay(null);
  }, []);

  const handleOnClose = React.useCallback(() => {
    setSelectedDisplay(null);
    onClose();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Modal open={open} onClose={handleOnClose}>
      <>
        <Stack direction='column' sx={ModalContentStyle} justifyContent='space-between'>
          <Stack direction='column'>
            <Stack justifyContent='space-between' direction='row' alignItems='center' spacing={2}>
              <Typography variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
                {selectedDisplay ? MODAL_TITLE[selectedDisplay] : 'Customize Table'}
              </Typography>
              <IconButton onClick={handleOnClose} component='label'>
                <HighlightOffRoundedIcon sx={closeIconStyle} />
              </IconButton>
            </Stack>
            <Divider sx={{ mb: 1 }} />
          </Stack>
          {selectedDisplay && (
            <Button
              variant='text'
              disableRipple
              onClick={handleOnClickBackToList}
              sx={backButtonStyles}
              startIcon={<KeyboardBackspaceOutlined />}
            >
              Back to list
            </Button>
          )}
          <div style={{ position: 'relative', overflow: 'hidden' }}>
            <Stack style={!selectedDisplay ? transitionStyles.entered : transitionStyles.exiting}>
              <SelectConfigOption onMenuOptionClick={handleOnSelectedConfigOption} />
            </Stack>
          </div>
          <div style={{ position: 'relative', overflow: 'auto', height: '100%', width: '100%' }}>
            <ListConfigInput
                onClose={handleOnClose}
              style={
                MODAL_TABLE_DEFINITION_CONFIG_OPTIONS.INPUT_CONFIG === selectedDisplay
                  ? transitionStyles.entered
                  : transitionStyles.exited
              }
              subjectId={subjectId}
            />
            <ListConfigDisplayTable
              subjectId={subjectId}
              onClose={handleOnClose}
              style={
                MODAL_TABLE_DEFINITION_CONFIG_OPTIONS.DISPLAY_CONFIG === selectedDisplay
                  ? transitionStyles.entered
                  : transitionStyles.exited
              }
            />
          </div>
        </Stack>
      </>
    </Modal>
  );
}
