import * as React from 'react';
import { Modal } from '@mui/material';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import Divider from '@mui/material/Divider';
import ButtonLoading from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import { TextAreaComponent } from 'components/TextAreaComponent';
import {
  useGetTableDefinitionDescription,
  useUpsertTableDefinitionDescription,
} from 'services/v1/SystemTenant/AROKMS/TableDefinitionDescriptionService';
import ActivityIndicator from 'components/ActivityIndicatorComponent';

const ModalContentStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 550,
  bgcolor: 'background.paper',
  minHeight: 450,
  borderRadius: 1,
  px: 3,
  py: 2,
};

const closeIconStyle = { color: '#98A2AE', cursor: 'pointer' };

export interface ModalCustomColumnOrderProps {
  open: boolean;
  onClose: () => void;
  subjectId: string;
  title?: string;
}

const MAX_CHARACTER = 400;

export function ModalTableDefinitionDescription(props: ModalCustomColumnOrderProps) {
  const { onClose, open, subjectId, title = 'Table Definition Description' } = props;

  const { data: tdDescription, isLoading: isFetching, remove } = useGetTableDefinitionDescription(subjectId);
  const { mutate: upsertDescription, isLoading: isInserting } = useUpsertTableDefinitionDescription();

  const [description, setDescription] = React.useState<string>('');

  const isLoading = isFetching || isInserting;

  const handleClose = () => {
    onClose();
    setDescription('');
    remove();
  };

  const handleOnTextAreaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (event.target.value.length > MAX_CHARACTER) return;
    setDescription(event.target.value);
  };

  const handleOnSave = () => {
    upsertDescription(
      {
        subjectId,
        description,
        id: tdDescription?.data?.id || null,
      },
      {
        onSuccess: () => {
          handleClose();
        },
      }
    );
  };

  React.useEffect(() => {
    if (tdDescription && tdDescription?.data) {
      setDescription(tdDescription.data.description ?? '');
    }
  }, [tdDescription]);

  return (
    <Modal open={open} onClose={handleClose}>
      <>
        <Stack direction='column' sx={ModalContentStyle} justifyContent='space-between'>
          {isFetching ? (
            <Stack sx={{ height: 400 }}>
              <ActivityIndicator />
            </Stack>
          ) : (
            <>
              <Stack direction='column'>
                <Stack justifyContent='space-between' direction='row' alignItems='center' spacing={2}>
                  <Typography variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
                    {title}
                  </Typography>
                  <IconButton onClick={handleClose} component='label'>
                    <HighlightOffRoundedIcon sx={closeIconStyle} />
                  </IconButton>
                </Stack>
                <Divider sx={{ mb: 1 }} />
                <Stack>
                  <Typography variant='input-label' mb={3}>
                    Include a brief description here to inform users about the table's purpose and context. This
                    guidance will be displayed on the data input page.
                  </Typography>
                  <TextAreaComponent
                    disabled={isLoading}
                    value={description}
                    placeholder='Description...'
                    rows={8}
                    onChange={handleOnTextAreaChange}
                  />
                  <Typography variant='input-label-gray' mt={1} textAlign='right'>
                    {`${description.length}/${MAX_CHARACTER} characters`}
                  </Typography>
                </Stack>
              </Stack>
              <Stack direction='row' justifyContent='space-between' alignItems='center'>
                <Stack></Stack>
                <Stack direction='row' justifyContent='flex-end' spacing={2} alignItems='center' sx={{ py: 1 }}>
                  <Button variant='main-table-panel-border' disabled={isLoading} onClick={handleClose}>
                    Cancel
                  </Button>
                  <ButtonLoading variant='main-table-panel' disabled={isLoading} onClick={handleOnSave}>
                    Save Description
                  </ButtonLoading>
                </Stack>
              </Stack>
            </>
          )}
        </Stack>
      </>
    </Modal>
  );
}
