import * as Yup from 'yup';
import { useFormik } from 'formik';
import React, { HTMLAttributes, useEffect, useMemo } from 'react';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import ButtonLoading from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import AutoComplete from '@mui/material/Autocomplete';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import { AutoCompleteItem } from 'types/api/Common/AutoCompleteTypes';
import { InsightViewCellColorItem } from 'types/api/SystemTenant/AROView/InsightViewDefinitionTypes';
import { INSIGHT_VIEW_CELL_COLOR_OPTIONS, MAP_COLOR_CODE_WITH_COLOR_HEXA } from 'constant/ViewConstant';
import { InputAdornment } from '@mui/material';
import { useGetStandardViewCubeAttributesDropdown } from 'services/v1/SystemTenant/AROView/StandardViewDefinitionService';

const autoCompleteStyle = {
  '& .MuiAutocomplete-popper': {
    backgroundColor: 'red !imoprtant',
    fontSize: '10px',
  },
};
const ModalContentStyle = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 650,
  bgcolor: 'background.paper',
  maxHeight: 850,
  overflow: 'auto',
  borderRadius: 1,
  py: 2,
};

const closeIconStyle = { color: '#98A2AE', cursor: 'pointer' };

export interface ModalAddCellColorDefinitionProps {
  visible?: boolean;
  onClose?: () => void;
  onSave?: (attributeItem: InsightViewCellColorItem) => void;
  onDelete?: (attributeItem: InsightViewCellColorItem) => void;
  selectedData?: InsightViewCellColorItem;
  usedCubeAttributeIds: Set<string>;
  cubeId?: string;
}

const formikValidationSchema = Yup.object().shape(
  {
    cubeAttribute: Yup.object().required('This field is required').nullable(),
    lessThanValue: Yup.string()
      .nullable()
      .when('greaterThanValue', {
        is: (greaterThanValue: string | null) => !greaterThanValue,
        then: Yup.string().required('Either Less Than Value or Greater Than Value is required').nullable(),
      })
      .when('greaterThanValue', (greaterThanValue, schema) =>
        schema.test(
          'is-less-than-greater',
          'Less Than Value must be less than Greater Than Value',
          function (value: string) {
            return !value || !greaterThanValue || parseFloat(value) <= parseFloat(greaterThanValue);
          }
        )
      ),
    lessThanColor: Yup.object()
      .when('lessThanValue', (lessThanValue: string | null, schema) =>
        lessThanValue ? schema.required('This field is required').nullable() : schema.nullable()
      )
      // @ts-ignore
      .when(['greaterThanValue', 'greaterThanColor'], (greaterThanValue, greaterThanColor, schema) =>
        !greaterThanValue && !greaterThanColor
          ? schema.required('This field is required').nullable()
          : schema.nullable()
      ),
    greaterThanValue: Yup.string()
      .nullable()
      .when('lessThanValue', {
        is: (lessThanValue: string | null) => !lessThanValue,
        then: Yup.string().required('Either Less Than Value or Greater Than Value is required').nullable(),
      }),
    greaterThanColor: Yup.object()
      .when('greaterThanValue', (greaterThanValue, schema) =>
        greaterThanValue ? schema.required('This field is required').nullable() : schema.nullable()
      )
      // @ts-ignore
      .when(['lessThanValue', 'lessThanColor'], (lessThanValue, lessThanColor, schema) =>
        !lessThanValue && !lessThanColor ? schema.required('This field is required').nullable() : schema.nullable()
      ),
  },
  [
    ['lessThanValue', 'greaterThanValue'],
    ['lessThanColor', 'greaterThanColor'],
  ]
);
export type FormikCellColorValues = Omit<InsightViewCellColorItem, 'id'>;

export const formikInsightViewCellColorInitialValues: FormikCellColorValues = {
  cubeAttribute: null,
  lessThanValue: null,
  lessThanColor: null,
  greaterThanValue: null,
  greaterThanColor: null,
};

function ColorOptionIndicator(props: { value: string | null }) {
  const { value } = props;
  if (!value) return null;
  return (
    <span
      style={{
        width: 25,
        height: 25,
        backgroundColor: MAP_COLOR_CODE_WITH_COLOR_HEXA.get(value),
      }}
    />
  );
}

export function ModalAddCellColor(props: Readonly<ModalAddCellColorDefinitionProps>) {
  const { onClose, visible = false, onSave, onDelete, selectedData, cubeId, usedCubeAttributeIds } = props;

  const formik = useFormik<FormikCellColorValues>({
    initialValues: formikInsightViewCellColorInitialValues,
    validationSchema: formikValidationSchema,
    onSubmit: () => {},
  });

  const { data: cubeAttributeDropdown } = useGetStandardViewCubeAttributesDropdown(cubeId);

  const cubeDropdownOptions = useMemo<AutoCompleteItem[]>(() => {
    if (!cubeAttributeDropdown) return [];
    const filteredOptions = cubeAttributeDropdown.data.filter(
      (item) => !usedCubeAttributeIds.has(item.value.toString())
    );
    return selectedData && selectedData.cubeAttribute
      ? [...filteredOptions, selectedData.cubeAttribute]
      : filteredOptions;
  }, [cubeAttributeDropdown, usedCubeAttributeIds, selectedData]);

  const handleOnCloseModal = () => {
    onClose?.();
    formik.resetForm();
  };

  const handleOnCancel = () => {
    handleOnCloseModal();
  };
  const handleOnDelete = () => {
    onDelete?.(selectedData as InsightViewCellColorItem);
    handleOnCloseModal();
  };

  const handleOnChangeGroup = (event: React.ChangeEvent<{}>, newValue: AutoCompleteItem | null) => {
    formik.setFieldValue('cubeAttribute', newValue);
  };

  const handleOnSave = () => {
    if (selectedData) {
      onSave?.({
        ...selectedData,
        ...formik.values,
      });
    } else {
      onSave?.({
        id: `NEWCELLCOLOR-${Date.now()}`,
        ...formik.values,
      });
    }

    handleOnCloseModal();
  };

  const renderOption = (props: HTMLAttributes<HTMLElement>, option: AutoCompleteItem) => {
    return (
      <Box component='li' sx={{ '& > span': { fontSize: '14px', mr: 1, flexShrink: 0 } }} {...props}>
        <span>{option.label}</span>
      </Box>
    );
  };
  const renderColorOption = (props: HTMLAttributes<HTMLElement>, option: AutoCompleteItem) => {
    return (
      <Box component='li' sx={{ '& > span': { fontSize: '14px', mr: 1, flexShrink: 0 } }} {...props}>
        {Boolean(option.value) && <ColorOptionIndicator value={option.value.toString()} />}
        <span>{option.label}</span>
      </Box>
    );
  };

  const isValidForm = formikValidationSchema.isValidSync(formik.values);

  useEffect(() => {
    if (selectedData) {
      formik.setValues(selectedData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedData]);

  return (
    <div>
      <Modal open={visible} onClose={handleOnCloseModal}>
        <Stack direction='column' sx={ModalContentStyle} px={2}>
          <Stack>
            <Stack justifyContent='space-between' direction='row' alignItems='center'>
              <Typography variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
                Cell Color Definition
              </Typography>
              <IconButton onClick={handleOnCloseModal} component='label'>
                <HighlightOffRoundedIcon sx={closeIconStyle} />
              </IconButton>
            </Stack>
          </Stack>
          <Divider sx={{ mb: 1 }} />

          <Stack mb={1.6} width='70%'>
            <Typography variant='body2' fontWeight='bold' color='black'>
              Cube Attribute
            </Typography>
            <Typography variant='input-label-gray' mb={1}>
              Choose the attribute for this rule to be applied to.
            </Typography>
            <AutoComplete
              clearIcon={null}
              size='small'
              value={formik.values.cubeAttribute}
              onChange={handleOnChangeGroup}
              onBlur={formik.handleBlur}
              getOptionLabel={(option: AutoCompleteItem) => option.label}
              options={cubeDropdownOptions}
              renderOption={renderOption}
              sx={autoCompleteStyle}
              renderInput={(params) => (
                <Stack direction='row' justifyContent='space-between' display='flex' alignItems='center'>
                  <TextField
                    {...params}
                    placeholder='Select Attribute'
                    name='cubeAttribute'
                    error={Boolean(formik.errors.cubeAttribute) && formik.touched.cubeAttribute}
                    helperText={formik.touched.cubeAttribute && formik.errors.cubeAttribute}
                  />
                </Stack>
              )}
            />
          </Stack>

          <Stack mb={1.6} width='70%'>
            <Typography variant='body2' fontWeight='bold' color='black'>
              Greater Than Value
            </Typography>
            <Typography variant='input-label-gray' mb={1}>
              Input greater than value
            </Typography>
            <TextField
              size='small'
              placeholder='e.g. 80'
              value={formik.values.greaterThanValue}
              onBlur={formik.handleBlur}
              onChange={(e) => {
                const value = e.target.value;
                const positiveNumberRegex = /^[0-9]*$/;
                if (value === '' || positiveNumberRegex.test(value)) {
                  formik.handleChange(e);
                } else {
                  e.target.value = '';
                }
              }}
              error={Boolean(formik.errors.greaterThanValue) && formik.touched.greaterThanValue}
              helperText={formik.touched.greaterThanValue && formik.errors.greaterThanValue}
              name='greaterThanValue'
            />
          </Stack>
          <Stack mb={1.6} width='70%'>
            <Typography variant='body2' fontWeight='bold' color='black'>
              Greater Than Colour
            </Typography>
            <Typography variant='input-label-gray' mb={1}>
              Choose the cell colour when the condition fulfill greater than value.
            </Typography>
            {/* <TextField
              size='small'
              placeholder='e.g. 80'
              value={formik.values.greaterThanValue}
              onBlur={formik.handleBlur}
              type='color'
              onChange={formik.handleChange}
              error={Boolean(formik.errors.greaterThanValue) && formik.touched.greaterThanValue}
              helperText={formik.touched.greaterThanValue && formik.errors.greaterThanValue}
              name='greaterThanValue'
            /> */}
            <AutoComplete
              clearIcon={null}
              size='small'
              value={formik.values.greaterThanColor}
              onChange={(event, newValue) => {
                formik.setFieldValue('greaterThanColor', newValue);
              }}
              onBlur={formik.handleBlur}
              getOptionLabel={(option: AutoCompleteItem) => option.label}
              //   options={targetColumnDropdownOptions}
              options={INSIGHT_VIEW_CELL_COLOR_OPTIONS}
              renderOption={renderColorOption}
              sx={autoCompleteStyle}
              renderInput={(params) => (
                <Stack direction='row' justifyContent='space-between' display='flex' alignItems='center'>
                  <TextField
                    {...params}
                    placeholder='Select Colour'
                    name='greaterThanColor'
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position='start'>
                          <ColorOptionIndicator value={formik.values?.greaterThanColor?.value?.toString() ?? null} />
                        </InputAdornment>
                      ),
                    }}
                    error={Boolean(formik.errors.greaterThanColor) && formik.touched.greaterThanColor}
                    helperText={formik.touched.greaterThanColor && formik.errors.greaterThanColor}
                  />
                </Stack>
              )}
            />
          </Stack>
          <Stack mb={1.6} width='70%'>
            <Typography variant='body2' fontWeight='bold' color='black'>
              Less Than Value
            </Typography>
            <Typography variant='input-label-gray' mb={1}>
              Input greater less value
            </Typography>
            <TextField
              size='small'
              placeholder='e.g. 25'
              value={formik.values.lessThanValue}
              onBlur={formik.handleBlur}
              error={Boolean(formik.errors.lessThanValue) && formik.touched.lessThanValue}
              helperText={formik.touched.lessThanValue && formik.errors.lessThanValue}
              onChange={(e) => {
                const value = e.target.value;
                const positiveNumberRegex = /^[0-9]*$/;
                if (value === '' || positiveNumberRegex.test(value)) {
                  formik.handleChange(e);
                } else {
                  e.target.value = '';
                }
              }}
              name='lessThanValue'
            />
          </Stack>
          <Stack mb={1.6} width='70%'>
            <Typography variant='body2' fontWeight='bold' color='black'>
              Less Than Colour
            </Typography>
            <Typography variant='input-label-gray' mb={1}>
              Choose the cell colour when the condition fulfill less than value.
            </Typography>
            <AutoComplete
              clearIcon={null}
              size='small'
              value={formik.values.lessThanColor}
              onChange={(event, newValue) => {
                formik.setFieldValue('lessThanColor', newValue);
              }}
              onBlur={formik.handleBlur}
              getOptionLabel={(option: AutoCompleteItem) => option.label}
              //   options={resultColumnDropdownOptions}
              options={INSIGHT_VIEW_CELL_COLOR_OPTIONS}
              renderOption={renderColorOption}
              sx={autoCompleteStyle}
              renderInput={(params) => (
                <Stack direction='row' justifyContent='space-between' display='flex' alignItems='center'>
                  <TextField
                    {...params}
                    placeholder='Select Result Column'
                    name='lessThanColor'
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position='start'>
                          <ColorOptionIndicator value={formik.values?.lessThanColor?.value?.toString() ?? null} />
                        </InputAdornment>
                      ),
                    }}
                    error={Boolean(formik.errors.lessThanColor) && formik.touched.lessThanColor}
                    helperText={formik.touched.lessThanColor && formik.errors.lessThanColor}
                  />
                </Stack>
              )}
            />
          </Stack>

          <Stack px={2}>
            <Divider sx={{ mb: 1, mt: 2 }} />
            <Stack direction='row' justifyContent='flex-end' spacing={2} alignItems='center' sx={{ py: 1 }}>
              {selectedData && (
                <Button
                  onClick={handleOnDelete}
                  variant='outlined'
                  sx={{
                    textTransform: 'none',
                  }}
                  color='error'
                >
                  Delete
                </Button>
              )}
              <Button variant='main-table-panel-border' onClick={handleOnCancel}>
                Cancel
              </Button>
              <ButtonLoading variant='main-table-panel' disabled={!isValidForm} onClick={() => handleOnSave()}>
                Save Cell Color
              </ButtonLoading>
            </Stack>
          </Stack>
        </Stack>
      </Modal>
    </div>
  );
}
