// @ts-nocheck
import { ALLOW_EMPTY_VALUE_RULES, RULES_CONSTANT } from 'constant/RuleConstant';
import { Field } from 'types/api/Tenant/AROKMS/DisplayTableTypes';
import * as yup from 'yup';

export function generateYupSchema(schema: any, config: Field) {
  const {
    id,
    validationType,
    validations = [],
    ruleTypeCode,
    allowEmpty,
    label,
    customRegex,
    customRegexErrorMessage,
  } = config;

  if (ALLOW_EMPTY_VALUE_RULES.includes(ruleTypeCode) || allowEmpty) {
    return schema;
  }

  if (!yup[validationType]) {
    return schema;
  }

  let validator = yup[validationType]();

  if (validationType === 'object') {
    validator = validator.test('labelAndValue', `${label} is required`, function (value) {
      const { label: labelField, value: valueField } = value || {};

      let labelValid = typeof labelField === 'string' && labelField.trim() !== '' && !/[#@]/.test(labelField);
      if (labelValid && customRegex) {
        labelValid = new RegExp(customRegex).test(labelField);
      }

      const valueValid = typeof valueField === 'string' && valueField.trim() !== '';

      if ((!labelValid && customRegex) || /[#@]/.test(labelField)) {
        return this.createError({ message: customRegexErrorMessage || 'Invalid format for label' });
      }

      return labelValid && valueValid;
    });
  } else if (validationType === 'string' && ruleTypeCode !== RULES_CONSTANT.COLOUR.RULE_COLOUR_HEXA) {
    validator = validator.matches(/^[^#@]*$/, {
      message: 'The input should not contain # or @ characters',
      excludeEmptyString: true,
    });
    if (customRegex) {
      validator = validator.matches(customRegex, {
        message: customRegexErrorMessage || 'Invalid format',
        excludeEmptyString: true,
      });
    }
  }

  validations.forEach((validation) => {
    const { params, type } = validation;
    if (!validator[type]) {
      return;
    }

    if (type === 'min' && params.length > 0 && typeof params[0] === 'string') {
      params[0] = yup.ref(params[0]);
    }

    validator = validator[type](...params);
  });

  schema[id] = validator;

  return schema;
}
