import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import Alert from '@mui/material/Alert';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { ENV_CONFIG } from 'config/env';
const ModalContentStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: 1,
  px: 2,
  py: 2,
};

const buttonActionStyle = { width: '100%' };

const closeIconStyle = { color: '#98A2AE', cursor: 'pointer' };

export type ModalInfoState = {
  open: boolean;
  key?: string | number;
  descriptionMessage?: string;
};

export interface ModalRecordFailedInfoProps {
  visible?: boolean;
  onClose?: () => void;
  descriptionMessage?: string;
}

const generateEmailContent = (descriptionMessage: string, tenantCode: string, systemCode: string) => {
  const baseContent = `Hi AroTarget Support Team, \nI have a problem with during the synchronization process. \n
  \n\nThe error message is: \n${descriptionMessage} \n\nPlease help me to solve this problem. \n\nThank you.`;

  const systemInfo = `System Info\nOS: ${navigator.platform}\nBrowser: ${navigator.userAgent}\n Customer Solution: ${tenantCode}\n System: ${systemCode}`;

  const emailContent = `${baseContent} \n\n\n\n${systemInfo}`;
  return emailContent;
};

export default function ModalRecordFailedInfo(props: ModalRecordFailedInfoProps) {
  const { onClose, visible = false, descriptionMessage = null } = props;
  const { selectedTenant } = useSelector((state: RootState) => state.auth);

  const handleSendEmail = () => {
    if (descriptionMessage && selectedTenant.tenant.tenantCode && selectedTenant.tenant.systemCode) {
      const email = {
        title: 'Synchronization History',
        description: generateEmailContent(
          descriptionMessage,
          selectedTenant.tenant.tenantCode,
          selectedTenant.tenant.systemCode
        ),
        supportEmail: ENV_CONFIG.config.SUPPORT_EMAIL,
      };
      window.location.href = `mailto:${email.supportEmail}?subject=${email.title}&body=${encodeURIComponent(
        email.description
      )}`;
    }
  };

  return (
    <div>
      <Modal open={visible} onClose={onClose}>
        <Stack direction='column' sx={ModalContentStyle}>
          <Stack justifyContent='space-between' direction='row' spacing={2} textAlign='center'>
            <Typography variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
              Record Failed
            </Typography>
            <IconButton onClick={onClose} component='label'>
              <HighlightOffRoundedIcon sx={closeIconStyle} />
            </IconButton>
          </Stack>

          <Stack alignItems='center' sx={{ py: 2 }}>
            <Alert severity='error' sx={{ width: '90%', mt: 2, backgroundColor: '#FFFBE4', color: '#BC0000' }}>
              {descriptionMessage}
            </Alert>

            <Typography variant='body1' align='center'></Typography>
          </Stack>
          <Divider sx={{ mb: 1 }} />

          <Stack direction='row' gap={2}>
            <Button variant='secondary-table-panel' sx={buttonActionStyle} onClick={handleSendEmail}>
              Ask for help
            </Button>
            <Button variant='main-table-panel' sx={buttonActionStyle} onClick={onClose}>
              Close
            </Button>
          </Stack>
        </Stack>
      </Modal>
    </div>
  );
}
