import { dayjs } from 'utils/DateUtils';
import { GridRenderCellParams, GridRenderEditCellParams, GridValueGetterParams } from '@mui/x-data-grid-pro';
import { renderDataGridSelectEditInputCell } from 'components/DatatableComponent/RenderSelectEditCell';
import { renderCreatableDropdownInputMemberList } from 'components/DatatableComponent/RenderCreatableDropdown';
import { RULES_CONSTANT } from 'constant/RuleConstant';
import { renderSelectTreeComponent } from 'components/DatatableComponent/SelectTreeComponent';
import { createTreeData } from 'utils/Object';
import { customFormatRenderValue } from 'utils/String';
import { ENV_CONFIG } from 'config/env';

/**
 * Rules that using the dropdown are
 * #RULE_PULL_FROM_SUBJECT_ATTRIBUTE
 * #RULE_ATTRIBUTE_SUBJECT_BASE
 * #RULE_ASSOCIATED_SUBJECT_LIST
 */

const generalDropdownSortComparator = (v1: any, v2: any, cellParams1: any, cellParams2: any) => {
  //  if value type is object, then use the label to compare
  if (!v1 && !v2) return 0; // both are null or undefined
  if (!v1) return -1; // v1 is null or undefined, so it should come first
  if (!v2) return 1; // v2 is null or undefined, so it should come first

  if (typeof v1 === 'object') {
    return v1.label.localeCompare(v2.label);
  }

  // if value type is string, then use the value to compare
  return v1?.localeCompare(v2);
};

export function renderDropdowns(options: { value: string; label: string; parent?: string }[], isRequired: boolean) {
  return {
    type: 'text',
    renderCell: (params: GridRenderCellParams) => {
      if (typeof params?.value === 'string') return <span>{params?.value}</span>;
      return <span>{params?.value?.label}</span>;
    },
    valueGetter(params: GridValueGetterParams) {
      const value = params?.value || '';
      if (typeof params?.value === 'string') return params?.value;

      let optionsValue = options.find((option) => option.label === value);

      if (!optionsValue) {
        optionsValue = options.find((option) => option.value === value);
      }

      return optionsValue;
    },
    renderEditCell: (params: GridRenderEditCellParams) => {
      return renderDataGridSelectEditInputCell({
        ...params,
        // @ts-ignore
        optionsSource: options,
        isRequired,
      });
    },
    sortComparator: generalDropdownSortComparator,
  };
}
export function renderAssociateSubjectLimitedDropdown(
  options: { value: string; label: string; parent?: string }[],
  referenceColumn: string,
  isRequired: boolean
) {
  return {
    type: 'text',
    renderCell: (params: GridRenderCellParams) => {
      if (typeof params?.value === 'string') return <span>{params?.value}</span>;
      return <span>{params?.value?.label}</span>;
    },
    valueGetter(params: GridValueGetterParams) {
      const value = params?.value || '';
      if (typeof params?.value === 'string') return params?.value;

      return options.find((option) => option.label === value);
    },
    renderEditCell: (params: GridRenderEditCellParams) => {
      const parenDependantId = params.row[referenceColumn] ? params.row[referenceColumn].value : '';

      return renderDataGridSelectEditInputCell({
        ...params,
        // @ts-ignore
        optionsSource: options.filter((option) => option.parent === parenDependantId),
        isRequired,
      });
    },
    sortComparator: generalDropdownSortComparator,
  };
}

export function renderCreatableDropdowns(
  options: { value: string; label: string }[],
  subjectId: string,
  isRequired: boolean
) {
  return {
    type: 'text',

    valueGetter(params: GridValueGetterParams) {
      return params?.value || '';
    },
    renderCell: (params: GridRenderCellParams) => {
      if (typeof params?.value === 'object') return <span>{params?.value?.label}</span>;
      return <span>{params?.value}</span>;
    },
    renderEditCell: (params: GridRenderEditCellParams) => {
      console.log({ params });
      return renderCreatableDropdownInputMemberList({
        ...params,
        // @ts-ignore
        optionsSource: options,
        subjectId,

        isRequired,
      });
    },

    sortComparator: generalDropdownSortComparator,
  };
}

export function renderPullAttribute(renderAsRuleTypeCode?: string, renderAsRuleTypeParams?: string) {
  return {
    type: 'text',
    editable: true,
    renderEditCell: (params: GridRenderEditCellParams) => {
      return <span>{params?.value}</span>;
    },
    renderCell: (params: GridRenderCellParams) => {
      if (!params.value) return null;

      if (renderAsRuleTypeCode && Object.values(RULES_CONSTANT.PERCENTAGE).includes(renderAsRuleTypeCode)) {
        return <span>{params.value}%</span>;
      }
      if (
        renderAsRuleTypeCode &&
        renderAsRuleTypeParams &&
        Object.values(RULES_CONSTANT.CURRENCY).includes(renderAsRuleTypeCode)
      ) {
        const prefix = renderAsRuleTypeParams.split(',')[0];
        const suffix = renderAsRuleTypeParams.split(',')[1];
        return <span>{`${prefix ? prefix : ''} ${params.value.toLocaleString()} ${suffix ? suffix : ''}`}</span>;
      }
      switch (renderAsRuleTypeCode) {
        case RULES_CONSTANT.GENERATED_SYSTEM.RULE_GENERATED_SYSTEM_DATE:
          return <span>{dayjs(params.value, 'YYYY-MM-DD').format(ENV_CONFIG.config.DATE_FORMAT)}</span>;
        case RULES_CONSTANT.GENERATED_SYSTEM.RULE_GENERATED_SYSTEM_DATE_TIME:
          return <span>{dayjs(params.value).format(ENV_CONFIG.config.DATE_TIME_FORMAT)}</span>;

        default:
          return <span>{customFormatRenderValue(params.value)}</span>;
      }
    },
    sortComparator: (v1: any, v2: any, cellParams1: any, cellParams2: any) => {
      if (v1 === undefined || v1 === null || v2 === undefined || v2 === null) return 0;

      // detect if string value is valid number
      if ((typeof v1 === 'string' && !isNaN(Number(v1))) || typeof v1 === 'number') {
        return Number(v1) - Number(v2);
      }

      if (renderAsRuleTypeCode === RULES_CONSTANT.GENERATED_SYSTEM.RULE_GENERATED_SYSTEM_DATE) {
        return dayjs(v1).diff(dayjs(v2));
      }
      if (renderAsRuleTypeCode === RULES_CONSTANT.BASE_SUBJECT.RULE_ATTRIBUTE_SUBJECT_BASE) {
        return v1?.localeCompare(v2);
      }

      //  if value type is object, then use the label to compare
      if (typeof v1 === 'object') {
        return v1.label.localeCompare(v2.label);
      }

      // if value type is string, then use the value to compare
      return v1?.localeCompare(v2);
    },
  };
}

export function renderReadOnlyGrid(ruleTypeCode: string) {
  return {
    type: 'text',
    editable: true,
    renderEditCell: (params: GridRenderEditCellParams) => {
      return <span>{params?.value}</span>;
    },
    renderCell: (params: GridRenderCellParams) => {
      if (!params.value) return null;
      switch (ruleTypeCode) {
        case RULES_CONSTANT.FUNCTION.RULE_INTEGER_FUNCTION_DEPRICIATION:
          return <span>{params.value.toLocaleString()}</span>;
        case RULES_CONSTANT.GENERATED_SYSTEM.RULE_GENERATED_SYSTEM_DATE:
          return <span>{dayjs(params.value, 'YYYY-MM-DD').format(ENV_CONFIG.config.DATE_FORMAT)}</span>;
        case RULES_CONSTANT.GENERATED_SYSTEM.RULE_GENERATED_SYSTEM_DATE_TIME:
          return <span>{dayjs(params.value).format(ENV_CONFIG.config.DATE_TIME_FORMAT)}</span>;

        case RULES_CONSTANT.INTEGER.RULE_INTEGER_MULTIPLICATION_WITH_PERCENTAGE:
          return <span>{params?.value && params.value?.toLocaleString()}</span>;

        case RULES_CONSTANT.PERCENTAGE.RULE_CALCULATE_PERCENTAGE:
          return <span>{params.value}%</span>;

        default:
          return <span>{customFormatRenderValue(params.value)}</span>;
      }
    },
    sortComparator: (v1: any, v2: any, cellParams1: any, cellParams2: any) => {
      if (v1 === undefined || v1 === null || v2 === undefined || v2 === null) return 0;

      // detect if string value is valid number
      if ((typeof v1 === 'string' && !isNaN(Number(v1))) || typeof v1 === 'number') {
        return Number(v1) - Number(v2);
      }

      if (ruleTypeCode === RULES_CONSTANT.GENERATED_SYSTEM.RULE_GENERATED_SYSTEM_DATE) {
        return dayjs(v1).diff(dayjs(v2));
      }
      if (ruleTypeCode === RULES_CONSTANT.BASE_SUBJECT.RULE_ATTRIBUTE_SUBJECT_BASE) {
        return v1?.localeCompare(v2);
      }

      //  if value type is object, then use the label to compare
      if (typeof v1 === 'object') {
        return v1.label.localeCompare(v2.label);
      }

      // if value type is string, then use the value to compare
      return v1?.localeCompare(v2);
    },
  };
}

export function renderBaseSubjectDropdown(
  options: { value: string; label: string }[],
  subjectId: string,
  allOptions: { value: string; label: string }[],
  isRequired: boolean
) {
  return {
    type: 'string',
    editable: true,
    valueGetter(params: GridValueGetterParams) {
      return params?.value || '';
    },
    valueFormatter: (params: GridValueGetterParams) => {
      return params?.value?.label || params?.value;
    },
    renderCell: (params: GridRenderCellParams) => {
      return <span>{params?.value?.label || params?.value}</span>;
    },
    renderEditCell: (params: GridRenderEditCellParams) => {
      if (params?.row?.isNew) {
        return renderCreatableDropdownInputMemberList({
          ...params,
          // @ts-ignore
          optionsSource: options,
          subjectId,
          isRequired,
        });
      }
      return <span>{params?.value?.label || params?.value}</span>;
    },
    sortComparator: generalDropdownSortComparator,
  };
}

export function renderKtreeSelectDropdown(
  options: { value: string; label: string; member?: string; id?: string }[],
  subjectId: string,
  isRequired: boolean
) {
  return {
    type: 'string',
    editable: true,
    width: 300,
    renderCell: (params: GridRenderCellParams) => {
      if (typeof params?.value === 'string') return <span>{params?.value}</span>;
      return <span>{params?.value?.label}</span>;
    },
    valueGetter(params: GridValueGetterParams) {
      const value = params?.value || '';
      if (typeof params?.value === 'string') return params?.value;
      const option = options.find((option) => option.member === value);
      const fieldValue = {
        label: option?.label || option?.member || '',
        value: option?.value || option?.id || '',
      };
      return fieldValue;
    },
    renderEditCell: (params: GridRenderEditCellParams) => {
      // @ts-ignore
      const ktreeOptions = options.length ? createTreeData(null, options) : [];
      return renderSelectTreeComponent({
        ...params,
        // @ts-ignore
        optionsSource: ktreeOptions,
        isRequired,
      });
    },
    sortComparator: (v1: any, v2: any, cellyaParams1: any, cellParams2: any) => {
      // if v1 is undefined, put it on the bottom
      if (!v1) return 1;
      // if v2 is undefined, put it on the top
      if (!v2) return -1;
      //  if value type is object, then use the label to compare
      if (typeof v1 === 'object') {
        return v1.member?.localeCompare(v2.member);
      }

      // if value type is string, then use the value to compare
      return v1.localeCompare(v2);
    },
  };
}
