import React, { useState, useCallback } from 'react';
import Popper from '@mui/material/Popper';
import Alert from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ButtonLoading from '@mui/lab/LoadingButton';
import SmsFailedOutlinedIcon from '@mui/icons-material/SmsFailedOutlined';
import Box from '@mui/material/Box';
import { useRetryCubeIngestion } from 'services/v1/Tenant/AROCube/CubeDataEntryService';
import { Paper, Stack } from '@mui/material';

const StyledPopper = styled(Popper)(({ theme }) => ({
  zIndex: 2000,
  '&[x-placement*="bottom"] $arrow': {
    top: 0,
    left: 0,
    marginTop: '-0.71em',
    marginLeft: 4,
    marginRight: 4,
    '&::before': {
      transformOrigin: '0 100%',
    },
  },
  '&[x-placement*="top"] $arrow': {
    bottom: 0,
    left: 0,
    marginBottom: '-0.71em',
    marginLeft: 4,
    marginRight: 4,
    '&::before': {
      transformOrigin: '100% 0',
    },
  },
  '&[x-placement*="right"] $arrow': {
    left: 0,
    marginLeft: '-0.71em',
    height: '1em',
    width: '0.71em',
    marginTop: 4,
    marginBottom: 4,
    '&::before': {
      transformOrigin: '100% 100%',
    },
  },
  '&[x-placement*="left"] $arrow': {
    right: 0,
    marginRight: '-0.71em',
    height: '1em',
    width: '0.71em',
    marginTop: 4,
    marginBottom: 4,
    '&::before': {
      transformOrigin: '0 0',
    },
  },
}));

export function ButtonRetryWithPopper(props: { cubeId: string; layerId: string; errorMessage: string | null }) {
  const { cubeId, layerId, errorMessage } = props;
  const { mutate: retryCubeIngestion, isLoading: isRetrying } = useRetryCubeIngestion();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleOnRetryClick = useCallback(() => {
    cubeId && layerId && retryCubeIngestion({ cubeId, layerId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cubeId, layerId]);

  const open = Boolean(anchorEl);

  return (
    <div>
      <IconButton
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup='true'
        sx={{
          color: '#ed5151',
        }}
        onMouseEnter={handlePopoverOpen}
      >
        <SmsFailedOutlinedIcon />
      </IconButton>
      <StyledPopper
        open={open}
        anchorEl={anchorEl}
        placement='right'
        modifiers={[
          {
            name: 'flip',
            enabled: true,
            options: {
              altBoundary: true,
              rootBoundary: 'document',
              padding: 8,
            },
          },
          {
            name: 'preventOverflow',
            enabled: true,
            options: {
              altAxis: true,
              altBoundary: true,
              tether: true,
              rootBoundary: 'document',
              padding: 8,
            },
          },
        ]}
      >
        {/* <ArrowComponent ref={setArrowRef} /> */}
        <Paper sx={{ p: 1, display: 'flex', flexDirection: 'column' }} onMouseLeave={handlePopoverClose}>
          <Stack width={330}>
            <Alert severity='error'>
              <Typography sx={{ fontSize: '14px' }}>Unable to process latest data.</Typography>
              <Typography sx={{ fontSize: '12px' }} mb={2}>
                There was an error processing the latest data. Please try again if the error still persists, please
                contact support.
              </Typography>
              <code
                style={{
                  fontSize: '12px',
                }}
              >
                {errorMessage}
              </code>
              <Box sx={{ display: 'flex', justifyContent: 'flex-start', mt: 1 }}>
                <ButtonLoading
                  onClick={handleOnRetryClick}
                  loading={isRetrying}
                  variant='secondary-login'
                  color='info'
                  startIcon={<ReplayOutlinedIcon />}
                  size='small'
                  sx={{
                    height: '30px',
                    padding: '0px 8px',
                    textTransform: 'none',
                    fontSize: '12px',
                  }}
                >
                  Retry
                </ButtonLoading>
              </Box>
            </Alert>
          </Stack>
        </Paper>
      </StyledPopper>
    </div>
  );
}
