import { CubeDefinitionDetails } from 'types/api/SystemTenant/AROCube/CubeBuilderTypes';
import { CubeDataEntryAttribute, Data } from './CubeDataEntryTypes';
import { DropdownItem } from 'types/api/SystemTenant/AROKMS/TableDefinitionTypes';
import { KTreeTableItem } from '../AROKMS/KTreeDataTypes';

export interface DropdownItemWithType extends DropdownItem {
  type: string;
}

export interface ResponseCubeDimensionalView {
  id: string;
  cubeDefinition: CubeDefinitionDetails;
  attributes: CubeDataEntryAttribute[];
  columnGrouping: KTreeTableItem[];
  data: Data;
  yoptions: DropdownItemWithType[];
  xoptions: DropdownItemWithType[];
  selectedXOption: DropdownItemWithType;
  selectedYOption: DropdownItemWithType;
  todayTimePeriodIndex: number;
}

export interface RequestCubeDimensionalView {
  cubeId: string;
  layerId: string;
  filterValue: string;
  secondaryFilterValue: string;
  selectedYOptionId: string;
  selectedXOptionId: string;
  selectedYOptionType: string;
  selectedXOptionType: string;
}

export enum DIMENSION_CATEGORY {
  CUBE_DIMENSIONAL_VIEW = 'CUBE_DIMENSIONAL_VIEW',
  CUBE_INSIGHT_VIEW = 'CUBE_INSIGHT_VIEW',
}
