export const RULES_CONSTANT = {
  NUMBER: {
    RULE_DECIMAL_ADDITION: 'RULE_DECIMAL_ADDITION',
    RULE_DECIMAL_SUBTRACTION: 'RULE_DECIMAL_SUBTRACTION',
    RULE_DECIMAL_DIVISION: 'RULE_DECIMAL_DIVISION',
    RULE_DECIMAL_INPUT: 'RULE_DECIMAL_INPUT',
    RULE_DECIMAL_MULTIPLICATION: 'RULE_DECIMAL_MULTIPLICATION',
  },
  PERCENTAGE: {
    RULE_PERCENTAGE: 'RULE_PERCENTAGE',
    RULE_PERCENTAGE_INPUT: 'RULE_PERCENTAGE_INPUT',
    RULE_PERCENTAGE_ADDITION: 'RULE_PERCENTAGE_ADDITION',
    RULE_PERCENTAGE_DIVISION: 'RULE_PERCENTAGE_DIVISION',
    RULE_PERCENTAGE_MULTIPLICATION: 'RULE_PERCENTAGE_MULTIPLICATION',
    RULE_PERCENTAGE_SUBTRACTION: 'RULE_PERCENTAGE_SUBSTRACTION',
    RULE_CALCULATE_PERCENTAGE: 'RULE_CALCULATE_PERCENTAGE',
  },
  INTEGER: {
    RULE_INTEGER_ADDITION: 'RULE_INTEGER_ADDITION',
    RULE_INTEGER_DIVISION: 'RULE_INTEGER_DIVISION',
    RULE_INTEGER_SUBSTRACTION: 'RULE_INTEGER_SUBSTRACTION',
    RULE_INTEGER_MULTIPLICATION: 'RULE_INTEGER_MULTIPLICATION',
    RULE_INTEGER: 'RULE_INTEGER',
    RULE_INTEGER_RANGE: 'RULE_INTEGER_RANGE',
    RULE_INTEGER_COUNT_ROW: 'RULE_INTEGER_COUNT_ROW',
    RULE_INTEGER_MULTIPLICATION_WITH_PERCENTAGE: 'RULE_INTEGER_MULTIPLICATION_WITH_PERCENTAGE',
    RULE_INTEGER_PULL_NUMERIC: 'RULE_INTEGER_PULL_NUMERIC',
  },
  FUNCTION: {
    RULE_INTEGER_FUNCTION_DEPRICIATION: 'RULE_INTEGER_FUNCTION_DEPRICIATION',
  },
  CURRENCY: {
    RULE_CURRENCY: 'RULE_CURRENCY',
    RULE_CURRENCY_ADDITION: 'RULE_CURRENCY_ADDITION',
    RULE_CURRENCY_SUBSTRACTION: 'RULE_CURRENCY_SUBSTRACTION',
    RULE_CURRENCY_MULTIPLICATION: 'RULE_CURRENCY_MULTIPLICATION',
    RULE_CURRENCY_DIVISION: 'RULE_CURRENCY_DIVISION',
    RULE_CURRENCY_RANGE: 'RULE_CURRENCY_RANGE',
  },
  DATETIME: {
    RULE_DATE_TIME_PERIODS: 'RULE_DATE_TIME_PERIODS',
    RULE_DATE_TIME: 'RULE_DATE_TIME',
    RULE_DATE_TIME_INPUT: 'RULE_DATE_TIME_INPUT',
  },
  TEXT: {
    RULE_CONCATENATE_ATTRIBUTES: 'RULE_CONCATENATE_ATTRIBUTES',
    RULE_PULL_FROM_SUBJECT_ATTRIBUTE: 'RULE_PULL_FROM_SUBJECT_ATTRIBUTE',
    RULE_PULL_ATTRIBUTE: 'RULE_PULL_ATTRIBUTE',
    RULE_PULL_ATTRIBUTE_STATIC_TABLE: 'RULE_PULL_ATTRIBUTE_STATIC_TABLE',
    RULE_TEXT_GENERAL_SUBSTRING: 'RULE_TEXT_GENERAL_SUBSTRING',
    RULE_TEXT_GENERAL_SUBSTRING_STATIC_TABLE: 'RULE_TEXT_GENERAL_SUBSTRING_STATIC_TABLE',
    RULE_INPUT: 'RULE_INPUT',
    RULE_ATTRIBUTE_LEFT: 'RULE_ATTRIBUTE_LEFT',
    RULE_ATTRIBUTE_MID: 'RULE_ATTRIBUTE_MID',
    RULE_CHAR_LIMIT: 'RULE_CHAR_LIMIT',
    RULE_PULL_FROM_STATIC_TABLE: 'RULE_PULL_FROM_STATIC_TABLE',
    RULE_SELECT_FROM_KTREE_TABLE: 'RULE_SELECT_FROM_KTREE_TABLE',
    RULE_TEXT_COLUMN_AGGREGATE: 'RULE_TEXT_COLUMN_AGGREGATE',
    RULE_TEXT_IF_CONDITIONAL: 'RULE_TEXT_IF_CONDITIONAL',
  },
  DATE: {
    RULE_DATE_PERIODS: 'RULE_DATE_PERIODS',
    RULE_DATE_INPUT: 'RULE_DATE_INPUT',
    RULE_DATE_WEEKNUM: 'RULE_DATE_WEEKNUM',
    RULE_DATE_INPUT_NUM_TYPE: 'RULE_DATE_INPUT_NUM_TYPE',
  },
  ASSOCIATED_SUBJECT: {
    RULE_ASSOCIATED_SUBJECT_LIST: 'RULE_ASSOCIATED_SUBJECT_LIST',
    RULE_ASSOCIATED_SUBJECT_LIMITED_DROPDOWN_LIST: 'RULE_ASSOCIATED_SUBJECT_LIMITED_DROPDOWN_LIST',
  },
  COLOUR: {
    RULE_COLOUR_HEXA: 'RULE_COLOUR_HEXA',
  },
  GPS: {
    RULE_GPS: 'RULE_GPS',
  },
  MULTIMEDIA: {
    RULE_MULTIMEDIA: 'RULE_MULTIMEDIA',
  },
  GENERATED_SYSTEM: {
    RULE_GENERATED_SYSTEM_DATE: 'RULE_GENERATED_SYSTEM_DATE',
    RULE_GENERATED_SYSTEM_DATE_TIME: 'RULE_GENERATED_SYSTEM_DATE_TIME',
    RULE_GENERATED_SYSTEM_MODIFIER: 'RULE_GENERATED_SYSTEM_MODIFIER',
    RULE_SYSTEM_GENERATED_EVENT_TIMESTAMP: 'RULE_SYSTEM_GENERATED_EVENT_TIMESTAMP',
  },
  BASE_SUBJECT: {
    RULE_ATTRIBUTE_SUBJECT_BASE: 'RULE_ATTRIBUTE_SUBJECT_BASE',
  },
  SYSTEM_OPTIONS: {
    RULE_SYSTEM_OPTIONS_DEPRECIATION_TYPE: 'RULE_SYSTEM_OPTIONS_DEPRECIATION_TYPE',
  },
  CUBE: {
    RULE_CUBE_INPUT: 'RULE_CUBE_INPUT',
    RULE_CUBE_INPUT_INITIAL: 'RULE_CUBE_INPUT_INITIAL',
    RULE_CUBE_INPUT_BASE: 'RULE_CUBE_INPUT_BASE',
    RULE_CUBE_CALC_ATTRIBUTE: 'RULE_CUBE_CALC_ATTRIBUTE',
    RULE_CUBE_FUNCTION: 'RULE_CUBE_FUNCTION',
    RULE_CUBE_PULL_FROM_EVENT: 'RULE_CUBE_PULL_EVENT',
  },
  VIEW: {
    RULE_STANDARD_VIEW_DISPLAY_FROM_SUBJECT: 'RULE_STANDARD_VIEW_DISPLAY_FROM_SUBJECT',
    RULE_STANDARD_VIEW_DISPLAY_FROM_CUBE: 'RULE_STANDARD_VIEW_DISPLAY_FROM_CUBE',
  },
};

// Non editable rules, which means that the value of the field can not be edited
export const NON_EDITABLE_RULES = [
  RULES_CONSTANT.TEXT.RULE_PULL_ATTRIBUTE,
  RULES_CONSTANT.TEXT.RULE_CONCATENATE_ATTRIBUTES,
  RULES_CONSTANT.TEXT.RULE_PULL_ATTRIBUTE_STATIC_TABLE,
  RULES_CONSTANT.TEXT.RULE_ATTRIBUTE_LEFT,
  RULES_CONSTANT.TEXT.RULE_ATTRIBUTE_MID,
  RULES_CONSTANT.NUMBER.RULE_DECIMAL_ADDITION,
  RULES_CONSTANT.NUMBER.RULE_DECIMAL_SUBTRACTION,
  RULES_CONSTANT.NUMBER.RULE_DECIMAL_DIVISION,
  RULES_CONSTANT.NUMBER.RULE_DECIMAL_MULTIPLICATION,
  RULES_CONSTANT.PERCENTAGE.RULE_PERCENTAGE_ADDITION,
  RULES_CONSTANT.PERCENTAGE.RULE_PERCENTAGE_DIVISION,
  RULES_CONSTANT.PERCENTAGE.RULE_PERCENTAGE_MULTIPLICATION,
  RULES_CONSTANT.PERCENTAGE.RULE_PERCENTAGE_SUBTRACTION,
  RULES_CONSTANT.INTEGER.RULE_INTEGER_ADDITION,
  RULES_CONSTANT.INTEGER.RULE_INTEGER_DIVISION,
  RULES_CONSTANT.INTEGER.RULE_INTEGER_SUBSTRACTION,
  RULES_CONSTANT.INTEGER.RULE_INTEGER_MULTIPLICATION,
  RULES_CONSTANT.CURRENCY.RULE_CURRENCY_ADDITION,
  RULES_CONSTANT.CURRENCY.RULE_CURRENCY_SUBSTRACTION,
  RULES_CONSTANT.CURRENCY.RULE_CURRENCY_MULTIPLICATION,
  RULES_CONSTANT.CURRENCY.RULE_CURRENCY_DIVISION,
  RULES_CONSTANT.DATETIME.RULE_DATE_TIME,
  RULES_CONSTANT.DATETIME.RULE_DATE_TIME_PERIODS,
  RULES_CONSTANT.DATE.RULE_DATE_INPUT_NUM_TYPE,
  RULES_CONSTANT.DATE.RULE_DATE_PERIODS,
  RULES_CONSTANT.DATE.RULE_DATE_WEEKNUM,
  RULES_CONSTANT.GENERATED_SYSTEM.RULE_GENERATED_SYSTEM_DATE,
  RULES_CONSTANT.GENERATED_SYSTEM.RULE_GENERATED_SYSTEM_DATE_TIME,
];

// Allow empty value rules, which means that the value of the field can be empty
// this list intented to  generate yup validation schema
export const ALLOW_EMPTY_VALUE_RULES = [
  RULES_CONSTANT.INTEGER.RULE_INTEGER_COUNT_ROW,
  RULES_CONSTANT.PERCENTAGE.RULE_CALCULATE_PERCENTAGE,
  RULES_CONSTANT.GENERATED_SYSTEM.RULE_GENERATED_SYSTEM_DATE,
  RULES_CONSTANT.GENERATED_SYSTEM.RULE_GENERATED_SYSTEM_DATE_TIME,
  RULES_CONSTANT.GENERATED_SYSTEM.RULE_GENERATED_SYSTEM_MODIFIER,
  RULES_CONSTANT.TEXT.RULE_PULL_ATTRIBUTE,
  RULES_CONSTANT.TEXT.RULE_PULL_FROM_STATIC_TABLE,
  RULES_CONSTANT.TEXT.RULE_PULL_ATTRIBUTE_STATIC_TABLE,
  RULES_CONSTANT.INTEGER.RULE_INTEGER_PULL_NUMERIC,
  RULES_CONSTANT.INTEGER.RULE_INTEGER_MULTIPLICATION_WITH_PERCENTAGE,
  RULES_CONSTANT.TEXT.RULE_TEXT_IF_CONDITIONAL,
  RULES_CONSTANT.FUNCTION.RULE_INTEGER_FUNCTION_DEPRICIATION,
];

export const RULE_TYPE_DATE_FROM_SUBJECT = [
  RULES_CONSTANT.DATE.RULE_DATE_INPUT,
  RULES_CONSTANT.DATETIME.RULE_DATE_TIME_INPUT,
];

export const RULE_TYPE_PRICE_FROM_SUBJECT = [
  ...Object.values(RULES_CONSTANT.CURRENCY),
  ...Object.values(RULES_CONSTANT.NUMBER),
  RULES_CONSTANT.INTEGER.RULE_INTEGER,
  RULES_CONSTANT.INTEGER.RULE_INTEGER_ADDITION,
  RULES_CONSTANT.INTEGER.RULE_INTEGER_DIVISION,
  RULES_CONSTANT.INTEGER.RULE_INTEGER_SUBSTRACTION,
  RULES_CONSTANT.INTEGER.RULE_INTEGER_MULTIPLICATION,
  RULES_CONSTANT.INTEGER.RULE_INTEGER_MULTIPLICATION_WITH_PERCENTAGE,
];

export const RULE_TYPE_NUMBER_FROM_SUBJECT = [
  ...Object.values(RULES_CONSTANT.NUMBER),
  RULES_CONSTANT.INTEGER.RULE_INTEGER,
  RULES_CONSTANT.INTEGER.RULE_INTEGER_ADDITION,
  RULES_CONSTANT.INTEGER.RULE_INTEGER_DIVISION,
  RULES_CONSTANT.INTEGER.RULE_INTEGER_SUBSTRACTION,
  RULES_CONSTANT.INTEGER.RULE_INTEGER_MULTIPLICATION,
  RULES_CONSTANT.INTEGER.RULE_INTEGER_MULTIPLICATION_WITH_PERCENTAGE,
  RULES_CONSTANT.INTEGER.RULE_INTEGER_COUNT_ROW,
  RULES_CONSTANT.INTEGER.RULE_INTEGER_RANGE,
];

export const RULE_TYPE_PERCENTAGE_FROM_SUBJECT = [...Object.values(RULES_CONSTANT.PERCENTAGE)];

export const VIEW_RULE_TYPE_DROPDOWN_OPTIONS = [
  {
    label: 'Display from Subject',
    value: RULES_CONSTANT.VIEW.RULE_STANDARD_VIEW_DISPLAY_FROM_SUBJECT,
  },
  {
    label: 'Display from Cube',
    value: RULES_CONSTANT.VIEW.RULE_STANDARD_VIEW_DISPLAY_FROM_CUBE,
  },
];

export const RULE_DATA_TYPE_DATE_TIME = new Set([
  RULES_CONSTANT.GENERATED_SYSTEM.RULE_GENERATED_SYSTEM_DATE,
  RULES_CONSTANT.GENERATED_SYSTEM.RULE_GENERATED_SYSTEM_DATE_TIME,
  RULES_CONSTANT.GENERATED_SYSTEM.RULE_SYSTEM_GENERATED_EVENT_TIMESTAMP,
  RULES_CONSTANT.DATE.RULE_DATE_INPUT,
]);
