import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import ButtonLoading from '@mui/lab/LoadingButton';
import { SvgIcon } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { getDropRejectErrorCode, getDropFileErrorMessageByCode, getErrorMessage } from 'utils/Error';

// Custom Icon
import { ReactComponent as CustomIconTrash } from 'assets/icons/icon-trash.svg';
import HighlightOffRounded from '@mui/icons-material/HighlightOffRounded';
import { useUploadDataInputImportFile } from 'services/v1/Tenant/AROKMS/DisplayTableService';
import { SubjectDetails } from 'types/api/SystemTenant/AROKMS/SubjectTypes';
import { useUploadDataEventImportFile } from 'services/v1/Tenant/AROEvent/EventTableService';
import { EVENT_TABLE_IMPORT_OPERATION_TYPE } from 'constant/DataInputConstant';

const ModalContentStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  borderRadius: 1,
  px: 3,
  py: 2,
};

const buttonActionStyle = { width: '100%' };

const closeIconStyle = { color: '#98A2AE', cursor: 'pointer' };

interface ModalImportDataInputProps {
  visible?: boolean;
  onClose: () => void;
  onCancel?: () => void;
  onImportSuccess: () => void;
  title?: string;
  message?: string;
  isError?: boolean;
  parameter?: { [key: string]: string | number | boolean };
  errorMessage?: string;
  category: string;
  fileType?: string;
  subject?: SubjectDetails;
  subjectType: 'SUBJECT' | 'EVENT';
  withSyncCubeCheckbox?: boolean;
}

export type ModalDataInputState = {
  title?: string;
  message: string;
  fileType: string;
  open: boolean;
};
export function ModalImportDataInput(props: Readonly<ModalImportDataInputProps>) {
  const { t } = useTranslation();
  const {
    onImportSuccess,
    onClose,
    visible = false,
    subject,
    fileType = 'EXCEL',
    subjectType,
    withSyncCubeCheckbox = false,
  } = props;

  const {
    reset: resetImporting,
    mutate: importData,
    isLoading: isImporting,
    isError: isErrorImporting,
  } = useUploadDataInputImportFile();

  const {
    reset: resetImportingEvent,
    mutate: importDataEvent,
    isLoading: isImportingEvent,
    isError: isErrorImportingEvent,
  } = useUploadDataEventImportFile();

  const [fileBuffer, setFileBuffer] = useState<File | null>(null);
  const [fileError, setFileError] = useState<string | null>(null);
  const [syncImportWithCube, setSyncImportWithCube] = useState(withSyncCubeCheckbox);
  const [operationType, setOperationType] = useState(EVENT_TABLE_IMPORT_OPERATION_TYPE.APPEND_DATA);

  const onDrop = useCallback((acceptedFiles) => {
    setFileError(null);
    if (acceptedFiles.length > 0) {
      setFileBuffer(acceptedFiles[0]);
      const formData = new FormData();
      formData.append('file', acceptedFiles[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDropRejected = useCallback((rejectedFiles) => {
    setFileBuffer(null);
    setFileError(getDropFileErrorMessageByCode(getDropRejectErrorCode(rejectedFiles[0])));
  }, []);

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    onDrop,
    onDropRejected,
    multiple: false,
    maxFiles: 1,
    accept:
      fileType === 'EXCEL'
        ? {
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
            'application/vnd.ms-excel': ['.xls'],
          }
        : {
            'text/csv': ['.csv'],
          },
  });

  const resetAllState = () => {
    setFileBuffer(null);
    resetImporting();
    resetImportingEvent();
    setSyncImportWithCube(withSyncCubeCheckbox);
    setOperationType(EVENT_TABLE_IMPORT_OPERATION_TYPE.APPEND_DATA);
    acceptedFiles.length = 0;
  };

  const handleCloseModal = () => {
    resetAllState();
    onClose();
  };

  const handleOnStartImport = () => {
    const formData = new FormData();
    formData.append('file', fileBuffer as Blob);
    if (subject?.id) {
      if (subjectType === 'EVENT') {
        importDataEvent(
          {
            formData,
            subjectId: subject.id?.toString(),
            fileType,
            withSyncCube: syncImportWithCube,
            operationType: operationType,
          },
          {
            onSuccess: () => {
              handleCloseModal();
              onImportSuccess();
            },
          }
        );
      }
      if (subjectType === 'SUBJECT') {
        importData(
          { formData, subjectId: subject.id?.toString(), fileType },
          {
            onSuccess: () => {
              handleCloseModal();
              onImportSuccess();
            },
            onError: (err) => {
              setFileError(getErrorMessage(err));
            },
          }
        );
      }
    }
  };

  const handleOnDeleteFile = () => {
    resetAllState();
  };

  return (
    <div>
      <Modal open={visible}>
        <Stack direction='column' sx={ModalContentStyle}>
          {/* ModalComponent Header (title and button close) */}
          <Stack justifyContent='space-between' direction='row' alignItems='center' spacing={2}>
            <Typography variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
              {`${t('COMMON.MODAL_IMPORT.TITLE')} - ${subject?.displayName}`}
            </Typography>
            <IconButton onClick={handleCloseModal} component='label'>
              <HighlightOffRounded sx={closeIconStyle} />
            </IconButton>
          </Stack>
          <Divider sx={{ mb: 1 }} />

          {!fileBuffer && (
            <Stack alignItems='center' sx={{ py: 2, bgcolor: '#F7F7F7', my: 1 }}>
              <Typography variant='body2'>
                {fileType === 'CSV'
                  ? t('COMMON.MODAL_IMPORT.INSTRUCTION_CSV')
                  : t('COMMON.MODAL_IMPORT.INSTRUCTION_EXCEL')}
              </Typography>
            </Stack>
          )}

          {/* Upload file Area */}

          <Stack alignItems='center' sx={{ py: 1 }}>
            {!fileBuffer && (
              <>
                <div {...getRootProps({ className: 'dropzone' })}>
                  <Button
                    endIcon={<AddCircleOutlineOutlinedIcon />}
                    variant='main-table-panel'
                    sx={{ width: '35%', mr: 1 }}
                  >
                    {t('COMMON.MODAL_IMPORT.BUTTON_UPLOAD')}
                  </Button>

                  <p>
                    {fileType === 'CSV'
                      ? t('COMMON.MODAL_IMPORT.UPLOAD_AREA_CSV')
                      : t('COMMON.MODAL_IMPORT.UPLOAD_AREA_EXCEL')}
                  </p>
                  <input {...getInputProps()} />
                </div>
              </>
            )}
            {fileBuffer && (
              <>
                <div {...getRootProps({ className: 'dropzone' })}>
                  <img
                    src='/images/excel-icon.png'
                    alt='Excel File'
                    style={{
                      width: '25px',
                      height: 'auto',
                    }}
                  />
                  <p className='file-name'>{fileBuffer?.name}</p>
                  <IconButton size='small' onClick={handleOnDeleteFile}>
                    <SvgIcon fontSize='small' sx={{ fill: '#42BB93' }} inheritViewBox component={CustomIconTrash} />
                  </IconButton>
                </div>
                {fileError && (
                  <Typography variant='body2' sx={{ color: '#BB4942', mt: 1 }}>
                    {fileError}
                  </Typography>
                )}
              </>
            )}
          </Stack>

          {fileBuffer && withSyncCubeCheckbox && subjectType === 'EVENT' && (
            <Stack
              sx={{
                bgcolor: '#FBFBFB',
                py: 2,
                px: 2,
                borderTop: '1px solid #E3EBF6',
                borderBottom: '1px solid #E3EBF6',
              }}
            >
              <FormControl>
                <FormLabel id='operationType-label'>
                  <Typography variant='modal-title' fontWeight='bold'>
                    Operation Type
                  </Typography>
                </FormLabel>
                <RadioGroup
                  row
                  name='operationType'
                  value={operationType}
                  onChange={(e) => setOperationType(e.target.value)}
                >
                  <FormControlLabel
                    value={EVENT_TABLE_IMPORT_OPERATION_TYPE.APPEND_DATA}
                    control={<Radio size='small' />}
                    label={<Typography variant='input-label-gray'>Append to existing data</Typography>}
                  />
                  <FormControlLabel
                    value={EVENT_TABLE_IMPORT_OPERATION_TYPE.OVERWRITE_DATA}
                    control={<Radio size='small' />}
                    label={<Typography variant='input-label-gray'>Replace existing data</Typography>}
                  />
                </RadioGroup>
              </FormControl>
            </Stack>
          )}

          {/* Footer ButtonComponent Action */}
          <Divider sx={{ mb: 1, mt: 1 }} />
          <Stack direction='row' justifyContent='center' spacing={2} alignItems='center' sx={{ py: 1 }}>
            <Button
              disabled={isImporting || isImportingEvent}
              variant='main-table-panel-border'
              sx={buttonActionStyle}
              onClick={handleCloseModal}
            >
              {t('COMMON.BUTTON.CANCEL')}
            </Button>
            <ButtonLoading
              loading={isImporting || isImportingEvent}
              disabled={isErrorImporting || isErrorImportingEvent || !fileBuffer}
              variant='main-table-panel'
              onClick={handleOnStartImport}
              sx={buttonActionStyle}
            >
              {t('COMMON.MODAL_IMPORT.START_IMPORT')}
            </ButtonLoading>
          </Stack>
        </Stack>
      </Modal>
    </div>
  );
}
