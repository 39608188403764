import React, { useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import LinkIcon from '@mui/icons-material/Link';
import Slide from '@mui/material/Slide';
import { Stack, Divider } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { SubjectItem } from 'types/api/SystemTenant/AROKMS/SubjectTypes';
import { ChannelList } from './ChannelList';
import { EventDataChannel } from 'types/api/Tenant/EventDataSourceTypes';
import { PairingColumns } from './PairingColumns';
import { useGetEventDataSourceConfigDetails } from 'services/v1/Tenant/AROEvent/EventDataSourceService';

export type ModalMappingEventDataSourceProps = {
  onClose?: () => void;
  isEdit: boolean;
  eventDataSourceConfigId?: string | null;
  open: boolean;
  eventSubject?: SubjectItem;
};

export enum DATA_CHANNEL_INTEGRATION_STEP {
  SELECT_CHANNEL,
  MAPPING,
  PREVIEW,
}

const closeIconStyle = { color: '#98A2AE', cursor: 'pointer' };
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />;
});

export function ModalConnectDataChannel(props: ModalMappingEventDataSourceProps) {
  const { onClose, open = false, eventSubject, isEdit } = props;

  const [currentStep, setCurrentStep] = React.useState<DATA_CHANNEL_INTEGRATION_STEP>(
    DATA_CHANNEL_INTEGRATION_STEP.SELECT_CHANNEL
  );
  const [selectedChannel, setSelectedChannel] = React.useState<EventDataChannel | null>(null);
  const { data: eventDataSourceConfigDetails, remove } = useGetEventDataSourceConfigDetails(
    eventSubject?.id?.toString()
  );

  const handleOnCompleteSelectChannel = (selectedChannel: EventDataChannel) => {
    setSelectedChannel(selectedChannel);
    setCurrentStep(DATA_CHANNEL_INTEGRATION_STEP.MAPPING);
  };

  const handleOnClose = () => {
    onClose?.();
    setCurrentStep(DATA_CHANNEL_INTEGRATION_STEP.SELECT_CHANNEL);
    remove();
  };

  useEffect(() => {
    if (eventDataSourceConfigDetails?.data && open) {
      setCurrentStep(DATA_CHANNEL_INTEGRATION_STEP.MAPPING);
      setSelectedChannel(eventDataSourceConfigDetails?.data?.dataChannel);
    }
  }, [eventDataSourceConfigDetails?.data, open]);

  return (
    <Dialog fullScreen open={open} onClose={handleOnClose} TransitionComponent={Transition}>
      <Stack px={3} py={1} justifyContent='space-between' direction='row' alignItems='center'>
        <Stack direction='row' gap={1} alignItems='center'>
          <LinkIcon
            sx={{
              color: '#42BB93',
            }}
          />
          <Stack>
            <Typography variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
              {isEdit ? 'Edit Data Channel Connection' : 'Connect a Data Channel'}
            </Typography>
          </Stack>
        </Stack>
        <IconButton onClick={handleOnClose} component='label'>
          <HighlightOffRoundedIcon sx={closeIconStyle} />
        </IconButton>
      </Stack>
      <Divider />
      {currentStep === DATA_CHANNEL_INTEGRATION_STEP.SELECT_CHANNEL && (
        <ChannelList eventSubject={eventSubject} onSelectedChannel={handleOnCompleteSelectChannel} />
      )}
      {currentStep === DATA_CHANNEL_INTEGRATION_STEP.MAPPING && selectedChannel && (
        <PairingColumns
          isEdit={isEdit}
          dataChannel={selectedChannel}
          eventSubject={eventSubject}
          onClose={handleOnClose}
        />
      )}
    </Dialog>
  );
}
