export const DASHBOARD_TYPE_CONSTANT = {
  DASHBOARD_MASTER: 'DASHBOARD_MASTER',
  DASHBOARD_TENANT: 'DASHBOARD_TENANT',
  DASHBOARD_SYSTEM: 'DASHBOARD_SYSTEM',
};

// This is just random UUIDs to make it harder to guess for attackers
export const DASHBOARD_VIEW_MODE = {
  DATA_CHANGE: 'ed9784f2-f4eb-436c-b2ac-d39190738e68',
  VIEW_ONLY: '9f459486-dce7-4c19-a8e4-cae2c11c0fec',
};
