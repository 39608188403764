import { AxiosResponse } from 'axios';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import axios from 'libs/Axios';

import { AxiosDefaultErrorEntity } from 'types/api/Common/ErrorTypes';
import {
  MyViewItemConfig,
  PeopleConfig,
  PeopleMyViewItemConfig,
  PersonalizationConfig,
  QuickAccessItem,
  TenantConfiguration,
} from 'types/api/Tenant/ConfigurationTypes';
import { KTreeNode } from 'types/api/Tenant/AROKMS/KTreeDataTypes';
import { createTreeData, sortTreeData } from 'utils/Object';
import { ENV_CONFIG } from 'config/env';
import { AutoCompleteItem } from 'types/api/Common/AutoCompleteTypes';

export const TENANT_CONFIGURATION_DATA_QUERY = {
  CONFIGURATION_LIST: 'CONFIGURATION_LIST',
  PEOPLE_CONFIG_LIST: 'PEOPLE_CONFIG_LIST',
  ALL_MY_VIEW_ITEMS: 'ALL_MY_VIEW_ITEMS',
  PEOPLE_MY_VIEW_ITEMS: 'PEOPLE_MY_VIEW_ITEMS',
  MY_PERSONALZIATION_CONFIG: 'MY_PERSONALZIATION_CONFIG',
  MY_VIEW_DETAILS: 'MY_VIEW_DETAILS',
  MY_VIEW_MEMBER: 'MY_VIEW_MEMBER',
  ADDITIONAL_WORKFLOW_DROPDOWN: 'ADDITIONAL_WORKFLOW_DROPDOWN',
};

export function useGetTenantConfigData({
  refetchInterval = false,
  enabled = true,
}: {
  refetchInterval: number | false;
  enabled?: boolean;
}) {
  return useQuery<AxiosResponse<TenantConfiguration[]>, AxiosDefaultErrorEntity>(
    [TENANT_CONFIGURATION_DATA_QUERY.CONFIGURATION_LIST],
    () => axios.get(`/api/v1/tenant/config/list`).then((res) => res),
    {
      refetchInterval,
      enabled: enabled,
    }
  );
}

export function useGetPeopleConfigList() {
  return useQuery<AxiosResponse<PeopleConfig[]>, AxiosDefaultErrorEntity>(
    [TENANT_CONFIGURATION_DATA_QUERY.PEOPLE_CONFIG_LIST],
    () => axios.get(`/api/v1/tenant/config/people`).then((res) => res)
  );
}

export function useGetAllMyViewItemList() {
  return useQuery<AxiosResponse<MyViewItemConfig[]>, AxiosDefaultErrorEntity>(
    [TENANT_CONFIGURATION_DATA_QUERY.ALL_MY_VIEW_ITEMS],
    () => axios.get(`/api/v1/tenant/config/people/my-view-items`).then((res) => res)
  );
}

export function useGetMyPersonalization({ enabled = true }: { enabled?: boolean }) {
  return useQuery<AxiosResponse<PersonalizationConfig>, AxiosDefaultErrorEntity>(
    [TENANT_CONFIGURATION_DATA_QUERY.MY_PERSONALZIATION_CONFIG],
    () => axios.get(`/api/v1/tenant/config/personalization`).then((res) => res),
    {
      cacheTime: ENV_CONFIG.endpoint.MAXIMUM_CACHE_TIME,
      staleTime: ENV_CONFIG.endpoint.MAXIMUM_CACHE_TIME,
    }
  );
}

export function useGetMyViewDetails(myViewId: string | null) {
  return useQuery<AxiosResponse<QuickAccessItem>, AxiosDefaultErrorEntity>(
    [TENANT_CONFIGURATION_DATA_QUERY.MY_VIEW_DETAILS, myViewId],
    () => axios.get(`/api/v1/tenant/config/my-view/${myViewId}`).then((res) => res),
    {
      enabled: !!myViewId,
      cacheTime: ENV_CONFIG.endpoint.MAXIMUM_CACHE_TIME,
      staleTime: ENV_CONFIG.endpoint.MAXIMUM_CACHE_TIME,
    }
  );
}

export function useGetMyPersonalizationLogin() {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse<PersonalizationConfig>, AxiosDefaultErrorEntity>(
    () => axios.get(`/api/v1/tenant/config/personalization`).then((res) => res),
    {
      onSuccess: (response) => {
        queryClient.setQueryData([TENANT_CONFIGURATION_DATA_QUERY.MY_PERSONALZIATION_CONFIG], response);
      },
    }
  );
}

export function useSavePeopleMyViewConfig() {
  const queryClient = useQueryClient();
  return useMutation<
    AxiosResponse<TenantConfiguration[]>,
    AxiosDefaultErrorEntity,
    {
      userId: string;
      myViewItems: {
        id: string;
        componentType: string;
        subjectId?: string | null;
      }[];
    }
  >(
    (bodyRequest) =>
      axios
        .post(`/api/v1/tenant/config/people/my-view/${bodyRequest.userId}`, bodyRequest.myViewItems)
        .then((res) => res),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(TENANT_CONFIGURATION_DATA_QUERY.PEOPLE_MY_VIEW_ITEMS);
      },
    }
  );
}

export function useUpdateDefaultDimensionFilter() {
  const queryClient = useQueryClient();
  return useMutation<
    AxiosResponse,
    AxiosDefaultErrorEntity,
    {
      myViewId: string;
      defaultXDimension: string | null;
      defaultYDimension: string | null;
      defaultFilterMember: string | null;
      memberId: string | null;
      additionalWorkFlows: string[];
    }
  >(
    (bodyRequest) =>
      axios.post(`/api/v1/tenant/config/people/my-view/additional-config`, bodyRequest).then((res) => res),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(TENANT_CONFIGURATION_DATA_QUERY.PEOPLE_MY_VIEW_ITEMS);
      },
    }
  );
}

export function useUpdateUserDefaultLoginView() {
  const queryClient = useQueryClient();
  return useMutation<
    AxiosResponse,
    AxiosDefaultErrorEntity,
    {
      myViewId: string;
      userId: string;
    }
  >(
    (bodyRequest) =>
      axios.post(`/api/v1/tenant/config/people/my-view/update-default-login`, bodyRequest).then((res) => res),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(TENANT_CONFIGURATION_DATA_QUERY.PEOPLE_MY_VIEW_ITEMS);
      },
    }
  );
}

export function useGetMyViewConfigUser(userId?: string) {
  return useQuery<AxiosResponse<PeopleMyViewItemConfig[]>, AxiosDefaultErrorEntity>(
    [TENANT_CONFIGURATION_DATA_QUERY.PEOPLE_MY_VIEW_ITEMS, userId],
    () => axios.get(`/api/v1/tenant/config/people/my-view/${userId}`).then((res) => res),
    {
      enabled: !!userId,
    }
  );
}

export function useGetMyViewMember(myViewId?: string | null) {
  return useQuery<KTreeNode[], AxiosDefaultErrorEntity>(
    [TENANT_CONFIGURATION_DATA_QUERY.MY_VIEW_MEMBER, myViewId],
    () =>
      axios.get(`/api/v1/tenant/config/people/my-view-/component-member/${myViewId}`).then((res) => {
        if (res.data.length > 0) {
          const treeData = createTreeData(null, res.data);
          return sortTreeData(treeData);
        }
        return null;
      }),
    {
      enabled: !!myViewId,
    }
  );
}

export function useGetAdditionalWorkFlowsDropdown() {
  return useQuery<AxiosResponse<AutoCompleteItem[]>, AxiosDefaultErrorEntity>(
    [TENANT_CONFIGURATION_DATA_QUERY.ADDITIONAL_WORKFLOW_DROPDOWN],
    () => axios.get(`/api/v1/tenant/config/people/my-view/additional-workflow`).then((res) => res)
  );
}

export function useGetTenantConfigDataMutation() {
  return useMutation<AxiosResponse<TenantConfiguration[]>, AxiosDefaultErrorEntity>(() =>
    axios.get(`/api/v1/tenant/config/list`).then((res) => res)
  );
}

export function useSaveTenantConfigData() {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse<TenantConfiguration[]>, AxiosDefaultErrorEntity, TenantConfiguration[]>(
    (data) => axios.post(`/api/v1/tenant/config/save`, data).then((res) => res),
    {
      onSettled: () => {
        queryClient.invalidateQueries(TENANT_CONFIGURATION_DATA_QUERY.CONFIGURATION_LIST);
      },
    }
  );
}

export function useSaveInitialTenantConfigData() {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse<TenantConfiguration[]>, AxiosDefaultErrorEntity, TenantConfiguration[]>(
    (data) => axios.post(`/api/v1/tenant/config/initial-setup`, data).then((res) => res),
    {
      onSettled: () => {
        queryClient.invalidateQueries(TENANT_CONFIGURATION_DATA_QUERY.CONFIGURATION_LIST);
      },
    }
  );
}
