import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AutoCompleteItem } from 'types/api/Common/AutoCompleteTypes';

interface SubjectAutoCompleteItem extends AutoCompleteItem {
  type?: string;
}

export interface NavigationStateValue {
  subject: SubjectAutoCompleteItem | null;
  ktree: AutoCompleteItem | null;
  event: AutoCompleteItem | null;
  cube: AutoCompleteItem | null;
  view: AutoCompleteItem | null;
  insight: AutoCompleteItem | null;
  dashboard: AutoCompleteItem | null;
  'subject-procedure': AutoCompleteItem | null;
}

export interface HomeState {
  navigationState: NavigationStateValue;
}

export const initialStateHomeReducer: HomeState = {
  navigationState: {
    cube: null,
    event: null,
    ktree: null,
    subject: null,
    insight: null,
    dashboard: null,
    view: null,
    'subject-procedure': null,
  },
};

export const homeSlice = createSlice({
  name: '@USER_HOME',
  initialState: initialStateHomeReducer,
  reducers: {
    setHomeNavigationState: (state, action: PayloadAction<Partial<NavigationStateValue>>) => {
      state.navigationState = { ...state.navigationState, ...action.payload };
    },
    resetHomeNavigatinState: (state) => {
      state.navigationState = initialStateHomeReducer.navigationState;
    },
  },
});

export const { setHomeNavigationState, resetHomeNavigatinState } = homeSlice.actions;

export default homeSlice.reducer;
