import { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Formik } from 'formik';
import dayjs from 'dayjs';
import Grid from '@mui/material/Grid';
import ButtonLoading from '@mui/lab/LoadingButton';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Avatar from '@mui/material/Avatar';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { getInitialsName } from 'utils/String';
import { InitialSetupValidationSchema } from 'config/FormValidaton/AuthValidationSchema';
import { RootState } from 'store';
import { DAY_OPTIONS, MONTH_OPTIONS } from 'constant/OptionsConstant';
import { TENANT_CONFIGURATION_KEY, TENANT_INITIAL_SETUP_STATUS } from 'constant/TenantConfigConstant';
import { useSaveInitialTenantConfigData, useGetTenantConfigData } from 'services/v1/Tenant/ConfigurationService';
import { ErrorInitialSetup } from './components/ErrorSetupComponent';
import { CompletedInitialSetup } from './components/CompletedSetupComponent';
import { LoadingSetupComponent } from './components/LoadingSetupComponent';
import { useDispatch } from 'react-redux';
import { setSetupStatus } from 'store/reducer/settingReducer';
import { AutoCompleteItem } from 'types/api/Common/AutoCompleteTypes';
import { TenantConfiguration } from 'types/api/Tenant/ConfigurationTypes';
import { Link } from '@mui/material';
import { FOOTER_MENU_OPTIONS } from 'constant/NavigationConstant';

interface formValues {
  [key: string]: AutoCompleteItem | null;
}

const formInitialValues: formValues = {
  [TENANT_CONFIGURATION_KEY.CONFIG_INITIAL_FINANCIAL_YEAR]: MONTH_OPTIONS[6],
  [TENANT_CONFIGURATION_KEY.CONFIG_END_DAY_OF_WEEK]: DAY_OPTIONS[0],
};

const textInputStyles = {
  '& .MuiOutlinedInput': {
    padding: '1px 2px',
  },
};

const gridBannerStyle = {
  backgroundImage: `url('/images/auth-banner.jpg')`,
  backgroundRepeat: 'no-repeat',
  backgroundBlendMode: 'color',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  height: '100%',
};

const greetingBoldStyle = {
  marginBottom: 1,
  fontWeight: '500',
};

const gridContentContainerStyle = {
  backgroundColor: '#F8FAFF',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
};

const contentContainerStyle = {
  my: 5,
  mx: 4,
  px: 4,
  py: 4,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: '#fff',
  borderRadius: '7px',
  boxShadow: '0px 1px 6px #C8CFE2',
};

const dropdownHelperTextStyle = {
  color: '#3B4797',
  fontWeight: '600',
};

export function InitialSetupPage(): JSX.Element {
  const { selectedTenant, profile } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();
  const { mutate: saveInitialTenantConfig, isLoading: isSubmitting } = useSaveInitialTenantConfigData();
  const [initialSetupStatus, setInitialSetupStatus] = useState<string | null>(null);
  const [initialSetupErrorMessage, setInitialSetupErrorMessage] = useState<string | null>(null);
  const { data: tenantConfigData } = useGetTenantConfigData({
    refetchInterval: initialSetupStatus === TENANT_INITIAL_SETUP_STATUS.ON_PROGRESS ? 5000 : 0,
  });
  const formikRef = useRef<any>(null);

  useEffect(() => {
    if (tenantConfigData?.data) {
      const setupStatus =
        tenantConfigData.data.find((item) => item.id === TENANT_CONFIGURATION_KEY.INITIAL_SETUP_STATUS)?.value || null;
      const setupErrorMessage =
        tenantConfigData.data.find((item) => item.id === TENANT_CONFIGURATION_KEY.INITIAL_SETUP_ERROR)?.value ||
        setInitialSetupStatus(setupStatus);

      if (setupStatus === TENANT_INITIAL_SETUP_STATUS.ERROR && setupErrorMessage) {
        setInitialSetupErrorMessage(setupErrorMessage);
      }

      setupStatus && dispatch(setSetupStatus(setupStatus));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenantConfigData]);

  const handleSubmit = async (data: formValues) => {
    const payload = Object.keys(data).map((key) => {
      if (key === TENANT_CONFIGURATION_KEY.CONFIG_INITIAL_FINANCIAL_YEAR) {
        const month = data[key]?.value || 1;
        return {
          id: key,
          value: dayjs()
            .set('month', +month - 1)
            .format('YYYY-MM-DD'),
        };
      }

      return {
        id: key,
        value: data[key]?.value || '',
      };
    });
    saveInitialTenantConfig(payload as TenantConfiguration[], {});
  };

  return (
    <Grid container component='main' sx={{ height: '100vh' }}>
      <CssBaseline />
      <Grid
        item
        display='flex'
        flexDirection='column'
        paddingBottom={3}
        justifyContent='space-between'
        xs={5}
        sx={gridBannerStyle}
      >
        <Box width='100%' justifyContent='center' display='flex' sx={{ marginTop: 15 }}>
          <Stack direction='column' width='75%'>
            <Stack display='flex' justifyContent='center' flexDirection='row' mb={2}>
              <Avatar sx={{ bgcolor: '#00B3DC', width: 89, height: 89, fontSize: 37 }}>
                {getInitialsName(profile.name)}
              </Avatar>
            </Stack>
            <Typography component='h2' variant='h5' color='#fff' sx={greetingBoldStyle} textAlign='center'>
              {`Welcome ${profile.name}`}
            </Typography>
            <Typography color='#fff' sx={greetingBoldStyle} textAlign='center'>
              {`You are currently logged in as ${selectedTenant.tenant.roleName}`}
            </Typography>

            <Typography component='p' variant='subtitle1' color='#fff'></Typography>
          </Stack>
        </Box>
        <Box width='100%' justifyContent='center' display='flex'>
          <Stack
            direction='row'
            width='75%'
            alignItems='center'
            justifyContent='flex-start'
            gap={3}
            height={80}
            divider={<Divider orientation='vertical' flexItem color='#fff' />}
          >
            <Stack direction='row' gap={1} mt={2}>
              <div>
                <img
                  src='/images/jazanz_logo.jpg'
                  alt='Jas ANZ Logo'
                  style={{
                    width: 'auto',
                    height: 60,
                  }}
                />
              </div>
              <div>
                <img
                  src='/images/iso_9001_certified.svg'
                  alt='ISO 9001 Certified'
                  style={{
                    background: 'white',
                    width: 'auto',
                    height: 60,
                  }}
                />
              </div>
            </Stack>
            <Stack>
              <Stack direction='row' spacing={2}>
                {FOOTER_MENU_OPTIONS.map((menu) => (
                  <Link
                    href={menu.link}
                    underline='hover'
                    target='_blank'
                    sx={{
                      color: '#fff',
                      fontSize: 12,
                      fontWeight: 'bold',
                      '&:hover': {
                        color: '#fff',
                      },
                    }}
                  >
                    {menu.label}
                  </Link>
                ))}
              </Stack>
              <Typography component='p' variant='subtitle1' color='#fff' sx={{ fontSize: 10, marginTop: 1 }}>
                {`©️ ${dayjs().year()} ARO Forecasting Pty Ltd All Rights Reserved.`}
              </Typography>
            </Stack>
          </Stack>
        </Box>
      </Grid>

      <Grid item xs={7} sx={gridContentContainerStyle}>
        {initialSetupStatus === TENANT_INITIAL_SETUP_STATUS.ON_PROGRESS && <LoadingSetupComponent />}
        {initialSetupStatus === TENANT_INITIAL_SETUP_STATUS.ERROR && (
          <ErrorInitialSetup selectedTenant={selectedTenant} error={initialSetupErrorMessage} />
        )}
        {initialSetupStatus === TENANT_INITIAL_SETUP_STATUS.COMPLETED && (
          <CompletedInitialSetup selectedTenant={selectedTenant} />
        )}

        {initialSetupStatus === TENANT_INITIAL_SETUP_STATUS.INITIAL && (
          <Box width={500} sx={contentContainerStyle}>
            <Box width={400} display='flex' justifyContent='space-between'>
              <Typography
                component='h5'
                variant='h5'
                color='#394798'
                sx={{
                  marginBottom: 3,
                }}
              >
                Initial Setup
              </Typography>

              <div>
                <img
                  src='/images/main-logo.svg'
                  alt='logo'
                  style={{
                    width: '100px',
                    height: 'auto',
                  }}
                />
              </div>
            </Box>
            <Box width={400}>
              <Typography component='h4' variant='h4' color='#2C2A29' mb={4}>
                Please setup Initial Financial Year
              </Typography>

              <Formik
                innerRef={formikRef}
                initialValues={formInitialValues}
                onSubmit={handleSubmit}
                validationSchema={InitialSetupValidationSchema}
              >
                {({ handleBlur, setFieldValue, handleSubmit, touched, errors, values }) => (
                  <form
                    onSubmit={handleSubmit}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleSubmit();
                      }
                    }}
                  >
                    <FormHelperText sx={dropdownHelperTextStyle}>
                      Select Initial Financial Year Start Month
                    </FormHelperText>
                    <FormControl fullWidth margin='dense'>
                      <Autocomplete
                        options={MONTH_OPTIONS}
                        getOptionLabel={(option) => option.label}
                        value={values[TENANT_CONFIGURATION_KEY.CONFIG_INITIAL_FINANCIAL_YEAR]}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            sx={textInputStyles}
                            name={TENANT_CONFIGURATION_KEY.CONFIG_INITIAL_FINANCIAL_YEAR}
                            onBlur={handleBlur}
                            error={
                              touched[TENANT_CONFIGURATION_KEY.CONFIG_INITIAL_FINANCIAL_YEAR] &&
                              !!errors[TENANT_CONFIGURATION_KEY.CONFIG_INITIAL_FINANCIAL_YEAR]
                            }
                            helperText={
                              touched[TENANT_CONFIGURATION_KEY.CONFIG_INITIAL_FINANCIAL_YEAR] &&
                              errors[TENANT_CONFIGURATION_KEY.CONFIG_INITIAL_FINANCIAL_YEAR]
                            }
                          />
                        )}
                        onChange={(e, selectedValue) => {
                          setFieldValue(TENANT_CONFIGURATION_KEY.CONFIG_INITIAL_FINANCIAL_YEAR, selectedValue);
                        }}
                      />
                    </FormControl>

                    <FormHelperText sx={dropdownHelperTextStyle}>Select Week End Day</FormHelperText>
                    <Autocomplete
                      options={DAY_OPTIONS}
                      getOptionLabel={(option) => option.label}
                      value={values[TENANT_CONFIGURATION_KEY.CONFIG_END_DAY_OF_WEEK]}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name={TENANT_CONFIGURATION_KEY.CONFIG_END_DAY_OF_WEEK}
                          onBlur={handleBlur}
                          sx={textInputStyles}
                          error={
                            touched[TENANT_CONFIGURATION_KEY.CONFIG_END_DAY_OF_WEEK] &&
                            !!errors[TENANT_CONFIGURATION_KEY.CONFIG_END_DAY_OF_WEEK]
                          }
                          helperText={
                            touched[TENANT_CONFIGURATION_KEY.CONFIG_END_DAY_OF_WEEK] &&
                            errors[TENANT_CONFIGURATION_KEY.CONFIG_END_DAY_OF_WEEK]
                          }
                        />
                      )}
                      onChange={(e, selectedValue) => {
                        setFieldValue(TENANT_CONFIGURATION_KEY.CONFIG_END_DAY_OF_WEEK, selectedValue);
                      }}
                    />
                    <ButtonLoading
                      loading={isSubmitting}
                      type='submit'
                      fullWidth
                      loadingPosition='start'
                      onClick={() => handleSubmit()}
                      variant='main-login'
                      sx={{
                        mt: 4,
                        mb: 2,
                      }}
                      disabled={!InitialSetupValidationSchema.isValidSync(values)}
                    >
                      Complete
                    </ButtonLoading>
                  </form>
                )}
              </Formik>
            </Box>
          </Box>
        )}
      </Grid>
    </Grid>
  );
}
