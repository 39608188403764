import { TENANT_CONFIGURATION_KEY } from 'constant/TenantConfigConstant';
import * as Yup from 'yup';

/**
 * Form validation schema for /auth/login
 */

export const LoginValidationSchema = Yup.object().shape({
  userName: Yup.string().email('Email must be valid email').required('Email is required'),
  password: Yup.string().required('Password is required'),
});

/**
 * Form validation schema for /auth/select-role
 */

export const SelectRoleValidationSchema = Yup.object().shape({
  role: Yup.string().required('Role is required'),
  tenantId: Yup.string().required('Tenant is required'),
});

export const InitialSetupValidationSchema = Yup.object().shape({
  [TENANT_CONFIGURATION_KEY.CONFIG_END_DAY_OF_WEEK]: Yup.object().required('End day of week is required').nullable(),
  [TENANT_CONFIGURATION_KEY.CONFIG_INITIAL_FINANCIAL_YEAR]: Yup.object()
    .required('Initial financial year is required')
    .nullable(),
});
