import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TocOutlinedIcon from '@mui/icons-material/TocOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Stack, Typography } from '@mui/material';

export const VIEW_ATTRIBUTE_DEFINITION_OPTIONS_MENU = {
  OPTION_COLUMN_ORDER: 'OPTION_COLUMN_ORDER',
};

interface AttributeDefinitionOptionsMenuProps {
  onOptionClick: (option: string) => void;
  disabled?: boolean;
}

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

export function AttributeDefinitionOptionsMenu(props: AttributeDefinitionOptionsMenuProps) {
  const { onOptionClick, disabled } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOnClickOptions = (option: string) => {
    onOptionClick(option);
    handleClose();
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id='menu-options'
        aria-controls={open ? 'menu-options' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        variant='main-table-panel-border'
        disableElevation
        disabled={disabled}
        onClick={handleClick}
        startIcon={<SettingsOutlinedIcon />}
        endIcon={<KeyboardArrowDownIcon />}
      >
        Config
      </Button>
      <StyledMenu
        id='menu-options'
        MenuListProps={{
          'aria-labelledby': 'menu-options',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => handleOnClickOptions(VIEW_ATTRIBUTE_DEFINITION_OPTIONS_MENU.OPTION_COLUMN_ORDER)}
          disableRipple
        >
          <TocOutlinedIcon />
          <Stack>
            <Typography fontSize={15} fontWeight={700}>
              Column Order Configuration
            </Typography>
            <Typography sx={{ display: 'block' }} fontSize={13} component='span' variant='body2' color='text.secondary'>
              Set the order of columns in the view
            </Typography>
          </Stack>
        </MenuItem>
      </StyledMenu>
    </div>
  );
}
