import { useMemo, useEffect, useCallback, useState } from 'react';
import { useFormik } from 'formik';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import ButtonLoading from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import Typograhpy from '@mui/material/Typography';
import AutoComplete from '@mui/material/Autocomplete';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import RemoveCircleOutlinedIcon from '@mui/icons-material/RemoveCircleOutlined';
import { DropdownItem } from 'types/api/SystemTenant/AROKMS/TableDefinitionTypes';
import { useSubjectUsedDropdown } from 'services/v1/SystemTenant/AROKMS/SubjectService';
import { SUBJECT_TYPE } from 'constant/DataInputConstant';
import { AutoCompleteItem } from 'types/api/Common/AutoCompleteTypes';
import {
  useGetCubeDefinitionDetails,
  useGetCubeEventAttributeListMutation,
} from 'services/v1/SystemTenant/AROCube/CubeBuilderService';
import { SubjectItem } from 'types/api/SystemTenant/AROKMS/SubjectTypes';
import { CubeDefinitionPullEventAttributeValidationSchema } from 'config/FormValidaton/AroCube/ModalCubePullEventAttributeValidationSchema';
import { ModalCubeDefinitionParamsProps } from './ModalCubeDefinitionParameter';
import {
  CubeDefinitionEventAttributeResponse,
  CubeDefinitionRowItem,
} from 'types/api/SystemTenant/AROCube/CubeBuilderTypes';
import { Alert, Tooltip } from '@mui/material';
import { getErrorMessage } from 'utils/Error';
import { FormCubeDefinitionMetaDataValues } from './CubeDefinitionSettingPanel';

const autoCompleteStyle = {
  width: 240,
  '& .MuiAutocomplete-popper': {
    backgroundColor: 'red !imoprtant',
    fontSize: '10px',
  },
};
const ModalContentStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 750,
  maxWidth: '100%',
  bgcolor: 'background.paper',
  minHeight: 340,
  maxHeight: 650,
  overflow: 'auto',
  borderRadius: 1,
  py: 2,
};

const closeIconStyle = { color: '#98A2AE', cursor: 'pointer' };

export type PullFromEventsParameterPayload = {
  rowItem: CubeDefinitionRowItem;
  cubePullEventDefinitons: FormikPullFromEventParameterItem[];
};

export interface CubeEventAttributesDefinitionParams {
  eventSubject: SubjectItem;
  memberAttribute: DropdownItem;
  xMemberAttribute: DropdownItem;
  timeAttribute: DropdownItem;
}

export type FormikPullFromEventParameterItem = {
  id?: string;
  eventSubject: DropdownItem | null;
  memberAttribute: DropdownItem | null;
  xMemberAttribute: DropdownItem | null;
  timeAttribute: DropdownItem | null;
};
type FormikInitivalValues = {
  parameters: FormikPullFromEventParameterItem[];
};

const formDefaultInitialValue = {
  parameters: [
    {
      eventSubject: null,
      memberAttribute: null,
      xMemberAttribute: null,
      timeAttribute: null,
    },
  ],
};

export function ModalCubeEventTable(
  props: ModalCubeDefinitionParamsProps<PullFromEventsParameterPayload> & {
    cubeMetaData: FormCubeDefinitionMetaDataValues;
    cubeId?: string;
  }
) {
  const { onClose, visible = false, onSubmitParameter, cubeDefinitionItem, cubeMetaData, cubeId } = props;

  const { data: cubeDefinitionDetails } = useGetCubeDefinitionDetails(cubeId);

  const [selectedEventIDs, setSelectedEventIDs] = useState<Set<string>>(new Set());

  const { data: subjectDropdownOptions, isLoading: isLoadingSubjectEvent } = useSubjectUsedDropdown({
    enabled: visible,
    subjectType: SUBJECT_TYPE.EVENT,
    containsAttributeId: cubeDefinitionItem?.attribute.value,
  });

  const formikInitialValues: FormikInitivalValues = useMemo(() => {
    if (cubeDefinitionDetails?.data) {
      const selectedAttributeId = cubeDefinitionItem?.attribute.value;
      const currentAttributesParameter: FormikPullFromEventParameterItem[] | null = selectedAttributeId
        ? cubeDefinitionDetails.data.attributesPullFromEvents[selectedAttributeId]
        : null;

      if (currentAttributesParameter) {
        console.log({
          currentAttributesParameter,
        });
        return {
          parameters: currentAttributesParameter.map((item) => ({
            id: item.id,
            eventSubject: item.eventSubject,
            memberAttribute: item.memberAttribute,
            xMemberAttribute: item.xMemberAttribute,
            timeAttribute: item.timeAttribute,
          })),
        };
      }
    }

    return formDefaultInitialValue;
  }, [cubeDefinitionDetails, cubeDefinitionItem?.attribute?.value]);

  const {
    mutate: cubeEventAttributeData,
    isSuccess: isSuccessCubeEventAttribute,
    isLoading: isLoadingCubeEventAttribute,
    isError: isErrorCubeEventAttribute,
    error: errorCubeEventAttribute,
  } = useGetCubeEventAttributeListMutation();

  const handleOnCloseModal = () => {
    onClose?.();
    formik.resetForm();
  };
  const handleOnCancel = () => {
    handleOnCloseModal?.();
  };

  const handleOnSave = (data: FormikInitivalValues) => {
    if (cubeDefinitionItem) {
      const ruleTypeParams = data.parameters
        .map((parameter) => {
          return parameter.eventSubject?.label;
        })
        .join(', ');
      const cubePullEventDefinitionRow: CubeDefinitionRowItem = {
        ...cubeDefinitionItem,
        ruleTypeParams: ruleTypeParams,
      };
      onSubmitParameter?.({
        rowItem: cubePullEventDefinitionRow,
        cubePullEventDefinitons: data.parameters,
      });
      handleOnCloseModal();
    }
  };

  const addRow = () => {
    const newParameters: FormikPullFromEventParameterItem = {
      id: undefined,
      eventSubject: null,
      memberAttribute: null,
      timeAttribute: null,
      xMemberAttribute: null,
    };
    formik.setFieldValue('parameters', [...formik.values.parameters, newParameters]);
  };

  const removeRow = (index: number) => {
    const newParameters = [...formik.values.parameters];
    newParameters.splice(index, 1);
    formik.setFieldValue('parameters', newParameters);
  };

  const filterAttributesOptions = useCallback(
    (options: DropdownItem[] | undefined, notEqualValue: string | undefined) => {
      if (!options) return;
      return options?.filter((option) => option?.value !== notEqualValue);
    },
    []
  );

  useEffect(() => {}, [cubeDefinitionItem]);

  const formik = useFormik<FormikInitivalValues>({
    initialValues: formikInitialValues,
    validationSchema: CubeDefinitionPullEventAttributeValidationSchema,
    onSubmit: handleOnSave,
  });

  const cubeEventAttributeDataOptions: Map<string, CubeDefinitionEventAttributeResponse> = useMemo(() => {
    const dataMap = new Map<string, CubeDefinitionEventAttributeResponse>();
    formik.values.parameters.forEach((event) => {
      if (event.eventSubject && event.eventSubject.value) {
        cubeEventAttributeData(
          { subjectId: event.eventSubject.value },
          {
            onSuccess: (response) => {
              response.data && event?.eventSubject?.value && dataMap.set(event.eventSubject?.value, response.data);
            },
          }
        );
      }
    });
    return dataMap;
    // eslint-disable-next-line
  }, [formik.values.parameters]);

  const subjectEventOptions = useMemo(() => {
    return subjectDropdownOptions?.data.filter((item) => !selectedEventIDs.has(item.value.toString())) || [];
  }, [subjectDropdownOptions?.data, selectedEventIDs]);

  useEffect(() => {
    formik.setValues(formikInitialValues);
    // eslint-disable-next-line
  }, [formikInitialValues]);

  useEffect(() => {
    const eventIDs = new Set<string>();
    formik.values.parameters.forEach((event) => {
      if (event.eventSubject) {
        eventIDs.add(event.eventSubject.value.toString());
      }
    });
    setSelectedEventIDs(eventIDs);
  }, [formik.values.parameters]);

  return (
    <div>
      <Modal open={visible} onClose={handleOnCloseModal}>
        <Stack direction='column' sx={ModalContentStyle}>
          <Stack>
            <Stack px={2} justifyContent='space-between' direction='row' alignItems='center'>
              <Typograhpy variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
                Pull Data From Event Rule
              </Typograhpy>
              <IconButton onClick={handleOnCloseModal} component='label'>
                <HighlightOffRoundedIcon sx={closeIconStyle} />
              </IconButton>
            </Stack>
          </Stack>
          {isErrorCubeEventAttribute && (
            <Alert severity='error' sx={{ mx: 2, my: 1 }}>
              {getErrorMessage(errorCubeEventAttribute)}
            </Alert>
          )}

          <div>
            {formik.values.parameters.map((parameter, parameterIndex) => (
              <Stack
                px={2}
                flexDirection='row'
                sx={{
                  bgcolor: '#FBFBFB',
                  py: 1,
                  px: 2,
                  borderTop: '1px solid #E3EBF6',
                  borderBottom: '1px solid #E3EBF6',
                }}
                alignItems='center'
                // justifyContent='space-between'
                gap={2}
                my={0.6}
              >
                <Stack>
                  <Typograhpy variant='body2' fontWeight='bold' color='black' mb={1}>
                    Select Event Table
                  </Typograhpy>
                  <AutoComplete
                    // @ts-ignore
                    onChange={(event, value) => {
                      formik.setFieldValue(`parameters[${parameterIndex}].eventSubject`, value);
                    }}
                    size='small'
                    value={formik.values.parameters?.[parameterIndex].eventSubject}
                    disabled={isLoadingSubjectEvent}
                    getOptionLabel={(option: AutoCompleteItem) => option.label}
                    options={subjectEventOptions}
                    renderOption={(props, option) => (
                      <Box component='li' sx={{ '& > span': { fontSize: '14px', mr: 1, flexShrink: 0 } }} {...props}>
                        <span>{option.label}</span>
                      </Box>
                    )}
                    sx={autoCompleteStyle}
                    renderInput={(params) => (
                      <Stack direction='row' justifyContent='space-between' display='flex' alignItems='center'>
                        <TextField
                          {...params}
                          onBlur={formik.handleBlur}
                          name='member'
                          placeholder={isLoadingSubjectEvent ? 'Loading...' : 'Select Event'}
                        />
                      </Stack>
                    )}
                  />
                </Stack>
                <Stack>
                  <Typograhpy variant='body2' fontWeight='bold' color='black' mb={1}>
                    Member Attribute
                  </Typograhpy>
                  <AutoComplete
                    // @ts-ignore
                    onChange={(event, value) => {
                      formik.setFieldValue(`parameters[${parameterIndex}].memberAttribute`, value);
                    }}
                    size='small'
                    value={formik.values.parameters?.[parameterIndex].memberAttribute}
                    disabled={!isSuccessCubeEventAttribute}
                    getOptionLabel={(option: DropdownItem) => option.label}
                    options={
                      filterAttributesOptions(
                        cubeEventAttributeDataOptions.get(
                          formik.values.parameters?.[parameterIndex].eventSubject?.value || ''
                        )?.memberAttributes || [],
                        formik.values.parameters[parameterIndex].xMemberAttribute?.value
                      ) || []
                    }
                    renderOption={(props, option) => (
                      <Box component='li' sx={{ '& > span': { fontSize: '14px', mr: 1, flexShrink: 0 } }} {...props}>
                        <span>{option.label}</span>
                      </Box>
                    )}
                    sx={autoCompleteStyle}
                    renderInput={(params) => (
                      <Stack direction='row' justifyContent='space-between' display='flex' alignItems='center'>
                        <TextField
                          {...params}
                          onBlur={formik.handleBlur}
                          name='memberAttributeId'
                          placeholder={isLoadingCubeEventAttribute ? 'Loading...' : 'Select Member Attribute'}
                        />
                      </Stack>
                    )}
                  />
                </Stack>
                <Stack>
                  <Typograhpy variant='body2' fontWeight='bold' color='black' mb={1}>
                    Time Attribute
                  </Typograhpy>
                  <AutoComplete
                    // @ts-ignore
                    onChange={(event, value) => {
                      formik.setFieldValue(`parameters[${parameterIndex}].timeAttribute`, value);
                    }}
                    size='small'
                    value={formik.values.parameters[parameterIndex].timeAttribute}
                    disabled={!isSuccessCubeEventAttribute}
                    getOptionLabel={(option: DropdownItem) => option.label}
                    options={
                      cubeEventAttributeDataOptions.get(
                        formik.values.parameters?.[parameterIndex].eventSubject?.value || ''
                      )?.timeAttributes || []
                    }
                    renderOption={(props, option) => (
                      <Box component='li' sx={{ '& > span': { fontSize: '14px', mr: 1, flexShrink: 0 } }} {...props}>
                        <span>{option.label}</span>
                      </Box>
                    )}
                    sx={autoCompleteStyle}
                    renderInput={(params) => (
                      <Stack direction='row' justifyContent='space-between' display='flex' alignItems='center'>
                        <TextField
                          {...params}
                          onBlur={formik.handleBlur}
                          placeholder={isLoadingCubeEventAttribute ? 'Loading...' : 'Select Time Attribute'}
                        />
                      </Stack>
                    )}
                  />
                </Stack>
                {cubeMetaData.subjectX && (
                  <Stack px={2} flexDirection='row' justifyContent='space-between' my={2}>
                    <Stack>
                      <Typograhpy variant='body2' fontWeight='bold' color='black' mb={1}>
                        Member X Attribute
                      </Typograhpy>
                      <AutoComplete
                        // @ts-ignore
                        onChange={(event, value) => {
                          formik.setFieldValue(`parameters[${parameterIndex}].xMemberAttribute`, value);
                        }}
                        size='small'
                        value={formik.values.parameters[parameterIndex].xMemberAttribute}
                        disabled={!isSuccessCubeEventAttribute}
                        getOptionLabel={(option: DropdownItem) => option.label}
                        options={
                          filterAttributesOptions(
                            cubeEventAttributeDataOptions.get(
                              formik.values.parameters?.[parameterIndex].eventSubject?.value || ''
                            )?.memberAttributes,
                            formik.values.parameters[parameterIndex].memberAttribute?.value
                          ) || []
                        }
                        renderOption={(props, option) => (
                          <Box
                            component='li'
                            sx={{ '& > span': { fontSize: '14px', mr: 1, flexShrink: 0 } }}
                            {...props}
                          >
                            <span>{option.label}</span>
                          </Box>
                        )}
                        sx={autoCompleteStyle}
                        renderInput={(params) => (
                          <Stack direction='row' justifyContent='space-between' display='flex' alignItems='center'>
                            <TextField
                              {...params}
                              onBlur={formik.handleBlur}
                              name='memberAttributeId'
                              placeholder={isLoadingCubeEventAttribute ? 'Loading...' : 'Select X Member Attribute'}
                            />
                          </Stack>
                        )}
                      />
                    </Stack>
                    <Stack />
                  </Stack>
                )}

                <Stack flex={1} flexDirection='row' gap={1}>
                  <Tooltip title='Remove event table'>
                    <IconButton onClick={() => removeRow(parameterIndex)}>
                      <RemoveCircleOutlinedIcon fontSize='medium' sx={{ fill: '#00B3DC' }} />
                    </IconButton>
                  </Tooltip>
                  {parameterIndex === formik.values.parameters.length - 1 && (
                    <Tooltip title='Add new event table'>
                      <IconButton onClick={addRow}>
                        <AddCircleOutlinedIcon fontSize='medium' sx={{ fill: '#00B3DC' }} />
                      </IconButton>
                    </Tooltip>
                  )}
                </Stack>
              </Stack>
            ))}
          </div>

          <Stack height={100} px={2}></Stack>
          <Stack px={2}>
            <Divider sx={{ mb: 1, mt: 2 }} />
            <Stack direction='row' justifyContent='flex-end' spacing={2} alignItems='center' sx={{ py: 1 }}>
              <Button variant='main-table-panel-border' onClick={handleOnCancel}>
                Cancel
              </Button>
              <ButtonLoading
                variant='main-table-panel'
                disabled={!CubeDefinitionPullEventAttributeValidationSchema.isValidSync(formik.values)}
                onClick={() => formik.handleSubmit()}
              >
                Save Parameter
              </ButtonLoading>
            </Stack>
          </Stack>
        </Stack>
      </Modal>
    </div>
  );
}
