import { memo, useState } from 'react';
import Button from '@mui/material/Button';
import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid';
import { ModalPreviewLocation } from 'pages/Tenant/AROKMS/DataInput/components/ModalPreviewLocation';
import { decodeStringToGeoLocations } from 'utils/DetectLocation';

const ButtonStyles = {
  height: '78%',
  fontSize: '12px',
  color: '#00B3DC',
  letterSpacing: '0.15px',
  textDecorationLine: 'underline',
  textTransform: 'none',

  '&:hover': {
    backgroundColor: 'transparent',
  },
};

const coordinateStyle = {
  display: 'inline-block',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

export function RenderLocation(props: GridRenderCellParams<string>) {
  const { value } = props;
  const [previewModal, setPreviewModal] = useState<{
    visible: boolean;
    locations: { lat: number; lng: number }[];
  }>({
    visible: false,
    locations: [],
  });

  const handleOnClick = () => {
    setPreviewModal({
      visible: true,
      locations: decodeStringToGeoLocations(value),
    });
  };

  const handleOnClose = () => {
    setPreviewModal({
      visible: false,
      locations: [],
    });
  };

  return (
    <>
      <Button variant='text' sx={ButtonStyles} onClick={handleOnClick}>
        <span style={coordinateStyle}>{value}</span>
      </Button>

      <ModalPreviewLocation onClose={handleOnClose} locations={previewModal.locations} visible={previewModal.visible} />
    </>
  );
}

const MemoRenderLocation = memo(RenderLocation);

type RenderLocationProps = GridColDef['renderCell'];
export const renderDataGridLocation: RenderLocationProps = (params) => {
  return <MemoRenderLocation {...params} key={params.id} />;
};
