import { AxiosResponse } from 'axios';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import axios from 'libs/Axios';
import qs from 'query-string';
import { AxiosDefaultErrorEntity } from 'types/api/Common/ErrorTypes';
import { CubeLayerItem, RequestCreateCubeLayer } from 'types/api/SystemTenant/AROCube/CubeLayerTypes';
import { DropdownItem } from 'types/api/SystemTenant/AROKMS/TableDefinitionTypes';

export const CUBE_LAYER_QUERY_KEY = {
  CUBE_LAYER_LIST: 'CUBE_LAYER_LIST',
  CUBE_LAYER_SET_DROPDOWNS: 'CUBE_LAYER_SET_DROPDOWNS',
  CUBE_LAYER_DROPDOWNS: 'CUBE_LAYER_DROPDOWNS',
  CUBE_LAYER_CUBE_DEFINITION_DROPDOWNS: 'CUBE_LAYER_CUBE_DEFINITION_DROPDOWNS',
};

export function useGetCubeLayerList() {
  return useQuery<AxiosResponse<CubeLayerItem[]>, AxiosDefaultErrorEntity>([CUBE_LAYER_QUERY_KEY.CUBE_LAYER_LIST], () =>
    axios.get(`/api/v1/cube-layer/list`).then((res) => res)
  );
}

export function useDeleteCubeLayer() {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosDefaultErrorEntity, { ids: string[] }>(
    (data) => axios.post(`/api/v1/cube-layer/delete`, data).then((res) => res),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(CUBE_LAYER_QUERY_KEY.CUBE_LAYER_LIST);
      },
      onError: (error) => {
        queryClient.invalidateQueries(CUBE_LAYER_QUERY_KEY.CUBE_LAYER_LIST);
      },
    }
  );
}

export function useGetCubeLayerSetDropdown() {
  return useQuery<AxiosResponse<DropdownItem[]>, AxiosDefaultErrorEntity>(
    [CUBE_LAYER_QUERY_KEY.CUBE_LAYER_SET_DROPDOWNS],
    () => axios.get(`/api/v1/cube-layer/dropdown/layer-set`).then((res) => res)
  );
}
export function useGetCubeLayerDropdown() {
  return useQuery<AxiosResponse<DropdownItem[]>, AxiosDefaultErrorEntity>(
    [CUBE_LAYER_QUERY_KEY.CUBE_LAYER_DROPDOWNS],
    () => axios.get(`/api/v1/cube-layer/dropdown`).then((res) => res)
  );
}

export function useGetCubeDefinitionByLayerIdDropdown(
  layerId?: string,
  timePeriodSetId?: string,
  attributeId?: string,
  currentCubeId?: string
) {
  return useQuery<AxiosResponse<DropdownItem[]>, AxiosDefaultErrorEntity>(
    [CUBE_LAYER_QUERY_KEY.CUBE_LAYER_CUBE_DEFINITION_DROPDOWNS, layerId, timePeriodSetId, attributeId, currentCubeId],
    () =>
      axios
        .get(
          `/api/v1/cube-layer/${layerId}/cube-definition/dropdown?${qs.stringify({
            timePeriodSetId,
            attributeId,
            currentCubeId,
          })}`
        )
        .then((res) => res),
    {
      enabled: !!layerId,
    }
  );
}

export function useUpsertCubeLayer() {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosDefaultErrorEntity, RequestCreateCubeLayer>(
    (data) => axios.post(`/api/v1/cube-layer/upsert`, data).then((res) => res),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(CUBE_LAYER_QUERY_KEY.CUBE_LAYER_LIST);
      },
      onError: (error) => {
        queryClient.invalidateQueries(CUBE_LAYER_QUERY_KEY.CUBE_LAYER_LIST);
      },
    }
  );
}

export function useUpsertCubeLayerSet() {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosDefaultErrorEntity, { name: string }>(
    (data) => axios.post(`/api/v1/cube-layer/layer-set/upsert`, data).then((res) => res),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(CUBE_LAYER_QUERY_KEY.CUBE_LAYER_SET_DROPDOWNS);
      },
      onError: (error) => {
        queryClient.invalidateQueries(CUBE_LAYER_QUERY_KEY.CUBE_LAYER_SET_DROPDOWNS);
      },
    }
  );
}
