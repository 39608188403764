// @ts-nocheck
import Snackbar from '@mui/material/Snackbar';
import { useState, useMemo, useEffect } from 'react';
import Button from '@mui/material/Button';
import { DataGridCellTextInput } from 'components/DatatableComponent/RenderCustomTextInput';
import {
  GridColDef,
  GridRowModesModel,
  GridRowModes,
  GridRowEditStopParams,
  GridSelectionModel,
  GridRowId,
  GridFilterModel,
  GridSortModel,
  getGridStringOperators,
  getGridDateOperators,
} from '@mui/x-data-grid-pro';
import { dayjs } from 'utils/DateUtils';
import { ENV_CONFIG } from 'config/env';
import Alert, { AlertProps } from '@mui/material/Alert';

import { CustomDataTable } from 'components/DatatableComponent';
import { PaginationAndSortingParams } from 'types/api/Common/PaginationTypes';
import { AxiosDefaultErrorEntity } from 'types/api/Common/ErrorTypes';
import { getErrorMessage, getErrorTitle } from 'utils/Error';
import { useSystemList, useCreateSystem } from 'services/v1/TenantMaster/MasterTable/SystemTableDataService';
import { SystemItem } from 'types/api/TenantMaster/MasterTable/SystemTableDataTypes';
import { SYSTEM_STATUS } from 'constant/SystemStatusConstant';
import { RULES_CONSTANT } from 'constant/RuleConstant';
import { ModalCreateSystem } from './component/ModalCreateSystem';
import { ModalUpdateSystem } from './component/ModalUpdateSystem';
import { TenantHomeDashboardNavigationBar } from 'components/LayoutComponent/VerticalLayout/HeaderBar';
import { serializeDataInputPayload } from 'pages/Tenant/AROKMS/DataInput/utils/serialiazePayload';
import { ButtonRecordEdit } from 'pages/Tenant/AROKMS/DataInput/components/ButtonRecordEdit';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PATH_CONSTANT } from 'constant/PathConstant';
import qs from 'query-string';
import { SettingPanel } from 'pages/Tenant/AROKMS/MemberList/components/MemberListPanel';

const panelStyle = {
  transform: 'translate3d(704px, 140.667px, 0px) !important',

  '& .MuiDataGrid-panelFooter': {
    display: 'none',
  },
};

const SYSTEM_TABLE_STATIC_RULE_TYPE = [
  {
    field: 'system',
    nullable: false,
    ruleTypeCode: RULES_CONSTANT.BASE_SUBJECT.RULE_ATTRIBUTE_SUBJECT_BASE,
  },
  {
    field: 'systemName',
    nullable: true,
    ruleTypeCode: RULES_CONSTANT.BASE_SUBJECT.RULE_ATTRIBUTE_SUBJECT_BASE,
  },
  {
    field: 'industry',
    nullable: true,
    ruleTypeCode: RULES_CONSTANT.TEXT.RULE_PULL_FROM_SUBJECT_ATTRIBUTE,
  },
  {
    field: 'industryAbbn',
    nullable: true,
    ruleTypeCode: RULES_CONSTANT.TEXT.RULE_ATTRIBUTE_MID,
  },
  {
    field: 'version',
    nullable: true,
    ruleTypeCode: RULES_CONSTANT.TEXT.RULE_CONCATENATE_ATTRIBUTES,
  },
  {
    field: 'codeStatus',
    nullable: false,
    ruleTypeCode: RULES_CONSTANT.ASSOCIATED_SUBJECT.RULE_ASSOCIATED_SUBJECT_LIST,
  },
  {
    field: 'fieldStatus',
    nullable: false,
    ruleTypeCode: RULES_CONSTANT.ASSOCIATED_SUBJECT.RULE_ASSOCIATED_SUBJECT_LIST,
  },
  {
    field: 'demoStatus',
    nullable: false,
    ruleTypeCode: RULES_CONSTANT.ASSOCIATED_SUBJECT.RULE_ASSOCIATED_SUBJECT_LIST,
  },
  {
    field: 'status',
    nullable: true,
    ruleType: '',
  },
];

export default function SystemTableDataPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [rowData, setRowData] = useState<SystemItem[]>([]);
  const [modalDeploy, setModalDeploy] = useState<{
    visible: boolean;
    data: SystemItem | null;
  }>({
    visible: false,
    data: null,
  });
  const [modalSystemDetails, setModalSystemDetails] = useState<{
    visible: boolean;
    data: SystemItem | null;
  }>({
    visible: false,
    data: null,
  });

  const [selectedDataID, setSelectedDataID] = useState<GridRowId[]>([]);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [filter, setFilter] = useState<PaginationAndSortingParams>({
    page: searchParams.get('page') ? Number(searchParams.get('page')) : 1,
    size: searchParams.get('size') ? Number(searchParams.get('size')) : 20,
    filterValue: '',
    sortType: 'asc',
    sortBy: 'systemName',
    filterOperator: 'contains',
    filterColumn: 'systemName',
  });

  const [filterButtonElement, setFilterButtonElement] = useState<HTMLButtonElement | null>(null);
  const [snackbar, setSnackbar] = useState<Pick<AlertProps, 'children' | 'severity'> | null>(null);

  const { data: systemList, isLoading, refetch: refreshAttributeData, isError, error } = useSystemList(filter);
  const { mutate: createSystem } = useCreateSystem();

  const columns = useMemo<GridColDef[]>(() => {
    return [
      {
        field: 'system',
        headerName: 'System',
        width: 160,
        editable: true,
        filterOperators: getGridStringOperators().filter((operator) => ['contains', 'equals'].includes(operator.value)),
        renderCell: (params) => {
          return <div>{params.value}</div>;
        },
      },

      {
        field: 'codeStatus',
        headerName: 'Code Status',
        width: 160,
        editable: true,
        filterOperators: getGridStringOperators().filter((operator) => ['contains', 'equals'].includes(operator.value)),
        renderCell: (params) => {
          return <div>{params.value}</div>;
        },
      },
      {
        field: 'fieldStatus',
        headerName: 'Field Status',
        width: 160,
        editable: true,
        filterOperators: getGridStringOperators().filter((operator) => ['contains', 'equals'].includes(operator.value)),
        renderCell: (params) => {
          return <div>{params.value}</div>;
        },
      },

      {
        field: 'demoStatus',
        headerName: 'Demo Status',
        width: 150,

        editable: true,
        filterOperators: getGridStringOperators().filter((operator) => ['contains', 'equals'].includes(operator.value)),
        renderCell: (params) => {
          return <div>{params.value}</div>;
        },
      },
      {
        field: 'industry',
        headerName: 'Industry',
        width: 200,
        editable: true,
        filterOperators: getGridStringOperators().filter((operator) => ['contains', 'equals'].includes(operator.value)),
        renderEditCell: (params) => <DataGridCellTextInput {...params} />,
        renderCell: (params) => {
          return <div>{params.value}</div>;
        },
      },
      {
        field: 'systemName',
        headerName: 'System name',
        width: 160,
        editable: true,
        filterOperators: getGridStringOperators().filter((operator) => ['contains', 'equals'].includes(operator.value)),
        preProcessEditCellProps: (params) => {
          const industry = params.otherFieldsProps.industry?.value;
          const version = params.otherFieldsProps.version?.value;
          const systemName = industry?.label ? `${industry.label} ${version}` : '';
          return {
            ...params,
            value: systemName,
          };
        },
        renderEditCell: (params) => <DataGridCellTextInput {...params} />,
        renderCell: (params) => {
          return <div>{params.value}</div>;
        },
      },
      {
        field: 'industryAbbn',
        headerName: 'Industry ABBN',
        width: 130,
        editable: true,
        filterOperators: getGridStringOperators().filter((operator) => ['contains', 'equals'].includes(operator.value)),
        preProcessEditCellProps: (params) => {
          // substing from selected dropdown in field system, ex: TR2.1 -> TR
          const system = params.otherFieldsProps.system?.value;
          const industryAbbn = system?.label ? system.label.substring(0, 2) : '';
          return {
            ...params,
            value: industryAbbn,
          };
        },
        renderEditCell: (params) => <DataGridCellTextInput {...params} />,
        renderCell: (params) => {
          return <div>{params.value}</div>;
        },
      },
      {
        field: 'version',
        headerName: 'Version',
        width: 100,
        editable: true,
        filterOperators: getGridStringOperators().filter((operator) => ['contains', 'equals'].includes(operator.value)),
        preProcessEditCellProps: (params) => {
          // substing from selected dropdown in field system, ex: TR2.1 -> 2.1 and contact with 'V'
          const system = params.otherFieldsProps.system?.value;
          const version = system?.label ? `v${system.label.substring(2)}` : '';
          return {
            ...params,
            value: version,
          };
        },
        renderEditCell: (params) => <DataGridCellTextInput {...params} />,

        renderCell: (params) => {
          return <div>{params.value}</div>;
        },
      },

      {
        field: 'status',
        headerName: 'Deployment Status',
        width: 200,
        editable: false,
        renderCell: (params) => {
          if (params.value === SYSTEM_STATUS.CREATED) {
            return (
              <Button
                variant='secondary-table-panel'
                sx={{
                  height: '78%',
                  fontSize: '12px',
                }}
                onClick={() => setModalDeploy({ visible: true, data: params.row as SystemItem })}
              >
                Deploy
              </Button>
            );
          }
          return <p>{params.value}</p>;
        },
        filterOperators: getGridStringOperators().filter((operator) => ['contains', 'equals'].includes(operator.value)),
      },
      {
        field: 'action',
        type: 'actions',
        headerName: 'Edit',
        width: 30,
        editable: false,
        filterable: false,
        renderCell: (params: GridRenderCellParams<MasterTableTenantItem>) => {
          return (
            <ButtonRecordEdit
              {...params}
              onClick={() => {
                navigate(
                  `${PATH_CONSTANT.TENANT_MASTER.MASTER_TABLE.EDIT_SYSTEM.replace(
                    ':id',
                    params.id as string
                  )}?refBackParam=${encodeURIComponent(window.location.pathname + window.location.search)}`
                );
              }}
            />
          );
        },
      },
      {
        field: 'createdBy',
        headerName: 'Created By',
        editable: false,
        width: 170,
        filterOperators: getGridStringOperators().filter((operator) => ['contains', 'equals'].includes(operator.value)),
      },
      {
        field: 'createdDate',
        headerName: 'Created Date',
        valueFormatter: (params) => {
          if (!params.value) return '';
          return `${dayjs(params.value).format(ENV_CONFIG.config.DATE_FORMAT)}`;
        },
        editable: false,
        width: 170,
        type: 'date',
        filterOperators: getGridDateOperators().filter(
          (operator) => !['onOrAfter', 'onOrBefore', 'isEmpty', 'isNotEmpty'].includes(operator.value)
        ),
      },
    ];

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const DataMutationSuccessHandlerOption = {
    onSuccess: () => {
      refreshAttributeData();
    },
    onError: (error: AxiosDefaultErrorEntity) => {
      setSnackbar({ children: getErrorMessage(error), severity: 'error' });
      setRowModesModel({});
      refreshAttributeData();
    },
  };

  useEffect(() => {
    if (systemList?.data?.data) {
      setRowData(systemList?.data?.data);
      setRowModesModel(
        systemList?.data?.data?.reduce((acc, item) => {
          return { ...acc, [item.id]: GridRowModes.View };
        }, {})
      );
    }
  }, [systemList]);

  const handleChangePage = (newPage: number) => {
    setFilter({ ...filter, page: newPage + 1 });
    navigate({
      search: qs.stringify({
        ...filter,
        page: newPage + 1,
      }),
    });
  };
  const handleChangePageSize = (newPageSize: number) => {
    setFilter({ ...filter, size: newPageSize });
    navigate({
      search: qs.stringify({
        ...filter,
        size: newPageSize,
        page: 1,
      }),
    });
  };

  const handleOnFilter = (params: GridFilterModel) => {
    let filterObj = {};
    if (params.items.length) {
      filterObj = {
        filterValue: params.items[0].value,
        filterOperator: params.items[0].operatorValue,
        filterColumn: params.items[0].columnField,
      };
    } else {
      filterObj = {
        filterValue: '',
        filterOperator: 'contains',
      };
    }
    setFilter({
      ...filter,
      ...filterObj,
    });
  };

  const handleOnSortModelChange = (model: GridSortModel) => {
    if (model.length === 0) {
      setFilter({ ...filter, sortType: 'asc', sortBy: 'systemName' });
      return;
    }

    setFilter({ ...filter, sortType: model[0].sort, sortBy: model[0].field });
  };

  // Process row Update will be triggered when
  // user modify or insert new row the table
  const handleProcessRowUpdate = async (data: SystemItem, oldData: SystemItem) => {
    const dataWithRuleTypeCode = _.mapValues(data, (value, key) => {
      const column = SYSTEM_TABLE_STATIC_RULE_TYPE.find((item) => item.field === key);
      if (column) {
        return {
          value,
          ruleTypeCode: column.ruleTypeCode,
        };
      }
      return { value };
    });
    // check is empty from data and check to column whether is nullable
    const isDataEmpty = Object.keys(_.omit(data, ['id', 'isNew'])).every((key) => {
      return !data[key];
    });

    const isAnyDataEmpty = Object.keys(_.omit(data, ['id', 'isNew'])).some((key) => {
      const column = SYSTEM_TABLE_STATIC_RULE_TYPE.find((item) => item.field === key);
      if (column) {
        if (column.nullable === false && !data[key]) return true;
      }
      return false;
    });

    if (isDataEmpty) {
      handleCancelInsert(data.id);
    }
    if (!isDataEmpty && isAnyDataEmpty) return;

    if (data.isNew) {
      if (!isDataEmpty) {
        const payload = serializeDataInputPayload(dataWithRuleTypeCode, data);
        // if the row is new and not empy, then insert the row to database

        createSystem(payload, DataMutationSuccessHandlerOption);
        return { ...data, isNew: false };
      }
    } else {
      return data;
    }
  };

  const handleCancelInsert = (id: string) => {
    const newRows = rowData.filter((item) => item.id !== id);
    setRowData(newRows);
    setRowModesModel((prev) => {
      const newModel = { ...prev };
      delete newModel[id];
      return newModel;
    });
  };

  const handleRowEditStop = (params: GridRowEditStopParams) => {
    setRowModesModel({ ...rowModesModel, [params.id]: { mode: GridRowModes.View } });
  };

  const handleCloseSnackbar = () => setSnackbar(null);

  const handleOnButtonInsertClick = () => {
    navigate(PATH_CONSTANT.TENANT_MASTER.MASTER_TABLE.ADD_SYSTEM);
  };

  return (
    <TenantHomeDashboardNavigationBar>
      <CustomDataTable
        initialState={{
          pinnedColumns: {
            left: ['action'],
          },
          pagination: {
            page: 0,
          },
          sorting: {
            sortModel: [
              {
                field: 'systemName',
                sort: 'asc',
              },
            ],
          },
        }}
        isError={isError}
        errorMessage={error && getErrorMessage(error)}
        errorTitle={getErrorTitle(error)}
        loading={isLoading}
        rows={rowData}
        columns={columns}
        rowCount={systemList?.data?.rowCount}
        page={systemList?.data?.page?.pageNumber || 0}
        pageSize={filter.size}
        getRowId={(row) => {
          return row.id || 0;
        }}
        rowModesModel={rowModesModel}
        disableSelectionOnClick
        rowsPerPageOptions={[5, 10, 20]}
        getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
        experimentalFeatures={{ newEditingApi: true }}
        onFilterModelChange={handleOnFilter}
        onSortModelChange={handleOnSortModelChange}
        onPageChange={handleChangePage}
        onPageSizeChange={handleChangePageSize}
        checkboxSelection={false}
        onSelectionModelChange={(selectedId: GridSelectionModel) => {
          setSelectedDataID(selectedId);
        }}
        components={{
          Toolbar: SettingPanel,
        }}
        onProcessRowUpdateError={(error) => console.log(error)}
        processRowUpdate={handleProcessRowUpdate}
        onRowEditStop={handleRowEditStop}
        componentsProps={{
          panel: {
            sx: panelStyle,
            anchorEl: filterButtonElement,
          },
          toolbar: {
            setRows: setRowData,
            setRowModesModel,
            onAddDataClick: handleOnButtonInsertClick,
            setFilterButtonElement: setFilterButtonElement,
            disabled: isLoading || rowData.some((item) => item.isNew),
            disabledDeleteButton: selectedDataID.length === 0,
            config: {
              withExport: false,
              onInsertClick: handleOnButtonInsertClick,
              deleteButtonText: 'Delete',
              fieldFocusOnInsert: 'system',
              insertButtonText: 'Add New System',
              insertInitialObject: {
                systemCode: null,
                systemName: null,
                industry: null,
                industryAbbn: null,
                status: null,
                version: null,
                isNew: true,
              },
            },
          },
        }}
      />

      <ModalCreateSystem
        visible={modalDeploy.visible}
        data={modalDeploy.data}
        onClose={() => setModalDeploy({ visible: false, data: null })}
      />

      <ModalUpdateSystem
        visible={modalSystemDetails.visible}
        data={modalSystemDetails.data}
        title={modalSystemDetails.data?.systemName}
        onClose={() => setModalSystemDetails({ visible: false, data: null })}
      />

      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          onClose={handleCloseSnackbar}
          autoHideDuration={3000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
    </TenantHomeDashboardNavigationBar>
  );
}
