import { AxiosResponse } from 'axios';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import qs from 'query-string';
import axios from 'libs/Axios';

import { AxiosDefaultErrorEntity } from 'types/api/Common/ErrorTypes';
import { PaginationAndSortingParams } from 'types/api/Common/PaginationTypes';
import { WithOptimisticUpdate } from 'utils/WithOptimisticUpdate';
import {
  DataInputUploadFileImportRequest,
  DisplayTableDeletePayload,
  DisplayTableResponse,
  DynamicDataItem,
  EventSyncCubeActivity,
} from 'types/api/Tenant/AROKMS/DisplayTableTypes';
import { DISPLAY_TABLE_QUERY_KEY } from '../AROKMS/DisplayTableService';

export interface DisplayTableParams extends PaginationAndSortingParams {
  subjectId?: string | number | undefined;
  cubeId?: string;
  ktreeMemberId?: string;
  ktreeMemberName?: string;
  timePeriod?: string;
}

export const EVENT_TABLE_QUERY_KEY = {
  DATA_LIST: 'EVENT_DATA_LIST',
  COLUMNS: 'EVENT_COLUMNS',
  FORM_INPUT: 'EVENT_FORM_INPUT',
  EVENT_SYNC_CUBE_ACTIVITY: 'EVENT_SYNC_CUBE_ACTIVITY',
};

//  Get Table Data
export function useDisplayEventTable(filter: DisplayTableParams) {
  return useQuery<AxiosResponse<DisplayTableResponse>, AxiosDefaultErrorEntity>(
    [EVENT_TABLE_QUERY_KEY.DATA_LIST, { filter }],
    () => {
      return axios.get(`/api/v1/event-table/fetch?${qs.stringify(filter)}`).then((res) => res);
    },
    {
      enabled: !!filter.subjectId,
    }
  );
}

// Get Table Colunms
// We have to seperate this from useDisplayTable, because whenever filter changes the columns will be re-rendered
// and it will cause the table to re-render as well and the filter will be reset
// so we have to seperate the columns and the data in to seperate query
export function useDisplayEventTableColumns(filter: DisplayTableParams) {
  return useQuery<AxiosResponse<DisplayTableResponse>, AxiosDefaultErrorEntity>(
    [EVENT_TABLE_QUERY_KEY.COLUMNS, { subjectId: filter.subjectId }],
    () => {
      return axios.get(`/api/v1/event-table/fetch?${qs.stringify(filter)}`).then((res) => res);
    },
    {
      enabled: !!filter.subjectId,
    }
  );
}

// Insert Data or Data Input
export function useUpsertEventTable(filter: DisplayTableParams) {
  const queryClient = useQueryClient();
  const listQuery = [EVENT_TABLE_QUERY_KEY.DATA_LIST, { filter }];
  return useMutation<AxiosResponse, AxiosDefaultErrorEntity, DynamicDataItem>(
    (bodyRequest) => axios.post(`/api/v1/event-table/input?${qs.stringify(filter)}`, bodyRequest),
    {
      onMutate: async (bodyRequest) =>
        WithOptimisticUpdate<DynamicDataItem>(bodyRequest, listQuery, queryClient, 'UPDATE'),
      onError: (err, variables, context) => {
        //@ts-ignore
        if (context?.previousData) {
          //@ts-ignore
          queryClient.setQueryData(listQuery, context.previousData);
        }
      },
      onSuccess(data, variables, context) {
        queryClient.invalidateQueries(listQuery);
        queryClient.invalidateQueries([EVENT_TABLE_QUERY_KEY.FORM_INPUT]);
      },
    }
  );
}

export function useUpdateEventData(filter: DisplayTableParams) {
  const queryClient = useQueryClient();
  const listQuery = [EVENT_TABLE_QUERY_KEY.DATA_LIST, { filter }];
  return useMutation<AxiosResponse, AxiosDefaultErrorEntity, DynamicDataItem>(
    (bodyRequest) => axios.post(`/api/v1/event-table/edit?${qs.stringify(filter)}`, bodyRequest),
    {
      onMutate: async (bodyRequest) =>
        WithOptimisticUpdate<DynamicDataItem>(bodyRequest, listQuery, queryClient, 'UPDATE'),
      onError: (err, variables, context) => {
        //@ts-ignore
        if (context?.previousData) {
          //@ts-ignore
          queryClient.setQueryData(listQuery, context.previousData);
        }
      },
      onSuccess(data, variables, context) {
        queryClient.invalidateQueries(listQuery);
        queryClient.invalidateQueries([EVENT_TABLE_QUERY_KEY.FORM_INPUT]);
      },
    }
  );
}

export function useDeleteEventData(filter: DisplayTableParams) {
  const queryClient = useQueryClient();
  const listQuery = [EVENT_TABLE_QUERY_KEY.DATA_LIST, { filter }];
  return useMutation<AxiosResponse, AxiosDefaultErrorEntity, DisplayTableDeletePayload>(
    ({ subjectId, ids }) =>
      axios.post(
        `/api/v1/event-table/delete?${qs.stringify({
          subjectId,
        })}`,
        ids
      ),
    {
      onMutate: async (bodyRequest) =>
        WithOptimisticUpdate<DisplayTableDeletePayload>(bodyRequest, listQuery, queryClient, 'DELETE'),
      onError: (err, variables, context) => {
        //@ts-ignore
        if (context?.previousData) {
          //@ts-ignore
          queryClient.setQueryData(listQuery, context.previousData);
        }
      },
      onSuccess(data, variables, context) {
        queryClient.refetchQueries(listQuery);
        queryClient.refetchQueries([EVENT_TABLE_QUERY_KEY.COLUMNS]);
      },
    }
  );
}

export function useSyncCubeEventData() {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosDefaultErrorEntity, { subjectEventId: string }>(
    (data) => axios.post(`/api/v1/tenant/cube/sync-event/${data.subjectEventId}`).then((res) => res),
    {
      onSuccess: (data, bodyRequest) => {
        queryClient.refetchQueries([EVENT_TABLE_QUERY_KEY.EVENT_SYNC_CUBE_ACTIVITY, bodyRequest.subjectEventId]);
        queryClient.invalidateQueries(EVENT_TABLE_QUERY_KEY.DATA_LIST);
      },
    }
  );
}

export function useUploadDataEventImportFile() {
  const queryClient = useQueryClient();
  return useMutation<
    AxiosResponse<{
      operationId: string;
    }>,
    AxiosDefaultErrorEntity,
    DataInputUploadFileImportRequest
  >(
    (bodyRequest) => {
      const { formData, fileType, subjectId, withSyncCube, operationType } = bodyRequest;
      return axios.post(
        `/api/v1/event-table/import/?${qs.stringify({
          fileType,
          subjectId,
          withSyncCube,
          operationType,
        })}`,
        formData
      );
    },
    {
      onSuccess: (data, variables) => {
        queryClient.refetchQueries([DISPLAY_TABLE_QUERY_KEY.DATA_INPUT_CURRENT_IMPORT_ACTIVITY, variables.subjectId]);
        queryClient.refetchQueries([DISPLAY_TABLE_QUERY_KEY.DATA_INPUT_IMPORT_HISTORY, variables.subjectId]);
      },
    }
  );
}

export function useGetCurrentCubeSyncEventActivity(subjectId?: string, options?: { refetchInterval: number | false }) {
  return useQuery<AxiosResponse<EventSyncCubeActivity>, AxiosDefaultErrorEntity>(
    [EVENT_TABLE_QUERY_KEY.EVENT_SYNC_CUBE_ACTIVITY, subjectId],
    () => {
      return axios.get(`/api/v1/event-table/sync-cube/${subjectId}/activity`).then((res) => res);
    },
    {
      enabled: !!subjectId,
      refetchInterval: options?.refetchInterval ?? false,
    }
  );
}
