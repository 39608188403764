export const PAGE_REF_NAVIGATION = {
  TOP: 'TopNavigation',
  RIGHT: 'RightNavigation',
  LEFT: 'LeftNavigation',
  BOTTOM: 'BottomNavigation',
};

export const getPageAnimationByRefNavigation = (refNavigation: string | null) => {
  if (!refNavigation) {
    return {
      initial: { opacity: 0 },
      animate: { opacity: 1 },
      exit: { opacity: 0 },
    };
  }
  switch (refNavigation) {
    case PAGE_REF_NAVIGATION.TOP:
      return {
        initial: { opacity: 0, y: -100 },
        animate: { opacity: 1, y: 0 },
        exit: { opacity: 0, y: 100 },
      };
    case PAGE_REF_NAVIGATION.RIGHT:
      return {
        initial: { opacity: 0, x: 350 },
        animate: { opacity: 1, x: 0, transition: { type: 'tween' } },
        exit: { opacity: 0, x: -350, transition: { type: 'tween' } },
      };

    case PAGE_REF_NAVIGATION.LEFT:
      return {
        initial: { opacity: 0, x: -350 },
        animate: { opacity: 1, x: 0, transition: { type: 'tween' } },
        exit: { opacity: 0, x: 350, transition: { type: 'tween' } },
      };

    case PAGE_REF_NAVIGATION.BOTTOM:
      return {
        initial: { opacity: 0, y: 100 },
        animate: { opacity: 1, y: 0 },
        exit: { opacity: 0, y: -100 },
      };
  }
};
