import { useMemo, useState } from 'react';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import { PaginationAndSortingParams } from 'types/api/Common/PaginationTypes';
import { dayjs } from 'utils/DateUtils';
import { ENV_CONFIG } from 'config/env';
import {
  DataGridPro,
  GridColumns,
  GridFilterModel,
  GridRowParams,
  GridValueFormatterParams,
  getGridDateOperators,
} from '@mui/x-data-grid-pro';
import { useGetDataInputImportHistory } from 'services/v1/Tenant/AROKMS/DisplayTableService';
import { DataInputImportActivity } from 'types/api/Tenant/AROKMS/DisplayTableTypes';

function getOperationTypeLabelDescriotion(operationType: string) {
  switch (operationType) {
    case 'OVERWRITE_DATA':
      return 'Overwrite existing data';
    case 'APPEND_DATA':
      return 'Append to existing data';
    default:
      return '';
  }
}

const ModalContentStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  bgcolor: 'background.paper',
  height: 'auto',
  overflow: 'auto',
  borderRadius: 1,
  py: 2,
};

const closeIconStyle = { color: '#98A2AE', cursor: 'pointer' };

const dataGridStyle = {
  '& .MuiCheckbox-root svg': {
    backgroundColor: 'transparent',
    color: '#98A2AE',
    borderRadius: 2,
  },
  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: '#EDF2FF',
    borderTop: '1px solid #E3EBF6',
    borderBottom: '1px solid #E3EBF6',
    textAlign: 'center',
    display: 'flex',
  },
};

export interface ModalImportDataHistoryProps {
  visible?: boolean;
  subjectId?: string;
  onClose?: () => void;
}

export function ModalImportDataHistory(props: Readonly<ModalImportDataHistoryProps>) {
  const { onClose, visible = false, subjectId } = props;

  const handleOnCloseModal = () => {
    onClose?.();
  };

  const [filter, setFilter] = useState<PaginationAndSortingParams>({
    page: 0,
    size: 10,
    filterValue: '',
    sortType: 'desc',
    sortBy: 'activityDate',
    filterColumn: 'fullName',
    filterOperator: 'contains',
  });

  const { data: importHistory, isLoading: isLoadingUserActivity } = useGetDataInputImportHistory(subjectId);

  const columns: GridColumns = useMemo(
    () => [
      {
        field: 'totalRecords',
        headerName: 'Total Records',
        valueFormatter: (params: GridValueFormatterParams) => {
          return params.value?.toLocaleString();
        },
        width: 120,
      },
      {
        field: 'totalInsertedRecords',
        headerName: 'Total Inserted',
        valueFormatter: (params: GridValueFormatterParams) => {
          return params.value?.toLocaleString();
        },
        width: 120,
      },
      {
        field: 'totalFailedRecords',
        headerName: 'Total Failed',
        valueFormatter: (params: GridValueFormatterParams) => {
          return params.value?.toLocaleString();
        },
        width: 120,
      },
      {
        field: 'operationType',
        headerName: 'Operation Type',
        valueFormatter: (params: GridValueFormatterParams) => {
          return getOperationTypeLabelDescriotion(params.value as string);
        },
        width: 180,
      },
      {
        field: 'status',
        headerName: 'Import Status',
        width: 150,
      },

      {
        field: 'createdAt',
        headerName: 'Created Date',
        width: 150,
        valueFormatter: (params: GridValueFormatterParams) => {
          return dayjs(params.value).format(ENV_CONFIG.config.DATE_TIME_FORMAT);
        },
        filterOperators: getGridDateOperators().filter(
          (operator) => !['onOrAfter', 'onOrBefore', 'isEmpty', 'isNotEmpty'].includes(operator.value)
        ),
      },
      {
        field: 'createdBy',
        headerName: 'Created By',
        valueFormatter: (params: GridValueFormatterParams) => {
          return params.value?.toLocaleString();
        },
        width: 120,
      },
      {
        field: 'channelName',
        headerName: 'Data Channel',
        valueFormatter: (params: GridValueFormatterParams) => {
          return params.value?.toLocaleString();
        },
        width: 120,
      },
      {
        field: 'ax',
        type: 'actions',
        headerName: 'Action',
        width: 200,
        getActions: (params: GridRowParams<DataInputImportActivity>) => {
          if (!params.row.failedRecordsFileUrl) return [];

          return [
            <Button
              variant='outlined'
              color='error'
              key={params.id + 'view'}
              size='small'
              startIcon={<CloudDownloadOutlinedIcon />}
              onClick={() => {
                window.open(params.row.failedRecordsFileUrl, '_blank');
              }}
              sx={{
                p: 1.5,
                height: 29,
              }}
            >
              Download File
            </Button>,
          ];
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleChangePage = (newPage: number) => {
    setFilter({ ...filter, page: newPage + 1 });
  };
  const handleChangePageSize = (newPageSize: number) => {
    setFilter({ ...filter, size: newPageSize });
  };

  const handleOnFilter = (params: GridFilterModel) => {
    let filterObj = {};
    if (params.items.length) {
      filterObj = {
        filterValue: params.items[0].value,
        filterOperator: params.items[0].operatorValue,
        filterColumn: params.items[0].columnField,
      };
    } else {
      filterObj = {
        filterValue: '',
        filterOperator: 'contains',
      };
    }
    setFilter({
      ...filter,
      ...filterObj,
    });
  };

  return (
    <div>
      <Modal open={visible} onClose={handleOnCloseModal}>
        <Stack direction='column' sx={ModalContentStyle}>
          <Stack px={2} justifyContent='space-between' direction='row' alignItems='center'>
            <Typography variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
              Import History
            </Typography>
            <IconButton onClick={handleOnCloseModal} component='label'>
              <HighlightOffRoundedIcon sx={closeIconStyle} />
            </IconButton>
          </Stack>
          <Divider sx={{ mb: 1, mt: 0 }} />
          <Stack height={450} px={2}>
            <DataGridPro
              sx={dataGridStyle}
              autoHeight={false}
              loading={isLoadingUserActivity}
              pagination
              disableMultipleColumnsFiltering
              paginationMode='client'
              density='compact'
              pageSize={filter.size}
              rowCount={importHistory?.data?.length}
              page={filter.page || 0}
              rows={importHistory?.data ?? []}
              columns={columns}
              filterMode='client'
              checkboxSelection={false}
              onFilterModelChange={handleOnFilter}
              onPageChange={handleChangePage}
              onPageSizeChange={handleChangePageSize}
            />
          </Stack>
        </Stack>
      </Modal>
    </div>
  );
}
