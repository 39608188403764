import * as Yup from 'yup';

export const CubeDefinitionPullEventAttributeValidationSchema = Yup.object().shape({
  parameters: Yup.array().of(
    Yup.object().shape({
      memberAttribute: Yup.object().required('Required').nullable(),
      timeAttribute: Yup.object().required('Required').nullable(),
    })
  ),
});
