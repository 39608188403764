import { GridPreProcessEditCellProps, GridRenderEditCellParams, GridValueFormatterParams } from '@mui/x-data-grid-pro';
import { DataGridCellTextInput } from 'components/DatatableComponent/RenderCustomTextInput';
import { renderNumberInputEditCell } from 'components/DatatableComponent/CustomNumberInput';

// Rule Type: Curency

// Helper function for formatting values
const formatValue = (params: GridValueFormatterParams, prefix: string, suffix: string) => {
  if (params.value === undefined || params.value === null) return '';
  const value = Number(params.value);

  const formattedValue = value.toLocaleString();
  const formattedPrefix = prefix ? `${prefix.toUpperCase()} ` : '';
  const formattedSuffix = suffix ? ` ${suffix.toUpperCase()}` : '';

  return `${formattedPrefix}${formattedValue}${formattedSuffix}`;
};

// #RULE_CURRENCY
export function RuleCurrencyInput(prefix: string, suffix: string, isRequired: boolean = false) {
  return {
    type: 'number',

    renderEditCell: (params: GridRenderEditCellParams) =>
      // @ts-ignore
      renderNumberInputEditCell({ ...params, prefix, suffix, isRequired }),
    valueFormatter: (params: GridValueFormatterParams) => formatValue(params, prefix, suffix),
  };
}

// #RULE_CURRENCY_RANGE
export function RuleCurrencyInputRange(
  prefix: string,
  suffix: string,
  minValue: number | undefined,
  maxValue: number | undefined,
  isRequired: boolean = false
) {
  return {
    type: 'number',
    renderEditCell: (params: GridRenderEditCellParams) => {
      // @ts-ignore
      return renderNumberInputEditCell({ ...params, prefix, suffix, min: minValue, max: maxValue, isRequired });
    },
    valueFormatter: (params: GridValueFormatterParams) => formatValue(params, prefix, suffix),
  };
}

// #RULE_CURRENCY_OPERATION
export function RuleCurrencyOperation(
  operation: (values: number[]) => number,
  columns: string[],
  prefix: string,
  suffix: string
) {
  return {
    renderEditCell: (params: GridRenderEditCellParams) => <DataGridCellTextInput {...params} />,
    preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
      const values = columns.map((field) => {
        const fieldValue = params?.otherFieldsProps?.[field].value || 0;
        return fieldValue ? Number(fieldValue) : 0;
      });

      const value = operation(values);

      return { ...params.props, value };
    },
    valueFormatter: (params: GridValueFormatterParams) => formatValue(params, prefix, suffix),
  };
}

// Examples of using RuleCurrencyOperation function
export const RuleCurrencyAddition = (columns: string[], prefix: string, suffix: string) =>
  RuleCurrencyOperation((values) => values.reduce((a, b) => a + b, 0), columns, prefix, suffix);

export const RuleCurrencySubstractuion = (columns: string[], prefix: string, suffix: string) =>
  RuleCurrencyOperation((values) => values.reduce((a, b) => a - b), columns, prefix, suffix);

export const RuleCurrencyMultipication = (columns: string[], prefix: string, suffix: string) =>
  RuleCurrencyOperation((values) => values.reduce((a, b) => a * b, 1), columns, prefix, suffix);

export const RuleCurrencyDivision = (columns: string[], prefix: string, suffix: string) =>
  RuleCurrencyOperation((values) => values.reduce((a, b) => a / b), columns, prefix, suffix);
