import { GridRenderCellParams } from '@mui/x-data-grid-pro';

import { renderDataGridAttachment } from 'components/DatatableComponent/RenderAttachment';

export function renderAttachment() {
  return {
    type: 'text',
    editable: false,
    filterable: false,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => {
      // @ts-ignore
      return renderDataGridAttachment({
        ...params,
      });
    },
  };
}
