import { forwardRef } from 'react';
import Button from '@mui/material/Button';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import Stack from '@mui/material/Stack';
import { AttributeDefinitionOptionsMenu } from './AttributeDefinitionOptionsMenu';

interface SettingPanelProps {
  disabled?: boolean;
  ref?: any;
  onAddButtonClick?: () => void;
  onConfigButtonClick?: (type: string) => void;
}

export const StandardViewAttributeDefinitionPanel = forwardRef((props: SettingPanelProps, ref) => {
  const { onAddButtonClick, onConfigButtonClick, disabled } = props;

  const handleOnConfigClick = (type: string) => {
    onConfigButtonClick?.(type);
  };
  const handleAddData = () => {
    onAddButtonClick?.();
  };
  return (
    <Stack direction='column' sx={{ backgroundColor: '#fff', py: 1.8 }}>
      <Stack direction='row' justifyContent='space-between' spacing={0} sx={{ backgroundColor: '#fff', px: 2 }}>
        <Stack direction='row' spacing={1} alignItems='center'></Stack>
      </Stack>
      <Stack
        direction='row'
        alignItems='center'
        sx={{ bgcolor: '#FBFBFB', py: 1.5, px: 2, border: '1px solid #E3EBF6' }}
      >
        <Stack direction='row' justifyContent='space-between' width='100%'>
          <Stack direction='row' spacing={5} alignItems='center'>
            <Button
              variant='main-table-panel'
              disabled={disabled}
              startIcon={<AddCircleOutlineOutlinedIcon />}
              onClick={handleAddData}
            >
              Add New Attribute
            </Button>
          </Stack>
          <AttributeDefinitionOptionsMenu onOptionClick={handleOnConfigClick} disabled={disabled} />
        </Stack>
      </Stack>
    </Stack>
  );
});
