import _ from 'lodash';
import { QueryClient, QueryKey } from 'react-query';

export async function WithOptimisticUpdate<T>(
  payload: { id: number | string } | T,
  listQuery: QueryKey,
  queryClient: QueryClient,
  operation: 'UPDATE' | 'DELETE'
) {
  await queryClient.cancelQueries(listQuery);
  const previousData = queryClient.getQueryData(listQuery);

  queryClient.setQueryData(listQuery, (oldData: any) => {
    const old = _.cloneDeep(oldData);
    const bodyRequest = _.cloneDeep(payload);

    if (!old) return null;
    if (operation === 'DELETE') {
      return {
        ...old,
        data: {
          //@ts-ignore
          data: old.data.data.filter((item: any) => item.id !== bodyRequest?.id),
        },
      };
    }
    //@ts-ignore
    if (!bodyRequest?.id) bodyRequest.id = _.uniqueId();

    //@ts-ignore
    const index = old.data.data.findIndex((item: any) => item.id === bodyRequest?.id);
    if (index !== -1) {
      old.data.data = old.data.data.map((item: any) => {
        //@ts-ignore
        if (item.id === bodyRequest?.id) {
          return {
            ...item,
            ...bodyRequest,
          };
        }
        return item;
      });
    } else {
      old.data.data = [bodyRequest, ...old.data.data];
    }
    return old;
  });

  return { previousData, query: listQuery };
}
