import { useEffect, useState } from 'react';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import ButtonLoading from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';

import { RectangleCheckBoxComponent } from 'components/Form/RectangleCheckBoxComponent';
import { useGetAllMyViewItemList, useSavePeopleMyViewConfig } from 'services/v1/Tenant/ConfigurationService';
import { MyViewItemConfig } from 'types/api/Tenant/ConfigurationTypes';
import ActivityIndicator from 'components/ActivityIndicatorComponent';

const ModalContentStyle = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 850,
  bgcolor: 'background.paper',
  maxHeight: 600,
  overflow: 'auto',
  borderRadius: 1,
  py: 2,
};

const closeIconStyle = { color: '#98A2AE', cursor: 'pointer' };

export interface ModalPeopleAddComponentProps {
  visible?: boolean;
  onClose?: () => void;
  selectedUser: { id: string; fullName: string } | null;
  selectedItemIds: Set<string>;
}

const getComponentTypeLabel = (componentType: string) => {
  switch (componentType) {
    case 'K_TREE':
      return 'K-Tree';
    case 'SUBJECT':
      return 'Subject';
    case 'EVENT':
      return 'Event';
    case 'CUBE':
      return 'Cube';
    case 'INSIGHT_VIEW':
      return 'Insight View';
    case 'STANDARD_VIEW':
      return 'Standard View';
    case 'DASHBOARD':
      return 'Dashboard';
    default:
      return '';
  }
};

export function ModalPeopleAddComponent(props: Readonly<ModalPeopleAddComponentProps>) {
  const { onClose, visible = false, selectedUser, selectedItemIds } = props;

  const [selectedItem, setSelectedItem] = useState<Set<string>>(new Set());

  const { isLoading, data: myViewItems } = useGetAllMyViewItemList();

  const { mutate: savePeopleMyViewConfig, isLoading: isSubmitting } = useSavePeopleMyViewConfig();

  const handleOnCloseModal = () => {
    setSelectedItem(selectedItemIds);
    onClose?.();
  };

  const handleOnCancel = () => {
    handleOnCloseModal();
  };

  const handleOnSave = () => {
    const data =
      myViewItems?.data
        ?.filter((item) => selectedItem.has(item.id))
        .map((item) => {
          if (item.subjectId) {
            const id = item.id.split('#')[0];
            return {
              id,
              componentType: item.componentType,
              subjectId: item.subjectId,
            };
          }
          return {
            id: item.id,
            componentType: item.componentType,
          };
        }) || [];

    if (selectedUser?.id) {
      savePeopleMyViewConfig(
        {
          userId: selectedUser.id,
          myViewItems: data,
        },
        {
          onSuccess: () => {
            handleOnCloseModal();
          },
        }
      );
    }
  };

  const handleOnClick = (item: MyViewItemConfig) => {
    setSelectedItem((prevState) => {
      if (prevState.has(item.id)) {
        prevState.delete(item.id);
      } else {
        prevState.add(item.id);
      }

      return new Set(prevState);
    });
  };

  useEffect(() => {
    setSelectedItem(new Set(selectedItemIds));
  }, [selectedItemIds]);

  return (
    <div>
      <Modal open={visible} onClose={handleOnCloseModal}>
        <Stack direction='column' sx={ModalContentStyle} px={2}>
          <Stack>
            <Stack justifyContent='space-between' direction='row' alignItems='center'>
              <Typography variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
                Add/Remove myView Items
              </Typography>
              <IconButton onClick={handleOnCloseModal} component='label'>
                <HighlightOffRoundedIcon sx={closeIconStyle} />
              </IconButton>
            </Stack>
          </Stack>
          <Divider sx={{ mb: 1 }} />
          <Stack width='70%'>
            <Typography variant='input-label-gray' mb={1}>
              Select the items you want to assign to {selectedUser?.fullName}
            </Typography>
          </Stack>
          <Stack
            direction='row'
            gap={1}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 1,
              flexWrap: 'wrap',
            }}
          >
            {isLoading ? (
              <Stack height={450}>
                <ActivityIndicator />
              </Stack>
            ) : (
              myViewItems?.data?.map((item) => (
                <RectangleCheckBoxComponent
                  checked={selectedItem.has(item.id)}
                  key={item.id}
                  secondLabel={getComponentTypeLabel(item.componentType)}
                  text={item.displayName}
                  onClick={() => handleOnClick(item)}
                />
              ))
            )}
          </Stack>
          <Stack px={2}>
            <Divider sx={{ mb: 1, mt: 2 }} />
            <Stack direction='row' justifyContent='flex-end' spacing={2} alignItems='center' sx={{ py: 1 }}>
              <Button variant='main-table-panel-border' onClick={handleOnCancel}>
                Cancel
              </Button>
              <ButtonLoading variant='main-table-panel' loading={isSubmitting} onClick={() => handleOnSave()}>
                Assign
              </ButtonLoading>
            </Stack>
          </Stack>
        </Stack>
      </Modal>
    </div>
  );
}
