import { Stack, SvgIcon, Typography } from '@mui/material';
import { ReactComponent as CustomIconArchiveError } from 'assets/icons/icon-archive-error.svg';
import { AxiosDefaultErrorEntity } from 'types';
export function ErrorBoxComponent(props: { error?: AxiosDefaultErrorEntity | null }) {
  const { error } = props;
  return (
    <Stack justifyContent='space-between' direction='row' spacing={2} px={3} py={1.5}>
      <Stack justifyContent='center' alignItems='center' direction='column' width='100%'>
        <SvgIcon
          sx={{ fill: 'transparent', color: '#fff', fontSize: 80 }}
          inheritViewBox
          component={CustomIconArchiveError}
        />
        <Typography variant='h4' mt={3} sx={{ fontWeight: 'bold' }}>
          Oops! Something went wrong...
        </Typography>
        <Stack width='50%'>
          <Typography variant='input-label' mt={1}>
            An error occurred while processing your request. If you need help or this error persists, please contact our
            support team for assistance.
          </Typography>
          {error?.response?.data.requestId && (
            <>
              <Typography variant='input-label' mt={1} fontWeight={700}>
                Request ID:
              </Typography>
              <Typography variant='input-label' mt={1} color='red'>
                <code>{error?.response?.data.requestId}</code>
              </Typography>
            </>
          )}

          <Typography variant='input-label' mt={1} fontWeight={700}>
            Error message:
          </Typography>
          <pre style={{ whiteSpace: 'pre-wrap', color: 'red', fontSize: 15 }}>{error?.response?.data.message}</pre>
        </Stack>
      </Stack>
    </Stack>
  );
}
