import Snackbar from '@mui/material/Snackbar';
import { useState, useMemo, useEffect } from 'react';
import { dayjs } from 'utils/DateUtils';
import {
  GridColDef,
  GridRowModesModel,
  GridRowModes,
  GridRowEditStopParams,
  GridSelectionModel,
  GridRowId,
  GridFilterModel,
  GridSortModel,
  getGridStringOperators,
  getGridDateOperators,
  GridRenderCellParams,
} from '@mui/x-data-grid-pro';
import Alert, { AlertProps } from '@mui/material/Alert';

import { CustomDataTable } from 'components/DatatableComponent';
import { PaginationAndSortingParams } from 'types/api/Common/PaginationTypes';
import { AxiosDefaultErrorEntity } from 'types/api/Common/ErrorTypes';
import { getErrorMessage, getErrorTitle } from 'utils/Error';
import { useCreateSystem } from 'services/v1/TenantMaster/MasterTable/SystemTableDataService';
import { SystemItem } from 'types/api/TenantMaster/MasterTable/SystemTableDataTypes';
import { useTenantList } from 'services/v1/TenantMaster/MasterTable/TenantTableDataService';
import { MasterTableTenantItem } from 'types/api/TenantMaster/MasterTable/TenantTableDataTypes';
import { TenantHomeDashboardNavigationBar } from 'components/LayoutComponent/VerticalLayout/HeaderBar';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PATH_CONSTANT } from 'constant/PathConstant';
import { ButtonRecordEdit } from 'pages/Tenant/AROKMS/DataInput/components/ButtonRecordEdit';
import { ENV_CONFIG } from 'config/env';
import qs from 'query-string';
import { SettingPanel } from 'pages/Tenant/AROKMS/MemberList/components/MemberListPanel';

const panelStyle = {
  transform: 'translate3d(704px, 140.667px, 0px) !important',

  '& .MuiDataGrid-panelFooter': {
    display: 'none',
  },
};

const INDUSTRY_ABBN_MOCK = [
  {
    label: 'TR',
    value: 'Transportation',
  },
  {
    label: 'EM',
    value: 'Earthmoving',
  },
];

export default function TenantTableDataPage() {
  const [rowData, setRowData] = useState<MasterTableTenantItem[]>([]);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [selectedDataID, setSelectedDataID] = useState<GridRowId[]>([]);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [filter, setFilter] = useState<PaginationAndSortingParams>({
    page: searchParams.get('page') ? Number(searchParams.get('page')) : 1,
    size: searchParams.get('size') ? Number(searchParams.get('size')) : 20,
    filterValue: '',
    sortType: 'asc',
    sortBy: 'tenantName',
    filterOperator: 'contains',
    filterColumn: 'tenantName',
  });

  const [filterButtonElement, setFilterButtonElement] = useState<HTMLButtonElement | null>(null);
  const [snackbar, setSnackbar] = useState<Pick<AlertProps, 'children' | 'severity'> | null>(null);

  const { data: systemList, isLoading, refetch: refreshAttributeData, isError, error } = useTenantList(filter);
  const { mutate: createSystem } = useCreateSystem();

  const columns = useMemo<GridColDef[]>(() => {
    return [
      {
        field: 'action',
        type: 'actions',
        headerName: 'Edit',
        width: 30,
        editable: false,
        filterable: false,
        renderCell: (params: GridRenderCellParams<MasterTableTenantItem>) => {
          return (
            <ButtonRecordEdit
              {...params}
              onClick={() => {
                navigate(
                  `${PATH_CONSTANT.TENANT_MASTER.MASTER_TABLE.EDIT_TENANT.replace(
                    ':id',
                    params.row.id
                  )}?refBackParam=${encodeURIComponent(window.location.pathname + window.location.search)}`
                );
              }}
            />
          );
        },
      },
      {
        field: 'tenantCode',
        headerName: 'Tenant',
        filterable: false,
        width: 130,
        editable: true,
        filterOperators: getGridStringOperators().filter((operator) => ['contains', 'equals'].includes(operator.value)),
      },
      {
        field: 'tenantName',
        headerName: 'Tenant Name',
        filterable: false,
        width: 180,
        editable: false,
        filterOperators: getGridStringOperators().filter((operator) => ['contains', 'equals'].includes(operator.value)),
      },
      {
        field: 'displayName',
        headerName: 'Display Name',
        filterable: false,
        width: 230,
        editable: false,
        filterOperators: getGridStringOperators().filter((operator) => ['contains', 'equals'].includes(operator.value)),
      },
      {
        field: 'organizationCode',
        headerName: 'Organization',
        width: 100,
        editable: true,
        filterOperators: getGridStringOperators().filter((operator) => ['contains', 'equals'].includes(operator.value)),
      },
      {
        field: 'organizationName',
        headerName: 'Organization Name',
        width: 150,
        editable: true,
        filterOperators: getGridStringOperators().filter((operator) => ['contains', 'equals'].includes(operator.value)),
      },
      {
        field: 'systemCode',
        headerName: 'System',
        width: 130,
        editable: false,
        filterOperators: getGridStringOperators().filter((operator) => ['contains', 'equals'].includes(operator.value)),
      },
      {
        field: 'systemName',
        headerName: 'System Name',
        width: 200,
        editable: false,
        filterOperators: getGridStringOperators().filter((operator) => ['contains', 'equals'].includes(operator.value)),
      },
      {
        field: 'serverName',
        headerName: 'Server',
        width: 200,
        editable: false,
        filterOperators: getGridStringOperators().filter((operator) => ['contains', 'equals'].includes(operator.value)),
      },
      {
        field: 'status',
        headerName: 'Status',
        width: 200,
        editable: false,
        renderCell: (params) => {
          return <p>{params.value}</p>;
        },
        filterOperators: getGridStringOperators().filter((operator) => ['contains', 'equals'].includes(operator.value)),
      },
      {
        field: 'createdBy',
        headerName: 'Created By',
        editable: false,
        width: 170,
        filterOperators: getGridStringOperators().filter((operator) => ['contains', 'equals'].includes(operator.value)),
      },
      {
        field: 'createdDate',
        headerName: 'Created Date',
        valueFormatter: (params) => {
          if (!params.value) return '';
          return `${dayjs(params.value).format(ENV_CONFIG.config.DATE_FORMAT)}`;
        },
        editable: false,
        width: 170,
        type: 'date',
        filterOperators: getGridDateOperators().filter(
          (operator) => !['onOrAfter', 'onOrBefore', 'isEmpty', 'isNotEmpty'].includes(operator.value)
        ),
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const DataMutationSuccessHandlerOption = {
    onSuccess: () => {
      refreshAttributeData();
    },
    onError: (error: AxiosDefaultErrorEntity) => {
      setSnackbar({ children: getErrorMessage(error), severity: 'error' });
    },
  };

  useEffect(() => {
    if (systemList?.data?.data) {
      setRowData(systemList?.data?.data);
      setRowModesModel(
        systemList?.data?.data?.reduce((acc, item) => {
          return { ...acc, [item.id]: GridRowModes.View };
        }, {})
      );
    }
  }, [systemList]);

  useEffect(() => {
    if (isError) {
      setSnackbar({ children: getErrorMessage(error), severity: 'error' });
    }
  }, [isError, error]);

  const handleChangePage = (newPage: number) => {
    setFilter({ ...filter, page: newPage + 1 });
    navigate({
      search: qs.stringify({
        ...filter,
        page: newPage + 1,
      }),
    });
  };
  const handleChangePageSize = (newPageSize: number) => {
    setFilter({ ...filter, size: newPageSize });
    navigate({
      search: qs.stringify({
        ...filter,
        size: newPageSize,
        page: 1,
      }),
    });
  };

  const handleOnFilter = (params: GridFilterModel) => {
    let filterObj = {};
    if (params.items.length) {
      filterObj = {
        filterValue: params.items[0].value,
        filterOperator: params.items[0].operatorValue,
        filterColumn: params.items[0].columnField,
      };
    } else {
      filterObj = {
        filterValue: '',
        filterOperator: 'contains',
      };
    }
    setFilter({
      ...filter,
      ...filterObj,
    });
  };

  const handleOnSortModelChange = (model: GridSortModel) => {
    if (model.length === 0) {
      setFilter({ ...filter, sortType: 'asc', sortBy: 'systemName' });
      return;
    }

    setFilter({ ...filter, sortType: model[0].sort, sortBy: model[0].field });
  };

  // Process row Update will be triggered when
  // user modify or insert new row the table
  const handleProcessRowUpdate = async (data: SystemItem, oldData: SystemItem) => {
    const isEmpty = !data.industry || !data.version;

    console.log(isEmpty, 'isEmpty', data);
    if (isEmpty) {
      handleCancelInsert(data.id);
      return;
    }
    if (data.isNew) {
      // if the row is new and not empy, then insert the row to database
      createSystem(
        {
          industryAbbn: INDUSTRY_ABBN_MOCK.find((item) => item?.value === data.industry)?.label || '',
          industry: data.industry,
          version: data.version,
        },
        DataMutationSuccessHandlerOption
      );
      return { ...data, isNew: false };
    }
    return data;
  };

  const handleCancelInsert = (id: string) => {
    const newRows = rowData.filter((item) => item.id !== id);
    setRowData(newRows);
    setRowModesModel((prev) => {
      const newModel = { ...prev };
      delete newModel[id];
      return newModel;
    });
  };

  const handleRowEditStop = (params: GridRowEditStopParams) => {
    setRowModesModel({ ...rowModesModel, [params.id]: { mode: GridRowModes.View } });
  };

  const handleCloseSnackbar = () => setSnackbar(null);

  const handleOnButtonInsertClick = () => {
    navigate(PATH_CONSTANT.TENANT_MASTER.MASTER_TABLE.ADD_TENANT);
  };

  return (
    <TenantHomeDashboardNavigationBar>
      <CustomDataTable
        initialState={{
          pinnedColumns: {
            left: ['action'],
          },
          pagination: {
            page: 0,
          },
          sorting: {
            sortModel: [
              {
                field: 'systemName',
                sort: 'asc',
              },
            ],
          },
        }}
        isError={isError}
        errorMessage={error && getErrorMessage(error)}
        errorTitle={getErrorTitle(error)}
        loading={isLoading}
        rows={rowData}
        columns={columns}
        rowCount={systemList?.data?.rowCount}
        page={systemList?.data?.page?.pageNumber || 0}
        pageSize={filter.size}
        getRowId={(row) => row.id}
        rowModesModel={rowModesModel}
        disableSelectionOnClick
        rowsPerPageOptions={[5, 10, 20]}
        getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
        experimentalFeatures={{ newEditingApi: true }}
        onFilterModelChange={handleOnFilter}
        onSortModelChange={handleOnSortModelChange}
        checkboxSelection={false}
        onPageChange={handleChangePage}
        onPageSizeChange={handleChangePageSize}
        onSelectionModelChange={(selectedId: GridSelectionModel) => {
          setSelectedDataID(selectedId);
        }}
        processRowUpdate={handleProcessRowUpdate}
        onRowEditStop={handleRowEditStop}
        components={{
          Toolbar: SettingPanel,
        }}
        componentsProps={{
          panel: {
            sx: panelStyle,
            anchorEl: filterButtonElement,
          },
          toolbar: {
            setRows: setRowData,
            setRowModesModel,

            setFilterButtonElement: setFilterButtonElement,
            disabled: isLoading || rowData.some((item) => item.isNew),
            disabledDeleteButton: selectedDataID.length === 0,
            onAddDataClick: handleOnButtonInsertClick,
            config: {
              withExport: false,
              deleteButtonText: 'Delete',
              fieldFocusOnInsert: 'version',
              insertButtonText: 'Add New Tenant',
              insertInitialObject: {
                systemCode: null,
                systemName: null,
                industry: null,
                industryAbbn: null,
                status: null,
                version: null,
                createdBy: null,
                createdDate: null,
                isNew: true,
              },
            },
          },
        }}
      />

      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          onClose={handleCloseSnackbar}
          autoHideDuration={3000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
    </TenantHomeDashboardNavigationBar>
  );
}
