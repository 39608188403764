import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import { SelectRole } from './SelectRole';

import { SIDEBAR_ITEMS } from './SidebarItems';
import { RootState } from 'store';

import { MenuItem } from './MenuItem';
import { RoleBasedAccessProvider } from 'components/RBAC';
import { ROLES } from 'constant/PermissonConstant';

const drawerWidth = 230;

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export function AppSidebar() {
  const { isSidebarOpen } = useSelector((state: RootState) => state.ui);

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,

        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',

          '&::-webkit-scrollbar': {
            width: '4px',
          },
          '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#b0aeae',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: '#555',
          },
        },
      }}
      variant='persistent'
      anchor='left'
      open={isSidebarOpen}
    >
      <DrawerHeader>
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
          <img
            src='/images/main-logo.svg'
            alt='logo'
            style={{
              width: '100px',
              height: 'auto',
            }}
          />
        </div>
      </DrawerHeader>

      <Divider />
      <Box sx={{ backgroundColor: '#232950', height: '100vh' }}>
        <RoleBasedAccessProvider
          allowedRoles={[
            ROLES.BUILDER,
            ROLES.ADMIN,
            ROLES.MANAGER,
            ROLES.TRAINER,
            ROLES.TESTER,
            ROLES.PACKAGER,
            ROLES.SUPPORT,
          ]}
        >
          <SelectRole />
        </RoleBasedAccessProvider>
        {SIDEBAR_ITEMS.map((item, key: number) => (
          // @ts-ignore
          <MenuItem item={item} key={key} />
        ))}
      </Box>
    </Drawer>
  );
}
