import { DataInputImportActivityStatus } from 'types/api/Tenant/AROKMS/DisplayTableTypes';

export function isImportingData(importActivityStatus: DataInputImportActivityStatus | null) {
  if (!importActivityStatus) return false;
  return (
    importActivityStatus === DataInputImportActivityStatus.ON_PROGRESS ||
    importActivityStatus === DataInputImportActivityStatus.PENDING ||
    importActivityStatus === DataInputImportActivityStatus.READING_DATA ||
    importActivityStatus === DataInputImportActivityStatus.VALIDATING_DATA ||
    importActivityStatus === DataInputImportActivityStatus.CONNECTING
  );
}
