import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Radio from '@mui/material/Radio';
import ActivityIndicator from 'components/ActivityIndicatorComponent';
import { TenantConfiguration } from 'types/api/Tenant/ConfigurationTypes';
import { useGetTenantConfigData, useSaveTenantConfigData } from 'services/v1/Tenant/ConfigurationService';
import { getErrorMessage } from 'utils/Error';

import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { CONFIG_FLAG_VALUE, TENANT_CONFIGURATION_KEY } from 'constant/TenantConfigConstant';
const radioButtonLabelStyle = {
  '& .MuiFormControlLabel-label': {
    fontSize: '14px',
    color: '#98A2AE',
  },
};

const radioButtonStyle = {
  color: '#98A2AE',
  '& .MuiSvgIcon-root': {
    fontSize: 20,
  },
  '&.Mui-checked': {
    color: '#42BB93',
  },
};

interface DataImportConfigurationState {
  allowDuplicationValidation: 'ENABLE' | 'DISABLE';
  allowOverrideValidation: 'ENABLE' | 'DISABLE';
}

export const DataImportConfigurationPage = () => {
  const [dataImportConfig, setDataImportConfig] = useState<DataImportConfigurationState | null>(null);
  const { mutate } = useSaveTenantConfigData();
  const { data, isLoading } = useGetTenantConfigData({ refetchInterval: false });
  const handleOnSubmit = () => {
    if (!dataImportConfig) return;
    const payload: TenantConfiguration[] = [
      {
        id: TENANT_CONFIGURATION_KEY.ENABLE_DUPLICATION_VALIDATION_EVENT_DATA_IMPORT,
        value: dataImportConfig.allowDuplicationValidation,
      },
      {
        id: TENANT_CONFIGURATION_KEY.ENABLE_OVERRIDE_VALIDATION_EVENT_DATA_IMPORT,
        value: dataImportConfig.allowOverrideValidation,
      },
    ];
    mutate(payload, {
      onError: (error) => {
        toast.dismiss();
        toast.error(getErrorMessage(error));
      },
    });
  };

  useEffect(() => {
    if (data?.data) {
      const duplicationValidation = data.data.find(
        (item) => item.id === TENANT_CONFIGURATION_KEY.ENABLE_DUPLICATION_VALIDATION_EVENT_DATA_IMPORT
      );
      const overrideValidation = data.data.find(
        (item) => item.id === TENANT_CONFIGURATION_KEY.ENABLE_OVERRIDE_VALIDATION_EVENT_DATA_IMPORT
      );
      setDataImportConfig({
        allowDuplicationValidation: duplicationValidation?.value as 'ENABLE' | 'DISABLE',
        allowOverrideValidation: overrideValidation?.value as 'ENABLE' | 'DISABLE',
      });
    }
  }, [data?.data]);

  useEffect(() => {
    if (dataImportConfig === null) return;
    if (dataImportConfig.allowDuplicationValidation && dataImportConfig.allowOverrideValidation) {
      handleOnSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataImportConfig]);

  return (
    <Box sx={{ bgcolor: '#fff', px: 1, py: 2, width: '100%' }}>
      <Stack mb={2} width='100%'>
        <Typography variant='h6' color='text.primary' fontWeight='bold'>
          Data Import Configuration
        </Typography>
        <Divider sx={{ mt: 1 }} />
      </Stack>
      {isLoading || !dataImportConfig ? (
        <Stack direction='column' justifyContent='center'>
          <ActivityIndicator />
        </Stack>
      ) : (
        <>
          <Stack direction='column' display='flex' width={600} gap={2}>
            <Stack>
              <Typography variant='h4' color='text.primary' fontWeight='bold'>
                Record Duplication Validation
              </Typography>
              <Typography variant='body2' color='text.secondary'>
                If enabled, the system will validate the record duplication during the data import process on a event
                table. The system will consider the record as a duplicate if all value across all columns are the same.
              </Typography>
              <Stack mt={1}>
                <RadioGroup
                  value={dataImportConfig.allowDuplicationValidation}
                  onChange={(event, value) => {
                    setDataImportConfig({
                      ...dataImportConfig,
                      allowDuplicationValidation: value as 'ENABLE' | 'DISABLE',
                    });
                  }}
                  name='allowDuplicationValidation'
                >
                  <Stack direction='row' spacing={1}>
                    <FormControlLabel
                      value={CONFIG_FLAG_VALUE.ENABLE}
                      sx={radioButtonLabelStyle}
                      control={<Radio sx={radioButtonStyle} />}
                      label='Enable'
                    />
                    <FormControlLabel
                      value={CONFIG_FLAG_VALUE.DISABLE}
                      sx={radioButtonLabelStyle}
                      control={<Radio sx={radioButtonStyle} />}
                      label='Disable'
                    />
                  </Stack>
                </RadioGroup>
              </Stack>
            </Stack>
            <Divider />
            <Stack>
              <Typography variant='h4' color='text.primary' fontWeight='bold'>
                Record Override Validation
              </Typography>
              <Typography variant='body2' color='text.secondary'>
                If enabled, the system will validate the record override during the data import process on a event
                table. The system will reject the record if the system found a record with the sampe date that had been
                upload before. The system reject the new record from the new import process and keep the old record.
              </Typography>
              <Stack mt={1}>
                <RadioGroup
                  value={dataImportConfig.allowOverrideValidation}
                  onChange={(event, value) => {
                    setDataImportConfig({
                      ...dataImportConfig,
                      allowOverrideValidation: value as 'ENABLE' | 'DISABLE',
                    });
                  }}
                  name='allowOverrideValidation'
                >
                  <Stack direction='row' spacing={1}>
                    <FormControlLabel
                      value={CONFIG_FLAG_VALUE.ENABLE}
                      sx={radioButtonLabelStyle}
                      control={<Radio sx={radioButtonStyle} />}
                      label='Enable'
                    />
                    <FormControlLabel
                      value={CONFIG_FLAG_VALUE.DISABLE}
                      sx={radioButtonLabelStyle}
                      control={<Radio sx={radioButtonStyle} />}
                      label='Disable'
                    />
                  </Stack>
                </RadioGroup>
              </Stack>
            </Stack>
          </Stack>
        </>
      )}
    </Box>
  );
};
