import { useState, useRef, useMemo, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Box, Chip, Grid, Stack, Typography } from '@mui/material';
import ButtonLoading from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import VisibilityOff from '@mui/icons-material/VisibilityOffOutlined';
import Visibility from '@mui/icons-material/VisibilityOutlined';
import { Formik, FormikProps } from 'formik';
import { TenantHomeDashboardNavigationBar } from 'components/LayoutComponent/VerticalLayout/HeaderBar';
import { ButtonBackToTop } from 'components/ButtonComponent/ButtonBackToTop';

import { valueSafeDecryptor, valueSafeEcryptor } from 'utils/Encryptor';
import { PATH_CONSTANT } from 'constant/PathConstant';
import {
  useAddServer,
  useGetServerDetails,
  useServerMemberListDropdown,
  useTestServerConnection,
} from 'services/v1/TenantMaster/MasterTable/ServerTableDataService';
import ContactlessOutlinedIcon from '@mui/icons-material/ContactlessOutlined';
import { DropdownItem } from 'types/api/SystemTenant/AROKMS/TableDefinitionTypes';
import { DropdownCreatableMemberList } from '../../../Tenant/AROKMS/DataInput/components/DropdownCreatableMemberList';
import { RULES_CONSTANT } from 'constant/RuleConstant';
import { Field } from 'types/api/Tenant/AROKMS/DisplayTableTypes';
import { useParams } from 'react-router-dom';
import ActivityIndicator from 'components/ActivityIndicatorComponent';

const textInputStyles = {
  width: '55%',
  '& .MuiOutlinedInput': {
    padding: '1px 2px',
  },
};

interface FormValue {
  serverName: DropdownItem | null;
  description: string;
  host: string;
  username: string;
  password: string;
  port: string;
}

const formInitialValues: FormValue = {
  serverName: null,
  description: '',
  host: '',
  username: '',
  password: '',
  port: '',
};
export default function EditServerTableData() {
  const { id } = useParams();
  const [showPassword, setShowPassword] = useState(false);
  const formikRef = useRef<FormikProps<FormValue>>(null);
  const navigate = useNavigate();

  const { data: serverMemberListOptions } = useServerMemberListDropdown();
  const { mutate: createNewServer, isLoading: isDeploying } = useAddServer();
  const { data: serverDetailsData, isLoading } = useGetServerDetails(id);
  const {
    mutate: testServerConnection,
    isLoading: isTesting,
    isSuccess: isServerConnected,
    isError: isServerConnectionError,
    error: serverConnectionError,
    data: serverConnectionData,
  } = useTestServerConnection();

  const handleOnTestConnection = () => {
    const payloadData = formikRef?.current?.values;
    payloadData &&
      payloadData.username &&
      payloadData.password &&
      payloadData.host &&
      testServerConnection({
        ...payloadData,
        serverName: payloadData?.serverName?.value as string,
        password: valueSafeEcryptor(payloadData?.password) as string,
        username: valueSafeEcryptor(payloadData?.username) as string,
      });
  };

  const handlePasswordToggle = () => {
    setShowPassword(!showPassword);
  };

  const handleOnSave = (data: FormValue) => {
    createNewServer(
      {
        ...data,
        id,
        serverName: data?.serverName?.value || '',
        password: valueSafeEcryptor(data?.password) as string,
        username: valueSafeEcryptor(data?.username) as string,
      },
      {
        onSuccess: () => {
          handleOnCancelInput();
        },
      }
    );
  };

  const isSemiLoading = isDeploying;

  const handleOnCancelInput = () => {
    navigate(PATH_CONSTANT.TENANT_MASTER.MASTER_TABLE.SERVER);
  };

  // @ts-ignore
  const fieldItem: Field = useMemo(() => {
    if (serverMemberListOptions) {
      return {
        name: 'serverName',
        options: serverMemberListOptions.data.options,
        label: 'Server',
        disabled: true,
        placeholder: 'Server Name',
        colSubjectId: serverMemberListOptions.data.colSubjectId,
      };
    }
    return {} as Field;
  }, [serverMemberListOptions]);

  useEffect(() => {
    if (formikRef.current) {
      if (serverDetailsData && serverMemberListOptions) {
        const { serverName, serverDescription, host, username, port, password } = serverDetailsData.data;
        const serverNameValue = serverMemberListOptions?.data.options.find((item) => item.value === serverName);
        formikRef?.current?.setValues({
          serverName: serverNameValue as DropdownItem,
          description: serverDescription,
          host,
          username,
          password: valueSafeDecryptor(password) || '',
          port,
        });
        handleOnTestConnection();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serverDetailsData, formikRef, serverMemberListOptions]);

  return (
    <TenantHomeDashboardNavigationBar>
      <ButtonBackToTop />
      <Box sx={{ height: '100%' }}>
        {isLoading ? (
          <Stack direction='column' sx={{ height: 500 }} justifyContent='center'>
            <ActivityIndicator />
          </Stack>
        ) : (
          <Formik innerRef={formikRef} initialValues={formInitialValues} onSubmit={handleOnSave}>
            {(formikProps: FormikProps<FormValue>) => {
              const { handleSubmit, values, handleChange, errors, touched, handleBlur } = formikProps;
              // @ts-ignore
              return (
                <Box sx={{ backgroundColor: '#fff', pb: 3, px: 3 }} alignItems='center'>
                  <Stack
                    direction='row'
                    alignItems='center'
                    py={1}
                    justifyContent='space-between'
                    sx={{ backgroundColor: '#fff' }}
                    spacing={2}
                  >
                    <Stack>
                      <Typography variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
                        Edit Server
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack>
                    <Typography variant='input-label' fontStyle='italic' sx={{ pt: 1, fontSize: 13 }}>
                      (<span style={{ color: 'red' }}>*</span>) indicates required fields
                    </Typography>
                  </Stack>

                  <Grid
                    container
                    marginTop={2}
                    sx={{ backgroundColor: '#fff', py: 1, borderTop: '1px solid #E3EBF6' }}
                    justifyContent='space-between'
                    display='flex'
                    flexWrap='wrap'
                    alignItems='flex-start'
                  >
                    <Grid container item xs={6} direction='column'>
                      <Stack>
                        <DropdownCreatableMemberList
                          field={fieldItem}
                          // @ts-ignore
                          formik={formikProps}
                          ruleTypeCode={RULES_CONSTANT.BASE_SUBJECT.RULE_ATTRIBUTE_SUBJECT_BASE}
                        />

                        <Stack
                          direction='row'
                          spacing={2}
                          alignItems='center'
                          justifyContent='space-between'
                          sx={{ mt: 2 }}
                        >
                          <Typography variant='input-label'>
                            Server Description
                            <span style={{ color: 'red', marginLeft: 2 }}>*</span>
                          </Typography>

                          <TextField
                            sx={textInputStyles}
                            size='small'
                            disabled={isSemiLoading}
                            multiline
                            placeholder='Your server description...'
                            onChange={handleChange}
                            value={values.description}
                            onBlur={handleBlur}
                            name='description'
                            error={touched.description && Boolean(errors.description)}
                            helperText={touched.description && errors.description}
                          />
                        </Stack>

                        <Stack
                          direction='row'
                          spacing={2}
                          alignItems='center'
                          justifyContent='space-between'
                          sx={{ mt: 2 }}
                        >
                          <Typography variant='input-label'>
                            Host
                            <span style={{ color: 'red', marginLeft: 2 }}>*</span>
                          </Typography>
                          <TextField
                            sx={textInputStyles}
                            size='small'
                            disabled={isSemiLoading}
                            placeholder='Example: server.yourdomain.com'
                            onChange={handleChange}
                            value={values?.host}
                            onBlur={handleBlur}
                            name='host'
                            error={touched.host && Boolean(errors.host)}
                            helperText={touched.host && errors.host}
                          />
                        </Stack>

                        <Stack
                          direction='row'
                          spacing={2}
                          alignItems='center'
                          justifyContent='space-between'
                          sx={{ mt: 2 }}
                        >
                          <Typography variant='input-label'>
                            Port
                            <span style={{ color: 'red', marginLeft: 2 }}>*</span>
                          </Typography>
                          <TextField
                            sx={textInputStyles}
                            size='small'
                            disabled={isSemiLoading}
                            placeholder='Example: 5432'
                            onChange={(e) => {
                              const value = e.target.value;
                              if (value.match(/^[0-9]*$/)) {
                                handleChange(e);
                              }
                            }}
                            onBlur={handleBlur}
                            value={values?.port}
                            name='port'
                            error={touched.port && Boolean(errors.port)}
                            helperText={touched.port && errors.port}
                          />
                        </Stack>
                        <Stack
                          direction='row'
                          spacing={2}
                          alignItems='center'
                          justifyContent='space-between'
                          sx={{ mt: 2 }}
                        >
                          <Typography variant='input-label'>
                            Username
                            <span style={{ color: 'red', marginLeft: 2 }}>*</span>
                          </Typography>
                          <TextField
                            sx={textInputStyles}
                            size='small'
                            disabled={isSemiLoading}
                            placeholder='Your database username'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.username}
                            name='username'
                            error={touched.username && Boolean(errors.username)}
                            helperText={touched.username && errors.username}
                          />
                        </Stack>

                        <Stack
                          direction='row'
                          spacing={2}
                          alignItems='center'
                          justifyContent='space-between'
                          sx={{ mt: 2 }}
                        >
                          <Typography variant='input-label'>
                            Password
                            <span style={{ color: 'red', marginLeft: 2 }}>*</span>
                          </Typography>
                          <TextField
                            sx={textInputStyles}
                            size='small'
                            disabled={isSemiLoading}
                            type={showPassword ? 'text' : 'password'}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder='Your database password'
                            value={values?.password}
                            name='password'
                            error={touched.password && Boolean(errors.password)}
                            helperText={touched.password && errors.password}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position='end'>
                                  <IconButton onClick={handlePasswordToggle} size='small'>
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Stack>
                        <Stack
                          direction='row'
                          spacing={2}
                          alignItems='center'
                          justifyContent='space-between'
                          sx={{ mt: 2 }}
                        >
                          <Typography variant='input-label'>Status</Typography>
                          <Stack sx={{ width: '55%' }}>
                            <Stack
                              direction='row'
                              alignItems='center'
                              justifyContent='space-between'
                              sx={{ width: '100%' }}
                            >
                              {serverConnectionData === undefined && !isServerConnectionError && (
                                <Typography variant='body2'>-</Typography>
                              )}
                              {serverConnectionData !== undefined && isServerConnected && (
                                <Chip label='Connected!' color='success' sx={{ width: '30%', bgcolor: '#42BB93' }} />
                              )}

                              {serverConnectionData === undefined && isServerConnectionError && (
                                // @ts-ignore
                                <Typography variant='body2' sx={{ color: '#BB4942' }}>
                                  {serverConnectionError?.response?.data?.message}
                                </Typography>
                              )}
                            </Stack>
                            <Stack sx={{ width: '50%' }} mt={2}>
                              <ButtonLoading
                                disabled={Object.values(values).some((value) => !value)}
                                variant='main-table-panel'
                                loading={isTesting}
                                startIcon={<ContactlessOutlinedIcon />}
                                onClick={handleOnTestConnection}
                              >
                                Test Connection
                              </ButtonLoading>
                            </Stack>
                          </Stack>
                        </Stack>
                      </Stack>
                    </Grid>
                  </Grid>
                  <Stack direction='row' spacing={2} justifyContent='flex-end'>
                    <Button disabled={isDeploying} onClick={handleOnCancelInput} variant='main-table-panel-border'>
                      Cancel
                    </Button>

                    <ButtonLoading
                      disabled={Object.values(values).some((value) => !value) || !isServerConnected}
                      variant='main-table-panel'
                      loading={isDeploying}
                      onClick={() => handleSubmit()}
                    >
                      Save
                    </ButtonLoading>
                  </Stack>
                </Box>
              );
            }}
          </Formik>
        )}
      </Box>
    </TenantHomeDashboardNavigationBar>
  );
}
