import { useMemo, useState } from 'react';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import ButtonLoading from '@mui/lab/LoadingButton';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import { PaginationAndSortingParams } from 'types/api/Common/PaginationTypes';
import { DataGridPro, GridColumns, GridFilterModel, GridRowId, GridValueFormatterParams } from '@mui/x-data-grid-pro';
import {
  useGetAsscoiatedSubjectFunction,
  useTriggerFunctionHardCalculation,
} from 'services/v1/Tenant/AROKMS/AssociateFunctionService';
import { toast } from 'react-toastify';

const ModalContentStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  bgcolor: 'background.paper',
  height: 'auto',
  overflow: 'auto',
  borderRadius: 1,
  py: 2,
};

const closeIconStyle = { color: '#98A2AE', cursor: 'pointer' };

const dataGridStyle = {
  '& .MuiCheckbox-root svg': {
    backgroundColor: 'transparent',
    color: '#98A2AE',
    borderRadius: 2,
  },
  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: '#EDF2FF',
    borderTop: '1px solid #E3EBF6',
    borderBottom: '1px solid #E3EBF6',
    textAlign: 'center',
    display: 'flex',
  },
};

export interface ModalAssociateFunctionProps {
  visible?: boolean;
  subjectId?: string;
  onClose?: () => void;
}

export function ModalAssociateFunction(props: Readonly<ModalAssociateFunctionProps>) {
  const { onClose, visible = false, subjectId } = props;

  const [selectedDataID, setSelectedDataID] = useState<GridRowId[]>([]);

  const [filter, setFilter] = useState<PaginationAndSortingParams>({
    page: 0,
    size: 10,
    filterValue: '',
    sortType: 'desc',
    sortBy: 'name',
    filterColumn: 'name',
    filterOperator: 'contains',
  });

  const { data: associatedSubjectFunction, isLoading: isLoadingData } = useGetAsscoiatedSubjectFunction(subjectId);
  const { mutate: hardCalculateFunction, isLoading: isSubmitting } = useTriggerFunctionHardCalculation();

  const columns: GridColumns = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Function Name',

        width: 300,
      },
      {
        field: 'cube',
        headerName: 'Cube Name',
        valueFormatter: (params: GridValueFormatterParams) => {
          return params.value?.label?.toLocaleString();
        },
        width: 300,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleOnCloseModal = () => {
    onClose?.();
    setSelectedDataID([]);
  };

  const handleChangePage = (newPage: number) => {
    setFilter({ ...filter, page: newPage + 1 });
  };
  const handleChangePageSize = (newPageSize: number) => {
    setFilter({ ...filter, size: newPageSize });
  };

  const handleOnFilter = (params: GridFilterModel) => {
    let filterObj = {};
    if (params.items.length) {
      filterObj = {
        filterValue: params.items[0].value,
        filterOperator: params.items[0].operatorValue,
        filterColumn: params.items[0].columnField,
      };
    } else {
      filterObj = {
        filterValue: '',
        filterOperator: 'contains',
      };
    }
    setFilter({
      ...filter,
      ...filterObj,
    });
  };

  const handleOnCalculateFunction = () => {
    hardCalculateFunction(
      { functionIds: selectedDataID as string[] },
      {
        onSuccess: () => {
          toast.success('Function calculation has been triggered successfully');
          setSelectedDataID([]);
        },
      }
    );
  };

  return (
    <div>
      <Modal open={visible} onClose={handleOnCloseModal}>
        <Stack direction='column' sx={ModalContentStyle}>
          <Stack px={2} justifyContent='space-between' direction='row' alignItems='center'>
            <Typography variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
              Associate Model
            </Typography>
            <IconButton onClick={handleOnCloseModal} component='label'>
              <HighlightOffRoundedIcon sx={closeIconStyle} />
            </IconButton>
          </Stack>
          <Divider sx={{ mb: 1, mt: 0 }} />
          <Stack height={350} px={2}>
            <DataGridPro
              sx={dataGridStyle}
              autoHeight={false}
              loading={isLoadingData}
              pagination
              disableMultipleColumnsFiltering
              paginationMode='client'
              density='compact'
              pageSize={filter.size}
              rowCount={associatedSubjectFunction?.data?.length}
              page={filter.page || 0}
              rows={associatedSubjectFunction?.data ?? []}
              columns={columns}
              selectionModel={selectedDataID}
              filterMode='client'
              checkboxSelection={true}
              onSelectionModelChange={(newSelection) => {
                setSelectedDataID(newSelection);
              }}
              onFilterModelChange={handleOnFilter}
              onPageChange={handleChangePage}
              onPageSizeChange={handleChangePageSize}
            />
          </Stack>
          <Stack direction='row' my={2} px={2} justifyContent='flex-end' alignItems='center'>
            <Stack direction='row' gap={2}>
              <ButtonLoading variant='main-table-panel-border' onClick={handleOnCloseModal}>
                Close
              </ButtonLoading>
              <ButtonLoading
                variant='main-table-panel'
                onClick={handleOnCalculateFunction}
                loading={isSubmitting}
                disabled={selectedDataID.length === 0 || isLoadingData}
              >
                Calculate Function
              </ButtonLoading>
            </Stack>
          </Stack>
        </Stack>
      </Modal>
    </div>
  );
}
