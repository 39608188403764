import * as React from 'react';
import {
  DataGridPro,
  GridColDef,
  GridRenderCellParams,
  GridRowModel,
  GridRowOrderChangeParams,
} from '@mui/x-data-grid-pro';
import _ from 'lodash';
import Typography from '@mui/material/Typography';
import { useMemo } from 'react';
import { Chip } from '@mui/material';
import Stack from '@mui/material/Stack';
import ButtonLoading from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import {
  useTableDefinitionColDisplayOrderList,
  useTableDefinitionColDisplayOrderUpsert,
} from 'services/v1/SystemTenant/AROKMS/TableDefinitionService';
import { TableDefinitionColumnOrderItem } from 'types/api/SystemTenant/AROKMS/TableDefinitionTypes';

export interface ModalCustomColumnOrderProps {
  subjectId: string;
  style?: React.CSSProperties;
  onClose?: () => void;
}

function updateRowPosition(
  oldIndex: number,
  newIndex: number,
  rows: Array<GridRowModel<TableDefinitionColumnOrderItem>>
) {
  const rowsClone = [...rows];
  const [row] = rowsClone.splice(oldIndex, 1);
  rowsClone.splice(newIndex, 0, row);

  for (let i = 0; i < rowsClone.length; i++) {
    rowsClone[i].colIndexOrder = i + 1;
  }

  return rowsClone;
}

export function ListConfigDisplayTable(props: ModalCustomColumnOrderProps) {
  const { subjectId, style, onClose } = props;

  const { data: list, isLoading: isFetching, refetch } = useTableDefinitionColDisplayOrderList(subjectId);
  const { mutate: upsertColumnOrder, isLoading: isInserting } = useTableDefinitionColDisplayOrderUpsert(subjectId);
  const [rows, setRows] = React.useState<TableDefinitionColumnOrderItem[] | []>([]);
  const [tableKey, setTableKey] = React.useState<number>(0);

  const isLoading = isFetching || isInserting;

  const handleCheckboxChange = React.useCallback(
    (checked: boolean, id: any) => {
      const newRows = rows.map((row) => {
        if (row.id === id) {
          return { ...row, columnEditable: checked };
        }
        return row;
      });

      setRows(newRows);
    },
    [rows]
  );

  const columns = useMemo<GridColDef[]>(() => {
    return [
      {
        field: 'colIndexOrder',
        headerName: 'Order',
        width: 80,
        editable: true,
      },
      {
        field: 'attributeName',
        headerName: 'Attribute',
        width: 300,
        editable: false,
        filterable: false,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <Stack direction='row' gap={1}>
              <Typography variant='body2' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
                {params.value}
              </Typography>
              {params.row.ruleTypeName === 'Subject Base' && (
                <Chip
                  label='Primary Key'
                  color='success'
                  variant='outlined'
                  size='small'
                  sx={{
                    fontSize: '10px',
                    px: 0,
                  }}
                />
              )}
            </Stack>
          );
        },
      },
      {
        field: 'ruleTypeName',
        headerName: 'Rule',
        width: 200,
        editable: false,
        filterable: false,
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleCheckboxChange]);

  const handleRowOrderChange = async (params: GridRowOrderChangeParams) => {
    const newRows = updateRowPosition(params.oldIndex, params.targetIndex, rows);
    setRows(newRows);
    setTableKey(tableKey + 1);
  };

  const handleOnResetOrder = () => {
    refetch();
  };
  const handleClose = () => {
    setTableKey(tableKey + 1);
    onClose?.();
  };

  const handleSaveData = () => {
    upsertColumnOrder(rows, {
      onSuccess: () => {
        handleClose();
      },
    });
  };
  React.useEffect(() => {
    if (list?.data) {
      setRows(_.cloneDeep(list.data));
    }
  }, [list]);

  return (
    <Stack height={500} style={style}>
      <DataGridPro
        loading={isLoading}
        columns={columns}
        sx={{
          height: 500,
        }}
        rows={rows}
        disableSelectionOnClick
        rowReordering
        onRowOrderChange={handleRowOrderChange}
      />

      <Stack direction='row' justifyContent='space-between' alignItems='center'>
        <Stack />
        <Stack direction='row' justifyContent='flex-end' spacing={2} alignItems='center' sx={{ py: 1 }}>
          <Button variant='main-table-panel-border' disabled={isLoading} onClick={handleOnResetOrder}>
            Reset Order
          </Button>
          <ButtonLoading variant='main-table-panel' loading={isInserting} onClick={handleSaveData}>
            Save Order
          </ButtonLoading>
        </Stack>
      </Stack>
    </Stack>
  );
}
