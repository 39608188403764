import * as React from 'react';
import { DataGridPro, GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import _ from 'lodash';
import { useMemo } from 'react';
import ButtonLoading from '@mui/lab/LoadingButton';
import { Checkbox, Chip, Stack, Typography } from '@mui/material';
import { useGetCustomRuleList, useUpdateCustomRuleStatus } from 'services/v1/Tenant/AROKMS/CustomRuleService';
import { CustomRuleListItem } from 'types/api/Tenant/AROKMS/CustomRuleTypes';
import { ButtonRecordEdit } from './ButtonRecordEdit';

// @ts-ignore
type RenderCheckBoxProps = GridRenderCellParams<any, boolean> & {
  onCheckboxChange: (checked: boolean, id: any) => void;
};

function RenderCheckBox(props: RenderCheckBoxProps) {
  const [checked, setChecked] = React.useState(props.value);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    props.onCheckboxChange(event.target.checked, props.id); // Notify parent component
  };

  return (
    <Checkbox
      checked={checked}
      onChange={handleChange}
      sx={{
        color: '#828C99',
      }}
    />
  );
}
export interface CustomRuleListProps {
  subjectId?: string | number;
  style?: React.CSSProperties;
  onCreateCustomRule?: () => void;
  onEditCustomRule?: (data: CustomRuleListItem) => void;
}

export function CustomRuleList(props: CustomRuleListProps) {
  const { subjectId, style, onCreateCustomRule = () => {}, onEditCustomRule = () => {} } = props;

  const { data: list, isLoading: isFetching } = useGetCustomRuleList(subjectId);
  const { mutate: updateCustomRuleStatus } = useUpdateCustomRuleStatus();

  const [rows, setRows] = React.useState<CustomRuleListItem[] | []>([]);

  const isLoading = isFetching;

  const handleCheckboxChange = React.useCallback(
    (checked: boolean, id: any) => {
      updateCustomRuleStatus({
        customRuleId: id,
        enabled: checked,
      });
      const newRows = rows.map((row) => {
        if (row.id === id) {
          return { ...row, columnEditable: checked };
        }
        return row;
      });

      setRows(newRows);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rows]
  );

  const columns = useMemo<GridColDef[]>(() => {
    return [
      {
        field: 'action',
        type: 'actions',
        headerName: 'Edit',
        width: 30,
        editable: false,
        filterable: false,
        renderCell: (params: GridRenderCellParams<CustomRuleListItem>) => {
          return (
            // @ts-ignore
            <ButtonRecordEdit
              onClick={() => {
                onEditCustomRule(params.row);
              }}
            />
          );
        },
      },
      {
        field: 'attributeName',
        headerName: 'Attribute',
        width: 200,
        editable: false,
        filterable: false,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <Stack direction='row' gap={1}>
              <Typography variant='body2' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
                {params.value}
              </Typography>
              {params.row.ruleTypeName === 'Subject Base' && (
                <Chip
                  label='Primary Key'
                  color='success'
                  variant='outlined'
                  size='small'
                  sx={{
                    fontSize: '10px',
                    px: 0,
                  }}
                />
              )}
            </Stack>
          );
        },
      },
      {
        field: 'ruleName',
        headerName: 'Rule Name',
        width: 400,
        editable: true,
      },
      {
        field: 'active',
        headerName: 'Enable',
        width: 150,
        editable: false,
        filterable: false,
        renderCell: (params: GridRenderCellParams) => (
          <RenderCheckBox {...params} onCheckboxChange={handleCheckboxChange} />
        ),
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleCheckboxChange]);

  React.useEffect(() => {
    if (list?.data) {
      setRows(_.cloneDeep(list.data));
    }
  }, [list]);

  console.log({
    rows,
  });

  return (
    <Stack style={style} height={450} px={2}>
      <Stack width='20%' mb={2}>
        <ButtonLoading variant='secondary-table-panel' onClick={onCreateCustomRule}>
          Create Custom Rule
        </ButtonLoading>
      </Stack>
      <DataGridPro loading={isLoading} columns={columns} rows={rows} disableSelectionOnClick />
    </Stack>
  );
}
