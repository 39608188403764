import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useMemo, forwardRef, useImperativeHandle, useEffect, useContext } from 'react';
import {
  useGetFunctionDefinitionCubeAttributeDropdown,
  useGetFunctionDefinitionDetailsById,
} from 'services/v1/SystemTenant/AROFunction/FunctionDefinitionService';
import { FunctionDefinitionParamFields, CreateFunctionDefinitionContext } from '../CreateFunctionDefinition';
import { FUNCTION_CORE } from 'constant/FunctionConstant';
import {
  generateFunctionDefinitionParamFormFields,
  getInitialValues,
  createValidationSchema,
} from './FunctionDefinitionLoadParameter';
import { AutoCompleteItem } from 'types/api/Common/AutoCompleteTypes';
import { FormikProps, useFormik } from 'formik';
import { Alert } from '@mui/material';
import { useParams } from 'react-router-dom';

interface IProps {
  cubeId?: string | null;
  layerId?: string | null;
  sectionTitle: string;
  functionCore: string;
  onFormikChange?: (values: any, isFormValid: boolean) => void;
}
interface SaveCountFunctionParameterFields {
  cubeAttributeStoreCountValue: AutoCompleteItem | null;
}

interface SaveDepreciationFunctionParameterFields {
  cubeAttributeStoreDepreciationValue: AutoCompleteItem | null;
  cubeAttributeStoreProfitLossOnSaleValue: AutoCompleteItem | null;
  cubeAttributeStoreBookValue: AutoCompleteItem | null;
}

export interface FunctionDefinitionSaveParameterRef {
  getValues: () => FormikProps<FormikInitialValuesSaveParameter>['values'];
}
type FormikInitialValuesSaveParameter = SaveCountFunctionParameterFields | SaveDepreciationFunctionParameterFields;

export const FunctionDefinitionSaveParameter = forwardRef<FunctionDefinitionSaveParameterRef, IProps>((props, ref) => {
  const { functionId } = useParams<{ functionId: string }>();
  const { data: functionDefinitionDetails } = useGetFunctionDefinitionDetailsById(functionId);
  const { cubeId, layerId, sectionTitle, functionCore, onFormikChange } = props;

  const { data: cubeAttributeDropdown } = useGetFunctionDefinitionCubeAttributeDropdown(cubeId, layerId, functionId);
  const { selectedCubeAttributeOptions, setSelectedCubeAttributeOptions } = useContext(CreateFunctionDefinitionContext);

  const saveParameterFields: FunctionDefinitionParamFields[] = useMemo(() => {
    switch (functionCore) {
      case FUNCTION_CORE.VALUE_FUNCTION:
        return [
          {
            name: 'cubeAttributeStoreRegoPOValue',
            fieldTitle: 'Rego PA Value',
            fieldSubtitle: 'Select the cube attribute to store the Rego PA value.',
            fieldType: 'autocomplete',
            placeholder: 'Select Cube Attribute...',
            fieldOptions: cubeAttributeDropdown?.data,
            ruleTypes: [],
            enforceUniqueSelection: true,
          },
          {
            name: 'cubeAttributeStoreMonthlyValue',
            fieldTitle: 'Monthly Value',
            fieldSubtitle: 'Select the cube attribute to store the Monthly value.',
            fieldType: 'autocomplete',
            placeholder: 'Select Cube Attribute...',
            fieldOptions: cubeAttributeDropdown?.data,
            ruleTypes: [],
            enforceUniqueSelection: true,
          },
        ];
      case FUNCTION_CORE.COUNT_FUNCTION:
        return [
          {
            name: 'cubeAttributeStoreCountValue',
            fieldTitle: 'Count Value',
            fieldSubtitle: 'Select the cube attribute to store the Count value.',
            fieldType: 'autocomplete',
            placeholder: 'Select Cube Attribute...',
            fieldOptions: cubeAttributeDropdown?.data,
            ruleTypes: [],
            enforceUniqueSelection: true,
          },
        ];
      case FUNCTION_CORE.DEPRECIATION_FUNCTION:
        return [
          {
            name: 'cubeAttributeStoreDepreciationValue',
            fieldTitle: 'Monthly Depreciation Value',
            fieldSubtitle: 'Select the cube attribute to store the Monthly Depreciation value.',
            fieldType: 'autocomplete',
            placeholder: 'Select Cube Attribute...',
            fieldOptions: cubeAttributeDropdown?.data,
            ruleTypes: [],
            enforceUniqueSelection: true,
          },
          {
            name: 'cubeAttributeStoreProfitLossOnSaleValue',
            fieldTitle: 'Profit Loss on Sale Value',
            fieldSubtitle: 'Select the cube attribute to store the Profit Loss on Sale value.',
            fieldType: 'autocomplete',
            placeholder: 'Select Cube Attribute...',
            fieldOptions: cubeAttributeDropdown?.data,
            ruleTypes: [],
            enforceUniqueSelection: true,
          },
          {
            name: 'cubeAttributeStoreBookValue',
            fieldTitle: 'Monthly Book Value',
            fieldSubtitle: 'Select the cube attribute to store the Monthly Book value.',
            fieldType: 'autocomplete',
            placeholder: 'Select Cube Attribute...',
            fieldOptions: cubeAttributeDropdown?.data,
            ruleTypes: [],
            enforceUniqueSelection: true,
          },
          {
            name: 'cubeAttributeStoreYearlyBookValue',
            fieldTitle: 'Yearly Book Value',
            fieldSubtitle: 'Select the cube attribute to store the Yearly Depreciation Value.',
            fieldType: 'autocomplete',
            placeholder: 'Select Cube Attribute...',
            fieldOptions: cubeAttributeDropdown?.data,
            ruleTypes: [],
            enforceUniqueSelection: true,
          },
          {
            name: 'cubeAttributeStoreMonthlyAssetLife',
            fieldTitle: 'Monthly Asset Life Value',
            fieldSubtitle: 'Select the cube attribute to store the Monthly Asset Life Value.',
            fieldType: 'autocomplete',
            placeholder: 'Select Cube Attribute...',
            fieldOptions: cubeAttributeDropdown?.data,
            ruleTypes: [],
            enforceUniqueSelection: true,
          },
        ];
      case FUNCTION_CORE.INTEREST_FUNCTION:
        return [
          {
            name: 'cubeAttributeStoreLoanPaymentRemainingValue',
            fieldTitle: 'Loan Payments Remaining Value',
            fieldSubtitle: 'Select the cube attribute to store the Loan Payments Remaining value.',
            fieldType: 'autocomplete',
            placeholder: 'Select Cube Attribute...',
            fieldOptions: cubeAttributeDropdown?.data,
            ruleTypes: [],
            enforceUniqueSelection: true,
          },
          {
            name: 'cubeAttributeStoreMonthlyPaymentValue',
            fieldTitle: 'Monthly Payment Value',
            fieldSubtitle: 'Select the cube attribute to store the Monthly Payment value.',
            fieldType: 'autocomplete',
            placeholder: 'Select Cube Attribute...',
            fieldOptions: cubeAttributeDropdown?.data,
            ruleTypes: [],
            enforceUniqueSelection: true,
          },
          {
            name: 'cubeAttributeStoreLoanBalanceValue',
            fieldTitle: 'Loan Balance Value',
            fieldSubtitle: 'Select the cube attribute to store the Loan Balance value.',
            fieldType: 'autocomplete',
            placeholder: 'Select Cube Attribute...',
            fieldOptions: cubeAttributeDropdown?.data,
            ruleTypes: [],
            enforceUniqueSelection: true,
          },
          {
            name: 'cubeAttributeStorePrincipalRepaidValue',
            fieldTitle: 'Principal Repaid Value',
            fieldSubtitle: 'Select the cube attribute to store the Repaid value.',
            fieldType: 'autocomplete',
            placeholder: 'Select Cube Attribute...',
            fieldOptions: cubeAttributeDropdown?.data,
            ruleTypes: [],
            enforceUniqueSelection: true,
          },
          {
            name: 'cubeAttributeStoreInterestCostValue',
            fieldTitle: 'Monthly Interest Cost Value',
            fieldSubtitle: 'Select the cube attribute to store the Monthly Interest Cost value.',
            fieldType: 'autocomplete',
            placeholder: 'Select Cube Attribute...',
            fieldOptions: cubeAttributeDropdown?.data,
            ruleTypes: [],
            enforceUniqueSelection: true,
          },
          {
            name: 'cubeAttributeStoreInterestRatePerMonthValue',
            fieldTitle: 'Interest Rate Per Month Value',
            fieldSubtitle: 'Select the cube attribute to store the Count value.',
            fieldType: 'autocomplete',
            placeholder: 'Select Cube Attribute...',
            fieldOptions: cubeAttributeDropdown?.data,
            ruleTypes: [],
            enforceUniqueSelection: true,
          },
          {
            name: 'cubeAttributeStoreTotalLoanRepaymentValue',
            fieldTitle: 'Loan Repayment Value',
            fieldSubtitle: 'Select the cube attribute to store the Loan Repayment value.',
            fieldType: 'autocomplete',
            placeholder: 'Select Cube Attribute...',
            fieldOptions: cubeAttributeDropdown?.data,
            ruleTypes: [],
            enforceUniqueSelection: true,
          },
          {
            name: 'cubeAttributeStoreFinalPaymentPayoutValue',
            fieldTitle: 'Final Payment Payout Value',
            fieldSubtitle: 'Select the cube attribute to store the Final Payment Payout value.',
            fieldType: 'autocomplete',
            placeholder: 'Select Cube Attribute...',
            fieldOptions: cubeAttributeDropdown?.data,
            ruleTypes: [],
            enforceUniqueSelection: true,
          },
        ];
      default:
        return [];
    }
  }, [cubeAttributeDropdown, functionCore]);

  const initialValues = getInitialValues<FormikInitialValuesSaveParameter>(saveParameterFields);

  const validationSchema = useMemo(() => createValidationSchema(saveParameterFields), [saveParameterFields]);
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {},
  });

  useImperativeHandle(ref, () => ({
    getValues: () => formik.values,
  }));

  const isAtleastOneCubeAttributeSelected = useMemo(() => {
    return Object.values(formik.values).some((value) => value !== null);
  }, [formik.values]);

  useEffect(() => {
    onFormikChange?.(formik.values, validationSchema.isValidSync(formik.values) && isAtleastOneCubeAttributeSelected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values]);

  useEffect(() => {
    formik.resetForm();
    formik.setValues(initialValues);
    setSelectedCubeAttributeOptions({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [functionCore]);

  useEffect(() => {
    if (functionDefinitionDetails?.data && functionDefinitionDetails.data.saveParameters) {
      formik.setValues(functionDefinitionDetails.data.saveParameters as unknown as FormikInitialValuesSaveParameter);
      const selectedCubeAttributeOptions: Record<string, string | null> = {};
      Object.entries(functionDefinitionDetails.data.saveParameters).forEach(([key, item]) => {
        selectedCubeAttributeOptions[key] = item?.value?.toString() ?? null;
      });
      setSelectedCubeAttributeOptions(selectedCubeAttributeOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [functionDefinitionDetails?.data]);

  return (
    <>
      <Typography variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
        {sectionTitle}
      </Typography>
      <Alert severity='info' sx={{ my: 1 }}>
        {functionCore === FUNCTION_CORE.VALUE_FUNCTION && (
          <Typography variant='body2'>
            This function will produce for Rego PA value and Monthly value, and store these in the selected cube
            attributes.
            <br />
            <u>At least one cube attribute must be selected to complete this form and save the function.</u>
          </Typography>
        )}
        {functionCore === FUNCTION_CORE.COUNT_FUNCTION && (
          <Typography variant='body2'>
            This function will produce the Count value, and store this in the selected cube attribute.
            <u>At least one cube attribute must be selected to complete this form and save the function.</u>
          </Typography>
        )}
        {functionCore === FUNCTION_CORE.DEPRECIATION_FUNCTION && (
          <Typography variant='body2'>
            This function will produce the Depreciation value, Profit Loss on Sale value, Book value, Yearly Book Value
            and Monthly Asset Life Value, and store these in the selected cube attributes.
            <u>At least one cube attribute must be selected to complete this form and save the function.</u>
          </Typography>
        )}
        {functionCore === FUNCTION_CORE.INTEREST_FUNCTION && (
          <Typography variant='body2'>
            This function will produce the Depreciation value, Profit Loss on Sale value, Book value, Yearly Book Value
            and Monthly Asset Life Value, and store these in the selected cube attributes.
            <u>At least one cube attribute must be selected to complete this form and save the function.</u>
          </Typography>
        )}
      </Alert>
      <Divider sx={{ my: 1 }} />
      <Stack width='100%' direction='row' gap={3} flexWrap='wrap'>
        {generateFunctionDefinitionParamFormFields<FormikInitialValuesSaveParameter>(
          saveParameterFields,
          formik,
          selectedCubeAttributeOptions,
          setSelectedCubeAttributeOptions
        )}
      </Stack>
    </>
  );
});
