import { FormikProps } from 'formik';
import { Field, GetPullAttributeMappingResponse } from 'types/api/Tenant/AROKMS/DisplayTableTypes';
import { Stack, TextField, Tooltip, Typography } from '@mui/material';
import { convertRuleTypeParamsToSnakeCase } from 'utils/String';
import { useEffect, memo } from 'react';
import HelpOutlined from '@mui/icons-material/HelpOutlined';

interface IProps {
  formik: FormikProps<{ [key: string]: string }>;
  field: Field;
  ruleTypeCode: string;
  required?: boolean;
  isEdit?: boolean;
  pullAttributeMapping?: GetPullAttributeMappingResponse;
}

const WIDTH_LABEL = '40%';

const INPUT_EDIT_MESSAGE = "Once you've submitted, this field can no longer be changed";

export function PullAttributeTextInputComponent(props: IProps) {
  const { formik, field, required, isEdit = false, pullAttributeMapping } = props;
  const pullParams = convertRuleTypeParamsToSnakeCase(field.ruleTypeParams);
  const filterBasedOnField = pullParams?.[3];
  const columReferenceValue = formik.values[filterBasedOnField];
  // @ts-ignore
  const referenceValue = columReferenceValue?.value || columReferenceValue;

  useEffect(() => {
    const formValue = pullAttributeMapping?.[field.name]?.[referenceValue] || '';
    formik.setFieldValue(field.name, formValue);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columReferenceValue, field, pullAttributeMapping]);

  return (
    <Stack direction='row' justifyContent='space-between' alignItems='center' marginTop={2.5} gap={1}>
      <Stack width={WIDTH_LABEL}>
        <Stack direction='row' alignItems='center' gap={0.3}>
          <Typography variant='input-label' fontWeight={700}>
            {field.label}
            {required && !field.disabled && <span style={{ color: 'red', marginLeft: 2 }}>*</span>}
          </Typography>
          {field.description && (
            <Tooltip title={field.description}>
              <HelpOutlined
                sx={{
                  fontSize: '14px',
                }}
              />
            </Tooltip>
          )}
        </Stack>
        {field.columnEditable === false && !isEdit && (
          <Typography variant='input-label-gray' fontSize={12}>
            {INPUT_EDIT_MESSAGE}
          </Typography>
        )}
      </Stack>
      <TextField
        name={field.name}
        value={formik.values[field.name]}
        disabled={field.disabled}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        error={!!formik.touched[field.name] && !!formik.errors[field.name]}
        helperText={formik.touched[field.name] && formik.errors[field.name]}
        sx={{ width: '65%' }}
        hiddenLabel
        variant='outlined'
        size='small'
        placeholder={field.placeholder}
      />
    </Stack>
  );
}

export const PullAttributeTextInput = memo(PullAttributeTextInputComponent);
