import Typography from '@mui/material/Typography';
import TableViewOutlinedIcon from '@mui/icons-material/TableViewOutlined';
import InputOutlinedIcon from '@mui/icons-material/InputOutlined';
import Stack from '@mui/material/Stack';

export interface ModalCustomColumnSelectConfigProps {
  onMenuOptionClick: (option: string) => void;
}

const menuOptionSx = {
  border: '1px solid #E0E0E0',
  p: 2,
  borderRadius: '4px',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#F5F5F5',
    border: '1px solid #42BB93',
    transition: 'ease-in 0.1s',
    '& svg': {
      color: '#42BB93',
    },
  },
};

const iconSx = {
  color: '#828C99',
  fontSize: 40,
};

export const MODAL_TABLE_DEFINITION_CONFIG_OPTIONS = {
  DISPLAY_CONFIG: 'DISPLAY_CONFIG',
  INPUT_CONFIG: 'INPUT_CONFIG',
};
export function SelectConfigOption(props: ModalCustomColumnSelectConfigProps) {
  const { onMenuOptionClick } = props;

  return (
    <Stack>
      <Stack direction='row' px={4} alignItems='center' justifyContent='space-around'>
        <Stack
          width='40%'
          gap={2}
          alignItems='center'
          direction='row'
          onClick={() => onMenuOptionClick(MODAL_TABLE_DEFINITION_CONFIG_OPTIONS.DISPLAY_CONFIG)}
          sx={menuOptionSx}
        >
          <TableViewOutlinedIcon sx={iconSx} />
          <Stack>
            <Typography variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
              Customize Table Display
            </Typography>
            <Typography variant='input-label-gray' fontWeight={400} component='h2'>
              Adjust how columns are displayed and ordered in your table.
            </Typography>
          </Stack>
        </Stack>
        <Stack
          width='40%'
          gap={2}
          alignItems='center'
          direction='row'
          onClick={() => onMenuOptionClick(MODAL_TABLE_DEFINITION_CONFIG_OPTIONS.INPUT_CONFIG)}
          sx={menuOptionSx}
        >
          <InputOutlinedIcon sx={iconSx} />
          <Stack>
            <Typography variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
              Customize Input Columns
            </Typography>
            <Typography variant='input-label-gray' fontWeight={400} component='h2'>
              Define which columns can be edited and their arrangement
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
