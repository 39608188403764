import { useEffect, useImperativeHandle, useMemo, useRef, useState, forwardRef, useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import qs from 'query-string';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import { Formik, FormikProps } from 'formik';
import { DropdownItem } from 'types/api/SystemTenant/AROKMS/TableDefinitionTypes';
import { ReactComponent as CubeAccrossIcon } from 'assets/icons/icon-cube-across.svg';
import { ReactComponent as CubeDown } from 'assets/icons/icon-cube-down.svg';
import { flattenTreeData, renderOptionsKtree, getListBoxPropsAutoScrolItem } from 'utils/Object';
import { useGetCubeDefinitionDetails } from 'services/v1/SystemTenant/AROCube/CubeBuilderService';
import { DIMENSION_CATEGORY, DropdownItemWithType } from 'types/api/Tenant/AROCube/CubeDataDimensionalViewTypes';
import { useGetCubeDimensionalViewFilterMember } from 'services/v1/Tenant/AROCube/CubeDimensionalViewService';
import { InsightViewDimensionalOptionValue } from 'types/api/Tenant/AROCube/CubeInisghtViewTypes';
import { AutoCompleteItem } from 'types/api/Common/AutoCompleteTypes';
import { ControllerNavigation, ControllerNavigationDirection } from 'pages/Tenant/AroDashboard/ControllerNavigation';
import { PATH_CONSTANT } from 'constant/PathConstant';
import { INSIGHT_VIEW_MEASUREMENT_TYPE } from 'constant/ViewConstant';
import { useGetMyPersonalization, useGetMyViewDetails } from 'services/v1/Tenant/ConfigurationService';
import { NAVIGATION_COMPONENT_TYPE } from 'constant/NavigationConstant';
import { PAGE_REF_NAVIGATION } from 'utils/Animation';
import { WorkFlowDrillNavigationDirection, getNextDrillDownURL, getWorkFlowDrillView } from 'utils/MyViewWorkflowUtils';

export interface OptionItem {
  value: string | number;
  label: string;
}

const autoCompleteStyle = {
  width: 230,
  '& .MuiAutocomplete-popper': {
    backgroundColor: 'red !imoprtant',
    fontSize: '10px',
  },
};

const textInputStyle = {};

interface CubeInsightViewPanelProps {
  disabled?: boolean;
  insightViewId?: string;
  insightName?: string;
  cubeDefinitionId?: string;
  xDimensionOptions: DropdownItemWithType[];
  yDimensionOptions: DropdownItemWithType[];
  ref?: any;
  onOptionChange?: (option: InsightViewDimensionalOptionValue) => void;
  loading?: boolean;
  initialValues: InsightViewDimensionalOptionValue;

  setIsLoadingOptionsData?: (isLoading: boolean) => void;
}

const formikInitialValues: InsightViewDimensionalOptionValue = {
  member: null,
  xDimensionType: null,
  yDimensionType: null,
};

export const CubeInsightViewPanel = forwardRef((props: CubeInsightViewPanelProps, ref) => {
  const {
    disabled: disableProps,
    initialValues,
    xDimensionOptions,
    onOptionChange,
    yDimensionOptions,
    cubeDefinitionId,
    insightViewId,
    setIsLoadingOptionsData,
  } = props;

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const myViewId = searchParams.get('myViewId');
  const workFlowId = searchParams.get('workFlowId');
  const memberId = searchParams.get('memberId');
  const dashboardId = searchParams.get('fromDashboardId');
  const dashboardName = searchParams.get('fromDashboardName');
  const defaultFilterMember = searchParams.get('defaultFilterMember');
  const defaultYDimension = searchParams.get('defaultYDimension');
  const defaultXDimension = searchParams.get('defaultXDimension');
  const proxyMainMemberId = searchParams.get('proxyMainMemberId') || undefined;

  const { data: allMyViewsData } = useGetMyPersonalization({ enabled: true });
  const { data: myViewDetails } = useGetMyViewDetails(myViewId);

  const insightViewList = useMemo(() => {
    return (
      allMyViewsData?.data.myViewItems.filter(
        (item) => item.componentType === NAVIGATION_COMPONENT_TYPE.INSIGHT_VIEW
      ) ?? []
    );
  }, [allMyViewsData?.data]);

  const [optionValue, setOptionValue] = useState<InsightViewDimensionalOptionValue>({
    xDimensionType: null,
    yDimensionType: null,
    member: null,
    showMemberOnly: false,
  });
  const formikRef = useRef<FormikProps<InsightViewDimensionalOptionValue>>(null);

  const { data: memberDropdown, isLoading: isFetchingMember } = useGetCubeDimensionalViewFilterMember(
    'MAIN_MEMBER',
    cubeDefinitionId,
    optionValue.xDimensionType?.type,
    optionValue.yDimensionType?.type,
    DIMENSION_CATEGORY.CUBE_INSIGHT_VIEW,
    insightViewId,
    proxyMainMemberId
  );

  const { data: cubeDefinitionDetails } = useGetCubeDefinitionDetails(cubeDefinitionId);

  const disabled = disableProps;

  const memberOptions = useMemo(() => {
    if (memberDropdown) {
      return flattenTreeData(memberDropdown);
    }
    return [];
  }, [memberDropdown]);

  const lowestMemberLevel = useMemo(() => {
    // @ts-ignore
    return memberOptions.reduce((acc, item) => {
      if (item.level > acc) {
        return item.level;
      } else {
        return acc;
      }
    }, 0);
  }, [memberOptions]);

  const nextInsightView = useMemo(() => {
    const currentInsightIndex = insightViewList.findIndex((item) => item.value === insightViewId);
    if (currentInsightIndex > -1) {
      if (currentInsightIndex < insightViewList.length - 1) {
        return insightViewList[currentInsightIndex + 1];
      }
    }
    return null;
  }, [insightViewList, insightViewId]);

  const currentMyViewItem = useMemo(() => {
    if (myViewDetails?.data) {
      return myViewDetails.data;
    }
    return null;
  }, [myViewDetails?.data]);

  const nextDrilDownWorkFlow = useMemo(() => {
    return getWorkFlowDrillView(currentMyViewItem, workFlowId, WorkFlowDrillNavigationDirection.DOWN);
  }, [currentMyViewItem, workFlowId]);

  const previousInsightView = useMemo(() => {
    const currentInsightIndex = insightViewList.findIndex((item) => item.value === insightViewId);
    if (currentInsightIndex > -1) {
      if (currentInsightIndex > 0) {
        return insightViewList[currentInsightIndex - 1];
      }
    }
    return null;
  }, [insightViewList, insightViewId]);

  const isMainMemberActiveAsFilter =
    (optionValue.xDimensionType?.type === INSIGHT_VIEW_MEASUREMENT_TYPE.INSIGHT_COLUMNS_MEMBER &&
      optionValue.yDimensionType?.type === INSIGHT_VIEW_MEASUREMENT_TYPE.MEASUREMENT_MEMBER) ||
    (optionValue.xDimensionType?.type === INSIGHT_VIEW_MEASUREMENT_TYPE.MEASUREMENT_MEMBER &&
      optionValue.yDimensionType?.type === INSIGHT_VIEW_MEASUREMENT_TYPE.INSIGHT_COLUMNS_MEMBER);

  const handleOnDashboardNavigate = (direction: ControllerNavigationDirection) => {
    if (!cubeDefinitionId) return;
    switch (direction) {
      case ControllerNavigationDirection.DOWN:
        const nextDrillDownURL = getNextDrillDownURL(currentMyViewItem, nextDrilDownWorkFlow, {
          memberId: optionValue.member?.value,
          ref: PAGE_REF_NAVIGATION.BOTTOM,
        });
        nextDrillDownURL && navigate(nextDrillDownURL);
        break;

      case ControllerNavigationDirection.LEFT:
        if (previousInsightView) {
          const insightURL = `${PATH_CONSTANT.TENANT.CUBE.INSIGHT.replace(
            ':cubeId',
            previousInsightView.cubeId
          ).replace(':insightId', previousInsightView?.value)}?${qs.stringify({
            memberId: isMainMemberActiveAsFilter ? optionValue.member?.value : memberId,
            defaultYDimension: previousInsightView?.defaultDimensionFilter?.defaultYDimension,
            defaultXDimension: previousInsightView?.defaultDimensionFilter?.defaultXDimension,
            defaultFilterMember: previousInsightView?.defaultDimensionFilter?.defaultFilterMember,
            workFlowId: previousInsightView?.drillDownWorkflows?.[0]?.id,
            myViewId: previousInsightView?.viewId,
            ref: PAGE_REF_NAVIGATION.RIGHT,
          })}`;
          navigate(insightURL);
        }
        break;

      case ControllerNavigationDirection.RIGHT:
        if (nextInsightView) {
          const insightURL = `${PATH_CONSTANT.TENANT.CUBE.INSIGHT.replace(':cubeId', nextInsightView.cubeId).replace(
            ':insightId',
            nextInsightView?.value
          )}?${qs.stringify({
            memberId: isMainMemberActiveAsFilter ? optionValue.member?.value : memberId,
            defaultYDimension: nextInsightView?.defaultDimensionFilter?.defaultYDimension,
            defaultXDimension: nextInsightView?.defaultDimensionFilter?.defaultXDimension,
            defaultFilterMember: nextInsightView?.defaultDimensionFilter?.defaultFilterMember,
            workFlowId: nextInsightView?.drillDownWorkflows?.[0]?.id,
            myViewId: nextInsightView?.viewId,
            ref: PAGE_REF_NAVIGATION.LEFT,
          })}`;
          navigate(insightURL);
        }
        break;

      case ControllerNavigationDirection.UP:
        const dashboardURL = `${PATH_CONSTANT.TENANT.DASHBOARD.DASHBOARD.replace(
          ':dashboardId',
          dashboardId || ''
        )}?${qs.stringify({
          memberId: optionValue.member?.value,
          ref: PAGE_REF_NAVIGATION.TOP,
        })}`;
        navigate(dashboardURL);
        break;

      default:
        break;
    }
  };

  const navigationControllerTooltips = useMemo(() => {
    const defaultToolTips: {
      [key: string]: string;
    } = {
      [ControllerNavigationDirection.UP]: dashboardName ? `Show ${dashboardName}` : 'Show Dashboard',
      [ControllerNavigationDirection.DOWN]: `Show ${nextDrilDownWorkFlow?.componentName}`,
    };

    if (insightViewList.length > 1) {
      const currentInsightIndex = insightViewList.findIndex((item) => item.value === insightViewId);
      if (currentInsightIndex > -1) {
        if (currentInsightIndex > 0) {
          defaultToolTips[ControllerNavigationDirection.LEFT] = `Show ${
            insightViewList[currentInsightIndex - 1].title
          }`;
        }
        if (currentInsightIndex < insightViewList.length - 1) {
          defaultToolTips[ControllerNavigationDirection.RIGHT] = `Show ${
            insightViewList[currentInsightIndex + 1].title
          }`;
        }
      }
    }

    return defaultToolTips;
  }, [nextDrilDownWorkFlow, dashboardName, insightViewList, insightViewId]);

  const mainMemberFilterLabel = useCallback(() => {
    if (cubeDefinitionDetails?.data) {
      if (
        (optionValue.xDimensionType?.type === INSIGHT_VIEW_MEASUREMENT_TYPE.MEASUREMENT_MEMBER &&
          optionValue.yDimensionType?.type === INSIGHT_VIEW_MEASUREMENT_TYPE.MAIN_MEMBER) ||
        (optionValue.xDimensionType?.type === INSIGHT_VIEW_MEASUREMENT_TYPE.MAIN_MEMBER &&
          optionValue.yDimensionType?.type === INSIGHT_VIEW_MEASUREMENT_TYPE.MEASUREMENT_MEMBER)
      ) {
        return 'Period';
      }
      if (
        (optionValue.xDimensionType?.type === INSIGHT_VIEW_MEASUREMENT_TYPE.INSIGHT_COLUMNS_MEMBER &&
          optionValue.yDimensionType?.type === INSIGHT_VIEW_MEASUREMENT_TYPE.MEASUREMENT_MEMBER) ||
        (optionValue.xDimensionType?.type === INSIGHT_VIEW_MEASUREMENT_TYPE.MEASUREMENT_MEMBER &&
          optionValue.yDimensionType?.type === INSIGHT_VIEW_MEASUREMENT_TYPE.INSIGHT_COLUMNS_MEMBER)
      ) {
        return cubeDefinitionDetails?.data.member.label;
      }

      if (
        (optionValue.xDimensionType?.type === INSIGHT_VIEW_MEASUREMENT_TYPE.INSIGHT_COLUMNS_MEMBER &&
          optionValue.yDimensionType?.type === INSIGHT_VIEW_MEASUREMENT_TYPE.MAIN_MEMBER) ||
        (optionValue.xDimensionType?.type === INSIGHT_VIEW_MEASUREMENT_TYPE.MAIN_MEMBER &&
          optionValue.yDimensionType?.type === INSIGHT_VIEW_MEASUREMENT_TYPE.INSIGHT_COLUMNS_MEMBER)
      ) {
        return 'Measure';
      }
      if (
        (optionValue.xDimensionType?.type === INSIGHT_VIEW_MEASUREMENT_TYPE.INSIGHT_COLUMNS_MEMBER &&
          optionValue.yDimensionType?.type === INSIGHT_VIEW_MEASUREMENT_TYPE.SECOND_MEMBER) ||
        (optionValue.xDimensionType?.type === INSIGHT_VIEW_MEASUREMENT_TYPE.SECOND_MEMBER &&
          optionValue.yDimensionType?.type === INSIGHT_VIEW_MEASUREMENT_TYPE.INSIGHT_COLUMNS_MEMBER)
      ) {
        return cubeDefinitionDetails?.data.member.label;
      }

      if (
        (optionValue?.xDimensionType?.type === INSIGHT_VIEW_MEASUREMENT_TYPE.MEASUREMENT_MEMBER &&
          optionValue.yDimensionType?.type === INSIGHT_VIEW_MEASUREMENT_TYPE.SECOND_MEMBER) ||
        (optionValue.xDimensionType?.type === INSIGHT_VIEW_MEASUREMENT_TYPE.SECOND_MEMBER &&
          optionValue.yDimensionType?.type === INSIGHT_VIEW_MEASUREMENT_TYPE.MEASUREMENT_MEMBER)
      ) {
        return cubeDefinitionDetails?.data.member.label;
      }

      if (
        (optionValue.xDimensionType?.type === INSIGHT_VIEW_MEASUREMENT_TYPE.MAIN_MEMBER &&
          optionValue.yDimensionType?.type === INSIGHT_VIEW_MEASUREMENT_TYPE.SECOND_MEMBER) ||
        (optionValue.xDimensionType?.type === INSIGHT_VIEW_MEASUREMENT_TYPE.INSIGHT_COLUMNS_MEMBER &&
          optionValue.yDimensionType?.type === INSIGHT_VIEW_MEASUREMENT_TYPE.INSIGHT_COLUMNS_MEMBER)
      ) {
        return 'Measure';
      }
    }
    return 'Member';
  }, [optionValue.xDimensionType?.type, optionValue.yDimensionType?.type, cubeDefinitionDetails?.data]);

  useEffect(() => {
    onOptionChange && onOptionChange(optionValue);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionValue]);

  useEffect(() => {
    if (initialValues) {
      setOptionValue((prevState) => ({
        ...prevState,
        xDimensionType: initialValues.xDimensionType,
        yDimensionType: initialValues.yDimensionType,
      }));
    }
  }, [initialValues]);

  // auto select member
  useEffect(() => {
    if (memberOptions && memberOptions?.length > 0) {
      const member = memberOptions?.find((item: DropdownItem) => item.value === memberId) ?? memberOptions?.[0];
      const selectedMember: DropdownItem = {
        id: member.value,
        value: member?.value?.toString() || '',
        label: member.label,
      };
      setOptionValue((prev) => ({ ...prev, member: selectedMember }));
    }
  }, [memberOptions, memberId]);

  // auto select dimension
  useEffect(() => {
    if (defaultXDimension) {
      const xDimension = xDimensionOptions.find((item) => item.type === defaultXDimension);
      if (xDimension) {
        setOptionValue((prev) => ({ ...prev, xDimensionType: xDimension }));
      }
    }
    if (defaultYDimension) {
      const yDimension = yDimensionOptions.find((item) => item.type === defaultYDimension);

      if (yDimension) {
        setOptionValue((prev) => ({ ...prev, yDimensionType: yDimension }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [xDimensionOptions, yDimensionOptions]);

  useEffect(() => {
    if (memberOptions.length > 0 && defaultFilterMember && !isMainMemberActiveAsFilter) {
      const member =
        memberOptions.find((item: DropdownItem) => item.value === defaultFilterMember) ?? memberOptions?.[0];

      if (member) {
        setOptionValue((prev) => ({ ...prev, member: member }));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberOptions]);

  useImperativeHandle(ref, () => ({
    isLoadingOptionsData: isFetchingMember,
  }));

  const resetSearchParamsFromDefaultFilter = () => {
    searchParams.delete('defaultFilterMember');
    searchParams.delete('defaultYDimension');
    searchParams.delete('defaultXDimension');
    setSearchParams(searchParams);
  };

  useEffect(() => {
    setIsLoadingOptionsData && setIsLoadingOptionsData(isFetchingMember);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchingMember]);

  const controllerNaviations = useMemo(() => {
    const controller = [ControllerNavigationDirection.DOWN];
    if (dashboardId) controller.push(ControllerNavigationDirection.UP);

    if (insightViewList?.length > 1) {
      const currentInsightIndex = insightViewList.findIndex((item) => item.value === insightViewId);
      if (currentInsightIndex > 0) controller.push(ControllerNavigationDirection.LEFT);
      if (currentInsightIndex < insightViewList.length - 1) controller.push(ControllerNavigationDirection.RIGHT);
    }

    return controller;
  }, [dashboardId, insightViewList, insightViewId]);

  return (
    <Stack direction='column' sx={{ backgroundColor: '#fff', py: 1 }}>
      <Formik onSubmit={() => {}} innerRef={formikRef} initialValues={formikInitialValues}>
        {({ errors, touched, handleBlur, handleChange, values, setFieldValue, handleSubmit }) => {
          return (
            <>
              <Stack
                direction='row'
                alignItems='center'
                sx={{ bgcolor: '#FBFBFB', py: 1, px: 2, border: '1px solid #E3EBF6' }}
              >
                <Stack direction='row' justifyContent='space-between' width='100%'>
                  <Stack direction='row' spacing={5} alignItems='center'>
                    <Autocomplete
                      // @ts-ignore
                      onChange={(event, value) => {
                        setOptionValue((prev) => ({ ...prev, member: value }));
                      }}
                      clearIcon={null}
                      sx={{
                        width: 300,
                      }}
                      size='small'
                      value={optionValue.member}
                      disabled={disabled || isFetchingMember}
                      getOptionLabel={(option: AutoCompleteItem) => option.label}
                      options={memberOptions || []}
                      renderOption={(props, option, state) =>
                        renderOptionsKtree(props, option, state, lowestMemberLevel)
                      }
                      ListboxProps={{
                        ...getListBoxPropsAutoScrolItem(memberOptions, optionValue.member),
                      }}
                      renderInput={(params) => (
                        <Stack direction='row' justifyContent='space-between' display='flex' alignItems='center'>
                          <TextField
                            {...params}
                            sx={textInputStyle}
                            label={isFetchingMember ? 'Loading...' : mainMemberFilterLabel() || 'Member'}
                            onBlur={handleBlur}
                            name='member'
                          />
                        </Stack>
                      )}
                    />
                    <Autocomplete
                      // @ts-ignore
                      onChange={(event, value) => {
                        resetSearchParamsFromDefaultFilter();
                        setOptionValue((prev) => ({
                          ...prev,
                          xDimensionType: value,
                        }));
                      }}
                      clearIcon={null}
                      size='small'
                      value={optionValue.xDimensionType}
                      disabled={disabled}
                      getOptionLabel={(option: DropdownItem) => option.label}
                      options={xDimensionOptions || []}
                      renderOption={(props, option) => (
                        <Box component='li' sx={{ '& > span': { fontSize: '14px', mr: 1, flexShrink: 0 } }} {...props}>
                          <span>{option.label}</span>
                        </Box>
                      )}
                      sx={autoCompleteStyle}
                      renderInput={(params) => (
                        <Stack direction='row' justifyContent='space-between' display='flex' alignItems='center'>
                          <TextField
                            {...params}
                            sx={textInputStyle}
                            onBlur={handleBlur}
                            label={'Down'}
                            InputProps={{
                              ...params.InputProps,
                              startAdornment: (
                                <InputAdornment position='start'>
                                  <CubeDown />
                                </InputAdornment>
                              ),
                            }}
                            name='xDimensionType'
                          />
                        </Stack>
                      )}
                    />
                    <Autocomplete
                      // @ts-ignore
                      onChange={(event, value) => {
                        resetSearchParamsFromDefaultFilter();
                        setOptionValue((prev) => ({
                          ...prev,
                          yDimensionType: value,
                        }));
                      }}
                      clearIcon={null}
                      size='small'
                      value={optionValue.yDimensionType}
                      disabled={disabled}
                      getOptionLabel={(option: DropdownItem) => option.label}
                      options={yDimensionOptions || []}
                      renderOption={(props, option) => (
                        <Box component='li' sx={{ '& > span': { fontSize: '14px', mr: 1, flexShrink: 0 } }} {...props}>
                          <span>{option.label}</span>
                        </Box>
                      )}
                      sx={autoCompleteStyle}
                      renderInput={(params) => (
                        <Stack direction='row' justifyContent='space-between' display='flex' alignItems='center'>
                          <TextField
                            {...params}
                            sx={textInputStyle}
                            label='Across'
                            InputProps={{
                              ...params.InputProps,
                              startAdornment: (
                                <InputAdornment position='start'>
                                  <CubeAccrossIcon />
                                </InputAdornment>
                              ),
                            }}
                            onBlur={handleBlur}
                            name='yDimenstionType'
                          />
                        </Stack>
                      )}
                    />

                    {(optionValue.xDimensionType?.type === 'MAIN_MEMBER' ||
                      optionValue.yDimensionType?.type === 'MAIN_MEMBER') && (
                      <Stack direction='row' alignItems='center'>
                        <CheckBox
                          checked={optionValue.showMemberOnly ?? false}
                          onChange={(e) => {
                            setOptionValue((prev) => ({
                              ...prev,
                              showMemberOnly: e.target.checked,
                            }));
                          }}
                          name='active'
                          sx={{
                            color: '#828C99',
                            px: 0,
                            paddingRight: 0.5,
                          }}
                        />
                        <Typography variant='input-label-gray'>Show base member only</Typography>
                      </Stack>
                    )}
                  </Stack>
                </Stack>

                <ControllerNavigation
                  enableNavigations={controllerNaviations}
                  onNavigate={handleOnDashboardNavigate}
                  tooltips={navigationControllerTooltips}
                />
              </Stack>
              <Stack direction='row' alignItems='center' sx={{ pt: 1, px: 2 }} width='100%'>
                <Stack direction='row' gap={3} width='100%' alignItems='center'></Stack>
              </Stack>
            </>
          );
        }}
      </Formik>
    </Stack>
  );
});
