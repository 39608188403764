import { useEffect, useState, useRef } from 'react';
import { Formik, FormikProps } from 'formik';
import Divider from '@mui/material/Divider';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import ButtonLoading from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';

import { SystemItem } from 'types/api/TenantMaster/MasterTable/SystemTableDataTypes';
import {
  useListCurrentSystemDropdown,
  useDeploySystem,
  useListAllSystemStatusDropdown,
} from 'services/v1/TenantMaster/MasterTable/SystemTableDataService';
import { TabSectionSystemDataContent } from './SystemDataContent';
import { TabSectionServerUserContent } from './TabSectionServerUser';
import { CreateAndDeploySystemValidationSchema } from 'config/FormValidaton/TenantMaster/MasterTable/SystemTableValidationScheman';
import { GLOBAL_MODAL_STYLE_VALUE } from 'constant/GloblaStyleConstant';

// Constant for this component
const TAB_PANEL = {
  TAB_PANEL_SYSTEM_DATA: 'TAB_PANEL_SYSTEM_DATA',
  TAB_PANEL_SERVER_USER_DATA: 'TAB_PANEL_SERVER_USER_DATA',
};

// Source of system data when creating a new system
// SCRATCH: Create a new system from scratch
// COPY_FROM_EXISTING_SYSTEM: Copy from an existing system
// If the value is COPY_FROM_EXISTING_SYSTEM, the user will be able to select the system to copy from existing systems
const SYSTEM_DATA_SOURCE = {
  SCRATCH: 'SCRATCH',
  COPY_FROM_EXISTING_SYSTEM: 'COPY_FROM_EXISTING_SYSTEM',
};

const ModalContentStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  minHeight: 610,
  borderRadius: 1,

  py: 2,
};

const TabStyle = {
  textTransform: 'none',
  '&.Mui-selected': {
    color: '#42BB93',
  },
};
const TabIndicatoryStyle = {
  style: {
    backgroundColor: '#42BB93',
  },
};

const closeIconStyle = { color: '#98A2AE', cursor: 'pointer' };
const radioButtonLabelStyle = {
  '& .MuiFormControlLabel-label': {
    fontSize: '14px',
    color: '#98A2AE',
  },
};
const radioButtonStyle = {
  color: '#98A2AE',
  '& .MuiSvgIcon-root': {
    fontSize: 20,
  },
  '&.Mui-checked': {
    color: '#42BB93',
  },
};

const autocompleteStyles = {
  width: '55%',
  '& .MuiOutlinedInput-root': {
    padding: '1px 2px',
  },
  '& .MuiAutocomplete-popper': {
    fontSize: '10px',
  },
};

const textInputAutoCompleteStyles = {
  '& .MuiOutlinedInput': {
    padding: '1px 2px',
  },
};

const optionStyles = { '& > span': { fontSize: '14px', mr: 2, flexShrink: 0 } };

interface formValues {
  sourceSystemId: {
    value: string;
    label: string;
  } | null;
  serverId: {
    value: string;
    label: string;
  };
  userId: string[];
  system: {
    value: string;
    label: string;
  };
  codeStatus: {
    value: string;
    label: string;
  };
  demoStatus: {
    value: string;
    label: string;
  };
  fieldStatus: {
    value: string;
    label: string;
  };
  dataSource: string;
}

const formInitialValues: formValues = {
  serverId: {
    value: '',
    label: '',
  },
  userId: [],
  system: {
    value: '',
    label: '',
  },
  codeStatus: {
    value: '',
    label: '',
  },
  demoStatus: {
    value: '',
    label: '',
  },
  fieldStatus: {
    value: '',
    label: '',
  },
  dataSource: SYSTEM_DATA_SOURCE.SCRATCH,
  sourceSystemId: null,
};
interface ModalParamsProps {
  visible?: boolean;
  data?: SystemItem | null;
  onClose?: () => void;
}

export function ModalCreateSystem(props: ModalParamsProps) {
  const formikRef = useRef<FormikProps<formValues>>(null);
  const { onClose, visible = false } = props;

  const [selectedTabPanel, setSelectedTabPanel] = useState(TAB_PANEL.TAB_PANEL_SYSTEM_DATA);
  const [selectedSourceData, setSelectedSourceData] = useState(SYSTEM_DATA_SOURCE.SCRATCH);
  const { data: listCurrentSystemDropdown } = useListCurrentSystemDropdown();

  const { mutate: deploySystem, isLoading: isDeploying, error, isError, reset } = useDeploySystem();
  const { data: optionsStatus } = useListAllSystemStatusDropdown();

  const handleOnCancel = () => {
    onClose?.();
    reset();
    setSelectedTabPanel(TAB_PANEL.TAB_PANEL_SYSTEM_DATA);
  };

  const handleOnSave = (data: formValues) => {
    const payloadData = {
      codeStatus: data.codeStatus.value,
      fieldStatus: data.fieldStatus.value,
      demoStatus: data.demoStatus.value,
      dataSource: data.dataSource,
      serverId: data.serverId.value,
      sourceSystemId: data.sourceSystemId?.value || null,
      system: data.system.value,
      userId: data.userId,
    };
    deploySystem(payloadData, {
      onSuccess: () => {
        handleOnCancel();
      },
    });
  };

  useEffect(() => {
    if (selectedSourceData === SYSTEM_DATA_SOURCE.SCRATCH) {
      formikRef.current?.setFieldValue('sourceSystemId', null);
    }
  }, [selectedSourceData]);
  useEffect(() => {
    if (optionsStatus) {
      formikRef.current?.setFieldValue(
        'codeStatus',
        optionsStatus.data.codeStatus.find((item) => item.label.toLocaleLowerCase() === 'changing')
      );
      formikRef.current?.setFieldValue(
        'demoStatus',
        optionsStatus.data.demoStatus.find((item) => item.label.toLocaleLowerCase() === 'changing')
      );
      formikRef.current?.setFieldValue(
        'fieldStatus',
        optionsStatus.data.fieldStatus.find((item) => item.label.toLocaleLowerCase() === 'not available')
      );
    }
  }, [optionsStatus]);

  return (
    <div>
      <Modal open={visible} onClose={onClose}>
        <Stack direction='column' sx={ModalContentStyle} justifyContent='space-between'>
          <Formik
            initialValues={formInitialValues}
            onSubmit={handleOnSave}
            innerRef={formikRef}
            validationSchema={CreateAndDeploySystemValidationSchema}
          >
            {(formikProps) => (
              <>
                <Stack direction='column' display='flex' flexGrow={3}>
                  <Stack
                    px={GLOBAL_MODAL_STYLE_VALUE.PADDING_X}
                    justifyContent='space-between'
                    direction='row'
                    alignItems='center'
                    spacing={2}
                  >
                    <Typography variant='modal-title' component='h2'>
                      Add New System
                    </Typography>
                    <IconButton onClick={onClose} component='label'>
                      <CloseIcon sx={closeIconStyle} />
                    </IconButton>
                  </Stack>

                  <Divider color='#E3EBF6' sx={{ mb: 1, mt: 1 }} />

                  <Stack
                    direction='row'
                    px={GLOBAL_MODAL_STYLE_VALUE.PADDING_X}
                    spacing={2}
                    alignItems='center'
                    justifyContent='space-between'
                  >
                    <Typography variant='input-label'>Create System</Typography>
                    <RadioGroup
                      aria-labelledby='demo-radio-buttons-group-label'
                      defaultValue={SYSTEM_DATA_SOURCE.SCRATCH}
                      value={formikProps.values.dataSource}
                      onChange={(event, value) => {
                        formikProps.setFieldValue('dataSource', value);
                        setSelectedSourceData(value);
                      }}
                      name='radio-buttons-group'
                    >
                      <Stack direction='row' spacing={1}>
                        <FormControlLabel
                          value={SYSTEM_DATA_SOURCE.SCRATCH}
                          sx={radioButtonLabelStyle}
                          control={<Radio sx={radioButtonStyle} />}
                          label='From Scratch'
                        />
                        <FormControlLabel
                          value={SYSTEM_DATA_SOURCE.COPY_FROM_EXISTING_SYSTEM}
                          sx={radioButtonLabelStyle}
                          control={<Radio sx={radioButtonStyle} />}
                          label='Copying from existing'
                        />
                      </Stack>
                    </RadioGroup>
                  </Stack>
                  {formikProps.values.dataSource === SYSTEM_DATA_SOURCE.COPY_FROM_EXISTING_SYSTEM && (
                    <Stack
                      direction='row'
                      spacing={2}
                      px={GLOBAL_MODAL_STYLE_VALUE.PADDING_X}
                      alignItems='center'
                      justifyContent='space-between'
                      sx={{ mt: 2, mb: 2 }}
                    >
                      <Typography variant='input-label'>Source System</Typography>
                      <Autocomplete
                        options={listCurrentSystemDropdown?.data || []}
                        // @ts-ignore
                        value={formikProps.values.sourceSystemId}
                        getOptionLabel={(option) => option.label}
                        clearIcon={null}
                        contentEditable={false}
                        onChange={(event, value) => {
                          formikProps.setFieldValue('sourceSystemId', value);
                        }}
                        renderOption={(props, option) => (
                          <Box component='li' sx={optionStyles} {...props}>
                            <span>{option.label}</span>
                          </Box>
                        )}
                        sx={autocompleteStyles}
                        renderInput={(params) => (
                          <TextField {...params} sx={textInputAutoCompleteStyles} placeholder='Please select' />
                        )}
                      />
                    </Stack>
                  )}
                  <Divider color='#E3EBF6' sx={{ mb: 1, mt: 1 }} />

                  <Box px={GLOBAL_MODAL_STYLE_VALUE.PADDING_X}>
                    <Tabs
                      TabIndicatorProps={TabIndicatoryStyle}
                      onChange={(event, newValue) => {
                        setSelectedTabPanel(newValue);
                      }}
                      value={selectedTabPanel}
                    >
                      <Tab sx={TabStyle} value={TAB_PANEL.TAB_PANEL_SYSTEM_DATA} label='System Data' />
                      <Tab sx={TabStyle} value={TAB_PANEL.TAB_PANEL_SERVER_USER_DATA} label='Server & Users' />
                    </Tabs>
                  </Box>

                  {selectedTabPanel === TAB_PANEL.TAB_PANEL_SYSTEM_DATA && (
                    <TabSectionSystemDataContent formikProps={formikProps} />
                  )}

                  {selectedTabPanel === TAB_PANEL.TAB_PANEL_SERVER_USER_DATA && (
                    <TabSectionServerUserContent formikProps={formikProps} />
                  )}
                </Stack>

                {isError && (
                  <Stack>
                    <Alert severity='error'>{error.response?.data.message}</Alert>
                  </Stack>
                )}

                <Stack>
                  <Divider sx={{ mb: 1 }} />
                  <Stack
                    px={GLOBAL_MODAL_STYLE_VALUE.PADDING_X}
                    direction='row'
                    justifyContent='flex-end'
                    spacing={2}
                    alignItems='center'
                    sx={{ py: 1 }}
                  >
                    <Button disabled={isDeploying} variant='main-table-panel-border' onClick={handleOnCancel}>
                      Cancel
                    </Button>
                    <ButtonLoading
                      disabled={!CreateAndDeploySystemValidationSchema.isValidSync(formikProps.values)}
                      variant='main-table-panel'
                      loading={isDeploying}
                      onClick={() => formikProps.handleSubmit()}
                    >
                      Create & Deploy
                    </ButtonLoading>
                  </Stack>
                </Stack>
              </>
            )}
          </Formik>
        </Stack>
      </Modal>
    </div>
  );
}
