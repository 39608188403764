import React from 'react';
import { Card, CardHeader, Chip, Divider, List, ListItem, ListItemText, Button, Stack } from '@mui/material';
import EastOutlinedIcon from '@mui/icons-material/EastOutlined';
import Typography from '@mui/material/Typography';
import { PairItem } from './ModalMappingEventDataSource';
import { CustomNoRowsOverlay } from 'components/DatatableComponent/components/GridOverlay';
interface IListColumnProps {
  items: PairItem[];
  title: string;
  subheader: string;
  onUnpair: (value: PairItem) => void;
}

const chipStaticSubjectStyle = { fontSize: 10, padding: 0, height: 'auto' };

export function PairsList(props: IListColumnProps) {
  const { items, title, subheader, onUnpair = () => {} } = props;

  return (
    <Card
      elevation={0}
      sx={{
        width: '36%',
        border: '1px solid #aac5e6',
      }}
    >
      <CardHeader
        sx={{ px: 2, py: 1 }}
        title={
          <Stack>
            <Typography variant='input-label' fontSize={14} fontWeight={800}>
              {title}
            </Typography>
            <Typography variant='input-label-gray' fontSize={13}>
              {subheader}
            </Typography>
          </Stack>
        }
      />
      <Divider />
      <List
        sx={{
          height: 260,
          bgcolor: 'background.paper',
          overflow: 'auto',
        }}
        dense
        component='div'
        role='list'
      >
        {items.length === 0 && <CustomNoRowsOverlay label='No columns paired' />}

        {items.map((pairItem) => {
          const labelId = `${pairItem.sourceColumn.columnName}-${pairItem.targetColumn.columnName}-label}`;

          return (
            <>
              <ListItem
                key={`${pairItem.sourceColumn.columnName}-${pairItem.targetColumn.columnName}`}
                role='listitem'
                sx={{
                  p: 0,
                  m: 0,
                  px: 2,
                }}
              >
                <ListItemText
                  id={labelId}
                  primary={
                    <Stack
                      direction='row'
                      gap={0.6}
                      alignItems='center'
                      sx={{
                        flexFlow: 'wrap',
                      }}
                    >
                      <Typography variant='body2'>{pairItem.sourceColumn.columnName} </Typography>
                      <EastOutlinedIcon sx={{ color: '#3B4797', fontSize: 14 }} />
                      <Typography variant='body2'>{pairItem.targetColumn.columnName} </Typography>
                    </Stack>
                  }
                  secondary={
                    <Chip
                      label={pairItem.targetColumn.dataType}
                      color='info'
                      variant='outlined'
                      sx={chipStaticSubjectStyle}
                      size='small'
                    />
                  }
                />
                <Button
                  variant='main-table-panel-border'
                  sx={{
                    p: 0,
                  }}
                  onClick={() => onUnpair(pairItem)}
                >
                  Unpair
                </Button>
              </ListItem>
              <Divider />
            </>
          );
        })}
      </List>
    </Card>
  );
}
