import { AxiosResponse } from 'axios';
import { useMutation, useQueryClient, useQuery } from 'react-query';
import axios from 'libs/Axios';
import { AxiosDefaultErrorEntity } from 'types/api/Common/ErrorTypes';
import { AutoCompleteItem } from 'types/api/Common/AutoCompleteTypes';
import {
  SubjectProcessDataUpsertRequest,
  SubjectProcessRowItem,
} from 'types/api/SystemTenant/AROKMS/SubjectProcessTypes';

export interface SubjectDropdownParameter extends AutoCompleteItem {
  persistentName?: string | null;
}

const SUBJECT_PROCESS_QUERY = {
  SUBJECT_PROCESS_LIST: 'SUBJECT_PROCESS_LIST',
  SUBJECT_PROCESS_DROPDOWN: 'SUBJECT_PROCESS_DROPDOWN',
};

export function useUpsertSubjectProcessData() {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosDefaultErrorEntity, SubjectProcessDataUpsertRequest>(
    (bodyRequest) => axios.post('/api/v1/subject-process/upsert', bodyRequest),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([SUBJECT_PROCESS_QUERY.SUBJECT_PROCESS_LIST]);
        queryClient.invalidateQueries([SUBJECT_PROCESS_QUERY.SUBJECT_PROCESS_DROPDOWN]);
      },
    }
  );
}

export function useSubjectProcessList() {
  return useQuery<AxiosResponse<SubjectProcessRowItem[]>, AxiosDefaultErrorEntity>(
    [SUBJECT_PROCESS_QUERY.SUBJECT_PROCESS_LIST],
    () => axios.get(`/api/v1/subject-process/list`).then((res) => res)
  );
}

export function useGetSubjectProcessDropdown() {
  return useQuery<AutoCompleteItem[], AxiosDefaultErrorEntity>([SUBJECT_PROCESS_QUERY.SUBJECT_PROCESS_LIST], () =>
    axios.get(`/api/v1/subject-process/list`).then((res) =>
      res?.data?.map((item: SubjectProcessRowItem) => {
        return {
          label: item.processName,
          value: item.id,
        };
      })
    )
  );
}

export function useDeleteSubjectProcess() {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosDefaultErrorEntity, { id: number | string }>(
    (bodyRequest) => axios.delete(`/api/v1/subject-process/${bodyRequest.id}`),
    {
      onSettled: () => {
        queryClient.invalidateQueries([SUBJECT_PROCESS_QUERY.SUBJECT_PROCESS_LIST]);
        queryClient.invalidateQueries([SUBJECT_PROCESS_QUERY.SUBJECT_PROCESS_DROPDOWN]);
      },
    }
  );
}
