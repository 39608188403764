// @ts-nocheck
import * as React from 'react';
import { GridRenderCellParams, GridColDef, useGridApiContext } from '@mui/x-data-grid';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { DropdownItem } from 'types/api/SystemTenant/AROKMS/TableDefinitionTypes';

const autocompleteStyles = {
  width: '95%',
  '& .MuiOutlinedInput-root': {
    padding: '1px 2px',
  },
  '& .MuiAutocomplete-popper': {
    fontSize: '10px',
  },
};

const textInputStyles = {
  '& .MuiOutlinedInput': {
    padding: '1px 2px',
  },
};

const optionStyles = { '& > span': { fontSize: '14px', mr: 2, flexShrink: 0 } };

function SelectEditInputCell(
  props: GridRenderCellParams & {
    parentSubjectId?: string | number;
    selectedSubjectId?: string | number;
    optionsSource: DropdownItem[];
  }
) {
  const { id, value, field, optionsSource } = props;

  const isFreeSolo = field === 'attribute';

  const apiRef = useGridApiContext();

  const filter = createFilterOptions<{ value: string; label: string; isNew?: boolean }>();

  const handleChange = async (
    event: React.SyntheticEvent,
    value: { label: string; value: string; isNew?: boolean }
  ) => {
    await apiRef.current.setEditCellValue({ id, field, value: value });
    apiRef.current.stopCellEditMode({ id, field });
  };

  return (
    <Autocomplete
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }

        if (option.isNew) {
          return option.value;
        }
        // Regular option
        return option.label || '';
      }}
      selectOnFocus
      filterOptions={(options, params) => {
        if (!isFreeSolo) {
          const filtered = filter(options, params);
          return filtered;
        } else {
          const filtered = filter(options, params);

          const { inputValue } = params;
          // Suggest the creation of a new value
          const isExisting = options.some((option) => inputValue.toLowerCase() === option.label.toLowerCase());

          if (inputValue !== '' && !isExisting) {
            filtered.push({
              value: inputValue,
              label: `Add "${inputValue}"`,
              isNew: true,
            });
          }

          return filtered;
        }
      }}
      disabled={field === 'ruleType' && !props.row?.dataType.id}
      contentEditable={false}
      value={value}
      defaultValue={{ label: value?.label || '', value: value?.value || '' }}
      options={optionsSource || []}
      renderOption={(props, option) => (
        <Box component='li' sx={optionStyles} {...props}>
          <span>{option?.label}</span>
        </Box>
      )}
      sx={autocompleteStyles}
      // @ts-ignore
      onChange={handleChange}
      renderInput={(params) => <TextField {...params} sx={textInputStyles} />}
    />
  );
}

type RenderSelectEditInputCellParams = GridColDef['renderCell'] & {
  parentSubjectId?: string | number;
  seletedSubjectId?: string | number;
  optionsSource: DropdownItem[];
};

const MemoizedSelectInputCell = React.memo(SelectEditInputCell);
export const renderSelectEditInputCellKtreeDefinition: RenderSelectEditInputCellParams = (params) => {
  return <MemoizedSelectInputCell {...params} key={params.id} />;
};
