import { dayjs } from 'utils/DateUtils';
import jwt_decode from 'jwt-decode';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import ButtonLoading from '@mui/lab/LoadingButton';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/VisibilityOutlined';
import VisibilityOff from '@mui/icons-material/VisibilityOffOutlined';
import IconButton from '@mui/material/IconButton';
import { LoginValidationSchema } from 'config/FormValidaton/AuthValidationSchema';
import { BasicAlert } from 'components/AlertComponent';
import { useGetRoleAccessToken, useLogin } from 'services/v1/Auth';
import {
  setRememberMe,
  setRole,
  setSelectedTenant,
  setTenants,
  setProfile,
  setRoles,
  setUserBehaviourRole,
  setAccessToken,
  setSessionStatus,
  logout,
  addBrowserLoginHistory,
} from 'store/reducer/authReducer';
import { removeAuthHeaderToken, setAuthHeaderToken, setAuthTenantId } from 'libs/Axios';
import { getErrorMessage } from 'utils/Error';
import { SESSION_STATUS_CONSTANT } from 'constant/SessionStatusConstant';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { useGetMyPersonalizationLogin } from 'services/v1/Tenant/ConfigurationService';
import { Link } from '@mui/material';
import { FOOTER_MENU_LINK, FOOTER_MENU_OPTIONS } from 'constant/NavigationConstant';
import { setTenantNavigation } from 'store/reducer/uiReducer';

interface formValues {
  userName: string;
  password: string;
}

const formInitialValues: formValues = {
  userName: '',
  password: '',
};

const gridBannerStyle = {
  backgroundImage: `url('/images/auth-banner.jpg')`,
  backgroundRepeat: 'no-repeat',
  backgroundBlendMode: 'color',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  height: '100%',
};

const greetingTextBoldStyle = {
  marginBottom: 2,
  fontWeight: '500',
};

const gridContentContainerStyle = {
  backgroundColor: '#F8FAFF',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
};

const contentContainerStyle = {
  my: 3,
  mx: 4,
  px: 4,
  py: 4,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: '#fff',
  borderRadius: '7px',
  boxShadow: '0px 1px 6px #C8CFE2',
};

export function LoginPage(): JSX.Element {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authReducer = useSelector((state: RootState) => state.auth);

  const { t } = useTranslation();
  const { mutate, isLoading, isError, error } = useLogin();
  const { mutateAsync: refetchPersonalization, isLoading: isLoadingPersonalization } = useGetMyPersonalizationLogin();
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const [isAgreeWithToc, setIsAgreeWithToc] = useState<boolean>(false);
  const [disabledCheckbox, setDisabledCheckbox] = useState<boolean>(false);

  const { mutateAsync: getRoleAccessToken, isLoading: isLoadingRoleAccessToken } = useGetRoleAccessToken();

  const handleSubmit = async (data: formValues) => {
    if (showPassword) setShowPassword(false);
    mutate(data, {
      onSuccess: async (response, variables, context) => {
        const sessionPersistData = {
          rememberMe: false,
          expiredDate: dayjs().add(120, 'minutes').toISOString(),
        };

        dispatch(setRememberMe(sessionPersistData));
        dispatch(setTenants(response.data.tenants));
        dispatch(setRoles(response.data.roles));
        setAuthHeaderToken(response.headers.authorization);

        const decodeToken = jwt_decode(response.headers.authorization);

        // @ts-ignore
        dispatch(setProfile({ name: response.data.fullName, id: decodeToken?.id || null }));

        if (response.data.tenants.length === 1) {
          const res = await getRoleAccessToken({
            roleName: response.data.tenants[0].tenant.roleName,
            tenantId: response.data.tenants[0].tenant.id,
          });
          const roleAccessToken = res.data.data;
          setAuthHeaderToken(roleAccessToken);
          dispatch(setAccessToken(roleAccessToken));
          dispatch(setRole(response.data.tenants[0].roleType));
          dispatch(setSelectedTenant(response.data.tenants[0]));
          setAuthTenantId(response.data.tenants[0].tenant.id);
          dispatch(setUserBehaviourRole(response.data.tenants[0].roleType));
        }

        dispatch(setSessionStatus(SESSION_STATUS_CONSTANT.ACTIVE));
        await refetchPersonalization();
        dispatch(
          addBrowserLoginHistory({
            timestamp: dayjs().toISOString(),
            // @ts-ignore
            userFullName: response.data.fullName,
          })
        );
        navigate('/');
      },
      onError: (error) => {
        removeAuthHeaderToken();
      },
    });
  };
  const handleConfirmPasswordToggle = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (authReducer.accessToken) {
      dispatch(setTenantNavigation(false));
      dispatch(logout());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (authReducer.browserLoginHistory?.length > 0) {
      setIsAgreeWithToc(true);
      setDisabledCheckbox(true);
    }
  }, [authReducer.browserLoginHistory]);

  const isSubmitting = isLoadingPersonalization || isLoadingRoleAccessToken;

  return (
    <Grid container component='main' sx={{ height: '100vh' }}>
      <CssBaseline />

      <Grid
        item
        display='flex'
        flexDirection='column'
        alignItems='center'
        paddingBottom={3}
        justifyContent='space-between'
        xs={5}
        sx={gridBannerStyle}
      >
        <Box width='100%' justifyContent='center' display='flex' sx={{ marginTop: 25 }}>
          <Stack direction='column' alignItems='flex-start' width='75%'>
            <Typography component='h2' variant='h5' color='#fff' sx={greetingTextBoldStyle}>
              {t('PAGE.LOGIN.WELCOME_TITLE')}
            </Typography>

            <Typography component='p' variant='subtitle1' color='#fff'>
              {t('PAGE.LOGIN.WELCOME_DESCRIPTION')}
            </Typography>
          </Stack>
        </Box>
        <Box width='100%' justifyContent='center' display='flex'>
          <Stack
            direction='row'
            width='75%'
            alignItems='center'
            justifyContent='flex-start'
            gap={3}
            height={80}
            divider={<Divider orientation='vertical' flexItem color='#fff' />}
          >
            <Stack direction='row' gap={1} mt={2}>
              <div>
                <img
                  src='/images/jazanz_logo.jpg'
                  alt='Jas ANZ Logo'
                  style={{
                    width: 'auto',
                    height: 60,
                  }}
                />
              </div>
              <div>
                <img
                  src='/images/iso_9001_certified.svg'
                  alt='ISO 9001 Certified'
                  style={{
                    background: 'white',
                    width: 'auto',
                    height: 60,
                  }}
                />
              </div>
            </Stack>
            <Stack>
              <Stack direction='row' spacing={2}>
                {FOOTER_MENU_OPTIONS.map((menu) => (
                  <Link
                    href={menu.link}
                    underline='hover'
                    target='_blank'
                    sx={{
                      color: '#fff',
                      fontSize: 12,
                      fontWeight: 'bold',
                      '&:hover': {
                        color: '#fff',
                      },
                    }}
                  >
                    {menu.label}
                  </Link>
                ))}
              </Stack>
              <Typography component='p' variant='subtitle1' color='#fff' sx={{ fontSize: 10, marginTop: 1 }}>
                {`©️ ${dayjs().year()} ARO Forecasting Pty Ltd All Rights Reserved.`}
              </Typography>
            </Stack>
          </Stack>
        </Box>
      </Grid>

      <Grid item xs={7} sx={gridContentContainerStyle}>
        <Box width={500} sx={contentContainerStyle}>
          <Box width={400} display='flex' justifyContent='space-between'>
            <Typography
              component='h5'
              variant='h5'
              color='#394798'
              sx={{
                marginBottom: 3,
              }}
            >
              {t('PAGE.LOGIN.FORM_TITLE')}
            </Typography>

            <div>
              <img
                src='/images/main-logo.svg'
                alt='logo'
                style={{
                  width: '100px',
                  height: 'auto',
                }}
              />
            </div>
          </Box>
          <Box width={400}>
            {!isError && (
              <Typography component='h4' variant='h4' color='#2C2A29'>
                {t('PAGE.LOGIN.FORM_SUB_TITLE')}
              </Typography>
            )}
            {isError && <BasicAlert type='info' title={getErrorMessage(error)} />}

            <Formik initialValues={formInitialValues} onSubmit={handleSubmit} validationSchema={LoginValidationSchema}>
              {({ handleChange, handleBlur, handleSubmit, touched, errors, values }) => (
                <form
                  onSubmit={handleSubmit}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleSubmit();
                    }
                  }}
                >
                  <Stack mt={2}>
                    <Typography component='p' variant='input-label' color='#98A2AE'>
                      {t('PAGE.LOGIN.FORM_LOGIN_INPUT_EMAIL_LABEL')}
                    </Typography>
                    <TextField
                      margin='dense'
                      value={values.userName}
                      disabled={isLoading || isSubmitting}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.userName && !!errors.userName}
                      helperText={touched.userName && errors.userName}
                      fullWidth
                      placeholder='Enter your email address'
                      name='userName'
                      variant='outlined'
                    />
                  </Stack>
                  <Stack>
                    <Typography component='p' variant='input-label' color='#98A2AE'>
                      {t('PAGE.LOGIN.FORM_LOGIN_INPUT_PASSWORD_LABEL')}
                    </Typography>
                    <TextField
                      value={values.password}
                      disabled={isLoading || isSubmitting}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.password && !!errors.password}
                      helperText={touched.password && errors.password}
                      margin='dense'
                      fullWidth
                      placeholder='Enter your password'
                      name='password'
                      type={showPassword ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <IconButton onClick={handleConfirmPasswordToggle} size='small'>
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      variant='outlined'
                    />
                  </Stack>
                  <Box display='flex' alignItems='center' justifyContent='space-between' mt={1}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          value='remember'
                          disabled={disabledCheckbox}
                          checked={isAgreeWithToc}
                          onChange={(e) => {
                            setIsAgreeWithToc(e.target.checked);
                          }}
                          style={{
                            color: '#3ABB93',
                            opacity: disabledCheckbox ? 0.7 : 1,
                          }}
                        />
                      }
                      label={
                        <Typography component='p' variant='body2' color='#98A2AE'>
                          I have read and accept the{' '}
                          <Link href={FOOTER_MENU_LINK.TERMS_OF_USE} target='_blank' color='primary'>
                            Terms of Use
                          </Link>{' '}
                          and{' '}
                          <Link href={FOOTER_MENU_LINK.PRIVACY_POLICY} target='_blank' color='primary'>
                            Privacy Policy
                          </Link>
                        </Typography>
                      }
                    />
                  </Box>

                  <ButtonLoading
                    type='submit'
                    fullWidth
                    loading={isLoading || isSubmitting}
                    loadingPosition='start'
                    onClick={() => handleSubmit()}
                    variant='main-login'
                    sx={{
                      mt: 2,
                      mb: 2,
                    }}
                    disabled={!LoginValidationSchema.isValidSync(values) || !isAgreeWithToc}
                  >
                    {t('PAGE.LOGIN.FORM_BUTTON_LOGIN')}
                  </ButtonLoading>
                </form>
              )}
            </Formik>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
