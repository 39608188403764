import { FILE_TYPE_PARAMS } from 'constant/ImportExportConstant';

export function downloadFileFromURL(url: string, filename?: string) {
  var link = document.createElement('a');
  link.href = url;
  if (filename) link.download = filename;
  link.click();
}

export function getFileName(fileName: string, fileType?: string) {
  if (!fileType) return fileName;
  if (FILE_TYPE_PARAMS.EXCEL === fileType) {
    return `${fileName}_${Math.round(new Date().getTime() / 1000)}.xlsx`;
  }
  if (FILE_TYPE_PARAMS.CSV === fileType) {
    return `${fileName}_${Math.round(new Date().getTime() / 1000)}.csv`;
  }
  return `${Math.round(new Date().getTime() / 1000)}.xlsx`;
}
