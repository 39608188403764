import { useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import ButtonLoading from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import AutoComplete from '@mui/material/Autocomplete';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import { DropdownItem } from 'types/api/SystemTenant/AROKMS/TableDefinitionTypes';
import { AutoCompleteItem } from 'types/api/Common/AutoCompleteTypes';
import {
  useGetCubeDefinitionTimePeriodSet,
  useGetPullCubeDropdown,
  useGetPullCubeLayerDropdown,
} from 'services/v1/SystemTenant/AROCube/CubeBuilderService';
import { ModalCubeDefinitionParamsProps } from './ModalCubeDefinitionParameter';
import { CubeDefinitionRowItem } from 'types/api/SystemTenant/AROCube/CubeBuilderTypes';
import { FormCubeDefinitionMetaDataValues } from './CubeDefinitionSettingPanel';
import { Alert } from '@mui/material';
import { RelativeTimeType } from 'pages/SystemTenant/AROView/InsightViewDefinition/components/RelativeTimeType';
import { InsightViewRelativeTimeType, InsightViewRelativeTimeCategory } from 'constant/ViewConstant';
import { useFormik } from 'formik';
import { getInsightViewRelativeTimeDescription } from 'utils/DateUtils';
import { isPositiveNumber } from 'utils/NumberUtils';
import { CUBE_TIME_PERIOD_TYPE } from 'constant/CubeConstant';

const autoCompleteStyle = {
  width: 260,
  '& .MuiAutocomplete-popper': {
    backgroundColor: 'red !imoprtant',
    fontSize: '10px',
  },
};
const ModalContentStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 550,
  bgcolor: 'background.paper',
  maxHeight: 500,
  overflow: 'auto',
  borderRadius: 1,
  py: 2,
};

const closeIconStyle = { color: '#98A2AE', cursor: 'pointer' };

type FormikPullFromCubeToBaseLineValueParameter = {
  id?: string;
  targetPullCube: DropdownItem | null;
  targetPullCubeLayer: DropdownItem | null;
  targetRelativeYearType: string | null;
  targetRelativeYear: number | null;
  targetRelativePeriod: number | null;
};

const initialValues: FormikPullFromCubeToBaseLineValueParameter = {
  targetPullCube: null,
  targetPullCubeLayer: null,
  targetRelativeYearType: InsightViewRelativeTimeType.CURRENT_TIME,
  targetRelativeYear: 0,
  targetRelativePeriod: null,
};

const getRelativePeriodValidation = (timePeriodType?: string) => {
  switch (timePeriodType) {
    case CUBE_TIME_PERIOD_TYPE.MONTH:
      return Yup.number().max(12, 'Max value for months is 12');
    case CUBE_TIME_PERIOD_TYPE.QUARTER:
      return Yup.number().max(4, 'Max value for quarters is 4');
    case CUBE_TIME_PERIOD_TYPE.YEAR:
      return Yup.number().max(1, 'Max value for years is 1');
    case CUBE_TIME_PERIOD_TYPE.WEEK:
      return Yup.number().max(52, 'Max value for weeks is 52');
    default:
      return Yup.number();
  }
};

const getRelativeYearValidation = (relativeYearType: string, futureYears: number, pastYears: number) => {
  switch (relativeYearType) {
    case InsightViewRelativeTimeType.FUTURE_TIME:
      return Yup.number().max(futureYears, `Max value for future years is ${futureYears}`);
    case InsightViewRelativeTimeType.PAST_TIME:
      return Yup.number().max(pastYears, `Max value for past years is ${pastYears}`);
    default:
      return Yup.number();
  }
};

export function ModalPullFromCubeToBaselineValueParameter(
  props: ModalCubeDefinitionParamsProps & { cubeMetaData: FormCubeDefinitionMetaDataValues }
) {
  const { onClose, visible = false, onSubmitParameter, cubeDefinitionItem, cubeMetaData } = props;
  const [targetPullCube, setTargetPullCube] = useState<DropdownItem | null>(null);
  const [selectedRelativeYearType, setSelectedRelativeYearType] = useState<string | null>(null);

  const { data: targetPullCubeTimePeriodSet, isLoading: isLoadingTargetPullCubeTimePeriodSet } =
    useGetCubeDefinitionTimePeriodSet(targetPullCube?.value);

  const validationSchema = useMemo(() => {
    const futureYears = targetPullCubeTimePeriodSet?.data.futureYears ?? 0;
    const pastYears = targetPullCubeTimePeriodSet?.data.pastYears ?? 0;

    const timePeriodType = targetPullCubeTimePeriodSet?.data.timePeriodType;

    return Yup.object().shape({
      targetPullCube: Yup.object().nullable().required('Please select cube'),
      targetPullCubeLayer: Yup.object().nullable().required('Please select cube layer'),
      targetRelativeYearType: Yup.string().nullable().required('Please select relative year type'),
      targetRelativeYear: getRelativeYearValidation(selectedRelativeYearType ?? '', futureYears, pastYears)
        .nullable()
        .required('Please enter relative year'),
      targetRelativePeriod: getRelativePeriodValidation(timePeriodType)
        .nullable()
        .required('Please enter relative period'),
    });
  }, [targetPullCubeTimePeriodSet, selectedRelativeYearType]);

  const formik = useFormik<FormikPullFromCubeToBaseLineValueParameter>({
    initialValues,
    validationSchema,
    onSubmit: (values) => {},
  });

  const { data: pullCubeDropdownOptions, isLoading: isLoadingSubjectEvent } = useGetPullCubeDropdown({
    attributeId: cubeDefinitionItem?.attribute.value,
  });

  const { data: pullCubeLayerDropdownOptions, isLoading: isLoadingCubeLayerOptions } = useGetPullCubeLayerDropdown(
    formik.values.targetPullCube?.value
  );

  const handleOnCancel = () => {
    handleOnCloseModal();
  };

  const handleOnCloseModal = () => {
    onClose?.();
    formik.resetForm();
    setTargetPullCube(null);
    setSelectedRelativeYearType(null);
  };

  const handleOnRelativePeriodTypeChange = (selectedVal: string) => {
    formik.setFieldValue('targetRelativeYearType', selectedVal);
    setSelectedRelativeYearType(selectedVal);
    const isCurrentTime = selectedVal === InsightViewRelativeTimeType.CURRENT_TIME;
    const shouldResetRelativePeriod = isCurrentTime || formik.values.targetRelativeYear === 0;
    if (shouldResetRelativePeriod) {
      formik.setFieldValue('targetRelativeYear', isCurrentTime ? 0 : 1);
    }
  };

  const handleOnSave = () => {
    if (cubeDefinitionItem && formik.values.targetPullCube && formik.values.targetPullCubeLayer) {
      const cubePullEventDefinitionRow: CubeDefinitionRowItem = {
        ...cubeDefinitionItem,
        targetPullCubeAttribute: cubeDefinitionItem.attribute,
        targetPullCube: formik.values.targetPullCube,
        ruleTypeParams: formik.values?.targetPullCube?.label,
        targetPullCubeLayer: formik.values?.targetPullCubeLayer,
        targetRelativeYearType: formik.values.targetRelativeYearType ?? undefined,
        targetRelativeYear: formik.values.targetRelativeYear ?? undefined,
        targetRelativePeriod: formik.values.targetRelativePeriod ?? undefined,
      };
      onSubmitParameter?.(cubePullEventDefinitionRow);
      formik.resetForm();
      setTargetPullCube(null);
      setSelectedRelativeYearType(null);
    }
  };

  const handleOnChangePositiveNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isPositiveNumber(Number(e.target.value)) && e.target.value !== '0') {
      formik.handleChange(e);
    }
  };

  useEffect(() => {
    if (cubeDefinitionItem?.targetPullCube) {
      formik.setFieldValue('targetPullCube', cubeDefinitionItem.targetPullCube);
      formik.setFieldValue('targetPullCubeLayer', cubeDefinitionItem.targetPullCubeLayer);
      formik.setFieldValue('targetRelativeYearType', cubeDefinitionItem.targetRelativeYearType);
      formik.setFieldValue('targetRelativeYear', cubeDefinitionItem.targetRelativeYear);
      formik.setFieldValue('targetRelativePeriod', cubeDefinitionItem.targetRelativePeriod);
      setTargetPullCube(cubeDefinitionItem.targetPullCube);
      cubeDefinitionItem.targetRelativeYearType &&
        setSelectedRelativeYearType(cubeDefinitionItem.targetRelativeYearType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cubeDefinitionItem]);

  return (
    <div>
      <Modal open={visible} onClose={handleOnCloseModal}>
        <Stack direction='column' sx={ModalContentStyle}>
          <Stack>
            <Stack px={2} justifyContent='space-between' direction='row' alignItems='center'>
              <Typography variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
                Pull From Cube To Baseline Value
              </Typography>
              <IconButton onClick={handleOnCloseModal} component='label'>
                <HighlightOffRoundedIcon sx={closeIconStyle} />
              </IconButton>
            </Stack>
          </Stack>

          <Stack px={2}>
            <Alert severity='warning' sx={{ mb: 2 }}>
              <Typography variant='body2' mb={1}>
                This rule only works if the source cube and the destination cube has different layer set.
              </Typography>
            </Alert>
            <Stack
              sx={{
                bgcolor: '#FBFBFB',
                py: 2,
                borderTop: '1px solid #E3EBF6',
                borderBottom: '1px solid #E3EBF6',
              }}
              gap={1}
              direction='row'
              mb={2}
            >
              <Stack>
                <Typography variant='input-label' fontWeight='bold' color='black' mb={1}>
                  Select Cube :
                </Typography>
                {(!cubeMetaData.timePeriod || !cubeMetaData.layerSet || !cubeMetaData.cubeName) && (
                  <Stack mb={1}>
                    <Alert severity='warning'>
                      <Typography variant='caption' mb={1}>
                        Please define the cube name,time period, and layer set for the cube before pulling the data.
                      </Typography>
                    </Alert>
                  </Stack>
                )}
                <AutoComplete
                  // @ts-ignore
                  onChange={(event, value) => {
                    formik.setFieldValue('targetPullCube', value);
                    formik.setFieldValue('targetPullCubeLayer', null);
                    setTargetPullCube(value);
                  }}
                  clearIcon={null}
                  size='small'
                  value={formik.values.targetPullCube}
                  onBlur={formik.handleBlur}
                  disabled={
                    isLoadingSubjectEvent ||
                    !cubeMetaData.timePeriod ||
                    !cubeMetaData.layerSet ||
                    !cubeMetaData.cubeName ||
                    isLoadingTargetPullCubeTimePeriodSet
                  }
                  getOptionLabel={(option: AutoCompleteItem) => option.label}
                  options={pullCubeDropdownOptions?.data ?? []}
                  renderOption={(props, option) => (
                    <Box component='li' sx={{ '& > span': { fontSize: '14px', mr: 1, flexShrink: 0 } }} {...props}>
                      <span>{option.label}</span>
                    </Box>
                  )}
                  sx={autoCompleteStyle}
                  renderInput={(params) => (
                    <Stack direction='row' justifyContent='space-between' display='flex' alignItems='center'>
                      <TextField
                        {...params}
                        name='targetPullCube'
                        onBlur={formik.handleBlur}
                        helperText={formik.touched.targetPullCube && formik.errors.targetPullCube}
                        error={formik.touched.targetPullCube && !!formik.errors.targetPullCube}
                        placeholder={isLoadingSubjectEvent ? 'Loading...' : 'Please Select Cube'}
                      />
                    </Stack>
                  )}
                />
              </Stack>

              <Stack>
                <Typography variant='input-label' fontWeight='bold' color='black' mb={1}>
                  Select Cube Layer:
                </Typography>

                <AutoComplete
                  // @ts-ignore
                  onChange={(event, value) => {
                    formik.setFieldValue('targetPullCubeLayer', value);
                  }}
                  clearIcon={null}
                  size='small'
                  value={formik.values.targetPullCubeLayer}
                  onBlur={formik.handleBlur}
                  disabled={
                    isLoadingCubeLayerOptions ||
                    !cubeMetaData.timePeriod ||
                    !cubeMetaData.layerSet ||
                    !cubeMetaData.cubeName
                  }
                  getOptionLabel={(option: AutoCompleteItem) => option.label}
                  options={pullCubeLayerDropdownOptions?.data ?? []}
                  renderOption={(props, option) => (
                    <Box component='li' sx={{ '& > span': { fontSize: '14px', mr: 1, flexShrink: 0 } }} {...props}>
                      <span>{option.label}</span>
                    </Box>
                  )}
                  sx={autoCompleteStyle}
                  renderInput={(params) => (
                    <Stack direction='row' justifyContent='space-between' display='flex' alignItems='center'>
                      <TextField
                        {...params}
                        name='targetPullCubeLayer'
                        onBlur={formik.handleBlur}
                        helperText={formik.touched.targetPullCubeLayer && formik.errors.targetPullCubeLayer}
                        error={formik.touched.targetPullCubeLayer && !!formik.errors.targetPullCubeLayer}
                        placeholder={isLoadingCubeLayerOptions ? 'Loading...' : 'Please Select Cube'}
                      />
                    </Stack>
                  )}
                />
              </Stack>
            </Stack>
            <Stack gap={1} direction='row' justifyContent='space-between'>
              <Stack direction='column' gap={1} width='100%'>
                <Stack>
                  <Typography variant='body2' fontWeight='bold' color='black'>
                    Relative Year
                  </Typography>
                </Stack>
                <RelativeTimeType
                  value={formik.values.targetRelativeYearType}
                  onChange={handleOnRelativePeriodTypeChange}
                />

                <TextField
                  size='small'
                  placeholder='e.g. 1'
                  type='number'
                  name='targetRelativeYear'
                  onChange={handleOnChangePositiveNumber}
                  onBlur={formik.handleBlur}
                  value={formik.values.targetRelativeYear}
                  error={formik.touched.targetRelativeYear && !!formik.errors.targetRelativeYear}
                  helperText={formik.touched.targetRelativeYear && formik.errors.targetRelativeYear}
                  disabled={formik.values.targetRelativeYearType === InsightViewRelativeTimeType.CURRENT_TIME}
                />

                {formik.values.targetRelativeYearType &&
                  formik.values.targetRelativeYear != null &&
                  formik.errors.targetRelativeYear == null && (
                    <Typography variant='input-label-gray' mb={1}>
                      {getInsightViewRelativeTimeDescription(
                        formik.values.targetRelativeYear,
                        formik.values.targetRelativeYearType,
                        InsightViewRelativeTimeCategory.YEAR_TIME
                      )}
                    </Typography>
                  )}
              </Stack>
              <Stack direction='column' gap={1} width='100%'>
                <Stack>
                  <Typography variant='body2' fontWeight='bold' color='black'>
                    Period in the year
                  </Typography>
                </Stack>
                <TextField
                  size='small'
                  placeholder='e.g. 1'
                  type='number'
                  name='targetRelativePeriod'
                  onChange={handleOnChangePositiveNumber}
                  onBlur={formik.handleBlur}
                  value={formik.values.targetRelativePeriod}
                  error={formik.touched.targetRelativePeriod && !!formik.errors.targetRelativePeriod}
                  helperText={formik.touched.targetRelativePeriod && formik.errors.targetRelativePeriod}
                />
              </Stack>
            </Stack>
          </Stack>

          <Stack height={300} px={2}></Stack>
          <Stack px={2}>
            <Divider sx={{ mb: 1, mt: 2 }} />
            <Stack direction='row' justifyContent='flex-end' spacing={2} alignItems='center' sx={{ py: 1 }}>
              <Button variant='main-table-panel-border' onClick={handleOnCancel}>
                Cancel
              </Button>
              <ButtonLoading
                variant='main-table-panel'
                onClick={() => handleOnSave()}
                disabled={!validationSchema.isValidSync(formik.values)}
              >
                Save Parameter
              </ButtonLoading>
            </Stack>
          </Stack>
        </Stack>
      </Modal>
    </div>
  );
}
