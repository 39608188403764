import Stack from '@mui/material/Stack';
import { useSelector } from 'react-redux';
import KeyboardDoubleArrowRight from '@mui/icons-material/KeyboardDoubleArrowRight';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { RootState } from 'store';
import { useGetTenantDataSourceMigrationDropdown } from 'services/v1/Tenant/DataMigrationService';
import { DropdownItem } from 'types/api/SystemTenant/AROKMS/TableDefinitionTypes';
import { Typography } from '@mui/material';

interface IProps {
  onChange: (value: DropdownItem) => void;
}

export function StepSelectTenant(props: IProps) {
  const { onChange } = props;
  const { data: tenantOptions, isLoading } = useGetTenantDataSourceMigrationDropdown();
  const { selectedTenant } = useSelector((state: RootState) => state.auth);

  if (isLoading) return null;

  const currentTenantOptions = [
    {
      id: selectedTenant.tenant.id,
      label: selectedTenant.tenant.tenantCode,
    },
  ];

  return (
    <>
      <Typography>Select Source</Typography>
      <Stack
        display='flex'
        flexDirection='row'
        alignItems='center'
        gap={3}
        p={4}
        mt={3}
        borderRadius={2}
        border='1px solid #E3EBF6'
      >
        <Autocomplete
          disablePortal
          id='select-source-tenant'
          onChange={(e, newValue) => {
            onChange(newValue as DropdownItem);
          }}
          options={tenantOptions?.data || []}
          sx={{ width: 260 }}
          renderInput={(params) => <TextField {...params} label='Data Source' placeholder='Select Data Source' />}
        />
        <KeyboardDoubleArrowRight sx={{ color: '#00B3DC' }} />
        <Autocomplete
          disablePortal
          id='select-target-tenant'
          disabled
          value={currentTenantOptions[0]}
          options={currentTenantOptions}
          sx={{ width: 260 }}
          renderInput={(params) => <TextField {...params} label='Target Source' />}
        />
      </Stack>
    </>
  );
}
