export const MEMBER_LIST_STATIC_RECORD = {
  CODE_STATUS: {
    CHANGING: 'member_list_record@b360948b-3472-4ec5-8567-d71561cd4f29',
    LOCKED: 'member_list_record@5f9cca7c-db22-4712-92a7-343a8dcd512b',
  },
  DEMO_STATUS: {
    CHANGING: 'member_list_record@d658c640-f611-4dcd-a39e-c4d6ef9b5000',
    LOCKED: 'member_list_record@d20897cf-f5bf-4a32-9163-e4d7b2cec7ec',
  },
  FIELD_STATUS: {
    NOT_AVAILABLE: 'member_list_record@55fff8f1-4452-4641-b092-d281a19ef9f9',
    RELEASED: 'member_list_record@b2bd0fc2-b5ce-41eb-a5dc-37399a1da004',
  },
};
