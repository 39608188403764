import {
  GridRowModel,
  GridFilterOperator,
  GridFilterInputValue,
  GridFilterItem,
  GridStateColDef,
} from '@mui/x-data-grid-pro';

export const customDropdownFilterOperator: GridFilterOperator = {
  value: 'dropdownFilter',
  getApplyFilterFn: (filterItem: GridFilterItem, columnField: GridStateColDef) => {
    if (!filterItem.value) {
      return null;
    }
    return (params: GridRowModel) => {
      const value = params.value as { value: string; label: string } | string;
      const filterValue = filterItem.value.toLowerCase();
      if (typeof value === 'string') return value?.toLowerCase().includes(filterItem.value.toLowerCase());
      return value?.label?.toLowerCase().includes(filterValue);
    };
  },
  InputComponent: GridFilterInputValue,
  InputComponentProps: { type: 'text' },
};
