import { GridPreProcessEditCellProps, GridRenderEditCellParams, GridValueFormatterParams } from '@mui/x-data-grid-pro';

import { DataGridCellTextInput } from 'components/DatatableComponent/RenderCustomTextInput';
import { renderNumberInputEditCell } from 'components/DatatableComponent/CustomNumberInput';

//  Rule Type: For Percentage

// #RULE_PERCENTAGE_OPERATION
export function RulePercentOperation(operation: (values: number[]) => number, columns: string[]) {
  return {
    type: 'number',
    renderEditCell: (params: GridRenderEditCellParams) => <DataGridCellTextInput {...params} />,
    preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
      const values = columns.map((field) => {
        const fieldValue = params?.otherFieldsProps?.[field].value;
        return typeof fieldValue === 'number' ? fieldValue : 0;
      });

      let value;
      try {
        value = operation(values);
      } catch (error) {
        console.error('Error during operation execution:', error);
      }

      return { ...params.props, value };
    },
    valueFormatter: (params: GridValueFormatterParams) =>
      params.value !== null && params.value !== undefined ? `${params.value?.toLocaleString()} %` : '',
  };
}

export const RulePercentAddition = (columns: string[]) =>
  RulePercentOperation((values) => values.reduce((a, b) => a + b, 0), columns);

export const RulePercentSubstraction = (columns: string[]) =>
  RulePercentOperation((values) => values.reduce((a, b) => a - b), columns);

export const RulePercentMultiplication = (columns: string[]) =>
  RulePercentOperation((values) => values.reduce((a, b) => a * b, 1), columns);

export const RulePercentDivision = (columns: string[]) =>
  // @ts-ignore
  RulePercentOperation((values) => {
    if (values.includes(0)) {
      console.error('Division by zero detected');
      return;
    }

    return values.reduce((a, b) => a / b);
  }, columns);

export function RulePercentInput(isRequired: boolean = false) {
  return {
    type: 'number',

    renderEditCell: (params: GridRenderEditCellParams) =>
      // @ts-ignore
      renderNumberInputEditCell({ ...params, suffix: '%', isRequired }),
    valueFormatter: (params: GridValueFormatterParams) =>
      params.value !== null && params.value !== undefined ? `${params.value?.toLocaleString()} %` : '',
  };
}

export function RulePercentageRange(minValue: number | undefined, maxValue: number | undefined) {
  return {
    type: 'number',
    renderEditCell: (params: GridRenderEditCellParams) =>
      // @ts-ignore
      renderNumberInputEditCell({ ...params, suffix: '%', min: minValue, max: maxValue }),
    valueFormatter: (params: GridValueFormatterParams) =>
      params.value !== null && params.value !== undefined ? `${params.value?.toLocaleString()} %` : '',
  };
}
