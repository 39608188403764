import { AxiosResponse } from 'axios';
import { useMutation } from 'react-query';
import qs from 'query-string';
import axios from 'libs/Axios';

import { AxiosDefaultErrorEntity } from 'types/api/Common/ErrorTypes';

interface UploadScanFileRequest {
  category: string;
  formData: FormData;
}
interface UploadScanFileResponse {
  totalRow: number;
  message: string;
}

export function useExportDataTable() {
  return useMutation<
    AxiosResponse,
    AxiosDefaultErrorEntity,
    { category: string; fileType?: string; dataCategory?: string }
  >((bodyRequest) =>
    axios.get(
      `/${bodyRequest.category}/export?${qs.stringify({
        fileType: bodyRequest.fileType,
        category: bodyRequest.dataCategory,
      })}`,
      {
        responseType: 'blob',
      }
    )
  );
}

export function useDownloadDataImportTemplate() {
  return useMutation<
    AxiosResponse,
    AxiosDefaultErrorEntity,
    { category: string; parameters?: { [key: string]: string }; fileType?: string }
  >((bodyRequest) => {
    return axios.get(
      `/${bodyRequest.category}/import/sample-file?${qs.stringify({
        fileType: bodyRequest.fileType,
        ...bodyRequest.parameters,
      })}`,
      {
        responseType: 'blob',
      }
    );
  });
}

export function useUploadScanFileImport(params?: { [key: string]: string | number | boolean }) {
  return useMutation<AxiosResponse<UploadScanFileResponse>, AxiosDefaultErrorEntity, UploadScanFileRequest>(
    (bodyRequest) => {
      const { category, formData } = bodyRequest;
      return axios.post(
        params ? `/${category}/import/initial?${qs.stringify(params)}` : `/${category}/import/initial`,
        formData
      );
    }
  );
}

export function useUploadFileImport(params?: { [key: string]: string | number | boolean }) {
  return useMutation<AxiosResponse<UploadScanFileResponse>, AxiosDefaultErrorEntity, UploadScanFileRequest>(
    (bodyRequest) => {
      const { category, formData } = bodyRequest;
      return axios.post(params ? `/${category}/import?${qs.stringify(params)}` : `/${category}/import`, formData, {
        responseType: 'blob',
      });
    }
  );
}
