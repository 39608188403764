
import { useTranslation } from 'react-i18next';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import Alert from "@mui/material/Alert";

const ModalContentStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  borderRadius: 1,
  px: 3,
  py: 2,
};

const buttonActionStyle = { width: '100%' };

const closeIconStyle = { color: '#98A2AE', cursor: 'pointer' };

export type ModalInfoState = {
  title?: string;
  open: boolean;
  key?: string | number;
  descriptionMessage?: string;
};

export interface ModalInfoProps {
  visible?: boolean;
  onClose?: () => void;
  title?: string;
  descriptionMessage?: string;
  data?: string[];
}

export default function ModalInfoComponent(props: ModalInfoProps) {
  const { t } = useTranslation();
  const {
    onClose,
    visible = false,
    descriptionMessage = 'One of the subject selected cannot be deleted as it is being referenced by other data in the system',
    title = t('COMMON.MODAL_DELETE.DESCRIPTION'),
    data = [],
  } = props;

  return (
    <div>
      <Modal open={visible} onClose={onClose}>
        <Stack direction='column' sx={ModalContentStyle}>
          <Stack justifyContent='space-between' direction='row' spacing={2} alignItems="center" textAlign='center'>
            <Typography variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
              {title}
            </Typography>
            <IconButton onClick={onClose} component='label'>
              <HighlightOffRoundedIcon sx={closeIconStyle} />
            </IconButton>
          </Stack>
          <Divider sx={{ mb: 1 }} />
          <Stack alignItems='flex-start' sx={{ py: 3 }}>
            <Alert severity='warning'>
              <Typography variant='body1' align='left'>
                {descriptionMessage}
              </Typography>
              {data.map((item, index) => (
                <ul key={index} style={{
                  fontSize: '15px',
                  fontWeight: 'bold',
                }}>
                  <li>{item}</li>
                </ul>
              ))}
            </Alert>

          </Stack>
          <Divider sx={{ mb: 1 }} />

          <Button variant='main-table-panel' sx={buttonActionStyle} onClick={onClose}>
            Close
          </Button>
        </Stack>
      </Modal>
    </div>
  );
}
