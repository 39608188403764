import { useIsMutating } from 'react-query';
import { useTranslation } from 'react-i18next';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import ButtonLoading from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Alert from '@mui/material/Alert';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';
import { DataInputObjectFile } from 'types/api/Tenant/AROKMS/DisplayTableTypes';
import DescriptionIcon from '@mui/icons-material/Description';
import { getYoutubeThumbnailFromURL } from 'utils/String';

const ModalContentStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  borderRadius: 1,
  px: 3,
  py: 2,
};

const buttonActionStyle = { width: '100%', textTransform: 'none' };

const closeIconStyle = { color: '#98A2AE', cursor: 'pointer' };

export type ModalDeleteDataInputObject = {
  title?: string;
  message: string;
  open: boolean;
  isError?: boolean;
  key?: string | number;
  errorMessage?: string;
};

export interface ModalDeleteDataInputObjectProps {
  visible?: boolean;
  onClose?: () => void;
  onCancel?: () => void;
  onApprove?: (item: DataInputObjectFile, sectionIndex: number) => void;
  item?: {
    data: DataInputObjectFile;
    sectionIndex: number;
  };
}

export default function ModalDeleteDataInputObjectConfirmation(props: ModalDeleteDataInputObjectProps) {
  const isMutating = Boolean(useIsMutating());
  const { t } = useTranslation();
  const { onApprove, onCancel, onClose, item, visible = false } = props;

  const renderPreviewContent = (itemContent: DataInputObjectFile) => {
    const contentType = itemContent.objectType;

    switch (contentType) {
      case 'IMAGE':
        return (
          <Stack alignItems='center' justifyContent='center' mb={2}>
            <img
              src={itemContent.link}
              alt={itemContent.objectName}
              style={{
                border: '3px solid #BC0000',
                height: 'auto',
                width: '50%',
              }}
            />
          </Stack>
        );
      case 'VIDEO':
        return (
          <Stack alignItems='center' justifyContent='center' mb={2}>
            <video
              key={item?.data.objectName}
              src={`${item?.data.link}#t=0.1`}
              style={{
                border: '3px solid #BC0000',
                height: 'auto',
                width: '70%',
              }}
              poster={getYoutubeThumbnailFromURL(item?.data.link || '')}
              preload='metadata'
            />
          </Stack>
        );
      case 'DOCUMENT':
        return (
          <Stack
            alignItems='center'
            alignSelf='center'
            sx={{
              width: '60%',
              border: '1px solid #E0E0E0',
              borderRadius: 2,
            }}
            justifyContent='center'
            flexDirection='row'
            mb={2}
            p={2}
          >
            <DescriptionIcon style={{ fontSize: 30 }} />
            <Typography
              variant='input-label-gray'
              component='p'
              fontSize='70%'
              textAlign='center'
              textOverflow='ellipsis'
              overflow='hidden'
              whiteSpace='nowrap'
              width='100%'
            >
              {itemContent.objectName}
            </Typography>
          </Stack>
        );

      case 'LINK':
        return (
          <Stack
            alignItems='center'
            alignSelf='center'
            sx={{
              width: '60%',
              border: '1px solid #E0E0E0',
              borderRadius: 2,
            }}
            justifyContent='flex-start'
            flexDirection='row'
            gap={1}
            mb={2}
            p={2}
          >
            <LaunchOutlinedIcon style={{ fontSize: 30 }} />
            <Typography
              variant='input-label-gray'
              component='p'
              fontSize='70%'
              textAlign='center'
              textOverflow='ellipsis'
              overflow='hidden'
              whiteSpace='nowrap'
            >
              {itemContent.objectName}
            </Typography>
          </Stack>
        );

      default:
        return null;
    }
  };

  const handleOnApproveDelete = () => {
    if (item && onApprove) {
      onApprove(item.data, item.sectionIndex);
    }
  };

  return (
    <div>
      <Modal open={visible} onClose={onClose}>
        <Stack direction='column' sx={ModalContentStyle}>
          <Stack justifyContent='space-between' direction='row' alignItems='center'>
            <Typography variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
              Delete File Confirmation
            </Typography>
            <IconButton onClick={onClose} component='label' disabled={isMutating}>
              <HighlightOffRoundedIcon sx={closeIconStyle} />
            </IconButton>
          </Stack>
          <Divider sx={{ mb: 1 }} />

          <Stack alignItems='center' sx={{ py: 3 }}>
            <Alert severity='error' sx={{ width: '90%', mt: 2, backgroundColor: '#FFFBE4', color: '#BC0000' }}>
              {`Are you sure you want to delete the file '${item?.data.objectName}'? Once deleted, the file cannot be recovered.`}
            </Alert>
          </Stack>

          {item?.data && renderPreviewContent(item.data)}
          <Divider sx={{ mb: 1 }} />

          <Stack direction='row' justifyContent='center' spacing={2} alignItems='center' sx={{ py: 1 }}>
            <Button disabled={isMutating} variant='main-table-panel' sx={buttonActionStyle} onClick={onCancel}>
              {t('COMMON.BUTTON.CANCEL')}
            </Button>
            <ButtonLoading
              loading={isMutating}
              variant='contained'
              color='error'
              sx={buttonActionStyle}
              onClick={handleOnApproveDelete}
            >
              {t('COMMON.MODAL_DELETE.BUTTON_DELETE')}
            </ButtonLoading>
          </Stack>
        </Stack>
      </Modal>
    </div>
  );
}
