// @ts-nocheck
import * as yup from 'yup';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useMemo, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Dayjs } from 'dayjs';
import { Box, Divider, Grid, Stack, Snackbar, Typography } from '@mui/material';
import Alert, { AlertProps } from '@mui/material/Alert';
import Button from '@mui/material/Button';
import {
  useDisplayFormInput,
  useGetDataInputPullAttributeMapping,
} from 'services/v1/Tenant/AROKMS/DisplayTableService';
import { Formik, FormikProps } from 'formik';
import { generateYupSchema } from 'utils/SchemaGenerator';
import { generateFormInput } from 'pages/Tenant/AROKMS/DataInput/components/FormGenerator';
import ActivityIndicator from 'components/ActivityIndicatorComponent';
import { serializeDataInputPayload } from 'pages/Tenant/AROKMS/DataInput/utils/serialiazePayload';
import { RULES_CONSTANT } from 'constant/RuleConstant';
import { RenderDataTableError } from 'components/DatatableComponent/components/DataTableError';
import { getErrorMessage, getErrorTitle } from 'utils/Error';
import ButtonLoading from '@mui/lab/LoadingButton';
import { TenantHomeDashboardNavigationBar } from 'components/LayoutComponent/VerticalLayout/HeaderBar';
import ModalDeleteComponent, { ModalDeleteState } from 'components/ModalComponent/ModalDeleteComponent';
import { PATH_CONSTANT } from 'constant/PathConstant';
import { useDeleteEventData, useUpdateEventData } from 'services/v1/Tenant/AROEvent/EventTableService';
import { useGetSubjectDetails } from 'services/v1/SystemTenant/AROKMS/SubjectService';

export default function UpdateDataEvent() {
  const { subjectId, recordId } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const authReducer = useSelector((state: RootState) => state.auth);
  const formikRef = useRef<FormikProps<any>>(null);

  const [displayKey, setDisplayKey] = useState<number>(0);
  const [modalDeleteState, setModalDeleteState] = useState<ModalDeleteState>({
    message: 'Are you sure you want to delete this data?',
    open: false,
  });
  const { mutate: updateData, isLoading: isInserting } = useUpdateEventData({ subjectId: subjectId as string });
  const { mutate: deleteData } = useDeleteEventData({ subjectId: subjectId as string });
  const { data: pullAttributeDataMapping } = useGetDataInputPullAttributeMapping(subjectId);
  const { data: eventDetails } = useGetSubjectDetails(subjectId);
  const {
    data: formDefinitionsData,
    isLoading,
    isFetching,
    isError: isFetchFormError,
    error: errorFetchForm,
  } = useDisplayFormInput({ subjectId, recordId });
  const [snackbar, setSnackbar] = useState<Pick<AlertProps, 'children' | 'severity'> | null>(null);

  const handleCloseSnackbar = () => setSnackbar(null);

  const handleOnSubmitForm = (data: { [key: string]: any }, redirectPath: string) => {
    // map data with ruleTypeCode from formDefinitionsData
    // then send to API

    const dataWithRuleTypeCode: {
      [key: string]: { ruleTypeCode: string; value: string | number | object | Dayjs };
    } = _.mapValues(data, (value, key) => {
      if (formDefinitionsData) {
        const fieldItem = _.find(formDefinitionsData.fields, { name: key });
        return { value, ruleTypeCode: fieldItem.ruleTypeCode };
      }
    });

    const payload = {
      ...serializeDataInputPayload(dataWithRuleTypeCode, authReducer),
      id: recordId,
    };

    const tableDefinitionFieldId = Object.keys(serializeDataInputPayload(dataWithRuleTypeCode, authReducer)).reduce(
      (acc, key) => {
        const fieldItem = _.find(formDefinitionsData.fields, { name: key });
        return { ...acc, [key]: fieldItem.tableDefinitionId };
      },
      {}
    );

    updateData(
      { data: payload, tableDefinitionId: tableDefinitionFieldId },
      {
        onSuccess: () => {
          toast.success('Data updated successfully!');
          formikRef.current?.resetForm();
          setDisplayKey(displayKey + 1);
          handleOnCancelInput();
        },
        onError: (err) => {
          toast.error("Something's wrong, please try again later");
        },
      }
    );
  };

  const handleOnShowDeleteConfirmation = () => {
    const message = `Are you sure you want to delete this data?`;
    setModalDeleteState({ ...modalDeleteState, open: true, message });
  };

  const handleOnDeleteData = () => {
    deleteData(
      { subjectId, ids: [recordId] },
      {
        onSuccess: () => {
          toast.success('Data deleted successfully!');
          setModalDeleteState({ ...modalDeleteState, open: false });
          handleOnCancelInput();
        },
      },
      {
        onError: (error: AxiosDefaultErrorEntity) => {
          setModalDeleteState({
            open: true,
            message: '',
            isError: true,
            errorMessage: getErrorMessage(error),
          });
        },
      }
    );
  };

  const handleOnCancelInput = () => {
    const redirectPath = decodeURIComponent(
      searchParams.get('refBackParam') || PATH_CONSTANT.TENANT.EVENT.EVENT.replace(':subjectId', subjectId)
    );
    navigate(redirectPath);
  };

  const yupSchema = useMemo(() => {
    if (!formDefinitionsData) {
      return {};
    }
    return formDefinitionsData.fields.reduce(generateYupSchema, {});
  }, [formDefinitionsData]);

  const validateSchema = useMemo(() => {
    return yupSchema ? yup.object().shape(yupSchema) : {};
  }, [yupSchema]);

  // Seperate fields into 2 columns
  const formFields = useMemo(() => {
    if (!formDefinitionsData) {
      return {
        input: [],
        multimedia: [],
      };
    }
    const ruleToSeperate = [
      RULES_CONSTANT.MULTIMEDIA.RULE_MULTIMEDIA,
      RULES_CONSTANT.GPS.RULE_GPS,
      RULES_CONSTANT.ASSOCIATED_SUBJECT.RULE_ASSOCIATED_SUBJECT_LIST,
      RULES_CONSTANT.ASSOCIATED_SUBJECT.RULE_ASSOCIATED_SUBJECT_LIMITED_DROPDOWN_LIST,
      RULES_CONSTANT.TEXT.RULE_SELECT_FROM_KTREE_TABLE,
      RULES_CONSTANT.BASE_SUBJECT.RULE_ATTRIBUTE_SUBJECT_BASE,
      ...Object.values(RULES_CONSTANT.DATE),
    ];

    return {
      input: formDefinitionsData.fields.filter((fieldItem, index) => {
        return ruleToSeperate.includes(fieldItem.ruleTypeCode);
      }),
      multimedia: formDefinitionsData.fields.filter((fieldItem, index) => {
        return !ruleToSeperate.includes(fieldItem.ruleTypeCode);
      }),
    };
  }, [formDefinitionsData]);

  const tableTitle = useMemo(() => {
    if (!eventDetails?.data) return '';
    return `Edit ${eventDetails?.data.displayName}`;
  }, [eventDetails?.data]);

  return (
    <TenantHomeDashboardNavigationBar>
      <Box sx={{ height: '100%', backgroundColor: '#fff', pb: 3 }}>
        {(isLoading || isFetching) && (
          <Stack height={500}>
            <ActivityIndicator />
          </Stack>
        )}

        {isFetchFormError && (
          <RenderDataTableError
            title={getErrorTitle(errorFetchForm)}
            description={errorFetchForm && getErrorMessage(errorFetchForm)}
          />
        )}

        {!isFetchFormError && !isFetching && formDefinitionsData && (
          <Formik
            innerRef={formikRef}
            enableReinitialize
            initialValues={formDefinitionsData.initialValues}
            validationSchema={validateSchema}
            onSubmit={handleOnSubmitForm}
          >
            {(formikProps: FormikProps<{ [key: string]: string }>) => {
              return (
                <Box sx={{ backgroundColor: '#fff', pb: 1, px: 3 }} alignItems='center' key={displayKey}>
                  <Stack
                    direction='row'
                    alignItems='center'
                    py={1}
                    justifyContent='space-between'
                    sx={{ backgroundColor: '#fff' }}
                    spacing={2}
                  >
                    <Stack>
                      <Typography variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
                        {tableTitle}
                      </Typography>
                    </Stack>
                  </Stack>

                  <Stack>
                    <Typography variant='input-label' fontStyle='italic' sx={{ pt: 1.3, fontSize: 13 }}>
                      (<span style={{ color: 'red' }}>*</span>) indicates required fields
                    </Typography>
                  </Stack>

                  <Grid
                    container
                    marginTop={2}
                    sx={{ backgroundColor: '#fff', py: 1, borderTop: '1px solid #E3EBF6' }}
                    justifyContent='space-between'
                    alignItems='flex-start'
                  >
                    <Grid container item xs={6} direction='column'>
                      {formFields?.input?.map((fieldItem) =>
                        generateFormInput(formikProps, fieldItem, false, undefined, pullAttributeDataMapping?.data)
                      )}
                    </Grid>

                    {formFields.multimedia.length > 0 && (
                      <Divider orientation='vertical' variant='middle' flexItem sx={{ px: 0.5 }} />
                    )}

                    <Grid container item xs={5} direction='column'>
                      {formFields?.multimedia?.map((fieldItem) =>
                        generateFormInput(formikProps, fieldItem, false, undefined, pullAttributeDataMapping?.data)
                      )}
                    </Grid>
                  </Grid>
                  <Stack spacing={2} direction='row' justifyContent='flex-end'>
                    <Button
                      disabled={isInserting}
                      onClick={handleOnShowDeleteConfirmation}
                      variant='outlined'
                      sx={{
                        textTransform: 'none',
                      }}
                      color='error'
                    >
                      Delete
                    </Button>
                    <Button disabled={isInserting} onClick={handleOnCancelInput} variant='main-table-panel-border'>
                      Cancel
                    </Button>
                    <ButtonLoading
                      variant='main-table-panel'
                      onClick={() => formikProps.handleSubmit()}
                      loading={isInserting}
                      // @ts-ignore
                      disabled={!validateSchema?.isValidSync(formikProps.values) || isInserting}
                    >
                      Update
                    </ButtonLoading>
                  </Stack>
                </Box>
              );
            }}
          </Formik>
        )}
        {!!snackbar && (
          <Snackbar
            open
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            onClose={handleCloseSnackbar}
            autoHideDuration={3000}
          >
            <Alert {...snackbar} onClose={handleCloseSnackbar} />
          </Snackbar>
        )}

        <ModalDeleteComponent
          errorMessage={modalDeleteState.errorMessage}
          isError={modalDeleteState.isError}
          visible={modalDeleteState.open}
          title={null}
          message={modalDeleteState.message}
          onApprove={handleOnDeleteData}
          onClose={() => setModalDeleteState({ ...modalDeleteState, open: false })}
          onCancel={() => setModalDeleteState({ ...modalDeleteState, open: false })}
        />
      </Box>
    </TenantHomeDashboardNavigationBar>
  );
}
