import { GridPreProcessEditCellProps, GridRenderEditCellParams, GridValueFormatterParams } from '@mui/x-data-grid-pro';
import { DataGridCellTextInput } from 'components/DatatableComponent/RenderCustomTextInput';
import { renderNumberInputEditCell } from 'components/DatatableComponent/CustomNumberInput';

// Rule Type: Interger

const generalNumberSortGenerator = (v1: any, v2: any, cellParams1: any, cellParams2: any) => {
  if (v1 === null) {
    return 1;
  }
  if (v2 === null) {
    return -1;
  }

  return v1 - v2;
};

// #RULE_INTEGER
export function RuleIntegerInput(isRequired: boolean = false) {
  return {
    type: 'number',

    renderEditCell: (params: GridRenderEditCellParams) => {
      // @ts-ignore
      return renderNumberInputEditCell({ ...params, type: 'number', decimalScale: 0, isRequired });
    },
    valueFormatter: (params: GridValueFormatterParams) => {
      return params.value?.toLocaleString();
    },
    sortComparator: generalNumberSortGenerator,
  };
}

// #RULE_INTEGER_RANGE
export function RuleIntegerInputRange(
  minValue: number | undefined,
  maxValue: number | undefined,
  isRequired: boolean = false
) {
  return {
    type: 'number',

    renderEditCell: (params: GridRenderEditCellParams) => {
      return renderNumberInputEditCell({
        ...params,
        // @ts-ignore
        type: 'number',
        decimalScale: 0,
        min: minValue,
        max: maxValue,
        isRequired,
      });
    },
    valueFormatter: (params: GridValueFormatterParams) => {
      return params.value?.toLocaleString();
    },
    sortComparator: generalNumberSortGenerator,
  };
}

// #RULE_INTEGER_ADDITION
export function RuleIntegerAddition(columns: string[]) {
  return {
    renderEditCell: (params: GridRenderEditCellParams) => <DataGridCellTextInput {...params} />,
    preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
      const value = columns.reduce((acc, field) => {
        const fieldValue = params?.otherFieldsProps?.[field].value || 0;
        if (fieldValue) {
          return acc + Number(fieldValue);
        }
        return acc;
      }, 0);

      return { ...params.props, value };
    },
    valueFormatter: (params: GridValueFormatterParams) => {
      return params.value?.toLocaleString();
    },
    sortComparator: generalNumberSortGenerator,
  };
}

// #RULE_INTEGER_SUBTRACTION
export function RuleIntegerSubstractuion(columns: string[]) {
  return {
    renderEditCell: (params: GridRenderEditCellParams) => <DataGridCellTextInput {...params} />,
    preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
      // values is an array of values of the columns with subtracted values
      const values = columns.map((field) => {
        const fieldValue = params?.otherFieldsProps?.[field].value || 0;
        if (fieldValue) {
          return Number(fieldValue);
        }
        return 0;
      });

      // value is the result of the subtraction
      const value = values.reduce((acc, fieldValue) => {
        return acc - fieldValue;
      });

      return { ...params.props, value };
    },
    valueFormatter: (params: GridValueFormatterParams) => {
      return params.value?.toLocaleString();
    },
    sortComparator: generalNumberSortGenerator,
  };
}

// #RULE_INTEGER_MULTIPLICATION
export function RuleIntegerMultipication(columns: string[]) {
  return {
    renderEditCell: (params: GridRenderEditCellParams) => <DataGridCellTextInput {...params} />,
    preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
      const value = columns.reduce((acc, field) => {
        const fieldValue = params?.otherFieldsProps?.[field]?.value || 0;
        if (fieldValue) {
          return acc * Number(fieldValue);
        }
        return acc;
      }, 1);

      return { ...params.props, value };
    },
    valueFormatter: (params: GridValueFormatterParams) => {
      return params.value?.toLocaleString();
    },
    sortComparator: generalNumberSortGenerator,
  };
}

// #RULE_INTEGER_DIVISION
export function RuleIntegerDivision(columns: string[]) {
  return {
    renderEditCell: (params: GridRenderEditCellParams) => <DataGridCellTextInput {...params} />,
    preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
      // values is an array of values of the columns with divided values
      const values = columns.map((field) => {
        const fieldValue = params?.otherFieldsProps?.[field].value || 0;
        if (fieldValue) {
          return Number(fieldValue);
        }
        return 0;
      });

      // value is the result of the division
      const value = values.reduce((acc, fieldValue) => {
        return acc / fieldValue;
      });

      return { ...params.props, value };
    },
    valueFormatter: (params: GridValueFormatterParams) => {
      return params.value?.toLocaleString();
    },
    sortComparator: generalNumberSortGenerator,
  };
}
