import * as yup from 'yup';
import { useMemo, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Alert from '@mui/lab/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import ButtonLoading from '@mui/lab/LoadingButton';
import ActivityIndicator from 'components/ActivityIndicatorComponent';
import ModalDeleteComponent, { ModalDeleteState } from 'components/ModalComponent/ModalDeleteComponent';
import { AutoCompleteItem } from 'types/api/Common/AutoCompleteTypes';
import {
  useDeleteCustomRule,
  useGetAttributeRuleColorDropdown,
  useGetCustomRuleDetails,
  useGetCustomRuleDropdownFormData,
  useUpsertCustomRule,
} from 'services/v1/Tenant/AROKMS/CustomRuleService';
import {
  AutoCompleteWithType,
  CustomRuleConditionDropdownItem,
  CustomRuleListItem,
  RequestUpsertCustomRule,
} from 'types/api/Tenant/AROKMS/CustomRuleTypes';
import { ConditionField } from './Conditional';
import { useSubjectUsedDropdown } from 'services/v1/SystemTenant/AROKMS/SubjectService';
import { SUBJECT_TYPE } from 'constant/DataInputConstant';

export const CUSTOM_RULE_OPERATOR_VALUE = {
  EQUALS: 'Equals',
  NOT_EQUALS: 'Not Equals',
  NOT_EMPTY: 'Not Empty',
};

export const CUSTOM_RULE_ACTION = {
  SHOW: 'SHOW',
  HIDE: 'HIDE',
  RENAME_FIELD: 'RENAME_FIELD',
  CELL_FORMAT_COLOR_REF_TABLE: 'CELL_FORMAT_COLOR_REF_TABLE',
};
const autocompleteStyles = {
  width: '55%',
  '& .MuiOutlinedInput-root': {
    padding: '1px 2px',
  },
  '& .MuiAutocomplete-popper': {
    fontSize: '10px',
  },
};

const textInputStyles = {
  '& .MuiOutlinedInput': {
    padding: '1px 2px',
  },
};
const conditionAndDividerStyle = {
  height: '40px',
  width: '9%',
  margin: '0 0',
  padding: '0 0 !important',
  '& .MuiDivider-wrapper': {
    padding: '0 0 !important',
  },
};

const conditionChipStyle = {
  height: 'auto',
  fontSize: '13px',
  fontWeight: 'bold',
  margin: '0 0 !important',
  padding: '0 0 !important',
};

const conditionOrDividerStyle = {
  height: '50px',
  width: '9%',
  margin: '0 0',
  '&::before': {
    border: 'none',
  },
  '&::after': {
    border: 'none',
  },
};

export interface CustomRuleDetailsProps {
  style?: React.CSSProperties;
  subjectId?: string | number;
  editRow?: CustomRuleListItem | null;
  onBackToList?: () => void;
}

interface FormikInitialValues {
  attribute: AutoCompleteWithType | null;
  ruleName: string;
  parameterValue?: string;
  cellColorSubjectRef: AutoCompleteItem | null;
  cellColorAttributeRef: AutoCompleteItem | null;
  conditions: {
    id: string;
    operator: string | null;
    field: string;
    operatorValue: string;
    operatorType: string;
    value: string;
  }[];
  action: AutoCompleteItem | null;
}
const formikInitialValues: FormikInitialValues = {
  attribute: null,
  ruleName: '',
  cellColorAttributeRef: null,
  cellColorSubjectRef: null,
  conditions: [
    {
      id: Date.now().toString(),
      operator: null,
      field: '',
      operatorValue: '',
      operatorType: '',
      value: '',
    },
  ],
  action: null,
};
const validationSchema = yup.object({
  attribute: yup
    .object()
    .nullable()
    .shape({
      label: yup.string().required('Attribute is required'),
      value: yup.string().required('Attribute is required'),
    })
    .required('Attribute is required'),

  conditions: yup.lazy((_, context) => {
    const actionValue = context.parent.action?.value;
    if (actionValue === 'CELL_FORMAT_COLOR_REF_TABLE') {
      return yup
        .array()
        .of(
          yup.object().shape({
            field: yup.string().nullable(),
            operator: yup.string().nullable(),
            value: yup.string().when('operator', {
              is: CUSTOM_RULE_OPERATOR_VALUE.NOT_EMPTY,
              then: yup.string().nullable().optional(),
              otherwise: yup.string().nullable().optional(),
            }),
          })
        )
        .optional();
    } else {
      return yup
        .array()
        .of(
          yup.object().shape({
            field: yup.string().required('Field is required'),
            operator: yup.string().required('Operator is required'),
            value: yup.string().when('operator', {
              is: CUSTOM_RULE_OPERATOR_VALUE.NOT_EMPTY,
              then: yup.string().nullable().optional(),
              otherwise: yup.string().required('Value is required'),
            }),
          })
        )
        .required('Conditions are required'); // Otherwise, conditions are required
    }
  }),

  action: yup
    .object()
    .nullable()
    .shape({
      label: yup.string().required('Action is required'),
      value: yup.string().required('Action is required'),
    })
    .required('Action is required'),
});

export function CustomRuleDetails(props: CustomRuleDetailsProps) {
  const { style, subjectId, editRow, onBackToList = () => {} } = props;

  const [modalDeleteState, setModalDeleteState] = useState<ModalDeleteState>({
    message: 'Are you sure you want to delete this rule?',
    open: false,
  });
  const { data: dropdownFormData, isLoading: isLoadingDropdownForm } = useGetCustomRuleDropdownFormData(subjectId);
  const { data: customRuleDetails, isLoading: isLoadingDetail } = useGetCustomRuleDetails(editRow?.id);
  const { mutate: upsertCustomRule, isLoading: isUpdating } = useUpsertCustomRule(subjectId);
  const { mutate: deleteCustomRule } = useDeleteCustomRule();
  const isLoading = isLoadingDropdownForm || isLoadingDetail;

  const { data: subjectDropdownData } = useSubjectUsedDropdown({
    enabled: true,
    subjectType: [SUBJECT_TYPE.TABLE],
  });

  const generateDescription = () => {
    let description = '';
    let stack: string[] = [];
    let tempGroup: string[] = [];

    if (formik.values.action?.value === CUSTOM_RULE_ACTION.CELL_FORMAT_COLOR_REF_TABLE) {
      if (formik.values.cellColorSubjectRef && formik.values.cellColorAttributeRef) {
        return `Attribute ${formik.values.attribute?.label} will be colored based on the data from the '${formik.values.cellColorAttributeRef.label}' column in the '${formik.values.cellColorSubjectRef.label}' table`;
      }
    } else {
      formik.values.conditions.forEach((condition, index) => {
        const { field, operatorValue, value, operator } = condition;

        const valueInDropdown = dropdownFormData?.data.allAttributeOptions.find((item) => item.fieldName === field);
        const valueOption = valueInDropdown?.options?.find((item) => item.value === value);
        // @ts-ignore
        const renderValue = valueOption ? valueOption?.label : value;

        tempGroup.push(
          `the ${field}  ${operatorValue} is ${operator} ${
            operatorValue !== CUSTOM_RULE_OPERATOR_VALUE.NOT_EMPTY ? renderValue : ''
          }`
        );

        if (index < formik.values.conditions.length - 1) {
          const nextOperatorType = formik.values.conditions[index + 1].operatorType;

          if (nextOperatorType === 'OR') {
            if (tempGroup.length > 1) {
              stack.push('(' + tempGroup.join(' AND ') + ')');
            } else {
              stack.push(tempGroup[0]);
            }
            tempGroup = [];
          }
        } else {
          if (tempGroup.length > 1) {
            stack.push('(' + tempGroup.join(' AND ') + ')');
          } else {
            stack.push(tempGroup[0]);
          }
        }
      });

      description = stack.join(' OR ');

      return `Attribute ${formik.values.attribute?.label} will be ${formik?.values?.action?.label} when ${description}.`;
    }
  };

  const formik = useFormik({
    initialValues: formikInitialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {},
  });

  const { data: attribtueColorDropdown, isLoading: isLoadingAttributeColor } = useGetAttributeRuleColorDropdown(
    formik.values.cellColorSubjectRef?.value
  );

  const addCondition = (operator: string) => {
    const newCondition = {
      id: Date.now().toString(),
      operator: null,
      operatorType: operator,
      field: '',
      operatorValue: '',
      value: '',
    };
    formik.setFieldValue('conditions', [...formik.values.conditions, newCondition]);
  };

  const removeCondition = (index: number) => {
    const newConditions = [...formik.values.conditions];
    newConditions.splice(index, 1);
    formik.setFieldValue('conditions', newConditions);
  };

  const isLastCondition = (index: number) => index === formik.values.conditions.length - 1;

  const attributeOptions: AutoCompleteItem[] = useMemo(() => {
    if (dropdownFormData) {
      return dropdownFormData.data.attributeOptions;
    }
    return [];
  }, [dropdownFormData]);

  const fieldData: CustomRuleConditionDropdownItem[] = useMemo(() => {
    if (dropdownFormData) {
      return dropdownFormData.data.allAttributeOptions;
    }
    return [];
  }, [dropdownFormData]);

  const handleOnSubmit = () => {
    const payload: RequestUpsertCustomRule = {
      action: formik.values.action?.value?.toString() || '',
      enabled: true,
      parameterValue: formik.values.parameterValue,
      ruleName: formik.values.ruleName || '',
      tableDefinitionId: formik.values.attribute?.value?.toString() || '',
      ruleCondition: JSON.stringify(formik.values.conditions),
    };

    if (
      formik.values.action?.value === CUSTOM_RULE_ACTION.CELL_FORMAT_COLOR_REF_TABLE &&
      formik.values.cellColorAttributeRef &&
      formik.values.cellColorSubjectRef
    ) {
      const colorReferenceDefinition = {
        tableDefinitionId: formik.values.cellColorAttributeRef.value,
      };
      payload.ruleCondition = JSON.stringify(colorReferenceDefinition);
    }

    if (editRow != null) {
      payload.id = editRow.id;
    }

    upsertCustomRule(payload, {
      onSuccess: () => {
        onBackToList && onBackToList();
        toast.success('Custom rule has been saved successfully');
        formik.resetForm();
      },
    });
  };

  const handleOnShowDeleteConfirmation = () => {
    const message = `Are you sure you want to delete rule '${formik.values.ruleName}'?`;
    setModalDeleteState({ ...modalDeleteState, open: true, message });
  };

  const handleOnDeleteData = () => {
    if (editRow?.id) {
      deleteCustomRule(editRow?.id, {
        onSuccess: () => {
          setModalDeleteState({ ...modalDeleteState, open: false });
          onBackToList && onBackToList();
          toast.success('Custom rule has been deleted successfully');
          formik.resetForm();
        },
      });
    }
  };

  useEffect(() => {
    if (!editRow) formik.resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editRow]);

  useEffect(() => {
    if (customRuleDetails && customRuleDetails.data) {
      const initialEditValues: FormikInitialValues = {
        cellColorAttributeRef: null,
        cellColorSubjectRef: null,
        action: customRuleDetails.data.action,
        attribute: customRuleDetails.data.tableDefinition as AutoCompleteWithType,
        ruleName: customRuleDetails.data.ruleName,
        conditions: JSON.parse(customRuleDetails.data.ruleCondition),
      };
      formik.setValues(initialEditValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customRuleDetails]);

  const isEligibleToSubmit = useMemo(() => {
    if (formik.values.attribute?.type === 'MANDATORY' && formik.values.action?.value === 'HIDE') {
      return false;
    }

    return validationSchema.isValidSync(formik.values);
  }, [formik.values]);

  const isValidSchema = useMemo(() => {
    return validationSchema.isValidSync(formik.values);
  }, [formik.values]);

  const ruleSummaryDesc = useMemo(() => {
    return generateDescription();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values]);

  if (isLoading) {
    return (
      <Stack sx={{ height: 400 }}>
        <ActivityIndicator />
      </Stack>
    );
  }

  return (
    <>
      <Stack px={2} style={style}>
        <Stack width='40%' mb={2}>
          <Typography variant='input-label' fontWeight={800}>
            Rule Name
          </Typography>
          <Typography variant='input-label-gray' fontSize={13}>
            Provide a name for the rule you are creating.
          </Typography>
          <TextField
            sx={textInputStyles}
            name='ruleName'
            hiddenLabel
            variant='outlined'
            size='small'
            onBlur={formik.handleBlur}
            error={formik.touched.ruleName && Boolean(formik.errors.ruleName)}
            helperText={formik.touched.ruleName && formik.errors.ruleName}
            value={formik.values.ruleName}
            placeholder='e.g. Hide Column A'
            onChange={formik.handleChange}
          />
        </Stack>
        <Stack width='40%' mb={2}>
          <Typography variant='input-label' fontWeight={800}>
            Select Attribute
          </Typography>
          <Typography variant='input-label-gray' fontSize={13}>
            Please select an attribute to be used for defining the rule.
          </Typography>
          <Autocomplete
            options={attributeOptions}
            getOptionLabel={(option) => option.label}
            value={formik.values.attribute}
            sx={{ ...autocompleteStyles, width: '100%' }}
            onChange={(event, newValue) => {
              formik.setFieldValue('attribute', newValue);
            }}
            clearIcon={null}
            renderInput={(params) => <TextField {...params} placeholder='Select Attribute...' />}
          />
        </Stack>
        <Divider sx={{ mb: 2, mt: 1 }} />
        <Stack width='100%' mb={3}>
          <Typography variant='input-label' fontWeight={800}>
            Action
          </Typography>
          <Stack flexDirection='row'>
            <Stack width='50%'>
              <Typography variant='input-label-gray' fontSize={13}>
                Choose the action that will be executed when the rule conditions are met.
              </Typography>
              <Autocomplete
                options={dropdownFormData?.data?.actionOptions || []}
                getOptionLabel={(option) => option.label}
                value={formik.values.action}
                onChange={(event, newValue) => {
                  formik.setFieldValue('action', newValue);
                }}
                sx={autocompleteStyles}
                clearIcon={null}
                renderInput={(params) => <TextField {...params} placeholder='Select Action...' />}
              />
            </Stack>
            {formik.values.action?.value === CUSTOM_RULE_ACTION.RENAME_FIELD && (
              <Stack width='40%' mb={2}>
                <Typography variant='input-label-gray' fontSize={13}>
                  Provide a name for overriding the default field name.
                </Typography>
                <TextField
                  sx={textInputStyles}
                  name='parameterValue'
                  hiddenLabel
                  variant='outlined'
                  size='small'
                  onBlur={formik.handleBlur}
                  error={formik.touched.parameterValue && Boolean(formik.errors.parameterValue)}
                  helperText={formik.touched.parameterValue && formik.errors.parameterValue}
                  value={formik.values.parameterValue}
                  placeholder='e.g Description'
                  onChange={formik.handleChange}
                />
              </Stack>
            )}
          </Stack>
        </Stack>

        {formik.values.action?.value === CUSTOM_RULE_ACTION.CELL_FORMAT_COLOR_REF_TABLE && (
          <Stack>
            <Typography variant='input-label' fontWeight={800} mb={1}>
              Select Reference value for cell color
            </Typography>
            <Stack direction='row' gap={2}>
              <Stack width='40%' mb={2}>
                <Typography variant='input-label-gray' fontSize={13}>
                  Please select an subject to be used for defining the rule.
                </Typography>
                <Autocomplete
                  options={subjectDropdownData?.data || []}
                  getOptionLabel={(option) => option.label}
                  value={formik.values.cellColorSubjectRef}
                  sx={{ ...autocompleteStyles, width: '100%' }}
                  onChange={(event, newValue) => {
                    formik.setFieldValue('cellColorSubjectRef', newValue);
                  }}
                  clearIcon={null}
                  renderInput={(params) => <TextField {...params} placeholder='Select Subject...' />}
                />
              </Stack>
              <Stack width='40%' mb={2}>
                <Typography variant='input-label-gray' fontSize={13}>
                  Please select an attribute to be used for defining the rule.
                </Typography>
                <Autocomplete
                  disabled={!formik.values.cellColorSubjectRef}
                  options={attribtueColorDropdown?.data || []}
                  loading={isLoadingAttributeColor}
                  getOptionLabel={(option) => option.label}
                  value={formik.values.cellColorAttributeRef}
                  sx={{ ...autocompleteStyles, width: '100%' }}
                  onChange={(event, newValue) => {
                    formik.setFieldValue('cellColorAttributeRef', newValue);
                  }}
                  clearIcon={null}
                  renderInput={(params) => <TextField {...params} placeholder='Select Color Attribute...' />}
                />
              </Stack>
            </Stack>
          </Stack>
        )}

        {formik.values.action?.value !== CUSTOM_RULE_ACTION.CELL_FORMAT_COLOR_REF_TABLE && (
          <Stack>
            <Typography variant='input-label' fontWeight={800} mb={1}>
              When the following input values are met...
            </Typography>
            <form onSubmit={formik.handleSubmit}>
              {formik.values.conditions.map((condition, index) => (
                <>
                  {condition.operatorType === 'AND' && index !== 0 && (
                    <Divider sx={conditionAndDividerStyle} orientation='vertical' variant='middle'>
                      <Chip label='And' sx={conditionChipStyle} />
                    </Divider>
                  )}
                  {condition.operatorType === 'OR' && index !== 0 && (
                    <Divider sx={conditionOrDividerStyle} orientation='vertical' variant='middle'>
                      <Chip label='Or' sx={conditionChipStyle} />
                    </Divider>
                  )}
                  <Stack>
                    <ConditionField
                      condition={condition}
                      index={index}
                      isLast={isLastCondition(index)}
                      fieldData={fieldData}
                      formik={formik}
                      onRemove={removeCondition}
                      onAdd={addCondition}
                    />
                  </Stack>
                </>
              ))}
            </form>
          </Stack>
        )}

        {isValidSchema && ruleSummaryDesc && (
          <Stack mt={2}>
            <Typography variant='input-label-gray' fontSize={13}>
              Rule summary:
            </Typography>
            <Alert variant='outlined' severity='info'>
              {ruleSummaryDesc}
            </Alert>
          </Stack>
        )}

        {isValidSchema &&
          formik.values.attribute?.type === 'MANDATORY' &&
          formik.values.action?.value === CUSTOM_RULE_ACTION.HIDE && (
            <Stack mt={2}>
              <Alert variant='outlined' severity='error'>
                {`Warning: The 'Hide' action is not applicable to mandatory attributes. '${formik.values.attribute?.label}' is a mandatory attribute and must always be displayed.`}
              </Alert>
            </Stack>
          )}

        {isValidSchema &&
          formik.values.attribute?.type === 'MANDATORY' &&
          formik.values.action?.value === CUSTOM_RULE_ACTION.SHOW && (
            <Stack mt={2}>
              <Alert variant='outlined' severity='error'>
                {`Warning: The 'Show' action cannot be applied to mandatory attributes. This restriction is in place to ensure that essential attributes, such as '${formik.values.attribute?.label}', are always displayed.`}
              </Alert>
            </Stack>
          )}

        <Stack direction='row' mt={5} mb={5} px={2} justifyContent='space-between' alignItems='center'>
          <Stack />
          <Stack direction='row' gap={2}>
            {Boolean(editRow) && (
              <ButtonLoading
                onClick={handleOnShowDeleteConfirmation}
                variant='outlined'
                sx={{
                  textTransform: 'none',
                }}
                color='error'
              >
                Delete
              </ButtonLoading>
            )}
            <ButtonLoading variant='main-table-panel-border' disabled={isUpdating} onClick={onBackToList}>
              Cancel
            </ButtonLoading>
            <ButtonLoading
              variant='main-table-panel'
              disabled={!isEligibleToSubmit}
              loading={isUpdating}
              onClick={handleOnSubmit}
            >
              Save Rule
            </ButtonLoading>
          </Stack>
        </Stack>
      </Stack>
      <ModalDeleteComponent
        errorMessage={modalDeleteState.errorMessage}
        isError={modalDeleteState.isError}
        visible={modalDeleteState.open}
        title={null}
        message={modalDeleteState.message}
        onApprove={handleOnDeleteData}
        onClose={() => setModalDeleteState({ ...modalDeleteState, open: false })}
        onCancel={() => setModalDeleteState({ ...modalDeleteState, open: false })}
      />
    </>
  );
}
