import { Player } from '@lottiefiles/react-lottie-player';
import animationData from 'assets/lottie/initial-setup-loading.json';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useLeavePageConfirm } from 'hooks/usePageLeaveConfirm';

const ModalContentStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: 1,
  px: 3,
  py: 2,
};

export type ModalLoadingState = {
  title?: string;
  open: boolean;
  key?: string | number;
  descriptionMessage?: string;
};

export interface ModalLoadingProps {
  visible?: boolean;
  onClose?: () => void;
  title?: string;
  descriptionMessage?: string;
}

export default function ModalLoadingComponent(props: ModalLoadingProps) {
  const {
    onClose,
    visible = false,
    descriptionMessage = 'Please do not close this page.',
    title = "We're setting things up for you...",
  } = props;

  useLeavePageConfirm(visible, 'Are you sure you want to leave this page?');

  return (
    <div>
      <Modal open={visible} onClose={onClose}>
        <Stack direction='column' sx={ModalContentStyle}>
          <Stack justifyContent='space-between' direction='row' spacing={2}>
            <Typography variant='body1' component='h2' color='#394798'>
              {title}
            </Typography>
          </Stack>

          <Stack alignItems='center' sx={{ py: 3 }}>
            <Player
              src={animationData}
              autoplay
              loop
              style={{
                height: 100,
              }}
            />
            <Typography variant='input-label' align='center'>
              {descriptionMessage}
            </Typography>
          </Stack>
        </Stack>
      </Modal>
    </div>
  );
}
