const JAPANASE_DICTIONARY = {
  translation: {
    PAGE: {
      LOGIN: {
        WELCOME_TITLE: 'アロターゲットへようこそ',
        WELCOME_DESCRIPTION:
          'Aiであなたに合わせて自動調整するビジネス予測ソフト。会計システムと統合された財務予測、リソース計画、サプライ チェーンの最適化',
        WELCOME_COPYRIGHT: 'ARO ターゲットの無断転載を禁じます',
        FORM_TITLE: 'ログイン',
        FORM_SUB_TITLE: '以下のフィールドに入力して、アカウントにサインインします。',
        FORM_BUTTON_LOGIN: 'ログインする',
        FORM_BUTTON_LOGIN_WITH_MICRSOFOT: 'MICROSOFT 365',
        FORM_LOGIN_OPTIONS: 'または認証を使用して',
        FORM_LOGIN_SESSION_CHECKBOX: '私を覚えてますか',
        FORM_LOGIN_FORGOT_PASSWORD: 'パスワードをお忘れですか？',
        FORM_LOGIN_INPUT_EMAIL_LABEL: '電子メールアドレス',
        FORM_LOGIN_INPUT_EMAIL_PLACEHOLDER: 'メールアドレスを入力してください',
        FORM_LOGIN_INPUT_PASSWORD_LABEL: 'パスワード',
        FORM_LOGIN_INPUT_PASSWORD_PLACEHOLDER: 'パスワードを入力してください',
      },
      SUBJECT: {
        TITLE: '科目一覧',
        BUTTON_ADD_NEW: '新しい件名を追加',
      },
      ATTRIBUTE: {
        TITLE: '属性一覧',
        BUTTON_ADD_NEW: '新しい属性を追加',
      },
      MEMBER_LIST: {
        TITLE: 'メンバーリスト',
        BUTTON_ADD_NEW: 'メンバーを追加',
      },
      TABLE_DEFINITION: {
        TITLE: 'テーブル定義',
        BUTTON_ADD_NEW: '新しいフィールドを追加',
        RULE_TYPE: 'ルールの種類',
        DATA_TYPE: 'データ・タイプ',
        DISPLAY_NAME: '表示名',
        PARAMETER: 'パラメータ',
        FIELD_ATTRIBUTE: 'フィールド属性',
        DATA_TYPE_ATTRIBUTE: 'データ型属性',
        MODAL_PARAMETER: {
          TITLE: 'パラメータ',
          BUTTON_SAVE: 'パラメータを保存',
        },
      },
      ERROR_NOT_FOUND: {
        DESCRIPTION: 'お探しのページは見つかりませんでした。',
        BUTTON_ACTION: 'ホームページに戻る',
      },
    },
    SIDEBAR_MENU: {
      DASHBOARD: 'ダッシュボード',
      DATA_BUILDER: 'データビルダー',
      DATA_INPUT: 'データ入力',
      SET_UP: '設定',
      UPDATE: 'アップデート',
      REPORTS: 'レポート',
      SUBJECT: '主題',
      ATTRIBUTE: '属性',
      MEMBER_LIST: 'メンバーリスト',
      TABLE_DEFINITION: 'テーブル定義',
      PROJECTS: 'プロジェクト',
    },
    HEADER_MENU: {
      OPEN_SETTINGS: '設定を開く',
      MULTILANGUAGE: '多言語',
      SETTING: '設定',
      LOGOUT: 'ログアウト',
    },
    COMMON: {
      MODAL_IMPORT: {
        TITLE: 'データのインポート',
        BUTTON_UPLOAD: 'アップロード',
        UPLOAD_AREA_EXCEL: 'またはファイル .xlsx をここにドロップします',
        UPLOAD_AREA_CSV: 'またはファイル .csv をここにドロップします',
        INSTRUCTION_EXCEL: 'インポートしたいEXCELファイルを選択してください',
        INSTRUCTION_CSV: 'Pインポートする CSV ファイルを選択してください',
        START_IMPORT: 'インポートを開始',
      },
      MODAL_DELETE: {
        TITLE: '削除の確認',
        DESCRIPTION: 'このレコードを削除しようとしています。本気ですか？',
        BUTTON_DELETE: '消去',
      },
      MODAL_SELECT_LANGUAGE: {
        TITLE: '言語',
        DESCRIPTION: '使用する言語を選択してください',
      },
      DATATABLE: {
        EMPTY_ROW: 'データがありません',
      },
      LABEL: {
        ASSOCIATE_SUBJECT: '準科目',
        ASSOCIATE_ATTRIBUTE: 'アトリビュートの関連付け',
      },
      BUTTON: {
        EXPORT: 'データのエクスポート',
        EXPORT_CSV_FILE: 'CSVファイルとしてエクスポート',
        EXPORT_EXCEL_FILE: 'Excelファイルとしてエクスポート',
        IMPORT: 'データのインポート',
        IMPORT_CSV_FILE: 'CSVファイルからインポート',
        IMPORT_EXCEL_FILE: 'Excelファイルからインポート',
        DOWNLOAD_TEMPLATE: 'インポート テンプレートのダウンロード',
        DOWNLOAD_TEMPLATE_CSV: 'CSV テンプレートをダウンロード',
        DOWNLOAD_TEMPLATE_EXCEL: 'Excel テンプレートをダウンロード',
        CANCEL: 'キャンセル',
        CREATE: '作成',
        APPLY: '申し込み',
      },
      FORM_ERROR: {
        LOGIN: {
          EMAIL_INVALID: '電子メールは有効な電子メールである必要があります',
          EMAIL_REQUIRED: 'メールアドレス必須',
          PASSWORD_REQUIRED: 'パスワードが必要です',
        },
      },
    },
  },
};

export default JAPANASE_DICTIONARY;
