import { useState, useRef } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import { Box, Grid, Stack, Typography } from '@mui/material';
import ButtonLoading from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import VisibilityOff from '@mui/icons-material/VisibilityOffOutlined';
import Visibility from '@mui/icons-material/VisibilityOutlined';
import { Formik, FormikProps } from 'formik';
import { TenantHomeDashboardNavigationBar } from 'components/LayoutComponent/VerticalLayout/HeaderBar';
import { ButtonBackToTop } from 'components/ButtonComponent/ButtonBackToTop';
import { AddNewPersonValidationSchema } from 'config/FormValidaton/TenantMaster/MasterTable/PersonTableValidationSchema';
import { PersonUsertRequest } from 'types/api/TenantMaster/MasterTable/PersonTableDataTypes';
import { valueSafeEcryptor } from 'utils/Encryptor';
import { useCreatePerson } from 'services/v1/TenantMaster/MasterTable/PersonTableDataService';
import { PATH_CONSTANT } from 'constant/PathConstant';

const textInputStyles = {
  width: '55%',
  '& .MuiOutlinedInput': {
    padding: '1px 2px',
  },
};

interface FormValue {
  person: string;
  fullName: string;
  mobile: string;
  email: string;
  password: string;
  confirmPassword: string;
}

const formInitialValues: FormValue = {
  person: '',
  fullName: '',
  mobile: '',
  confirmPassword: '',
  password: '',
  email: '',
};
export default function AddPersonTablePage() {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const formikRef = useRef<FormikProps<FormValue>>(null);
  const navigate = useNavigate();
  const { mutate: addPerson, isLoading: isInserting } = useCreatePerson();

  const handlePasswordToggle = () => {
    setShowPassword(!showPassword);
  };

  const handleConfirmPasswordToggle = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const handleOnSave = (data: FormValue) => {
    const payload: PersonUsertRequest = {
      fullName: data.fullName,
      mobile: data.mobile,
      userName: data.email,
      password: valueSafeEcryptor(data.confirmPassword) || '',
      person: data.person,
    };

    addPerson(payload, {
      onSuccess: () => {
        toast.success('Data submitted successfully!');
        formikRef.current?.resetForm();
        handleOnCancelInput();
      },
    });
  };

  const isSemiLoading = isInserting;

  const handleOnCancelInput = () => {
    navigate(PATH_CONSTANT.TENANT_MASTER.MASTER_TABLE.PERSON);
  };
  return (
    <TenantHomeDashboardNavigationBar>
      <ButtonBackToTop />
      <Box sx={{ height: '100%' }}>
        <Formik
          innerRef={formikRef}
          initialValues={formInitialValues}
          validationSchema={AddNewPersonValidationSchema}
          onSubmit={handleOnSave}
        >
          {(formikProps: FormikProps<{ [key: string]: string }>) => {
            const { values, handleChange, errors, touched, handleBlur } = formikProps;
            return (
              <Box sx={{ backgroundColor: '#fff', pb: 3, px: 3 }} alignItems='center'>
                <Stack
                  direction='row'
                  alignItems='center'
                  py={1}
                  justifyContent='space-between'
                  sx={{ backgroundColor: '#fff' }}
                  spacing={2}
                >
                  <Stack>
                    <Typography variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
                      Add Person
                    </Typography>
                  </Stack>
                </Stack>
                <Stack>
                  <Typography variant='input-label' fontStyle='italic' sx={{ pt: 1, fontSize: 13 }}>
                    (<span style={{ color: 'red' }}>*</span>) indicates required fields
                  </Typography>
                </Stack>

                <Grid
                  container
                  marginTop={2}
                  sx={{ backgroundColor: '#fff', py: 1, borderTop: '1px solid #E3EBF6' }}
                  justifyContent='space-between'
                  display='flex'
                  flexWrap='wrap'
                  alignItems='flex-start'
                >
                  <Grid container item xs={6} direction='column'>
                    <Stack>
                      <Stack
                        direction='row'
                        spacing={2}
                        alignItems='center'
                        justifyContent='space-between'
                        sx={{ mt: 2 }}
                      >
                        <Typography variant='input-label'>
                          Person
                          <span style={{ color: 'red', marginLeft: 2 }}>*</span>
                        </Typography>

                        <TextField
                          sx={textInputStyles}
                          size='small'
                          disabled={isSemiLoading}
                          placeholder='e.g. John'
                          onChange={handleChange}
                          value={values?.person}
                          onBlur={handleBlur}
                          name='person'
                          error={touched.person && Boolean(errors.person)}
                          helperText={touched.person && errors.person}
                        />
                      </Stack>

                      <Stack
                        direction='row'
                        spacing={2}
                        alignItems='center'
                        justifyContent='space-between'
                        sx={{ mt: 2 }}
                      >
                        <Typography variant='input-label'>
                          Full Name
                          <span style={{ color: 'red', marginLeft: 2 }}>*</span>
                        </Typography>
                        <TextField
                          sx={textInputStyles}
                          size='small'
                          disabled={isSemiLoading}
                          placeholder='e.g. John Doe'
                          onChange={handleChange}
                          value={values?.fullName}
                          onBlur={handleBlur}
                          name='fullName'
                          error={touched.fullName && Boolean(errors.fullName)}
                          helperText={touched.fullName && errors.fullName}
                        />
                      </Stack>

                      <Stack
                        direction='row'
                        spacing={2}
                        alignItems='center'
                        justifyContent='space-between'
                        sx={{ mt: 2 }}
                      >
                        <Typography variant='input-label'>
                          Email
                          <span style={{ color: 'red', marginLeft: 2 }}>*</span>
                        </Typography>
                        <TextField
                          sx={textInputStyles}
                          size='small'
                          disabled={isSemiLoading}
                          placeholder='e.g. johndoe@email.com'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.email}
                          name='email'
                          error={touched.email && Boolean(errors.email)}
                          helperText={touched.email && errors.email}
                        />
                      </Stack>
                      <Stack
                        direction='row'
                        spacing={2}
                        alignItems='center'
                        justifyContent='space-between'
                        sx={{ mt: 2 }}
                      >
                        <Typography variant='input-label'>
                          Mobile Phone
                          <span style={{ color: 'red', marginLeft: 2 }}>*</span>
                        </Typography>
                        <TextField
                          sx={textInputStyles}
                          size='small'
                          disabled={isSemiLoading}
                          placeholder='e.g. 6123456789'
                          onChange={(e) => {
                            const value = e.target.value;
                            if (value.match(/^[0-9]*$/)) {
                              handleChange(e);
                            }
                          }}
                          onBlur={handleBlur}
                          value={values?.mobile}
                          name='mobile'
                          error={touched.mobile && Boolean(errors.mobile)}
                          helperText={touched.mobile && errors.mobile}
                        />
                      </Stack>

                      <Stack
                        direction='row'
                        spacing={2}
                        alignItems='center'
                        justifyContent='space-between'
                        sx={{ mt: 2 }}
                      >
                        <Typography variant='input-label'>
                          Password
                          <span style={{ color: 'red', marginLeft: 2 }}>*</span>
                        </Typography>
                        <TextField
                          sx={textInputStyles}
                          size='small'
                          disabled={isSemiLoading}
                          type={showPassword ? 'text' : 'password'}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder={`User's password`}
                          value={values?.password}
                          name='password'
                          error={touched.password && Boolean(errors.password)}
                          helperText={touched.password && errors.password}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position='end'>
                                <IconButton onClick={handlePasswordToggle} size='small'>
                                  {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Stack>
                      <Stack
                        direction='row'
                        spacing={2}
                        alignItems='center'
                        justifyContent='space-between'
                        sx={{ mt: 2 }}
                      >
                        <Typography variant='input-label'>
                          Confirm Password
                          <span style={{ color: 'red', marginLeft: 2 }}>*</span>
                        </Typography>
                        <TextField
                          sx={textInputStyles}
                          size='small'
                          type={showConfirmPassword ? 'text' : 'password'}
                          disabled={isSemiLoading}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Please re-enter user's password"
                          value={values?.confirmPassword}
                          name='confirmPassword'
                          error={touched.confirmPassword && Boolean(errors.confirmPassword)}
                          helperText={touched.confirmPassword && errors.confirmPassword}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position='end'>
                                <IconButton onClick={handleConfirmPasswordToggle} size='small'>
                                  {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Stack>
                    </Stack>
                  </Grid>
                </Grid>
                <Stack direction='row' spacing={2} justifyContent='flex-end'>
                  <Button disabled={isInserting} onClick={handleOnCancelInput} variant='main-table-panel-border'>
                    Cancel
                  </Button>

                  <ButtonLoading
                    loading={isInserting}
                    variant='main-table-panel'
                    onClick={() => formikProps.handleSubmit()}
                    // @ts-ignore
                    disabled={!AddNewPersonValidationSchema?.isValidSync(formikProps.values) || isInserting}
                  >
                    Save
                  </ButtonLoading>
                </Stack>
              </Box>
            );
          }}
        </Formik>
      </Box>
    </TenantHomeDashboardNavigationBar>
  );
}
