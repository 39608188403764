import { Button, TextField, Autocomplete, Stack } from '@mui/material';
import { CUSTOM_RULE_OPERATOR_VALUE } from './CustomRuleDetail';

const autocompleteStyles = {
  '& .MuiOutlinedInput-root': {
    padding: '1px 2px',
  },
  '& .MuiAutocomplete-popper': {
    fontSize: '10px',
  },
};

const buttonActionConditionStyles = {
  p: 0.5,
  fontSize: 12,
  lineHeight: 1.3,
};

const textInputStyles = {
  '& .MuiOutlinedInput': {
    padding: '1px 2px',
  },
};

function getDisabledNextButton(formik, index) {
  const operatorValue = formik.values?.conditions?.[index]?.operator;

  if (operatorValue === CUSTOM_RULE_OPERATOR_VALUE.NOT_EMPTY) {
    return !formik.values?.conditions?.[index]?.field;
  }

  const disabledNextButton =
    !formik.values?.conditions?.[index]?.field || !operatorValue || !formik.values?.conditions?.[index]?.value;
  return disabledNextButton;
}

export const ConditionField = ({ condition, index, isLast, fieldData, formik, onRemove, onAdd }) => {
  const fieldName = `conditions[${index}].field`;
  const operatorName = `conditions[${index}].operator`;
  const valueName = `conditions[${index}].value`;
  const field = fieldData.find((item) => item.fieldName === condition.field) || {};
  const operatorValue = formik.values?.conditions?.[index]?.operator;
  const disabledOperator = !formik.values?.conditions?.[index]?.field;
  const disabledValue = !formik.values?.conditions?.[index]?.field || !operatorValue;
  const disabledNextButton = getDisabledNextButton(formik, index);

  let InputElement;
  if (field.type === 'OPTIONS') {
    InputElement = (
      <Autocomplete
        options={field.options}
        getOptionLabel={(option) => option.label}
        value={field.options.find((option) => option.value === condition.value) || null}
        onChange={(_, newValue) => {
          formik.setFieldValue(valueName, newValue ? newValue.value : '');
        }}
        disabled={disabledValue}
        sx={autocompleteStyles}
        clearIcon={null}
        renderInput={(params) => <TextField {...params} placeholder='Select Value...' />}
      />
    );
  } else if (field.type === 'URL') {
    InputElement = (
      <TextField
        type='url'
        sx={textInputStyles}
        name={valueName}
        value={condition.value}
        onChange={formik.handleChange}
        hiddenLabel
        disabled={disabledValue}
        variant='outlined'
        size='small'
      />
    );
  } else if (field.type === 'NUMBER') {
    InputElement = (
      <TextField
        sx={textInputStyles}
        type='number'
        name={valueName}
        disabled={disabledValue}
        hiddenLabel
        variant='outlined'
        size='small'
        value={condition.value}
        onChange={formik.handleChange}
      />
    );
  } else {
    InputElement = (
      <TextField
        sx={textInputStyles}
        name={valueName}
        value={condition.value}
        onChange={formik.handleChange}
        hiddenLabel
        disabled={disabledValue}
        variant='outlined'
        size='small'
      />
    );
  }

  if (operatorValue === CUSTOM_RULE_OPERATOR_VALUE.NOT_EMPTY) {
    InputElement = null;
  }

  let OperatorElement = (
    <Autocomplete
      options={field.operator || []}
      getOptionLabel={(option) => option}
      value={condition.operator || null}
      disabled={disabledOperator}
      onChange={(_, newValue) => {
        formik.setFieldValue(operatorName, newValue ? newValue : '');
      }}
      clearIcon={null}
      sx={{ ...autocompleteStyles, width: '100%' }}
      renderInput={(params) => <TextField {...params} placeholder='Select Operator...' />}
    />
  );

  let FieldElement = (
    <Autocomplete
      options={fieldData}
      getOptionLabel={(option) => option.fieldName}
      value={fieldData.find((item) => item.fieldName === condition.field) || null}
      onChange={(_, newValue) => {
        formik.setFieldValue(fieldName, newValue ? newValue.fieldName : '');
      }}
      clearIcon={null}
      sx={autocompleteStyles}
      renderInput={(params) => <TextField {...params} placeholder='Select Attribute...' />}
    />
  );

  return (
    <Stack direction='row' gap={2} width='100%'>
      <Stack width='100%'>{FieldElement}</Stack>
      <Stack width='75%'>{OperatorElement}</Stack>
      <Stack width='100%'>{InputElement}</Stack>

      <Stack width='100%' direction='row' gap={2}>
        {!isLast && (
          <Button variant='main-table-panel' color='primary' size='small' onClick={() => onRemove(index)}>
            Remove
          </Button>
        )}
        {isLast && (
          <>
            <Button
              variant='secondary-table-panel'
              color='primary'
              size='small'
              disabled={disabledNextButton}
              sx={buttonActionConditionStyles}
              onClick={() => onAdd('AND')}
            >
              AND Condition
            </Button>
            <Button
              variant='secondary-table-panel'
              color='primary'
              size='small'
              disabled={disabledNextButton}
              sx={buttonActionConditionStyles}
              onClick={() => onAdd('OR')}
            >
              OR Condition
            </Button>
            {index !== 0 && (
              <Button variant='main-table-panel' color='primary' size='small' onClick={() => onRemove(index)}>
                Remove
              </Button>
            )}
          </>
        )}
      </Stack>
    </Stack>
  );
};
