import { GridRenderEditCellParams } from '@mui/x-data-grid-pro';
import { renderTextInputEditCell } from 'components/DatatableComponent/CustomTextInput';

// Hexa Code
export function RuleHexaCode(isRequired: boolean = false) {
  return {
    type: 'text',
    renderEditCell: (params: GridRenderEditCellParams) => {
      // @ts-ignore
      return renderTextInputEditCell({ ...params, type: 'color', defaultValue: '#000000' });
    },
    renderCell: (params: GridRenderEditCellParams) => {
      if (!Boolean(params.value)) return false;

      return (
        <>
          <div
            style={{
              width: '20px',
              height: '20px',
              backgroundColor: params.value,
              marginRight: 2,
            }}
          />
          <span>{params.value}</span>
        </>
      );
    },
  };
}
