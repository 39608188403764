import { forwardRef } from 'react';
import Button from '@mui/material/Button';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import Stack from '@mui/material/Stack';

interface SettingPanelProps {
  disabled?: boolean;
  onAddColumnAttributeButtonClick?: () => void;
  onAddGroupAttributeButtonClick?: () => void;
  buttonAddColumnLabel?: string;
  buttonAddGroupLabel?: string;
}

export const InsightViewAttributeDefinitionPanel = forwardRef((props: SettingPanelProps, ref) => {
  const {
    onAddColumnAttributeButtonClick,
    onAddGroupAttributeButtonClick,
    disabled,
    buttonAddColumnLabel = 'Add Column',
    buttonAddGroupLabel = 'Add Group',
  } = props;

  const handleAddColunmn = () => {
    onAddColumnAttributeButtonClick?.();
  };

  const handleAddGroup = () => {
    onAddGroupAttributeButtonClick?.();
  };

  return (
    <Stack direction='column' sx={{ backgroundColor: '#fff', py: 1.8 }}>
      <Stack direction='row' justifyContent='space-between' spacing={0} sx={{ backgroundColor: '#fff', px: 2 }}>
        <Stack direction='row' spacing={1} alignItems='center'></Stack>
      </Stack>
      <Stack
        direction='row'
        alignItems='center'
        sx={{ bgcolor: '#FBFBFB', py: 1.5, px: 2, border: '1px solid #E3EBF6' }}
      >
        <Stack direction='row' justifyContent='space-between' width='100%'>
          <Stack direction='row' spacing={2} alignItems='center'>
            <Button
              variant='main-table-panel'
              disabled={disabled}
              startIcon={<AddCircleOutlineOutlinedIcon />}
              onClick={handleAddColunmn}
            >
              {buttonAddColumnLabel}
            </Button>
            {onAddGroupAttributeButtonClick && (
              <Button
                variant='secondary-table-panel'
                disabled={disabled}
                startIcon={<AddCircleOutlineOutlinedIcon />}
                onClick={handleAddGroup}
              >
                {buttonAddGroupLabel}
              </Button>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
});
