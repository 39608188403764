import { toast } from 'react-toastify';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import { Chip } from '@mui/material';
import {
  useGenerateFilterKTreeData,
  useGenerateKTreeData,
  useGetKtreeDefinitionDetails,
  useGetKTreeGenerationFilter,
} from 'services/v1/Tenant/AROKMS/KTreeDataService';
import { useParams } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
import { useFormik } from 'formik';
import { useGetCustomRuleDropdownFormData } from 'services/v1/Tenant/AROKMS/CustomRuleService';
import { CustomRuleConditionDropdownItem } from 'types/api/Tenant/AROKMS/CustomRuleTypes';
import { ConditionField } from '../../DataInput/components/Conditional';
import { getErrorMessage } from 'utils/Error';

const ModalContentStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 900,
  minHeight: 300,
  bgcolor: 'background.paper',
  borderRadius: 1,
  px: 3,
  py: 2,
};

const conditionAndDividerStyle = {
  height: '40px',
  width: '9%',
  margin: '0 0',
  padding: '0 0 !important',
  '& .MuiDivider-wrapper': {
    padding: '0 0 !important',
  },
};

const conditionChipStyle = {
  height: 'auto',
  fontSize: '13px',
  fontWeight: 'bold',
  margin: '0 0 !important',
  padding: '0 0 !important',
};

const conditionOrDividerStyle = {
  height: '50px',
  width: '9%',
  margin: '0 0',
  '&::before': {
    border: 'none',
  },
  '&::after': {
    border: 'none',
  },
};

const buttonActionStyle = { width: '100%' };

const closeIconStyle = { color: '#98A2AE', cursor: 'pointer' };

interface FormikInitialValues {
  conditions: {
    id: string;
    operator: string | null;
    field: string;
    operatorValue: string;
    operatorType: string;
    value: string;
  }[];
}

const formikInitialValues: FormikInitialValues = {
  conditions: [
    {
      id: Date.now().toString(),
      operator: null,
      field: '',
      operatorValue: '',
      operatorType: '',
      value: '',
    },
  ],
};

export interface ModalDeleteProps {
  visible?: boolean;
  title?: string;
  onClose?: () => void;
  ktreeTableId?: string;
}

export default function ModalFilterKTree(props: ModalDeleteProps) {
  const { kTreeId } = useParams<{ kTreeId: string }>();
  const { data: kreeDefinitionDetails } = useGetKtreeDefinitionDetails(kTreeId);
  const { data: ktreeGenerationFilter } = useGetKTreeGenerationFilter(kTreeId);
  const { mutate: generateFilterKTreeData } = useGenerateFilterKTreeData();

  const { mutate: generateKtreeData } = useGenerateKTreeData();

  const { data: dropdownFormData } = useGetCustomRuleDropdownFormData(
    kreeDefinitionDetails?.data?.subject?.id?.toString()
  );

  const { onClose, visible = false, title = 'Generate KTree - Filter' } = props;

  const formik = useFormik({
    initialValues: formikInitialValues,
    onSubmit: (values) => {
      handleOnSubmit();
    },
  });

  const isLastCondition = (index: number) => index === formik.values.conditions.length - 1;
  const handleOnSubmit = () => {
    if (kTreeId) {
      generateFilterKTreeData(
        {
          id: kTreeId,
          filterValue: JSON.stringify(formik.values.conditions),
        },
        {
          onSuccess: () => {
            handleOnGenerateKtreeData();
            formik.resetForm();
            onClose?.();
          },
        }
      );
    }
  };

  const addCondition = (operator: string) => {
    const newCondition = {
      id: Date.now().toString(),
      operator: null,
      operatorType: operator,
      field: '',
      operatorValue: '',
      value: '',
    };
    formik.setFieldValue('conditions', [...formik.values.conditions, newCondition]);
  };

  const removeCondition = (index: number) => {
    const newConditions = [...formik.values.conditions];
    newConditions.splice(index, 1);
    formik.setFieldValue('conditions', newConditions);
  };

  const handleClose = () => {
    formik.resetForm();
    onClose?.();
  };

  const handleOnResetFilter = () => {
    if (kTreeId) {
      generateFilterKTreeData(
        {
          id: kTreeId,
          resetFilter: true,
          filterValue: null,
        },
        {
          onSuccess: () => {
            handleOnGenerateKtreeData();
            handleClose();
          },
        }
      );
    }
  };

  const handleOnGenerateKtreeData = () => {
    const toastId = toast.loading('Building KTree data...');
    if (kTreeId) {
      generateKtreeData(
        { id: kTreeId },
        {
          onSuccess: () => {
            toast.update(toastId, {
              render: 'Build data success!',
              type: 'success',
              isLoading: false,
              autoClose: 3000,
            });
          },
          onError: (error: any) => {
            toast.update(toastId, { render: getErrorMessage(error), type: 'error', isLoading: false, autoClose: 3000 });
          },
        }
      );
    }
  };

  const fieldData: CustomRuleConditionDropdownItem[] = useMemo(() => {
    if (dropdownFormData) {
      return dropdownFormData.data.allAttributeOptions;
    }
    return [];
  }, [dropdownFormData]);

  useEffect(() => {
    if (ktreeGenerationFilter?.data && visible) {
      if (ktreeGenerationFilter.data.filterValue) {
        formik.setValues({
          conditions: JSON.parse(ktreeGenerationFilter.data.filterValue),
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ktreeGenerationFilter?.data, visible]);
  return (
    <div>
      <Modal open={visible}>
        <Stack direction='column' sx={ModalContentStyle} justifyContent='space-between'>
          <Stack>
            <Stack justifyContent='space-between' direction='row' spacing={2}>
              <Typography variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
                {title}
              </Typography>
              <IconButton onClick={handleClose} component='label'>
                <HighlightOffRoundedIcon sx={closeIconStyle} />
              </IconButton>
            </Stack>
            <Divider />
          </Stack>

          <Stack>
            <Typography variant='input-label' fontWeight={800} mb={1}>
              Generate a KTree exclusively for data that meets the following criteria...
            </Typography>
            <form onSubmit={formik.handleSubmit}>
              {formik.values.conditions.map((condition, index) => (
                <>
                  {condition.operatorType === 'AND' && index !== 0 && (
                    <Divider sx={conditionAndDividerStyle} orientation='vertical' variant='middle'>
                      <Chip label='And' sx={conditionChipStyle} />
                    </Divider>
                  )}
                  {condition.operatorType === 'OR' && index !== 0 && (
                    <Divider sx={conditionOrDividerStyle} orientation='vertical' variant='middle'>
                      <Chip label='Or' sx={conditionChipStyle} />
                    </Divider>
                  )}
                  <Stack>
                    <ConditionField
                      condition={condition}
                      index={index}
                      isLast={isLastCondition(index)}
                      fieldData={fieldData}
                      formik={formik}
                      onRemove={removeCondition}
                      onAdd={addCondition}
                    />
                  </Stack>
                </>
              ))}
            </form>
          </Stack>
          <Stack mt={2}>
            <Divider sx={{ mb: 1 }} />

            <Stack flexDirection='row' gap={2} width='90%'>
              <Button variant='main-table-panel' sx={buttonActionStyle} onClick={onClose}>
                Cancel
              </Button>
              <Button variant='main-table-panel' sx={buttonActionStyle} onClick={handleOnResetFilter}>
                Reset Filter
              </Button>
              <Button variant='main-table-panel' sx={buttonActionStyle} onClick={handleOnSubmit}>
                Save
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Modal>
    </div>
  );
}
